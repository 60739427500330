import React, { useState, useEffect } from 'react'
import axios from 'axios'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import { API } from '../../../API/index'
import { CssTextField } from '../../Styles'
import MenuItem from '@mui/material/MenuItem'
import Input from '@mui/material/Input'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import { storage } from '../../../firebase/index'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import { thumbsContainerState, thumb, img, thumbInner } from '../../Styles'
import Button from '@mui/material/Button'
import '../../Create/Create.css'
import Swal from 'sweetalert2'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const names = ['Seed', 'Fertilizers', 'Pesticides']

const clearInputs = {
    firstName: '',
    lastName: '',
    gender: '',
    mobileNumber: '',
    email: '',
    alternatemobile: '',
    dateOfBirth: '',
    language: '',

    firmName: '',
    gst: '',
    pancard: '',
    adharcard: '',
    typeOfCrops: [],

    state: '',
    district: '',
    subDistrict: '',
    village: '',
    pincode: '',
    fullAddress: '',

    unit: '',
    commissionAmount: '',
    category: '',

    bankAccountNumber: '',
    bankAccountName: '',
    ifscCode: '',
    bankName: '',
    bankBranchName: '',
}

function InputSuppliers() {
    const [tab, setTab] = useState(1)
    const [states, setStates] = useState([])
    const [cropTypes, setCropTypes] = React.useState([])
    let licenseTypesArr = cropTypes.map((type) => ({
        name: type,
        licenseNumber: '',
        expDate: '',
        photo: '',
    }))
    console.log(licenseTypesArr)
    const [licensePhoto, setLicensePhoto] = useState(null)
    const [inputSuppliers, setInputSuppliers] = useState([])
    const [inputs, setInputs] = useState({
        firstName: '',
        lastName: '',
        mobileNumber: '',
        email: '',
        alternatemobile: '',
        dateOfBirth: '',
        language: '',

        firmName: '',
        gst: '',
        pancard: '',
        adharcard: '',
        typeOfCrops: cropTypes,

        state: '',
        district: '',
        subDistrict: '',
        village: '',
        pincode: '',
        fullAddress: '',

        unit: '',
        commissionAmount: '',
        category: '',

        bankAccountNumber: '',
        bankAccountName: '',
        ifscCode: '',
        bankName: '',
        bankBranchName: '',
    })

    useEffect(() => {
        getState()
        getInputSuppliers()
    }, [])

    const getInputSuppliers = async () => {
        const { data } = await API.get(`/getInputSupplier?type=0`)
        setInputSuppliers(data?.result)
    }

    const getState = async () => {
        const { data } = await axios.get(
            'http://65.0.145.198:4080/api/v1/getStateList'
        )
        setStates(data?.result)
        console.log(data?.result)
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event
        setCropTypes(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    const setLicenceInputs = (e, index) => {
        console.log(index)
        let myArr = [...licenseTypesArr]
        myArr[index][e.target.name] = e.target.value
        console.log(licenseTypesArr)
    }

    const handleUploadDP = (e, index) => {
        let myArr = [...licenseTypesArr]
        setLicensePhoto(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `licensePhoto/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                async () => {
                    getDownloadURL(storageRef).then((url) => {
                        //  myArr[index]['name'] = url;
                        // alert(index)
                        // console.log(url);
                    })
                }
            )
        }
    }

    const onSubmitInputs = async (e) => {
        console.log('Inputs', inputs)
        const { data } = await API.post('/inputSuppliersRegister', inputs)
        if (data?.success) {
            Swal.fire({
                icon: 'success',
                iconColor: '#28a745',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
            console.log(data)
            setInputs(clearInputs)
            setCropTypes([])
            getInputSuppliers()
        } else {
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    return (
        <div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card card-table p-10-25">
                        <div class="card-header">
                            <h5 class="card-title">Edit Trader</h5>
                        </div>
                        <div class="card-body">
                            <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                                <li class="nav-item">
                                    <a
                                        class={
                                            tab === 1
                                                ? 'nav-link active'
                                                : 'nav-link'
                                        }
                                    >
                                        Personal Info
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a
                                        class={
                                            tab === 2
                                                ? 'nav-link active'
                                                : 'nav-link'
                                        }
                                    >
                                        Firm Details
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a
                                        class={
                                            tab === 3
                                                ? 'nav-link active'
                                                : 'nav-link'
                                        }
                                    >
                                        Contact Details
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a
                                        class={
                                            tab === 4
                                                ? 'nav-link active'
                                                : 'nav-link'
                                        }
                                    >
                                        Commission Details
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a
                                        class={
                                            tab === 5
                                                ? 'nav-link active'
                                                : 'nav-link'
                                        }
                                    >
                                        Bank Account Info
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                {/* Personal Info */}
                                <div
                                    class="tab-pane show active"
                                    id="bottom-justified-tab1"
                                >
                                    {tab === 1 && (
                                        <form onSubmit={() => setTab(2)}>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        fullWidth
                                                        id="fName"
                                                        label="First Name"
                                                        variant="standard"
                                                        name="firstName"
                                                        value={inputs.firstName}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        fullWidth
                                                        id="lName"
                                                        label="Last Name"
                                                        variant="standard"
                                                        name="lastName"
                                                        value={inputs.lastName}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        className="mt-3"
                                                    >
                                                        <InputLabel
                                                            id="demo-simple-select-standard-label"
                                                            style={{
                                                                color: 'black',
                                                            }}
                                                        >
                                                            Gender
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="gender"
                                                            label="Gender"
                                                            color="success"
                                                            name="gender"
                                                            value={
                                                                inputs.gender
                                                            }
                                                            onChange={(e) =>
                                                                setInputs({
                                                                    ...inputs,
                                                                    [e.target
                                                                        .name]:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            <MenuItem value="Male">
                                                                Male
                                                            </MenuItem>
                                                            <MenuItem value="Female">
                                                                Female
                                                            </MenuItem>
                                                            <MenuItem value="Others">
                                                                Others
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        fullWidth
                                                        id="mobileNumber"
                                                        type="number"
                                                        label="Mobile Number"
                                                        variant="standard"
                                                        name="mobileNumber"
                                                        value={
                                                            inputs.mobileNumber
                                                        }
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        fullWidth
                                                        id="emailId"
                                                        label="Email ID"
                                                        variant="standard"
                                                        name="email"
                                                        value={inputs.email}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        fullWidth
                                                        id="altMobile"
                                                        label="Altenate Mobile"
                                                        type="number"
                                                        variant="standard"
                                                        name="alternatemobile"
                                                        value={
                                                            inputs.alternatemobile
                                                        }
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Date Of Birth
                                                    </InputLabel>
                                                    <CssTextField
                                                        fullWidth
                                                        type="date"
                                                        id="dob"
                                                        variant="standard"
                                                        name="dob"
                                                        className="mt-2"
                                                        value={
                                                            inputs.dateOfBirth
                                                        }
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        className="mt-3"
                                                    >
                                                        <InputLabel
                                                            id="demo-simple-select-standard-label"
                                                            style={{
                                                                color: 'black',
                                                            }}
                                                        >
                                                            Language*
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            label="Language"
                                                            color="success"
                                                            name="language"
                                                            value={
                                                                inputs.language
                                                            }
                                                            onChange={(e) =>
                                                                setInputs({
                                                                    ...inputs,
                                                                    [e.target
                                                                        .name]:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                        >
                                                            <MenuItem value="">
                                                                <em>
                                                                    - Select
                                                                    Language -
                                                                </em>
                                                            </MenuItem>
                                                            <MenuItem value="Hindi">
                                                                Hindi
                                                            </MenuItem>
                                                            <MenuItem value="Bengali">
                                                                Bengali
                                                            </MenuItem>
                                                            <MenuItem value="Urdu">
                                                                Urdu
                                                            </MenuItem>
                                                            <MenuItem value="Punjabi">
                                                                Punjabi
                                                            </MenuItem>
                                                            <MenuItem value="Marathi">
                                                                Marathi
                                                            </MenuItem>
                                                            <MenuItem value="Telugu">
                                                                Telugu
                                                            </MenuItem>
                                                            <MenuItem value="Tamil">
                                                                Tamil
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div class="col-md-12 text-end mt-2 mb-2">
                                                    <button
                                                        type="submit"
                                                        class=" w-10 btn btn-primary"
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>

                                {/* Firm Details */}
                                <div
                                    class="tab-pane show active"
                                    id="bottom-justified-tab2"
                                >
                                    {tab === 2 && (
                                        <form
                                            onSubmit={(e) => {
                                                setTab(3)
                                                setInputs({
                                                    ...inputs,
                                                    typeOfLicense:
                                                        licenseTypesArr,
                                                })
                                            }}
                                        >
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        id="firmName"
                                                        label="Firm Name"
                                                        variant="standard"
                                                        name="firmName"
                                                        value={inputs.firmName}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id="gstNo"
                                                        label="GST No"
                                                        variant="standard"
                                                        name="gst"
                                                        value={inputs.gst}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id="panNo"
                                                        label="PAN No"
                                                        variant="standard"
                                                        name="pancard"
                                                        value={inputs.pancard}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id="adNo"
                                                        label="Adhaar No"
                                                        variant="standard"
                                                        name="adharcard"
                                                        value={inputs.adharcard}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        className="mt-3"
                                                    >
                                                        <InputLabel id="demo-multiple-checkbox-label">
                                                            Type Of Crops
                                                        </InputLabel>
                                                        <Select
                                                            required
                                                            labelId="demo-multiple-checkbox-label"
                                                            id="demo-multiple-checkbox"
                                                            multiple
                                                            value={cropTypes}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            input={
                                                                <Input
                                                                    label="Type Of Crop"
                                                                    variant="standard"
                                                                />
                                                            }
                                                            renderValue={(
                                                                selected
                                                            ) =>
                                                                selected.join(
                                                                    ', '
                                                                )
                                                            }
                                                            MenuProps={
                                                                MenuProps
                                                            }
                                                        >
                                                            {names.map(
                                                                (name) => (
                                                                    <MenuItem
                                                                        key={
                                                                            name
                                                                        }
                                                                        value={
                                                                            name
                                                                        }
                                                                    >
                                                                        <Checkbox
                                                                            checked={
                                                                                cropTypes.indexOf(
                                                                                    name
                                                                                ) >
                                                                                -1
                                                                            }
                                                                        />
                                                                        <ListItemText
                                                                            primary={
                                                                                name
                                                                            }
                                                                        />
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                            {/* {licenseTypes.map((crop, index) => {
                                                return (
                                                    <div className="row mt-3 mb-3">
                                                        <div class="col-md-3 mt-4">
                                                            <CssTextField
                                                                fullWidth id="standard-basic"
                                                                label="Type Of License"
                                                                variant="standard"
                                                                value={crop}
                                                            />
                                                        </div>
                                                        <div class="col-md-3 mt-4">
                                                            <CssTextField
                                                                fullWidth id="standard-basic"
                                                                type="number"
                                                                label="License Number"
                                                                variant="standard"
                                                                name="licenseNumber"
                                                                //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licenseNumber']}
                                                                onChange={(e) => setLicenceInputs(e, index)}
                                                            />
                                                        </div>
                                                        <div class="col-md-3 mt-4">
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
                                                                License Exp Date
                                                            </InputLabel>
                                                            <CssTextField
                                                                type="date"
                                                                fullWidth
                                                                variant="standard"
                                                                style={{ marginTop: '10px', marginBottom: '10px' }}
                                                                name="expDate"
                                                                //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licenseNumber']}
                                                                onChange={(e) => setLicenceInputs(e, index)}
                                                            />
                                                        </div>
                                                        <div class="col-md-3 d-flex" style={{ justifyContent: 'space-between' }}>
                                                            <label htmlFor="contained-button-file">
                                                                <Button variant="contained" component="span" color="success">
                                                                    <Input
                                                                        accept="image/"
                                                                        id="contained-button-file"
                                                                        multiple="false"
                                                                        name="photo"
                                                                        type="file"
                                                                        onChange={(e) => handleUploadDP(e, index)}
                                                                        style={{
                                                                            display: 'none',
                                                                            marginBottom: '16px',
                                                                            marginTop: '16px'
                                                                        }}
                                                                    />
                                                                    Upload
                                                                </Button>
                                                            </label>
                                                            <aside style={thumbsContainerState}>
                                                                <div style={thumb}>
                                                                    <div style={thumbInner}>
                                                                        <img src={licensePhoto} style={img} alt="" />
                                                                    </div>
                                                                </div>
                                                            </aside>
                                                        </div>
                                                    </div>
                                                )
                                            })} */}

                                            <div class="col-md-12 text-end mt-2 mb-2">
                                                <button
                                                    className="w-10 btn btn-secondary"
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                    onClick={(e) => setTab(1)}
                                                >
                                                    Previous
                                                </button>
                                                <button
                                                    type="submit"
                                                    class=" w-10 btn btn-primary"
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </div>

                                {/* Contact Details */}
                                <div
                                    class="tab-pane show active"
                                    id="bottom-justified-tab2"
                                >
                                    {tab === 3 && (
                                        <form
                                            onSubmit={(e) => {
                                                setTab(4)
                                                setInputs({
                                                    ...inputs,
                                                    typeOfLicense:
                                                        licenseTypesArr,
                                                })
                                            }}
                                        >
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        className="mt-3"
                                                    >
                                                        <InputLabel
                                                            id="demo-simple-select-standard-label"
                                                            style={{
                                                                color: 'black',
                                                            }}
                                                        >
                                                            State
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            label="State"
                                                            color="success"
                                                            className="pt-1"
                                                            name="state"
                                                            value={inputs.state}
                                                            onChange={(e) =>
                                                                setInputs({
                                                                    ...inputs,
                                                                    [e.target
                                                                        .name]:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                        >
                                                            <MenuItem value="">
                                                                <em>
                                                                    {' '}
                                                                    - Select
                                                                    State -{' '}
                                                                </em>
                                                            </MenuItem>
                                                            <MenuItem value="Assam">
                                                                Assam
                                                            </MenuItem>
                                                            <MenuItem value="Bihar">
                                                                Bihar
                                                            </MenuItem>
                                                            <MenuItem value="Chattisgarh">
                                                                Chattisgarh
                                                            </MenuItem>
                                                            <MenuItem value="Delhi">
                                                                Delhi
                                                            </MenuItem>
                                                            <MenuItem value="Kolkata">
                                                                Kolkata
                                                            </MenuItem>
                                                            <MenuItem value="Mumbai">
                                                                Mumbai
                                                            </MenuItem>
                                                            <MenuItem value="TamilNadu">
                                                                TamilNadu
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        id="standard-basic"
                                                        label="District"
                                                        variant="standard"
                                                        name="district"
                                                        value={inputs.district}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        id="subDistrict"
                                                        label="Sub District"
                                                        variant="standard"
                                                        name="subDistrict"
                                                        value={
                                                            inputs.subDistrict
                                                        }
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        id="standard-basic"
                                                        label="Village"
                                                        variant="standard"
                                                        name="village"
                                                        value={inputs.village}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        id="standard-basic"
                                                        label="Pincode"
                                                        variant="standard"
                                                        name="pincode"
                                                        value={inputs.pincode}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        id="standard-basic"
                                                        label="Full Address"
                                                        variant="standard"
                                                        name="fullAddress"
                                                        value={
                                                            inputs.fullAddress
                                                        }
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-12 text-end mt-2 mb-2">
                                                <button
                                                    className="w-10 btn btn-secondary"
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                    onClick={(e) => setTab(2)}
                                                >
                                                    Previous
                                                </button>
                                                <button
                                                    type="submit"
                                                    class=" w-10 btn btn-primary"
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </div>

                                {/* Commission Details */}

                                <div
                                    class="tab-pane show active"
                                    id="bottom-justified-tab3"
                                >
                                    {tab === 4 && (
                                        <form onSubmit={() => setTab(5)}>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        className="mt-3"
                                                    >
                                                        <InputLabel
                                                            id="demo-simple-select-standard-label"
                                                            style={{
                                                                color: 'black',
                                                            }}
                                                        >
                                                            Unit
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="farmEquipments"
                                                            label="Farm Equipments"
                                                            color="success"
                                                            name="unit"
                                                            value={inputs.unit}
                                                            onChange={(e) =>
                                                                setInputs({
                                                                    ...inputs,
                                                                    [e.target
                                                                        .name]:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                        >
                                                            <MenuItem value="">
                                                                <em>
                                                                    - Select
                                                                    Unit -
                                                                </em>
                                                            </MenuItem>
                                                            <MenuItem value="percentage">
                                                                %
                                                            </MenuItem>
                                                            <MenuItem value="fixedAmount">
                                                                Fixed Amount
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        fullWidth
                                                        id="IScommission"
                                                        label="Commision Amount"
                                                        variant="standard"
                                                        name="commissionAmount"
                                                        className="mt-3 pt-0"
                                                        value={
                                                            inputs.commissionAmount
                                                        }
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        className="mt-3"
                                                    >
                                                        <InputLabel
                                                            id="demo-simple-select-standard-label"
                                                            style={{
                                                                color: 'black',
                                                            }}
                                                        >
                                                            Category
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="Category"
                                                            label="Farm Equipments"
                                                            color="success"
                                                            name="category"
                                                            value={
                                                                inputs.category
                                                            }
                                                            onChange={(e) =>
                                                                setInputs({
                                                                    ...inputs,
                                                                    [e.target
                                                                        .name]:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                        >
                                                            <MenuItem value="">
                                                                <em>
                                                                    - Select
                                                                    Category -
                                                                </em>
                                                            </MenuItem>
                                                            <MenuItem value="Registrations">
                                                                Registrations
                                                            </MenuItem>
                                                            <MenuItem value="Tranctions">
                                                                Tranctions
                                                            </MenuItem>
                                                            <MenuItem value="Referall">
                                                                Referall
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>

                                                <div class="col-md-12 text-end mt-2 mb-2">
                                                    <button
                                                        className="w-10 btn btn-secondary"
                                                        style={{
                                                            marginRight: '10px',
                                                        }}
                                                        onClick={(e) =>
                                                            setTab(3)
                                                        }
                                                    >
                                                        Previous
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        class=" w-10 btn btn-primary"
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>

                                {/* Bank Account Info */}
                                <div
                                    class="tab-pane show active"
                                    id="bottom-justified-tab4"
                                >
                                    {tab === 5 && (
                                        <form action="#">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id=""
                                                        label="Bank Acc"
                                                        variant="standard"
                                                        name="bankAccountNumber"
                                                        value={
                                                            inputs.bankAccountNumber
                                                        }
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>

                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id=""
                                                        label="Account Name"
                                                        variant="standard"
                                                        name="bankAccountName"
                                                        value={
                                                            inputs.bankAccountName
                                                        }
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id=""
                                                        label="IFSC Code"
                                                        variant="standard"
                                                        name="ifscCode"
                                                        value={inputs.ifscCode}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id=""
                                                        label="Bank Name"
                                                        variant="standard"
                                                        name="bankName"
                                                        value={inputs.bankName}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id=""
                                                        label="Branch Name"
                                                        variant="standard"
                                                        name="bankBranchName"
                                                        value={
                                                            inputs.bankBranchName
                                                        }
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-12 text-end mt-2 mb-2">
                                                    <button
                                                        className="w-10 btn btn-secondary"
                                                        style={{
                                                            marginRight: '10px',
                                                        }}
                                                        onClick={(e) =>
                                                            setTab(4)
                                                        }
                                                    >
                                                        Previous
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={(e) =>
                                                            onSubmitInputs()
                                                        }
                                                        class=" w-10 btn btn-primary"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default InputSuppliers
