import React, { useState, useEffect } from 'react'
import FormControl from '@mui/material/FormControl'
import { API } from '../../../API/index'
import Swal from 'sweetalert2'
import Select from 'react-select'
import { Drawer, Grid } from '@mui/material'
import ProductList from './ProductList/ProductList'
import Cart from './ProductList/Cart/cart'
import axios from 'axios'

const bookClear = {
    MicroEnterpreneurId: '',
    farmerId: '',
    firm: '',
    modeOfBooking: '',
    category: '',
}
const formClear = {
    subCategory: '',
    brand: '',
    product: '',
    productImage: '',
    unit: '',
    quantity: '',
    price: '',
    stock: '',
    No: '',
    totalPrice: '',
}
function InputBookings(props) {
    console.log('props', props)
    const [cartItems, setCartItems] = useState([])
    const [open, setOpen] = useState()
    const [addInput, setAddInput] = useState([
        {
            subCategory: '',
            brand: '',
            product: '',
            productImage: '',
            unit: '',
            quantity: '',
            price: '',
            stock: '',
            No: '',
            totalPrice: '',
        },
    ])
    const [inputsArr, setInputsAArr] = useState([
        {
            subCategory: '',
            brand: '',
            product: '',
            productImage: '',
            unit: '',
            quantity: '',
            price: '',
            stock: '',
            No: '',
            totalPrice: '',
        },
    ])
    const [formValues, setFormValues] = useState([
        {
            subCategory: '',
            brand: '',
            product: '',
            productImage: '',
            quantity: '',
            unit: '',
            price: '',
            stock: '',
            No: '',
            totalPrice: '',
        },
    ])
    const [stockValues, setstockValues] = useState([
        {
            id: '',
            stock: '',
        },
    ])
    const [Firmlist, setFirmlist] = useState([])
    const [categorylist, setcategorylist] = useState([])
    const [categoryoptions,setCategoryOptions]=useState([])
    const [subcategorylist, setsubcategorylist] = useState([])
    const [brandlist, setbrandlist] = useState([])
    const [productlist, setproductlist] = useState([])
    const [categoryname, setCategoryName] = useState('')
    const [subcategoryName, setsubcategoryName] = useState('')
    const [list, setlist] = useState([])
    const [catlist, setCatlist] = useState('')
    const [BrandName, setBrandName] = useState('')
    const [Quantitylist, setQuantitylist] = useState([])
    const [unitnewArray, setunitnewArray] = useState([])
    const [stockid, setstockid] = useState('')
    const [inputBooking, setInputBooking] = useState({
        MicroEnterpreneurId: '',
        farmerId: '',
        createdBy: 'Admin',
        firm: '',
        modeOfBooking: '',
        paymentMode:"",
        category: '',
        bookedBy: 'MI',
    })
    useEffect(() => {
        // getState();
        getInputBooking()
        getFirmList()
        getCategoryList()
    }, [])
    // firmlist
    const getFirmList = async () => {
        const { data } = await API.get(`/getInputSupplier?type=0`)
        // console.log("hkjjhj",data)
        setFirmlist(data?.result)
        console.log('firm search', data)
    }
    const Firmoption =
        Firmlist &&
        Firmlist.map((item, ind) => ({
            value: item.mobileNumber,
            label: item.mobileNumber,
        }))
    // categorylist
    const getCategoryList = async () => {
    
        var token = localStorage.getItem("token");
        var config = {
          headers: { 'Authorization': `Bearer ${token}` }
        };
        await axios.get(`https://prod-api.krishione.com/farmer-function/category/inputSupplier`, config)
          .then(res => {
            console.log("d :", res.data.category);
            setcategorylist(res.data.category);
          })
      }

   
    const categoryoption = categorylist.map((item, ind) => ({
        value: item.categoryName,
        label: item.categoryName,
    }))
    console.log(categoryoption,'options')
    const getsubCategoryList = async (val) => {
        // const { data } = await API.get(`/getProductSubCategory?type=0&title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=`+val);
        // console.log("hkjjhj",data)
        // setsubCategorylist(data?.result);
        var token = localStorage.getItem("token");
        var config = {
          headers: { 'Authorization': `Bearer ${token}` }
        };
        setCategoryName(val)
        const subCat = {
          catalogue: "inputSupplier",
          categoryName: val
        }

        await axios.post(`https://prod-api.krishione.com/farmer-function/get-subcategory`, subCat, config)
          .then(res => {
            console.log("get sub cat in input catalogue", res.data.subcategory);
            setsubcategorylist(res.data.subcategory);
          })
      }

    const subcategoryoption = subcategorylist.map((item, ind) => ({
        value: item.subCategoryName,
        label: item.subCategoryName,
    }))

    const getbrandList = async (val) => {
        // const { data } = await API.get(`/getProductBrand?type=0&title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=`+categoryName+'&subCategoryName='+val);
        // console.log("hkjjhj",data)
        // setbrandlist(data?.result);
        let forGet = {
          "categoryName": categoryname,
          "subCategoryName": val,
          "catalogue": "inputSupplier"
        };
        var token = localStorage.getItem("token");
        var config = {
          headers: { 'Authorization': `Bearer ${token}` }
        };
        await axios.post(`https://prod-api.krishione.com/farmer-function/get-brand`, forGet, config)
          .then(data => {
            console.log("brand in input", data);
            setbrandlist(data.data.brand)
          })
          .catch(err => {
            console.log(err)
          })
    
      }
    
    const brandoption = brandlist.map((item, ind) => ({
        value: item.brandName,
        label: item.brandName,
    }))
    // product
    const getproductList = async (val) => {

        let forGet = {
            "categoryName": categoryname,
            "subCategoryName": subcategoryName,
            "catalogue": "inputSupplier",
            "brandName":val,
            "mobile_number": localStorage.getItem('mobile')

          };
          var token = localStorage.getItem("token");
          var config = {
            headers: { 'Authorization': `Bearer ${token}` }
          };
          await axios.post(`https://prod-api.krishione.com/farmer-function/get-fltered-products`, forGet, config)
            .then(data => {
              console.log("brand in input", data);
              setproductlist(data.data.product)
            })
            .catch(err => {
              console.log(err)
            })
     
    }
    const productoption =
        productlist &&
        productlist.map((item, ind) => ({
            value: item.productName,
            label: item.productName,
            image: item.productImage,
        }))

    const getQuantityList = async (val) => {
        const { data } = await API.get(
            `/getProductCatelogQuantity?type=1&title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=` +
                categoryname +
                '&subCategoryName=' +
                subcategoryName +
                '&brandName=' +
                BrandName +
                `&productName=` +
                val +
                `&id=` +
                catlist
        )
        // console.log("hkjjhj",data)
        setQuantitylist(data?.result)
        console.log('product search', data)
    }

    const QuantitylistOption =
        Quantitylist &&
        Quantitylist.map((item, ind) => ({
            value: item.quantity,
            label: item.quantity,
        }))

    const quantityoption =
        productlist &&
        productlist.map((item, ind) => ({
            value: item.quantity,
            label: item.quantity,
        }))
    const getInputBooking = async () => {
        const { data } = await API.get(
            `/getInputBookings?type=0&bookedBy=Farmer`
        )
        var products = data.result.map((item) => item.inputs[0])
        // setinputBookfetch(products);
        setproductlist(products)
        console.log('data', products)
    }
    const handleChange = (i, e) => {
        let newFormValues = [...formValues]
        let newstockValues = [...stockValues]
        console.log('newFormValues', newFormValues, i, newFormValues[i])
        newFormValues[i][e.target.name] = e.target.value
        setFormValues(newFormValues)

        let val =
            newFormValues[i][e.target.name] == newFormValues[i]['No']
                ? e.target.value * newFormValues[i]['price']
                : e.target.value
        console.log('val', val)
        newFormValues[i]['totalPrice'] = val

        if (newFormValues[i][e.target.name] == newFormValues[i]['No']) {
            let res = newFormValues[i]['stock'] - e.target.value
            console.log('res', res)

            console.log('newstockValues', newstockValues, i, newstockValues[i])
            newstockValues[i]['stock'] = res
            newstockValues[i]['id'] = stockid
            console.log('newstockValues', newstockValues)
            setstockValues(newstockValues)
        }
    }
    const addFields = () => {
        setstockValues([...stockValues, { id: '', stock: '' }])

        setFormValues([
            ...formValues,
            {
                subCategory: '',
                brand: '',
                product: '',
                productImage: '',
                quantity: '',
                unit: '',
                price: '',
                stock: '',
                quantity: '',
                totalPrice: '',
            },
        ])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues]
        newFormValues.splice(i, 1)
        setFormValues(newFormValues)
    }
    const handleBook = async () => {
        console.log('handleBook', formValues, {
            ...inputBooking,
            input: formValues,
        })
        let inputBook = {
            ...inputBooking,
            inputs: formValues,
            remainingStack: stockValues,
            farmerId: props && props.farmer,
            MicroEnterpreneurId: props && props.MicroEnt,
        }

        const { data } = await API.post('/inputBookings', inputBook)
        console.log('datas', data)
        if (data?.success) {
            Swal.fire({
                icon: 'success',
                iconColor: '#28a745',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
            console.log(data)
            setFormValues([formClear])
            setInputBooking([bookClear])
            setAddInput([formClear])
            getInputBooking()
            //  setCropTypes([]);
            //  props.getFarmers();
        } else {
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const bookingmodeoption = [
        { value: 'delivery', label: 'DELIVERY' },
        { value: 'pickup', label: 'PICKUP' },
    ]
    const paymentmodeoption = [
        { value: 'cash', label: 'CASH' },
        { value: 'online', label: 'ONLINE' },
    ]

    const handlecategory = (e) => {
        setCategoryName(e.value)
        setInputBooking({ ...inputBooking, category: e.value })
        getsubCategoryList(e.value)
        // setFormValues(formClear)
    }
    const handlesubcategory = (i, e) => {
        getbrandList(e.value)
        setsubcategoryName(e.value)
        let newFormValues = [...formValues]
        newFormValues[i]['subCategory'] = e.value
        newFormValues[i]['brand'] = ''
        newFormValues[i]['product'] = ''
        newFormValues[i]['quantity'] = ''
        newFormValues[i]['unit'] = ''
        newFormValues[i]['price'] = ''
        newFormValues[i]['totalPrice'] = ''
        newFormValues[i]['No'] = ''
        newFormValues[i]['stock'] = ''
        setFormValues(newFormValues)
    }
    const handleBrandcategory = (i, e) => {
        getproductList(e.value)
        setBrandName(e.value)
        let newFormValues = [...formValues]
        newFormValues[i]['brand'] = e.value
        newFormValues[i]['product'] = ''
        newFormValues[i]['quantity'] = ''
        newFormValues[i]['unit'] = ''
        newFormValues[i]['price'] = ''
        newFormValues[i]['totalPrice'] = ''
        newFormValues[i]['No'] = ''
        newFormValues[i]['stock'] = ''
        setFormValues(newFormValues)
    }
    const handleProductcategory = (i, e) => {
        getQuantityList(e.value)
        // handleValues(i,e)
        // getproductList(e.value)
        let newFormValues = [...formValues]
        newFormValues[i]['product'] = e.value

        newFormValues[i]['productImage'] = e.image
        newFormValues[i]['quantity'] = ''
        newFormValues[i]['unit'] = ''
        newFormValues[i]['price'] = ''
        newFormValues[i]['totalPrice'] = ''
        newFormValues[i]['No'] = ''
        newFormValues[i]['stock'] = ''
        setFormValues(newFormValues)
    }

    const handleOptioncategory = (i, e) => {
        handleValues(i, e)
        // getproductList(e.value)
        let newFormValues = [...formValues]
        newFormValues[i]['quantity'] = e.value
        newFormValues[i]['unit'] = ''
        newFormValues[i]['price'] = ''
        newFormValues[i]['totalPrice'] = ''
        newFormValues[i]['No'] = ''
        newFormValues[i]['stock'] = ''
        setFormValues(newFormValues)
        var newArray = Quantitylist.filter(function (el) {
            return el.quantity == e.value
        })
        setunitnewArray(newArray)
        console.log('newArray1', newArray)
    }

    const unitnewArraylist =
        unitnewArray &&
        unitnewArray.map((item, ind) => ({
            value: item.unit,
            label: item.unit,
        }))
    const handleValues = async (i, e) => {
        console.log(
            'handleValues',
            categoryname,
            formValues[i]['brand'],
            formValues[i]['subCategory'],
            formValues[i]['product']
        )
        const { data } = await API.get(
            `/getProductCatelog?type=1&title=ProductCatelog&catelogName=InputSupplierCatelog&brandName=` +
                formValues[i]['brand'] +
                '&subCategoryName=' +
                formValues[i]['subCategory'] +
                '&categoryName=' +
                categoryname +
                '&productName=' +
                formValues[i]['product'] +
                '&id=' +
                catlist
        )
        console.log('hkjjhj', data)
        setlist(data?.result)
        // console.log("brand search",data)
        // console.log('handleValues','categoryName='+categoryname&'brandName='+formvalue[i]['Brand']&'title=ProductCatelog'& 'SubCategoryName='+formvalue[i]['subCategory']&'productName='+formvalue[i]['product']&'catelogName=LiveStockCatelog')
    }
    const stockOption =
        list &&
        list.map((item, ind) => ({
            value: item.stock,
            label: item.stock,
            id: item._id,
        }))
    const unitOption =
        list &&
        list.map((item, ind) => ({
            value: item.unit,
            label: item.unit,
        }))
    const priceOption =
        list &&
        list.map((item, ind) => ({
            value: item.price,
            label: item.price,
        }))
    const handleunitcategory = (i, e) => {
        // handleValues(i,e)
        // getproductList(e.value)
        let newFormValues = [...formValues]
        newFormValues[i]['unit'] = e.value
        newFormValues[i]['price'] = ''
        newFormValues[i]['totalPrice'] = ''
        newFormValues[i]['No'] = ''
        newFormValues[i]['stock'] = ''
        setFormValues(newFormValues)
    }
    const handlepricecategory = (i, e) => {
        // handleValues(i,e)
        // getproductList(e.value)
        let newFormValues = [...formValues]
        newFormValues[i]['price'] = e.value
        newFormValues[i]['totalPrice'] = ''
        newFormValues[i]['No'] = ''
        newFormValues[i]['stock'] = ''
        setFormValues(newFormValues)
    }

    const handlestockcategory = (i, e) => {
        console.log('newArray  e', e)
        let newFormValues = [...formValues]
        newFormValues[i]['stock'] = e.value
        setFormValues(newFormValues)

        setstockid(e.id)
    }
    const handlebooking = (e) => {
        var newArray = Firmlist.filter(function (el) {
            return el.mobileNumber == e.value
        })
        console.log('newArray', newArray[0]._id)
        setCatlist(newArray[0]._id)
        setInputBooking({
            ...inputBooking,
            firm: e.value,
            firmId: newArray[0]._id,
        })
        getCategoryList(newArray[0]._id)
    }
    const onClickAdd = (clickedItem) => {
        setOpen(true)
        console.log(clickedItem, 'clicked')
        setCartItems((prev) => {
            const isItemInCart = prev.find(
                (item) => item._id === clickedItem._id
            )

            if (isItemInCart) {
                return prev.map((item) =>
                    item._id === clickedItem._id
                        ? { ...item, amount: item.amount + 1 }
                        : item
                )
            }

            return [...prev, { ...clickedItem, amount: 1 }]
        })
    }

    const handleAddToCart = (clickedItem) => {
        setCartItems((prev) => {
            const isItemInCart = prev.find(
                (item) => item._id === clickedItem._id
            )

            if (isItemInCart) {
                return prev.map((item) =>
                    item._id === clickedItem._id
                        ? { ...item, amount: item.amount + 1 }
                        : item
                )
            }

            return [...prev, { ...clickedItem, amount: 1 }]
        })
    }

    const handleRemoveFromCart = (clickedItem) => {
        setCartItems((prev) => {
            const isItemInCart = prev.find(
                (item) => item._id === clickedItem._id
            )
            if (isItemInCart) {
                if (isItemInCart.amount === 1)
                    return prev.filter((item) => item._id !== clickedItem._id)
                return prev.map((item) =>
                    item._id === clickedItem._id
                        ? { ...item, amount: item.amount - 1 }
                        : item
                )
            }
            return [...prev, { ...clickedItem, amount: 1 }]
        })
    }
    const handleMobilesearch = (e) => {
        if (e.target.value.length == 10) {
            mobilechecksearch(e.target.value)
        }
    }

    const mobilechecksearch = async (number) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        localStorage.setItem('mobile', number)
        var payload = {
            catalogue: 'inputSupplier',
            mobile_number: number,
        }
        var products = [
            {
                _id: '628f794588b2dc00097aa04b',
                catalogue: 'inputSupplier',
                categoryName: 'test',
                subCategoryName: 'test2',
                brandName: 'test',
                productName: 'product1',
                quantity: '34',
                unit: 'GM',
                purchasePrice: '345',
                salePrice: '23',
                discount: '23',
                isUnlimited: true,
                stock: '',
                productImage: [
                    {
                        _id: '628effcba6b8790009563d83',
                        catalogue: 'inputSupplier',
                        categoryName: 'test',
                        subCategoryName: 'test2',
                        brandName: 'test',
                        productName: 'product1',
                        productImage:
                            'https://firebasestorage.googleapis.com/v0/b/krishivan-dev.appspot.com/o/driverPhotos%2FLogo.svg?alt=media&token=97e3ab4a-cc5e-40d8-9e39-2ba23393a1c5',
                        mobile_number: '2356435342',
                        user_id: 'e9dt5qmjcoOrSMhCDsYIvNLis743',
                        created_at: 1653538763997,
                    },
                ],
                mobile_number: '2356435342',
                user_id: 'e9dt5qmjcoOrSMhCDsYIvNLis743',
                created_at: 1653569861314,
            },
        ]

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product-getinventory`,
            payload,
            config
        )
        console.log('data', data)
        const categories= [...new Set(data.data.map((item, ind) => ({
            value: item.categoryName,
            label: item.categoryName,})))];
            setCategoryOptions(categories)
        setproductlist(data.data)
    }
    return (
        <>
            <div>
                {console.log('inputBooking', inputBooking, addInput, inputsArr)}
                <div>
                    <div class="row">
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <div   style={{
                                        padding: '30px 0',
                                        display: 'flex',
                                        width: '500px',
                                        justifyContent: 'space-between',
                                        alignItems:"center",
                                        flexWrap: 'wrap',
                                    }}>
                                <div
                                        class="form-group"
                                        style={{ marginLeft: '2%' }}
                                        className="mt-3"
                                    >
                                        <input
                                            type="text"
                                            style={{padding:"25px"}}
                                            class="form-control"
                                            placeholder="Search Firm Number"
                                            onChange={(e) =>
                                                handleMobilesearch(e)
                                            }
                                        />
                                    </div>
                                    <div>
                                        <FormControl
                                            variant="standard"
                                            className="mt-3"
                                        >
                                            <Select
                                                style={{
                                                    width: '270px',
                                                    height: '40px',
                                                }}
                                                placeholder={
                                                    'Select Booking Mode'
                                                }
                                                onChange={(e) =>
                                                    setInputBooking({
                                                        ...inputBooking,
                                                        modeOfBooking: e.value,
                                                    })
                                                }
                                                options={bookingmodeoption}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        padding: '30px 0',
                                        display: 'flex',
                                        width: '1032px',
                                        justifyContent: 'space-between',
                                        marginBottom: '60px',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                  
                                    <div>
                                        <FormControl
                                            variant="standard"
                                            className="mt-3"
                                        >
                                            <Select
                                                style={{
                                                    width: '270px',
                                                    height: '40px',
                                                }}
                                                placeholder={
                                                    'Select Payment Mode'
                                                }
                                                onChange={(e) =>
                                                    setInputBooking({
                                                        ...inputBooking,
                                                        paymentMode: e.value,
                                                    })
                                                }
                                                options={paymentmodeoption}
                                            />
                                        </FormControl>
                                    </div>

                                    <div>
                                        <FormControl
                                            variant="standard"
                                            className="mt-3"
                                        >
                                            <Select
                                                style={{
                                                    width: '270px',
                                                    height: '40px',
                                                }}
                                                placeholder={'Select Category'}
                                                onChange={(e) =>
                                                    handlecategory(e)
                                                }
                                                options={categoryoption}
                                            />
                                        </FormControl>
                                    </div>

                                    {formValues.map((element, index) => {
                                        return (
                                            <>
                                                <div>
                                                    {console.log(
                                                        'input',
                                                        formValues
                                                    )}
                                                    <FormControl
                                                        variant="standard"
                                                        className="mt-3"
                                                    >
                                                        <Select
                                                            style={{
                                                                width: '250px',
                                                                height: '40px',
                                                            }}
                                                            placeholder={
                                                                'Select Sub Category'
                                                            }
                                                            onChange={(e) =>
                                                                handlesubcategory(
                                                                    index,
                                                                    e
                                                                )
                                                            }
                                                            options={
                                                                subcategoryoption
                                                            }
                                                        />
                                                    </FormControl>
                                                </div>

                                                <div>
                                                    <FormControl
                                                        variant="standard"
                                                        className="mt-3"
                                                    >
                                                        <Select
                                                            style={{
                                                                width: '250px',
                                                                height: '40px',
                                                            }}
                                                            placeholder={
                                                                'Select Brand'
                                                            }
                                                            onChange={(e) =>
                                                                handleBrandcategory(
                                                                    index,
                                                                    e
                                                                )
                                                            }
                                                            options={
                                                                brandoption
                                                            }
                                                        />
                                                    </FormControl>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>

                                <ProductList
                                    products={productlist}
                                    onClickAdd={onClickAdd}
                                />
                            </Grid>
                            <Drawer
                                anchor="right"
                                open={open}
                                onClose={() => setOpen()}
                            >
                                <Cart
                                    inputBooking={inputBooking}
                                    cartItems={cartItems}
                                    addToCart={handleAddToCart}
                                    removeFromCart={handleRemoveFromCart}
                                />
                            </Drawer>
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InputBookings
