import React from 'react'
import farmericon from './Farmer.png';
import Acceptedicon from './Accepted.png';
import ordericon from './Order.png';
function PGListWidget(props) {
    return (
        <div class="row">
            <div class="col-xl-3 col-sm-6 col-12">
                <div class="card cardhome">
                    <div class="card-body">
                        <div class="dash-widget-header">
                            <span class="dash-widget-icon ">
                               <img src={farmericon} width={65} />
                            </span>
                            <div class="dash-count">
                                <div class="dash-title"> Farmer</div>
                                <div class="dash-counts">
                                    <p>{props.farmerCount?props.farmerCount:0}</p>
                                </div>
                            </div>
                        </div>
                        <div class="progress progress-sm mt-3">
                            <div
                                class="progress-bar bg-5 width_65"
                                role="progressbar 5"
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        <p class="text-muted mt-3 mb-0">
                            <span class="text-danger me-1">
                                <i class="fas fa-arrow-down me-1"></i>1.15%
                            </span>{' '}
                            since last week
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-sm-6 col-12">
                <div class="card cardhome">
                    <div class="card-body">
                        <div class="dash-widget-header">
                            <span class="dash-widget-icon ">
                            ₹

                            </span>
                            <div class="dash-count">
                                <div class="dash-title"> Revenue</div>
                                <div class="dash-counts">
                                    <p>{props.revenue}</p>
                                </div>
                            </div>
                        </div>
                        <div class="progress progress-sm mt-3">
                            <div
                                class="progress-bar bg-6 width_55"
                                role="progressbar "
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        <p class="text-muted mt-3 mb-0">
                            <span class="text-success me-1">
                                <i class="fas fa-arrow-up me-1"></i>2.37%
                            </span>{' '}
                            since last week
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-sm-6 col-12">
                <div class="card cardhome">
                    <div class="card-body">
                        <div class="dash-widget-header">
                            <span class="dash-widget-icon ">
                            <img src={ordericon} width={65} />

                            </span>
                            <div class="dash-count">
                                <div class="dash-title"> Orders</div>
                                <div class="dash-counts">
                                    <p>{props.bookingCount}</p>
                                </div>
                            </div>
                        </div>
                        <div class="progress progress-sm mt-3">
                            <div
                                class="progress-bar bg-7 width_45"
                                role="progressbar "
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        <p class="text-muted mt-3 mb-0">
                            <span class="text-success me-1">
                                <i class="fas fa-arrow-up me-1"></i>3.77%
                            </span>{' '}
                            since last week
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-sm-6 col-12">
                <div class="card cardhome">
                    <div class="card-body">
                        <div class="dash-widget-header">
                            <span class="dash-widget-icon ">
                            ₹

                            </span>
                            <div class="dash-count">
                                <div class="dash-title">
                                    GMV
                                </div>
                                <div class="dash-counts">
                                    <p>{props.gmv}</p>
                                </div>
                            </div>
                        </div>
                        <div class="progress progress-sm mt-3">
                            <div
                                class="progress-bar bg-8 width_75"
                                role="progressbar "
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        <p class="text-muted mt-3 mb-0">
                            <span class="text-danger me-1">
                                <i class="fas fa-arrow-down me-1"></i>8.68%
                            </span>{' '}
                            since last week
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PGListWidget
