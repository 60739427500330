import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import CartItem from './cartItem'

const Cart = ({ inputBooking, cartItems, addToCart, removeFromCart, type }) => {
    const [deliveryfee, setDeliveryfee] = useState(0)
    const calculateTotal = (items) =>
        items.reduce((acc, item) => acc + item.amount * item.salePrice, 0)

    const price = cartItems.reduce(
        (int, a) => int + +a.salePrice * +a.amount,
        0
    )
    const discount = cartItems.reduce(
        (int, a) => int + +a.discount * +a.amount,
        0
    )
    useEffect(() => {
        if (inputBooking.modeOfBooking == 'delivery') {
            setDeliveryfee(30)
        }
    }, [inputBooking.modeOfBooking])

    const handlebook = () => {
        // var data = {
        //   "crop_name": "Test 2",
        //   "crop_type": "INput SUb CAT",
        //   "variety": "INput SUb CAT",
        //   "user_id": "test123",
        //   "availability_date": "23-06-2022",
        //   "availability_days": "23",
        //   "quantity": 1,
        //   "unit": "2",
        //   "type": "sell",
        //   "price": 1,
        //   "remark": "des",
        //   "images": [],
        //   "status": "in_progress"
        // }
        // axios.post(`${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product/sell', data).then(res => Swal.fire({
        //   icon: 'success',
        //   iconColor: '#28a745',
        //   title: cartItems.length + ' items Successfully Booked',
        //   showConfirmButton: false,
        //   timer: 2000
        // })).catch(err => Swal.fire({
        //   icon: 'warning',
        //   iconColor: '#dc3545',
        //   title: "error occured!",
        //   showConfirmButton: false,
        //   timer: 2000
        // }))

        const cartItem = cartItems.map((item) => {
            console.log(item)
            const it = {}
            it.product_name = item.productName
            it.qty = item.quantity
            it.unit = item.unit
            it.sizeColor=item.sizeColor
            it.product_id = item.product_id
            it.image = item.productImage
            it.price = item.salePrice
            it.discount = item.discount
            it.total_no = item.amount.toString()
            return it
        })

        const data = {
            user_id: localStorage.getItem('FormerId'),
            createdById: localStorage.getItem('microId'),
            firm: localStorage.getItem('mobile'),
            mode_of_booking: inputBooking.modeOfBooking,
            subtotal: price,
            total_discount: discount,
            total_price:
                calculateTotal(cartItems).toFixed(2) - discount + deliveryfee,
                billing_type:inputBooking.paymentMode,    
            cart: cartItem,
            type: 'buy',
            delivery_fees: deliveryfee,
            total_fees:
                calculateTotal(cartItems).toFixed(2) - discount + deliveryfee,
            status: 'in_progress',
        }
        const livestockdata = {
            user_id: localStorage.getItem('FormerId'),
            createdById: localStorage.getItem('microId'),
            firm: localStorage.getItem('mobile'),
            mode_of_booking: inputBooking.modeOfBooking,
            total_discount: discount,
            billing_type:inputBooking.paymentMode,
            cart: cartItem,
            subtotal: price,
            type: 'live-stock',
            delivery_fees: deliveryfee,
            total_price:
                calculateTotal(cartItems).toFixed(2) - discount + deliveryfee,
            total_fees:
                calculateTotal(cartItems).toFixed(2) - discount + deliveryfee,
            status: 'in_progress',
        }
        console.log('cart data', data)
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        if (type) {
            axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/live-stock`,
                    livestockdata,
                    config
                )
                .then((res) =>
                    Swal.fire({
                        icon: 'success',
                        iconColor: '#28a745',
                        title: cartItems.length + ' items Successfully Booked',
                        showConfirmButton: false,
                        timer: 2000,
                    })
                )
                .catch((err) =>
                    Swal.fire({
                        icon: 'warning',
                        iconColor: '#dc3545',
                        title: 'error occured!',
                        showConfirmButton: false,
                        timer: 2000,
                    })
                )
        } else {
            axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product/buy`,
                    data,
                    config
                )
                .then((res) =>
                    Swal.fire({
                        icon: 'success',
                        iconColor: '#28a745',
                        title: cartItems.length + ' items Successfully Booked',
                        showConfirmButton: false,
                        timer: 2000,
                    })
                )
                .catch((err) =>
                    Swal.fire({
                        icon: 'warning',
                        iconColor: '#dc3545',
                        title: 'error occured!',
                        showConfirmButton: false,
                        timer: 2000,
                    })
                )
        }
    }
    return (
        <div style={{ padding: '50px' }}>
            <h2 style={{ paddingBottom: '50px' }}>Your Cart</h2>
            {cartItems.length === 0 ? (
                <div class="col-sm-12 empty-cart-cls text-center">
                    {' '}
                    <img
                        src="https://i.imgur.com/dCdflKN.png"
                        width="130"
                        height="130"
                        class="img-fluid mb-4 mr-3"
                    />
                    <h3>
                        <strong>Oops! Your Cart is Empty</strong>
                    </h3>
                </div>
            ) : null}
            {cartItems.map((item) => (
                <CartItem
                    key={item._id}
                    item={item}
                    addToCart={addToCart}
                    removeFromCart={removeFromCart}
                />
            ))}
            <br />
            <h5>Sub Total: ₹{price}</h5>
            <h5>Discount: ₹{discount}</h5>
            <h5>Delivery Fee: ₹{deliveryfee}</h5>

            <h2>
                Total: ₹
                {calculateTotal(cartItems).toFixed(2) - discount + deliveryfee}
            </h2>

            <div className="col-md-12 text-end mt-2 mb-2 mt-4">
                <button
                    style={{ width: '100%' }}
                    type="submit"
                    class="  btn btn-primary"
                    onClick={() => handlebook()}
                >
                    Book
                </button>
            </div>
        </div>
    )
}

export default Cart
