import React, { useState, useEffect } from 'react'
import { API } from '../../../API/index'
import { CssTextField } from '../../Styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import axios from 'axios'
import Swal from 'sweetalert2'
import MicroEntTable from './MicroEntTable'
import $ from 'jquery'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Selects from 'react-select'
import Map from '../Map'
import OtpBox from '../../../components/OtpBox'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const clearvalue = {
    firstName: '',
    lastName: '',
    mobileNumber: '',
    gender: '',
    category: '',
    dateOfBirth: '',
    language: '',
    partner_mode: '',
    businessType: '',
    email: '',
    alternateMobile: '',
    handicapped: '',
    availability: '',

    firmName: '',
    state: '',
    district: '',
    subDistrict: '',
    village: '',
    pincode: '',
    gstNumber: '',
    fullAddress: '',

    unit: '',
    commissionAmount: '',
    commissionCategory: '',

    bankAccountNumber: '',
    bankAccountName: '',
    ifscCode: '',
    bankName: '',
    bankBranchName: '',
}
function EditMicroEnterpreneur() {
    const [Formerinfo, setFormerinfo] = useState({})
    const { id } = useParams()
    const [fpoList, setFpoList] = useState([])
    const [fpo, setFpo] = useState([])
    const [createdByid,setCreatedById]=useState('')
    const [createdByRole,setCreatedByRole]=useState("")
    const [tab, setTab] = useState(1)
    const [states, setStates] = useState([])
    const [citylist, setcitylist] = useState([])
    const [statelist, setstatelist] = useState([])
    const [CommisionCat, setCommisionCat] = useState([])
    const [MicroENt, setMicroENt] = useState([])
    const [otpWindow, setOtpWindow] = useState(false)
    const [microEnt, setMicroEnt] = useState({
        id:"",
        user_id:"",
        firstName: '',
        lastName: '',
        mobileNumber: '',
        gender: '',
        category: '',
        dateOfBirth: '',
        language: '',
        partner_mode: '',
        businessType: '',
        email: '',
        alternateMobile: '',
        handicapped: '',
        availability: '',
        designation:"",
        firmName: '',
        state: '',
        district: '',
        subDistrict: '',
        village: '',
        pincode: '',
        gstNumber: '',
        fullAddress: '',

        unit: '',
        commissionAmount: '',
        commissionCategory: '',

        bankAccountNumber: '',
        bankAccountName: '',
        ifscCode: '',
        bankName: '',
        bankBranchName: '',
    })

   
    useEffect(() => {
      
        mobilechecksearch()
    }, [])
   
    const handleSearch = (query) => {
        const baseUrls = `${process.env.REACT_APP_API_ENDPOINT}/auth-function/fpo-list/?search=`
        // const baseUrl = "https://Warranty.lsin.panasonic.com/api/generator/?search=";
        const configs = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        axios.get(`${baseUrls}${query}`, configs).then((res) => {
            console.log(res.data.msg)
            // setData(res.data.msg)
            var colourOptions = res.data.data.map((a) => {
                return {
                    //  value:a.itemCode,
                    label: a.id,
                    createdById: a.user_id,
                }
            })
            setFpoList(colourOptions)

            // fetch(`${SEARCH_URI}?q=${query}+in:login&page=1&per_page=50`)
            //   .then((resp) => resp.json())
            //   .then(({ items }: Response) => {
            //     setOptions(items);
            //     setIsLoading(false);
            //   });
        })
    }
  
    const handleSelectChange = (selectedOption, index) => {
        setFpo(selectedOption)
    }
  
    const onSubmitMicroEnt = async (e) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const dataForOtp = {
            mobile_number: microEnt.mobileNumber,
            role: 'microp',
            created_by: 'admin',
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/auth-function/otp`,
                dataForOtp,
                config
            )
            .then((res) => {
                setOtpWindow(true)
            })
    }
    const mobilechecksearch = async (number) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var farmerid = localStorage.getItem('farmerid')

        var payload = {
            mobile_no: id,
            role: 'micro-entrepreneur',
        }
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-register`,
            payload,
            config
        )

        console.log('data', data.data, data.farm_info)

        if (data.data) {
            setMicroEnt((prevFarmer) => ({
                ...prevFarmer,
                id: data.data.data.id,
                user_id: data.data.data.user_id,
                firstName: data.data.data.first_name,
                lastName: data.data.data.last_name,
                gender: data.data.data.gender,
                mobileNumber: data.data.data.mobile_number,
                category: data.data.data.role,
                dob: data.data.data.dob,
                AadharNo: '', // You can set this if available in data
                language: '', // You can set this if available in data
                designation: data.data.data.designation,
                state: data.data.data.state,
                district: data.data.data.district,
                subDistrict: data.data.data.sub_distric,
                village: data.data.data.village,
                pincode: data.data.data.pincode,
                fullAddress: data.data.data.full_address,

                

            }))
        }
    }
    const syncOtpSubmit = (e) => {
        setOtpWindow(false)
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var micdata = {
            user_id: '',
            first_name: microEnt.firstName,
            last_name: microEnt.lastName,
            gender: microEnt.gender,
            dob: microEnt.dateOfBirth,
            mobile_number: microEnt.mobileNumber,
            role: 'micro-entrepreneur',
            coordinates: [microEnt.lat, microEnt.lng],
            photo: 'test',
            language: microEnt.language,
            partner_mode:  fpo[0].createdById,
            state: microEnt.state,
            designation:microEnt.designation,
            district: microEnt.district,
            sub_distric: microEnt.district,
            village: microEnt.district,
            pincode: +microEnt.pincode,
            full_address: microEnt.address,
            created_by: createdByRole,
            createdById: fpo[0].createdById,
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/auth-function/farmers`,
                micdata,
                config
            )
            .then((res) => {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: 'Micro Entrepreneur Created Successfully',
                    showConfirmButton: false,
                    timer: 2000,
                })
                setMicroEnt(clearvalue)

                setTab(1)
            })
            .catch((err) =>
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: 'error occured!',
                    showConfirmButton: false,
                    timer: 2000,
                })
            )
        setMicroEnt(clearvalue)
    }

    const handleMobVal = (e) => {
        setMicroEnt({ ...microEnt, [e.target.name]: e.target.value })
        if (e.target.value.length == 10) {
            mobilecheck(e.target.value)
        } else if (e.target.value.length > 10) {
            console.log('e.target.value.length', e.target.value.length)
            setMicroEnt({ ...microEnt, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: 'Mobile length should be 10',
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const mobilecheck = async (number) => {
        const { data } = await API.get(`/checkNumber?number=` + number)
        console.log('data', data)
        if (data.success == true) {
            // setFarmer({ ...farmer, [e.target.name]: number })
        } else {
            setMicroEnt({ ...microEnt, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data.result,
                showConfirmButton: false,
                timer: 2000,
            })
        }
        // setDetails(data?.result);
    }
    const IrrigationSource = [
        {
            value: 'FO',
            label: 'FO',
        },
        {
            value: 'VLE',
            label: 'VLE',
        },
        {
            value: 'EMPLOYEE',
            label: 'EMPLOYEE',
        },
        
    ]


   
    $(function () {
        $('#txtNumeric').keydown(function (e) {
            if (e.shiftKey || e.ctrlKey || e.altKey) {
                e.preventDefault()
            } else {
                var key = e.keyCode

                if (
                    !(
                        key == 8 ||
                        key == 32 ||
                        key == 46 ||
                        (key >= 35 && key <= 40) ||
                        (key >= 65 && key <= 90)
                    )
                ) {
                    e.preventDefault()
                }
            }
        })
    })
    const mapdata = (data) => {
        console.log('ran')
        setMicroEnt({
            ...microEnt,
            state: data.state,
            district: data.district,
            village: data.village,
            pincode: data.pincode,
            address: data.address,
            fullAddress: data.address,
            lat: data.latitude,
            lng: data.longitude,
        })
    }
    const handleFarmer = (e) => {
        if (e.target.value.length == 10) {
            getFormerinfo(e.target.value)
        } else {
            setFormerinfo({})
        }
    }
    const getFormerinfo = async (Mob) => {
        const token = localStorage.getItem('token')
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        const farmerNumber = {
            mobile_no: Mob,
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/register-details`,
                farmerNumber,
                config
            )
            .then((res) => {
                const d = res.data.data.data
                localStorage.setItem('FormerId', d.user_id)
                setCreatedById(d.user_id)
                setCreatedByRole(d.role)
                localStorage.setItem('microId', '')
                setFormerinfo(d)
            })
            .catch((err) => {
                console.log(err)
                setFormerinfo({})
            })
    }
    return (
        <>
        
            
                <div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card card-table p-10-25">
                                <div class="card-header">
                                    <h5 class="card-title">
                                        Edit MicroEntrepreneur
                                    </h5>
                                </div>
                                <div class="card-body">
                                    <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                                        <li class="nav-item">
                                            <a
                                                class={
                                                    tab === 1
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                Personal Info
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                class={
                                                    tab === 2
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                Contact Details
                                            </a>
                                        </li>
                                        {/* <li class="nav-item"><a class={tab === 3 ? "nav-link active" : "nav-link"}>Commission Details</a></li> */}
                                        {/* <li class="nav-item"><a class={tab === 4 ? "nav-link active" : "nav-link"}>Bank Account Info</a></li> */}
                                    </ul>
                                    <div class="tab-content">
                                        {/* Personal Info */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab1"
                                        >
                                            {tab === 1 && (
                                                // <form
                                                //     onSubmit={() => setTab(2)}
                                                // >
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id=""
                                                                label="First Name"
                                                                variant="standard"
                                                                name="firstName"
                                                                value={
                                                                    microEnt.firstName
                                                                }
                                                                onChange={(e) =>
                                                                    setMicroEnt(
                                                                        {
                                                                            ...microEnt,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id=""
                                                                label="Last Name"
                                                                variant="standard"
                                                                name="lastName"
                                                                value={
                                                                    microEnt.lastName
                                                                }
                                                                onChange={(e) =>
                                                                    setMicroEnt(
                                                                        {
                                                                            ...microEnt,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id=""
                                                                label="Mobile Number"
                                                                variant="standard"
                                                                name="mobileNumber"
                                                                value={
                                                                    microEnt.mobileNumber
                                                                }
                                                                onChange={(e) =>
                                                                    handleMobVal(
                                                                        e
                                                                    )
                                                                }
                                                                // onChange={(e) => setMicroEnt({ ...microEnt, [e.target.name]: e.target.value })}
                                                            />
                                                        </div>
                                                        {/* <div class="col-md-3">
                          <CssTextField
                            fullWidth
                            required
                            id=""
                            label="Gender"
                            variant="standard"
                            name="gender"
                            value={microEnt.gender}
                            onChange={(e) => setMicroEnt({ ...microEnt, [e.target.name]: e.target.value })}
                          />
                        </div> */}
                                                        <div class="col-md-4">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                >
                                                                    Gender*
                                                                </InputLabel>
                                                                <Select
                                                                    required
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    label="Gender"
                                                                    color="success"
                                                                    name="gender"
                                                                    value={
                                                                        microEnt.gender
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setMicroEnt(
                                                                            {
                                                                                ...microEnt,
                                                                                [e
                                                                                    .target
                                                                                    .name]:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>
                                                                            None
                                                                        </em>
                                                                    </MenuItem>
                                                                    <MenuItem value="Male">
                                                                        Male
                                                                    </MenuItem>
                                                                    <MenuItem value="Female">
                                                                        Female
                                                                    </MenuItem>
                                                                    <MenuItem value="Others">
                                                                        Others
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <InputLabel
                                                                id="demo-simple-select-standard-label"
                                                                style={{
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                DOB
                                                            </InputLabel>
                                                            <CssTextField
                                                                type="date"
                                                                fullWidth
                                                                variant="standard"
                                                                style={{
                                                                    marginTop:
                                                                        '10px',
                                                                }}
                                                                name="dateOfBirth"
                                                                value={
                                                                    microEnt.dateOfBirth
                                                                }
                                                                onChange={(e) =>
                                                                    setMicroEnt(
                                                                        {
                                                                            ...microEnt,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-4"> 
                                                        <FormControl
                                                            variant="standard"
                                                            fullWidth
                                                            className="mt-3"
                                                        >
                                                              <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                >
                                                                   Designation
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    label="designation"
                                                                    color="success"
                                                                    name="designation"
                                                                    value={
                                                                        microEnt.designation
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                    setMicroEnt(
                                                                        {
                                                                            ...microEnt,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                    }
                                                                >
                                                                   
                                                                    <MenuItem value="FO">
                                                                        FO
                                                                    </MenuItem>
                                                                    <MenuItem value="VLE">
                                                                        VLE
                                                                    </MenuItem>
                                                                    <MenuItem value="EMPLOYEE">
                                                                    EMPLOYEE
                                                                    </MenuItem>
                                                                </Select>
                                                        </FormControl>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-2"
                                                            >
                                                                <InputLabel
                                                                    id="fpolist"
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                >
                                                                     Partner Mode
                                                                </InputLabel>
                                                                Partner Mode:{' '}
                                                                <AsyncTypeahead
                                                                    labelId="fpolist"
                                                                    id="fpolist"
                                                                    options={
                                                                        fpoList
                                                                    }
                                                                    labelKey="label"
                                                                    onSearch={
                                                                        handleSearch
                                                                    }
                                                                    style={{
                                                                        marginRight:
                                                                            '20px',
                                                                    }}
                                                                    selected={
                                                                        fpo
                                                                    }
                                                                    onChange={(
                                                                        selected
                                                                    ) =>
                                                                        handleSelectChange(
                                                                            selected
                                                                        )
                                                                    }
                                                                />{' '}
                                                            </FormControl>
                                                        </div>
                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                            <button
                                                                                                                   onClick={() => setTab(2)}

                                                                class=" w-16 btn btn-primary"
                                                            >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                // </form>
                                            )}
                                        </div>

                                        {/* Firm Details */}

                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab2"
                                        >
                                            {tab === 2 && (
                                                <div class="row">
                                                    <div
                                                        class="col-md-3"
                                                        style={{
                                                            marginTop: '2%',
                                                        }}
                                                    >
                                                        <Map
                                                            center={{
                                                                lat: 18.5204,
                                                                lng: 73.8567,
                                                            }}
                                                            height="300px"
                                                            zoom={15}
                                                            mapdata={mapdata}
                                                        />
                                                    </div>

                                                    <div class="col-md-12 text-end justify-space-between">
                                                        <button
                                                            className="w-16 btn btn-secondary"
                                                            style={{
                                                                marginRight:
                                                                    '10px',
                                                            }}
                                                            onClick={(e) =>
                                                                setTab(1)
                                                            }
                                                        >
                                                            Previous
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={(e) =>
                                                                syncOtpSubmit(
                                                                    e
                                                                )
                                                            }
                                                            class=" w-16 btn btn-primary"
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {/* Commission Details */}
                                        {/* <div class="tab-pane show active" id="bottom-justified-tab4">
                  {tab === 3 &&
                    <form onSubmit={() => setTab(4)}>
                      <div class="row">
                        <div class="col-md-4" style={{marginTop:'2%'}}>
                          <FormControl variant="standard" fullWidth className="mt-3">
                           
                             <Selects style={{ width: "270px", height: "40px" }}
                              onChange={(e) => setMicroEnt({ ...microEnt, ['unit']: e.value })}
                                // onChange={(e) => setInputs({ ...inputs, ['unit']: e.value })}
                                options={CommisionUnit}
                                placeholder={'Select Unit'}
                              //  value={inputBooking.firm}
                              />
                          </FormControl>
                        </div>
                        <div class="col-md-4" style={{marginTop:'3.5%'}}>
                        <Selects style={{ width: "270px", height: "40px" }}
                          onChange={(e) => setMicroEnt({ ...microEnt,['commissionAmount']: e.value })}
                                // onChange={(e) => setInputs({ ...inputs, ['commissionAmount']: e.value })}
                                options={CommisionAmount}
                                placeholder={'Select commissionAmount'}
                              //  value={inputBooking.firm}
                              />
                        </div>
                        <div class="col-md-4" style={{marginTop:'2%'}}>
                          <FormControl variant="standard" fullWidth className="mt-3">
                           
                            <Selects style={{ width: "270px", height: "40px" }}
                             onChange={(e) => setMicroEnt({ ...microEnt, ['category']: e.value  })}
                                // onChange={(e) => setInputs({ ...inputs, ['category']: e.value })}
                                options={commisiontype}
                                placeholder={'Select  commision type'}
                              //  value={inputBooking.firm}
                              />
                          </FormControl>
                        </div>

                        <div class="col-md-12 text-end mt-2 mb-2">
                          <button className="w-10 btn btn-secondary" style={{ marginRight: '10px' }} onClick={e => setTab(2)}>Previous</button>
                          <button type="submit" class=" w-10 btn btn-primary">Next</button>
                        </div>
                      </div>
                    </form>
                  }
                </div> */}

                                        {/* Bank Account Info */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab3"
                                        >
                                            {tab === 4 && (
                                                <form>
                                                    <div class="row">
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="accNum"
                                                                label="Bank Acc"
                                                                variant="standard"
                                                                type="number"
                                                                name="bankAccountNumber"
                                                                value={
                                                                    microEnt.bankAccountNumber
                                                                }
                                                                onChange={(e) =>
                                                                    setMicroEnt(
                                                                        {
                                                                            ...microEnt,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>

                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="accName"
                                                                label="Account Name"
                                                                variant="standard"
                                                                name="bankAccountName"
                                                                value={
                                                                    microEnt.bankAccountName
                                                                }
                                                                onChange={(e) =>
                                                                    setMicroEnt(
                                                                        {
                                                                            ...microEnt,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="ifscCode"
                                                                label="IFSC Code"
                                                                variant="standard"
                                                                name="ifscCode"
                                                                value={
                                                                    microEnt.ifscCode
                                                                }
                                                                onChange={(e) =>
                                                                    setMicroEnt(
                                                                        {
                                                                            ...microEnt,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="acc"
                                                                label="Bank Name"
                                                                variant="standard"
                                                                pattern="[a-zA-Z]*"
                                                                type="text"
                                                                name="bankName"
                                                                value={
                                                                    microEnt.bankName
                                                                }
                                                                onChange={(e) =>
                                                                    setMicroEnt(
                                                                        {
                                                                            ...microEnt,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="branchName"
                                                                label="Branch Name"
                                                                variant="standard"
                                                                name="bankBranchName"
                                                                value={
                                                                    microEnt.bankBranchName
                                                                }
                                                                onChange={(e) =>
                                                                    setMicroEnt(
                                                                        {
                                                                            ...microEnt,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                            <button
                                                                className="w-10 btn btn-secondary"
                                                                style={{
                                                                    marginRight:
                                                                        '10px',
                                                                }}
                                                                onClick={(e) =>
                                                                    setTab(3)
                                                                }
                                                            >
                                                                Previous
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={(e) =>
                                                                    onSubmitMicroEnt(
                                                                        e
                                                                    )
                                                                }
                                                                class=" w-16 btn btn-primary"
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
        </>
    )
}
export default EditMicroEnterpreneur
