import React, { useState } from 'react'
import Table from './Table'

const MicroEntOrders = () => {
    const [tab, setTab] = useState()
    return (
        <div style={{ marginBottom: '20px' }}>
            <div className="row mb-4">
                <div className="col-12">
                    <ul className="nav nav-tab nav-tab-bottom nav-justified orders-tab">
                        {/* <li
              class={
                tab === 1
                  ? 'nav-item activeOrders active'
                  : 'nav-item activeOrders'
              }
            >
              <a
                class='nav-link'
                href='#bottom-justified-tab1'
                data-bs-toggle='tab'
                onClick={(e) => setTab(1)}
              >
                <h6>RS. 1000</h6>
                <small>5 Active Orders</small>
              </a>
            </li> */}
                        <li
                            className={
                                tab === 1
                                    ? 'nav-item pending active'
                                    : 'nav-item pending'
                            }
                        >
                            <a
                                className="nav-link"
                                href="#bottom-justified-tab2"
                                data-bs-toggle="tab"
                                onClick={(e) => setTab(2)}
                            >
                                <h6>RS. 1500</h6>
                                <small>2 Pending Orders</small>
                            </a>
                        </li>
                        <li className="nav-item accepted">
                            <a
                                className={
                                    tab === 3 ? 'nav-link active' : 'nav-link'
                                }
                                href="#bottom-justified-tab3"
                                data-bs-toggle="tab"
                                onClick={(e) => setTab(3)}
                            >
                                <h6>RS. 1600</h6>
                                <small>1 Accepted Orders</small>
                            </a>
                        </li>
                        {/* <li className='nav-item picked'>
              <a
                className='nav-link'
                href='#bottom-justified-tab4'
                data-bs-toggle='tab'
                onClick={(e) => setTab(4)}
              >
                <h6>RS. 1600</h6>
                <small>0 Picked Orders</small>
              </a>
            </li> */}
                        {/* <li className='nav-item ready'>
              <a
                className='nav-link'
                href='#bottom-justified-tab5'
                data-bs-toggle='tab'
                onClick={(e) => setTab(5)}
              >
                <h6>RS. 7000</h6>
                <small>2 Ready Orders</small>
              </a>
            </li> */}
                        <li className="nav-item completed">
                            <a
                                className="nav-link "
                                href="#bottom-justified-tab6"
                                data-bs-toggle="tab"
                                onClick={(e) => setTab(6)}
                            >
                                <h6>RS. {}</h6>
                                <small>{} Completed Orders</small>
                            </a>
                        </li>
                        <li className="nav-item cancelled">
                            <a
                                className="nav-link "
                                href="#bottom-justified-tab6"
                                data-bs-toggle="tab"
                                onClick={(e) => setTab(7)}
                            >
                                <h6>RS. 16000</h6>
                                <small>50 Cancelled Orders</small>
                            </a>
                        </li>
                        {/* <li className='nav-item failed'>
              <a
                className='nav-link '
                href='#bottom-justified-tab6'
                data-bs-toggle='tab'
                onClick={(e) => setTab(8)}
              >
                <h6>RS. 0</h6>
                <small>1 Failed Orders</small>
              </a>
            </li> */}
                    </ul>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-2">
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="All Stores"
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="All Customer"
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Search"
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Search"
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Search"
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Search"
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="State"
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="District"
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Sub District"
                        />
                    </div>
                </div>
            </div>

            <Table />
        </div>
    )
}

export default MicroEntOrders
