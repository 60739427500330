import React, { useState, useEffect } from 'react'
import { CssTextField } from '../../Styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
// import Select from '@mui/material/Select';
import Select from 'react-select'
import MenuItem from '@mui/material/MenuItem'
import axios from 'axios'
import { API } from '../../../API/index'
import LivestockTable from './LivestockTable'
import Swal from 'sweetalert2'
import $ from 'jquery'
import { thumbsContainerState, thumb, img, thumbInner } from '../../Styles'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../../firebase/index'

import Selects from 'react-select'
import Map from '../Map'
import { Button, Input } from '@mui/material'
import OtpBox from '../../../components/OtpBox'
const clearLivestock = {
    firstName: '',
    lastName: '',
    mobileNumber: '',
    email: '',
    alternatemobile: '',
    pancard: '',
    adharcard: '',
    gender: '',
    dob: '',
    language: '',
    business_mode: '',
    firmName: '',
    state: '',
    district: '',
    subDistrict: '',
    village: '',
    pincode: '',
    gstNumber: '',
    fullAddress: '',

    unit: '',
    commissionAmount: '',
    category: '',

    bankAccountNumber: '',
    bankAccountName: '',
    ifscCode: '',
    bankName: '',
    bankBranchName: '',
    licenseImage: '',
}

function Livestock() {
    const [tab, setTab] = useState(1)
    const [states, setStates] = useState([])
    const [livestockList, setLivestockList] = useState([])
    const [citylist, setcitylist] = useState([])
    const [statelist, setstatelist] = useState([])
    const [CommisionCat, setCommisionCat] = useState([])
    const [licensePhotoImg, setlicensePhotoImg] = useState(null)
    const [Formerinfo, setFormerinfo] = useState({})

    const [otpWindow, setOtpWindow] = useState(false)
    const [createdByid,setCreatedById]=useState('')
    const [createdByRole,setCreatedByRole]=useState("")
        const [livestock, setLivestock] = useState({
        firstName: '',
        lastName: '',
        mobileNumber: '',
        email: '',
        alternatemobile: '',
        pancard: '',
        adharcard: '',
        gender: '',
        dob: '',
        language: '',
        business_mode: '',
        firmName: '',
        state: '',
        district: '',
        village: '',
        pincode: '',
        gstNumber: '',
        fullAddress: '',
        licenseNumber: '',
        unit: '',
        commissionAmount: '',
        category: '',
        licenseExpiryDate: '',
        bankAccountNumber: '',
        bankAccountName: '',
        ifscCode: '',
        bankName: '',
        bankBranchName: '',
        licenseImage: '',
    })

    useEffect(() => {
        getState()
        getStateList()
        getCommisionCat()
        // getLivestocks();
    }, [])
    const getCommisionCat = async () => {
        const { data } = await API.get(
            `/getCommissionCatelog?title=CommissionCatelog&catelogName=LiveStockCatelog&type=2`
        )
        // console.log("hkjjhj",data)
        setCommisionCat(data?.result)
        console.log('firm search', data)
    }
    const CommisionUnit =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.commissionUnit,
            label: item.commissionUnit,
        }))
    const CommisionAmount =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.commissionAmount,
            label: item.commissionAmount,
        }))
    const commisiontype =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.subCategoryName,
            label: item.subCategoryName,
        }))
    const getStateList = async () => {
        const { data } = await API.get(`/getStateList`)
        // console.log("hkjjhj",data)
        setstatelist(data?.result)
        console.log('firm search', data)
    }
    const statelistoption =
        statelist &&
        statelist.map((item, ind) => ({
            value: item,
            label: item,
        }))
    const statechane = (e) => {
        console.log('hkjjhj', e.value)
        setLivestock({ ...livestock, ['state']: e.value })
        // setInputs({ ...inputs, ['state']: e.value })
        // onChange={(e) => setInputs({ ...inputs, ['state']: e.target.value })}
        getCityList(e.value)
    }
    const getCityList = async (val) => {
        const { data } = await API.get(`/getCityList?stateName=` + val)
        // console.log("hkjjhj",data)
        setcitylist(data?.result)
        console.log('firm search', data)
    }
    const citylistoption =
        citylist &&
        citylist.map((item, ind) => ({
            value: item,
            label: item,
        }))
    const getState = async () => {
        const { data } = await axios.get(
            'http://65.0.145.198:4080/api/v1/getStateList'
        )
        setStates(data?.result)
        console.log(data?.result)
    }

    const getLivestocks = async () => {
        const { data } = await API.get(`/getLivestocks?type=0`)
        setLivestockList(data?.result)
    }

    const onSubmitLivestock = async (e) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const dataForOtp = {
            mobile_number: livestock.mobileNumber,
            role: 'livestock',
            created_by: 'admin',
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/auth-function/otp`,
                dataForOtp,
                config
            )
            .then((res) => {
                setOtpWindow(true)
            })
    }

    function syncOtpSubmit(e) {
        setOtpWindow(false)
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }

        var livestockData = {
            user_id: '',
            first_name: livestock.firstName,
            last_name: livestock.lastName,
            dob: livestock.dob,
            role: 'livestock',
            gender: livestock.gender,
            coordinates: [livestock.lat, livestock.lng],
            photo: 'test',
            mobile_number: livestock.mobileNumber,
            language: livestock.language,
            business_mode: livestock.business_mode,
            state: livestock.state,
            district: livestock.district,
            sub_distric: livestock.district,
            village: livestock.village,
            pincode: +livestock.pincode,
            full_address: livestock.fullAddress,
            farm_info: {
                firm_name: livestock.firmName,
                gst_no: livestock.gstNumber,
                document: {
                    image_url: livestock.licenseImage,
                    licence_exp_date: livestock.licenseExpiryDate,
                    licence_number: livestock.licenseNumber,
                },
            },
            created_by: createdByRole,
            createdById:createdByid   
        }

        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/auth-function/farmers`,
                livestockData,
                config
            )
            .then((res) => {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: 'Livestock Created Successfully',
                    showConfirmButton: false,
                    timer: 2000,
                })
                setTab(1)
            })
            .catch((err) =>
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: 'error occured!',
                    showConfirmButton: false,
                    timer: 2000,
                })
            )
    }

    const handleMobVal = (e) => {
        setLivestock({ ...livestock, [e.target.name]: e.target.value })
        if (e.target.value.length == 10) {
            mobilecheck(e.target.value)
        } else if (e.target.value.length > 10) {
            console.log('e.target.value.length', e.target.value.length)
            setLivestock({ ...livestock, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: 'Mobile length should be 10',
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const mobilecheck = async (number) => {
        const { data } = await API.get(`/checkNumber?number=` + number)
        console.log('data', data)
        if (data.success == true) {
            // setFarmer({ ...farmer, [e.target.name]: number })
        } else {
            setLivestock({ ...livestock, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data.result,
                showConfirmButton: false,
                timer: 2000,
            })
        }
        // setDetails(data?.result);
    }
    const handleMobilesearch = (e) => {
        console.log('e', e.target.value)
        if (e.target.value.length == 10) {
            mobilechecksearch(e.target.value)
        } else {
            setLivestockList([])
        }
    }
    const mobilechecksearch = async (number) => {
        const { data } = await API.get(`/searchLiveStock?search=` + number)
        console.log('data', data)
        if (data.success == true) {
            let arr = []
            arr.push(data?.result)
            setLivestockList(arr)
        } else {
        }
    }

    $(function () {
        $('#txtNumeric').keydown(function (e) {
            if (e.shiftKey || e.ctrlKey || e.altKey) {
                e.preventDefault()
            } else {
                var key = e.keyCode

                if (
                    !(
                        key == 8 ||
                        key == 32 ||
                        key == 46 ||
                        (key >= 35 && key <= 40) ||
                        (key >= 65 && key <= 90)
                    )
                ) {
                    e.preventDefault()
                }
            }
        })
    })

    const mapdata = (data) => {
        console.log('ran')
        setLivestock({
            ...livestock,
            state: data.state,
            district: data.district,
            village: data.village,
            pincode: data.pincode,
            address: data.address,
            fullAddress: data.address,
            lat: data.latitude,
            lng: data.longitude,
        })
    }

    const handleImageUpload = (e) => {
        setlicensePhotoImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        setLivestock({ ...livestock, licenseImage: url })
                    })
                }
            )
        }
    }

    const gender = [
        {
            value: 'Male',
            label: 'Male',
        },
        {
            value: 'Female',
            label: 'Female',
        },
        {
            value: 'Others',
            label: 'Others',
        },
    ]

    const LanguageVale = [
        {
            value: 'Wholesale',
            label: 'Wholesale',
        },
        {
            value: 'Retail',
            label: 'Retail',
        },
        {
            value: 'Distributor',
            label: 'Distributor',
        },
        {
            value: 'Manfacture',
            label: 'Manfacture',
        },
        {
            value: 'Organisation',
            label: 'Organisation',
        },
    ]
    const handleFarmer = (e) => {
        if (e.target.value.length == 10) {
            getFormerinfo(e.target.value)
        } else {
            setFormerinfo({})
        }
    }
    const getFormerinfo = async (Mob) => {
        const token = localStorage.getItem('token')
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        const farmerNumber = {
            mobile_no: Mob,
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/register-details`,
                farmerNumber,
                config
            )
            .then((res) => {
                const d = res.data.data.data
                localStorage.setItem('FormerId', d.user_id)
                setCreatedById(d.user_id)
                setCreatedByRole(d.role)
                localStorage.setItem('microId', '')
                setFormerinfo(d)
            })
            .catch((err) => {
                console.log(err)
                setFormerinfo({})
            })
    }
    return (
        <>
        <div class="card card-table p-10-25">
                            <div class="card-header">
                                <h5 class="card-title"> Info</h5>
                            </div>
           <div class="tab-content">
                                        <div action="#">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="farmerNum"
                                                        //  label="Farmer Number"
                                                        placeholder="Farmer Number"
                                                        variant="standard"
                                                        //  value={}
                                                        onChange={(e) =>
                                                            handleFarmer(e)
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.role
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.id
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.first_name
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerState"
                                                        //  label="State"
                                                        placeholder="State"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.state
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="farmerDistrict"
                                                        disabled
                                                        //  label="District"
                                                        placeholder="District"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.district
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        disabled
                                                        fullWidth
                                                        id="farmerVillage"
                                                        //  label="Village"
                                                        placeholder="Village"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.village
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        disabled
                                                        fullWidth
                                                        id="farmerPincode"
                                                        //  label="Pincode"
                                                        placeholder="Pincode"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.pincode
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>
                                          
                                        </div>
                                        </div>

                                    </div>
            {otpWindow ? (
                <OtpBox
                    mobileNumber={livestock.mobileNumber}
                    syncOtpSubmit={syncOtpSubmit}
                />
            ) : (
                <div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card card-table p-10-25">
                                <div class="card-header">
                                    <h5 class="card-title">Create Livestock</h5>
                                </div>
                                <div class="card-body">
                                    <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                                        <li class="nav-item">
                                            <a
                                                class={
                                                    tab === 1
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                Personal Info
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                class={
                                                    tab === 2
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                Firm Details
                                            </a>
                                        </li>
                                        {/* <li class="nav-item"><a class={tab === 3 ? "nav-link active" : "nav-link"}>Commission Details</a></li> */}
                                        {/* <li class="nav-item"><a class={tab === 4 ? "nav-link active" : "nav-link"}>Bank Account Info</a></li> */}
                                    </ul>
                                    <div class="tab-content">
                                        {/* Personal Info */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab1"
                                        >
                                            {
                                                tab === 1 && (
                                                    // <form onSubmit={() => setTab(2)}>
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="fName"
                                                                label="First Name"
                                                                variant="standard"
                                                                name="firstName"
                                                                value={
                                                                    livestock.firstName
                                                                }
                                                                onChange={(e) =>
                                                                    setLivestock(
                                                                        {
                                                                            ...livestock,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="lName"
                                                                label="Last Name"
                                                                variant="standard"
                                                                name="lastName"
                                                                value={
                                                                    livestock.lastName
                                                                }
                                                                onChange={(e) =>
                                                                    setLivestock(
                                                                        {
                                                                            ...livestock,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="mobile"
                                                                type="number"
                                                                label="Mobile Number"
                                                                variant="standard"
                                                                name="mobileNumber"
                                                                value={
                                                                    livestock.mobileNumber
                                                                }
                                                                onChange={(e) =>
                                                                    handleMobVal(
                                                                        e
                                                                    )
                                                                }
                                                                // onChange={(e) => setLivestock({ ...livestock, [e.target.name]: e.target.value })}
                                                            />
                                                        </div>
                                                        <div
                                                            class="col-md-4"
                                                            style={{
                                                                marginTop: '2%',
                                                            }}
                                                        >
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <Select
                                                                    style={{
                                                                        width: '270px',
                                                                        height: '40px',
                                                                    }}
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setLivestock(
                                                                            {
                                                                                ...livestock,
                                                                                ['gender']:
                                                                                    e.value,
                                                                            }
                                                                        )
                                                                    }
                                                                    options={
                                                                        gender
                                                                    }
                                                                    placeholder={
                                                                        'Select Gender'
                                                                    }
                                                                />
                                                            </FormControl>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <InputLabel
                                                                id="demo-simple-select-standard-label"
                                                                style={{
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                DOB
                                                            </InputLabel>
                                                            <CssTextField
                                                                type="date"
                                                                fullWidth
                                                                variant="standard"
                                                                style={{
                                                                    marginTop:
                                                                        '10px',
                                                                    marginBottom:
                                                                        '10px',
                                                                }}
                                                                name="dob"
                                                                value={
                                                                    livestock.dob
                                                                }
                                                                onChange={(e) =>
                                                                    setLivestock(
                                                                        {
                                                                            ...livestock,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            class="col-md-4"
                                                            style={{
                                                                marginTop: '2%',
                                                            }}
                                                        >
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <Select
                                                                    style={{
                                                                        width: '270px',
                                                                        height: '40px',
                                                                    }}
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setLivestock(
                                                                            {
                                                                                ...livestock,
                                                                                ['business_mode']:
                                                                                    e.value,
                                                                            }
                                                                        )
                                                                    }
                                                                    options={
                                                                        LanguageVale
                                                                    }
                                                                    placeholder={
                                                                        'Business Mode'
                                                                    }
                                                                />
                                                            </FormControl>
                                                        </div>

                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                            <button
                                                                onClick={(e) =>
                                                                    setTab(2)
                                                                }
                                                                class=" w-16 btn btn-primary"
                                                            >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                                // </form>
                                            }
                                        </div>

                                        {/* Firm Details */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab2"
                                        >
                                            {
                                                tab === 2 && (
                                                    // <form onSubmit={() => setTab(4)}>
                                                    <div class="row">
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'space-between',
                                                                width: '550px',
                                                            }}
                                                        >
                                                            <div class="col-md-3">
                                                                <CssTextField
                                                                    fullWidth
                                                                    required
                                                                    id=""
                                                                    label="Firm Name"
                                                                    variant="standard"
                                                                    name="firmName"
                                                                    value={
                                                                        livestock.firmName
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setLivestock(
                                                                            {
                                                                                ...livestock,
                                                                                [e
                                                                                    .target
                                                                                    .name]:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            }
                                                                        )
                                                                    }
                                                                />
                                                            </div>

                                                            <div class="col-md-3">
                                                                <CssTextField
                                                                    fullWidth
                                                                    id="standard-basic"
                                                                    label="GST"
                                                                    variant="standard"
                                                                    name="gstNumber"
                                                                    value={
                                                                        livestock.gstNumber
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setLivestock(
                                                                            {
                                                                                ...livestock,
                                                                                [e
                                                                                    .target
                                                                                    .name]:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            }
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 ">
                                                            <CssTextField
                                                                fullWidth
                                                                id="standard-basic"
                                                                type="text"
                                                                label="License Number"
                                                                variant="standard"
                                                                name="licenseNumber"
                                                                value={
                                                                    livestock.licenseNumber
                                                                }
                                                                onChange={(e) =>
                                                                    setLivestock(
                                                                        {
                                                                            ...livestock,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3 ">
                                                            <InputLabel
                                                                id="demo-simple-select-standard-label"
                                                                style={{
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                License Exp Date
                                                            </InputLabel>
                                                            <CssTextField
                                                                type="date"
                                                                fullWidth
                                                                variant="standard"
                                                                style={{
                                                                    marginTop:
                                                                        '10px',
                                                                    marginBottom:
                                                                        '10px',
                                                                }}
                                                                name="licenseExpiryDate"
                                                                //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licenseNumber']}
                                                                value={
                                                                    livestock.licenseExpiryDate
                                                                }
                                                                onChange={(e) =>
                                                                    setLivestock(
                                                                        {
                                                                            ...livestock,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>

                                                        <div
                                                            class="col-md-3 d-flex"
                                                            style={{
                                                                justifyContent:
                                                                    'space-between',
                                                            }}
                                                        >
                                                            <label htmlFor="contained-button-filed">
                                                                <Button
                                                                    variant="contained"
                                                                    component="span"
                                                                    color="success"
                                                                >
                                                                    <Input
                                                                        accept="image/"
                                                                        id="contained-button-filed"
                                                                        multiple="false"
                                                                        name="photo"
                                                                        type="file"
                                                                        onChange={
                                                                            handleImageUpload
                                                                        }
                                                                        style={{
                                                                            display:
                                                                                'none',
                                                                            marginBottom:
                                                                                '16px',
                                                                            marginTop:
                                                                                '16px',
                                                                        }}
                                                                    />
                                                                    Upload
                                                                </Button>
                                                            </label>
                                                            <aside
                                                                style={
                                                                    thumbsContainerState
                                                                }
                                                            >
                                                                <div
                                                                    style={
                                                                        thumb
                                                                    }
                                                                >
                                                                    <div
                                                                        style={
                                                                            thumbInner
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={
                                                                                licensePhotoImg
                                                                            }
                                                                            style={
                                                                                img
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </aside>
                                                        </div>
                                                        <div
                                                            class=""
                                                            style={{
                                                                marginTop: '2%',
                                                            }}
                                                        >
                                                            <Map
                                                                center={{
                                                                    lat: 18.5204,
                                                                    lng: 73.8567,
                                                                }}
                                                                height="300px"
                                                                zoom={15}
                                                                mapdata={
                                                                    mapdata
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                            <button
                                                                className="w-16 btn btn-secondary"
                                                                style={{
                                                                    marginRight:
                                                                        '10px',
                                                                }}
                                                                onClick={(e) =>
                                                                    setTab(1)
                                                                }
                                                            >
                                                                Previous
                                                            </button>
                                                            <button
                                                                type="button"
                                                                class=" w-16 btn btn-primary"
                                                                onClick={
                                                                    syncOtpSubmit
                                                                }
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                                // </form>
                                            }
                                        </div>

                                        {/* Commission Details */}
                                        {/* <div class="tab-pane show active" id="bottom-justified-tab4">
                  {tab === 3 &&
                    <form onSubmit={() => setTab(4)}>
                      <div class="row">
                        <div class="col-md-4" style={{marginTop:'2%'}}>
                          <FormControl variant="standard" fullWidth className="mt-3">
                           
                            <Selects style={{ width: "270px", height: "40px" }}
                             onChange={(e) => setLivestock({ ...livestock, ['unit']: e.value })}
                                // onChange={(e) => setInputs({ ...inputs, ['unit']: e.value })}
                                options={CommisionUnit}
                                placeholder={'Select Unit'}
                              //  value={inputBooking.firm}
                              />
                          </FormControl>
                        </div>
                        <div class="col-md-4" style={{marginTop:'3.5%'}}>
                        <Selects style={{ width: "270px", height: "40px" }}
                         onChange={(e) => setLivestock({ ...livestock, ['commissionAmount']: e.value })}
                                // onChange={(e) => setInputs({ ...inputs, ['commissionAmount']: e.value })}
                                options={CommisionAmount}
                                placeholder={'Select commissionAmount'}
                              //  value={inputBooking.firm}
                              />
                          
                        </div>
                        <div class="col-md-4" style={{marginTop:'2%'}}>
                          <FormControl variant="standard" fullWidth className="mt-3">
                           
                             <Selects style={{ width: "270px", height: "40px" }}
                              onChange={(e) => setLivestock({ ...livestock,  ['category']: e.value })}
                                // onChange={(e) => setInputs({ ...inputs, ['category']: e.value })}
                                options={commisiontype}
                                placeholder={'Select  commision type'}
                              //  value={inputBooking.firm}
                              />
                          </FormControl>
                        </div>

                        <div class="col-md-12 text-end mt-2 mb-2">
                          <button className="w-10 btn btn-secondary" style={{ marginRight: '10px' }} onClick={e => setTab(2)}>Previous</button>
                          <button type="submit" class=" w-10 btn btn-primary">Next</button>
                        </div>
                      </div>
                    </form>
                  }
                </div> */}

                                        {/* Bank Account Info */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab3"
                                        >
                                            {
                                                tab === 4 && (
                                                    // <form action="#" onSubmit={onSubmitLivestock}>
                                                    <div class="row">
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="accNum"
                                                                label="Bank Acc"
                                                                variant="standard"
                                                                type="number"
                                                                name="bankAccountNumber"
                                                                value={
                                                                    livestock.bankAccountNumber
                                                                }
                                                                onChange={(e) =>
                                                                    setLivestock(
                                                                        {
                                                                            ...livestock,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>

                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="accName"
                                                                label="Account Name"
                                                                variant="standard"
                                                                name="bankAccountName"
                                                                value={
                                                                    livestock.bankAccountName
                                                                }
                                                                onChange={(e) =>
                                                                    setLivestock(
                                                                        {
                                                                            ...livestock,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="ifscCode"
                                                                label="IFSC Code"
                                                                variant="standard"
                                                                name="ifscCode"
                                                                value={
                                                                    livestock.ifscCode
                                                                }
                                                                onChange={(e) =>
                                                                    setLivestock(
                                                                        {
                                                                            ...livestock,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="bankName"
                                                                label="Bank Name"
                                                                variant="standard"
                                                                pattern="[a-zA-Z]*"
                                                                type="text"
                                                                id="txtNumeric"
                                                                name="bankName"
                                                                value={
                                                                    livestock.bankName
                                                                }
                                                                onChange={(e) =>
                                                                    setLivestock(
                                                                        {
                                                                            ...livestock,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="branchName"
                                                                label="Branch Name"
                                                                variant="standard"
                                                                name="bankBranchName"
                                                                value={
                                                                    livestock.bankBranchName
                                                                }
                                                                onChange={(e) =>
                                                                    setLivestock(
                                                                        {
                                                                            ...livestock,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                            <button
                                                                className="w-16 btn btn-secondary"
                                                                style={{
                                                                    marginRight:
                                                                        '10px',
                                                                }}
                                                                onClick={(e) =>
                                                                    setTab(2)
                                                                }
                                                            >
                                                                Previous
                                                            </button>
                                                            <button
                                                                type="button"
                                                                class=" w-16 btn btn-primary"
                                                                onClick={
                                                                    onSubmitLivestock
                                                                }
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                                // </form>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
export default Livestock
