import React, { useState } from 'react'
import LivestockBD from './LivestockBD'
import InputSupplierBD from './InputSupplier'
import AgriMachineBD from './AgriMachineBD'
import AgriTransportBD from './AgriTransportBD'
import SellCropBD from './SellCropBD'
import TradersBD from './TradersBD'

function BookingDetails() {
    const [tab, setTab] = useState(1)

    return (
        <div class="content container-fluid">
            <div class="page-header">
                <div class="row align-items-center">
                    {/* <div class="col">
            <h3 class="page-title">Individual Informations</h3>
            <ul class="breadcrumb">
               <li class="breadcrumb-item"><a href="/Home">Dashboard</a></li>
               <li class="breadcrumb-item active">Catelog</li>
            </ul>
         </div> */}
                </div>
                <div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div class="card card-table p-10-25">
                                <div class="card-header">
                                    <h3 class="card-title">
                                        Choose Booking Module
                                    </h3>
                                </div>
                                <form
                                    class="card-body"
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        console.log('Form Successfully working')
                                    }}
                                >
                                    <>
                                        <div class="tab-content">
                                            <div action="#">
                                                <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                                    <li class="nav-item">
                                                        <a
                                                            class={
                                                                tab === 1
                                                                    ? 'nav-link active'
                                                                    : 'nav-link'
                                                            }
                                                            href="#bottom-justified-tab1"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(1)
                                                            }
                                                        >
                                                            Livestock
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab2"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(2)
                                                            }
                                                        >
                                                            Input Supplier
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(3)
                                                            }
                                                        >
                                                            Agri Machine
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(4)
                                                            }
                                                        >
                                                            Agri Transport
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(5)
                                                            }
                                                        >
                                                            Sell Crop
                                                        </a>
                                                    </li>

                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(6)
                                                            }
                                                        >
                                                            Traders
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                </form>
                            </div>

                            {tab === 1 && <LivestockBD />}

                            {tab === 2 && <InputSupplierBD />}

                            {tab === 3 && <AgriMachineBD />}

                            {tab === 4 && <AgriTransportBD />}

                            {tab === 5 && <SellCropBD />}
                            {tab === 6 && <TradersBD />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingDetails
