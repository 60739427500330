const BankingDetails = ({ Details, bankDetails }) => {
    return (
        <div class="card">
            <div class="card-body">
                <h5 class="card-title d-flex justify-content-between store_edit">
                    <span>Bank Details</span>
                    {/* <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                           <span class="material-icons-outlined">edit</span>
                        </a> */}
                </h5>
                <ul class="list-unstyled mb-0 store_list">
                    <li class="pt-2 pb-0">
                        <small class="text-dark">
                            Account No : {bankDetails?.account_no}
                        </small>
                        <span>{Details.bankAccountNumber}</span>
                    </li>
                    <li class="pt-2 pb-0">
                        <small class="text-dark">
                            Account Name : {bankDetails?.account_name}
                        </small>
                        <span>{Details.bankAccountName}</span>
                    </li>
                    <li class="pt-2 pb-0">
                        <small class="text-dark">
                            IFSC Code : {bankDetails?.ifsc_code}
                        </small>
                        <span>{Details.ifscCode}</span>
                    </li>
                    <li class="pt-2 pb-0">
                        <small class="text-dark">
                            Bank Name : {bankDetails?.bank_name}
                        </small>
                        <span>{Details.bankName}</span>
                    </li>
                    <li class="pt-2 pb-0">
                        <small class="text-dark">
                            Branch Name : {bankDetails?.branch_name}
                        </small>
                        <span>{Details.bankBranchName}</span>
                    </li>
                    <li class="pt-2 pb-0">
                        <small class="text-dark">
                            Document :{' '}
                            <img
                                src={bankDetails?.document_url}
                                width="100px"
                            />
                        </small>
                    </li>
                </ul>
            </div>
        </div>
    )
}
export default BankingDetails
