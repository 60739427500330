// import React, { useState } from 'react';
// import { CssTextField } from '../../Styles'
// import AddFarmer from '../AddFarmer';
// import FormControl from '@mui/material/FormControl';
// import InputLabel from '@mui/material/InputLabel';
// import { useDropzone } from 'react-dropzone';
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import { thumbsContainerState, thumb, img, thumbInner, mapThumb, baseStyle } from '../../Styles';
// import EditTraders from './EditTraderDetails';
// import { IconButton } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };
// const names = [
//     'Wheat',
//     'Rice',
//     'Barley',
//     'Cereal',
//     'Tomato',
//     'Oat',
// ];

// function Traders() {
//     const [addDoc, setAddDoc] = useState(false);
//     const [addWallet, setAddWallet] = useState(false);
//     const [managerDoc, setManagerDoc] = useState(false);
//     const [Files, setFiles] = useState([]);
//     const [firmEdit, setFirmEdit] = useState(false);

//     const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
//         accept: "image/*",
//         onDrop: (acceptedFiles) => {
//             setFiles(
//                 acceptedFiles.map((file) =>
//                     Object.assign(file, {
//                         preview: URL.createObjectURL(file)
//                     })
//                 )
//             );
//         }
//     });

//     const PGthumbs = Files.map((file, index) => (
//         <div style={thumb} key={file.name}>
//             <div style={thumbInner}>
//                 <img src={file.preview} style={img} alt="" />
//             </div>
//         </div>
//     ));

//     return (

//         <div>
//             <div class="row">

//                 <div class="page-header">
//                     <div class="row align-items-center">

//                         <div class="col-md-12">
//                             <ul class="breadcrumb justify-content-end">
//                                 <li class="breadcrumb-item"><a href="#">Home</a></li>
//                                 <li class="breadcrumb-item"><a href="#">Trader</a></li>
//                                 <li class="breadcrumb-item active">Raju</li>
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
// {/*
//                 <div class="col-lg-3">
//                     <div class="card">
//                         <div class="card-header">
//                             <h5 class="card-title d-flex justify-content-between store_edit">
//                                 <span>Traders</span>
//                                 <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
//                                     <span class="material-icons-outlined">edit</span>
//                                 </a>
//                             </h5>
//                             <a class="store-avatar">
//                                 <div class="staffAvatar-root1">R</div>
//                                 <div class="store_display">
//                                     <h4>Raju</h4>
//                                     <span>8098081991</span>
//                                 </div>
//                             </a>

//                         </div>

//                         <div class="card-body">
//                             <h5 class="card-title d-flex justify-content-between store_edit">
//                                 <span>Personal Info</span>
//                                 <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
//                                     <span class="material-icons-outlined">edit</span>
//                                 </a>
//                             </h5>
//                             <ul class="list-unstyled mb-0 store_list">

//                                 <li class="pt-2 pb-0">
//                                     <small class="text-dark">Name : </small>
//                                     <span style={{ marginLeft: '20px' }}>.....</span>
//                                 </li>
//                                 <li class="pt-2 pb-0">
//                                     <small class="text-dark">Mobile Number : </small>
//                                     <span style={{ marginLeft: '20px' }}>.....</span>
//                                 </li>
//                                 <li class="pt-2 pb-0">
//                                     <small class="text-dark">Email ID : </small>
//                                     <span style={{ marginLeft: '20px' }}>.....</span>
//                                 </li>
//                                 <li class="pt-2 pb-0">
//                                     <small class="text-dark">Gender : </small>
//                                     <span style={{ marginLeft: '20px' }}>.....</span>
//                                 </li>
//                                 <li class="pt-2 pb-0">
//                                     <small class="text-dark">D.O.B  : </small>
//                                     <span style={{ marginLeft: '20px' }}>.....</span>
//                                 </li>
//                                 <li class="pt-2 pb-0">
//                                     <small class="text-dark">Language : </small>
//                                     <span style={{ marginLeft: '20px' }}>.....</span>
//                                 </li> */}
//                                 {/* <li class="pt-2 pb-0">
//                                     <small class="text-dark">Alternate Mobile No : </small>
//                                     <span style={{ marginLeft: '20px' }}>.....</span>
//                                 </li> */}
//                             {/* </ul>
//                         </div>
//                     </div>

//                     <div className="card">
//                         <div class="card-body">
//                             <h5 class="card-title d-flex justify-content-between store_edit">
//                                 <span>Commission Details</span>
//                                 <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
//                                     <span class="material-icons-outlined">edit</span>
//                                 </a>
//                             </h5>
//                             <ul class="list-unstyled mb-0 store_list">

//                                 <li class="pt-2 pb-0">
//                                     <small class="text-dark">Commission : </small>
//                                     <span style={{ marginLeft: '20px' }}>10%</span>
//                                 </li>
//                             </ul>
//                         </div>
//                     </div>

//                     <div className="card">
//                         <div class="card-body">
//                             <h5 class="card-title d-flex justify-content-between store_edit">
//                                 <span>Bank Details</span>
//                                 <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
//                                     <span class="material-icons-outlined">edit</span>
//                                 </a>
//                             </h5>
//                             <ul class="list-unstyled mb-0 store_list">

//                                 <li class="pt-2 pb-0">
//                                     <small class="text-dark">Account No : </small>
//                                     <span style={{ marginLeft: '20px' }}>24242424</span>
//                                 </li>
//                                 <li class="pt-2 pb-0">
//                                     <small class="text-dark">Account Name : </small>
//                                     <span style={{ marginLeft: '20px' }}>Raj</span>
//                                 </li>
//                                 <li class="pt-2 pb-0">
//                                     <small class="text-dark">IFSC Code : </small>
//                                     <span style={{ marginLeft: '20px' }}>rj12061985</span>
//                                 </li>
//                                 <li class="pt-2 pb-0">
//                                     <small class="text-dark">Bank Name : </small>
//                                     <span style={{ marginLeft: '20px' }}>Union Bank</span>
//                                 </li>
//                                 <li class="pt-2 pb-0">
//                                     <small class="text-dark">Branch Name : </small>
//                                     <span style={{ marginLeft: '20px' }}>Adyar</span>
//                                 </li>
//                             </ul>
//                         </div>
//                     </div>

//                 </div> */}

//                 <div class="row mt-3">
//                <div class="col-sm-12">
//                   <div class="card card-table p-10-25">
//                             <form>
//                                 <div class="row form-group">
//                                     <div class="col-md-12">
//                                         <div class="card bg-white bn_15">
//                                             <div class="card-body card-body_1">
//                                                 <ul class="nav nav-tabs nav-tabs-bottom nav_add_bt">
//                                                     <li class="nav-item"><a class="nav-link active" href="#bottom-tab1" data-bs-toggle="tab">Orders</a></li>
//                                                     <li class="nav-item"><a class="nav-link" href="#bottom-tab2" data-bs-toggle="tab">Wallet</a></li>
//                                                     <li class="nav-item"><a class="nav-link" href="#bottom-tab3" data-bs-toggle="tab">Documents</a></li>
//                                                     {/* <li class="nav-item"><a class="nav-link" href="#bottom-tab4" data-bs-toggle="tab">Manager Documents</a></li> */}
//                                                     <li class="nav-item"><a class="nav-link" href="#bottom-tab6" data-bs-toggle="tab">Address Details</a></li>
//                                                     <li class="nav-item"><a class="nav-link" href="#bottom-tab7 " data-bs-toggle="tab">Firm Details</a></li>
//                                                 </ul>

//                                                 {/* Orders */}
//                                                 <div class="tab-content">
//                                                     <div class="tab-pane show active" id="bottom-tab1">
//                                                         <div class="row">
//                                                             <div class="col-lg-12">
//                                                                 <div class="card-body card-body_1 card-body_2">
//                                                                     <div class="table-wrap">
//                                                                         <div class="table-responsive">
//                                                                             <table class="table display setting-td mb-30 store_table" >
//                                                                                 <thead>
//                                                                                     <tr>
//                                                                                         <th>ID</th>
//                                                                                         <th>CATEGORY</th>
//                                                                                         <th>PRICE</th>
//                                                                                         <th>MODE OF PAYMENT</th>
//                                                                                         <th>STATUS</th>
//                                                                                         <th>PENDING AMOUNT</th>
//                                                                                         <th>CREATED</th>
//                                                                                     </tr>
//                                                                                 </thead>
//                                                                                 <tbody>
//                                                                                     <tr>
//                                                                                         <td><a href="#">#467</a></td>
//                                                                                         <td><a href="#">Umadevi Ramakrishna kiranam</a></td>
//                                                                                         <td>₹1,462.50	</td>
//                                                                                         <td>Before 8:08 AM</td>
//                                                                                         <td><span class="badge badge-pill bg-success-light">PENDING	</span></td>
//                                                                                         <td>₹1462.50</td>

//                                                                                         <td>
//                                                                                             Nov 3, 12:20 PM
//                                                                                         </td>
//                                                                                     </tr>
//                                                                                     <tr>
//                                                                                         <td><a href="#">#467</a></td>
//                                                                                         <td><a href="#">Lingamurthy Ashwini kiranam</a></td>
//                                                                                         <td>₹875.00	</td>
//                                                                                         <td>Before Nov 2, 12:58 PM</td>
//                                                                                         <td><span class="badge badge-pill bg-success-light">PENDING	</span></td>
//                                                                                         <td>₹1462.50</td>

//                                                                                         <td>
//                                                                                             Nov 3, 12:20 PM
//                                                                                         </td>
//                                                                                     </tr>
//                                                                                     <tr>
//                                                                                         <td><a href="#">#466</a></td>
//                                                                                         <td><a href="#">SRR KIRANAM STORE</a></td>
//                                                                                         <td>₹1,462.50	</td>
//                                                                                         <td>Before 8:08 AM</td>
//                                                                                         <td><span class="badge badge-pill bg-success-light">PENDING	</span></td>
//                                                                                         <td>₹1462.50</td>

//                                                                                         <td>
//                                                                                             Nov 3, 12:20 PM
//                                                                                         </td>
//                                                                                     </tr>

//                                                                                     <tr>
//                                                                                         <td><a href="#">#467</a></td>
//                                                                                         <td><a href="#">Umadevi Ramakrishna kiranam</a></td>
//                                                                                         <td>₹1,462.50	</td>
//                                                                                         <td>Before 8:08 AM</td>
//                                                                                         <td><span class="badge badge-pill bg-success-light">PENDING	</span></td>
//                                                                                         <td>₹1462.50</td>

//                                                                                         <td>
//                                                                                             Nov 3, 12:20 PM
//                                                                                         </td>
//                                                                                     </tr>
//                                                                                     <tr>
//                                                                                         <td><a href="#">#467</a></td>
//                                                                                         <td><a href="#">Lingamurthy Ashwini kiranam</a></td>
//                                                                                         <td>₹875.00	</td>
//                                                                                         <td>Before Nov 2, 12:58 PM</td>
//                                                                                         <td><span class="badge badge-pill bg-success-light">PENDING	</span></td>
//                                                                                         <td>₹1462.50</td>

//                                                                                         <td>
//                                                                                             Nov 3, 12:20 PM
//                                                                                         </td>
//                                                                                     </tr>
//                                                                                     <tr>
//                                                                                         <td><a href="#">#466</a></td>
//                                                                                         <td><a href="#">SRR KIRANAM STORE</a></td>
//                                                                                         <td>₹1,462.50	</td>
//                                                                                         <td>Before 8:08 AM</td>
//                                                                                         <td><span class="badge badge-pill bg-success-light">PENDING	</span></td>
//                                                                                         <td>₹1462.50</td>

//                                                                                         <td>
//                                                                                             Nov 3, 12:20 PM
//                                                                                         </td>
//                                                                                     </tr>

//                                                                                     <tr>
//                                                                                         <td><a href="#">#467</a></td>
//                                                                                         <td><a href="#">Lingamurthy Ashwini kiranam</a></td>
//                                                                                         <td>₹875.00	</td>
//                                                                                         <td>Before Nov 2, 12:58 PM</td>
//                                                                                         <td><span class="badge badge-pill bg-success-light">PENDING	</span></td>
//                                                                                         <td>₹1462.50</td>

//                                                                                         <td>
//                                                                                             Nov 3, 12:20 PM
//                                                                                         </td>
//                                                                                     </tr>
//                                                                                     <tr>
//                                                                                         <td><a href="#">#466</a></td>
//                                                                                         <td><a href="#">SRR KIRANAM STORE</a></td>
//                                                                                         <td>₹1,462.50	</td>
//                                                                                         <td>Before 8:08 AM</td>
//                                                                                         <td><span class="badge badge-pill bg-success-light">PENDING	</span></td>
//                                                                                         <td>₹1462.50</td>

//                                                                                         <td>
//                                                                                             Nov 3, 12:20 PM
//                                                                                         </td>
//                                                                                     </tr>

//                                                                                     <tr>
//                                                                                         <td><a href="#">#467</a></td>
//                                                                                         <td><a href="#">Umadevi Ramakrishna kiranam</a></td>
//                                                                                         <td>₹1,462.50	</td>
//                                                                                         <td>Before 8:08 AM</td>
//                                                                                         <td><span class="badge badge-pill bg-success-light">PENDING	</span></td>
//                                                                                         <td>₹1462.50</td>

//                                                                                         <td>
//                                                                                             Nov 3, 12:20 PM
//                                                                                         </td>
//                                                                                     </tr>
//                                                                                     <tr>
//                                                                                         <td><a href="#">#467</a></td>
//                                                                                         <td><a href="#">Lingamurthy Ashwini kiranam</a></td>
//                                                                                         <td>₹875.00	</td>
//                                                                                         <td>Before Nov 2, 12:58 PM</td>
//                                                                                         <td><span class="badge badge-pill bg-success-light">PENDING	</span></td>
//                                                                                         <td>₹1462.50</td>

//                                                                                         <td>
//                                                                                             Nov 3, 12:20 PM
//                                                                                         </td>
//                                                                                     </tr>

//                                                                                 </tbody>
//                                                                             </table>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>

//                                                             </div>
//                                                         </div>
//                                                     </div>

//                                                     {/* Wallet */}
//                                                     <div class="tab-pane" id="bottom-tab2">
//                                                         <div class="row">
//                                                             <div class="col-lg-12">
//                                                                 <div class="card-body card-body_1">
//                                                                     <div className="row">
//                                                                         <div class="col-md-12 text-end justify-space-between mb-4">
//                                                                             {!addWallet ?
//                                                                                 <button type="submit" className="btn btn-primary" onClick={(e) => {
//                                                                                     e.preventDefault();
//                                                                                     setAddWallet(true)
//                                                                                 }}
//                                                                                 >
//                                                                                     + Add Wallet
//                                                                                 </button>
//                                                                                 :
//                                                                                 <button type="submit" className="btn btn-primary" onClick={(e) => {
//                                                                                     e.preventDefault();
//                                                                                     setAddWallet(false)
//                                                                                 }}
//                                                                                 >
//                                                                                     Wallet List
//                                                                                 </button>
//                                                                             }
//                                                                         </div>
//                                                                     </div>
//                                                                     {!addWallet ?
//                                                                         <>
//                                                                             <div class="table-wrap">
//                                                                                 <div class="table-responsive">
//                                                                                     <table class="table display setting-td mb-30" >
//                                                                                         <thead>
//                                                                                             <tr>
//                                                                                                 <th>POINTS</th>
//                                                                                                 <th>TEXT</th>
//                                                                                                 <th>DATE</th>
//                                                                                             </tr>
//                                                                                         </thead>
//                                                                                         <tbody>
//                                                                                             <tr>
//                                                                                                 <td>1212.50</td>
//                                                                                                 <td>Commission earned on order #461</td>
//                                                                                                 <td>Nov 3, 6:12 PM</td>
//                                                                                             </tr>

//                                                                                             <tr>
//                                                                                                 <td>1212.50</td>
//                                                                                                 <td>Commission earned on order #461</td>
//                                                                                                 <td>Nov 3, 6:12 PM</td>
//                                                                                             </tr>
//                                                                                             <tr>
//                                                                                                 <td>1212.50</td>
//                                                                                                 <td>Commission earned on order #461</td>
//                                                                                                 <td>Nov 3, 6:12 PM</td>
//                                                                                             </tr>
//                                                                                             <tr>
//                                                                                                 <td>1212.50</td>
//                                                                                                 <td>Commission earned on order #461</td>
//                                                                                                 <td>Nov 3, 6:12 PM</td>
//                                                                                             </tr>

//                                                                                         </tbody>
//                                                                                     </table>
//                                                                                 </div>
//                                                                             </div>
//                                                                         </>
//                                                                         :
//                                                                         <>
//                                                                             <form class="tab-pane show active" id="bottom-justified-tab4" onSubmit={e => console.log(5)}>
//                                                                                 <div className="row">
//                                                                                     <h4>Create Wallet</h4>
//                                                                                     <div className="col-md-3 mb-4">
//                                                                                         <CssTextField
//                                                                                             fullWidth
//                                                                                             type="number"
//                                                                                             id="totalLand"
//                                                                                             label="Amount"
//                                                                                             variant="standard"
//                                                                                             name="totalLand"
//                                                                                         //   value={farmer.totalLand}
//                                                                                         //   onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
//                                                                                         />
//                                                                                     </div>
//                                                                                     <div class="col-md-3">
//                                                                                         <FormControl variant="standard" fullWidth className="mt-3">
//                                                                                             <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
//                                                                                                 Wallet Type
//                                                                                             </InputLabel>
//                                                                                             <Select
//                                                                                                 labelId="demo-simple-select-standard-label"
//                                                                                                 id="irrigationSource"
//                                                                                                 label="Irrigation Source"
//                                                                                                 color="success"
//                                                                                                 name="irrigationSource"
//                                                                                             //   value={farmer.irrigationSource}
//                                                                                             //   onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
//                                                                                             >
//                                                                                                 <MenuItem value="">
//                                                                                                     <em>- Select An Option -</em>
//                                                                                                 </MenuItem>
//                                                                                                 <MenuItem value="Canal">Canal</MenuItem>
//                                                                                                 <MenuItem value="River">River</MenuItem>
//                                                                                                 <MenuItem value="Bore">Bore</MenuItem>
//                                                                                                 <MenuItem value="Well">Well</MenuItem>
//                                                                                             </Select>
//                                                                                         </FormControl>
//                                                                                     </div>
//                                                                                     <div class="col-md-4">
//                                                                                         <CssTextField
//                                                                                             fullWidth
//                                                                                             type="number"
//                                                                                             id="totalLand"
//                                                                                             label="Description"
//                                                                                             variant="standard"
//                                                                                             name="totalLand"
//                                                                                         //   value={farmer.totalLand}
//                                                                                         //   onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
//                                                                                         />
//                                                                                     </div>

//                                                                                     <div class="col-md-12 text-end mt-2 mb-2">
//                                                                                         <button type="submit" class=" w-10 btn btn-primary">Submit</button>
//                                                                                     </div>
//                                                                                 </div>
//                                                                             </form>
//                                                                         </>
//                                                                     }
//                                                                 </div>

//                                                             </div>
//                                                         </div>
//                                                     </div>

//                                                     {/* Document */}

//                                                     <div class="tab-pane" id="bottom-tab3">
//                                                         <div class="row">
//                                                             <div class="col-lg-12">
//                                                                 <div class="card-body card-body_1">
//                                                                     <div className="row">
//                                                                         <ul class="nav nav-tabs nav-tabs-bottom nav-justified mb-3">
//                                                                             <li class="nav-item">
//                                                                                 <a class="nav-link active" href="#bottom-tab" data-bs-toggle="tab" onClick={() => setManagerDoc(false)}>Documents</a>
//                                                                             </li>
//                                                                             <li class="nav-item">
//                                                                                 <a class="nav-link" href="#bottom-tab" data-bs-toggle="tab" onClick={() => setManagerDoc(true)}>Manager Douments</a>
//                                                                             </li>
//                                                                         </ul>
//                                                                     </div>
//                                                                     {!managerDoc ?
//                                                                         <>
//                                                                             <div className="row">
//                                                                                 <div class="col-md-12 text-end justify-space-between mb-4">
//                                                                                     {!addDoc ?
//                                                                                         <button type="submit" className="btn btn-primary" onClick={(e) => {
//                                                                                             e.preventDefault();
//                                                                                             setAddDoc(true)
//                                                                                         }}
//                                                                                         >
//                                                                                             + Add Document
//                                                                                         </button>
//                                                                                         :
//                                                                                         <button type="submit" className="btn btn-primary" onClick={(e) => {
//                                                                                             e.preventDefault();
//                                                                                             setAddDoc(false)
//                                                                                         }}
//                                                                                         >
//                                                                                             Document List
//                                                                                         </button>
//                                                                                     }
//                                                                                 </div>
//                                                                             </div>
//                                                                             {!addDoc ?
//                                                                                 <>
//                                                                                     <div class="table-wrap">
//                                                                                         <div class="table-responsive">
//                                                                                             <table class="table display setting-td mb-30" >
//                                                                                                 <thead>
//                                                                                                     <tr>
//                                                                                                         <th>TITLE</th>
//                                                                                                         <th>PHOTO</th>
//                                                                                                         <th>CREATED</th>
//                                                                                                         <th>ACTIONS</th>
//                                                                                                     </tr>
//                                                                                                 </thead>
//                                                                                                 <tbody>
//                                                                                                     <tr>
//                                                                                                         <td>TRADE LIC</td>
//                                                                                                         <td>-</td>
//                                                                                                         <td>Sep 21, 11:19 AM</td>
//                                                                                                         <td>
//                                                                                                             <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
//                                                                                                                 <span class="material-icons-outlined">edit</span>
//                                                                                                             </a>
//                                                                                                             <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
//                                                                                                                 <span class="material-icons-outlined">delete</span>
//                                                                                                             </a>
//                                                                                                         </td>
//                                                                                                     </tr>

//                                                                                                     <tr>
//                                                                                                         <td>TRADE LIC</td>
//                                                                                                         <td>-</td>
//                                                                                                         <td>Sep 21, 11:19 AM</td>
//                                                                                                         <td>
//                                                                                                             <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
//                                                                                                                 <span class="material-icons-outlined">edit</span>
//                                                                                                             </a>
//                                                                                                             <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
//                                                                                                                 <span class="material-icons-outlined">delete</span>
//                                                                                                             </a>
//                                                                                                         </td>
//                                                                                                     </tr>

//                                                                                                     <tr>
//                                                                                                         <td>TRADE LIC</td>
//                                                                                                         <td>-</td>
//                                                                                                         <td>Sep 21, 11:19 AM</td>
//                                                                                                         <td>
//                                                                                                             <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
//                                                                                                                 <span class="material-icons-outlined">edit</span>
//                                                                                                             </a>
//                                                                                                             <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
//                                                                                                                 <span class="material-icons-outlined">delete</span>
//                                                                                                             </a>
//                                                                                                         </td>
//                                                                                                     </tr>

//                                                                                                 </tbody>
//                                                                                             </table>
//                                                                                         </div>
//                                                                                     </div>
//                                                                                 </>
//                                                                                 :
//                                                                                 <>
//                                                                                     <form class="tab-pane show active" id="bottom-justified-tab4" onSubmit={e => console.log(5)}>
//                                                                                         <div className="row">
//                                                                                             <h4>Add New Document</h4>
//                                                                                             <div className="col-md-3 mb-2">
//                                                                                                 <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
//                                                                                                     Document Upload
//                                                                                                 </InputLabel>
//                                                                                                 <div class="form-group row widget-3" style={{ width: '200px', margin: 'auto', display: 'flex', justifyContent: 'center' }}>
//                                                                                                     <section className="container" style={baseStyle}>
//                                                                                                         <div {...getRootProps({ className: 'dropzone' })}
//                                                                                                             style={{
//                                                                                                                 borderRadius: '5px',
//                                                                                                                 display: 'flex',
//                                                                                                                 justifyContent: 'center',
//                                                                                                                 alignItems: 'center',
//                                                                                                                 height: '100px'
//                                                                                                             }}
//                                                                                                         >
//                                                                                                             <input {...getInputProps()} multiple={false} />
//                                                                                                             {Files &&
//                                                                                                                 <>
//                                                                                                                     {Files.length === 0 &&
//                                                                                                                         <p align="center">Drop Image  here or click to upload.</p>
//                                                                                                                     }
//                                                                                                                 </>
//                                                                                                             }
//                                                                                                             {Files &&
//                                                                                                                 <>
//                                                                                                                     {Files.length > 0 &&
//                                                                                                                         <aside style={thumbsContainerState}>{PGthumbs}</aside>
//                                                                                                                     }
//                                                                                                                 </>
//                                                                                                             }
//                                                                                                         </div>
//                                                                                                     </section>
//                                                                                                 </div>
//                                                                                             </div>
//                                                                                         </div>
//                                                                                         <div className="row">
//                                                                                             <div class="col-md-3">
//                                                                                                 <FormControl variant="standard" fullWidth>
//                                                                                                     <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
//                                                                                                         Document Type
//                                                                                                     </InputLabel>
//                                                                                                     <Select
//                                                                                                         labelId="demo-simple-select-standard-label"
//                                                                                                         id="soilType"
//                                                                                                         color="success"
//                                                                                                         name="soilType"
//                                                                                                     //  value={farmer.selectUnit}
//                                                                                                     //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
//                                                                                                     >
//                                                                                                         <MenuItem value="">
//                                                                                                             <em>- Select Unit -</em>
//                                                                                                         </MenuItem>
//                                                                                                         <MenuItem value="Hindi">Aadhar</MenuItem>
//                                                                                                         <MenuItem value="Bengali">PAN</MenuItem>
//                                                                                                     </Select>
//                                                                                                 </FormControl>
//                                                                                             </div>
//                                                                                         </div>
//                                                                                         <div className="row">
//                                                                                             <div class="col-md-3">
//                                                                                                 <CssTextField
//                                                                                                     fullWidth
//                                                                                                     id="fEducation"
//                                                                                                     label="Document Number"
//                                                                                                     variant="standard"
//                                                                                                     style={{ marginBottom: '1.25rem' }}
//                                                                                                     name="education"
//                                                                                                 //  value={farmer.education}
//                                                                                                 //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
//                                                                                                 />
//                                                                                             </div>
//                                                                                         </div>
//                                                                                         <div class="col-md-12 text-end mt-2 mb-2">
//                                                                                             <button type="submit" class=" w-10 btn btn-primary">Submit</button>
//                                                                                         </div>
//                                                                                     </form>
//                                                                                 </>
//                                                                             }
//                                                                         </>
//                                                                         :
//                                                                         // ManagerDocuments
//                                                                         <>
//                                                                             <div class="row">
//                                                                                 <div class="col-lg-12">
//                                                                                     <div class="card-body card-body_1">
//                                                                                         <div className="row">
//                                                                                             <div class="col-md-12 text-end justify-space-between mb-4">
//                                                                                                 {!addDoc ?
//                                                                                                     <button type="submit" className="btn btn-primary" onClick={(e) => {
//                                                                                                         e.preventDefault();
//                                                                                                         setAddDoc(true)
//                                                                                                     }}
//                                                                                                     >
//                                                                                                         + Add Document
//                                                                                                     </button>
//                                                                                                     :
//                                                                                                     <button type="submit" className="btn btn-primary" onClick={(e) => {
//                                                                                                         e.preventDefault();
//                                                                                                         setAddDoc(false)
//                                                                                                     }}
//                                                                                                     >
//                                                                                                         Document List
//                                                                                                     </button>
//                                                                                                 }
//                                                                                             </div>
//                                                                                         </div>
//                                                                                         {!addDoc ?
//                                                                                             <>
//                                                                                                 <div class="table-wrap">
//                                                                                                     <div class="table-responsive">
//                                                                                                         <table class="table display setting-td mb-30" >
//                                                                                                             <thead>
//                                                                                                                 <tr>
//                                                                                                                     <th>TITLE</th>
//                                                                                                                     <th>PHOTO</th>
//                                                                                                                     <th>CREATED</th>
//                                                                                                                     <th>ACTIONS</th>
//                                                                                                                 </tr>
//                                                                                                             </thead>
//                                                                                                             <tbody>
//                                                                                                                 <tr>
//                                                                                                                     <td>TRADE LIC</td>
//                                                                                                                     <td>-</td>
//                                                                                                                     <td>Sep 21, 11:19 AM</td>
//                                                                                                                     <td>
//                                                                                                                         <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
//                                                                                                                             <span class="material-icons-outlined">edit</span>
//                                                                                                                         </a>
//                                                                                                                         <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
//                                                                                                                             <span class="material-icons-outlined">delete</span>
//                                                                                                                         </a>
//                                                                                                                     </td>
//                                                                                                                 </tr>

//                                                                                                                 <tr>
//                                                                                                                     <td>TRADE LIC</td>
//                                                                                                                     <td>-</td>
//                                                                                                                     <td>Sep 21, 11:19 AM</td>
//                                                                                                                     <td>
//                                                                                                                         <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
//                                                                                                                             <span class="material-icons-outlined">edit</span>
//                                                                                                                         </a>
//                                                                                                                         <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
//                                                                                                                             <span class="material-icons-outlined">delete</span>
//                                                                                                                         </a>
//                                                                                                                     </td>
//                                                                                                                 </tr>

//                                                                                                                 <tr>
//                                                                                                                     <td>TRADE LIC</td>
//                                                                                                                     <td>-</td>
//                                                                                                                     <td>Sep 21, 11:19 AM</td>
//                                                                                                                     <td>
//                                                                                                                         <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
//                                                                                                                             <span class="material-icons-outlined">edit</span>
//                                                                                                                         </a>
//                                                                                                                         <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
//                                                                                                                             <span class="material-icons-outlined">delete</span>
//                                                                                                                         </a>
//                                                                                                                     </td>
//                                                                                                                 </tr>

//                                                                                                             </tbody>
//                                                                                                         </table>
//                                                                                                     </div>
//                                                                                                 </div>
//                                                                                             </>
//                                                                                             :
//                                                                                             <>
//                                                                                                 <form class="tab-pane show active" id="bottom-justified-tab4" onSubmit={e => console.log(5)}>
//                                                                                                     <div className="row">
//                                                                                                         <h4>Add Manager Document</h4>
//                                                                                                         <div className="col-md-3 mb-2">
//                                                                                                             <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
//                                                                                                                 Document Upload
//                                                                                                             </InputLabel>
//                                                                                                             <div class="form-group row widget-3" style={{ width: '200px', margin: 'auto', display: 'flex', justifyContent: 'center' }}>
//                                                                                                                 <section className="container" style={baseStyle}>
//                                                                                                                     <div {...getRootProps({ className: 'dropzone' })}
//                                                                                                                         style={{
//                                                                                                                             borderRadius: '5px',
//                                                                                                                             display: 'flex',
//                                                                                                                             justifyContent: 'center',
//                                                                                                                             alignItems: 'center',
//                                                                                                                             height: '100px'
//                                                                                                                         }}
//                                                                                                                     >
//                                                                                                                         <input {...getInputProps()} multiple={false} />
//                                                                                                                         {Files &&
//                                                                                                                             <>
//                                                                                                                                 {Files.length === 0 &&
//                                                                                                                                     <p align="center">Drop Image  here or click to upload.</p>
//                                                                                                                                 }
//                                                                                                                             </>
//                                                                                                                         }
//                                                                                                                         {Files &&
//                                                                                                                             <>
//                                                                                                                                 {Files.length > 0 &&
//                                                                                                                                     <aside style={thumbsContainerState}>{PGthumbs}</aside>
//                                                                                                                                 }
//                                                                                                                             </>
//                                                                                                                         }
//                                                                                                                     </div>
//                                                                                                                 </section>
//                                                                                                             </div>
//                                                                                                         </div>
//                                                                                                     </div>
//                                                                                                     <div className="row">
//                                                                                                         <div class="col-md-3">
//                                                                                                             <FormControl variant="standard" fullWidth className="mt-3">
//                                                                                                                 <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
//                                                                                                                     Document Type
//                                                                                                                 </InputLabel>
//                                                                                                                 <Select
//                                                                                                                     labelId="demo-simple-select-standard-label"
//                                                                                                                     id="soilType"
//                                                                                                                     label="Soil Type"
//                                                                                                                     color="success"
//                                                                                                                     name="soilType"
//                                                                                                                 //  value={farmer.selectUnit}
//                                                                                                                 //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
//                                                                                                                 >
//                                                                                                                     <MenuItem value="">
//                                                                                                                         <em>- Select Unit -</em>
//                                                                                                                     </MenuItem>
//                                                                                                                     <MenuItem value="Hindi">Aadhar</MenuItem>
//                                                                                                                     <MenuItem value="Bengali">PAN</MenuItem>
//                                                                                                                 </Select>
//                                                                                                             </FormControl>
//                                                                                                         </div>
//                                                                                                     </div>
//                                                                                                     <div className="row">
//                                                                                                         <div class="col-md-3">
//                                                                                                             <CssTextField
//                                                                                                                 fullWidth
//                                                                                                                 id="fEducation"
//                                                                                                                 label="Document Number"
//                                                                                                                 variant="standard"
//                                                                                                                 className="mt-3"
//                                                                                                                 style={{ marginBottom: '1.25rem' }}
//                                                                                                                 name="education"
//                                                                                                             //  value={farmer.education}
//                                                                                                             //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
//                                                                                                             />
//                                                                                                         </div>
//                                                                                                     </div>
//                                                                                                     <div class="col-md-12 text-end mt-2 mb-2">
//                                                                                                         <button type="submit" class=" w-10 btn btn-primary">Submit</button>
//                                                                                                     </div>
//                                                                                                 </form>
//                                                                                             </>
//                                                                                         }
//                                                                                     </div>
//                                                                                 </div>
//                                                                             </div>
//                                                                         </>
//                                                                     }
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>

//                                                     {/* Address Details */}
//                                                     <div class="tab-pane" id="bottom-tab6">
//                                                         <div class="row">
//                                                             <div class="col-lg-12">
//                                                                 <div class="card-body card-body_1">
//                                                                     <div className="row">
//                                                                         <div class="col-md-12 text-end justify-space-between mb-4">
//                                                                         </div>
//                                                                     </div>
//                                                                     <div class="card" style={{ backgroundColor: '#f3f3f3 ' }}>
//                                                                         <div class="card-body">
//                                                                             <h5 class="card-title d-flex justify-content-between store_edit">
//                                                                                 <span>Details</span>
//                                                                                 <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
//                                                                                     <span class="material-icons-outlined mr-3">edit</span>
//                                                                                     <span class="material-icons-outlined">delete</span>
//                                                                                 </a>
//                                                                             </h5>
//                                                                             <ul class="list-unstyled mb-0 store_list">
//                                                                                 <div className="row">
//                                                                                     <div className="col-md-4 mb-4">
//                                                                                         <li class="pt-2 pb-0">
//                                                                                             <small class="text-dark">Company Name  </small>
//                                                                                         </li>
//                                                                                         <li>
//                                                                                             ........
//                                                                                         </li>
//                                                                                     </div>
//                                                                                     <div className="col-md-4 mb-4">
//                                                                                         <li class="pt-2 pb-0">
//                                                                                             <small class="text-dark">No Of Employees  </small>
//                                                                                         </li>
//                                                                                         <li>
//                                                                                             ........
//                                                                                         </li>
//                                                                                     </div>
//                                                                                     <div className="col-md-4 mb-4">
//                                                                                         <li class="pt-2 pb-0">
//                                                                                             <small class="text-dark">No Of Members </small>
//                                                                                         </li>
//                                                                                         <li>
//                                                                                             ........
//                                                                                         </li>
//                                                                                     </div>
//                                                                                     <div className="col-md-4 mb-4">
//                                                                                         <li class="pt-2 pb-0">
//                                                                                             <small class="text-dark">Types of Crop </small>
//                                                                                         </li>
//                                                                                         <li>
//                                                                                             ........
//                                                                                         </li>
//                                                                                     </div>
//                                                                                     <div className="col-md-4 mb-4">
//                                                                                         <li class="pt-2 pb-0">
//                                                                                             <small class="text-dark">Govt Fund </small>
//                                                                                         </li>
//                                                                                         <li>
//                                                                                             ........
//                                                                                         </li>
//                                                                                     </div>
//                                                                                     <div className="col-md-12 mb-4">

//                                                                                     </div>
//                                                                                 </div>
//                                                                             </ul>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>

//                                                     {/* Firm Details */}
//                                                     <div class="tab-pane" id="bottom-tab7">
//                                                         {!firmEdit ?
//                                                             <div class="row">
//                                                                 <div class="col-lg-12">
//                                                                     <div class="card-body card-body_1">
//                                                                         <div class="card" style={{ backgroundColor: '#f3f3f3 ' }}>
//                                                                             <div class="card-body">
//                                                                                 <h5 class="card-title d-flex justify-content-between store_edit">
//                                                                                     <span>Address</span>
//                                                                                     <div>
//                                                                                         <IconButton onClick={() => setFirmEdit(true)} color="success">
//                                                                                             <EditIcon color="success" />
//                                                                                         </IconButton>
//                                                                                         <IconButton style={{ marginLeft: '8px' }} color="error">
//                                                                                             <DeleteIcon color="error" />
//                                                                                         </IconButton>
//                                                                                     </div>

//                                                                                 </h5>
//                                                                                 <ul class="list-unstyled mb-0 store_list">
//                                                                                     <div className="row">
//                                                                                         <div className="col-md-3 mb-4">
//                                                                                             <li class="pt-2 pb-0">
//                                                                                                 <small class="text-dark">Firm Name </small>
//                                                                                             </li>
//                                                                                             <li>
//                                                                                                 Pixalive
//                                                                                             </li>
//                                                                                         </div>
//                                                                                         <div className="col-md-3 mb-4">
//                                                                                             <li class="pt-2 pb-0">
//                                                                                                 <small class="text-dark">GST No </small>
//                                                                                             </li>
//                                                                                             <li>
//                                                                                                 .........
//                                                                                             </li>
//                                                                                         </div>
//                                                                                         <div className="col-md-3 mb-4">
//                                                                                             <li class="pt-2 pb-0">
//                                                                                                 <small class="text-dark">PAN No </small>
//                                                                                             </li>
//                                                                                             <li>
//                                                                                                 ..........
//                                                                                             </li>
//                                                                                         </div>
//                                                                                         <div className="col-md-3 mb-4">
//                                                                                             <li class="pt-2 pb-0">
//                                                                                                 <small class="text-dark">Types Of  Crop </small>
//                                                                                             </li>
//                                                                                             <li>
//                                                                                                 ..........
//                                                                                             </li>
//                                                                                         </div>
//                                                                                         <div className="col-md-3 mb-4">
//                                                                                             <li class="pt-2 pb-0">
//                                                                                                 <small class="text-dark">State </small>
//                                                                                             </li>
//                                                                                             <li>
//                                                                                                 TamilNadu
//                                                                                             </li>
//                                                                                         </div>
//                                                                                         <div className="col-md-3 mb-4">
//                                                                                             <li class="pt-2 pb-0">
//                                                                                                 <small class="text-dark">District </small>
//                                                                                             </li>
//                                                                                             <li>
//                                                                                                 Chennai
//                                                                                             </li>
//                                                                                         </div>
//                                                                                         <div className="col-md-3 mb-4">
//                                                                                             <li class="pt-2 pb-0">
//                                                                                                 <small class="text-dark">Sub District </small>
//                                                                                             </li>
//                                                                                             <li>
//                                                                                                 Chennai
//                                                                                             </li>
//                                                                                         </div>
//                                                                                         <div className="col-md-3 mb-4">
//                                                                                             <li class="pt-2 pb-0">
//                                                                                                 <small class="text-dark">  Village </small>
//                                                                                             </li>
//                                                                                             <li>
//                                                                                                 Nedugundram
//                                                                                             </li>
//                                                                                         </div>
//                                                                                         <div className="col-md-3 mb-4">
//                                                                                             <li class="pt-2 pb-0">
//                                                                                                 <small class="text-dark">  Pincode </small>
//                                                                                             </li>
//                                                                                             <li>
//                                                                                                 600014
//                                                                                             </li>
//                                                                                         </div>
//                                                                                         <div className="col-md-3 mb-4">
//                                                                                             <li class="pt-2 pb-0">
//                                                                                                 <small class="text-dark">  Address </small>
//                                                                                             </li>
//                                                                                             <li>
//                                                                                                 No: 6/32........
//                                                                                             </li>
//                                                                                         </div>
//                                                                                         <div className="col-md-12 mb-4">
//                                                                                             <li class="pt-2 pb-0">
//                                                                                                 <h5 class="card-title d-flex justify-content-between mt-4">
//                                                                                                     <span>Location</span>
//                                                                                                 </h5>
//                                                                                             </li>
//                                                                                             <li>
//                                                                                                 <a class="store-avatar" style={mapThumb}>
//                                                                                                     <div class="store_display">
//                                                                                                         <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15318.518229107614!2d80.4303255!3d16.2907457!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3d93323686c7ea15!2sSRI%20HANUMAN%20TRADERS!5e0!3m2!1sen!2sin!4v1636515519444!5m2!1sen!2sin" width="100%" height="250" allowfullscreen="" loading="lazy"></iframe>
//                                                                                                     </div>
//                                                                                                 </a>
//                                                                                             </li>
//                                                                                         </div>
//                                                                                     </div>
//                                                                                 </ul>
//                                                                             </div>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                             :
//                                                             <>
//                                                                 <div class="row">
//                                                                     <div class="col-md-12 text-end mb-2">
//                                                                         <button type="submit" class=" w-10 btn btn-primary" onClick={() => setFirmEdit(false)}>Back</button>
//                                                                     </div>
//                                                                 </div>
//                                                                 <EditTraders />
//                                                             </>
//                                                         }
//                                                     </div>

//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>

//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>

//     );
// }
// export default Traders;
import React, { useState, useEffect } from 'react'

import { CssTextField } from '../../Styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { useDropzone } from 'react-dropzone'
// import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem'
import {
    thumbsContainerState,
    thumb,
    img,
    thumbInner,
    mapThumb,
    baseStyle,
} from '../../Styles'
// import LivestockProduct from './Product/LivestockProduct';
import { API } from '../../../API'
import TraderTable from '../../Create/CreateTrader/TraderTable'
// import 'antd/dist/antd.css';
// import { Select } from 'antd';
// const { Option } = Select;
import Select from 'react-select'
import axios from 'axios'
function Settings() {
    const [product, setProduct] = useState(false)
    const [addDoc, setAddDoc] = useState(false)
    const [addWallet, setAddWallet] = useState(false)
    const [cropTypes, setCropTypes] = React.useState([])
    const [Files, setFiles] = useState([])
    const [Details, setDetails] = useState([])
    const [number, setNumber] = useState()
    const [Option, setOption] = useState([])
    const [districtOption, setdistrictOption] = useState([])
    // const [farmers,setf]
    const getdistrictOptionlist = async () => {
        const { data } = await API.get(`/districtTrader`)
        // console.log("hkjjhj",data)
        setdistrictOption(data?.result)
        console.log('data', data)
    }
    const DistrictOption =
        districtOption &&
        districtOption.map((item, ind) => ({
            value: item,
            label: item,
        }))

    useEffect(async () => {
        // console.log(localStorage.getItem('farmerid'),"hgfjgjfjh")
        // const { data } = await API.get(`/getFarmer?type=0`);
        //  console.log("data",data)
        //  setDetails(data?.result);
        getCategoryList()
        getdistrictOptionlist()
    }, [])

    const getCategoryList = async () => {
        const { data } = await API.get(`/stateTrader`)
        // console.log("hkjjhj",data)
        setOption(data?.result)
        console.log('data', data)
    }
    const option =
        Option &&
        Option.map((item, ind) => ({
            value: item,
            label: item,
        }))

    const handlesearchstate = async (e) => {
        console.log('e', e.value)
        setDetails([])
        const { data } = await API.get(`/getStateTrader?state=` + e.value)
        console.log('data', data)
        if (data.success == true) {
            // let arr =[]
            // arr.push(data?.result)
            setDetails(data?.result)
        } else {
        }
    }
    const handlesearchdistrict = async (e) => {
        console.log('e', e.value)
        setDetails([])
        const { data } = await API.get(`/getDistrictTrader?district=` + e.value)
        console.log('data', data)
        if (data.success == true) {
            // let arr =[]
            // arr.push(data?.result)
            setDetails(data?.result)
        } else {
        }
    }

    //   const options = [
    //    { value: 'chocolate', label: 'Chocolate' },
    //    { value: 'strawberry', label: 'Strawberry' },
    //    { value: 'vanilla', label: 'Vanilla' }
    //  ]
    //   agrimachinefarmerid

    //   useEffect(async() => {
    //   console.log(localStorage.getItem('livestockfarmerid'),"hgfjgjfjh")
    //   const { data } = await API.get(`/getLiveStocks?type=0`);
    //    console.log("data",data)
    //    setDetails(data?.result);
    //  }, []);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
        },
    })

    const PGthumbs = Files.map((file, index) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt="" />
            </div>
        </div>
    ))

    function onChange(value) {
        console.log(`selected ${value}`)
    }

    function onSearch(val) {
        console.log('search:', val)
    }
    const handleMobilesearch = (e) => {
        if (e.target.value.length <= 10) setNumber(e.target.value)
        if (e.target.value.length == 10) mobilechecksearch(e.target.value)
        else setDetails([])
    }
    const mobilechecksearch = async (number) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }

        var payload = {
            mobile_no: number,
            role: 'trader',
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-register`,
            payload,
            config
        )

        console.log('data', data.data, data.farm_info)

        if (data.data) {
            var a = {
                _id: data.data.data._id,
                user_id: data.data.data.user_id,
                firstName: data.data.data.first_name,
                lastName: data.data.data.last_name,
                mobileNumber: data.data.data.mobile_number,
                gender: data.data.data.gender,
                category: data.data.data.role,
                dateOfBirth: '2022-04-10',
                education: '',
                language: data.data.data.language,
                state: data.data.data.state,
                district: data.data.data.district,
                subDistrict: data.data.data.sub_distric,
                firmName: data.data.farm_info.firm_name,
                village: data.data.data.village,
                pincode: data.data.data.pincode,
                adhaarNumber: '',
                fullAddress: data.data.data.fullAddress,
                bankAccountNumber: '',
                bankAccountName: '',
                ifscCode: '',
                bankName: '',
                bankBranchName: '',
                area: data.data.farm_info.total_land,
                totalLand: data.data.farm_info.total_land,
                createdAt: data.data.data.created_at,
                id: data.data.data.id,
            }
            let arr = []
            arr.push(a)
            setDetails(arr)
        }
        // const { data } = await API.get(`/searchTrader?search=`+number);
        // console.log("data",data)
        // if(data.success ==true) {
        //   let arr =[]
        //   arr.push(data?.result)
        //   setDetails(arr);

        // }
        else {
        }
    }
    return (
        <div class="row">
            <div class="col-sm-12">
                {/* <h3 className="mb-3">List</h3> */}
                <div class="card card-table">
                    <div class="card-body">
                        <div class="table-responsive">
                            <div
                                id="DataTables_Table_0_wrapper"
                                class="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: '2%',
                                    }}
                                >
                                    {/* <div
                                        class="form-group"
                                        style={{
                                            marginLeft: '2%',
                                            width: '200px',
                                        }}
                                    >
                                        <Select
                                            options={option}
                                            placeholder={'Select State'}
                                            onChange={(e) =>
                                                handlesearchstate(e)
                                            }
                                        />
                                    </div>
                                    <div
                                        class="form-group"
                                        style={{
                                            marginLeft: '2%',
                                            width: '200px',
                                        }}
                                    >
                                        <Select
                                            options={DistrictOption}
                                            placeholder={'Select District'}
                                            onChange={(e) =>
                                                handlesearchdistrict(e)
                                            }
                                        />
                                    </div> */}
                                    {/* <div class="form-group" style={{marginLeft:'2%',width:'200px'}}>
                 
   <Select 
  //  options={options}  
      />
                    </div>   */}
                                    <div
                                        class="form-group"
                                        style={{ marginLeft: '2%' }}
                                    >
                                        <input
                                            type="number"
                                            class="form-control"
                                            placeholder="Search Number"
                                            value={number}
                                            onChange={(e) =>
                                                handleMobilesearch(e)
                                            }
                                        />
                                    </div>
                                </div>

                                <div class="row" style={{ margin: '2%' }}>
                                    {/* <FpoTable fpoList={Details} /> */}
                                    <TraderTable inputs={Details} />
                                </div>

                                <div class="row">
                                    <div class="col-sm-12 col-md-5">
                                        {/* <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 1 to 10 of 12 entries</div> */}
                                    </div>
                                    <div class="col-sm-12 col-md-7">
                                        <div
                                            class="dataTables_paginate paging_simple_numbers"
                                            id="DataTables_Table_0_paginate"
                                        >
                                            <ul class="pagination">
                                                <li
                                                    class="paginate_button page-item previous disabled"
                                                    id="DataTables_Table_0_previous"
                                                >
                                                    <a
                                                        href="#"
                                                        aria-controls="DataTables_Table_0"
                                                        data-dt-idx="0"
                                                        tabindex="0"
                                                        class="page-link"
                                                    >
                                                        Previous
                                                    </a>
                                                </li>
                                                <li class="paginate_button page-item active">
                                                    <a
                                                        href="#"
                                                        aria-controls="DataTables_Table_0"
                                                        data-dt-idx="1"
                                                        tabindex="0"
                                                        class="page-link"
                                                    >
                                                        1
                                                    </a>
                                                </li>
                                                <li class="paginate_button page-item ">
                                                    <a
                                                        href="#"
                                                        aria-controls="DataTables_Table_0"
                                                        data-dt-idx="2"
                                                        tabindex="0"
                                                        class="page-link"
                                                    >
                                                        2
                                                    </a>
                                                </li>
                                                <li
                                                    class="paginate_button page-item next"
                                                    id="DataTables_Table_0_next"
                                                >
                                                    <a
                                                        href="#"
                                                        aria-controls="DataTables_Table_0"
                                                        data-dt-idx="3"
                                                        tabindex="0"
                                                        class="page-link"
                                                    >
                                                        Next
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Settings
