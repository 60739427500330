import React, { useState, useEffect } from 'react'
import { CssTextField } from '../../Styles'
import LsCategories from './ProductLsCategories'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import LivestockInventory from './LivestockInventory'
import Menu from '@mui/material/Menu'
import Fade from '@mui/material/Fade'
import ProductLivestock from '../../Catelog/ProductCatelog/Livestock/LivestockInvetoryTable'
import { API } from '../../../API/index'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import {
    thumbsContainerState,
    thumb,
    img,
    thumbInner,
    baseStyle,
    driverDP,
    sectionDiv,
    uploadBtnSm,
    panImg,
} from '../../Styles'
import { useDropzone } from 'react-dropzone'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { storage } from '../../../firebase/index'
// import LivestockInventoryTable from "./LivestockInvetoryTable"
import Swal from 'sweetalert2'
const inputClear = {
    title: 'ProductCatelog',
    catelogName: 'LiveStockCatelog',
    categoryName: '',
}
const Subclear = {
    title: 'ProductCatelog',
    catelogName: 'LiveStockCatelog',
    CategoryName: '',
    subCategoryName: '',
}
const BrandClear = {
    title: 'ProductCatelog',
    catelogName: 'LiveStockCatelog',
    categoryName: '',
    subCategoryName: '',
    brandName: '',
    brandImage: '',
}

const ProductClear = {
    title: 'ProductCatelog',
    catelogName: 'LiveStockCatelog',
    categoryName: '',
    subCategoryName: '',
    brandName: '',
    productName: '',
    quantity: '',
    productImage: '',
}
const VariantClear = {
    title: 'ProductCatelog',
    catelogName: 'LiveStockCatelog',
    categoryName: '',
    subCategoryName: '',
    brandName: '',
    productName: '',
    variantName: '',
}
const Input = styled('input')({
    display: 'none',
    marginBottom: '16px',
    marginTop: '16px',
})

function LivestockCatelog() {
    const [Details, setDetails] = useState({})
    useEffect(async () => {
        console.log(localStorage.getItem('inputfarmerid'), 'hgfjgjfjh')
        const { data } = await API.get(
            `/getLiveStocks?type=1&id=` +
                localStorage.getItem('livestockfarmerid')
        )
        console.log('data', data)
        setDetails(data?.result)
        getCategoryList(data?.result._id)
        getProductInput(data?.result._id)
    }, [])
    const [showCategory, setShowCategory] = useState(false)
    const [createProduct, setCreateProduct] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [Categorylist, setCategorylist] = useState([])
    const [subCategorylist, setsubCategorylist] = useState([])
    const [brandlist, setbrandlist] = useState([])
    const [categoryName, setcategoryName] = useState('')
    const [subcategoryName, setsubcategoryName] = useState('')
    const [Productlist, setProductlist] = useState([])
    const [ProductInput, setProductInput] = useState([])
    const [driverImg, setDriverImg] = useState(null)
    const [BrandImg, setBrandImg] = useState(null)
    const open = Boolean(anchorEl)

    useEffect(() => {
        // getState();
        // getCategoryList();
        // getCategoryList();
    }, [])
    const getProductInput = async (val) => {
        const { data } = await API.get(
            `/getProductCatelog?title=ProductCatelog&catelogName=LiveStockCatelog&type=3&id=` +
                val
        )
        setProductInput(data?.result)
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const [InputLivestockProduct, setInputLivestockProduct] = useState({
        title: 'ProductCatelog',
        catelogName: 'LiveStockCatelog',
        categoryName: '',
        id: '',
    })
    const [SubLivestockProduct, setSubLivestockProduct] = useState({
        title: 'ProductCatelog',
        catelogName: 'LiveStockCatelog',
        categoryName: '',
        subCategoryName: '',
        id: '',
    })
    const [driverLicense, setDriverLicense] = useState([])
    const [BrandLivestockProduct, setBrandLivestockProduct] = useState({
        title: 'ProductCatelog',
        catelogName: 'LiveStockCatelog',
        categoryName: '',
        subCategoryName: '',
        brandName: '',
        brandImage: '',
        id: '',
    })
    const [driverLicenses, setDriverLicenses] = useState([])
    const [CommissionLivestockProduct, setCommissionLivestockProduct] =
        useState({
            title: 'ProductCatelog',
            catelogName: 'LiveStockCatelog',
            categoryName: '',
            subCategoryName: '',
            brandName: '',
            productName: '',
            quantity: '',
            productImage: '',
            id: '',
        })
    const [LivestockVariantProduct, setLivestockVariantProduct] = useState({
        title: 'ProductCatelog',
        catelogName: 'LiveStockCatelog',
        categoryName: '',
        subCategoryName: '',
        brandName: '',
        brandImage: '',
        productName: '',
        productImage: '',
        Quantity: '',
        unit: '',
        id: '',
    })
    const handleInputLivestockProduct = async () => {
        console.log('InputLivestockProduct', InputLivestockProduct)

        try {
            let datas = { ...InputLivestockProduct, id: Details._id }
            console.log('InputLivestockProduct', datas)
            const { data } = await API.post('/createProductCategory ', datas)
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)
                getCategoryList(Details._id)
                setInputLivestockProduct(inputClear)
            } else {
                setInputLivestockProduct(inputClear)
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setInputLivestockProduct(inputClear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handleSubLivestockProduct = async () => {
        console.log('SubLivestockProduct ', SubLivestockProduct)

        try {
            let datas = { ...SubLivestockProduct, id: Details._id }
            const { data } = await API.post('/createProductSubCategory ', datas)
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)

                setSubLivestockProduct(Subclear)
            } else {
                setSubLivestockProduct(Subclear)
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setSubLivestockProduct(Subclear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handleBrandLivestockProduct = async () => {
        console.log('BrandLivestockProduct', BrandLivestockProduct)

        try {
            let datas = { ...BrandLivestockProduct, id: Details._id }
            const { data } = await API.post('/createProductBrand', datas)
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)
                setBrandImg(null)
                setDriverImg(null)
                setBrandLivestockProduct(BrandClear)
            } else {
                setBrandLivestockProduct(BrandClear)
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setBrandLivestockProduct(BrandClear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handleCommissionLivestockProduct = async () => {
        console.log('CommissionLivestockProduct', CommissionLivestockProduct)

        try {
            let datas = { ...CommissionLivestockProduct, id: Details._id }
            const { data } = await API.post(
                '/createProductCatelogProduct ',
                datas
            )
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)
                setBrandImg(null)
                setDriverImg(null)
                setCommissionLivestockProduct(ProductClear)
            } else {
                setCommissionLivestockProduct(ProductClear)
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setCommissionLivestockProduct(ProductClear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handleLivestockVariantProduct = async () => {
        console.log('LivestockVariantProduct', LivestockVariantProduct)

        try {
            let datas = { ...LivestockVariantProduct, id: Details._id }
            const { data } = await API.post(
                '/createProductCatelogQuantity',
                datas
            )
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)

                setLivestockVariantProduct(VariantClear)
            } else {
                setLivestockVariantProduct(VariantClear)
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setLivestockVariantProduct(VariantClear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    const getCategoryList = async (val) => {
        const { data } = await API.get(
            `/getProductCategory?type=1&title=ProductCatelog&catelogName=LiveStockCatelog&id=` +
                val
        )
        // console.log("hkjjhj",data)
        setCategorylist(data?.result)
    }
    const handleCategoryChange = (e) => {
        // setSubLivestockProduct({...SubLivestockProduct,categoryName:e.target.value})
        setBrandLivestockProduct({
            ...BrandLivestockProduct,
            categoryName: e.target.value,
        })
        getSubCategoryList(e.target.value)
        setcategoryName(e.target.value)
    }
    const getSubCategoryList = async (val) => {
        const { data } = await API.get(
            `/getProductSubCategory?type=1&title=ProductCatelog&catelogName=LiveStockCatelog&categoryName=` +
                val +
                `&id=` +
                Details._id
        )
        console.log('hkjjhj', data)
        setsubCategorylist(data?.result)
    }
    const handleCategoryChangeProduct = (e) => {
        setCommissionLivestockProduct({
            ...CommissionLivestockProduct,
            categoryName: e.target.value,
        })
        getSubCategoryList(e.target.value)
        setcategoryName(e.target.value)
    }

    const handlechangeproductBrand = (e) => {
        console.log(e.target.value, 'e')
        setCommissionLivestockProduct({
            ...CommissionLivestockProduct,
            subCategoryName: e.target.value,
        })
        getBrandList(e.target.value)
    }

    const getBrandList = async (val) => {
        const { data } = await API.get(
            `/getProductBrand?type=1&title=ProductCatelog&catelogName=LiveStockCatelog&categoryName=` +
                categoryName +
                '&subCategoryName=' +
                val +
                `&id=` +
                Details._id
        )
        console.log('hkjjhj', data)
        setbrandlist(data?.result)
    }

    const handleCategoryChangeVarient = (e) => {
        setLivestockVariantProduct({
            ...LivestockVariantProduct,
            categoryName: e.target.value,
        })
        getSubCategoryList(e.target.value)
        setcategoryName(e.target.value)
    }

    const handlechangeproductVarientBrand = (e) => {
        console.log(e.target.value, 'e')
        setLivestockVariantProduct({
            ...LivestockVariantProduct,
            subCategoryName: e.target.value,
        })
        getBrandListVarient(e.target.value)
        setsubcategoryName(e.target.value)
    }
    const getBrandListVarient = async (val) => {
        const { data } = await API.get(
            `/getProductBrand?type=1&title=ProductCatelog&catelogName=LiveStockCatelog&categoryName=` +
                categoryName +
                '&subCategoryName=' +
                val +
                `&id=` +
                Details._id
        )
        console.log('hkjjhj', data)
        setbrandlist(data?.result)
    }

    const handlechangeproductVarientProduct = (e) => {
        setLivestockVariantProduct({
            ...LivestockVariantProduct,
            brandName: e.target.value,
        })
        getProductListVarient(e.target.value)
    }
    const getProductListVarient = async (val) => {
        const { data } = await API.get(
            `/getProductCatelogProduct?type=7&title=ProductCatelog&catelogName=LiveStockCatelog&categoryName=` +
                categoryName +
                '&subCategoryName=' +
                subcategoryName +
                '&brandName=' +
                val +
                `&id=` +
                Details._id
        )
        console.log('hkjjhj', data)
        setProductlist(data?.result)
    }
    const handleView = () => {
        setShowCategory(true)
        getProductInput(Details._id)
    }

    const BrandUpload = () => {
        const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
            accept: 'image/',
            onDrop: (acceptedFiles) => {
                setDriverLicense(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )
                )
            },
        })

        return (
            <section className="container" style={baseStyle}>
                <div
                    {...getRootProps({ className: 'dropzone' })}
                    style={panImg}
                >
                    <input {...getInputProps()} multiple={false} />
                    {driverLicense.length > 0 ? (
                        <aside style={thumbsContainerState}>
                            <div style={thumb}>
                                <div style={thumbInner}>
                                    <img
                                        src={driverLicense[0].preview}
                                        style={img}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </aside>
                    ) : (
                        <p align="center">
                            Drop Image here or click to upload.
                        </p>
                    )}
                </div>
            </section>
        )
    }
    const handleUploadDL = (e) => {
        const doc = driverLicense[0]
        if (doc) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        setBrandLivestockProduct({
                            ...BrandLivestockProduct,
                            brandImage: url,
                        })
                    })
                }
            )
        }
    }

    // product

    const ProductUpload = () => {
        const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
            accept: 'image/',
            onDrop: (acceptedFiles) => {
                setDriverLicenses(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )
                )
            },
        })

        return (
            <section className="container" style={baseStyle}>
                <div
                    {...getRootProps({ className: 'dropzone' })}
                    style={panImg}
                >
                    <input {...getInputProps()} multiple={false} />
                    {driverLicenses.length > 0 ? (
                        <aside style={thumbsContainerState}>
                            <div style={thumb}>
                                <div style={thumbInner}>
                                    <img
                                        src={driverLicenses[0].preview}
                                        style={img}
                                        alt="product"
                                    />
                                </div>
                            </div>
                        </aside>
                    ) : (
                        <p align="center">
                            Drop Image here or click to upload.
                        </p>
                    )}
                </div>
            </section>
        )
    }
    const handleUploadDLs = (e) => {
        const doc = driverLicenses[0]
        if (doc) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        setCommissionLivestockProduct({
                            ...CommissionLivestockProduct,
                            productName: url,
                        })
                    })
                }
            )
        }
    }
    const handleUploadDP = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setDriverImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        setBrandLivestockProduct({
                            ...BrandLivestockProduct,
                            brandImage: url,
                        })
                    })
                }
            )
        }
    }
    const handleUploadIP = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setBrandImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        // setAgriMachine({ ...agriMachine, driverPhoto: url });
                        setCommissionLivestockProduct({
                            ...CommissionLivestockProduct,
                            productImage: url,
                        })
                    })
                }
            )
        }
    }
    const handleCloseclear = () => {
        // setAnchorEl(null);
        handleClose()
        setDriverImg(null)
        setBrandImg(null)
    }
    return (
        <>
            <div class="row align-items-center mb-3">
                <div class="col">
                    {createProduct ? (
                        <h3 class="page-title">Livestock Inventory</h3>
                    ) : (
                        <h3 class="page-title">Livestock Catalogue</h3>
                    )}
                </div>
                {createProduct ? (
                    <div class="col-auto">
                        <button
                            class="btn btn-primary filter-btn"
                            onClick={(e) => setCreateProduct(false)}
                        >
                            <i class="fas fa-left"></i> <span> Back</span>
                        </button>
                    </div>
                ) : (
                    <>
                        {!showCategory ? (
                            <>
                                <div className="col-auto">
                                    <button
                                        class="btn btn-primary filter-btn"
                                        id="fade-button"
                                        aria-controls="fade-menu"
                                        aria-haspopup="true"
                                        aria-expanded={
                                            open ? 'true' : undefined
                                        }
                                        onClick={handleClick}
                                    >
                                        <i class="fas fa-plus"></i>{' '}
                                        <span> Create </span>
                                    </button>
                                    <Menu
                                        id="fade-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'fade-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        TransitionComponent={Fade}
                                    >
                                        <MenuItem
                                            onClick={handleClose}
                                            id="openCategory"
                                        >
                                            Category
                                        </MenuItem>
                                        <MenuItem
                                            onClick={handleClose}
                                            id="openSubCategory"
                                        >
                                            SubCategory
                                        </MenuItem>
                                        <MenuItem
                                            onClick={handleCloseclear}
                                            id="openBrand"
                                        >
                                            Brand
                                        </MenuItem>
                                        <MenuItem
                                            onClick={handleCloseclear}
                                            id="openProduct"
                                        >
                                            Product
                                        </MenuItem>
                                        <MenuItem
                                            onClick={handleClose}
                                            id="openVariant"
                                        >
                                            Quantity & unit
                                        </MenuItem>
                                        <MenuItem
                                            onClick={(e) => {
                                                setCreateProduct(true)
                                                handleClose()
                                            }}
                                        >
                                            Inventory
                                        </MenuItem>
                                    </Menu>
                                </div>
                                <div class="col-auto">
                                    <button
                                        class="btn btn-primary filter-btn"
                                        onClick={() => handleView()}
                                        //  onClick={e=>setShowCategory(true)}
                                    >
                                        <i class="fas fa-eye"></i>{' '}
                                        <span> View Category</span>
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div class="col-auto">
                                <button
                                    class="btn btn-primary filter-btn"
                                    onClick={(e) => setShowCategory(false)}
                                >
                                    <i class="fas fa-left"></i>{' '}
                                    <span> Back</span>
                                </button>
                            </div>
                        )}
                    </>
                )}
            </div>

            {createProduct ? (
                <LivestockInventory />
            ) : (
                <>
                    {showCategory === false ? (
                        <>
                            {/* Add Category */}
                            <div id="category" class="card filter-card ">
                                <form
                                    class="card-body pb-0"
                                    onSubmit={(e) => e.preventDefault()}
                                >
                                    <div class="row">
                                        <h5 class="card-title">
                                            Create Category
                                        </h5>
                                        <div class="col-md-4 select2-container">
                                            <div
                                                class="form-group"
                                                data-select2-id="11"
                                            >
                                                <CssTextField
                                                    required
                                                    fullWidth
                                                    id="InpCategory"
                                                    label="Category"
                                                    variant="standard"
                                                    name="InpCategory"
                                                    value={
                                                        InputLivestockProduct.categoryName
                                                    }
                                                    onChange={(e) =>
                                                        setInputLivestockProduct(
                                                            {
                                                                ...InputLivestockProduct,
                                                                categoryName:
                                                                    e.target
                                                                        .value,
                                                            }
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div class="col-sm-2 col-md-2">
                                            <div class="form-group">
                                                <button
                                                    type="submit"
                                                    class="btn btn-primary mt-26"
                                                    onClick={() =>
                                                        handleInputLivestockProduct()
                                                    }
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/* Add SubCategory */}
                            <div id="subCategory" class="card filter-card">
                                <form
                                    class="card-body pb-0"
                                    onSubmit={(e) => e.preventDefault()}
                                >
                                    <div class="row">
                                        <h5 class="card-title">
                                            Create SubCategory
                                        </h5>
                                        <div class="col-md-4">
                                            <FormControl
                                                variant="standard"
                                                fullWidth
                                                className="mt-3"
                                            >
                                                <InputLabel
                                                    id="demo-simple-select-standard-label"
                                                    style={{ color: 'black' }}
                                                >
                                                    Category
                                                </InputLabel>
                                                <Select
                                                    required
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    //  value={age}
                                                    //  onChange={handleChange}
                                                    label="Category"
                                                    color="success"
                                                    value={
                                                        SubLivestockProduct.categoryName
                                                    }
                                                    //  onChange={(e)=>handleCategoryChange(e)}
                                                    onChange={(e) =>
                                                        setSubLivestockProduct({
                                                            ...SubLivestockProduct,
                                                            categoryName:
                                                                e.target.value,
                                                        })
                                                    }
                                                >
                                                    {/* <MenuItem value="">
                 <em>None</em>
               </MenuItem>
               <MenuItem value={"test"}>test</MenuItem>
               <MenuItem value={"Krishivan Input"}>Krishivan Input</MenuItem> */}
                                                    {Categorylist &&
                                                        Categorylist.map(
                                                            (val, ind) => {
                                                                // {console.log("Categorylist",val)}
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            val.categoryName
                                                                        }
                                                                    >
                                                                        {
                                                                            val.categoryName
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div class="col-md-4 select2-container">
                                            <div
                                                class="form-group"
                                                data-select2-id="11"
                                            >
                                                <CssTextField
                                                    required
                                                    fullWidth
                                                    id="InpSubCategory"
                                                    label="SubCategory"
                                                    variant="standard"
                                                    name="InpSubCategory"
                                                    value={
                                                        SubLivestockProduct.subCategoryName
                                                    }
                                                    onChange={(e) =>
                                                        setSubLivestockProduct({
                                                            ...SubLivestockProduct,
                                                            subCategoryName:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div class="col-sm-2 col-md-2">
                                            <div class="form-group">
                                                <button
                                                    type="submit"
                                                    class="btn btn-primary mt-26"
                                                    onClick={() =>
                                                        handleSubLivestockProduct()
                                                    }
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/* Create Brand */}
                            <div
                                id="brand"
                                class="card filter-card"
                                onSubmit={(e) => e.preventDefault()}
                            >
                                <form
                                    class="card-body pb-2"
                                    onSubmit={(e) => e.preventDefault()}
                                >
                                    <div className="mt-3">
                                        <h5 class="card-title">Create Brand</h5>
                                        <div class="row mb-4">
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Category
                                                    </InputLabel>
                                                    <Select
                                                        required
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="Firm"
                                                        color="success"
                                                        value={
                                                            BrandLivestockProduct.categoryName
                                                        }
                                                        onChange={(e) =>
                                                            handleCategoryChange(
                                                                e
                                                            )
                                                        }
                                                        // onChange={(e)=>setBrandLivestockProduct({...BrandLivestockProduct,categoryName:e.target.value})}
                                                    >
                                                        {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"General"}>General</MenuItem>
                    <MenuItem value={"OBC"}>OBC</MenuItem>
                    <MenuItem value={"SC"}>SC</MenuItem>
                    <MenuItem value={"ST"}>ST</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem> */}
                                                        {Categorylist &&
                                                            Categorylist.map(
                                                                (val, ind) => {
                                                                    // {console.log("Categorylist",val)}
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                val.categoryName
                                                                            }
                                                                        >
                                                                            {
                                                                                val.categoryName
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        SubCategory
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="Booking Mode"
                                                        color="success"
                                                        value={
                                                            BrandLivestockProduct.subCategoryName
                                                        }
                                                        onChange={(e) =>
                                                            setBrandLivestockProduct(
                                                                {
                                                                    ...BrandLivestockProduct,
                                                                    subCategoryName:
                                                                        e.target
                                                                            .value,
                                                                }
                                                            )
                                                        }
                                                    >
                                                        {/* <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"General"}>General</MenuItem>
                    <MenuItem value={"OBC"}>OBC</MenuItem>
                    <MenuItem value={"SC"}>SC</MenuItem>
                    <MenuItem value={"ST"}>ST</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem> */}
                                                        {subCategorylist &&
                                                            subCategorylist.map(
                                                                (val, ind) => {
                                                                    //  {console.log("Categorylist",val)}
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                val.subCategoryName
                                                                            }
                                                                        >
                                                                            {
                                                                                val.subCategoryName
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 mt-3">
                                                    <div
                                                        class="form-group row widget-3"
                                                        style={sectionDiv}
                                                    >
                                                        <section
                                                            className="container"
                                                            style={driverDP}
                                                        >
                                                            {driverImg ===
                                                            null ? (
                                                                <p align="center">
                                                                    Upload Image
                                                                </p>
                                                            ) : (
                                                                <aside
                                                                    style={
                                                                        thumbsContainerState
                                                                    }
                                                                >
                                                                    <div
                                                                        style={
                                                                            thumb
                                                                        }
                                                                    >
                                                                        <div
                                                                            style={
                                                                                thumbInner
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    driverImg
                                                                                }
                                                                                style={
                                                                                    img
                                                                                }
                                                                                alt="driverPhoto"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </aside>
                                                            )}
                                                        </section>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mt-3">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                            marginTop: '90px',
                                                        }}
                                                    >
                                                        {/* Product */}
                                                    </InputLabel>
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="brandName"
                                                        label="Brand Name"
                                                        variant="standard"
                                                        name="brandName"
                                                        value={
                                                            BrandLivestockProduct.brandName
                                                        }
                                                        onChange={(e) =>
                                                            setBrandLivestockProduct(
                                                                {
                                                                    ...BrandLivestockProduct,
                                                                    brandName:
                                                                        e.target
                                                                            .value,
                                                                }
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-12 mt-3 d-flex">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                            marginRight: '10px',
                                                        }}
                                                    >
                                                        Upload Brand Image:
                                                    </InputLabel>
                                                    <label htmlFor="contained-button-files">
                                                        <Button
                                                            variant="contained"
                                                            component="span"
                                                            color="success"
                                                            size="small"
                                                            style={uploadBtnSm}
                                                        >
                                                            <Input
                                                                accept="image/"
                                                                id="contained-button-files"
                                                                multiple="false"
                                                                type="file"
                                                                onChange={
                                                                    handleUploadDP
                                                                }
                                                            />
                                                            Upload
                                                        </Button>
                                                    </label>
                                                </div>
                                            </div>

                                            {/* <div class="col-md-3">
                 <CssTextField 
                    required
                    fullWidth 
                    id="brandName"
                    label="Brand Name" 
                    variant="standard"
                    name="brandName" value={BrandLivestockProduct.brandName}
                    onChange={(e)=>setBrandLivestockProduct({...BrandLivestockProduct, brandName:e.target.value})}
                  /> 
                 </div> */}
                                        </div>

                                        <div
                                            className="row justify-content-end mb-3"
                                            style={{ marginRight: '18%' }}
                                        >
                                            <div
                                                className="col-md-1"
                                                style={{ marginRight: '5%' }}
                                            >
                                                <button
                                                    type="submit"
                                                    className="btn btn-danger"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className="col-md-1">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    onClick={() =>
                                                        handleBrandLivestockProduct()
                                                    }
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/* Create Product */}
                            <div id="product" class="card filter-card">
                                <form
                                    class="card-body pb-2"
                                    onSubmit={(e) => e.preventDefault()}
                                >
                                    <div className="mt-3">
                                        <h5 class="card-title">
                                            Create Product
                                        </h5>
                                        <div class="row mb-4">
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Category
                                                    </InputLabel>
                                                    <Select
                                                        required
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="Firm"
                                                        color="success"
                                                        value={
                                                            CommissionLivestockProduct.categoryName
                                                        }
                                                        onChange={(e) =>
                                                            handleCategoryChangeProduct(
                                                                e
                                                            )
                                                        }
                                                        // onChange={(e)=>setCommissionLivestockProduct({...CommissionLivestockProduct, categoryName:e.target.value})}
                                                    >
                                                        {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"General"}>General</MenuItem>
                    <MenuItem value={"OBC"}>OBC</MenuItem>
                    <MenuItem value={"SC"}>SC</MenuItem>
                    <MenuItem value={"ST"}>ST</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem> */}
                                                        {Categorylist &&
                                                            Categorylist.map(
                                                                (val, ind) => {
                                                                    // {console.log("Categorylist",val)}
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                val.categoryName
                                                                            }
                                                                        >
                                                                            {
                                                                                val.categoryName
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        SubCategory
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="Booking Mode"
                                                        color="success"
                                                        value={
                                                            CommissionLivestockProduct.subCategoryName
                                                        }
                                                        onChange={(e) =>
                                                            handlechangeproductBrand(
                                                                e
                                                            )
                                                        }
                                                        // onChange={(e)=>setCommissionLivestockProduct({...CommissionLivestockProduct,subCategoryName:e.target.value})}
                                                    >
                                                        {/* <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"General"}>General</MenuItem>
                    <MenuItem value={"OBC"}>OBC</MenuItem>
                    <MenuItem value={"SC"}>SC</MenuItem>
                    <MenuItem value={"ST"}>ST</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem> */}
                                                        {subCategorylist &&
                                                            subCategorylist.map(
                                                                (val, ind) => {
                                                                    //  {console.log("Categorylist",val)}
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                val.subCategoryName
                                                                            }
                                                                        >
                                                                            {
                                                                                val.subCategoryName
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Brand Name
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="BRAND Name"
                                                        color="success"
                                                        value={
                                                            CommissionLivestockProduct.brandName
                                                        }
                                                        onChange={(e) =>
                                                            setCommissionLivestockProduct(
                                                                {
                                                                    ...CommissionLivestockProduct,
                                                                    brandName:
                                                                        e.target
                                                                            .value,
                                                                }
                                                            )
                                                        }
                                                    >
                                                        {/* <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"General"}>General</MenuItem>
                    <MenuItem value={"OBC"}>OBC</MenuItem>
                    <MenuItem value={"SC"}>SC</MenuItem>
                    <MenuItem value={"ST"}>ST</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem> */}
                                                        {brandlist &&
                                                            brandlist.map(
                                                                (val, ind) => {
                                                                    //  {console.log("Categorylist",val)}
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                val.brandName
                                                                            }
                                                                        >
                                                                            {
                                                                                val.brandName
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            {/* <div className="row">
                          <div className="col-md-12 mt-3 d-flex">
                            <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black', marginRight: '10px' }}>
                              Upload Product:
                            </InputLabel>
                            <Button variant="contained" color="success" size="small" style={uploadBtnSm} onClick={handleUploadDLs}>
                              Upload
                            </Button>
                          </div>
                          <div className="col-md-3 mt-3">
                            <div class="form-group row widget-3" style={sectionDiv}>
                              <ProductUpload />
                            </div>
                          </div>
                          <div className="col-md-3 mt-3">
                            <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black', marginTop: '90px' }}>
                            Product
                            </InputLabel>
                            <CssTextField 
                    required
                    fullWidth 
                    id="productName"
                    label="Product Name" 
                    variant="standard"
                    name="productName" value={CommissionLivestockProduct.productName}
                    onChange={(e)=>setCommissionLivestockProduct({...CommissionLivestockProduct, productName:e.target.value})}
                  /> 
                          </div>
                
               </div> */}
                                            {/* <div className="col-md-3 mt-3">
                  
                  <div class="form-group row widget-3" style={sectionDiv}>
                    <section className="container" style={driverDP}>
                      {driverImg === null ?
                        <p align="center">Upload Image</p>
                        :
                        <aside style={thumbsContainerState}>
                          <div style={thumb}>
                            <div style={thumbInner}>
                              <img src={driverImg} style={img} alt="driverPhoto" />
                            </div>
                          </div>
                        </aside>
                      }
                    </section>
                  </div>
                </div> */}
                                            <div className="row">
                                                <div className="col-md-3 mt-3">
                                                    <div
                                                        class="form-group row widget-3"
                                                        style={sectionDiv}
                                                    >
                                                        <section
                                                            className="container"
                                                            style={driverDP}
                                                        >
                                                            {BrandImg ===
                                                            null ? (
                                                                <p align="center">
                                                                    Upload Image
                                                                </p>
                                                            ) : (
                                                                <aside
                                                                    style={
                                                                        thumbsContainerState
                                                                    }
                                                                >
                                                                    <div
                                                                        style={
                                                                            thumb
                                                                        }
                                                                    >
                                                                        <div
                                                                            style={
                                                                                thumbInner
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    BrandImg
                                                                                }
                                                                                style={
                                                                                    img
                                                                                }
                                                                                alt="driverPhoto"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </aside>
                                                            )}
                                                        </section>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mt-3">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                            marginTop: '90px',
                                                        }}
                                                    >
                                                        {/* Product */}
                                                    </InputLabel>
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="productName"
                                                        label="Product Name"
                                                        variant="standard"
                                                        name="productName"
                                                        value={
                                                            CommissionLivestockProduct.productName
                                                        }
                                                        onChange={(e) =>
                                                            setCommissionLivestockProduct(
                                                                {
                                                                    ...CommissionLivestockProduct,
                                                                    productName:
                                                                        e.target
                                                                            .value,
                                                                }
                                                            )
                                                        }
                                                    />
                                                </div>
                                                {/* <div className="col-md-3 mt-3">
                  <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black', marginTop: '90px' }}>
                 
                  </InputLabel>
                  <CssTextField 
                    required
                    fullWidth 
                    id="variantName"
                    label="Quantity" 
                    variant="standard"
                    name="quantity"  
                    value={CommissionLivestockProduct.quantity}
                    onChange={(e)=>setCommissionLivestockProduct({...CommissionLivestockProduct, quantity:e.target.value})}
                  /> 
                </div> */}
                                                <div className="col-md-12 mt-3 d-flex">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                            marginRight: '10px',
                                                        }}
                                                    >
                                                        Upload Product Image:
                                                    </InputLabel>
                                                    <label htmlFor="contained-button-file">
                                                        <Button
                                                            variant="contained"
                                                            component="span"
                                                            color="success"
                                                            size="small"
                                                            style={uploadBtnSm}
                                                        >
                                                            <Input
                                                                accept="image/"
                                                                id="contained-button-file"
                                                                multiple="false"
                                                                type="file"
                                                                onChange={(e) =>
                                                                    handleUploadIP(
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                            Upload
                                                        </Button>
                                                    </label>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-3 mt-3">
                  <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black', marginTop: '90px' }}>
                  Product
                  </InputLabel>
                  <CssTextField 
                    required
                    fullWidth 
                    id="productName"
                    label="Product Name" 
                    variant="standard"
                    name="productName" value={CommissionLivestockProduct.productName}
                    onChange={(e)=>setCommissionLivestockProduct({...CommissionLivestockProduct, productName:e.target.value})}
                  /> 
                </div> */}

                                            <div
                                                className="row justify-content-end mb-3"
                                                style={{ marginRight: '18%' }}
                                            >
                                                <div
                                                    className="col-md-1"
                                                    style={{
                                                        marginRight: '5%',
                                                    }}
                                                >
                                                    <button
                                                        type="submit"
                                                        className="btn btn-danger"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                                <div className="col-md-1">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                        onClick={() =>
                                                            handleCommissionLivestockProduct()
                                                        }
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/* Quantity & unit */}
                            <div id="variant" class="card filter-card">
                                <form
                                    class="card-body pb-2"
                                    onSubmit={(e) => e.preventDefault()}
                                >
                                    <div className="mt-3">
                                        <h5 class="card-title">
                                            Quantity & unit
                                        </h5>
                                        <div class="row mb-4">
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Category
                                                    </InputLabel>
                                                    <Select
                                                        required
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="Firm"
                                                        color="success"
                                                        value={
                                                            LivestockVariantProduct.categoryName
                                                        }
                                                        onChange={(e) =>
                                                            handleCategoryChangeVarient(
                                                                e
                                                            )
                                                        }
                                                        // onChange={(e)=>setLivestockVariantProduct({...LivestockVariantProduct,categoryName:e.target.value})}
                                                    >
                                                        {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"General"}>General</MenuItem>
                    <MenuItem value={"OBC"}>OBC</MenuItem>
                    <MenuItem value={"SC"}>SC</MenuItem>
                    <MenuItem value={"ST"}>ST</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem> */}
                                                        {Categorylist &&
                                                            Categorylist.map(
                                                                (val, ind) => {
                                                                    // {console.log("Categorylist",val)}
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                val.categoryName
                                                                            }
                                                                        >
                                                                            {
                                                                                val.categoryName
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        SubCategory
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="Booking Mode"
                                                        color="success"
                                                        value={
                                                            LivestockVariantProduct.subCategoryName
                                                        }
                                                        onChange={(e) =>
                                                            handlechangeproductVarientBrand(
                                                                e
                                                            )
                                                        }
                                                        // onChange={(e)=>setLivestockVariantProduct({...LivestockVariantProduct,subCategoryName:e.target.value})}
                                                    >
                                                        {/* <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"General"}>General</MenuItem>
                    <MenuItem value={"OBC"}>OBC</MenuItem>
                    <MenuItem value={"SC"}>SC</MenuItem>
                    <MenuItem value={"ST"}>ST</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem> */}
                                                        {subCategorylist &&
                                                            subCategorylist.map(
                                                                (val, ind) => {
                                                                    // {console.log("Categorylist",val)}
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                val.subCategoryName
                                                                            }
                                                                        >
                                                                            {
                                                                                val.subCategoryName
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Brand Name
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="BRAND Name"
                                                        color="success"
                                                        value={
                                                            LivestockVariantProduct.brandName
                                                        }
                                                        onChange={(e) =>
                                                            handlechangeproductVarientProduct(
                                                                e
                                                            )
                                                        }
                                                        // onChange={(e)=>setLivestockVariantProduct({...LivestockVariantProduct,brandName:e.target.value})}
                                                    >
                                                        {/* <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"General"}>General</MenuItem>
                    <MenuItem value={"OBC"}>OBC</MenuItem>
                    <MenuItem value={"SC"}>SC</MenuItem>
                    <MenuItem value={"ST"}>ST</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem> */}
                                                        {brandlist &&
                                                            brandlist.map(
                                                                (val, ind) => {
                                                                    // {console.log("Categorylist",val)}
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                val.brandName
                                                                            }
                                                                        >
                                                                            {
                                                                                val.brandName
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Product Name
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="Product Name"
                                                        color="success"
                                                        value={
                                                            LivestockVariantProduct.productName
                                                        }
                                                        onChange={(e) =>
                                                            setLivestockVariantProduct(
                                                                {
                                                                    ...LivestockVariantProduct,
                                                                    productName:
                                                                        e.target
                                                                            .value,
                                                                }
                                                            )
                                                        }
                                                    >
                                                        {Productlist &&
                                                            Productlist.map(
                                                                (val, ind) => {
                                                                    // {console.log("Categorylist",val)}
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                val.productName
                                                                            }
                                                                        >
                                                                            {
                                                                                val.productName
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                        {/* <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"General"}>General</MenuItem>
                    <MenuItem value={"OBC"}>OBC</MenuItem>
                    <MenuItem value={"SC"}>SC</MenuItem>
                    <MenuItem value={"ST"}>ST</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem> */}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div class="col-md-3">
                                                <CssTextField
                                                    required
                                                    fullWidth
                                                    id="quantity"
                                                    label="Quantity"
                                                    variant="standard"
                                                    name="quantity"
                                                    value={
                                                        LivestockVariantProduct.quantity
                                                    }
                                                    onChange={(e) =>
                                                        setLivestockVariantProduct(
                                                            {
                                                                ...LivestockVariantProduct,
                                                                quantity:
                                                                    e.target
                                                                        .value,
                                                            }
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Unit
                                                    </InputLabel>
                                                    <Select
                                                        required
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="Unit"
                                                        color="success"
                                                        value={
                                                            LivestockVariantProduct.unit
                                                        }
                                                        onChange={(e) =>
                                                            setLivestockVariantProduct(
                                                                {
                                                                    ...LivestockVariantProduct,
                                                                    unit: e
                                                                        .target
                                                                        .value,
                                                                }
                                                            )
                                                        }

                                                        //  value={Invendory.unit}
                                                        //  onChange={(e)=>setInventory({...Invendory,unit:e.target.value})}
                                                    >
                                                        <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        <MenuItem value={'GM'}>
                                                            GM
                                                        </MenuItem>
                                                        <MenuItem value={'KG'}>
                                                            KG
                                                        </MenuItem>
                                                        <MenuItem value={'BAG'}>
                                                            BAG
                                                        </MenuItem>
                                                        {/* {
                          Unitlist&&Unitlist.map((val,ind)=>{
                           // {console.log("Categorylist",val)}
                             return(
                              <MenuItem value={val}>{val}</MenuItem>
                             )
                          })
                       } */}
                                                    </Select>
                                                </FormControl>

                                                {/* <CssTextField
                          required
                          fullWidth
                          id="unit"
                          label="unit"
                          variant="standard"
                          name="unit"
                          value={LivestockVariantProduct.unit}
                          onChange={(e) => setLivestockVariantProduct({ ...LivestockVariantProduct, unit: e.target.value })}
                        /> */}
                                            </div>
                                        </div>

                                        <div
                                            className="row justify-content-end mb-3"
                                            style={{ marginRight: '18%' }}
                                        >
                                            <div
                                                className="col-md-1"
                                                style={{ marginRight: '5%' }}
                                            >
                                                <button
                                                    type="submit"
                                                    className="btn btn-danger"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className="col-md-1">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    onClick={() =>
                                                        handleLivestockVariantProduct()
                                                    }
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <h3 className="mb-3">List</h3>
                                    <div class="card card-table">
                                        <div class="card-body">
                                            <div class="table-responsive">
                                                <div
                                                    id="DataTables_Table_0_wrapper"
                                                    class="dataTables_wrapper dt-bootstrap4 no-footer"
                                                >
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-6">
                                                            <div
                                                                class="dataTables_length"
                                                                id="DataTables_Table_0_length"
                                                            >
                                                                <label>
                                                                    Show
                                                                    <select
                                                                        name="DataTables_Table_0_length"
                                                                        aria-controls="DataTables_Table_0"
                                                                        class="custom-select custom-select-sm form-control form-control-sm"
                                                                    >
                                                                        <option value="10">
                                                                            10
                                                                        </option>
                                                                        <option value="25">
                                                                            25
                                                                        </option>
                                                                        <option value="50">
                                                                            50
                                                                        </option>
                                                                        <option value="100">
                                                                            100
                                                                        </option>
                                                                    </select>
                                                                    entries
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-3 position_right">
                                                            <div class="form-group">
                                                                {/* <label>Search</label> */}
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    placeholder="Search"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="row"
                                                            style={{
                                                                margin: '2%',
                                                            }}
                                                        >
                                                            <ProductLivestock
                                                                inputs={
                                                                    ProductInput
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-5">
                                                            <div
                                                                class="dataTables_info"
                                                                id="DataTables_Table_0_info"
                                                                role="status"
                                                                aria-live="polite"
                                                            >
                                                                Showing 1 to 10
                                                                of 12 entries
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-7">
                                                            <div
                                                                class="dataTables_paginate paging_simple_numbers"
                                                                id="DataTables_Table_0_paginate"
                                                            >
                                                                <ul class="pagination">
                                                                    <li
                                                                        class="paginate_button page-item previous disabled"
                                                                        id="DataTables_Table_0_previous"
                                                                    >
                                                                        <a
                                                                            href="#"
                                                                            aria-controls="DataTables_Table_0"
                                                                            data-dt-idx="0"
                                                                            tabindex="0"
                                                                            class="page-link"
                                                                        >
                                                                            Previous
                                                                        </a>
                                                                    </li>
                                                                    <li class="paginate_button page-item active">
                                                                        <a
                                                                            href="#"
                                                                            aria-controls="DataTables_Table_0"
                                                                            data-dt-idx="1"
                                                                            tabindex="0"
                                                                            class="page-link"
                                                                        >
                                                                            1
                                                                        </a>
                                                                    </li>
                                                                    <li class="paginate_button page-item ">
                                                                        <a
                                                                            href="#"
                                                                            aria-controls="DataTables_Table_0"
                                                                            data-dt-idx="2"
                                                                            tabindex="0"
                                                                            class="page-link"
                                                                        >
                                                                            2
                                                                        </a>
                                                                    </li>
                                                                    <li
                                                                        class="paginate_button page-item next"
                                                                        id="DataTables_Table_0_next"
                                                                    >
                                                                        <a
                                                                            href="#"
                                                                            aria-controls="DataTables_Table_0"
                                                                            data-dt-idx="3"
                                                                            tabindex="0"
                                                                            class="page-link"
                                                                        >
                                                                            Next
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="row">
    <div class="col-sm-12">
    <h3 className="mb-3">List</h3>
       <div class="card card-table">
          <div class="card-body">
             <div class="table-responsive">
                <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                   <div class="row">
                      <div class="col-sm-12 col-md-6">
                         <div class="dataTables_length" id="DataTables_Table_0_length">
                            <label>
                               Show 
                               <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" class="custom-select custom-select-sm form-control form-control-sm">
                                  <option value="10">10</option>
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                               </select>
                               entries
                            </label>
                         </div>
                      </div>
                      <div class="col-sm-12 col-md-3 position_right">
                         <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search" />
                         </div>                                                
                      </div>
                   </div>
                   <div class="row">
                      <div class="col-sm-12 col-md-5">
                         <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 1 to 10 of 12 entries</div>
                      </div>
                      <div class="col-sm-12 col-md-7">
                         <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                            <ul class="pagination">
                               <li class="paginate_button page-item previous disabled" id="DataTables_Table_0_previous"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li>
                               <li class="paginate_button page-item active"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0" class="page-link">1</a></li>
                               <li class="paginate_button page-item "><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="2" tabindex="0" class="page-link">2</a></li>
                               <li class="paginate_button page-item next" id="DataTables_Table_0_next"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="3" tabindex="0" class="page-link">Next</a></li>
                            </ul>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </div> */}
                        </>
                    ) : (
                        //   // <LsCategories />
                        <div class="row">
                            <div class="col-sm-12">
                                {/* <h3 className="mb-3">Livestock Products</h3> */}
                                <div class="card card-table">
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <div
                                                id="DataTables_Table_0_wrapper"
                                                class="dataTables_wrapper dt-bootstrap4 no-footer"
                                            >
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-6">
                                                        <div
                                                            class="dataTables_length"
                                                            id="DataTables_Table_0_length"
                                                        >
                                                            <label>
                                                                Show
                                                                <select
                                                                    name="DataTables_Table_0_length"
                                                                    aria-controls="DataTables_Table_0"
                                                                    class="custom-select custom-select-sm form-control form-control-sm"
                                                                >
                                                                    <option value="10">
                                                                        10
                                                                    </option>
                                                                    <option value="25">
                                                                        25
                                                                    </option>
                                                                    <option value="50">
                                                                        50
                                                                    </option>
                                                                    <option value="100">
                                                                        100
                                                                    </option>
                                                                </select>
                                                                entries
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-3 position_right">
                                                        <div class="form-group">
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                placeholder="Search"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row"
                                                    style={{ margin: '2%' }}
                                                >
                                                    <ProductLivestock
                                                        inputs={ProductInput}
                                                    />
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-5">
                                                        <div
                                                            class="dataTables_info"
                                                            id="DataTables_Table_0_info"
                                                            role="status"
                                                            aria-live="polite"
                                                        >
                                                            Showing 1 to 10 of
                                                            12 entries
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-7">
                                                        <div
                                                            class="dataTables_paginate paging_simple_numbers"
                                                            id="DataTables_Table_0_paginate"
                                                        >
                                                            <ul class="pagination">
                                                                <li
                                                                    class="paginate_button page-item previous disabled"
                                                                    id="DataTables_Table_0_previous"
                                                                >
                                                                    <a
                                                                        href="#"
                                                                        aria-controls="DataTables_Table_0"
                                                                        data-dt-idx="0"
                                                                        tabindex="0"
                                                                        class="page-link"
                                                                    >
                                                                        Previous
                                                                    </a>
                                                                </li>
                                                                <li class="paginate_button page-item active">
                                                                    <a
                                                                        href="#"
                                                                        aria-controls="DataTables_Table_0"
                                                                        data-dt-idx="1"
                                                                        tabindex="0"
                                                                        class="page-link"
                                                                    >
                                                                        1
                                                                    </a>
                                                                </li>
                                                                <li class="paginate_button page-item ">
                                                                    <a
                                                                        href="#"
                                                                        aria-controls="DataTables_Table_0"
                                                                        data-dt-idx="2"
                                                                        tabindex="0"
                                                                        class="page-link"
                                                                    >
                                                                        2
                                                                    </a>
                                                                </li>
                                                                <li
                                                                    class="paginate_button page-item next"
                                                                    id="DataTables_Table_0_next"
                                                                >
                                                                    <a
                                                                        href="#"
                                                                        aria-controls="DataTables_Table_0"
                                                                        data-dt-idx="3"
                                                                        tabindex="0"
                                                                        class="page-link"
                                                                    >
                                                                        Next
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    )
}
// const inputClear = {
//   title : "ProductCatelog",
// catelogName : "LiveStockCatelog",
// categoryName:""}
// const Subclear = {
//   title : "ProductCatelog",
// catelogName : "LiveStockCatelog",
// CategoryName:"",
// subCategoryName:""}
// const BrandClear = {
//   title : "ProductCatelog",
//   catelogName : "LiveStockCatelog",
//   categoryName:"",
//   subCategoryName :"",
//   brandName:"",
// image:""}

//   const ProductClear={title : "ProductCatelog",
//   catelogName : "LiveStockCatelog",
//   categoryName:"",
//   subCategoryName:"",
//   brandName:"",
//   productName:"",
//   quantity:"",
//   image:""
// }
// const VariantClear={title : "ProductCatelog",
// catelogName : "LiveStockCatelog",
// categoryName:"",
// subCategoryName:"",
// brandName:"",
// productName:"",
// variantName:""}
// const Input = styled('input')({
//   display: 'none',
//   marginBottom: '16px',
//   marginTop: '16px'
// });

// function LivestockCatelog() {
//     const [ showCategory, setShowCategory ] = useState(false);
//     const [ createProduct, setCreateProduct ] = useState(false);
//     const [anchorEl, setAnchorEl] = React.useState(null);
//     const [Categorylist,setCategorylist] =useState([])
//     const [subCategorylist,setsubCategorylist] =useState([])
//     const [brandlist,setbrandlist] =useState([])
//     const [categoryName,setcategoryName] =useState('')
//     const [subcategoryName,setsubcategoryName] =useState('')
//     const [Productlist,setProductlist] =useState([])
//     const [ProductInput,setProductInput ] = useState([])
//     const [driverImg, setDriverImg] = useState(null);
//   const [BrandImg,setBrandImg] =useState(null)
//      const open = Boolean(anchorEl);

//      useEffect(() => {
//       // getState();
//       getCategoryList();

//  getCategoryList();
//     }, []);
//     const getProductInput = async() => {
//       const { data } = await API.get(`/getProductCatelog?title=ProductCatelog&catelogName=LiveStockCatelog&type=1`);
//       setProductInput(data?.result);
//       }
//      const handleClick = (event) => {
//        setAnchorEl(event.currentTarget);
//      };
//      const handleClose = () => {
//        setAnchorEl(null);
//      };
//      const [InputLivestockProduct,setInputLivestockProduct] =useState({

//       title : "ProductCatelog",
//       catelogName : "LiveStockCatelog",
//       categoryName:""

//      })
//      const[SubLivestockProduct,setSubLivestockProduct] =useState({
//       title : "ProductCatelog",
//       catelogName : "LiveStockCatelog",
//       categoryName:"",
//       subCategoryName:""

//      })
//      const [driverLicense, setDriverLicense] = useState([]);
//      const[BrandLivestockProduct,setBrandLivestockProduct] =useState({
//       title : "ProductCatelog",
//       catelogName : "LiveStockCatelog",
//       categoryName:"",
//       subCategoryName :"",
//       brandName:"",
//       image:""

//      })
//      const [driverLicenses, setDriverLicenses] = useState([]);
//      const[CommissionLivestockProduct,setCommissionLivestockProduct] =useState({
//       title : "ProductCatelog",
//       catelogName : "LiveStockCatelog",
//       categoryName:"",
//       subCategoryName:"",
//       brandName:"",
//       productName:"",
//       quantity:"",
//       image:""

//      })
//      const [LivestockVariantProduct,setLivestockVariantProduct] =useState({

//       title : "ProductCatelog",
//       catelogName : "LiveStockCatelog",
//       categoryName:"",
//       subCategoryName:"",
//       brandName:"",
//       productName:"",
//       variantName:""

//      })
//      const handleInputLivestockProduct =async()=>{
//       console.log("InputLivestockProduct",InputLivestockProduct)

//       try{
//       const { data } = await API.post('/createCategory ', InputLivestockProduct);
//       if(data?.success){
//        Swal.fire({
//             icon: 'success',
//             iconColor: '#28a745',
//             title: data?.message,
//             showConfirmButton: false,
//             timer: 2000
//           });
//        console.log(data);
//        getCategoryList()
//        setInputLivestockProduct(inputClear)
//       } else {
//          setInputLivestockProduct(inputClear)
//        Swal.fire({
//          icon: 'warning',
//          iconColor: '#dc3545',
//          title: data?.message,
//          showConfirmButton: false,
//          timer: 2000
//          });
//       }
//      }
//      catch(err){
//       setInputLivestockProduct(inputClear)
//        Swal.fire({
//          icon: 'warning',
//          iconColor: '#dc3545',
//          title: err.message,
//          showConfirmButton: false,
//          timer: 2000
//          });
//       }

//    }
//    const  handleSubLivestockProduct =async()=>{
//     console.log("SubLivestockProduct ",SubLivestockProduct )

//     try{
//     const { data } = await API.post('/createSubCategory ',SubLivestockProduct );
//     if(data?.success){
//      Swal.fire({
//           icon: 'success',
//           iconColor: '#28a745',
//           title: data?.message,
//           showConfirmButton: false,
//           timer: 2000
//         });
//      console.log(data);

//      setSubLivestockProduct (Subclear)
//     } else {
//        setSubLivestockProduct (Subclear)
//      Swal.fire({
//        icon: 'warning',
//        iconColor: '#dc3545',
//        title: data?.message,
//        showConfirmButton: false,
//        timer: 2000
//        });
//     }
//    }
//    catch(err){
//     setSubLivestockProduct (Subclear)
//      Swal.fire({
//        icon: 'warning',
//        iconColor: '#dc3545',
//        title: err.message,
//        showConfirmButton: false,
//        timer: 2000
//        });
//     }

//  }
//  const handleBrandLivestockProduct =async()=>{
//   console.log("BrandLivestockProduct",BrandLivestockProduct)

//   try{
//   const { data } = await API.post('/createBrand',BrandLivestockProduct);
//   if(data?.success){
//    Swal.fire({
//         icon: 'success',
//         iconColor: '#28a745',
//         title: data?.message,
//         showConfirmButton: false,
//         timer: 2000
//       });
//    console.log(data);
//    setBrandImg(null);
//    setDriverImg(null);
//    setBrandLivestockProduct(BrandClear)
//   } else {
//      setBrandLivestockProduct(BrandClear)
//    Swal.fire({
//      icon: 'warning',
//      iconColor: '#dc3545',
//      title: data?.message,
//      showConfirmButton: false,
//      timer: 2000
//      });
//   }
//  }
//  catch(err){
//   setBrandLivestockProduct(BrandClear)
//    Swal.fire({
//      icon: 'warning',
//      iconColor: '#dc3545',
//      title: err.message,
//      showConfirmButton: false,
//      timer: 2000
//      });
//   }

// }
// const handleCommissionLivestockProduct =async()=>{
//   console.log("CommissionLivestockProduct",CommissionLivestockProduct)

//   try{
//   const { data } = await API.post('/createProduct ', CommissionLivestockProduct);
//   if(data?.success){
//    Swal.fire({
//         icon: 'success',
//         iconColor: '#28a745',
//         title: data?.message,
//         showConfirmButton: false,
//         timer: 2000
//       });
//    console.log(data);
//    setBrandImg(null);
//    setDriverImg(null);
//    setCommissionLivestockProduct(ProductClear)
//   } else {
//      setCommissionLivestockProduct(ProductClear)
//    Swal.fire({
//      icon: 'warning',
//      iconColor: '#dc3545',
//      title: data?.message,
//      showConfirmButton: false,
//      timer: 2000
//      });
//   }
//  }
//  catch(err){
//   setCommissionLivestockProduct(ProductClear)
//    Swal.fire({
//      icon: 'warning',
//      iconColor: '#dc3545',
//      title: err.message,
//      showConfirmButton: false,
//      timer: 2000
//      });
//   }

// }
// const handleLivestockVariantProduct =async()=>{
//   console.log("LivestockVariantProduct",LivestockVariantProduct)

//   try{
//   const { data } = await API.post('/createVariant', LivestockVariantProduct);
//   if(data?.success){
//    Swal.fire({
//         icon: 'success',
//         iconColor: '#28a745',
//         title: data?.message,
//         showConfirmButton: false,
//         timer: 2000
//       });
//    console.log(data);

//    setLivestockVariantProduct(VariantClear)
//   } else {
//      setLivestockVariantProduct(VariantClear)
//    Swal.fire({
//      icon: 'warning',
//      iconColor: '#dc3545',
//      title: data?.message,
//      showConfirmButton: false,
//      timer: 2000
//      });
//   }
//  }
//  catch(err){
//   setLivestockVariantProduct(VariantClear)
//    Swal.fire({
//      icon: 'warning',
//      iconColor: '#dc3545',
//      title: err.message,
//      showConfirmButton: false,
//      timer: 2000
//      });
//   }

// }

// const getCategoryList = async() => {
//   const { data } = await API.get(`/getCategory?title=ProductCatelog&catelogName=LiveStockCatelog`);
//   // console.log("hkjjhj",data)
//   setCategorylist(data?.result);
// }
// const handleCategoryChange =(e)=>{
//   // setSubLivestockProduct({...SubLivestockProduct,categoryName:e.target.value})
//   setBrandLivestockProduct({...BrandLivestockProduct,categoryName:e.target.value})
//   getSubCategoryList(e.target.value)
//   setcategoryName(e.target.value)
// }
// const getSubCategoryList = async(val) => {
//   const { data } = await API.get(`/getSubCategory?title=ProductCatelog&catelogName=LiveStockCatelog&categoryName=`+val);
//   console.log("hkjjhj",data)
//   setsubCategorylist(data?.result);
// }
// const handleCategoryChangeProduct =(e)=>{
// setCommissionLivestockProduct({...CommissionLivestockProduct, categoryName:e.target.value})
// getSubCategoryList(e.target.value)
// setcategoryName(e.target.value)
// }

// const handlechangeproductBrand =(e)=>{
//   console.log(e.target.value,"e")
//   setCommissionLivestockProduct({...CommissionLivestockProduct,subCategoryName:e.target.value})
//   getBrandList(e.target.value)
// }

// const getBrandList = async(val) => {
//   const { data } = await API.get(`/getBrand?title=ProductCatelog&catelogName=LiveStockCatelog&categoryName=`+categoryName+'&subCategoryName='+val);
//   console.log("hkjjhj",data)
//   setbrandlist(data?.result);
// }

// const handleCategoryChangeVarient =(e)=>{
//   setLivestockVariantProduct({...LivestockVariantProduct,categoryName:e.target.value})
//   getSubCategoryList(e.target.value)
//   setcategoryName(e.target.value)
//   }

//   const handlechangeproductVarientBrand =(e)=>{
//     console.log(e.target.value,"e")
//     setLivestockVariantProduct({...LivestockVariantProduct,subCategoryName:e.target.value})
//     getBrandListVarient(e.target.value)
//     setsubcategoryName(e.target.value)
//   }
//   const getBrandListVarient = async(val) => {
//     const { data } = await API.get(`/getBrand?title=ProductCatelog&catelogName=LiveStockCatelog&categoryName=`+categoryName+'&subCategoryName='+val);
//     console.log("hkjjhj",data)
//     setbrandlist(data?.result);
//   }

//   const handlechangeproductVarientProduct =(e)=>{
//     setLivestockVariantProduct({...LivestockVariantProduct,brandName:e.target.value})
//     getProductListVarient(e.target.value)
//   }
//   const getProductListVarient =async(val) =>{
//     const { data } = await API.get(`/getProduct?title=ProductCatelog&catelogName=LiveStockCatelog&categoryName=`+categoryName+'&subCategoryName='+subcategoryName+'&brandName='+val);
//     console.log("hkjjhj",data)
//     setProductlist(data?.result);
//   }
//   const handleView =() =>{
//     setShowCategory(true)
//     getProductInput()
//   }

//   const BrandUpload = () => {
//     const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
//       accept: "image/",
//       onDrop: (acceptedFiles) => {
//         setDriverLicense(
//           acceptedFiles.map((file) =>
//             Object.assign(file, {
//               preview: URL.createObjectURL(file)
//             }))
//         );
//       }
//     });

//     return (
//       <section className="container" style={baseStyle}>
//         <div {...getRootProps({ className: 'dropzone' })} style={panImg}>
//           <input {...getInputProps()} multiple={false} />
//           {driverLicense.length > 0 ?
//             <aside style={thumbsContainerState}>
//               <div style={thumb}>
//                 <div style={thumbInner}>
//                   <img src={driverLicense[0].preview} style={img} alt="" />
//                 </div>
//               </div>
//             </aside>
//             :
//             <p align="center">Drop Image  here or click to upload.</p>
//           }
//         </div>
//       </section>
//     )
//   }
//   const handleUploadDL = (e) => {
//     const doc = driverLicense[0];
//     if (doc) {
//       const storageRef = ref(storage, `driverPhotos/${doc.name}`);
//       const uploadTask = uploadBytesResumable(storageRef, doc);
//       uploadTask.on('state_changed',
//         (snapshot) => {
//         },
//         (error) => {
//           console.log(error);
//         },
//         () => {
//           getDownloadURL(storageRef).then((url) => {
//             setBrandLivestockProduct({ ...BrandLivestockProduct, brandName: url });
//           });
//         }
//       );
//     }
//   };

// // product

// const ProductUpload = () => {
//   const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
//     accept: "image/",
//     onDrop: (acceptedFiles) => {
//       setDriverLicenses(
//         acceptedFiles.map((file) =>
//           Object.assign(file, {
//             preview: URL.createObjectURL(file)
//           }))
//       );
//     }
//   });

//   return (
//     <section className="container" style={baseStyle}>
//       <div {...getRootProps({ className: 'dropzone' })} style={panImg}>
//         <input {...getInputProps()} multiple={false} />
//         {driverLicenses.length > 0 ?
//           <aside style={thumbsContainerState}>
//             <div style={thumb}>
//               <div style={thumbInner}>
//                 <img src={driverLicenses[0].preview} style={img} alt="product" />
//               </div>
//             </div>
//           </aside>
//           :
//           <p align="center">Drop Image  here or click to upload.</p>
//         }
//       </div>
//     </section>
//   )
// }
// const handleUploadDLs = (e) => {
//   const doc = driverLicenses[0];
//   if (doc) {
//     const storageRef = ref(storage, `driverPhotos/${doc.name}`);
//     const uploadTask = uploadBytesResumable(storageRef, doc);
//     uploadTask.on('state_changed',
//       (snapshot) => {
//       },
//       (error) => {
//         console.log(error);
//       },
//       () => {
//         getDownloadURL(storageRef).then((url) => {
//          setCommissionLivestockProduct({ ...CommissionLivestockProduct, productName: url });
//         });
//       }
//     );
//   }
// };
// const handleUploadDP = (e) => {
//   console.log("e.target.files[0]",e.target.files[0])
//   setDriverImg(URL.createObjectURL(e.target.files[0]));
//   const doc = e.target.files[0];
//   if (e.target.files[0]) {
//     const storageRef = ref(storage, `driverPhotos/${doc.name}`);
//     const uploadTask = uploadBytesResumable(storageRef, doc);
//     uploadTask.on('state_changed',
//       (snapshot) => {
//       },
//       (error) => {
//         console.log(error);
//       },
//       () => {
//         getDownloadURL(storageRef).then((url) => {
//           setBrandLivestockProduct({ ...BrandLivestockProduct, image: url });
//         });
//       }
//     );
//   }
// };
// const handleUploadIP = (e) => {
//   console.log("e.target.files[0]",e.target.files[0])
//   setBrandImg(URL.createObjectURL(e.target.files[0]));
//   const doc = e.target.files[0];
//   if (e.target.files[0]) {
//     const storageRef = ref(storage, `driverPhotos/${doc.name}`);
//     const uploadTask = uploadBytesResumable(storageRef, doc);
//     uploadTask.on('state_changed',
//       (snapshot) => {
//       },
//       (error) => {
//         console.log(error);
//       },
//       () => {
//         getDownloadURL(storageRef).then((url) => {
//           // setAgriMachine({ ...agriMachine, driverPhoto: url });
//           setCommissionLivestockProduct({ ...CommissionLivestockProduct, image: url });
//         });
//       }
//     );
//   }
// };
// const handleCloseclear = () => {
//   // setAnchorEl(null);
//   handleClose()
//   setDriverImg(null)
//   setBrandImg(null)
// };
//     return (
//       <>
//       <div class="row align-items-center mb-3">
//       <div class="col">
//          {createProduct ?
//          <h3 class="page-title">Livestock Inventory</h3>
//          :
//          <h3 class="page-title">Livestock Catalogue</h3>
//          }
//       </div>
//       {createProduct ?
//        <div class="col-auto">
//        <button class="btn btn-primary filter-btn" onClick={e=>setCreateProduct(false)}>
//           <i class="fas fa-left"></i> <span>  Back</span>
//        </button>
//        </div>
//       :
//       <>
//        {!showCategory ?
//       <>
//        <div className="col-auto">
//       <button class="btn btn-primary filter-btn"
//         id="fade-button"
//         aria-controls="fade-menu"
//         aria-haspopup="true"
//         aria-expanded={open ? 'true' : undefined}
//         onClick={handleClick}
//       >
//         <i class="fas fa-plus"></i> <span>  Create </span>
//       </button>
//       <Menu
//         id="fade-menu"
//         MenuListProps={{
//           'aria-labelledby': 'fade-button',
//         }}
//         anchorEl={anchorEl}
//         open={open}
//         onClose={handleClose}
//         TransitionComponent={Fade}
//       >
//         <MenuItem onClick={handleClose} id="openCategory">Category</MenuItem>
//         <MenuItem onClick={handleClose} id="openSubCategory">SubCategory</MenuItem>
//         <MenuItem onClick={handleCloseclear} id="openBrand">Brand</MenuItem>
//         <MenuItem onClick={handleCloseclear} id="openProduct">Product</MenuItem>
//         {/* <MenuItem onClick={handleClose} id="openVariant">Variant</MenuItem> */}
//         <MenuItem onClick={(e)=>{setCreateProduct(true); handleClose()}}>Inventory</MenuItem>
//       </Menu>
//     </div>
//     <div class="col-auto">
//          <button class="btn btn-primary filter-btn" onClick={()=>handleView()}
//         //  onClick={e=>setShowCategory(true)}
//          >
//             <i class="fas fa-eye"></i> <span>  View Category</span>
//          </button>
//       </div>
//       </>
//       :
//       <div class="col-auto">
//          <button class="btn btn-primary filter-btn" onClick={e=>setShowCategory(false)}>
//             <i class="fas fa-left"></i> <span>  Back</span>
//          </button>
//       </div>
//       }
//       </>
//       }
//    </div>

//    {createProduct ?
//      <LivestockInventory />
//      :
//      <>
//      {showCategory === false ?
//       <>
//                               {/* Add Category */}
//        <div id="category" class="card filter-card ">
//         <form class="card-body pb-0" onSubmit={(e)=>e.preventDefault()} >
//          <div class="row">
//          <h5 class="card-title">Create Category</h5>
//           <div class="col-md-4 select2-container">
//             <div class="form-group" data-select2-id="11">
//             <CssTextField
//               required
//               fullWidth
//               id="InpCategory"
//               label="Category"
//               variant="standard"
//               name="InpCategory" value={InputLivestockProduct.categoryName}
//               onChange={(e)=>setInputLivestockProduct({...InputLivestockProduct,categoryName:e.target.value})}
//             />
//             </div>
//                </div>
//            <div class="col-sm-2 col-md-2">
//                <div class="form-group">
//                  <button type="submit" class="btn btn-primary mt-26" onClick={()=> handleInputLivestockProduct()}>Submit</button>
//                </div>
//             </div>
//            </div>
//          </form>
//        </div>
//                                  {/* Add SubCategory */}
//        <div id="subCategory" class="card filter-card">
//         <form class="card-body pb-0" onSubmit={(e)=>e.preventDefault()}>
//          <div class="row">
//          <h5 class="card-title">Create SubCategory</h5>
//          <div class="col-md-4">
//            <FormControl variant="standard" fullWidth className="mt-3">
//              <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>Category</InputLabel>
//              <Select
//                required
//                labelId="demo-simple-select-standard-label"
//                id="demo-simple-select-standard"
//               //  value={age}
//               //  onChange={handleChange}
//                label="Category"
//                color="success" value={SubLivestockProduct. categoryName}

//               //  onChange={(e)=>handleCategoryChange(e)}
//                onChange={(e)=>setSubLivestockProduct({...SubLivestockProduct,categoryName:e.target.value})}
//              >
//                {/* <MenuItem value="">
//                  <em>None</em>
//                </MenuItem>
//                <MenuItem value={"test"}>test</MenuItem>
//                <MenuItem value={"Krishivan Input"}>Krishivan Input</MenuItem> */}
//                 {
//                           Categorylist&&Categorylist.map((val,ind)=>{
//                            // {console.log("Categorylist",val)}
//                              return(
//                               <MenuItem value={val}>{val}</MenuItem>
//                              )
//                           })
//                        }
//              </Select>
//            </FormControl>
//           </div>
//           <div class="col-md-4 select2-container">
//             <div class="form-group" data-select2-id="11">
//             <CssTextField
//               required
//               fullWidth
//               id="InpSubCategory"
//               label="SubCategory"
//               variant="standard"
//               name="InpSubCategory" value={SubLivestockProduct.subCategoryName}
//               onChange={(e)=>setSubLivestockProduct({...SubLivestockProduct,subCategoryName:e.target.value})}
//             />
//             </div>
//                </div>
//            <div class="col-sm-2 col-md-2">
//                <div class="form-group">
//                  <button type="submit" class="btn btn-primary mt-26" onClick={()=>handleSubLivestockProduct()}>Submit</button>
//                </div>
//             </div>
//            </div>
//          </form>
//        </div>
//                                         {/* Create Brand */}
//        <div id="brand" class="card filter-card" onSubmit={(e)=>e.preventDefault()}>
//        <form class="card-body pb-2"  onSubmit={(e)=>e.preventDefault()}>
//            <div className="mt-3">
//                <h5 class="card-title">Create Brand</h5>
//              <div class="row mb-4">
//                <div class="col-md-3">
//                  <FormControl variant="standard" fullWidth className="mt-3">
//                   <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>Category</InputLabel>
//                   <Select
//                     required
//                     labelId="demo-simple-select-standard-label"
//                     id="demo-simple-select-standard"
//                    //  value={age}
//                    //  onChange={handleChange}
//                     label="Firm"
//                     color="success" value={BrandLivestockProduct.categoryName}
//                     onChange={(e)=>handleCategoryChange(e)}
//                     // onChange={(e)=>setBrandLivestockProduct({...BrandLivestockProduct,categoryName:e.target.value})}
//                   >
//                     {/* <MenuItem value="">
//                       <em>None</em>
//                     </MenuItem>
//                     <MenuItem value={"General"}>General</MenuItem>
//                     <MenuItem value={"OBC"}>OBC</MenuItem>
//                     <MenuItem value={"SC"}>SC</MenuItem>
//                     <MenuItem value={"ST"}>ST</MenuItem>
//                     <MenuItem value={"Others"}>Others</MenuItem> */}
//                      {
//                           Categorylist&&Categorylist.map((val,ind)=>{
//                            // {console.log("Categorylist",val)}
//                              return(
//                               <MenuItem value={val}>{val}</MenuItem>
//                              )
//                           })
//                        }
//                    </Select>
//                  </FormControl>
//                  </div>
//                  <div class="col-md-3">
//                    <FormControl variant="standard" fullWidth className="mt-3">
//                      <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>SubCategory</InputLabel>
//                       <Select
//                         labelId="demo-simple-select-standard-label"
//                         id="demo-simple-select-standard"
//                        //  value={age}
//                        //  onChange={handleChange}
//                         label="Booking Mode"
//                         color="success" value={BrandLivestockProduct.subCategoryName}
//                         onChange={(e)=>setBrandLivestockProduct({...BrandLivestockProduct,subCategoryName:e.target.value})}
//                       >
//                         {/* <MenuItem value="">
//                           <em>None</em>
//                         </MenuItem>
//                         <MenuItem value={"General"}>General</MenuItem>
//                     <MenuItem value={"OBC"}>OBC</MenuItem>
//                     <MenuItem value={"SC"}>SC</MenuItem>
//                     <MenuItem value={"ST"}>ST</MenuItem>
//                     <MenuItem value={"Others"}>Others</MenuItem> */}
//                      {
//                           subCategorylist&&subCategorylist.map((val,ind)=>{
//                           //  {console.log("Categorylist",val)}
//                              return(
//                               <MenuItem value={val}>{val}</MenuItem>
//                              )
//                           })
//                        }
//                       </Select>
//                     </FormControl>
//                  </div>
//                  <div className="row">

//                 <div className="col-md-3 mt-3">

//                   <div class="form-group row widget-3" style={sectionDiv}>
//                     <section className="container" style={driverDP}>
//                       {driverImg === null ?
//                         <p align="center">Upload Image</p>
//                         :
//                         <aside style={thumbsContainerState}>
//                           <div style={thumb}>
//                             <div style={thumbInner}>
//                               <img src={driverImg} style={img} alt="driverPhoto" />
//                             </div>
//                           </div>
//                         </aside>
//                       }
//                     </section>
//                   </div>
//                 </div>
//                 <div className="col-md-3 mt-3">
//                   <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black', marginTop: '90px' }}>
//                   {/* Product */}
//                   </InputLabel>
//                   <CssTextField
//                     required
//                     fullWidth
//                     id="brandName"
//                     label="Brand Name"
//                     variant="standard"
//                     name="brandName" value={BrandLivestockProduct.brandName}
//                     onChange={(e)=>setBrandLivestockProduct({...BrandLivestockProduct, brandName:e.target.value})}
//                   />
//                 </div>
//                 <div className="col-md-12 mt-3 d-flex">
//                   <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black', marginRight: '10px' }}>
//                     Upload Brand Image:
//                   </InputLabel>
//                   <label htmlFor="contained-button-files">
//                     <Button variant="contained" component="span" color="success" size="small" style={uploadBtnSm}>
//                       <Input accept="image/" id="contained-button-files" multiple="false" type="file" onChange={handleUploadDP} />
//                       Upload
//                     </Button>
//                   </label>
//                 </div>
//               </div>

//                  {/* <div class="col-md-3">
//                  <CssTextField
//                     required
//                     fullWidth
//                     id="brandName"
//                     label="Brand Name"
//                     variant="standard"
//                     name="brandName" value={BrandLivestockProduct.brandName}
//                     onChange={(e)=>setBrandLivestockProduct({...BrandLivestockProduct, brandName:e.target.value})}
//                   />
//                  </div> */}
//                </div>

//                <div className="row justify-content-end mb-3">
//                <div className="col-md-1" style={{marginRight:'3%'}}>
//                  <button type="submit" className="btn btn-danger">Cancel</button>
//                </div>
//                <div className="col-md-1" style={{marginRight:'3%'}}>
//                  <button type="submit" className="btn btn-primary" onClick={()=>handleBrandLivestockProduct()}>Submit</button>
//                </div>
//                </div>

//              </div>
//           </form>
//        </div>
//                                           {/* Create Product */}
//        <div id="product" class="card filter-card">
//        <form class="card-body pb-2" onSubmit={(e)=>e.preventDefault()}>
//            <div className="mt-3">
//                <h5 class="card-title">Create Product</h5>
//                <div class="row mb-4">
//                <div class="col-md-3">
//                  <FormControl variant="standard" fullWidth className="mt-3">
//                   <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>Category</InputLabel>
//                   <Select
//                     required
//                     labelId="demo-simple-select-standard-label"
//                     id="demo-simple-select-standard"
//                    //  value={age}
//                    //  onChange={handleChange}
//                     label="Firm"
//                     color="success" value={CommissionLivestockProduct.categoryName}
//                     onChange={(e)=>handleCategoryChangeProduct(e)}
//                     // onChange={(e)=>setCommissionLivestockProduct({...CommissionLivestockProduct, categoryName:e.target.value})}
//                   >
//                     {/* <MenuItem value="">
//                       <em>None</em>
//                     </MenuItem>
//                     <MenuItem value={"General"}>General</MenuItem>
//                     <MenuItem value={"OBC"}>OBC</MenuItem>
//                     <MenuItem value={"SC"}>SC</MenuItem>
//                     <MenuItem value={"ST"}>ST</MenuItem>
//                     <MenuItem value={"Others"}>Others</MenuItem> */}
//                     {
//                           Categorylist&&Categorylist.map((val,ind)=>{
//                            // {console.log("Categorylist",val)}
//                              return(
//                               <MenuItem value={val}>{val}</MenuItem>
//                              )
//                           })
//                        }
//                    </Select>
//                  </FormControl>
//                  </div>
//                  <div class="col-md-3">
//                    <FormControl variant="standard" fullWidth className="mt-3">
//                      <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>SubCategory</InputLabel>
//                       <Select
//                         labelId="demo-simple-select-standard-label"
//                         id="demo-simple-select-standard"
//                        //  value={age}
//                        //  onChange={handleChange}
//                         label="Booking Mode"
//                         color="success"  value={CommissionLivestockProduct. subCategoryName}
//                         onChange={(e)=>handlechangeproductBrand(e)}
//                         // onChange={(e)=>setCommissionLivestockProduct({...CommissionLivestockProduct,subCategoryName:e.target.value})}
//                       >
//                         {/* <MenuItem value="">
//                           <em>None</em>
//                         </MenuItem>
//                         <MenuItem value={"General"}>General</MenuItem>
//                     <MenuItem value={"OBC"}>OBC</MenuItem>
//                     <MenuItem value={"SC"}>SC</MenuItem>
//                     <MenuItem value={"ST"}>ST</MenuItem>
//                     <MenuItem value={"Others"}>Others</MenuItem> */}
//                     {
//                           subCategorylist&&subCategorylist.map((val,ind)=>{
//                           //  {console.log("Categorylist",val)}
//                              return(
//                               <MenuItem value={val}>{val}</MenuItem>
//                              )
//                           })
//                        }
//                       </Select>
//                     </FormControl>
//                  </div>
//                  <div class="col-md-3">
//                    <FormControl variant="standard" fullWidth className="mt-3">
//                      <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>Brand Name</InputLabel>
//                       <Select
//                         labelId="demo-simple-select-standard-label"
//                         id="demo-simple-select-standard"
//                        //  value={age}
//                        //  onChange={handleChange}
//                         label="BRAND Name"
//                         color="success" value={CommissionLivestockProduct.brandName}
//                         onChange={(e)=>setCommissionLivestockProduct({...CommissionLivestockProduct, brandName:e.target.value})}
//                       >
//                         {/* <MenuItem value="">
//                           <em>None</em>
//                         </MenuItem>
//                         <MenuItem value={"General"}>General</MenuItem>
//                     <MenuItem value={"OBC"}>OBC</MenuItem>
//                     <MenuItem value={"SC"}>SC</MenuItem>
//                     <MenuItem value={"ST"}>ST</MenuItem>
//                     <MenuItem value={"Others"}>Others</MenuItem> */}
//                      {
//                           brandlist&&brandlist.map((val,ind)=>{
//                           //  {console.log("Categorylist",val)}
//                              return(
//                               <MenuItem value={val}>{val}</MenuItem>
//                              )
//                           })
//                        }
//                       </Select>
//                     </FormControl>
//                  </div>
//                  {/* <div className="row">
//                           <div className="col-md-12 mt-3 d-flex">
//                             <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black', marginRight: '10px' }}>
//                               Upload Product:
//                             </InputLabel>
//                             <Button variant="contained" color="success" size="small" style={uploadBtnSm} onClick={handleUploadDLs}>
//                               Upload
//                             </Button>
//                           </div>
//                           <div className="col-md-3 mt-3">
//                             <div class="form-group row widget-3" style={sectionDiv}>
//                               <ProductUpload />
//                             </div>
//                           </div>
//                           <div className="col-md-3 mt-3">
//                             <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black', marginTop: '90px' }}>
//                             Product
//                             </InputLabel>
//                             <CssTextField
//                     required
//                     fullWidth
//                     id="productName"
//                     label="Product Name"
//                     variant="standard"
//                     name="productName" value={CommissionLivestockProduct.productName}
//                     onChange={(e)=>setCommissionLivestockProduct({...CommissionLivestockProduct, productName:e.target.value})}
//                   />
//                           </div>

//                </div> */}
//                 {/* <div className="col-md-3 mt-3">

//                   <div class="form-group row widget-3" style={sectionDiv}>
//                     <section className="container" style={driverDP}>
//                       {driverImg === null ?
//                         <p align="center">Upload Image</p>
//                         :
//                         <aside style={thumbsContainerState}>
//                           <div style={thumb}>
//                             <div style={thumbInner}>
//                               <img src={driverImg} style={img} alt="driverPhoto" />
//                             </div>
//                           </div>
//                         </aside>
//                       }
//                     </section>
//                   </div>
//                 </div> */}
//                 <div className="row">

//                 <div className="col-md-3 mt-3">

//                   <div class="form-group row widget-3" style={sectionDiv}>
//                     <section className="container" style={driverDP}>
//                       {BrandImg === null ?
//                         <p align="center">Upload Image</p>
//                         :
//                         <aside style={thumbsContainerState}>
//                           <div style={thumb}>
//                             <div style={thumbInner}>
//                               <img src={BrandImg} style={img} alt="driverPhoto" />
//                             </div>
//                           </div>
//                         </aside>
//                       }
//                     </section>
//                   </div>
//                 </div>
//                 <div className="col-md-3 mt-3">
//                   <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black', marginTop: '90px' }}>
//                   {/* Product */}
//                   </InputLabel>
//                   <CssTextField
//                     required
//                     fullWidth
//                     id="productName"
//                     label="Product Name"
//                     variant="standard"
//                     name="productName" value={CommissionLivestockProduct.productName}
//                     onChange={(e)=>setCommissionLivestockProduct({...CommissionLivestockProduct, productName:e.target.value})}
//                   />
//                 </div>
//                 <div className="col-md-3 mt-3">
//                   <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black', marginTop: '90px' }}>
//                   {/* Product */}
//                   </InputLabel>
//                   <CssTextField
//                     required
//                     fullWidth
//                     id="variantName"
//                     label="Quantity"
//                     variant="standard"
//                     name="quantity"
//                     value={CommissionLivestockProduct.quantity}
//                     onChange={(e)=>setCommissionLivestockProduct({...CommissionLivestockProduct, quantity:e.target.value})}
//                   />
//                 </div>
//                 <div className="col-md-12 mt-3 d-flex">
//                   <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black', marginRight: '10px' }}>
//                     Upload Product Image:
//                   </InputLabel>
//                   <label htmlFor="contained-button-file">
//                     <Button variant="contained" component="span" color="success" size="small" style={uploadBtnSm}>
//                       <Input accept="image/" id="contained-button-file" multiple="false" type="file" onChange={(e)=>handleUploadIP(e)} />
//                       Upload
//                     </Button>
//                   </label>
//                 </div>
//               </div>
//                 {/* <div className="col-md-3 mt-3">
//                   <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black', marginTop: '90px' }}>
//                   Product
//                   </InputLabel>
//                   <CssTextField
//                     required
//                     fullWidth
//                     id="productName"
//                     label="Product Name"
//                     variant="standard"
//                     name="productName" value={CommissionLivestockProduct.productName}
//                     onChange={(e)=>setCommissionLivestockProduct({...CommissionLivestockProduct, productName:e.target.value})}
//                   />
//                 </div> */}

//                <div className="row justify-content-end mb-3">
//                <div className="col-md-1" style={{marginRight:'3%'}}>
//                  <button type="submit" className="btn btn-danger">Cancel</button>
//                </div>
//                <div className="col-md-1" style={{marginRight:'3%'}}>
//                  <button type="submit" className="btn btn-primary" onClick={()=>handleCommissionLivestockProduct()}>Submit</button>
//                </div>
//                </div>

//              </div>
//              </div>
//           </form>
//        </div>
//                                           {/* Quantity & unit */}
//        <div id="variant" class="card filter-card">
//        <form class="card-body pb-2" onSubmit={(e)=>e.preventDefault()}>
//            <div className="mt-3">
//                <h5 class="card-title">Quantity & unit</h5>
//                <div class="row mb-4">
//                <div class="col-md-3">
//                  <FormControl variant="standard" fullWidth className="mt-3">
//                   <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>Category</InputLabel>
//                   <Select
//                     required
//                     labelId="demo-simple-select-standard-label"
//                     id="demo-simple-select-standard"
//                    //  value={age}
//                    //  onChange={handleChange}
//                     label="Firm"
//                     color="success" value={LivestockVariantProduct.categoryName}
//                     onChange={(e)=>handleCategoryChangeVarient(e)}
//                     // onChange={(e)=>setLivestockVariantProduct({...LivestockVariantProduct,categoryName:e.target.value})}
//                   >
//                     {/* <MenuItem value="">
//                       <em>None</em>
//                     </MenuItem>
//                     <MenuItem value={"General"}>General</MenuItem>
//                     <MenuItem value={"OBC"}>OBC</MenuItem>
//                     <MenuItem value={"SC"}>SC</MenuItem>
//                     <MenuItem value={"ST"}>ST</MenuItem>
//                     <MenuItem value={"Others"}>Others</MenuItem> */}
//                      {
//                           Categorylist&&Categorylist.map((val,ind)=>{
//                            // {console.log("Categorylist",val)}
//                              return(
//                               <MenuItem value={val}>{val}</MenuItem>
//                              )
//                           })
//                        }
//                    </Select>
//                  </FormControl>
//                  </div>
//                  <div class="col-md-3">
//                    <FormControl variant="standard" fullWidth className="mt-3">
//                      <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>SubCategory</InputLabel>
//                       <Select
//                         labelId="demo-simple-select-standard-label"
//                         id="demo-simple-select-standard"
//                        //  value={age}
//                        //  onChange={handleChange}
//                         label="Booking Mode"
//                         color="success" value={LivestockVariantProduct.subCategoryName}
//                         onChange={(e)=>handlechangeproductVarientBrand(e)}
//                         // onChange={(e)=>setLivestockVariantProduct({...LivestockVariantProduct,subCategoryName:e.target.value})}
//                       >
//                         {/* <MenuItem value="">
//                           <em>None</em>
//                         </MenuItem>
//                         <MenuItem value={"General"}>General</MenuItem>
//                     <MenuItem value={"OBC"}>OBC</MenuItem>
//                     <MenuItem value={"SC"}>SC</MenuItem>
//                     <MenuItem value={"ST"}>ST</MenuItem>
//                     <MenuItem value={"Others"}>Others</MenuItem> */}
//                       {
//                           subCategorylist&&subCategorylist.map((val,ind)=>{
//                            // {console.log("Categorylist",val)}
//                              return(
//                               <MenuItem value={val}>{val}</MenuItem>
//                              )
//                           })
//                        }
//                       </Select>
//                     </FormControl>
//                  </div>
//                  <div class="col-md-3">
//                    <FormControl variant="standard" fullWidth className="mt-3">
//                      <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>Brand Name</InputLabel>
//                       <Select
//                         labelId="demo-simple-select-standard-label"
//                         id="demo-simple-select-standard"
//                        //  value={age}
//                        //  onChange={handleChange}
//                         label="BRAND Name"
//                         color="success"  value={LivestockVariantProduct.brandName}
//                         onChange={(e)=>handlechangeproductVarientProduct(e)}
//                         // onChange={(e)=>setLivestockVariantProduct({...LivestockVariantProduct,brandName:e.target.value})}
//                       >

//                         {/* <MenuItem value="">
//                           <em>None</em>
//                         </MenuItem>
//                         <MenuItem value={"General"}>General</MenuItem>
//                     <MenuItem value={"OBC"}>OBC</MenuItem>
//                     <MenuItem value={"SC"}>SC</MenuItem>
//                     <MenuItem value={"ST"}>ST</MenuItem>
//                     <MenuItem value={"Others"}>Others</MenuItem> */}
//                      {
//                           brandlist&&brandlist.map((val,ind)=>{
//                            // {console.log("Categorylist",val)}
//                              return(
//                               <MenuItem value={val}>{val}</MenuItem>
//                              )
//                           })
//                        }
//                       </Select>
//                     </FormControl>
//                  </div>
//                  <div class="col-md-3">
//                    <FormControl variant="standard" fullWidth className="mt-3">
//                      <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>Product Name</InputLabel>
//                       <Select
//                         labelId="demo-simple-select-standard-label"
//                         id="demo-simple-select-standard"
//                        //  value={age}
//                        //  onChange={handleChange}
//                         label="Product Name"
//                         color="success" value={LivestockVariantProduct. productName}
//                         onChange={(e)=>setLivestockVariantProduct({...LivestockVariantProduct, productName:e.target.value})}
//                       >

//                         {
//                           Productlist&&Productlist.map((val,ind)=>{
//                            // {console.log("Categorylist",val)}
//                              return(
//                               <MenuItem value={val.productName}>{val.productName}</MenuItem>
//                              )
//                           })
//                        }
//                         {/* <MenuItem value="">
//                           <em>None</em>
//                         </MenuItem>
//                         <MenuItem value={"General"}>General</MenuItem>
//                     <MenuItem value={"OBC"}>OBC</MenuItem>
//                     <MenuItem value={"SC"}>SC</MenuItem>
//                     <MenuItem value={"ST"}>ST</MenuItem>
//                     <MenuItem value={"Others"}>Others</MenuItem> */}
//                       </Select>
//                     </FormControl>
//                  </div>
//                  <div class="col-md-3">
//                  <CssTextField
//                     required
//                     fullWidth
//                     id="variantName"
//                     label="Variant Name"
//                     variant="standard"
//                     name="variantName"
//                     value={LivestockVariantProduct.variantName}
//                     onChange={(e)=>setLivestockVariantProduct({...LivestockVariantProduct, variantName:e.target.value})}
//                   />
//                  </div>
//                </div>

//                <div className="row justify-content-end mb-3">
//                <div className="col-md-1">
//                  <button type="submit" className="btn btn-danger">Cancel</button>
//                </div>
//                <div className="col-md-1">
//                  <button type="submit" className="btn btn-primary" onClick={()=>handleLivestockVariantProduct()}>Submit</button>
//                </div>
//                </div>

//              </div>
//           </form>
//        </div>
//        <div class="row">
//       <div class="col-sm-12">
//      <h3 className="mb-3">List</h3>
//          <div class="card card-table">
//             <div class="card-body">
//                <div class="table-responsive">
//                   <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
//                      <div class="row">
//                         <div class="col-sm-12 col-md-6">
//                            <div class="dataTables_length" id="DataTables_Table_0_length">
//                               <label>
//                                  Show
//                                  <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" class="custom-select custom-select-sm form-control form-control-sm">
//                                     <option value="10">10</option>
//                                     <option value="25">25</option>
//                                     <option value="50">50</option>
//                                     <option value="100">100</option>
//                                  </select>
//                                  entries
//                               </label>
//                            </div>
//                         </div>
//                         <div class="col-sm-12 col-md-3 position_right">
//                            <div class="form-group">
//                               {/* <label>Search</label> */}
//                               <input type="text" class="form-control" placeholder="Search" />
//                            </div>
//                         </div>
//                         <div class="row" style={{margin:'2%'}}>
//                           <ProductLivestock inputs={ProductInput} />
//                        </div>
//                      </div>
//                      <div class="row">
//                         <div class="col-sm-12 col-md-5">
//                            <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 1 to 10 of 12 entries</div>
//                         </div>
//                         <div class="col-sm-12 col-md-7">
//                            <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
//                               <ul class="pagination">
//                                  <li class="paginate_button page-item previous disabled" id="DataTables_Table_0_previous"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li>
//                                  <li class="paginate_button page-item active"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0" class="page-link">1</a></li>
//                                  <li class="paginate_button page-item "><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="2" tabindex="0" class="page-link">2</a></li>
//                                  <li class="paginate_button page-item next" id="DataTables_Table_0_next"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="3" tabindex="0" class="page-link">Next</a></li>
//                               </ul>
//                            </div>
//                         </div>
//                      </div>
//                   </div>
//                </div>
//             </div>
//          </div>
//       </div>
//    </div>
//       {/* <div class="row">
//     <div class="col-sm-12">
//     <h3 className="mb-3">List</h3>
//        <div class="card card-table">
//           <div class="card-body">
//              <div class="table-responsive">
//                 <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
//                    <div class="row">
//                       <div class="col-sm-12 col-md-6">
//                          <div class="dataTables_length" id="DataTables_Table_0_length">
//                             <label>
//                                Show
//                                <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" class="custom-select custom-select-sm form-control form-control-sm">
//                                   <option value="10">10</option>
//                                   <option value="25">25</option>
//                                   <option value="50">50</option>
//                                   <option value="100">100</option>
//                                </select>
//                                entries
//                             </label>
//                          </div>
//                       </div>
//                       <div class="col-sm-12 col-md-3 position_right">
//                          <div class="form-group">
//                             <input type="text" class="form-control" placeholder="Search" />
//                          </div>
//                       </div>
//                    </div>
//                    <div class="row">
//                       <div class="col-sm-12 col-md-5">
//                          <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 1 to 10 of 12 entries</div>
//                       </div>
//                       <div class="col-sm-12 col-md-7">
//                          <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
//                             <ul class="pagination">
//                                <li class="paginate_button page-item previous disabled" id="DataTables_Table_0_previous"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li>
//                                <li class="paginate_button page-item active"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0" class="page-link">1</a></li>
//                                <li class="paginate_button page-item "><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="2" tabindex="0" class="page-link">2</a></li>
//                                <li class="paginate_button page-item next" id="DataTables_Table_0_next"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="3" tabindex="0" class="page-link">Next</a></li>
//                             </ul>
//                          </div>
//                       </div>
//                    </div>
//                 </div>
//              </div>
//           </div>
//        </div>
//     </div>
//  </div> */}
//  </>
//  :
//   // <LsCategories />
//   <div class="row">
//   <div class="col-sm-12">
//   {/* <h3 className="mb-3">Livestock Products</h3> */}
//      <div class="card card-table">
//         <div class="card-body">
//            <div class="table-responsive">
//               <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
//                  <div class="row">
//                     <div class="col-sm-12 col-md-6">
//                        <div class="dataTables_length" id="DataTables_Table_0_length">
//                           <label>
//                              Show
//                              <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" class="custom-select custom-select-sm form-control form-control-sm">
//                                 <option value="10">10</option>
//                                 <option value="25">25</option>
//                                 <option value="50">50</option>
//                                 <option value="100">100</option>
//                              </select>
//                              entries
//                           </label>
//                        </div>
//                     </div>
//                     <div class="col-sm-12 col-md-3 position_right">
//                        <div class="form-group">
//                           <input type="text" class="form-control" placeholder="Search" />
//                        </div>
//                     </div>
//                  </div>
//                  <div class="row" style={{margin:'2%'}}>
//                  <ProductLivestock inputs={ProductInput} />
//                 </div>

//                  <div class="row">
//                     <div class="col-sm-12 col-md-5">
//                        <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 1 to 10 of 12 entries</div>
//                     </div>
//                     <div class="col-sm-12 col-md-7">
//                        <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
//                           <ul class="pagination">
//                              <li class="paginate_button page-item previous disabled" id="DataTables_Table_0_previous"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li>
//                              <li class="paginate_button page-item active"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0" class="page-link">1</a></li>
//                              <li class="paginate_button page-item "><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="2" tabindex="0" class="page-link">2</a></li>
//                              <li class="paginate_button page-item next" id="DataTables_Table_0_next"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="3" tabindex="0" class="page-link">Next</a></li>
//                           </ul>
//                        </div>
//                     </div>
//                  </div>
//               </div>
//            </div>
//         </div>
//      </div>
//   </div>
// </div>
//   }
//   </>
// }

// </>
//     )
// }

export default LivestockCatelog
