// import firebase from 'firebase/compat/app';
// import {getStorage} from "firebase/storage";

// const firebaseConfig = {
//     apiKey: "AIzaSyBrLcPOuk6PAOXtLq5LnWxAbVsfe5vR9rA",
//     authDomain: "krishivanadmin.firebaseapp.com",
//     projectId: "krishivanadmin",
//     storageBucket: "krishivanadmin.appspot.com",
//     messagingSenderId: "540467931751",
//     appId: "1:540467931751:web:5a4b902a472597021702c2",
//     measurementId: "G-RVNXJHNW3S"
//   };

// const app = firebase.initializeApp(firebaseConfig);

// const storage = getStorage(app);

// export { storage, firebase as default };

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey: 'AIzaSyBCgGkH7S3OL-fNKGnQmfMQzbJyY9AZ66o',
    authDomain: 'krishivan-app.firebaseapp.com',
    projectId: 'krishivan-app',
    storageBucket: 'krishivan-app.appspot.com',
    messagingSenderId: '1018772914539',
    appId: '1:1018772914539:web:3f3d750142694da0a74c2e',
    measurementId: 'G-CMCSVD54TQ',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

const storage = getStorage(app)

export { app,storage }
