import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'

import CountTable from './CountTable'
import { format } from 'date-fns'
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
const BookingCountTab = () => {
    const [tab, setTab] = useState('livestock')
    const [counts, setCounts] = useState([])
    const [from, setFrom] = useState(Date.parse(new Date('01/01/1900')))
    const [to, setTo] = useState(Date.parse(new Date('01/01/1900')))
    const [currentPage, setCurrentPage] = useState(0)
    const [pageCount,setPageCount]=useState(5)
    const [count, setCount] = useState(0)
    const [limit, setLimit] = useState(100)

    useEffect(() => {
        console.log(new Date('01/01/1900'), to, from)
        if (to > 0 && from > 0) getCounts(0)
    }, [tab, to, from])

    const createUserObject = (userId, userData) => {
        const userObject = {
          id: userId,
          // Add other common fields here
        };
      
        userData.forEach(categoryData => {
          const categoryName = Object.keys(categoryData)[0];
          const countData = categoryData[categoryName];
          if (countData) {
            // Extract common fields from the first available count object
            if (!userObject.mobile_number) {
              userObject.mobile_number = countData.mobile_number;
              userObject.state = countData.state;
              userObject.district = countData.district;
              userObject.pincode = countData.pincode;
              userObject.first_name = countData.first_name;
              userObject.lastName = countData.lastName;
              // Add other common fields here
            }
        }
          userObject[`${categoryName}Count`] = countData.count;
          userObject[`${categoryName}KrishiAmountSum`] = countData.krishiAmountSum;
          userObject[`${categoryName}UserAmountSum`] = countData.userAmountSum;
          userObject[`${categoryName}UserAmountSum`] = countData.userAmountSum;

          // Add other category-specific fields here
        });
      
        return userObject;
      };
    const getCounts = async (page=0) => {
        const token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_ENDPOINT}/auth-function/report-booking?role=${tab}&from=${from}&to=${to}&page=${page*10}&limit=${limit}`,
            config
        )
        if (data != undefined && Object.keys(data.data).length != 0) {
            let countIds = data.data
            console.log(countIds,'offdata')
            const finalArray = [];

// Iterate through the data
Object.keys(countIds).forEach(userId => {
  const userData = countIds[userId];
  const userObject = createUserObject(userId, userData);
  finalArray.push(userObject);
});

// Display the final array
console.table(finalArray);
           
            setCounts(finalArray)
            return
        }
    }

    const handleCallback = (start, end, label) => {
        const d1 = Date.parse(start._d)
        const d2 = Date.parse(end._d)
        setFrom(d1)
        setTo(d2)
    }
    const CustomPagination = () => (
        <ReactPaginate
          previousLabel={''}
          nextLabel={''}
          forcePage={currentPage}
          onPageChange={page => onPageChange(page)}
          pageCount={100/10}
          breakLabel={'...'}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          activeClassName='active'
          pageClassName='page-item'
          breakClassName='page-item'
          nextLinkClassName='page-link'
          pageLinkClassName='page-link'
          breakLinkClassName='page-link'
          previousLinkClassName='page-link'
          nextClassName='page-item next-item'
          previousClassName='page-item prev-item'
          containerClassName={'pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1'}
        />
      )
      const onPageChange = (page) => {
        setCurrentPage(page.selected)

        console.log('ran', page)
        getCounts(page.selected)
    }
    return (
        <div class="content container-fluid">
            <div class="page-header">
                <div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div class="card card-table p-10-25">
                                <div class="card-header">
                                    <h3 class="card-title">Count</h3>
                                </div>
                                <form
                                    class="card-body"
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        console.log('Form Successfully working')
                                    }}
                                >
                                    <>
                                        <div class="tab-content">
                                            <div action="#">
                                                <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                                <li class="nav-item">
                                                        <a
                                                            class={
                                                                tab ===
                                                                'farmer'
                                                                    ? 'nav-link active'
                                                                    : 'nav-link'
                                                            }
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(
                                                                    'farmer'
                                                                )
                                                            }
                                                        >
                                                            Farmer
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class={
                                                                tab ===
                                                                'livestock'
                                                                    ? 'nav-link active'
                                                                    : 'nav-link'
                                                            }
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(
                                                                    'livestock'
                                                                )
                                                            }
                                                        >
                                                            Live Stock
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab2"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(
                                                                    'agri-input-supplier'
                                                                )
                                                            }
                                                        >
                                                            Input Supplier
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(
                                                                    'agri-machine'
                                                                )
                                                            }
                                                        >
                                                            Agri Machine
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(
                                                                    'agri-transport'
                                                                )
                                                            }
                                                        >
                                                            Agri Transport
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(
                                                                    'micro-entrepreneur'
                                                                )
                                                            }
                                                        >
                                                            Micro Entrepreneur
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab('trader')
                                                            }
                                                        >
                                                            Traders
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab('fpo')
                                                            }
                                                        >
                                                            Fpo
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                </form>
                            </div>

                            <DateRangePicker
                                initialSettings={{
                                    startDate: new Date().getDate(),
                                    endDate: new Date().getDate(),
                                }}
                                onCallback={handleCallback}
                            >
                                <input className="date-filter" />
                            </DateRangePicker>

                          <CountTable counts={counts} />
                          
                          <CustomPagination/>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingCountTab
