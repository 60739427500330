import React, { useState, useEffect } from 'react'
import Bookings from '../Bookings/Bookings'
import { API } from '../../API/index'
import CreateFarmer from './CreateFarmer/CreateFarmer'
import InputSuppliers from './CreateInputSupplier/InputSuppliers'
import MicroEnt from './CreateMicroEnt/MicroEntrepreneurs'
import AgriMachine from './CreateAgriMachine/AgriMachine'
import AgriTransport from './CreateAgriTransport/AgriTransport'
import Livestock from './CreateLivestock/Livestock'
import FPO from './CreateFPO/CreateFPO'
import FarmerTable from './CreateFarmer/FarmerTable'
import Trader from './CreateTrader/CreateTrader'
import '../../App.css'
import Map from './Map'
import * as XLSX from 'xlsx';

function Farmers() {
    const [booking, setBooking] = useState(false)
    const [create, setCreate] = useState(0)
    const [excel,setExcel]=useState(true)
    const [json, setJson] = useState(null);

    const [farmers, setFarmers] = useState([])
    const [searchdata, setsearchdata] = useState('')
    console.log(farmers)

    useEffect(() => {
        //   getAllFarmers();
    }, [])

    const getAllFarmers = async () => {
        const { data } = await API.get(`/getFarmer?type=0`)
        setFarmers(data?.result)
    }
    const handleMobilesearch = (e) => {
        console.log('e', e.target.value)
        setsearchdata(e.target.value)
        if (e.target.value.length == 10) {
            mobilechecksearch(e.target.value)
        } else {
            setFarmers([])
        }
    }
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
    
        reader.onload = (event) => {
          const binaryString = event.target.result;
          const workbook = XLSX.read(binaryString, { type: 'binary' });
          const sheetName = workbook.SheetNames[0]; // Assuming only one sheet
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet);
    
          setJson(jsonData[0]); // Assuming only one row
        };
    
        reader.readAsBinaryString(file);
      };
    const mobilechecksearch = async (number) => {
        const { data } = await API.get(`/searchFarmer?search=` + number)
        console.log('data', data)
        if (data.success == true) {
            let arr = []
            arr.push(data?.result)
            setFarmers(arr)
        } else {
        }
    }
    const handelchange = (e) => {
        setCreate(e.target.value)
        setFarmers([])
        setsearchdata('')
    }
    return (
        <div class="content container-fluid">
            <div class="page-header">
                <div class="row align-items-center">
                    <div class="col">
                        <h3 class="page-title">Create & Bookings</h3>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a href="#">Dashboard</a>
                            </li>
                            <li class="breadcrumb-item active">Farmer</li>
                        </ul>
                    </div>
                    <div class="col-auto">
                        <a
                            class="btn btn-primary filter-btn"
                            id="filter_search"
                            onClick={(e) => setExcel(true)}
                        >
                            <i class="fas fa-plus"></i> <span> Upload Excel Registration</span>
                        </a>
                    </div>
                    <div class="col-auto">
                        <a
                            class="btn btn-primary filter-btn"
                            id="filter_search"
                            onClick={(e) => setBooking(false)}
                        >
                            <i class="fas fa-plus"></i> <span> Create</span>
                        </a>
                    </div>
                    {booking === false && (
                        <div class="col-auto">
                            <a
                                class="btn btn-primary filter-btn"
                                onClick={(e) => setBooking(true)}
                            >
                                <i class="fas fa-plus"></i>{' '}
                                <span> Booking</span>
                            </a>
                        </div>
                    )}
                </div>
            </div>
            {
                        excel===true &&(
<div>
      <h2>Upload Excel File</h2>
      <input type="file" onChange={handleFileUpload} />
      <div>
        {json ? (
          <div>
            <h3>JSON Data</h3>
            <pre>{JSON.stringify(json, null, 2)}</pre>
          </div>
        ) : null}
      </div>
    </div>                        )
                    }
            {booking === false && (
                <div id="filter_inputs" class="card filter-card ">
                    <div class="card-body pb-0">
                        <div class="row">
                            <div class="col-md-4 select2-container">
                                <div
                                    className="form-group mb-4"
                                    data-select2-id="11"
                                >
                                    <label>Create</label>
                                    <select
                                        required=""
                                        tabindex="-1"
                                        class="mdl-textfield__input"
                                        type="text"
                                        id="sample24"
                                        name="stateName"
                                        value={create}
                                        onChange={(e) => handelchange(e)}
                                    >
                                        <option>Select Option</option>
                                        <option value="1">Farmer</option>
                                        <option value="2">
                                            Input Supplier
                                        </option>
                                        <option value="3">Agri Machine</option>
                                        <option value="4">
                                            Agri Transport
                                        </option>
                                        <option value="5">Livestock</option>
                                        <option value="6">
                                            Micro EntrePreneurs
                                        </option>
                                        <option value="7">FPO</option>
                                        <option value="8">Trader</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {booking === false && create === '1' && (
                <CreateFarmer
                    booking={booking}
                    create={create}
                    getFarmers={getAllFarmers}
                />
            )}
            {booking === false && create === '2' && <InputSuppliers />}
            {booking === false && create === '3' && <AgriMachine />}
            {booking === false && create === '4' && <AgriTransport />}
            {booking === false && create === '5' && <Livestock />}
            {booking === false && create === '6' && <MicroEnt />}
            {booking === false && create === '7' && <FPO />}
            {booking === false && create === '8' && <Trader />}

            {booking === true && <Bookings />}
        </div>
    )
}
export default Farmers
