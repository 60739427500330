
import React, { useState, useEffect } from 'react'
import { CssTextField } from '../../../Styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import Fade from '@mui/material/Fade'
import  Selected  from 'react-select'
import RichTextEditor from 'react-rte';

import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import {
    thumbsContainerState,
    thumb,
    img,
    thumbInner,
    baseStyle,
    driverDP,
    sectionDiv,
    uploadBtnSm,
    panImg,
} from '../../../Styles'
import { useDropzone } from 'react-dropzone'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { storage } from '../../../../firebase/index'
import Swal from 'sweetalert2'
import axios from 'axios'


const Input = styled('input')({
    display: 'none',
    marginBottom: '16px',
    marginTop: '16px',
})

function ProductUpdateDialog(props) {
    const catalogue = props.product.catalogue
    const [showCategory, setShowCategory] = useState(false)
    const [createProduct, setCreateProduct] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [Categorylist, setCategorylist] = useState([])
    const [subCategorylist, setsubCategorylist] = useState([])
    const [brandlist, setbrandlist] = useState([])
    const [categoryName, setcategoryName] = useState('')
    const [subcategoryName, setsubcategoryName] = useState('')
    const [Productlist, setProductlist] = useState([])
    const [ProductInput, setProductInput] = useState([])
    const [driverImg, setDriverImg] = useState(null)
    const [BrandImg, setBrandImg] = useState(null)
    const [productImg,setProductImg]=useState(null)
    const [progress, setProgress] = useState([])
    const [defaultProduct,setDefaultProduct]=useState([])
    const [description, setDescription] = useState('')
    const [editorState, setEditorState] = useState(
      RichTextEditor.createValueFromString(props.product.description, 'html')
      );
    
      const handleChange = (value) => {
        setEditorState(value);
        const htmlString = editorState.toString('html');
    // You can now save or use the 'htmlString' as needed
    console.log(htmlString);
      };
    const open = Boolean(anchorEl)
    console.log(props,'product rpops')
    const mobileNo = localStorage.getItem('livestockfarmerid')


    const inputClear = {
        catalogue:  props.product.catalogue,
        categoryName: '',
    }
    const Subclear = {
        catalogue:  props.product.catalogue,
        categoryName: '',
        subCategoryName: '',
    }
    const BrandClear = {
        catalogue:  props.product.catalogue,
        categoryName: '',
        subCategoryName: '',
        brandName: '',
        imageUrl: '',
        brandImage: '',
    }
    
    const ProductClear = {
        catalogue:  props.product.catalogue,
        categoryName: '',
        subCategoryName: '',
        brandName: '',
        productImage: '',
        productName: '',
    }
    const VariantClear = {
        catalogue:  props.product.catalogue,
        categoryName: '',
        subCategoryName: '',
        brandName: '',
        productName: '',
        quantity: '',
        unit: '',
    }


    useEffect(() => {
        getCategoryList()
        getAllProducts();
        setDescription(props.product.description); 
        setDefaultProduct({
            categoryName:props.product.categoryName,
            subCategoryName:props.product.subCategoryName,
            brandName:props.product.brandName,
            productName:props.product.productName,
            hsn:props.product.hsn,
            sku:props.product.sku,
            
        })

    }, [])
    const getAllProducts = async () => {
        var farmerid = localStorage.getItem('Traderid')
        var token = localStorage.getItem("token");
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        const db = {
          "catalogue": "livestock",
        //   "mobile_number": "5555555555"
        }
        // const { data } = await API.get(`/getProductCatelog?&title=ProductCatelog&catelogName=InputSupplierCatelog&type=2`);
        axios.post("https://prod-api.krishione.com/farmer-function/get-all-products", db, config)
          .then(res => {
            console.log("test", res.data.data);
            setProductInput(res?.data?.product);
          })
      }
    const getProductInput = async () => {
        var data1 = {
            catalogue:  props.product.catalogue,
            mobile_number: mobileNo,
        }
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product-getinventory`,
                data1,
                config
            )
            .then((res) => {
                // setProductInput(res.data?.data)
                setProductInput(res.data?.data)
            })
        // const { data } = await API.get(`/getProductCatelog?title=ProductCatelog&catelogName=LiveStockCatelog&type=2`);
        // setProductInput(res.data?.data[0]);
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const [InputLivestockProduct, setInputLivestockProduct] = useState({
        catalogue:  props.product.catalogue,
        categoryName: '',
    })
    const [SubLivestockProduct, setSubLivestockProduct] = useState({
        catalogue:  props.product.catalogue,
        categoryName: '',
        subCategoryName: '',
    })
    const [driverLicense, setDriverLicense] = useState([])
    const [BrandLivestockProduct, setBrandLivestockProduct] = useState({
        catalogue:  props.product.catalogue,
        categoryName: '',
        subCategoryName: '',
        brandName: '',
        imageUrl: '',
        brandImage: '',
    })
    const [driverLicenses, setDriverLicenses] = useState([])
    const [CommissionLivestockProduct, setCommissionLivestockProduct] =
        useState({
            catalogue:  props.product.catalogue,
            categoryName: '',
            subCategoryName: '',
            brandName: '',
            brandImage:props.product.brandImage,
            productName: props.product.productName,            
            productImage: props.product.productImage,
            hsn:props.product.hsn,
            sku:props.product.sku,
        })
    const [LivestockVariantProduct, setLivestockVariantProduct] = useState({
        catalogue:  props.product.catalogue,
        categoryName: '',
        subCategoryName: '',
        brandName: '',
        productName: '',
        quantity: '',
        unit: '',
    })
    const typeoptiob =
    brandlist &&
    brandlist.map((item, ind) => ({
        value: item.brandName,
        label: item.brandName,
        image: item.imageUrl,
        
  
    }))

    const handleUploadIP = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setProductImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

        setProgress((prevProgress) => {
          const newProgress = [...prevProgress]
          newProgress[0] = progress.toFixed(2)
          return newProgress
        })
        console.log(progress,'progress,')
                },
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        // setAgriMachine({ ...agriMachine, driverPhoto: url });
                        setCommissionLivestockProduct({
                            ...CommissionLivestockProduct,
                            productImage: url,
                        })
                    })
                }
            )
        }
    
}
    const handleUploadBrandImage = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setBrandImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        // setAgriMachine({ ...agriMachine, driverPhoto: url });
                        setCommissionLivestockProduct({
                            ...CommissionLivestockProduct,
                            brandImage: url,
                        })
                    })
                }
            )
        }
    }

    const handleCloseclear = () => {
        // setAnchorEl(null);
        handleClose()
        setDriverImg(null)
        setBrandImg(null)
    }
    const handleInputLivestockProduct = async () => {
        console.log('InputLivestockProduct', InputLivestockProduct)

        try {
            // const { data } = await API.post('/createProductCategory ', InputLivestockProduct);
            // if (data?.success) {
            //   Swal.fire({
            //     icon: 'success',
            //     iconColor: '#28a745',
            //     title: data?.message,
            //     showConfirmButton: false,
            //     timer: 2000
            //   });
            //   console.log(data);
            //   getCategoryList()
            //   setInputLivestockProduct(inputClear)
            // } else {
            //   setInputLivestockProduct(inputClear)
            //   Swal.fire({
            //     icon: 'warning',
            //     iconColor: '#dc3545',
            //     title: data?.message,
            //     showConfirmButton: false,
            //     timer: 2000
            //   });
            // }
            var token = localStorage.getItem('token')
            var config = {
                headers: { Authorization: `Bearer ${token}` },
            }

            if (props.productCatelog) {
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product-category`,
                        { ...InputLivestockProduct, mobile_number: mobileNo },
                        config
                    )
                    .then((data) => {
                        console.log('category in input livestock: ', data)
                        Swal.fire({
                            icon: 'success',
                            iconColor: '#28a745',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                        console.log(data)
                        getCategoryList()
                        setInputLivestockProduct(inputClear)
                    })
                    .catch((data) => {
                        console.log(data)
                        setInputLivestockProduct(inputClear)
                        Swal.fire({
                            icon: 'warning',
                            iconColor: '#dc3545',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                    })
            } else {
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/category`,
                        InputLivestockProduct,
                        config
                    )
                    .then((data) => {
                        console.log('category in input livestock: ', data)
                        Swal.fire({
                            icon: 'success',
                            iconColor: '#28a745',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                        console.log(data)
                        getCategoryList()
                        setInputLivestockProduct(inputClear)
                    })
                    .catch((data) => {
                        console.log(data)
                        setInputLivestockProduct(inputClear)
                        Swal.fire({
                            icon: 'warning',
                            iconColor: '#dc3545',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                    })
            }
        } catch (err) {
            setInputLivestockProduct(inputClear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handleSubLivestockProduct = async () => {
        console.log('SubLivestockProduct ', SubLivestockProduct)

        try {
            var token = localStorage.getItem('token')
            var config = {
                headers: { Authorization: `Bearer ${token}` },
            }

            if (props.productCatelog) {
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product-subcategory`,
                        { ...SubLivestockProduct, mobile_number: mobileNo },
                        config
                    )
                    .then((data) => {
                        console.log('category in sub livestock: ', data)
                        Swal.fire({
                            icon: 'success',
                            iconColor: '#28a745',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                        console.log(data)
                        setSubLivestockProduct(inputClear)
                    })
                    .catch((data) => {
                        console.log(data)
                        setSubLivestockProduct(inputClear)
                        Swal.fire({
                            icon: 'warning',
                            iconColor: '#dc3545',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                    })
            } else {
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/subcategory`,
                        SubLivestockProduct,
                        config
                    )
                    .then((data) => {
                        console.log('category in input livestock: ', data)
                        Swal.fire({
                            icon: 'success',
                            iconColor: '#28a745',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                        setSubLivestockProduct(inputClear)
                    })
                    .catch((data) => {
                        console.log(data)
                        setSubLivestockProduct(inputClear)
                        Swal.fire({
                            icon: 'warning',
                            iconColor: '#dc3545',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                    })
            }
        } catch (err) {
            setSubLivestockProduct(Subclear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handleBrandLivestockProduct = async () => {
        console.log('BrandLivestockProduct', BrandLivestockProduct)

        try {
            // const { data } = await API.post('/createProductBrand', BrandLivestockProduct);
            // if (data?.success) {
            //   Swal.fire({
            //     icon: 'success',
            //     iconColor: '#28a745',
            //     title: data?.message,
            //     showConfirmButton: false,
            //     timer: 2000
            //   });
            //   console.log(data);
            //   setBrandImg(null);
            //   setDriverImg(null);
            //   setBrandLivestockProduct(BrandClear)
            // } else {
            //   setBrandLivestockProduct(BrandClear)
            //   Swal.fire({
            //     icon: 'warning',
            //     iconColor: '#dc3545',
            //     title: data?.message,
            //     showConfirmButton: false,
            //     timer: 2000
            //   });
            // }
            var token = localStorage.getItem('token')
            var config = {
                headers: { Authorization: `Bearer ${token}` },
            }
            if (props.productCatelog) {
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product-brand`,
                        { ...BrandLivestockProduct, mobile_number: mobileNo },
                        config
                    )
                    .then((data) => {
                        console.log('category in brand livestock: ', data)
                        Swal.fire({
                            icon: 'success',
                            iconColor: '#28a745',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                        console.log(data)
                        setBrandLivestockProduct(BrandClear)
                        setBrandImg(null)
                        setDriverImg(null)
                    })
                    .catch((data) => {
                        console.log(data)
                        setBrandLivestockProduct(BrandClear)
                        Swal.fire({
                            icon: 'warning',
                            iconColor: '#dc3545',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                    })
            } else {
                await axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/brand`,
                        BrandLivestockProduct,
                        config
                    )
                    .then((data) => {
                        console.log('brand in input', data)
                        Swal.fire({
                            icon: 'success',
                            iconColor: '#28a745',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                        setBrandLivestockProduct(BrandClear)
                        setBrandImg(null)
                        setDriverImg(null)
                    })
                    .catch((data) => {
                        Swal.fire({
                            icon: 'warning',
                            iconColor: '#dc3545',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                    })
            }
        } catch (err) {
            setBrandLivestockProduct(BrandClear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handleCommissionLivestockProduct = async () => {
        console.log('CommissionLivestockProduct', CommissionLivestockProduct)

        try {
           
            var token = localStorage.getItem('token')
            var config = {
                headers: { Authorization: `Bearer ${token}` },
            }
            if (props.productCatelog) {
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product-catelogue-product`,
                        {
                            ...CommissionLivestockProduct,
                            mobile_number: mobileNo,
                        },
                        config
                    )
                    .then((data) => {
                        console.log('category in commission livestock: ', data)
                        Swal.fire({
                            icon: 'success',
                            iconColor: '#28a745',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                        console.log(data)
                        setCommissionLivestockProduct(ProductClear)
                    })
                    .catch((data) => {
                        console.log(data)
                        setCommissionLivestockProduct(ProductClear)
                        Swal.fire({
                            icon: 'warning',
                            iconColor: '#dc3545',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                    })
            } else {
                function removeEmptyFields(obj) {
                    for (const key in obj) {
                      if (obj[key] === null || obj[key] === undefined || obj[key] === '' || (Array.isArray(obj[key]) && obj[key].length === 0) || (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)) {
                        delete obj[key];
                      }
                    }
                  }
                  
                
              var cleaned=    CommissionLivestockProduct
                  removeEmptyFields(cleaned)
                await axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/edit-product`,
                      
                        {_id:props.product._id,...cleaned,description: editorState.toString('html')},
                        config
                    )
                    .then((data) => {
                        console.log('brand in input', data)
                        Swal.fire({
                            icon: 'success',
                            iconColor: '#28a745',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                        setCommissionLivestockProduct(ProductClear)
                    })
                    .catch((data) => {
                        Swal.fire({
                            icon: 'warning',
                            iconColor: '#dc3545',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                    })
            }
        } catch (err) {
            setCommissionLivestockProduct(ProductClear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handleLivestockVariantProduct = async () => {
        console.log('LivestockVariantProduct', LivestockVariantProduct)

        try {
            var token = localStorage.getItem('token')
            var config = {
                headers: { Authorization: `Bearer ${token}` },
            }
            if (props.productCatelog) {
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product-catelog-quantity`,
                        { ...LivestockVariantProduct, mobile_number: mobileNo },
                        config
                    )
                    .then((data) => {
                        console.log('category in livestock variant: ', data)
                        Swal.fire({
                            icon: 'success',
                            iconColor: '#28a745',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                        console.log(data)
                        setLivestockVariantProduct(VariantClear)
                    })
                    .catch((data) => {
                        console.log(data)
                        setLivestockVariantProduct(VariantClear)
                        Swal.fire({
                            icon: 'warning',
                            iconColor: '#dc3545',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                    })
            } else {
                await axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/createProductCatelogQuantity`,
                        LivestockVariantProduct,
                        config
                    )
                    .then((data) => {
                        console.log('brand in input', data)
                        Swal.fire({
                            icon: 'success',
                            iconColor: '#28a745',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                        setLivestockVariantProduct(VariantClear)
                    })
                    .catch((data) => {
                        Swal.fire({
                            icon: 'warning',
                            iconColor: '#dc3545',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                    })
            }
        } catch (err) {
            setLivestockVariantProduct(VariantClear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    const getCategoryList = async () => {
        // const { data } = await API.get(`/getProductCategory?type=0&title=ProductCatelog&catelogName=LiveStockCatelog`);
        // console.log("hkjjhj",data)
        // setCategorylist(data?.result);
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        if (props.productCatelog) {
            var data = {
                catalogue: catalogue,
                mobile_number: mobileNo,
            }
            axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product-getcategory`,
                    data,
                    config
                )
                .then((res) => {
                    console.log('category in livestock variant: ', data)
                    setCategorylist(res.data.category)
                })
                .catch((data) => {
                    console.log(data)
                })
        } else {
            await axios
                .get(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/category/${catalogue}`,
                    config
                )
                .then((res) => {
                    console.log('d :', res.data.category)
                    setCategorylist(res.data.category)
                })
        }
    }
    const handleCategoryChange = (e) => {
        // setSubLivestockProduct({...SubLivestockProduct,categoryName:e.target.value})
        setBrandLivestockProduct({
            ...BrandLivestockProduct,
            categoryName: e.target.value,
        })
        getSubCategoryList(e.target.value)
        setcategoryName(e.target.value)
    }
    const getSubCategoryList = async (val) => {
        // const { data } = await API.get(`/getProductSubCategory?type=0&title=ProductCatelog&catelogName=LiveStockCatelog&categoryName=` + val);
        // console.log("hkjjhj", data)
        // setsubCategorylist(data?.result);
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const subCat = {
            catalogue: catalogue,
            categoryName: val,
        }
        if (props.productCatelog) {
            var data = {
                catalogue: catalogue,
                mobile_number: mobileNo,
                categoryName: val,
            }
            axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product-getsubcategory`,
                    data,
                    config
                )
                .then((res) => {
                    console.log('category in livestock variant: ', data)
                    setsubCategorylist(res.data.subcategory)
                })
                .catch((data) => {
                    console.log(data)
                })
        } else {
            await axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-subcategory`,
                    subCat,
                    config
                )
                .then((res) => {
                    console.log(
                        'get sub cat in input catalogue',
                        res.data.subcategory
                    )
                    setsubCategorylist(res.data.subcategory)
                })
        }
    }
    const handleCategoryChangeProduct = (e) => {
        setCommissionLivestockProduct({
            ...CommissionLivestockProduct,
            categoryName: e.target.value,
        })
        getSubCategoryList(e.target.value)
        setcategoryName(e.target.value)
    }

    const handlechangeproductBrand = (e) => {
        console.log(e.target.value, 'e')
        setCommissionLivestockProduct({
            ...CommissionLivestockProduct,
            subCategoryName: e.target.value,
        })
        getBrandList(e.target.value)
    }

    const getBrandList = async (val) => {
        // const { data } = await API.get(`/getProductBrand?type=0&title=ProductCatelog&catelogName=LiveStockCatelog&categoryName=` + categoryName + '&subCategoryName=' + val);
        // console.log("hkjjhj", data)
        // setbrandlist(data?.result);
        let forGetBrands = {
            categoryName: CommissionLivestockProduct.categoryName,
            subCategoryName: val,
            catalogue: catalogue,
        }
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }

        if (props.productCatelog) {
            await axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product-getbrand`,
                    { ...forGetBrands, mobile_number: mobileNo },
                    config
                )
                .then((data) => {
                    console.log('brand in input', data)
                    setbrandlist(data.data.brand)
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            await axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-brand`,
                    forGetBrands,
                    config
                )
                .then((data) => {
                    console.log('brand in input', data)
                    setbrandlist(data.data.brand)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const handleCategoryChangeVarient = (e) => {
        setLivestockVariantProduct({
            ...LivestockVariantProduct,
            categoryName: e.target.value,
        })
        getSubCategoryList(e.target.value)
        setcategoryName(e.target.value)
    }

    const handlechangeproductVarientBrand = (e) => {
        console.log(e.target.value, 'e')
        setLivestockVariantProduct({
            ...LivestockVariantProduct,
            subCategoryName: e.target.value,
        })
        getBrandListVarient(e.target.value)
        setsubcategoryName(e.target.value)
    }
    const getBrandListVarient = async (val) => {
        // const { data } = await API.get(`/getProductBrand?type=0&title=ProductCatelog&catelogName=LiveStockCatelog&categoryName=` + categoryName + '&subCategoryName=' + val);
        // console.log("hkjjhj", data)
        // setbrandlist(data?.result);
        let forGet = {
            categoryName: LivestockVariantProduct.categoryName,
            subCategoryName: val,
            catalogue: catalogue,
        }
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        if (props.productCatelog) {
            await axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product-getbrand`,
                    { ...forGet, mobile_number: mobileNo },
                    config
                )
                .then((data) => {
                    console.log('brand in input', data)
                    setbrandlist(data.data.brand)
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            await axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-brand`,
                    forGet,
                    config
                )
                .then((data) => {
                    console.log('brand in input', data)
                    setbrandlist(data.data.brand)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const handlechangeproductVarientProduct = (e) => {
        setLivestockVariantProduct({
            ...LivestockVariantProduct,
            brandName: e.target.value,
        })
        getProductListVarient(e.target.value)
    }
    const getProductListVarient = async (val) => {
        // const { data } = await API.get(`/getProductCatelogProduct?type=3&title=ProductCatelog&catelogName=LiveStockCatelog&categoryName=` + categoryName + '&subCategoryName=' + subcategoryName + '&brandName=' + val);
        // console.log("hkjjhj", data)
        // setProductlist(data?.result);
        let forGet = {
            categoryName: LivestockVariantProduct.categoryName,
            subCategoryName: LivestockVariantProduct.subCategoryName,
            brandName: val,
            catalogue: catalogue,
        }
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }

        if (props.productCatelog) {
            await axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product-getcatelogue-product`,
                    { ...forGet, mobile_number: mobileNo },
                    config
                )
                .then((data) => {
                    console.log('product in input', data)
                    setProductlist(data.data.product)
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            await axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-product`,
                    forGet,
                    config
                )
                .then((data) => {
                    setProductlist(data.data.product)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }
   
  return (
          <div >

            {
                <>
                <h5>Current Product Details</h5>
                <li>Category:     { defaultProduct.categoryName}</li>
                <li>Subcategory:  { defaultProduct.subCategoryName}</li>
                <li>Brand:        { defaultProduct.brandName}</li>
                <li>Product:      { defaultProduct.productName}</li>
                {/* <li>{ CommissionLivestockProduct.categoryName}</li> */}
                </>
                
            }
                                <form
                                    class="card-body pb-2"
                                    onSubmit={(e) => e.preventDefault()}
                                >
                                    <div className="mt-3">
                                        <h5 class="card-title">
                                           Edit product here:
                                        </h5>
                                        <div class="row mb-4">
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Category
                                                    </InputLabel>
                                                    <Select
                                                        required
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="Firm"
                                                        color="success"
                                                        value={
                                                            CommissionLivestockProduct.categoryName
                                                        }
                                                        onChange={(e) =>
                                                            handleCategoryChangeProduct(
                                                                e
                                                            )
                                                        }
                                                    >
                                                        
                                                        {Categorylist &&
                                                            Categorylist.map(
                                                                (val, ind) => {
                                                                    // {console.log("Categorylist",val)}
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                val.categoryName
                                                                            }
                                                                        >
                                                                            {
                                                                                val.categoryName
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        SubCategory
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="Booking Mode"
                                                        color="success"
                                                        
                                                        value={
                                                            CommissionLivestockProduct.subCategoryName
                                                        }
                                                        onChange={(e) =>
                                                            handlechangeproductBrand(
                                                                e
                                                            )
                                                        }
                                                        // onChange={(e)=>setCommissionLivestockProduct({...CommissionLivestockProduct,subCategoryName:e.target.value})}
                                                    >
                                                      
                                                        {subCategorylist &&
                                                            subCategorylist.map(
                                                                (val, ind) => {
                                                                    //  {console.log("Categorylist",val)}
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                val.subCategoryName
                                                                            }
                                                                        >
                                                                            {
                                                                                val.subCategoryName
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Brand Name
                                                    </InputLabel>
                                                    <Selected
                                        style={{
                                            width: '270px',
                                            height: '40px',
                                        }}
                                        placeholder={'Select Brand'}
                                        // value={inputBooking.modeOfBooking}
                                        onChange={(e) => setCommissionLivestockProduct({ ...CommissionLivestockProduct, brandName: e.value,brandImage:e.image })}
              
                                        options={typeoptiob}
                                    />
                         
                                           
                                                       
                                                       
                                                </FormControl>
                                            </div>
                                            <div className='row'>
                                            <div className="col-md-3 mt-3">
                                                    <div
                                                        class="form-group row widget-3"
                                                        style={sectionDiv}
                                                    >
                                                        <section
                                                            className="container"
                                                            style={driverDP}
                                                        >
                                                            {BrandImg ===
                                                            null ? (
                                                                <p align="center">
                                                                    Upload Image
                                                                </p>
                                                            ) : (
                                                                <aside
                                                                    style={
                                                                        thumbsContainerState
                                                                    }
                                                                >
                                                                    <div
                                                                        style={
                                                                            thumb
                                                                        }
                                                                    >
                                                                        <div
                                                                            style={
                                                                                thumbInner
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    BrandImg
                                                                                }
                                                                                style={
                                                                                    img
                                                                                }
                                                                                alt="driverPhoto"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </aside>
                                                            )}
                                                        </section>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-3 d-flex">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                            marginRight: '10px',
                                                        }}
                                                    >
                                                        Upload Brand Image:
                                                    </InputLabel>
                                                    <label htmlFor="contained-button-file">
                                                        <Button
                                                            variant="contained"
                                                            component="span"
                                                            color="success"
                                                            size="small"
                                                            style={uploadBtnSm}
                                                        >
                                                            <Input
                                                                accept="image/"
                                                                id="contained-button-file"
                                                                multiple="false"
                                                                type="file"
                                                                onChange={(e) =>
                                                                    handleUploadBrandImage(
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                            Upload
                                                        </Button>
                                                    </label>
                                                </div>  
                                            </div>
                                             
                                            <div className="row">
                                                <div className="col-md-3 mt-3">
                                                    <div
                                                        class="form-group row widget-3"
                                                        style={sectionDiv}
                                                    >
                                                        <section
                                                            className="container"
                                                            style={driverDP}
                                                        >
                                                            {productImg ===
                                                            null ? (
                                                                <p align="center">
                                                                    Upload Image
                                                                </p>
                                                            ) : (
                                                                <aside
                                                                    style={
                                                                        thumbsContainerState
                                                                    }
                                                                >
                                                                    <div
                                                                        style={
                                                                            thumb
                                                                        }
                                                                    >
                                                                        <div
                                                                            style={
                                                                                thumbInner
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    productImg
                                                                                }
                                                                                style={
                                                                                    img
                                                                                }
                                                                                alt="driverPhotos"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </aside>
                                                            )}
                                                        </section>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mt-3">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                            marginTop: '90px',
                                                        }}
                                                    >
                                                        {/* Product */}
                                                    </InputLabel>
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="productName"
                                                        label="Product Name"
                                                        variant="standard"
                                                        name="productName"
                                                        value={
                                                            CommissionLivestockProduct.productName
                                                        }
                                                        onChange={(e) =>
                                                            setCommissionLivestockProduct(
                                                                {
                                                                    ...CommissionLivestockProduct,
                                                                    productName:
                                                                        e.target
                                                                            .value,
                                                                }
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-3 mt-3">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                            marginTop: '90px',
                                                        }}
                                                    >
                                                        {/* Product */}
                                                    </InputLabel>
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="hsn"
                                                        label="HSN Code"
                                                        variant="standard"
                                                        name="hsn"
                                                        value={
                                                            CommissionLivestockProduct.hsn
                                                        }
                                                        onChange={(e) =>
                                                            setCommissionLivestockProduct(
                                                                {
                                                                    ...CommissionLivestockProduct,
                                                                    hsn:
                                                                        e.target
                                                                            .value,
                                                                }
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="">
                                                <RichTextEditor
        value={editorState}
        onChange={handleChange}
      />
                                                    {/* <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Product Description
                                                    </InputLabel>
                                                    <textarea
                                                        class="form-control"
                                                        rows="3"
                                                        onChange={(e) =>
                                                            setDescription(
                                                                e.target.value
                                                            )
                                                        }
                                                        cols="30"
                                                    ></textarea> */}
                                                </div>
                                                <div className="col-md-3 mt-3">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                            marginTop: '90px',
                                                        }}
                                                    >
                                                        {/* Product */}
                                                    </InputLabel>
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="sku"
                                                        label="SKU Code"
                                                        variant="standard"
                                                        name="sku"
                                                        value={
                                                            CommissionLivestockProduct.sku
                                                        }
                                                        onChange={(e) =>
                                                            setCommissionLivestockProduct(
                                                                {
                                                                    ...CommissionLivestockProduct,
                                                                    sku:
                                                                        e.target
                                                                            .value,
                                                                }
                                                            )
                                                        }
                                                    />
                                                </div>
                                           
                                                <div className="col-md-12 mt-3 d-flex">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                            marginRight: '10px',
                                                        }}
                                                    >
                                                        Upload Product Image:
                                                    </InputLabel>
                                                    <label htmlFor="contained-button-files">
                                                        <Button
                                                            variant="contained"
                                                            component="span"
                                                            color="success"
                                                            size="small"
                                                            style={uploadBtnSm}
                                                        >
                                                            <Input
                                                                accept="image/"
                                                                id="contained-button-files"
                                                                multiple="false"
                                                                type="file"
                                                                onChange={(e) =>
                                                                    handleUploadIP(
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                            Upload
                                                        </Button>
                                                    </label>
                                                </div>
                                            </div>
                                        

                                            <div className="row justify-content-end mb-3">
                                                
                                                <div className="col-md-1">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                        onClick={() =>
                                                            handleCommissionLivestockProduct()
                                                        }
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
    
  )
}

export default ProductUpdateDialog