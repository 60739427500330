import React, { useState } from 'react'
import InputInventory from './InputInventory/InputInventory'
import LivestockInventory from './LivestockInventory/LivestockInventory'
import MachineInventory from './MachineInventory/MachineInventory'
import TransportInventory from './TransportInventory/TransportInventory'
import CropInventory from './CropInventory/CropInventory'

function Inventory() {
    const [tab, setTab] = useState(1)

    return (
        <div class="content container-fluid">
            <div class="page-header">
                <div class="row align-items-center">
                    <div class="col">
                        <h3 class="page-title">Inventory</h3>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a href="/Home">Dashboard</a>
                            </li>
                            <li class="breadcrumb-item active">Inventory</li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div class="card card-table p-10-25">
                                <div class="card-header">
                                    <h3 class="card-title">Create Inventory</h3>
                                </div>
                                <form
                                    class="card-body"
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        console.log('Form Successfully working')
                                    }}
                                >
                                    <>
                                        <div class="tab-content">
                                            <div action="#">
                                                <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                                    <li class="nav-item">
                                                        <a
                                                            class={
                                                                tab === 1
                                                                    ? 'nav-link active'
                                                                    : 'nav-link'
                                                            }
                                                            href="#bottom-justified-tab1"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(1)
                                                            }
                                                        >
                                                            Input Supplier
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab2"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(2)
                                                            }
                                                        >
                                                            Livestock
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(3)
                                                            }
                                                        >
                                                            Machine
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab4"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(4)
                                                            }
                                                        >
                                                            Transport
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link "
                                                            href="#bottom-justified-tab5"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(5)
                                                            }
                                                        >
                                                            Crop
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                </form>
                            </div>

                            {tab === 1 && <InputInventory />}

                            {tab === 2 && <LivestockInventory />}

                            {tab === 3 && <MachineInventory />}

                            {tab === 4 && <TransportInventory />}

                            {tab === 5 && <CropInventory />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Inventory
