import React from 'react'
import ChartSecond from './ChartSecond'
import Barchart from './barChart'

import { Box, Grid } from '@mui/material'
import Map from './map'
function Dashboard() {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div class="card cardhome">
                <div style={{ padding: '25px' }}>
                    <h4 class="card-title cart-header">Current Visitors</h4>
                    <div class="text-muted card-subtitle">
                        Different Devices Used to Visit
                    </div>
                </div>

                <ChartSecond />
            </div>

            <div className="dashmap card cardhome">
                <div style={{ padding: '25px' }}>
                    <h4 class="card-title cart-header">Current Visitors</h4>
                    <div class="text-muted card-subtitle">
                        Different Devices Used to Visit
                    </div>
                </div>
                <Map />
            </div>
            <div class="card cardhome">
                <div style={{ padding: '25px' }}>
                    <h4 class="card-title cart-header">Sales Overview</h4>
                    <div class="text-muted card-subtitle">
                        Most Sold Products
                    </div>
                </div>

                <Barchart />
            </div>

            {/* <ChartFirst /> */}
        </div>
    )
}

export default Dashboard
