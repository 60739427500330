import React, { useState, useEffect } from 'react'
import { API } from '../../../API/index'
import { CssTextField } from '../../Styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import axios from 'axios'
import Swal from 'sweetalert2'
import MicroEntTable from './MicroEntTable'
import $ from 'jquery'

import Selects from 'react-select'

const clearvalue = {
    firstName: '',
    lastName: '',
    mobileNumber: '',
    gender: '',
    category: '',
    dateOfBirth: '',
    language: '',
    businessType: '',
    email: '',
    alternateMobile: '',
    handicapped: '',
    availability: '',

    firmName: '',
    state: '',
    district: '',
    subDistrict: '',
    village: '',
    pincode: '',
    gstNumber: '',
    fullAddress: '',

    unit: '',
    commissionAmount: '',
    commissionCategory: '',

    bankAccountNumber: '',
    bankAccountName: '',
    ifscCode: '',
    bankName: '',
    bankBranchName: '',
}
function MicroEnterpreneur() {
    const [tab, setTab] = useState(1)
    const [states, setStates] = useState([])
    const [citylist, setcitylist] = useState([])
    const [statelist, setstatelist] = useState([])
    const [CommisionCat, setCommisionCat] = useState([])
    const [MicroENt, setMicroENt] = useState([])
    const [microEnt, setMicroEnt] = useState({
        firstName: '',
        lastName: '',
        mobileNumber: '',
        gender: '',
        category: '',
        dateOfBirth: '',
        language: '',
        businessType: '',
        email: '',
        alternateMobile: '',
        handicapped: '',
        availability: '',

        firmName: '',
        state: '',
        district: '',
        subDistrict: '',
        village: '',
        pincode: '',
        gstNumber: '',
        fullAddress: '',

        unit: '',
        commissionAmount: '',
        commissionCategory: '',

        bankAccountNumber: '',
        bankAccountName: '',
        ifscCode: '',
        bankName: '',
        bankBranchName: '',
    })

    useEffect(() => {
        getState()
        getStateList()
        getCommisionCat()
        // getMicroENt();
    }, [])
    const getCommisionCat = async () => {
        const { data } = await API.get(
            `/getCommissionCatelog?title=CommissionCatelog&catelogName=MicroEnterpreneurCatelog&type=2`
        )
        // console.log("hkjjhj",data)
        setCommisionCat(data?.result)
        console.log('firm search', data)
    }
    const CommisionUnit =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.commissionUnit,
            label: item.commissionUnit,
        }))
    const CommisionAmount =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.commissionAmount,
            label: item.commissionAmount,
        }))
    const commisiontype =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.subCategoryName,
            label: item.subCategoryName,
        }))
    const getStateList = async () => {
        const { data } = await API.get(`/getStateList`)
        // console.log("hkjjhj",data)
        setstatelist(data?.result)
        console.log('firm search', data)
    }
    const statelistoption =
        statelist &&
        statelist.map((item, ind) => ({
            value: item,
            label: item,
        }))
    const statechane = (e) => {
        console.log('hkjjhj', e.value)
        setMicroEnt({ ...microEnt, ['state']: e.value })
        // setInputs({ ...inputs, ['state']: e.value })
        // onChange={(e) => setInputs({ ...inputs, ['state']: e.target.value })}
        getCityList(e.value)
    }
    const getCityList = async (val) => {
        const { data } = await API.get(`/getCityList?stateName=` + val)
        // console.log("hkjjhj",data)
        setcitylist(data?.result)
        console.log('firm search', data)
    }
    const citylistoption =
        citylist &&
        citylist.map((item, ind) => ({
            value: item,
            label: item,
        }))
    const getState = async () => {
        const { data } = await axios.get(
            'http://65.0.145.198:4080/api/v1/getStateList'
        )
        setStates(data?.result)
        console.log(data?.result)
    }
    const getMicroENt = async () => {
        const { data } = await API.get(`/getMicroEntrepreneur?type=0`)
        setMicroENt(data?.result)
    }

    const onSubmitMicroEnt = async (e) => {
        e.preventDefault()
        console.log('Micro Enterpreneur', microEnt)
        const { data } = await API.post('/microEntrepreneurRegister', microEnt)
        if (data?.success) {
            Swal.fire({
                icon: 'success',
                iconColor: '#28a745',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
            console.log(data)
            setMicroEnt(clearvalue)
            setTab(1)
            //  setMicroEnt(clearMicroEnt);
        } else {
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    const handleMobVal = (e) => {
        setMicroEnt({ ...microEnt, [e.target.name]: e.target.value })
        if (e.target.value.length == 10) {
            mobilecheck(e.target.value)
        } else if (e.target.value.length > 10) {
            console.log('e.target.value.length', e.target.value.length)
            setMicroEnt({ ...microEnt, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: 'Mobile length should be 10',
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const mobilecheck = async (number) => {
        const { data } = await API.get(`/checkNumber?number=` + number)
        console.log('data', data)
        if (data.success == true) {
            // setFarmer({ ...farmer, [e.target.name]: number })
        } else {
            setMicroEnt({ ...microEnt, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data.result,
                showConfirmButton: false,
                timer: 2000,
            })
        }
        // setDetails(data?.result);
    }

    const handleMobilesearch = (e) => {
        console.log('e', e.target.value)
        if (e.target.value.length == 10) {
            mobilechecksearch(e.target.value)
        } else {
            setMicroENt([])
        }
    }
    const mobilechecksearch = async (number) => {
        const { data } = await API.get(
            `/searchMicroEntrepreneur?search=` + number
        )
        console.log('data', data)
        if (data.success == true) {
            let arr = []
            arr.push(data?.result)
            setMicroENt(arr)
        } else {
        }
    }
    $(function () {
        $('#txtNumeric').keydown(function (e) {
            if (e.shiftKey || e.ctrlKey || e.altKey) {
                e.preventDefault()
            } else {
                var key = e.keyCode

                if (
                    !(
                        key == 8 ||
                        key == 32 ||
                        key == 46 ||
                        (key >= 35 && key <= 40) ||
                        (key >= 65 && key <= 90)
                    )
                ) {
                    e.preventDefault()
                }
            }
        })
    })

    return (
        <div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card card-table p-10-25">
                        <div class="card-header">
                            <h5 class="card-title">Create MicroEntrepreneur</h5>
                        </div>
                        <div class="card-body">
                            <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                                <li class="nav-item">
                                    <a
                                        class={
                                            tab === 1
                                                ? 'nav-link active'
                                                : 'nav-link'
                                        }
                                    >
                                        Personal Info
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a
                                        class={
                                            tab === 2
                                                ? 'nav-link active'
                                                : 'nav-link'
                                        }
                                    >
                                        Contact Details
                                    </a>
                                </li>
                                {/* <li class="nav-item"><a class={tab === 3 ? "nav-link active" : "nav-link"}>Commission Details</a></li> */}
                                <li class="nav-item">
                                    <a
                                        class={
                                            tab === 4
                                                ? 'nav-link active'
                                                : 'nav-link'
                                        }
                                    >
                                        Bank Account Info
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                {/* Personal Info */}
                                <div
                                    class="tab-pane show active"
                                    id="bottom-justified-tab1"
                                >
                                    {tab === 1 && (
                                        <form onSubmit={() => setTab(2)}>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id=""
                                                        label="First Name"
                                                        variant="standard"
                                                        name="firstName"
                                                        value={
                                                            microEnt.firstName
                                                        }
                                                        onChange={(e) =>
                                                            setMicroEnt({
                                                                ...microEnt,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id=""
                                                        label="Last Name"
                                                        variant="standard"
                                                        name="lastName"
                                                        value={
                                                            microEnt.lastName
                                                        }
                                                        onChange={(e) =>
                                                            setMicroEnt({
                                                                ...microEnt,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id=""
                                                        label="Mobile Number"
                                                        variant="standard"
                                                        name="mobileNumber"
                                                        value={
                                                            microEnt.mobileNumber
                                                        }
                                                        onChange={(e) =>
                                                            handleMobVal(e)
                                                        }
                                                        // onChange={(e) => setMicroEnt({ ...microEnt, [e.target.name]: e.target.value })}
                                                    />
                                                </div>
                                                {/* <div class="col-md-3">
                          <CssTextField
                            fullWidth
                            required
                            id=""
                            label="Gender"
                            variant="standard"
                            name="gender"
                            value={microEnt.gender}
                            onChange={(e) => setMicroEnt({ ...microEnt, [e.target.name]: e.target.value })}
                          />
                        </div> */}
                                                <div class="col-md-3">
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        className="mt-3"
                                                    >
                                                        <InputLabel
                                                            id="demo-simple-select-standard-label"
                                                            style={{
                                                                color: 'black',
                                                            }}
                                                        >
                                                            Gender*
                                                        </InputLabel>
                                                        <Select
                                                            required
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            label="Gender"
                                                            color="success"
                                                            name="gender"
                                                            value={
                                                                microEnt.gender
                                                            }
                                                            onChange={(e) =>
                                                                setMicroEnt({
                                                                    ...microEnt,
                                                                    [e.target
                                                                        .name]:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            <MenuItem value="Male">
                                                                Male
                                                            </MenuItem>
                                                            <MenuItem value="Female">
                                                                Female
                                                            </MenuItem>
                                                            <MenuItem value="Others">
                                                                Others
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                {/* <div class="col-md-3">
                          <FormControl variant="standard" fullWidth className="mt-3">
                            <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
                              Category
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              label="Category"
                              color="success"
                              name="category"
                              value={microEnt.category}
                              onChange={(e) => setMicroEnt({ ...microEnt, [e.target.name]: e.target.value })}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="Farmer">Farmer</MenuItem>
                              <MenuItem value="Middleman">Middleman</MenuItem>
                              <MenuItem value="Micro Enterpreneur">Micro Enterpreneur</MenuItem>
                            </Select>
                          </FormControl>
                        </div> */}
                                                <div class="col-md-3">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        DOB
                                                    </InputLabel>
                                                    <CssTextField
                                                        type="date"
                                                        fullWidth
                                                        variant="standard"
                                                        style={{
                                                            marginTop: '10px',
                                                        }}
                                                        name="dateOfBirth"
                                                        value={
                                                            microEnt.dateOfBirth
                                                        }
                                                        onChange={(e) =>
                                                            setMicroEnt({
                                                                ...microEnt,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        className="mt-3"
                                                    >
                                                        <InputLabel
                                                            id="demo-simple-select-standard-label"
                                                            style={{
                                                                color: 'black',
                                                            }}
                                                        >
                                                            Language
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            label="Language"
                                                            color="success"
                                                            name="language"
                                                            value={
                                                                microEnt.language
                                                            }
                                                            onChange={(e) =>
                                                                setMicroEnt({
                                                                    ...microEnt,
                                                                    [e.target
                                                                        .name]:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            <MenuItem value="0">
                                                                Select Language
                                                            </MenuItem>
                                                            <MenuItem value="Hindi">
                                                                Hindi
                                                            </MenuItem>
                                                            <MenuItem value="Bengali">
                                                                Bengali
                                                            </MenuItem>
                                                            <MenuItem value="Urdu">
                                                                Urdu
                                                            </MenuItem>
                                                            <MenuItem value="Punjabi">
                                                                Punjabi
                                                            </MenuItem>
                                                            <MenuItem value="Marathi">
                                                                Marathi
                                                            </MenuItem>
                                                            <MenuItem value="Telugu">
                                                                Telugu
                                                            </MenuItem>
                                                            <MenuItem value="Tamil">
                                                                Tamil
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                {/* <div class="col-md-3">
                          <FormControl variant="standard" fullWidth className="mt-3">
                            <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
                              Business Type*
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="businessType"
                              label="Business Type"
                              color="success"
                              name="businessType"
                              value={microEnt.businessType}
                              onChange={(e) => setMicroEnt({ ...microEnt, [e.target.name]: e.target.value })}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="Farmer">Farmer</MenuItem>
                              <MenuItem value="Middleman">Middleman</MenuItem>
                              <MenuItem value="Micro Enterpreneur">Micro Enterpreneur</MenuItem>
                            </Select>
                          </FormControl>
                        </div> */}
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id="email"
                                                        label="Email ID"
                                                        variant="standard"
                                                        name="email"
                                                        value={microEnt.email}
                                                        onChange={(e) =>
                                                            setMicroEnt({
                                                                ...microEnt,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                {/* <div class="col-md-3">
                          <CssTextField
                            fullWidth
                            required
                            id="altMobile"
                            label="Altenate Mobile"
                            variant="standard"
                            name="alternateMobile"
                            value={microEnt.alternateMobile}
                            onChange={(e) => setMicroEnt({ ...microEnt, [e.target.name]: e.target.value })}
                          />
                        </div>
                        <div class="col-md-3">
                          <FormControl variant="standard" fullWidth className="mt-3">
                            <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
                              Handicapped
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="handicapped"
                              label="Handicapped"
                              color="success"
                              name="handicapped"
                              value={microEnt.handicapped}
                              onChange={(e) => setMicroEnt({ ...microEnt, [e.target.name]: e.target.value })}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="true">Yes</MenuItem>
                              <MenuItem value="false">No</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div class="col-md-3">
                          <CssTextField
                            fullWidth
                            id="availability"
                            label="Availability"
                            variant="standard"
                            name="availability"
                            value={microEnt.availability}
                            onChange={(e) => setMicroEnt({ ...microEnt, [e.target.name]: e.target.value })}
                          />
                        </div> */}
                                                <div class="col-md-12 text-end mt-2 mb-2">
                                                    <button
                                                        type="submit"
                                                        class=" w-16 btn btn-primary"
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>

                                {/* Firm Details */}
                                <div
                                    class="tab-pane show active"
                                    id="bottom-justified-tab2"
                                >
                                    {tab === 2 && (
                                        <form onSubmit={() => setTab(4)}>
                                            <div class="row">
                                                {/* <div class="col-md-3">
                          <CssTextField
                            fullWidth id="standard-basic"
                            label="Firm Name"
                            variant="standard"
                            name="firmName"
                            value={microEnt.firmName}
                            onChange={(e) => setMicroEnt({ ...microEnt, [e.target.name]: e.target.value })}
                          />
                        </div> */}
                                                <div
                                                    class="col-md-3"
                                                    style={{ marginTop: '2%' }}
                                                >
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        className="mt-3"
                                                    >
                                                        {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
                              State
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              label="State"
                              color="success"
                              name="state"
                              value={microEnt.state}
                              onChange={(e) => setMicroEnt({ ...microEnt, [e.target.name]: e.target.value })}
                            >
                              <MenuItem value="">
                                <em> - Select State - </em>
                              </MenuItem>
                              <MenuItem value="Assam">Assam</MenuItem>
                              <MenuItem value="Bihar">Bihar</MenuItem>
                              <MenuItem value="Chattisgarh">Chattisgarh</MenuItem>
                              <MenuItem value="Delhi">Delhi</MenuItem>
                              <MenuItem value="Kolkata">Kolkata</MenuItem>
                              <MenuItem value="Mumbai">Mumbai</MenuItem>
                              <MenuItem value="TamilNadu">TamilNadu</MenuItem>
                            </Select> */}
                                                        <Selects
                                                            style={{
                                                                width: '270px',
                                                                height: '40px',
                                                            }}
                                                            onChange={(e) =>
                                                                statechane(e)
                                                            }
                                                            // onChange={(e) => setFarmer({ ...farmer, ['state']: e.value })}
                                                            options={
                                                                statelistoption
                                                            }
                                                            placeholder={
                                                                'Select State'
                                                            }
                                                            //  value={inputBooking.firm}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div
                                                    class="col-md-3"
                                                    style={{ marginTop: '4%' }}
                                                >
                                                    <Selects
                                                        style={{
                                                            width: '270px',
                                                            height: '40px',
                                                        }}
                                                        onChange={(e) =>
                                                            setMicroEnt({
                                                                ...microEnt,
                                                                ['district']:
                                                                    e.value,
                                                            })
                                                        }
                                                        options={citylistoption}
                                                        placeholder={
                                                            'Select district'
                                                        }
                                                        //  value={inputBooking.firm}
                                                    />
                                                    {/* <CssTextField
                            fullWidth id="standard-basic"
                            label="District"
                            variant="standard"
                            name="district"
                            value={microEnt.district}
                            onChange={(e) => setMicroEnt({ ...microEnt, [e.target.name]: e.target.value })}
                          /> */}
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        id="standard-basic"
                                                        label="Sub District"
                                                        variant="standard"
                                                        name="subDistrict"
                                                        value={
                                                            microEnt.subDistrict
                                                        }
                                                        onChange={(e) =>
                                                            setMicroEnt({
                                                                ...microEnt,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        id="standard-basic"
                                                        label="Village"
                                                        variant="standard"
                                                        name="village"
                                                        value={microEnt.village}
                                                        onChange={(e) =>
                                                            setMicroEnt({
                                                                ...microEnt,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        id="standard-basic"
                                                        label="Pincode"
                                                        variant="standard"
                                                        name="pincode"
                                                        value={microEnt.pincode}
                                                        onChange={(e) =>
                                                            setMicroEnt({
                                                                ...microEnt,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                {/* <div class="col-md-3">
                          <FormControl variant="standard" fullWidth className="mt-3">
                            <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
                              Category
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              label="Category"
                              color="success"
                              name="category"
                              value={microEnt.category}
                              onChange={(e) => setMicroEnt({ ...microEnt, [e.target.name]: e.target.value })}
                            >
                              <MenuItem value="">
                                <em> - Select Category - </em>
                              </MenuItem>
                              <MenuItem value="Seed">Seed</MenuItem>
                              <MenuItem value="Pesticides">Pesticides</MenuItem>
                              <MenuItem value="Fertilizers">Fertilizers</MenuItem>
                            </Select>
                          </FormControl>
                        </div> */}
                                                {/* <div class="col-md-3">
                          <CssTextField
                            fullWidth id="standard-basic"
                            label="GST"
                            variant="standard"
                            name="gstNumber"
                            value={microEnt.gstNumber}
                            onChange={(e) => setMicroEnt({ ...microEnt, [e.target.name]: e.target.value })}
                          />
                        </div> */}
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        id="standard-basic"
                                                        label="Full Address"
                                                        variant="standard"
                                                        name="fullAddress"
                                                        value={
                                                            microEnt.fullAddress
                                                        }
                                                        onChange={(e) =>
                                                            setMicroEnt({
                                                                ...microEnt,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-12 text-end mt-2 mb-2">
                                                    <button
                                                        className="w-16 btn btn-secondary"
                                                        style={{
                                                            marginRight: '10px',
                                                        }}
                                                        onClick={(e) =>
                                                            setTab(1)
                                                        }
                                                    >
                                                        Previous
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        class=" w-16 btn btn-primary"
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>

                                {/* Commission Details */}
                                {/* <div class="tab-pane show active" id="bottom-justified-tab4">
                  {tab === 3 &&
                    <form onSubmit={() => setTab(4)}>
                      <div class="row">
                        <div class="col-md-4" style={{marginTop:'2%'}}>
                          <FormControl variant="standard" fullWidth className="mt-3">
                           
                             <Selects style={{ width: "270px", height: "40px" }}
                              onChange={(e) => setMicroEnt({ ...microEnt, ['unit']: e.value })}
                                // onChange={(e) => setInputs({ ...inputs, ['unit']: e.value })}
                                options={CommisionUnit}
                                placeholder={'Select Unit'}
                              //  value={inputBooking.firm}
                              />
                          </FormControl>
                        </div>
                        <div class="col-md-4" style={{marginTop:'3.5%'}}>
                        <Selects style={{ width: "270px", height: "40px" }}
                          onChange={(e) => setMicroEnt({ ...microEnt,['commissionAmount']: e.value })}
                                // onChange={(e) => setInputs({ ...inputs, ['commissionAmount']: e.value })}
                                options={CommisionAmount}
                                placeholder={'Select commissionAmount'}
                              //  value={inputBooking.firm}
                              />
                         
                        </div>
                        <div class="col-md-4" style={{marginTop:'2%'}}>
                          <FormControl variant="standard" fullWidth className="mt-3">
                           
                            <Selects style={{ width: "270px", height: "40px" }}
                             onChange={(e) => setMicroEnt({ ...microEnt, ['category']: e.value  })}
                                // onChange={(e) => setInputs({ ...inputs, ['category']: e.value })}
                                options={commisiontype}
                                placeholder={'Select  commision type'}
                              //  value={inputBooking.firm}
                              />
                          </FormControl>
                        </div>

                        <div class="col-md-12 text-end mt-2 mb-2">
                          <button className="w-10 btn btn-secondary" style={{ marginRight: '10px' }} onClick={e => setTab(2)}>Previous</button>
                          <button type="submit" class=" w-10 btn btn-primary">Next</button>
                        </div>
                      </div>
                    </form>
                  }
                </div> */}

                                {/* Bank Account Info */}
                                <div
                                    class="tab-pane show active"
                                    id="bottom-justified-tab3"
                                >
                                    {tab === 4 && (
                                        <form onSubmit={onSubmitMicroEnt}>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id="accNum"
                                                        label="Bank Acc"
                                                        variant="standard"
                                                        type="number"
                                                        name="bankAccountNumber"
                                                        value={
                                                            microEnt.bankAccountNumber
                                                        }
                                                        onChange={(e) =>
                                                            setMicroEnt({
                                                                ...microEnt,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>

                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id="accName"
                                                        label="Account Name"
                                                        variant="standard"
                                                        name="bankAccountName"
                                                        value={
                                                            microEnt.bankAccountName
                                                        }
                                                        onChange={(e) =>
                                                            setMicroEnt({
                                                                ...microEnt,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id="ifscCode"
                                                        label="IFSC Code"
                                                        variant="standard"
                                                        name="ifscCode"
                                                        value={
                                                            microEnt.ifscCode
                                                        }
                                                        onChange={(e) =>
                                                            setMicroEnt({
                                                                ...microEnt,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id="acc"
                                                        label="Bank Name"
                                                        variant="standard"
                                                        pattern="[a-zA-Z]*"
                                                        type="text"
                                                        id="txtNumeric"
                                                        name="bankName"
                                                        value={
                                                            microEnt.bankName
                                                        }
                                                        onChange={(e) =>
                                                            setMicroEnt({
                                                                ...microEnt,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id="branchName"
                                                        label="Branch Name"
                                                        variant="standard"
                                                        name="bankBranchName"
                                                        value={
                                                            microEnt.bankBranchName
                                                        }
                                                        onChange={(e) =>
                                                            setMicroEnt({
                                                                ...microEnt,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-12 text-end mt-2 mb-2">
                                                    <button
                                                        className="w-10 btn btn-secondary"
                                                        style={{
                                                            marginRight: '10px',
                                                        }}
                                                        onClick={(e) =>
                                                            setTab(3)
                                                        }
                                                    >
                                                        Previous
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        class=" w-16 btn btn-primary"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <h3 className="mb-3">All Micro EntrePreneurs</h3>
                    <div class="card card-table">
                        <div class="card-body">
                            <div class="table-responsive">
                                <div
                                    id="DataTables_Table_0_wrapper"
                                    class="dataTables_wrapper dt-bootstrap4 no-footer"
                                >
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <h3 className="mt-3">
                                                Micro EntrePreneurs
                                            </h3>
                                            <div
                                                class="dataTables_length"
                                                id="DataTables_Table_0_length"
                                            >
                                                <hr />
                                                <label>
                                                    Show
                                                    <select
                                                        name="DataTables_Table_0_length"
                                                        aria-controls="DataTables_Table_0"
                                                        class="custom-select custom-select-sm form-control form-control-sm"
                                                    >
                                                        <option value="10">
                                                            10
                                                        </option>
                                                        <option value="25">
                                                            25
                                                        </option>
                                                        <option value="50">
                                                            50
                                                        </option>
                                                        <option value="100">
                                                            100
                                                        </option>
                                                    </select>
                                                    entries
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 position_right">
                                            <div class="form-group">
                                                {/* <label>Search</label> */}
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Search"
                                                    onChange={(e) =>
                                                        handleMobilesearch(e)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <MicroEntTable inputs={MicroENt} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MicroEnterpreneur
