import React from 'react'

function BulkUpload() {
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Bulk Upload</h5>
                </div>
                <div class="card-body">
                    <form>
                        <div class="row form-group">
                            <div class="col-sm-12">
                                <h6>
                                    Upload Bulk Items
                                    <a href="#" class="down_scv">
                                        Download Sample CSV file
                                    </a>
                                </h6>
                            </div>
                        </div>
                        <div class="row form-group mlt-5 ">
                            <div class="col-sm-7">
                                <div class="row form-group">
                                    <label
                                        for="addressline1"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        Choose File
                                    </label>
                                    <input class="form-control" type="file" />
                                </div>

                                <div class="row form-group">
                                    <label
                                        for="addressline1"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        Select Store
                                    </label>
                                    <select class="form-select">
                                        <option>-- All Store --</option>
                                        <option>Option 1</option>
                                        <option>Option 2</option>
                                        <option>Option 3</option>
                                        <option>Option 4</option>
                                        <option>Option 5</option>
                                    </select>
                                </div>
                                <div class="row form-group">
                                    <div class="text-left mt-4">
                                        <button
                                            type="submit"
                                            class="btn btn-primary"
                                        >
                                            Upload
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default BulkUpload
