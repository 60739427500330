import React, { useState, useEffect } from 'react'
import { CssTextField } from '../../Styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Selects from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Input from '@mui/material/Input'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import { API } from '../../../API/index'
import Swal from 'sweetalert2'
import $ from 'jquery'
import Select from 'react-select'
import Map from '../Map'
import axios from 'axios'

import OtpBox from '../../../components/OtpBox'
import { initializeApp } from 'firebase/app'

import { getAuth, signInWithPhoneNumber,PhoneAuthProvider,createUserWithEmailAndPassword,signInWithEmailAndPassword, RecaptchaVerifier } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
const firebaseConfig = {
    apiKey: 'AIzaSyBCgGkH7S3OL-fNKGnQmfMQzbJyY9AZ66o',
    authDomain: 'krishivan-app.firebaseapp.com',
    projectId: 'krishivan-app',
    storageBucket: 'krishivan-app.appspot.com',
    messagingSenderId: '1018772914539',
    appId: '1:1018772914539:web:3f3d750142694da0a74c2e',
    measurementId: 'G-CMCSVD54TQ',
}


const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const names = ['Wheat', 'Rice', 'Barley', 'Cereal', 'Tomato', 'Oat']
const farmerClear = {
    firstName: '',
    lastName: '',
    gender: '',
    mobileNumber: '',
    category: '',
    dateOfBirth: '',
    AadharNo: '',
    language: '',
    farming_mode: '',
    dob: '',

    state: '',
    district: '',
    subDistrict: '',
    village: '',
    pincode: '',
    fullAddress: '',

    selectUnit: '',
    totalLand: '',
    leasedInLand: '',
    leasedOutLand: '',
    soilTest: false,

    typeOfCrop: [],
    irrigationSource: '',
    farmEquipments: '',

    bankAccountNumber: '',
    bankAccountName: '',
    ifscCode: '',
    bankName: '',
    bankBranchName: '',
}

function Farmers(props) {
    const [bookAs, setBookAs] = useState(1)
    const [Formerinfo, setFormerinfo] = useState({})
    const [verificationCode, setVerificationCode] = useState('');
    const [verificationId, setVerificationId] = useState('');
    const [booking, setBooking] = useState(props.booking)
    const [tab, setTab] = useState(1)
    const [create, setCreate] = useState(props.create)
    const [cropTypes, setCropTypes] = React.useState([])
    const [citylist, setcitylist] = useState([])
    const [CropProduct, setCropProduct] = useState([])
    const [cropImage, setCropImage] = useState('')
    const [otpWindow, setOtpWindow] = useState(false)
    const [createdByid,setCreatedById]=useState('')
    const [createdByRole,setCreatedByRole]=useState("")
    const [farmer, setFarmer] = useState({
        firstName: '',
        lastName: '',
        gender: '',
        mobileNumber: '',
        category: '',
        dob: '',
        AadharNo: '',
        language: '',
        farming_mode: '',
        state: '',
        district: '',
        subDistrict: '',
        village: '',
        pincode: '',
        fullAddress: '',

        typeOfCrop: cropTypes,
        irrigationSource: '',
        farmEquipments: '',
        selectUnit: '',
        totalLand: '',
        soilType: '',
        sowingDate: '',
        soilTest: false,

        bankAccountNumber: '',
        bankAccountName: '',
        ifscCode: '',
        bankName: '',
        bankBranchName: '',
    })

    useEffect(() => {
        // getFarmer();
        // getStateList()
        setUpRecaptcha();

        getCropProduct()
    }, [])
   

      const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);


const setUpRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",{
        size: "invisible",
        callback: function (response) {
            console.log("recature resolved",response)
           
        }
      },auth
    );
  };
const onSubmitFarmer = async () => {
    try {
        if (!window.recaptchaVerifier) {
            setUpRecaptcha();
          }
    
      const confirmationResult = await signInWithPhoneNumber(auth,  `+91${farmer.mobileNumber}`,  window.recaptchaVerifier);
      setVerificationId(confirmationResult.verificationId);
      window.confirmationResult = confirmationResult;
      var verificationId = window.prompt("Enter otp")
      confirmationResult
          .confirm(verificationId)
          .then(function (result) {
              // User signed in successfully.
              var user = result.user;
              syncOtpSubmit()
              user.getIdToken().then(idToken => {
                  window.localStorage.setItem('idToken', idToken);
                  console.log(idToken);
              });
          })
    //   setOtpWindow(true)

      // Handle UI or navigate to OTP entry screen
    } catch (error) {
        alert("Error sending verification code")
      console.error('Error sending verification code:', error);
    }
  };
  const handleVerifyCode = async (code) => {
    console.log(code)
    try {
        const provider = new PhoneAuthProvider(auth);

      const credential =auth.PhoneAuthProvider.credential(verificationId, code);
      // Now you have the credential, you can use it as needed
      console.log('Verification Credential:', credential);
      // Handle UI or store the verified phone number as needed
    } catch (error) {
      console.error('Error verifying code:', error);
    }
  };


    const onSubmitFarmers = async (e) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }

        const dataForOtp = {
            mobile_number: farmer.mobileNumber,
            role: 'farmer',
            created_by: 'admin',
        }

        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/auth-function/otp`,
                dataForOtp,
                config
            )
            .then((res) => {
                setOtpWindow(true)
            })
        setCropTypes([])
    }

    const getCropProduct = async () => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-crops`,
            config
        )
        // console.log("hkjjhj",data)
        setCropProduct(data?.crops)
        console.log('firm search', data)
    }
    const CropProductoption =
        CropProduct &&
        CropProduct.map((item, ind) => ({
            value: item.productName,
            label: item.productName,
            image: item.productImage,
        }))
    // const statelistoption = statelist && statelist.map((item, ind) => ({
    //   "value": item,
    //   "label": item
    // })
    // )``````````````````````````````````
    const handleChange = (event) => {
        var image = CropProductoption.filter(
            (item) => item.value === event.target.value[0]
        )
        setCropImage(image[0].image)

        const {
            target: { value },
        } = event
        console.log('value', value)
        setCropTypes(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    const gender = [
        {
            value: 'Male',
            label: 'Male',
        },
        {
            value: 'Female',
            label: 'Female',
        },
        {
            value: 'Others',
            label: 'Others',
        },
    ]

    const LanguageVale = [
        {
            value: 'Agriculture',
            label: 'Agriculture',
        },
        {
            value: 'Horticulture',
            label: 'Horticulture',
        },
        {
            value: 'Aquaculture',
            label: 'Aquaculture',
        },
        {
            value: 'Animal Husbandary',
            label: 'Animal Husbandary',
        },
        {
            value: 'Nursery',
            label: 'Nursery',
        },
    ]

    const IrrigationSource = [
        {
            value: 'Canal',
            label: 'Canal',
        },
        {
            value: 'River',
            label: 'River',
        },
        {
            value: 'Bore',
            label: 'Bore',
        },
        {
            value: 'Well',
            label: 'Well',
        },
    ]

    const Unitvalue = [
        {
            value: 'HECTARE',
            label: 'HECTARE',
        },
        {
            value: 'ACRE',
            label: 'ACRE',
        },
        {
            value: 'CENT',
            label: 'CENT',
        },
        {
            value: 'KATHA',
            label: 'KATHA',
        },
        {
            value: 'BIGHA',
            label: 'BIGHA',
        },
        {
            value: 'GUNTA',
            label: 'GUNTA',
        },
    ]
    const SoilType = [
        {
            value: 'Alluvial soil',
            label: 'Alluvial soil',
        },
        {
            value: 'Black Soil',
            label: 'Black Soil',
        },
        {
            value: 'Desert Soil',
            label: 'Desert Soil',
        },
        {
            value: 'Laterita Soil',
            label: 'Laterita Soil',
        },
        {
            value: 'Mountain Soil',
            label: 'Mountain Soil',
        },
        {
            value: 'Red Soil',
            label: 'Red Soil',
        },
    ]
    const farmEquipmentsValue = [
        {
            value: 'Tractor',
            label: 'Tractor',
        },
        {
            value: 'Tractor with implements',
            label: 'Tractor with implements',
        },
        {
            value: 'No',
            label: 'No',
        },
    ]
    const SoilTest = [
        {
            value: true,
            label: 'YES',
        },
        {
            value: false,
            label: 'NO',
        },
    ]

    const handleMobVal = (e) => {
        setFarmer({ ...farmer, [e.target.name]: e.target.value })
        if (e.target.value.length == 10) {
            // mobilecheck(e.target.value)
        } else if (e.target.value.length > 10) {
            console.log('e.target.value.length', e.target.value.length)
            setFarmer({ ...farmer, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: 'Mobile length should be 10',
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const mobilecheck = async (number) => {
        const { data } = await API.get(`/checkNumber?number=` + number)
        console.log('data', data)
        if (data.success == true) {
            // setFarmer({ ...farmer, [e.target.name]: number })
        } else {
            setFarmer({ ...farmer, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data.result,
                showConfirmButton: false,
                timer: 2000,
            })
        }
        // setDetails(data?.result);
    }
    $(function () {
        $('#txtNumeric').keydown(function (e) {
            if (e.shiftKey || e.ctrlKey || e.altKey) {
                e.preventDefault()
            } else {
                var key = e.keyCode

                if (
                    !(
                        key == 8 ||
                        key == 32 ||
                        key == 46 ||
                        (key >= 35 && key <= 40) ||
                        (key >= 65 && key <= 90)
                    )
                ) {
                    e.preventDefault()
                }
            }
        })
    })
    const statechane = (e) => {
        console.log('hkjjhj', e.value)
        setFarmer({ ...farmer, ['state']: e.value })
        getCityList(e.value)
    }
    const getCityList = async (val) => {
        const { data } = await API.get(`/getCityList?stateName=` + val)
        // console.log("hkjjhj",data)
        setcitylist(data?.result)
        console.log('firm search', data)
    }
    const citylistoption =
        citylist &&
        citylist.map((item, ind) => ({
            value: item,
            label: item,
        }))

    const mapdata = (data) => {
        console.log('ran')
        setFarmer({
            ...farmer,
            state: data.state,
            district: data.district,
            village: data.village,
            pincode: data.pincode,
            address: data.address,
            fullAddress: data.address,
            lat: data.latitude,
            lng: data.longitude,
        })
    }

    async function  syncOtpSubmit () {
        setOtpWindow(false)
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const email = farmer.mobileNumber+"@krishivan.com"
        const password= "123@krishivan.com"

        // const userCredential = await createUserWithEmailAndPassword(auth,email, password);
        // User successfully registered
        // const user = userCredential.user;
//         console.log('User registered:', user);
//   if(user.uid){
    var farmerData = {
        user_id:"",
        first_name: farmer.firstName,
        last_name: farmer.lastName,
        gender: farmer.gender,
        dob: farmer.dob,
        role: 'farmer',
        mobile_number: farmer.mobileNumber,
        coordinates: [farmer.lat, farmer.lng],
        photo: 'test',
        language: farmer.language,
        farming_mode: farmer.farming_mode,
        state: farmer.state,
        district: farmer.district,
        sub_distric: farmer.district,
        village: farmer.district || farmer.village,
        pincode: +farmer.pincode,
        full_address: farmer.fullAddress,
        farm_info: {
            corp_img: cropImage || 'test',
            irrigation_source: farmer.irrigationSource,
            farm_equipements: farmer.farmEquipments,
            unit: farmer.selectUnit,
            total_land: farmer.totalLand,
            soil_type: farmer.soilType,
            soil_test: farmer.soilTest.toString(),
            sowing_date: farmer.sowingDate,
        },
        created_by: createdByRole,
        createdById:createdByid
    }

    axios
        .post(
            `${process.env.REACT_APP_API_ENDPOINT}/auth-function/farmers`,
            farmerData,
            config
        )
        .then((res) => {
            Swal.fire({
                icon: 'success',
                iconColor: '#28a745',
                title: 'Farmer Registered Successfully',
                showConfirmButton: false,
                timer: 2000,
            })
            setFarmer(farmerClear)
            setCropTypes([])
            setTab(1)
        })
        .catch((err) =>
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: 'error occured!',
                showConfirmButton: false,
                timer: 2000,
            })
        )
    setFarmer(farmerClear)
  }
       
    
    
    const handleFarmer = (e) => {
        if (e.target.value.length == 10) {
            getFormerinfo(e.target.value)
        } else {
            setFormerinfo({})
        }
    }
    const getFormerinfo = async (Mob) => {
        const token = localStorage.getItem('token')
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        const farmerNumber = {
            mobile_no: Mob,
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/register-details`,
                farmerNumber,
                config
            )
            .then((res) => {
                const d = res.data.data.data
                localStorage.setItem('FormerId', d.user_id)
                setCreatedById(d.user_id)
                setCreatedByRole(d.role)
                localStorage.setItem('microId', '')
                setFormerinfo(d)
            })
            .catch((err) => {
                console.log(err)
                setFormerinfo({})
            })
    }
    return (
        <>
        <div class="card card-table p-10-25">
                            <div class="card-header">
                                <h5 class="card-title"> Info</h5>
                            </div>
           <div class="tab-content">
                                        <div action="#">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="farmerNum"
                                                        //  label="Farmer Number"
                                                        placeholder="Farmer Number"
                                                        variant="standard"
                                                        //  value={}
                                                        onChange={(e) =>
                                                            handleFarmer(e)
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.role
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.id
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.first_name
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerState"
                                                        //  label="State"
                                                        placeholder="State"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.state
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="farmerDistrict"
                                                        disabled
                                                        //  label="District"
                                                        placeholder="District"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.district
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        disabled
                                                        fullWidth
                                                        id="farmerVillage"
                                                        //  label="Village"
                                                        placeholder="Village"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.village
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        disabled
                                                        fullWidth
                                                        id="farmerPincode"
                                                        //  label="Pincode"
                                                        placeholder="Pincode"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.pincode
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>
                                          
                                        </div>
                                        </div>

                                    </div>
            {otpWindow ? (
                <OtpBox
                    mobileNumber={farmer.mobileNumber}
                    syncOtpSubmit={syncOtpSubmit}
                    verificationCode={verificationId}
                    handleVerifyCode={handleVerifyCode}
                />
            ) : (
                booking === false &&
                create === '1' && (
                    <div class="row">
                              <div id="recaptcha-container"></div>

                        <div class="col-sm-12">
                            <div class="card card-table p-10-25">
                                <div class="card-header">
                                    <h5 class="card-title">Create Farmer</h5>
                                </div>
                                <div class="card-body">
                                    <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                                        <li class="nav-item">
                                            <a
                                                class={
                                                    tab === 1
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                Personal Info
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                class={
                                                    tab === 2
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                Contact Details
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                class={
                                                    tab === 4
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                Farm Info
                                            </a>
                                        </li>
                                    </ul>

                                    <div class="tab-content">
                                        {
                                            tab === 1 && (
                                                // <form class="tab-pane show active" id="bottom-justified-tab1" onSubmit={e => setTab(2)}>
                                                <div action="#">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                required
                                                                fullWidth
                                                                id="fName"
                                                                name="firstName"
                                                                label="First Name"
                                                                variant="standard"
                                                                className="mt-3"
                                                                value={
                                                                    farmer.firstName
                                                                }
                                                                onChange={(e) =>
                                                                    setFarmer({
                                                                        ...farmer,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="lName"
                                                                name="lastName"
                                                                label="Last Name"
                                                                variant="standard"
                                                                className="mt-3"
                                                                value={
                                                                    farmer.lastName
                                                                }
                                                                onChange={(e) =>
                                                                    setFarmer({
                                                                        ...farmer,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />

                                                            {/* </div> */}
                                                        </div>
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="farmerMobile"
                                                                name="mobileNumber"
                                                                label="Mobile Number"
                                                                variant="standard"
                                                                className="mt-3"
                                                                style={{
                                                                    marginBottom:
                                                                        '1.25rem',
                                                                }}
                                                                value={
                                                                    farmer.mobileNumber
                                                                }
                                                                onChange={(e) =>
                                                                    handleMobVal(
                                                                        e
                                                                    )
                                                                }

                                                                // onChange={(e) => setFarmer({ ...farmer, [e.target.name]: e.target.value })}
                                                            />
                                                        </div>
                                                        <div
                                                            class="col-md-4"
                                                            style={{
                                                                marginTop: '2%',
                                                            }}
                                                        >
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <Select
                                                                    style={{
                                                                        width: '270px',
                                                                        height: '40px',
                                                                    }}
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setFarmer(
                                                                            {
                                                                                ...farmer,
                                                                                ['gender']:
                                                                                    e.value,
                                                                            }
                                                                        )
                                                                    }
                                                                    options={
                                                                        gender
                                                                    }
                                                                    placeholder={
                                                                        'Select Gender'
                                                                    }
                                                                />
                                                            </FormControl>
                                                        </div>

                                                        <div class="col-md-4">
                                                            <InputLabel
                                                                id="demo-simple-select-standard-label"
                                                                style={{
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                DOB
                                                            </InputLabel>
                                                            <CssTextField
                                                                required
                                                                type="date"
                                                                fullWidth
                                                                variant="standard"
                                                                style={{
                                                                    marginTop:
                                                                        '10px',
                                                                    marginBottom:
                                                                        '10px',
                                                                }}
                                                                name="dob"
                                                                value={
                                                                    farmer.dob
                                                                }
                                                                onChange={(e) =>
                                                                    setFarmer({
                                                                        ...farmer,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <Select
                                                                    style={{
                                                                        width: '270px',
                                                                        height: '40px',
                                                                    }}
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setFarmer(
                                                                            {
                                                                                ...farmer,
                                                                                ['farming_mode']:
                                                                                    e.value,
                                                                            }
                                                                        )
                                                                    }
                                                                    options={
                                                                        LanguageVale
                                                                    }
                                                                    placeholder={
                                                                        'Farming Mode'
                                                                    }
                                                                />
                                                            </FormControl>
                                                        </div>

                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                            <button
                                                                onClick={(e) =>
                                                                    setTab(2)
                                                                }
                                                                class="w-16 btn btn-primary"
                                                            >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            // </form>
                                        }

                                        {/* Contact Details */}
                                        {tab === 2 && (
                                            <div class="row">
                                                <div
                                                    class="col-md-3"
                                                    style={{ marginTop: '2%' }}
                                                >
                                                    <Map
                                                        center={{
                                                            lat: 18.5204,
                                                            lng: 73.8567,
                                                        }}
                                                        height="300px"
                                                        zoom={15}
                                                        mapdata={mapdata}
                                                    />
                                                </div>
                                                <div class="col-md-12 text-end justify-space-between">
                                                    <button
                                                        className="w-16 btn btn-secondary"
                                                        style={{
                                                            marginRight: '10px',
                                                        }}
                                                        onClick={(e) =>
                                                            setTab(1)
                                                        }
                                                    >
                                                        Previous
                                                    </button>
                                                    <button
                                                        onClick={(e) =>
                                                            setTab(4)
                                                        }
                                                        className="w-16 btn btn-primary"
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </div>
                                        )}

                                        {/* Farm Info */}
                                        {
                                            tab === 4 && (
                                                <div className="row">
                                                    <div className="col-md-3 mb-4">
                                                        <FormControl
                                                            variant="standard"
                                                            fullWidth
                                                            className="mt-3"
                                                        >
                                                            <InputLabel id="demo-multiple-checkbox-label">
                                                                Crop Name
                                                            </InputLabel>
                                                            {console.log(
                                                                'CropProduct',
                                                                CropProduct,
                                                                CropProductoption
                                                            )}
                                                            <Selects
                                                                required
                                                                labelId="demo-multiple-checkbox-label"
                                                                id="demo-multiple-checkbox"
                                                                multiple
                                                                value={
                                                                    cropTypes
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                input={
                                                                    <Input
                                                                        label="Crop Name"
                                                                        variant="standard"
                                                                    />
                                                                }
                                                                renderValue={(
                                                                    selected
                                                                ) =>
                                                                    selected.join(
                                                                        ', '
                                                                    )
                                                                }
                                                                MenuProps={
                                                                    MenuProps
                                                                }
                                                            >
                                                                {CropProductoption &&
                                                                    CropProductoption.map(
                                                                        (
                                                                            name
                                                                        ) => (
                                                                            <MenuItem
                                                                                key={
                                                                                    name.value
                                                                                }
                                                                                value={
                                                                                    name.value
                                                                                }
                                                                            >
                                                                               
                                                                                <Checkbox
                                                                                    checked={
                                                                                        cropTypes.indexOf(
                                                                                            name.value
                                                                                        ) >
                                                                                        -1
                                                                                    }
                                                                                />
                                                                                <ListItemText
                                                                                    primary={
                                                                                        name.value
                                                                                    }
                                                                                />
                                                                            </MenuItem>
                                                                        )
                                                                    )}
                                                            </Selects>
                                                        </FormControl>
                                                    </div>
                                                    <div
                                                        class="col-md-3"
                                                        style={{
                                                            marginTop: '2%',
                                                        }}
                                                    >
                                                        <FormControl
                                                            variant="standard"
                                                            fullWidth
                                                            className="mt-3"
                                                        >
                                                            <Select
                                                                style={{
                                                                    width: '270px',
                                                                    height: '40px',
                                                                }}
                                                                // onChange={(e) => statechane(e)}
                                                                onChange={(e) =>
                                                                    setFarmer({
                                                                        ...farmer,
                                                                        ['irrigationSource']:
                                                                            e.value,
                                                                    })
                                                                }
                                                                // onChange={(e) => setFarmer({ ...farmer, ['irrigationSource']: e.value })}
                                                                options={
                                                                    IrrigationSource
                                                                }
                                                                placeholder={
                                                                    'Select irrigationSource'
                                                                }
                                                                //  value={inputBooking.firm}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    <div
                                                        class="col-md-3"
                                                        style={{
                                                            marginTop: '2%',
                                                        }}
                                                    >
                                                        <FormControl
                                                            variant="standard"
                                                            fullWidth
                                                            className="mt-3"
                                                        >
                                                            <Select
                                                                style={{
                                                                    width: '270px',
                                                                    height: '40px',
                                                                }}
                                                                // onChange={(e) => statechane(e)}
                                                                onChange={(e) =>
                                                                    setFarmer({
                                                                        ...farmer,
                                                                        ['farmEquipments']:
                                                                            e.value,
                                                                    })
                                                                }
                                                                // onChange={(e) => setFarmer({ ...farmer, ['irrigationSource']: e.value })}
                                                                options={
                                                                    farmEquipmentsValue
                                                                }
                                                                placeholder={
                                                                    'Select farmEquipments'
                                                                }
                                                                //  value={inputBooking.firm}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    <div
                                                        class="col-md-3"
                                                        style={{
                                                            marginTop: '2%',
                                                        }}
                                                    >
                                                        <FormControl
                                                            variant="standard"
                                                            fullWidth
                                                            className="mt-3"
                                                        >
                                                            <Select
                                                                style={{
                                                                    width: '270px',
                                                                    height: '40px',
                                                                }}
                                                                // onChange={(e) => statechane(e)}
                                                                onChange={(e) =>
                                                                    setFarmer({
                                                                        ...farmer,
                                                                        ['selectUnit']:
                                                                            e.value,
                                                                    })
                                                                }
                                                                // onChange={(e) => setFarmer({ ...farmer, ['irrigationSource']: e.value })}
                                                                options={
                                                                    Unitvalue
                                                                }
                                                                placeholder={
                                                                    'Select selectUnit'
                                                                }
                                                                //  value={inputBooking.firm}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <CssTextField
                                                            fullWidth
                                                            type="number"
                                                            id="totalLand"
                                                            label="Total Land"
                                                            variant="standard"
                                                            name="totalLand"
                                                            value={
                                                                farmer.totalLand
                                                            }
                                                            onChange={(e) =>
                                                                setFarmer({
                                                                    ...farmer,
                                                                    [e.target
                                                                        .name]:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        class="col-md-3"
                                                        style={{
                                                            marginTop: '2%',
                                                        }}
                                                    >
                                                        <FormControl
                                                            variant="standard"
                                                            fullWidth
                                                            className="mt-3"
                                                        >
                                                            <Select
                                                                style={{
                                                                    width: '270px',
                                                                    height: '40px',
                                                                }}
                                                                // onChange={(e) => statechane(e)}
                                                                onChange={(e) =>
                                                                    setFarmer({
                                                                        ...farmer,
                                                                        ['soilType']:
                                                                            e.value,
                                                                    })
                                                                }
                                                                // onChange={(e) => setFarmer({ ...farmer, ['irrigationSource']: e.value })}
                                                                options={
                                                                    SoilType
                                                                }
                                                                placeholder={
                                                                    'Select soilType'
                                                                }
                                                                //  value={inputBooking.firm}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <InputLabel
                                                            id="demo-simple-select-standard-label"
                                                            style={{
                                                                color: 'black',
                                                            }}
                                                        >
                                                            Sowing Date
                                                        </InputLabel>
                                                        <CssTextField
                                                            type="date"
                                                            fullWidth
                                                            name="sowingDate"
                                                            variant="standard"
                                                            style={{
                                                                marginTop:
                                                                    '10px',
                                                                marginBottom:
                                                                    '10px',
                                                            }}
                                                            value={
                                                                farmer.sowingDate
                                                            }
                                                            onChange={(e) =>
                                                                setFarmer({
                                                                    ...farmer,
                                                                    [e.target
                                                                        .name]:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        class="col-md-3"
                                                        style={{
                                                            marginTop: '2%',
                                                        }}
                                                    >
                                                        <FormControl
                                                            variant="standard"
                                                            fullWidth
                                                            className="mt-3"
                                                        >
                                                            <Select
                                                                style={{
                                                                    width: '270px',
                                                                    height: '40px',
                                                                }}
                                                                // onChange={(e) => statechane(e)}
                                                                onChange={(e) =>
                                                                    setFarmer({
                                                                        ...farmer,
                                                                        ['soilTest']:
                                                                            e.value,
                                                                    })
                                                                }
                                                                // onChange={(e) => setFarmer({ ...farmer, ['irrigationSource']: e.value })}
                                                                options={
                                                                    SoilTest
                                                                }
                                                                placeholder={
                                                                    'Select soilTest'
                                                                }
                                                                //  value={inputBooking.firm}
                                                            />
                                                        </FormControl>
                                                    </div>

                                                    <div class="col-md-12 text-end mt-2 mb-2">
                                                        <button
                                                            className="w-16 btn btn-secondary"
                                                            style={{
                                                                marginRight:
                                                                    '10px',
                                                            }}
                                                            onClick={(e) =>
                                                                setTab(2)
                                                            }
                                                        >
                                                            Previous
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={(e) =>
                                                                syncOtpSubmit(
                                                                    e
                                                                )
                                                            }
                                                            class=" w-16 btn btn-primary"
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                            // </form>
                                        }

                                        {/* Banck ACC Details */}
                                        {
                                            tab === 5 && (
                                                // <form class="tab-pane show active" id="bottom-justified-tab5" onSubmit={onSubmitFarmer}>
                                                <div className="row">
                                                    <div class="col-md-3">
                                                        <CssTextField
                                                            fullWidth
                                                            id="bankAccountNumber"
                                                            label="Bank Acc No"
                                                            variant="standard"
                                                            type="number"
                                                            name="bankAccountNumber"
                                                            value={
                                                                farmer.bankAccountNumber
                                                            }
                                                            onChange={(e) =>
                                                                setFarmer({
                                                                    ...farmer,
                                                                    [e.target
                                                                        .name]:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                        />
                                                    </div>

                                                    <div class="col-md-3">
                                                        <CssTextField
                                                            required
                                                            fullWidth
                                                            id="bankAccountName"
                                                            label="Account Name"
                                                            variant="standard"
                                                            name="bankAccountName"
                                                            value={
                                                                farmer.bankAccountName
                                                            }
                                                            onChange={(e) =>
                                                                setFarmer({
                                                                    ...farmer,
                                                                    [e.target
                                                                        .name]:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div class="col-md-3">
                                                        <CssTextField
                                                            required
                                                            fullWidth
                                                            id="ifscCode"
                                                            label="IFSC Code"
                                                            variant="standard"
                                                            name="ifscCode"
                                                            value={
                                                                farmer.ifscCode
                                                            }
                                                            onChange={(e) =>
                                                                setFarmer({
                                                                    ...farmer,
                                                                    [e.target
                                                                        .name]:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div class="col-md-3">
                                                        <CssTextField
                                                            required
                                                            fullWidth
                                                            id="bankName"
                                                            label="Bank Name"
                                                            variant="standard"
                                                            pattern="[a-zA-Z]*"
                                                            type="text"
                                                            name="bankName"
                                                            value={
                                                                farmer.bankName
                                                            }
                                                            onChange={(e) =>
                                                                setFarmer({
                                                                    ...farmer,
                                                                    [e.target
                                                                        .name]:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div class="col-md-3">
                                                        <CssTextField
                                                            required
                                                            fullWidth
                                                            id="bankBranchName"
                                                            label="Branch Name"
                                                            variant="standard"
                                                            name="bankBranchName"
                                                            value={
                                                                farmer.bankBranchName
                                                            }
                                                            onChange={(e) =>
                                                                setFarmer({
                                                                    ...farmer,
                                                                    [e.target
                                                                        .name]:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                        />
                                                    </div>

                                                    <div class="col-md-12 text-end mt-4 mb-2">
                                                        <button
                                                            className="w-16 btn btn-secondary"
                                                            style={{
                                                                marginRight:
                                                                    '10px',
                                                            }}
                                                            onClick={(e) =>
                                                                setTab(4)
                                                            }
                                                        >
                                                            Previous
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={(e) =>
                                                                onSubmitFarmer(
                                                                    e
                                                                )
                                                            }
                                                            class=" w-16 btn btn-primary"
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                            // </form>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )}
        </>
    )
}
export default Farmers
