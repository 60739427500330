import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { API } from '../../API'
import Livestockbookingtable from '../Bookings/LiveStock/Livestockbookingtable'
import InputsellCroptable from '../Bookings/SellCrop/SellCropTable'

const SellCropTable = () => {
    const [sellcropBookfetch, setsellcropBookfetch] = useState([])
    useEffect(() => {
        getSellCropBooking()
    }, [])

    const getSellCropBooking = async () => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/all-crop-bookings`,
                config
            )
            .then((res) => {
                console.log('checking ma c booking', res.data.data)
                setsellcropBookfetch(res.data.data)
            })
    }

    return (
        <div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card card-table">
                        <div class="card-body">
                            <div class="table-responsive">
                                <div
                                    id="DataTables_Table_0_wrapper"
                                    class="dataTables_wrapper dt-bootstrap4 no-footer"
                                >
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <h3 className="mt-3">
                                                All Sell Crop Bookings
                                            </h3>
                                            <div
                                                class="dataTables_length"
                                                id="DataTables_Table_0_length"
                                            >
                                                <hr />
                                                <label>
                                                    Show
                                                    <select
                                                        name="DataTables_Table_0_length"
                                                        aria-controls="DataTables_Table_0"
                                                        class="custom-select custom-select-sm form-control form-control-sm"
                                                    >
                                                        <option value="10">
                                                            10
                                                        </option>
                                                        <option value="25">
                                                            25
                                                        </option>
                                                        <option value="50">
                                                            50
                                                        </option>
                                                        <option value="100">
                                                            100
                                                        </option>
                                                    </select>
                                                    entries
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 position_right">
                                            <div class="form-group">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Search"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <InputsellCroptable
                                            inputs={sellcropBookfetch}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SellCropTable
