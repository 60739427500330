import React from 'react'

function TagsManagement() {
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Tags Management</h5>
                </div>
                <div class="card-body">
                    <form>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="col-md-4">
                                    Tags
                                    <div class="tag_add_btn">
                                        <a href="#">
                                            <span class="material-icons-outlined">
                                                add
                                            </span>{' '}
                                            Add
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default TagsManagement
