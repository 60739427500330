import React, { Component } from 'react'
import {
    withGoogleMap,
    GoogleMap,
    withScriptjs,
    InfoWindow,
    Marker,
} from 'react-google-maps'
import Geocode from 'react-geocode'
import Autocomplete from 'react-google-autocomplete'

const GoogleMapsAPI = 'AIzaSyBmifzYhVWcmSLMi3s5gQp0H1gaHhI_ua0'

Geocode.setApiKey(GoogleMapsAPI)
Geocode.enableDebug()

class Map extends Component {
    constructor(props) {
        super(props)
        this.state = {
            address: '',
            city: '',
            area: '',
            state: '',
            pincode: '',
            mapPosition: {
                lat: this.props.center.lat,
                lng: this.props.center.lng,
            },
            markerPosition: {
                lat: this.props.center.lat,
                lng: this.props.center.lng,
            },
        }
    }
    /**
     * Get the current address from the default map position and set those values in the state
     */
    componentDidMount() {
        // Geocode.fromLatLng(this.state.mapPosition.lat, this.state.mapPosition.lng).then(
        //   response => {
        //     console.log(response, 'response')
        //     const address = response.results[0].formatted_address,
        //       addressArray = response.results[0].address_components,
        //       city = this.getCity(addressArray),
        //       area = this.getArea(addressArray),
        //       state = this.getState(addressArray),
        //       pincode = this.getPincode(address);

        //     console.log('city', city, area, state);

        //     this.setState({
        //       address: (address) ? address : '',
        //       area: (area) ? area : '',
        //       city: (city) ? city : '',
        //       state: (state) ? state : '',
        //       pincode: (pincode) ? pincode : ''
        //     })
        //     var data = {
        //       state: this.state.state,
        //       district: this.state.city,
        //       subDistrict: this.state.area,
        //       village: this.state.area,
        //       pincode: this.state.pincode,
        //       address: this.state.address,
        //       latitude: this.state.mapPosition.lat,
        //       longitude: this.state.mapPosition.lng
        //     }
        //     this.props.mapdata(data)
        //   },
        //   error => {
        //     console.error(error);
        //   }
        // );
        localStorage.setItem('mapdata', JSON.stringify(this.state.mapPosition))
    }
    /**
     * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.state.markerPosition.lat !== this.props.center.lat ||
            this.state.address !== nextState.address ||
            this.state.city !== nextState.city ||
            this.state.area !== nextState.area ||
            this.state.state !== nextState.state
        ) {
            return true
        } else if (this.props.center.lat === nextProps.center.lat) {
            return false
        }
    }
    /**
     * Get the city and set the city input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getCity = (addressArray) => {
        console.log(addressArray, 'address')
        let city = ''
        for (let i = 0; i < addressArray.length; i++) {
            for (let j = 0; j < addressArray[i].types.length; j++) {
                if (
                    
                    "administrative_area_level_3" === addressArray[i].types[j]
                ) {
                city = addressArray[i].long_name
                return city
            }
        }
    }}

    getPincode = (addressArray) => {
        console.log(addressArray, 'addresss')
        var a = addressArray.split(' ')
        a = addressArray.split(' ')[a.length - 2]
        return a.split(',')[0]
    }
    /**
     * Get the area and set the area input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getArea = (addressArray) => {
        let area = ''
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0]) {
                for (let j = 0; j < addressArray[i].types.length; j++) {
                    if (
                        'sublocality_level_1' === addressArray[i].types[j] ||
                        'locality' === addressArray[i].types[j]
                    ) {
                        area = addressArray[i].long_name
                        return area
                    }
                }
            }
        }
    }
    /**
     * Get the address and set the address input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getState = (addressArray) => {
        let state = ''
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (
                    addressArray[i].types[0] &&
                    'administrative_area_level_1' === addressArray[i].types[0]
                ) {
                    state = addressArray[i].long_name
                    return state
                }
            }
        }
    }

    /**
     * And function for city,state and address input
     * @param event
     */
    
    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
        var data = {
            state: this.state.state,
            district: this.state.city,
            subDistrict: this.state.area,
            village: this.state.area,
            pincode: this.state.pincode,
            address: this.state.address,
            latitude: this.state.mapPosition.lat,
            longitude: this.state.mapPosition.lng,
        }
        this.props.mapdata(data)
    }
    /**
     * This Event triggers when the marker window is closed
     *
     * @param event
     */
    onInfoWindowClose = (event) => {}

    /**
     * When the marker is dragged you get the lat and long using the functions available from event object.
     * Use geocode to get the address, city, area and state from the lat and lng positions.
     * And then set those values in the state.
     *
     * @param event
     */
    onMarkerDragEnd = (event) => {
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng()

        Geocode.fromLatLng(newLat, newLng).then(
            (response) => {
                const address = response.results[0].formatted_address,
                    addressArray = response.results[0].address_components,
                    city = this.getCity(addressArray),
                    area = this.getArea(addressArray),
                    state = this.getState(addressArray),
                    pincode = this.getPincode(address)
                localStorage.setItem('lat', newLat)
                localStorage.setItem('lng', newLng)
                this.setState({
                    address: address ? address : '',
                    area: area ? area : '',
                    city: city ? city : '',
                    state: state ? state : '',
                    pincode: pincode ? pincode : '',
                    markerPosition: {
                        lat: newLat,
                        lng: newLng,
                    },
                    mapPosition: {
                        lat: newLat,
                        lng: newLng,
                    },
                })
                var data = {
                    state: this.state.state,
                    district: this.state.city,
                    subDistrict: this.state.area,
                    village: this.state.area,
                    pincode: this.state.pincode,
                    address: this.state.address,
                    latitude: this.state.mapPosition.lat,
                    longitude: this.state.mapPosition.lng,
                }
                this.props.mapdata(data)
            },
            (error) => {
                console.error(error)
            }
        )
    }

    /**
     * When the user types an address in the search box
     * @param place
     */
    onPlaceSelected = (place) => {
        console.log('plc', place)
        const address = place.formatted_address,
            addressArray = place.address_components,
            city = this.getCity(addressArray),
            area = this.getArea(addressArray),
            state = this.getState(addressArray),
            pincode = this.getPincode(address),
            latValue = place.geometry.location.lat(),
            lngValue = place.geometry.location.lng()
        localStorage.setItem('lat', latValue)
        localStorage.setItem('lng', lngValue)
        // Set these values in the state.
        this.setState({
            address: address ? address : '',
            area: area ? area : '',
            city: city ? city : '',
            state: state ? state : '',
            pincode: pincode ? pincode : '',
            markerPosition: {
                lat: latValue,
                lng: lngValue,
            },
            mapPosition: {
                lat: latValue,
                lng: lngValue,
            },
        })
        var data = {
            state: this.state.state,
            district: this.state.city,
            subDistrict: this.state.area,
            village: this.state.area,
            pincode: this.state.pincode,
            address: this.state.address,
            latitude: this.state.mapPosition.lat,
            longitude: this.state.mapPosition.lng,
        }
        this.props.mapdata(data)
    }

    render() {
        const AsyncMap = withScriptjs(
            withGoogleMap((props) => (
                <GoogleMap
                    google={this.props.google}
                    defaultZoom={this.props.zoom}
                    defaultCenter={{
                        lat: this.state.mapPosition.lat,
                        lng: this.state.mapPosition.lng,
                    }}
                >
                    {/* InfoWindow on top of marker */}
                    <InfoWindow
                        onClose={this.onInfoWindowClose}
                        position={{
                            lat: this.state.markerPosition.lat + 0.0018,
                            lng: this.state.markerPosition.lng,
                        }}
                    >
                        <div>
                            <span style={{ padding: 0, margin: 0 }}>
                                {this.state.address}
                            </span>
                        </div>
                    </InfoWindow>
                    {/*Marker*/}
                    <Marker
                        google={this.props.google}
                        name={'Dolores park'}
                        draggable={true}
                        onDragEnd={this.onMarkerDragEnd}
                        position={{
                            lat: this.state.markerPosition.lat,
                            lng: this.state.markerPosition.lng,
                        }}
                    />
                    <Marker />
                    {/* For Auto complete Search Box */}
                    <Autocomplete
                        style={{
                            width: '100%',
                            height: '40px',
                            paddingLeft: '16px',
                            marginTop: '2px',
                            marginBottom: '500px',
                        }}
                        onPlaceSelected={this.onPlaceSelected}
                        types={['(regions)']}
                    />
                </GoogleMap>
            ))
        )
        let map
        if (this.props.center.lat !== undefined) {
            map = (
                <div style={{ display: 'flex', width: '100vw' }}>
                    <div>
                        <div className="mapaddress">
                            <div className="form-group">
                                <label htmlFor="">State</label>
                                <input
                                    type="text"
                                    name="state"
                                    className="form-control"
                                    onChange={this.onChange}
                                    readOnly="readOnly"
                                    value={this.state.state}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">District</label>
                                <input
                                    type="text"
                                    name="city"
                                    className="form-control"
                                    onChange={this.onChange}
                                    readOnly="readOnly"
                                    value={this.state.city}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Sub District</label>
                                <input
                                    type="text"
                                    name="area"
                                    className="form-control"
                                    onChange={this.onChange}
                                    readOnly="readOnly"
                                    value={this.state.area}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Village</label>
                                <input
                                    type="text"
                                    name="area"
                                    className="form-control"
                                    onChange={this.onChange}
                                    readOnly="readOnly"
                                    value={this.state.area}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Pincode</label>
                                <input
                                    type="text"
                                    name="area"
                                    className="form-control"
                                    onChange={this.onChange}
                                    readOnly="readOnly"
                                    value={this.state.pincode}
                                />
                            </div>
                        </div>

                        <div className="form-group" style={{ padding: '20px' }}>
                            <label htmlFor="">Full Address</label>
                            <input
                                type="text"
                                name="address"
                                className="form-control"
                                onChange={this.onChange}
                                readOnly="readOnly"
                                value={this.state.address}
                            />
                        </div>
                    </div>

                    <AsyncMap
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyBmifzYhVWcmSLMi3s5gQp0H1gaHhI_ua0&callback=Function.prototype&libraries=places`}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={
                            <div
                                style={{
                                    height: '300px',
                                    width: '500px',
                                    marginBottom: '49px',
                                }}
                            />
                        }
                        mapElement={<div style={{ height: `100%` }} />}
                    />
                </div>
            )
        } else {
            map = (
                <div
                    style={{
                        height: '298px',
                        width: '500px',
                        marginBottom: '49px',
                    }}
                />
            )
        }
        return map
    }
}
export default Map
