import { Card, Tab, Tabs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import PGListWidget from '../../components/PGListWidget/PGListWidget'
import Dashboard from './Dashboard/Dashboard'
import CountUp from 'react-countup'
import Map from './Dashboard/map'
import Dashboard2 from './Dashboard/Dashboard2'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import farmericon from './Farmer.png'
import machineicone from './machine.png'
import fpoicon from './FPO.png'
import inputicon from './input.png'
import micicon from './mic.png'
import tradericon from './Trader.png'
import transportIcon from './transport.png'
import livestockicon from './Livestock.png'
// import TotalVistors from './Dashboard/totalvistors';
// import BarChart from './Dashboard/barChart';
// import { TabPanel } from '@mui/lab';

function TabPanel(props) {
    const { children, value, index, ...other } = props

    useEffect(() => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/auth-function/dashboard-count`,
                config
            )
            .then((res) => {
                console.log('checking ma c booking', res.data)
            })
    }, [])

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    )
}

function Home() {
    const [revenue,setRevenue]=useState(0)
const [gmv,setGMV]=useState(0)
const [bookingCount,setBookingcount]=useState(0)
    const [startDate, setDate] = React.useState(new Date())
    const [create, setCreate] = useState(0)
    const [machineCount, setMachineCount] = useState(0)
    const [machineAmount, setMachineAmount] = useState(0)
    const [cropCount, setCropCount] = useState(0)
    const [cropAmount, setCropAmount] = useState(0)
    const [livestockCount, setlivestockCount] = useState(0)
    const [livestockAmount, setlivestockAmount] = useState(0)

    const [cropKrishiAmount,setCropKrishiAmount]=useState(0)
    const [machineKrishiAmount,setMachineKrishiAmount]=useState(0)
    const [livestockKrishiAmount,setlivestockKrishiAmount]=useState(0)

    const [bookings, setBookings] = useState([
        {
            name: 'Transport Bookings',
            amount: 0,
            count: 0,
            bgcolor: 'bg-3',
            icon: transportIcon,
        },
        {
            name: 'Machine Bookings',
            amount: 0,
            count: 0,
            bgcolor: 'bg-4',
            icon: machineicone,
        },
        {
            name: 'Livestock Bookings',
            amount: 0,
            count: 0,
            bgcolor: 'bg-5',
            icon: livestockicon,
        },
        {
            name: 'Sell Crop Bookings',
            amount: 0,
            count: 0,
            bgcolor: 'bg-1',
            icon: tradericon,
        },
        {
            name: 'InputSupplier',
            amount: 0,
            count: 0,
            bgcolor: 'bg-1',
            icon: micicon,
        },
    ])

    const [data, setData] = useState([
        { name: 'Total Farmer', id: 0, bgcolor: 'bg-1', icon: farmericon },
        { name: 'Total FPO', id: 0, bgcolor: 'bg-2', icon: fpoicon },
        {
            name: 'Total Agri Transport',
            id: 0,
            bgcolor: 'bg-3',
            icon: transportIcon,
        },
        { name: 'Total Machine', id: 0, bgcolor: 'bg-4', icon: machineicone },
        {
            name: 'Total Livestock',
            id: 0,
            bgcolor: 'bg-5',
            icon: livestockicon,
        },
        { name: 'Total Trader', id: 0, bgcolor: 'bg-1', icon: tradericon },
        {
            name: 'Total Micro-Entreprenur',
            id: 0,
            bgcolor: 'bg-1',
            icon: micicon,
        },
        {
            name: 'Total Input Supplier',
            id: 0,
            bgcolor: 'bg-1',
            icon: inputicon,
        },
    ])
    const [rangeStart, setRangeStart] = React.useState(new Date())
    const defaultEndDate = new Date()
    defaultEndDate.setDate(defaultEndDate.getDate() + 7)
    const [rangeEnd, setRangeEnd] = React.useState(defaultEndDate)
    const today = new Date()
    const useStyles = makeStyles((theme) => ({}))
    const Admin = JSON.parse(localStorage.getItem('Admin'))
    const classes = useStyles()

    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const fetchNames = async () => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var cropdata = {
            status: 'completed',
        }
        var machinedata = {
            status: 'End',
        }
        var livestockdata = {
            status: 'Completed',
        }
        var inputdata = {
            status: 'Completed',
        }
        var vehicledata = {
            status: 'End',
        }

        try {
            const [crop, machine, livestock, input, vehicle] =
                await Promise.all([
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/cropbystatus`,
                        cropdata,
                        config
                    ),
                    axios.get(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-booking-count`,
                        config
                    ),
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/livestockbystatus`,
                        livestockdata,
                        config
                    ),
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/inputbystatus`,
                        inputdata,
                        config
                    ),
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/vehiclebystatus`,
                        vehicledata,
                        config
                    ),
                ])
            const cropAmount = crop.data.data.length?crop.data.data
                .map((a) => a.userAmount)
                .reduce((prev, next) => prev + next):0
            setCropAmount(cropAmount)
                //krishiAmount
            const cropKrishiAmount = crop.data.data.length?crop.data.data
            .map((a) => a.krishiAmount)
            .reduce((prev, next) => prev + next):0
             setCropKrishiAmount(cropKrishiAmount)

            const cropCount = crop.data.data.length

            const machineAmount =machine.data.data.totalSumMachineAmount
            setMachineAmount(machineAmount)
            const machineKrishiAmount =machine.data.data.totalSumKrishiAmount
            setMachineKrishiAmount(machineKrishiAmount)
                            //krishiAmount

            // const machineKrishiAmount = machine.data.data.length?machine.data.data
            // .map((a) => a.krishiAmount)
            // .reduce((prev, next) => prev + next):0
            //  setMachineKrishiAmount(machineKrishiAmount)

            const machineCount = machine.data.data.machineCount

            const livestockAmount = livestock.data.data.length?livestock.data.data
                .map((a) => a.userAmount)
                .reduce((prev, next) => prev + next):0
            setlivestockAmount(livestockAmount)
                            
            //krishiAmount
            const livestockKrishiAmount = livestock.data.data.length?livestock.data.data
            .map((a) => a.krishiAmount)
            .reduce((prev, next) => prev + next):0
            setlivestockKrishiAmount(livestockKrishiAmount)

            const livestockCount = livestock.data.data.length

            const inputamount = input.data.data.length?input.data.data
                .map((a) => a.userAmount)
                .reduce((prev, next) => prev + next):0
                const inputKrishiamount = input.data.data.length?input.data.data
                .map((a) => a.krishiAmount)
                .reduce((prev, next) => prev + next):0
            const inputcount = input.data.data.length

            const vehicleamount = vehicle.data.data.length?vehicle.data.data
                .map((a) => a.userAmount)
                .reduce((prev, next) => prev + next):0
                const vehicleKrishiamount = vehicle.data.data.length?vehicle.data.data
                .map((a) => a.krishiAmount)
                .reduce((prev, next) => prev + next):0
            const vehiclecounts = vehicle.data.data.length
            var bookings = [
                {
                    name: 'Transport Bookings',
                    amount: vehicleamount,
                    count: vehiclecounts,
                    krishiAmount:vehicleKrishiamount,
                    bgcolor: 'bg-3',
                    icon: transportIcon,
                },
                {
                    name: 'Machine Bookings',
                    amount: machineAmount,
                    count: machineCount,
                    krishiAmount:machineKrishiAmount,
                    bgcolor: 'bg-4',
                    icon: machineicone,
                },
                {
                    name: 'Livestock Bookings',
                    amount: livestockAmount,
                    count: livestockCount,
                    krishiAmount:livestockKrishiAmount,
                    bgcolor: 'bg-5',
                    icon: livestockicon,
                },
                {
                    name: 'Sell Crop Bookings',
                    amount: cropAmount,
                    count: cropCount,
                    krishiAmount:cropKrishiAmount,
                    bgcolor: 'bg-1',
                    icon: tradericon,
                },
                {
                    name: 'Input Bookings',
                    amount: inputamount,
                    count: inputcount,
                    krishiAmount:inputKrishiamount,
                    bgcolor: 'bg-1',
                    icon: micicon,
                },
            ]
            setBookings(bookings)

            //   const datas = res.map((res) => res.data);
            //   console.log(datas.flat());
        } catch (err) {
            console.log(err)
            throw Error('Promise failed')
        }
    }
    useEffect(() => {
        fetchNames()

        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/all-userAmount-count`,
                config
            )
            .then((res) => setGMV(res.data.data))


            axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/all-commission-count`,
                config
            )
            .then((res) => setRevenue(res.data.data))
         axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/all-booking-count`,
                config
            )
            .then((res) => setBookingcount(res.data.count))
        },[])

    useEffect(() => {
        fetchNames()

        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/auth-function/dashboard-count`,
                config
            )
            .then((res) => {
                console.log('checking ma c booking', res.data)
                var arr = [
                    {
                        name: 'Farmer',
                        id: res.data.farmerCount,
                        bgcolor: 'bg-1',
                        icon: farmericon,
                    },
                    {
                        name: 'FPO',
                        id: res.data.fpoCount,
                        bgcolor: 'bg-2',
                        icon: fpoicon,
                    },
                    {
                        name: 'Agri Transport',
                        id: res.data.agriMachine,
                        bgcolor: 'bg-3',
                        icon: transportIcon,
                    },
                    {
                        name: 'Machine',
                        id: res.data.machineCount,
                        bgcolor: 'bg-4',
                        icon: machineicone,
                    },
                    {
                        name: 'Livestock',
                        id: res.data.liveStockCount,
                        bgcolor: 'bg-5',
                        icon: livestockicon,
                    },
                    {
                        name: 'Trader',
                        id: res.data.traderCount,
                        bgcolor: 'bg-1',
                        icon: tradericon,
                    },
                    {
                        name: 'InputSupplier',
                        id: res.data.inputCount,
                        bgcolor: 'bg-1',
                        icon: inputicon,
                    },
                    {
                        name: 'Micro-Entreprenur',
                        id: res.data.microCount,
                        bgcolor: 'bg-1',
                        icon: micicon,
                    },
                ]
                setData(arr)
            })
    }, [])
    const selectDateHandler = (d) => {
        setDate(d)
    }

    const selectStartDate = (d) => {
        setRangeStart(d)
    }

    const selectEndDate = (d) => {
        setRangeEnd(d)
    }

    var arr = [
        { name: 'Total Customer', id: '1334', bgcolor: 'bg-1' },
        { name: 'Total Order', id: '243434', bgcolor: 'bg-2' },
        { name: 'Avg Sale', id: '3234', bgcolor: 'bg-3' },
        { name: 'Avg Item Sale', id: '434', bgcolor: 'bg-4' },
        { name: 'Visitors', id: '53423423', bgcolor: 'bg-5' },
        { name: 'Total Products', id: '53423423', bgcolor: 'bg-1' },
        { name: 'Top Selling Items', id: '53423423', bgcolor: 'bg-1' },
    ]
    const handelchange = (e) => {
        setCreate(e.target.value)
    }
    return (
        <div className="homebg">
            <PGListWidget farmerCount={data[0].id} revenue={revenue} gmv={gmv} bookingCount={bookingCount} />
            <div className="filters">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    centered
                    className={classes.tabsWrapper}
                    classes={{
                        indicator: classes.indicator,
                    }}
                >
                    <Tab className="filtertab" label="Today" value={0} />
                    <Tab className="filtertab" label="Week" value={1} />
                    <Tab className="filtertab" label="Month" value={2} />
                    <Tab className="filtertab" label="Year" value={3} />
                </Tabs>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        color: '#929ea6',
                    }}
                >
                    <p style={{ marginRight: '24px' }}>From </p>
                    <DatePicker
                        selectsStart
                        selected={rangeStart}
                        minDate={today}
                        startDate={rangeStart}
                        endDate={rangeEnd}
                        className="datepicker"
                        onChange={selectStartDate}
                    />
                    <p style={{ marginRight: '24px', marginLeft: '12px' }}>
                        To{' '}
                    </p>

                    <DatePicker
                        selectsEnd
                        selected={rangeEnd}
                        startDate={rangeStart}
                        endDate={rangeEnd}
                        className="datepicker"
                        onChange={selectEndDate}
                    />
                </div>
            </div>

            <div class="dashboard_cards">
                {data.map((item, index) => {
                    return (
                        <div
                            style={{ width: '210px' }}
                            class={`dashboard_card cardhome`}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: '10px',
                                }}
                            >
                                <div class="dash-count">
                                    <h6 class="dash-title-card">{item.name}</h6>
                                    <CountUp
                                        end={item.id}
                                        duration={5}
                                        class="card-text p-y-1"
                                    />
                                </div>
                                <div>
                                    <span class="dash-widget-icon ">
                                        <img width={65} src={item.icon} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="filters">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    centered
                    className={classes.tabsWrapper}
                    classes={{
                        indicator: classes.indicator,
                    }}
                >
                    <Tab className="filtertab" label="Today" value={0} />
                    <Tab className="filtertab" label="Week" value={1} />
                    <Tab className="filtertab" label="Month" value={2} />
                    <Tab className="filtertab" label="Year" value={3} />
                </Tabs>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        color: '#929ea6',
                    }}
                >
                    <p style={{ marginRight: '24px' }}>From </p>
                    <DatePicker
                        selectsStart
                        selected={rangeStart}
                        minDate={today}
                        startDate={rangeStart}
                        endDate={rangeEnd}
                        className="datepicker"
                        onChange={selectStartDate}
                    />
                    <p style={{ marginRight: '24px', marginLeft: '12px' }}>
                        To{' '}
                    </p>

                    <DatePicker
                        selectsEnd
                        selected={rangeEnd}
                        startDate={rangeStart}
                        endDate={rangeEnd}
                        className="datepicker"
                        onChange={selectEndDate}
                    />
                </div>
            </div>

            <div class="dashboard_cards">
                {bookings.map((item, index) => {
                    return (
                        <div
                            style={{ width: '250px', height: '138px' }}
                            class={`dashboard_card cardhome`}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: '10px',
                                }}
                            >
                                <div class="dash-count">
                                    <h6 class="dash-title-card">{item.name}</h6>
                                    <div>
                                        <div>
                                            Count:{' '}
                                            <CountUp
                                                end={item.count}
                                                duration={2}
                                                class="card-text p-y-1"
                                            />
                                        </div>
                                        <div>
                                            Amount: ₹{' '}
                                            <CountUp
                                                end={item.amount}
                                                duration={2}
                                                class="card-text p-y-1"
                                            />
                                        </div>
                                        <div>
                                            CM: ₹{' '}
                                            <CountUp
                                                end={item.krishiAmount}
                                                duration={2}
                                                class="card-text p-y-1"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <span class="dash-widget-icon ">
                                        <img width={65} src={item.icon} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            {/* <Dashboard /> */}

            {/* <Dashboard2 /> */}
        </div>
    )
}
export default Home