import React, { useState, useEffect } from 'react'
import { CssTextField } from '../../Styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { useDropzone } from 'react-dropzone'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Input from '@mui/material/Input'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import { storage } from '../../../firebase/index'

import $ from 'jquery'
// import { thumbsContainerState, thumb, img, thumbInner, mapThumb,  baseStyle } from '../../Styles';
import { API } from '../../../API'

import Swal from 'sweetalert2'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import {
    thumbsContainerState,
    thumb,
    img,
    thumbInner,
    baseStyle,
    driverDP,
    sectionDiv,
    uploadBtnSm,
    panImg,
} from '../../Styles'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}
const names = ['Wheat', 'Rice', 'Barley', 'Cereal', 'Tomato', 'Oat']
const transclear = {
    id: '',
    price: '',
    perUnit: '',
    minimumBokingAmount: '',

    category: '',
    subCategory: '',
    typeOfVehicle: '',
    vehicleNumber: '',
    document: '',
    documentExpiryDate: '',
    brandName: '',
}
const VehicleDetails = (props) => {
    console.log('props', props)
    const [addFarm, setAddFarm] = useState(false)
    const [cropTypes, setCropTypes] = React.useState([])
    const [Files, setFiles] = useState([])
    const [file, setFile] = useState(null)
    const [Categorylist, setCategorylist] = useState([])
    const [subCategorylist, setsubCategorylist] = useState([])
    const [BrandCategorylist, setBrandCategorylist] = useState([])
    const [Productlist, setProductlist] = useState([])
    const [CategoryName, setCategoryName] = useState('')
    const [subcategoryName, setsubcategoryName] = useState('')

    const [MachineDetailsvalue, setMachineDetailsvalue] = useState([])
    const VehicleDetailsval = async () => {
        const { data } = await API.get(
            `/getVehicle?type=0&id=` +
                localStorage.getItem('agriTransportfarmerid')
        )
        console.log('data', data)
        setMachineDetailsvalue(data?.result)
        // let arr =[]
        // arr.push(data?.result)
        // setMachineDetailsvalue(arr);
    }
    const [docImg, setDocImg] = useState(null)
    const [agriTransport, setAgriTransport] = useState({
        id: localStorage.getItem('agriTransportfarmerid'),
        price: '',
        perUnit: '',
        minimumBokingAmount: '',

        category: '',
        subCategory: '',
        typeOfVehicle: '',
        vehicleNumber: '',
        document: '',
        documentExpiryDate: '',
        brandName: '',
    })
    useEffect(() => {
        setMachineDetailsvalue(props.value)
        // getState();
        // getAgriMachineList()
        VehicleDetailsval()
        getCategoryList()
        // getStateList()
        // getCommisionCat()
    }, [props.value])

    const getCategoryList = async () => {
        const { data } = await API.get(
            `/getProductCategory?type=0&title=ProductCatelog&catelogName=TransportCatelog`
        )
        // console.log("hkjjhj",data)
        setCategorylist(data?.result)
    }
    // const getSubCategoryList = async(val) => {
    //   const { data } = await API.get(`/getSubCategory?title=ProductCatelog&catelogName=TransportCatelog&categoryName=`+val);
    //   console.log("hkjjhj",data)
    //   setsubCategorylist(data?.result);
    // }
    // const handleCategoryChangeVarient =(e)=>{
    //   setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })
    //   setCategoryName(e.target.value)
    //   getSubCategoryList(e.target.value)
    //   }
    // const handleSUbCategoryChangeVarient =(e)=>{
    //   setsubcategoryName(e.target.value)
    //   setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })
    //   getBrandCategoryList(e.target.value)
    //   }
    //   const handleBRANDCategoryChangeVarient =(e)=>{
    //     setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })
    //     getProductListVarient(e.target.value)
    //     }
    //     const getProductListVarient =async(val) =>{
    //       const { data } = await API.get(`/getProduct?title=ProductCatelog&catelogName=TransportCatelog&categoryName=`+CategoryName+'&subCategoryName='+subcategoryName+'&brandName='+val);
    //       console.log("hkjjhj",data)
    //       setProductlist(data?.result);
    //     }
    //   const getBrandCategoryList = async(val) => {
    //     const { data } = await API.get(`/getBrand?title=ProductCatelog&catelogName=TransportCatelog&categoryName=`+CategoryName+'&subCategoryName='+val)
    //     console.log("hkjjhj",data)
    //     setBrandCategorylist(data?.result);
    //   }

    // const handleMobilesearch =(e)=>{
    //   console.log("e",e.target.value)
    //   if(e.target.value.length ==10){
    //     mobilechecksearch(e.target.value)
    //   }

    //   else{
    //     setAgriMachineList([]);
    //   }

    // }
    // const mobilechecksearch =async(number)=>{
    //   const { data } = await API.get(`/searchAgriMachine?search=`+number);
    //   console.log("data",data)
    //   if(data.success ==true) {
    //     let arr =[]
    //     arr.push(data?.result)
    //     setAgriMachineList(arr);

    //   }
    // else{

    // }
    // }
    $(function () {
        $('#txtNumeric').keydown(function (e) {
            if (e.shiftKey || e.ctrlKey || e.altKey) {
                e.preventDefault()
            } else {
                var key = e.keyCode

                if (
                    !(
                        key == 8 ||
                        key == 32 ||
                        key == 46 ||
                        (key >= 35 && key <= 40) ||
                        (key >= 65 && key <= 90)
                    )
                ) {
                    e.preventDefault()
                }
            }
        })
    })
    const handlebehivlesubmit = async (e) => {
        e.preventDefault()
        console.log('Agri Machine : ', agriTransport)
        const { data } = await API.post('/addVehicle', agriTransport)
        if (data?.success) {
            Swal.fire({
                icon: 'success',
                iconColor: '#28a745',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
            // getAgriMachineList();
            setDocImg(null)
            console.log(data)
            setAgriTransport(transclear)
            VehicleDetailsval()
        } else {
            setAgriTransport(transclear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handleCategoryChangeVarient = (e) => {
        setAgriTransport({ ...agriTransport, [e.target.name]: e.target.value })
        getSubCategoryList(e.target.value)
        setCategoryName(e.target.value)
    }
    const getSubCategoryList = async (val) => {
        const { data } = await API.get(
            `/getProductSubCategory?type=0&title=ProductCatelog&catelogName=TransportCatelog&categoryName=` +
                val
        )
        console.log('hkjjhj', data)
        setsubCategorylist(data?.result)
    }
    const handleSUbCategoryChangeVarient = (e) => {
        setsubcategoryName(e.target.value)
        setAgriTransport({ ...agriTransport, [e.target.name]: e.target.value })
        getBrandCategoryList(e.target.value)
    }
    const handleBRANDCategoryChangeVarient = (e) => {
        setAgriTransport({ ...agriTransport, [e.target.name]: e.target.value })
        getProductListVarient(e.target.value)
    }
    const getProductListVarient = async (val) => {
        const { data } = await API.get(
            `/getProductCatelogProduct?type=1&title=ProductCatelog&catelogName=TransportCatelog&categoryName=` +
                CategoryName +
                '&subCategoryName=' +
                subcategoryName +
                '&brandName=' +
                val
        )
        console.log('hkjjhj', data)
        setProductlist(data?.result)
    }
    const getBrandCategoryList = async (val) => {
        const { data } = await API.get(
            `/getProductBrand?type=0&title=ProductCatelog&catelogName=TransportCatelog&categoryName=` +
                CategoryName +
                '&subCategoryName=' +
                val
        )
        console.log('hkjjhj', data)
        setBrandCategorylist(data?.result)
    }
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
        },
    })
    const handleUploadDoc = (e) => {
        setDocImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const uploadTask = ref(storage, `docs/${doc.name}`)
            const uploadTask02 = uploadBytesResumable(uploadTask, doc)
            uploadTask02.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    // Handle unsuccessful uploads
                },
                () => {
                    getDownloadURL(uploadTask).then((url) => {
                        setAgriTransport({ ...agriTransport, document: url })
                    })
                }
            )
        }
    }

    const PGthumbs = Files.map((file, index) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt="" />
            </div>
        </div>
    ))

    return (
        <div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card-body card-body_1">
                        <div className="row">
                            {console.log(
                                'MachineDetailsvalue',
                                MachineDetailsvalue
                            )}
                        </div>
                        {!addFarm ? (
                            <>
                                {console.log(
                                    'MachineDetailsvalue',
                                    MachineDetailsvalue
                                )}
                                <div
                                    class="card"
                                    style={{ backgroundColor: '#f3f3f3 ' }}
                                >
                                    {MachineDetailsvalue &&
                                        MachineDetailsvalue &&
                                        MachineDetailsvalue.map((val, ind) => {
                                            return (
                                                <div class="card-body">
                                                    <h5 class="card-title d-flex justify-content-between store_edit">
                                                        <span>
                                                            Vehicle {ind + 1}
                                                        </span>
                                                        <a
                                                            href="#"
                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                        >
                                                            <span class="material-icons-outlined mr-3">
                                                                edit
                                                            </span>
                                                            <span class="material-icons-outlined">
                                                                delete
                                                            </span>
                                                        </a>
                                                    </h5>
                                                    <h6 class="card-title d-flex justify-content-between store_edit">
                                                        <span>
                                                            Vehicle Details
                                                        </span>
                                                    </h6>
                                                    <ul class="list-unstyled mb-0 store_list">
                                                        <div className="row">
                                                            <div className="col-md-3 mb-4">
                                                                <li class="pt-2 pb-0">
                                                                    <small class="text-dark">
                                                                        Category{' '}
                                                                    </small>
                                                                </li>
                                                                <li>
                                                                    {
                                                                        val.category
                                                                    }
                                                                </li>
                                                            </div>
                                                            <div className="col-md-3 mb-4">
                                                                <li class="pt-2 pb-0">
                                                                    <small class="text-dark">
                                                                        Body
                                                                        Type{' '}
                                                                    </small>
                                                                </li>
                                                                <li>
                                                                    {
                                                                        val.body_type
                                                                    }
                                                                </li>
                                                            </div>
                                                            <div className="col-md-3 mb-4">
                                                                <li class="pt-2 pb-0">
                                                                    <small class="text-dark">
                                                                        Brand
                                                                        Name{' '}
                                                                    </small>
                                                                </li>
                                                                <li>
                                                                    {
                                                                        val.brand_name
                                                                    }
                                                                </li>
                                                            </div>
                                                            <div className="col-md-3 mb-4">
                                                                <li class="pt-2 pb-0">
                                                                    <small class="text-dark">
                                                                        Type Of
                                                                        Vehicle{' '}
                                                                    </small>
                                                                </li>
                                                                <li>
                                                                    {
                                                                        val.vehicle_type
                                                                    }
                                                                </li>
                                                            </div>
                                                            <div className="col-md-3 mb-4">
                                                                <li class="pt-2 pb-0">
                                                                    <small class="text-dark">
                                                                        Vehicle
                                                                        Number{' '}
                                                                    </small>
                                                                </li>
                                                                <li>
                                                                    {
                                                                        val.vehicle_number
                                                                    }
                                                                </li>
                                                            </div>
                                                            <div className="col-md-3 mb-4">
                                                                <li class="pt-2 pb-0">
                                                                    <small class="text-dark">
                                                                        Document{' '}
                                                                    </small>
                                                                </li>
                                                                <li>
                                                                    {/* {val.document} */}
                                                                    <img
                                                                        src={
                                                                            val
                                                                                .document
                                                                                .image
                                                                        }
                                                                        height={
                                                                            '80px'
                                                                        }
                                                                        style={{
                                                                            width: '50%',
                                                                        }}
                                                                        alt=""
                                                                    />
                                                                    {/* <a target="_blank" href={val.document}> {val.document}</a> */}
                                                                </li>
                                                            </div>
                                                            <div className="col-md-3 mb-4">
                                                                <li class="pt-2 pb-0">
                                                                    <small class="text-dark">
                                                                        Document
                                                                        Exp Date{' '}
                                                                    </small>
                                                                </li>
                                                                <li>
                                                                    {/* {new Date(val.document.document_exp).toDateString()} */}
                                                                    {
                                                                        val.document.document_exp.split(
                                                                            ' '
                                                                        )[0]
                                                                    }
                                                                </li>
                                                            </div>
                                                        </div>
                                                    </ul>
                                                    {/* Rental Details */}
                                                </div>
                                            )
                                        })}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="row">
                                    <div className="col-md-3">
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            className="mt-3"
                                        >
                                            <InputLabel
                                                id="demo-simple-select-standard-label"
                                                style={{ color: 'black' }}
                                            >
                                                Category
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                label="Category"
                                                color="success"
                                                name="category"
                                                value={agriTransport.category}
                                                onChange={(e) =>
                                                    handleCategoryChangeVarient(
                                                        e
                                                    )
                                                }
                                                // onChange={(e) => setAgriTransport({ ...agriTransport, [e.target.name]: e.target.value })}
                                            >
                                                {/* <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="0">Select State</MenuItem>
                              <MenuItem value="Hindi">Hindi</MenuItem>
                              <MenuItem value="Bengali">Bengali</MenuItem>
                              <MenuItem value="Urdu">Urdu</MenuItem>
                              <MenuItem value="Punjabi">Punjabi</MenuItem>
                              <MenuItem value="Marathi">Marathi</MenuItem>
                              <MenuItem value="Telugu">Telugu</MenuItem>
                              <MenuItem value="Tamil">Tamil</MenuItem> */}
                                                {Categorylist &&
                                                    Categorylist.map(
                                                        (val, ind) => {
                                                            // {console.log("Categorylist",val)}
                                                            return (
                                                                <MenuItem
                                                                    value={
                                                                        val.categoryName
                                                                    }
                                                                >
                                                                    {
                                                                        val.categoryName
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        }
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-3">
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            className="mt-3"
                                        >
                                            <InputLabel
                                                id="demo-simple-select-standard-label"
                                                style={{ color: 'black' }}
                                            >
                                                Body Type
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                label="Body Type"
                                                color="success"
                                                name="subCategory"
                                                value={
                                                    agriTransport.subCategory
                                                }
                                                onChange={(e) =>
                                                    handleSUbCategoryChangeVarient(
                                                        e
                                                    )
                                                }
                                                // onChange={(e) => setAgriTransport({ ...agriTransport, [e.target.name]: e.target.value })}
                                            >
                                                {/* <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="0">Select State</MenuItem>
                              <MenuItem value="Hindi">Hindi</MenuItem>
                              <MenuItem value="Bengali">Bengali</MenuItem>
                              <MenuItem value="Urdu">Urdu</MenuItem>
                              <MenuItem value="Punjabi">Punjabi</MenuItem>
                              <MenuItem value="Marathi">Marathi</MenuItem>
                              <MenuItem value="Telugu">Telugu</MenuItem>
                              <MenuItem value="Tamil">Tamil</MenuItem> */}
                                                {subCategorylist &&
                                                    subCategorylist.map(
                                                        (val, ind) => {
                                                            // {console.log("Categorylist",val)}
                                                            return (
                                                                <MenuItem
                                                                    value={
                                                                        val.subCategoryName
                                                                    }
                                                                >
                                                                    {
                                                                        val.subCategoryName
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        }
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-3">
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            className="mt-3"
                                        >
                                            <InputLabel
                                                id="demo-simple-select-standard-label"
                                                style={{ color: 'black' }}
                                            >
                                                Brand Name
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                label="Brand Name"
                                                color="success"
                                                name="brandName"
                                                value={agriTransport.brandName}
                                                onChange={(e) =>
                                                    handleBRANDCategoryChangeVarient(
                                                        e
                                                    )
                                                }
                                                // onChange={(e) => setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })}
                                            >
                                                {BrandCategorylist &&
                                                    BrandCategorylist.map(
                                                        (val, ind) => {
                                                            // {console.log("Categorylist",val)}
                                                            return (
                                                                <MenuItem
                                                                    value={
                                                                        val.brandName
                                                                    }
                                                                >
                                                                    {
                                                                        val.brandName
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        }
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-3">
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            className="mt-3"
                                        >
                                            <InputLabel
                                                id="demo-simple-select-standard-label"
                                                style={{ color: 'black' }}
                                            >
                                                Type Of Vehicle
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                label="Product Name"
                                                color="success"
                                                name="typeOfVehicle"
                                                value={
                                                    agriTransport.typeOfVehicle
                                                }
                                                onChange={(e) =>
                                                    setAgriTransport({
                                                        ...agriTransport,
                                                        [e.target.name]:
                                                            e.target.value,
                                                    })
                                                }
                                            >
                                                {Productlist &&
                                                    Productlist.map(
                                                        (val, ind) => {
                                                            // {console.log("Categorylist",val)}
                                                            return (
                                                                <MenuItem
                                                                    value={
                                                                        val.productName
                                                                    }
                                                                >
                                                                    {
                                                                        val.productName
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        }
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    {/* <div className="col-md-3">
                          <CssTextField
                            fullWidth
                            id="productName"
                            label="Product Name"
                            variant="standard"
                            name="productName"
                            value={agriTransport.productName}
                            onChange={(e) => setAgriTransport({ ...agriTransport, [e.target.name]: e.target.value })}
                          />
                        </div> */}
                                    <div className="col-md-3">
                                        <CssTextField
                                            fullWidth
                                            id="machineNumber"
                                            label="Vehicle Number"
                                            variant="standard"
                                            name="vehicleNumber"
                                            value={agriTransport.vehicleNumber}
                                            onChange={(e) =>
                                                setAgriTransport({
                                                    ...agriTransport,
                                                    [e.target.name]:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-md-12 mt-3" style={{}}>
                                        <InputLabel
                                            id="demo-simple-select-standard-label"
                                            style={{ color: 'black' }}
                                            className="mb-3"
                                        >
                                            Document :
                                        </InputLabel>
                                    </div>
                                    <div className="col-md-3">
                                        <InputLabel
                                            id="demo-simple-select-standard-label"
                                            style={{
                                                color: 'black',
                                                marginTop: '22px',
                                            }}
                                        >
                                            Document Expire Date:
                                        </InputLabel>
                                        <CssTextField
                                            fullWidth
                                            type="date"
                                            id="documentExpiryDate"
                                            variant="standard"
                                            name="documentExpiryDate"
                                            value={
                                                agriTransport.documentExpiryDate
                                            }
                                            onChange={(e) =>
                                                setAgriTransport({
                                                    ...agriTransport,
                                                    [e.target.name]:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-4">
                                        <label
                                            htmlFor="contained-button-file"
                                            style={{ marginTop: '60px' }}
                                        >
                                            <Button
                                                variant="contained"
                                                component="span"
                                                color="success"
                                            >
                                                <Input
                                                    accept="image/"
                                                    id="contained-button-file"
                                                    multiple="false"
                                                    type="file"
                                                    onChange={handleUploadDoc}
                                                />
                                                {/* Select File */}
                                            </Button>
                                        </label>
                                    </div>
                                    <div className="col-md-3">
                                        <aside style={thumbsContainerState}>
                                            <div style={thumb}>
                                                <div style={thumbInner}>
                                                    <img
                                                        src={docImg}
                                                        style={img}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </aside>
                                    </div>
                                    <div
                                        class="tab-pane show active"
                                        id="bottom-justified-tab5"
                                    >
                                        <div className="row">
                                            <div className="col-md-3">
                                                <CssTextField
                                                    fullWidth
                                                    type="text"
                                                    id="price"
                                                    label="Price"
                                                    variant="standard"
                                                    name="price"
                                                    value={agriTransport.price}
                                                    onChange={(e) =>
                                                        setAgriTransport({
                                                            ...agriTransport,
                                                            [e.target.name]:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CssTextField
                                                    fullWidth
                                                    type="text"
                                                    id="Per Unit"
                                                    label="Per Unit"
                                                    variant="standard"
                                                    name="perUnit"
                                                    value={
                                                        agriTransport.perUnit
                                                    }
                                                    onChange={(e) =>
                                                        setAgriTransport({
                                                            ...agriTransport,
                                                            [e.target.name]:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CssTextField
                                                    fullWidth
                                                    type="text"
                                                    id="MininumAmt"
                                                    label="Minimum Booking Amount"
                                                    variant="standard"
                                                    name="minimumBokingAmount"
                                                    value={
                                                        agriTransport.minimumBokingAmount
                                                    }
                                                    onChange={(e) =>
                                                        setAgriTransport({
                                                            ...agriTransport,
                                                            [e.target.name]:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            {/* <div class="col-md-12 text-end mt-2 mb-2">
                          <button className="w-10 btn btn-secondary" style={{ marginRight: '10px' }} onClick={e => setTab(3)}>Previous</button>
                          <button type="submit" class=" w-10 btn btn-primary">Next</button>
                        </div> */}
                                        </div>
                                    </div>
                                    <div class="col-md-12 text-end mt-2 mb-2">
                                        <button
                                            class=" w-16 btn btn-primary"
                                            onClick={(e) =>
                                                handlebehivlesubmit(e)
                                            }
                                        >
                                            Submit
                                        </button>
                                    </div>
                                    {/* <div class="col-md-12 text-end mt-2 mb-2">
                          <button className="w-10 btn btn-secondary" style={{ marginRight: '10px' }} onClick={e => setTab(2)}>Previous</button>
                          <button type="submit" class=" w-10 btn btn-primary">Next</button>
                        </div> */}
                                </div>
                                {/* <form class="tab-pane show active" id="bottom-justified-tab4" onSubmit={e=>console.log(5)}>
            <div className="row">
               <h4>Add Vehicle</h4>
               <div className="col-md-3">
                                    <FormControl variant="standard" fullWidth className="mt-3">
                                      <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>
                                        Category
                                      </InputLabel>
                                      <Select
                                       style={{padding: '4px 0 0px'}}
                                       labelId="demo-simple-select-standard-label"
                                       id="demo-simple-select-standard"
                                       //  value={age}
                                       //  onChange={handleChange}
                                       label="Category"
                                       color="success"
                                      >
                                       <MenuItem value="">
                                         <em>None</em>
                                       </MenuItem>
                                       <MenuItem value="0">Select State</MenuItem>
                                       <MenuItem value="Hindi">Hindi</MenuItem>
                                       <MenuItem value="Bengali">Bengali</MenuItem>
                                       <MenuItem value="Urdu">Urdu</MenuItem>
                                       <MenuItem value="Punjabi">Punjabi</MenuItem>
                                       <MenuItem value="Marathi">Marathi</MenuItem>
                                       <MenuItem value="Telugu">Telugu</MenuItem>
                                       <MenuItem value="Tamil">Tamil</MenuItem>
                                      </Select>
                                    </FormControl>
                                   </div>
                                   <div className="col-md-3">
                                    <FormControl variant="standard" fullWidth className="mt-3">
                                      <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>
                                        Sub Category
                                      </InputLabel>
                                      <Select
                                       style={{padding: '4px 0 0px'}}
                                       labelId="demo-simple-select-standard-label"
                                       id="demo-simple-select-standard"
                                       //  value={age}
                                       //  onChange={handleChange}
                                       label="Sub Category"
                                       color="success"
                                      >
                                       <MenuItem value="">
                                         <em>None</em>
                                       </MenuItem>
                                       <MenuItem value="0">Select State</MenuItem>
                                       <MenuItem value="Hindi">Hindi</MenuItem>
                                       <MenuItem value="Bengali">Bengali</MenuItem>
                                       <MenuItem value="Urdu">Urdu</MenuItem>
                                       <MenuItem value="Punjabi">Punjabi</MenuItem>
                                       <MenuItem value="Marathi">Marathi</MenuItem>
                                       <MenuItem value="Telugu">Telugu</MenuItem>
                                       <MenuItem value="Tamil">Tamil</MenuItem>
                                      </Select>
                                    </FormControl>
                                   </div>
                                   <div className="col-md-3">
                                    <CssTextField 
                                      fullWidth 
                                      id="productName" 
                                      label="Product Name*" 
                                      variant="standard"
                                    />
                                   </div>
                                   <div className="col-md-3">
                                    <CssTextField 
                                      fullWidth 
                                      id="vehicleNumber" 
                                      label="Vehicle Number*" 
                                      variant="standard"
                                    />
                                   </div>
                                   <div className="col-md-3">
                                      <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>
                                        Document : 
                                      </InputLabel>
                                      <div class="form-group row widget-3" style={{ width: '150px', margin: 'auto', display: 'flex', justifyContent: 'center'}}>
                                        <section className="container" style={baseStyle}>
                                         <div {...getRootProps({className: 'dropzone'})}
									         style={{
									     	 borderRadius: '5px',
									    	 display: 'flex',
									    	 justifyContent: 'center',
									    	 alignItems: 'center',
                                             height: '100px'
									    	 }}
									      >
                                          <input {...getInputProps()} multiple={false}/>
										  {Files &&
										   <>
										    {Files.length === 0 && 
                                          <p align="center">Drop Image  here or click to upload.</p>
                                          }
									      </>
									     }
									     {Files && 
									  <>
                                     {Files.length > 0 && 
                                      <aside style={thumbsContainerState}>{PGthumbs}</aside>
                                      }
								    </>
								   }
                                   </div>
                                  </section>
                                  </div>
                                   </div>
                                   <div className="col-md-12">
                                    <CssTextField 
                                      id="vehicleNumber" 
                                      label="Document Exp Date" 
                                      variant="standard"
                                    />
                                   </div>
                                   <div className="col-md-12">
                                    <h5>Vehicle Rental Details</h5>
                                   </div>
                                   <div className="col-md-3">
                                    <CssTextField 
                                      fullWidth 
                                      type="text"
                                      id="price"
                                      label="Price"
                                      variant="standard"
                                    />
                                   </div>
                                   <div className="col-md-3">
                                    <CssTextField 
                                      fullWidth 
                                      type="text"
                                      id="Per Unit"
                                      label="Per Unit"
                                      variant="standard"
                                    />
                                   </div>
                                   <div className="col-md-3">
                                    <CssTextField 
                                      required
                                      fullWidth 
                                      type="text"
                                      id="MininumAmt"
                                      label="Minimum Booking Amount"
                                      variant="standard"
                                    />
                                   </div>
    
                    <div class="col-md-12 text-end mt-2 mb-2">
                       <button type="submit" class=" w-10 btn btn-primary">Submit</button>
                    </div>
             </div> 
           </form> */}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VehicleDetails
