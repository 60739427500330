import React from 'react'

function Events() {
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Events</h5>
                    <div class="tab_add_new_btn">
                        <a href="#">Add</a>
                    </div>
                </div>
                <div class="card-body card-body_1">
                    <form>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card-body card-body_1">
                                    <div class="table-wrap">
                                        <div class="table-responsive">
                                            <table class="table display setting-td mb-30">
                                                <thead>
                                                    <tr>
                                                        <th>TITLE</th>
                                                        <th>DATE</th>
                                                        <th>ACTIONS</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            Independence Day
                                                        </td>
                                                        <td>2021-08-15</td>
                                                        <td>
                                                            <a
                                                                href="#"
                                                                class="btn btn-tbl-edit btn-xs td_btn"
                                                            >
                                                                <span class="material-icons-outlined">
                                                                    edit
                                                                </span>
                                                            </a>
                                                            <a
                                                                href="#"
                                                                class="btn btn-tbl-edit btn-xs td_btn"
                                                            >
                                                                <span class="material-icons-outlined">
                                                                    delete
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Holiday</td>
                                                        <td>2021-09-19</td>
                                                        <td>
                                                            <a
                                                                href="#"
                                                                class="btn btn-tbl-edit btn-xs td_btn"
                                                            >
                                                                <span class="material-icons-outlined">
                                                                    edit
                                                                </span>
                                                            </a>
                                                            <a
                                                                href="#"
                                                                class="btn btn-tbl-edit btn-xs td_btn"
                                                            >
                                                                <span class="material-icons-outlined">
                                                                    delete
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Events
