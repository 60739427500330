import { format } from 'date-fns'

export const COLUMNS = [
    {
        Header: 'ID',
        accessor: 'id',
    },
    {
        Header: 'Mobile Num',
        accessor: 'mobile_number',
    },
    {
        Header: 'Farmer',
        accessor: 'farmer',
    },
    {
        Header: 'Trader',
        accessor: 'trader',
    },
    {
        Header: 'Machine',
        accessor: 'agri-machine',
    },
    {
        Header: 'Transport',
        accessor: 'agri-transport',
    },
    {
        Header: 'Input Supplier',
        accessor: 'agri-input-supplier',
    },
    {
        Header: 'Livestock',
        accessor: 'livestock',
    },
    {
        Header: 'FPO',
        accessor: 'fpo',
    },
    {
        Header: 'Total',
        accessor: 'total',
    },
]
