import React from 'react'
import ViewModuleIcon from '@mui/icons-material/ViewModule'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'

function Sidebar(props) {
    const sidebar = [
        {
            title: 'Dashboard ',
            icon: 'maps_home_work',
            path: '/Home',
            active: false,
        },
        {
            title: 'Create & Book',
            icon: 'people',
            path: '/CreatenBookings',
            active: false,
        },
        {
            title: 'Catalogue',
            icon: 'category',
            path: '/Catalogue',
            active: false,
        },
        // {
        //     title: 'Revenue Plans',
        //     icon: 'account_balance_wallet',
        //     path: '/RevenuePlans',
        //     active: false,
        // },
        {
            title: 'Settings',
            icon: 'settings',
            path: '/settings',
            active: false,
        },
        {
            title: 'Modules',
            icon: 'book',
            path: '/Modules',
            active: false,
        },
        {
            title: 'Booking Details',
            icon: 'pie_chart_oultined',
            path: '/BookingDetailsidebar',
            active: false,
        },
        {
            title: 'Orders',
            icon: 'note',
            path: '/Orders',
            active: false,
        },
        {
            title: 'Reports',
            icon: 'account_balance_wallet',
            path: '/Reports',
            active: false,
        },
        {
            title: 'Count',
            icon: 'account_balance_wallet',
            path: '/Count',
            active: false,
        },
        {
            title: 'Booking Count',
            icon: 'account_balance_wallet',
            path: '/BookingCount',
            active: false,
        },
        {
            title: 'List',
            icon: 'account_balance_wallet',
            path: '/List',
            active: false,
        },
        {
            title: 'Invoice',
            icon: 'account_balance_wallet',
            path: '/Invoice',
            active: false,
        },
    ]
    const [_sidebar, setSidebar] = React.useState(sidebar)

    const setNavActive = (item) => {
        // eslint-disable-next-line
        setSidebar((prev) =>
            prev.map((menuItem) => {
                // eslint-disable-next-line
                if (!menuItem.active) menuItem.active = false
                return menuItem
            })
        )
        // console.log(sidebar);
        // item.active = !item.active;
        // setSidebar(sidebar);
    }
    return (
        <div class="sidebar" id="sidebar" style={{ zIndex: 99 }}>
            <div class="sidebar-inner slimscroll">
                <div id="sidebar-menu" class="sidebar-menu">
                    <ul>
                        {sidebar.map((item, index) => {
                            return (
                                <li
                                    onClick={() => setNavActive(item)}
                                    class={item.active ? 'active' : ''}
                                >
                                    <Link to={item.path}>
                                        <i class="material-icons-outlined">
                                            {item.icon}
                                        </i>
                                        <span> {item.title} </span>
                                        <span></span>
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Sidebar)
