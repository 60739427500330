import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { thumb, img, thumbInner } from '../Styles'
import { API } from '../../API'
import LivestockTable from '../Create/CreateLivestock/LivestockTable'
import Select from 'react-select'
import axios from 'axios'

function Settings() {
    const [Files, setFiles] = useState([])
    const [Details, setDetails] = useState([])
    const [number, setNumber] = useState()

    const [Option, setOption] = useState([])
    const [districtOption, setdistrictOption] = useState([])
    const getdistrictOptionlist = async () => {
        const { data } = await API.get(`/districtLiveStock`)
        setdistrictOption(data?.result)
        console.log('data', data)
    }
    const DistrictOption =
        districtOption &&
        districtOption.map((item, ind) => ({
            value: item,
            label: item,
        }))

    useEffect(async () => {
        getCategoryList()
        getdistrictOptionlist()
    }, [])

    const getCategoryList = async () => {
        const { data } = await API.get(`/stateLiveStock`)
        setOption(data?.result)
        console.log('data', data)
    }
    const option =
        Option &&
        Option.map((item, ind) => ({
            value: item,
            label: item,
        }))

    const handlesearchstate = async (e) => {
        console.log('e', e.value)
        setDetails([])
        const { data } = await API.get(`/getStateLiveStock?state=` + e.value)
        console.log('data', data)
        if (data.success == true) {
            setDetails(data?.result)
        }
    }
    const handlesearchdistrict = async (e) => {
        console.log('e', e.value)
        setDetails([])
        const { data } = await API.get(
            `/getDistrictLiveStock?district=` + e.value
        )
        console.log('data', data)
        if (data.success == true) {
            setDetails(data?.result)
        }
    }

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
        },
    })

    const PGthumbs = Files.map((file, index) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt="" />
            </div>
        </div>
    ))

    const handleMobilesearch = (e) => {
        console.log('e', e.target.value)
        if (e.target.value.length <= 10) setNumber(e.target.value)
        if (e.target.value.length == 10) mobilechecksearch(e.target.value)
        else setDetails([])
    }

    const mobilechecksearch = async (number) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var payload = {
            mobile_no: number,
            role: 'livestock',
        }
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-register`,
            payload,
            config
        )
        if (data.data) {
            var a = {
                id: data.data.data.id,
                user_id: data.data.data.user_id,
                firstName: data.data.data.first_name,
                lastName: data.data.data.last_name,
                mobileNumber: data.data.data.mobile_number,
                gender: data.data.data.gender,
                category: data.data.data.role,
                dateOfBirth: '2022-04-10',
                firmName: data.data.farm_info.firm_name,
                language: data.data.data.language,
                state: data.data.data.state,
                district: data.data.data.district,
                subDistrict: data.data.data.sub_distric,
                village: data.data.data.village,
                pincode: data.data.data.pincode,
                lat: data.data.data.lat,
                long: data.data.data.long,
                adhaarNumber: '',
                fullAddress: data.data.data.fullAddress,
                bankAccountNumber: '',
                bankAccountName: '',
                ifscCode: '',
                bankName: '',
                bankBranchName: '',
                createdAt: data.data.data.created_at,
            }
            let arr = []
            arr.push(a)
            setDetails(arr)
        }
    }

    return (
        <div class="row">
            <div class="col-sm-12">
                <div class="card card-table">
                    <div class="card-body">
                        <div class="table-responsive">
                            <div
                                id="DataTables_Table_0_wrapper"
                                class="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: '2%',
                                    }}
                                >
                                    {/* <div
                                        class="form-group"
                                        style={{
                                            marginLeft: '2%',
                                            width: '200px',
                                        }}
                                    >
                                        <Select
                                            options={option}
                                            placeholder={'Select State'}
                                            onChange={(e) =>
                                                handlesearchstate(e)
                                            }
                                        />
                                    </div>
                                    <div
                                        class="form-group"
                                        style={{
                                            marginLeft: '2%',
                                            width: '200px',
                                        }}
                                    >
                                        <Select
                                            options={DistrictOption}
                                            placeholder={'Select District'}
                                            onChange={(e) =>
                                                handlesearchdistrict(e)
                                            }
                                        />
                                    </div> */}
                                    <div
                                        class="form-group"
                                        style={{ marginLeft: '2%' }}
                                    >
                                        <input
                                            type="number"
                                            class="form-control"
                                            placeholder="Search Number"
                                            value={number}
                                            onChange={(e) =>
                                                handleMobilesearch(e)
                                            }
                                        />
                                    </div>
                                </div>
                                <div class="row" style={{ margin: '2%' }}>
                                    <LivestockTable livestock={Details} />
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-5"></div>
                                    <div class="col-sm-12 col-md-7">
                                        <div
                                            class="dataTables_paginate paging_simple_numbers"
                                            id="DataTables_Table_0_paginate"
                                        >
                                            <ul class="pagination">
                                                <li
                                                    class="paginate_button page-item previous disabled"
                                                    id="DataTables_Table_0_previous"
                                                >
                                                    <a
                                                        href="#"
                                                        aria-controls="DataTables_Table_0"
                                                        data-dt-idx="0"
                                                        tabindex="0"
                                                        class="page-link"
                                                    >
                                                        Previous
                                                    </a>
                                                </li>
                                                <li class="paginate_button page-item active">
                                                    <a
                                                        href="#"
                                                        aria-controls="DataTables_Table_0"
                                                        data-dt-idx="1"
                                                        tabindex="0"
                                                        class="page-link"
                                                    >
                                                        1
                                                    </a>
                                                </li>
                                                <li class="paginate_button page-item ">
                                                    <a
                                                        href="#"
                                                        aria-controls="DataTables_Table_0"
                                                        data-dt-idx="2"
                                                        tabindex="0"
                                                        class="page-link"
                                                    >
                                                        2
                                                    </a>
                                                </li>
                                                <li
                                                    class="paginate_button page-item next"
                                                    id="DataTables_Table_0_next"
                                                >
                                                    <a
                                                        href="#"
                                                        aria-controls="DataTables_Table_0"
                                                        data-dt-idx="3"
                                                        tabindex="0"
                                                        class="page-link"
                                                    >
                                                        Next
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Settings
