import React, { useState, useEffect } from 'react'
import { CssTextField } from '../../Styles'
import AddFarmer from '../AddFarmer'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { useDropzone } from 'react-dropzone'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {
    thumbsContainerState,
    thumb,
    img,
    thumbInner,
    mapThumb,
    baseStyle,
} from '../../Styles'
import EditTraders from './EditTraderDetails'
import { IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { API } from '../../../API'
import axios from 'axios'
import Map2 from '../../Create/Map2'
import ModuleOrders from '../../../components/ModuleOrders'
import BankingDetails from '../../../components/BankingDetails'
import Wallet from '../../../components/Wallet'
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}
const names = ['Wheat', 'Rice', 'Barley', 'Cereal', 'Tomato', 'Oat']

function Traders(props) {
    const [addDoc, setAddDoc] = useState(false)
    const [addWallet, setAddWallet] = useState(false)
    const [managerDoc, setManagerDoc] = useState(false)
    const [Files, setFiles] = useState([])
    const [firmEdit, setFirmEdit] = useState(false)
    const [Details, setDetails] = useState({})
    const [createdByData, setCreatedByData] = useState({})
    const [idForAssign, setIdForAssign] = useState('')
    const [userId, setUserId] = useState('')
    const [bankDetails, setBankDetails] = useState({})

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        var farmerid = localStorage.getItem('Traderid')
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }

        var payload = {
            mobile_no: farmerid,
            role: 'trader',
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-register`,
            payload,
            config
        )

        console.log('data', data)
        if (data.data) {
            var a = {
                _id: data.data.data._id,
                user_id: data.data.data._id,
                firstName: data.data.data.first_name,
                lastName: data.data.data.last_name,
                mobileNumber: data.data.data.mobile_number,
                gender: data.data.data.gender,
                category: data.data.data.role,
                createdById: data.data.data.createdById,
                dateOfBirth: '2022-04-10',
                education: '',
                language: data.data.data.language,
                businessMode: data.data.data.business_mode,
                state: data.data.data.state,
                district: data.data.data.district,
                subDistrict: data.data.data.sub_distric,
                village: data.data.data.village,
                pincode: data.data.data.pincode,
                adhaarNumber: '',
                fullAddress: data.data.data.full_address,
                bankAccountNumber: '',
                bankAccountName: '',
                ifscCode: '',
                lat: data.data.data.coordinates.coordinates[1],
                long: data.data.data.coordinates.coordinates[0],
                bankName: '',
                bankBranchName: '',
                gstNo: data.data.farm_info.gst_no,
                firmName: data.data.farm_info.firm_name,

                irrigation_source: 'Canal',
                mobile_number: '6353454356',
                soil_test: true,
                soil_type: 'Alluvial soil',
                sowing_date: '2022-04-08',
            }
            setDetails(a)

            setBankDetails(data.data.data.bank_info)
            setUserId(data.data.data.user_id)
            setIdForAssign(data.data.data.id)

            payload = {
                userId: data.data.data.createdById,
            }
            axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-user`,
                    payload,
                    config
                )
                .then((res) => {
                    const { role, id, mobile_number } = res.data.user[0]
                    setCreatedByData({
                        role,
                        id,
                        mobile_number,
                    })
                })
        }
    }, [])
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
        },
    })

    const PGthumbs = Files.map((file, index) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt="" />
            </div>
        </div>
    ))

    return (
        <div>
            <div class="row">
                <div class="page-header">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <ul class="breadcrumb justify-content-end">
                                <li class="breadcrumb-item">
                                    <a href="#">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="#">Trader</a>
                                </li>
                                <li class="breadcrumb-item active">
                                    {Details.firstName}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="card-title d-flex justify-content-between store_edit">
                                <span>Traders</span>
                                <a
                                    href="#"
                                    class="btn btn-tbl-edit btn-xs td_btn"
                                >
                                    <span class="material-icons-outlined">
                                        edit
                                    </span>
                                </a>
                            </h5>
                            <a class="store-avatar">
                                <div class="staffAvatar-root1">
                                    {Details &&
                                    Details.firstName &&
                                    Details.firstName.length > 0
                                        ? Details.firstName.slice(0, 1)
                                        : ''}
                                </div>
                                <div class="store_display">
                                    <h4>{Details.firstName}</h4>
                                    <span>{Details.mobileNumber}</span>
                                </div>
                            </a>
                        </div>

                        <div class="card-body">
                            <h5 class="card-title d-flex justify-content-between store_edit">
                                <span>Personal Info</span>
                                <a
                                    href="#"
                                    class="btn btn-tbl-edit btn-xs td_btn"
                                >
                                    <span class="material-icons-outlined">
                                        edit
                                    </span>
                                </a>
                            </h5>
                            <ul class="list-unstyled mb-0 store_list">
                                {/* <li class="pt-2 pb-0">
                                    <small class="text-dark">Name : </small>
                                    <span >{Details.firstName}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">MobileNumber: </small>
                                    <span >{Details.mobileNumber}</span>
                                </li>*/}
                                {/* <li class="pt-2 pb-0">
                                    <small class="text-dark">Email ID : </small>
                                    <span >{Details.email}</span>
                                </li> */}
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">Gender : </small>
                                    <span>{Details.gender}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">D.O.B : </small>
                                    <span>{Details.dateOfBirth}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">
                                        Business Mode :{' '}
                                    </small>
                                    <span>{Details.businessMode}</span>
                                </li>
                                {/* <li class="pt-2 pb-0">
                                    <small class="text-dark">Alternate Mobile No : </small>
                                    <span style={{ marginLeft: '20px' }}>.....</span>
                                </li> */}
                            </ul>
                        </div>
                    </div>

                    {/* <div className="card">
                        <div class="card-body">
                            <h5 class="card-title d-flex justify-content-between store_edit">
                                <span>Commission Details</span>
                                <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                                    <span class="material-icons-outlined">edit</span>
                                </a>
                            </h5>
                            <ul class="list-unstyled mb-0 store_list">

                                <li class="pt-2 pb-0">
                                    <small class="text-dark">Commission : </small>
                                    <span style={{ marginLeft: '20px' }}>{Details.commissionAmount}</span>
                                </li>
                            </ul>
                        </div>
                    </div> */}

                    <BankingDetails
                        Details={Details}
                        bankDetails={bankDetails}
                    />

                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title d-flex justify-content-between store_edit">
                                <span>Created By :</span>
                            </h5>
                            <ul class="list-unstyled mb-0 store_list">
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">Role : </small>
                                    <span>{createdByData.role}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">ID : </small>
                                    <span>{createdByData.id}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">
                                        Phone Number :{' '}
                                    </small>
                                    <span>{createdByData.mobile_number}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-xl-9 col-md-8">
                    <div class="card">
                        <div
                            class="card-body p_7"
                            style={{ padding: '10px 0px' }}
                        >
                            <form>
                                <div class="row form-group">
                                    <div class="col-md-12">
                                        <div class="card bg-white bn_15">
                                            <div class="card-body card-body_1">
                                                <ul class="nav nav-tabs nav-tabs-bottom nav_add_bt">
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link active"
                                                            href="#bottom-tab1"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Orders
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab2"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Wallet
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab3"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Documents
                                                        </a>
                                                    </li>
                                                    {/* <li class="nav-item"><a class="nav-link" href="#bottom-tab4" data-bs-toggle="tab">Manager Documents</a></li> */}

                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab6"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Firm Details
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab7 "
                                                            data-bs-toggle="tab"
                                                        >
                                                            Address Details
                                                        </a>
                                                    </li>
                                                </ul>

                                                <div class="tab-content">
                                                    {/* Orders */}
                                                    <ModuleOrders
                                                        userId={userId}
                                                        idForAssign={
                                                            idForAssign
                                                        }
                                                    />

                                                    {/* Wallet */}
                                                 <Wallet/>

                                                    {/* Document */}

                                                    <div
                                                        class="tab-pane"
                                                        id="bottom-tab3"
                                                    >
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="card-body card-body_1">
                                                                    <div className="row">
                                                                        <ul class="nav nav-tabs nav-tabs-bottom nav-justified mb-3">
                                                                            <li class="nav-item">
                                                                                <a
                                                                                    class="nav-link active"
                                                                                    href="#bottom-tab"
                                                                                    data-bs-toggle="tab"
                                                                                    onClick={() =>
                                                                                        setManagerDoc(
                                                                                            false
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Documents
                                                                                </a>
                                                                            </li>
                                                                            <li class="nav-item">
                                                                                <a
                                                                                    class="nav-link"
                                                                                    href="#bottom-tab"
                                                                                    data-bs-toggle="tab"
                                                                                    onClick={() =>
                                                                                        setManagerDoc(
                                                                                            true
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Manager
                                                                                    Douments
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    {!managerDoc ? (
                                                                        <>
                                                                            <div className="row">
                                                                                <div class="col-md-12 text-end justify-space-between mb-4">
                                                                                    {!addDoc ? (
                                                                                        <button
                                                                                            type="submit"
                                                                                            className="btn btn-primary"
                                                                                            onClick={(
                                                                                                e
                                                                                            ) => {
                                                                                                e.preventDefault()
                                                                                                setAddDoc(
                                                                                                    true
                                                                                                )
                                                                                            }}
                                                                                        >
                                                                                            +
                                                                                            Add
                                                                                            Document
                                                                                        </button>
                                                                                    ) : (
                                                                                        <button
                                                                                            type="submit"
                                                                                            className="btn btn-primary"
                                                                                            onClick={(
                                                                                                e
                                                                                            ) => {
                                                                                                e.preventDefault()
                                                                                                setAddDoc(
                                                                                                    false
                                                                                                )
                                                                                            }}
                                                                                        >
                                                                                            Document
                                                                                            List
                                                                                        </button>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            {!addDoc ? (
                                                                                <>
                                                                                    <div class="table-wrap">
                                                                                        <div class="table-responsive">
                                                                                            <table class="table display setting-td mb-30">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>
                                                                                                            TITLE
                                                                                                        </th>
                                                                                                        <th>
                                                                                                            PHOTO
                                                                                                        </th>
                                                                                                        <th>
                                                                                                            CREATED
                                                                                                        </th>
                                                                                                        <th>
                                                                                                            ACTIONS
                                                                                                        </th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            TRADE
                                                                                                            LIC
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            -
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            Sep
                                                                                                            21,
                                                                                                            11:19
                                                                                                            AM
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <a
                                                                                                                href="#"
                                                                                                                class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                            >
                                                                                                                <span class="material-icons-outlined">
                                                                                                                    edit
                                                                                                                </span>
                                                                                                            </a>
                                                                                                            <a
                                                                                                                href="#"
                                                                                                                class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                            >
                                                                                                                <span class="material-icons-outlined">
                                                                                                                    delete
                                                                                                                </span>
                                                                                                            </a>
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            TRADE
                                                                                                            LIC
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            -
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            Sep
                                                                                                            21,
                                                                                                            11:19
                                                                                                            AM
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <a
                                                                                                                href="#"
                                                                                                                class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                            >
                                                                                                                <span class="material-icons-outlined">
                                                                                                                    edit
                                                                                                                </span>
                                                                                                            </a>
                                                                                                            <a
                                                                                                                href="#"
                                                                                                                class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                            >
                                                                                                                <span class="material-icons-outlined">
                                                                                                                    delete
                                                                                                                </span>
                                                                                                            </a>
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            TRADE
                                                                                                            LIC
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            -
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            Sep
                                                                                                            21,
                                                                                                            11:19
                                                                                                            AM
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <a
                                                                                                                href="#"
                                                                                                                class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                            >
                                                                                                                <span class="material-icons-outlined">
                                                                                                                    edit
                                                                                                                </span>
                                                                                                            </a>
                                                                                                            <a
                                                                                                                href="#"
                                                                                                                class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                            >
                                                                                                                <span class="material-icons-outlined">
                                                                                                                    delete
                                                                                                                </span>
                                                                                                            </a>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <form
                                                                                        class="tab-pane show active"
                                                                                        id="bottom-justified-tab4"
                                                                                        onSubmit={(
                                                                                            e
                                                                                        ) =>
                                                                                            console.log(
                                                                                                5
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <div className="row">
                                                                                            <h4>
                                                                                                Add
                                                                                                New
                                                                                                Document
                                                                                            </h4>
                                                                                            <div className="col-md-3 mb-2">
                                                                                                <InputLabel
                                                                                                    id="demo-simple-select-standard-label"
                                                                                                    style={{
                                                                                                        color: 'black',
                                                                                                    }}
                                                                                                >
                                                                                                    Document
                                                                                                    Upload
                                                                                                </InputLabel>
                                                                                                <div
                                                                                                    class="form-group row widget-3"
                                                                                                    style={{
                                                                                                        width: '200px',
                                                                                                        margin: 'auto',
                                                                                                        display:
                                                                                                            'flex',
                                                                                                        justifyContent:
                                                                                                            'center',
                                                                                                    }}
                                                                                                >
                                                                                                    <section
                                                                                                        className="container"
                                                                                                        style={
                                                                                                            baseStyle
                                                                                                        }
                                                                                                    >
                                                                                                        <div
                                                                                                            {...getRootProps(
                                                                                                                {
                                                                                                                    className:
                                                                                                                        'dropzone',
                                                                                                                }
                                                                                                            )}
                                                                                                            style={{
                                                                                                                borderRadius:
                                                                                                                    '5px',
                                                                                                                display:
                                                                                                                    'flex',
                                                                                                                justifyContent:
                                                                                                                    'center',
                                                                                                                alignItems:
                                                                                                                    'center',
                                                                                                                height: '100px',
                                                                                                            }}
                                                                                                        >
                                                                                                            <input
                                                                                                                {...getInputProps()}
                                                                                                                multiple={
                                                                                                                    false
                                                                                                                }
                                                                                                            />
                                                                                                            {Files && (
                                                                                                                <>
                                                                                                                    {Files.length ===
                                                                                                                        0 && (
                                                                                                                        <p align="center">
                                                                                                                            Drop
                                                                                                                            Image
                                                                                                                            here
                                                                                                                            or
                                                                                                                            click
                                                                                                                            to
                                                                                                                            upload.
                                                                                                                        </p>
                                                                                                                    )}
                                                                                                                </>
                                                                                                            )}
                                                                                                            {Files && (
                                                                                                                <>
                                                                                                                    {Files.length >
                                                                                                                        0 && (
                                                                                                                        <aside
                                                                                                                            style={
                                                                                                                                thumbsContainerState
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {
                                                                                                                                PGthumbs
                                                                                                                            }
                                                                                                                        </aside>
                                                                                                                    )}
                                                                                                                </>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </section>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div class="col-md-3">
                                                                                                <FormControl
                                                                                                    variant="standard"
                                                                                                    fullWidth
                                                                                                >
                                                                                                    <InputLabel
                                                                                                        id="demo-simple-select-standard-label"
                                                                                                        style={{
                                                                                                            color: 'black',
                                                                                                        }}
                                                                                                    >
                                                                                                        Document
                                                                                                        Type
                                                                                                    </InputLabel>
                                                                                                    <Select
                                                                                                        labelId="demo-simple-select-standard-label"
                                                                                                        id="soilType"
                                                                                                        color="success"
                                                                                                        name="soilType"
                                                                                                        //  value={farmer.selectUnit}
                                                                                                        //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                                                                                    >
                                                                                                        <MenuItem value="">
                                                                                                            <em>
                                                                                                                -
                                                                                                                Select
                                                                                                                Unit
                                                                                                                -
                                                                                                            </em>
                                                                                                        </MenuItem>
                                                                                                        <MenuItem value="Hindi">
                                                                                                            Aadhar
                                                                                                        </MenuItem>
                                                                                                        <MenuItem value="Bengali">
                                                                                                            PAN
                                                                                                        </MenuItem>
                                                                                                    </Select>
                                                                                                </FormControl>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div class="col-md-3">
                                                                                                <CssTextField
                                                                                                    fullWidth
                                                                                                    id="fEducation"
                                                                                                    label="Document Number"
                                                                                                    variant="standard"
                                                                                                    style={{
                                                                                                        marginBottom:
                                                                                                            '1.25rem',
                                                                                                    }}
                                                                                                    name="education"
                                                                                                    //  value={farmer.education}
                                                                                                    //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                                                            <button
                                                                                                type="submit"
                                                                                                class=" w-10 btn btn-primary"
                                                                                            >
                                                                                                Submit
                                                                                            </button>
                                                                                        </div>
                                                                                    </form>
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        // ManagerDocuments
                                                                        <>
                                                                            <div class="row">
                                                                                <div class="col-lg-12">
                                                                                    <div class="card-body card-body_1">
                                                                                        <div className="row">
                                                                                            <div class="col-md-12 text-end justify-space-between mb-4">
                                                                                                {!addDoc ? (
                                                                                                    <button
                                                                                                        type="submit"
                                                                                                        className="btn btn-primary"
                                                                                                        onClick={(
                                                                                                            e
                                                                                                        ) => {
                                                                                                            e.preventDefault()
                                                                                                            setAddDoc(
                                                                                                                true
                                                                                                            )
                                                                                                        }}
                                                                                                    >
                                                                                                        +
                                                                                                        Add
                                                                                                        Document
                                                                                                    </button>
                                                                                                ) : (
                                                                                                    <button
                                                                                                        type="submit"
                                                                                                        className="btn btn-primary"
                                                                                                        onClick={(
                                                                                                            e
                                                                                                        ) => {
                                                                                                            e.preventDefault()
                                                                                                            setAddDoc(
                                                                                                                false
                                                                                                            )
                                                                                                        }}
                                                                                                    >
                                                                                                        Document
                                                                                                        List
                                                                                                    </button>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                        {!addDoc ? (
                                                                                            <>
                                                                                                <div class="table-wrap">
                                                                                                    <div class="table-responsive">
                                                                                                        <table class="table display setting-td mb-30">
                                                                                                            <thead>
                                                                                                                <tr>
                                                                                                                    <th>
                                                                                                                        TITLE
                                                                                                                    </th>
                                                                                                                    <th>
                                                                                                                        PHOTO
                                                                                                                    </th>
                                                                                                                    <th>
                                                                                                                        CREATED
                                                                                                                    </th>
                                                                                                                    <th>
                                                                                                                        ACTIONS
                                                                                                                    </th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td>
                                                                                                                        TRADE
                                                                                                                        LIC
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        -
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        Sep
                                                                                                                        21,
                                                                                                                        11:19
                                                                                                                        AM
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <a
                                                                                                                            href="#"
                                                                                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                                        >
                                                                                                                            <span class="material-icons-outlined">
                                                                                                                                edit
                                                                                                                            </span>
                                                                                                                        </a>
                                                                                                                        <a
                                                                                                                            href="#"
                                                                                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                                        >
                                                                                                                            <span class="material-icons-outlined">
                                                                                                                                delete
                                                                                                                            </span>
                                                                                                                        </a>
                                                                                                                    </td>
                                                                                                                </tr>

                                                                                                                <tr>
                                                                                                                    <td>
                                                                                                                        TRADE
                                                                                                                        LIC
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        -
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        Sep
                                                                                                                        21,
                                                                                                                        11:19
                                                                                                                        AM
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <a
                                                                                                                            href="#"
                                                                                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                                        >
                                                                                                                            <span class="material-icons-outlined">
                                                                                                                                edit
                                                                                                                            </span>
                                                                                                                        </a>
                                                                                                                        <a
                                                                                                                            href="#"
                                                                                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                                        >
                                                                                                                            <span class="material-icons-outlined">
                                                                                                                                delete
                                                                                                                            </span>
                                                                                                                        </a>
                                                                                                                    </td>
                                                                                                                </tr>

                                                                                                                <tr>
                                                                                                                    <td>
                                                                                                                        TRADE
                                                                                                                        LIC
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        -
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        Sep
                                                                                                                        21,
                                                                                                                        11:19
                                                                                                                        AM
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <a
                                                                                                                            href="#"
                                                                                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                                        >
                                                                                                                            <span class="material-icons-outlined">
                                                                                                                                edit
                                                                                                                            </span>
                                                                                                                        </a>
                                                                                                                        <a
                                                                                                                            href="#"
                                                                                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                                        >
                                                                                                                            <span class="material-icons-outlined">
                                                                                                                                delete
                                                                                                                            </span>
                                                                                                                        </a>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <form
                                                                                                    class="tab-pane show active"
                                                                                                    id="bottom-justified-tab4"
                                                                                                    onSubmit={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        console.log(
                                                                                                            5
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <div className="row">
                                                                                                        <h4>
                                                                                                            Add
                                                                                                            Manager
                                                                                                            Document
                                                                                                        </h4>
                                                                                                        <div className="col-md-3 mb-2">
                                                                                                            <InputLabel
                                                                                                                id="demo-simple-select-standard-label"
                                                                                                                style={{
                                                                                                                    color: 'black',
                                                                                                                }}
                                                                                                            >
                                                                                                                Document
                                                                                                                Upload
                                                                                                            </InputLabel>
                                                                                                            <div
                                                                                                                class="form-group row widget-3"
                                                                                                                style={{
                                                                                                                    width: '200px',
                                                                                                                    margin: 'auto',
                                                                                                                    display:
                                                                                                                        'flex',
                                                                                                                    justifyContent:
                                                                                                                        'center',
                                                                                                                }}
                                                                                                            >
                                                                                                                <section
                                                                                                                    className="container"
                                                                                                                    style={
                                                                                                                        baseStyle
                                                                                                                    }
                                                                                                                >
                                                                                                                    <div
                                                                                                                        {...getRootProps(
                                                                                                                            {
                                                                                                                                className:
                                                                                                                                    'dropzone',
                                                                                                                            }
                                                                                                                        )}
                                                                                                                        style={{
                                                                                                                            borderRadius:
                                                                                                                                '5px',
                                                                                                                            display:
                                                                                                                                'flex',
                                                                                                                            justifyContent:
                                                                                                                                'center',
                                                                                                                            alignItems:
                                                                                                                                'center',
                                                                                                                            height: '100px',
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <input
                                                                                                                            {...getInputProps()}
                                                                                                                            multiple={
                                                                                                                                false
                                                                                                                            }
                                                                                                                        />
                                                                                                                        {Files && (
                                                                                                                            <>
                                                                                                                                {Files.length ===
                                                                                                                                    0 && (
                                                                                                                                    <p align="center">
                                                                                                                                        Drop
                                                                                                                                        Image
                                                                                                                                        here
                                                                                                                                        or
                                                                                                                                        click
                                                                                                                                        to
                                                                                                                                        upload.
                                                                                                                                    </p>
                                                                                                                                )}
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                        {Files && (
                                                                                                                            <>
                                                                                                                                {Files.length >
                                                                                                                                    0 && (
                                                                                                                                    <aside
                                                                                                                                        style={
                                                                                                                                            thumbsContainerState
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        {
                                                                                                                                            PGthumbs
                                                                                                                                        }
                                                                                                                                    </aside>
                                                                                                                                )}
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </section>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row">
                                                                                                        <div class="col-md-3">
                                                                                                            <FormControl
                                                                                                                variant="standard"
                                                                                                                fullWidth
                                                                                                                className="mt-3"
                                                                                                            >
                                                                                                                <InputLabel
                                                                                                                    id="demo-simple-select-standard-label"
                                                                                                                    style={{
                                                                                                                        color: 'black',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    Document
                                                                                                                    Type
                                                                                                                </InputLabel>
                                                                                                                <Select
                                                                                                                    labelId="demo-simple-select-standard-label"
                                                                                                                    id="soilType"
                                                                                                                    label="Soil Type"
                                                                                                                    color="success"
                                                                                                                    name="soilType"
                                                                                                                    //  value={farmer.selectUnit}
                                                                                                                    //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                                                                                                >
                                                                                                                    <MenuItem value="">
                                                                                                                        <em>
                                                                                                                            -
                                                                                                                            Select
                                                                                                                            Unit
                                                                                                                            -
                                                                                                                        </em>
                                                                                                                    </MenuItem>
                                                                                                                    <MenuItem value="Hindi">
                                                                                                                        Aadhar
                                                                                                                    </MenuItem>
                                                                                                                    <MenuItem value="Bengali">
                                                                                                                        PAN
                                                                                                                    </MenuItem>
                                                                                                                </Select>
                                                                                                            </FormControl>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row">
                                                                                                        <div class="col-md-3">
                                                                                                            <CssTextField
                                                                                                                fullWidth
                                                                                                                id="fEducation"
                                                                                                                label="Document Number"
                                                                                                                variant="standard"
                                                                                                                className="mt-3"
                                                                                                                style={{
                                                                                                                    marginBottom:
                                                                                                                        '1.25rem',
                                                                                                                }}
                                                                                                                name="education"
                                                                                                                //  value={farmer.education}
                                                                                                                //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="col-md-12 text-end mt-2 mb-2">
                                                                                                        <button
                                                                                                            type="submit"
                                                                                                            class=" w-10 btn btn-primary"
                                                                                                        >
                                                                                                            Submit
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </form>
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Address Details */}
                                                    <div
                                                        class="tab-pane"
                                                        id="bottom-tab6"
                                                    >
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="card-body card-body_1">
                                                                    <div className="row">
                                                                        <div class="col-md-12 text-end justify-space-between mb-4"></div>
                                                                    </div>
                                                                    <div
                                                                        class="card"
                                                                        style={{
                                                                            backgroundColor:
                                                                                '#f3f3f3 ',
                                                                        }}
                                                                    >
                                                                        <div class="card-body">
                                                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                                                <span>
                                                                                    Details
                                                                                </span>
                                                                                <a
                                                                                    href="#"
                                                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                                                >
                                                                                    <span class="material-icons-outlined mr-3">
                                                                                        edit
                                                                                    </span>
                                                                                    <span class="material-icons-outlined">
                                                                                        delete
                                                                                    </span>
                                                                                </a>
                                                                            </h5>
                                                                            <ul class="list-unstyled mb-0 store_list">
                                                                                <div className="row">
                                                                                    <div className="col-md-4 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                Firm
                                                                                                Name{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.firmName
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-4 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                GST
                                                                                                No{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.gstNo
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-4 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                PAN
                                                                                                No{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.panNumber
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    {/* <div className="col-md-4 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">Company Name  </small>
                                                                                        </li>
                                                                                        <li>
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-4 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">No Of Employees  </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            ........
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-4 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">No Of Members </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            ........
                                                                                        </li>
                                                                                    </div> */}
                                                                                    <div className="col-md-4 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                Types
                                                                                                of
                                                                                                Crop{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            Test
                                                                                        </li>
                                                                                    </div>
                                                                                    {/* <div className="col-md-4 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">Govt Fund </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            ........
                                                                                        </li>
                                                                                    </div> */}
                                                                                    <div className="col-md-12 mb-4"></div>
                                                                                </div>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Firm Details */}
                                                    <div
                                                        class="tab-pane"
                                                        id="bottom-tab7"
                                                    >
                                                        {!firmEdit ? (
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <div class="card-body card-body_1">
                                                                        <div
                                                                            class="card"
                                                                            style={{
                                                                                backgroundColor:
                                                                                    '#f3f3f3 ',
                                                                            }}
                                                                        >
                                                                            <div class="card-body">
                                                                                <h5 class="card-title d-flex justify-content-between store_edit">
                                                                                    <span>
                                                                                        Address
                                                                                    </span>
                                                                                    <div>
                                                                                        <IconButton
                                                                                            onClick={() =>
                                                                                                setFirmEdit(
                                                                                                    true
                                                                                                )
                                                                                            }
                                                                                            color="success"
                                                                                        >
                                                                                            <EditIcon color="success" />
                                                                                        </IconButton>
                                                                                        <IconButton
                                                                                            style={{
                                                                                                marginLeft:
                                                                                                    '8px',
                                                                                            }}
                                                                                            color="error"
                                                                                        >
                                                                                            <DeleteIcon color="error" />
                                                                                        </IconButton>
                                                                                    </div>
                                                                                </h5>
                                                                                <ul class="list-unstyled mb-0 store_list">
                                                                                    <div className="row">
                                                                                        {/* <div className="col-md-3 mb-4">
                                                                                            <li class="pt-2 pb-0">
                                                                                                <small class="text-dark">Types Of  Crop </small>
                                                                                            </li>
                                                                                            <li>
                                                                                            {Details && Details.typeOfCrops &&Details.typeOfCrops.map((val)=>val)}

                                                                                            </li>
                                                                                        </div> */}
                                                                                        <div className="col-md-3 mb-4">
                                                                                            <li class="pt-2 pb-0">
                                                                                                <small class="text-dark">
                                                                                                    State{' '}
                                                                                                </small>
                                                                                            </li>
                                                                                            <li>
                                                                                                {
                                                                                                    Details.state
                                                                                                }
                                                                                            </li>
                                                                                        </div>
                                                                                        <div className="col-md-3 mb-4">
                                                                                            <li class="pt-2 pb-0">
                                                                                                <small class="text-dark">
                                                                                                    District{' '}
                                                                                                </small>
                                                                                            </li>
                                                                                            <li>
                                                                                                {
                                                                                                    Details.district
                                                                                                }
                                                                                            </li>
                                                                                        </div>
                                                                                        <div className="col-md-3 mb-4">
                                                                                            <li class="pt-2 pb-0">
                                                                                                <small class="text-dark">
                                                                                                    Sub
                                                                                                    District{' '}
                                                                                                </small>
                                                                                            </li>
                                                                                            <li>
                                                                                                {
                                                                                                    Details.subDistrict
                                                                                                }
                                                                                            </li>
                                                                                        </div>
                                                                                        <div className="col-md-3 mb-4">
                                                                                            <li class="pt-2 pb-0">
                                                                                                <small class="text-dark">
                                                                                                    {' '}
                                                                                                    Village{' '}
                                                                                                </small>
                                                                                            </li>
                                                                                            <li>
                                                                                                {
                                                                                                    Details.village
                                                                                                }
                                                                                            </li>
                                                                                        </div>
                                                                                        <div className="col-md-3 mb-4">
                                                                                            <li class="pt-2 pb-0">
                                                                                                <small class="text-dark">
                                                                                                    {' '}
                                                                                                    Pincode{' '}
                                                                                                </small>
                                                                                            </li>
                                                                                            <li>
                                                                                                {
                                                                                                    Details.pincode
                                                                                                }
                                                                                            </li>
                                                                                        </div>
                                                                                        <div className="col-md-3 mb-4">
                                                                                            <li class="pt-2 pb-0">
                                                                                                <small class="text-dark">
                                                                                                    {' '}
                                                                                                    Address{' '}
                                                                                                </small>
                                                                                            </li>
                                                                                            <li>
                                                                                                {
                                                                                                    Details.fullAddress
                                                                                                }
                                                                                            </li>
                                                                                        </div>
                                                                                        <div className="col-md-12 mb-4">
                                                                                            <li class="pt-2 pb-0">
                                                                                                <h5 class="card-title d-flex justify-content-between mt-4">
                                                                                                    <span>
                                                                                                        Location
                                                                                                    </span>
                                                                                                </h5>
                                                                                            </li>
                                                                                            <li>
                                                                                                <Map2
                                                                                                    center={{
                                                                                                        lat: +Details.lat,
                                                                                                        lng: +Details.long,
                                                                                                    }}
                                                                                                    height="300px"
                                                                                                    zoom={
                                                                                                        15
                                                                                                    }
                                                                                                />
                                                                                                {/* <a class="store-avatar" style={mapThumb}>
                                                                                                    <div class="store_display">
                                                                                                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15318.518229107614!2d80.4303255!3d16.2907457!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3d93323686c7ea15!2sSRI%20HANUMAN%20TRADERS!5e0!3m2!1sen!2sin!4v1636515519444!5m2!1sen!2sin" width="100%" height="250" allowfullscreen="" loading="lazy"></iframe>
                                                                                                    </div>
                                                                                                </a> */}
                                                                                            </li>
                                                                                        </div>
                                                                                    </div>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div class="row">
                                                                    <div class="col-md-12 text-end mb-2">
                                                                        <button
                                                                            type="submit"
                                                                            class=" w-10 btn btn-primary"
                                                                            onClick={() =>
                                                                                setFirmEdit(
                                                                                    false
                                                                                )
                                                                            }
                                                                        >
                                                                            Back
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <EditTraders />
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Traders
