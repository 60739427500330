import React, { useState, useEffect } from 'react'
import { CssTextField } from '../../../Styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Switch from '@mui/material/Switch'
import { API } from '../../../../API'
import Swal from 'sweetalert2'
import MachineInventoryTable from './MachineInventoryTable'
function MachineInventory() {
    // useEffect(()=> {
    //    console.log('Its Working');
    //    alert('You Clicked Add Product...')
    // }, [])
    const InventoryClear = {
        title: 'ProductCatelog',
        catelogName: 'MachineCatelog',
        categoryName: '',
        subCategoryName: '',
        brandName: '',
        productName: '',
        variantName: '',
        unit: '',
        price: '',
        maxPrice: '',
        isUnlimited: false,
        discount: '',
        stock: '',
    }

    const [Invendory, setInventory] = useState({
        title: 'ProductCatelog',
        catelogName: 'MachineCatelog',
        categoryName: '',
        subCategoryName: '',
        brandName: '',
        productName: '',
        variantName: '',
        unit: '',
        price: '',
        maxPrice: '',
        isUnlimited: false,
        discount: '',
        stock: '',
    })
    const [Categorylist, setCategorylist] = useState([])
    const [bro, setbro] = useState()
    const [subCategorylist, setsubCategorylist] = useState([])
    const [brandlist, setbrandlist] = useState([])
    const [categoryName, setcategoryName] = useState('')
    const [subcategoryName, setsubcategoryName] = useState('')
    const [Productlist, setProductlist] = useState([])
    const [Varientlist, setVarientlist] = useState([])
    const [Unitlist, setUnitlist] = useState([])
    const [brandName, setbrandName] = useState('')
    const handleSubmit = async () => {
        console.log('handleSubmit', Invendory)
        try {
            const { data } = await API.post('/createProductCatelog', Invendory)
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)

                setInventory(InventoryClear)
            } else {
                setInventory(InventoryClear)
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setInventory(InventoryClear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const [ProductInput, setProductInput] = useState([])
    useEffect(() => {
        getCategoryList()

        // getState();
        getProductInput()
    }, [])

    const getProductInput = async () => {
        const { data } = await API.get(
            `/getProductCatelog?title=ProductCatelog&catelogName=MachineCatelog&type=2`
        )
        setProductInput(data?.result)
    }
    const handleCategoryChangeVarient = (e) => {
        // setInventory({...Invendory,subCategoryName:e.target.value})
        setInventory({ ...Invendory, categoryName: e.target.value })
        getSubCategoryList(e.target.value)
        setcategoryName(e.target.value)
    }
    const getCategoryList = async () => {
        const { data } = await API.get(
            `/getProductCategory?type=0&title=ProductCatelog&catelogName=MachineCatelog`
        )
        // console.log("hkjjhj",data)
        setCategorylist(data?.result)
    }

    const getSubCategoryList = async (val) => {
        const { data } = await API.get(
            `/getProductSubCategory?type=0&title=ProductCatelog&catelogName=MachineCatelog&categoryName=` +
                val
        )
        console.log('hkjjhj', data)
        setsubCategorylist(data?.result)
    }

    const handlechangeproductVarientBrand = (e) => {
        console.log(e.target.value, 'e')
        setInventory({ ...Invendory, subCategoryName: e.target.value })
        getBrandListVarient(e.target.value)
        setsubcategoryName(e.target.value)
    }
    const getBrandListVarient = async (val) => {
        const { data } = await API.get(
            `/getProductBrand?type=0&title=ProductCatelog&catelogName=MachineCatelog&categoryName=` +
                categoryName +
                '&subCategoryName=' +
                val
        )
        console.log('hkjjhj', data)
        setbrandlist(data?.result)
    }
    const handlechangeproductVarientProduct = (e) => {
        setInventory({ ...Invendory, brandName: e.target.value })
        getProductListVarient(e.target.value)
        setbrandName(e.target.value)
    }
    const getProductListVarient = async (val) => {
        const { data } = await API.get(
            `/getProductCatelogProduct?type=3&title=ProductCatelog&catelogName=MachineCatelog&categoryName=` +
                categoryName +
                '&subCategoryName=' +
                subcategoryName +
                '&brandName=' +
                val
        )
        console.log('hkjjhj', data)
        setProductlist(data?.result)
    }
    const getunitList = async (val) => {
        const { data } = await API.get(
            `/getProductUnit?type=0&title=ProductCatelog&catelogName=MachineCatelog`
        )
        console.log('hkjjhj', data)
        setUnitlist(data?.result)
    }
    const handlevarientlistname = (e) => {
        setInventory({ ...Invendory, variantName: e.target.value })
        getunitList(e.target.value)
    }

    const handleVarientchange = (e) => {
        // setInventory({...Invendory,brandName:e.target.value})
        console.log('e', e)
        setbro(e.target.value)
        console.log(',', e.target.value.productName)
        setInventory({
            ...Invendory,
            productName: e.target.value.productName,
            quantity: e.target.value.insideQuantity,
        })
        getVarientlistVarient(e.target.value)
    }
    const getVarientlistVarient = async (val) => {
        const { data } = await API.get(
            `/getProductCatelogQuantity?type=0&title=ProductCatelog&catelogName=MachineCatelog&categoryName=` +
                categoryName +
                '&subCategoryName=' +
                subcategoryName +
                '&brandName=' +
                brandName +
                '&productName=' +
                val
        )
        console.log('hkjjhj', data)
        setVarientlist(data?.result)
    }
    return (
        <>
            <div className="card card-table p-10-25">
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="mt-3">
                        <h5 class="card-title">Create Inventory</h5>
                        <div class="row mb-4">
                            <div class="col-md-3">
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    className="mt-3"
                                >
                                    <InputLabel
                                        id="demo-simple-select-standard-label"
                                        style={{ color: 'black' }}
                                    >
                                        Category
                                    </InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        //  value={age}
                                        //  onChange={handleChange}
                                        label="Firm"
                                        color="success"
                                        value={Invendory.categoryName}
                                        onChange={(e) =>
                                            handleCategoryChangeVarient(e)
                                        }
                                        // onChange={(e)=>setInventory({...Invendory,categoryName:e.target.value})}
                                    >
                                        {/* <MenuItem value="">
                         <em>None</em>
                       </MenuItem>
                       <MenuItem value={"General"}>General</MenuItem>
                        <MenuItem value={"OBC"}>OBC</MenuItem>
                        <MenuItem value={"SC"}>SC</MenuItem>
                        <MenuItem value={"ST"}>ST</MenuItem>
                        <MenuItem value={"Others"}>Others</MenuItem> */}
                                        {Categorylist &&
                                            Categorylist.map((val, ind) => {
                                                // {console.log("Categorylist",val)}
                                                return (
                                                    <MenuItem
                                                        value={val.categoryName}
                                                    >
                                                        {val.categoryName}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div class="col-md-3">
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    className="mt-3"
                                >
                                    <InputLabel
                                        id="demo-simple-select-standard-label"
                                        style={{ color: 'black' }}
                                    >
                                        TypeOfMachine
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        //  value={age}
                                        //  onChange={handleChange}
                                        label="Booking Mode"
                                        color="success"
                                        value={Invendory.subCategoryName}
                                        //  onChange={(e)=>setInventory({...Invendory,subCategoryName:e.target.value})}
                                        onChange={(e) =>
                                            handlechangeproductVarientBrand(e)
                                        }
                                    >
                                        {/* <MenuItem value="">
                             <em>None</em>
                           </MenuItem>
                           <MenuItem value={"General"}>General</MenuItem>
                        <MenuItem value={"OBC"}>OBC</MenuItem>
                        <MenuItem value={"SC"}>SC</MenuItem>
                        <MenuItem value={"ST"}>ST</MenuItem>
                        <MenuItem value={"Others"}>Others</MenuItem> */}
                                        {subCategorylist &&
                                            subCategorylist.map((val, ind) => {
                                                // {console.log("Categorylist",val)}
                                                return (
                                                    <MenuItem
                                                        value={
                                                            val.subCategoryName
                                                        }
                                                    >
                                                        {val.subCategoryName}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div class="col-md-3">
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    className="mt-3"
                                >
                                    <InputLabel
                                        id="demo-simple-select-standard-label"
                                        style={{ color: 'black' }}
                                    >
                                        Brand Name
                                    </InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        //  value={age}
                                        //  onChange={handleChange}
                                        label="Category"
                                        color="success"
                                        value={Invendory.brandName}
                                        //  onChange={(e)=>setInventory({...Invendory,brandName:e.target.value})}
                                        onChange={(e) =>
                                            handlechangeproductVarientProduct(e)
                                        }
                                    >
                                        {/* <MenuItem value="">
                           <em>None</em>
                         </MenuItem>
                         <MenuItem value={'test'}>test</MenuItem>
               <MenuItem value={'Krishivan Input'}>Krishivan Input</MenuItem> */}
                                        {brandlist &&
                                            brandlist.map((val, ind) => {
                                                // {console.log("Categorylist",val)}
                                                return (
                                                    <MenuItem
                                                        value={val.brandName}
                                                    >
                                                        {val.brandName}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div class="col-md-3">
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    className="mt-3"
                                >
                                    <InputLabel
                                        id="demo-simple-select-standard-label"
                                        style={{ color: 'black' }}
                                    >
                                        Machine model
                                    </InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        //  value={age}
                                        //  onChange={handleChange}
                                        label="Machine model"
                                        color="success"
                                        value={Invendory.bro}
                                        onChange={(e) => handleVarientchange(e)}
                                        //  onChange={(e)=>setInventory({...Invendory,productName:e.target.value})}
                                    >
                                        {/* <MenuItem value="">
                           <em>None</em>
                         </MenuItem>
                         <MenuItem value={'test'}>test</MenuItem>
               <MenuItem value={'Krishivan Input'}>Krishivan Input</MenuItem> */}
                                        {Productlist &&
                                            Productlist.map((val, ind) => {
                                                // {console.log("Categorylist",val)}
                                                return (
                                                    <MenuItem value={val}>
                                                        {val.productName}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                            </div>

                            {/* <div class="col-md-3" style={{marginTop:'1.5%'}}>
                     <CssTextField 
                        required
                        fullWidth
                        id="quantity" 
                        placeholder="Quantity" 
                        variant="standard" 
                        value={Invendory.quantity}
                        onChange={(e)=>setInventory({...Invendory,quantity:e.target.value})}
                     />
                    </div> */}
                            {/* <div class="col-md-3">
                     <FormControl variant="standard" fullWidth className="mt-3">
                       <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>Quantity</InputLabel>
                       <Select
                         required
                         labelId="demo-simple-select-standard-label"
                         id="demo-simple-select-standard"
                        //  value={age}
                        //  onChange={handleChange}
                         label="Quantity"
                         color="success"
                         value={Invendory.variantName}
                         onChange={(e)=>handlevarientlistname(e)}
                        //  onChange={(e)=>setInventory({...Invendory,variantName:e.target.value})}
                       >
                        
             {
                          Varientlist&&Varientlist.map((val,ind)=>{
                           // {console.log("Categorylist",val)}
                             return(
                              <MenuItem value={val.insideQuantity}>{val.insideQuantity}</MenuItem>
                             )
                          })
                       }
                       </Select>
                     </FormControl>
                    </div> */}
                            {/* <div class="col-md-3">
                     <FormControl variant="standard" fullWidth className="mt-3">
                       <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>Unit</InputLabel>
                       <Select
                         required
                         labelId="demo-simple-select-standard-label"
                         id="demo-simple-select-standard"
                        
                         label="Unit"
                         color="success"
                         value={Invendory.unit}
                         onChange={(e)=>setInventory({...Invendory,unit:e.target.value})}
                       >
                         <MenuItem value="">
                           <em>None</em>
                         </MenuItem>
                         <MenuItem value={'HR'}>HR</MenuItem>
               <MenuItem value={'ACER'}>ACER</MenuItem>
               <MenuItem value={'TRIP'}>TRIP</MenuItem>
               
                       </Select>
                     </FormControl>
                    </div> */}
                            {/* <div class="col-md-3">
                     <CssTextField 
                        required
                        fullWidth
                        id="maxPrice" 
                        label="Purchase Price" 
                        variant="standard" 
                        value={Invendory.maxPrice}
                        onChange={(e)=>setInventory({...Invendory,maxPrice:e.target.value})}
                     />
                    </div> */}
                            {/* <div class="col-md-3">
                     <CssTextField 
                        required
                        fullWidth
                        id="Price" 
                        label="Sale Price" 
                        variant="standard" 
                        value={Invendory.price}
                        onChange={(e)=>setInventory({...Invendory,price:e.target.value})}
                     />
                    </div> */}

                            {/* <div class="col-md-3">
                     <CssTextField 
                        required
                        fullWidth
                        id="Discount" 
                        label="Discount" 
                        variant="standard" 
                        value={Invendory.discount}
                        onChange={(e)=>setInventory({...Invendory,discount:e.target.value})}
                     />
                    </div> */}
                            {/* <div class="col-md-3">
                        <div className="row">
                            <div className="col-4">
                             <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}} className="mt-3">
                                 Unlimited
                             </InputLabel>
                             <Switch  
                             checked={Invendory.isUnlimited}
                          
                             value="checkedA"
                         
                           onChange={(e)=>setInventory({...Invendory,isUnlimited:e.target.checked})}
                           />
                            </div>
                            {
                               Invendory.isUnlimited ?"":
                               <div className="col-8">
                            <CssTextField 
                                 required
                                 fullWidth
                                 id="stock" 
                                 label="Stock" 
                                 variant="standard" 
                                 value={Invendory.stock}
                                 onChange={(e)=>setInventory({...Invendory,stock:e.target.value})}
                              />
                              </div>
                            }
                            {/* <div className="col-8">
                             <CssTextField 
                                 required
                                 fullWidth
                                 id="stock" 
                                 label="Stock" 
                                 variant="standard" 
                                 value={Invendory.stock}
                                 onChange={(e)=>setInventory({...Invendory,stock:e.target.value})}
                              />
                            </div> */}
                            {/* </div> */}
                            {/* </div> */}
                        </div>
                        <div className="row justify-content-end mb-3">
                            <div className="col-md-1">
                                <button
                                    type="submit"
                                    className="btn btn-danger"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div className="col-md-1">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={() => handleSubmit()}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            {/* <div class="row">
       <div class="col-sm-12">
       <h3 className="mb-3">Machine Products</h3>
          <div class="card card-table">
             <div class="card-body">
                <div class="table-responsive">
                   <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                      <div class="row">
                         <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="DataTables_Table_0_length">
                               <label>
                                  Show 
                                  <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" class="custom-select custom-select-sm form-control form-control-sm">
                                     <option value="10">10</option>
                                     <option value="25">25</option>
                                     <option value="50">50</option>
                                     <option value="100">100</option>
                                  </select>
                                  entries
                               </label>
                            </div>
                         </div>
                         <div class="col-sm-12 col-md-3 position_right">
                            <div class="form-group">
                               <input type="text" class="form-control" placeholder="Search" />
                            </div>                                                
                         </div>
                      </div>
                      <div class="row">
                        <MachineInventoryTable inputs={ProductInput} />
                     </div>
                      <div class="row">
                         <div class="col-sm-12 col-md-5">
                            <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 1 to 10 of 12 entries</div>
                         </div>
                         <div class="col-sm-12 col-md-7">
                            <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                               <ul class="pagination">
                                  <li class="paginate_button page-item previous disabled" id="DataTables_Table_0_previous"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li>
                                  <li class="paginate_button page-item active"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0" class="page-link">1</a></li>
                                  <li class="paginate_button page-item "><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="2" tabindex="0" class="page-link">2</a></li>
                                  <li class="paginate_button page-item next" id="DataTables_Table_0_next"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="3" tabindex="0" class="page-link">Next</a></li>
                               </ul>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div> */}
        </>
    )
}

export default MachineInventory
