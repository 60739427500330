import React from 'react'
import Logo from './logonew.jpg'
// import Logo from "../../../public/images/logonew.jpg"
import Converter from 'number-to-words'

const Invoice = ({ Details, invoiceId, booking, trader, inputData,taxes }) => {
    const [price, setPrice] = React.useState(0)
    const [totalPrice, setTotalPrice] = React.useState(0)
    const [totalDiscount, setTotalDiscount] = React.useState(0)
    React.useEffect(() => {
        console.log('Invoice')
        calculateDiscount()
    }, [])
    var date = new Date(Details.invoiceDate).toString()
    date = new Date(date).toDateString().slice(4, 15)

    var total;

    let bookingfee
    let BookingfeesAmt
    let gstAmount
    let TotalAmountWords
    if(taxes.gstPercentage){
       var sub= Number(Details.subtotal)-Number(Details.total_discount)
        bookingfee=  sub *Number(taxes.PercentageIncGstBookingFee)/100;
            console.log(Details.subtotal,Details.total_discount,taxes.PercentageIncGstBookingFee,bookingfee,'od')
            BookingfeesAmt= bookingfee/(1+Number(taxes.gstPercentage/100))
            gstAmount= bookingfee-BookingfeesAmt
        console.log(BookingfeesAmt,'sdfg')
        // var result = +taxes.PercentageIncGstBookingFee / (1  + Number(taxes.gstPercentage)/100);
        // feeAmount=result
         total= Number(   Details.subtotal-Details.total_discount)-Number(bookingfee);
         console.log(total)
    }
if(taxes.type==="flat"){
    bookingfee=taxes.incGstBookingFee
      total= Number(   Details.subtotal-Details.total_discount)-Number(taxes.incGstBookingFee);
        console.log(total,'sdtoal')
}
let discount

var priced =
Details &&
Details.cart &&
Details.cart.reduce((int, a) => int + +a.price * +a.total_no, 0)
discount =
Details &&
Details.cart &&
Details.cart.reduce((int, a) => int + +a.discount * +a.total_no, 0)
    const calculateDiscount = () => {
        console.log('calculateDiscount')
      
        // return discount
        console.log('price', priced)
        console.log('discount', discount)
        setPrice(priced)
        setTotalDiscount(discount)
        setTotalPrice(price - discount)
        return price - discount
    }
    const sum = Details.cart.reduce((accumulator, currentObject) => {
        return accumulator + currentObject.bookingCommision.totalCommisionAmt;
      }, 0);
      console.log(sum,bookingfee,taxes.incGstBookingFee,'sdf')
     const totolCommisionBS=sum+Number(bookingfee)|| +taxes.incGstBookingFee
     TotalAmountWords= price-discount-totolCommisionBS
     console.log(totolCommisionBS,priced-discount,'totalcommision')
    return (
        <>
        <div class="card">
            <div class="card-body p_7">
                <div id="invoice-top">
                    <div class="logo">
                        <img
                            style={{ width: '225px', height: '100%' }}
                            src={Logo}
                            alt="Logo"
                        />
                    </div>
                    <div class="title">
                        <h5>
                            Booking Id #
                            <span class="invoiceVal invoice_num">
                                {localStorage.getItem('Bookinginputid')}
                            </span>
                        </h5>

                        <h5>
                            Invoice #
                            <span class="invoiceVal invoice_num">
                                {invoiceId}
                            </span>
                        </h5>
                        <p>
                            Invoice Date: <span id="invoice_date"> {date}</span>
                            <br />
                        </p>
                    </div>
                </div>
                <div className="invoiceAdd">
                    <div class="col-left">
                        {/* <div class="clientlogo"><img src="https://cdn3.iconfinder.com/data/icons/daily-sales/512/Sale-card-address-512.png" alt="Sup" /></div> */}
                        <div class="clientinfo" style={{ fontSize: '12px' }}>
                            <h5 id="supplier">Booking From:</h5>

                            <p>Name:{booking.first_name+" "+booking.last_name}</p>

                            <p>ID: {booking.id}</p>
                            <p>Mobile: {booking.mobile_number}</p>
                            <p style={{ width: '330px' }}>
                                Adderss: {booking.full_address}
                            </p>
                        </div>
                    </div>
                    <div class="col-right">
                        {/* <div class="clientlogo"><img src="https://cdn3.iconfinder.com/data/icons/daily-sales/512/Sale-card-address-512.png" alt="Sup" /></div> */}
                        <div class="clientinfo" style={{ fontSize: '12px' }}>
                            <h5 id="supplier">Booking To:</h5>
                            <p>{inputData?.role}</p>
                            <p>Name: {inputData?.first_name}</p>
                            <p>ID: {inputData?.id}</p>
                            <p>Mobile : {inputData?.mobile_number}</p>
                            {/* <p>Date:  05-07-2022</p> */}
                        </div>
                    </div>
                </div>
                <form>
                    <div class="row form-group">
                        <div class="col-md-12">
                            <div class="card-body card-body_1">
                                <h5 class="card-title d-flex justify-content-between store_edit">
                                    <span></span>
                                </h5>
                                <div class="table-wrap">
                                    <div class="table-responsive">
                                        <table class="table display setting-td mb-30 store_table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Product</th>
                                                    <th>Quantity</th>
                                                    <th>Unit</th>
                                                    <th>No</th>
                                                    <th>Price</th>
                                                    <th>discount</th>

                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Details &&
                                                    Details.cart &&
                                                    Details.cart.map(
                                                        (val, ind) => {
                                                            return (
                                                                <tr
                                                                    style={{
                                                                        borderBottom:
                                                                            '1px solid #ededed',
                                                                    }}
                                                                >
                                                                    {/* <td><div class="staffAvatar-root1">{Details && Details.farmerId&& Details.farmerId.firstName &&Details.farmerId.firstName.length>0?Details.farmerId.firstName.slice(0, 1):""}</div> </td> */}
                                                                    <td>
                                                                        {' '}
                                                                        <img
                                                                            src={
                                                                                val.image
                                                                            }
                                                                            style={{
                                                                                width: '100px',
                                                                                height: '100%',
                                                                            }}
                                                                            alt=""
                                                                        />{' '}
                                                                    </td>

                                                                    <td>
                                                                        {
                                                                            val.product_name 
                                                                        }({val?.sizeColor})
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            val.qty
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            val.unit
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            val.total_no
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        ₹{' '}
                                                                        {
                                                                            val.price
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            val.discount
                                                                        }
                                                                    </td>

                                                                    <td>
                                                                        ₹{' '}
                                                                        {val.price *
                                                                            val.total_no}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    )}
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    
                                                    <td></td>
                                                    <td>Discount</td>
                                                    <td>   {Details &&
                                                        Details.total_discount}</td>
                                                 
                                                    
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>Sub Total</td>
                                                    <td> {Details &&
                                                        Details.subtotal- Details.total_discount}{' '}</td>
                                                   
                                                </tr>
                                                <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>Delivery Fee</td>
                                                        <td>
                                                            ₹
                                                            {Details &&
                                                                Details.delivery_fees}
                                                        </td>
                                                    </tr>{' '}

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>Total</b>
                                                    </td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>
                                                            ₹{' '}
                                                            {Details &&
                                                                Details.userAmount}
                                                        </b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div>
                                            <hr />
                                            <h6
                                                style={{ padding: '13px 0' }}
                                                class="c22"
                                            >
                                                <span class="c12">
                                                    Amount In Words:{' '}
                                                    {Converter.toWords(
                                                        Details.userAmount
                                                    ).toUpperCase()}{' '}
                                                    RUPEES{' '}
                                                </span>
                                            </h6>
                                            <p class="c25">
                                                <span class="c12">
                                                    Terms of trade credit
                                                    provided by KRISHIVAN
                                                    TECHNOLOGIES PVT LTD{' '}
                                                </span>
                                            </p>
                                            <p class="c6">
                                                <span class="c1">
                                            Goods once sold will not be taken back or exchanged. 
{' '}
                                                </span>
                                            </p>
                                            <p class="c6">
                                                <span class="c1">
                                                No credit is given on this invoice.
{' '}
                                                </span>
                                            </p>
                                                       <p class="c23">
                                                <span class="c1">
                                                    This invoice doesn&#39;t
                                                    attribute to the receipt of
                                                    payment{' '}
                                                </span>
                                            </p>
                                            <p class="c23">
                                                <span class="c1">
                                                We are not responsible for any damages or leakages in transit.
                                                </span>
                                            </p>
                                            <p class="c13">
                                                <span class="c1">
                                                    No change in price is
                                                    allowed during delivery.
                                                </span>
                                            </p>
                                            <p class="c8">
                                                <span class="c1">
                                                    Declaration: We declare that
                                                    the invoice shows the actual
                                                    price of the goods described
                                                    and that the particulars are
                                                    true and correct{' '}
                                                </span>
                                            </p>
                                            <p class="c8">
                                                <span class="c1">
                                                Any discrepancy in this invoice should be pointed within 3 days from the date of this invoice, failing which the invoiced amount shall be deemed to have been accepted as correct.
                                                </span>
                                            </p>
                                            <hr />
                                            <p class="c18">
                                                <span class="c1">
                                                    
This is a computer generated invoice and doesn't require a signature.
No tax is payable on reverse charge basis
                                                </span>
                                            </p>
                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <br/><br/><br/>
        <div className='transparent'></div>
        <div class="card">
            <div class="card-body p_7">
                <div id="invoice-top">
                    <div class="logo">
                        <img
                            style={{ width: '225px', height: '100%' }}
                            src={Logo}
                            alt="Logo"
                        />
                    </div>
                    <div class="title">
                        <h5>
                            Booking Id #
                            <span class="invoiceVal invoice_num">
                                {localStorage.getItem('Bookinginputid')}
                            </span>
                        </h5>

                        <h5>
                            Invoice #
                            <span class="invoiceVal invoice_num">
                                {invoiceId}
                            </span>
                        </h5>
                        <p>
                            Invoice Date: <span id="invoice_date"> {date}</span>
                            <br />
                        </p>
                    </div>
                </div>
               
                <form>
                    <div class="row form-group">
                        <div class="col-md-12">
                            <div class="card-body card-body_1">
                                <h5 class="card-title d-flex justify-content-between store_edit">
                                    <span></span>
                                </h5>
                                <div class="table-wrap">
                                    <div class="table-responsive">
                                        <table class="table display setting-td mb-30 store_table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Product</th>
                                                    <th>Quantity</th>
                                                    <th>Unit</th>
                                                    <th>No</th>
                                                    <th>Price</th>
                                                    <th>discount</th>

                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Details &&
                                                    Details.cart &&
                                                    Details.cart.map(
                                                        (val, ind) => {
                                                            return (
                                                                <tr
                                                                    style={{
                                                                        borderBottom:
                                                                            '1px solid #ededed',
                                                                    }}
                                                                >
                                                                    {/* <td><div class="staffAvatar-root1">{Details && Details.farmerId&& Details.farmerId.firstName &&Details.farmerId.firstName.length>0?Details.farmerId.firstName.slice(0, 1):""}</div> </td> */}
                                                                    <td>
                                                                        {' '}
                                                                        <img
                                                                            src={
                                                                                val.image
                                                                            }
                                                                            style={{
                                                                                width: '100px',
                                                                                height: '100%',
                                                                            }}
                                                                            alt=""
                                                                        />{' '}
                                                                    </td>

                                                                    <td>
                                                                        {
                                                                            val.product_name
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            val.qty
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            val.unit
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            val.total_no
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        ₹{' '}
                                                                        {
                                                                            val.price
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            val.discount
                                                                        }
                                                                    </td>

                                                                    <td>
                                                                        ₹{' '}
                                                                        {val.price *
                                                                            val.total_no}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    )}
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>Discount</td>
                                                    <td>  {Details &&
                                                        Details.total_discount}</td>
                                                  
                                                    
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>Sub Total</td>
                                                    <td>  {Details &&
                                                        Details.subtotal-Details.total_discount}{' '}</td>
                                                  
                                                </tr>
                                              

                                                {/* <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>Total</b>
                                                    </td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>
                                                            ₹{' '}
                                                            {Details &&
                                                                Details.total_price}
                                                        </b>
                                                    </td>
                                                </tr> */}
                                                {taxes.gstAmount?
                                                <>
                                              <tr>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td>Booking Fee</td>
                                             

                                              <td>
                                                  ₹
                                                  {
                                                    
                                                      taxes
                                                          .bookingFee
                                                  }{' '}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td>GST Amt.</td>
                                             

                                              <td>
                                                  ₹
                                                  {
                                                      taxes
                                                      .gstAmount
                                                  }{' '}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td>Booking Fee Inc. GST</td>
                                             

                                              <td>
                                                  ₹
                                                  {
                                                     taxes
                                                     .incGstBookingFee
                                                  }{' '}
                                              </td>
                                          </tr>
                                          </>
                                          :  <>
                                        
                                     
                                      <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>Booking Fee Inc. GST</td>
 

                                          <td>
                                              ₹
                                              {bookingfee}
                                              {/* {
                                                Math.round((bookingfee+ Number.EPSILON) * 100) / 100
                                              }{' '} */}
                                          </td>
                                      </tr>
                                      <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>Booking fees Amt</td>
                                         

                                          <td>
                                              ₹
                                              {  Math.round((  BookingfeesAmt+ Number.EPSILON) * 100)/100 }
                                              {/* {BookingfeesAmt} */}
                                              {/* {
                                                 
                                                    Math.round((   Number(taxes.PercentageIncGstBookingFee)-Number(feeAmount)+ Number.EPSILON) * 100) / 100
                                                
                                             
                                              }{' '} */}
                                          </td>
                                      </tr>
                                      
                                      <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>GST Amount</td>
                                         

                                          <td>
                                              ₹
                                              {  Math.round((  gstAmount) * 100)/100 }
                                              {/* {BookingfeesAmt} */}
                                              {/* {
                                                 
                                                    Math.round((   Number(taxes.PercentageIncGstBookingFee)-Number(feeAmount)+ Number.EPSILON) * 100) / 100
                                                
                                             
                                              }{' '} */}
                                          </td>
                                      </tr>
                                      </>
                                            } 
                                               <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>Total</b>
                                                    </td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>
                                                            ₹
                                                          {Details.userAmount}
                                                          
                                                        </b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div>
                                            <hr />
                                            <h6
                                                style={{ padding: '13px 0' }}
                                                class="c22"
                                            >
                                                <span class="c12">
                                                    Amount In Words:{' '}
                                                    {Converter.toWords(
                                                      Details.userAmount
                                                    ).toUpperCase()}{' '}
                                                    RUPEES{' '}
                                                </span>
                                            </h6>
                                            <p class="c25">
                                                <span class="c12">
                                                    Terms of trade credit
                                                    provided by KRISHIVAN
                                                    TECHNOLOGIES PVT LTD{' '}
                                                </span>
                                            </p>
                                            <p class="c6">
                                                <span class="c1">
                                                    Bill amount to be paid in up
                                                    to 3 days{' '}
                                                </span>
                                            </p>
                                            <p class="c23">
                                                <span class="c1">
                                                    This invoice doesn&#39;t
                                                    attribute to the receipt of
                                                    payment{' '}
                                                </span>
                                            </p>
                                            <p class="c13">
                                                <span class="c1">
                                                    No change in price is
                                                    allowed during delivery and
                                                    only on-spot returns are
                                                    accepted
                                                </span>
                                            </p>
                                            <p class="c8">
                                                <span class="c1">
                                                    Declaration: We declare that
                                                    the invoice shows the actual
                                                    price of the goods described
                                                    and that the particulars are
                                                    true and correct{' '}
                                                </span>
                                            </p>
                                            <hr />
                                            <p class="c18">
                                                <span class="c1">
                                                    This is a computer generated
                                                    invoice and doesn&#39;t
                                                    require a signature.{' '}
                                                </span>
                                            </p>
                                            <p class="c13">
                                                <span class="c1">
                                                    No tax is payable on reverse
                                                    charge basis{' '}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="card">
            <div class="card-body p_7">
                <div id="invoice-top">
                    <div class="logo">
                        <img
                            style={{ width: '225px', height: '100%' }}
                            src={Logo}
                            alt="Logo"
                        />
                    </div>
                    <div class="title">
                        <h5>
                            Booking Id #
                            <span class="invoiceVal invoice_num">
                                {localStorage.getItem('Bookinginputid')}
                            </span>
                        </h5>

                        <h5>
                            Invoice #
                            <span class="invoiceVal invoice_num">
                                {invoiceId}
                            </span>
                        </h5>
                        <p>
                            Invoice Date: <span id="invoice_date"> {date}</span>
                            <br />
                        </p>
                    </div>
                </div>
               
                <form>
                    <div class="row form-group">
                        <div class="col-md-12">
                            <div class="card-body card-body_1">
                                <h5 class="card-title d-flex justify-content-between store_edit">
                                    <span></span>
                                </h5>
                                <div class="table-wrap">
                                    <div class="table-responsive">
                                        <table class="table display setting-td mb-30 store_table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Product</th>
                                                    <th>Quantity</th>
                                                    <th>Unit</th>
                                                    <th>No</th>
                                                    <th>Sale Price</th>
                                                    <th>discount</th>
                                                    <th>Sale Amt After Disc</th>
                                                    <th>PR Price</th>
                                                    <th>PR GST Amt</th>
                                                    <th>Total Sale Amt After Disc</th>
                                                    <th>Total PR Price </th>
                                                    <th>Total PR GST Amount </th>
                                                    <th>Total PR Amt Inc GST  </th>

                                                    <th>Commision Type</th>
                                                     <th>Inc GST PRD Commision</th>
                                                     <th>PRD Commision</th>
                                                     <th>PRD Commision GST </th>
                                                     <th>Total Commision Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Details &&
                                                    Details.cart &&
                                                    Details.cart.map(
                                                        (val, ind) => {
                                                            return (
                                                                <tr
                                                                    style={{
                                                                        borderBottom:
                                                                            '1px solid #ededed',
                                                                    }}
                                                                >
                                                                    {/* <td><div class="staffAvatar-root1">{Details && Details.farmerId&& Details.farmerId.firstName &&Details.farmerId.firstName.length>0?Details.farmerId.firstName.slice(0, 1):""}</div> </td> */}
                                                                    <td>
                                                                        {' '}
                                                                        <img
                                                                            src={
                                                                                val.image
                                                                            }
                                                                            style={{
                                                                                width: '100px',
                                                                                height: '100%',
                                                                            }}
                                                                            alt=""
                                                                        />{' '}
                                                                    </td>

                                                                    <td>
                                                                        {
                                                                            val.product_name
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            val.qty
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            val.unit
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            val.total_no
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        ₹{' '}
                                                                        {
                                                                            val.price
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            val.discount
                                                                        }
                                                                    </td>
                                                                    <td>{val.bookingCommision.SaleAmountAfterDiscount.toFixed(2)} </td>
                                                                    <td>{val.bookingCommision.ProductPriceIncGST.toFixed(2)} </td>
                                                                    <td>{val.bookingCommision.ProductGSTAmount.toFixed(2)} </td>
                                                                    <td>{val.bookingCommision.totalSaleAmountAfterDiscount.toFixed(2)} </td>
                                                                    <td>{val.bookingCommision.totalProductPrice.toFixed(2)} </td>
                                                                    <td>{val.bookingCommision.totalProductGSTPrice.toFixed(2)} </td>
                                                                    <td>{val.bookingCommision.totalProductIncGSTAmount.toFixed(2)} </td>

                                                                    <td>{val.bookingCommision.type} </td>
                                                    <td>{val.bookingCommision.incGSTCommision}</td>
                                                    <td>{Number(val.bookingCommision.productCommision).toFixed(2)}</td>
                                                    <td>{Number(val.bookingCommision.productCommisionGST).toFixed(2)}</td>
                                                    <td>{Number(val.bookingCommision.totalCommisionAmt)}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    )}
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                     <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>Discount</td>
                                                    <td>  {Details &&
                                                        Details.total_discount}</td>
                                                  
                                                    
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                     <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>Sub Total</td>
                                                    <td>  {Details &&
                                                        Details.subtotal-Details.total_discount}{' '}</td>
                                                  
                                                </tr>
                                              

                                                {/* <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>Total</b>
                                                    </td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>
                                                            ₹{' '}
                                                            {Details &&
                                                                Details.total_price}
                                                        </b>
                                                    </td>
                                                </tr> */}
                                                {taxes.gstAmount?
                                                <>
                                              <tr>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                     <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                              <td>Booking Fee</td>
                                             

                                              <td>
                                                  ₹
                                                  {
                                                    
                                                      taxes
                                                          .bookingFee
                                                  }{' '}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                     <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                              <td>GST Amt.</td>
                                             

                                              <td>
                                                  ₹
                                                  {
                                                      taxes
                                                      .gstAmount
                                                  }{' '}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                     <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                              <td>Booking Fee Inc. GST</td>
                                             

                                              <td>
                                                  ₹
                                                  {
                                                     taxes
                                                     .incGstBookingFee
                                                  }{' '}
                                              </td>
                                          </tr>
                                          <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                     <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                          <td>Total Commision+ BS Amount</td>
                                         

                                          <td>
                                              ₹
                                              {sum+Number(taxes.incGstBookingFee)}
                                          </td>
                                      </tr>
                                          </>
                                          :  <>
                                        
                                     
                                      <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                     <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                          <td>Booking Fee Inc. GST</td>
 

                                          <td>
                                              ₹
                                              {bookingfee}
                                              {/* {
                                                Math.round((bookingfee+ Number.EPSILON) * 100) / 100
                                              }{' '} */}
                                          </td>
                                      </tr>
                                      <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                     <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                          <td>Booking fees Amt</td>
                                         

                                          <td>
                                              ₹
                                              {  Math.round((  BookingfeesAmt+ Number.EPSILON) * 100)/100 }
                                              {/* {BookingfeesAmt} */}
                                              {/* {
                                                 
                                                    Math.round((   Number(taxes.PercentageIncGstBookingFee)-Number(feeAmount)+ Number.EPSILON) * 100) / 100
                                                
                                             
                                              }{' '} */}
                                          </td>
                                      </tr>
                                      
                                      <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                           <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                          <td>GST Amount</td>
                                         

                                          <td>
                                              ₹
                                              {  Math.round((  gstAmount) * 100)/100 }
                                              {/* {BookingfeesAmt} */}
                                              {/* {
                                                 
                                                    Math.round((   Number(taxes.PercentageIncGstBookingFee)-Number(feeAmount)+ Number.EPSILON) * 100) / 100
                                                
                                             
                                              }{' '} */}
                                          </td>
                                      </tr>
                                      <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>

                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                     <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                          <td>Total Commision+ BS Amount</td>
                                         

                                          <td>
                                              ₹
                                              {sum+Number(bookingfee)}
                                          </td>
                                      </tr>
                                      </>
                                            } 
                                               <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                     <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>User Amount</b>
                                                    </td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>
                                                            ₹ {Details.userAmount.toFixed(2)}
                                                          {/* {(Details.subtotal-Details.total_discount-(Number(bookingfee))+sum)} */}
                                                          
                                                        </b>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                     <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>TCS AMT</b>
                                                    </td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>
                                                            ₹ { Details.tcsAmount.toFixed(2)}
                                                          {/* {(Details.subtotal-Details.total_discount-(Number(bookingfee))+sum)} */}
                                                          
                                                        </b>
                                                    </td>
                                                    
                                                </tr>
                                                <tr>
                                          
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                          <td>Total CM AMT Exc GST</td>
                                         

                                          <td>
                                              ₹ {Details.totalCmAmtExcGst?.toFixed(2)}
                                          </td>
                                      </tr>
                                      <tr>
                                          
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                          <td>Total CM GST Amt</td>
                                         

                                          <td>
                                              ₹ {Details.totalCmGstAmt?.toFixed(2)}
                                          </td>
                                      </tr>
                                      <tr>
                                          
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                          <td>Total CM+BS AMT Exc GST</td>
                                         

                                          <td>
                                              ₹ {Details.totalCmBsAmtExcGst?.toFixed(2)}
                                          </td>
                                      </tr>
                                      <tr>
                                          
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                          <td>Total CM+BS AMT GST AMT</td>
                                         

                                          <td>
                                              ₹ {Details.totalCmBSAmtGstAmt?.toFixed(2)}
                                          </td>
                                      </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                     <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>Firm Amount</b>
                                                    </td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>
                                                            ₹ { Details.firmAmount.toFixed(2)}
                                                          {/* {(Details.subtotal-Details.total_discount-(Number(bookingfee))+sum)} */}
                                                          
                                                        </b>
                                                    </td>
                                                    
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                     <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>Krishi Amount</b>
                                                    </td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>
                                                            ₹ { Details.krishiAmount.toFixed(2)}
                                                          {/* {(Details.subtotal-Details.total_discount-(Number(bookingfee))+sum)} */}
                                                          
                                                        </b>
                                                    </td>
                                                    
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div>
                                            <hr />
                                            <h6
                                                style={{ padding: '13px 0' }}
                                                class="c22"
                                            >
                                                <span class="c12">
                                                    Amount In Words:{' '}
                                                    {Converter.toWords(
                                                       Details.userAmount
                                                    ).toUpperCase()}{' '}
                                                    RUPEES{' '}
                                                </span>
                                            </h6>
                                            <p class="c25">
                                                <span class="c12">
                                                    Terms of trade credit
                                                    provided by KRISHIVAN
                                                    TECHNOLOGIES PVT LTD{' '}
                                                </span>
                                            </p>
                                            <p class="c6">
                                                <span class="c1">
                                                    Bill amount to be paid in up
                                                    to 3 days{' '}
                                                </span>
                                            </p>
                                            <p class="c23">
                                                <span class="c1">
                                                    This invoice doesn&#39;t
                                                    attribute to the receipt of
                                                    payment{' '}
                                                </span>
                                            </p>
                                            <p class="c13">
                                                <span class="c1">
                                                    No change in price is
                                                    allowed during delivery and
                                                    only on-spot returns are
                                                    accepted
                                                </span>
                                            </p>
                                            <p class="c8">
                                                <span class="c1">
                                                    Declaration: We declare that
                                                    the invoice shows the actual
                                                    price of the goods described
                                                    and that the particulars are
                                                    true and correct{' '}
                                                </span>
                                            </p>
                                            <hr />
                                            <p class="c18">
                                                <span class="c1">
                                                    This is a computer generated
                                                    invoice and doesn&#39;t
                                                    require a signature.{' '}
                                                </span>
                                            </p>
                                            <p class="c13">
                                                <span class="c1">
                                                    No tax is payable on reverse
                                                    charge basis{' '}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </>
    )
}

export default Invoice
