import React, { useState, useEffect } from 'react'
import { CssTextField } from '../../Styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import {
    thumbsContainerState,
    thumb,
    img,
    thumbInner,
    driverDP,
} from '../../Styles'
import axios from 'axios'
import Swal from 'sweetalert2'
import Select from 'react-select'
import { storage } from '../../../firebase/index'
import { ref, getDownloadURL } from 'firebase/storage'
import $ from 'jquery'
import moment from 'moment'

function MachineBooking(Props) {
    const catalogue = 'machine'
    console.log('props', Props)
    const machineclear = {
        MicroEnterpreneurId: '',
        farmerId: '',
        machineType: '',
        machineModel: '',
        machineTypeImage: '',
        cropImage: '',
        crop: '',
        fromdate: '',
        toDate: '',
        fromTime: '',
        toTime: '',
        area: '',
        unit: '',
        billingType: '',
    }

    const [machine, setmachine] = useState({
        MicroEnterpreneurId: '',
        farmerId: '',
        machineType: '',
        machineModel: '',
        machineTypeImage: '',
        cropImage: '',
        crop: '',
        fromdate: '',
        toDate: '',
        fromTime: '',
        toTime: '',
        area: '',
        unit: '',
        bookedBy: 'MI',
        createdBy: 'Admin',
        billingType: '',
    })
    const [typeofmachine, settypeofmachine] = useState([])
    const [machinemodel, setmachinemodel] = useState([])
    const [clear, setClear] = useState(false)
    const [crop, setcrop] = useState([])
    const [machineDate, setMachineDate] = useState('1900-01-02T12:42:13.000000')
    useEffect(() => {
        // getState();
        getMachineBooking()
        getCrop()
        // getMachineBookingModel();
        gettypeofmachine()
        getmachinemodel()
    }, [])
    const getMachineBooking = async () => {
        const customer = 'book'
        const skip = 0
        const limit = 10
        const type = 'vehicle'
        const date = '2022-02-09'
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product/${customer}/${skip}/${limit}/${type}/${date}`,
                config
            )
            .then((res) => {
                console.log('machine booking get', res)
            })
    }

    const gettypeofmachine = async (val) => {
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-machines`,
                config
            )
            .then((res) => {
                console.log('types machine:', res.data.machines)
                settypeofmachine(res.data.machines)
            })
    }
    const typeofmachineoption =
        typeofmachine &&
        typeofmachine.map((item, ind) => ({
            value: item.productName,
            label: item.productName,
            image: item.productImage,
            model: item.subCategoryName,
            productId:item._id,
        }))
    const cleartypemachine = [
        {
            value: '',
            label: '',
            image: '',
        },
    ]

    const getmachinemodel = async (val) => {
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-machines`,
                config
            )
            .then((res) => {
                console.log('model machine:', res.data.machines)
                setmachinemodel(res.data.machines)
            })
    }
    const machinemodeloption =
        machinemodel &&
        machinemodel.map((item, ind) => ({
            value: item.subCategoryName,
            label: item.subCategoryName,
        }))

    const getCrop = async (val) => {
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-crops`,
                config
            )
            .then((res) => {
                console.log('crops: ', res.data.crops)
                setcrop(res.data.crops)
            })
    }
    const cropoption =
        crop &&
        crop.map((item, ind) => ({
            value: item.productName,
            label: item.productName,
            image: item.productImage,
        }))

    const handlebook = async () => {
        console.log('machine', machine)
        // // setmachine(machineclear)
        // setClear(true)
        try {
            var data = {
                user_id: localStorage.getItem('FormerId'),
                createdById: localStorage.getItem('microId')||"",
                created_at: Date.now(),
                mobile_number: '9506434531',
                productId:machine.productId,

                machine: {
                    image: machine.machineTypeImage,
                    type_of_machine: machine.machineType,
                    machine_model: machine.machineModel,
                },
                crop: {
                    image: machine.cropImage,
                    name: machine.crop,
                },
                area: {
                    land_size: machine.area,
                    unit: machine.unit,
                },
                status: 'pending',
                service_time: {
                    date: moment(machine.fromdate).format("yyyy-MM-DD HH:mm:ss.SSS"),
                    time: moment(machine.fromdate).format("yyyy-MM-DD HH:mm:ss.SSS"),
                },
                payment: {
                    indicate_order_value: machine.incentiveOrderValue,
                    billing_type: machine.billingType,
                },
            }
            let machineVal = {
                ...machine,
                farmerId: Props && Props.farmer,
                MicroEnterpreneurId: Props && Props.MicroEnt,
            }

            var token = localStorage.getItem('token')
            var config = {
                headers: { Authorization: `Bearer ${token}` },
            }
            axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/machine`,
                    data,
                    config
                )
                .then((res) => {
                    setmachine([])
                    Swal.fire({
                        icon: 'success',
                        iconColor: '#28a745',
                        title: data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                })
                .catch((err) => {
                    setmachine([])
                    Swal.fire({
                        icon: 'warning',
                        iconColor: '#dc3545',
                        title: 'error occured!',
                        showConfirmButton: false,
                        timer: 2000,
                    })
                })
        } catch (err) {
            setmachine(machineclear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const CropOptions = [
        {
            label: 'Acre',
            value: 'Acre',
        },
        {
            label: 'Guntha',
            value: 'Guntha',
        },
        {
            label: 'Kantha',
            value: 'Kantha',
        },
        {
            label: 'Hectare',
            value: 'Hectare',
        },
    ]
    const BillingTypevalue = [
        {
            label: 'Cash',
            value: 'Cash',
        },
        {
            label: 'Online',
            value: 'Online',
        },
    ]

    const handleType = (e) => {
        setmachine({
            ...machine,
            machineType: e.value,
            machineTypeImage: e.image,
            machineModel: e.model,
            productId:e.productId
        })
        console.log('image path: ', e.image)
        const starsRef = ref(storage, e.image)
        getDownloadURL(starsRef)
            .then((url) => {
                // Insert url into an <img> tag to "download"
                console.log(url)
                // var test = url;
                // document.getElementById("typeImage").src = test;
                $('#typeImage').attr('src', url)
                console.log(document.getElementById('typeImage'))
            })
            .catch((error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/object-not-found':
                        console.log("File doesn't exist")
                        break
                    case 'storage/unauthorized':
                        console.log(
                            "User doesn't have permission to access the object"
                        )
                        // User doesn't have permission to access the object
                        break
                    case 'storage/canceled':
                        console.log('User canceled the upload')
                        // User canceled the upload
                        break

                    // ...

                    case 'storage/unknown':
                        console.log(
                            'Unknown error occurred, inspect the server response'
                        )
                        // Unknown error occurred, inspect the server response
                        break
                }
            })
    }

    const setDateInMachine = (machineDate) => {
        if (machineDate == null || machineDate == '') return
        const d =
            machineDate.substring(0, 10) +
            ' ' +
            machineDate.substring(11, 16) +
            ':00:000000'
        setmachine({
            ...machine,
            fromdate: d,
        })
        setMachineDate(machineDate)
        console.log(machine)
    }

    return (
        <>
            <div>
                <div class="card-header">
                    <h5 class="card-title">Machine Booking Info</h5>
                </div>
                <div className="mt-3">
                    <div class="row">
                        <div class="col-md-3">
                            <FormControl
                                variant="standard"
                                fullWidth
                                className="mt-3"
                            >
                                <Select
                                    style={{ width: '270px', height: '40px' }}
                                    //  onChange={(e)=>handlecategory(e)}
                                    onChange={(e) => handleType(e)}
                                    placeholder={'Type Of Machine'}
                                    //  onChange={(e)=>setInputBooking({...inputBooking,category:e.target.value})}
                                    options={typeofmachineoption}
                                />
                            </FormControl>
                        </div>

                        <div class="col-md-3">
                            <section className="container" style={driverDP}>
                                <aside style={thumbsContainerState}>
                                    <div style={thumb}>
                                        <div style={thumbInner}>
                                            <img
                                                style={img}
                                                id="typeImage"
                                                alt="Select Typ of Machine"
                                            />
                                        </div>
                                    </div>
                                </aside>
                            </section>
                        </div>

                        <div class="col-md-3">
                            <FormControl
                                variant="standard"
                                fullWidth
                                className="mt-3"
                            >
                                <Select
                                    style={{ width: '270px', height: '40px' }}
                                    //  onChange={(e)=>handlecategory(e)}
                                    onChange={(e) =>
                                        setmachine({
                                            ...machine,
                                            crop: e.value,
                                            cropImage: e.image,
                                        })
                                    }
                                    placeholder={'Select the Crop'}
                                    //  onChange={(e)=>setInputBooking({...inputBooking,category:e.target.value})}
                                    options={cropoption}
                                />
                            </FormControl>
                        </div>

                        <div className="row mt-4 mb-4">
                            <h5>Date & Time </h5>

                            <div class="col-md-3 mt-2">
                                <InputLabel
                                    id="demo-simple-select-standard-label"
                                    style={{ color: 'black' }}
                                >
                                    Date & Time
                                </InputLabel>
                                <CssTextField
                                    value={machineDate}
                                    onChange={(e) =>
                                        setDateInMachine(e.target.value)
                                    }
                                    required
                                    type="datetime-local"
                                    className="mt-2"
                                    defaultValue="2015-01-02T11:42:13.510"
                                    fullWidth
                                    id="MBfrom"
                                    label=""
                                    variant="standard"
                                />
                            </div>
                            <div class="col-md-3 mt-4">
                                <CssTextField
                                    fullWidth
                                    className="mt-2"
                                    id="time"
                                    type="text"
                                    value={machine.incentiveOrderValue}
                                    onChange={(e) =>
                                        setmachine({
                                            ...machine,
                                            incentiveOrderValue: e.target.value,
                                        })
                                    }
                                    label="Indicative order value"
                                    variant="standard"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <h5>Area</h5>
                        <div class="col-md-3 mt-4">
                            <CssTextField
                                fullWidth
                                className="mt-0"
                                id="time"
                                type="number"
                                value={machine.area}
                                onChange={(e) =>
                                    setmachine({
                                        ...machine,
                                        area: e.target.value,
                                    })
                                }
                                label="Land size"
                                variant="standard"
                            />
                        </div>
                        <div class="col-md-4" style={{ marginTop: '2%' }}>
                            <FormControl
                                variant="standard"
                                fullWidth
                                className="mt-3"
                            >
                                <Select
                                    style={{ width: '270px', height: '40px' }}
                                    //  onChange={(e)=>handlecategory(e)}
                                    onChange={(e) =>
                                        setmachine({
                                            ...machine,
                                            unit: e.value,
                                        })
                                    }
                                    placeholder={'Select the Unit'}
                                    options={CropOptions}
                                />
                            </FormControl>
                        </div>
                        <div class="col-md-4" style={{ marginTop: '2%' }}>
                            <FormControl
                                variant="standard"
                                fullWidth
                                className="mt-3"
                            >
                                <Select
                                    style={{ width: '270px', height: '40px' }}
                                    //  onChange={(e)=>handlecategory(e)}
                                    onChange={(e) =>
                                        setmachine({
                                            ...machine,
                                            billingType: e.value,
                                        })
                                    }
                                    placeholder={'Select the Billing Type'}
                                    options={BillingTypevalue}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className="col-md-12 text-end mt-2 mb-2 mt-4">
                        <button
                            type="submit"
                            class=" w-10 btn btn-primary"
                            onClick={() => handlebook()}
                        >
                            Book
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MachineBooking
