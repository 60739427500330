import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import { visuallyHidden } from '@mui/utils'
import { Button, FormControl } from 'react-bootstrap'
import { Dialog, DialogContent, DialogTitle, InputLabel, Switch } from '@mui/material'
import ProductUpdateDialog from './ProductUpdateDialog'
import { CssTextField } from '../../../Styles'
import  Select  from 'react-select'
import axios from 'axios'

function createData(
    id,
    title,
    catelogName,
    categoryName,
    subCategoryName,
    brandName,
    productName,
    productImage,
    variantName,
    unit,
    // price,
    // maxPrice,
    purchasePrice,
    salePrice,
    isUnlimited,
    discount,
    stock,
    created_at,
    product_id,
    _id,
) {
    var date = new Date(created_at).toDateString().slice(3)
    const createdDate = date
    console.log('d:', createdDate)
    return {
        id,
        title,
        catelogName,
        categoryName,
        subCategoryName,
        brandName,
        productName,
        productImage,
        variantName,
        unit,
        // price,
        // maxPrice,
        purchasePrice,
        salePrice,
        isUnlimited,
        discount,
        stock,
        createdDate,
        product_id,
        _id
    }
}

const headCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'ID',
    },
    // {
    //   id: 'title',
    //   numeric: false,
    //   disablePadding: true,
    //   label: 'Title',
    // },
    // {
    //   id: 'catelogName',
    //   numeric: true,
    //   disablePadding: false,
    //   label: 'CatelogName',
    // },
    {
        id: 'categoryName',
        numeric: true,
        disablePadding: false,
        label: 'Category',
    },
    {
        id: 'subCategoryName',
        numeric: true,
        disablePadding: false,
        label: 'SubCategory',
    },
    {
        id: 'brandName',
        numeric: true,
        disablePadding: false,
        label: 'Brand',
    },
    {
        id: 'productName',
        numeric: true,
        disablePadding: false,
        label: 'Product',
    },
    {
        id: 'productImage',
        numeric: true,
        disablePadding: false,
        label: 'Product Image',
    },
    {
        id: 'quantity',
        numeric: true,
        disablePadding: false,
        label: 'Quantity',
    },
    {
        id: 'unit',
        numeric: true,
        disablePadding: false,
        label: 'Unit',
    },
    {
        id: 'maxPrice',
        numeric: true,
        disablePadding: false,
        label: 'Purchase Price',
    },
    {
        id: 'price',
        numeric: true,
        disablePadding: false,
        label: 'Sale Price',
    },

    {
        id: 'isUnlimited',
        numeric: true,
        disablePadding: false,
        label: 'IsUnlimited',
    },
    {
        id: 'discount',
        numeric: true,
        disablePadding: false,
        label: 'Discount',
    },
    {
        id: 'stock',
        numeric: true,
        disablePadding: false,
        label: 'Stock',
    },
    {
        id: 'createdAt',
        numeric: true,
        disablePadding: false,
        label: 'CreatedAt',
    },
    {
        id: '',
        numeric: true,
        disablePadding: false,
        label: 'Add Commision',
    },
    {
        id: '',
        numeric: true,
        disablePadding: false,
        label: 'Edit Product',
    },
]

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow style={{ backgroundColor: '#f3f3f3' }}>
                <TableCell padding="checkbox"></TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        // align={headCell.numeric ? 'right' : 'left'}
                        align={headCell.numeric ? 'center' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
}

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props

    return (
        <Toolbar>
            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Orders
            </Typography>

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    )
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
}

export default function ProductVariantTable(props) {
    const inputs = props.inputs
    console.log('InputSuppliers : ', inputs)
    const [percentageFlatfee, setPercentageFlatfee] = React.useState('')
    const [flatGSTpercentage, setFlatGSTpercentage] = React.useState('')
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('calories')
    const [selected, setSelected] = React.useState([])
    const [page, setPage] = React.useState(0)
    const [dense, setDense] = React.useState(false)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const [editOpen,setEditOpen]=React.useState(false)
    const [id, setId] = React.useState('')
    const [opensed, setOpensed] = React.useState(false)
    const [taxtype, setTaxType] = React.useState('')
    const [incgst, setIncgst] = React.useState('')
    const [percentage, setPercentage] = React.useState('')
    const [feeAmount, setFeeamount] = React.useState('')
    const [gstAmount, setGSTAmount] = React.useState('')
    const [editProduct,setEditProduct]=React.useState();
    const [Invendory, setInventory] = React.useState({
        _id:"",
        unit:"" ,
        quantity:"",
        purchasePrice:"" ,
        salePrice:"" ,
        stock:"" ,
        discount:"" ,
        isUnlimited:"" 
    })
    var rows = inputs.map((input, index) =>
        createData(
            index + 1,
            input.title,
            input.catelogName,
            input.categoryName,
            input.subCategoryName,
            input.brandName,
            input.productName,
            input.productImage,
            input.quantity,
            input.unit,
            // input.price,
            // input.maxPrice,
            input.purchasePrice,
            input.salePrice,
            input.isUnlimited,
            input.discount,
            input.stock,
            input.created_at,
            input.product_id,
            input._id
        )
    )
    const onSubmit = (e) => {
        var data = {
            bookingType:"buy",
            _id: id,
            type: taxtype.value,
            incGstBookingFee: incgst,
            gst: percentage,
            bookingFee: feeAmount,
            gstAmount: gstAmount,
        }
        var flatdata = {
            _id: id,
            bookingType:"buy",
            type: taxtype.value,
            PercentageIncGstBookingFee: percentageFlatfee,
            gstPercentage: flatGSTpercentage,
        }

        const token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        if (taxtype.value === 'flat') {
            axios
                .post(
                    'https://prod-api.krishione.com/farmer-function/set-commision',
                    data,
                    config
                )
                .then((res) => {
                    alert('Success!')
                    console.log(res.data)
                })
        }
        if (taxtype.value === 'percentage') {
            axios
                .post(
                    'https://prod-api.krishione.com/farmer-function/set-commision',
                    flatdata,
                    config
                )
                .then((res) => {
                    alert('Success!')
                    console.log(res.data)
                })
        }
    }
    const handleCommisions = (row) => {
        // setVariantData(row)
        console.log(row)
        setId(row.product_id)
        // setProductName(row.productName)
        setOpensed(true)
    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }
    const calculate = (perc) => {
        setPercentage(perc)
        const result = incgst / (1 + perc / 100)
        setGSTAmount(Number(incgst - result).toFixed(2))
        setFeeamount(result.toFixed(2))
    }
    const handleEditProduct = (row) => {
        // setVariantData(row)
        console.log(row,'this is row')
        setInventory({
            _id:row._id,
            unit: row.unit,
            quantity:row.variantName,
            purchasePrice: row.purchasePrice,
            salePrice: row.salePrice,
            stock: row.stock,
            discount: row.discount,
            isUnlimited: row.isUnlimited})
        // setProductName(row.productName)
        setEditOpen(true)
    }
    const handleEdit = () => {
        setEditOpen(!editOpen)
    }
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name)
            setSelected(newSelecteds)
            return
        }
        setSelected([])
    }
    const handlecloses = () => {
        setOpensed(!opensed)
    }
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            )
        }

        setSelected(newSelected)
    }
    const categoryoption = [
        { value: '', label: 'Select Category' },
        { value: 'GM', label: 'GM' },
        { value: 'KG', label: 'KG' },
        { value: 'ML', label: 'ML' },
        { value: 'LTR', label: 'LTR' },
        { value: 'SEEDS', label: 'SEEDS' },
        { value: 'BAG', label: 'BAG' },
        { value: 'PACKET', label: 'PACKET' },
        { value: 'ROLLS', label: 'ROLLS' }, 
        { value: 'QTY', label: 'QTY' },
        { value: 'NO.', label: 'NO.' }, 
        { value: 'PACK', label: 'PACK' },
        { value: 'UNIT', label: 'UNIT' },
         { value: 'GSM', label: 'GSM' },
        { value: 'FEET', label: 'FEET' }, 
        { value: 'BOX', label: 'BOX' },
        { value: 'INCH', label: 'INCH' }, 
        { value: 'ML', label: 'ML' },
    ]
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleChangeDense = (event) => {
        setDense(event.target.checked)
    }

    const isSelected = (name) => selected.indexOf(name) !== -1
    const taxtypes = [
        {
            label: 'Percentage',
            value: 'percentage',
        },
        {
            label: 'flat',
            value: 'flat',
        },
    ]
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0
        const onVariantSubmit=()=>{
            var token = localStorage.getItem("token");
            const config = {
              headers: { Authorization: `Bearer ${token}` }
            };
            let mobileno;
            if(props.catelogname==="livestock"){
                mobileno=localStorage.getItem("livestockfarmerid")
            }else{
                mobileno=localStorage.getItem("farmerid")
            }
            var data=Invendory

            axios.post("https://prod-api.krishione.com/farmer-function/edit-Inventory",data,config).then(res=>alert("Succesfully Created")).catch(err=>alert("Error Occured!"))
          setEditOpen(!editOpen)
            console.log(data)
        }
    return (
        <>
         <Dialog
                className="mapmodel"
                open={opensed}
                onClose={handlecloses}
                style={{
                    zIndex: 100,
                }}
            >
                <DialogTitle>
                    <b>Add Commision</b>
                </DialogTitle>
                <DialogContent>
                    <div class="card" style={{ width: '500px' }}>
                        <div class="card-header">
                            <div class="col-md-4">
                                <p>Select Commision Type</p>

                                <Select
                                    style={{
                                        width: '270px',
                                        height: '40px',
                                    }}
                                    placeholder={'Select Role'}
                                    value={taxtype}
                                    onChange={(e) => setTaxType(e)}
                                    options={taxtypes}
                                />
                            </div>
                        </div>

                        <div class="card-body">
                            <div class="row tx_bdr">
                                {taxtype.value === 'flat' ? (
                                    <div>
                                        <div class="col-md-4">
                                            <label
                                                for="Title"
                                                class="p-l-0 col-form-label input-label"
                                            >
                                                Flat Booking fees Inc. GSt
                                            </label>
                                            <input
                                                class="form-control"
                                                type="number"
                                                value={incgst}
                                                onChange={(e) =>
                                                    setIncgst(e.target.value)
                                                }
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <label
                                                for="Percentage"
                                                class="p-l-0 col-form-label input-label"
                                            >
                                                GST Percentage
                                            </label>
                                            <input
                                                class="form-control"
                                                type="number"
                                                value={percentage}
                                                onChange={(e) =>
                                                    calculate(e.target.value)
                                                }
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <label
                                                style={{ margintop: '24px' }}
                                                for="Percentage"
                                                class="p-l-0 col-form-label input-label"
                                            >
                                                Booking Fees
                                            </label>
                                            <input
                                                class="form-control"
                                                type="Text"
                                                value={feeAmount}
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <label
                                                for="Percentage"
                                                class="p-l-0 col-form-label input-label"
                                            >
                                                GST Amount
                                            </label>
                                            <input
                                                class="form-control"
                                                type="number"
                                                value={gstAmount}
                                            />
                                        </div>
                                    </div>
                                ) : null}

                                <br />
                                {taxtype.value === 'percentage' ? (
                                    <div>
                                        <div class="col-md-4">
                                            <label
                                                for="Percentage"
                                                class="p-l-0 col-form-label input-label"
                                            >
                                                % Booking fees Inc. GST
                                            </label>
                                            <input
                                                class="form-control"
                                                type="number"
                                                value={percentageFlatfee}
                                                onChange={(e) =>
                                                    setPercentageFlatfee(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <label
                                                for="Percentage"
                                                class="p-l-0 col-form-label input-label"
                                            >
                                                GST %
                                            </label>
                                            <input
                                                class="form-control"
                                                type="number"
                                                value={flatGSTpercentage}
                                                onChange={(e) =>
                                                    setFlatGSTpercentage(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div class="row tx_bdr">
                                <button
                                    variant="contained"
                                    onClick={(e) => onSubmit(e)}
                                    component="span"
                                    color="success"
                                    className="btn btn-primary mt-3"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        <Dialog className='mapmodel'  style={{
                    zIndex: 100,
                }} open={editOpen} onClose={handleEdit}>
            <DialogTitle>
                    <b>Edit Variant for </b>
                    {/* <b>Edit Product</b> */}

                </DialogTitle>
                <DialogContent>
                                                                                <div >
                                                                                <CssTextField
                          required
                          fullWidth
                          id="quantity"
                          label="quantity"
                          variant="standard"
                          value={Invendory.quantity}
                          name="quantity"
                          onChange={(e) =>
                            setInventory({
                                ...Invendory,
                                quantity: e.target.value,
                            })
                        }
                        />
                            </div>
                            <div >
                          {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Unit</InputLabel> */}
                          <InputLabel id="demo-simple-select-label">Unit</InputLabel>

                          <Select
                            id="demo-simple-select"
                           
                            label="Unit"
                            value={Invendory.unit}
                            onChange={(e) => 
                                setInventory({
                                    ...Invendory,
                                    unit:e.value
                                })
                            }
                            options={categoryoption}
                         />
                               
                            </div>
                             
                            <div >
                                <CssTextField
                                    required
                                    fullWidth
                                    id="maxPrice"
                                    label="Purchase Price"
                                    variant="standard"
                                    value={Invendory.purchasePrice}
                                    onChange={(e) =>
                                        setInventory({
                                            ...Invendory,
                                            purchasePrice: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div >
                                <CssTextField
                                    required
                                    fullWidth
                                    id="Price"
                                    label="Sale Price"
                                    variant="standard"
                                    value={Invendory.salePrice}
                                    onChange={(e) =>
                                        setInventory({
                                            ...Invendory,
                                            salePrice: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div >
                                <CssTextField
                                    required
                                    fullWidth
                                    id="Discount"
                                    label="Discount"
                                    variant="standard"
                                    value={Invendory.discount}
                                    onChange={(e) =>
                                        setInventory({
                                            ...Invendory,
                                            discount: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div >
                                <div className="row">
                                    <div className="col-4">
                                        <InputLabel
                                            id="demo-simple-select-standard-label"
                                            style={{ color: 'black' }}
                                            className="mt-3"
                                        >
                                            Unlimited
                                        </InputLabel>
                                        <Switch
                                            checked={Invendory.isUnlimited}
                                            //   onChange={this.handleChange('checkedA')}
                                            value="checkedA"
                                            //   onChange={this.handleChange("checkedA")}
                                            //   value={Invendory.isUnlimited}
                                            onChange={(e) =>
                                                setInventory({
                                                    ...Invendory,
                                                    isUnlimited:
                                                        e.target.checked,
                                                })
                                            }
                                        />
                                    </div>
                                    {Invendory.isUnlimited ? (
                                        ''
                                    ) : (
                                        <div className="col-8">
                                            <CssTextField
                                                required
                                                fullWidth
                                                id="stock"
                                                label="Stock"
                                                variant="standard"
                                                value={Invendory.stock}
                                                onChange={(e) =>
                                                    setInventory({
                                                        ...Invendory,
                                                        stock: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Button type="submit" color='primary'
                                  onClick={(e)=>onVariantSubmit(e)}
                                    style={{ marginRight: '10px' }}>Edit Variant</Button>
                                                                                    
                                                                                </DialogContent>
            </Dialog>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Box
                sx={{
                    width: '100%',
                    marginBottom: '20px',
                    paddingRight: '0px',
                    paddingLeft: '0px',
                }}
            >
                <Paper sx={{ width: '100%', mb: 2, backgroundColor: '#FFF' }}>
                    {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                    <TableContainer>
                        <Table
                            //  style={{fontWeight:'bold'}}
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody style={{ marginBottom: '20px' }}>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(
                                            row.name
                                        )
                                        const labelId = `enhanced-table-checkbox-${index}`

                                        return (
                                            <>
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    aria-checked={
                                                        isItemSelected
                                                    }
                                                    tabIndex={-1}
                                                    key={row.name}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox"></TableCell>
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        {row.id}
                                                    </TableCell>
                                                    {/* <TableCell align="center">{row.title}</TableCell>
                      <TableCell align="center">{row.catelogName}</TableCell> */}
                                                    {/* <TableCell align="right"><a href={`/InputSupplierDetails/${row._id}`}><u>{row.mobile}</u></a></TableCell> */}
                                                    <TableCell align="center">
                                                        {row.categoryName}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.subCategoryName}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.brandName}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.productName}
                                                    </TableCell>
                                                    <TableCell>
                                                        <img
                                                            src={
                                                                row.productImage
                                                            }
                                                            style={{
                                                                width: '100px',
                                                            }}
                                                        />
                                                    </TableCell>
                                                    {/* <TableCell><img src={row.productImage} style={{width: "100px"}} /></TableCell> */}
                                                    <TableCell align="center">
                                                        {row.variantName}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.unit}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.purchasePrice}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.salePrice}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.isUnlimited.toString()}
                                                    </TableCell>
                                                    {/* <TableCell align="center">{row.commissionAmount}</TableCell> */}
                                                    <TableCell align="center">
                                                        {row.discount}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.stock}
                                                    </TableCell>
                                                    {/* <TableCell>{new Date(row.createdAt).toDateString().slice(4, 15)}</TableCell> */}

                                                    <TableCell align="center">
                                                        {row.createdAt}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                                <Button
                                                                    onClick={() =>
                                                                        handleCommisions(
                                                                            row
                                                                        )
                                                                    }
                                                                >
                                                                    ADD
                                                                </Button>
                                                            </TableCell>
                                                    <TableCell align="center">
                                                                <Button
                                                                    onClick={() => handleEditProduct(row)}
                                                                >
                                                                    EDIT
                                                                </Button>
                                                            </TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height:
                                                (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
        </>
    )
}