import React, { useState } from 'react'

import ListFarmer from './ListFarmer/ListFarmer'
import ListFpo from './ListFpo/ListFpo'
import ListInputSupplier from './ListInputSupplier/ListInputSupplier'
import ListLivestock from './ListLivestock/ListLivestock'
import ListMachine from './ListMachine/ListMachine'
import ListMicro from './ListMicro/ListMicro'
import ListTrader from './ListTrader/ListTrader'
import ListTransport from './ListTransport/ListMachine'

const ListTab = () => {
    const [tab, setTab] = useState(1)

    return (
        <div class="content container-fluid">
            <div class="page-header">
                <div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div class="card card-table p-10-25">
                                <div class="card-header">
                                    <h3 class="card-title">List</h3>
                                </div>
                                <form
                                    class="card-body"
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        console.log('Form Successfully working')
                                    }}
                                >
                                    <>
                                        <div class="tab-content">
                                            <div action="#">
                                                <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                                    <li class="nav-item">
                                                        <a
                                                            class={
                                                                tab === 'farmer'
                                                                    ? 'nav-link active'
                                                                    : 'nav-link'
                                                            }
                                                            href="#bottom-justified-tab1"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(1)
                                                            }
                                                        >
                                                            Farmer
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(2)
                                                            }
                                                        >
                                                            Live Stock
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab2"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(3)
                                                            }
                                                        >
                                                            Input Supplier
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(4)
                                                            }
                                                        >
                                                            Agri Machine
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(5)
                                                            }
                                                        >
                                                            Agri Transport
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(6)
                                                            }
                                                        >
                                                            Micro Entrepreneur
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(7)
                                                            }
                                                        >
                                                            Traders
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(8)
                                                            }
                                                        >
                                                            Fpo
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                </form>
                            </div>

                            {/* here our table component */}
                            {tab == 1 && <ListFarmer />}
                            {tab == 2 && <ListLivestock />}
                            {tab == 3 && <ListInputSupplier />}
                            {tab == 4 && <ListMachine />}
                            {tab == 5 && <ListTransport />}
                            {tab == 6 && <ListMicro />}
                            {tab == 7 && <ListTrader />}
                            {tab == 8 && <ListFpo />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListTab
