import React, { useState } from 'react'
import { CssTextField } from '../Styles'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import AddIcon from '@mui/icons-material/Add'

function PricingRule() {
    const [radio, setRadio] = useState('')
    const [dSlots, setDSlots] = useState(['slot'])
    console.log(dSlots)

    const addSlots = () => {
        let myArr = [...dSlots]
        myArr.push(`slot ${myArr.length + 1}`)
        setDSlots(myArr)
    }

    const removeSlots = (e, index) => {
        let myArr = [...dSlots]
        myArr.splice(index, 1)
        setDSlots(myArr)
    }

    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <h5 class="card-title">Pricing Rules</h5>
                            <p class="card-title">Default - </p>
                        </div>
                        <div className="col-md-6 text-end">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                id="filter_search"
                            >
                                {' '}
                                + Add{' '}
                            </button>
                        </div>
                    </div>
                </div>

                {/* Add Pricing Rules */}
                <div
                    id="filter_inputs"
                    class="card filter-card mt-3"
                    style={{
                        backgroundColor: '#f4f5f8',
                        marginLeft: '20px',
                        marginRight: '20px',
                        borderRadius: '7px',
                    }}
                >
                    <form class="card-body pb-0" action="#">
                        <div class="row">
                            <h5 class="card-title">Create Pricing Rules</h5>
                            <div class="col-md-4 select2-container">
                                <div class="form-group" data-select2-id="11">
                                    <CssTextField
                                        required
                                        fullWidth
                                        id="ruleName"
                                        label="Rule Name"
                                        variant="standard"
                                        className="mt-3"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <h6>Fare Calculations</h6>
                            <p>Apply to</p>
                            <div class="col-md-12 select2-container">
                                <FormControl
                                    component="fieldset"
                                    onChange={(e) => setRadio(e.target.value)}
                                >
                                    <RadioGroup
                                        row
                                        aria-label="gender"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel
                                            value="1"
                                            control={<Radio />}
                                            label="Flat Fare"
                                        />
                                        <FormControlLabel
                                            value="2"
                                            control={<Radio />}
                                            label="Distance and Time based Fare"
                                        />
                                        <FormControlLabel
                                            value="3"
                                            control={<Radio />}
                                            label="Distance Slots based Fare"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>

                            {(radio === '1' || radio === '2') && (
                                <div class="col-md-4 select2-container">
                                    <div
                                        class="form-group"
                                        data-select2-id="11"
                                    >
                                        <CssTextField
                                            required
                                            fullWidth
                                            id="baseFare"
                                            label="Base Fare"
                                            variant="standard"
                                            className="mt-3"
                                        />
                                    </div>
                                </div>
                            )}

                            {radio === '2' && (
                                <>
                                    <div class="col-md-4 select2-container">
                                        <div
                                            class="form-group"
                                            data-select2-id="11"
                                        >
                                            <CssTextField
                                                required
                                                fullWidth
                                                id="durationFee"
                                                label="Duration Fee"
                                                variant="standard"
                                                className="mt-3"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-4 select2-container">
                                        <div
                                            class="form-group"
                                            data-select2-id="11"
                                        >
                                            <CssTextField
                                                required
                                                fullWidth
                                                id="distanceFee"
                                                label="Distance Fee"
                                                variant="standard"
                                                className="mt-3"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-8 select2-container">
                                        <div
                                            class="form-group"
                                            data-select2-id="11"
                                        >
                                            <CssTextField
                                                required
                                                fullWidth
                                                id="distanceFee"
                                                label="Minimum Fare (Leave empty if there is no minimum fare)"
                                                variant="standard"
                                                className="mt-1"
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="row mt-3">
                            {radio === '3' && (
                                <>
                                    <p>
                                        (If the distance is more than the last
                                        slot, then the price of last slot will
                                        be applied.)
                                    </p>
                                    <div class="col-md-8 select2-container text-end"></div>
                                    <div class="col-md-4 select2-container ">
                                        <Stack direction="row" spacing={1}>
                                            <IconButton
                                                aria-label="add"
                                                color="success"
                                                onClick={(e) => addSlots()}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Stack>
                                    </div>
                                    {dSlots.map((slot, index) => {
                                        return (
                                            <>
                                                <div class="col-md-4 select2-container">
                                                    <div
                                                        class="form-group"
                                                        data-select2-id="11"
                                                    >
                                                        <CssTextField
                                                            required
                                                            fullWidth
                                                            id="upto"
                                                            label="Upto"
                                                            variant="standard"
                                                            className="mt-1"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-4 select2-container">
                                                    <div
                                                        class="form-group"
                                                        data-select2-id="11"
                                                    >
                                                        <CssTextField
                                                            required
                                                            fullWidth
                                                            id="cost"
                                                            label="Cost"
                                                            variant="standard"
                                                            className="mt-1"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-4 select2-container">
                                                    <Stack
                                                        direction="row"
                                                        spacing={1}
                                                    >
                                                        <IconButton
                                                            aria-label="delete"
                                                            style={{
                                                                color: 'red',
                                                                marginTop:
                                                                    '20px',
                                                            }}
                                                            onClick={(e) =>
                                                                removeSlots(
                                                                    e,
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </Stack>
                                                </div>
                                            </>
                                        )
                                    })}
                                </>
                            )}
                        </div>
                        <div className="row justify-content-end mb-3">
                            <div class="col-sm-2 col-md-2 ">
                                <button
                                    type="submit"
                                    className="btn btn-primary mt-26"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="card card-body bg-gray">
                                <h5>Rule 1</h5>
                                <div class="status-options">
                                    <a href="#">
                                        <span class="material-icons-outlined">
                                            edit
                                        </span>
                                    </a>
                                    <a href="#">
                                        <span class="material-icons-outlined">
                                            delete
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card card-body bg-gray">
                                <h5>Rule 2</h5>
                                <div class="status-options">
                                    <a href="#">
                                        <span class="material-icons-outlined">
                                            edit
                                        </span>
                                    </a>
                                    <a href="#">
                                        <span class="material-icons-outlined">
                                            delete
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card card-body bg-gray">
                                <h5>LIC</h5>
                                <div class="status-options">
                                    <a href="#">
                                        <span class="material-icons-outlined">
                                            edit
                                        </span>
                                    </a>
                                    <a href="#">
                                        <span class="material-icons-outlined">
                                            delete
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PricingRule
