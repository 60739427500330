import React, { useState, useEffect } from 'react'
import { CssTextField } from '../Styles'
import IconButton from '@mui/material/IconButton'
import RoomIcon from '@mui/icons-material/Room'
import SendIcon from '@mui/icons-material/Send'
import AssessmentIcon from '@mui/icons-material/Assessment'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import { useParams } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Swal from 'sweetalert2'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import Typography from '@mui/material/Typography'
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService'
import FormControl from '@mui/material/FormControl'
// import Select from '@mui/material/Select';
import {
    thumb,
    img,
    thumbInner,
    thumbsContainerState,
    baseStyle02,
} from '../Styles'
import './Styles.css'
import InputLabel from '@mui/material/InputLabel'
import { API } from '../../API'
import Select from 'react-select'
import { useDropzone } from 'react-dropzone'
import { storage } from '../../firebase/index'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { OpenInFullSharp, Sell } from '@mui/icons-material'
import Button from '@mui/material/Button'
import axios from 'axios'
import Map from '../Create/Map'
import BookingMap from '../Create/bookingMap'
import SellCropInvoice from './SellCropInvoice'
import CancelAssignDialog from './cancelAssign'
import { Drawer } from '@mui/material'
const SellCropClear = {
    // farmerId: "",
    id: '',
    cropType: '',
    cropName: '',
    variety: '',
    variety: '',
    availabilityDate: '',
    availabilityDay: '',
    expectedQuailty: '',
    expectedPriceMin: '',
    expectedPriceMax: '',
    unit: '',
    description: '',
    photoUrl: '',
}
const SellCropDB = (props) => {
    const [booking, setBooking] = useState({})
    const [invoiceId, setInvoiceId] = useState('')
    const [invoiceopen, setInvoiceOpen] = React.useState(false)
    const [TotalPrice, setTotalPrice] = useState(0)
    const [createdByData, setCreatedByData] = useState({})
    const [lat, setLat] = useState(18.5204)
    const [long, setLong] = useState(73.8567)
    let { id } = useParams()
    const [transactionId, setTransactionId] = useState('')
    const [paymentMode,setPaymentMode]=useState("")

    const [disabled, setDisabled] = useState(false)
    const [taxes,setTaxes]=useState()

    useEffect(() => {
        Getvalue()
        // getTaxes()
    }, [])

    
    // const getTaxes=()=>{
    //     const token = localStorage.getItem('token')

    //     const config = {
    //         headers: { Authorization: `Bearer ${token}` },
    //     }
    //     axios
    //         .get(
    //             `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/invoice-taxes/sell`,
    //             config
    //         )
    //         .then((res) => {
    //             setTaxes(res.data.data[0])
               
    //         })
    // }
    const Getvalue = async () => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }

        axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product/sell/${id}`,
                config
            )
            .then((res) => {
                console.log(res.data.product, 'res.data.data')
                if (res.data.product.cancelled) setDisabled(true)
                if (res.data.product.taxes) setTaxes(res.data.product.taxes)

                setDetails(res.data.product)
                if (res.data.product.invoiceId) {
                    setInvoiceId(res.data.product.invoiceId)
                }
                if (res.data.product.status === 'accepted') {
                    setActiveColor('green')
                }
                if (res.data.product.status === 'confirmed') {
                    setActiveColor('green')
                    setActiveColor1('green')
                }
                if (res.data.product.status === 'buy') {
                    setActiveColor('green')
                    setActiveColor1('green')
                    setActiveColor2('green')
                    setTab1(true)
                }
                if (res.data.product.status === 'sell') {
                    setActiveColor('green')
                    setActiveColor1('green')
                    setActiveColor2('green')
                    setActiveColor3('green')

                    setTab1(true)
                    setTab2(true)
                    setTab3(true)
                }
                if (res.data.product.status === 'completed') {
                    setActiveColor('green')
                    setActiveColor1('green')
                    setActiveColor2('green')
                    setActiveColor3('green')
                    setTab1(true)
                    setTab2(true)
                    setTab3(true)
                }
                if (res.data.product.payment?.transaction_notes){
                    setPaymentStatus(true)
                    setTransactionNotes(res.data.product.payment.transaction_notes)

                }
                if (res.data.product.transactionId){
                    setPaymentStatus(true)
                    setTransactionId(res.data.product.transactionId)

                }
                const user = {
                    userId: res.data.product.user_id,
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-user`,
                        user,
                        config
                    )
                    .then((res) => {
                        console.log('check1: ', res.data)
                        if (res.data.user.length) {
                            setBooking(res.data.user[0])
                        } else {
                            setBooking({})
                        }
                    })

                const createdBy = {
                    userId: res.data.product.createdById,
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-user`,
                        createdBy,
                        config
                    )
                    .then((res) => {
                        if (!res.data.user.length) return

                        const { id, role, mobile_number } = res.data.user[0]
                        setCreatedByData({
                            id,
                            role,
                            mobile_number,
                        })
                    })
            })
        // const { data } = await API.get(`/getSellCropsBookings?type=1&bookedBy=Farmer&id=` + localStorage.getItem('Bookingsellcropid'));
        // console.log("data", data)
        // setDetails(data?.result);
        // setUpdateDetails(data?.result)
        // setCropTypes({
        //    value: data?.result.cropType,
        //    label: data?.result.cropType
        // }

        // )
        // setCropNames({
        //    value: data?.result.cropName,
        //    label: data?.result.cropName
        // })
        // setUnitName({
        //    value: data?.result.unit,
        //    label: data?.result.unit
        // })
    }

    const [opens, setOpens] = React.useState(false)
    const [paymentModal, setPaymentModal] = useState(false)
    const [transactionNotes,setTransactionNotes]=useState("")

    const [tab, setTab] = useState(true)
    const [tab1, setTab1] = useState(false)
    const [tab2, setTab2] = useState(false)
    const [tab3, setTab3] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [description,setDescription]=useState('')
   
    const [editProducts, setEditProducts] = useState(false)
    const [quantity, setQuantity] = useState(0)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const [cropType, setCropTypes] = useState('')
    const [CropNames, setCropNames] = useState('')
    const [unitName, setUnitName] = useState('')
    const [Details, setDetails] = useState({})
    const [District, setDistrict] = useState('')
    const [UpdateDetails, setUpdateDetails] = useState({})
    const [Files, setFiles] = useState([])
    const [file, setFile] = useState(null)
    const [docImg, setDocImg] = useState(null)
    const [getAllProduct, setgetAllProduct] = useState([])
    const [getAllCrop, setgetAllCrop] = useState([])
    const [driverList, setDriverList] = useState([])
    const [driverList1, setDriverList1] = useState([])
    const [drivers, setDrivers] = useState([])
    const [getAllSubCategory, setgetAllSubCategory] = useState([])
    const [sellcropBookfetch, setsellcropBookfetch] = useState([])
    const [assignData, setAssignedData] = useState([])
    const [assignDriverData, setAssignedDriverData] = useState([])
    const [paymentStatus, setPaymentStatus] = useState(false)
    const [openAssign, setOpensAssign] = useState(false)

    const [sellCrop, setSellCrop] = useState({
        id: '',
        cropType: Details.cropType,
        cropName: Details.CropName,
        variety: Details.variety,
        // variety: "",
        availabilityDate: '',
        availabilityDay: '',
        expectedQuailty: '',
        expectedPriceMin: '',
        expectedPriceMax: '',
        unit: '',
        description: '',
        photoUrl: [],
        bookedBy: 'Farmer',
        createdBy: 'Admin',
    })

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const PaymentOptions = [
        {
            label: 'Online',
            value: 'Online',
        },
        {
            label: 'Cash',
            value: 'Cash',
        },
    ]
    useEffect(() => {
        // getState();
        getSellCropBooking()
        getBodyType()
        getCropCatelog()
        getAssignedDriver()
        // getPaymentDetails()
    }, [])

    // const getPaymentDetails = () => {
    //     // const u = location.pathname
    //     const orderId = id
    //     const token = localStorage.getItem('token')

    //     const config = {
    //         headers: { Authorization: `Bearer ${token}` },
    //     }
    //     axios
    //         .get(
    //             `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-payment/${orderId}`,
    //             config
    //         )
    //         .then((res) => {
    //             console.log(res)
    //             if (res.data.paymentStatus) {
    //                 setPaymentStatus(true)
    //                 setTransactionId(res.data.orderId)
    //             }
    //         })
    // }
    const getSellCropBooking = async () => {
        const { data } = await API.get(
            `/getSellCropsBookings?type=0&bookedBy=Farmer`
        )
        setsellcropBookfetch(data?.result)
        console.log('setsellcropBookfetch', data?.result)
    }
    const getBodyType = async () => {
        const { data } = await API.get(
            `/getProductSubCategory?type=2&title=ProductCatelog&catelogName=CropCatelog`
        )
        console.log('cate', data)
        setgetAllSubCategory(data?.result)
    }
    const getCropCatelog = async () => {
        const { data } = await API.get(
            `/getProductCatelogProduct?type=0&title=ProductCatelog&catelogName=CropCatelog`
        )
        console.log('cate', data)
        setgetAllCrop(data?.result)
    }
    const handleBook = async (e) => {
        e.preventDefault()
        console.log('handleChange', UpdateDetails)
        let inputBook = { ...UpdateDetails, id: UpdateDetails._id }
        try {
            const { data } = await API.post(
                '/updateSellCropsBooking',
                inputBook
            )
            if (data?.success) {
                Getvalue()
                setEditProducts(false)
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    // setDocImg(null)
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })

                console.log(data)
                //  setSellCrop(SellCropClear);
                //  setDocImg(null)
                //  setFiles([])
                //  getSellCropBooking()
                // setCropTypes([]);
            } else {
                Getvalue()
                setEditProducts(false)
                //   setSellCrop(SellCropClear);
                //  setDocImg(null)
                //  setFiles([])
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            Getvalue()
            setEditProducts(false)
            // setSellCrop(SellCropClear);
            // setFiles([])
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            console.log('file1', acceptedFiles)
            console.log('file', acceptedFiles[0])
            setFiles(
                acceptedFiles.map((file) =>
                    //  { console.log("file",file)},
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
            let val = []
            let arr = []
            for (let i = 0; i < acceptedFiles.length; i++) {
                setDocImg(URL.createObjectURL(acceptedFiles[i]))
                const doc = acceptedFiles[i]
                if (acceptedFiles[i]) {
                    const uploadTask = ref(storage, `docs/${doc.name}`)
                    const uploadTask02 = uploadBytesResumable(uploadTask, doc)
                    uploadTask02.on(
                        'state_changed',
                        (snapshot) => {},
                        (error) => {
                            // Handle unsuccessful uploads
                        },
                        () => {
                            getDownloadURL(uploadTask).then((url) => {
                                console.log('url', url)

                                arr.push(url)
                            })
                        }
                    )
                    setSellCrop({ ...sellCrop, photoUrl: arr })
                    console.log('arr', arr)
                }
            }

            // setDocImg(URL.createObjectURL(acceptedFiles[0]));
            //       const doc = acceptedFiles[0];
            //       if(acceptedFiles[0]) {
            //         const uploadTask = ref(storage, `docs/${doc.name}`);
            //         const uploadTask02 = uploadBytesResumable(uploadTask, doc);
            //         uploadTask02.on('state_changed',
            //           (snapshot) => {
            //           },
            //           (error) => {
            //             // Handle unsuccessful uploads
            //           },
            //           () => {
            //             getDownloadURL(uploadTask).then((url) => {
            //               setSellCrop({...sellCrop, photoUrl: url});
            //             });
            //           }
            //         );
            //       }
        },
    })

    const PGthumbs =
        Files &&
        Files.map((file, index) => (
            <div style={thumb} key={file.name}>
                <div style={thumbInner}>
                    <img src={file.preview} style={img} alt="" />
                </div>
            </div>
        ))
    const vehicleOptiom =
        getAllSubCategory &&
        getAllSubCategory.map((item, ind) => ({
            value: item.subCategoryName,
            label: item.subCategoryName,
        }))
    const typeoptiob =
        getAllCrop &&
        getAllCrop.map((item, ind) => ({
            value: item.productName,
            label: item.productName,
        }))
    const CropOptions = [
        {
            label: 'KG',
            value: 'KG',
        },
        {
            label: 'TON',
            value: 'TON',
        },
        {
            label: 'QUINTAL',
            value: 'QUINTAL',
        },
    ]
    //  const sellcrop = sellcropBookfetch&&sellcropBookfetch.map((item,ind)=>({
    //    "value" :item.MicroEnterpreneurId,
    //    "label" : item.MicroEnterpreneurId})

    //    )
    //  sellcropBookfetch
    //  MicroEnterpreneurId
    const updatechangecrop = (e) => {
        setUpdateDetails({ ...UpdateDetails, cropType: e.value })
        setCropTypes({
            value: e.value,
            label: e.value,
        })
    }

    const updatechangecropname = (e) => {
        setUpdateDetails({ ...UpdateDetails, cropName: e.value })
        setCropNames({
            value: e.value,
            label: e.value,
        })
    }

    const updatechangecropname1 = (e) => {
        setUpdateDetails({ ...UpdateDetails, unit: e.value })
        setUnitName({
            value: e.value,
            label: e.value,
        })
    }

    const handleclickopens = () => {
        console.log('truepopup')
        setOpens(true)
    }

    const categoryoption = [
        { value: '', label: 'Select Category' },
        { value: 'agri-machine', label: 'AGRI MACHINE' },
        { value: 'agri-transport', label: 'AGRI TRANSPORT' },
        { value: 'trader', label: 'TRADER' },
        { value: 'micro-entrepreneur', label: 'MIC ENTREPRENEUR' },
    ]

    const handlecloses = () => {
        setOpens(false)
        handleClose()
    }
    const bookingmodeoption = [
        { value: 'DELIVERY', label: 'DELIVERY' },
        { value: 'PICKUP', label: 'PICKUP' },
    ]
    const handleTab1 = (status) => {
        var token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            bookingId: id,
            status: status,
        }

        console.log('handleActive')
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/set-booking-status`,
                data,
                config
            )
            .then((res) => {
                console.log('res', res)
                setActiveColor('green')
                setActiveColor1('green')
                setActiveColor2('green')
                setActiveColor3('green')
                setTab(true)
                setTab1(true)
                setTab2(true)
                setTab3(true)
            })
            .catch((err) => {
                console.log('err', err)
                setActiveColor('')
            })
    }

    const [activeColor, setActiveColor] = useState('')
    const [activeColor1, setActiveColor1] = useState('')
    const [activeColor2, setActiveColor2] = useState('')
    const [activeColor3, setActiveColor3] = useState('')
    const handleActive = async (status) => {
        var token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            bookingId: id,
            status: status,
        }

        console.log('handleActive')
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/set-booking-status`,
                data,
                config
            )
            .then((res) => {
                console.log('res', res)
                setActiveColor('green')
                setActiveColor1('')
            })
            .catch((err) => {
                console.log('err', err)
                setActiveColor('')
            })
    }

    const handleActive1 = (status) => {
        var token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            bookingId: localStorage.getItem('Bookingsellcropid'),
            status: status,
        }

        console.log('handleActive')
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/set-booking-status`,
                data,
                config
            )
            .then((res) => {
                console.log('res', res)
                setActiveColor('green')
                setActiveColor1('green')
                setActiveColor2('')
                setActiveColor3('')
            })
            .catch((err) => {
                console.log('err', err)
                setActiveColor1('')
            })
    }

    const handleActive2 = (status) => {
        var token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            bookingId: localStorage.getItem('Bookingsellcropid'),
            status: status,
        }

        console.log('handleActive')
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/set-booking-status`,
                data,
                config
            )
            .then((res) => {
                console.log('res', res)
                setActiveColor('green')
                setActiveColor1('green')
                setActiveColor2('green')
                setActiveColor3('')
                setTab1(true)
            })
            .catch((err) => {
                console.log('err', err)
                setActiveColor2('')
            })
    }

    const handleActive3 = (status) => {
        var token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            bookingId: localStorage.getItem('Bookingsellcropid'),
            status: status,
        }

        console.log('handleActive')
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/set-booking-status`,
                data,
                config
            )
            .then((res) => {
                console.log('res', res)
                setActiveColor('green')
                setActiveColor1('green')
                setActiveColor2('green')
                setActiveColor3('green')
            })
            .catch((err) => {
                console.log('err', err)
                setActiveColor3('')
            })

        var data2 = {
            bookingId: localStorage.getItem('Bookingsellcropid'),
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/generate-invoice`,
                data2,
                config
            )
            .then((res) => {
                console.log('res', res)
                setInvoiceId(res.data.data.invoiceId)
                // setI("green")
            })
            .catch((err) => {
                console.log('err', err)
                // setI("")
            })
    }

    const searchDriver = (e) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            role: e.value,
            district: District,
        }
        setAssignedData(data)

        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-drivers`,
                data,
                config
            )
            .then((res) => {
                console.log('res', res.data.data)
                setDriverList(res.data.mobile_numbers)
                var options = []
                res.data.mobile_numbers.map((item, ind) => {
                    options.push({
                        value: item.mobile_number,
                        label: item.mobile_number,
                    })
                })
                setDriverList1(options)
            })
        console.log(e.value, e)
    }

    const getAssignedDriver = () => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .get(
                `${
                    process.env.REACT_APP_API_ENDPOINT
                }/farmer-function/get-assigned-driver/${localStorage.getItem(
                    'Bookingsellcropid'
                )}`,
                config
            )
            .then((res) => {
                console.log('res', res.data)
                var data ={
                    "mobile_no":res.data.drivers[0].mobile_number
                }
                axios
                .post(
                    `${
                        process.env.REACT_APP_API_ENDPOINT
                    }/farmer-function/register-details`,data,
                    config
                ).then(response=>{
                    return(
                        //  console.log(...res.data.drivers,response.data.da))
                        setAssignedDriverData([...res.data.drivers,response.data.data.data]))
                })
                // setAssignedDriverData(res.data.drivers)
                if (res.data.drivers.length) {
                    setTab(true)
                    setTab(true)
                }
                console.log('assignedDriverData', assignDriverData)
            })
    }

    const setDriver = (e) => {
        var details = driverList.filter((item) => item.mobile_number == e.value)
        setDrivers(details)
        setAssignedData({
            ...assignData,
            mobile_number: details[0].mobile_number,
            trader_id: details[0].id,
            user_id: details[0].user_id,
            name: details[0].first_name + ' ' + details[0].last_name,
        })
    }
    const mapdataPickup = (data) => {
        console.log(data)
        setDistrict(data.subDistrict)
    }
    const handleCancelAssign = () => {
        console.log('ress')
        setOpensAssign(true)
    }
    const closeAssignDialog = () => {
        setOpensAssign(false)
    }

    const handleAssign = () => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            ...assignData,
            district: District,
            bookingId: localStorage.getItem('Bookingsellcropid'),
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/assign-driver`,
                data,
                config
            )
            .then((res) => {
                setActiveColor('green')
                setTab1(true)
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    // setDocImg(null)
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            })
            .catch((err) => console.log(err))
        console.log(assignData)
        // var data = {
        //    bookingId: localStorage.getItem('Bookingsellcropid'),

        // }
    }

    const fetchByphone = (value) => {
        const token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        if (value.length === 10) {
            var payload = {
                mobile_no: value,
            }
            axios
                .post(
                    'https://prod-api.krishione.com/farmer-function/register-details',
                    payload,
                    config
                )
                .then((res) => {
                    console.log(res.data)

                    setAssignedData({
                        ...assignData,
                        mobile_number: res.data.data.data.mobile_number,
                        trader_id: res.data.data.data.id,
                        name:
                            res.data.data.data.first_name +
                            ' ' +
                            res.data.data.data.last_name,
                    })
                    setDrivers([res.data.data.data])
                    setDriverList(res.data.data.data.mobile_numbers)
                    setDistrict(res.data.data.data.district)
                    setLat(res.data.data.data.coordinates.coordinates[1])
                    setLong(res.data.data.data.coordinates.coordinates[0])

                    var options = [
                        {
                            value: res.data.data.data.mobile_number,
                        },
                    ]
                    // res.data.map((item, ind) => {
                    //     options.push({
                    //         value: item.mobile_number,
                    //         label: item.mobile_number,
                    //     })
                    // })
                    setDriverList1(options)
                })
        } else {
            return
        }
    }

    const cancelBooking = () => {
        const token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }

        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/cancel-booking`,
                {
                    bookingId: id,
                    type: 'sell',
                },
                config
            )
            .then((res) => setDisabled(true))
    }
    const handlePaymentStatus=(e)=>{
        const token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data={
            amount:  Number(Details.price) * Number(Details.quantity),
          user_id:Details.user_id,
          currency: "INR",
          orderId:id,
          quotationId:id,
          createdById:Details.createdById,
          assignById:Details.traderId,
          created_at: Date.now(),
          completed: false,
          isVisible: false,
          paymentStatus: "captured",
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/update-cashpayment`,
                {
                    wallet_data:data,
                    bookingId: id,
                    type: 'sell',
                    "billing_type": paymentMode.value,
                    "transaction_notes":description,
                    "date":startDate
                },
                config
            )
            .then((res) =>{
                setPaymentModal(false)
                alert("Payment Updated!")
            } )
    }
    return (
        <div class="content container-fluid">
            <div
                class="page-header"
                style={
                    disabled ? { pointerEvents: 'none', opacity: '0.4' } : {}
                }
            >
                <div class="row align-items-center">
                    <div class="col">
                        <h3 class="page-title">#{invoiceId}</h3>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a
                                    style={{ color: 'green' }}
                                    onClick={() => setInvoiceOpen(true)}
                                >
                                    <u>View Invoice</u>
                                </a>
                            </li>
                            <li
                                style={{ cursor: 'pointer' }}
                                class="breadcrumb-item"
                            >
                                <a
                                    onClick={() => cancelBooking()}
                                    style={{ color: 'green' }}
                                >
                                    <u>Cancel Booking</u>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <Dialog
                    fullWidth={'xl'}
                    maxWidth={'md'}
                    open={invoiceopen}
                    onClose={() => setInvoiceOpen(false)}
                >
                    <SellCropInvoice
                        Details={Details}
                        booking={booking}
                        trader={assignDriverData[0]}
                        traderAdd={assignDriverData[1]}
                        TotalPrice={
                            Number(Details.price) * Number(Details.quantity)
                        }
                        invoiceId={invoiceId}
                        taxes={taxes}
                    />
                </Dialog>
                <Drawer
                    open={paymentModal}
                    onClose={()=>setPaymentModal(false)}
                    anchor="right"

                >

<div
                        class="col-md-3"
                        style={{
                            marginTop: '2%',
                            width: 500, padding: 31,height: 500,
                        }}
                    >
                        <h3>Select Payment Mode</h3>
                        <FormControl
                            variant="standard"
                            fullWidth
                            className="mt-3"
                        >
                            {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Booking Mode</InputLabel> */}
                            <Select
                                style={{
                                    width: '270px',
                                    height: '40px',
                                }}
                                placeholder={'Select Payment Mode'}
                                value={paymentMode}
                                onChange={(e) => setPaymentMode(e)}
                                //   onChange={(e)=>setUpdateDetails({...UpdateDetails, unit: e.value})}
                                options={PaymentOptions}
                            />
                            <br/>
                            <label>Select Date</label>
<br/>
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                            <br/>
                             <label>Transaction Notes</label>
                                    <textarea
                                        class="form-control"
                                        rows="3"
                                        onChange={(e)=>setDescription(e.target.value)}
                                        cols="30"
                                        // onChange={(e) => handlecreate(e)}
                                        // id="Biography"
                                        // value={data.Biography}
                                    ></textarea>
                            <button variant="contained" onClick={(e)=>handlePaymentStatus(e)}
                                                            component="span"
                                                            color="success"  className="btn btn-primary mt-3">Submit</button>
                            
                        </FormControl>

                    </div>
                </Drawer>
                <div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div
                                class="card card-table cardMain"
                                style={{ overflow: 'hidden' }}
                            >
                                <div class="tab-content p-0">
                                    <div action="#">
                                        <ul className="nav nav-tabs nav-tabs-bottom nav-justified navs">
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab1"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(1)}
                                                >
                                                    Pending
                                                </a>
                                                <span
                                                    className={
                                                        tab
                                                            ? 'bread active'
                                                            : 'bread'
                                                    }
                                                    style={{
                                                        marginLeft: '16%',
                                                    }}
                                                ></span>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab1
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab1"
                                                    data-bs-toggle="2"
                                                    onClick={(e) =>
                                                        setTab1(!tab1)
                                                    }
                                                >
                                                    Accepted
                                                </a>
                                                <span
                                                    className={
                                                        tab1
                                                            ? 'bread active'
                                                            : 'bread'
                                                    }
                                                    style={{
                                                        marginLeft: '16%',
                                                    }}
                                                ></span>
                                            </li>
                                            {/* <li class="nav-item">
                                    <a class={tab === 3 ? "nav-link active" : "nav-link"} href="#bottom-justified-tab3" data-bs-toggle="tab" onClick={(e) => setTab(3)}>Accepted</a>
                                    <span className={tab === 3 ? "bread active" : "bread"} style={{ marginLeft: '16%' }}></span>
                                 </li> */}
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab2
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab4"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) =>
                                                        handleTab1('buy')
                                                    }
                                                >
                                                    Buy
                                                </a>
                                                <span
                                                    className={
                                                        tab2
                                                            ? 'bread active'
                                                            : 'bread'
                                                    }
                                                    style={{
                                                        marginLeft: '16%',
                                                    }}
                                                ></span>
                                            </li>
                                            {/* <li class="nav-item">
                                 <a class={tab === 5 ? "nav-link active" : "nav-link"} href="#bottom-justified-tab5" data-bs-toggle="tab" onClick={(e) => setTab(5)}>Ready</a>
                                 <span className={tab === 5 ? "bread active" : "bread"} style={{ marginLeft: '16%' }}></span>
                              </li> */}
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab3
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab6"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(5)}
                                                >
                                                    Completed
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xl-8 col-md-8">
                                    <div class="card">
                                        <div class="card-body p_7">
                                            <form>
                                                <div class="row form-group">
                                                    <div class="col-md-12">
                                                        {/* Table     */}
                                                        {!editProducts ? (
                                                            <>
                                                                <div class="card-body card-body_1">
                                                                    <h5 class="card-title d-flex justify-content-between store_edit">
                                                                        <span></span>
                                                                        <button
                                                                            href="#"
                                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                                            style={{
                                                                                padding:
                                                                                    '0rem 0.75rem',
                                                                            }}
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.preventDefault()
                                                                                setEditProducts(
                                                                                    true
                                                                                )
                                                                            }}
                                                                        >
                                                                            <span>
                                                                                <u>
                                                                                    Edit
                                                                                    Items
                                                                                </u>
                                                                            </span>
                                                                        </button>
                                                                    </h5>
                                                                    <ul class="list-unstyled mb-0 store_list">
                                                                        <div className="row">
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Crop
                                                                                        Type
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        Agriculture{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            {/* <div className="col-md-4 mb-4">
                                                         <li class="pt-2 pb-0">
                                                            <small className="text-dark">Crop Type</small>
                                                         </li>
                                                         <li className="ml-2">
                                                            <span> {Details.cropType} </span>
                                                         </li>
                                                      </div> */}

                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Crop
                                                                                        Name
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        {
                                                                                            Details.crop_name
                                                                                        }
                                                                                    </span>
                                                                                </li>
                                                                            </div>

                                                                            <div class="col-md-3">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Crop
                                                                                        Image
                                                                                    </small>
                                                                                </li>
                                                                                <li>
                                                                                    <div
                                                                                        style={
                                                                                            thumb
                                                                                        }
                                                                                        sx={{
                                                                                            backgroundColor:
                                                                                                'grey',
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            style={
                                                                                                thumbInner
                                                                                            }
                                                                                        >
                                                                                            <img
                                                                                                src={
                                                                                                    Details.crop_image
                                                                                                }
                                                                                                alt=""
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Unit
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        {
                                                                                            Details.unit
                                                                                        }
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Quantity
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        {
                                                                                            Details.quantity
                                                                                        }{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Price
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        {
                                                                                            Details.price
                                                                                        }{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Total
                                                                                        Price
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        Rs.{' '}
                                                                                        {Number(
                                                                                            Details.userAmount
                                                                                        ) }
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Delivery
                                                                                        Date
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {Details &&
                                                                                            Details.availability_date &&
                                                                                            Details.availability_date.slice(
                                                                                                0,
                                                                                                10
                                                                                            )}{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            {/* <div className="col-md-4 mb-4">
                                                         <li class="pt-2 pb-0">
                                                            <small className="text-dark">Time</small>
                                                         </li>
                                                         <li className="ml-2">
                                                            <span> {Details &&Details.availabilityDate&& Details.availabilityDate.slice(11, 19)} </span>
                                                         </li>
                                                      </div> */}
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        No
                                                                                        Of
                                                                                        Days
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        {
                                                                                            Details.availability_days
                                                                                        }{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Description
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        {
                                                                                            Details.remark
                                                                                        }{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        {' '}
                                                                                        Crop
                                                                                        Images
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    {/* <img src={Dfetails.photoUrl} height={'70px'} style={{ width: '40%' }} alt="" /> */}

                                                                                    {/* <span> {Details.photoUrl} </span> */}
                                                                                </li>

                                                                                <div className="col-md-20 mb-4">
                                                                                    <div className="row">
                                                                                        {Details &&
                                                                                            Details.images &&
                                                                                            Details.images.map(
                                                                                                (
                                                                                                    val
                                                                                                ) => {
                                                                                                    //   console.log("val",val)
                                                                                                    return (
                                                                                                        <div className="col-4">
                                                                                                            {/* <div style={thumb} sx={{ backgroundColor: 'grey' }}>
                                                                  <div style={thumbInner}> */}
                                                                                                            <img
                                                                                                                src={
                                                                                                                    val
                                                                                                                }
                                                                                                                alt=""
                                                                                                                height={
                                                                                                                    '100px'
                                                                                                                }
                                                                                                                style={{
                                                                                                                    width: '100%',
                                                                                                                    marginTop:
                                                                                                                        '22%',
                                                                                                                }}
                                                                                                            />
                                                                                                            {/* </div>
                                                               </div> */}
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                            )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ul>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div class="card-body card-body_1">
                                                                    <h5 class="card-title d-flex justify-content-between store_edit">
                                                                        <ul class="list-unstyled mb-0 store_list">
                                                                            <li class="pt-2 pb-0">
                                                                                <span class="text-dark">
                                                                                    Update
                                                                                    Info
                                                                                </span>
                                                                            </li>
                                                                        </ul>
                                                                        <button
                                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                                            style={{
                                                                                padding:
                                                                                    '0rem 0.75rem',
                                                                            }}
                                                                        >
                                                                            <span
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    e.preventDefault()
                                                                                    setEditProducts(
                                                                                        false
                                                                                    )
                                                                                }}
                                                                            >
                                                                                Cancel
                                                                            </span>
                                                                        </button>
                                                                    </h5>
                                                                    <ul
                                                                        class="list-unstyled mb-0 store_list"
                                                                        onSubmit={(
                                                                            e
                                                                        ) =>
                                                                            e.preventDefault()
                                                                        }
                                                                    >
                                                                        <div className="mt-3">
                                                                            <div class="row">
                                                                                {console.log(
                                                                                    'sellCrop',
                                                                                    sellCrop,
                                                                                    CropNames
                                                                                )}
                                                                                <div class="col-md-4">
                                                                                    <FormControl
                                                                                        variant="standard"
                                                                                        fullWidth
                                                                                        className="mt-3"
                                                                                    >
                                                                                        {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Booking Mode</InputLabel> */}
                                                                                        <Select
                                                                                            style={{
                                                                                                width: '270px',
                                                                                                height: '40px',
                                                                                            }}
                                                                                            placeholder={
                                                                                                'Select Crop Type'
                                                                                            }
                                                                                            value={
                                                                                                cropType
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                updatechangecrop(
                                                                                                    e
                                                                                                )
                                                                                            }
                                                                                            //   onChange={(e)=>setUpdateDetails({...UpdateDetails, cropType: e.value})}

                                                                                            options={
                                                                                                vehicleOptiom
                                                                                            }
                                                                                        />
                                                                                    </FormControl>
                                                                                </div>

                                                                                <div class="col-md-4">
                                                                                    <FormControl
                                                                                        variant="standard"
                                                                                        fullWidth
                                                                                        className="mt-3"
                                                                                    >
                                                                                        {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Booking Mode</InputLabel> */}
                                                                                        <Select
                                                                                            style={{
                                                                                                width: '270px',
                                                                                                height: '40px',
                                                                                            }}
                                                                                            placeholder={
                                                                                                'Select CropName'
                                                                                            }
                                                                                            value={
                                                                                                CropNames
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                updatechangecropname(
                                                                                                    e
                                                                                                )
                                                                                            }
                                                                                            // onChange={(e)=>setUpdateDetails({...UpdateDetails, cropName: e.value})}
                                                                                            options={
                                                                                                typeoptiob
                                                                                            }
                                                                                        />
                                                                                    </FormControl>
                                                                                </div>
                                                                                <div class="col-md-3">
                                                                                    <li class="pt-2 pb-0">
                                                                                        <small className="text-dark">
                                                                                            Crop
                                                                                            Image
                                                                                        </small>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div
                                                                                            style={
                                                                                                thumb
                                                                                            }
                                                                                            sx={{
                                                                                                backgroundColor:
                                                                                                    'grey',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                style={
                                                                                                    thumbInner
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        Details.cropTypeImage
                                                                                                    }
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                </div>
                                                                                <div class="col-md-3">
                                                                                    <CssTextField
                                                                                        fullWidth
                                                                                        value={
                                                                                            UpdateDetails.variety
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setUpdateDetails(
                                                                                                {
                                                                                                    ...UpdateDetails,
                                                                                                    variety:
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                        id="crpVariety"
                                                                                        label="Variety"
                                                                                        variant="standard"
                                                                                    />
                                                                                </div>
                                                                                <div class="col-md-3">
                                                                                    <InputLabel
                                                                                        id="demo-simple-select-standard-label"
                                                                                        style={{
                                                                                            color: 'black',
                                                                                        }}
                                                                                    >
                                                                                        Delivery
                                                                                        Date
                                                                                    </InputLabel>
                                                                                    <CssTextField
                                                                                        fullWidth
                                                                                        type="date"
                                                                                        id="AvailDate"
                                                                                        className="mt-2"
                                                                                        label=""
                                                                                        variant="standard"
                                                                                        value={
                                                                                            UpdateDetails.availabilityDate
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setUpdateDetails(
                                                                                                {
                                                                                                    ...UpdateDetails,
                                                                                                    availabilityDate:
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div class="col-md-3">
                                                                                    <CssTextField
                                                                                        value={
                                                                                            UpdateDetails.availabilityDay
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setUpdateDetails(
                                                                                                {
                                                                                                    ...UpdateDetails,
                                                                                                    availabilityDay:
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                        fullWidth
                                                                                        type="number"
                                                                                        id="AvailDays"
                                                                                        label="Availability Days"
                                                                                        variant="standard"
                                                                                    />
                                                                                </div>
                                                                                <div class="col-md-3">
                                                                                    <CssTextField
                                                                                        value={
                                                                                            UpdateDetails.expectedQuailty
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setUpdateDetails(
                                                                                                {
                                                                                                    ...UpdateDetails,
                                                                                                    expectedQuailty:
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                        fullWidth
                                                                                        type="text"
                                                                                        id="expQuantity"
                                                                                        label="Expected Quantity"
                                                                                        variant="standard"
                                                                                    />
                                                                                </div>
                                                                                {/* <div class="col-md-3"> */}
                                                                                <div
                                                                                    class="col-md-3"
                                                                                    style={{
                                                                                        marginTop:
                                                                                            '2%',
                                                                                    }}
                                                                                >
                                                                                    <FormControl
                                                                                        variant="standard"
                                                                                        fullWidth
                                                                                        className="mt-3"
                                                                                    >
                                                                                        {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Booking Mode</InputLabel> */}
                                                                                        <Select
                                                                                            style={{
                                                                                                width: '270px',
                                                                                                height: '40px',
                                                                                            }}
                                                                                            placeholder={
                                                                                                'Select Unit'
                                                                                            }
                                                                                            value={
                                                                                                unitName
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                updatechangecropname1(
                                                                                                    e
                                                                                                )
                                                                                            }
                                                                                            //   onChange={(e)=>setUpdateDetails({...UpdateDetails, unit: e.value})}
                                                                                            options={
                                                                                                CropOptions
                                                                                            }
                                                                                        />
                                                                                    </FormControl>
                                                                                </div>

                                                                                <div class="col-md-3">
                                                                                    <CssTextField
                                                                                        value={
                                                                                            UpdateDetails.expectedPriceMax
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setUpdateDetails(
                                                                                                {
                                                                                                    ...UpdateDetails,
                                                                                                    expectedPriceMax:
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                        fullWidth
                                                                                        type="text"
                                                                                        id="expMax"
                                                                                        label="Price"
                                                                                        variant="standard"
                                                                                    />
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <CssTextField
                                                                                        value={
                                                                                            UpdateDetails.description
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setUpdateDetails(
                                                                                                {
                                                                                                    ...UpdateDetails,
                                                                                                    description:
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                        fullWidth
                                                                                        type="text"
                                                                                        id="description"
                                                                                        label="Description"
                                                                                        variant="standard"
                                                                                    />
                                                                                </div>
                                                                                {/* <div className="col-md-12 mt-3">
                                   <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>
                                        Upload Crop Photo: 
                                   </InputLabel>
                                   <div class="form-group row widget-3" style={{ margin: 'auto', display: 'flex', justifyContent: 'center'}}>
                                   <section className="container" style={baseStyle02}>
                                     <div {...getRootProps({className: 'dropzone'})}
									    style={{
									 	 borderRadius: '5px',
										 display: 'flex',
										 justifyContent: 'center',
										 alignItems: 'center',
                                         height: '100px'
									     }}
							    		  >
                                      <input {...getInputProps()} multiple={true}/>
										  	             {Files &&
										  	              <>
										  	               {Files.length === 0 && 
                                         <p align="center">Drop Image  here or click to upload.</p>
                                       }
										  	              </>
										  	             }
										  	             {Files && 
									      	            <>
                                     {Files.length > 0 && 
                                      <aside style={thumbsContainerState}>{PGthumbs}</aside>
                                      }
									      	            </>
									      	             }
                                   </div>
                                  </section>
                                  </div>

                                  
                                 
                                 
                                   </div> */}

                                                                                <div className="col-md-20 mb-4">
                                                                                    <div className="row">
                                                                                        {UpdateDetails &&
                                                                                            UpdateDetails.photoUrl &&
                                                                                            UpdateDetails.photoUrl.map(
                                                                                                (
                                                                                                    val
                                                                                                ) => {
                                                                                                    //   console.log("val",val)
                                                                                                    return (
                                                                                                        <div className="col-4">
                                                                                                            {/* <div style={thumb} sx={{ backgroundColor: 'grey' }}>
                                                                  <div style={thumbInner}> */}
                                                                                                            <img
                                                                                                                src={
                                                                                                                    val
                                                                                                                }
                                                                                                                alt=""
                                                                                                                height={
                                                                                                                    '100px'
                                                                                                                }
                                                                                                                style={{
                                                                                                                    width: '100%',
                                                                                                                    marginTop:
                                                                                                                        '22%',
                                                                                                                }}
                                                                                                            />
                                                                                                            {/* </div>
                                                               </div> */}
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                            )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="col-md-12 text-end mt-2 mb-2 mt-4">
                        <button type="submit" class=" w-10 btn btn-primary" onClick={()=>handleBook()}>Submit</button>
                      </div> */}
                                                                        </div>
                                                                    </ul>
                                                                    <div class="col-md-12 text-end mt-2 mb-2">
                                                                        <button
                                                                            class="btn btn-primary"
                                                                            onClick={(
                                                                                e
                                                                            ) =>
                                                                                handleBook(
                                                                                    e
                                                                                )
                                                                            }
                                                                        >
                                                                            Update
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <div class="card">
                                                <div class="card-body p_7">
                                                    <form>
                                                        <div class="row form-group">
                                                            <div class="col-md-12">
                                                                <div class="card-body card-body_1">
                                                                    <h5 class="card-title d-flex justify-content-between store_edit">
                                                                        <span>
                                                                            Assigned
                                                                            Trader
                                                                            Task
                                                                        </span>
                                                                        {/* <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                                                         <span>PENDING</span>
                                                      </a> */}
                                                                    </h5>
                                                                    <ul class="list-unstyled mb-0 store_list">
                                                                        <div className="row">
                                                                            <div className="col-md-3 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <CheckCircleIcon
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                            color: activeColor,
                                                                                        }}
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleActive(
                                                                                                'accepted'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <small className="text-secondary">
                                                                                        Accepted
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            {/* <div className="col-md-3 mb-4">
                                                            <li class="pt-2 pb-0">
                                                               <CheckCircleIcon style={{ marginRight: '5px', color: activeColor1 }} onClick={(e) => handleActive1('Confirmed')} /><small className="text-secondary">Confirmed</small>
                                                            </li>
                                                            <li className="ml-2">
                                                               <span> - </span>
                                                            </li>
                                                         </div> */}
                                                                            <div className="col-md-3 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <CheckCircleIcon
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                            color: activeColor2,
                                                                                        }}
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleActive2(
                                                                                                'buy'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <small className="text-secondary">
                                                                                        Buy
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-3 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <CheckCircleIcon
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                            color: activeColor3,
                                                                                        }}
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleActive3(
                                                                                                'completed'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <small className="text-secondary">
                                                                                        Completed
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            {/* <div className="col-md-4">
                                                         <li class="pt-2 pb-0">
                                                            <CheckCircleIcon style={{ marginRight: '5px' }} /><small className="text-secondary">Completed</small>
                                                         </li>
                                                         <li className="ml-2">
                                                            <span> - </span>
                                                         </li>
                                                      </div> */}
                                                                        </div>
                                                                    </ul>
                                                                    <hr
                                                                        style={{
                                                                            marginTop:
                                                                                '0px',
                                                                            marginBottom:
                                                                                '10px',
                                                                        }}
                                                                    />
                                                                    <hr />
                                                                    <div className="assigncancel">
                                                                        <h5>
                                                                            Driver
                                                                            Details
                                                                        </h5>
                                                                        <div
                                                                            style={{
                                                                                paddingLeft:
                                                                                    '300px',
                                                                            }}
                                                                        >
                                                                            {assignDriverData.length ? (
                                                                                <Button
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            '#60a132',
                                                                                        color: 'white',
                                                                                    }}
                                                                                    onClick={
                                                                                        handleCancelAssign
                                                                                    }
                                                                                >
                                                                                    Cancel
                                                                                    Assign
                                                                                </Button>
                                                                            ) : null}
                                                                        </div>
                                                                        <Dialog
                                                                            open={
                                                                                openAssign
                                                                            }
                                                                            onClose={() =>
                                                                                closeAssignDialog()
                                                                            }
                                                                            aria-labelledby="form-dialog-title"
                                                                        >
                                                                            <CancelAssignDialog
                                                                                bookingId={
                                                                                    id
                                                                                }
                                                                                type="sell"
                                                                                assignId={
                                                                                    assignDriverData
                                                                                }
                                                                            />
                                                                        </Dialog>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4"></div>
                                                                    </div>
                                                                    <a className="store-avatar">
                                                                        {assignDriverData.length ? null : (
                                                                            <div
                                                                                style={{
                                                                                    marginRight:
                                                                                        '10px',
                                                                                }}
                                                                                onClick={
                                                                                    handleClick
                                                                                }
                                                                            >
                                                                                <AddCircleOutlineIcon />
                                                                            </div>
                                                                        )}
                                                                        <div
                                                                            class="store_display"
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                                flexDirection:
                                                                                    'row',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '50px',
                                                                                    width: '25%',
                                                                                }}
                                                                            >
                                                                                <h4>
                                                                                    Name
                                                                                </h4>
                                                                                <span>
                                                                                    {assignDriverData.length
                                                                                        ? assignDriverData[0]
                                                                                              .name
                                                                                        : ''}
                                                                                </span>
                                                                            </div>

                                                                            <div
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '50px',
                                                                                    width: '25%',
                                                                                }}
                                                                            >
                                                                                <h4>
                                                                                    MobileNumber
                                                                                </h4>
                                                                                <span>
                                                                                    {assignDriverData.length
                                                                                        ? assignDriverData[0]
                                                                                              .mobile_number
                                                                                        : ''}
                                                                                </span>
                                                                            </div>

                                                                            <div
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '50px',
                                                                                }}
                                                                            >
                                                                                <h4>
                                                                                    District
                                                                                </h4>
                                                                                <span>
                                                                                    {assignDriverData.length
                                                                                        ? assignDriverData[0]
                                                                                              .district
                                                                                        : ''}
                                                                                </span>
                                                                            </div>
                                                                            <br />
                                                                            <div
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '50px',
                                                                                }}
                                                                            >
                                                                                <h4>
                                                                                    Trader
                                                                                    Id
                                                                                </h4>
                                                                                <span>
                                                                                    {assignDriverData.length
                                                                                        ? assignDriverData[0]
                                                                                              .trader_id
                                                                                        : ''}
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                        <Menu
                                                                            id="basic-menu"
                                                                            anchorEl={
                                                                                anchorEl
                                                                            }
                                                                            open={
                                                                                open
                                                                            }
                                                                            onClose={
                                                                                handleClose
                                                                            }
                                                                            MenuListProps={{
                                                                                'aria-labelledby':
                                                                                    'basic-button',
                                                                            }}
                                                                        >
                                                                            <MenuItem
                                                                                onClick={() =>
                                                                                    handleclickopens()
                                                                                }
                                                                            >
                                                                                Assign
                                                                                Manually
                                                                            </MenuItem>
                                                                            <MenuItem>
                                                                                Assign
                                                                                Automatically
                                                                            </MenuItem>
                                                                        </Menu>
                                                                        {opens ==
                                                                        true ? (
                                                                            <Dialog
                                                                                open={
                                                                                    opens
                                                                                }
                                                                                onClose={
                                                                                    handlecloses
                                                                                }
                                                                                style={{
                                                                                    zIndex: 100,
                                                                                }}
                                                                            >
                                                                                <DialogTitle>
                                                                                    <b>
                                                                                        Assign
                                                                                        Driver
                                                                                    </b>
                                                                                </DialogTitle>
                                                                                <DialogContent>
                                                                                    <div
                                                                                        style={{
                                                                                            display:
                                                                                                'flex',
                                                                                        }}
                                                                                    >
                                                                                        <div>
                                                                                            <BookingMap
                                                                                                center={{
                                                                                                    lat: lat,
                                                                                                    lng: long,
                                                                                                }}
                                                                                                height="10px"
                                                                                                zoom={
                                                                                                    15
                                                                                                }
                                                                                                booking={
                                                                                                    true
                                                                                                }
                                                                                                mapdata={
                                                                                                    mapdataPickup
                                                                                                }
                                                                                            />

                                                                                            <div
                                                                                                style={{
                                                                                                    display:
                                                                                                        'flex',
                                                                                                    width: '290px',
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    style={{
                                                                                                        margin: '2%',
                                                                                                        width: '200px',
                                                                                                    }}
                                                                                                >
                                                                                                    <FormControl
                                                                                                        variant="standard"
                                                                                                        fullWidth
                                                                                                        className="mt-4"
                                                                                                    >
                                                                                                        <Select
                                                                                                            onChange={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                searchDriver(
                                                                                                                    e
                                                                                                                )
                                                                                                            }
                                                                                                            placeholder={
                                                                                                                'Choose Category'
                                                                                                            }
                                                                                                            options={
                                                                                                                categoryoption
                                                                                                            }
                                                                                                        />
                                                                                                    </FormControl>
                                                                                                </div>
                                                                                                <div
                                                                                                    style={{
                                                                                                        margin: '2%',
                                                                                                        width: '200px',
                                                                                                    }}
                                                                                                >
                                                                                                    <FormControl
                                                                                                        variant="standard"
                                                                                                        fullWidth
                                                                                                        className="mt-4"
                                                                                                    >
                                                                                                        <Select
                                                                                                            onChange={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                setDriver(
                                                                                                                    e
                                                                                                                )
                                                                                                            }
                                                                                                            placeholder={
                                                                                                                'Search Driver'
                                                                                                            }
                                                                                                            options={
                                                                                                                driverList1
                                                                                                            }
                                                                                                        />
                                                                                                    </FormControl>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <br />
                                                                                        <div
                                                                                            style={{
                                                                                                minWidth:
                                                                                                    '150px',
                                                                                            }}
                                                                                        >
                                                                                            <div>
                                                                                                Or
                                                                                                Search
                                                                                                By
                                                                                                Phone
                                                                                                No.
                                                                                            </div>
                                                                                            <div class="col">
                                                                                                <CssTextField
                                                                                                    fullWidth
                                                                                                    id="standard-basic"
                                                                                                    label="Mobile No.*"
                                                                                                    variant="standard"
                                                                                                    name="lastName"
                                                                                                    onChange={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        fetchByphone(
                                                                                                            e
                                                                                                                .target
                                                                                                                .value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </div>

                                                                                            <div
                                                                                                class="row"
                                                                                                className="mt-4"
                                                                                            >
                                                                                                {drivers.map(
                                                                                                    (
                                                                                                        item,
                                                                                                        index
                                                                                                    ) => {
                                                                                                        return (
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    width: '260px',
                                                                                                                    height: '70px',
                                                                                                                    marginBottom:
                                                                                                                        '50px',
                                                                                                                }}
                                                                                                            >
                                                                                                                <div
                                                                                                                    class="card card-body bg-gray"
                                                                                                                    style={{
                                                                                                                        display:
                                                                                                                            'flex',
                                                                                                                        flexDirection:
                                                                                                                            'row',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <div>
                                                                                                                        <h5>
                                                                                                                            {item.first_name +
                                                                                                                                ' ' +
                                                                                                                                item.last_name}
                                                                                                                        </h5>

                                                                                                                        <div>
                                                                                                                            <a href="#">
                                                                                                                                <span>
                                                                                                                                    {
                                                                                                                                        item.mobile_number
                                                                                                                                    }
                                                                                                                                </span>
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <div
                                                                                                                            class="circle"
                                                                                                                            style={{
                                                                                                                                width: '30px',
                                                                                                                                height: '30px',
                                                                                                                                padding:
                                                                                                                                    '5px',
                                                                                                                                marginLeft:
                                                                                                                                    '60px',
                                                                                                                                borderRadius:
                                                                                                                                    '50%',
                                                                                                                                background:
                                                                                                                                    '#fff',
                                                                                                                                border: ' 3px solid #000',
                                                                                                                                color: '#000',
                                                                                                                                textAlign:
                                                                                                                                    'center',
                                                                                                                                font: '14px Arial, sans-serif',
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            0
                                                                                                                        </div>

                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                marginLeft:
                                                                                                                                    '60px',
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <a href="#">
                                                                                                                                <span>
                                                                                                                                    task
                                                                                                                                </span>
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                )}
                                                                                                <div
                                                                                                    className="mt-3"
                                                                                                    style={{
                                                                                                        fontSize:
                                                                                                            '23px',
                                                                                                    }}
                                                                                                >
                                                                                                    District:{' '}
                                                                                                    <span
                                                                                                        style={{
                                                                                                            color: 'green',
                                                                                                        }}
                                                                                                    >
                                                                                                        {' '}
                                                                                                        {
                                                                                                            District
                                                                                                        }
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            display:
                                                                                                'flex',
                                                                                            flexDirection:
                                                                                                'row',
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            style={{
                                                                                                paddingLeft:
                                                                                                    '300px',
                                                                                            }}
                                                                                        >
                                                                                            <Button
                                                                                                style={{
                                                                                                    backgroundColor:
                                                                                                        '#60a132',
                                                                                                    color: 'white',
                                                                                                }}
                                                                                                onClick={
                                                                                                    handlecloses
                                                                                                }
                                                                                            >
                                                                                                Cancel
                                                                                            </Button>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{
                                                                                                marginLeft:
                                                                                                    '30px',
                                                                                            }}
                                                                                        >
                                                                                            <Button
                                                                                                style={{
                                                                                                    backgroundColor:
                                                                                                        '#60a132',
                                                                                                    color: 'white',
                                                                                                }}
                                                                                                onClick={
                                                                                                    handleAssign
                                                                                                }
                                                                                            >
                                                                                                Assign
                                                                                                Task
                                                                                            </Button>
                                                                                        </div>
                                                                                    </div>
                                                                                </DialogContent>
                                                                            </Dialog>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </a>
                                                                    {/* <ul class="list-unstyled mb-0 store_list">
                                                   <div className="row">
                                                      <div className="col-md-4">
                                                         <li class="pt-2 pb-0">
                                                            <CheckCircleIcon style={{ marginRight: '5px' }} /><small className="text-secondary">Negotiate</small>
                                                         </li>
                                                         <li className="ml-2">
                                                            <span> - </span>
                                                         </li>
                                                      </div>
                                                      <div className="col-md-4">
                                                         <li class="pt-2 pb-0">
                                                            <CheckCircleIcon style={{ marginRight: '5px' }} /><small className="text-secondary">Confirmed Order</small>
                                                         </li>
                                                         <li className="ml-2">
                                                            <span> - </span>
                                                         </li>
                                                      </div>
                                                      <div className="col-md-4">
                                                         <li class="pt-2 pb-0">
                                                            <CheckCircleIcon style={{ marginRight: '5px' }} /><small className="text-secondary">Buy</small>
                                                         </li>
                                                         <li className="ml-2">
                                                            <span> - </span>
                                                         </li>
                                                      </div>
                                                      <div className="col-md-4">
                                                         <li class="pt-2 pb-0">
                                                            <CheckCircleIcon style={{ marginRight: '5px' }} /><small className="text-secondary">Completed</small>
                                                         </li>
                                                         <li className="ml-2">
                                                            <span> - </span>
                                                         </li>
                                                      </div>
                                                   </div>
                                                </ul> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12 col-sm-12">
                              <div class="card">
                                 <div class="card-body p_7">
                                    <form>
                                       <div class="row form-group">
                                          <div class="col-md-12">
                                             <div class="card-body card-body_1">
                                                <h5 class="card-title d-flex justify-content-between store_edit">
                                                   <span>Delivery Task</span>
                                                   <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                                                   </a>
                                                </h5>
                                                <ul class="list-unstyled mb-0 store_list">
                                                   <div className="row">
                                                      <div className="col-md-2 mb-4">
                                                         <li class="pt-2 pb-0">
                                                            <CheckCircleIcon style={{ marginRight: '5px' }} /><small className="text-secondary">Accepted</small>
                                                         </li>
                                                         <li className="ml-2">
                                                            <span> - </span>
                                                         </li>
                                                      </div>
                                                      <div className="col-md-2 mb-4">
                                                         <li class="pt-2 pb-0">
                                                            <CheckCircleIcon style={{ marginRight: '5px' }} /><small className="text-secondary">Reached</small>
                                                         </li>
                                                         <li className="ml-2">
                                                            <span> - </span>
                                                         </li>
                                                      </div>
                                                      <div className="col-md-2 mb-4">
                                                         <li class="pt-2 pb-0">
                                                            <CheckCircleIcon style={{ marginRight: '8px' }} /><small className="text-secondary">Picked</small>
                                                         </li>
                                                         <li className="ml-2">
                                                            <span> - </span>
                                                         </li>
                                                      </div>
                                                      <div className="col-md-2 mb-4">
                                                         <li class="pt-2 pb-0">
                                                            <CheckCircleIcon style={{ marginRight: '5px' }} /><small className="text-secondary">Arrived</small>
                                                         </li>
                                                         <li className="ml-2">
                                                            <span> - </span>
                                                         </li>
                                                      </div>
                                                      <div className="col-md-3 mb-4">
                                                         <li class="pt-2 pb-0">
                                                            <CheckCircleIcon style={{ marginRight: '5px' }} /><small className="text-secondary">Completed</small>
                                                         </li>
                                                         <li className="ml-2">
                                                            <span> - </span>
                                                         </li>
                                                      </div>
                                                   </div>
                                                </ul>
                                                <hr />
                                                <h5>Vehicle Details</h5>
                                                <a className="store-avatar">
                                                   <div style={{ marginRight: '10px' }} onClick={handleClick}><AddCircleOutlineIcon /></div>
                                                   <div class="store_display">
                                                      <h4>Rajsekar</h4>
                                                      <span style={{ marginTop: '5px' }}>No vehicle assigned yet</span>
                                                   </div>
                                                   <Menu
                                                      id="basic-menu"
                                                      anchorEl={anchorEl}
                                                      open={open}
                                                      onClose={handleClose}
                                                      MenuListProps={{
                                                         'aria-labelledby': 'basic-button',
                                                      }}
                                                   >
                                                      <MenuItem onClick={handleClose}>Assign Manually</MenuItem>
                                                      <MenuItem onClick={handleClose}>Assign Automatically</MenuItem>
                                                   </Menu>
                                                </a>
                                                <div class="row mt-3">
                                                   <div class="col">
                                                      <div class="timeline-steps aos-init aos-animate" data-aos="fade-up" style={{ flexWrap: 'nowrap' }}>
                                                         <div class="timeline-step">
                                                            <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                                                               <LocalShippingIcon />
                                                               <p class="h6 mt-2 mb-2">ID : KRS238612</p>
                                                               <p class="h6 text-muted mb-0 mb-lg-0">+91 8098081991</p>
                                                            </div>
                                                         </div>
                                                         <div class="timeline-step">
                                                            <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2004">
                                                               <SupervisedUserCircleIcon />
                                                               <p class="h6 mt-2 mb-2"></p>
                                                               <p class="h6 text-muted mb-0 mb-lg-0">Farmer</p>
                                                            </div>
                                                         </div>
                                                         <div class="timeline-step">
                                                            <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2005">
                                                               <AssessmentIcon />
                                                               <p class="h6 mt-2 mb-2"></p>
                                                               <p class="h6 text-muted mb-0 mb-lg-0">Trader</p>
                                                            </div>
                                                         </div>
                                                         <div class="timeline-step">
                                                            <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                                                               <RoomIcon />
                                                               <p class="h6 mt-2 mb-2">Sri Krishna Traders</p>
                                                               <p class="h6 text-muted mb-0 mb-lg-0"></p>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </form>
                                 </div>
                              </div>
                           </div> */}
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="card">
                                        {/* <div class="card-body">
                                 <h5 class="card-title d-flex justify-content-between store_edit">
                                    <span>Farmer Details</span>
                                    <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                                    </a>
                                 </h5>
                                 <a class="store-avatar">
                                    <div class="staffAvatar-root1">R</div>
                                    <div class="store_display">
                                       <h4>Rajsekar</h4>
                                       <span>8098081991</span>
                                    </div>
                                 </a>
                              </div> */}
                                        <hr
                                            width="85%"
                                            style={{ margin: 'auto' }}
                                        />
                                        <div class="card">
                                            <div class="card-body">
                                                <h5 class="card-title d-flex justify-content-between store_edit">
                                                    <span>Booking from</span>
                                                    <a
                                                        href="#"
                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                    ></a>
                                                </h5>
                                                <hr
                                                    style={{ margin: 'auto' }}
                                                />
                                                <div className="row mt-3">
                                                    <h6>Farmer</h6>
                                                    <p>
                                                        First Name:{' '}
                                                        {booking.first_name}
                                                    </p>
                                                    <p>
                                                        Last Name:{' '}
                                                        {booking.last_name}
                                                    </p>
                                                    <p>
                                                        Mobile:{' '}
                                                        {booking.mobile_number}
                                                    </p>
                                                    <p>
                                                        BookingId:{' '}
                                                        {localStorage.getItem(
                                                            'Bookingsellcropid'
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div class="card-body ">
                                 <h5 class="card-title d-flex justify-content-between store_edit">
                                    <span> Assigned Details</span>
                                    <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                                    </a>
                                 </h5>
                                 <a class="store-avatar">
                                    <div class="staffAvatar-root1">R</div>
                                    <div class="store_display">
                                       <h4>{assignDriverData.length ? assignDriverData[0].name : ""}</h4>
                                       <span>{assignDriverData.length ? assignDriverData[0].mobile_number : ""}</span>
                                    </div>
                                 </a>
                              </div> */}
                                        <hr
                                            width="85%"
                                            style={{ margin: 'auto' }}
                                        />
                                          <div class="card-body ">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Payment Details</span>
                                                <a
                                                    href="#"
                                                   
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                    style={{
                                                        padding: '0rem 0.75rem',
                                                    }}
                                                >
                                                    <span>
                                                        {paymentStatus ? (
                                                            <u>
                                                                {' '}
                                                                {transactionId}{transactionNotes}
                                                            </u>
                                                        ) : (
                                                            <u  onClick={() =>
                                                                setPaymentModal(
                                                                    !paymentModal
                                                                )
                                                            }>
                                                                Receive Payment
                                                            </u>
                                                        )}
                                                    </span>
                                                </a>
                                            </h5>
                                            <div className="col-md-4 mb-4">
                                                <small className="text-dark">
                                                    Amount:{' '}
                                                </small>
                                                <span>
                                                Rs.{' '}
                                                                                        {Number(
                                                                                            Details.userAmount
                                                                                        ) }
                                                </span>
                                            </div>
                                            <div className="">
                                                <small className="text-dark">
                                                    Payment Status:{' '}
                                                </small>
                                                <span>
                                                    {' '}
                                                    {Details?.payment_status
                                                        ? 'Received'
                                                        : 'Not Recevied'}{' '}
                                                </span>
                                            </div>
                                            <p>
                                                {paymentStatus
                                                    ? 'Payment Recieved  -'
                                                    : 'Pending Amount -'}
                                                <span>
                                                    Rs.{' '}
                                                    {
                                                           Number(
                                                            Details.userAmount
                                                        ) 
                                                    }
                                                </span>
                                            </p>
                                            <span>
                                                {paymentStatus ? (
                                                    <i>
                                                        {' '}
                                                        Payment Recieved via {Details?.payment
                                                            ?.cash_type || Details?.payment
                                                            ?.billing_type } 
                                                    </i>
                                                ) : (
                                                    <i>No transaction yet</i>
                                                )}
                                            </span>
                                            <br/>
                                            <p>
                                            <span>
                                            Date:    {Details?.payment
                                                            ?.date ? (
                                                    <i>
                                                        {' '}
                                                      {  new Date(Details?.payment
                                                            ?.date).toDateString()}
                                                    </i>
                                                ) :null}
                                            </span>
                                            </p>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Notes (0)</span>
                                                <a
                                                    href="#"
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                ></a>
                                            </h5>
                                            <hr style={{ margin: 'auto' }} />
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <CssTextField
                                                        fullWidth
                                                        id="totalLand"
                                                        variant="standard"
                                                        placeholder="Add a note...."
                                                        name="totalLand"
                                                        className="mb-0"
                                                    />
                                                </div>
                                                <div className="col-md-2">
                                                    <IconButton
                                                        color="success"
                                                        aria-label="add an alarm"
                                                        className="mt-3"
                                                    >
                                                        <SendIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Created By :</span>
                                            </h5>
                                            <ul class="list-unstyled mb-0 store_list">
                                                <li class="pt-2 pb-0">
                                                    <small class="text-dark">
                                                        Role :{' '}
                                                    </small>
                                                    <span>
                                                        {createdByData.role}
                                                    </span>
                                                </li>
                                                <li class="pt-2 pb-0">
                                                    <small class="text-dark">
                                                        ID :{' '}
                                                    </small>
                                                    <span>
                                                        {createdByData.id}
                                                    </span>
                                                </li>
                                                <li class="pt-2 pb-0">
                                                    <small class="text-dark">
                                                        Phone Number :{' '}
                                                    </small>
                                                    <span>
                                                        {
                                                            createdByData.mobile_number
                                                        }
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Booking from</span>
                                                <a
                                                    href="#"
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                ></a>
                                            </h5>
                                            <hr style={{ margin: 'auto' }} />
                                            <div className="row mt-3">
                                                <h6>ID: {booking.id}</h6>
                                            </div>
                                            <div className="row mt-3">
                                                <h6>Role: {booking.role}</h6>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div class="card">
                           <div class="card-body p_7">
                              <div class="row form-group">
                                 <div class="col-md-12">
                                    <div class="card-body card-body_1">
                                       <h5 class="card-title d-flex justify-content-between store_edit">
                                          <span>Order Summary</span>
                                       </h5>
                                       <ul class="list-unstyled mb-0 store_list">
                                          <li class="pt-2 pb-0">
                                             <small class="text-dark">Order Type </small>
                                          </li>
                                          <li>
                                             <span>Home Delivery</span>
                                          </li>
                                          <li class="pt-2 pb-0">
                                             <small class="text-dark">Delivery Type </small>
                                          </li>
                                          <li>
                                             <span>Scheduled</span>
                                          </li>
                                          <li class="pt-2 pb-0">
                                             <small class="text-dark">Delivery Time </small>
                                          </li>
                                          <li>
                                             <span>Nov 14, 8:00 AM - Nov 14, 9:00 AM</span>
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SellCropDB
