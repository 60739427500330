import React, { useState, useEffect } from 'react'
import Bookings from '../Bookings/Bookings'
import { API } from '../../API/index'
import CreateFarmer from '../Create Modules/CreateFarmer/CreateFarmer'
import InputSuppliers from '../Create Modules/CreateInputSupplier/InputSuppliers'
import MicroEnt from '../Create Modules/CreateMicroEnt/MicroEntrepreneurs'
import AgriMachine from '../Create Modules/CreateAgriMachine/AgriMachine'
import AgriTransport from '../Create Modules/CreateAgriTransport/AgriTransport'
import Livestock from '../Create Modules/CreateLivestock/Livestock'
import FPO from '../Create Modules/CreateFPO/CreateFPO'
import FarmerTable from '../Create Modules/CreateFarmer/FarmerTable'
import Trader from '../Create Modules/CreateTrader/CreateTrader'
import '../../App.css'

function Farmers(props) {
    const [booking, setBooking] = useState(false)
    const [create, setCreate] = useState(0)
    const [farmers, setFarmers] = useState([])
    const [searchdata, setsearchdata] = useState('')
    console.log(farmers)

    useEffect(() => {
        //   getAllFarmers();
    }, [])

    const getAllFarmers = async () => {
        const { data } = await API.get(`/getFarmer?type=0`)
        setFarmers(data?.result)
    }
    const handleMobilesearch = (e) => {
        console.log('e', e.target.value)
        setsearchdata(e.target.value)
        if (e.target.value.length == 10) {
            mobilechecksearch(e.target.value)
        } else {
            setFarmers([])
        }
    }
    const mobilechecksearch = async (number) => {
        const { data } = await API.get(`/searchFarmer?search=` + number)
        console.log('data', data)
        if (data.success == true) {
            let arr = []
            arr.push(data?.result)
            setFarmers(arr)
        } else {
        }
    }
    const handelchange = (e) => {
        setCreate(e.target.value)
        setFarmers([])
        setsearchdata('')
    }
    return (
        <div class="content container-fluid">
            {/* <div class="page-header">
      <div class="row align-items-center">
         <div class="col">
            <h3 class="page-title">Create & Bookings</h3>
            <ul class="breadcrumb">
               <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
               <li class="breadcrumb-item active">Farmer</li>
            </ul>
         </div>
         <div class="col-auto">
            <a class="btn btn-primary filter-btn" id="filter_search" onClick={(e => setBooking(false))}>
            	<i class="fas fa-plus"></i> <span>  Create</span>
            </a>           
         </div>
         {booking === false && 
          <div class="col-auto">
            <a class="btn btn-primary filter-btn" onClick={(e => setBooking(true))}>
            	<i class="fas fa-plus"></i> <span> Booking</span>
            </a>           
          </div>
         }
      </div>
   </div> */}
            <div class="card-body pb-0">
                <div class="row">
                    <div class="col-md-4 select2-container">
                        <div className="form-group mb-4" data-select2-id="11">
                            <label>Create</label>
                            <select
                                required=""
                                tabindex="-1"
                                class="mdl-textfield__input"
                                type="text"
                                id="sample24"
                                name="stateName"
                                value={create}
                                onChange={(e) => handelchange(e)}
                                //  onChange={(e) => setCreate(e.target.value)}
                            >
                                <option>Select Option</option>
                                {props && props.val == 'FPO' ? (
                                    <>
                                        <option value="1">Farmer</option>
                                        <option value="2">
                                            Input Supplier
                                        </option>
                                        <option value="3">Agri Machine</option>
                                        <option value="4">
                                            Agri Transport
                                        </option>
                                        <option value="5">Livestock</option>
                                        {/* <option value="6">Micro EntrePreneurs</option> */}
                                    </>
                                ) : (
                                    <>
                                        <option value="1">Farmer</option>
                                        <option value="2">
                                            Input Supplier
                                        </option>
                                        <option value="3">Agri Machine</option>
                                        <option value="4">
                                            Agri Transport
                                        </option>
                                        <option value="5">Livestock</option>
                                        {/* <option value="6">Micro EntrePreneurs</option> */}
                                        <option value="7">FPO</option>
                                    </>
                                )}
                                {/* <option value="1">Farmer</option>
               <option value="2">Input Supplier</option>
               <option value="3">Agri Machine</option>
               <option value="4">Agri Transport</option>
               <option value="5">Livestock</option>
              
               <option value="7">FPO</option> */}
                                {/* <option value="8">Trader</option> */}
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-2">
                        <div class="form-group">
                            {/* <button type="submit" class="btn btn-primary mt-26">Submit</button> */}
                        </div>
                    </div>
                </div>
            </div>
            {booking === false && (
                <div id="filter_inputs" class="card filter-card ">
                    <div class="card-body pb-0">
                        <div class="row">
                            <div class="col-md-4 select2-container">
                                <div
                                    className="form-group mb-4"
                                    data-select2-id="11"
                                >
                                    <label>Create</label>
                                    <select
                                        required=""
                                        tabindex="-1"
                                        class="mdl-textfield__input"
                                        type="text"
                                        id="sample24"
                                        name="stateName"
                                        value={create}
                                        onChange={(e) => handelchange(e)}
                                        //  onChange={(e) => setCreate(e.target.value)}
                                    >
                                        <option>Select Option</option>
                                        <option value="1">Farmer</option>
                                        <option value="2">
                                            Input Supplier
                                        </option>
                                        <option value="3">Agri Machine</option>
                                        <option value="4">
                                            Agri Transport
                                        </option>
                                        <option value="5">Livestock</option>
                                        <option value="6">
                                            Micro EntrePreneurs
                                        </option>
                                        <option value="7">FPO</option>
                                        <option value="8">Trader</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-2 col-md-2">
                                <div class="form-group">
                                    {/* <button type="submit" class="btn btn-primary mt-26">Submit</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {booking === false && create === '1' && (
                <CreateFarmer
                    booking={booking}
                    create={create}
                    getFarmers={getAllFarmers}
                />
            )}

            {booking === false && create === '2' && <InputSuppliers />}
            {booking === false && create === '3' && <AgriMachine />}
            {booking === false && create === '4' && <AgriTransport />}
            {booking === false && create === '5' && <Livestock />}
            {booking === false && create === '6' && <MicroEnt />}
            {booking === false && create === '7' && <FPO />}
            {booking === false && create === '8' && <Trader />}

            {/* {booking === true && <Bookings />} */}

            {create < 2 && booking === false && (
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card card-table">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <div
                                        id="DataTables_Table_0_wrapper"
                                        class="dataTables_wrapper dt-bootstrap4 no-footer"
                                    >
                                        <div class="row">
                                            <div class="col-sm-12 col-md-6">
                                                <h3 className="mt-3">
                                                    All Farmers
                                                </h3>
                                            </div>
                                            <div class="col-sm-12 col-md-3 position_right">
                                                <div class="form-group">
                                                    <input
                                                        type="text"
                                                        value={searchdata}
                                                        class="form-control"
                                                        placeholder="Search"
                                                        maxLength={'10'}
                                                        onChange={(e) =>
                                                            handleMobilesearch(
                                                                e
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <FarmerTable farmers={farmers} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
export default Farmers
