import React, { useState, useEffect } from 'react'
import { CssTextField } from '../Styles'
import IconButton from '@mui/material/IconButton'
import RoomIcon from '@mui/icons-material/Room'
import SendIcon from '@mui/icons-material/Send'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import './Styles.css'
import { thumb, img, thumbInner } from '../Styles'
import FormControl from '@mui/material/FormControl'
// import Select from '@mui/material/Select';
import Select from 'react-select'
import { API } from '../../API'
import Swal from 'sweetalert2'

import Dialog from '@mui/material/Dialog'
import { Button } from 'antd'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import BookingMap from '../Create/bookingMap'
import { DialogContent, DialogTitle, Drawer } from '@mui/material'
import 'react-datepicker/dist/react-datepicker.css'

import AgriTransportInvoice from './AgriTransportInvoice'
import CancelAssignDialog from './cancelAssign'

const AgriTransportBD = () => {
    const [opens, setOpens] = React.useState(false)
    const [paymentModal, setPaymentModal] = useState(false)

    const [tab, setTab] = useState(1)
    const [editProducts, setEditProducts] = useState(false)
    const [paymentMode,setPaymentMode]=useState("")

    const [quantity, setQuantity] = useState(0)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const [invoiceopen, setInvoiceOpen] = React.useState(false)
    const [Details, setDetails] = useState({})
    const [DetailsInput, setDetailsInput] = useState({})
    const [TotalPrice, setTotalPrice] = useState(0)
    const [updatedvalue, setUpdatedvalue] = useState({})
    const [bookingMode, setbookingMode] = useState('')
    const [billingType, setbillingType] = useState('')
    const [driverList, setDriverList] = useState([])
    const [driverList1, setDriverList1] = useState([])
    const [drivers, setDrivers] = useState([])
    const [assignData, setAssignedData] = useState([])
    const [assignDriverData, setAssignedDriverData] = useState([])
    const [booking, setBooking] = useState({})
    const [details1, setDetails1] = useState({})
    const [District, setDistrict] = useState('')
    const [tab1, setTab1] = useState(false)
    const [tab2, setTab2] = useState(false)
    const [tab3, setTab3] = useState(false)
    const [tab4, setTab4] = useState(false)
    const [tab5, setTab5] = useState(false)
    const [paymentStatus, setPaymentStatus] = useState(false)
    const [activeColor, setActiveColor] = useState('')
    const [activeColor1, setActiveColor1] = useState('')
    const [activeColor2, setActiveColor2] = useState('')
    const [activeColor3, setActiveColor3] = useState('')
    const [activeColor4, setActiveColor4] = useState('')
    const [orderId, setOrderId] = useState([])
    const [invoiceId, setInvoiceId] = useState('')
    const [createdByData, setCreatedByData] = useState({})
    const [transactionId, setTransactionId] = useState('')
    const [lat, setLat] = useState(18.5204)
    const [long, setLong] = useState(73.8567)
    const location = useLocation()
    const [openAssign, setOpensAssign] = useState(false)
    const [cancelopen, setCancelOpen] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [description,setDescription]=useState('')
    const [disabled, setDisabled] = useState(false)
    const [transactionNotes,setTransactionNotes]=useState("")
    const [taxes,setTaxes]=useState()

    useEffect(async () => {
        getvalue()
        getAssignedDriver()
        getPaymentDetails()
        // getTaxes()
    }, [])
    const PaymentOptions = [
        {
            label: 'Online',
            value: 'Online',
        },
        {
            label: 'Cash',
            value: 'Cash',
        },
    ]

    const getTaxes=()=>{
        const token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/invoice-taxes/vehicle`,
                config
            )
            .then((res) => {
                setTaxes(res.data.data[0])
               
            })
    }
    const getvalue = async () => {
        const u = location.pathname
        const orderId = u.split('/')[2]
        setOrderId(orderId)

        const token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/vehicle/${orderId}`,
                config
            )
            .then((res) => {
                console.log('machining data: ', res.data.vehicle)
                if (res.data.vehicle.cancelled) setDisabled(true)
                if (res.data.vehicle.taxes) setTaxes(res.data.vehicle.taxes)

                setDetails1(res.data.vehicle)
                if (res.data.vehicle.invoiceId) {
                    setInvoiceId(res.data.vehicle.invoiceId)
                }
                if (res.data.vehicle.status === 'Accepted') {
                    setActiveColor('green')
                }

                if (res.data.vehicle.status === 'Reached') {
                    setActiveColor('green')
                    setActiveColor1('green')
                    setActiveColor2('green')
                    setTab1(true)
                    setTab2(true)
                    setTab3(true)
                }
                if (res.data.vehicle.status === 'Start') {
                    setActiveColor('green')
                    setActiveColor1('green')
                    setActiveColor2('green')
                    setTab1(true)
                    setTab1(true)
                    setTab2(true)
                    setTab3(true)
                    setTab4(true)
                }
                if (res.data.vehicle.status === 'End') {
                    setActiveColor('green')
                    setActiveColor1('green')
                    setActiveColor2('green')
                    setActiveColor3('green')
                    setActiveColor4('green')

                    setTab1(true)
                    setTab2(true)
                    setTab3(true)
                    setTab4(true)
                    setTab5(true)
                }
                if (res.data.vehicle.payment_status){
                    setPaymentStatus(true)
                    setTransactionNotes(res.data.vehicle.payment.transaction_notes)

                }
                if (res.data.vehicle.transactionId){
                    setPaymentStatus(true)
                    setTransactionId(res.data.vehicle.transactionId)

                }
                const user = {
                    userId: res.data.vehicle.user_id,
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-user`,
                        user,
                        config
                    )
                    .then((res) => setBooking(res.data.user[0]))

                const createdBy = {
                    userId: res.data.vehicle.createdById,
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-user`,
                        createdBy,
                        config
                    )
                    .then((res) => {
                        if (res.data.user.length) {
                            const { id, role, mobile_number } = res.data.user[0]
                            setCreatedByData({
                                id,
                                role,
                                mobile_number,
                            })
                        } else {
                            return
                        }
                    })
            })
    }

    const getPaymentDetails = () => {
        const u = location.pathname
        const orderId = u.split('/')[2]
        const token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-payment/${orderId}`,
                config
            )
            .then((res) => {
                console.log(res)
                if (res.data.paymentStatus) {
                    setPaymentStatus(true)
                    setTransactionId(res.data.orderId)
                }
            })
    }
    const getAssignedDriver = () => {
        const u = location.pathname
        const orderId = u.split('/')[2]
        const token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-assigned-driver/${orderId}`,
                config
            )
            .then((res) => {
                console.log('res', res.data)
                setAssignedDriverData(res.data.drivers)
                console.log('assignedDriverData', assignDriverData)
            })
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setOpens(true)
    }

    const handleCancelAssign = () => {
        console.log('ress')
        setOpensAssign(true)
    }
    const closeAssignDialog = () => {
        setOpensAssign(false)
    }

    const inputval = async (val) => {
        const { data } = await API.get(
            `/getAgriTransportByNumber?mobileNumber=` + val
        )

        setDetailsInput(data?.result)
    }
    const BillingTypevalue = [
        {
            label: 'CASH',
            value: 'CASH',
        },
        {
            label: 'ONLINE',
            value: 'ONLINE',
        },
    ]
    const BookingTypeOptiom = [
        {
            value: 'BookNow',
            label: 'BookNow',
        },
        {
            value: 'Schedule',
            label: 'Schedule',
        },
    ]

    const BookingModeOptiom = [
        {
            value: 'Fixed',
            label: 'Fixed',
        },
        {
            value: 'Per KG',
            label: 'Per KG',
        },
        {
            value: 'Per Tonne',
            label: 'Per Tonne',
        },
        {
            value: 'Per KM',
            label: 'Per KM',
        },
        {
            value: 'Per Day',
            label: 'Per Day',
        },
        {
            value: 'Per Hour',
            label: 'Per Hour',
        },
        {
            value: 'Per Litre',
            label: 'Per Litre',
        },
        {
            value: 'Per Bag',
            label: 'Per Bag',
        },
    ]

    const handleBook = async (e) => {
        e.preventDefault()
        console.log('handleChange', updatedvalue)
        let inputBook = { ...updatedvalue, id: updatedvalue._id }
        try {
            const { data } = await API.post('/updateTransport', inputBook)
            if (data?.success) {
                getvalue()
                setEditProducts(false)
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })

                console.log(data)
                //  setSellCrop(SellCropClear);
                //  setDocImg(null)
                //  setFiles([])
                //  getSellCropBooking()
                // setCropTypes([]);
            } else {
                getvalue()
                setEditProducts(false)
                //   setSellCrop(SellCropClear);
                //  setDocImg(null)
                //  setFiles([])
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            getvalue()
            setEditProducts(false)
            // setSellCrop(SellCropClear);
            // setDocImg(null)
            // setFiles([])
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const categoryoption = [
        { value: '', label: 'Select Category' },
        { value: 'agri-machine', label: 'AGRI MACHINE' },
        { value: 'agri-transport', label: 'AGRI TRANSPORT' },
        { value: 'trader', label: 'TRADER' },
        { value: 'micro-entrepreneur', label: 'MIC ENTREPRENEUR' },
    ]
    const handletransportchange1 = (e) => {
        setUpdatedvalue({ ...updatedvalue, bookingMode: e.value })
        setbookingMode({
            value: e.value,
            label: e.value,
        })
    }
    const handletransportchange = (e) => {
        setUpdatedvalue({ ...updatedvalue, bookingMode: e.value })
        setbillingType({
            value: e.value,
            label: e.value,
        })
    }

    const handleclickopens = () => {
        console.log('truepopup')
        setOpens(!opens)
    }

    const handlecloses = () => {
        setOpens(false)
        handleClose()
    }
    const bookingmodeoption = [
        { value: 'DELIVERY', label: 'DELIVERY' },
        { value: 'PICKUP', label: 'PICKUP' },
    ]
    const searchDriver = (e) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            role: e.value,
            district: District,
        }
        setAssignedData(data)

        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-drivers`,
                data,
                config
            )
            .then((res) => {
                console.log('res', res.data.data)
                setDriverList(res.data.mobile_numbers)
                var options = []
                res.data.mobile_numbers.map((item, ind) => {
                    options.push({
                        value: item.mobile_number,
                        label: item.mobile_number,
                    })
                })
                setDriverList1(options)
            })
        console.log(e.value, e)
    }

    const setDriver = (e) => {
        var details = driverList.filter((item) => item.mobile_number == e.value)
        setDrivers(details)
        setAssignedData({
            ...assignData,
            mobile_number: details[0].mobile_number,
            trader_id: details[0].id,
            user_id: details[0].user_id,
            name: details[0].first_name + ' ' + details[0].last_name,
        })
    }
    const mapdataPickup = (data) => {
        console.log(data)
        setDistrict(data.subDistrict)
    }

    const handleAssign = () => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const u = location.pathname
        const orderId = u.split('/')[2]
        var data = {
            ...assignData,
            bookingId: orderId,
            district: District,
            type: 'vehicle',
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/assign-driver`,
                data,
                config
            )
            .then((res) => {
                setActiveColor('green')
                setTab1(true)
                setTab2(true)
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    // setDocImg(null)
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            })
            .catch((err) => console.log(err))
        console.log(assignData)
        // var data = {
        //    bookingId: localStorage.getItem('Bookingsellcropid'),

        // }
    }

    const handleActive2 = (status) => {
        var token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            bookingId: localStorage.getItem('BookingTransportid'),
            status: status,
            type: 'vehicle',
        }

        console.log('handleActive')
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/set-booking-status`,
                data,
                config
            )
            .then((res) => {
                console.log('res', res)
                setActiveColor('green')
                setActiveColor1('green')
                setActiveColor2('green')
                setActiveColor3('')
                setTab1(true)
                setTab2(true)
                setTab3(true)
            })
            .catch((err) => {
                console.log('err', err)
                setActiveColor2('')
            })
    }
    const handleActive3 = (status) => {
        var token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            bookingId: localStorage.getItem('BookingTransportid'),
            status: status,
            type: 'vehicle',
        }

        console.log('handleActive')
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/set-booking-status`,
                data,
                config
            )
            .then((res) => {
                console.log('res', res)
                setActiveColor('green')
                setActiveColor1('green')
                setActiveColor2('green')
                setActiveColor3('green')
                setTab1(true)
                setTab2(true)
                setTab3(true)
                setTab4(true)
            })
            .catch((err) => {
                console.log('err', err)
                setActiveColor3('')
            })
    }
    const handleActive4 = (status) => {
        var token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            bookingId: localStorage.getItem('BookingTransportid'),
            status: status,
            type: 'vehicle',
        }

        console.log('handleActive')
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/set-booking-status`,
                data,
                config
            )
            .then((res) => {
                console.log('res', res)
                setActiveColor('green')
                setActiveColor1('green')
                setActiveColor2('green')
                setActiveColor3('green')
                setActiveColor4('green')
                setTab1(true)
                setTab2(true)
                setTab3(true)
                setTab4(true)
                setTab5(true)
            })
            .catch((err) => {
                console.log('err', err)
                setActiveColor3('')
            })

        var data2 = {
            bookingId: orderId,
            type: 'vehicle',
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/generate-invoice`,
                data2,
                config
            )
            .then((res) => {
                console.log('res', res)
                setInvoiceId(res.data.data.invoiceId)
                // setI("green")
            })
            .catch((err) => {
                console.log('err', err)
                // setI("")
            })
    }

    const fetchByphone = (value) => {
        const token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        if (value.length === 10) {
            var payload = {
                mobile_no: value,
            }
            axios
                .post(
                    'https://prod-api.krishione.com/farmer-function/register-details',
                    payload,
                    config
                )
                .then((res) => {
                    console.log(res.data)

                    setAssignedData({
                        ...assignData,
                        mobile_number: res.data.data.data.mobile_number,
                        trader_id: res.data.data.data.id,
                        name:
                            res.data.data.data.first_name +
                            ' ' +
                            res.data.data.data.last_name,
                    })
                    setDrivers([res.data.data.data])
                    setDriverList(res.data.data.data.mobile_numbers)
                    setDistrict(res.data.data.data.district)
                    setLat(res.data.data.data.coordinates.coordinates[1])
                    setLong(res.data.data.data.coordinates.coordinates[0])

                    var options = [
                        {
                            value: res.data.data.data.mobile_number,
                        },
                    ]
                    // res.data.map((item, ind) => {
                    //     options.push({
                    //         value: item.mobile_number,
                    //         label: item.mobile_number,
                    //     })
                    // })
                    setDriverList1(options)
                })
        } else {
            return
        }
    }

    const cancelBooking = () => {
        const token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const u = location.pathname
        const orderId = u.split('/')[2]
        setOrderId(orderId)

        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/cancel-booking`,
                {
                    bookingId: orderId,
                    type: 'vehicle',
                },
                config
            )
            .then((res) => setDisabled(true))
    }
    const handlePaymentStatus=(e)=>{
        const token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data={
            amount:details1.payment.indicate_order_value,
          user_id:details1.user_id,
          currency: "INR",
          orderId:orderId,
          quotationId:orderId,
          createdById:details1.createdById,
          assignById:details1.traderId,
          created_at: Date.now(),
          completed: false,
          isVisible: false,
          paymentStatus: "captured",
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/update-cashpayment`,
                {
                    wallet_data:data,
                    bookingId: orderId,
                    type: 'vehicle',
                    "billing_type": paymentMode.value,
                    "transaction_notes":description,
                    "date":startDate
                },
                config
            )
            .then((res) =>{
                setPaymentModal(false)
                alert("Payment Updated!")
            } )
    }

    return (
        <div class="content container-fluid">
            <div
                class="page-header"
                style={
                    disabled ? { pointerEvents: 'none', opacity: '0.4' } : {}
                }
            >
                <div class="row align-items-center">
                    <div class="col">
                        <h3 class="page-title">#{invoiceId}</h3>
                        <ul class="breadcrumb">
                            <li
                                style={{ cursor: 'pointer' }}
                                class="breadcrumb-item"
                            >
                                <a
                                    onClick={() => setInvoiceOpen(true)}
                                    style={{ color: 'green' }}
                                >
                                    <u>View Invoice</u>
                                </a>
                            </li>
                            <li
                                style={{ cursor: 'pointer' }}
                                class="breadcrumb-item"
                            >
                                <a
                                    onClick={() => cancelBooking()}
                                    style={{ color: 'green' }}
                                >
                                    <u>Cancel Booking</u>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <Dialog
                    fullWidth={'xl'}
                    maxWidth={'md'}
                    open={invoiceopen}
                    className='invoicetaxes'
                    onClose={() => setInvoiceOpen(false)}
                >
                    <AgriTransportInvoice
                        Details={details1}
                        trader={assignDriverData[0]}
                        booking={booking}
                        TotalPrice={TotalPrice}
                        taxes={taxes}
                    />
                </Dialog>
                <Drawer
                    open={paymentModal}
                    onClose={()=>setPaymentModal(false)}
                    anchor="right"

                >

<div
                        class="col-md-3"
                        style={{
                            marginTop: '2%',
                            width: 500, padding: 31,height: 500,
                        }}
                    >
                        <h3>Select Payment Mode</h3>
                        <FormControl
                            variant="standard"
                            fullWidth
                            className="mt-3"
                        >
                            {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Booking Mode</InputLabel> */}
                            <Select
                                style={{
                                    width: '270px',
                                    height: '40px',
                                }}
                                placeholder={'Select Payment Mode'}
                                value={paymentMode}
                                onChange={(e) => setPaymentMode(e)}
                                //   onChange={(e)=>setUpdateDetails({...UpdateDetails, unit: e.value})}
                                options={PaymentOptions}
                            />
                            <br/>
                            <label>Select Date</label>
<br/>
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                            <br/>
                             <label>Transaction Notes</label>
                                    <textarea
                                        class="form-control"
                                        rows="3"
                                        onChange={(e)=>setDescription(e.target.value)}
                                        cols="30"
                                     
                                    ></textarea>
                            <button variant="contained" onClick={(e)=>handlePaymentStatus(e)}
                                                            component="span"
                                                            color="success"  className="btn btn-primary mt-3">Submit</button>
                            
                        </FormControl>

                    </div>
                </Drawer>
                <div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div
                                class="card card-table cardMain"
                                style={{ overflow: 'hidden' }}
                            >
                                <div class="tab-content p-0">
                                    <div action="#">
                                        <ul className="nav nav-tabs nav-tabs-bottom nav-justified navs">
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab1
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab1"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(1)}
                                                >
                                                    Pending
                                                </a>
                                                <span
                                                    className={
                                                        tab1
                                                            ? 'bread active'
                                                            : 'bread'
                                                    }
                                                ></span>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab2
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab2"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(2)}
                                                >
                                                    Accepted
                                                </a>
                                                <span
                                                    className={
                                                        tab2
                                                            ? 'bread active'
                                                            : 'bread'
                                                    }
                                                ></span>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab3
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab3"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(3)}
                                                >
                                                    Reached
                                                </a>
                                                <span
                                                    className={
                                                        tab3
                                                            ? 'bread active'
                                                            : 'bread'
                                                    }
                                                ></span>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab4
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab4"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(4)}
                                                >
                                                    Start
                                                </a>
                                                <span
                                                    className={
                                                        tab4
                                                            ? 'bread active'
                                                            : 'bread'
                                                    }
                                                ></span>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab5
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab5"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(5)}
                                                >
                                                    End
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xl-8 col-md-8">
                                    <div class="card">
                                        <div class="card-body p_7">
                                            <form>
                                                <div class="row form-group">
                                                    <div class="col-md-12">
                                                        {/* Table     */}
                                                        {!editProducts ? (
                                                            <>
                                                                <div class="card-body card-body_1">
                                                                    <h5 class="card-title d-flex justify-content-between store_edit">
                                                                        <span></span>
                                                                        <button
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.preventDefault()
                                                                                setEditProducts(
                                                                                    true
                                                                                )
                                                                            }}
                                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                                            style={{
                                                                                padding:
                                                                                    '0rem 0.75rem',
                                                                            }}
                                                                        >
                                                                            <span>
                                                                                <u>
                                                                                    Edit
                                                                                    Items
                                                                                </u>
                                                                            </span>
                                                                        </button>
                                                                    </h5>
                                                                    <ul class="list-unstyled mb-0 store_list">
                                                                        <div className="row">
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        pickup
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        {
                                                                                            details1
                                                                                                ?.pick_up
                                                                                                ?.full_address
                                                                                        }{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-8 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Drop
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        {
                                                                                            details1
                                                                                                ?.drop_off
                                                                                                ?.full_address
                                                                                        }{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Crop
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <li class="pt-2 pb-0">
                                                                                        <small class="">
                                                                                            {' '}
                                                                                            {
                                                                                                details1
                                                                                                    ?.corp
                                                                                                    ?.name
                                                                                            }
                                                                                        </small>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div
                                                                                            style={
                                                                                                thumb
                                                                                            }
                                                                                            sx={{
                                                                                                backgroundColor:
                                                                                                    'grey',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                style={
                                                                                                    thumbInner
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        details1
                                                                                                            ?.corp
                                                                                                            ?.image
                                                                                                    }
                                                                                                    style={
                                                                                                        img
                                                                                                    }
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Vehicle
                                                                                        Type
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <li class="pt-2 pb-0">
                                                                                        <small class="">
                                                                                            {' '}
                                                                                            {
                                                                                                details1
                                                                                                    ?.vehicle
                                                                                                    ?.type_of_vehicle
                                                                                            }
                                                                                        </small>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div
                                                                                            style={
                                                                                                thumb
                                                                                            }
                                                                                            sx={{
                                                                                                backgroundColor:
                                                                                                    'grey',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                style={
                                                                                                    thumbInner
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        details1
                                                                                                            ?.vehicle
                                                                                                            ?.image
                                                                                                    }
                                                                                                    style={
                                                                                                        img
                                                                                                    }
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Vehicle
                                                                                        Body
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <li class="pt-2 pb-0">
                                                                                        <small class="">
                                                                                            {
                                                                                                details1
                                                                                                    ?.vehicle
                                                                                                    ?.body_type
                                                                                            }
                                                                                        </small>
                                                                                    </li>
                                                                                    {/* <li>
                                                               <div style={thumb} sx={{ backgroundColor: 'grey' }}>
                                                                  <div style={thumbInner}>
                                                                     <img src={""} style={img} alt="" />
                                                                  </div>
                                                               </div>
                                                            </li> */}
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Date
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        {
                                                                                            details1
                                                                                                ?.service_time
                                                                                                ?.date
                                                                                        }{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Time
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        {
                                                                                            details1
                                                                                                ?.service_time
                                                                                                ?.time
                                                                                        }{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Booking
                                                                                        Mode
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        {
                                                                                            details1?.booking_mode
                                                                                        }{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Billing
                                                                                        Type
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        {
                                                                                            details1
                                                                                                ?.payment
                                                                                                ?.billing_type
                                                                                        }{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Indicative
                                                                                        Order
                                                                                        Value
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        {
                                                                                            details1?.userAmount
                                                                                               
                                                                                        }{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Contact
                                                                                        person
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        {
                                                                                            details1?.contact
                                                                                        }{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                        </div>
                                                                    </ul>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div class="card-body card-body_1">
                                                                    <h5 class="card-title d-flex justify-content-between store_edit">
                                                                        <ul class="list-unstyled mb-0 store_list">
                                                                            <li class="pt-2 pb-0">
                                                                                <span class="text-dark">
                                                                                    Update
                                                                                    Info
                                                                                </span>
                                                                            </li>
                                                                        </ul>
                                                                        <button
                                                                            href="#"
                                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                                            style={{
                                                                                padding:
                                                                                    '0rem 0.75rem',
                                                                            }}
                                                                        >
                                                                            <span
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    e.preventDefault()
                                                                                    setEditProducts(
                                                                                        false
                                                                                    )
                                                                                }}
                                                                            >
                                                                                Cancel
                                                                            </span>
                                                                        </button>
                                                                    </h5>
                                                                    <ul class="list-unstyled mb-0 store_list">
                                                                        <div className="row">
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Pickup
                                                                                        Address
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        <CssTextField
                                                                                            fullWidth
                                                                                            type="text"
                                                                                            // value=""
                                                                                            label=""
                                                                                            name="pickupAddress"
                                                                                            variant="standard"
                                                                                            // name="totalLand"
                                                                                            className="mt-2"
                                                                                            value={
                                                                                                updatedvalue.pickupAddress
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setUpdatedvalue(
                                                                                                    {
                                                                                                        ...updatedvalue,
                                                                                                        pickupAddress:
                                                                                                            e
                                                                                                                .target
                                                                                                                .value,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Drop
                                                                                        Address
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        <CssTextField
                                                                                            fullWidth
                                                                                            type="text"
                                                                                            // value=""
                                                                                            label=""
                                                                                            variant="standard"
                                                                                            name="totalLand"
                                                                                            className="mt-2"
                                                                                            value={
                                                                                                updatedvalue.dropAddress
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setUpdatedvalue(
                                                                                                    {
                                                                                                        ...updatedvalue,
                                                                                                        dropAddress:
                                                                                                            e
                                                                                                                .target
                                                                                                                .value,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                            //  value={farmer.totalLand}
                                                                                            //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                                                                        />
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark"></small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span></span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Crop
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <li class="pt-2 pb-0">
                                                                                        <small class="">
                                                                                            {' '}
                                                                                            {
                                                                                                updatedvalue.crop
                                                                                            }
                                                                                        </small>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div
                                                                                            style={
                                                                                                thumb
                                                                                            }
                                                                                            sx={{
                                                                                                backgroundColor:
                                                                                                    'grey',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                style={
                                                                                                    thumbInner
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        updatedvalue.cropImage
                                                                                                    }
                                                                                                    style={
                                                                                                        img
                                                                                                    }
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Vehicle
                                                                                        Type
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <li class="pt-2 pb-0">
                                                                                        <small class="">
                                                                                            {' '}
                                                                                            {
                                                                                                updatedvalue.vehicleType
                                                                                            }
                                                                                        </small>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div
                                                                                            style={
                                                                                                thumb
                                                                                            }
                                                                                            sx={{
                                                                                                backgroundColor:
                                                                                                    'grey',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                style={
                                                                                                    thumbInner
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        updatedvalue.vehicleTypeImage
                                                                                                    }
                                                                                                    style={
                                                                                                        img
                                                                                                    }
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Vehicle
                                                                                        Body
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <li class="pt-2 pb-0">
                                                                                        <small class="">
                                                                                            {' '}
                                                                                            {
                                                                                                updatedvalue.bodyType
                                                                                            }
                                                                                        </small>
                                                                                    </li>
                                                                                    {/* <li>
                                                               <div style={thumb} sx={{ backgroundColor: 'grey' }}>
                                                                  <div style={thumbInner}>
                                                                     <img src={""} style={img} alt="" />
                                                                  </div>
                                                               </div>
                                                            </li> */}
                                                                                </li>
                                                                            </div>
                                                                            {/* <div className="col-md-4 mb-4">
                                                         <li class="pt-1 pb-1">
                                                            <small className="text-dark"></small>
                                                         </li>
                                                         <li className="ml-2">
                                                            <span></span>
                                                         </li>
                                                      </div> */}
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-1">
                                                                                    <small className="text-dark">
                                                                                        Date
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        <CssTextField
                                                                                            fullWidth
                                                                                            type="date"
                                                                                            // value=""
                                                                                            label=""
                                                                                            name="date"
                                                                                            variant="standard"
                                                                                            className="mt-2"
                                                                                            value={
                                                                                                updatedvalue.date
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setUpdatedvalue(
                                                                                                    {
                                                                                                        ...updatedvalue,
                                                                                                        date: e
                                                                                                            .target
                                                                                                            .value,
                                                                                                    }
                                                                                                )
                                                                                            }

                                                                                            //  value={farmer.totalLand}
                                                                                            //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                                                                        />
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-1">
                                                                                    <small className="text-dark">
                                                                                        Time{' '}
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        <CssTextField
                                                                                            fullWidth
                                                                                            type="time"
                                                                                            // value=""
                                                                                            label=""
                                                                                            name="time"
                                                                                            variant="standard"
                                                                                            // name="totalLand"
                                                                                            className="mt-2"
                                                                                            value={
                                                                                                updatedvalue.time
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setUpdatedvalue(
                                                                                                    {
                                                                                                        ...updatedvalue,
                                                                                                        time: e
                                                                                                            .target
                                                                                                            .value,
                                                                                                    }
                                                                                                )
                                                                                            }

                                                                                            //  value={farmer.totalLand}
                                                                                            //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                                                                        />
                                                                                    </span>
                                                                                </li>
                                                                            </div>

                                                                            <div
                                                                                class="col-md-4"
                                                                                style={{
                                                                                    marginTop:
                                                                                        '4%',
                                                                                }}
                                                                            >
                                                                                <FormControl
                                                                                    variant="standard"
                                                                                    fullWidth
                                                                                    className="mt-3"
                                                                                >
                                                                                    {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Booking Mode</InputLabel> */}
                                                                                    <Select
                                                                                        style={{
                                                                                            width: '270px',
                                                                                            height: '40px',
                                                                                        }}
                                                                                        placeholder={
                                                                                            'Booking Mode'
                                                                                        }
                                                                                        value={
                                                                                            bookingMode
                                                                                        }
                                                                                        //   onChange={(e)=>setUpdatedvalue({...updatedvalue, bookingMode: e.value})}
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handletransportchange1(
                                                                                                e
                                                                                            )
                                                                                        }
                                                                                        options={
                                                                                            BookingModeOptiom
                                                                                        }
                                                                                    />
                                                                                </FormControl>
                                                                            </div>

                                                                            <div
                                                                                class="col-md-4"
                                                                                style={{
                                                                                    marginTop:
                                                                                        '4%',
                                                                                }}
                                                                            >
                                                                                <FormControl
                                                                                    variant="standard"
                                                                                    fullWidth
                                                                                    className="mt-3"
                                                                                >
                                                                                    <Select
                                                                                        style={{
                                                                                            width: '270px',
                                                                                            height: '40px',
                                                                                        }}
                                                                                        //  onChange={(e)=>handlecategory(e)}
                                                                                        // onChange={(e)=>setmachine({...machine,unit:e.value})}
                                                                                        placeholder={
                                                                                            'Select the Billing Type'
                                                                                        }
                                                                                        value={
                                                                                            billingType
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handletransportchange(
                                                                                                e
                                                                                            )
                                                                                        }
                                                                                        // onChange={(e)=>setUpdatedvalue({...updatedvalue, billingType: e.value})}
                                                                                        //  onChange={(e)=>setInputBooking({...inputBooking,category:e.target.value})}
                                                                                        options={
                                                                                            BillingTypevalue
                                                                                        }
                                                                                    />
                                                                                </FormControl>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Indicative
                                                                                        order
                                                                                        value
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        <CssTextField
                                                                                            fullWidth
                                                                                            type="text"
                                                                                            // value=""
                                                                                            label=""
                                                                                            name="Indicative order value"
                                                                                            variant="standard"
                                                                                            // name="totalLand"
                                                                                            className="mt-2"
                                                                                            value={
                                                                                                updatedvalue.incentiveOrderValue
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setUpdatedvalue(
                                                                                                    {
                                                                                                        ...updatedvalue,
                                                                                                        incentiveOrderValue:
                                                                                                            e
                                                                                                                .target
                                                                                                                .value,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Contact
                                                                                        person
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        <CssTextField
                                                                                            fullWidth
                                                                                            type="number"
                                                                                            // value=""
                                                                                            label=""
                                                                                            name="Contact person"
                                                                                            variant="standard"
                                                                                            // name="totalLand"
                                                                                            className="mt-2"
                                                                                            value={
                                                                                                updatedvalue.contactPerson
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setUpdatedvalue(
                                                                                                    {
                                                                                                        ...updatedvalue,
                                                                                                        contactPerson:
                                                                                                            e
                                                                                                                .target
                                                                                                                .value,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            {/* <div className="col-md-4 mb-4">
                                                         <li class="pt-2 pb-0">
                                                            <small className="text-dark">Booking Mode</small>
                                                         </li>
                                                         <li className="ml-2">
                                                            <span>
                                                               <FormControl variant="standard" fullWidth>
                                                                  <Select
                                                                     labelId="demo-simple-select-standard-label"
                                                                     id="demo-simple-select-standard"
                                                                     value={10}
                                                                     //  onChange={handleChange}
                                                                     color="success"
                                                                  >
                                                                     <MenuItem value="">
                                                                        <em>None</em>
                                                                     </MenuItem>
                                                                     <MenuItem value="now">Now</MenuItem>
                                                                     <MenuItem value="Sheduled">Sheduled</MenuItem>
                                                                  </Select>
                                                               </FormControl></span>
                                                         </li>
                                                      </div>
                                                      <div className="col-md-4 mb-4">
                                                         <li class="pt-2 pb-0">
                                                            <small className="text-dark">Billing Type</small>
                                                         </li>
                                                         <li className="ml-2">
                                                            <span>
                                                               <FormControl variant="standard" fullWidth>
                                                                  <Select
                                                                     labelId="demo-simple-select-standard-label"
                                                                     id="demo-simple-select-standard"
                                                                     value={10}
                                                                     //  onChange={handleChange}
                                                                     color="success"
                                                                  >
                                                                     <MenuItem value="">
                                                                        <em>None</em>
                                                                     </MenuItem>
                                                                     <MenuItem value="fixed">Fixed</MenuItem>
                                                                     <MenuItem value="Per Tone">Per Tone</MenuItem>
                                                                  </Select>
                                                               </FormControl></span>
                                                         </li>
                                                      </div> */}
                                                                            {/* <div className="col-md-4 mb-4">
                                                         <li class="pt-1 pb-0">
                                                            <small className="text-dark">Indicative Order Value</small>
                                                         </li>
                                                         <li className="ml-2">
                                                            <span>
                                                               <CssTextField
                                                                  fullWidth
                                                                  type="text"
                                                                  variant="standard"
                                                                  name="totalLand"
                                                                  className="mt-0"
                                                               //  value={farmer.totalLand}
                                                               //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})} 
                                                               />
                                                            </span>
                                                         </li>
                                                      </div> */}
                                                                        </div>
                                                                    </ul>
                                                                    <div class="col-md-12 text-end mt-2 mb-2">
                                                                        <button
                                                                            class="btn btn-primary"
                                                                            onClick={(
                                                                                e
                                                                            ) =>
                                                                                handleBook(
                                                                                    e
                                                                                )
                                                                            }
                                                                        >
                                                                            Update
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <div class="card">
                                                <div class="card-body p_7">
                                                    <form>
                                                        <div class="row form-group">
                                                            <div class="col-md-12">
                                                                <div class="card-body card-body_1">
                                                                    <h5 class="card-title d-flex justify-content-between store_edit">
                                                                        <span>
                                                                            Delivery
                                                                            Task
                                                                        </span>
                                                                        <a
                                                                            href="#"
                                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                                        ></a>
                                                                    </h5>
                                                                    <ul class="list-unstyled mb-0 store_list">
                                                                        <div className="row">
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <CheckCircleIcon
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                            color: activeColor,
                                                                                        }}
                                                                                    />
                                                                                    <small className="text-secondary">
                                                                                        Pending
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <CheckCircleIcon
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                            color: activeColor2,
                                                                                        }}
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleActive2(
                                                                                                'Accepted'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <small className="text-secondary">
                                                                                        Accepted
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <CheckCircleIcon
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                            color: activeColor3,
                                                                                        }}
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleActive3(
                                                                                                'Reached'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <small className="text-secondary">
                                                                                        Reached
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <CheckCircleIcon
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                            color: activeColor3,
                                                                                        }}
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleActive3(
                                                                                                'Start'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <small className="text-secondary">
                                                                                        Start
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <CheckCircleIcon
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                            color: activeColor4,
                                                                                        }}
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleActive4(
                                                                                                'End'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <small className="text-secondary">
                                                                                        End
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            {/* <div className="col-md-4 mb-4">
                                        <li class="pt-2 pb-0">
                                          <CheckCircleIcon style={{ marginRight: '5px' }} /><small className="text-secondary">End</small>
                                        </li>
                                        <li className="ml-2">
                                          <span> - </span>
                                        </li>
                                      </div> */}
                                                                        </div>
                                                                    </ul>
                                                                    <hr />
                                                                    <div className="assigncancel">
                                                                        <h5>
                                                                            Driver
                                                                            Details
                                                                        </h5>
                                                                        <div
                                                                            style={{
                                                                                paddingLeft:
                                                                                    '300px',
                                                                            }}
                                                                        >
                                                                            {assignDriverData.length ? (
                                                                                <Button
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            '#60a132',
                                                                                        color: 'white',
                                                                                    }}
                                                                                    onClick={
                                                                                        handleCancelAssign
                                                                                    }
                                                                                >
                                                                                    Cancel
                                                                                    Assign
                                                                                </Button>
                                                                            ) : null}
                                                                        </div>
                                                                        <Dialog
                                                                            open={
                                                                                openAssign
                                                                            }
                                                                            onClose={() =>
                                                                                closeAssignDialog()
                                                                            }
                                                                            aria-labelledby="form-dialog-title"
                                                                        >
                                                                            <CancelAssignDialog
                                                                                bookingId={
                                                                                    orderId
                                                                                }
                                                                                type="vehicle"
                                                                                assignId={
                                                                                    assignDriverData
                                                                                }
                                                                            />
                                                                        </Dialog>
                                                                    </div>
                                                                    <a className="store-avatar">
                                                                        <div
                                                                            style={{
                                                                                marginRight:
                                                                                    '10px',
                                                                            }}
                                                                           
                                                                        >
                                                                            <AddCircleOutlineIcon  onClick={()=>
                                                                                handleclickopens()

                                                                            } />
                                                                        </div>
                                                                        <div
                                                                            class="store_display"
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                                flexDirection:
                                                                                    'row',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '50px',
                                                                                    width: '25%',
                                                                                }}
                                                                            >
                                                                                <h4>
                                                                                    Name
                                                                                </h4>
                                                                                <span>
                                                                                    {assignDriverData.length
                                                                                        ? assignDriverData[0]
                                                                                              .name
                                                                                        : ''}
                                                                                </span>
                                                                            </div>

                                                                            <div
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '50px',
                                                                                    width: '25%',
                                                                                }}
                                                                            >
                                                                                <h4>
                                                                                    MobileNumber
                                                                                </h4>
                                                                                <span>
                                                                                    {assignDriverData.length
                                                                                        ? assignDriverData[0]
                                                                                              .mobile_number
                                                                                        : ''}
                                                                                </span>
                                                                            </div>

                                                                            <div
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '50px',
                                                                                }}
                                                                            >
                                                                                <h4>
                                                                                    District
                                                                                </h4>
                                                                                <span>
                                                                                    {assignDriverData.length
                                                                                        ? assignDriverData[0]
                                                                                              .district
                                                                                        : ''}
                                                                                </span>
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '50px',
                                                                                }}
                                                                            >
                                                                                <h4>
                                                                                    Trader
                                                                                    Id
                                                                                </h4>
                                                                                <span>
                                                                                    {assignDriverData.length
                                                                                        ? assignDriverData[0]
                                                                                              .trader_id
                                                                                        : ''}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <Menu
                                                                            id="basic-menu"
                                                                            anchorEl={
                                                                                anchorEl
                                                                            }
                                                                            open={
                                                                                open
                                                                            }
                                                                            onClose={
                                                                                handleClose
                                                                            }
                                                                            MenuListProps={{
                                                                                'aria-labelledby':
                                                                                    'basic-button',
                                                                            }}
                                                                        >
                                                                            <MenuItem
                                                                                onClick={() =>
                                                                                    handleclickopens()
                                                                                }
                                                                            >
                                                                                Assign
                                                                                Manually
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                onClick={
                                                                                    handleClose
                                                                                }
                                                                            >
                                                                                Assign
                                                                                Automatically
                                                                            </MenuItem>
                                                                        </Menu>
                                                                        {opens ==
                                                                        true ? (
                                                                            <Dialog
                                                                                className="mapmodel"
                                                                                open={
                                                                                    opens
                                                                                }
                                                                                onClose={()=>
                                                                                    setOpens(false)
                                                                                }
                                                                                style={{
                                                                                    zIndex: 100,
                                                                                }}
                                                                            >
                                                                                <DialogTitle>
                                                                                    <b>
                                                                                        Assign
                                                                                        Driver
                                                                                    </b>
                                                                                </DialogTitle>
                                                                                <DialogContent>
                                                                                    <div
                                                                                        style={{
                                                                                            display:
                                                                                                'flex',
                                                                                        }}
                                                                                    >
                                                                                        <div>
                                                                                            <BookingMap
                                                                                                center={{
                                                                                                    lat: lat,
                                                                                                    lng: long,
                                                                                                }}
                                                                                                height="10px"
                                                                                                zoom={
                                                                                                    15
                                                                                                }
                                                                                                booking={
                                                                                                    true
                                                                                                }
                                                                                                mapdata={
                                                                                                    mapdataPickup
                                                                                                }
                                                                                            />

                                                                                            <div
                                                                                                style={{
                                                                                                    display:
                                                                                                        'flex',
                                                                                                    width: '290px',
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    style={{
                                                                                                        margin: '2%',
                                                                                                        width: '200px',
                                                                                                    }}
                                                                                                >
                                                                                                    <FormControl
                                                                                                        variant="standard"
                                                                                                        fullWidth
                                                                                                        className="mt-4"
                                                                                                    >
                                                                                                        <Select
                                                                                                            onChange={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                searchDriver(
                                                                                                                    e
                                                                                                                )
                                                                                                            }
                                                                                                            placeholder={
                                                                                                                'Choose Category'
                                                                                                            }
                                                                                                            options={
                                                                                                                categoryoption
                                                                                                            }
                                                                                                        />
                                                                                                    </FormControl>
                                                                                                </div>
                                                                                                <div
                                                                                                    style={{
                                                                                                        margin: '2%',
                                                                                                        width: '200px',
                                                                                                    }}
                                                                                                >
                                                                                                    <FormControl
                                                                                                        variant="standard"
                                                                                                        fullWidth
                                                                                                        className="mt-4"
                                                                                                    >
                                                                                                        <Select
                                                                                                            onChange={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                setDriver(
                                                                                                                    e
                                                                                                                )
                                                                                                            }
                                                                                                            placeholder={
                                                                                                                'Search Driver'
                                                                                                            }
                                                                                                            options={
                                                                                                                driverList1
                                                                                                            }
                                                                                                        />
                                                                                                    </FormControl>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <br />
                                                                                        <div
                                                                                            style={{
                                                                                                minWidth:
                                                                                                    '150px',
                                                                                            }}
                                                                                        >
                                                                                            <div>
                                                                                                Or
                                                                                                Search
                                                                                                By
                                                                                                Phone
                                                                                                No.
                                                                                            </div>
                                                                                            <div class="col">
                                                                                                <CssTextField
                                                                                                    fullWidth
                                                                                                    id="standard-basic"
                                                                                                    label="Mobile No.*"
                                                                                                    variant="standard"
                                                                                                    name="lastName"
                                                                                                    onChange={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        fetchByphone(
                                                                                                            e
                                                                                                                .target
                                                                                                                .value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </div>

                                                                                            <div
                                                                                                class="row"
                                                                                                className="mt-4"
                                                                                            >
                                                                                                {drivers.map(
                                                                                                    (
                                                                                                        item,
                                                                                                        index
                                                                                                    ) => {
                                                                                                        return (
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    width: '260px',
                                                                                                                    height: '70px',
                                                                                                                    marginBottom:
                                                                                                                        '50px',
                                                                                                                }}
                                                                                                            >
                                                                                                                <div
                                                                                                                    class="card card-body bg-gray"
                                                                                                                    style={{
                                                                                                                        display:
                                                                                                                            'flex',
                                                                                                                        flexDirection:
                                                                                                                            'row',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <div>
                                                                                                                        <h5>
                                                                                                                            {item.first_name +
                                                                                                                                ' ' +
                                                                                                                                item.last_name}
                                                                                                                        </h5>

                                                                                                                        <div>
                                                                                                                            <a href="#">
                                                                                                                                <span>
                                                                                                                                    {
                                                                                                                                        item.mobile_number
                                                                                                                                    }
                                                                                                                                </span>
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <div
                                                                                                                            class="circle"
                                                                                                                            style={{
                                                                                                                                width: '30px',
                                                                                                                                height: '30px',
                                                                                                                                padding:
                                                                                                                                    '5px',
                                                                                                                                marginLeft:
                                                                                                                                    '60px',
                                                                                                                                borderRadius:
                                                                                                                                    '50%',
                                                                                                                                background:
                                                                                                                                    '#fff',
                                                                                                                                border: ' 3px solid #000',
                                                                                                                                color: '#000',
                                                                                                                                textAlign:
                                                                                                                                    'center',
                                                                                                                                font: '14px Arial, sans-serif',
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            0
                                                                                                                        </div>

                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                marginLeft:
                                                                                                                                    '60px',
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <a href="#">
                                                                                                                                <span>
                                                                                                                                    task
                                                                                                                                </span>
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                )}
                                                                                                <div
                                                                                                    className="mt-3"
                                                                                                    style={{
                                                                                                        fontSize:
                                                                                                            '23px',
                                                                                                    }}
                                                                                                >
                                                                                                    District:{' '}
                                                                                                    <span
                                                                                                        style={{
                                                                                                            color: 'green',
                                                                                                        }}
                                                                                                    >
                                                                                                        {' '}
                                                                                                        {
                                                                                                            District
                                                                                                        }
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            display:
                                                                                                'flex',
                                                                                            flexDirection:
                                                                                                'row',
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            style={{
                                                                                                paddingLeft:
                                                                                                    '300px',
                                                                                            }}
                                                                                        >
                                                                                            <Button
                                                                                                style={{
                                                                                                    backgroundColor:
                                                                                                        '#60a132',
                                                                                                    color: 'white',
                                                                                                }}
                                                                                                onClick={
                                                                                                    handlecloses
                                                                                                }
                                                                                            >
                                                                                                Cancel
                                                                                            </Button>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{
                                                                                                marginLeft:
                                                                                                    '30px',
                                                                                            }}
                                                                                        >
                                                                                            <Button
                                                                                                style={{
                                                                                                    backgroundColor:
                                                                                                        '#60a132',
                                                                                                    color: 'white',
                                                                                                }}
                                                                                                onClick={
                                                                                                    handleAssign
                                                                                                }
                                                                                            >
                                                                                                Assign
                                                                                                Task
                                                                                            </Button>
                                                                                        </div>
                                                                                    </div>
                                                                                </DialogContent>
                                                                            </Dialog>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </a>
                                                                    {/* <div class="store_display">
                                                <h4>Name</h4>
                                                <span style={{ marginTop: '5px' }}>murugesh</span>
                                                </div>
                                                <div class="store_display">
                                                <h4>MobileNumber</h4>
                                                <span style={{ marginTop: '5px' }}>8675191591</span>
                                                </div>
                                                <div class="store_display">
                                                <h4>Id</h4>
                                                <span style={{ marginTop: '5px' }}>31</span>
                                                </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Booking from</span>
                                                <a
                                                    href="#"
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                ></a>
                                            </h5>
                                            <hr style={{ margin: 'auto' }} />
                                            <div className="row mt-3">
                                                <h6>Farmer</h6>
                                                <p>
                                                    First Name:{' '}
                                                    {booking.first_name}
                                                </p>
                                                <p>
                                                    Last Name:{' '}
                                                    {booking.last_name}
                                                </p>
                                                <p>
                                                    Mobile:{' '}
                                                    {booking.mobile_number}
                                                </p>
                                                <p>BookingId: {orderId}</p>
                                            </div>
                                            {/* <div class="staffAvatar-root1">R</div>
                                 <div class="store_display">
                                    <h4>Rajsekar</h4>
                                    <span>8098081991</span>
                                 </div> */}
                                        </div>

                                        <hr
                                            width="85%"
                                            style={{ margin: 'auto' }}
                                        />
                                        {DetailsInput &&
                                        DetailsInput.length > 0 ? (
                                            <div class="card-body ">
                                                <h5 class="card-title d-flex justify-content-between store_edit">
                                                    <span>
                                                        {' '}
                                                        Agri Machine Details
                                                    </span>
                                                    <a
                                                        href="#"
                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                    ></a>
                                                </h5>
                                                <a class="store-avatar">
                                                    <div class="staffAvatar-root1">
                                                        {DetailsInput &&
                                                        DetailsInput.farmerId &&
                                                        DetailsInput.farmerId
                                                            .firstName &&
                                                        DetailsInput.farmerId
                                                            .firstName.length >
                                                            0
                                                            ? DetailsInput.farmerId.firstName.slice(
                                                                  0,
                                                                  1
                                                              )
                                                            : ''}
                                                    </div>
                                                    <div class="store_display">
                                                        <h4>
                                                            {DetailsInput &&
                                                                DetailsInput.firstName +
                                                                    ' ' +
                                                                    DetailsInput &&
                                                                DetailsInput.lastName}
                                                        </h4>
                                                        <span>
                                                            {Details &&
                                                                Details.Firm}
                                                        </span>
                                                    </div>
                                                    {/* <div class="staffAvatar-root1">R</div>
                                 <div class="store_display">
                                    <h4>Rajsekar</h4>
                                    <span>8098081991</span>
                                 </div> */}
                                                </a>
                                            </div>
                                        ) : (
                                            <></>
                                        )}

                                        <hr
                                            width="85%"
                                            style={{ margin: 'auto' }}
                                        />
                                      <div class="card-body ">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Payment Details</span>
                                                <a
                                                    href="#"
                                                   
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                    style={{
                                                        padding: '0rem 0.75rem',
                                                    }}
                                                >
                                                    <span>
                                                        {paymentStatus ? (
                                                            <u>
                                                                {' '}
                                                                {transactionId}{transactionNotes}
                                                            </u>
                                                        ) : (
                                                            <u  onClick={() =>
                                                                setPaymentModal(
                                                                    !paymentModal
                                                                )
                                                            }>
                                                                Receive Payment
                                                            </u>
                                                        )}
                                                    </span>
                                                </a>
                                            </h5>
                                            <div className="col-md-4 mb-4">
                                                <small className="text-dark">
                                                    Amount:{' '}
                                                </small>
                                                <span>
                                                    {' '}
                                                    {
                                                        details1?.userAmount
                                                    }{' '}
                                                </span>
                                            </div>
                                            <div className="">
                                                <small className="text-dark">
                                                    Payment Status:{' '}
                                                </small>
                                                <span>
                                                    {' '}
                                                    {details1?.payment_status
                                                        ? 'Received'
                                                        : 'Not Recevied'}{' '}
                                                </span>
                                            </div>
                                            <p>
                                                {paymentStatus
                                                    ? 'Payment Recieved  -'
                                                    : 'Pending Amount -'}
                                                <span>
                                                    Rs.{' '}
                                                    {
                                                        details1?.userAmount
                                                    }
                                                </span>
                                            </p>
                                            <span>
                                                {paymentStatus ? (
                                                    <i>
                                                        {' '}
                                                        Payment Recieved via {details1?.payment
                                                            ?.cash_type || details1?.payment
                                                            ?.billing_type } 
                                                    </i>
                                                ) : (
                                                    <i>No transaction yet</i>
                                                )}
                                            </span>
                                            <br/>
                                            <p>
                                            <span>
                                            Date:    {details1?.payment
                                                            ?.date ? (
                                                    <i>
                                                        {' '}
                                                      {  new Date(details1?.payment
                                                            ?.date).toDateString()}
                                                    </i>
                                                ) :null}
                                            </span>
                                            </p>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Notes (0)</span>
                                                <a
                                                    href="#"
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                ></a>
                                            </h5>
                                            <hr style={{ margin: 'auto' }} />
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <CssTextField
                                                        fullWidth
                                                        id="totalLand"
                                                        variant="standard"
                                                        placeholder="Add a note...."
                                                        name="totalLand"
                                                        className="mb-0"
                                                    />
                                                </div>
                                                <div className="col-md-2">
                                                    <IconButton
                                                        color="success"
                                                        aria-label="add an alarm"
                                                        className="mt-3"
                                                    >
                                                        <SendIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Created By :</span>
                                            </h5>
                                            <ul class="list-unstyled mb-0 store_list">
                                                <li class="pt-2 pb-0">
                                                    <small class="text-dark">
                                                        Role :{' '}
                                                    </small>
                                                    <span>
                                                        {createdByData.role}
                                                    </span>
                                                </li>
                                                <li class="pt-2 pb-0">
                                                    <small class="text-dark">
                                                        ID :{' '}
                                                    </small>
                                                    <span>
                                                        {createdByData.id}
                                                    </span>
                                                </li>
                                                <li class="pt-2 pb-0">
                                                    <small class="text-dark">
                                                        Phone Number :{' '}
                                                    </small>
                                                    <span>
                                                        {
                                                            createdByData.mobile_number
                                                        }
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Booking from</span>
                                                <a
                                                    href="#"
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                ></a>
                                            </h5>
                                            <hr style={{ margin: 'auto' }} />
                                            <div className="row mt-3">
                                                <h6>ID: {booking.id}</h6>
                                            </div>
                                            <div className="row mt-3">
                                                <h6>Role: {booking.role}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgriTransportBD
