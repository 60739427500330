import React, { useState } from 'react'
import './Orders.css'
import LivestockOrders from './LivestockOrders'
import InputSuppliers from './InputSupplierOrders'
import AgriTransportOrders from './AgriTransportOrders'
import AgriMachineOrders from './AgriMachineOrders'
import MicroEntOrders from './MicroEntOrders'
import FarmerOrders from './FarmerOrders'
import SellCropOrders from './SellCropOrders'
import FpoOrders from './FpoOrders'

const Orders = () => {
    const [tab, setTab] = useState(1)
    return (
        <div class="content container-fluid" style={{ paddingBottom: '10px' }}>
            <div class="page-header">
                <div class="row align-items-center">
                    <div class="col">
                        <h3 class="page-title">Orders</h3>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a href="/Home">Dashboard</a>
                            </li>
                            <li class="breadcrumb-item active">Orders</li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div class="card card-table p-10-25">
                                <>
                                    <div
                                        class="tab-content"
                                        style={{ paddingTop: '0px' }}
                                    >
                                        <div action="#">
                                            <ul
                                                className="nav nav-tabs nav-tabs-bottom nav-justified"
                                                style={{
                                                    borderBottom: 'transparent',
                                                }}
                                            >
                                                <li class="nav-item">
                                                    <a
                                                        class={
                                                            tab === 1
                                                                ? 'nav-link active'
                                                                : 'nav-link'
                                                        }
                                                        href="#bottom-justified-tab1"
                                                        data-bs-toggle="tab"
                                                        onClick={(e) =>
                                                            setTab(1)
                                                        }
                                                    >
                                                        Sell Crop
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        class="nav-link"
                                                        href="#bottom-justified-tab2"
                                                        data-bs-toggle="tab"
                                                        onClick={(e) =>
                                                            setTab(2)
                                                        }
                                                    >
                                                        Input Supplier
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        class="nav-link "
                                                        href="#bottom-justified-tab3"
                                                        data-bs-toggle="tab"
                                                        onClick={(e) =>
                                                            setTab(3)
                                                        }
                                                    >
                                                        Livestock{' '}
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        class="nav-link"
                                                        href="#bottom-justified-tab4"
                                                        data-bs-toggle="tab"
                                                        onClick={(e) =>
                                                            setTab(4)
                                                        }
                                                    >
                                                        Agri Machine
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        class="nav-link"
                                                        href="#bottom-justified-tab5"
                                                        data-bs-toggle="tab"
                                                        onClick={(e) =>
                                                            setTab(5)
                                                        }
                                                    >
                                                        Agri Transport
                                                    </a>
                                                </li>
                                             
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>

                    {tab === 1 && <SellCropOrders />}

                    {tab === 2 && <InputSuppliers />}

                    {tab === 3 && <LivestockOrders />}

                    {tab === 4 && <AgriMachineOrders />}

                    {tab === 5 && <AgriTransportOrders />}

                    {tab === 6 && <MicroEntOrders />}

                    {tab === 7 && <FpoOrders />}
                </div>
            </div>
        </div>
    )
}

export default Orders
