import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import axios from 'axios'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

export default function OtpBox(props) {
    const { syncOtpSubmit,verificationId,handleVerifyCode } = props
    const [open, setOpen] = React.useState(true)
    const handleClose = () => setOpen(false)
    const [otp, setOtp] = useState(new Array(6).fill(''))

    function handleChange(element, index) {
        if (isNaN(element.value)) return false
        setOtp([...otp.map((d, id) => (id === index ? element.value : d))])

        //Focus to the next Input
        if (element.nextSibling) {
            element.nextSibling.focus()
        }
    }

    function handleSubmitOtp(e) {
         const checkOtp = otp.join('')
console.log(checkOtp)
        handleVerifyCode(checkOtp)
        // var token = localStorage.getItem('token')
        // const config = {
        //     headers: { Authorization: `Bearer ${token}` },
        // }
        // console.log('checking otp:', otp)
        // const checkOtp = otp.join('')
        // console.log(checkOtp)
        // const dataForOtp = {
        //     mobile_number: props.mobileNumber,
        //     otp: checkOtp,
        // }

        // axios
        //     .post(
        //         `${process.env.REACT_APP_API_ENDPOINT}/auth-function/verify-otp`,
        //         dataForOtp,
        //         config
        //     )
        //     .then((res) => {
        //         syncOtpSubmit()
        //     })
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                        }}
                    >
                        {otp.map((data, index) => {
                            return (
                                <input
                                    style={{ width: '30px' }}
                                    type="text"
                                    className="otp"
                                    name="otp"
                                    maxLength="1"
                                    key={index}
                                    value={data}
                                    onChange={(e) =>
                                        handleChange(e.target, index)
                                    }
                                    onFocus={(e) => e.target.select()}
                                />
                            )
                        })}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            onClick={handleSubmitOtp}
                            style={{
                                backgroundColor: '#24d146',
                                color: 'white',
                                position: 'relative',
                                margin: '20px',
                            }}
                        >
                            Submit OTP
                        </Button>
                    </div>
                </Box>
            </Modal>
        </>
    )
}
