import React, { useEffect, useState } from 'react'
import { CssTextField } from '../Styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { useDropzone } from 'react-dropzone'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Input from '@mui/material/Input'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import {
    thumbsContainerState,
    thumb,
    img,
    thumbInner,
    mapThumb,
    baseStyle,
} from '../Styles'
// import { API } from '../../API'

import Swal from 'sweetalert2'
import Selects from 'react-select'
import axios from 'axios'
import Map2 from '../Create/Map2'
import ModuleOrders from '../../components/ModuleOrders'
import BankingDetails from '../../components/BankingDetails'
import Wallet from '../../components/Wallet'
import EditFarmers from '../Create/CreateFarmer/EditFarmer'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}
const names = ['Wheat', 'Rice', 'Barley', 'Cereal', 'Tomato', 'Oat']
const farmerClear = {
    typeOfCrop: '',
    irrigationSource: '',
    farmEquipments: '',
    selectUnit: '',
    totalLand: '',
    soilType: '',
    sowingDate: '',
    soilTest: false,
}
var lati = localStorage.getItem('lat')
var long = localStorage.getItem('lng')

function Settings() {
    const [addFarm, setAddFarm] = useState(false)
    const [addDoc, setAddDoc] = useState(false)
    const [cropTypes, setCropTypes] = React.useState([])
    const [Files, setFiles] = useState([])
    const [Details, setDetails] = useState({})
    const [FirmDetails, setFirmDetails] = useState([])
    const [logo, setLogo] = useState('')
    const [CropProduct, setCropProduct] = useState([])
    const [userId, setUserId] = useState('')
    const [idForAssign, setIdForAssign] = useState('')
    const [bankDetails, setBankDetails] = useState({})

    const [subDetails, setSubDetails] = useState({})
    const [showNull, setShowNull] = useState(false)
    const [farmer, setFarmer] = useState({
        farmerId: localStorage.getItem('farmerid'),
        typeOfCrop: cropTypes,
        irrigationSource: '',
        farmEquipments: '',
        selectUnit: '',
        totalLand: '',
        soilType: '',
        sowingDate: '',
        soilTest: false,
    })
    const [createdByData, setCreatedByData] = useState({})

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        var farmerid = localStorage.getItem('farmerid')
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }

        var payload = {
            mobile_no: farmerid,
            role: 'farmer',
        }

        var farmInfo

        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-register`,
                payload,
                config
            )
            .then((res) => {
                let data = res.data
                var a = {
                    _id: data.data.data._id,
                    user_id: data.data.data._id,
                    firstName: data.data.data?.first_name,
                    lastName: data.data.data.last_name,
                    mobileNumber: data.data.data.mobile_number,
                    gender: data.data.data.gender,
                    category: data.data.data.role,
                    dateOfBirth: data.data.data.dob,
                    education: '',
                    language: data.data.data.language,
                    farmingMode: data.data.data.farming_mode,
                    state: data.data.data.state,
                    district: data.data.data.district,
                    subDistrict: data.data.data.sub_distric,
                    village: data.data.data.village,
                    pincode: data.data.data.pincode,
                    adhaarNumber: '',
                    fullAddress: data.data.data.full_address,
                    bankAccountNumber: '',
                    bankAccountName: '',
                    ifscCode: '',
                    lat: data.data.data.coordinates[1],
                    long: data.data.data.coordinates[0],
                    bankName: '',
                    bankBranchName: '',
                    area: data.data.farm_info.total_land,
                    totalLand: data.data.farm_info.total_land,
                    createdAt: data.data.farm_info.created_at,
                    irrigation_source: 'Canal',
                    mobile_number: '6353454356',
                    soil_test: true,
                    soil_type: 'Alluvial soil',
                    sowing_date: '2022-04-08',
                }
                farmInfo = [data.data.farm_info]
                setLogo(data.data.data.photo)
                setDetails(a)
                FirmDetailsvalue(farmInfo)

                setBankDetails(data.data.data.bank_info)

                payload = {
                    userId: data.data.data.createdById,
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-user`,
                        payload,
                        config
                    )
                    .then((res) => {
                        const { role, id, mobile_number } = res.data.user[0]
                        setCreatedByData({
                            role,
                            id,
                            mobile_number,
                        })
                    })
                setUserId(data.data.data.user_id)
                setIdForAssign(data.data.data.id)
            })
    }, [])
    const farmEquipmentsValue = [
        {
            value: 'TRACTOR',
            label: 'TRACTOR',
        },
    ]
    const SoilTest = [
        {
            value: true,
            label: 'YES',
        },
        {
            value: false,
            label: 'NO',
        },
    ]
    const IrrigationSource = [
        {
            value: 'Canal',
            label: 'Canal',
        },
        {
            value: 'River',
            label: 'River',
        },
        {
            value: 'Bore',
            label: 'Bore',
        },
        {
            value: 'Well',
            label: 'Well',
        },
    ]

    const Unitvalue = [
        {
            value: 'HECTARE',
            label: 'HECTARE',
        },
        {
            value: 'ACRE',
            label: 'ACRE',
        },
        {
            value: 'CENT',
            label: 'CENT',
        },
        {
            value: 'KATHA',
            label: 'KATHA',
        },
        {
            value: 'BIGHA',
            label: 'BIGHA',
        },
        {
            value: 'GUNTA',
            label: 'GUNTA',
        },
    ]
    const SoilType = [
        {
            value: 'Alluvial soil',
            label: 'Alluvial soil',
        },
        {
            value: 'Black Soil',
            label: 'Black Soil',
        },
        {
            value: 'Desert Soil',
            label: 'Desert Soil',
        },
        {
            value: 'Laterita Soil',
            label: 'Laterita Soil',
        },
        {
            value: 'Mountain Soil',
            label: 'Mountain Soil',
        },
        {
            value: 'Red Soil',
            label: 'Red Soil',
        },
    ]
  
    const CropProductoption =
        CropProduct &&
        CropProduct.map((item, ind) => ({
            value: item.productName,
            label: item.productName,
        }))
    const handleChange = (event) => {
        const {
            target: { value },
        } = event
        setCropTypes(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    const FirmDetailsvalue = async (value) => {
        setFirmDetails(value)
    }

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
        },
    })

    const PGthumbs = Files.map((file, index) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt="" />
            </div>
        </div>
    ))


    return (
        <div>
            <div class="row">
                <div class="page-header">
                    {console.log(localStorage.getItem('farmerid'))}
                    <div class="row align-items-center">
                        <div class="col-md-2" for="edit_img"></div>
                        <div className="col-md-10">
                            <ul class="breadcrumb justify-content-end">
                                <li class="breadcrumb-item">
                                    <a href="#">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="#">Farmers</a>
                                </li>
                                <li class="breadcrumb-item active">
                                    {Details.firstName}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="card-title d-flex justify-content-between store_edit">
                                <span>Farmer</span>
                            </h5>
                            <a class="store-avatar">
                                <div class="staffAvatar-root1">
                                    {logo ? (
                                        <img src={logo} />
                                    ) : Details &&
                                      Details.firstName &&
                                      Details.firstName.length > 0 ? (
                                        Details.firstName.slice(0, 1)
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div class="store_display">
                                    <h4>{Details.firstName}</h4>
                                    <span>{Details.mobileNumber}</span>
                                </div>
                            </a>
                        </div>
                        <div class="card-body">
                            <h5 class="card-title d-flex justify-content-between store_edit">
                                <span>Personal Details</span>
                                {/* <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                           <span class="material-icons-outlined">edit</span>
                        </a> */}
                            </h5>
                            <ul class="list-unstyled mb-0 store_list">
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">Gender : </small>
                                    <span style={{ marginLeft: '20px' }}>
                                        {Details.gender}
                                    </span>
                                </li>
                                {/* <li class="pt-2 pb-0">
                           <small class="text-dark">Category : </small>
                           <span style={{ marginLeft: '20px' }}>{Details.category}</span>
                        </li> */}
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">DOB : </small>
                                    <span style={{ marginLeft: '20px' }}>
                                        {Details.dateOfBirth}
                                    </span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">
                                        Farming Mode :{' '}
                                    </small>
                                    <span style={{ marginLeft: '20px' }}>
                                        {Details.farmingMode}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <BankingDetails
                        Details={Details}
                        bankDetails={bankDetails}
                    />

                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title d-flex justify-content-between store_edit">
                                <span>Created By :</span>
                            </h5>
                            <ul class="list-unstyled mb-0 store_list">
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">Role : </small>
                                    <span>{createdByData.role}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">ID : </small>
                                    <span>{createdByData.id}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">
                                        Phone Number :{' '}
                                    </small>
                                    <span>{createdByData.mobile_number}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-xl-9 col-md-8">
                    <div class="card">
                        <div class="card-body p_7">
                            <form>
                                <div class="row form-group">
                                    <div class="col-md-12">
                                        <div class="card bg-white bn_15">
                                            <div class="card-body card-body_1">
                                                <ul class="nav nav-tabs nav-tabs-bottom nav_add_bt">
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link active"
                                                            href="#bottom-tab1"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Orders
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab2"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Wallet
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab3"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Documents
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab4"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Farm Details
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab5"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Address Details
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab6"
                                                            data-bs-toggle="tab"
                                                        >
                                                           Edit User
                                                        </a>
                                                    </li>
                                                </ul>

                                                <div class="tab-content">
                                                    {/* Orders */}
                                                    <ModuleOrders
                                                    role="farmer"
                                                        userId={userId}
                                                        idForAssign={
                                                            idForAssign
                                                        }
                                                    />

                                                    {/* Wallet */}
                                                    <Wallet moduleType="farmer" />
                                                    <div
                                                        class="tab-pane"
                                                        id="bottom-tab3"
                                                    >
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="card-body card-body_1">
                                                                    <div className="row">
                                                                        <div class="col-md-12 text-end justify-space-between mb-4">
                                                                            {!addDoc ? (
                                                                                <button
                                                                                    type="submit"
                                                                                    className="btn btn-primary"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.preventDefault()
                                                                                        setAddDoc(
                                                                                            true
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    +
                                                                                    Add
                                                                                    Document
                                                                                </button>
                                                                            ) : (
                                                                                <button
                                                                                    type="submit"
                                                                                    className="btn btn-primary"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.preventDefault()
                                                                                        setAddDoc(
                                                                                            false
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    Document
                                                                                    List
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    {!addDoc ? (
                                                                        <>
                                                                            <div class="table-wrap">
                                                                                <div class="table-responsive">
                                                                                    <table class="table display setting-td mb-30">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>
                                                                                                    TITLE
                                                                                                </th>
                                                                                                <th>
                                                                                                    PHOTO
                                                                                                </th>
                                                                                                <th>
                                                                                                    CREATED
                                                                                                </th>
                                                                                                <th>
                                                                                                    ACTIONS
                                                                                                </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    TRADE
                                                                                                    LIC
                                                                                                </td>
                                                                                                <td>
                                                                                                    -
                                                                                                </td>
                                                                                                <td>
                                                                                                    Sep
                                                                                                    21,
                                                                                                    11:19
                                                                                                    AM
                                                                                                </td>
                                                                                                <td>
                                                                                                    <a
                                                                                                        href="#"
                                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                    >
                                                                                                        <span class="material-icons-outlined">
                                                                                                            edit
                                                                                                        </span>
                                                                                                    </a>
                                                                                                    <a
                                                                                                        href="#"
                                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                    >
                                                                                                        <span class="material-icons-outlined">
                                                                                                            delete
                                                                                                        </span>
                                                                                                    </a>
                                                                                                </td>
                                                                                            </tr>

                                                                                            <tr>
                                                                                                <td>
                                                                                                    TRADE
                                                                                                    LIC
                                                                                                </td>
                                                                                                <td>
                                                                                                    -
                                                                                                </td>
                                                                                                <td>
                                                                                                    Sep
                                                                                                    21,
                                                                                                    11:19
                                                                                                    AM
                                                                                                </td>
                                                                                                <td>
                                                                                                    <a
                                                                                                        href="#"
                                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                    >
                                                                                                        <span class="material-icons-outlined">
                                                                                                            edit
                                                                                                        </span>
                                                                                                    </a>
                                                                                                    <a
                                                                                                        href="#"
                                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                    >
                                                                                                        <span class="material-icons-outlined">
                                                                                                            delete
                                                                                                        </span>
                                                                                                    </a>
                                                                                                </td>
                                                                                            </tr>

                                                                                            <tr>
                                                                                                <td>
                                                                                                    TRADE
                                                                                                    LIC
                                                                                                </td>
                                                                                                <td>
                                                                                                    -
                                                                                                </td>
                                                                                                <td>
                                                                                                    Sep
                                                                                                    21,
                                                                                                    11:19
                                                                                                    AM
                                                                                                </td>
                                                                                                <td>
                                                                                                    <a
                                                                                                        href="#"
                                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                    >
                                                                                                        <span class="material-icons-outlined">
                                                                                                            edit
                                                                                                        </span>
                                                                                                    </a>
                                                                                                    <a
                                                                                                        href="#"
                                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                    >
                                                                                                        <span class="material-icons-outlined">
                                                                                                            delete
                                                                                                        </span>
                                                                                                    </a>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <form
                                                                                class="tab-pane show active"
                                                                                id="bottom-justified-tab4"
                                                                                onSubmit={(
                                                                                    e
                                                                                ) =>
                                                                                    console.log(
                                                                                        5
                                                                                    )
                                                                                }
                                                                            >
                                                                                <div className="row">
                                                                                    <h4>
                                                                                        Add
                                                                                        New
                                                                                        Document
                                                                                    </h4>
                                                                                    <div className="col-md-3 mb-2">
                                                                                        <InputLabel
                                                                                            id="demo-simple-select-standard-label"
                                                                                            style={{
                                                                                                color: 'black',
                                                                                            }}
                                                                                        >
                                                                                            Document
                                                                                            Upload
                                                                                        </InputLabel>
                                                                                        <div
                                                                                            class="form-group row widget-3"
                                                                                            style={{
                                                                                                width: '200px',
                                                                                                margin: 'auto',
                                                                                                display:
                                                                                                    'flex',
                                                                                                justifyContent:
                                                                                                    'center',
                                                                                            }}
                                                                                        >
                                                                                            <section
                                                                                                className="container"
                                                                                                style={
                                                                                                    baseStyle
                                                                                                }
                                                                                            >
                                                                                                <div
                                                                                                    {...getRootProps(
                                                                                                        {
                                                                                                            className:
                                                                                                                'dropzone',
                                                                                                        }
                                                                                                    )}
                                                                                                    style={{
                                                                                                        borderRadius:
                                                                                                            '5px',
                                                                                                        display:
                                                                                                            'flex',
                                                                                                        justifyContent:
                                                                                                            'center',
                                                                                                        alignItems:
                                                                                                            'center',
                                                                                                        height: '100px',
                                                                                                    }}
                                                                                                >
                                                                                                    <input
                                                                                                        {...getInputProps()}
                                                                                                        multiple={
                                                                                                            false
                                                                                                        }
                                                                                                    />
                                                                                                    {Files && (
                                                                                                        <>
                                                                                                            {Files.length ===
                                                                                                                0 && (
                                                                                                                <p align="center">
                                                                                                                    Drop
                                                                                                                    Image
                                                                                                                    here
                                                                                                                    or
                                                                                                                    click
                                                                                                                    to
                                                                                                                    upload.
                                                                                                                </p>
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                    {Files && (
                                                                                                        <>
                                                                                                            {Files.length >
                                                                                                                0 && (
                                                                                                                <aside
                                                                                                                    style={
                                                                                                                        thumbsContainerState
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        PGthumbs
                                                                                                                    }
                                                                                                                </aside>
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            </section>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div class="col-md-3">
                                                                                        <FormControl
                                                                                            variant="standard"
                                                                                            fullWidth
                                                                                            className="mt-3"
                                                                                        >
                                                                                            <InputLabel
                                                                                                id="demo-simple-select-standard-label"
                                                                                                style={{
                                                                                                    color: 'black',
                                                                                                }}
                                                                                            >
                                                                                                Document
                                                                                                Type
                                                                                            </InputLabel>
                                                                                            <Select
                                                                                                labelId="demo-simple-select-standard-label"
                                                                                                id="soilType"
                                                                                                label="Soil Type"
                                                                                                color="success"
                                                                                                name="soilType"
                                                                                                //  value={farmer.selectUnit}
                                                                                                //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                                                                            >
                                                                                                <MenuItem value="">
                                                                                                    <em>
                                                                                                        -
                                                                                                        Select
                                                                                                        Unit
                                                                                                        -
                                                                                                    </em>
                                                                                                </MenuItem>
                                                                                                <MenuItem value="Hindi">
                                                                                                    Aadhar
                                                                                                </MenuItem>
                                                                                                <MenuItem value="Bengali">
                                                                                                    PAN
                                                                                                </MenuItem>
                                                                                            </Select>
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div class="col-md-3">
                                                                                        <CssTextField
                                                                                            fullWidth
                                                                                            id="fEducation"
                                                                                            label="Document Number"
                                                                                            variant="standard"
                                                                                            className="mt-3"
                                                                                            style={{
                                                                                                marginBottom:
                                                                                                    '1.25rem',
                                                                                            }}
                                                                                            name="education"
                                                                                            //  value={farmer.education}
                                                                                            //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 text-end mt-2 mb-2">
                                                                                    <button
                                                                                        type="submit"
                                                                                        class=" w-10 btn btn-primary"
                                                                                    >
                                                                                        Submit
                                                                                    </button>
                                                                                </div>
                                                                            </form>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="tab-pane"
                                                        id="bottom-tab4"
                                                    >
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="card-body card-body_1">
                                                                    <div className="row">
                                                                        <div class="col-md-12 text-end justify-space-between mb-4">
                                                                            {!addFarm ? (
                                                                                <button
                                                                                    type="submit"
                                                                                    className="btn btn-primary"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.preventDefault()
                                                                                        setAddFarm(
                                                                                            true
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    +
                                                                                    Add
                                                                                    Farm
                                                                                </button>
                                                                            ) : (
                                                                                <button
                                                                                    type="submit"
                                                                                    className="btn btn-primary"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.preventDefault()
                                                                                        setAddFarm(
                                                                                            false
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    Farm
                                                                                    List
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    <>
                                                                        <div
                                                                            class="card"
                                                                            style={{
                                                                                backgroundColor:
                                                                                    '#f3f3f3 ',
                                                                            }}
                                                                        >
                                                                            <div class="card-body">
                                                                                {console.log(
                                                                                    'check firm:',
                                                                                    FirmDetails
                                                                                ) &&
                                                                                    FirmDetails.length &&
                                                                                    FirmDetails.map(
                                                                                        (
                                                                                            FirmDetails,
                                                                                            ind
                                                                                        ) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <h5 class="card-title d-flex justify-content-between store_edit">
                                                                                                        <span>
                                                                                                            Farm{' '}
                                                                                                            {ind +
                                                                                                                1}
                                                                                                        </span>
                                                                                                        <a
                                                                                                            href="#"
                                                                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                        >
                                                                                                            <span class="material-icons-outlined mr-3">
                                                                                                                edit
                                                                                                            </span>
                                                                                                            <span class="material-icons-outlined">
                                                                                                                delete
                                                                                                            </span>
                                                                                                        </a>
                                                                                                    </h5>
                                                                                                    <ul class="list-unstyled mb-0 store_list">
                                                                                                        <div className="row">
                                                                                                            <div className="col-md-3 mb-4">
                                                                                                                <li class="pt-2 pb-0">
                                                                                                                    <small class="text-dark">
                                                                                                                        Types
                                                                                                                        of
                                                                                                                        Crop{' '}
                                                                                                                    </small>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <img
                                                                                                                        src={
                                                                                                                            FirmDetails.corp_img
                                                                                                                        }
                                                                                                                        alt="crop"
                                                                                                                    />
                                                                                                                </li>
                                                                                                            </div>
                                                                                                            <div className="col-md-3 mb-4">
                                                                                                                <li class="pt-2 pb-0">
                                                                                                                    <small class="text-dark">
                                                                                                                        Irrigation
                                                                                                                        Source{' '}
                                                                                                                    </small>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    {
                                                                                                                        FirmDetails.irrigation_source
                                                                                                                    }
                                                                                                                    {/* hlo */}
                                                                                                                </li>
                                                                                                            </div>
                                                                                                            <div className="col-md-3 mb-4">
                                                                                                                <li class="pt-2 pb-0">
                                                                                                                    <small class="text-dark">
                                                                                                                        Farmin
                                                                                                                        Equipment{' '}
                                                                                                                    </small>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    {
                                                                                                                        FirmDetails.farm_equipements
                                                                                                                    }
                                                                                                                </li>
                                                                                                            </div>
                                                                                                            <div className="col-md-3 mb-4">
                                                                                                                <li class="pt-2 pb-0">
                                                                                                                    <small class="text-dark">
                                                                                                                        {' '}
                                                                                                                        Unit{' '}
                                                                                                                    </small>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    {
                                                                                                                        FirmDetails.unit
                                                                                                                    }
                                                                                                                </li>
                                                                                                            </div>
                                                                                                            <div className="col-md-3 mb-4">
                                                                                                                <li class="pt-2 pb-0">
                                                                                                                    <small class="text-dark">
                                                                                                                        {' '}
                                                                                                                        Soil
                                                                                                                        Type{' '}
                                                                                                                    </small>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    {
                                                                                                                        FirmDetails.soil_type
                                                                                                                    }
                                                                                                                </li>
                                                                                                            </div>
                                                                                                            <div className="col-md-3 mb-4">
                                                                                                                <li class="pt-2 pb-0">
                                                                                                                    <small class="text-dark">
                                                                                                                        {' '}
                                                                                                                        Sowing
                                                                                                                        Date{' '}
                                                                                                                    </small>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    {FirmDetails &&
                                                                                                                        FirmDetails &&
                                                                                                                        FirmDetails.sowing_date}
                                                                                                                </li>
                                                                                                            </div>
                                                                                                            <div className="col-md-3 mb-4">
                                                                                                                <li class="pt-2 pb-0">
                                                                                                                    <small class="text-dark">
                                                                                                                        {' '}
                                                                                                                        Soil
                                                                                                                        Test{' '}
                                                                                                                    </small>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    {FirmDetails &&
                                                                                                                        FirmDetails.soil_test &&
                                                                                                                        FirmDetails.soil_test.toString()}
                                                                                                                </li>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </ul>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                    </>

                                                                    {!addFarm ? (
                                                                        <>
                                                                            <div
                                                                                class="card"
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        '#f3f3f3 ',
                                                                                }}
                                                                            >
                                                                                <div class="card-body">
                                                                                    {FirmDetails.length &&
                                                                                        FirmDetails.map(
                                                                                            (
                                                                                                FirmDetails,
                                                                                                ind
                                                                                            ) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <h5 class="card-title d-flex justify-content-between store_edit">
                                                                                                            <span>
                                                                                                                Farm{' '}
                                                                                                                {ind +
                                                                                                                    1}
                                                                                                            </span>
                                                                                                            <a
                                                                                                                href="#"
                                                                                                                class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                            >
                                                                                                                <span class="material-icons-outlined mr-3">
                                                                                                                    edit
                                                                                                                </span>
                                                                                                                <span class="material-icons-outlined">
                                                                                                                    delete
                                                                                                                </span>
                                                                                                            </a>
                                                                                                        </h5>
                                                                                                        <ul class="list-unstyled mb-0 store_list">
                                                                                                            <div className="row">
                                                                                                                <div className="col-md-3 mb-4">
                                                                                                                    <li class="pt-2 pb-0">
                                                                                                                        <small class="text-dark">
                                                                                                                            Types
                                                                                                                            of
                                                                                                                            Crop{' '}
                                                                                                                        </small>
                                                                                                                    </li>
                                                                                                                    <li>
                                                                                                                        <img
                                                                                                                            src={
                                                                                                                                FirmDetails.corp_img
                                                                                                                            }
                                                                                                                            alt="crop"
                                                                                                                        />
                                                                                                                    </li>
                                                                                                                </div>
                                                                                                                <div className="col-md-3 mb-4">
                                                                                                                    <li class="pt-2 pb-0">
                                                                                                                        <small class="text-dark">
                                                                                                                            Irrigation
                                                                                                                            Source{' '}
                                                                                                                        </small>
                                                                                                                    </li>
                                                                                                                    <li>
                                                                                                                        {
                                                                                                                            FirmDetails.irrigation_source
                                                                                                                        }
                                                                                                                        {/* hlo */}
                                                                                                                    </li>
                                                                                                                </div>
                                                                                                                <div className="col-md-3 mb-4">
                                                                                                                    <li class="pt-2 pb-0">
                                                                                                                        <small class="text-dark">
                                                                                                                            Farmin
                                                                                                                            Equipment{' '}
                                                                                                                        </small>
                                                                                                                    </li>
                                                                                                                    <li>
                                                                                                                        {
                                                                                                                            FirmDetails.farm_equipements
                                                                                                                        }
                                                                                                                    </li>
                                                                                                                </div>
                                                                                                                <div className="col-md-3 mb-4">
                                                                                                                    <li class="pt-2 pb-0">
                                                                                                                        <small class="text-dark">
                                                                                                                            {' '}
                                                                                                                            Unit{' '}
                                                                                                                        </small>
                                                                                                                    </li>
                                                                                                                    <li>
                                                                                                                        {
                                                                                                                            FirmDetails.unit
                                                                                                                        }
                                                                                                                    </li>
                                                                                                                </div>
                                                                                                                <div className="col-md-3 mb-4">
                                                                                                                    <li class="pt-2 pb-0">
                                                                                                                        <small class="text-dark">
                                                                                                                            {' '}
                                                                                                                            Soil
                                                                                                                            Type{' '}
                                                                                                                        </small>
                                                                                                                    </li>
                                                                                                                    <li>
                                                                                                                        {
                                                                                                                            FirmDetails.soil_type
                                                                                                                        }
                                                                                                                    </li>
                                                                                                                </div>
                                                                                                                <div className="col-md-3 mb-4">
                                                                                                                    <li class="pt-2 pb-0">
                                                                                                                        <small class="text-dark">
                                                                                                                            {' '}
                                                                                                                            Sowing
                                                                                                                            Date{' '}
                                                                                                                        </small>
                                                                                                                    </li>
                                                                                                                    <li>
                                                                                                                        {FirmDetails &&
                                                                                                                            FirmDetails &&
                                                                                                                            FirmDetails.sowing_date}
                                                                                                                    </li>
                                                                                                                </div>
                                                                                                                <div className="col-md-3 mb-4">
                                                                                                                    <li class="pt-2 pb-0">
                                                                                                                        <small class="text-dark">
                                                                                                                            {' '}
                                                                                                                            Soil
                                                                                                                            Test{' '}
                                                                                                                        </small>
                                                                                                                    </li>
                                                                                                                    <li>
                                                                                                                        {FirmDetails &&
                                                                                                                            FirmDetails.soil_test &&
                                                                                                                            FirmDetails.soil_test.toString()}
                                                                                                                    </li>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </ul>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        )}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <h4>
                                                                                Create
                                                                                New
                                                                                Farm
                                                                            </h4>
                                                                            <div className="row">
                                                                                <div className="col-md-3 mb-4">
                                                                                    <FormControl
                                                                                        variant="standard"
                                                                                        fullWidth
                                                                                        className="mt-3"
                                                                                        style={{
                                                                                            marginTop:
                                                                                                '2%',
                                                                                        }}
                                                                                    >
                                                                                        <InputLabel id="demo-multiple-checkbox-label">
                                                                                            Crop
                                                                                            Name
                                                                                        </InputLabel>
                                                                                        {console.log(
                                                                                            'CropProduct',
                                                                                            CropProduct,
                                                                                            CropProductoption
                                                                                        )}
                                                                                        <Select
                                                                                            required
                                                                                            labelId="demo-multiple-checkbox-label"
                                                                                            id="demo-multiple-checkbox"
                                                                                            multiple
                                                                                            value={
                                                                                                cropTypes
                                                                                            }
                                                                                            onChange={
                                                                                                handleChange
                                                                                            }
                                                                                            input={
                                                                                                <Input
                                                                                                    label="Crop Name"
                                                                                                    variant="standard"
                                                                                                />
                                                                                            }
                                                                                            renderValue={(
                                                                                                selected
                                                                                            ) =>
                                                                                                selected.join(
                                                                                                    ', '
                                                                                                )
                                                                                            }
                                                                                            MenuProps={
                                                                                                MenuProps
                                                                                            }
                                                                                        >
                                                                                            {CropProductoption &&
                                                                                                CropProductoption.map(
                                                                                                    (
                                                                                                        name
                                                                                                    ) => (
                                                                                                        <MenuItem
                                                                                                            key={
                                                                                                                name.value
                                                                                                            }
                                                                                                            value={
                                                                                                                name.value
                                                                                                            }
                                                                                                        >
                                                                                                            {console.log(
                                                                                                                'CropProduct',
                                                                                                                name,
                                                                                                                name.value
                                                                                                            )}
                                                                                                            <Checkbox
                                                                                                                checked={
                                                                                                                    cropTypes.indexOf(
                                                                                                                        name.value
                                                                                                                    ) >
                                                                                                                    -1
                                                                                                                }
                                                                                                            />
                                                                                                            <ListItemText
                                                                                                                primary={
                                                                                                                    name.value
                                                                                                                }
                                                                                                            />
                                                                                                        </MenuItem>
                                                                                                    )
                                                                                                )}
                                                                                        </Select>
                                                                                    </FormControl>
                                                                                </div>
                                                                                <div
                                                                                    class="col-md-3"
                                                                                    style={{
                                                                                        marginTop:
                                                                                            '2%',
                                                                                    }}
                                                                                >
                                                                                    <FormControl
                                                                                        variant="standard"
                                                                                        fullWidth
                                                                                        className="mt-3"
                                                                                    >
                                                                                        <Selects
                                                                                            style={{
                                                                                                width: '270px',
                                                                                                height: '40px',
                                                                                            }}
                                                                                            // onChange={(e) => statechane(e)}
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setFarmer(
                                                                                                    {
                                                                                                        ...farmer,
                                                                                                        ['irrigationSource']:
                                                                                                            e.value,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                            // onChange={(e) => setFarmer({ ...farmer, ['irrigationSource']: e.value })}
                                                                                            options={
                                                                                                IrrigationSource
                                                                                            }
                                                                                            placeholder={
                                                                                                'Select irrigationSource'
                                                                                            }
                                                                                            //  value={inputBooking.firm}
                                                                                        />
                                                                                    </FormControl>
                                                                                </div>
                                                                                <div
                                                                                    class="col-md-3"
                                                                                    style={{
                                                                                        marginTop:
                                                                                            '2%',
                                                                                    }}
                                                                                >
                                                                                    <FormControl
                                                                                        variant="standard"
                                                                                        fullWidth
                                                                                        className="mt-3"
                                                                                    >
                                                                                        <Selects
                                                                                            style={{
                                                                                                width: '270px',
                                                                                                height: '40px',
                                                                                            }}
                                                                                            // onChange={(e) => statechane(e)}
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setFarmer(
                                                                                                    {
                                                                                                        ...farmer,
                                                                                                        ['farmEquipments']:
                                                                                                            e.value,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                            // onChange={(e) => setFarmer({ ...farmer, ['irrigationSource']: e.value })}
                                                                                            options={
                                                                                                farmEquipmentsValue
                                                                                            }
                                                                                            placeholder={
                                                                                                'Select farmEquipments'
                                                                                            }
                                                                                            //  value={inputBooking.firm}
                                                                                        />
                                                                                    </FormControl>
                                                                                </div>
                                                                                <div
                                                                                    class="col-md-3"
                                                                                    style={{
                                                                                        marginTop:
                                                                                            '2%',
                                                                                    }}
                                                                                >
                                                                                    <FormControl
                                                                                        variant="standard"
                                                                                        fullWidth
                                                                                        className="mt-3"
                                                                                    >
                                                                                        <Selects
                                                                                            style={{
                                                                                                width: '270px',
                                                                                                height: '40px',
                                                                                            }}
                                                                                            // onChange={(e) => statechane(e)}
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setFarmer(
                                                                                                    {
                                                                                                        ...farmer,
                                                                                                        ['selectUnit']:
                                                                                                            e.value,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                            // onChange={(e) => setFarmer({ ...farmer, ['irrigationSource']: e.value })}
                                                                                            options={
                                                                                                Unitvalue
                                                                                            }
                                                                                            placeholder={
                                                                                                'Select selectUnit'
                                                                                            }
                                                                                            //  value={inputBooking.firm}
                                                                                        />
                                                                                    </FormControl>
                                                                                </div>
                                                                                <div class="col-md-3">
                                                                                    <CssTextField
                                                                                        fullWidth
                                                                                        type="number"
                                                                                        id="totalLand"
                                                                                        label="Total Land"
                                                                                        variant="standard"
                                                                                        name="totalLand"
                                                                                        value={
                                                                                            farmer.totalLand
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setFarmer(
                                                                                                {
                                                                                                    ...farmer,
                                                                                                    [e
                                                                                                        .target
                                                                                                        .name]:
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div
                                                                                    class="col-md-3"
                                                                                    style={{
                                                                                        marginTop:
                                                                                            '2%',
                                                                                    }}
                                                                                >
                                                                                    <FormControl
                                                                                        variant="standard"
                                                                                        fullWidth
                                                                                        className="mt-3"
                                                                                    >
                                                                                        <Selects
                                                                                            style={{
                                                                                                width: '270px',
                                                                                                height: '40px',
                                                                                            }}
                                                                                            // onChange={(e) => statechane(e)}
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setFarmer(
                                                                                                    {
                                                                                                        ...farmer,
                                                                                                        ['soilType']:
                                                                                                            e.value,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                            // onChange={(e) => setFarmer({ ...farmer, ['irrigationSource']: e.value })}
                                                                                            options={
                                                                                                SoilType
                                                                                            }
                                                                                            placeholder={
                                                                                                'Select soilType'
                                                                                            }
                                                                                            //  value={inputBooking.firm}
                                                                                        />
                                                                                    </FormControl>
                                                                                </div>
                                                                                <div class="col-md-3">
                                                                                    <InputLabel
                                                                                        id="demo-simple-select-standard-label"
                                                                                        style={{
                                                                                            color: 'black',
                                                                                        }}
                                                                                    >
                                                                                        Sowing
                                                                                        Date
                                                                                    </InputLabel>
                                                                                    <CssTextField
                                                                                        type="date"
                                                                                        fullWidth
                                                                                        name="sowingDate"
                                                                                        variant="standard"
                                                                                        style={{
                                                                                            marginTop:
                                                                                                '10px',
                                                                                            marginBottom:
                                                                                                '10px',
                                                                                        }}
                                                                                        value={
                                                                                            farmer.sowingDate
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setFarmer(
                                                                                                {
                                                                                                    ...farmer,
                                                                                                    [e
                                                                                                        .target
                                                                                                        .name]:
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div
                                                                                    class="col-md-3"
                                                                                    style={{
                                                                                        marginTop:
                                                                                            '2%',
                                                                                    }}
                                                                                >
                                                                                    <FormControl
                                                                                        variant="standard"
                                                                                        fullWidth
                                                                                        className="mt-3"
                                                                                    >
                                                                                        <Selects
                                                                                            style={{
                                                                                                width: '270px',
                                                                                                height: '40px',
                                                                                            }}
                                                                                            // onChange={(e) => statechane(e)}
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setFarmer(
                                                                                                    {
                                                                                                        ...farmer,
                                                                                                        ['soilTest']:
                                                                                                            e.value,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                            // onChange={(e) => setFarmer({ ...farmer, ['irrigationSource']: e.value })}
                                                                                            options={
                                                                                                SoilTest
                                                                                            }
                                                                                            placeholder={
                                                                                                'Select soilTest'
                                                                                            }
                                                                                            //  value={inputBooking.firm}
                                                                                        />
                                                                                    </FormControl>
                                                                                </div>
                                                                                <div
                                                                                    class="col-md-12 text-end mt-2 mb-2"
                                                                                    style={{
                                                                                        marginLeft:
                                                                                            '3%',
                                                                                    }}
                                                                                >
                                                                                    <button
                                                                                        type="submit"
                                                                                        class="btn btn-primary"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            console.log(
                                                                                                e
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Submit
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            {/* </form> */}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Address Details */}
                                                    <div
                                                        class="tab-pane"
                                                        id="bottom-tab5"
                                                    >
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="card-body card-body_1">
                                                                    <div className="row">
                                                                        <div class="col-md-12 text-end justify-space-between mb-4"></div>
                                                                    </div>
                                                                    <div
                                                                        class="card"
                                                                        style={{
                                                                            backgroundColor:
                                                                                '#f3f3f3 ',
                                                                        }}
                                                                    >
                                                                        <div class="card-body">
                                                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                                                <span>
                                                                                    Address
                                                                                </span>
                                                                                <a
                                                                                    href="#"
                                                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                                                >
                                                                                    <span class="material-icons-outlined mr-3">
                                                                                        edit
                                                                                    </span>
                                                                                    <span class="material-icons-outlined">
                                                                                        delete
                                                                                    </span>
                                                                                </a>
                                                                            </h5>
                                                                            <ul class="list-unstyled mb-0 store_list">
                                                                                <div className="row">
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                State{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.state
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                District{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.district
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                Sub
                                                                                                District{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.subDistrict
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                {' '}
                                                                                                Village{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.village
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                {' '}
                                                                                                Pincode{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.pincode
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                {' '}
                                                                                                Address{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.fullAddress
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-12 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <h5 class="card-title d-flex justify-content-between mt-4">
                                                                                                <span>
                                                                                                    Location
                                                                                                </span>
                                                                                                <a
                                                                                                    href="#"
                                                                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                >
                                                                                                    <span class="material-icons-outlined">
                                                                                                        edit
                                                                                                    </span>
                                                                                                </a>
                                                                                            </h5>
                                                                                        </li>
                                                                                        <li>
                                                                                            <Map2
                                                                                                center={{
                                                                                                    lat: +Details.lat,
                                                                                                    lng: +Details.long,
                                                                                                }}
                                                                                                height="300px"
                                                                                                zoom={
                                                                                                    15
                                                                                                }
                                                                                            />
                                                                                        </li>
                                                                                    </div>
                                                                                </div>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="tab-pane"
                                                        id="bottom-tab6"
                                                    >
                                                        <EditFarmers/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Settings
