import React from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

function TimeslotSettings() {
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Timeslot Settings</h5>
                </div>
                <div class="card-body">
                    <form>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="col-md-4">
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        className="mt-3"
                                    >
                                        <InputLabel
                                            id="demo-simple-select-standard-label"
                                            style={{ color: 'black' }}
                                        >
                                            Timeslot Starts from
                                        </InputLabel>
                                        <Select
                                            required
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            //  value={age}
                                            //  onChange={handleChange}
                                            label="Firm"
                                            color="success"
                                        >
                                            <MenuItem value="">
                                                <em>Select value</em>
                                            </MenuItem>
                                            <MenuItem value={10}>
                                                Today
                                            </MenuItem>
                                            <MenuItem value={20}>
                                                Tomorrow
                                            </MenuItem>
                                            <MenuItem value={30}>
                                                +2 days
                                            </MenuItem>
                                            <MenuItem value={30}>
                                                +3 days
                                            </MenuItem>
                                            <MenuItem value={30}>
                                                +4 days
                                            </MenuItem>
                                            <MenuItem value={30}>
                                                +5 days
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default TimeslotSettings
