import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import { visuallyHidden } from '@mui/utils'
import axios from 'axios'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { Button } from 'react-bootstrap'
import Select from 'react-select'
import ProductUpdateDialog from '../InputSupplier/ProductUpdateDialog'
import ProductForm from '../../../../additionalProduct'

function createData(
    _id,
    id,
    title,
    catelogName,
    catalogue,
    categoryName,
    subCategoryName,
    brandName,
    brandImage,
    productName,
    productImage,sku,hsn,description,
    commision,
    gst,
    unit,
    price,
    maxPrice,
    isUnlimited,
    discount,
    stock,
    createdAt
) {
    return {
        _id,
        id,
        title,
        catelogName,
        catalogue,
        categoryName,
        subCategoryName,
        brandName,
        brandImage,
        productName,
        productImage,sku,hsn,description,
        commision,
        gst,
        unit,
        price,
        maxPrice,
        isUnlimited,
        discount,
        stock,
        createdAt,
    }
}

const headCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'ID',
    },
 
    {
        id: 'categoryName',
        numeric: true,
        disablePadding: false,
        label: 'Category',
    },
    {
        id: 'subCategoryName',
        numeric: true,
        disablePadding: false,
        label: 'Subcategory Name',
    },
    {
        id: 'brandName',
        numeric: true,
        disablePadding: false,
        label: 'Brand',
    },
    {
        id: 'brandImage',
        numeric: true,
        disablePadding: false,
        label: 'Brand Image',
    },
    {
        id: 'productName',
        numeric: true,
        disablePadding: false,
        label: 'Product Name',
    },

    {
        id: 'productImage',
        numeric: true,
        disablePadding: false,
        label: 'image',
    },
    {
        id: 'sku',
        numeric: true,
        disablePadding: false,
        label: 'SKU',
    },
    {
        id: 'hsn',
        numeric: true,
        disablePadding: false,
        label: 'HSN Code',
    },
    {
        id: 'description',
        numeric: true,
        disablePadding: false,
        label: 'Description',
    },
    {
        id: '',
        numeric: true,
        disablePadding: false,
        label: 'View Commision',
    },
    {
        id: '',
        numeric: true,
        disablePadding: false,
        label: 'Add Commision',
    },
    {
        id: '',
        numeric: true,
        disablePadding: false,
        label: 'Edit Product',
    },
    {
        id: '',
        numeric: true,
        disablePadding: false,
        label: 'Add GST',
    },
    {
        id: '',
        numeric: true,
        disablePadding: false,
        label: 'View GST',
    },

    // {
    //     id: 'maxPrice',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Purchase Price',
    // },
  
]

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow style={{ backgroundColor: '#f3f3f3' }}>
                {/* <TableCell padding="checkbox"></TableCell> */}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        // align={headCell.numeric ? 'right' : 'left'}
                        align={headCell.numeric ? 'center' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
}

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props

    return (
        <Toolbar>
            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Orders
            </Typography>

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    )
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
}

export default function InputSupplierTable(props) {
    const inputs = props.inputs
    console.log('InputSuppliers : ', inputs)
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('calories')
    const [selected, setSelected] = React.useState([])
    const [page, setPage] = React.useState(0)
    const [dense, setDense] = React.useState(false)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const [opens, setOpens] = React.useState(false)
    const [taxtype, setTaxType] = React.useState('')
    const [tableType,setTableType]=React.useState("")
    const [incgst, setIncgst] = React.useState('')
    const [percentage, setPercentage] = React.useState('')
    const [feeAmount, setFeeamount] = React.useState('')
    const [gstAmount, setGSTAmount] = React.useState('')
    const [percentageFlatfee,setPercentageFlatfee]=React.useState("")
    const [flatGSTpercentage,setFlatGSTpercentage]=React.useState("")
    const [taxdata, setTaxes] = React.useState([])
    const [viewCommision,setViewCommison]=React.useState({})
    const [commissionOpen,setCommisionOpen]=React.useState(false)
    const [id,setId]=React.useState("")
    const [editProduct,setEditProduct]=React.useState();
    const [editOpen,setEditOpen]=React.useState(false)
    const [gstOpen,setGSTOpensed]=React.useState(false)
    const [description,setDescription]=React.useState(false)
    const [descriptionTitle,setDescriptionTitle]=React.useState("")
  
    var rows = inputs.map((input, index) =>
        createData(
            input._id,
            index + 1,
            input.title,
            input.catelogName,
            input.catalogue,
            input.categoryName,
            input.subCategoryName,
            input.brandName,
            input.brandImage,
            input.productName,
            input.productImage,
            input.sku,
            input.hsn,
            input.description,
            // input.unit,
            // input.price,
            // input.maxPrice,
            // input.isUnlimited,
            // input.discount,
            // input.stock,
            // `${input.createdAt.slice(0, 10)}`,
            // input._id
            input.commision,
            input.gst,
        )
    )


    const handleClose = (row) => {

        // setVariantData(row)
        console.log(row)
        setId(row._id)
        // setProductName(row.productName)
        setOpens(true)
    }
    const handleEditProduct = (row) => {
        // setVariantData(row)
        console.log(row)
        setEditProduct(row)
        // setProductName(row.productName)
        setEditOpen(true)
    }
    const addGST = (row) => {
        // setVariantData(row)
        console.log(row)
        setEditProduct(row)
        setId(row._id)
        // setProductName(row.productName)
        setGSTOpensed(true)
    }
    const onSubmit = (e) => {
        var data = {
            _id:id,
            type:taxtype.value,
            incGstBookingFee: incgst,
            gst: percentage,
            bookingFee: feeAmount,
            gstAmount: gstAmount,
        }
        var flatdata={
            _id:id,
                type:taxtype.value,
                PercentageIncGstBookingFee: percentageFlatfee,
                gstPercentage: flatGSTpercentage,
               
            
        }

        const token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        if(taxtype.value==="flat"){
        axios
            .post(
                'https://prod-api.krishione.com/farmer-function/set-commision',
                data,
                config
            )
            .then((res) => {
                alert('Success!')
                console.log(res.data)
            })
        }
        if(taxtype.value==="percentage"){
            axios
                .post(
                    'https://prod-api.krishione.com/farmer-function/set-commision',
                    flatdata,
                    config
                )
                .then((res) => {
                    alert('Success!')
                    console.log(res.data)
                })
            }
    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name)
            setSelected(newSelecteds)
            return
        }
        setSelected([])
    }

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            )
        }

        setSelected(newSelected)
    }
    const taxtypes = [
        {
            label: 'Percentage',
            value: 'percentage',
        },
        {
            label: 'flat',
            value: 'flat',
        },
    ]
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleChangeDense = (event) => {
        setDense(event.target.checked)
    }
    const handlecloses = () => {
        setOpens(!opens)
    }
    const commissionCloses = () => {
        setCommisionOpen(false)
    }
    const calculate = (perc) => {
        setPercentage(perc)
        const result = incgst / (1 + perc / 100)
        setGSTAmount(Number(incgst - result).toFixed(2))
        setFeeamount(result.toFixed(2))
    }
    const handleCommision=(row)=>{
        setCommisionOpen(true)
        setViewCommison(row)
    }
    const handleDescription=(row)=>{
        setDescription(true)
        setDescriptionTitle(row)
    }
    const isSelected = (name) => selected.indexOf(name) !== -1
    const handleGST = () => {
        setGSTOpensed(!gstOpen)
    }
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0
        const handleEdit = () => {
            setEditOpen(!editOpen)
        }
    return (
        <>
         <Dialog className='mapmodel'  style={{
                    zIndex: 100,
                }} open={description} onClose={()=>setDescription(false)}>
            <DialogTitle>
                    {/* <b>Add Variant for {productName}</b> */}
                    {/* <b>Edit Product</b> */}

                </DialogTitle>
                <DialogContent>
                <div className='htmldescription' dangerouslySetInnerHTML={{ __html: descriptionTitle }} />
             

                </DialogContent>
            </Dialog>
          <Dialog className='mapmodel'  style={{
                    zIndex: 100,
                }} open={gstOpen} onClose={handleGST}>
            <DialogTitle>
                  

                </DialogTitle>
                <DialogContent>
                    <ProductForm  product={editProduct}/>
             

                </DialogContent>
            </Dialog>
         <Dialog className='mapmodel'  style={{
                    zIndex: 100,
                }} open={editOpen} onClose={handleEdit}>
            <DialogTitle>
                    {/* <b>Add Variant for {productName}</b> */}
                    {/* <b>Edit Product</b> */}

                </DialogTitle>
                <DialogContent>
                <ProductUpdateDialog  product={editProduct}/>

                </DialogContent>
            </Dialog>
         <Dialog
                className="mapmodel"
                open={commissionOpen}
                onClose={commissionCloses}
                style={{
                    zIndex: 100,
                }}
            >
                <DialogTitle>
                    <b> Commision Type:   {viewCommision.type}</b>
                </DialogTitle>
                <DialogContent>
                <div class="card" style={{width:"500px"}}>
                <div class="card-header">
                    <div class="col-md-4">
                      

                       
                    </div>
                </div>

                <div class="card-body">
                    <div class="row tx_bdr">
                    {viewCommision.type === 'flat' ? 
                        <div>
                     
                        <div class="col-md-4">
                            <label
                                for="Title"
                                class="p-l-0 col-form-label input-label"
                            >
                                Flat Booking fees Inc. GSt
                            </label>
                            <input
                                class="form-control"
                                type="number"
                                value={viewCommision.incGstBookingFee}
                                onChange={(e) => setIncgst(e.target.value)}
                            />
                        </div>
                        <div class="col-md-4">
                            <label
                                for="Percentage"
                                class="p-l-0 col-form-label input-label"
                            >
                                GST Percentage
                            </label>
                            <input
                                class="form-control"
                                type="number"
                                value={viewCommision.gst}
                                onChange={(e) => calculate(e.target.value)}
                            />
                        </div>
                        <div class="col-md-4" >
                            <label
                                style={{ margintop: '24px' }}
                                for="Percentage"
                                class="p-l-0 col-form-label input-label"
                            >
                                Booking Fees
                            </label>
                            <input
                                class="form-control"
                                type="Text"
                                value={viewCommision.bookingFee}
                            />
                        </div>
                        <div class="col-md-4">
                            <label
                                for="Percentage"
                                class="p-l-0 col-form-label input-label"
                            >
                                GST Amount
                            </label>
                            <input
                                class="form-control"
                                type="number"
                                value={viewCommision.gstAmount}
                            />
                        </div>
                        </div> :null}

                        <br />
                        {viewCommision.type === 'percentage' ? (
                            <div>
                               
                                <div class="col-md-4">
                                    <label
                                        for="Percentage"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        % Booking fees Inc. GST
                                    </label>
                                    <input
                                        class="form-control"
                                        type="number"
                                        value={viewCommision.PercentageIncGstBookingFee}
                                        onChange={(e) => setPercentageFlatfee(e.target.value)}
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label
                                        for="Percentage"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        GST %
                                    </label>
                                    <input
                                        class="form-control"
                                        type="number"
                                        value={viewCommision.gstPercentage}
                                        onChange={(e) => setFlatGSTpercentage(e.target.value)}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div class="row tx_bdr">
                      
                        <button
                            variant="contained"
                            onClick={(e) => console.log(e)}
                            component="span"
                            color="success"
                            className="btn btn-primary mt-3"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
                </DialogContent>
            </Dialog>
            <Dialog
                className="mapmodel"
                open={opens}
                onClose={handlecloses}
                style={{
                    zIndex: 100,
                }}
            >
                <DialogTitle>
                    <b>Add Commision</b>
                </DialogTitle>
                <DialogContent>
                <div class="card" style={{width:"500px"}}>
                <div class="card-header">
                    <div class="col-md-4">
                        <p>Select Commision Type</p>

                        <Select
                            style={{
                                width: '270px',
                                height: '40px',
                            }}
                            placeholder={'Select Role'}
                            value={taxtype}
                            onChange={(e) => setTaxType(e)}
                            options={taxtypes}
                        />
                    </div>
                </div>

                <div class="card-body">
                    <div class="row tx_bdr">
                    {taxtype.value === 'flat' ? 
                        <div>
                     
                        <div class="col-md-4">
                            <label
                                for="Title"
                                class="p-l-0 col-form-label input-label"
                            >
                                Flat Booking fees Inc. GSt
                            </label>
                            <input
                                class="form-control"
                                type="number"
                                value={incgst}
                                onChange={(e) => setIncgst(e.target.value)}
                            />
                        </div>
                        <div class="col-md-4">
                            <label
                                for="Percentage"
                                class="p-l-0 col-form-label input-label"
                            >
                                GST Percentage
                            </label>
                            <input
                                class="form-control"
                                type="number"
                                value={percentage}
                                onChange={(e) => calculate(e.target.value)}
                            />
                        </div>
                        <div class="col-md-4" >
                            <label
                                style={{ margintop: '24px' }}
                                for="Percentage"
                                class="p-l-0 col-form-label input-label"
                            >
                                Booking Fees
                            </label>
                            <input
                                class="form-control"
                                type="Text"
                                value={feeAmount}
                            />
                        </div>
                        <div class="col-md-4">
                            <label
                                for="Percentage"
                                class="p-l-0 col-form-label input-label"
                            >
                                GST Amount
                            </label>
                            <input
                                class="form-control"
                                type="number"
                                value={gstAmount}
                            />
                        </div>
                        </div> :null}

                        <br />
                        {taxtype.value === 'percentage' ? (
                            <div>
                               
                                <div class="col-md-4">
                                    <label
                                        for="Percentage"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        % Booking fees Inc. GST
                                    </label>
                                    <input
                                        class="form-control"
                                        type="number"
                                        value={percentageFlatfee}
                                        onChange={(e) => setPercentageFlatfee(e.target.value)}
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label
                                        for="Percentage"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        GST %
                                    </label>
                                    <input
                                        class="form-control"
                                        type="number"
                                        value={flatGSTpercentage}
                                        onChange={(e) => setFlatGSTpercentage(e.target.value)}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div class="row tx_bdr">
                      
                        <button
                            variant="contained"
                            onClick={(e) => onSubmit(e)}
                            component="span"
                            color="success"
                            className="btn btn-primary mt-3"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
                </DialogContent>
            </Dialog>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Box
                sx={{
                    width: '100%',
                    marginBottom: '20px',
                    paddingRight: '0px',
                    paddingLeft: '0px',
                }}
            >
                <Paper sx={{ width: '100%', mb: 2, backgroundColor: '#FFF' }}>
                    {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody style={{ marginBottom: '20px' }}>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(
                                            row.name
                                        )
                                        const labelId = `enhanced-table-checkbox-${index}`

                                        return (
                                            <>
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    aria-checked={
                                                        isItemSelected
                                                    }
                                                    tabIndex={-1}
                                                    key={row.name}
                                                    selected={isItemSelected}
                                                >
                                                    {/* <TableCell padding="checkbox"></TableCell> */}
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        {row.id}
                                                    </TableCell>
                                                    {/* <TableCell align="center">{row.title}</TableCell> */}
                                                    {/* <TableCell align="center">
                                                        {row.catelogName}
                                                    </TableCell> */}
                                                    {/* <TableCell align="right"><a href={`/InputSupplierDetails/${row._id}`}><u>{row.mobile}</u></a></TableCell> */}
                                                    <TableCell align="center">
                                                        {row.categoryName}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.subCategoryName}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.brandName}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <img
                                                            src={
                                                                row.brandImage
                                                            }
                                                            style={{
                                                                width: '100px',
                                                            }}
                                                        />
                                                        </TableCell>
                                                    <TableCell align="center">
                                                        {row.productName}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                    <img
                                                            src={
                                                                row.productImage
                                                            }
                                                            style={{
                                                                width: '100px',
                                                            }}
                                                        />   
                                                        
                                                        </TableCell>  
                                                        <TableCell align="center">
                                                        {row.sku}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.hsn}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.description? <Button
                                                            onClick={() =>
                                                                handleDescription(row.description)
                                                            }
                                                        >
                                                            VIEW
                                                        </Button>:""}
                                                    </TableCell>                                        
                                                        
                                                                                                         <TableCell align="center">
                                                        {row.commision? <Button
                                                            onClick={() =>
                                                                handleCommision(row.commision)
                                                            }
                                                        >
                                                            VIEW
                                                        </Button>:""}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Button
                                                            onClick={() =>
                                                                handleClose(row)
                                                            }
                                                        >
                                                            ADD
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                                <Button
                                                                    onClick={() =>
                                                                        handleEditProduct(
                                                                            row
                                                                        )
                                                                    }
                                                                >
                                                                    EDIT
                                                                </Button>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Button
                                                                    onClick={() =>
                                                                        addGST(
                                                                            row
                                                                        )
                                                                    }
                                                                >
                                                                     ADD GST
                                                                </Button>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {row.gst ? (
                                                                    <Button
                                                                        onClick={() =>
                                                                            addGST(
                                                                                row
                                                                            )
                                                                        }
                                                                    >
                                                                        VIEW GST
                                                                    </Button>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height:
                                                (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
        </>
    )
}
