import React, { useState, useEffect } from 'react'
import { CssTextField } from '../../../Styles'
import InpCategories from './ProductInpCategory'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Switch from '@mui/material/Switch'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AddIcon from '@mui/icons-material/Add'
import { API } from '../../../../API'
import axios from 'axios'
import Swal from 'sweetalert2'
import ProductInputTable from './ProductInputTable'

const Addinputcatelogclear = {
    title: 'IncentivesCatelog',
    catelogName: 'InputSupplierCatelog',
    categoryName: '',
}
const Addinputsubcategoryclear = {
    title: 'IncentivesCatelog',
    catelogName: 'InputSupplierCatelog',
    categoryName: '',
    subCategoryName: '',
}
const Addinputunitclear = {
    title: 'IncentivesCatelog',
    catelogName: 'InputSupplierCatelog',
    unit: '',
}
const Createincentiveclear = {
    title: 'IncentivesCatelog',
    catelogName: 'InputSupplierCatelog',
    categoryName: '',
    subCategoryName: '',
    incentiveUnit: '',
    incentiveAmount: '',
}

function InputCatelog() {
    const [showCategory, setShowCategory] = useState(false)
    const [booking, setBooking] = useState(false)
    const [ProductInput, setProductInput] = useState([])
    const [Categorylist, setCategorylist] = useState([])
    const [subCategorylist, setsubCategorylist] = useState([])
    const [categoryName, setCategoryName] = useState('')
    const [Unitlist, setUnitlist] = useState([])
    useEffect(() => {
        // getState();
        getProductInput()
        getCategoryList()
    }, [])

    const getProductInput = async () => {
        const { data } = await API.get(
            `/getIncentiveCatelog?title=IncentivesCatelog&catelogName=InputSupplierCatelog&type=2`
        )
        setProductInput(data?.result)
    }
    const [Addinputunit, setAddinputunit] = useState({
        title: 'IncentivesCatelog',
        catelogName: 'InputSupplierCatelog',
        unit: '',
    })
    const unitsubmit = async () => {
        console.log('submit', Addinputunit)
        try {
            const { data } = await API.post('/createUnit', Addinputunit)

            console.log('data', data)
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)
                setAddinputunit(Addinputunitclear)
                // setAddinputsubcategory()
                // setCropTypes([]);
            } else {
                setAddinputunit(Addinputunitclear)
                // setAddinputsubcategory()
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setAddinputunit(Addinputunitclear)
            // setAddinputsubcategory()
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    const [Addinputsubcategory, setAddinputsubcategory] = useState({
        title: 'IncentivesCatelog',
        catelogName: 'InputSupplierCatelog',
        categoryName: '',
        subCategoryName: '',
    })

    const subCategorysubmit = async () => {
        console.log('submit', Addinputsubcategory)
        try {
            const { data } = await API.post(
                '/createSubCategory',
                Addinputsubcategory
            )

            console.log('data', data)
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)
                setAddinputsubcategory(Addinputsubcategoryclear)
                // setAddinputsubcategory()
                // setCropTypes([]);
            } else {
                setAddinputsubcategory(Addinputsubcategoryclear)
                // setAddinputsubcategory()
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setAddinputsubcategory(Addinputsubcategoryclear)
            // setAddinputsubcategory()
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    const [AddinputCategory, setAddinputCategory] = useState({
        title: 'IncentivesCatelog',
        catelogName: 'InputSupplierCatelog',
        categoryName: '',
    })

    const AddCategorysubmit = async () => {
        // console.log("machine",machine)
        console.log('submit', AddinputCategory)
        try {
            const { data } = await API.post('/createCategory', AddinputCategory)

            console.log('data', data)
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)
                getCategoryList()
                setAddinputCategory(Addinputcatelogclear)
                // setAddinputsubcategory()
                // setCropTypes([]);
            } else {
                setAddinputCategory(Addinputcatelogclear)
                // setAddinputsubcategory()
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setAddinputCategory(Addinputcatelogclear)
            // setAddinputsubcategory()
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    //   create incentive
    const [Createincentive, setCreateincentive] = useState({
        title: 'IncentivesCatelog',
        catelogName: 'InputSupplierCatelog',
        categoryName: '',
        subCategoryName: '',
        incentiveUnit: '',
        incentiveAmount: '',
    })

    const Createincentivesubmit = async () => {
        console.log('submit', Createincentive)
        try {
            const { data } = await API.post(
                '/createIncentiveCatelog',
                Createincentive
            )

            console.log('data', data)
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)
                getProductInput()
                setCreateincentive(Createincentiveclear)
                // setAddinputsubcategory()
                // setCropTypes([]);
            } else {
                setCreateincentive(Createincentiveclear)
                // setAddinputsubcategory()
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setCreateincentive(Createincentiveclear)
            // setAddinputsubcategory()
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    const getCategoryList = async () => {
        const { data } = await API.get(
            `/getCategory?type=0&title=IncentivesCatelog&catelogName=InputSupplierCatelog`
        )
        // console.log("hkjjhj",data)
        setCategorylist(data?.result)
    }

    const handleCategoryChange = (e) => {
        console.log('value', e.target.value)
        setCategoryName(e.target.value)
        setCreateincentive({ ...Createincentive, categoryName: e.target.value })
        getSubCategoryList(e.target.value)
    }
    const getSubCategoryList = async (val) => {
        const { data } = await API.get(
            `/getSubCategory?type=0&title=IncentivesCatelog&catelogName=InputSupplierCatelog&categoryName=` +
                val
        )
        console.log('hkjjhj', data)
        setsubCategorylist(data?.result)
    }
    const getunitList = async (val) => {
        const { data } = await API.get(
            `/getUnit?type=0&title=IncentivesCatelog&catelogName=InputSupplierCatelog`
        )
        console.log('hkjjhj', data)
        setUnitlist(data?.result)
    }
    const handleSubCategoryChange = (e) => {
        setCreateincentive({
            ...Createincentive,
            subCategoryName: e.target.value,
        })
        getunitList(e.target.value)
    }

    return (
        <>
            <div class="row align-items-center mb-3">
                <div class="col">
                    <h3 class="page-title">Input Supplier Incentives</h3>
                </div>
                {!showCategory ? (
                    <>
                        <div class="col-auto">
                            <button
                                class="btn btn-primary filter-btn"
                                id="openCategory"
                            >
                                <i class="fas fa-plus"></i>{' '}
                                <span> Add Category</span>
                            </button>
                        </div>
                        <div class="col-auto">
                            <button
                                class="btn btn-primary filter-btn"
                                id="openSubCategory"
                            >
                                <i class="fas fa-plus"></i>{' '}
                                <span> Add SubCategory</span>
                            </button>
                        </div>
                        <div class="col-auto">
                            <button
                                class="btn btn-primary filter-btn"
                                id="openProduct"
                            >
                                <i class="fas fa-plus"></i>{' '}
                                <span> Add Unit</span>
                            </button>
                        </div>
                        <div class="col-auto">
                            <button
                                class="btn btn-primary filter-btn"
                                onClick={(e) => setShowCategory(true)}
                            >
                                <i class="fas fa-eye"></i>{' '}
                                <span> View Category</span>
                            </button>
                        </div>
                    </>
                ) : (
                    <div class="col-auto">
                        <button
                            class="btn btn-primary filter-btn"
                            onClick={(e) => setShowCategory(false)}
                        >
                            <i class="fas fa-left"></i> <span> Back</span>
                        </button>
                    </div>
                )}
                {/* <div class="col-auto">
            {showCategory ? 
                 <button type="button" className="btn btn-secondary" onClick={e=>setShowCategory(false)}>
                  <AddIcon size="small" /> Create Category 
                 </button>
                 :
                 <button class="btn btn-secondary filter-btn" onClick={e=>setShowCategory(true)}>
            	   <i class="fas fa-eye"></i> <span> View Categories</span>
                 </button>
                }         
          </div> */}
            </div>

            {showCategory === false ? (
                <>
                    {/* Add Category */}
                    <div id="category" class="card filter-card ">
                        <form
                            class="card-body pb-0"
                            onSubmit={(e) => e.preventDefault()}
                        >
                            <div class="row">
                                <h5 class="card-title">Create Category</h5>
                                <div class="col-md-4 select2-container">
                                    <div
                                        class="form-group"
                                        data-select2-id="11"
                                    >
                                        <CssTextField
                                            required
                                            fullWidth
                                            id="InpCategory"
                                            label="Category"
                                            variant="standard"
                                            name="InpCategory"
                                            value={
                                                AddinputCategory.categoryName
                                            }
                                            onChange={(e) =>
                                                setAddinputCategory({
                                                    ...AddinputCategory,
                                                    categoryName:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2">
                                    <div class="form-group">
                                        <button
                                            type="submit"
                                            class="btn btn-primary mt-26"
                                            onClick={() => AddCategorysubmit()}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    {/* Add SubCategory */}
                    <div id="subCategory" class="card filter-card">
                        <form
                            class="card-body pb-0"
                            onSubmit={(e) => e.preventDefault()}
                        >
                            <div class="row">
                                <h5 class="card-title">Create SubCategory</h5>
                                <div class="col-md-4">
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        className="mt-3"
                                    >
                                        <InputLabel
                                            id="demo-simple-select-standard-label"
                                            style={{ color: 'black' }}
                                        >
                                            Category
                                        </InputLabel>
                                        <Select
                                            required
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            //  value={age}
                                            //  onChange={handleChange}
                                            value={
                                                Addinputsubcategory.categoryName
                                            }
                                            onChange={(e) =>
                                                setAddinputsubcategory({
                                                    ...Addinputsubcategory,
                                                    categoryName:
                                                        e.target.value,
                                                })
                                            }
                                            label="Category"
                                            color="success"
                                        >
                                            {Categorylist &&
                                                Categorylist.map((val, ind) => {
                                                    // {console.log("Categorylist",val)}
                                                    return (
                                                        <MenuItem
                                                            value={
                                                                val.categoryName
                                                            }
                                                        >
                                                            {val.categoryName}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div class="col-md-4 select2-container">
                                    <div
                                        class="form-group"
                                        data-select2-id="11"
                                    >
                                        <CssTextField
                                            required
                                            fullWidth
                                            id="InpSubCategory"
                                            label="SubCategory"
                                            variant="standard"
                                            name="InpSubCategory"
                                            value={
                                                Addinputsubcategory.subCategoryName
                                            }
                                            onChange={(e) =>
                                                setAddinputsubcategory({
                                                    ...Addinputsubcategory,
                                                    subCategoryName:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2">
                                    <div class="form-group">
                                        <button
                                            type="submit"
                                            class="btn btn-primary mt-26"
                                            onClick={() => subCategorysubmit()}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    {/* Add Unit */}
                    <div id="product" class="card filter-card">
                        <form
                            class="card-body pb-0"
                            onSubmit={(e) => e.preventDefault()}
                        >
                            <div class="row">
                                <h5 class="card-title">Create Unit</h5>
                                <div class="col-md-4">
                                    <CssTextField
                                        required
                                        fullWidth
                                        id="CUnit"
                                        label="Unit"
                                        variant="standard"
                                        name="unit"
                                        value={Addinputunit.unit}
                                        onChange={(e) =>
                                            setAddinputunit({
                                                ...Addinputunit,
                                                unit: e.target.value,
                                            })
                                        }
                                    />
                                    {/* <FormControl variant="standard" fullWidth className="mt-3">
                <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>Unit</InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                 //  value={age}
                 //  onChange={handleChange}
                 value={Addinputunit.unit}
                 onChange={(e)=>setAddinputunit({...Addinputunit,unit:e.target.value})}
                
                  label="Category"
                  color="success"
                >
                  <MenuItem value="">
                    <em>- Select Unit - </em>
                  </MenuItem>
                  <MenuItem value={"test"}>test</MenuItem>
                  <MenuItem value={"Krishivan Input"}>Krishivan Input</MenuItem>
                </Select>
              </FormControl> */}
                                </div>
                                <div class="col-sm-2 col-md-2">
                                    <div class="form-group">
                                        <button
                                            type="submit"
                                            class="btn btn-primary mt-26"
                                            onClick={() => unitsubmit()}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="card card-table p-10-25">
                        <form action="#" onSubmit={(e) => e.preventDefault()}>
                            <div className="mt-3">
                                <h5 class="card-title">Create Incentive</h5>
                                <div class="row mb-4">
                                    <div class="col-md-3">
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            className="mt-3"
                                        >
                                            <InputLabel
                                                id="demo-simple-select-standard-label"
                                                style={{ color: 'black' }}
                                            >
                                                Category
                                            </InputLabel>
                                            <Select
                                                required
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                //  value={age}
                                                //  onChange={handleChange}
                                                value={
                                                    Createincentive.categoryName
                                                }
                                                onChange={(e) =>
                                                    handleCategoryChange(e)
                                                }
                                                //  onChange={(e)=>setCreateincentive({...Createincentive,categoryName:e.target.value})}
                                                label="Firm"
                                                color="success"
                                            >
                                                {Categorylist &&
                                                    Categorylist.map(
                                                        (val, ind) => {
                                                            // {console.log("Categorylist",val)}
                                                            return (
                                                                <MenuItem
                                                                    value={
                                                                        val.categoryName
                                                                    }
                                                                >
                                                                    {
                                                                        val.categoryName
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        }
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div class="col-md-3">
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            className="mt-3"
                                        >
                                            <InputLabel
                                                id="demo-simple-select-standard-label"
                                                style={{ color: 'black' }}
                                            >
                                                SubCategory
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                //  value={age}
                                                //  onChange={handleChange}
                                                value={
                                                    Createincentive.subCategoryName
                                                }
                                                onChange={(e) =>
                                                    handleSubCategoryChange(e)
                                                }
                                                //   onChange={(e)=>setCreateincentive({...Createincentive,subCategoryName:e.target.value})}
                                                label="Booking Mode"
                                                color="success"
                                            >
                                                {subCategorylist &&
                                                    subCategorylist.map(
                                                        (val, ind) => {
                                                            // {console.log("Categorylist",val)}
                                                            return (
                                                                <MenuItem
                                                                    value={
                                                                        val.subCategoryName
                                                                    }
                                                                >
                                                                    {
                                                                        val.subCategoryName
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        }
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div class="col-md-3">
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            className="mt-3"
                                        >
                                            <InputLabel
                                                id="demo-simple-select-standard-label"
                                                style={{ color: 'black' }}
                                            >
                                                Incentive Unit
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                //  value={age}
                                                //  onChange={handleChange}
                                                value={
                                                    Createincentive.incentiveUnit
                                                }
                                                onChange={(e) =>
                                                    setCreateincentive({
                                                        ...Createincentive,
                                                        incentiveUnit:
                                                            e.target.value,
                                                    })
                                                }
                                                label="Booking Mode"
                                                color="success"
                                            >
                                                {Unitlist &&
                                                    Unitlist.map((val, ind) => {
                                                        // {console.log("Categorylist",val)}
                                                        return (
                                                            <MenuItem
                                                                value={val.unit}
                                                            >
                                                                {val.unit}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div class="col-md-3">
                                        <CssTextField
                                            required
                                            fullWidth
                                            id="unit"
                                            label="Incentive Amount"
                                            variant="standard"
                                            name="unit"
                                            value={
                                                Createincentive.incentiveAmount
                                            }
                                            onChange={(e) =>
                                                setCreateincentive({
                                                    ...Createincentive,
                                                    incentiveAmount:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="row justify-content-end mb-3">
                                    <div className="col-md-1">
                                        <button
                                            type="submit"
                                            className="btn btn-danger"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                    <div className="col-md-1">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={() =>
                                                Createincentivesubmit()
                                            }
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    {/* <div class="row">
       <div class="col-sm-12">
       <h3 className="mb-3">List</h3>
          <div class="card card-table">
             <div class="card-body">
                <div class="table-responsive">
                   <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                      <div class="row">
                         <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="DataTables_Table_0_length">
                               <label>
                                  Show 
                                  <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" class="custom-select custom-select-sm form-control form-control-sm">
                                     <option value="10">10</option>
                                     <option value="25">25</option>
                                     <option value="50">50</option>
                                     <option value="100">100</option>
                                  </select>
                                  entries
                               </label>
                            </div>
                         </div>
                         <div class="col-sm-12 col-md-3 position_right">
                            <div class="form-group">
                               <input type="text" class="form-control" placeholder="Search" />
                            </div>                                                
                         </div>
                      </div>
                      <div class="row" style={{margin:'2%'}}>
                        <ProductInputTable inputs={ProductInput} />
                     </div>
                    
                      <div class="row">
                         <div class="col-sm-12 col-md-5">
                            <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 1 to 10 of 12 entries</div>
                         </div>
                         <div class="col-sm-12 col-md-7">
                            <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                               <ul class="pagination">
                                  <li class="paginate_button page-item previous disabled" id="DataTables_Table_0_previous"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li>
                                  <li class="paginate_button page-item active"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0" class="page-link">1</a></li>
                                  <li class="paginate_button page-item "><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="2" tabindex="0" class="page-link">2</a></li>
                                  <li class="paginate_button page-item next" id="DataTables_Table_0_next"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="3" tabindex="0" class="page-link">Next</a></li>
                               </ul>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div> */}
                </>
            ) : (
                //  <InpCategories />
                <div class="row">
                    <div class="col-sm-12">
                        <h3 className="mb-3">List</h3>
                        <div class="card card-table">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <div
                                        id="DataTables_Table_0_wrapper"
                                        class="dataTables_wrapper dt-bootstrap4 no-footer"
                                    >
                                        <div class="row">
                                            <div class="col-sm-12 col-md-6">
                                                <div
                                                    class="dataTables_length"
                                                    id="DataTables_Table_0_length"
                                                >
                                                    <label>
                                                        Show
                                                        <select
                                                            name="DataTables_Table_0_length"
                                                            aria-controls="DataTables_Table_0"
                                                            class="custom-select custom-select-sm form-control form-control-sm"
                                                        >
                                                            <option value="10">
                                                                10
                                                            </option>
                                                            <option value="25">
                                                                25
                                                            </option>
                                                            <option value="50">
                                                                50
                                                            </option>
                                                            <option value="100">
                                                                100
                                                            </option>
                                                        </select>
                                                        entries
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-3 position_right">
                                                <div class="form-group">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Search"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="row"
                                            style={{ margin: '2%' }}
                                        >
                                            <ProductInputTable
                                                inputs={ProductInput}
                                            />
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12 col-md-5">
                                                <div
                                                    class="dataTables_info"
                                                    id="DataTables_Table_0_info"
                                                    role="status"
                                                    aria-live="polite"
                                                >
                                                    Showing 1 to 10 of 12
                                                    entries
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-7">
                                                <div
                                                    class="dataTables_paginate paging_simple_numbers"
                                                    id="DataTables_Table_0_paginate"
                                                >
                                                    <ul class="pagination">
                                                        <li
                                                            class="paginate_button page-item previous disabled"
                                                            id="DataTables_Table_0_previous"
                                                        >
                                                            <a
                                                                href="#"
                                                                aria-controls="DataTables_Table_0"
                                                                data-dt-idx="0"
                                                                tabindex="0"
                                                                class="page-link"
                                                            >
                                                                Previous
                                                            </a>
                                                        </li>
                                                        <li class="paginate_button page-item active">
                                                            <a
                                                                href="#"
                                                                aria-controls="DataTables_Table_0"
                                                                data-dt-idx="1"
                                                                tabindex="0"
                                                                class="page-link"
                                                            >
                                                                1
                                                            </a>
                                                        </li>
                                                        <li class="paginate_button page-item ">
                                                            <a
                                                                href="#"
                                                                aria-controls="DataTables_Table_0"
                                                                data-dt-idx="2"
                                                                tabindex="0"
                                                                class="page-link"
                                                            >
                                                                2
                                                            </a>
                                                        </li>
                                                        <li
                                                            class="paginate_button page-item next"
                                                            id="DataTables_Table_0_next"
                                                        >
                                                            <a
                                                                href="#"
                                                                aria-controls="DataTables_Table_0"
                                                                data-dt-idx="3"
                                                                tabindex="0"
                                                                class="page-link"
                                                            >
                                                                Next
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default InputCatelog
