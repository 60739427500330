import React, { useState, useEffect } from 'react'
import { CssTextField } from '../Styles'
import IconButton from '@mui/material/IconButton'
import SendIcon from '@mui/icons-material/Send'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import './Styles.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Swal from 'sweetalert2'
import { API } from '../../API'
import Select from 'react-select'
import FormControl from '@mui/material/FormControl'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import Invoice from '../BookingDetail/Invoice'
import axios from 'axios'
import BookingMap from '../Create/bookingMap'
import { Drawer } from '@mui/material'

const InputSupplierBD = () => {
    const [opens, setOpens] = React.useState(false)
    const [invoiceopen, setInvoiceOpen] = React.useState(false)
    const [description,setDescription]=useState('')
    const [paymentMode,setPaymentMode]=useState("")

    const [tab, setTab] = useState(true)
    const [tab1, setTab1] = useState(false)
    const [tab2, setTab2] = useState(false)
    const [tab3, setTab3] = useState(false)
    const [tab4, setTab4] = useState(false)
    const [editProducts, setEditProducts] = useState(false)
    const [quantity, setQuantity] = useState(0)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [ArrayData, setArrayData] = useState([])
    const open = Boolean(anchorEl)
    const [DetailsInput, setDetailsInput] = useState({})
    const [TotalPrice, setTotalPrice] = useState(0)
    const [Details, setDetails] = useState({})
    const [Option, setOption] = useState([])
    const [drivers, setDrivers] = useState([])
    const [driverList, setDriverList] = useState([])
    const [driverList1, setDriverList1] = useState([])
    const [invoiceId, setInvoiceId] = useState('')
    const [Price, setPrice] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [transactionNotes,setTransactionNotes]=useState("")
    const [paymentModal, setPaymentModal] = useState(false)

    const [activeColor, setActiveColor] = useState('')
    const [activeColor1, setActiveColor1] = useState('')
    const [activeColor2, setActiveColor2] = useState('')
    const [activeColor3, setActiveColor3] = useState('')
    const [activeColor4, setActiveColor4] = useState('')
    const [booking, setBooking] = useState({})
    const [District, setDistrict] = useState('')
    const [assignData, setAssignedData] = useState([])
    const [assignDriverData, setAssignedDriverData] = useState([])
    const [inputData, setInputData] = useState({})
    const [createdByData, setCreatedByData] = useState({})
    const [transactionId, setTransactionId] = useState('')
    const [paymentStatus, setPaymentStatus] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [taxes,setTaxes]=useState()

    const [updateDetails, setUpdateDetails] = useState({
        No: '',
        firm: '',
        modeOfBooking: '',
        modeOfPayment: '',
        billingAddress: '',
        totalPrice: '',
        category: '',
        inputs: [],
    })

    const [disabled, setDisabled] = useState(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        getval()
        getAssignedDriver()
        // getPaymentDetails()
        // getTaxes()
    }, [])

    // const getTaxes=()=>{
    //     const token = localStorage.getItem('token')

    //     const config = {
    //         headers: { Authorization: `Bearer ${token}` },
    //     }
    //     axios
    //         .get(
    //             `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/invoice-taxes/input`,
    //             config
    //         )
    //         .then((res) => {
    //             setTaxes(res.data.data[0])
               
    //         })
    // }

    const getAssignedDriver = () => {
        const input = localStorage.getItem('Bookinginputid')
        const token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .get(
                `${
                    process.env.REACT_APP_API_ENDPOINT
                }/farmer-function/get-assigned-driver/${localStorage.getItem(
                    'Bookinginputid'
                )}`,
                config
            )
            .then((res) => {
                console.log('res', res.data)
                setAssignedDriverData(res.data.drivers)
                console.log('assignedDriverData', assignDriverData)
            })
    }

    const getPaymentDetails = () => {
        // const u = location.pathname
        const orderId = localStorage.getItem('Bookinginputid')
        const token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-payment/${orderId}`,
                config
            )
            .then((res) => {
                console.log(res)
                if (res.data.paymentStatus) {
                    setPaymentStatus(true)
                    setTransactionId(res.data.orderId)
                }
            })
    }

    const getval = async () => {
        const input = localStorage.getItem('Bookinginputid')
        const token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product/buy/${input}`,
                config
            )
            .then((res) => {
                console.log('machining data: ', res)
                if (res.data.product.cancelled) setDisabled(true)
                if (res.data.product.taxes) setTaxes(res.data.product.taxes)

                setDetails(res.data.product)
                calculateDiscount()

                if (res.data.product.invoiceId) {
                    setInvoiceId(res.data.product.invoiceId)
                }
                if (res.data.product.status === 'Accepted') {
                    setActiveColor1('green')
                    setTab1(true)
                }
                if (res.data.product.status === 'Packed') {
                    setActiveColor('green')
                    setActiveColor1('green')
                    setTab1(true)
                    setTab2(true)
                }
                if (res.data.product.status === 'Packed') {
                    setActiveColor('green')
                    setActiveColor1('green')
                    setActiveColor2('green')
                    setTab1(true)
                    setTab2(true)
                }
                if (res.data.product.status === 'Shipped') {
                    setActiveColor('green')
                    setActiveColor1('green')
                    setActiveColor2('green')
                    setActiveColor3('green')

                    setTab1(true)
                    setTab2(true)
                    setTab3(true)
                }
                if (res.data.product.status === 'Completed') { 
                    setActiveColor('green')
                    setActiveColor1('green')
                    setActiveColor2('green')
                    setActiveColor3('green')
                    setActiveColor4('green')
                    setTab1(true)
                    setTab2(true)
                    setTab3(true)
                    setTab4(true)
                }
                if (res.data.product.payment?.transaction_notes){
                    setPaymentStatus(true)
                    setTransactionNotes(res.data.product.payment.transaction_notes)

                }
                if (res.data.product.transactionId){
                    setPaymentStatus(true)
                    setTransactionId(res.data.product.transactionId)

                }
                // setTotalPrice(res.data.product.totalPrice);
                console.log(Details)
                const user = {
                    userId: res.data.product.user_id,
                }
                var payload = {
                    mobile_no: res.data.product.firm,
                    role: 'agri-input-supplier',
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-register`,
                        payload,
                        config
                    )
                    .then((res) => {
                        setInputData(res.data.data)
                    })
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-user`,
                        user,
                        config
                    )
                    .then((res) => {
                        console.log('check1: ', res.data)
                        setBooking(res.data.user[0])
                    })

                const createdBy = {
                    userId: res.data.product.createdById,
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-user`,
                        createdBy,
                        config
                    )
                    .then((res) => {
                        if (!res.data.user.length) return
                        const { id, role, mobile_number } = res.data.user[0]

                        setCreatedByData({
                            id,
                            role,
                            mobile_number,
                        })
                    })
            })
        // const { data } = await API.get(`/getInputBookings?type=1&bookedBy=Farmer&id=` + localStorage.getItem('Bookinginputid'));
        // setDetails(data?.result);
        // setArrayData(data?.result.inputs)
        // setUpdateDetails(data?.result)
        // let arr = []
        // //     grnqrcoderes.map(x => grnqrcodeArr.push(x.grnqrcode));
        // // grnqrcodeStr = "'" + grnqrcodeArr.join("','") + "'";
        // let value = data?.result && data?.result.inputs && data?.result.inputs.map(x => arr.push(parseInt(x.totalPrice)))
        // let val = arr.reduce((a, b) => a + b, 0)
        // setTotalPrice(val)
        // console.log("value", val)
        // inputval(data?.result.firm)
        // handleClickDD(data?.result.firmId)
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    useEffect(() => {
        handleClickDD()
    }, [])
    const handleClickDD = async (firmId) => {
        const { data } = await API.get(
            `/getProductCatelog?type=2&title=ProductCatelog&catelogName=InputSupplierCatelog&id=` +
                firmId
        )
        setOption(data?.result)
        console.log('data', data)
        console.log('firmId', firmId)
    }
    const categoryoption = [
        { value: '', label: 'Select Category' },
        { value: 'agri-machine', label: 'AGRI MACHINE' },
        { value: 'agri-transport', label: 'AGRI TRANSPORT' },
        { value: 'trader', label: 'TRADER' },
        { value: 'micro-entrepreneur', label: 'MIC ENTREPRENEUR' },
    ]
    const handleClickDDval = (e) => {
        console.log(e, 'e')
        var newArray =
            updateDetails &&
            updateDetails.inputs.filter(function (el) {
                console.log(el, 'val')
                if (
                    e.subCategoryName == el.subCategory &&
                    e.brandName == el.brand &&
                    e.value == el.product
                ) {
                    el.No = parseInt(el.No) + 1
                    el.No = el.No.toString()
                    el.totalPrice = el.No * el.price

                    console.log('e;l', el)
                    return el
                }
            })

        console.log('updateDetails', updateDetails, newArray)
        if (newArray && newArray.length > 0) {
            console.log('false', updateDetails)
            handleBook()
        } else {
            console.log('true', updateDetails)
            let resp = {
                subCategory: e.subCategoryName,
                brand: e.brandName,
                product: e.value,
                productImage: e.productImage,
                No: '1',
                unit: e.unit,
                price: e.price,
                stock: e.stock,
                quantity: e.quantity,
                totalPrice: e.price,
            }
            console.log('e', e.value, resp)
            let check = updateDetails.inputs.push(resp)

            handleBook()
        }
    }

    const option = Option.map((item, ind) => ({
        value: item.productName,
        label: item.productName,
        brandName: item.brandName,
        quantity: item.quantity,
        subCategoryName: item.subCategoryName,
        brandImage: item.brandImage,
        unit: item.unit,
        price: item.price,
        stock: item.stock,
        productImage: item.productImage,
    }))
    const handleAssign = () => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            ...assignData,
            district: District,
            bookingId: localStorage.getItem('Bookinginputid'),
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/assign-driver`,
                data,
                config
            )
            .then((res) => {
                handleActive2('Accepted')
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    // setDocImg(null)
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            })
            .catch((err) => console.log(err))
        console.log(assignData)
        // var data = {
        //    bookingId: localStorage.getItem('Bookinginputid'),
        // }
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    const setDriver = (e) => {
        var details = driverList.filter((item) => item.mobile_number == e.value)
        setDrivers(details)
        setAssignedData({
            ...assignData,
            mobile_number: details[0].mobile_number,
            trader_id: details[0].id,
            user_id: details[0].user_id,
            name: details[0].first_name + ' ' + details[0].last_name,
        })
    }
    const searchDriver = (e) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            role: e.value,
            district: District,
        }
        setAssignedData(data)

        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-drivers`,
                data,
                config
            )
            .then((res) => {
                console.log('res', res.data.data)
                setDriverList(res.data.mobile_numbers)
                var options = []
                res.data.mobile_numbers.map((item, ind) => {
                    options.push({
                        value: item.mobile_number,
                        label: item.mobile_number,
                    })
                })
                setDriverList1(options)
            })
        console.log(e.value, e)
    }
    const inputval = async (val) => {
        console.log('num,', val)
        const { data } = await API.get(
            `/getInputSupplierByNumber?mobileNumber=` + val
        )

        setDetailsInput(data?.result)
    }
    const handleChange = (e, i, val) => {
        // Details
        console.log(e, i, val)

        var newArray =
            Details &&
            Details.inputs.filter(function (el) {
                if (el.quantity == e.quantity) {
                    el.No = val
                    el.totalPrice = val * el.price
                }
                return el
            })

        setUpdateDetails({ ...updateDetails, inputs: newArray })
        setArrayData(newArray)

        console.log(newArray, updateDetails)

        //  handleBook()
    }

    const handleDelete = async (val, id) => {
        console.log(val, id)

        const { data } = await API.post('/inputBookingsDelete', {
            id: updateDetails._id,
            inputId: id,
        })
        console.log('datas', data)
        if (data?.success) {
            Swal.fire({
                icon: 'success',
                iconColor: '#28a745',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
            console.log(data)
            getval()
        } else {
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
            getval()
        }
    }
    const handleBook = async () => {
        // console.log("handleBook",formValues,{...inputBooking,input:formValues})
        // let inputBook ={...inputBooking,inputs:formValues,remainingStack:stockValues}
        let inputBook = { ...updateDetails, id: updateDetails._id }
        console.log('handleBook', updateDetails._id, {
            ...updateDetails,
            id: updateDetails._id,
        })
        const { data } = await API.post('/updateInputBooking', inputBook)
        console.log('datas', data)
        if (data?.success) {
            Swal.fire({
                icon: 'success',
                iconColor: '#28a745',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
            console.log(data)
            getval()
        } else {
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
            getval()
        }
    }

    const handleclickopens = () => {
        console.log('truepopup')
        setOpens(true)
    }

    const mapdataPickup = (data) => {
        console.log(data)
        setDistrict(data.district)
    }

    const handlecloses = () => {
        setOpens(false)
        handleClose()
    }
    const bookingmodeoption = [
        { value: 'DELIVERY', label: 'DELIVERY' },
        { value: 'PICKUP', label: 'PICKUP' },
    ]

    const handleActive = async (status) => {
        var token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            bookingId: localStorage.getItem('Bookinginputid'),
            status: status,
        }

        console.log('handleActive')
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/set-booking-status`,
                data,
                config
            )
            .then((res) => {
                console.log('res', res)
                setActiveColor('green')
                setActiveColor1('')
            })
            .catch((err) => {
                console.log('err', err)
                setActiveColor('')
            })
    }
    const PaymentOptions = [
        {
            label: 'Online',
            value: 'Online',
        },
        {
            label: 'Cash',
            value: 'Cash',
        },
    ]
    const handleActive1 = (status) => {
        var token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            bookingId: localStorage.getItem('Bookinginputid'),
            status: status,
        }

        console.log('handleActive')
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/set-booking-status`,
                data,
                config
            )
            .then((res) => {
                console.log('res', res)
                setActiveColor('green')
                setActiveColor1('green')
                setActiveColor2('')
                setActiveColor3('')
            })
            .catch((err) => {
                console.log('err', err)
                setActiveColor1('')
            })
    }

    const handleActive2 = (status) => {
        var token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            bookingId: localStorage.getItem('Bookinginputid'),
            status: status,
        }

        console.log('handleActive')
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/set-booking-status`,
                data,
                config
            )
            .then((res) => {
                console.log('res', res)
                setActiveColor('green')
                setActiveColor1('green')
                setActiveColor2('green')
                setTab1(true)
                setTab2(true)
            })
            .catch((err) => {
                console.log('err', err)
                setActiveColor2('')
            })
    }

    const handleActive3 = (status) => {
        var token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            bookingId: localStorage.getItem('Bookinginputid'),
            status: status,
        }

        console.log('handleActive')
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/set-booking-status`,
                data,
                config
            )
            .then((res) => {
                console.log('res', res)
                setActiveColor('green')
                setActiveColor1('green')
                setActiveColor2('green')
                setActiveColor3('green')
                setTab1(true)
                setTab2(true)
                setTab3(true)
            })
            .catch((err) => {
                console.log('err', err)
                setActiveColor3('')
            })
    }

    const handleActive4 = (status) => {
        var token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            bookingId: localStorage.getItem('Bookinginputid'),
            status: status,
        }

        console.log('handleActive')
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/set-booking-status`,
                data,
                config
            )
            .then((res) => {
                console.log('res', res)
                setActiveColor('green')
                setActiveColor1('green')
                setActiveColor2('green')
                setActiveColor3('green')
                setActiveColor4('green')
                setTab1(true)
                setTab2(true)
                setTab3(true)
                setTab4(true)
            })
            .catch((err) => {
                console.log('err', err)
                setActiveColor3('')
            })

        var data2 = {
            bookingId: localStorage.getItem('Bookinginputid'),
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/generate-invoice`,
                data2,
                config
            )
            .then((res) => {
                console.log('res', res)
                setInvoiceId(res.data.data.invoiceId)
                // setI("green")
            })
            .catch((err) => {
                console.log('err', err)
                // setI("")
            })
    }
    const fetchByphone = (value) => {
        const token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        if (value.length === 10) {
            var payload = {
                mobile_no: value,
            }
            axios
                .post(
                    'https://prod-api.krishione.com/farmer-function/register-details',
                    payload,
                    config
                )
                .then((res) => {
                    console.log(res.data)

                    setAssignedData({
                        ...assignData,
                        mobile_number: res.data.data.data.mobile_number,
                        trader_id: res.data.data.data.id,
                        name:
                            res.data.data.data.first_name +
                            ' ' +
                            res.data.data.data.last_name,
                    })
                    setDrivers([res.data.data.data])
                    setDriverList(res.data.data.data.mobile_numbers)
                    setDistrict(res.data.data.data.district)

                    var options = [
                        {
                            value: res.data.data.data.mobile_number,
                        },
                    ]
                    // res.data.map((item, ind) => {
                    //     options.push({
                    //         value: item.mobile_number,
                    //         label: item.mobile_number,
                    //     })
                    // })
                    setDriverList1(options)
                })
        } else {
            return
        }
    }
    const calculateDiscount = () => {
        var price =
            Details &&
            Details.cart &&
            Details.cart.reduce((int, a) => int + +a.price * +a.total_no, 0)
        var discount =
            Details &&
            Details.cart &&
            Details.cart.reduce((int, a) => int + +a.discount * +a.total_no, 0)
        // return discount
        console.log('price', price)
        console.log('discount', discount)
        // setPrice(price)
        // setDiscount(discount)
        // setTotalPrice(price - discount)

        return price - discount
    }

    const cancelBooking = () => {
        const input = localStorage.getItem('Bookinginputid')
        const token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }

        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/cancel-booking`,
                {
                    bookingId: input,
                    type: 'buy',
                },
                config
            )
            .then((res) => setDisabled(true))
    }
    const handlePaymentStatus=(e)=>{
        const token = localStorage.getItem('token')
        const input = localStorage.getItem('Bookinginputid')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data={
            amount:Details.total_price,
          user_id:Details.user_id,
          currency: "INR",
          orderId:input,
          quotationId:input,
          createdById:Details.createdById,
          assignById:Details.traderId,
          created_at: Date.now(),
          completed: false,
          isVisible: false,
          paymentStatus: "captured",
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/update-cashpayment`,
                {
                    wallet_data:data,
                    bookingId: input,
                    type: 'input',
                    "billing_type": paymentMode.value,
                    "transaction_notes":description,
                    "date":startDate
                },
                config
            )
            .then((res) =>{
                setPaymentModal(false)
                alert("Payment Updated!")
            } )
    }
    return (
        <div class="content container-fluid">
            <div
                class="page-header"
                style={
                    disabled ? { pointerEvents: 'none', opacity: '0.4' } : {}
                }
            >
                {console.log('updateDetails', updateDetails)}
                <div class="row align-items-center">
                    <div class="col">
                        {console.log('updateDetails', updateDetails)}
                        <h3 class="page-title">#{invoiceId}</h3>
                        <ul class="breadcrumb">
                            <li
                                style={{ cursor: 'pointer' }}
                                class="breadcrumb-item"
                            >
                                <a
                                    onClick={() => setInvoiceOpen(true)}
                                    style={{ color: 'green' }}
                                >
                                    <u>View Invoice</u>
                                </a>
                            </li>
                            <li
                                style={{ cursor: 'pointer' }}
                                class="breadcrumb-item"
                            >
                                <a
                                    onClick={() => cancelBooking()}
                                    style={{ color: 'green' }}
                                >
                                    <u>Cancel Booking</u>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <Dialog
                    fullWidth={'xl'}
                    maxWidth={'md'}
                    open={invoiceopen}
                    onClose={() => setInvoiceOpen(false)}
                >
                    <Invoice
                        Details={Details}
                        invoiceId={invoiceId}
                        inputData={inputData.data}
                        price={Price}
                        discount={discount}
                        TotalPrice={TotalPrice}
                        booking={booking}
                        trader={assignDriverData[0]}
                        taxes={taxes}

                    />
                </Dialog>
                <Drawer
                    open={paymentModal}
                    onClose={()=>setPaymentModal(false)}
                    anchor="right"

                >

<div
                        class="col-md-3"
                        style={{
                            marginTop: '2%',
                            width: 500, padding: 31,height: 500,
                        }}
                    >
                        <h3>Select Payment Mode</h3>
                        <FormControl
                            variant="standard"
                            fullWidth
                            className="mt-3"
                        >
                            {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Booking Mode</InputLabel> */}
                            <Select
                                style={{
                                    width: '270px',
                                    height: '40px',
                                }}
                                placeholder={'Select Payment Mode'}
                                value={paymentMode}
                                onChange={(e) => setPaymentMode(e)}
                                //   onChange={(e)=>setUpdateDetails({...UpdateDetails, unit: e.value})}
                                options={PaymentOptions}
                            />
                            <br/>
                            <label>Select Date</label>
<br/>
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                            <br/>
                             <label>Transaction Notes</label>
                                    <textarea
                                        class="form-control"
                                        rows="3"
                                        onChange={(e)=>setDescription(e.target.value)}
                                        cols="30"
                                        // onChange={(e) => handlecreate(e)}
                                        // id="Biography"
                                        // value={data.Biography}
                                    ></textarea>
                            <button variant="contained" onClick={(e)=>handlePaymentStatus(e)}
                                                            component="span"
                                                            color="success"  className="btn btn-primary mt-3">Submit</button>
                            
                        </FormControl>

                    </div>
                </Drawer>
                <div>
                    {console.log(Details, 'Details')}
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div
                                class="card card-table cardMain"
                                style={{ overflow: 'hidden' }}
                            >
                                <div class="tab-content p-0">
                                    <div action="#">
                                        <ul className="nav nav-tabs nav-tabs-bottom nav-justified navs">
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab1
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab1"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(1)}
                                                >
                                                    Pending
                                                </a>
                                                <span
                                                    className={
                                                        tab1
                                                            ? 'bread active'
                                                            : 'bread'
                                                    }
                                                ></span>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab1
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab2"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(2)}
                                                >
                                                    Accepted
                                                </a>
                                                <span
                                                    className={
                                                        tab2
                                                            ? 'bread active'
                                                            : 'bread'
                                                    }
                                                ></span>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab2
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab3"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(3)}
                                                >
                                                    Packed
                                                </a>
                                                <span
                                                    className={
                                                        tab2
                                                            ? 'bread active'
                                                            : 'bread'
                                                    }
                                                ></span>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab3
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab4"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(4)}
                                                >
                                                    Shipped
                                                </a>
                                                <span
                                                    className={
                                                        tab3
                                                            ? 'bread active'
                                                            : 'bread'
                                                    }
                                                ></span>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab4
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab5"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(5)}
                                                >
                                                    Completed
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xl-8 col-md-8">
                                    <div class="card">
                                        <div class="card-body p_7">
                                            <form>
                                                <div class="row form-group">
                                                    <div class="col-md-12">
                                                        {!editProducts ? (
                                                            <>
                                                                <div class="card-body card-body_1">
                                                                    <h5 class="card-title d-flex justify-content-between store_edit">
                                                                        <span></span>
                                                                        <button
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.preventDefault()
                                                                                setEditProducts(
                                                                                    true
                                                                                )
                                                                            }}
                                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                                            style={{
                                                                                padding:
                                                                                    '0rem 0.75rem',
                                                                            }}
                                                                        >
                                                                            <span>
                                                                                <u>
                                                                                    Edit
                                                                                    Items
                                                                                </u>
                                                                            </span>
                                                                        </button>
                                                                    </h5>
                                                                    <div class="table-wrap">
                                                                        <div class="table-responsive">
                                                                            <table class="table display setting-td mb-30 store_table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th></th>
                                                                                        <th>
                                                                                            Product
                                                                                        </th>
                                                                                        {/* <th>Brand</th> */}
                                                                                        <th>
                                                                                            Quantity
                                                                                        </th>
                                                                                        <th>
                                                                                            Unit
                                                                                        </th>
                                                                                        <th>
                                                                                            No
                                                                                        </th>
                                                                                        <th>
                                                                                            Price
                                                                                        </th>
                                                                                        <th>
                                                                                            discount
                                                                                        </th>
                                                                                        <th>
                                                                                            Amount
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {Details &&
                                                                                        Details.cart &&
                                                                                        Details.cart.map(
                                                                                            (
                                                                                                val,
                                                                                                ind
                                                                                            ) => {
                                                                                                return (
                                                                                                    <tr
                                                                                                        style={{
                                                                                                            borderBottom:
                                                                                                                '1px solid #ededed',
                                                                                                        }}
                                                                                                    >
                                                                                                        <td>
                                                                                                            {' '}
                                                                                                            <img
                                                                                                                style={{
                                                                                                                    width: '100px',
                                                                                                                }}
                                                                                                                src={
                                                                                                                    val.image
                                                                                                                }
                                                                                                                alt=""
                                                                                                            />{' '}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {val.product_name} ({val?.sizeColor})
                                                                                                        </td>
                                                                                                        {/* <td>{val.brand}</td> */}
                                                                                                        <td>
                                                                                                            {
                                                                                                                val.qty
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {
                                                                                                                val.unit
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {
                                                                                                                val.total_no
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            ₹{' '}
                                                                                                            {
                                                                                                                val.price
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {
                                                                                                                val.discount
                                                                                                            }
                                                                                                        </td>

                                                                                                        <td>
                                                                                                            ₹{' '}
                                                                                                            {val.price *
                                                                                                                val.total_no -
                                                                                                                val.discount *
                                                                                                                    val.total_no}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                )
                                                                                            }
                                                                                        )}
                                                                                    <tr>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td>
                                                                                            Sub
                                                                                            Total
                                                                                        </td>
                                                                                        <td>
                                                                                            ₹
                                                                                            {Details &&
                                                                                                Details.subtotal}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td>
                                                                                            Discount
                                                                                        </td>
                                                                                        <td>
                                                                                            ₹
                                                                                            {Details &&
                                                                                                Details.total_discount}
                                                                                        </td>
                                                                                    </tr>{' '}
                                                                                    <tr>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td>
                                                                                            Delivery
                                                                                            Fee
                                                                                        </td>
                                                                                        <td>
                                                                                            ₹
                                                                                            {Details &&
                                                                                                Details.delivery_fees}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td
                                                                                            style={{
                                                                                                borderTop:
                                                                                                    '1px solid #ddd',
                                                                                            }}
                                                                                        >
                                                                                            <b>
                                                                                                Total
                                                                                            </b>
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                borderTop:
                                                                                                    '1px solid #ddd',
                                                                                            }}
                                                                                        >
                                                                                            <b>
                                                                                                ₹
                                                                                                {Details &&
                                                                                                    Details.userAmount}
                                                                                            </b>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div class="card-body card-body_1">
                                                                    <h5 class="card-title d-flex justify-content-between store_edit">
                                                                        <ul class="list-unstyled mb-0 store_list">
                                                                            <li class="pt-2 pb-0">
                                                                                <span class="text-dark">
                                                                                    Search
                                                                                    Items
                                                                                </span>
                                                                            </li>
                                                                            {/* <li class="pt-2 pb-0">
                                                         <div class="col-sm-12 col-md-12">
                                                            <div class="form-group">
                                                               <input type="text" class="form-control" placeholder="Search" />
                                                            </div>
                                                         </div>
                                                      </li> */}
                                                                            <div
                                                                                class="form-group"
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '2%',
                                                                                    width: '200px',
                                                                                }}
                                                                            >
                                                                                {/* {console.log("Option2",searchstate)} */}
                                                                                <Select
                                                                                    options={
                                                                                        option
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        handleClickDDval(
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                    // value={searchstate}
                                                                                    // placeholder={searchstate}
                                                                                    placeholder={
                                                                                        'search'
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </ul>
                                                                        <div>
                                                                            <button
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    e.preventDefault()
                                                                                    handleBook()
                                                                                    setEditProducts(
                                                                                        false
                                                                                    )
                                                                                }}
                                                                                class="btn btn-tbl-edit btn-xs td_btn"
                                                                            >
                                                                                <span>
                                                                                    Update
                                                                                </span>
                                                                            </button>
                                                                            <button
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    e.preventDefault()
                                                                                    setEditProducts(
                                                                                        false
                                                                                    )
                                                                                }}
                                                                                class="btn btn-tbl-edit btn-xs td_btn"
                                                                                style={{
                                                                                    padding:
                                                                                        '0rem 0.75rem',
                                                                                }}
                                                                            >
                                                                                <span>
                                                                                    Cancel
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                    </h5>
                                                                    <div class="table-wrap">
                                                                        <div class="table-responsive">
                                                                            <table class="table display setting-td mb-30 store_table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th></th>
                                                                                        <th>
                                                                                            image
                                                                                        </th>
                                                                                        <th>
                                                                                            Product
                                                                                        </th>
                                                                                        <th>
                                                                                            Brand
                                                                                        </th>
                                                                                        <th>
                                                                                            Quantity
                                                                                        </th>
                                                                                        <th>
                                                                                            Unit
                                                                                        </th>
                                                                                        <th>
                                                                                            No
                                                                                        </th>
                                                                                        <th>
                                                                                            Price
                                                                                        </th>
                                                                                        <th>
                                                                                            Amount
                                                                                        </th>
                                                                                        <th>
                                                                                            Delete
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {ArrayData &&
                                                                                        ArrayData &&
                                                                                        ArrayData.map(
                                                                                            (
                                                                                                val,
                                                                                                ind
                                                                                            ) => {
                                                                                                return (
                                                                                                    <tr
                                                                                                        style={{
                                                                                                            borderBottom:
                                                                                                                '1px solid #ededed',
                                                                                                        }}
                                                                                                    >
                                                                                                        <td>
                                                                                                            {' '}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {' '}
                                                                                                            <img
                                                                                                                src={
                                                                                                                    val.productImage
                                                                                                                }
                                                                                                                style={{
                                                                                                                    width: '100%',
                                                                                                                    height: '100%',
                                                                                                                }}
                                                                                                                alt=""
                                                                                                            />{' '}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {
                                                                                                                val.product
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {
                                                                                                                val.brand
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {
                                                                                                                val.quantity
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {
                                                                                                                val.unit
                                                                                                            }
                                                                                                        </td>

                                                                                                        <td>
                                                                                                            <div
                                                                                                                class="col-md-6"
                                                                                                                style={{
                                                                                                                    width: '80px',
                                                                                                                    margin: 'auto',
                                                                                                                    borderRadius:
                                                                                                                        '3px',
                                                                                                                }}
                                                                                                            >
                                                                                                                <CssTextField
                                                                                                                    onChange={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        handleChange(
                                                                                                                            val,
                                                                                                                            ind,
                                                                                                                            e
                                                                                                                                .target
                                                                                                                                .value
                                                                                                                        )
                                                                                                                    }
                                                                                                                    // name="No"
                                                                                                                    value={
                                                                                                                        val.No ||
                                                                                                                        ''
                                                                                                                    }
                                                                                                                    fullWidth
                                                                                                                    id="inpQuantity"
                                                                                                                    type={
                                                                                                                        'number'
                                                                                                                    }
                                                                                                                    //  label="No"
                                                                                                                    variant="standard"
                                                                                                                />
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            ₹{' '}
                                                                                                            {
                                                                                                                val.price
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            ₹{' '}
                                                                                                            {
                                                                                                                val.totalPrice
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <a class="btn btn-tbl-edit btn-xs td_btn">
                                                                                                                <span
                                                                                                                    class="material-icons-outlined"
                                                                                                                    onClick={() =>
                                                                                                                        handleDelete(
                                                                                                                            val,
                                                                                                                            val._id
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    delete
                                                                                                                </span>
                                                                                                            </a>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                )
                                                                                            }
                                                                                        )}

                                                                                    <tr>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td>
                                                                                            Sub
                                                                                            Total
                                                                                        </td>
                                                                                        <td>
                                                                                            ₹
                                                                                            {
                                                                                                TotalPrice
                                                                                            }
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                        <td
                                                                                            style={{
                                                                                                borderTop:
                                                                                                    '1px solid #ddd',
                                                                                            }}
                                                                                        >
                                                                                            <b>
                                                                                                Total
                                                                                            </b>
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                borderTop:
                                                                                                    '1px solid #ddd',
                                                                                            }}
                                                                                        >
                                                                                            <b>
                                                                                                ₹
                                                                                                {
                                                                                                    TotalPrice
                                                                                                }
                                                                                            </b>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <div class="card">
                                                <div class="card-body p_7">
                                                    <form>
                                                        <div class="row form-group">
                                                            <div class="col-md-12">
                                                                <div class="card-body card-body_1">
                                                                    <h5 class="card-title d-flex justify-content-between store_edit">
                                                                        <span>
                                                                            Delivery
                                                                            Task
                                                                        </span>
                                                                        <a
                                                                            href="#"
                                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                                        >
                                                                            <span>
                                                                                PENDING
                                                                            </span>
                                                                        </a>
                                                                    </h5>
                                                                    <ul class="list-unstyled mb-0 store_list">
                                                                        <div className="row">
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <CheckCircleIcon
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                            color: activeColor1,
                                                                                        }}
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleActive2(
                                                                                                'Accepted'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <small className="text-secondary">
                                                                                        Accepted
                                                                                    </small>

                                                                                    {/* <CheckCircleIcon style={{ marginRight: '5px' }} /><small className="text-secondary">Accepted</small> */}
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                         
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <CheckCircleIcon
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                            color: activeColor2,
                                                                                        }}
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleActive2(
                                                                                                'Packed'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <small className="text-secondary">
                                                                                        Packed
                                                                                    </small>

                                                                                    {/* <CheckCircleIcon style={{ marginRight: '5px' }} /><small className="text-secondary">Packed</small> */}
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <CheckCircleIcon
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                            color: activeColor3,
                                                                                        }}
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleActive3(
                                                                                                'Shipped'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <small className="text-secondary">
                                                                                        Shipped
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <CheckCircleIcon
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                            color: activeColor4,
                                                                                        }}
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleActive4(
                                                                                                'Completed'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <small className="text-secondary">
                                                                                        Completed
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                        </div>
                                                                    </ul>
                                                                    <hr />
                                                                    <h5>
                                                                        Driver
                                                                        Details
                                                                    </h5>
                                                                    <a className="store-avatar">
                                                                        {assignDriverData.length ? null : (
                                                                            <div
                                                                                style={{
                                                                                    marginRight:
                                                                                        '10px',
                                                                                }}
                                                                                onClick={
                                                                                    handleClick
                                                                                }
                                                                            >
                                                                                <AddCircleOutlineIcon />
                                                                            </div>
                                                                        )}
                                                                        <div
                                                                            class="store_display"
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                                flexDirection:
                                                                                    'row',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '50px',
                                                                                    width: '25%',
                                                                                }}
                                                                            >
                                                                                <h4>
                                                                                    Name
                                                                                </h4>
                                                                                <span>
                                                                                    {assignDriverData.length
                                                                                        ? assignDriverData[0]
                                                                                              .name
                                                                                        : ''}
                                                                                </span>
                                                                            </div>

                                                                            <div
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '50px',
                                                                                    width: '25%',
                                                                                }}
                                                                            >
                                                                                <h4>
                                                                                    MobileNumber
                                                                                </h4>
                                                                                <span>
                                                                                    {assignDriverData.length
                                                                                        ? assignDriverData[0]
                                                                                              .mobile_number
                                                                                        : ''}
                                                                                </span>
                                                                            </div>

                                                                            <div
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '50px',
                                                                                }}
                                                                            >
                                                                                <h4>
                                                                                    District
                                                                                </h4>
                                                                                <span>
                                                                                    {assignDriverData.length
                                                                                        ? assignDriverData[0]
                                                                                              .district
                                                                                        : ''}
                                                                                </span>
                                                                            </div>
                                                                            <br />
                                                                            <div
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '50px',
                                                                                }}
                                                                            >
                                                                                <h4>
                                                                                    Trader
                                                                                    Id
                                                                                </h4>
                                                                                <span>
                                                                                    {assignDriverData.length
                                                                                        ? assignDriverData[0]
                                                                                              .trader_id
                                                                                        : ''}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <Menu
                                                                            id="basic-menu"
                                                                            anchorEl={
                                                                                anchorEl
                                                                            }
                                                                            open={
                                                                                open
                                                                            }
                                                                            onClose={
                                                                                handleClose
                                                                            }
                                                                            MenuListProps={{
                                                                                'aria-labelledby':
                                                                                    'basic-button',
                                                                            }}
                                                                        >
                                                                            <MenuItem
                                                                                onClick={() =>
                                                                                    handleclickopens()
                                                                                }
                                                                            >
                                                                                Assign
                                                                                Manually
                                                                            </MenuItem>
                                                                            <MenuItem>
                                                                                Assign
                                                                                Automatically
                                                                            </MenuItem>
                                                                        </Menu>
                                                                        {opens ==
                                                                        true ? (
                                                                            <Dialog
                                                                                open={
                                                                                    opens
                                                                                }
                                                                                onClose={
                                                                                    handlecloses
                                                                                }
                                                                                style={{
                                                                                    zIndex: 100,
                                                                                }}
                                                                            >
                                                                                <DialogTitle>
                                                                                    <b>
                                                                                        Assign
                                                                                        Driver
                                                                                    </b>
                                                                                </DialogTitle>
                                                                                <DialogContent>
                                                                                    <BookingMap
                                                                                        center={{
                                                                                            lat: 18.5204,
                                                                                            lng: 73.8567,
                                                                                        }}
                                                                                        height="10px"
                                                                                        zoom={
                                                                                            15
                                                                                        }
                                                                                        booking={
                                                                                            true
                                                                                        }
                                                                                        mapdata={
                                                                                            mapdataPickup
                                                                                        }
                                                                                    />

                                                                                    <div
                                                                                        className="mt-3"
                                                                                        style={{
                                                                                            fontSize:
                                                                                                '23px',
                                                                                        }}
                                                                                    >
                                                                                        District:{' '}
                                                                                        <span
                                                                                            style={{
                                                                                                color: 'green',
                                                                                            }}
                                                                                        >
                                                                                            {' '}
                                                                                            {
                                                                                                District
                                                                                            }
                                                                                        </span>
                                                                                    </div>

                                                                                    <div
                                                                                        style={{
                                                                                            display:
                                                                                                'flex',
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            style={{
                                                                                                margin: '2%',
                                                                                                width: '200px',
                                                                                            }}
                                                                                        >
                                                                                            <FormControl
                                                                                                variant="standard"
                                                                                                fullWidth
                                                                                                className="mt-4"
                                                                                            >
                                                                                                <Select
                                                                                                    onChange={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        searchDriver(
                                                                                                            e
                                                                                                        )
                                                                                                    }
                                                                                                    placeholder={
                                                                                                        'Choose Category'
                                                                                                    }
                                                                                                    options={
                                                                                                        categoryoption
                                                                                                    }
                                                                                                />
                                                                                            </FormControl>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{
                                                                                                margin: '2%',
                                                                                                width: '200px',
                                                                                            }}
                                                                                        >
                                                                                            <FormControl
                                                                                                variant="standard"
                                                                                                fullWidth
                                                                                                className="mt-4"
                                                                                            >
                                                                                                <Select
                                                                                                    onChange={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        setDriver(
                                                                                                            e
                                                                                                        )
                                                                                                    }
                                                                                                    placeholder={
                                                                                                        'Search Driver'
                                                                                                    }
                                                                                                    options={
                                                                                                        driverList1
                                                                                                    }
                                                                                                />
                                                                                            </FormControl>
                                                                                        </div>
                                                                                    </div>

                                                                                    <br />
                                                                                    <div>
                                                                                        Or
                                                                                        Search
                                                                                        By
                                                                                        Phone
                                                                                        No.
                                                                                    </div>
                                                                                    <div class="col-md-3">
                                                                                        <CssTextField
                                                                                            fullWidth
                                                                                            id="standard-basic"
                                                                                            label="Mobile No.*"
                                                                                            variant="standard"
                                                                                            name="lastName"
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                fetchByphone(
                                                                                                    e
                                                                                                        .target
                                                                                                        .value
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>

                                                                                    <div
                                                                                        class="row"
                                                                                        className="mt-4"
                                                                                    >
                                                                                        {drivers.map(
                                                                                            (
                                                                                                item,
                                                                                                index
                                                                                            ) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        style={{
                                                                                                            width: '260px',
                                                                                                            height: '70px',
                                                                                                            marginBottom:
                                                                                                                '50px',
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            class="card card-body bg-gray"
                                                                                                            style={{
                                                                                                                display:
                                                                                                                    'flex',
                                                                                                                flexDirection:
                                                                                                                    'row',
                                                                                                            }}
                                                                                                        >
                                                                                                            <div>
                                                                                                                <h5>
                                                                                                                    {item.first_name +
                                                                                                                        ' ' +
                                                                                                                        item.last_name}
                                                                                                                </h5>

                                                                                                                <div>
                                                                                                                    <a href="#">
                                                                                                                        <span>
                                                                                                                            {
                                                                                                                                item.mobile_number
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <div
                                                                                                                    class="circle"
                                                                                                                    style={{
                                                                                                                        width: '30px',
                                                                                                                        height: '30px',
                                                                                                                        padding:
                                                                                                                            '5px',
                                                                                                                        marginLeft:
                                                                                                                            '60px',
                                                                                                                        borderRadius:
                                                                                                                            '50%',
                                                                                                                        background:
                                                                                                                            '#fff',
                                                                                                                        border: ' 3px solid #000',
                                                                                                                        color: '#000',
                                                                                                                        textAlign:
                                                                                                                            'center',
                                                                                                                        font: '14px Arial, sans-serif',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    0
                                                                                                                </div>

                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        marginLeft:
                                                                                                                            '60px',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <a href="#">
                                                                                                                        <span>
                                                                                                                            task
                                                                                                                        </span>
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        )}
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            display:
                                                                                                'flex',
                                                                                            flexDirection:
                                                                                                'row',
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            style={{
                                                                                                paddingLeft:
                                                                                                    '300px',
                                                                                            }}
                                                                                        >
                                                                                            <Button
                                                                                                style={{
                                                                                                    backgroundColor:
                                                                                                        '#60a132',
                                                                                                    color: 'white',
                                                                                                }}
                                                                                                onClick={
                                                                                                    handlecloses
                                                                                                }
                                                                                            >
                                                                                                Cancel
                                                                                            </Button>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{
                                                                                                marginLeft:
                                                                                                    '30px',
                                                                                            }}
                                                                                        >
                                                                                            <Button
                                                                                                style={{
                                                                                                    backgroundColor:
                                                                                                        '#60a132',
                                                                                                    color: 'white',
                                                                                                }}
                                                                                                onClick={
                                                                                                    handleAssign
                                                                                                }
                                                                                            >
                                                                                                Assign
                                                                                                Task
                                                                                            </Button>
                                                                                        </div>
                                                                                    </div>
                                                                                </DialogContent>
                                                                            </Dialog>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Farmer Details</span>
                                                <a
                                                    href="#"
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                ></a>
                                            </h5>
                                            <a class="store-avatar">
                                                <div class="staffAvatar-root1">
                                                    {Details &&
                                                    Details.farmerId &&
                                                    Details.farmerId.firstName
                                                        .length > 0
                                                        ? Details &&
                                                          Details.farmerId &&
                                                          Details.farmerId.firstName.slice(
                                                              0,
                                                              1
                                                          )
                                                        : ''}
                                                </div>
                                                <div class="store_display">
                                                    <p>
                                                        First Name:{' '}
                                                        {booking.first_name}
                                                    </p>
                                                    <p>
                                                        Last Name:{' '}
                                                        {booking.last_name}
                                                    </p>
                                                    <p>
                                                        Mobile:{' '}
                                                        {booking.mobile_number}
                                                    </p>
                                                    <p>Role: {booking.role}</p>{' '}
                                                </div>
                                            </a>
                                        </div>
                                        <div class="card-body ">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span> Assigned Details</span>
                                                <a
                                                    href="#"
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                ></a>
                                            </h5>
                                            <a class="store-avatar">
                                                <div class="staffAvatar-root1">
                                                    R
                                                </div>
                                                <div class="store_display">
                                                    <h4>
                                                        {assignDriverData.length
                                                            ? assignDriverData[0]
                                                                  .name
                                                            : ''}
                                                    </h4>
                                                    <span>
                                                        {assignDriverData.length
                                                            ? assignDriverData[0]
                                                                  .mobile_number
                                                            : ''}
                                                    </span>
                                                </div>
                                            </a>
                                        </div>
                                        <hr
                                            width="85%"
                                            style={{ margin: 'auto' }}
                                        />
                                        <div class="card-body ">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>
                                                    {' '}
                                                    Input Supplier Details
                                                </span>
                                                <a
                                                    href="#"
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                ></a>
                                            </h5>
                                            <a class="store-avatar">
                                                <div class="staffAvatar-root1">
                                                    {DetailsInput &&
                                                    DetailsInput.farmerId &&
                                                    DetailsInput.farmerId
                                                        .firstName.length > 0
                                                        ? DetailsInput.farmerId.firstName.slice(
                                                              0,
                                                              1
                                                          )
                                                        : ''}
                                                </div>
                                                <div class="store_display">
                                                    <h4>
                                                        {DetailsInput &&
                                                            DetailsInput.firstName +
                                                                ' ' +
                                                                DetailsInput &&
                                                            DetailsInput.lastName}
                                                    </h4>
                                                    <span>
                                                        {Details &&
                                                            Details.firm}
                                                    </span>
                                                </div>
                                            </a>
                                        </div>
                                        <hr
                                            width="85%"
                                            style={{ margin: 'auto' }}
                                        />
                                           <div class="card-body ">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Payment Details</span>
                                                <a
                                                    href="#"
                                                   
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                    style={{
                                                        padding: '0rem 0.75rem',
                                                    }}
                                                >
                                                    <span>
                                                        {paymentStatus ? (
                                                            <u>
                                                                {' '}
                                                                {transactionId}{transactionNotes}
                                                            </u>
                                                        ) : (
                                                            <u  onClick={() =>
                                                                setPaymentModal(
                                                                    !paymentModal
                                                                )
                                                            }>
                                                                Receive Payment
                                                            </u>
                                                        )}
                                                    </span>
                                                </a>
                                            </h5>
                                            <div className="col-md-4 mb-4">
                                                <small className="text-dark">
                                                    Amount:{' '}
                                                </small>
                                                <span>
                                                    {' '}
                                                    {
                                                        Details?.userAmount
                                                           
                                                    }{' '}
                                                </span>
                                            </div>
                                            <div className="">
                                                <small className="text-dark">
                                                    Payment Status:{' '}
                                                </small>
                                                <span>
                                                    {' '}
                                                    {Details?.payment_status
                                                        ? 'Received'
                                                        : 'Not Recevied'}{' '}
                                                </span>
                                            </div>
                                            <p>
                                                {paymentStatus
                                                    ? 'Payment Recieved  -'
                                                    : 'Pending Amount -'}
                                                <span>
                                                    Rs.{' '}
                                                    {
                                                        Details?.userAmount
                                                    }
                                                </span>
                                            </p>
                                            <span>
                                                {paymentStatus ? (
                                                    <i>
                                                        {' '}
                                                        Payment Recieved via {Details?.payment
                                                            ?.cash_type || Details?.payment
                                                            ?.billing_type } 
                                                    </i>
                                                ) : (
                                                    <i>No transaction yet</i>
                                                )}
                                            </span>
                                            <br/>
                                            <p>
                                            <span>
                                            Date:    {Details?.payment
                                                            ?.date ? (
                                                    <i>
                                                        {' '}
                                                      {  new Date(Details?.payment
                                                            ?.date).toDateString()}
                                                    </i>
                                                ) :null}
                                            </span>
                                            </p>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Notes (0)</span>
                                                <a
                                                    href="#"
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                ></a>
                                            </h5>
                                            <hr style={{ margin: 'auto' }} />
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <CssTextField
                                                        fullWidth
                                                        id="totalLand"
                                                        variant="standard"
                                                        placeholder="Add a note...."
                                                        name="totalLand"
                                                        className="mb-0"
                                                    />
                                                </div>
                                                <div className="col-md-2">
                                                    <IconButton
                                                        color="success"
                                                        aria-label="add an alarm"
                                                        className="mt-3"
                                                    >
                                                        <SendIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Created By :</span>
                                            </h5>
                                            <ul class="list-unstyled mb-0 store_list">
                                                <li class="pt-2 pb-0">
                                                    <small class="text-dark">
                                                        Role :{' '}
                                                    </small>
                                                    <span>
                                                        {createdByData.role}
                                                    </span>
                                                </li>
                                                <li class="pt-2 pb-0">
                                                    <small class="text-dark">
                                                        ID :{' '}
                                                    </small>
                                                    <span>
                                                        {createdByData.id}
                                                    </span>
                                                </li>
                                                <li class="pt-2 pb-0">
                                                    <small class="text-dark">
                                                        Phone Number :{' '}
                                                    </small>
                                                    <span>
                                                        {
                                                            createdByData.mobile_number
                                                        }
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Booking from</span>
                                                <a
                                                    href="#"
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                ></a>
                                            </h5>
                                            <hr style={{ margin: 'auto' }} />
                                            <div className="row mt-3">
                                                <h6>ID: {booking.id}</h6>
                                            </div>
                                            <div className="row mt-3">
                                                <h6>Role: {booking.role}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="card">
                           <div class="card-body p_7">
                              <div class="row form-group">
                                 <div class="col-md-12">
                                    <div class="card-body card-body_1">
                                       <h5 class="card-title d-flex justify-content-between store_edit">
                                          <span>Order Summary</span>
                                       </h5>
                                       <ul class="list-unstyled mb-0 store_list">
                                          <li class="pt-2 pb-0">
                                             <small class="text-dark">Order Type </small>
                                          </li>
                                          <li>
                                             <span>Home Delivery</span>
                                          </li>
                                          <li class="pt-2 pb-0">
                                             <small class="text-dark">Delivery Type </small>
                                          </li>
                                          <li>
                                             <span>Scheduled</span>
                                          </li>
                                          <li class="pt-2 pb-0">
                                             <small class="text-dark">Delivery Time </small>
                                          </li>
                                          <li>
                                             <span>Nov 14, 8:00 AM - Nov 14, 9:00 AM</span>
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        //         <div class="content container-fluid">
        //    <div class="page-header">
        //       <div class="row align-items-center">
        //          <div class="col">
        //             <h3 class="page-title">#002</h3>
        //             <ul class="breadcrumb">
        //                <li class="breadcrumb-item"><a href="/Home" style={{ color: 'green' }}><u>View Invoice</u></a></li>
        //             </ul>
        //          </div>
        //       </div>
        //       <div>
        //       <div class="row mt-3">
        //                <div class="col-sm-12">
        //                   <div class="card card-table cardMain" style={{ overflow: 'hidden'}}>
        //                        <div class="tab-content p-0">
        //                        <div action="#">
        //                              <ul className="nav nav-tabs nav-tabs-bottom nav-justified navs">
        //                               <li class="nav-item">
        //                                 <a class={tab === 1   ? "nav-link active" : "nav-link" } href="#bottom-justified-tab1" data-bs-toggle="tab" onClick={(e)=>setTab(1)}>
        //                                  Pending

        //                                 </a>
        //                                 {/* &&Details.status&& Details.status=='Pending' */}
        //                                 <span className={tab === 1 ? "bread active" : "bread"}></span>
        //                               </li>
        //                               <li class="nav-item">
        //                                 <a class={tab === 2 ? "nav-link active" : "nav-link" } href="#bottom-justified-tab2" data-bs-toggle="tab" onClick={(e)=>setTab(2)}>Accepted</a>
        //                                 <span className={tab === 2 ? "bread active" : "bread"}></span>
        //                               </li>
        //                               <li class="nav-item">
        //                                   <a class={tab === 3 ? "nav-link active" : "nav-link" } href="#bottom-justified-tab3" data-bs-toggle="tab" onClick={(e)=>setTab(3)}>Packed</a>
        //                                   <span className={tab === 3 ? "bread active" : "bread"}></span>
        //                               </li>
        //                               <li class="nav-item">
        //                                   <a class={tab === 4 ? "nav-link active" : "nav-link" } href="#bottom-justified-tab4" data-bs-toggle="tab" onClick={(e)=>setTab(4)}>Ready</a>
        //                                   <span className={tab === 4 ? "bread active" : "bread"}></span>
        //                               </li>
        //                               <li class="nav-item">
        //                                   <a class={tab === 5 ? "nav-link active" : "nav-link" } href="#bottom-justified-tab5" data-bs-toggle="tab" onClick={(e)=>setTab(5)}>Completed</a>
        //                               </li>
        //                              </ul>
        //                          </div>
        //                         </div>
        //                   </div>

        //                   <div class="row">

        //       <div class="col-xl-8 col-md-8">
        //          <div class="card">
        //             <div class="card-body p_7">
        //                <form>
        //                   <div class="row form-group">
        //                      <div class="col-md-12">
        //                         {!editProducts ?
        //                         <>
        //                         <div class="card-body card-body_1">
        //                            <h5 class="card-title d-flex justify-content-between store_edit">
        //                               <span>6 Items</span>
        //                               <button onClick={(e)=>{e.preventDefault(); setEditProducts(true)}} class="btn btn-tbl-edit btn-xs td_btn" style={{ padding: '0rem 0.75rem'}}>
        //                                  <span ><u>Edit Items</u></span>
        //                               </button>
        //                            </h5>
        //                            <div class="table-wrap">
        //                              <div class="table-responsive">
        //                                 <table class="table display setting-td mb-30 store_table" >
        //                                    <thead>
        //                                       <tr>
        //                                          <th></th>
        //                                          <th>Product Name</th>
        //                                          <th>Brand</th>
        //                                          <th>Unit</th>
        //                                          <th>Quantity</th>
        //                                          <th>Price</th>
        //                                       </tr>
        //                                    </thead>
        //                                    {
        //                                                       console.log("Details",Details)
        //                                                    }
        //                                    <tbody>

        //                                          {
        //                                             Details && Details.inputs &&Details.inputs.map((val,ind)=>{
        //                                                return(

        //                                                 <tr style={{ borderBottom: '1px solid #ededed'}}>
        //                                                    {
        //                                                       console.log("val",val)
        //                                                    }
        //                                                 <td><div class="staffAvatar-root1">img</div></td>
        //                                                 <td>{val.product}</td>
        //                                                 <td>{val.brand}</td>
        //                                                 <td>{val.unit}</td>
        //                                                 <td>{val.quantity}</td>
        //                                                 <td>₹ {val.price}</td>
        //                                                 </tr>
        //                                                )
        //                                             })
        //                                          }

        //                                       {/* <tr style={{ borderBottom: '1px solid #ededed'}}>
        //                                          <td><div class="staffAvatar-root1">R</div></td>
        //                                          <td>TOMATO</td>
        //                                          <td>Heritage</td>
        //                                          <td>KG</td>
        //                                          <td>2</td>
        //                                          <td>₹ 60.00</td>
        //                                       </tr>
        //                                       <tr style={{ borderBottom: '1px solid #ededed'}}>
        //                                          <td><div class="staffAvatar-root1">R</div> </td>
        //                                          <td>ONION</td>
        //                                          <td>Heritage</td>
        //                                          <td>KG</td>
        //                                          <td>8</td>
        //                                          <td>₹ 350.00</td>
        //                                       </tr> */}

        //                                       {/* <tr style={{ borderBottom: '1px solid #ededed'}}>
        //                                          <td><div class="staffAvatar-root1">R</div> </td>
        //                                          <td>CARROT</td>
        //                                          <td>Heritage</td>
        //                                          <td>KG</td>
        //                                          <td>10</td>
        //                                          <td>₹ 200.00</td>
        //                                       </tr>

        //                                       <tr>
        //                                          <td></td>
        //                                          <td></td>
        //                                          <td></td>
        //                                          <td>Sub Total</td>
        //                                          <td>₹1,462.00	</td>
        //                                       </tr>      */}

        //                                       <tr>
        //                                          <td></td>
        //                                          <td></td>
        //                                          <td></td>
        //                                          <td style={{ borderTop: "1px solid #ddd"}}><b>Total</b></td>
        //                                          <td style={{ borderTop: "1px solid #ddd"}}><b>₹1,462.00</b></td>
        //                                       </tr>

        //                                    </tbody>
        //                                 </table>
        //                              </div>
        //                           </div>
        //                         </div>
        //                         </>
        //                         :
        //                         <>
        //                         <div class="card-body card-body_1">
        //                            <h5 class="card-title d-flex justify-content-between store_edit">
        //                            <ul class="list-unstyled mb-0 store_list">
        //                              <li class="pt-2 pb-0">
        //                                 <span class="text-dark">Search Items</span>
        //                              </li>
        //                              <li class="pt-2 pb-0">
        //                               <div class="col-sm-12 col-md-12">
        //                                <div class="form-group">
        //                                  <input type="text" class="form-control" placeholder="Search" />
        //                                </div>
        //                               </div>
        //                              </li>
        //                              </ul>
        //                               <button onClick={(e)=>{e.preventDefault(); setEditProducts(false)}} class="btn btn-tbl-edit btn-xs td_btn" style={{ padding: '0rem 0.75rem'}}>
        //                                  <span >Cancel</span>
        //                               </button>
        //                            </h5>
        //                            <div class="table-wrap">
        //                              <div class="table-responsive">
        //                                 <table class="table display setting-td mb-30 store_table" >
        //                                    <thead>
        //                                       <tr>
        //                                          <th></th>
        //                                          <th>Product Name</th>
        //                                          <th>Brand</th>
        //                                          <th>Unit</th>
        //                                          <th>Quantity</th>
        //                                          <th>Price</th>
        //                                          <th>Delete</th>
        //                                       </tr>
        //                                    </thead>
        //                                    <tbody>
        //                                       <tr style={{ borderBottom: '1px solid #ededed'}}>
        //                                          <td><div class="staffAvatar-root1">img</div></td>
        //                                          <td>POTATO</td>
        //                                          <td>Heritage</td>
        //                                          <td> KG</td>
        //                                          <td>
        //                                             <div style={{ border: '1px solid green', width: '80px', margin: 'auto', borderRadius: '3px'}}>
        //                                              <IconButton style={{ color: 'red', padding: '0px'}} onClick={()=>setQuantity(quantity - 1)}>
        //                                                 <RemoveIcon/>
        //                                              </IconButton>
        //                                              <span> {quantity} </span>
        //                                              <IconButton style={{ color: 'green', padding: '0px'}} onClick={()=>setQuantity(quantity + 1)}>
        //                                                 <AddIcon/>
        //                                              </IconButton>
        //                                             </div>
        //                                          </td>
        //                                          <td>₹ 200.00</td>
        //                                          <td>
        //                                             <IconButton style={{ color: 'red'}}>
        //                                                <DeleteIcon/>
        //                                             </IconButton>
        //                                          </td>
        //                                       </tr>
        //                                       <tr style={{ borderBottom: '1px solid #ededed'}}>
        //                                          <td><div class="staffAvatar-root1">R</div></td>
        //                                          <td>TOMATO</td>
        //                                          <td>Heritage</td>
        //                                          <td> KG</td>
        //                                          <td>
        //                                          <div style={{ border: '1px solid green', width: '80px', margin: 'auto', borderRadius: '3px'}}>
        //                                              <IconButton style={{ color: 'red', padding: '0px'}} onClick={()=>setQuantity(quantity - 1)}>
        //                                                 <RemoveIcon/>
        //                                              </IconButton>
        //                                              <span> {quantity} </span>
        //                                              <IconButton style={{ color: 'green', padding: '0px'}} onClick={()=>setQuantity(quantity + 1)}>
        //                                                 <AddIcon/>
        //                                              </IconButton>
        //                                             </div>
        //                                          </td>
        //                                          <td>₹ 60.00</td>
        //                                          <td>
        //                                             <IconButton style={{ color: 'red'}}>
        //                                                <DeleteIcon/>
        //                                             </IconButton>
        //                                          </td>
        //                                       </tr>
        //                                       <tr style={{ borderBottom: '1px solid #ededed'}}>
        //                                          <td><div class="staffAvatar-root1">R</div> </td>
        //                                          <td>ONION</td>
        //                                          <td>Heritage</td>
        //                                          <td> KG</td>
        //                                          <td>
        //                                          <div style={{ border: '1px solid green', width: '80px', margin: 'auto', borderRadius: '3px'}}>
        //                                              <IconButton style={{ color: 'red', padding: '0px'}} onClick={()=>setQuantity(quantity - 1)}>
        //                                                 <RemoveIcon/>
        //                                              </IconButton>
        //                                              <span> {quantity} </span>
        //                                              <IconButton style={{ color: 'green', padding: '0px'}} onClick={()=>setQuantity(quantity + 1)}>
        //                                                 <AddIcon/>
        //                                              </IconButton>
        //                                             </div>
        //                                          </td>
        //                                          <td>₹ 350.00</td>
        //                                          <td>
        //                                             <IconButton style={{ color: 'red'}}>
        //                                                <DeleteIcon/>
        //                                             </IconButton>
        //                                          </td>
        //                                       </tr>

        //                                       <tr style={{ borderBottom: '1px solid #ededed'}}>
        //                                          <td><div class="staffAvatar-root1">R</div> </td>
        //                                          <td>CARROT</td>
        //                                          <td>Heritage</td>
        //                                          <td> KG</td>
        //                                          <td>
        //                                          <div style={{ border: '1px solid green', width: '80px', margin: 'auto', borderRadius: '3px'}}>
        //                                              <IconButton style={{ color: 'red', padding: '0px'}} onClick={()=>setQuantity(quantity - 1)}>
        //                                                 <RemoveIcon/>
        //                                              </IconButton>
        //                                              <span> {quantity} </span>
        //                                              <IconButton style={{ color: 'green', padding: '0px'}} onClick={()=>setQuantity(quantity + 1)}>
        //                                                 <AddIcon/>
        //                                              </IconButton>
        //                                             </div>
        //                                          </td>
        //                                          <td>₹ 200.00</td>
        //                                          <td>
        //                                             <IconButton style={{ color: 'red'}}>
        //                                                <DeleteIcon/>
        //                                             </IconButton>
        //                                          </td>
        //                                       </tr>

        //                                       <tr>
        //                                          <td></td>
        //                                          <td></td>
        //                                          <td></td>
        //                                          <td>Sub Total</td>
        //                                          <td>₹1,462.00	</td>
        //                                       </tr>

        //                                       <tr>
        //                                          <td></td>
        //                                          <td></td>
        //                                          <td></td>
        //                                          <td style={{ borderTop: "1px solid #ddd"}}><b>Total</b></td>
        //                                          <td style={{ borderTop: "1px solid #ddd"}}><b>₹1,462.00</b></td>
        //                                       </tr>

        //                                    </tbody>
        //                                 </table>
        //                              </div>
        //                           </div>
        //                         </div>
        //                         </>
        //                         }
        //                       </div>
        //                      </div>
        //                </form>
        //             </div>
        //          </div>

        //          <div className="row">
        //              <div className="col-md-7 col-sm-12">
        //              <div class="card">
        //             <div class="card-body p_7">
        //                <form>
        //                   <div class="row form-group">
        //                      <div class="col-md-12">
        //                            <div class="card-body card-body_1">
        //                            <h5 class="card-title d-flex justify-content-between store_edit">
        //                               <span>Delivery Task</span>
        //                               <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
        //                                  <span>PENDING</span>
        //                               </a>
        //                            </h5>
        //                            <ul class="list-unstyled mb-0 store_list">
        //                            <div className="row">
        //                             <div className="col-md-4 mb-4">
        //                              <li class="pt-2 pb-0">
        //                                <CheckCircleIcon style={{ marginRight: '5px'}}/><small className="text-secondary">Accepted</small>
        //                               </li>
        //                               <li className="ml-2">
        //                                  <span> - </span>
        //                               </li>
        //                             </div>
        //                             <div className="col-md-4 mb-4">
        //                              <li class="pt-2 pb-0">
        //                                <CheckCircleIcon style={{ marginRight: '5px'}}/><small className="text-secondary">Reached</small>
        //                               </li>
        //                               <li className="ml-2">
        //                                  <span> - </span>
        //                               </li>
        //                             </div>
        //                             <div className="col-md-4 mb-4">
        //                              <li class="pt-2 pb-0">
        //                                <CheckCircleIcon style={{ marginRight: '5px'}}/><small className="text-secondary">Packed</small>
        //                               </li>
        //                               <li className="ml-2">
        //                                  <span> - </span>
        //                               </li>
        //                             </div>
        //                             <div className="col-md-4 mb-4">
        //                              <li class="pt-2 pb-0">
        //                                <CheckCircleIcon style={{ marginRight: '5px'}}/><small className="text-secondary">Shipped</small>
        //                               </li>
        //                               <li className="ml-2">
        //                                  <span> - </span>
        //                               </li>
        //                             </div>
        //                             <div className="col-md-4 mb-4">
        //                              <li class="pt-2 pb-0">
        //                                <CheckCircleIcon style={{ marginRight: '5px'}}/><small className="text-secondary">Completed</small>
        //                               </li>
        //                               <li className="ml-2">
        //                                  <span> - </span>
        //                               </li>
        //                             </div>
        //                          </div>
        //                         </ul>
        //                         <hr/>
        //                         <h5>Driver Details</h5>
        //                         <a className="store-avatar">
        //                            <div style={{ marginRight: '10px'}} onClick={handleClick}><AddCircleOutlineIcon/></div>
        //                            <div class="store_display">
        //                               <h4>Rajsekar</h4>
        //                               <span style={{ marginTop: '5px'}}>No driver assigned yet</span>
        //                            </div>
        //                            <Menu
        //                              id="basic-menu"
        //                              anchorEl={anchorEl}
        //                              open={open}
        //                              onClose={handleClose}
        //                              MenuListProps={{
        //                                'aria-labelledby': 'basic-button',
        //                              }}
        //                            >
        //                              <MenuItem onClick={handleClose}>Assign Manually</MenuItem>
        //                              <MenuItem onClick={handleClose}>Assign Automatically</MenuItem>
        //                            </Menu>
        //                         </a>
        //                         <Timeline position="alternate">
        //       <TimelineItem>
        //         <TimelineSeparator>
        //           <TimelineConnector />
        //           <TimelineDot>
        //             <RoomIcon />
        //           </TimelineDot>
        //           <TimelineConnector />
        //         </TimelineSeparator>
        //         <TimelineContent sx={{ py: '12px', px: 2 }}>
        //           <Typography variant="h6" component="span">
        //            SRI HANUMAN TRADERS
        //           </Typography>
        //           <Typography>Kalyan Nagar</Typography>
        //         </TimelineContent>
        //       </TimelineItem>
        //       <TimelineItem>
        //         <TimelineSeparator>
        //           <TimelineConnector />
        //           <TimelineDot>
        //             <HomeRepairServiceIcon />
        //           </TimelineDot>
        //           <TimelineConnector />
        //         </TimelineSeparator>
        //         <TimelineContent sx={{ py: '12px', px: 2 }}>
        //           <Typography variant="h6" component="span">
        //            SRI HANUMAN TRADERS
        //           </Typography>
        //           <Typography>Kalyan Nagar</Typography>
        //         </TimelineContent>
        //       </TimelineItem>
        //     </Timeline>
        //     </div>
        //    </div>
        //    </div>
        //                </form>
        //             </div>
        //          </div>
        //         </div>
        //         <div className="col-md-5 col-sm-12">
        //         <div class="card">
        //             <div class="card-body p_7">
        //                   <div class="row form-group">
        //                      <div class="col-md-12">
        //                            <div class="card-body card-body_1">
        //                            <h5 class="card-title d-flex justify-content-between store_edit">
        //                               <span>Order Summary</span>
        //                            </h5>
        //                            <ul class="list-unstyled mb-0 store_list">
        //                              <li class="pt-2 pb-0">
        //                                 <small class="text-dark">Order Type </small>
        //                              </li>
        //                              <li>
        //                                 <span>Home Delivery</span>
        //                              </li>
        //                              <li class="pt-2 pb-0">
        //                                 <small class="text-dark">Delivery Type </small>
        //                              </li>
        //                              <li>
        //                                 <span>Scheduled</span>
        //                              </li>
        //                              <li class="pt-2 pb-0">
        //                                 <small class="text-dark">Delivery Time </small>
        //                              </li>
        //                              <li>
        //                                 <span>Nov 14, 8:00 AM - Nov 14, 9:00 AM</span>
        //                              </li>
        //                           </ul>
        //                         </div>
        //                      </div>
        //                      </div>
        //             </div>
        //          </div>
        //         </div>
        //     </div>

        //       </div>

        //       <div class="col-lg-4">
        //       <div class="card">
        //          <div class="card-body">
        //             <h5 class="card-title d-flex justify-content-between store_edit">
        //                <span>Farmer Details</span>
        //                <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
        //                </a>
        //             </h5>
        //             <a class="store-avatar">
        //                <div class="staffAvatar-root1">R</div>
        //                <div class="store_display">
        //                   <h4>Rajsekar</h4>
        //                   <span>8098081991</span>
        //                </div>
        //             </a>
        //          </div>
        //          <hr width="85%" style={{ margin: 'auto'}}/>
        //          <div class="card-body ">
        //             <h5 class="card-title d-flex justify-content-between store_edit">
        //                <span> Input Supplier Details</span>
        //                <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
        //                </a>
        //             </h5>
        //             <a class="store-avatar">
        //                <div class="staffAvatar-root1">R</div>
        //                <div class="store_display">
        //                   <h4>Rajsekar</h4>
        //                   <span>8098081991</span>
        //                </div>
        //             </a>
        //          </div>
        //          <hr width="85%" style={{ margin: 'auto'}}/>
        //          <div class="card-body ">
        //             <h5 class="card-title d-flex justify-content-between store_edit">
        //                <span>Payment Details</span>
        //                <a href="#" class="btn btn-tbl-edit btn-xs td_btn" style={{ padding: '0rem 0.75rem' }}>
        //                   <span><u>Receive Payment</u></span>
        //                </a>
        //             </h5>
        //             <p>Pending Amount - <span>Rs. 4000</span></p>
        //             <span><i>No transaction yet</i></span>
        //          </div>
        //       </div>

        //       <div class="card">
        //       <div class="card-body">
        //             <h5 class="card-title d-flex justify-content-between store_edit">
        //                <span>Notes (0)</span>
        //                <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
        //                </a>
        //             </h5>
        //             <hr style={{ margin: 'auto'}}/>
        //             <div className="row">
        //                 <div className="col-md-10">
        //                  <CssTextField
        //                   fullWidth
        //                   id="totalLand"
        //                   variant="standard"
        //                   placeholder="Add a note...."
        //                   name="totalLand"
        //                   className="mb-0"
        //                  />
        //                 </div>
        //                 <div className="col-md-2">
        //                 <IconButton color="success" aria-label="add an alarm" className="mt-3">
        //                   <SendIcon />
        //                 </IconButton>
        //                 </div>
        //             </div>
        //          </div>
        //       </div>

        //       <div class="card">
        //       <div class="card-body">
        //             <h5 class="card-title d-flex justify-content-between store_edit">
        //                <span>Messages (0)</span>
        //                <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
        //                </a>
        //             </h5>
        //             <hr style={{ margin: 'auto'}}/>
        //             <div className="row">
        //                 <div className="col-md-10">
        //                  <CssTextField
        //                   fullWidth
        //                   id="totalLand"
        //                   variant="standard"
        //                   placeholder="Add a Message...."
        //                   name="totalLand"
        //                   className="mb-0"
        //                  />
        //                 </div>
        //                 <div className="col-md-2">
        //                 <IconButton color="success" aria-label="add an alarm" className="mt-3">
        //                   <SendIcon />
        //                 </IconButton>
        //                 </div>
        //             </div>
        //          </div>
        //       </div>

        //       <div class="card">
        //       <div class="card-body">
        //             <h5 class="card-title d-flex justify-content-between store_edit">
        //                <span>Booking from</span>
        //                <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
        //                </a>
        //             </h5>
        //             <hr style={{ margin: 'auto'}}/>
        //             <div className="row mt-3">
        //                 <h6>Farmer</h6>
        //             </div>
        //          </div>
        //       </div>

        //    </div>
        //    </div>
        //   </div>
        // </div>

        //       </div>
        //    </div>

        // </div>
    )
}

export default InputSupplierBD
