import React from 'react'
import Barchart from './barChart'
import LineChart from './LIneChart'
const Dashboard2 = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {/* <div class="card cardhome">
        <div style={{ padding: '25px' }}><h4 class="card-title cart-header">Sales Overview
        </h4><div class="text-muted card-subtitle">Most Sold Products</div></div>

        <Barchart />

      </div> */}

            <div className="dashmap card cardhome">
                <div style={{ padding: '25px' }}>
                    <h4 class="card-title cart-header">Sales Overview</h4>
                    <div class="text-muted card-subtitle">
                        Most Sold Products
                    </div>
                </div>
                <LineChart />
            </div>

            {/* <ChartFirst /> */}
        </div>
    )
}

export default Dashboard2
