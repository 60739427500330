import React from 'react'
function VariantTypes() {
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Variant Types</h5>
                    <div class="tab_add_new_btn">
                        <a href="#">Add</a>
                    </div>
                </div>
                <div class="card-body card-body_1">
                    <form>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <div class="card bg-white">
                                    <div class="card-body card-body_1">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <div class="card card-body bg-gray">
                                                    <h5>DELIVER CHARGE 1/KG</h5>

                                                    <input
                                                        class="react-switch-checkbox"
                                                        id="22"
                                                        type="checkbox"
                                                        value="true"
                                                        checked=""
                                                    />
                                                    <label
                                                        class="react-switch-label"
                                                        for="22"
                                                    >
                                                        <span class="react-switch-button"></span>
                                                    </label>

                                                    <div class="status-options mt-4">
                                                        <a href="#">
                                                            <span class="material-icons-outlined">
                                                                edit
                                                            </span>
                                                        </a>
                                                        <a href="#">
                                                            <span class="material-icons-outlined">
                                                                delete
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default VariantTypes
