import React from 'react'

function HomepagePlugins() {
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Homepage Plugins</h5>
                    <div class="tab_add_new_btn">
                        <a href="#">Add</a>
                    </div>
                </div>
                <div class="card-body card-body_1">
                    <form>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <div class="card bg-white">
                                    <div class="card-body card-body_1">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="noRecords">
                                                    <img
                                                        src="../assets/images/norecord_empty.svg"
                                                        alt=""
                                                    />
                                                    <h5>No Records</h5>
                                                    <p>
                                                        No data found for this
                                                        search
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default HomepagePlugins
