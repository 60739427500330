import React from 'react'

function Timeslots() {
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Timeslots</h5>
                    <div class="tab_add_new_btn">
                        <a href="#">Add</a>
                    </div>
                </div>
                <div class="card-body card-body_1">
                    <form>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <div class="card bg-white">
                                    <div class="card-body card-body_1">
                                        <ul class="nav nav-tabs nav-tabs-bottom nav_add_bt">
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link active"
                                                    href="#bottom-tab1"
                                                    data-bs-toggle="tab"
                                                >
                                                    Mon
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#bottom-tab2"
                                                    data-bs-toggle="tab"
                                                >
                                                    Tue
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#bottom-tab3"
                                                    data-bs-toggle="tab"
                                                >
                                                    Wed
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#bottom-tab4"
                                                    data-bs-toggle="tab"
                                                >
                                                    Thu
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#bottom-tab5"
                                                    data-bs-toggle="tab"
                                                >
                                                    Fri
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#bottom-tab6"
                                                    data-bs-toggle="tab"
                                                >
                                                    Sat
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#bottom-tab7"
                                                    data-bs-toggle="tab"
                                                >
                                                    Sun
                                                </a>
                                            </li>
                                        </ul>

                                        <div class="tab-content">
                                            <div
                                                class="tab-pane show active"
                                                id="bottom-tab1"
                                            >
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="card-body card-body_1">
                                                            <div class="table-wrap">
                                                                <div class="table-responsive">
                                                                    <table class="table display setting-td mb-30">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    FROM
                                                                                    TIME
                                                                                </th>
                                                                                <th>
                                                                                    TO
                                                                                    TIME
                                                                                </th>
                                                                                <th>
                                                                                    ACTIONS
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    06:00:00
                                                                                </td>
                                                                                <td>
                                                                                    07:00:00
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td>
                                                                                    08:00:00
                                                                                </td>
                                                                                <td>
                                                                                    09:00:00
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    16:00:00
                                                                                </td>
                                                                                <td>
                                                                                    17:00:00
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="tab-pane show active"
                                                id="bottom-tab2"
                                            >
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="card-body card-body_1">
                                                            <div class="table-wrap">
                                                                <div class="table-responsive">
                                                                    <table class="table display setting-td mb-30">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    FROM
                                                                                    TIME
                                                                                </th>
                                                                                <th>
                                                                                    TO
                                                                                    TIME
                                                                                </th>
                                                                                <th>
                                                                                    ACTIONS
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    06:00:00
                                                                                </td>
                                                                                <td>
                                                                                    07:00:00
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td>
                                                                                    08:00:00
                                                                                </td>
                                                                                <td>
                                                                                    09:00:00
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    16:00:00
                                                                                </td>
                                                                                <td>
                                                                                    17:00:00
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="tab-pane show active"
                                                id="bottom-tab3"
                                            >
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="card-body card-body_1">
                                                            <div class="table-wrap">
                                                                <div class="table-responsive">
                                                                    <table class="table display setting-td mb-30">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    FROM
                                                                                    TIME
                                                                                </th>
                                                                                <th>
                                                                                    TO
                                                                                    TIME
                                                                                </th>
                                                                                <th>
                                                                                    ACTIONS
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    06:00:00
                                                                                </td>
                                                                                <td>
                                                                                    07:00:00
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td>
                                                                                    08:00:00
                                                                                </td>
                                                                                <td>
                                                                                    09:00:00
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    16:00:00
                                                                                </td>
                                                                                <td>
                                                                                    17:00:00
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="tab-pane show active"
                                                id="bottom-tab4"
                                            >
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="card-body card-body_1">
                                                            <div class="table-wrap">
                                                                <div class="table-responsive">
                                                                    <table class="table display setting-td mb-30">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    FROM
                                                                                    TIME
                                                                                </th>
                                                                                <th>
                                                                                    TO
                                                                                    TIME
                                                                                </th>
                                                                                <th>
                                                                                    ACTIONS
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    06:00:00
                                                                                </td>
                                                                                <td>
                                                                                    07:00:00
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td>
                                                                                    08:00:00
                                                                                </td>
                                                                                <td>
                                                                                    09:00:00
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    16:00:00
                                                                                </td>
                                                                                <td>
                                                                                    17:00:00
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="tab-pane show active"
                                                id="bottom-tab5"
                                            >
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="card-body card-body_1">
                                                            <div class="table-wrap">
                                                                <div class="table-responsive">
                                                                    <table class="table display setting-td mb-30">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    FROM
                                                                                    TIME
                                                                                </th>
                                                                                <th>
                                                                                    TO
                                                                                    TIME
                                                                                </th>
                                                                                <th>
                                                                                    ACTIONS
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    06:00:00
                                                                                </td>
                                                                                <td>
                                                                                    07:00:00
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td>
                                                                                    08:00:00
                                                                                </td>
                                                                                <td>
                                                                                    09:00:00
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    16:00:00
                                                                                </td>
                                                                                <td>
                                                                                    17:00:00
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="tab-pane show active"
                                                id="bottom-tab6"
                                            >
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="card-body card-body_1">
                                                            <div class="table-wrap">
                                                                <div class="table-responsive">
                                                                    <table class="table display setting-td mb-30">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    FROM
                                                                                    TIME
                                                                                </th>
                                                                                <th>
                                                                                    TO
                                                                                    TIME
                                                                                </th>
                                                                                <th>
                                                                                    ACTIONS
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    06:00:00
                                                                                </td>
                                                                                <td>
                                                                                    07:00:00
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td>
                                                                                    08:00:00
                                                                                </td>
                                                                                <td>
                                                                                    09:00:00
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    16:00:00
                                                                                </td>
                                                                                <td>
                                                                                    17:00:00
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="tab-pane show active"
                                                id="bottom-tab7"
                                            >
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="card-body card-body_1">
                                                            <div class="table-wrap">
                                                                <div class="table-responsive">
                                                                    <table class="table display setting-td mb-30">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    FROM
                                                                                    TIME
                                                                                </th>
                                                                                <th>
                                                                                    TO
                                                                                    TIME
                                                                                </th>
                                                                                <th>
                                                                                    ACTIONS
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    06:00:00
                                                                                </td>
                                                                                <td>
                                                                                    07:00:00
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td>
                                                                                    08:00:00
                                                                                </td>
                                                                                <td>
                                                                                    09:00:00
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    16:00:00
                                                                                </td>
                                                                                <td>
                                                                                    17:00:00
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Timeslots
