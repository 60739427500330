import { format } from 'date-fns'

export const COLUMNS = [
    {
        Header: 'Invoice Num',
        accessor: 'invoiceId',
    },
    {
        Header: 'Booking ID',
        accessor: 'bookingId',
        Cell: (props) => {
            let url
            switch (props.row.original.type) {
                case 'buy':
                    url = 'InputSupplierBookingDetail/' + props.cell.value
                    break
                case 'live-stock':
                    url = 'LivestockBookingDetail/' + props.cell.value
                    break
                case 'sell':
                    url = 'SellCropDB/' + props.cell.value
                    break
                case 'machine':
                    url = 'AgriMachineBookingDetail/' + props.cell.value
                    break
                case 'vehicle':
                    url = 'AgriTransportBookingDetail/' + props.cell.value
                    break
            }
            return <a href={url}>{props.cell.value}</a>
        },
    },
    {
        Header: 'Inv Date',
        accessor: 'invoiceDate',
        Cell: ({ value }) => {
            return format(value, 'dd/MM/yyyy')
        },
    },
    {
        Header: 'User Amt',
        accessor: 'userAmount',
    },
    {
        Header: 'TCS Amt',
        accessor: (acc) =>
            acc?.bookingCommision.tcsAmount?.toFixed(2)
       ?  acc?.bookingCommision.tcsAmount?.toFixed(2)
        :acc?.tcsAmount?.toFixed(2)
    }, 

    {
        Header: 'Receiver Amt',
        accessor: (acc) =>
            acc?.machineAmount?.toFixed(2)
            ? acc.machineAmount?.toFixed(2)
            : acc.firmAmount?.toFixed(2)
           
    },
    {
        Header: 'Krishi Amt',
        accessor: 'krishiAmount',
    },
    {
        Header: 'Total CM Amt',
        accessor: (acc) =>
        acc?.totalCmAmt?.toFixed(2)?
        acc?.totalCmAmt?.toFixed(2):
        acc?.bookingCommision.totalCommisionAmt?.toFixed(2)
    },
      {
        Header: 'Total CM AMT Exc GST',
        accessor:   (acc) =>
       acc?.totalCmAmtExcGst?.toFixed(2)?
        acc?.totalCmAmtExcGst?.toFixed(2):
        acc?.bookingCommision?.totalCmAmtExcGst?.toFixed(2)
    },
     {
        Header: 'TOTAL CM GST Amt',
        accessor:   (acc) =>
       acc?.totalCmGstAmt?.toFixed(2)?
       acc?.totalCmGstAmt?.toFixed(2):
        acc?.bookingCommision?.totalCMGSTAmount?.toFixed(2)
    },
    {
        Header: 'Total CM+BS AMT Exc GST',
        accessor:   (acc) =>
       acc?.totalCmBsAmtExcGst?.toFixed(2)?
       acc?.totalCmBsAmtExcGst?.toFixed(2):
       acc?.bookingCommision?.totalCmBsAmtExcGst?.toFixed(2)
    },
    // {
    //     Header: 'Total CM+BS AMT Exc GST',
    //     accessor:   (acc) =>
    //     Number(acc?.totalCmBsAmtExcGst)?.toFixed(2)
    // },
    {
        Header: 'Total CM+BS AMT',
        accessor:   (acc) =>
        Number(acc?.krishiAmount)?.toFixed(2)
    },
    {
        Header: 'Booking Fee',
        accessor: (acc) => acc.bookingfee.toFixed(2)
        // Number(acc?.taxes?.bookingFee)?.toFixed(2)?
        // Number(acc?.taxes?.bookingFee)?.toFixed(2):
        // Number(acc.bookingfee)?.toFixed(2)
        
    },
    {
        Header: 'Booking Fee GST',
        accessor: (acc) =>
        // Number(acc?.taxes?.gstAmount)?.toFixed(2)?
        // Number(acc?.taxes?.gstAmount)?.toFixed(2):
        Number(acc?.bookingFeeGstAmount)?.toFixed(2)
    },
    {
        Header: 'Booking Fee Inc GST',
        accessor: (acc) =>
        // Number(acc?.taxes?.incGstBookingFee)?.toFixed(2)?
        // Number(acc?.taxes?.incGstBookingFee)?.toFixed(2):
        Number(acc?.bookingfeeIncGst)?.toFixed(2)
    },
    // {
    //     Header: 'Total Amt',
    //     accessor: (acc) =>
    //         acc?.total_price
    //             ? acc.total_price
    //             : acc?.payment?.indicate_order_value
    //             ? acc?.payment?.indicate_order_value
    //             : acc?.price * acc?.quantity,
    // },
    {
        Header: 'User Id',
        accessor: 'id',
    },
    {
        Header: 'Mob',
        accessor: 'mobile_number',
    },
    {
        Header: 'Type of Booking',
        accessor: 'type',
        Cell: (props) => {
            return <>{props?.cell?.value}</>
        },
    },
    {
        Header: 'Payment Status',
        accessor: 'payment_status',
        Cell: (props) => {
            return <>{props?.cell?.value ? 'Paid' : 'UnPaid'}</>
        },
    },
    {
        Header: 'State',
        accessor: 'state',
    },
    {
        Header: 'Status',
        accessor: 'status',
    },
    {
        Header: 'District',
        accessor: 'district',
    },
    {
        Header: 'Village',
        accessor: 'village',
    },
    {
        Header: 'Created',
        accessor: 'created_at',
        Cell: ({ value }) => {
            return format(value, 'dd/MM/yyyy')
        },
    },
]
