import { LoginOutlined } from '@mui/icons-material'

class LoginTimer {
    constructor({ timeout, onTimeout }) {
        this.timeout = timeout
        this.onTimeout = onTimeout

        this.eventHandler = this.updateExpiredTime.bind(this)
        this.tracker()
        this.startInterval()
    }

    tracker() {
        window.addEventListener('keydown', this.eventHandler)
        window.addEventListener('click', this.eventHandler)
        window.addEventListener('mousemove', this.eventHandler)
        window.addEventListener('scroll', this.eventHandler)
    }

    updateExpiredTime() {
        if (this.timeoutTracker) {
            clearTimeout(this.timeoutTracker)
        }
        this.timeoutTracker = setTimeout(() => {
            localStorage.setItem(
                '_expiredTime',
                Date.now() + this.timeout * 1000
            )
        })
    }

    startInterval() {
        this.updateExpiredTime()

        this.interval = setInterval(() => {
            const expiredTime = parseInt(
                localStorage.getItem('_expiredTime') || 0,
                10
            )
            if (expiredTime < Date.now()) {
                console.log('timeout')
                this.onTimeout()
                this.cleanUp()
            }
        }, 1000)
    }

    cleanUp() {
        clearInterval(this.interval)
        window.removeEventListener('keydown', this.eventHandler)
    }
}

export default LoginTimer
