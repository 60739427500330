import React from 'react'
function Branches() {
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Branches</h5>
                    <div class="tab_add_new_btn">
                        <a href="#">Add</a>
                    </div>
                </div>
                <div class="card-body card-body_1">
                    <form>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <div class="card bg-white">
                                    <div class="card-body card-body_1">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <div class="card card-body bg-gray">
                                                    <h5>GODAVARIKHANI</h5>
                                                    <div class="status-options">
                                                        <a href="#">
                                                            <span class="material-icons-outlined">
                                                                edit
                                                            </span>
                                                        </a>
                                                        <a href="#">
                                                            <span class="material-icons-outlined">
                                                                delete
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4">
                                                <div class="card card-body bg-gray">
                                                    <h5>BANGALORE</h5>
                                                    <div class="status-options">
                                                        <a href="#">
                                                            <span class="material-icons-outlined">
                                                                edit
                                                            </span>
                                                        </a>
                                                        <a href="#">
                                                            <span class="material-icons-outlined">
                                                                delete
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="card card-body bg-gray">
                                                    <h5>MANCHERIAL</h5>
                                                    <div class="status-options">
                                                        <a href="#">
                                                            <span class="material-icons-outlined">
                                                                edit
                                                            </span>
                                                        </a>
                                                        <a href="#">
                                                            <span class="material-icons-outlined">
                                                                delete
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default Branches
