import React, { useEffect, useState } from 'react'
import { API } from '../../API/index'
import Swal from 'sweetalert2'

const documentclear = {
    user: '',
    document: '',
    isActive: '',
}

function DocumentTypes() {
    const [Document, setDocument] = useState({
        user: '',
        document: '',
        isActive: '',
    })

    const handleBook = async () => {
        console.log('handleBook', Document)
        try {
            const { data } = await API.post('/addDocumentType', Document)
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)
                setDocument(documentclear)
            } else {
                setDocument(documentclear)
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setDocument(documentclear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Document Types</h5>
                </div>
                <div class="card-body card-body_1">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <div class="card bg-white">
                                    <div class="card-body card-body_1">
                                        <ul class="nav nav-tabs nav-tabs-bottom nav_add_bt">
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link active"
                                                    href="#bottom-tab1"
                                                    data-bs-toggle="tab"
                                                >
                                                    Customer
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#bottom-tab2"
                                                    data-bs-toggle="tab"
                                                >
                                                    Manager
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#bottom-tab3"
                                                    data-bs-toggle="tab"
                                                >
                                                    Driver
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#bottom-tab4"
                                                    data-bs-toggle="tab"
                                                >
                                                    Store
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="nav-item tab_add_new">
                                            <a href="#">
                                                <span class="material-icons-outlined">
                                                    add
                                                </span>
                                            </a>
                                        </div>
                                        <div class="tab-content">
                                            <div
                                                class="tab-pane show active"
                                                id="bottom-tab1"
                                            >
                                                <div class="row">
                                                    <div class="col-lg-4">
                                                        <div class="card card-body bg-gray">
                                                            <h5>ADHHAR</h5>
                                                            <div class="status-options">
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        edit
                                                                    </span>
                                                                </a>
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        delete
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="card card-body bg-gray">
                                                            <h5>PANCARD</h5>
                                                            <div class="status-options">
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        edit
                                                                    </span>
                                                                </a>
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        delete
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="tab-pane"
                                                id="bottom-tab2"
                                            >
                                                <div class="row">
                                                    <div class="col-lg-4">
                                                        <div class="card card-body bg-gray">
                                                            <h5>PANCARD</h5>
                                                            <div class="status-options">
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        edit
                                                                    </span>
                                                                </a>
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        delete
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="card card-body bg-gray">
                                                            <h5>ADHHAR</h5>
                                                            <div class="status-options">
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        edit
                                                                    </span>
                                                                </a>
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        delete
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="card card-body bg-gray">
                                                            <h5>LIC</h5>
                                                            <div class="status-options">
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        edit
                                                                    </span>
                                                                </a>
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        delete
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="tab-pane"
                                                id="bottom-tab3"
                                            >
                                                <div class="row">
                                                    <div class="col-lg-4">
                                                        <div class="card card-body bg-gray">
                                                            <h5>DL</h5>
                                                            <div class="status-options">
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        edit
                                                                    </span>
                                                                </a>
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        delete
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="card card-body bg-gray">
                                                            <h5>RC</h5>
                                                            <div class="status-options">
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        edit
                                                                    </span>
                                                                </a>
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        delete
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="card card-body bg-gray">
                                                            <h5>FC</h5>
                                                            <div class="status-options">
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        edit
                                                                    </span>
                                                                </a>
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        delete
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="card card-body bg-gray">
                                                            <h5>POLLUTION</h5>
                                                            <div class="status-options">
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        edit
                                                                    </span>
                                                                </a>
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        delete
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="tab-pane"
                                                id="bottom-tab4"
                                            >
                                                <div class="row">
                                                    <div class="col-lg-4">
                                                        <div class="card card-body bg-gray">
                                                            <h5>RENTAL AGR</h5>
                                                            <div class="status-options">
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        edit
                                                                    </span>
                                                                </a>
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        delete
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="card card-body bg-gray">
                                                            <h5>TRADE LIC</h5>
                                                            <div class="status-options">
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        edit
                                                                    </span>
                                                                </a>
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        delete
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="card card-body bg-gray">
                                                            <h5>
                                                                ACCOUNT DETAILS
                                                            </h5>
                                                            <div class="status-options">
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        edit
                                                                    </span>
                                                                </a>
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        delete
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="card card-body bg-gray">
                                                            <h5>SHOP PIC</h5>
                                                            <div class="status-options">
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        edit
                                                                    </span>
                                                                </a>
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        delete
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="card card-body bg-gray">
                                                            <h5>PIC</h5>
                                                            <div class="status-options">
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        edit
                                                                    </span>
                                                                </a>
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        delete
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default DocumentTypes
