import React, { useState } from 'react'
import { CssTextField } from '../Styles'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}))

function Cities() {
    const [showCities, setShowCities] = useState(false)

    return (
        <div class="content container-fluid">
            <div class="page-header">
                <div class="row align-items-center">
                    <div class="col">
                        <h3 class="page-title">Cities</h3>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a href="/Home">Dashboard</a>
                            </li>
                            <li class="breadcrumb-item active">Cities</li>
                        </ul>
                    </div>
                    <div class="col-auto">
                        <a
                            class="btn btn-primary filter-btn"
                            id="filter_search"
                        >
                            <i class="fas fa-plus"></i> <span> Add</span>
                        </a>
                    </div>
                </div>
                {/* Add City */}
                <div id="filter_inputs" class="card filter-card mt-3">
                    <form class="card-body pb-0" action="#">
                        <div class="row">
                            <h5 class="card-title">Create City</h5>
                            <div class="col-md-4 select2-container">
                                <div class="form-group" data-select2-id="11">
                                    <CssTextField
                                        required
                                        fullWidth
                                        id="cityName"
                                        label="City Name"
                                        variant="standard"
                                        className="mt-3"
                                    />
                                </div>
                            </div>
                            <div class="col-sm-2 col-md-2">
                                <div class="form-group">
                                    <button
                                        type="submit"
                                        class="btn btn-primary mt-26"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {/* Create Geofence */}
                <div id="category" class="card filter-card mt-3">
                    <form class="card-body pb-0" action="#">
                        <div class="row">
                            <h5 class="card-title">Create Geofence</h5>
                            <div class="col-md-4 select2-container">
                                <div class="form-group" data-select2-id="11">
                                    <CssTextField
                                        required
                                        fullWidth
                                        id="GeoTittle"
                                        label="Title"
                                        variant="standard"
                                        className="mt-3"
                                    />
                                </div>
                            </div>
                            <div class="col-md-4 select2-container">
                                <div class="form-group" data-select2-id="11">
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        className="mt-3"
                                    >
                                        <InputLabel
                                            id="demo-simple-select-standard-label"
                                            style={{ color: 'black' }}
                                        >
                                            City
                                        </InputLabel>
                                        <Select
                                            required
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            //  value={age}
                                            //  onChange={handleChange}
                                            label="City"
                                            color="success"
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={10}>
                                                Chennai
                                            </MenuItem>
                                            <MenuItem value={20}>
                                                Kerala
                                            </MenuItem>
                                            <MenuItem value={30}>
                                                Hyderabad
                                            </MenuItem>
                                            <MenuItem value={30}>
                                                Andhra Pradesh
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div class="col-sm-2 col-md-2">
                                <div class="form-group">
                                    <button
                                        type="submit"
                                        class="btn btn-primary mt-26"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {/* Cities */}
                <div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div class="card card-table p-10-25">
                                <div className="mb-3">
                                    <div class="card-header pb-1">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <h3 class="page-title">
                                                    Chennai
                                                </h3>
                                            </div>
                                            <div class="col-auto">
                                                <a
                                                    class="btn btn-primary filter-btn"
                                                    id="openCategory"
                                                >
                                                    <i class="fas fa-plus"></i>{' '}
                                                    <span></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <form
                                        class="card-body"
                                        onSubmit={(e) => {
                                            e.preventDefault()
                                            console.log(
                                                'Form Successfully working'
                                            )
                                        }}
                                    >
                                        <>
                                            <div class="tab-content">
                                                <div action="#">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={4}>
                                                            <Item
                                                                sx={{
                                                                    background:
                                                                        '#e0e0e0',
                                                                }}
                                                            >
                                                                Master 01
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Item
                                                                sx={{
                                                                    background:
                                                                        '#e0e0e0',
                                                                }}
                                                            >
                                                                Master 02
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Item
                                                                sx={{
                                                                    background:
                                                                        '#e0e0e0',
                                                                }}
                                                            >
                                                                Master 03
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Item
                                                                sx={{
                                                                    background:
                                                                        '#e0e0e0',
                                                                }}
                                                            >
                                                                Master 04
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </div>
                                        </>
                                    </form>
                                </div>
                                <div>
                                    <div class="card-header">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <h3 class="page-title">
                                                    Kerala
                                                </h3>
                                            </div>
                                            <div class="col-auto">
                                                <a
                                                    class="btn btn-primary filter-btn"
                                                    id="filter_search"
                                                >
                                                    <i class="fas fa-plus"></i>{' '}
                                                    <span></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <form
                                        class="card-body"
                                        onSubmit={(e) => {
                                            e.preventDefault()
                                            console.log(
                                                'Form Successfully working'
                                            )
                                        }}
                                    >
                                        <>
                                            <div class="tab-content">
                                                <div action="#">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={4}>
                                                            <Item
                                                                sx={{
                                                                    background:
                                                                        '#e0e0e0',
                                                                }}
                                                            >
                                                                mAVRICK 01
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Item
                                                                sx={{
                                                                    background:
                                                                        '#e0e0e0',
                                                                }}
                                                            >
                                                                mAVRICK 02
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Item
                                                                sx={{
                                                                    background:
                                                                        '#e0e0e0',
                                                                }}
                                                            >
                                                                mAVRICK 03
                                                            </Item>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Item
                                                                sx={{
                                                                    background:
                                                                        '#e0e0e0',
                                                                }}
                                                            >
                                                                mAVRICK 04
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </div>
                                        </>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cities
