import React, { useState } from 'react'
import axios from 'axios'
import { RssFeed } from '@material-ui/icons'

function ModuleOrders({ userId, idForAssign,role }) {
    var urlToRedirect = ''
    const [subDetails, setSubDetails] = useState({})

    const handleSubTab = async (tab) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        let payload, payload2, res, result, v, d
        switch (tab) {
            case 1:
                urlToRedirect = 'InputSupplierBookingDetail'
                payload = {
                    user_id: userId,
                    category: 'buy',
                    type: 'buy',
                }
                payload2 = {
                    user_id: idForAssign,
                    category: 'buy',
                    type: 'buy',
                }

                res = await Promise.all([
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/adminproduct`,
                        payload,
                        config
                    ),
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/createdbybookings`,
                        payload,
                        config
                    ),
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/assignedBybookings`,
                        payload2,
                        config
                    ),
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/fpoadminproduct`,
                        payload,
                        config
                    ),
                ])
                result = []
                d = res.map((r) => {
                    v = r?.data?.data
                    if (v && v.length > 0) {
                        const a = v.map((val) => {
                            return {
                                id: val?.order_id,
                                bookingId: val?.bookingId,
                                price: role==="input"?val?.firmAmount.toFixed(2):val?.userAmount,
                                status: val.status,
                                paymentStatus: val?.payment_status
                                    ? 'Paid'
                                    : 'Unpaid',
                                created: val.created_at,
                                urlToRedirect: 'InputSupplierBookingDetail',
                            }
                        })
                        Array.prototype.push.apply(result, a)
                    }
                })
                setSubDetails(result)
                break
            case 2:
                urlToRedirect = 'LivestockBookingDetail'
                payload = {
                    user_id: userId,
                    category: 'buy',
                    type: 'live-stock',
                }
                payload2 = {
                    user_id: idForAssign,
                    category: 'buy',
                    type: 'live-stock',
                }

                res = await Promise.all([
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/adminproduct`,
                        payload,
                        config
                    ),
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/createdbybookings`,
                        payload,
                        config
                    ),
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/assignedBybookings`,
                        payload2,
                        config
                    ),
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/fpoadminproduct`,
                        payload,
                        config
                    ),
                ])
                result = []
                d = res.map((r) => {
                    v = r?.data?.data
                    if (v && v.length > 0) {
                        const a = v.map((val) => {
                            return {
                                id: val?.order_id,
                                bookingId: val?.bookingId,
                                price: role==="livestock"?val?.firmAmount.toFixed(2):val?.userAmount,
                                status: val.status,
                                paymentStatus: val?.payment_status
                                    ? 'Paid'
                                    : 'Unpaid',
                                created: val.created_at,
                                urlToRedirect: 'LivestockBookingDetail',
                            }
                        })
                        Array.prototype.push.apply(result, a)
                    }
                })
                setSubDetails(result)
                break
            case 3:
                urlToRedirect = 'AgriMachineBookingDetail'
                payload = {
                    user_id: userId,
                    category: 'book',
                    type: 'machine',
                }
                payload2 = {
                    user_id: idForAssign,
                    category: 'book',
                    type: 'machine',
                }

                res = await Promise.all([
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/adminproduct`,
                        payload,
                        config
                    ),
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/createdbybookings`,
                        payload,
                        config
                    ),
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/assignedBybookings`,
                        payload2,
                        config
                    ),
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/fpoadminproduct`,
                        payload,
                        config
                    ),
                ])
                result = []
                d = res.map((r) => {
                    v = r?.data?.data
                    if (v && v.length > 0) {
                        const a = v.map((val) => {
                            return {
                                id: val?.order_id,
                                bookingId: val?.bookingId,
                                price: role==="machine"?val?.machineAmount.toFixed(2):val?.userAmount,
                                status: val.status,
                                paymentStatus: val?.payment_status
                                    ? 'Paid'
                                    : 'Unpaid',
                                created: val.created_at,
                                urlToRedirect: 'AgriMachineBookingDetail',
                            }
                        })
                        Array.prototype.push.apply(result, a)
                    }
                })
                setSubDetails(result)
                break
            case 4:
                urlToRedirect = 'AgriTransportBookingDetail'
                payload = {
                    user_id: userId,
                    category: 'book',
                }
                payload2 = {
                    user_id: idForAssign,
                    category: 'book',
                }

                res = await Promise.all([
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/adminproduct`,
                        payload,
                        config
                    ),
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/createdbybookings`,
                        payload,
                        config
                    ),
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/assignedBybookings`,
                        payload2,
                        config
                    ),
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/fpoadminproduct`,
                        payload,
                        config
                    ),
                ])
                result = []
                d = res.map((r) => {
                    v = r?.data?.data
                    if (v && v.length > 0) {
                        const a = v.map((val) => {
                            return {
                                id: val?.order_id,
                                bookingId: val?.bookingId,
                                price: role==="transport"?val?.machineAmount.toFixed(2):val?.userAmount,
                                status: val.status,
                                paymentStatus: val?.payment_status
                                    ? 'Paid'
                                    : 'Unpaid',
                                created: val.created_at,
                                urlToRedirect: 'AgriTransportBookingDetail',
                            }
                        })
                        Array.prototype.push.apply(result, a)
                    }
                })
                setSubDetails(result)
                break
            case 5:
                urlToRedirect = 'SellCropDB'
                payload = {
                    user_id: userId,
                    category: 'sell',
                    type: 'sell',
                }
                payload2 = {
                    user_id: idForAssign,
                    category: 'sell',
                }

                res = await Promise.all([
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/adminproduct`,
                        payload,
                        config
                    ),
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/createdbybookings`,
                        payload,
                        config
                    ),
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/assignedBybookings`,
                        payload2,
                        config
                    ),
                    axios.post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/fpoadminproduct`,
                        payload,
                        config
                    ),
                ])
                result = []
                d = res.map((r) => {
                    v = r?.data?.data
                    if (v && v.length > 0) {
                        const a = v.map((val) => {
                            return {
                                id: val?.order_id,
                                bookingId: val?.bookingId,
                                price: role==="farmer"?val?.machineAmount:val?.userAmount,
                                status: val.status,
                                paymentStatus: val?.payment_status
                                    ? 'Paid'
                                    : 'Unpaid',
                                created: val.created_at,
                                urlToRedirect: 'SellCropDB',
                            }
                        })
                        Array.prototype.push.apply(result, a)
                    }
                })
                setSubDetails(result)
                break
        }
    }

    return (
        <div class="tab-pane show active" id="bottom-tab1">
            <div class="row">
                <div class="col-lg-12">
                    <ul class="nav nav-tabs nav-tabs-bottom nav_add_bt">
                        <li
                            class="nav-item"
                            style={{
                                width: '20%',
                            }}
                        >
                            <a
                                class="nav-link"
                                href="#bottom-tab1-sub1"
                                onClick={() => handleSubTab(1)}
                                data-bs-toggle="tab"
                            >
                                Input Supplier
                            </a>
                        </li>
                        <li
                            class="nav-item"
                            style={{
                                width: '20%',
                            }}
                        >
                            <a
                                class="nav-link"
                                href="#bottom-tab1-sub2"
                                onClick={() => handleSubTab(2)}
                                data-bs-toggle="tab"
                            >
                                Livestock
                            </a>
                        </li>
                        <li
                            class="nav-item"
                            style={{
                                width: '20%',
                            }}
                        >
                            <a
                                class="nav-link"
                                href="#bottom-tab1-sub3"
                                onClick={() => handleSubTab(3)}
                                data-bs-toggle="tab"
                            >
                                Agri Machine
                            </a>
                        </li>
                        <li
                            class="nav-item"
                            style={{
                                width: '20%',
                            }}
                        >
                            <a
                                class="nav-link"
                                href="#bottom-tab1-sub4"
                                onClick={() => handleSubTab(4)}
                                data-bs-toggle="tab"
                            >
                                Agri Transport
                            </a>
                        </li>
                        <li
                            class="nav-item"
                            style={{
                                width: '20%',
                            }}
                        >
                            <a
                                class="nav-link"
                                href="#bottom-tab1-sub5"
                                onClick={() => handleSubTab(5)}
                                data-bs-toggle="tab"
                            >
                                Sell Crop
                            </a>
                        </li>
                    </ul>
                </div>

                <div>
                    <div class="tab-content">
                        <div class="tab-pane show active" id="bottom-tab1-sub1">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card-body card-body_1 card-body_2">
                                        <div class="table-wrap">
                                            <div class="table-responsive">
                                                <table class="table display setting-td mb-30 store_table">
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>PRICE</th>
                                                            <th>STATUS</th>
                                                            <th>
                                                                PAYMENT STATUS
                                                            </th>
                                                            <th>CREATED</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Object.keys(subDetails)
                                                            .length !== 0 &&
                                                            subDetails.map(
                                                                (subDetail) => (
                                                                    <tr>
                                                                        <td>
                                                                            <a
                                                                                href={`/${subDetail?.urlToRedirect}/${subDetail?.bookingId}`}
                                                                                style={{
                                                                                    color: 'green',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    subDetail?.bookingId
                                                                                }
                                                                            </a>
                                                                        </td>
                                                                        <td>
                                                                            ₹{' '}
                                                                            {
                                                                                subDetail.price
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                subDetail.status
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                subDetail.paymentStatus
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {new Date(
                                                                                subDetail.created
                                                                            )
                                                                                .toDateString()
                                                                                .slice(
                                                                                    4,
                                                                                    15
                                                                                )}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModuleOrders
