import React, { useState } from 'react'
import { CssTextField } from '../Styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
// import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem'
import { useDropzone } from 'react-dropzone'
import {
    thumbsContainerState,
    thumb,
    img,
    thumbInner,
    baseStyle02,
} from '../Styles'
import Select from 'react-select'
function Bookings() {
    const [tab, setTab] = useState(1)
    const [Files, setFiles] = useState([])
    const [addInput, setAddInput] = useState([
        { id: 1, subCategory: '', Brand: '' },
    ])

    const addFields = () => {
        let myArr = [...addInput]
        myArr.push({ id: myArr.length + 1, subCategory: '', Brand: '' })
        setAddInput(myArr)
        console.log(addInput)
    }

    const removeFields = (e, id) => {
        e.preventDefault()
        let myArr = [...addInput]
        const index = myArr.findIndex((prop) => prop.id === id)
        myArr.splice(index, 1)
        setAddInput(myArr)
    }

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
        },
    })

    const PGthumbs = Files.map((file, index) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt="" />
            </div>
        </div>
    ))
    const option = [
        {
            value: '9999999999',
            label: '9999999999',
        },
    ]
    return (
        <div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card card-table p-10-25">
                        <div class="card-header">
                            <h5 class="card-title">Trader Info</h5>
                        </div>
                        <form
                            class="card-body"
                            onSubmit={(e) => {
                                e.preventDefault()
                                console.log('Form Successfully working')
                            }}
                        >
                            <div class="tab-content">
                                <div action="#">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                id="traderNum"
                                                label="Trader Number"
                                                variant="standard"
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                id="traderName"
                                                label="Trader Name"
                                                variant="standard"
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                id="traderState"
                                                label="State"
                                                variant="standard"
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                id="traderDistrict"
                                                label="District"
                                                variant="standard"
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                id="traderVillage"
                                                label="Village"
                                                variant="standard"
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                id="traderPincode"
                                                label="Pincode"
                                                variant="standard"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Farmer Info */}
                            {/* <div class="card-header">
                                <h5 class="card-title">Farmer Info</h5>
                            </div> */}
                            <div class="tab-content">
                                <div action="#">
                                    {/* <div class="row">
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                id=""
                                                label="Farmer Number"
                                                variant="standard"
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                id=""
                                                label="Farmer Name"
                                                variant="standard"
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                id=""
                                                label="State"
                                                variant="standard"
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                id=""
                                                label="District"
                                                variant="standard"
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                id=""
                                                label="Village"
                                                variant="standard"
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                id=""
                                                label="Pincode"
                                                variant="standard"
                                            />
                                        </div>
                                    </div> */}
                                    <ul className="nav nav-tabs nav-tabs-bottom nav-justified mt-4">
                                        <li class="nav-item">
                                            <a
                                                class="nav-link"
                                                href="#bottom-justified-tab4"
                                                data-bs-toggle="tab"
                                                onClick={(e) => setTab(1)}
                                            >
                                                Transport Booking
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                class="nav-link "
                                                href="#bottom-justified-tab5"
                                                data-bs-toggle="tab"
                                                onClick={(e) => setTab(2)}
                                            >
                                                Sell Crop
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* Transport Booking */}
                            {tab === 1 && (
                                <div>
                                    <div class="card-header">
                                        <h5 class="card-title">
                                            Transport Booking Info
                                        </h5>
                                    </div>
                                    <div className="mt-3">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <InputLabel
                                                    id="demo-simple-select-standard-label"
                                                    style={{ color: 'black' }}
                                                >
                                                    From
                                                </InputLabel>
                                                <CssTextField
                                                    required
                                                    type="date"
                                                    className="mt-2"
                                                    fullWidth
                                                    id="MBfrom"
                                                    label=""
                                                    variant="standard"
                                                />
                                            </div>
                                            <div class="col-md-3">
                                                <InputLabel
                                                    id="demo-simple-select-standard-label"
                                                    style={{ color: 'black' }}
                                                >
                                                    TO
                                                </InputLabel>
                                                <CssTextField
                                                    required
                                                    type="date"
                                                    className="mt-2"
                                                    fullWidth
                                                    id="MBto"
                                                    label=""
                                                    variant="standard"
                                                />
                                            </div>
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Type Of Vehicle
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="Type Of Vehicle"
                                                        color="success"
                                                    >
                                                        <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        <MenuItem value={10}>
                                                            General
                                                        </MenuItem>
                                                        <MenuItem value={20}>
                                                            OBC
                                                        </MenuItem>
                                                        <MenuItem value={30}>
                                                            SC
                                                        </MenuItem>
                                                        <MenuItem value={30}>
                                                            ST
                                                        </MenuItem>
                                                        <MenuItem value={30}>
                                                            Others
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Body Type
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="Body Type"
                                                        color="success"
                                                    >
                                                        <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        <MenuItem value={10}>
                                                            test
                                                        </MenuItem>
                                                        <MenuItem value={20}>
                                                            Krishivan Input
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Select the Crop
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="Select the Crop"
                                                        color="success"
                                                    >
                                                        <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        <MenuItem value={10}>
                                                            test
                                                        </MenuItem>
                                                        <MenuItem value={20}>
                                                            Krishivan Input
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div class="col-md-3">
                                                <InputLabel
                                                    id="demo-simple-select-standard-label"
                                                    style={{ color: 'black' }}
                                                >
                                                    Date
                                                </InputLabel>
                                                <CssTextField
                                                    required
                                                    type="date"
                                                    className="mt-2"
                                                    fullWidth
                                                    id="date"
                                                    label=""
                                                    variant="standard"
                                                />
                                            </div>
                                            <div class="col-md-3">
                                                <InputLabel
                                                    id="demo-simple-select-standard-label"
                                                    style={{ color: 'black' }}
                                                >
                                                    Time
                                                </InputLabel>
                                                <CssTextField
                                                    fullWidth
                                                    type="time"
                                                    id="time"
                                                    variant="standard"
                                                    className="mt-2"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Sell Crop */}
                            {tab === 2 && (
                                <div>
                                    <div class="card-header">
                                        <h5 class="card-title">
                                            Crop Booking Info
                                        </h5>
                                    </div>
                                    <div className="mt-3">
                                        {addInput.map((input, index) => {
                                            return (
                                                <>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            {addInput && (
                                                                <>
                                                                    {addInput.length >
                                                                        1 && (
                                                                        <span>
                                                                            Farmer{' '}
                                                                            {index +
                                                                                1}
                                                                        </span>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                        {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>Crop Name</InputLabel> */}
                                                        <div
                                                            class="col-md-3"
                                                            style={{
                                                                marginTop: '3%',
                                                            }}
                                                        >
                                                            {/* <CssTextField
                                                                fullWidth
                                                                id="farmerId"
                                                                label="Farmer ID"
                                                                variant="standard"
                                                            /> */}
                                                            <Select
                                                                options={option}
                                                                //   onChange={(e)=>handlesearchstate(e)}
                                                                // value={searchstate}
                                                                placeholder={
                                                                    'Farmer ID'
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                id="district"
                                                                label="District"
                                                                variant="standard"
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                id="village"
                                                                className="mt-3"
                                                                label="Village"
                                                                variant="standard"
                                                            />
                                                        </div>
                                                        <div
                                                            class="col-md-3"
                                                            style={{
                                                                marginTop: '3%',
                                                            }}
                                                        >
                                                            <Select
                                                                options={option}
                                                                //   onChange={(e)=>handlesearchstate(e)}
                                                                // value={searchstate}
                                                                placeholder={
                                                                    'Crop Type'
                                                                }
                                                            />
                                                            {/* <FormControl variant="standard" fullWidth className="mt-3">
                                                                <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Crop Type</InputLabel>
                                                                <Select
                                                                    required
                                                                    className="mt-3 pb-2"
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    //  value={age}
                                                                    //  onChange={handleChange}
                                                                    label="CropType"
                                                                    color="success"
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>None</em>
                                                                    </MenuItem>
                                                                    <MenuItem value={10}>Type 1</MenuItem>
                                                                    <MenuItem value={20}>Type 2</MenuItem>
                                                                    <MenuItem value={30}>Type 3</MenuItem>
                                                                </Select>
                                                            </FormControl> */}
                                                        </div>
                                                        <div
                                                            class="col-md-3"
                                                            style={{
                                                                marginTop: '3%',
                                                            }}
                                                        >
                                                            {/* <FormControl variant="standard" fullWidth className="mt-3">
                                                                <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Unit</InputLabel>
                                                                <Select
                                                                    required
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    //  value={age}
                                                                    //  onChange={handleChange}
                                                                    label="Firm"
                                                                    color="success"
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>None</em>
                                                                    </MenuItem>
                                                                    <MenuItem value={10}>KG</MenuItem>
                                                                    <MenuItem value={20}>Gram</MenuItem>
                                                                    <MenuItem value={30}>Litre</MenuItem>
                                                                </Select>
                                                            </FormControl> */}
                                                            <Select
                                                                options={option}
                                                                //   onChange={(e)=>handlesearchstate(e)}
                                                                // value={searchstate}
                                                                placeholder={
                                                                    'CropName'
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            class="col-md-3"
                                                            style={{
                                                                marginTop: '3%',
                                                            }}
                                                        >
                                                            {/* <FormControl variant="standard" fullWidth className="mt-3">
                                                                <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Unit</InputLabel>
                                                                <Select
                                                                    required
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    //  value={age}
                                                                    //  onChange={handleChange}
                                                                    label="Firm"
                                                                    color="success"
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>None</em>
                                                                    </MenuItem>
                                                                    <MenuItem value={10}>KG</MenuItem>
                                                                    <MenuItem value={20}>Gram</MenuItem>
                                                                    <MenuItem value={30}>Litre</MenuItem>
                                                                </Select>
                                                            </FormControl> */}
                                                            <Select
                                                                options={option}
                                                                //   onChange={(e)=>handlesearchstate(e)}
                                                                // value={searchstate}
                                                                placeholder={
                                                                    'Unit'
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                type="number"
                                                                id="quantity"
                                                                label="Quantity"
                                                                variant="standard"
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            {/* <CssTextField
                                                                fullWidth
                                                                type="number"
                                                                id="quantity"
                                                                label="Quantity"
                                                                variant="standard"
                                                            /> */}
                                                            <CssTextField
                                                                fullWidth
                                                                id="price"
                                                                label="Price"
                                                                variant="standard"
                                                            />
                                                        </div>
                                                        <div className="col-md-12 mt-3">
                                                            <InputLabel
                                                                id="demo-simple-select-standard-label"
                                                                style={{
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                Upload Images:
                                                            </InputLabel>
                                                            <div
                                                                class="form-group row widget-3"
                                                                style={{
                                                                    margin: 'auto',
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'center',
                                                                }}
                                                            >
                                                                <section
                                                                    className="container"
                                                                    style={
                                                                        baseStyle02
                                                                    }
                                                                >
                                                                    <div
                                                                        {...getRootProps(
                                                                            {
                                                                                className:
                                                                                    'dropzone',
                                                                            }
                                                                        )}
                                                                        style={{
                                                                            borderRadius:
                                                                                '5px',
                                                                            display:
                                                                                'flex',
                                                                            justifyContent:
                                                                                'center',
                                                                            alignItems:
                                                                                'center',
                                                                            height: '100px',
                                                                        }}
                                                                    >
                                                                        <input
                                                                            {...getInputProps()}
                                                                            multiple={
                                                                                true
                                                                            }
                                                                        />
                                                                        {Files && (
                                                                            <>
                                                                                {Files.length ===
                                                                                    0 && (
                                                                                    <p align="center">
                                                                                        Drop
                                                                                        Image
                                                                                        here
                                                                                        or
                                                                                        click
                                                                                        to
                                                                                        upload.
                                                                                    </p>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        {Files && (
                                                                            <>
                                                                                {Files.length >
                                                                                    0 && (
                                                                                    <aside
                                                                                        style={
                                                                                            thumbsContainerState
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            PGthumbs
                                                                                        }
                                                                                    </aside>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12 text-end mt-0 mb-4">
                                                        {input.id > 1 && (
                                                            <>
                                                                <button
                                                                    className="btn btn-danger"
                                                                    type="button"
                                                                    style={{
                                                                        marginRight:
                                                                            '10px',
                                                                    }}
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        removeFields(
                                                                            e,
                                                                            input.id
                                                                        )
                                                                    }
                                                                >
                                                                    - Remove
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-primary"
                                                                    onClick={() =>
                                                                        addFields()
                                                                    }
                                                                >
                                                                    + Add Farmer
                                                                </button>
                                                            </>
                                                        )}
                                                        {addInput && (
                                                            <>
                                                                {addInput.length ===
                                                                    1 && (
                                                                    <button
                                                                        type="button"
                                                                        class="btn btn-primary"
                                                                        onClick={() =>
                                                                            addFields()
                                                                        }
                                                                    >
                                                                        + Add
                                                                        Farmer
                                                                    </button>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            )}
                            <div className="col-md-12 text-end mt-2 mb-2 mt-4">
                                <button
                                    type="submit"
                                    class=" w-10 btn btn-primary"
                                >
                                    Book
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bookings
