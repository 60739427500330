import axios from 'axios'
import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import TaxTable from './taxtable'
import TaxTableFlat from './taxtableflat'

function Tax() {
    const [paymentMode, setPaymentMode] = useState('')
    const [role, setRole] = useState('')
    const [taxtype, setTaxType] = useState('')
    const [tableType,setTableType]=useState("")
    const [incgst, setIncgst] = useState('')
    const [percentage, setPercentage] = useState('')
    const [feeAmount, setFeeamount] = useState('')
    const [gstAmount, setGSTAmount] = useState('')
    const [percentageFlatfee,setPercentageFlatfee]=useState("")
    const [flatGSTpercentage,setFlatGSTpercentage]=useState("")
    const [taxdata, setTaxes] = useState([])
    useEffect(() => {
        getTaxes()
    }, [])

    const PaymentOptions = [
        {
            label: 'Input Supplier',
            value: 'input',
        },
        {
            label: 'Livestock',
            value: 'livestock',
        },
        {
            label: 'Agri Machine',
            value: 'machine',
        },
        {
            label: 'Agri Transport',
            value: 'vehicle',
        },
        {
            label: 'Sell Crop',
            value: 'sell',
        },
    ]
    const taxtypes = [
        {
            label: 'Percentage',
            value: 'percentage',
        },
        {
            label: 'flat',
            value: 'flat',
        },
    ]

    const calculate = (perc) => {
        setPercentage(perc)
        const result = incgst / (1 + perc / 100)
        setGSTAmount(Number(incgst - result).toFixed(2))
        setFeeamount(result.toFixed(2))
    }
    const fillTable=(e)=>{
        setTableType(e)
        getTaxes(e.value)
    }
    const getTaxes = (type) => {
        setTaxes([])
        const token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .get(
                `https://prod-api.krishione.com/farmer-function/get-taxes/${type}`,
                config
            )
            .then((res) => {
                setTaxes(res.data.data)
                console.log(res.data)
            })
    }

    const onSubmit = (e) => {
        var data = {
            type:taxtype.value,
            serviceType: paymentMode.value,
            incGstBookingFee: incgst,
            gst: percentage,
            bookingFee: feeAmount,
            gstAmount: gstAmount,
        }
        var flatdata={
            
                type:taxtype.value,
                serviceType: paymentMode.value,
                PercentageIncGstBookingFee: percentageFlatfee,
                gstPercentage: flatGSTpercentage,
               
            
        }
        const token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        if(taxtype.value==="flat"){
        axios
            .post(
                'https://prod-api.krishione.com/farmer-function/set-taxes',
                data,
                config
            )
            .then((res) => {
                alert('Success!')
                console.log(res.data)
                getTaxes(taxtype.value)
            })
        }
        if(taxtype.value==="percentage"){
            axios
                .post(
                    'https://prod-api.krishione.com/farmer-function/set-taxes',
                    flatdata,
                    config
                )
                .then((res) => {
                    alert('Success!')
                    console.log(res.data)
                    getTaxes(taxtype.value)
                })
            }
    }
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Tax Calculator</h4>
                    <div class="col-md-4">
                        <p>Select Tax Type</p>

                        <Select
                            style={{
                                width: '270px',
                                height: '40px',
                            }}
                            placeholder={'Select Role'}
                            value={taxtype}
                            onChange={(e) => setTaxType(e)}
                            options={taxtypes}
                        />
                    </div>
                </div>

                <div class="card-body">
                    <div class="row tx_bdr">
                    {taxtype.value === 'flat' ? 
                        <div>
                       
                        <div class="col-md-4">
                            <p>Select Service Type</p>

                            <Select
                                style={{
                                    width: '270px',
                                    height: '40px',
                                }}
                                placeholder={'Select Role'}
                                value={paymentMode}
                                onChange={(e) => setPaymentMode(e)}
                                options={PaymentOptions}
                            />
                        </div>
                        <div class="col-md-4">
                            <label
                                for="Title"
                                class="p-l-0 col-form-label input-label"
                            >
                                Flat Booking fees Inc. GSt
                            </label>
                            <input
                                class="form-control"
                                type="number"
                                value={incgst}
                                onChange={(e) => setIncgst(e.target.value)}
                            />
                        </div>
                        <div class="col-md-4">
                            <label
                                for="Percentage"
                                class="p-l-0 col-form-label input-label"
                            >
                                GST Percentage
                            </label>
                            <input
                                class="form-control"
                                type="number"
                                value={percentage}
                                onChange={(e) => calculate(e.target.value)}
                            />
                        </div>
                        <div class="col-md-4" >
                            <label
                                style={{ margintop: '24px' }}
                                for="Percentage"
                                class="p-l-0 col-form-label input-label"
                            >
                                Booking Fees
                            </label>
                            <input
                                class="form-control"
                                type="Text"
                                value={feeAmount}
                            />
                        </div>
                        <div class="col-md-4">
                            <label
                                for="Percentage"
                                class="p-l-0 col-form-label input-label"
                            >
                                GST Amount
                            </label>
                            <input
                                class="form-control"
                                type="number"
                                value={gstAmount}
                            />
                        </div>
                        </div> :null}

                        <br />
                        {taxtype.value === 'percentage' ? (
                            <div>
                                 <div class="col-md-4">
                            <p>Select Service Type</p>

                            <Select
                                style={{
                                    width: '270px',
                                    height: '40px',
                                }}
                                placeholder={'Select Role'}
                                value={paymentMode}
                                onChange={(e) => setPaymentMode(e)}
                                options={PaymentOptions}
                            />
                        </div>
                                <div class="col-md-4">
                                    <label
                                        for="Percentage"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        % Booking fees Inc. GST
                                    </label>
                                    <input
                                        class="form-control"
                                        type="number"
                                        value={percentageFlatfee}
                                        onChange={(e) => setPercentageFlatfee(e.target.value)}
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label
                                        for="Percentage"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        GST %
                                    </label>
                                    <input
                                        class="form-control"
                                        type="number"
                                        value={flatGSTpercentage}
                                        onChange={(e) => setFlatGSTpercentage(e.target.value)}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div class="row tx_bdr">
                      
                        <button
                            variant="contained"
                            onClick={(e) => onSubmit(e)}
                            component="span"
                            color="success"
                            className="btn btn-primary mt-3"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" style={{ margin: '2%' }}>
            <div class="col-md-4">
                        <p>Select Tax Type</p>

                        <Select
                            style={{
                                width: '270px',
                                height: '40px',
                            }}
                            placeholder={'Select Role'}
                            value={tableType}
                            onChange={(e) => fillTable(e)}
                            options={taxtypes}
                        />
                    </div>
            {tableType.value === 'percentage' ? (
                <TaxTableFlat inputs={taxdata} />
           ): <TaxTable inputs={taxdata} />}
            </div>
        </div>
    )
}

export default Tax
