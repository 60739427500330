import React from 'react'
import ProductInput from './InputSupplier/ProductInput'
import ProductLivestock from './Livestock/ProductLivestock'
import ProductMachine from './Machine/ProductMachine'
import ProductTransport from './Transport/ProductTransport'
import ProductCrop from './MicroEnterPreneur/ProductCrop'

function ProductCatelog() {
    const [tab, setTab] = React.useState(1)

    return (
        <div>
            <div class="row mt-3">
                <div class="col-sm-12">
                    <div class="card card-table p-10-25">
                        <div class="card-header">
                            <h3 class="card-title">
                                Create Commission Catalogue
                            </h3>
                        </div>
                        <form
                            class="card-body"
                            onSubmit={(e) => {
                                e.preventDefault()
                                console.log('Form Successfully working')
                            }}
                        >
                            <>
                                <div class="tab-content">
                                    <div action="#">
                                        <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab === 1
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab1"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(1)}
                                                >
                                                    Input Supplier Catalogue
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#bottom-justified-tab2"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(2)}
                                                >
                                                    Livestock Catalogue
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#bottom-justified-tab3"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(3)}
                                                >
                                                    Machine Catalogue
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#bottom-justified-tab4"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(4)}
                                                >
                                                    Transport Catalogue
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link "
                                                    href="#bottom-justified-tab5"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(5)}
                                                >
                                                    Micro Enterpreneur Catalogue
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </>
                        </form>
                    </div>

                    {tab === 1 && <ProductInput />}

                    {tab === 2 && <ProductLivestock />}

                    {tab === 3 && <ProductMachine />}

                    {tab === 4 && <ProductTransport />}

                    {tab === 5 && <ProductCrop />}
                </div>
            </div>
        </div>
    )
}

export default ProductCatelog
