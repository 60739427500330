// import React, { useState, useEffect } from 'react';

// import { CssTextField } from '../Styles';
// import FormControl from '@mui/material/FormControl';
// import InputLabel from '@mui/material/InputLabel';
// import { useDropzone } from 'react-dropzone';
// // import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import {
//   thumbsContainerState,
//   thumb,
//   img,
//   thumbInner,
//   mapThumb,
//   baseStyle,
// } from '../Styles';
// import LivestockProduct from './Product/LivestockProduct';
// import { API } from '../../API';
// import MicroEntTable from '../Create/CreateMicroEnt/MicroEntTable';
// // import 'antd/dist/antd.css';
// // import { Select } from 'antd';
// // const { Option } = Select;
// import Select from 'react-select';
// import axios from 'axios';
// function Settings() {
//   var urlToRedirect = '';
//   const [product, setProduct] = useState(false);
//   const [addDoc, setAddDoc] = useState(false);
//   const [addWallet, setAddWallet] = useState(false);
//   const [cropTypes, setCropTypes] = React.useState([]);
//   const [Files, setFiles] = useState([]);
//   const [Details, setDetails] = useState([]);
//   const [number, setNumber] = useState(0);
//   const [Option, setOption] = useState([]);
//   const [subDetails, setSubDetails] = useState({});
//   const [userId, setUserId] = useState('');
//   const [districtOption, setdistrictOption] = useState([]);
//   // const [farmers,setf]
//   const getdistrictOptionlist = async () => {
//     const { data } = await API.get(`/districtMicroEntrepreneur`);
//     // console.log("hkjjhj",data)
//     setdistrictOption(data?.result);
//     console.log('data', data);
//   };
//   const DistrictOption =
//     districtOption &&
//     districtOption.map((item, ind) => ({
//       value: item,
//       label: item,
//     }));

//   useEffect(async () => {
//     // console.log(localStorage.getItem('farmerid'),"hgfjgjfjh")
//     // const { data } = await API.get(`/getFarmer?type=0`);
//     //  console.log("data",data)
//     //  setDetails(data?.result);
//     getCategoryList();
//     getdistrictOptionlist();
//   }, []);

//   const getCategoryList = async () => {
//     const { data } = await API.get(`/stateMicroEntrepreneur`);
//     // console.log("hkjjhj",data)
//     setOption(data?.result);
//     console.log('data', data);
//   };
//   const option =
//     Option &&
//     Option.map((item, ind) => ({
//       value: item,
//       label: item,
//     }));

//   const handlesearchstate = async (e) => {
//     console.log('e', e.value);
//     setDetails([]);
//     const { data } = await API.get(
//       `/getStateMicroEntrepreneur?state=` + e.value
//     );
//     console.log('data', data);
//     if (data.success == true) {
//       // let arr =[]
//       // arr.push(data?.result)
//       setDetails(data?.result);
//     } else {
//     }
//   };
//   const handlesearchdistrict = async (e) => {
//     console.log('e', e.value);
//     setDetails([]);
//     const { data } = await API.get(
//       `/getDistrictMicroEntrepreneur?district=` + e.value
//     );
//     console.log('data', data);
//     if (data.success == true) {
//       // let arr =[]
//       // arr.push(data?.result)
//       setDetails(data?.result);
//     } else {
//     }
//   };

//   //   const options = [
//   //    { value: 'chocolate', label: 'Chocolate' },
//   //    { value: 'strawberry', label: 'Strawberry' },
//   //    { value: 'vanilla', label: 'Vanilla' }
//   //  ]
//   //   agrimachinefarmerid

//   //   useEffect(async() => {
//   //   console.log(localStorage.getItem('livestockfarmerid'),"hgfjgjfjh")
//   //   const { data } = await API.get(`/getLiveStocks?type=0`);
//   //    console.log("data",data)
//   //    setDetails(data?.result);
//   //  }, []);

//   const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
//     accept: 'image/*',
//     onDrop: (acceptedFiles) => {
//       setFiles(
//         acceptedFiles.map((file) =>
//           Object.assign(file, {
//             preview: URL.createObjectURL(file),
//           })
//         )
//       );
//     },
//   });

//   const PGthumbs = Files.map((file, index) => (
//     <div style={thumb} key={file.name}>
//       <div style={thumbInner}>
//         <img src={file.preview} style={img} alt='' />
//       </div>
//     </div>
//   ));

//   function onChange(value) {
//     console.log(`selected ${value}`);
//   }

//   function onSearch(val) {
//     console.log('search:', val);
//   }
//   const handleMobilesearch = (e) => {
//     if (e.target.value.length <= 10) setNumber(e.target.value);
//     if (e.target.value.length == 10) mobilechecksearch(e.target.value);
//     else setDetails([]);
//   };

//   const mobilechecksearch = async (number) => {
//     var token = localStorage.getItem('token');
//     const config = {
//       headers: { Authorization: `Bearer ${token}` },
//     };

//     var payload = {
//       mobile_no: number,
//       role: 'micro-entrepreneur',
//     };

//     var farmInfo;

//     axios
//       .post(
//         `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-register`,
//         payload,
//         config
//       )
//       .then((res) => {
//         let data = res.data;
//         var a = {
//           "id": data.data.data.id,
//           "user_id": data.data.data.user_id,
//           "firstName": data.data.data.first_name,
//           "lastName": data.data.data.last_name,
//           "mobileNumber": data.data.data.mobile_number,
//           "gender": data.data.data.gender,
//           "category": data.data.data.role,
//           "dateOfBirth": "2022-04-10",
//           "education": "",
//           "language": data.data.data.language,
//           "state": data.data.data.state,
//           "district": data.data.data.district,
//           "subDistrict": data.data.data.sub_distric,
//           "village": data.data.data.village,
//           "pincode": data.data.data.pincode,
//           "adhaarNumber": "",
//           "fullAddress": data.data.data.fullAddress,
//           "bankAccountNumber": "",
//           "bankAccountName": "",
//           "ifscCode": "",
//           "bankName": "",
//           "bankBranchName": "",
//           "createdAt": data.data.data.created_at
//         };
//         setDetails([a]);
//         setUserId(data.data.data.user_id);

//         const localId = data.data.data.user_id;

//         const data2 = {
//           user_id: localId,
//           category: 'buy',
//         };
//         axios
//           .post(
//             `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/adminproduct`,
//             data2,
//             config
//           )
//           .then((res) => {
//             const d = res.data.data;
//             urlToRedirect = 'AgriMachineBookingDetail';
//             const a = d.map((val) => {
//               if (val.status == 'pending')
//                 return {
//                   id: val?.order_id,
//                   bookingId: val?.bookingId,
//                   mobile_number: val?.mobile_number,
//                   price: val?.payment?.indicate_order_value,
//                   mode_of_payment: val?.payment?.billing_type,
//                   status: val.status,
//                   pending_amount: val?.payment?.indicate_order_value,
//                   created: val.created_at,
//                   urlToRedirect: 'AgriMachineBookingDetail',
//                 };
//               else
//                 return {
//                   id: val?.order_id,
//                   mobile_number: val?.mobile_number,
//                   bookingId: val?.bookingId,
//                   price: val?.payment?.indicate_order_value,
//                   mode_of_payment: val?.payment?.billing_type,
//                   status: val.status,
//                   pending_amount: 0,
//                   created: val.created_at,
//                   urlToRedirect: 'AgriMachineBookingDetail',
//                 };
//             });
//             let arr = [];
//             arr.push(a);
//             setDetails(arr);
//           });
//       });
//   };
//   return (
//     <div class='row'>
//       <div class='col-sm-12'>
//         {/* <h3 className="mb-3">List</h3> */}
//         <div class='card card-table'>
//           <div class='card-body'>
//             <div class='table-responsive'>
//               <div
//                 id='DataTables_Table_0_wrapper'
//                 class='dataTables_wrapper dt-bootstrap4 no-footer'
//               >
//                 <div
//                   style={{
//                     display: 'flex',
//                     flexDirection: 'row',
//                     alignItems: 'center',
//                     justifyContent: 'center',
//                     marginTop: '2%',
//                   }}
//                 >
//                   <div
//                     class='form-group'
//                     style={{ marginLeft: '2%', width: '200px' }}
//                   >
//                     <Select
//                       options={option}
//                       placeholder={'Select State'}
//                       onChange={(e) => handlesearchstate(e)}
//                     />
//                   </div>
//                   <div
//                     class='form-group'
//                     style={{ marginLeft: '2%', width: '200px' }}
//                   >
//                     <Select
//                       options={DistrictOption}
//                       placeholder={'Select District'}
//                       onChange={(e) => handlesearchdistrict(e)}
//                     />
//                   </div>
//                   {/* <div class="form-group" style={{marginLeft:'2%',width:'200px'}}>

//    <Select
//   //  options={options}
//        />
//                     </div>   */}
//                   <div class='form-group' style={{ marginLeft: '2%' }}>
//                     <input
//                       type='number'
//                       class='form-control'
//                       placeholder='Search Number'
//                       value={number}
//                       onChange={(e) => handleMobilesearch(e)}
//                     />
//                   </div>
//                 </div>

//                 <div class='row' style={{ margin: '2%' }}>
//                   {/* <FpoTable fpoList={Details} /> */}
//                   <MicroEntTable inputs={Details} />
//                 </div>

//                 <div class='row'>
//                   <div class='col-sm-12 col-md-5'>
//                     {/* <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 1 to 10 of 12 entries</div> */}
//                   </div>
//                   <div class='col-sm-12 col-md-7'>
//                     <div
//                       class='dataTables_paginate paging_simple_numbers'
//                       id='DataTables_Table_0_paginate'
//                     >
//                       <ul class='pagination'>
//                         <li
//                           class='paginate_button page-item previous disabled'
//                           id='DataTables_Table_0_previous'
//                         >
//                           <a
//                             href='#'
//                             aria-controls='DataTables_Table_0'
//                             data-dt-idx='0'
//                             tabindex='0'
//                             class='page-link'
//                           >
//                             Previous
//                           </a>
//                         </li>
//                         <li class='paginate_button page-item active'>
//                           <a
//                             href='#'
//                             aria-controls='DataTables_Table_0'
//                             data-dt-idx='1'
//                             tabindex='0'
//                             class='page-link'
//                           >
//                             1
//                           </a>
//                         </li>
//                         <li class='paginate_button page-item '>
//                           <a
//                             href='#'
//                             aria-controls='DataTables_Table_0'
//                             data-dt-idx='2'
//                             tabindex='0'
//                             class='page-link'
//                           >
//                             2
//                           </a>
//                         </li>
//                         <li
//                           class='paginate_button page-item next'
//                           id='DataTables_Table_0_next'
//                         >
//                           <a
//                             href='#'
//                             aria-controls='DataTables_Table_0'
//                             data-dt-idx='3'
//                             tabindex='0'
//                             class='page-link'
//                           >
//                             Next
//                           </a>
//                         </li>
//                       </ul>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
// export default Settings;

import React, { useState, useEffect } from 'react'

import { CssTextField } from '../Styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { useDropzone } from 'react-dropzone'
// import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem'
import {
    thumbsContainerState,
    thumb,
    img,
    thumbInner,
    mapThumb,
    baseStyle,
} from '../Styles'
import LivestockProduct from './Product/LivestockProduct'
import { API } from '../../API'
import MicroEntTable from '../Create/CreateMicroEnt/MicroEntTable'
// import 'antd/dist/antd.css';
// import { Select } from 'antd';
// const { Option } = Select;
import Select from 'react-select'
import axios from 'axios'
function Settings() {
    const [product, setProduct] = useState(false)
    const [addDoc, setAddDoc] = useState(false)
    const [addWallet, setAddWallet] = useState(false)
    const [cropTypes, setCropTypes] = React.useState([])
    const [Files, setFiles] = useState([])
    const [Details, setDetails] = useState([])
    const [number, setNumber] = useState()
    const [Option, setOption] = useState([])
    const [districtOption, setdistrictOption] = useState([])
    // const [farmers,setf]
    const getdistrictOptionlist = async () => {
        const { data } = await API.get(`/districtMicroEntrepreneur`)
        // console.log("hkjjhj",data)
        setdistrictOption(data?.result)
        console.log('data', data)
    }
    const DistrictOption =
        districtOption &&
        districtOption.map((item, ind) => ({
            value: item,
            label: item,
        }))

    useEffect(async () => {
        // console.log(localStorage.getItem('farmerid'),"hgfjgjfjh")
        // const { data } = await API.get(`/getFarmer?type=0`);
        //  console.log("data",data)
        //  setDetails(data?.result);
        getCategoryList()
        getdistrictOptionlist()
    }, [])

    const getCategoryList = async () => {
        const { data } = await API.get(`/stateMicroEntrepreneur`)
        // console.log("hkjjhj",data)
        setOption(data?.result)
        console.log('data', data)
    }
    const option =
        Option &&
        Option.map((item, ind) => ({
            value: item,
            label: item,
        }))

    const handlesearchstate = async (e) => {
        console.log('e', e.value)
        setDetails([])
        const { data } = await API.get(
            `/getStateMicroEntrepreneur?state=` + e.value
        )
        console.log('data', data)
        if (data.success == true) {
            // let arr =[]
            // arr.push(data?.result)
            setDetails(data?.result)
        } else {
        }
    }
    const handlesearchdistrict = async (e) => {
        console.log('e', e.value)
        setDetails([])
        const { data } = await API.get(
            `/getDistrictMicroEntrepreneur?district=` + e.value
        )
        console.log('data', data)
        if (data.success == true) {
            // let arr =[]
            // arr.push(data?.result)
            setDetails(data?.result)
        } else {
        }
    }

    //   const options = [
    //    { value: 'chocolate', label: 'Chocolate' },
    //    { value: 'strawberry', label: 'Strawberry' },
    //    { value: 'vanilla', label: 'Vanilla' }
    //  ]
    //   agrimachinefarmerid

    //   useEffect(async() => {
    //   console.log(localStorage.getItem('livestockfarmerid'),"hgfjgjfjh")
    //   const { data } = await API.get(`/getLiveStocks?type=0`);
    //    console.log("data",data)
    //    setDetails(data?.result);
    //  }, []);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
        },
    })

    const PGthumbs = Files.map((file, index) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt="" />
            </div>
        </div>
    ))

    function onChange(value) {
        console.log(`selected ${value}`)
    }

    function onSearch(val) {
        console.log('search:', val)
    }
    const handleMobilesearch = (e) => {
        if (e.target.value.length <= 10) setNumber(e.target.value)
        if (e.target.value.length == 10) mobilechecksearch(e.target.value)
        else setDetails([])
    }

    const mobilechecksearch = async (number) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }

        var payload = {
            mobile_no: number,
            role: 'micro-entrepreneur',
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-register`,
            payload,
            config
        )

        console.log('data', data.data, data.farm_info)

        if (data.data) {
            var a = {
                id: data.data.data.id,
                user_id: data.data.data.user_id,
                firstName: data.data.data.first_name,
                lastName: data.data.data.last_name,
                mobileNumber: data.data.data.mobile_number,
                gender: data.data.data.gender,
                category: data.data.data.role,
                dateOfBirth: '2022-04-10',
                education: '',
                language: data.data.data.language,
                state: data.data.data.state,
                district: data.data.data.district,
                subDistrict: data.data.data.sub_distric,
                village: data.data.data.village,
                pincode: data.data.data.pincode,
                adhaarNumber: '',
                fullAddress: data.data.data.fullAddress,
                bankAccountNumber: '',
                bankAccountName: '',
                ifscCode: '',
                bankName: '',
                bankBranchName: '',
                createdAt: data.data.data.created_at,
            }
            let arr = []
            arr.push(a)
            setDetails(arr)
        } else {
        }
    }
    return (
        <div class="row">
            <div class="col-sm-12">
                {/* <h3 className="mb-3">List</h3> */}
                <div class="card card-table">
                    <div class="card-body">
                        <div class="table-responsive">
                            <div
                                id="DataTables_Table_0_wrapper"
                                class="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: '2%',
                                    }}
                                >
                                    {/* <div
                                        class="form-group"
                                        style={{
                                            marginLeft: '2%',
                                            width: '200px',
                                        }}
                                    >
                                        <Select
                                            options={option}
                                            placeholder={'Select State'}
                                            onChange={(e) =>
                                                handlesearchstate(e)
                                            }
                                        />
                                    </div>
                                    <div
                                        class="form-group"
                                        style={{
                                            marginLeft: '2%',
                                            width: '200px',
                                        }}
                                    >
                                        <Select
                                            options={DistrictOption}
                                            placeholder={'Select District'}
                                            onChange={(e) =>
                                                handlesearchdistrict(e)
                                            }
                                        />
                                    </div> */}
                                    {/* <div class="form-group" style={{marginLeft:'2%',width:'200px'}}>
                 
   <Select 
  //  options={options} 
       />
                    </div>   */}
                                    <div
                                        class="form-group"
                                        style={{ marginLeft: '2%' }}
                                    >
                                        <input
                                            type="number"
                                            class="form-control"
                                            placeholder="Search Number"
                                            value={number}
                                            onChange={(e) =>
                                                handleMobilesearch(e)
                                            }
                                        />
                                    </div>
                                </div>

                                <div class="row" style={{ margin: '2%' }}>
                                    {/* <FpoTable fpoList={Details} /> */}
                                    <MicroEntTable inputs={Details} />
                                </div>

                                <div class="row">
                                    <div class="col-sm-12 col-md-5">
                                        {/* <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 1 to 10 of 12 entries</div> */}
                                    </div>
                                    <div class="col-sm-12 col-md-7">
                                        <div
                                            class="dataTables_paginate paging_simple_numbers"
                                            id="DataTables_Table_0_paginate"
                                        >
                                            <ul class="pagination">
                                                <li
                                                    class="paginate_button page-item previous disabled"
                                                    id="DataTables_Table_0_previous"
                                                >
                                                    <a
                                                        href="#"
                                                        aria-controls="DataTables_Table_0"
                                                        data-dt-idx="0"
                                                        tabindex="0"
                                                        class="page-link"
                                                    >
                                                        Previous
                                                    </a>
                                                </li>
                                                <li class="paginate_button page-item active">
                                                    <a
                                                        href="#"
                                                        aria-controls="DataTables_Table_0"
                                                        data-dt-idx="1"
                                                        tabindex="0"
                                                        class="page-link"
                                                    >
                                                        1
                                                    </a>
                                                </li>
                                                <li class="paginate_button page-item ">
                                                    <a
                                                        href="#"
                                                        aria-controls="DataTables_Table_0"
                                                        data-dt-idx="2"
                                                        tabindex="0"
                                                        class="page-link"
                                                    >
                                                        2
                                                    </a>
                                                </li>
                                                <li
                                                    class="paginate_button page-item next"
                                                    id="DataTables_Table_0_next"
                                                >
                                                    <a
                                                        href="#"
                                                        aria-controls="DataTables_Table_0"
                                                        data-dt-idx="3"
                                                        tabindex="0"
                                                        class="page-link"
                                                    >
                                                        Next
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Settings
