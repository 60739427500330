import React, { useState, useEffect } from 'react'
import { CssTextField } from '../../Styles'
import FormControl from '@mui/material/FormControl'
import { styled } from '@mui/material/styles'
import InputLabel from '@mui/material/InputLabel'
import { useDropzone } from 'react-dropzone'
import {
    thumbsContainerState,
    thumb,
    img,
    thumbInner,
    baseStyle,
    baseStyle02,
    driverDP,
} from '../../Styles'
import Button from '@mui/material/Button'
import Input from '@mui/material/Input'
import MenuItem from '@mui/material/MenuItem'
import Swal from 'sweetalert2'
import InputTransporttable from './TransportBooking'
import Select from 'react-select'
import axios from 'axios'
import $ from 'jquery'
import { storage } from '../../../firebase/index'
import { ref, getDownloadURL } from 'firebase/storage'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import Map from '../../Create/Map'
import { flexbox } from '@mui/system'
import moment from 'moment'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
}

const TransportClear = {
    farmerId: '',
    vehicleType: '',
    vehicleTypeImage: '',
    bodyType: '',
    crop: '',
    fromdate: '',
    pickupAddress: '',
    pickup_lat: '',
    pickup_lng: '',
    dropoff_lat: '',
    dropoff_lng: '',
    dropAddress: '',
    bookingMode: '',
    bookingType: 'BookNow',
    unit: '',
    billingType: '',
    incentiveOrderValue: '',
    contactPerson: '',
    bookedBy: 'MI',
}

function Transport(Props) {
    console.log('props', Props)
    const [getAllSubCategory, setgetAllSubCategory] = useState([])
    const [getAllProduct, setgetAllProduct] = useState([])
    const [getAllCrop, setgetAllCrop] = useState([])
    const [pickup, setPickup] = useState('')
    const [dropoff, setDropoff] = useState('')
    const [Transport, setTransport] = useState({
        farmerId: '',
        MicroEnterpreneurId: '',
        vehicleType: '',
        vehicleTypeImage: '',
        bodyType: '',
        crop: '',
        pickupAddress: '',
        pickup_lat: '',
        pickup_lng: '',
        dropAddress: '',
        dropoff_lat: '',
        dropoff_lng: '',
        bookingMode: '',
        bookingType: 'BookNow',
        date: '',
        time: '',
        bookedBy: 'MI',
        createdBy: 'Admin',
        contactPerson: '',
        incentiveOrderValue: '',
        billingType: '',
    })
    const [transportDate, setTransportDate] = useState(
        '1900-01-02T12:42:13.000000'
    )

    useEffect(() => {
        getTypeofvehicle()
        getBodyType()
        getCropCatelog()
    }, [])

    const getBodyType = async () => {
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-vehicles`,
                config
            )
            .then((res) => {
                console.log('body types:', res.data.vehicles)
                setgetAllSubCategory(res.data.vehicles)
            })
    }
    const getCropCatelog = async () => {
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-crops`,
                config
            )
            .then((res) => {
                console.log('crops: ', res.data.crops)
                setgetAllCrop(res.data.crops)
            })
    }
    const getTypeofvehicle = async () => {
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-vehicles`,
                config
            )
            .then((res) => {
                console.log('model machine:', res.data.vehicles)
                setgetAllProduct(res.data.vehicles)
            })
    }

    const handleBook = async () => {
        console.log('handleChange', Transport)

        var newDate = new Date()
        var date =
            newDate.getDate() +
            '/' +
            newDate.getMonth() +
            '/' +
            newDate.getFullYear()
        var time = newDate.getHours() + ':' + newDate.getMinutes()
        let data = {
            productId: Transport.productId,

            pick_up: {
                type: 'Points',
                full_address: pickup,
                coordinates: [Transport.pickup_lat, Transport.pickup_lng],
            },
            user_id: localStorage.getItem('FormerId'),
            createdById: localStorage.getItem('microId'),
            drop_off: {
                type: 'Points',
                full_address: dropoff,
                coordinates: [Transport.dropoff_lat, Transport.dropoff_lng],
            },
            vehicle: {
                image: Transport.vehicleTypeImage,
                type_of_vehicle: Transport.vehicleType,
                body_type: Transport.bodyType,
            },
            contact: Transport.contactPerson,
            corp: {
                image: Transport.cropImage,
                name: Transport.crop,
            },
            status: 'Pending',
            service_time: {
                date: Transport.date? moment(Transport.date ).format("YYYY-MM-DD HH:mm:ss.SSS") :moment(Date.now()).format("YYYY-MM-DD HH:mm:ss.SSS") ,
                time:  Transport.date? moment(Transport.date ).format("YYYY-MM-DD HH:mm:ss.SSS") :moment(Date.now()).format("YYYY-MM-DD HH:mm:ss.SSS")  ,
            },
            booking_mode: Transport.bookingMode,
            booking_type: Transport.bookingType,
            payment: {
                indicate_order_value: Transport.incentiveOrderValue,
                billing_type: Transport.billingType,
            },
        }

        try {
            let Transportval = {
                ...Transport,
                farmerId: Props && Props.farmer,
                MicroEnterpreneurId: Props && Props.MicroEnt,
            }
            var token = localStorage.getItem('token')
            var config = {
                headers: { Authorization: `Bearer ${token}` },
            }
            axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/vehicle`,
                    data,
                    config
                )
                .then((res) =>
                    Swal.fire({
                        icon: 'success',
                        iconColor: '#28a745',
                        title: data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                )
                .catch((err) =>
                    Swal.fire({
                        icon: 'warning',
                        iconColor: '#dc3545',
                        title: 'error occured!',
                        showConfirmButton: false,
                        timer: 2000,
                    })
                )
        } catch (err) {
            setTransport(TransportClear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    const BillingTypevalue = [
        {
            label: 'Cash',
            value: 'Cash',
        },
        {
            label: 'Online',
            value: 'Online',
        },
    ]
    const BookingTypeOptiom = [
        {
            value: 'BookNow',
            label: 'BookNow',
        },
        {
            value: 'Schedule',
            label: 'Schedule',
        },
    ]

    const BookingModeOptiom = [
        {
            value: 'Fixed',
            label: 'Fixed',
        },
        {
            value: 'Per KG',
            label: 'Per KG',
        },
        {
            value: 'Per Tonne',
            label: 'Per Tonne',
        },
        {
            value: 'Per KM',
            label: 'Per KM',
        },
        {
            value: 'Per Day',
            label: 'Per Day',
        },
        {
            value: 'Per Hour',
            label: 'Per Hour',
        },
        {
            value: 'Per Litre',
            label: 'Per Litre',
        },
        {
            value: 'Per Bag',
            label: 'Per Bag',
        },
    ]
    const vehicleOptiom =
        getAllSubCategory &&
        getAllSubCategory.map((item, ind) => ({
            value: item.subCategoryName,
            label: item.subCategoryName,
        }))
    const typeoptiob =
        getAllProduct &&
        getAllProduct.map((item, ind) => ({
            value: item.productName,
            label: item.productName,
            image: item.productImage,
            bodyType: item.subCategoryName,
            productId:item._id

        }))
    const cropOptiom =
        getAllCrop &&
        getAllCrop.map((item, ind) => ({
            value: item.productName,
            label: item.productName,
            image: item.productImage,
        }))

    const handleType = (e) => {
        if (e.image && e.image != '') {
            setTransport({
                ...Transport,
                vehicleTypeImage: e.image,
                vehicleType: e.value,
                bodyType: e.bodyType,
                productId:e.productId

            })
        } else {
            setTransport({ ...Transport, vehicleType: e.value })
        }
        console.log(Transport)
        console.log('image path: ', e.image)
        const starsRef = ref(storage, e.image)
        getDownloadURL(starsRef)
            .then((url) => {
                $('#typeImage').attr('src', url)
                console.log(document.getElementById('typeImage'))
            })
            .catch((error) => {
                $('#typeImage').attr('src', '')
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/object-not-found':
                        console.log("File doesn't exist")
                        break
                    case 'storage/unauthorized':
                        console.log(
                            "User doesn't have permission to access the object"
                        )
                        break
                    case 'storage/canceled':
                        console.log('User canceled the upload')
                        break
                    case 'storage/unknown':
                        console.log(
                            'Unknown error occurred, inspect the server response'
                        )
                        break
                }
            })
    }
    const mapdataPickup = (data) => {
        setPickup(data.address)
        setTransport({
            ...Transport,
            pickup_lat: data.latitude,
            pickup_lng: data.longitude,
        })
    }

    const [openPickup, setOpenPickup] = useState(false)
    const handleOpenPickup = () => {
        setOpenPickup(true)
    }
    const handleClosePickup = () => {
        setOpenPickup(false)
    }

    const mapdataDropoff = (data) => {
        setDropoff(data.address)
        setTransport({
            ...Transport,
            dropoff_lat: data.latitude,
            dropoff_lng: data.longitude,
        })
    }

    const [openDropoff, setOpenDropoff] = useState(false)
    const handleOpenDropoff = () => {
        setOpenDropoff(true)
    }
    const handleCloseDropoff = () => {
        setOpenDropoff(false)
    }

    return (
        <>
            <div>
                <div class="card-header">
                    <h5 class="card-title">Transport Booking Info</h5>
                </div>
                <div className="mt-3">
                    <div class="row">
                        <div class="col-md-3">
                            <FormControl
                                variant="standard"
                                fullWidth
                                className="mt-3"
                            >
                                {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Booking Mode</InputLabel> */}
                                <Select
                                    style={{ width: '270px', height: '40px' }}
                                    placeholder={'Select Type Of Vehicle'}
                                    // value={inputBooking.modeOfBooking}
                                    onChange={(e) => {
                                        // setTransport({ ...Transport, vehicleType: e.value});
                                        handleType(e)
                                    }}
                                    // value={Transport.vehicleType}
                                    options={typeoptiob}
                                />
                            </FormControl>
                        </div>
                        <div class="col-md-3">
                            <section className="container" style={driverDP}>
                                <aside style={thumbsContainerState}>
                                    <div style={thumb}>
                                        <div style={thumbInner}>
                                            <img
                                                style={img}
                                                id="typeImage"
                                                alt="Select Type of Machine"
                                            />
                                        </div>
                                    </div>
                                </aside>
                            </section>
                        </div>
                        {/* <div class="col-md-4">
                     <FormControl variant="standard" fullWidth className="mt-3">
                       <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>Type Of Vehicle</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                         value={Transport.vehicleType}
                         onChange={(e)=>setTransport({...Transport, vehicleType: e.target.value})}
                          label="Type Of Vehicle"
                          color="success"
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={'General'}>General</MenuItem>
                          <MenuItem value={'OBC'}>OBC</MenuItem>
                          <MenuItem value={'SC'}>SC</MenuItem>
                          <MenuItem value={'ST'}>ST</MenuItem>
                          <MenuItem value={'Others'}>Others</MenuItem>
                        </Select>
                      </FormControl>
                   </div> */}

                        {/* <div class="col-md-4">
                    <FormControl variant="standard" fullWidth className="mt-3">
                      <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>Body Type</InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={Transport.bodyType}
                        onChange={(e)=>setTransport({...Transport, bodyType: e.target.value})}
                        label="Body Type"
                        color="success"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={'test'}>test</MenuItem>
                        <MenuItem value={'Krishivan Input'}>Krishivan Input</MenuItem>
                      </Select>
                    </FormControl>
                   </div> */}
                        <div class="col-md-3">
                            <FormControl
                                variant="standard"
                                fullWidth
                                className="mt-3"
                            >
                                {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Booking Mode</InputLabel> */}
                                <Select
                                    style={{ width: '270px', height: '40px' }}
                                    placeholder={'Select the Crop'}
                                    // value={inputBooking.modeOfBooking}
                                    onChange={(e) =>
                                        setTransport({
                                            ...Transport,
                                            crop: e.value,
                                            cropImage: e.image,
                                        })
                                    }
                                    options={cropOptiom}
                                />
                            </FormControl>
                        </div>

                        {/* <div class="col-md-4">
                    <FormControl variant="standard" fullWidth className="mt-3">
                      <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>Select the Crop</InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                       value={Transport.crop}
                       onChange={(e)=>setTransport({...Transport, crop: e.target.value})}
                        label="Select the Crop"
                        color="success"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={'test'}>test</MenuItem>
                        <MenuItem value={'Krishivan Input'}>Krishivan Input</MenuItem>
                      </Select>
                    </FormControl>
                   </div> */}
                    </div>
                    <div className="row mt-5">
                        <div class="col-md-6" style={{ marginBottom: '30px' }}>
                            <InputLabel
                                id="demo-simple-select-standard-label"
                                style={{ color: 'black' }}
                            >
                                Pick Up
                            </InputLabel>
                            {/* <CssTextField
                value={Transport.pickupAddress}
                onChange={(e) => setTransport({ ...Transport, pickupAddress: e.target.value })}
                required
                type="text"
                className="mt-2"
                fullWidth
                id="MBfrom"
                placeholder="Address"
                variant="standard"
              /> */}
                            <Input
                                onClick={handleOpenPickup}
                                placeholder="Select Pickup"
                                value={pickup}
                                fullWidth
                            />
                            <Modal
                                style={{ zIndex: 100 }}
                                open={openPickup}
                                onClose={handleClosePickup}
                                aria-labelledby="parent-modal-title"
                                aria-describedby="parent-modal-description"
                            >
                                <Box sx={{ ...style, width: '90%' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'right',
                                        }}
                                    >
                                        <IconButton onClick={handleClosePickup}>
                                            <CloseIcon
                                                style={{ fontSize: '30px' }}
                                            />
                                        </IconButton>
                                    </div>
                                    <Map
                                        center={{ lat: 18.5204, lng: 73.8567 }}
                                        height="10px"
                                        zoom={15}
                                        mapdata={mapdataPickup}
                                    />
                                </Box>
                            </Modal>
                        </div>
                        <div class="col-md-6" style={{ marginBottom: '30px' }}>
                            <InputLabel
                                id="demo-simple-select-standard-label"
                                style={{ color: 'black' }}
                            >
                                Drop
                            </InputLabel>
                            <Input
                                onClick={handleOpenDropoff}
                                placeholder="Select Dropoff"
                                value={dropoff}
                                fullWidth
                            />
                            <Modal
                                style={{ zIndex: 100 }}
                                open={openDropoff}
                                onClose={handleCloseDropoff}
                                aria-labelledby="parent-modal-title"
                                aria-describedby="parent-modal-description"
                            >
                                <Box sx={{ ...style, width: '90%' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'right',
                                        }}
                                    >
                                        <IconButton
                                            onClick={handleCloseDropoff}
                                        >
                                            <CloseIcon
                                                style={{ fontSize: '30px' }}
                                            />
                                        </IconButton>
                                    </div>
                                    <Map
                                        center={{ lat: 18.5204, lng: 73.8567 }}
                                        height="10px"
                                        zoom={15}
                                        mapdata={mapdataDropoff}
                                    />
                                </Box>
                            </Modal>
                        </div>
                        <div class="col-md-3">
                            <FormControl
                                variant="standard"
                                fullWidth
                                className="mt-3"
                            >
                                {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Booking Mode</InputLabel> */}
                                <Select
                                    style={{ width: '270px', height: '40px' }}
                                    placeholder={'Booking Mode'}
                                    // value={inputBooking.modeOfBooking}
                                    onChange={(e) =>
                                        setTransport({
                                            ...Transport,
                                            bookingMode: e.value,
                                        })
                                    }
                                    options={BookingModeOptiom}
                                />
                            </FormControl>
                        </div>

                        <div class="col-md-3">
                            <FormControl
                                variant="standard"
                                fullWidth
                                className="mt-3"
                            >
                                {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Booking Type</InputLabel> */}
                                <Select
                                    style={{ width: '270px', height: '40px' }}
                                    placeholder={'Booking Type'}
                                    // value={inputBooking.modeOfBooking}
                                    onChange={(e) =>
                                        setTransport({
                                            ...Transport,
                                            bookingType: e.value,
                                        })
                                    }
                                    options={BookingTypeOptiom}
                                />
                            </FormControl>
                        </div>

                        <div class="col-md-3">
                            <FormControl
                                variant="standard"
                                fullWidth
                                className="mt-3"
                            >
                                <Select
                                    style={{ width: '270px', height: '40px' }}
                                    //  onChange={(e)=>handlecategory(e)}
                                    // onChange={(e)=>setmachine({...machine,unit:e.value})}
                                    placeholder={'Select the Billing Type'}
                                    onChange={(e) =>
                                        setTransport({
                                            ...Transport,
                                            billingType: e.value,
                                        })
                                    }
                                    //  onChange={(e)=>setInputBooking({...inputBooking,category:e.target.value})}
                                    options={BillingTypevalue}
                                />
                            </FormControl>
                        </div>
                        <div class="col-md-3">
                            <InputLabel
                                id="demo-simple-select-standard-label"
                                style={{ color: 'black' }}
                            >
                                Indicative order value
                            </InputLabel>
                            <CssTextField
                                value={Transport.incentiveOrderValue}
                                onChange={(e) =>
                                    setTransport({
                                        ...Transport,
                                        incentiveOrderValue: e.target.value,
                                    })
                                }
                                required
                                type="text"
                                className="mt-2"
                                fullWidth
                                id="MBfrom"
                                placeholder="Indicative order value"
                                variant="standard"
                            />
                        </div>
                        <div class="col-md-4">
                            <InputLabel
                                id="demo-simple-select-standard-label"
                                style={{ color: 'black' }}
                            >
                                Contact person
                            </InputLabel>
                            <CssTextField
                                value={Transport.contactPerson}
                                onChange={(e) =>
                                    setTransport({
                                        ...Transport,
                                        contactPerson: e.target.value,
                                    })
                                }
                                required
                                type="number"
                                className="mt-2"
                                fullWidth
                                id="MBfrom"
                                placeholder="contact person"
                                variant="standard"
                            />
                        </div>
                        {/* 
            <div class="col-md-3 mt-4">
                     <CssTextField 
                       fullWidth 
                       className="mt-0"
                       id="time" 
                       type="text"
                      //  value={machine.area}
                      value=""
                      //  onChange={(e)=>setmachine({...machine,area:e.target.value})}
                       label="Indicative order value" 
                       variant="standard"
                     />  
                   </div>
                 <div class="col-md-3 mt-4">
                     <CssTextField 
                       fullWidth 
                       className="mt-0"
                       id="time" 
                       type="number"
                       value=""
                      //  value={machine.area}
                      //  onChange={(e)=>setmachine({...machine,area:e.target.value})}
                       label="contact person" 
                       variant="standard"
                     />  
                   </div> */}
                    </div>

                    {console.log(
                        'Transport.bookingType',
                        Transport.bookingType
                    )}
                    {Transport.bookingType == 'BookNow' ? (
                        ''
                    ) : (
                        <div className="row mt-4 mb-4">
                            <h5>Date & Time </h5>
                            <div class="col-md-3 mt-2">
                                <InputLabel
                                    id="demo-simple-select-standard-label"
                                    style={{ color: 'black' }}
                                >
                                    Date & Time
                                </InputLabel>
                                <CssTextField
                                    value={transportDate}
                                    onChange={(e) => {
                                        if (
                                            e.target.value &&
                                            e.target.value != ''
                                        ) {
                                            setTransport({
                                                ...Transport,
                                                date:
                                                    e.target.value.substring(
                                                        0,
                                                        10
                                                    ) +
                                                    ' ' +
                                                    e.target.value.substring(
                                                        11,
                                                        16
                                                    ) +
                                                    ':00:000000',
                                            })
                                            setTransportDate(e.target.value)
                                        }
                                    }}
                                    required
                                    type="datetime-local"
                                    className="mt-2"
                                    fullWidth
                                    id="MBfrom"
                                    label=""
                                    variant="standard"
                                />
                            </div>
                        </div>
                    )}
                    <div className="col-md-12 text-end mt-2 mb-2 mt-4">
                        <button
                            type="submit"
                            class=" w-10 btn btn-primary"
                            onClick={() => handleBook()}
                        >
                            Book
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Transport
