import React, { useState } from 'react'
import BasicSettings from './BasicSettings'
import SocialLinks from './SocialLinks'
import Invoice from './Invoice'
import AppSettings from './AppSettings'
import DocumentTypes from './DocumentTypes'
import OrderNotifications from './OrderNotifications'
import CancelReasons from './CancelReasons'
import OtherSettings from './OtherSettings'
import AssignSettings from './AssignSettings'

// import PricingRules from './PricingRules';
// import TaskAllocation from './TaskAllocation';
// import TaskActions from './TaskActions';
// import TaskNotifications from './TaskNotifications';

import Events from './Events'
import TimeslotSettings from './TimeslotSettings'
import Timeslots from './Timeslots'
import TagsManagement from './TagsManagement'

import VariantTypes from './VariantTypes'
import BulkUpload from './BulkUpload'
import Tax from './Tax'
import TCS from './TCS'

import WalletSettings from './WalletSettings'

import Banners from './Banners'
import FAQ from './FAQ'
import PrivacyPolicy from './PrivacyPolicy'
import TermsConditions from './TermsConditions'
import WebsiteSettings from './WebsiteSettings'
import WebBanners from './WebBanners'
import HomepagePlugins from './HomepagePlugins'
import Cities from './Cities'
import Email from './Email'
import SMS from './SMS'
import StaffMembers from './StaffMembers'
import Branches from './Branches'
import PricingRule from './PricingRule'
import TaskAllocation from './TaskAllocation'
import TaskActions from './TaskActions'
import TaskNotifications from './TaskNotifications'
import Roles from './Roles/Roles'

function Settings() {
    const [tab, setTab] = useState(1)

    return (
        <div>
            <div class="row">
                <div class="col-xl-3 col-md-4">
                    <div class="widget settings-menu">
                        <ul>
                            <li class="menu-title1">
                                <span>General Settings</span>
                            </li>
                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 1
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(1)}
                                >
                                    {/* <i class="far fa-user"></i>*/}{' '}
                                    <span>Basic Settings</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 2
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(2)}
                                >
                                    <span>Social Links</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 3
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(3)}
                                >
                                    <span>Invoice Settings</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 4
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(4)}
                                >
                                    <span>App Settings</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 5
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(5)}
                                >
                                    <span>Document Types</span>
                                </a>
                            </li>

                            <li class="menu-title1 brd">
                                <span>Order Settings</span>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 6
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(6)}
                                >
                                    <span>Notifications</span>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 7
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(7)}
                                >
                                    <span>Cancel Reasons</span>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 8
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(8)}
                                >
                                    <span>Other Settings</span>
                                </a>
                            </li>

                            <li class="menu-title1 brd">
                                <span>Delivery Settings</span>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 9
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(9)}
                                >
                                    <span>Assign Settings</span>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 10
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(10)}
                                >
                                    <span>Pricing Rule</span>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 11
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(11)}
                                >
                                    <span>Task Allocation</span>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 12
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(12)}
                                >
                                    <span>Task Actions</span>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 13
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(13)}
                                >
                                    <span>Task Notifications</span>
                                </a>
                            </li>

                            <li class="menu-title1 brd">
                                <span>Store Settings</span>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 14
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(14)}
                                >
                                    <span>Events</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 15
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(15)}
                                >
                                    <span>Timeslot Settings</span>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 16
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(16)}
                                >
                                    <span>Timeslots</span>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 17
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(17)}
                                >
                                    <span>Tags Management</span>
                                </a>
                            </li>

                            <li class="menu-title1 brd">
                                <span>Item Settings</span>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 18
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(18)}
                                >
                                    <span>Variant Types</span>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 19
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(19)}
                                >
                                    <span>Bulk Upload</span>
                                </a>
                            </li>

                            <li class="menu-title1 brd">
                                <span>Payment Settings</span>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 20
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(20)}
                                >
                                    <span>Tax</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 20
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(21)}
                                >
                                    <span>TCS</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 21
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(21)}
                                >
                                    <span>Wallet Settings</span>
                                </a>
                            </li>

                            <li class="menu-title1 brd">
                                <span>Content Settings</span>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 22
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(22)}
                                >
                                    <span>Banners</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 23
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(23)}
                                >
                                    <span>FAQ's</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 24
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(24)}
                                >
                                    <span>Privacy Policy</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 25
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(25)}
                                >
                                    <span>Terms Conditions</span>
                                </a>
                            </li>

                            <li class="menu-title1 brd">
                                <span>Website Settings</span>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 26
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(26)}
                                >
                                    <span>Basic Settings</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 27
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(27)}
                                >
                                    <span>Web Banners</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 28
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(28)}
                                >
                                    <span>Homepage Plugins</span>
                                </a>
                            </li>

                            <li class="menu-title1 brd">
                                <span>Geo-location Settings</span>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 29
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(29)}
                                >
                                    <span>Cities</span>
                                </a>
                            </li>

                            <li class="menu-title1 brd">
                                <span>Notification Settings</span>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 30
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(30)}
                                >
                                    <span>Email</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 31
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(31)}
                                >
                                    <span>SMS</span>
                                </a>
                            </li>

                            <li class="menu-title1 brd">
                                <span>Staff Settings</span>
                            </li>

                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 32
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(32)}
                                >
                                    <span>Roles</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 33
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(33)}
                                >
                                    <span>Staff Members</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    href="#"
                                    class={
                                        tab === 34
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }
                                    onClick={(e) => setTab(34)}
                                >
                                    <span>Branches</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                {tab === 1 && <BasicSettings />}

                {tab === 2 && <SocialLinks />}

                {tab === 3 && <Invoice />}

                {tab === 4 && <AppSettings />}

                {tab === 5 && <DocumentTypes />}

                {tab === 6 && <OrderNotifications />}

                {tab === 7 && <CancelReasons />}

                {tab === 8 && <OtherSettings />}

                {tab === 9 && <AssignSettings />}

                {tab === 10 && <PricingRule />}

                {tab === 11 && <TaskAllocation />}

                {tab === 12 && <TaskActions />}

                {tab === 13 && <TaskNotifications />}

                {tab === 14 && <Events />}

                {tab === 15 && <TimeslotSettings />}

                {tab === 16 && <Timeslots />}

                {tab === 17 && <TagsManagement />}

                {tab === 18 && <VariantTypes />}

                {tab === 19 && <BulkUpload />}

                {tab === 20 && <Tax />}

                {tab === 21 && <TCS />}

                {tab === 22 && <Banners />}

                {tab === 23 && <FAQ />}

                {tab === 24 && <PrivacyPolicy />}

                {tab === 25 && <TermsConditions />}

                {tab === 26 && <WebsiteSettings />}

                {tab === 27 && <WebBanners />}

                {tab === 28 && <HomepagePlugins />}

                {tab === 29 && <Cities />}

                {tab === 30 && <Email />}

                {tab === 31 && <SMS />}

                {tab === 32 && <Roles />}

                {tab === 33 && <StaffMembers />}

                {tab === 34 && <Branches />}
            </div>
        </div>
    )
}
export default Settings
