import React, { useState, useEffect } from 'react'
import { CssTextField } from '../../Styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { useDropzone } from 'react-dropzone'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Input from '@mui/material/Input'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import {
    thumbsContainerState,
    thumb,
    img,
    thumbInner,
    baseStyle,
    driverDP,
    sectionDiv,
    uploadBtnSm,
    panImg,
} from '../../Styles'

import { API } from '../../../API'
import $ from 'jquery'

import Swal from 'sweetalert2'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import axios from 'axios'
import { storage } from '../../../firebase/index'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}
const names = ['Wheat', 'Rice', 'Barley', 'Cereal', 'Tomato', 'Oat']
const clearmachine = {
    id: '',
    price: '',
    perUnit: '',
    minimumBokingAmount: '',

    category: '',
    typeOfMachine: '',
    brandName: '',
    machineModel: '',
    machineNumber: '',
    document: '',
    documentExpiryDate: '',
}

const MachineDetails = (props) => {
    // console.log("props",props[0]._id)
    const [addFarm, setAddFarm] = useState(false)
    const [cropTypes, setCropTypes] = React.useState([])
    const [Files, setFiles] = useState([])
    const [file, setFile] = useState(null)
    const [Categorylist, setCategorylist] = useState([])
    const [subCategorylist, setsubCategorylist] = useState([])
    const [BrandCategorylist, setBrandCategorylist] = useState([])
    const [Productlist, setProductlist] = useState([])
    const [CategoryName, setCategoryName] = useState('')
    const [subcategoryName, setsubcategoryName] = useState('')
    const [citylist, setcitylist] = useState([])
    const [statelist, setstatelist] = useState([])
    const [agriMachineList, setAgriMachineList] = useState([])
    const [docImg, setDocImg] = useState(null)
    const [driverImg, setDriverImg] = useState(null)
    const [driverLicenseImg, setDriverLicenseImg] = useState(null)
    const [AAdharImg, setAAdharImg] = useState(null)
    const [PANImg, setPANImg] = useState(null)
    const [driverPAN, setDriverPAN] = useState([])
    const [driverLicense, setDriverLicense] = useState([])
    const [driverAD, setDriverAD] = useState([])

    const [states, setStates] = useState([])
    const [CommisionCat, setCommisionCat] = useState([])
    const [MachineDetailsvalue, setMachineDetailsvalue] = useState([])
    const [agriMachine, setAgriMachine] = useState({
        id: '',
        price: '',
        perUnit: '',
        minimumBokingAmount: '',
        category: '',
        typeOfMachine: '',
        brandName: '',
        machineModel: '',
        machineNumber: '',
        document: '',
        documentExpiryDate: '',
    })
    useEffect(() => {
        if (props.machine.length) {
            setMachineDetailsvalue(props.machine)
            console.log(props.machine)
        }
        getState()
        // getAgriMachineList();
        getCategoryList()
        // MachineDetailsval()
        getStateList()
        getCommisionCat()
    }, [props.machine])
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
        },
    })

    const PGthumbs = Files.map((file, index) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt="" />
            </div>
        </div>
    ))

    const getCommisionCat = async () => {
        const { data } = await API.get(
            `/getCommissionCatelog?title=CommissionCatelog&catelogName=InputSupplierCatelog&type=0`
        )
        // console.log("hkjjhj",data)
        setCommisionCat(data?.result)
        console.log('firm search', data)
    }
    const CommisionUnit =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.commissionUnit,
            label: item.commissionUnit,
        }))
    const CommisionAmount =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.commissionAmount,
            label: item.commissionAmount,
        }))
    const commisiontype =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.subCategoryName,
            label: item.subCategoryName,
        }))
    const getStateList = async () => {
        const { data } = await API.get(`/getStateList`)
        // console.log("hkjjhj",data)
        setstatelist(data?.result)
        console.log('firm search', data)
    }
    const statelistoption =
        statelist &&
        statelist.map((item, ind) => ({
            value: item,
            label: item,
        }))
    const statechane = (e) => {
        console.log('hkjjhj', e.value)
        setAgriMachine({ ...agriMachine, ['state']: e.value })
        // setInputs({ ...inputs, ['state']: e.value })
        // onChange={(e) => setInputs({ ...inputs, ['state']: e.target.value })}
        getCityList(e.value)
    }
    const MachineDetailsval = async () => {
        // const { data } = await API.get(`/getMachine?type=0&id=`+props.value && props.value[0]&&props.value[0]._id);

        const { data } = await API.get(
            `/getMachine?type=0&id=` +
                localStorage.getItem('agrimachinefarmerid')
        )
        //  console.log("data",data,"test")
        console.log('data', data, 'getMachine')

        setMachineDetailsvalue(props.MachineDetailsvalue)
    }
    const onSubmitAgriMachine = async (e) => {
        console.log('Agri Machine : ', agriMachine, props)
        e.preventDefault()
        let value = {
            ...agriMachine,
            id: localStorage.getItem('agrimachinefarmerid'),
        }
        const { data } = await API.post('/addMachine', value)
        if (data?.success) {
            Swal.fire({
                icon: 'success',
                iconColor: '#28a745',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })

            // console.log(data);
            setDocImg(null)
            setDriverImg(null)
            setDriverLicenseImg(null)
            setAAdharImg(null)
            setPANImg(null)

            setAgriMachine(clearmachine)
            setAddFarm(false)
            MachineDetailsval()
        } else {
            setAgriMachine(clearmachine)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const getCityList = async (val) => {
        const { data } = await API.get(`/getCityList?stateName=` + val)
        // console.log("hkjjhj",data)
        setcitylist(data?.result)
        console.log('firm search', data)
    }
    const citylistoption =
        citylist &&
        citylist.map((item, ind) => ({
            value: item,
            label: item,
        }))
    const getState = async () => {
        const { data } = await axios.get(
            'http://65.0.145.198:4080/api/v1/getStateList'
        )
        setStates(data?.result)
        console.log(data?.result)
    }

    const getAgriMachineList = async () => {
        const { data } = await API.get(`/getAgriMachine?type=0`)
        setAgriMachineList(data?.result)
    }

    // Document Upload
    const handleUploadDoc = (e) => {
        setDocImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const uploadTask = ref(storage, `docs/${doc.name}`)
            const uploadTask02 = uploadBytesResumable(uploadTask, doc)
            uploadTask02.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    // Handle unsuccessful uploads
                },
                () => {
                    getDownloadURL(uploadTask).then((url) => {
                        setAgriMachine({ ...agriMachine, document: url })
                    })
                }
            )
        }
    }
    //handleUploadAADHAR

    const handleUploadAADHAR = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setAAdharImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverAADhar/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        console.log('url', url)
                        setAgriMachine({
                            ...agriMachine,
                            driverAdharPhoto: url,
                        })
                    })
                }
            )
        }
    }
    //PAN
    const handleUploadPAN = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setPANImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPAN/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        console.log('url', url)
                        setAgriMachine({
                            ...agriMachine,
                            driverPancardPhoto: url,
                        })
                    })
                }
            )
        }
    }

    // Driver Licence Photo
    const handleUploadDLP = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setDriverLicenseImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverLicence/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        console.log('url', url)
                        setAgriMachine({
                            ...agriMachine,
                            drivingLicensePhoto: url,
                        })
                    })
                }
            )
        }
    }

    // Driver Photo
    const handleUploadDP = (e) => {
        setDriverImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        setAgriMachine({ ...agriMachine, driverPhoto: url })
                    })
                }
            )
        }
    }

    // Driving License Upload
    const DriverLicenseUpload = () => {
        const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
            accept: 'image/',
            onDrop: (acceptedFiles) => {
                setDriverLicense(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )
                )
            },
        })

        return (
            <section className="container" style={baseStyle}>
                <div
                    {...getRootProps({ className: 'dropzone' })}
                    style={panImg}
                >
                    <input {...getInputProps()} multiple={false} />
                    {driverLicense.length > 0 ? (
                        <aside style={thumbsContainerState}>
                            <div style={thumb}>
                                <div style={thumbInner}>
                                    <img
                                        src={driverLicense[0].preview}
                                        style={img}
                                        alt="Driving License"
                                    />
                                </div>
                            </div>
                        </aside>
                    ) : (
                        <p align="center">
                            Drop Image here or click to upload.
                        </p>
                    )}
                </div>
            </section>
        )
    }

    const handleUploadDL = (e) => {
        const doc = driverLicense[0]
        if (doc) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        setAgriMachine({
                            ...agriMachine,
                            drivingLicensePhoto: url,
                        })
                    })
                }
            )
        }
    }

    // Driver PAN upload
    const PanUpload = () => {
        const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
            accept: 'image/',
            onDrop: (acceptedFiles) => {
                setDriverPAN(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )
                )
            },
        })

        return (
            <section className="container" style={baseStyle}>
                <div
                    {...getRootProps({ className: 'dropzone' })}
                    style={panImg}
                >
                    <input {...getInputProps()} multiple={false} />
                    {driverPAN.length > 0 ? (
                        <aside style={thumbsContainerState}>
                            <div style={thumb}>
                                <div style={thumbInner}>
                                    <img
                                        src={driverPAN[0].preview}
                                        style={img}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </aside>
                    ) : (
                        <p align="center">
                            Drop Image here or click to upload.
                        </p>
                    )}
                </div>
            </section>
        )
    }

    // const handleUploadPAN = (e) => {
    //   const doc = driverPAN[0];
    //   if (doc) {
    //     const storageRef = ref(storage, `driverPhotos/${doc.name}`);
    //     const uploadTask = uploadBytesResumable(storageRef, doc);
    //     uploadTask.on('state_changed',
    //       (snapshot) => {
    //       },
    //       (error) => {
    //         console.log(error);
    //       },
    //       () => {
    //         getDownloadURL(storageRef).then((url) => {
    //           setAgriMachine({ ...agriMachine, driverPancardPhoto: url });
    //         });
    //       }
    //     );
    //   }
    // };

    // Driver Aadhaar upload
    const AadhaarUpload = () => {
        const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
            accept: 'image/',
            onDrop: (acceptedFiles) => {
                setDriverAD(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )
                )
            },
        })

        return (
            <section className="container" style={baseStyle}>
                <div
                    {...getRootProps({ className: 'dropzone' })}
                    style={panImg}
                >
                    <input {...getInputProps()} multiple={false} />
                    {driverAD.length > 0 ? (
                        <aside style={thumbsContainerState}>
                            <div style={thumb}>
                                <div style={thumbInner}>
                                    <img
                                        src={driverAD[0].preview}
                                        style={img}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </aside>
                    ) : (
                        <p align="center">
                            Drop Image here or click to upload.
                        </p>
                    )}
                </div>
            </section>
        )
    }

    const handleUploadAD = (e) => {
        const doc = driverAD[0]
        if (doc) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        setAgriMachine({
                            ...agriMachine,
                            driverAdharPhoto: url,
                        })
                    })
                }
            )
        }
    }

    // Driver Details
    const onSubmitDriverDetails = () => {
        // setTab(6);
    }

    // Submit Agri Machine
    // const onSubmitAgriMachine = async (e) => {
    //   console.log("Agri Machine : ", agriMachine);
    //   const { data } = await API.post('/agriMachine', agriMachine);
    //   if (data?.success) {
    //     Swal.fire({
    //       icon: 'success',
    //       iconColor: '#28a745',
    //       title: data?.message,
    //       showConfirmButton: false,
    //       timer: 2000
    //     });
    //     // getAgriMachineList();
    //     console.log(data);
    //   } else {
    //     Swal.fire({
    //       icon: 'warning',
    //       iconColor: '#dc3545',
    //       title: data?.message,
    //       showConfirmButton: false,
    //       timer: 2000
    //     });
    //   }
    // }

    const handleMobVal = (e) => {
        setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })
        if (e.target.value.length == 10) {
            mobilecheck(e.target.value)
        } else if (e.target.value.length > 10) {
            console.log('e.target.value.length', e.target.value.length)
            // setInputs({ ...inputs, mobileNumber: "" })
            setAgriMachine({ ...agriMachine, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: 'Mobile length should be 10',
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const mobilecheck = async (number) => {
        const { data } = await API.get(`/checkNumber?number=` + number)
        console.log('data', data)
        if (data.success == true) {
            // setFarmer({ ...farmer, [e.target.name]: number })
        } else {
            setAgriMachine({ ...agriMachine, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data.result,
                showConfirmButton: false,
                timer: 2000,
            })
        }
        // setDetails(data?.result);
    }
    const getCategoryList = async () => {
        const { data } = await API.get(
            `/getProductCategory?type=0&title=ProductCatelog&catelogName=MachineCatelog`
        )
        // console.log("hkjjhj",data)
        setCategorylist(data?.result)
    }
    const getSubCategoryList = async (val) => {
        const { data } = await API.get(
            `/getProductSubCategory?type=0&title=ProductCatelog&catelogName=MachineCatelog&categoryName=` +
                val
        )
        console.log('hkjjhj', data)
        setsubCategorylist(data?.result)
    }
    const handleCategoryChangeVarient = (e) => {
        setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })
        setCategoryName(e.target.value)
        getSubCategoryList(e.target.value)
    }
    const handleSUbCategoryChangeVarient = (e) => {
        setsubcategoryName(e.target.value)
        setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })
        getBrandCategoryList(e.target.value)
    }
    const handleBRANDCategoryChangeVarient = (e) => {
        setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })
        getProductListVarient(e.target.value)
    }
    const getProductListVarient = async (val) => {
        const { data } = await API.get(
            `/getProductCatelogProduct?type=1&title=ProductCatelog&catelogName=MachineCatelog&categoryName=` +
                CategoryName +
                '&subCategoryName=' +
                subcategoryName +
                '&brandName=' +
                val
        )
        console.log('hkjjhj', data)
        setProductlist(data?.result)
    }
    const getBrandCategoryList = async (val) => {
        const { data } = await API.get(
            `/getProductBrand?type=0&title=ProductCatelog&catelogName=MachineCatelog&categoryName=` +
                CategoryName +
                '&subCategoryName=' +
                val
        )
        console.log('hkjjhj', data)
        setBrandCategorylist(data?.result)
    }

    const handleMobilesearch = (e) => {
        console.log('e', e.target.value)
        if (e.target.value.length == 10) {
            mobilechecksearch(e.target.value)
        } else {
            setAgriMachineList([])
        }
    }
    const mobilechecksearch = async (number) => {
        const { data } = await API.get(`/searchAgriMachine?search=` + number)
        console.log('data', data)
        if (data.success == true) {
            let arr = []
            arr.push(data?.result)
            setAgriMachineList(arr)
        } else {
        }
    }
    $(function () {
        $('#txtNumeric').keydown(function (e) {
            if (e.shiftKey || e.ctrlKey || e.altKey) {
                e.preventDefault()
            } else {
                var key = e.keyCode

                if (
                    !(
                        key == 8 ||
                        key == 32 ||
                        key == 46 ||
                        (key >= 35 && key <= 40) ||
                        (key >= 65 && key <= 90)
                    )
                ) {
                    e.preventDefault()
                }
            }
        })
    })
    return (
        <div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card-body card-body_1">
                        {!addFarm ? (
                            <>
                                <div
                                    class="card"
                                    style={{ backgroundColor: '#f3f3f3 ' }}
                                >
                                    {MachineDetailsvalue &&
                                        MachineDetailsvalue &&
                                        MachineDetailsvalue.map((val, ind) => {
                                            return (
                                                <div class="card-body">
                                                    <h5 class="card-title d-flex justify-content-between store_edit">
                                                        <span>
                                                            Machine {ind + 1}
                                                        </span>
                                                        <a
                                                            href="#"
                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                        >
                                                            <span class="material-icons-outlined mr-3">
                                                                edit
                                                            </span>
                                                            <span class="material-icons-outlined">
                                                                delete
                                                            </span>
                                                        </a>
                                                    </h5>
                                                    <h6 class="card-title d-flex justify-content-between store_edit">
                                                        <span>
                                                            Machine Details
                                                        </span>
                                                    </h6>
                                                    <ul class="list-unstyled mb-0 store_list">
                                                        <div className="row">
                                                            <div className="col-md-3 mb-4">
                                                                <li class="pt-2 pb-0">
                                                                    <small class="text-dark">
                                                                        Category{' '}
                                                                    </small>
                                                                </li>
                                                                <li>
                                                                    {
                                                                        val.category
                                                                    }
                                                                </li>
                                                            </div>
                                                            <div className="col-md-3 mb-4">
                                                                <li class="pt-2 pb-0">
                                                                    <small class="text-dark">
                                                                        Type Of
                                                                        machine{' '}
                                                                    </small>
                                                                </li>
                                                                <li>
                                                                    {
                                                                        val.machine_type
                                                                    }
                                                                </li>
                                                            </div>
                                                            <div className="col-md-3 mb-4">
                                                                <li class="pt-2 pb-0">
                                                                    <small class="text-dark">
                                                                        brand
                                                                        Name{' '}
                                                                    </small>
                                                                </li>
                                                                <li>
                                                                    {
                                                                        val.brand_name
                                                                    }
                                                                </li>
                                                            </div>
                                                            <div className="col-md-3 mb-4">
                                                                <li class="pt-2 pb-0">
                                                                    <small class="text-dark">
                                                                        Machine
                                                                        Model
                                                                    </small>
                                                                </li>
                                                                <li>
                                                                    {
                                                                        val.machine_model
                                                                    }
                                                                </li>
                                                            </div>
                                                            <div className="col-md-3 mb-4">
                                                                <li class="pt-2 pb-0">
                                                                    <small class="text-dark">
                                                                        Machine
                                                                        Number{' '}
                                                                    </small>
                                                                </li>
                                                                <li>
                                                                    {
                                                                        val.machine_number
                                                                    }
                                                                </li>
                                                            </div>
                                                            <div className="col-md-3 mb-4">
                                                                <li class="pt-2 pb-0">
                                                                    <small class="text-dark">
                                                                        Document{' '}
                                                                    </small>
                                                                </li>
                                                                <li>
                                                                    <img
                                                                        src={
                                                                            val
                                                                                .document
                                                                                .image
                                                                        }
                                                                        height={
                                                                            '80px'
                                                                        }
                                                                        style={{
                                                                            width: '50%',
                                                                        }}
                                                                        alt=""
                                                                    />
                                                                    {/* <a target="_blank" href={MachineDetailsvalue.document}> {MachineDetailsvalue.document}</a> */}
                                                                    {/* {val.document} */}
                                                                </li>
                                                            </div>
                                                            <div className="col-md-3 mb-4">
                                                                <li class="pt-2 pb-0">
                                                                    <small class="text-dark">
                                                                        Document
                                                                        Exp Date{' '}
                                                                    </small>
                                                                </li>
                                                                <li>
                                                                    {
                                                                        val
                                                                            .document
                                                                            .document_exp
                                                                    }
                                                                </li>
                                                            </div>
                                                        </div>
                                                    </ul>
                                                </div>
                                            )
                                        })}
                                </div>
                            </>
                        ) : (
                            <>
                                <form
                                    class="tab-pane show active"
                                    id="bottom-justified-tab4"
                                    onSubmit={(e) => console.log(5)}
                                >
                                    {/* <div className="row">
               <h4>Add Machine</h4>
               <div className="col-md-3">
                                    <FormControl variant="standard" fullWidth className="mt-3">
                                      <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>
                                        Category
                                      </InputLabel>
                                      <Select
                                       style={{padding: '4px 0 0px'}}
                                       labelId="demo-simple-select-standard-label"
                                       id="demo-simple-select-standard"
                                       //  value={age}
                                       //  onChange={handleChange}
                                       label="Category"
                                       color="success"
                                      >
                                       <MenuItem value="">
                                         <em>None</em>
                                       </MenuItem>
                                       <MenuItem value="0">Select State</MenuItem>
                                       <MenuItem value="Hindi">Hindi</MenuItem>
                                       <MenuItem value="Bengali">Bengali</MenuItem>
                                       <MenuItem value="Urdu">Urdu</MenuItem>
                                       <MenuItem value="Punjabi">Punjabi</MenuItem>
                                       <MenuItem value="Marathi">Marathi</MenuItem>
                                       <MenuItem value="Telugu">Telugu</MenuItem>
                                       <MenuItem value="Tamil">Tamil</MenuItem>
                                      </Select>
                                    </FormControl>
                                   </div>
                                   <div className="col-md-3">
                                    <FormControl variant="standard" fullWidth className="mt-3">
                                      <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>
                                        Sub Category
                                      </InputLabel>
                                      <Select
                                       style={{padding: '4px 0 0px'}}
                                       labelId="demo-simple-select-standard-label"
                                       id="demo-simple-select-standard"
                                       //  value={age}
                                       //  onChange={handleChange}
                                       label="Sub Category"
                                       color="success"
                                      >
                                       <MenuItem value="">
                                         <em>None</em>
                                       </MenuItem>
                                       <MenuItem value="0">Select State</MenuItem>
                                       <MenuItem value="Hindi">Hindi</MenuItem>
                                       <MenuItem value="Bengali">Bengali</MenuItem>
                                       <MenuItem value="Urdu">Urdu</MenuItem>
                                       <MenuItem value="Punjabi">Punjabi</MenuItem>
                                       <MenuItem value="Marathi">Marathi</MenuItem>
                                       <MenuItem value="Telugu">Telugu</MenuItem>
                                       <MenuItem value="Tamil">Tamil</MenuItem>
                                      </Select>
                                    </FormControl>
                                   </div>
                                   <div className="col-md-3">
                                    <CssTextField 
                                      fullWidth 
                                      id="productName" 
                                      label="Product Name*" 
                                      variant="standard"
                                    />
                                   </div>
                                   <div className="col-md-3">
                                    <CssTextField 
                                      fullWidth 
                                      id="machineNumber" 
                                      label="Machine Number*" 
                                      variant="standard"
                                    />
                                   </div>
                                   <div className="col-md-3">
                                      <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black'}}>
                                        Document : 
                                      </InputLabel>
                                      <div class="form-group row widget-3" style={{ width: '150px', margin: 'auto', display: 'flex'}}>
                                        <section className="container" style={baseStyle}>
                                         <div {...getRootProps({className: 'dropzone'})}
									         style={{
									     	 borderRadius: '5px',
									    	 display: 'flex',
									    	 justifyContent: 'center',
									    	 alignItems: 'center',
                                             height: '100px'
									    	 }}
									      >
                                          <input {...getInputProps()} multiple={false}/>
										  {Files &&
										   <>
										    {Files.length === 0 && 
                                          <p align="center">Drop Image  here or click to upload.</p>
                                          }
									      </>
									     }
									     {Files && 
									  <>
                                     {Files.length > 0 && 
                                      <aside style={thumbsContainerState}>{PGthumbs}</aside>
                                      }
								    </>
								   }
                                   </div>
                                  </section>
                                  </div>
                                   </div>
                                   <div className="col-md-12">
                                    <CssTextField 
                                      id="vehicleNumber" 
                                      label="Document Exp Date" 
                                      variant="standard"
                                    />
                                   </div>
                                   <div className="col-md-12">
                                    <h5>Machine Rental Details</h5>
                                   </div>
                                   <div className="col-md-3">
                                    <CssTextField 
                                      fullWidth 
                                      type="text"
                                      id="price"
                                      label="Price"
                                      variant="standard"
                                    />
                                   </div>
                                   <div className="col-md-3">
                                    <CssTextField 
                                      fullWidth 
                                      type="text"
                                      id="Per Unit"
                                      label="Per Unit"
                                      variant="standard"
                                    />
                                   </div>
                                   <div className="col-md-3">
                                    <CssTextField 
                                      required
                                      fullWidth 
                                      type="text"
                                      id="MininumAmt"
                                      label="Minimum Booking Amount"
                                      variant="standard"
                                    />
                                   </div>
    
                    <div class="col-md-12 text-end mt-2 mb-2">
                       <button type="submit" class=" w-10 btn btn-primary">Submit</button>
                    </div>
             </div>  */}
                                    <div className="row">
                                        <h4>Add Machine</h4>
                                        <div className="col-md-3">
                                            <FormControl
                                                variant="standard"
                                                fullWidth
                                                className="mt-3"
                                            >
                                                <InputLabel
                                                    id="demo-simple-select-standard-label"
                                                    style={{ color: 'black' }}
                                                >
                                                    Category
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    label="Category"
                                                    color="success"
                                                    name="category"
                                                    value={agriMachine.category}
                                                    onChange={(e) =>
                                                        handleCategoryChangeVarient(
                                                            e
                                                        )
                                                    }
                                                    // onChange={(e) => setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })}
                                                >
                                                    {/* <MenuItem value=""><em> - Select State - </em></MenuItem>
                              <MenuItem value="Hindi">Hindi</MenuItem>
                              <MenuItem value="Bengali">Bengali</MenuItem>
                              <MenuItem value="Urdu">Urdu</MenuItem>
                              <MenuItem value="Punjabi">Punjabi</MenuItem>
                              <MenuItem value="Marathi">Marathi</MenuItem>
                              <MenuItem value="Telugu">Telugu</MenuItem>
                              <MenuItem value="Tamil">Tamil</MenuItem> */}
                                                    {Categorylist &&
                                                        Categorylist.map(
                                                            (val, ind) => {
                                                                // {console.log("Categorylist",val)}
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            val.categoryName
                                                                        }
                                                                    >
                                                                        {
                                                                            val.categoryName
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-3">
                                            <FormControl
                                                variant="standard"
                                                fullWidth
                                                className="mt-3"
                                            >
                                                <InputLabel
                                                    id="demo-simple-select-standard-label"
                                                    style={{ color: 'black' }}
                                                >
                                                    TypeOfMachine
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    label="Type Of Machine"
                                                    color="success"
                                                    name="typeOfMachine"
                                                    value={
                                                        agriMachine.typeOfMachine
                                                    }
                                                    onChange={(e) =>
                                                        handleSUbCategoryChangeVarient(
                                                            e
                                                        )
                                                    }
                                                    // onChange={(e) => setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })}
                                                >
                                                    {/* <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="0">Select State</MenuItem>
                              <MenuItem value="Hindi">Hindi</MenuItem>
                              <MenuItem value="Bengali">Bengali</MenuItem>
                              <MenuItem value="Urdu">Urdu</MenuItem>
                              <MenuItem value="Punjabi">Punjabi</MenuItem>
                              <MenuItem value="Marathi">Marathi</MenuItem>
                              <MenuItem value="Telugu">Telugu</MenuItem>
                              <MenuItem value="Tamil">Tamil</MenuItem> */}
                                                    {subCategorylist &&
                                                        subCategorylist.map(
                                                            (val, ind) => {
                                                                // {console.log("Categorylist",val)}
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            val.subCategoryName
                                                                        }
                                                                    >
                                                                        {
                                                                            val.subCategoryName
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-3">
                                            <FormControl
                                                variant="standard"
                                                fullWidth
                                                className="mt-3"
                                            >
                                                <InputLabel
                                                    id="demo-simple-select-standard-label"
                                                    style={{ color: 'black' }}
                                                >
                                                    Brand Name
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    label="Brand Name"
                                                    color="success"
                                                    name="brandName"
                                                    value={
                                                        agriMachine.brandName
                                                    }
                                                    onChange={(e) =>
                                                        handleBRANDCategoryChangeVarient(
                                                            e
                                                        )
                                                    }
                                                    // onChange={(e) => setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })}
                                                >
                                                    {BrandCategorylist &&
                                                        BrandCategorylist.map(
                                                            (val, ind) => {
                                                                // {console.log("Categorylist",val)}
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            val.brandName
                                                                        }
                                                                    >
                                                                        {
                                                                            val.brandName
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-md-3">
                                            <FormControl
                                                variant="standard"
                                                fullWidth
                                                className="mt-3"
                                            >
                                                <InputLabel
                                                    id="demo-simple-select-standard-label"
                                                    style={{ color: 'black' }}
                                                >
                                                    Machine Model
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    label="Machine model"
                                                    color="success"
                                                    name="machineModel"
                                                    value={
                                                        agriMachine.machineModel
                                                    }
                                                    onChange={(e) =>
                                                        setAgriMachine({
                                                            ...agriMachine,
                                                            [e.target.name]:
                                                                e.target.value,
                                                        })
                                                    }
                                                >
                                                    {Productlist &&
                                                        Productlist.map(
                                                            (val, ind) => {
                                                                // {console.log("Categorylist",val)}
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            val.productName
                                                                        }
                                                                    >
                                                                        {
                                                                            val.productName
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {/* <div className="col-md-3">
                          <CssTextField
                            fullWidth
                            id="productName"
                            label="Product Name"
                            variant="standard"
                            name="productName"
                            value={agriMachine.productName}
                            onChange={(e) => setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })}
                          />
                        </div> */}
                                        <div className="col-md-3">
                                            <CssTextField
                                                fullWidth
                                                id="machineNumber"
                                                label="Machine Number"
                                                variant="standard"
                                                name="machineNumber"
                                                value={
                                                    agriMachine.machineNumber
                                                }
                                                onChange={(e) =>
                                                    setAgriMachine({
                                                        ...agriMachine,
                                                        [e.target.name]:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div
                                            className="col-md-12 mt-3"
                                            style={{}}
                                        >
                                            <InputLabel
                                                id="demo-simple-select-standard-label"
                                                style={{ color: 'black' }}
                                                className="mb-3"
                                            >
                                                Document :
                                            </InputLabel>
                                        </div>
                                        <div className="col-md-3">
                                            <InputLabel
                                                id="demo-simple-select-standard-label"
                                                style={{
                                                    color: 'black',
                                                    marginTop: '22px',
                                                }}
                                            >
                                                Document Expire Date:
                                            </InputLabel>
                                            <CssTextField
                                                fullWidth
                                                type="date"
                                                id="documentExpiryDate"
                                                variant="standard"
                                                name="documentExpiryDate"
                                                value={
                                                    agriMachine.documentExpiryDate
                                                }
                                                onChange={(e) =>
                                                    setAgriMachine({
                                                        ...agriMachine,
                                                        [e.target.name]:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <label
                                                htmlFor="contained-button-file"
                                                style={{ marginTop: '60px' }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    component="span"
                                                    color="success"
                                                >
                                                    <Input
                                                        accept="image/"
                                                        id="contained-button-file"
                                                        multiple="false"
                                                        type="file"
                                                        onChange={
                                                            handleUploadDoc
                                                        }
                                                    />
                                                    {/* Select File */}
                                                </Button>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <aside style={thumbsContainerState}>
                                                <div style={thumb}>
                                                    <div style={thumbInner}>
                                                        <img
                                                            src={docImg}
                                                            style={img}
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            </aside>
                                        </div>
                                        {/* <div class="col-md-12 text-end mt-2 mb-2">
                          <button className="w-10 btn btn-secondary" style={{ marginRight: '10px' }} onClick={e => setTab(2)}>Previous</button>
                          <button type="submit" class=" w-10 btn btn-primary">Next</button>
                        </div> */}
                                    </div>
                                    {/* </br> */}
                                    <br></br>
                                    <br></br>
                                    <div className="col-md-12">
                                        <h5>Machine Rental Details</h5>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <CssTextField
                                                fullWidth
                                                type="text"
                                                id="price"
                                                label="price"
                                                variant="standard"
                                                name="price"
                                                value={agriMachine.price}
                                                onChange={(e) =>
                                                    setAgriMachine({
                                                        ...agriMachine,
                                                        [e.target.name]:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <CssTextField
                                                fullWidth
                                                type="text"
                                                id="Per Unit"
                                                label="Per Unit"
                                                variant="standard"
                                                name="perUnit"
                                                value={agriMachine.perUnit}
                                                onChange={(e) =>
                                                    setAgriMachine({
                                                        ...agriMachine,
                                                        [e.target.name]:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <CssTextField
                                                fullWidth
                                                type="text"
                                                id="MininumAmt"
                                                label="Minimum Booking Amount"
                                                variant="standard"
                                                name="minimumBokingAmount"
                                                value={
                                                    agriMachine.minimumBokingAmount
                                                }
                                                onChange={(e) =>
                                                    setAgriMachine({
                                                        ...agriMachine,
                                                        [e.target.name]:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div class="col-md-12 text-end mt-2 mb-2">
                                            <button
                                                className=" btn btn-primary"
                                                style={{ marginRight: '10px' }}
                                                onClick={(e) =>
                                                    onSubmitAgriMachine(e)
                                                }
                                            >
                                                Submit
                                            </button>
                                            {/* <button type="submit" class=" w-10 btn btn-primary">Next</button> */}
                                        </div>
                                    </div>
                                </form>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MachineDetails
