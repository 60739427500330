import React from 'react'

function TermsConditions() {
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Terms & Conditions</h5>
                </div>
                <div class="card-body ">
                    <form>
                        <div class="row form-group">
                            <div class="col-sm-7"></div>
                        </div>

                        <div class="text-end">
                            <button type="submit" class="btn btn-primary">
                                Save Changes
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default TermsConditions
