import React from 'react'
import Switch from '@mui/material/Switch'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'

function TaskActions() {
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Task Actions</h5>
                </div>
                <div class="card-body card-body_1">
                    <form>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <div class="card bg-white">
                                    <div class="card-body card-body_1">
                                        <p>Action a driver can in a task</p>
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="card-body card-body_1">
                                                    <div class="table-wrap">
                                                        <div class="table-responsive">
                                                            <table class="table display setting-td mb-30">
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            ACTION
                                                                        </th>
                                                                        <th>
                                                                            ENABLED
                                                                        </th>
                                                                        <th>
                                                                            REQUIRED
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            Add
                                                                            Notes
                                                                        </td>
                                                                        <td>
                                                                            <FormControl component="fieldset">
                                                                                <FormGroup
                                                                                    aria-label="position"
                                                                                    row
                                                                                >
                                                                                    <FormControlLabel
                                                                                        value="start"
                                                                                        control={
                                                                                            <Switch color="success" />
                                                                                        }
                                                                                        label=""
                                                                                    />
                                                                                </FormGroup>
                                                                            </FormControl>
                                                                        </td>
                                                                        <td>
                                                                            -
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            Add
                                                                            Images
                                                                        </td>
                                                                        <td>
                                                                            <FormControl component="fieldset">
                                                                                <FormGroup
                                                                                    aria-label="position"
                                                                                    row
                                                                                >
                                                                                    <FormControlLabel
                                                                                        value="start"
                                                                                        control={
                                                                                            <Switch color="success" />
                                                                                        }
                                                                                        label=""
                                                                                    />
                                                                                </FormGroup>
                                                                            </FormControl>
                                                                        </td>
                                                                        <td>
                                                                            -
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            Add
                                                                            Signature
                                                                        </td>
                                                                        <td>
                                                                            <FormControl component="fieldset">
                                                                                <FormGroup
                                                                                    aria-label="position"
                                                                                    row
                                                                                >
                                                                                    <FormControlLabel
                                                                                        value="start"
                                                                                        control={
                                                                                            <Switch
                                                                                                color="success"
                                                                                                defaultChecked
                                                                                            />
                                                                                        }
                                                                                        label=""
                                                                                    />
                                                                                </FormGroup>
                                                                            </FormControl>
                                                                        </td>
                                                                        <td>
                                                                            <FormControl component="fieldset">
                                                                                <FormGroup
                                                                                    aria-label="position"
                                                                                    row
                                                                                >
                                                                                    <FormControlLabel
                                                                                        value="start"
                                                                                        control={
                                                                                            <Switch color="success" />
                                                                                        }
                                                                                        label=""
                                                                                    />
                                                                                </FormGroup>
                                                                            </FormControl>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default TaskActions
