import React from 'react'

function WebsiteSettings() {
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Website Settings</h5>
                </div>
                <div class="card-body">
                    <form>
                        <div class="row form-group">
                            <div class="col-sm-4">
                                <label
                                    for="name"
                                    class="col-form-label input-label"
                                >
                                    Logo
                                </label>
                                <div class="d-flex align-items-center">
                                    <label
                                        class="avatar avatar-xxl_web profile-cover-avatar m-0"
                                        for="edit_img"
                                    >
                                        <img
                                            id="avatarImg"
                                            class="avatar-img"
                                            src="../assets/images/img.png"
                                            alt="Profile Image"
                                        />
                                        <input type="file" id="edit_img" />
                                        <span class="avatar-edit">
                                            <span class="material-icons-outlined">
                                                photo_camera
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label
                                    for="name"
                                    class="col-form-label input-label "
                                >
                                    Favicon
                                </label>
                                <div class="d-flex align-items-center">
                                    <label
                                        class="avatar avatar-xxl_web profile-cover-avatar m-0"
                                        for="edit_img"
                                    >
                                        <img
                                            id="avatarImg"
                                            class="avatar-img"
                                            src="../assets/images/img.png"
                                            alt="Profile Image"
                                        />
                                        <input type="file" id="edit_img" />
                                        <span class="avatar-edit">
                                            <span class="material-icons-outlined">
                                                photo_camera
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="row form-group p-hr-1">
                            <div class="col-sm-4">
                                <label
                                    for="addressline1"
                                    class="p-l-0 col-form-label input-label"
                                >
                                    Color
                                </label>
                                <input
                                    type="text"
                                    class="form-control m-t-10"
                                    value="#7ED321"
                                />
                            </div>
                            <div class="col-sm-4">
                                <label
                                    for="addressline1"
                                    class="p-l-0 col-form-label input-label"
                                >
                                    Footer Color
                                </label>
                                <input
                                    type="text"
                                    class="form-control m-t-10"
                                    value="#FFF"
                                />
                            </div>
                            <div class="col-sm-8">
                                <label
                                    for="addressline1"
                                    class="p-l-0 col-form-label input-label"
                                >
                                    Homepage Title
                                </label>
                                <input
                                    type="text"
                                    class="form-control m-t-10"
                                    value="B2B Online Mandi for Retailers"
                                />
                            </div>
                            <div class="col-sm-8">
                                <label
                                    for="addressline1"
                                    class="p-l-0 col-form-label input-label"
                                >
                                    Homepage Description
                                </label>
                                <input
                                    type="text"
                                    class="form-control m-t-10"
                                    value="-"
                                />
                            </div>
                            <div class="col-sm-6">
                                <label
                                    for="addressline1"
                                    class="p-l-0 col-form-label input-label"
                                >
                                    Header Image
                                </label>
                                <div class="d-flex align-items-center">
                                    <label
                                        class="avatar avatar-xxl_lg profile-cover-avatar m-0"
                                        for="edit_img"
                                    >
                                        <span class="banner_txt_img">
                                            Add Images
                                        </span>
                                        <input type="file" id="edit_img" />
                                        <span class="avatar-edit">
                                            <span class="material-icons-outlined">
                                                photo_camera
                                            </span>
                                        </span>
                                    </label>
                                </div>
                                <span class="banner_txt">
                                    Use images with size 500(h) x 1400(w).
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="text-end btn-krish">
                                <button type="submit" class="btn btn-primary">
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default WebsiteSettings
