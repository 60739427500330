import React from 'react'

function CancelReasons() {
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Order Cancel Reasons</h5>
                </div>
                <div class="card-body card-body_1">
                    <form>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <div class="card bg-white">
                                    <div class="card-body card-body_1">
                                        <ul class="nav nav-tabs nav-tabs-bottom nav_add_bt">
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link active"
                                                    href="#bottom-tab1"
                                                    data-bs-toggle="tab"
                                                >
                                                    Customer
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#bottom-tab2"
                                                    data-bs-toggle="tab"
                                                >
                                                    Manager
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="nav-item tab_add_new">
                                            <a href="#">
                                                <span class="material-icons-outlined">
                                                    add
                                                </span>
                                            </a>
                                        </div>
                                        <div class="tab-content">
                                            <div
                                                class="tab-pane show active"
                                                id="bottom-tab1"
                                            >
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="card-body card-body_1">
                                                            <div class="table-wrap">
                                                                <div class="table-responsive">
                                                                    <table class="table display setting-td mb-30">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    TITLE
                                                                                </th>
                                                                                <th>
                                                                                    ACTIONS
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    Testing
                                                                                </td>
                                                                                <td>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            edit
                                                                                        </span>
                                                                                    </a>
                                                                                    <a
                                                                                        href="#"
                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                    >
                                                                                        <span class="material-icons-outlined">
                                                                                            delete
                                                                                        </span>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="tab-pane"
                                                id="bottom-tab2"
                                            >
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="noRecords">
                                                            <img
                                                                src="../assets/images/norecord_empty.svg"
                                                                alt=""
                                                            />
                                                            <h5>No Records</h5>
                                                            <p>
                                                                No data found
                                                                for this search
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CancelReasons
