import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'

import CountTable from './CountTable'

const CountTab = () => {
    const [tab, setTab] = useState('livestock')
    const [counts, setCounts] = useState([])
    const [from, setFrom] = useState(Date.parse(new Date('01/01/1900')))
    const [to, setTo] = useState(Date.parse(new Date('01/01/1900')))

    useEffect(() => {
        console.log(new Date('01/01/1900'), to, from)
        if (to > 0 && from > 0) getCounts()
    }, [tab, to, from])

    const getCounts = async () => {
        const token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/auth-function/report-count/${tab}?from=${from}&to=${to}`,
            {},
            config
        )
        if (data != undefined && Object.keys(data.data).length != 0) {
            let finalData = []
            let countIds = data.data
            Object.keys(countIds).forEach(function (key, index) {
                let obj = {
                        id: key,
                        mobile_number: countIds[key][0].mobile_number,
                    },
                    total = 0
                countIds[key].map((countData) => {
                    let k = countData['type'],
                        v = countData['total']
                    total = total + v
                    obj = {
                        ...obj,
                        [k]: v,
                    }
                })
                obj = { ...obj, total: total }
                finalData.push(obj)
            })
            setCounts(finalData)
            return
        }
    }

    const handleCallback = (start, end, label) => {
        const d1 = Date.parse(start._d)
        const d2 = Date.parse(end._d)
        setFrom(d1)
        setTo(d2)
    }

    return (
        <div class="content container-fluid">
            <div class="page-header">
                <div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div class="card card-table p-10-25">
                                <div class="card-header">
                                    <h3 class="card-title">Count</h3>
                                </div>
                                <form
                                    class="card-body"
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        console.log('Form Successfully working')
                                    }}
                                >
                                    <>
                                        <div class="tab-content">
                                            <div action="#">
                                                <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                                    <li class="nav-item">
                                                        <a
                                                            class={
                                                                tab ===
                                                                'livestock'
                                                                    ? 'nav-link active'
                                                                    : 'nav-link'
                                                            }
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(
                                                                    'livestock'
                                                                )
                                                            }
                                                        >
                                                            Live Stock
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab2"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(
                                                                    'agri-input-supplier'
                                                                )
                                                            }
                                                        >
                                                            Input Supplier
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(
                                                                    'agri-machine'
                                                                )
                                                            }
                                                        >
                                                            Agri Machine
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(
                                                                    'agri-transport'
                                                                )
                                                            }
                                                        >
                                                            Agri Transport
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(
                                                                    'micro-entrepreneur'
                                                                )
                                                            }
                                                        >
                                                            Micro Entrepreneur
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab('trader')
                                                            }
                                                        >
                                                            Traders
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab('fpo')
                                                            }
                                                        >
                                                            Fpo
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                </form>
                            </div>

                            <DateRangePicker
                                initialSettings={{
                                    startDate: new Date().getDate(),
                                    endDate: new Date().getDate(),
                                }}
                                onCallback={handleCallback}
                            >
                                <input className="date-filter" />
                            </DateRangePicker>

                            {/* here our table component */}
                            <CountTable counts={counts} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CountTab
