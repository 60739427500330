import React, { useEffect, useState } from 'react'
import { API } from '../../API/index'
import Swal from 'sweetalert2'
const Sociallinkclear = {
    name: '',
    link: '',
    isActive: false,
}

function SocialLinks() {
    const [Sociallink, setSociallink] = useState({
        name: '',
        link: '',
        isActive: false,
    })

    // useEffect(()=>{
    //    hanlesave();
    // })

    const hanlesave = async () => {
        try {
            const { data } = await API.post(`/addSocialLink`, Sociallink)
            console.log('data', data)
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)
                setSociallink(Sociallinkclear)
            } else {
                setSociallink(Sociallinkclear)
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setSociallink(Sociallinkclear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Social Links</h5>
                </div>
                <div class="card-body">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div class="row form-group">
                            <div class="col-sm-7">
                                <div class="row form-group">
                                    <label
                                        for="addressline1"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        Facebook
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="addressline1"
                                        placeholder="https://facebook.com/goteso"
                                        value={Sociallink.name}
                                        onChange={(e) =>
                                            setSociallink({
                                                ...Sociallink,
                                                name: e.target.value,
                                            })
                                        }
                                    />
                                </div>

                                <div class="row form-group">
                                    <label
                                        for="addressline1"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        Instagram
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="addressline1"
                                        placeholder="https://www.instagram.com/goteso"
                                        value={Sociallink.link}
                                        onChange={(e) =>
                                            setSociallink({
                                                ...Sociallink,
                                                link: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="text-end">
                            <button
                                type="submit"
                                class="btn btn-primary"
                                onClick={() => hanlesave()}
                            >
                                Save Changes
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SocialLinks
