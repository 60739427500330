import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'

export const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'black',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'green',
    },
    marginBottom: '16px',
    marginTop: '16px',
    padding: '4px 0 10px',
})

export const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
    padding: 20,
}

export const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginBottom: '15px',
    marginTop: '15px',
}

export const baseStyle02 = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#e0ebd8',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginBottom: '15px',
    marginTop: '15px',
}
export const driverDP = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px',
    width: 'fit-content',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginBottom: '15px',
    marginTop: '15px',
    justifyContent: 'center',
}

export const thumbsContainerState = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
}

export const thumb = {
    position: 'relative',
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
}

export const mapThumb = {
    display: 'block',
    borderRadius: 2,
    border: '1px solid #ddd',
    marginBottom: 8,
    width: '100%',
    padding: 4,
    boxSizing: 'border-box',
}

export const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
}

export const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
}

export const sectionDiv = {
    width: '150px',
    minHeight: '170px',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
}

export const uploadBtnSm = {
    lineHeight: '1.10',
    textTransform: 'none',
}

export const panImg = {
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
}
