import React, { useState, useEffect } from 'react'
import axios from 'axios'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Selects from '@mui/material/Select'
import Select from 'react-select'
import { API } from '../../../API/index'
import { CssTextField } from '../../Styles'
import MenuItem from '@mui/material/MenuItem'
import Input from '@mui/material/Input'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import { storage } from '../../../firebase/index'
import InputTable from './InputTable'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import { thumbsContainerState, thumb, img, thumbInner } from '../../Styles'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Swal from 'sweetalert2'
import $ from 'jquery'

import '../Create.css'
import Map from '../Map'
import OtpBox from '../../../components/OtpBox'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const names = ['Seed', 'Fertilizers', 'Pesticides']

const clearInputs = {
    firstName: '',
    lastName: '',
    mobileNumber: '',
    email: '',
    alternatemobile: '',
    pancard: '',
    adharcard: '',
    gender: '',
    dob: '',
    language: '',
    business_mode: '',

    firmName: '',
    state: '',
    district: '',
    subDistrict: '',
    village: '',
    pincode: '',
    gstNumber: '',
    fullAddress: '',
    typeOfLicense: [],
    seedLicencePhoto: '',
    FertilizersLicencePhoto: '',
    Pesticides: '',
    seed: [],
    fertilizers: [],
    pesticides: [],

    unit: '',
    commissionAmount: '',
    category: '',

    bankAccountNumber: '',
    bankAccountName: '',
    ifscCode: '',
    bankName: '',
    bankBranchName: '',
}

function InputSuppliers() {
    const [tab, setTab] = useState(1)
    const [states, setStates] = useState([])
    const [licenseTypes, setLicenseTypes] = React.useState([])
    let licenseTypesArr = licenseTypes.map((type) => ({
        name: type,
        licenseNumber: '',
        expDate: '',
        photo: '',
    }))
    console.log(licenseTypesArr)
    const [Licence, setLicence] = useState([])
    const [licensePhoto, setLicensePhoto] = useState(null)
    const [inputSuppliers, setInputSuppliers] = useState([])

    const [citylist, setcitylist] = useState([])
    const [statelist, setstatelist] = useState([])
    const [CommisionCat, setCommisionCat] = useState([])
    const [mob, setMob] = useState(null)
    const [otpWindow, setOtpWindow] = useState(false)

    const [Formerinfo, setFormerinfo] = useState({})

    const [createdByid,setCreatedById]=useState('')
    const [createdByRole,setCreatedByRole]=useState("")
    const [licensePhotoImg, setlicensePhotoImg] = useState(null)
    const [licenseFertPhoto, setlicenseFertPhoto] = useState(null)
    const [licensePestiPhoto, setlicensePestiPhoto] = useState(null)
    const [seedType, setseedType] = useState({
        type: 'Seed',
        licence_number: '',
        licence_exp_date: '',
        image_url: licensePhotoImg,
    })
    const [fertilizersType, setfertilizersType] = useState({
        type: 'Fertilizers',
        licence_number: '',
        licence_exp_date: '',
        image_url: '',
    })
    const [pesticidesType, setpesticidesType] = useState({
        type: 'Pesticides',
        licence_number: '',
        licence_exp_date: '',
        image_url: '',
    })
    const [inputs, setInputs] = useState({
        firstName: '',
        lastName: '',
        mobileNumber: '',
        email: '',
        alternatemobile: '',
        pancard: '',
        adharcard: '',
        firmName: '',
        state: '',
        district: '',
        village: '',
        pincode: '',
        gstNumber: '',
        fullAddress: '',
        typeOfLicense: licenseTypesArr,
        seedLicencePhoto: '',
        FertilizersLicencePhoto: '',
        Pesticides: '',
        seed: seedType,
        fertilizers: fertilizersType,
        pesticides: pesticidesType,
        unit: '',
        gender: '',
        dob: '',
        language: '',
        business_mode: '',

        commissionAmount: '',
        category: '',

        bankAccountNumber: '',
        bankAccountName: '',
        ifscCode: '',
        bankName: '',
        bankBranchName: '',
    })

    useEffect(() => {
        getState()
        getStateList()
        getCommisionCat()
        // getInputSuppliers();
    }, [])
    const getCommisionCat = async () => {
        const { data } = await API.get(
            `/getCommissionCatelog?title=CommissionCatelog&catelogName=InputSupplierCatelog&type=2`
        )
        // console.log("hkjjhj",data)
        setCommisionCat(data?.result)
        console.log('firm search', data)
    }
    const CommisionUnit =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.commissionUnit,
            label: item.commissionUnit,
        }))
    const CommisionAmount =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.commissionAmount,
            label: item.commissionAmount,
        }))
    const commisiontype =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.subCategoryName,
            label: item.subCategoryName,
        }))
    const getStateList = async () => {
        const { data } = await API.get(`/getStateList`)
        // console.log("hkjjhj",data)
        setstatelist(data?.result)
        console.log('firm search', data)
    }
    const statelistoption =
        statelist &&
        statelist.map((item, ind) => ({
            value: item,
            label: item,
        }))
    const statechane = (e) => {
        console.log('hkjjhj', e.value)
        setInputs({ ...inputs, ['state']: e.value })
        // onChange={(e) => setInputs({ ...inputs, ['state']: e.target.value })}
        getCityList(e.value)
    }
    const getCityList = async (val) => {
        const { data } = await API.get(`/getCityList?stateName=` + val)
        // console.log("hkjjhj",data)
        setcitylist(data?.result)
        console.log('firm search', data)
    }
    const citylistoption =
        citylist &&
        citylist.map((item, ind) => ({
            value: item,
            label: item,
        }))
    const getInputSuppliers = async () => {
        const { data } = await API.get(`/getInputSupplier?type=0`)
        setInputSuppliers(data?.result)
    }

    const getState = async () => {
        const { data } = await axios.get(
            'http://65.0.145.198:4080/api/v1/getStateList'
        )
        setStates(data?.result)
        console.log(data?.result)
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event
        setLicenseTypes(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    const setLicenceInputs = (e, index) => {
        console.log(index)
        let myArr = [...licenseTypesArr]
        myArr[index][e.target.name] = e.target.value
        console.log(licenseTypesArr, 'myArr', myArr)
        setseedType({ ...seedType, licenseNumber: myArr })
    }

    const handleUploadcheck = (e, index) => {
        console.log(index, e.target.files[0])
    }
    const handleUploadDP = (e, index) => {
        console.log(index, e.target.files[0], e.target.name)
        // let myArr = [...Licence];
        // myArr[index] = e.target.files[0];
        // console.log(licenseTypesArr,"myArr",myArr);
        setLicensePhoto(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `licensePhoto/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                async () => {
                    getDownloadURL(storageRef).then((url) => {
                        let myArr = [...licenseTypesArr]
                        // myArr[index] = e.target.files[0];
                        console.log(licenseTypesArr, 'myArr', myArr)
                        myArr[index]['photo'] = url
                        // alert(index)
                        console.log(url, 'URL', index)
                    })
                }
            )
        }
    }

    // const onSubmitInputs = async (e) => {
    //     var token = localStorage.getItem('token')
    //     const config = {
    //         headers: { Authorization: `Bearer ${token}` },
    //     }
    //     const dataForOtp = {
    //         mobile_number: inputs.mobileNumber,
    //         role: 'inputSupplier',
    //         created_by: 'admin',
    //     }
    //     axios
    //         .post(
    //             `${process.env.REACT_APP_API_ENDPOINT}/auth-function/otp`,
    //             dataForOtp,
    //             config
    //         )
    //         .then((res) => {
    //             setOtpWindow(true)
    //         })
    // }
    const handleMobVal = (e) => {
        setInputs({ ...inputs, [e.target.name]: e.target.value })
        if (e.target.value.length == 10) {
            mobilecheck(e.target.value)
        } else if (e.target.value.length > 10) {
            console.log('e.target.value.length', e.target.value.length)
            // setFarmer({ ...farmer, mobileNumber: "" })
            setInputs({ ...inputs, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: 'Mobile length should be 10',
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const mobilecheck = async (number) => {
        const { data } = await API.get(`/checkNumber?number=` + number)
        console.log('data', data)
        if (data.success == true) {
            // setFarmer({ ...farmer, [e.target.name]: number })
        } else {
            setInputs({ ...inputs, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data.result,
                showConfirmButton: false,
                timer: 2000,
            })
        }
        // setDetails(data?.result);
    }
    const handleMobilesearch = (e) => {
        console.log('e', e.target.value)
        if (e.target.value.length == 10) {
            mobilechecksearch(e.target.value)
        } else {
            setInputSuppliers([])
        }
    }
    const mobilechecksearch = async (number) => {
        const { data } = await API.get(`/searchInputSupplier?search=` + number)
        console.log('data', data)
        if (data.success == true) {
            let arr = []
            arr.push(data?.result)
            setInputSuppliers(arr)
        } else {
        }
    }

    $(function () {
        $('#txtNumeric').keydown(function (e) {
            if (e.shiftKey || e.ctrlKey || e.altKey) {
                e.preventDefault()
            } else {
                var key = e.keyCode

                if (
                    !(
                        key == 8 ||
                        key == 32 ||
                        key == 46 ||
                        (key >= 35 && key <= 40) ||
                        (key >= 65 && key <= 90)
                    )
                ) {
                    e.preventDefault()
                }
            }
        })
    })

    const handleUploadLI = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setlicensePhotoImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPAN/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        console.log('url', url)
                        // fpo, setFpo

                        setseedType({ ...seedType, photo: url })
                    })
                }
            )
        }
    }
    const handleUploadFERTI = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setlicenseFertPhoto(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPAN/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        // fpo, setFpo
                        // setseedType({...seedType,photo:url})
                        setfertilizersType({ ...fertilizersType, photo: url })
                        console.log('url', fertilizersType)
                        // setInputs({ ...inputs, FertilizersLicencePhoto: url });
                    })
                }
            )
        }
    }
    const handleUploadPESTI = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setlicensePestiPhoto(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPAN/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        console.log('url', url)
                        // inputs, setInputs
                        // setInputs({ ...inputs, Pesticides: url });
                        setpesticidesType({ ...pesticidesType, photo: url })
                    })
                }
            )
        }
    }

    const mapdata = (data) => {
        console.log('ran')
        setInputs({
            ...inputs,
            state: data.state,
            district: data.district,
            village: data.village,
            pincode: data.pincode,
            fullAddress: data.address,
            lat: data.latitude,
            lng: data.longitude,
        })
    }

    const gender = [
        {
            value: 'Male',
            label: 'Male',
        },
        {
            value: 'Female',
            label: 'Female',
        },
        {
            value: 'Others',
            label: 'Others',
        },
    ]

    const LanguageVale = [
        {
            value: 'Wholesale',
            label: 'Wholesale',
        },
        {
            value: 'Retail',
            label: 'Retail',
        },
        {
            value: 'Distributor',
            label: 'Distributor',
        },
        {
            value: 'Manfacture',
            label: 'Manfacture',
        },
        {
            value: 'Organisation',
            label: 'Organisation',
        },
    ]

    function onSubmitInputs() {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var inputData = {
            user_id: '',
            first_name: inputs.firstName,
            last_name: inputs.lastName,
            gender: inputs.gender,
            role: 'agri-input-supplier',
            coordinates: [inputs.lat, inputs.lng],
            photo: 'test',
            language: inputs.language,
            business_mode: inputs.business_mode,
            state: inputs.state,
            district: inputs.district,
            sub_distric: inputs.district,
            village: inputs.village,
            pincode: +inputs.pincode,
            dob: inputs.dob,
            mobile_number: inputs.mobileNumber,
            full_address: inputs.fullAddress,
            farm_info: {
                firm_name: inputs.firmName || 'test',
                gst_no: inputs.gstNumber,
                type_of_license: ['seed', 'fertilizer', 'pesticides'],
                seed: {
                    image_url: seedType.photo || 'test',
                    licence_exp_date: seedType.licence_exp_date || 'test',
                    licence_number: seedType.licence_number || 'test',
                },
                fertilizer: {
                    image_url: fertilizersType.photo || 'test',
                    licence_exp_date:
                        fertilizersType.licence_exp_date || 'test',
                    licence_number: fertilizersType.licence_number || 'test',
                },
                pesticides: {
                    image_url: pesticidesType.photo || 'test',
                    licence_exp_date: pesticidesType.licence_exp_date || 'test',
                    licence_number: pesticidesType.licence_number || 'test',
                },
            },
            created_by: createdByRole,
            createdById:createdByid        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/auth-function/farmers`,
                inputData,
                config
            )
            .then((res) => {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: 'Input Supplier Created Successfully',
                    showConfirmButton: false,
                    timer: 2000,
                })
                setInputs(clearInputs)
                setLicenseTypes([])
                setTab(1)
            })
            .catch((err) =>
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: 'error occured!',
                    showConfirmButton: false,
                    timer: 2000,
                })
            )
    }
    const handleFarmer = (e) => {
        if (e.target.value.length == 10) {
            getFormerinfo(e.target.value)
        } else {
            setFormerinfo({})
        }
    }
    const getFormerinfo = async (Mob) => {
        const token = localStorage.getItem('token')
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        const farmerNumber = {
            mobile_no: Mob,
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/register-details`,
                farmerNumber,
                config
            )
            .then((res) => {
                const d = res.data.data.data
                localStorage.setItem('FormerId', d.user_id)
                setCreatedById(d.user_id)
                setCreatedByRole(d.role)
                localStorage.setItem('microId', '')
                setFormerinfo(d)
            })
            .catch((err) => {
                console.log(err)
                setFormerinfo({})
            })
    }
    return (
        <>
        <div class="card card-table p-10-25">
                            <div class="card-header">
                                <h5 class="card-title"> Info</h5>
                            </div>
           <div class="tab-content">
                                        <div action="#">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="farmerNum"
                                                        //  label="Farmer Number"
                                                        placeholder="Farmer Number"
                                                        variant="standard"
                                                        //  value={}
                                                        onChange={(e) =>
                                                            handleFarmer(e)
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.role
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.id
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.first_name
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerState"
                                                        //  label="State"
                                                        placeholder="State"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.state
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="farmerDistrict"
                                                        disabled
                                                        //  label="District"
                                                        placeholder="District"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.district
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        disabled
                                                        fullWidth
                                                        id="farmerVillage"
                                                        //  label="Village"
                                                        placeholder="Village"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.village
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        disabled
                                                        fullWidth
                                                        id="farmerPincode"
                                                        //  label="Pincode"
                                                        placeholder="Pincode"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.pincode
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>
                                          
                                        </div>
                                        </div>

                                    </div>
            {otpWindow ? (
                <OtpBox
                    mobileNumber={inputs.mobileNumber}
                    // syncOtpSubmit={syncOtpSubmit}
                />
            ) : (
                <div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card card-table p-10-25">
                                <div class="card-header">
                                    <h5 class="card-title">
                                        Create Input Supplier
                                    </h5>
                                </div>
                                {console.log('seedType', inputs)}
                                <div class="card-body">
                                    <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                                        <li class="nav-item">
                                            <a
                                                class={
                                                    tab === 1
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                Personal Info
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                class={
                                                    tab === 2
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                Firm Details
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                class={
                                                    tab === 3
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                Contact Details
                                            </a>
                                        </li>
                                        {/* <li class="nav-item"><a class={tab === 4 ? "nav-link active" : "nav-link"}>Bank Account Info</a></li> */}
                                    </ul>
                                    <div class="tab-content">
                                        {/* Personal Info */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab1"
                                        >
                                            {
                                                tab === 1 && (
                                                    // <form onSubmit={(e) =>{e.preventDefault(); setTab(2);} }>
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="fName"
                                                                label="First Name"
                                                                variant="standard"
                                                                name="firstName"
                                                                value={
                                                                    inputs.firstName
                                                                }
                                                                onChange={(e) =>
                                                                    setInputs({
                                                                        ...inputs,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="lName"
                                                                label="Last Name"
                                                                variant="standard"
                                                                name="lastName"
                                                                value={
                                                                    inputs.lastName
                                                                }
                                                                onChange={(e) =>
                                                                    setInputs({
                                                                        ...inputs,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="mobileNumber"
                                                                type="number"
                                                                label="Mobile Number"
                                                                variant="standard"
                                                                name="mobileNumber"
                                                                value={
                                                                    inputs.mobileNumber
                                                                }
                                                                onChange={(e) =>
                                                                    handleMobVal(
                                                                        e
                                                                    )
                                                                }
                                                                // onChange={(e) => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <Select
                                                                    style={{
                                                                        width: '270px',
                                                                        height: '40px',
                                                                    }}
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setInputs(
                                                                            {
                                                                                ...inputs,
                                                                                ['gender']:
                                                                                    e.value,
                                                                            }
                                                                        )
                                                                    }
                                                                    options={
                                                                        gender
                                                                    }
                                                                    placeholder={
                                                                        'Select Gender'
                                                                    }
                                                                />
                                                            </FormControl>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <InputLabel
                                                                id="demo-simple-select-standard-label"
                                                                style={{
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                DOB
                                                            </InputLabel>
                                                            <CssTextField
                                                                type="date"
                                                                fullWidth
                                                                variant="standard"
                                                                style={{
                                                                    marginTop:
                                                                        '10px',
                                                                    marginBottom:
                                                                        '10px',
                                                                }}
                                                                name="dob"
                                                                value={
                                                                    inputs.dob
                                                                }
                                                                onChange={(e) =>
                                                                    setInputs({
                                                                        ...inputs,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <Select
                                                                    style={{
                                                                        width: '270px',
                                                                        height: '40px',
                                                                    }}
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setInputs(
                                                                            {
                                                                                ...inputs,
                                                                                ['business_mode']:
                                                                                    e.value,
                                                                            }
                                                                        )
                                                                    }
                                                                    options={
                                                                        LanguageVale
                                                                    }
                                                                    placeholder={
                                                                        'Business Mode'
                                                                    }
                                                                />
                                                            </FormControl>
                                                        </div>
                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                            <button
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault()
                                                                    setTab(2)
                                                                }}
                                                                class=" w-16 btn btn-primary"
                                                            >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                                // {/* </form> */}
                                            }
                                        </div>

                                        {/* Firm Details */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab2"
                                        >
                                            {tab === 2 && (
                                                // <form onSubmit={(e) => { setTab(4); setInputs({ ...inputs, typeOfLicense: licenseTypesArr }) }}>

                                                <div>
                                                    <div class="row">
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'space-around',
                                                            }}
                                                        >
                                                            <div class="col-md-3">
                                                                <CssTextField
                                                                    fullWidth
                                                                    required
                                                                    id=""
                                                                    label="Firm Name"
                                                                    variant="standard"
                                                                    name="firmName"
                                                                    value={
                                                                        inputs.firmName
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setInputs(
                                                                            {
                                                                                ...inputs,
                                                                                [e
                                                                                    .target
                                                                                    .name]:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            }
                                                                        )
                                                                    }
                                                                />
                                                            </div>

                                                            <div class="col-md-3">
                                                                <CssTextField
                                                                    fullWidth
                                                                    id="standard-basic"
                                                                    label="GST"
                                                                    variant="standard"
                                                                    name="gstNumber"
                                                                    value={
                                                                        inputs.gstNumber
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setInputs(
                                                                            {
                                                                                ...inputs,
                                                                                [e
                                                                                    .target
                                                                                    .name]:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            }
                                                                        )
                                                                    }
                                                                />
                                                            </div>

                                                            <div className="col-md-4">
                                                                <FormControl
                                                                    variant="standard"
                                                                    fullWidth
                                                                    className="mt-3"
                                                                >
                                                                    <InputLabel id="demo-multiple-checkbox-label">
                                                                        Type Of
                                                                        License
                                                                    </InputLabel>
                                                                    <Selects
                                                                        required
                                                                        labelId="demo-multiple-checkbox-label"
                                                                        id="demo-multiple-checkbox"
                                                                        multiple
                                                                        value={
                                                                            licenseTypes
                                                                        }
                                                                        onChange={
                                                                            handleChange
                                                                        }
                                                                        input={
                                                                            <Input
                                                                                label="Type Of Crop"
                                                                                variant="standard"
                                                                            />
                                                                        }
                                                                        renderValue={(
                                                                            selected
                                                                        ) =>
                                                                            selected.join(
                                                                                ', '
                                                                            )
                                                                        }
                                                                        MenuProps={
                                                                            MenuProps
                                                                        }
                                                                    >
                                                                        {names.map(
                                                                            (
                                                                                name
                                                                            ) => (
                                                                                <MenuItem
                                                                                    key={
                                                                                        name
                                                                                    }
                                                                                    value={
                                                                                        name
                                                                                    }
                                                                                >
                                                                                    <Checkbox
                                                                                        checked={
                                                                                            licenseTypes.indexOf(
                                                                                                name
                                                                                            ) >
                                                                                            -1
                                                                                        }
                                                                                    />
                                                                                    <ListItemText
                                                                                        primary={
                                                                                            name
                                                                                        }
                                                                                    />
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Selects>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {licenseTypes.map(
                                                        (crop, index) => {
                                                            return (
                                                                <div className="row mt-3 mb-3">
                                                                    {
                                                                        // console.log("crop",crop)
                                                                        crop ==
                                                                        'Seed' ? (
                                                                            <>
                                                                                <div class="col-md-3 mt-4">
                                                                                    <CssTextField
                                                                                        fullWidth
                                                                                        id="standard-basic"
                                                                                        label="Type Of License"
                                                                                        variant="standard"
                                                                                        value={
                                                                                            crop
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setseedType(
                                                                                                {
                                                                                                    ...seedType,
                                                                                                    type: 'seed',
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div class="col-md-3 mt-4">
                                                                                    <CssTextField
                                                                                        fullWidth
                                                                                        id="standard-basic"
                                                                                        type="text"
                                                                                        label="License Number"
                                                                                        variant="standard"
                                                                                        name="licence_number"
                                                                                        // onChange={(e) => setLicenceInputs(e, index)}
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setseedType(
                                                                                                {
                                                                                                    ...seedType,
                                                                                                    licence_number:
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                        //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licence_number']}
                                                                                        // onChange={(e) => setLicenceInputs(e, index)}
                                                                                    />
                                                                                </div>
                                                                                <div class="col-md-3 mt-4">
                                                                                    <InputLabel
                                                                                        id="demo-simple-select-standard-label"
                                                                                        style={{
                                                                                            color: 'black',
                                                                                        }}
                                                                                    >
                                                                                        License
                                                                                        Exp
                                                                                        Date
                                                                                    </InputLabel>
                                                                                    <CssTextField
                                                                                        type="date"
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        style={{
                                                                                            marginTop:
                                                                                                '10px',
                                                                                            marginBottom:
                                                                                                '10px',
                                                                                        }}
                                                                                        name="licence_exp_date"
                                                                                        //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licence_number']}
                                                                                        // onChange={(e) => setLicenceInputs(e, index)}
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setseedType(
                                                                                                {
                                                                                                    ...seedType,
                                                                                                    licence_exp_date:
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>

                                                                                <div
                                                                                    class="col-md-3 d-flex"
                                                                                    style={{
                                                                                        justifyContent:
                                                                                            'space-between',
                                                                                    }}
                                                                                >
                                                                                    <label htmlFor="contained-button-file">
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            component="span"
                                                                                            color="success"
                                                                                        >
                                                                                            <Input
                                                                                                accept="image/"
                                                                                                id="contained-button-file"
                                                                                                multiple="false"
                                                                                                name="photo"
                                                                                                type="file"
                                                                                                // onChange={(e) => handleUploadDP(e, index)}
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    handleUploadLI(
                                                                                                        e
                                                                                                    )
                                                                                                }
                                                                                                style={{
                                                                                                    display:
                                                                                                        'none',
                                                                                                    marginBottom:
                                                                                                        '16px',
                                                                                                    marginTop:
                                                                                                        '16px',
                                                                                                }}
                                                                                            />
                                                                                            Upload
                                                                                        </Button>
                                                                                    </label>
                                                                                    <aside
                                                                                        style={
                                                                                            thumbsContainerState
                                                                                        }
                                                                                    >
                                                                                        <div
                                                                                            style={
                                                                                                thumb
                                                                                            }
                                                                                        >
                                                                                            <div
                                                                                                style={
                                                                                                    thumbInner
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        licensePhotoImg
                                                                                                    }
                                                                                                    style={
                                                                                                        img
                                                                                                    }
                                                                                                    alt=""
                                                                                                />
                                                                                                {/* <img src="" style={img} alt="" /> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </aside>
                                                                                </div>
                                                                            </>
                                                                        ) : crop ==
                                                                          'Fertilizers' ? (
                                                                            <>
                                                                                <div class="col-md-3 mt-4">
                                                                                    <CssTextField
                                                                                        fullWidth
                                                                                        id="standard-basic"
                                                                                        label="Type Of License"
                                                                                        variant="standard"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setfertilizersType(
                                                                                                {
                                                                                                    ...fertilizersType,
                                                                                                    type: e
                                                                                                        .target
                                                                                                        .value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                        value={
                                                                                            crop
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div class="col-md-3 mt-4">
                                                                                    <CssTextField
                                                                                        fullWidth
                                                                                        id="standard-basic"
                                                                                        type="text"
                                                                                        label="License Number"
                                                                                        variant="standard"
                                                                                        name="licence_number"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setfertilizersType(
                                                                                                {
                                                                                                    ...fertilizersType,
                                                                                                    licence_number:
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                        // setpesticidesType({...pesticidesType,photo:url})
                                                                                        //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licence_number']}
                                                                                        // onChange={(e) => setLicenceInputs(e, index)}
                                                                                    />
                                                                                </div>
                                                                                <div class="col-md-3 mt-4">
                                                                                    <InputLabel
                                                                                        id="demo-simple-select-standard-label"
                                                                                        style={{
                                                                                            color: 'black',
                                                                                        }}
                                                                                    >
                                                                                        License
                                                                                        Exp
                                                                                        Date
                                                                                    </InputLabel>
                                                                                    <CssTextField
                                                                                        type="date"
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        style={{
                                                                                            marginTop:
                                                                                                '10px',
                                                                                            marginBottom:
                                                                                                '10px',
                                                                                        }}
                                                                                        name="licence_exp_date"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setfertilizersType(
                                                                                                {
                                                                                                    ...fertilizersType,
                                                                                                    licence_exp_date:
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                        //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licenseNumber']}
                                                                                        // onChange={(e) => setLicenceInputs(e, index)}
                                                                                    />
                                                                                </div>

                                                                                <div
                                                                                    class="col-md-3 d-flex"
                                                                                    style={{
                                                                                        justifyContent:
                                                                                            'space-between',
                                                                                    }}
                                                                                >
                                                                                    <label htmlFor="contained-button-files">
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            component="span"
                                                                                            color="success"
                                                                                        >
                                                                                            <Input
                                                                                                accept="image/"
                                                                                                id="contained-button-files"
                                                                                                multiple="false"
                                                                                                name="photo"
                                                                                                type="file"
                                                                                                // onChange={(e) => handleUploadDP(e, index)}
                                                                                                // onChange={(e)=>handleUploadcheck(e)}
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    handleUploadFERTI(
                                                                                                        e
                                                                                                    )
                                                                                                }
                                                                                                style={{
                                                                                                    display:
                                                                                                        'none',
                                                                                                    marginBottom:
                                                                                                        '16px',
                                                                                                    marginTop:
                                                                                                        '16px',
                                                                                                }}
                                                                                            />
                                                                                            Upload
                                                                                        </Button>
                                                                                    </label>
                                                                                    <aside
                                                                                        style={
                                                                                            thumbsContainerState
                                                                                        }
                                                                                    >
                                                                                        <div
                                                                                            style={
                                                                                                thumb
                                                                                            }
                                                                                        >
                                                                                            <div
                                                                                                style={
                                                                                                    thumbInner
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        licenseFertPhoto
                                                                                                    }
                                                                                                    style={
                                                                                                        img
                                                                                                    }
                                                                                                    alt=""
                                                                                                />
                                                                                                {/* <img src="" style={img} alt="" /> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </aside>
                                                                                </div>
                                                                            </>
                                                                        ) : crop ==
                                                                          'Pesticides' ? (
                                                                            <>
                                                                                <div class="col-md-3 mt-4">
                                                                                    <CssTextField
                                                                                        fullWidth
                                                                                        id="standard-basic"
                                                                                        label="Type Of License"
                                                                                        variant="standard"
                                                                                        value={
                                                                                            crop
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setpesticidesType(
                                                                                                {
                                                                                                    ...pesticidesType,
                                                                                                    type: e
                                                                                                        .target
                                                                                                        .value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div class="col-md-3 mt-4">
                                                                                    <CssTextField
                                                                                        fullWidth
                                                                                        id="standard-basic"
                                                                                        type="text"
                                                                                        label="License Number"
                                                                                        variant="standard"
                                                                                        name="licence_number"
                                                                                        // onChange={(e) => setLicenceInputs(e, index)}
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setpesticidesType(
                                                                                                {
                                                                                                    ...pesticidesType,
                                                                                                    licence_number:
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                        //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licenseNumber']}
                                                                                        // onChange={(e) => setLicenceInputs(e, index)}
                                                                                    />
                                                                                </div>
                                                                                <div class="col-md-3 mt-4">
                                                                                    <InputLabel
                                                                                        id="demo-simple-select-standard-label"
                                                                                        style={{
                                                                                            color: 'black',
                                                                                        }}
                                                                                    >
                                                                                        License
                                                                                        Exp
                                                                                        Date
                                                                                    </InputLabel>
                                                                                    <CssTextField
                                                                                        type="date"
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        style={{
                                                                                            marginTop:
                                                                                                '10px',
                                                                                            marginBottom:
                                                                                                '10px',
                                                                                        }}
                                                                                        name="licence_exp_date"
                                                                                        //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licenseNumber']}
                                                                                        // onChange={(e) => setLicenceInputs(e, index)}
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setpesticidesType(
                                                                                                {
                                                                                                    ...pesticidesType,
                                                                                                    licence_exp_date:
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>

                                                                                <div
                                                                                    class="col-md-3 d-flex"
                                                                                    style={{
                                                                                        justifyContent:
                                                                                            'space-between',
                                                                                    }}
                                                                                >
                                                                                    <label htmlFor="contained-button-filed">
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            component="span"
                                                                                            color="success"
                                                                                        >
                                                                                            <Input
                                                                                                accept="image/"
                                                                                                id="contained-button-filed"
                                                                                                multiple="false"
                                                                                                name="photo"
                                                                                                type="file"
                                                                                                // onChange={(e) => handleUploadDP(e, index)}
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    handleUploadPESTI(
                                                                                                        e
                                                                                                    )
                                                                                                }
                                                                                                style={{
                                                                                                    display:
                                                                                                        'none',
                                                                                                    marginBottom:
                                                                                                        '16px',
                                                                                                    marginTop:
                                                                                                        '16px',
                                                                                                }}
                                                                                            />
                                                                                            Upload
                                                                                        </Button>
                                                                                    </label>
                                                                                    <aside
                                                                                        style={
                                                                                            thumbsContainerState
                                                                                        }
                                                                                    >
                                                                                        <div
                                                                                            style={
                                                                                                thumb
                                                                                            }
                                                                                        >
                                                                                            <div
                                                                                                style={
                                                                                                    thumbInner
                                                                                                }
                                                                                            >
                                                                                                {/* <img src={licenseFertPhoto} style={img} alt="" /> */}
                                                                                                <img
                                                                                                    src={
                                                                                                        licensePestiPhoto
                                                                                                    }
                                                                                                    style={
                                                                                                        img
                                                                                                    }
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </aside>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            ''
                                                                        )
                                                                    }
                                                                    {/* <div class="col-md-3 mt-4">
                              <CssTextField
                                fullWidth id="standard-basic"
                                label="Type Of License"
                                variant="standard"
                                value={crop}
                              />
                            </div>
                            <div class="col-md-3 mt-4">
                              <CssTextField
                                fullWidth id="standard-basic"
                                type="number"
                                label="License Number"
                                variant="standard"
                                name="licenseNumber"
                                //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licenseNumber']}
                                onChange={(e) => setLicenceInputs(e, index)}
                              />
                            </div>
                            <div class="col-md-3 mt-4">
                              <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
                                License Exp Date
                              </InputLabel>
                              <CssTextField
                                type="date"
                                fullWidth
                                variant="standard"
                                style={{ marginTop: '10px', marginBottom: '10px' }}
                                name="expDate"
                                //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licenseNumber']}
                                onChange={(e) => setLicenceInputs(e, index)}
                              />
                            </div>
                            {console.log("licenseTypesArr",licenseTypesArr[index],index)}
                            <div class="col-md-3 d-flex" style={{ justifyContent: 'space-between' }}>
                              <label htmlFor="contained-button-file">
                                <Button variant="contained" component="span" color="success">
                                  <Input
                                    accept="image/"
                                    id="contained-button-file"
                                    multiple="false"
                                    name="photo"
                                    type="file"
                                    onChange={(e) => handleUploadDP(e, index)}
                                    // onChange={(e)=>handleUploadcheck(e, index)}
                                    style={{
                                      display: 'none',
                                      marginBottom: '16px',
                                      marginTop: '16px'
                                    }}
                                  />
                                  Upload
                                </Button>
                              </label>
                              <aside style={thumbsContainerState}>
                                <div style={thumb}>
                                  <div style={thumbInner}>
                                    <img src={licensePhoto} style={img} alt="" />
                                    
                                  </div>
                                </div>
                              </aside>
                            </div> */}
                                                                </div>
                                                            )
                                                        }
                                                    )}

                                                    <div class="col-md-12 text-end mt-2 mb-2">
                                                        <button
                                                            className="w-16 btn btn-secondary"
                                                            style={{
                                                                marginRight:
                                                                    '10px',
                                                            }}
                                                            onClick={(e) =>
                                                                setTab(1)
                                                            }
                                                        >
                                                            Previous
                                                        </button>
                                                        <button
                                                            onClick={(e) =>
                                                                setTab(3)
                                                            }
                                                            className="w-16 btn btn-primary"
                                                        >
                                                            Next
                                                        </button>
                                                    </div>

                                                    {/* </form> */}
                                                </div>
                                            )}
                                        </div>

                                        {/* Commission Details */}

                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab3"
                                        >
                                            {tab === 3 && (
                                                <div class="row">
                                                    <div
                                                        class=""
                                                        style={{
                                                            marginTop: '2%',
                                                        }}
                                                    >
                                                        <Map
                                                            center={{
                                                                lat: 18.5204,
                                                                lng: 73.8567,
                                                            }}
                                                            height="300px"
                                                            zoom={15}
                                                            mapdata={mapdata}
                                                        />
                                                    </div>
                                                    <div class="col-md-12 text-end justify-space-between">
                                                        <button
                                                            className="w-16 btn btn-secondary"
                                                            style={{
                                                                marginRight:
                                                                    '10px',
                                                            }}
                                                            onClick={(e) =>
                                                                setTab(1)
                                                            }
                                                        >
                                                            Previous
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={(e) =>
                                                                onSubmitInputs()
                                                            }
                                                            class=" w-16 btn btn-primary"
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {/* Bank Account Info */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab4"
                                        >
                                            {tab === 4 && (
                                                <form
                                                    action="#"
                                                    onSubmit={onSubmitInputs}
                                                >
                                                    <div class="row">
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="bankAccountNumber"
                                                                label="Bank Acc"
                                                                variant="standard"
                                                                name="bankAccountNumber"
                                                                type="number"
                                                                value={
                                                                    inputs.bankAccountNumber
                                                                }
                                                                onChange={(e) =>
                                                                    setInputs({
                                                                        ...inputs,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>

                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id=""
                                                                label="Account Name"
                                                                variant="standard"
                                                                name="bankAccountName"
                                                                value={
                                                                    inputs.bankAccountName
                                                                }
                                                                onChange={(e) =>
                                                                    setInputs({
                                                                        ...inputs,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id=""
                                                                label="IFSC Code"
                                                                variant="standard"
                                                                name="ifscCode"
                                                                value={
                                                                    inputs.ifscCode
                                                                }
                                                                onChange={(e) =>
                                                                    setInputs({
                                                                        ...inputs,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                label="Bank Name"
                                                                variant="standard"
                                                                pattern="[a-zA-Z]*"
                                                                type="text"
                                                                id="txtNumeric"
                                                                name="bankName"
                                                                value={
                                                                    inputs.bankName
                                                                }
                                                                onChange={(e) =>
                                                                    setInputs({
                                                                        ...inputs,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id=""
                                                                label="Branch Name"
                                                                variant="standard"
                                                                name="bankBranchName"
                                                                value={
                                                                    inputs.bankBranchName
                                                                }
                                                                onChange={(e) =>
                                                                    setInputs({
                                                                        ...inputs,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                            <button
                                                                className="w-16 btn btn-secondary"
                                                                style={{
                                                                    marginRight:
                                                                        '10px',
                                                                }}
                                                                onClick={(e) =>
                                                                    setTab(2)
                                                                }
                                                            >
                                                                Previous
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={(e) =>
                                                                    onSubmitInputs()
                                                                }
                                                                class=" w-16 btn btn-primary"
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <h3 className="mb-3">All Input Suppliers</h3>
                            <div class="card card-table">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <div
                                            id="DataTables_Table_0_wrapper"
                                            class="dataTables_wrapper dt-bootstrap4 no-footer"
                                        >
                                            <div class="row">
                                                <div class="col-sm-12 col-md-6">
                                                    <h3 className="mt-3">
                                                        Input Suppliers
                                                    </h3>
                                                    <div
                                                        class="dataTables_length"
                                                        id="DataTables_Table_0_length"
                                                    >
                                                        <hr />
                                                        <label>
                                                            Show
                                                            <select
                                                                name="DataTables_Table_0_length"
                                                                aria-controls="DataTables_Table_0"
                                                                class="custom-select custom-select-sm form-control form-control-sm"
                                                            >
                                                                <option value="10">
                                                                    10
                                                                </option>
                                                                <option value="25">
                                                                    25
                                                                </option>
                                                                <option value="50">
                                                                    50
                                                                </option>
                                                                <option value="100">
                                                                    100
                                                                </option>
                                                            </select>
                                                            entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-3 position_right">
                                                    <div class="form-group">
                                                        {/* <label>Search</label> */}
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="Search MobileNo"
                                                            maxLength={'10'}
                                                            onChange={(e) =>
                                                                handleMobilesearch(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <InputTable
                                                    inputs={inputSuppliers}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
export default InputSuppliers
