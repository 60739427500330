import React, { useState } from 'react';
import {
  Card,
  CardContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import axios from 'axios';

const initialFormData = {
  productName: '',
  price: '',
  priceType: 'inc_gst',
  gstRate: '',
  nonTaxable: '',
  netPriceGST: '',
  hsnCode: '',
  codeType:"",
  cessAmount: '',
  sku: '',
  productDescription: '',
};

const gstRates = [0, 5, 12, 18, 28];
const codes = ["HSN Code","CESS Code"];

function ProductForm(props) {
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
console.log(props)
  const handleSubmit = (event) => {
    event.preventDefault();
    const token = localStorage.getItem('token')

    const config = {
      headers: { Authorization: `Bearer ${token}` },
  }
    // You can now access formData and submit it to your backend or perform any desired actions.
    axios
    .post(
        'https://prod-api.krishione.com/farmer-function/set-gst',
        {_id:props.product._id,gstRate:formData.gstRate,priceType:formData.priceType,},config
    )
    .then((res) => {
        alert('Success!')
        console.log(res.data)
    })
  };

  return (
    <Card>
     {props.product?.gst?.gstRate?  <h2>Current GST Rate: { props.product.gst.gstRate}</h2>:null}
      <CardContent>
        <form className="form-grid" onSubmit={handleSubmit}>
          <TextField
            label="Product Name"
            name="productName"
            value={props.product.productName}
            onChange={handleChange}
            fullWidth
            required
          />

          <FormControl fullWidth>
            <InputLabel>Price Type</InputLabel>
            <Select
              name="priceType"
              value={formData.priceType}
              onChange={handleChange}
            >
              <MenuItem value="inc_gst">Inclusive of GST</MenuItem>
              <MenuItem value="exc_gst">Exclusive of GST</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>GST Rate</InputLabel>
            <Select
              name="gstRate"
              value={formData.gstRate}
              onChange={handleChange}
            >
              {gstRates.map((rate) => (
                <MenuItem key={rate} value={rate}>
                  {rate}%
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <TextField
            label="Non Taxable"
            name="nonTaxable"
            type="number"
            value={formData.nonTaxable}
            onChange={handleChange}
            fullWidth
          />

          <TextField
            label="NET Price GST"
            name="netPriceGST"
            value={formData.netPriceGST}
            onChange={handleChange}
            fullWidth
          /> */}
 {/* <FormControl fullWidth>
            <InputLabel>Code Type</InputLabel>
            <Select
              name="codeType"
              value={formData.codeType}
              onChange={handleChange}
            >
              {codes.map((rate) => (
                <MenuItem key={rate} value={rate}>
                  {rate}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="HSN Code"
            name="hsnCode"
            value={formData.hsnCode}
            onChange={handleChange}
            fullWidth
          />

          {/* <TextField
            label="Cess Amount"
            name="cessAmount"
            value={formData.cessAmount}
            onChange={handleChange}
            fullWidth
          /> */}

          {/* <TextField
            label="SKU"
            name="sku"
            value={formData.sku}
            onChange={handleChange}
            fullWidth
          />

          <TextField
            label="Product Description"
            name="productDescription"
            value={formData.productDescription}
            onChange={handleChange}
            fullWidth
            multiline
          /> */} 

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >
            Save
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}

export default ProductForm;
