import React, { useState, useEffect } from 'react'
import { CssTextField } from '../Styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { useDropzone } from 'react-dropzone'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
// import Input from '@mui/material/Input';
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import { API } from '../../API/index'
import Map2 from '../Create/Map2'

import { storage } from '../../firebase/index'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import {
    thumbsContainerState,
    thumb,
    img,
    thumbInner,
    mapThumb,
    baseStyle,
} from '../Styles'
import Swal from 'sweetalert2'
import VehicleDetails from './Details/VehicleDetails'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { driverDP, sectionDiv, uploadBtnSm, panImg } from '../Styles'
import axios from 'axios'
import ModuleOrders from '../../components/ModuleOrders'
import BankingDetails from '../../components/BankingDetails'
import Wallet from '../../components/Wallet'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}
const Input = styled('input')({
    display: 'none',
    marginBottom: '16px',
    marginTop: '16px',
})
const names = ['Wheat', 'Rice', 'Barley', 'Cereal', 'Tomato', 'Oat']
const agriclear = {
    id: '',
    driverFirstName: '',
    driverLastName: '',
    driverMobileNumber: '',
    driverEmail: '',
    driverPhoto: '',
    driverLicenceNumber: '',
    drivingLicencePhoto: '',
    driverPancardPhoto: '',
    driverPancardNumber: '',
    driverAdhaarPhoto: '',
    driverAdhaarNumber: '',
}

function Settings() {
    const [addFarm, setAddFarm] = useState(false)
    const [addDoc, setAddDoc] = useState(false)

    //  const [driverImg, setDriverImg] = useState(null);
    const [driverLicenseImg, setDriverLicenseImg] = useState(null)
    const [AAdharImg, setAAdharImg] = useState(null)
    const [PANImg, setPANImg] = useState(null)
    const [driverImg, setDriverImg] = useState(null)
    const [addWallet, setAddWallet] = useState(false)
    const [cropTypes, setCropTypes] = React.useState([])
    const [Files, setFiles] = useState([])

    const [Detailsvalue, setDetailsvalue] = useState([])
    const [Details, setDetails] = useState({})
    const [FirmDetails, setFirmDetails] = useState([])
    const [createdByData, setCreatedByData] = useState({})
    const [subDetails, setSubDetails] = useState({})
    const [userId, setUserId] = useState('')
    const [idForAssign, setIdForAssign] = useState('')
    const [bankDetails, setBankDetails] = useState({})

    const [agriMachine, setAgriMachine] = useState({
        id: localStorage.getItem('agriTransportfarmerid'),
        driverFirstName: '',
        driverLastName: '',
        driverMobileNumber: '',
        driverEmail: '',
        driverPhoto: '',
        driverLicenceNumber: '',
        drivingLicencePhoto: '',
        driverPancardPhoto: '',
        driverPancardNumber: '',
        driverAdhaarPhoto: '',
        driverAdhaarNumber: '',
    })

    const handleUploadDP = (e) => {
        setDriverImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        setAgriMachine({ ...agriMachine, driverPhoto: url })
                    })
                }
            )
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        var farmerid = localStorage.getItem('agriTransportfarmerid')
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }

        var payload = {
            mobile_no: farmerid,
            role: 'agri-transport',
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-register`,
            payload,
            config
        )

        console.log('data', data)
        if (data.data) {
            setDetailsvalue([data.data.data.vehicle_info])

            var a = {
                _id: data.data.data._id,
                user_id: data.data.data.user_id,
                firstName: data.data.data.first_name,
                lastName: data.data.data.last_name,
                mobileNumber: data.data.data.mobile_number,
                gender: data.data.data.gender,
                category: data.data.data.role,
                dateOfBirth: data.data.data.dob,
                education: '',
                language: data.data.data.language,
                businessMode: data.data.data.business_mode,
                state: data.data.data.state,
                district: data.data.data.district,
                subDistrict: data.data.data.sub_distric,
                village: data.data.data.village,
                pincode: data.data.data.pincode,
                adhaarNumber: '',
                fullAddress: data.data.data.full_address,
                bankAccountNumber: '',
                bankAccountName: '',
                ifscCode: '',
                lat: data.data.data.coordinates.coordinates[1],
                long: data.data.data.coordinates.coordinates[0],
                bankName: '',
                bankBranchName: '',
                createdAt: data.data.data.created_at,
                irrigation_source: 'Canal',
                mobile_number: '6353454356',
                soil_test: true,
                soil_type: 'Alluvial soil',
                sowing_date: '2022-04-08',
            }
            var farmInfo = [data.data.farm_info]
            setDetails(a)
            setBankDetails(data.data.data.bank_info)
            setUserId(data.data.data.user_id)
            setIdForAssign(data.data.data.id)

            payload = {
                userId: data.data.data.createdById,
            }
            axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-user`,
                    payload,
                    config
                )
                .then((res) => {
                    const { role, id, mobile_number } = res.data.user[0]
                    setCreatedByData({
                        role,
                        id,
                        mobile_number,
                    })
                })
        }
    }, [])
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
        },
    })

    const PGthumbs = Files.map((file, index) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt="" />
            </div>
        </div>
    ))
    const FirmDetailsvalue = async () => {
        // console.log(localStorage.getItem('farmerid'), "hgfjgjfjh")
        const { data } = await API.get(
            `/getDriver?type=0&id=` +
                localStorage.getItem('agriTransportfarmerid')
        )
        console.log('data', data)
        setFirmDetails(data?.result)
    }
    const handlebehivlesubmit = async (e) => {
        e.preventDefault()
        console.log('Agri Machine : ', agriMachine)
        const { data } = await API.post('/addDriver', agriMachine)
        if (data?.success) {
            Swal.fire({
                icon: 'success',
                iconColor: '#28a745',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
            setDriverLicenseImg(null)
            setAAdharImg(null)
            setPANImg(null)
            setDriverImg(null)
            setAddFarm(false)
            setAgriMachine(agriclear)
            FirmDetailsvalue()
            console.log(data)
        } else {
            setAgriMachine(agriclear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handleUploadDLP = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setDriverLicenseImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverLicence/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        console.log('url', url)
                        setAgriMachine({
                            ...agriMachine,
                            drivingLicencePhoto: url,
                        })
                    })
                }
            )
        }
    }

    const handleUploadPAN = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setPANImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPAN/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        console.log('url', url)
                        setAgriMachine({
                            ...agriMachine,
                            driverPancardPhoto: url,
                        })
                    })
                }
            )
        }
    }
    const handleUploadAADHAR = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setAAdharImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverAADhar/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        console.log('url', url)
                        setAgriMachine({
                            ...agriMachine,
                            driverAdhaarPhoto: url,
                        })
                    })
                }
            )
        }
    }

    return (
        <div>
            <div class="row">
                <div class="page-header">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <ul class="breadcrumb justify-content-end">
                                <li class="breadcrumb-item">
                                    <a href="#">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="#">AgriTransport</a>
                                </li>
                                <li class="breadcrumb-item active">
                                    {Details.firstName}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="card-title d-flex justify-content-between store_edit">
                                <span>Agri Transport</span>
                                <a
                                    href="#"
                                    class="btn btn-tbl-edit btn-xs td_btn"
                                >
                                    <span class="material-icons-outlined">
                                        edit
                                    </span>
                                </a>
                            </h5>
                            <a class="store-avatar">
                                <div class="staffAvatar-root1">
                                    {Details &&
                                    Details.firstName &&
                                    Details.firstName.length > 0
                                        ? Details.firstName.slice(0, 1)
                                        : ''}
                                </div>
                                <div class="store_display">
                                    <h4>{Details.firstName}</h4>
                                    <span>{Details.mobileNumber}</span>
                                </div>
                            </a>
                        </div>

                        <div class="card-body">
                            <h5 class="card-title d-flex justify-content-between store_edit">
                                <span>Personal Details</span>
                                <a
                                    href="#"
                                    class="btn btn-tbl-edit btn-xs td_btn"
                                >
                                    <span class="material-icons-outlined">
                                        edit
                                    </span>
                                </a>
                            </h5>
                            <ul class="list-unstyled mb-0 store_list">
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">Gender : </small>
                                    <span>{Details.gender}</span>
                                </li>

                                <li class="pt-2 pb-0">
                                    <small class="text-dark">DOB : </small>
                                    <span>{Details.dateOfBirth}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">
                                        Business Mode :{' '}
                                    </small>
                                    <span>{Details.businessMode}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <BankingDetails
                        Details={Details}
                        bankDetails={bankDetails}
                    />

                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title d-flex justify-content-between store_edit">
                                <span>Created By :</span>
                            </h5>
                            <ul class="list-unstyled mb-0 store_list">
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">Role : </small>
                                    <span>{createdByData.role}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">ID : </small>
                                    <span>{createdByData.id}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">
                                        Phone Number :{' '}
                                    </small>
                                    <span>{createdByData.mobile_number}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-xl-9 col-md-8">
                    <div class="card">
                        <div
                            class="card-body p_7"
                            style={{ padding: '10px 0px' }}
                        >
                            <form>
                                <div class="row form-group">
                                    <div class="col-md-12">
                                        <div class="card bg-white bn_15">
                                            <div class="card-body card-body_1">
                                                <ul class="nav nav-tabs nav-tabs-bottom nav_add_bt">
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link active"
                                                            href="#bottom-tab1"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Orders
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab2"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Wallet
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab3"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Documents
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab4"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Driver Details
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab5"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Vehicle Details
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab6"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Address Details
                                                        </a>
                                                    </li>
                                                </ul>

                                                <div class="tab-content">
                                                    {/* Orders */}
                                                    <ModuleOrders
                                                    role="transport"
                                                        userId={userId}
                                                        idForAssign={
                                                            idForAssign
                                                        }
                                                    />

                                                    {/* Wallet */}
                                                    <Wallet  moduleType="vehicle" />

                                                    <div
                                                        class="tab-pane"
                                                        id="bottom-tab3"
                                                    >
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="card-body card-body_1">
                                                                    <div className="row">
                                                                        <div class="col-md-12 text-end justify-space-between mb-4">
                                                                            {!addDoc ? (
                                                                                <button
                                                                                    type="submit"
                                                                                    className="btn btn-primary"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.preventDefault()
                                                                                        setAddDoc(
                                                                                            true
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    +
                                                                                    Add
                                                                                    Document
                                                                                </button>
                                                                            ) : (
                                                                                <button
                                                                                    type="submit"
                                                                                    className="btn btn-primary"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.preventDefault()
                                                                                        setAddDoc(
                                                                                            false
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    Document
                                                                                    List
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    {!addDoc ? (
                                                                        <>
                                                                            <div class="table-wrap">
                                                                                <div class="table-responsive">
                                                                                    <table class="table display setting-td mb-30">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>
                                                                                                    TITLE
                                                                                                </th>
                                                                                                <th>
                                                                                                    PHOTO
                                                                                                </th>
                                                                                                <th>
                                                                                                    CREATED
                                                                                                </th>
                                                                                                <th>
                                                                                                    ACTIONS
                                                                                                </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    -
                                                                                                </td>
                                                                                                <td>
                                                                                                    {' '}
                                                                                                    <img
                                                                                                        src={
                                                                                                            Details.document
                                                                                                        }
                                                                                                        height={
                                                                                                            '90px'
                                                                                                        }
                                                                                                        style={{
                                                                                                            width: '50%',
                                                                                                        }}
                                                                                                        alt=""
                                                                                                    />
                                                                                                </td>
                                                                                                {/* <td>   {Details && Details.createdAt && Details.createdAt.slice(0, 10)}   </td> */}
                                                                                                <td>
                                                                                                    <a
                                                                                                        href="#"
                                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                    >
                                                                                                        <span class="material-icons-outlined">
                                                                                                            edit
                                                                                                        </span>
                                                                                                    </a>
                                                                                                    <a
                                                                                                        href="#"
                                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                    >
                                                                                                        <span class="material-icons-outlined">
                                                                                                            delete
                                                                                                        </span>
                                                                                                    </a>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <form
                                                                                class="tab-pane show active"
                                                                                id="bottom-justified-tab4"
                                                                                onSubmit={(
                                                                                    e
                                                                                ) =>
                                                                                    console.log(
                                                                                        5
                                                                                    )
                                                                                }
                                                                            >
                                                                                <div className="row">
                                                                                    <h4>
                                                                                        Add
                                                                                        New
                                                                                        Document
                                                                                    </h4>
                                                                                    <div className="col-md-3 mb-2">
                                                                                        <InputLabel
                                                                                            id="demo-simple-select-standard-label"
                                                                                            style={{
                                                                                                color: 'black',
                                                                                            }}
                                                                                        >
                                                                                            Document
                                                                                            Upload
                                                                                        </InputLabel>
                                                                                        <div
                                                                                            class="form-group row widget-3"
                                                                                            style={{
                                                                                                width: '200px',
                                                                                                margin: 'auto',
                                                                                                display:
                                                                                                    'flex',
                                                                                                justifyContent:
                                                                                                    'center',
                                                                                            }}
                                                                                        >
                                                                                            <section
                                                                                                className="container"
                                                                                                style={
                                                                                                    baseStyle
                                                                                                }
                                                                                            >
                                                                                                <div
                                                                                                    {...getRootProps(
                                                                                                        {
                                                                                                            className:
                                                                                                                'dropzone',
                                                                                                        }
                                                                                                    )}
                                                                                                    style={{
                                                                                                        borderRadius:
                                                                                                            '5px',
                                                                                                        display:
                                                                                                            'flex',
                                                                                                        justifyContent:
                                                                                                            'center',
                                                                                                        alignItems:
                                                                                                            'center',
                                                                                                        height: '100px',
                                                                                                    }}
                                                                                                >
                                                                                                    <input
                                                                                                        {...getInputProps()}
                                                                                                        multiple={
                                                                                                            false
                                                                                                        }
                                                                                                    />
                                                                                                    {Files && (
                                                                                                        <>
                                                                                                            {Files.length ===
                                                                                                                0 && (
                                                                                                                <p align="center">
                                                                                                                    Drop
                                                                                                                    Image
                                                                                                                    here
                                                                                                                    or
                                                                                                                    click
                                                                                                                    to
                                                                                                                    upload.
                                                                                                                </p>
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                    {Files && (
                                                                                                        <>
                                                                                                            {Files.length >
                                                                                                                0 && (
                                                                                                                <aside
                                                                                                                    style={
                                                                                                                        thumbsContainerState
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        PGthumbs
                                                                                                                    }
                                                                                                                </aside>
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            </section>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div class="col-md-3">
                                                                                        <FormControl
                                                                                            variant="standard"
                                                                                            fullWidth
                                                                                            className="mt-3"
                                                                                        >
                                                                                            <InputLabel
                                                                                                id="demo-simple-select-standard-label"
                                                                                                style={{
                                                                                                    color: 'black',
                                                                                                }}
                                                                                            >
                                                                                                Document
                                                                                                Type
                                                                                            </InputLabel>
                                                                                            <Select
                                                                                                labelId="demo-simple-select-standard-label"
                                                                                                id="soilType"
                                                                                                label="Soil Type"
                                                                                                color="success"
                                                                                                name="soilType"
                                                                                                //  value={farmer.selectUnit}
                                                                                                //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                                                                            >
                                                                                                <MenuItem value="">
                                                                                                    <em>
                                                                                                        -
                                                                                                        Select
                                                                                                        Unit
                                                                                                        -
                                                                                                    </em>
                                                                                                </MenuItem>
                                                                                                <MenuItem value="Hindi">
                                                                                                    Aadhar
                                                                                                </MenuItem>
                                                                                                <MenuItem value="Bengali">
                                                                                                    PAN
                                                                                                </MenuItem>
                                                                                            </Select>
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div class="col-md-3">
                                                                                        <CssTextField
                                                                                            fullWidth
                                                                                            id="fEducation"
                                                                                            label="Document Number"
                                                                                            variant="standard"
                                                                                            className="mt-3"
                                                                                            style={{
                                                                                                marginBottom:
                                                                                                    '1.25rem',
                                                                                            }}
                                                                                            name="education"
                                                                                            //  value={farmer.education}
                                                                                            //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 text-end mt-2 mb-2">
                                                                                    <button
                                                                                        type="submit"
                                                                                        class=" w-10 btn btn-primary"
                                                                                    >
                                                                                        Submit
                                                                                    </button>
                                                                                </div>
                                                                            </form>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div class="tab-pane" id="bottom-tab4">    
                                 <div class="row">                                 
                                       <div class="col-lg-12">
                                       <div class="card-body card-body_1">
                                             <div class="table-wrap">
                                                <div class="table-responsive">
                                                   <table class="table display setting-td mb-30" >
                                                      <thead>
                                                         <tr>
                                                            <th>TITLE</th>
                                                            <th>PHOTO</th>
                                                            <th>CREATED</th>
                                                            <th>ACTIONS</th>
                                                         </tr>
                                                      </thead>
                                                      <tbody>
                                                      <tr>
                                                      <td>{Details.driverLicenseNumber}</td>
                                                      <td> <img src={Details.driverPhoto}  height={'90px'} style={{width:'50%'}} alt="" /></td>    
                                                      <td>   {Details && Details.createdAt && Details.createdAt.slice(0, 10)}   </td>                                                           
                                                      <td>
                                                         <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                                                            <span class="material-icons-outlined">edit</span>
                                                         </a>  
                                                         <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                                                            <span class="material-icons-outlined">delete</span>
                                                         </a>                                                                 
                                                       </td>
                                                   </tr>

                                                                                                       
                                                         
                                                         
                                                      </tbody>
                                                   </table>
                                                </div>
                                             </div>
                                          </div>

                                       </div>
                                    </div>
                                 </div> */}
                                                    <div
                                                        class="tab-pane"
                                                        id="bottom-tab4"
                                                    >
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="card-body card-body_1">
                                                                    <div className="row">
                                                                        <div class="col-md-12 text-end justify-space-between mb-4">
                                                                            {!addFarm ? (
                                                                                <button
                                                                                    type="submit"
                                                                                    className="btn btn-primary"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.preventDefault()
                                                                                        setAddFarm(
                                                                                            true
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    +
                                                                                    Add
                                                                                    Driver
                                                                                </button>
                                                                            ) : (
                                                                                <button
                                                                                    type="submit"
                                                                                    className="btn btn-primary"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.preventDefault()
                                                                                        setAddFarm(
                                                                                            false
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    Drivers
                                                                                    List
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    {!addFarm ? (
                                                                        <>
                                                                            <div
                                                                                class="card"
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        '#f3f3f3 ',
                                                                                }}
                                                                            >
                                                                                {FirmDetails &&
                                                                                    FirmDetails &&
                                                                                    FirmDetails.map(
                                                                                        (
                                                                                            val,
                                                                                            ind
                                                                                        ) => {
                                                                                            return (
                                                                                                <div class="card-body">
                                                                                                    <h5 class="card-title d-flex justify-content-between store_edit">
                                                                                                        <span>
                                                                                                            Driver
                                                                                                            1
                                                                                                        </span>
                                                                                                        <a
                                                                                                            href="#"
                                                                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                        >
                                                                                                            <span class="material-icons-outlined mr-3">
                                                                                                                edit
                                                                                                            </span>
                                                                                                            <span class="material-icons-outlined">
                                                                                                                delete
                                                                                                            </span>
                                                                                                        </a>
                                                                                                    </h5>
                                                                                                    <ul class="list-unstyled mb-0 store_list">
                                                                                                        <div className="row">
                                                                                                            <div className="col-md-12 mb-4">
                                                                                                                <li class="pt-2 pb-0">
                                                                                                                    <small class="text-dark">
                                                                                                                        {' '}
                                                                                                                        Driver
                                                                                                                        Photo{' '}
                                                                                                                    </small>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <div
                                                                                                                        style={
                                                                                                                            thumb
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <div
                                                                                                                            style={
                                                                                                                                thumbInner
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <img
                                                                                                                                src={
                                                                                                                                    val.driverPhoto
                                                                                                                                }
                                                                                                                                style={
                                                                                                                                    img
                                                                                                                                }
                                                                                                                                alt=""
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                            </div>
                                                                                                            <div className="col-md-4 mb-4">
                                                                                                                <li class="pt-2 pb-0">
                                                                                                                    <small class="text-dark">
                                                                                                                        Name
                                                                                                                    </small>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    {val.driverFirstName +
                                                                                                                        ' ' +
                                                                                                                        val.driverLastName}
                                                                                                                </li>
                                                                                                            </div>
                                                                                                            <div className="col-md-4 mb-4">
                                                                                                                <li class="pt-2 pb-0">
                                                                                                                    <small class="text-dark">
                                                                                                                        Mobile
                                                                                                                        Number
                                                                                                                    </small>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    {
                                                                                                                        val.driverMobileNumber
                                                                                                                    }
                                                                                                                </li>
                                                                                                            </div>
                                                                                                            <div className="col-md-4 mb-4">
                                                                                                                <li class="pt-2 pb-0">
                                                                                                                    <small class="text-dark">
                                                                                                                        Email
                                                                                                                        ID{' '}
                                                                                                                    </small>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    {
                                                                                                                        val.driverEmail
                                                                                                                    }
                                                                                                                </li>
                                                                                                            </div>
                                                                                                            <div className="col-md-3 mb-4">
                                                                                                                <li class="pt-2 pb-0">
                                                                                                                    <small class="text-dark">
                                                                                                                        {' '}
                                                                                                                        DL
                                                                                                                        Photo{' '}
                                                                                                                    </small>
                                                                                                                </li>
                                                                                                                <li class="mt-2">
                                                                                                                    <div
                                                                                                                        style={
                                                                                                                            thumb
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <div
                                                                                                                            style={
                                                                                                                                thumbInner
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <img
                                                                                                                                src={
                                                                                                                                    val.drivingLicencePhoto
                                                                                                                                }
                                                                                                                                style={
                                                                                                                                    img
                                                                                                                                }
                                                                                                                                alt=""
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                                <li class="pt-2 pb-0">
                                                                                                                    DL
                                                                                                                    Number
                                                                                                                    :{' '}
                                                                                                                    {
                                                                                                                        val.driverLicenceNumber
                                                                                                                    }
                                                                                                                </li>
                                                                                                            </div>
                                                                                                            <div className="col-md-3 mb-4">
                                                                                                                <li class="pt-2 pb-0">
                                                                                                                    <small class="text-dark">
                                                                                                                        {' '}
                                                                                                                        PAN
                                                                                                                        Photo{' '}
                                                                                                                    </small>
                                                                                                                </li>
                                                                                                                <li class="mt-2">
                                                                                                                    <div
                                                                                                                        style={
                                                                                                                            thumb
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <div
                                                                                                                            style={
                                                                                                                                thumbInner
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <img
                                                                                                                                src={
                                                                                                                                    val.driverPancardPhoto
                                                                                                                                }
                                                                                                                                style={
                                                                                                                                    img
                                                                                                                                }
                                                                                                                                alt=""
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                                <li class="pt-2 pb-0">
                                                                                                                    PAN
                                                                                                                    Number
                                                                                                                    :
                                                                                                                    {
                                                                                                                        val.driverPancardNumber
                                                                                                                    }
                                                                                                                </li>
                                                                                                            </div>
                                                                                                            <div className="col-md-3 mb-4">
                                                                                                                <li class="pt-2 pb-0">
                                                                                                                    <small class="text-dark">
                                                                                                                        {' '}
                                                                                                                        Aadhar
                                                                                                                        Photo{' '}
                                                                                                                    </small>
                                                                                                                </li>
                                                                                                                <li class="mt-2">
                                                                                                                    <div
                                                                                                                        style={
                                                                                                                            thumb
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <div
                                                                                                                            style={
                                                                                                                                thumbInner
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <img
                                                                                                                                src={
                                                                                                                                    val.driverAdhaarPhoto
                                                                                                                                }
                                                                                                                                style={
                                                                                                                                    img
                                                                                                                                }
                                                                                                                                alt=""
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                                <li class="pt-2 pb-0">
                                                                                                                    Aadhar
                                                                                                                    Number
                                                                                                                    :{' '}
                                                                                                                    {
                                                                                                                        val.driverAdhaarNumber
                                                                                                                    }
                                                                                                                </li>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    )}
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <form
                                                                                class="tab-pane show active"
                                                                                id="bottom-justified-tab4"
                                                                                onSubmit={(
                                                                                    e
                                                                                ) =>
                                                                                    console.log(
                                                                                        5
                                                                                    )
                                                                                }
                                                                            >
                                                                                <div className="row">
                                                                                    <h4>
                                                                                        Add
                                                                                        New
                                                                                        Driver
                                                                                    </h4>
                                                                                    <div className="col-md-3">
                                                                                        <CssTextField
                                                                                            fullWidth
                                                                                            type="text"
                                                                                            id="driverFirstName"
                                                                                            label="First Name"
                                                                                            variant="standard"
                                                                                            name="driverFirstName"
                                                                                            value={
                                                                                                agriMachine.driverFirstName
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setAgriMachine(
                                                                                                    {
                                                                                                        ...agriMachine,
                                                                                                        [e
                                                                                                            .target
                                                                                                            .name]:
                                                                                                            e
                                                                                                                .target
                                                                                                                .value,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-3">
                                                                                        <CssTextField
                                                                                            fullWidth
                                                                                            type="text"
                                                                                            id="driverLastName"
                                                                                            label="Last Name"
                                                                                            variant="standard"
                                                                                            name="driverLastName"
                                                                                            value={
                                                                                                agriMachine.driverLastName
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setAgriMachine(
                                                                                                    {
                                                                                                        ...agriMachine,
                                                                                                        [e
                                                                                                            .target
                                                                                                            .name]:
                                                                                                            e
                                                                                                                .target
                                                                                                                .value,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-3">
                                                                                        <CssTextField
                                                                                            fullWidth
                                                                                            type="text"
                                                                                            id="driverMobileNumber"
                                                                                            label="Mobile Number"
                                                                                            variant="standard"
                                                                                            name="driverMobileNumber"
                                                                                            value={
                                                                                                agriMachine.driverMobileNumber
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setAgriMachine(
                                                                                                    {
                                                                                                        ...agriMachine,
                                                                                                        [e
                                                                                                            .target
                                                                                                            .name]:
                                                                                                            e
                                                                                                                .target
                                                                                                                .value,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-3">
                                                                                        <CssTextField
                                                                                            fullWidth
                                                                                            type="text"
                                                                                            id="driverEmail"
                                                                                            label="Email ID"
                                                                                            variant="standard"
                                                                                            name="driverEmail"
                                                                                            value={
                                                                                                agriMachine.driverEmail
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                setAgriMachine(
                                                                                                    {
                                                                                                        ...agriMachine,
                                                                                                        [e
                                                                                                            .target
                                                                                                            .name]:
                                                                                                            e
                                                                                                                .target
                                                                                                                .value,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    {/*  */}
                                                                                    <div className="row">
                                                                                        <div className="col-md-12 mt-3 d-flex">
                                                                                            <InputLabel
                                                                                                id="demo-simple-select-standard-label"
                                                                                                style={{
                                                                                                    color: 'black',
                                                                                                    marginRight:
                                                                                                        '10px',
                                                                                                }}
                                                                                            >
                                                                                                Upload
                                                                                                Driver
                                                                                                Photo:
                                                                                            </InputLabel>
                                                                                            <label htmlFor="contained-button-file">
                                                                                                <Button
                                                                                                    variant="contained"
                                                                                                    component="span"
                                                                                                    color="success"
                                                                                                    size="small"
                                                                                                    style={
                                                                                                        uploadBtnSm
                                                                                                    }
                                                                                                >
                                                                                                    <Input
                                                                                                        accept="image/"
                                                                                                        id="contained-button-file"
                                                                                                        multiple="false"
                                                                                                        type="file"
                                                                                                        onChange={
                                                                                                            handleUploadDP
                                                                                                        }
                                                                                                    />
                                                                                                    Upload
                                                                                                </Button>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="col-md-3 mt-3">
                                                                                            <div
                                                                                                class="form-group row widget-3"
                                                                                                style={
                                                                                                    sectionDiv
                                                                                                }
                                                                                            >
                                                                                                <section
                                                                                                    className="container"
                                                                                                    style={
                                                                                                        driverDP
                                                                                                    }
                                                                                                >
                                                                                                    {driverImg ===
                                                                                                    null ? (
                                                                                                        <p align="center">
                                                                                                            Upload
                                                                                                            Image
                                                                                                        </p>
                                                                                                    ) : (
                                                                                                        <aside
                                                                                                            style={
                                                                                                                thumbsContainerState
                                                                                                            }
                                                                                                        >
                                                                                                            <div
                                                                                                                style={
                                                                                                                    thumb
                                                                                                                }
                                                                                                            >
                                                                                                                <div
                                                                                                                    style={
                                                                                                                        thumbInner
                                                                                                                    }
                                                                                                                >
                                                                                                                    <img
                                                                                                                        src={
                                                                                                                            driverImg
                                                                                                                        }
                                                                                                                        style={
                                                                                                                            img
                                                                                                                        }
                                                                                                                        alt="driverPhoto"
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </aside>
                                                                                                    )}
                                                                                                </section>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* Driver Photo*/}
                                                                                    <div className="row">
                                                                                        <div className="col-md-12 mt-3 d-flex">
                                                                                            <InputLabel
                                                                                                id="demo-simple-select-standard-label"
                                                                                                style={{
                                                                                                    color: 'black',
                                                                                                    marginRight:
                                                                                                        '10px',
                                                                                                }}
                                                                                            >
                                                                                                Upload
                                                                                                Driving
                                                                                                License
                                                                                                Photo:
                                                                                            </InputLabel>

                                                                                            <label htmlFor="contained-button">
                                                                                                <Button
                                                                                                    variant="contained"
                                                                                                    component="span"
                                                                                                    color="success"
                                                                                                    size="small"
                                                                                                    style={
                                                                                                        uploadBtnSm
                                                                                                    }
                                                                                                >
                                                                                                    <Input
                                                                                                        accept="image/"
                                                                                                        id="contained-button"
                                                                                                        multiple="false"
                                                                                                        type="file"
                                                                                                        onChange={
                                                                                                            handleUploadDLP
                                                                                                        }
                                                                                                    />
                                                                                                    Upload
                                                                                                </Button>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="col-md-3 mt-3">
                                                                                            {console.log(
                                                                                                'driverLicenseImg',
                                                                                                driverLicenseImg
                                                                                            )}
                                                                                            <div
                                                                                                class="form-group row widget-3"
                                                                                                style={
                                                                                                    sectionDiv
                                                                                                }
                                                                                            >
                                                                                                <section
                                                                                                    className="container"
                                                                                                    style={
                                                                                                        driverDP
                                                                                                    }
                                                                                                >
                                                                                                    {driverLicenseImg ===
                                                                                                    null ? (
                                                                                                        <p align="center">
                                                                                                            Upload
                                                                                                            Image
                                                                                                        </p>
                                                                                                    ) : (
                                                                                                        <aside
                                                                                                            style={
                                                                                                                thumbsContainerState
                                                                                                            }
                                                                                                        >
                                                                                                            <div
                                                                                                                style={
                                                                                                                    thumb
                                                                                                                }
                                                                                                            >
                                                                                                                <div
                                                                                                                    style={
                                                                                                                        thumbInner
                                                                                                                    }
                                                                                                                >
                                                                                                                    <img
                                                                                                                        src={
                                                                                                                            driverLicenseImg
                                                                                                                        }
                                                                                                                        style={
                                                                                                                            img
                                                                                                                        }
                                                                                                                        alt="driver Licence"
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </aside>
                                                                                                    )}
                                                                                                </section>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-md-3 mt-3">
                                                                                            <InputLabel
                                                                                                id="demo-simple-select-standard-label"
                                                                                                style={{
                                                                                                    color: 'black',
                                                                                                    marginTop:
                                                                                                        '90px',
                                                                                                }}
                                                                                            >
                                                                                                Driving
                                                                                                Licence
                                                                                                Number
                                                                                            </InputLabel>
                                                                                            <CssTextField
                                                                                                fullWidth
                                                                                                id="driverLicenceNumber"
                                                                                                label=""
                                                                                                variant="standard"
                                                                                                className="mt-0"
                                                                                                name="driverLicenceNumber"
                                                                                                value={
                                                                                                    agriMachine.driverLicenceNumber
                                                                                                }
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    setAgriMachine(
                                                                                                        {
                                                                                                            ...agriMachine,
                                                                                                            [e
                                                                                                                .target
                                                                                                                .name]:
                                                                                                                e
                                                                                                                    .target
                                                                                                                    .value,
                                                                                                        }
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* PAN Upload */}
                                                                                    <div className="row">
                                                                                        <div className="col-md-12 mt-3 d-flex">
                                                                                            <InputLabel
                                                                                                id="demo-simple-select-standard-label"
                                                                                                style={{
                                                                                                    color: 'black',
                                                                                                    marginRight:
                                                                                                        '10px',
                                                                                                }}
                                                                                            >
                                                                                                Upload
                                                                                                PAN
                                                                                                Card
                                                                                                Photo:
                                                                                            </InputLabel>

                                                                                            <label htmlFor="contained-button-PAN">
                                                                                                <Button
                                                                                                    variant="contained"
                                                                                                    component="span"
                                                                                                    color="success"
                                                                                                    size="small"
                                                                                                    style={
                                                                                                        uploadBtnSm
                                                                                                    }
                                                                                                >
                                                                                                    <Input
                                                                                                        accept="image/"
                                                                                                        id="contained-button-PAN"
                                                                                                        multiple="false"
                                                                                                        type="file"
                                                                                                        onChange={
                                                                                                            handleUploadPAN
                                                                                                        }
                                                                                                    />
                                                                                                    Upload
                                                                                                </Button>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="col-md-3 mt-3">
                                                                                            {console.log(
                                                                                                'driverLicenseImg',
                                                                                                PANImg
                                                                                            )}
                                                                                            <div
                                                                                                class="form-group row widget-3"
                                                                                                style={
                                                                                                    sectionDiv
                                                                                                }
                                                                                            >
                                                                                                <section
                                                                                                    className="container"
                                                                                                    style={
                                                                                                        driverDP
                                                                                                    }
                                                                                                >
                                                                                                    {PANImg ===
                                                                                                    null ? (
                                                                                                        <p align="center">
                                                                                                            Upload
                                                                                                            Image
                                                                                                        </p>
                                                                                                    ) : (
                                                                                                        <aside
                                                                                                            style={
                                                                                                                thumbsContainerState
                                                                                                            }
                                                                                                        >
                                                                                                            <div
                                                                                                                style={
                                                                                                                    thumb
                                                                                                                }
                                                                                                            >
                                                                                                                <div
                                                                                                                    style={
                                                                                                                        thumbInner
                                                                                                                    }
                                                                                                                >
                                                                                                                    <img
                                                                                                                        src={
                                                                                                                            PANImg
                                                                                                                        }
                                                                                                                        style={
                                                                                                                            img
                                                                                                                        }
                                                                                                                        alt="driver Licence"
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </aside>
                                                                                                    )}
                                                                                                </section>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-md-3 mt-3">
                                                                                            <InputLabel
                                                                                                id="demo-simple-select-standard-label"
                                                                                                style={{
                                                                                                    color: 'black',
                                                                                                    marginTop:
                                                                                                        '90px',
                                                                                                }}
                                                                                            >
                                                                                                PAN
                                                                                                Number
                                                                                            </InputLabel>
                                                                                            <CssTextField
                                                                                                fullWidth
                                                                                                id="driverPancardNumber"
                                                                                                variant="standard"
                                                                                                className="mt-0"
                                                                                                name="driverPancardNumber"
                                                                                                value={
                                                                                                    agriMachine.driverPancardNumber
                                                                                                }
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    setAgriMachine(
                                                                                                        {
                                                                                                            ...agriMachine,
                                                                                                            [e
                                                                                                                .target
                                                                                                                .name]:
                                                                                                                e
                                                                                                                    .target
                                                                                                                    .value,
                                                                                                        }
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    {/*Aadhaar Upload */}
                                                                                    <div className="row">
                                                                                        <div className="col-md-12 mt-3 d-flex">
                                                                                            <InputLabel
                                                                                                id="demo-simple-select-standard-label"
                                                                                                style={{
                                                                                                    color: 'black',
                                                                                                    marginRight:
                                                                                                        '10px',
                                                                                                }}
                                                                                            >
                                                                                                Upload
                                                                                                Aadhaar
                                                                                                Card
                                                                                                Photo:
                                                                                            </InputLabel>

                                                                                            <label htmlFor="contained-button-AADHAR">
                                                                                                <Button
                                                                                                    variant="contained"
                                                                                                    component="span"
                                                                                                    color="success"
                                                                                                    size="small"
                                                                                                    style={
                                                                                                        uploadBtnSm
                                                                                                    }
                                                                                                >
                                                                                                    <Input
                                                                                                        accept="image/"
                                                                                                        id="contained-button-AADHAR"
                                                                                                        multiple="false"
                                                                                                        type="file"
                                                                                                        onChange={
                                                                                                            handleUploadAADHAR
                                                                                                        }
                                                                                                    />
                                                                                                    Upload
                                                                                                </Button>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="col-md-3 mt-3">
                                                                                            {console.log(
                                                                                                'AAdharImg',
                                                                                                AAdharImg
                                                                                            )}
                                                                                            <div
                                                                                                class="form-group row widget-3"
                                                                                                style={
                                                                                                    sectionDiv
                                                                                                }
                                                                                            >
                                                                                                <section
                                                                                                    className="container"
                                                                                                    style={
                                                                                                        driverDP
                                                                                                    }
                                                                                                >
                                                                                                    {AAdharImg ===
                                                                                                    null ? (
                                                                                                        <p align="center">
                                                                                                            Upload
                                                                                                            Image
                                                                                                        </p>
                                                                                                    ) : (
                                                                                                        <aside
                                                                                                            style={
                                                                                                                thumbsContainerState
                                                                                                            }
                                                                                                        >
                                                                                                            <div
                                                                                                                style={
                                                                                                                    thumb
                                                                                                                }
                                                                                                            >
                                                                                                                <div
                                                                                                                    style={
                                                                                                                        thumbInner
                                                                                                                    }
                                                                                                                >
                                                                                                                    <img
                                                                                                                        src={
                                                                                                                            AAdharImg
                                                                                                                        }
                                                                                                                        style={
                                                                                                                            img
                                                                                                                        }
                                                                                                                        alt="driver Licence"
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </aside>
                                                                                                    )}
                                                                                                </section>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-md-3 mt-3">
                                                                                            <InputLabel
                                                                                                id="demo-simple-select-standard-label"
                                                                                                style={{
                                                                                                    color: 'black',
                                                                                                    marginTop:
                                                                                                        '90px',
                                                                                                }}
                                                                                            >
                                                                                                Adhaar
                                                                                                Number
                                                                                            </InputLabel>
                                                                                            <CssTextField
                                                                                                fullWidth
                                                                                                id="aadhaarNo"
                                                                                                label=""
                                                                                                variant="standard"
                                                                                                className="mt-0"
                                                                                                name="driverAdhaarNumber"
                                                                                                value={
                                                                                                    agriMachine.driverAdhaarNumber
                                                                                                }
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    setAgriMachine(
                                                                                                        {
                                                                                                            ...agriMachine,
                                                                                                            [e
                                                                                                                .target
                                                                                                                .name]:
                                                                                                                e
                                                                                                                    .target
                                                                                                                    .value,
                                                                                                        }
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-md-12 text-end mt-2 mb-2">
                                                                                        <button
                                                                                            class=" w-16 btn btn-primary"
                                                                                            onClick={(
                                                                                                e
                                                                                            ) =>
                                                                                                handlebehivlesubmit(
                                                                                                    e
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            Submit
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className="row">
                             <h4>Add New Driver</h4>
                                  <div class="col-md-3">
                                    <CssTextField 
                                      fullWidth
                                      id="totalLand" 
                                      label="Name" 
                                      variant="standard"
                                      name="totalLand"
                                    //   value={farmer.totalLand}
                                    //   onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})} 
                                    />                                          
                                  </div>
                                  <div class="col-md-3">
                                    <CssTextField 
                                      fullWidth
                                      id="totalLand" 
                                      label="Mobile Number" 
                                      variant="standard"
                                      name="totalLand"
                                    //   value={farmer.totalLand}
                                    //   onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})} 
                                    />                                          
                                  </div>
                                  <div class="col-md-3">
                                    <CssTextField 
                                      fullWidth
                                      id="totalLand" 
                                      label="Email ID" 
                                      variant="standard"
                                      name="totalLand"
                                    //   value={farmer.totalLand}
                                    //   onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})} 
                                    />                                          
                                  </div>
                           </div> 
                           <div className="row">
                              <div className="col-md-3">
                               <InputLabel id="demo-simple-select-standard-label" className="mt-2 mb-2">Driver Photo</InputLabel>
                               <div style={thumb}>
                                 <div style={thumbInner}>
                                 <img src={""} style={img} alt="" />
                               </div>
                              </div>
                             </div>
                           </div>
                           <div className="row">
                              <div className="col-md-3">
                               <InputLabel id="demo-simple-select-standard-label" className="mt-2 mb-2"> DL Photo</InputLabel>
                               <div style={thumb}>
                                 <div style={thumbInner}>
                                 <img src={""} style={img} alt="" />
                               </div>
                              </div>
                             </div>
                              <div className="col-md-3 d-flex align-items-end">
                               <CssTextField 
                                 fullWidth
                                 id="totalLand" 
                                 label="DL Number" 
                                 variant="standard"
                                 name="totalLand"
                                 //   value={farmer.totalLand}
                                 //   onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})} 
                               />    
                             </div>
                           </div>
                           <div className="row">
                              <div className="col-md-3">
                               <InputLabel id="demo-simple-select-standard-label" className="mt-2 mb-2"> PAN Photo</InputLabel>
                               <div style={thumb}>
                                 <div style={thumbInner}>
                                 <img src={""} style={img} alt="" />
                               </div>
                              </div>
                             </div>
                              <div className="col-md-3 d-flex align-items-end">
                               <CssTextField 
                                 fullWidth
                                 id="totalLand" 
                                 label="PAN Number" 
                                 variant="standard"
                                 name="totalLand"
                                 //   value={farmer.totalLand}
                                 //   onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})} 
                               />    
                             </div>
                           </div>
                           <div className="row">
                              <div className="col-md-3">
                               <InputLabel id="demo-simple-select-standard-label" className="mt-2 mb-2"> Aadhar Photo</InputLabel>
                               <div style={thumb}>
                                 <div style={thumbInner}>
                                 <img src={""} style={img} alt="" />
                               </div>
                              </div>
                             </div>
                              <div className="col-md-3 d-flex align-items-end">
                               <CssTextField 
                                 fullWidth
                                 id="totalLand" 
                                 label="Aadhar Number" 
                                 variant="standard"
                                 name="totalLand"
                                 //   value={farmer.totalLand}
                                 //   onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})} 
                               />    
                             </div>
                              <div class="col-md-12 text-end mt-2 mb-2">
                                <button type="submit" class=" w-10 btn btn-primary">Submit</button>
                              </div>
                           </div> */}
                                                                            </form>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div class="tab-pane" id="bottom-tab4">    
                                 <div class="row">                                 
                                       <div class="col-lg-12">
                                       <div class="card-body card-body_1">
                                        <div className="row">
                                        <div class="col-md-12 text-end justify-space-between mb-4">
                                          {!addFarm ?
                                            <button type="submit" className="btn btn-primary" onClick={(e)=>{
                                               e.preventDefault();
                                               setAddFarm(true)}}
                                               >
                                                 + Add Driver
                                              </button>
                                          :
                                          <button type="submit" className="btn btn-primary" onClick={(e)=>{
                                             e.preventDefault();
                                             setAddFarm(false)}}
                                          >
                                             Drivers List 
                                          </button>
                                          }
                                       </div>
                                        </div>  

                                      {!addFarm ? 
                                      <>
                                      <div class="card" style={{ backgroundColor: '#f3f3f3 '}}>   
                                         <div class="card-body">
                                          <h5 class="card-title d-flex justify-content-between store_edit">
                                             <span>Driver 1</span>
                                             <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                                                <span class="material-icons-outlined mr-3">edit</span>
                                                <span class="material-icons-outlined">delete</span>
                                             </a>  
                                          </h5>
                                          <ul class="list-unstyled mb-0 store_list">
                                          <div className="row">
                                          <div className="col-md-12 mb-4">
                                              <li class="pt-2 pb-0">
                                                <small class="text-dark">  Driver Photo </small>
                                               </li>
                                               <li>
                                               <div style={thumb}>
                                                 <div style={thumbInner}>
                                                  <img src={""} style={img} alt="" />
                                                 </div>
                                               </div>
                                               </li>
                                             </div>
                                             <div className="col-md-4 mb-4">
                                              <li class="pt-2 pb-0">
                                                <small class="text-dark">Name</small>
                                               </li>
                                               <li>
                                                  .......
                                               </li>
                                             </div>
                                             <div className="col-md-4 mb-4">
                                              <li class="pt-2 pb-0">
                                                <small class="text-dark">Mobile Number</small>
                                               </li>
                                               <li>
                                                  .......
                                               </li>
                                             </div>
                                             <div className="col-md-4 mb-4">
                                              <li class="pt-2 pb-0">
                                                <small class="text-dark">Email ID </small>
                                               </li>
                                               <li>
                                                  .......
                                               </li>
                                             </div>
                                             <div className="col-md-3 mb-4">
                                               <li class="pt-2 pb-0">
                                                <small class="text-dark">  DL Photo </small>
                                               </li>
                                               <li class="mt-2">
                                               <div style={thumb}>
                                                 <div style={thumbInner}>
                                                  <img src={""} style={img} alt="" />
                                                 </div>
                                               </div>
                                               </li>
                                               <li class="pt-2 pb-0">
                                                  DL Number : ........
                                               </li>
                                             </div>
                                             <div className="col-md-3 mb-4">
                                               <li class="pt-2 pb-0">
                                                <small class="text-dark">  PAN Photo </small>
                                               </li>
                                               <li class="mt-2">
                                               <div style={thumb}>
                                                 <div style={thumbInner}>
                                                  <img src={""} style={img} alt="" />
                                                 </div>
                                               </div>
                                               </li>
                                               <li class="pt-2 pb-0">
                                                  PAN Number : ........
                                               </li>
                                             </div>
                                             <div className="col-md-3 mb-4">
                                               <li class="pt-2 pb-0">
                                                <small class="text-dark">  Aadhar Photo </small>
                                               </li>
                                               <li class="mt-2">
                                               <div style={thumb}>
                                                 <div style={thumbInner}>
                                                  <img src={""} style={img} alt="" />
                                                 </div>
                                               </div>
                                               </li>
                                               <li class="pt-2 pb-0">
                                                  Aadhar Number : ........
                                               </li>
                                             </div>
                                          </div>
                                          </ul>
                                       </div>
                                       </div>
                                       <div class="card" style={{ backgroundColor: '#f3f3f3 '}}>   
                                         <div class="card-body">
                                          <h5 class="card-title d-flex justify-content-between store_edit">
                                             <span>Driver 2</span>
                                             <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                                                <span class="material-icons-outlined mr-3">edit</span>
                                                <span class="material-icons-outlined">delete</span>
                                             </a>  
                                          </h5>
                                          <ul class="list-unstyled mb-0 store_list">
                                          <div className="row">
                                          <div className="col-md-12 mb-4">
                                              <li class="pt-2 pb-0">
                                                <small class="text-dark">  Driver Photo </small>
                                               </li>
                                               <li>
                                               <div style={thumb}>
                                                 <div style={thumbInner}>
                                                  <img src={""} style={img} alt="" />
                                                 </div>
                                               </div>
                                               </li>
                                             </div>
                                             <div className="col-md-4 mb-4">
                                              <li class="pt-2 pb-0">
                                                <small class="text-dark">Name</small>
                                               </li>
                                               <li>
                                                  .......
                                               </li>
                                             </div>
                                             <div className="col-md-4 mb-4">
                                              <li class="pt-2 pb-0">
                                                <small class="text-dark">Mobile Number</small>
                                               </li>
                                               <li>
                                                  .......
                                               </li>
                                             </div>
                                             <div className="col-md-4 mb-4">
                                              <li class="pt-2 pb-0">
                                                <small class="text-dark">Email ID </small>
                                               </li>
                                               <li>
                                                  .......
                                               </li>
                                             </div>
                                             <div className="col-md-3 mb-4">
                                               <li class="pt-2 pb-0">
                                                <small class="text-dark">  DL Photo </small>
                                               </li>
                                               <li class="mt-2">
                                               <div style={thumb}>
                                                 <div style={thumbInner}>
                                                  <img src={""} style={img} alt="" />
                                                 </div>
                                               </div>
                                               </li>
                                               <li class="pt-2 pb-0">
                                                  DL Number : ........
                                               </li>
                                             </div>
                                             <div className="col-md-3 mb-4">
                                               <li class="pt-2 pb-0">
                                                <small class="text-dark">  PAN Photo </small>
                                               </li>
                                               <li class="mt-2">
                                               <div style={thumb}>
                                                 <div style={thumbInner}>
                                                  <img src={""} style={img} alt="" />
                                                 </div>
                                               </div>
                                               </li>
                                               <li class="pt-2 pb-0">
                                                  PAN Number : ........
                                               </li>
                                             </div>
                                             <div className="col-md-3 mb-4">
                                               <li class="pt-2 pb-0">
                                                <small class="text-dark">  Aadhar Photo </small>
                                               </li>
                                               <li class="mt-2">
                                               <div style={thumb}>
                                                 <div style={thumbInner}>
                                                  <img src={""} style={img} alt="" />
                                                 </div>
                                               </div>
                                               </li>
                                               <li class="pt-2 pb-0">
                                                  Aadhar Number : ........
                                               </li>
                                             </div>
                                          </div>
                                          </ul>
                                       </div>
                                       </div>
                                      </>
                                      :
                                      <>
                        <form class="tab-pane show active" id="bottom-justified-tab4" onSubmit={e=>console.log(5)}>
                          <div className="row">
                             <h4>Add New Driver</h4>
                                  <div class="col-md-3">
                                    <CssTextField 
                                      fullWidth
                                      id="totalLand" 
                                      label="Name" 
                                      variant="standard"
                                      name="totalLand"
                                    //   value={farmer.totalLand}
                                    //   onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})} 
                                    />                                          
                                  </div>
                                  <div class="col-md-3">
                                    <CssTextField 
                                      fullWidth
                                      id="totalLand" 
                                      label="Mobile Number" 
                                      variant="standard"
                                      name="totalLand"
                                    //   value={farmer.totalLand}
                                    //   onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})} 
                                    />                                          
                                  </div>
                                  <div class="col-md-3">
                                    <CssTextField 
                                      fullWidth
                                      id="totalLand" 
                                      label="Email ID" 
                                      variant="standard"
                                      name="totalLand"
                                    //   value={farmer.totalLand}
                                    //   onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})} 
                                    />                                          
                                  </div>
                           </div> 
                           <div className="row">
                              <div className="col-md-3">
                               <InputLabel id="demo-simple-select-standard-label" className="mt-2 mb-2">Driver Photo</InputLabel>
                               <div style={thumb}>
                                 <div style={thumbInner}>
                                 <img src={""} style={img} alt="" />
                               </div>
                              </div>
                             </div>
                           </div>
                           <div className="row">
                              <div className="col-md-3">
                               <InputLabel id="demo-simple-select-standard-label" className="mt-2 mb-2"> DL Photo</InputLabel>
                               <div style={thumb}>
                                 <div style={thumbInner}>
                                 <img src={""} style={img} alt="" />
                               </div>
                              </div>
                             </div>
                              <div className="col-md-3 d-flex align-items-end">
                               <CssTextField 
                                 fullWidth
                                 id="totalLand" 
                                 label="DL Number" 
                                 variant="standard"
                                 name="totalLand"
                                 //   value={farmer.totalLand}
                                 //   onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})} 
                               />    
                             </div>
                           </div>
                           <div className="row">
                              <div className="col-md-3">
                               <InputLabel id="demo-simple-select-standard-label" className="mt-2 mb-2"> PAN Photo</InputLabel>
                               <div style={thumb}>
                                 <div style={thumbInner}>
                                 <img src={""} style={img} alt="" />
                               </div>
                              </div>
                             </div>
                              <div className="col-md-3 d-flex align-items-end">
                               <CssTextField 
                                 fullWidth
                                 id="totalLand" 
                                 label="PAN Number" 
                                 variant="standard"
                                 name="totalLand"
                                 //   value={farmer.totalLand}
                                 //   onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})} 
                               />    
                             </div>
                           </div>
                           <div className="row">
                              <div className="col-md-3">
                               <InputLabel id="demo-simple-select-standard-label" className="mt-2 mb-2"> Aadhar Photo</InputLabel>
                               <div style={thumb}>
                                 <div style={thumbInner}>
                                 <img src={""} style={img} alt="" />
                               </div>
                              </div>
                             </div>
                              <div className="col-md-3 d-flex align-items-end">
                               <CssTextField 
                                 fullWidth
                                 id="totalLand" 
                                 label="Aadhar Number" 
                                 variant="standard"
                                 name="totalLand"
                                 //   value={farmer.totalLand}
                                 //   onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})} 
                               />    
                             </div>
                              <div class="col-md-12 text-end mt-2 mb-2">
                                <button type="submit" class=" w-10 btn btn-primary">Submit</button>
                              </div>
                           </div>

                         </form>
                                      </>
                                      }
                                      </div>
                                     </div>
                                    </div>
                                 </div> */}

                                                    {/* Vehicle Details */}
                                                    <div
                                                        class="tab-pane"
                                                        id="bottom-tab5"
                                                    >
                                                        <VehicleDetails
                                                            value={Detailsvalue}
                                                        />
                                                    </div>

                                                    {/* Address Details */}
                                                    <div
                                                        class="tab-pane"
                                                        id="bottom-tab6"
                                                    >
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="card-body card-body_1">
                                                                    <div className="row">
                                                                        <div class="col-md-12 text-end justify-space-between mb-4"></div>
                                                                    </div>
                                                                    <div
                                                                        class="card"
                                                                        style={{
                                                                            backgroundColor:
                                                                                '#f3f3f3 ',
                                                                        }}
                                                                    >
                                                                        <div class="card-body">
                                                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                                                <span>
                                                                                    Address
                                                                                </span>
                                                                                <a
                                                                                    href="#"
                                                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                                                >
                                                                                    <span class="material-icons-outlined mr-3">
                                                                                        edit
                                                                                    </span>
                                                                                    <span class="material-icons-outlined">
                                                                                        delete
                                                                                    </span>
                                                                                </a>
                                                                            </h5>
                                                                            <ul class="list-unstyled mb-0 store_list">
                                                                                <div className="row">
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                State{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.state
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                District{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.district
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                Sub
                                                                                                District{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.subDistrict
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                {' '}
                                                                                                Village{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.village
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                {' '}
                                                                                                Pincode{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.pincode
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                {' '}
                                                                                                Address{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.fullAddress
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-12 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <h5 class="card-title d-flex justify-content-between mt-4">
                                                                                                <span>
                                                                                                    Location
                                                                                                </span>
                                                                                                <a
                                                                                                    href="#"
                                                                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                >
                                                                                                    <span class="material-icons-outlined">
                                                                                                        edit
                                                                                                    </span>
                                                                                                </a>
                                                                                            </h5>
                                                                                        </li>
                                                                                        <li>
                                                                                            <Map2
                                                                                                center={{
                                                                                                    lat: +Details.lat,
                                                                                                    lng: +Details.long,
                                                                                                }}
                                                                                                height="300px"
                                                                                                zoom={
                                                                                                    15
                                                                                                }
                                                                                            />
                                                                                            {/* <a class="store-avatar" style={mapThumb}>
                                                <div class="store_display">
                                                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15318.518229107614!2d80.4303255!3d16.2907457!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3d93323686c7ea15!2sSRI%20HANUMAN%20TRADERS!5e0!3m2!1sen!2sin!4v1636515519444!5m2!1sen!2sin" width="100%" height="250" allowfullscreen="" loading="lazy"></iframe>
                                                </div>
                                              </a> */}
                                                                                        </li>
                                                                                    </div>
                                                                                </div>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Settings
