import React, { useState, useEffect } from 'react'
import { CssTextField } from '../../../Styles'
import InpCategories from './ProductInpCategory'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from 'react-select'
import MenuItem from '@mui/material/MenuItem'
import InputInventory from './ProductInventory'
import Menu from '@mui/material/Menu'
import Fade from '@mui/material/Fade'
import { API } from '../../../../API/index'
import Swal from 'sweetalert2'
import { useDropzone } from 'react-dropzone'
import Button from '@mui/material/Button'
import axios from 'axios'
// import { storage } from '../../../../firebase/index';

import ProductInventoryTable from './ProductInventoryTable'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import {
    thumbsContainerState,
    thumb,
    img,
    thumbInner,
    baseStyle,
    driverDP,
    sectionDiv,
    uploadBtnSm,
    panImg,
} from '../../../Styles'
import { Api } from '@mui/icons-material'

import { styled } from '@mui/material/styles'

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'
import { Grid } from '@mui/material'
import ProductVariantTable from './productvariantTable'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyBCgGkH7S3OL-fNKGnQmfMQzbJyY9AZ66o',
    authDomain: 'krishivan-app.firebaseapp.com',
    projectId: 'krishivan-app',
    storageBucket: 'krishivan-app.appspot.com',
    messagingSenderId: '1018772914539',
    appId: '1:1018772914539:web:3f3d750142694da0a74c2e',
    measurementId: 'G-CMCSVD54TQ',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const storage = getStorage(app)

// import ProductInputTable from '../Prod'
// import ProductInventoryTable from './ProductInventoryTable'

const inputClear = {
    title: 'ProductCatelog',
    catelogName: 'InputSupplierCatelog',
    categoryName: '',
}
const Subclear = {
    title: 'ProductCatelog',
    catelogName: 'InputSupplierCatelog',
    CategoryName: '',
    subCategoryName: '',
}
const BrandClear = {
    catalogue: 'inputSupplier',
    categoryName: '',
    subCategoryName: '',
    imageUrl: '',
    brandName: '',
    brandImage: '',
}

const ProductClear = {
    catalogue: 'inputSupplier',
    categoryName: '',
    subCategoryName: '',
    brandName: '',
    productImage: '',
    productName: '',
}
const VariantClear = {
    title: 'ProductCatelog',
    catelogName: 'InputSupplierCatelog',
    categoryName: '',
    subCategoryName: '',
    brandName: '',
    brandImage: '',
    productName: '',
    productImage: '',
    quantity: '',
    unit: '',
}

const Input = styled('input')({
    display: 'none',
    marginBottom: '16px',
    marginTop: '16px',
})

function InputCatelog() {
    const catalogue = 'inputSupplier'
    const [categorylist, setCategorylist] = useState([])
    // const [categoryoptions,setCategoryOptions]=useState([])
    const [subcategorylist, setsubCategorylist] = useState([])
    const [showCategory, setShowCategory] = useState(false)
    const [createProduct, setCreateProduct] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [brandlist, setbrandlist] = useState([])
    const [productlist, setProductlist] = useState([])
    const [categoryname, setcategoryName] = useState('')
    const [subcategoryName, setsubcategoryName] = useState('')
    const [list, setlist] = useState([])
    const [catlist, setCatlist] = useState('')
    const [BrandName, setBrandName] = useState('')
    const [ProductInput, setProductInput] = useState([])
    const [inputBooking, setInputBooking] = useState({
        MicroEnterpreneurId: '',
        farmerId: '',
        createdBy: 'Admin',
        firm: '',
        modeOfBooking: '',
        category: '',
        bookedBy: 'MI',
    })
    const [QuantityARR, setQuantityArr] = useState({
        catalogue: 'inputSupplier',
        categoryName: '',
        subCategoryName: '',
        brandName: '',
        productName: '',
        productImage: '',
        quantity: '',
        unit: '',
    })
    const [formValues, setFormValues] = useState([
        {
            subCategory: '',
            brand: '',
            product: '',
            productImage: '',
            quantity: '',
            unit: '',
            price: '',
            stock: '',
            No: '',
            totalPrice: '',
        },
    ])
    const [variantTableData, setVariantTableData] = React.useState([])
    const viewVariants = () => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }

        var data = {
            catalogue: 'inputSupplier',
            mobile_number: localStorage.getItem('farmerid'),
        }
        axios
            .post(
                'https://prod-api.krishione.com/farmer-function/product-getinventory',
                data,
                config
            )
            .then((res) => setVariantTableData(res.data.data))
            .catch((err) => console.log(err))
    }
    const [driverImg, setDriverImg] = useState(null)
    const [BrandImg, setBrandImg] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    useEffect(() => {
        // getState();
        getCategoryList()
        getProductInput()
        viewVariants()
    }, [])
    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleCloseclear = () => {
        // setAnchorEl(null);
        handleClose()
        setDriverImg(null)
        setBrandImg(null)
    }
    const getProductInput = async () => {
        var farmerid = localStorage.getItem('Traderid')
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const db = {
            catalogue: 'inputSupplier',
            //   "mobile_number": "5555555555"
        }
        // const { data } = await API.get(`/getProductCatelog?&title=ProductCatelog&catelogName=InputSupplierCatelog&type=2`);
        axios
            .post(
                'https://prod-api.krishione.com/farmer-function/get-all-products',
                db,
                config
            )
            .then((res) => {
                console.log('test', res.data.data)
                setProductInput(res?.data?.product)
            })
    }
    const [InputProduct, setInputProduct] = useState({
        catalogue: 'inputSupplier',
        categoryName: '',
    })
    const [SubProduct, setSubProduct] = useState({
        catalogue: 'inputSupplier',
        categoryName: '',
        subCategoryName: '',
    })
    const [driverLicense, setDriverLicense] = useState([])
    const [BrandProduct, setBrandProduct] = useState({
        catalogue: 'inputSupplier',
        categoryName: '',
        subCategoryName: '',
        imageUrl: '',
        brandName: '',
        brandImage: '',
    })
    const [driverLicenses, setDriverLicenses] = useState([])
    const [CommissionProduct, setCommissionProduct] = useState({
        catalogue: 'inputSupplier',
        categoryName: '',
        subCategoryName: '',
        brandName: '',
        productName: '',
        productImage: '',
    })

    const [VariantProduct, setVariantProduct] = useState({
        title: 'ProductCatelog',
        catelogName: 'InputSupplierCatelog',
        categoryName: '',
        subCategoryName: '',
        brandName: '',
        productName: '',
        quantity: '',
    })

    const handleInputProduct = async () => {
        console.log('InputProduct', InputProduct)

        try {
            // const { data } = await API.post('/createProductCategory ', InputProduct);
            var token = localStorage.getItem('token')
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            }
            const data = await axios
                .post(
                    'https://prod-api.krishione.com/farmer-function/category',
                    InputProduct,
                    config
                )
                .then((data) => {
                    console.log('category in input S: ', data)
                    Swal.fire({
                        icon: 'success',
                        iconColor: '#28a745',
                        title: data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                    console.log(data)
                    getCategoryList()
                    setInputProduct(inputClear)
                })
                .catch((err) => {
                    console.log(err)
                    setInputProduct(inputClear)
                    Swal.fire({
                        icon: 'warning',
                        iconColor: '#dc3545',
                        title: data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                })
        } catch (err) {
            setInputProduct(inputClear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handlesubCategoryName = async () => {
        console.log('SubProduct', SubProduct)

        try {
            var token = localStorage.getItem('token')
            var config = {
                headers: { Authorization: `Bearer ${token}` },
            }
            await axios
                .post(
                    `https://prod-api.krishione.com/farmer-function/subcategory`,
                    SubProduct,
                    config
                )
                .then((data) => {
                    console.log('sub cat:', data)
                    Swal.fire({
                        icon: 'success',
                        iconColor: '#28a745',
                        title: data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                    console.log(data)
                    getCategoryList()
                    setSubProduct(Subclear)
                })
                .catch((data) => {
                    console.log(data)
                    setSubProduct(Subclear)
                    Swal.fire({
                        icon: 'warning',
                        iconColor: '#dc3545',
                        title: data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                })
        } catch (err) {
            setSubProduct(Subclear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handleBrandProduct = async () => {
        console.log('BrandProduct', BrandProduct)

        try {
            // const { data } = await API.post('/createProductBrand',BrandProduct);
            // if(data?.success){
            //  Swal.fire({
            //       icon: 'success',
            //       iconColor: '#28a745',
            //       title: data?.message,
            //       showConfirmButton: false,
            //       timer: 2000
            //     });
            //  console.log(data);

            //  setBrandProduct(BrandClear)
            //  setBrandImg(null);
            //  setDriverImg(null);
            // } else {
            //    setBrandProduct(BrandClear)
            //  Swal.fire({
            //    icon: 'warning',
            //    iconColor: '#dc3545',
            //    title: data?.message,
            //    showConfirmButton: false,
            //    timer: 2000
            //    });
            // }
            var token = localStorage.getItem('token')
            var config = {
                headers: { Authorization: `Bearer ${token}` },
            }
            await axios
                .post(
                    `https://prod-api.krishione.com/farmer-function/brand`,
                    BrandProduct,
                    config
                )
                .then((data) => {
                    console.log('brand in input', data)
                    Swal.fire({
                        icon: 'success',
                        iconColor: '#28a745',
                        title: data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                    setBrandProduct(BrandClear)
                    setBrandImg(null)
                    setDriverImg(null)
                })
                .catch((data) => {
                    Swal.fire({
                        icon: 'warning',
                        iconColor: '#dc3545',
                        title: data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                })
        } catch (err) {
            setBrandProduct(BrandClear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handleCommissionProduct = async () => {
        console.log('CommissionProduct', CommissionProduct)

        try {
            // const { data } = await API.post('/createProductCatelogProduct ', CommissionProduct);
            // if(data?.success){
            //  Swal.fire({
            //       icon: 'success',
            //       iconColor: '#28a745',
            //       title: data?.message,
            //       showConfirmButton: false,
            //       timer: 2000
            //     });
            //  console.log(data);

            //  setCommissionProduct(ProductClear)
            //  setBrandImg(null);
            //  setDriverImg(null);
            // } else {
            //    setCommissionProduct(ProductClear)
            //  Swal.fire({
            //    icon: 'warning',
            //    iconColor: '#dc3545',
            //    title: data?.message,
            //    showConfirmButton: false,
            //    timer: 2000
            //    });
            // }
            var token = localStorage.getItem('token')
            var config = {
                headers: { Authorization: `Bearer ${token}` },
            }
            await axios
                .post(
                    `https://prod-api.krishione.com/farmer-function/add-product`,
                    CommissionProduct,
                    config
                )
                .then((data) => {
                    console.log('brand in input', data)
                    Swal.fire({
                        icon: 'success',
                        iconColor: '#28a745',
                        title: data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                    setCommissionProduct(ProductClear)
                })
                .catch((data) => {
                    Swal.fire({
                        icon: 'warning',
                        iconColor: '#dc3545',
                        title: data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                })
        } catch (err) {
            setCommissionProduct(ProductClear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handleVariantProduct = async () => {
        console.log('VariantProduct', QuantityARR)

        try {
            // const { data } = await API.post('/createProductCatelogQuantity', QuantityARR);
            // if(data?.success){
            //  Swal.fire({
            //       icon: 'success',
            //       iconColor: '#28a745',
            //       title: data?.message,
            //       showConfirmButton: false,
            //       timer: 2000
            //     });
            //  console.log(data);

            //  setQuantityArr(VariantClear)
            // } else {
            //   setQuantityArr(VariantClear)
            //  Swal.fire({
            //    icon: 'warning',
            //    iconColor: '#dc3545',
            //    title: data?.message,
            //    showConfirmButton: false,
            //    timer: 2000
            //    });
            // }
            var token = localStorage.getItem('token')
            var config = {
                headers: { Authorization: `Bearer ${token}` },
            }
            await axios
                .post(
                    `https://prod-api.krishione.com/farmer-function/createProductCatelogQuantity`,
                    QuantityARR,
                    config
                )
                .then((data) => {
                    console.log('quantity', data)
                    Swal.fire({
                        icon: 'success',
                        iconColor: '#28a745',
                        title: data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                    setQuantityArr(ProductClear)
                })
                .catch((data) => {
                    Swal.fire({
                        icon: 'warning',
                        iconColor: '#dc3545',
                        title: data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                })
        } catch (err) {
            setQuantityArr(VariantClear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    // const getCategoryList = async () => {

    //   var token = localStorage.getItem("token");
    //   var config = {
    //     headers: { 'Authorization': `Bearer ${token}` }
    //   };
    //   await axios.get(`https://prod-api.krishione.com/farmer-function/category/${catalogue}`, config)
    //     .then(res => {
    //       console.log("d :", res.data.category);
    //       setCategorylist(res.data.category);
    //     })
    // }
    const handleCategoryChange = (e) => {
        // setSubLivestockProduct({...SubLivestockProduct,categoryName:e.target.value})
        setBrandProduct({ ...BrandProduct, categoryName: e.target.value })
        getSubCategoryList(e.target.value)
        setcategoryName(e.target.value)
    }
    const getSubCategoryList = async (val) => {
        // const { data } = await API.get(`/getProductSubCategory?type=0&title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=`+val);
        // console.log("hkjjhj",data)
        // setsubCategorylist(data?.result);
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        console.log(InputProduct)
        console.log(SubProduct)
        const subCat = {
            catalogue: catalogue,
            categoryName: val,
        }
        await axios
            .post(
                `https://prod-api.krishione.com/farmer-function/get-subcategory`,
                subCat,
                config
            )
            .then((res) => {
                console.log(
                    'get sub cat in input catalogue',
                    res.data.subcategory
                )
                setsubCategorylist(res.data.subcategory)
            })
    }
    const handleCategoryChangeProduct = (e) => {
        // setCommissionLivestockProduct({...CommissionLivestockProduct, categoryName:e.target.value})
        setCommissionProduct({
            ...CommissionProduct,
            categoryName: e.target.value,
        })
        getSubCategoryList(e.target.value)
        setcategoryName(e.target.value)
    }

    const handlechangeproductBrand = (e) => {
        console.log(e.target.value, 'e')
        setCommissionProduct({
            ...CommissionProduct,
            subCategoryName: e.target.value,
        })
        getBrandList(e.target.value)
    }

    const getBrandList = async (val) => {
        // const { data } = await API.get(`/getProductBrand?type=0&title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=`+categoryName+'&subCategoryName='+val);
        // console.log("hkjjhj",data)
        // setbrandlist(data?.result);
        let forGetBrands = {
            categoryName: CommissionProduct.categoryName,
            subCategoryName: val,
            catalogue: catalogue,
        }
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .post(
                `https://prod-api.krishione.com/farmer-function/get-brand`,
                forGetBrands,
                config
            )
            .then((data) => {
                console.log('brand in input', data)
                setbrandlist(data.data.brand)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleCategoryChangeVarient = (e) => {
        // setLivestockVariantProduct({...LivestockVariantProduct,categoryName:e.target.value})
        setQuantityArr({ ...QuantityARR, categoryName: e.target.value })
        getSubCategoryList(e.target.value)
        setcategoryName(e.target.value)
    }

    const handlechangeproductVarientBrand = (e) => {
        console.log(e.target.value, 'e')
        // setLivestockVariantProduct({...LivestockVariantProduct,subCategoryName:e.target.value})
        setQuantityArr({ ...QuantityARR, subCategoryName: e.target.value })
        getBrandListVarient(e.target.value)
        setsubcategoryName(e.target.value)
    }
    const getBrandListVarient = async (val) => {
        // const { data } = await API.get(`/getProductBrand?type=0&title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=`+categoryName+'&subCategoryName='+val);
        // console.log("hkjjhj",data)
        // setbrandlist(data?.result);
        let forGet = {
            categoryName: QuantityARR.categoryName,
            subCategoryName: val,
            catalogue: catalogue,
        }
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .post(
                `https://prod-api.krishione.com/farmer-function/get-brand`,
                forGet,
                config
            )
            .then((data) => {
                console.log('brand in input', data)
                setbrandlist(data.data.brand)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handlechangeproductVarientProduct = (e) => {
        // setLivestockVariantProduct({...LivestockVariantProduct,brandName:e.target.value})
        setQuantityArr({ ...QuantityARR, brandName: e.target.value })
        getProductListVarient(e.target.value)
    }
    const getProductListVarient = async (val) => {
        // const { data } = await API.get(`/getProductCatelogProduct?type=3&title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=`+categoryName+'&subCategoryName='+subcategoryName+'&brandName='+val);
        // console.log("hkjjhj",data)
        // setProductlist(data?.result);
        let forGet = {
            categoryName: QuantityARR.categoryName,
            subCategoryName: QuantityARR.subCategoryName,
            brandName: val,
            catalogue: catalogue,
        }
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .post(
                `https://prod-api.krishione.com/farmer-function/get-product`,
                forGet,
                config
            )
            .then((data) => {
                console.log('product', data)
                setProductlist(data.data.product)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleView = () => {
        setShowCategory(true)
        getProductInput()
    }
    // brand
    const BrandUpload = () => {
        const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
            accept: 'image/',
            onDrop: (acceptedFiles) => {
                setDriverLicense(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )
                )
            },
        })

        return (
            <section className="container" style={baseStyle}>
                <div
                    {...getRootProps({ className: 'dropzone' })}
                    style={panImg}
                >
                    <input {...getInputProps()} multiple={false} />
                    {driverLicense.length > 0 ? (
                        <aside style={thumbsContainerState}>
                            <div style={thumb}>
                                <div style={thumbInner}>
                                    <img
                                        src={driverLicense[0].preview}
                                        style={img}
                                        alt="brand"
                                    />
                                </div>
                            </div>
                        </aside>
                    ) : (
                        <p align="center">
                            Drop Image here or click to upload.
                        </p>
                    )}
                </div>
            </section>
        )
    }
    const handleUploadDL = (e) => {
        const doc = driverLicense[0]
        if (doc) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        setBrandProduct({
                            ...BrandProduct,
                            drivingLicensePhoto: url,
                        })
                    })
                }
            )
        }
    }

    // product

    const ProductUpload = () => {
        const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
            accept: 'image/',
            onDrop: (acceptedFiles) => {
                setDriverLicenses(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )
                )
            },
        })

        return (
            <section className="container" style={baseStyle}>
                <div
                    {...getRootProps({ className: 'dropzone' })}
                    style={panImg}
                >
                    <input {...getInputProps()} multiple={false} />
                    {driverLicenses.length > 0 ? (
                        <aside style={thumbsContainerState}>
                            <div style={thumb}>
                                <div style={thumbInner}>
                                    <img
                                        src={driverLicenses[0].preview}
                                        style={img}
                                        alt="product"
                                    />
                                </div>
                            </div>
                        </aside>
                    ) : (
                        <p align="center">
                            Drop Image here or click to upload.
                        </p>
                    )}
                </div>
            </section>
        )
    }
    const handleUploadDLs = (e) => {
        const doc = driverLicenses[0]
        if (doc) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        setCommissionProduct({
                            ...CommissionProduct,
                            productName: url,
                        })
                    })
                }
            )
        }
    }
    const handleUploadDP = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setDriverImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        setBrandProduct({ ...BrandProduct, brandImage: url })
                    })
                }
            )
        }
    }
    const handleUploadIP = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setBrandImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        setCommissionProduct({
                            ...CommissionProduct,
                            productImage: url,
                        })
                    })
                }
            )
        }
    }
    const getCategoryList = async () => {
        console.log('called')
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .get(
                `https://prod-api.krishione.com/farmer-function/category/inputSupplier`,
                config
            )
            .then((res) => {
                console.log('d :', res.data.category)
                setCategorylist(res.data.category)
                console.log(categorylist, 'lists')
            })
    }

    const categoryoption = categorylist.map((item, ind) => ({
        value: item.categoryName,
        label: item.categoryName,
    }))
    console.log(categoryoption, 'options')

    const getsubCategoryList = async (val) => {
        // const { data } = await API.get(`/getProductSubCategory?type=0&title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=`+val);
        // console.log("hkjjhj",data)
        // setsubCategorylist(data?.result);
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        setcategoryName(val)
        const subCat = {
            catalogue: 'inputSupplier',
            categoryName: val,
        }

        await axios
            .post(
                `https://prod-api.krishione.com/farmer-function/get-subcategory`,
                subCat,
                config
            )
            .then((res) => {
                console.log(
                    'get sub cat in input catalogue',
                    res.data.subcategory
                )
                setsubCategorylist(res.data.subcategory)
            })
    }

    const subcategoryoption = subcategorylist.map((item, ind) => ({
        value: item.subCategoryName,
        label: item.subCategoryName,
    }))

    const getbrandList = async (val) => {
        // const { data } = await API.get(`/getProductBrand?type=0&title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=`+categoryName+'&subCategoryName='+val);
        // console.log("hkjjhj",data)
        // setbrandlist(data?.result);
        let forGet = {
            categoryName: categoryname,
            subCategoryName: val,
            catalogue: 'inputSupplier',
        }
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .post(
                `https://prod-api.krishione.com/farmer-function/get-brand`,
                forGet,
                config
            )
            .then((data) => {
                console.log('brand in input', data)
                setbrandlist(data.data.brand)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const brandoption = brandlist.map((item, ind) => ({
        value: item.brandName,
        label: item.brandName,
    }))
    // product
    // const getproductList = async (val) => {

    //     let forGet = {
    //         "categoryName": categoryname,
    //         "subCategoryName": subcategoryName,
    //         "catalogue": "inputSupplier",
    //         "brandName":val,
    //         "mobile_number": localStorage.getItem('mobile')

    //       };
    //       var token = localStorage.getItem("token");
    //       var config = {
    //         headers: { 'Authorization': `Bearer ${token}` }
    //       };
    //       await axios.post(`https://prod-api.krishione.com/farmer-function/get-fltered-products`, forGet, config)
    //         .then(data => {
    //           console.log("brand in input", data);
    //           setProductlist(data.data.product)
    //         })
    //         .catch(err => {
    //           console.log(err)
    //         })

    // }

    const handlecategory = (e) => {
        setcategoryName(e.value)
        setInputBooking({ ...inputBooking, category: e.value })
        getsubCategoryList(e.value)
        // setFormValues(formClear)
    }
    const handlesubcategory = (i, e) => {
        getbrandList(e.value)
        setsubcategoryName(e.value)
        let newFormValues = [...formValues]
        newFormValues[i]['subCategory'] = e.value
        newFormValues[i]['brand'] = ''
        newFormValues[i]['product'] = ''
        newFormValues[i]['quantity'] = ''
        newFormValues[i]['unit'] = ''
        newFormValues[i]['price'] = ''
        newFormValues[i]['totalPrice'] = ''
        newFormValues[i]['No'] = ''
        newFormValues[i]['stock'] = ''
        setFormValues(newFormValues)
    }
    const handleBrandcategory = (i, e) => {
        getproductList(e.value)
        setBrandName(e.value)
        let newFormValues = [...formValues]
        newFormValues[i]['brand'] = e.value
        newFormValues[i]['product'] = ''
        newFormValues[i]['quantity'] = ''
        newFormValues[i]['unit'] = ''
        newFormValues[i]['price'] = ''
        newFormValues[i]['totalPrice'] = ''
        newFormValues[i]['No'] = ''
        newFormValues[i]['stock'] = ''
        setFormValues(newFormValues)
    }

    const getproductList = async (val) => {
        let forGet = {
            categoryName: categoryname,
            subCategoryName: subcategoryName,
            catalogue: 'inputSupplier',
            brandName: val,
            // "mobile_number": localStorage.getItem('mobile')
        }
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .post(
                `https://prod-api.krishione.com/farmer-function/get-product`,
                forGet,
                config
            )
            .then((data) => {
                console.log('brand in input', data)
                setProductInput(data.data.product)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    return (
        <>
            <div class="row align-items-center mb-3">
                <div class="col">
                    {createProduct ? (
                        <h3 class="page-title">Input Supplier Inventory</h3>
                    ) : (
                        <h3 class="page-title">Input Supplier Catalogue</h3>
                    )}
                </div>
                {createProduct ? (
                    <div class="col-auto">
                        <button
                            class="btn btn-primary filter-btn"
                            onClick={(e) => setCreateProduct(false)}
                        >
                            <i class="fas fa-left"></i> <span> Back</span>
                        </button>
                    </div>
                ) : (
                    <>
                        {!showCategory ? (
                            <>
                                <div className="col-auto">
                                    <button
                                        class="btn btn-primary filter-btn"
                                        id="fade-button"
                                        aria-controls="fade-menu"
                                        aria-haspopup="true"
                                        aria-expanded={
                                            open ? 'true' : undefined
                                        }
                                        onClick={handleClick}
                                    >
                                        <i class="fas fa-plus"></i>{' '}
                                        <span> Create </span>
                                    </button>
                                    <Menu
                                        id="fade-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'fade-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        TransitionComponent={Fade}
                                    >
                                        {/* <MenuItem onClick={handleClose} id="openCategory">Category</MenuItem>
                    <MenuItem onClick={handleClose} id="openSubCategory">SubCategory</MenuItem>
                    <MenuItem onClick={handleCloseclear} id="openBrand">Brand</MenuItem>
                    <MenuItem onClick={handleCloseclear} id="openProduct">Product</MenuItem> */}
                                        <MenuItem
                                            onClick={handleClose}
                                            id="openVariant"
                                        >
                                            Quantity & Unit
                                        </MenuItem>
                                        <MenuItem
                                            onClick={(e) => {
                                                setCreateProduct(true)
                                                handleClose()
                                            }}
                                        >
                                            Inventory
                                        </MenuItem>
                                    </Menu>
                                </div>
                                <div class="col-auto">
                                    <button
                                        class="btn btn-primary filter-btn"
                                        onClick={() => handleView()}
                                        //  onClick={e=>setShowCategory(true)}
                                    >
                                        <i class="fas fa-eye"></i>{' '}
                                        <span> View Category</span>
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div class="col-auto">
                                <button
                                    class="btn btn-primary filter-btn"
                                    onClick={(e) => setShowCategory(false)}
                                >
                                    <i class="fas fa-left"></i>{' '}
                                    <span> Back</span>
                                </button>
                            </div>
                        )}
                    </>
                )}
            </div>

            <div class="row">
                <Grid item xs={8}>
                    <div
                        style={{
                            padding: '30px 0',
                            display: 'flex',
                            width: '500px',
                            justifyContent: 'space-between',
                            marginBottom: '60px',
                            flexWrap: 'wrap',
                        }}
                    >
                        <div>
                            <FormControl variant="standard" className="mt-3">
                                <Select
                                    style={{
                                        width: '270px',
                                        height: '40px',
                                    }}
                                    placeholder={'Select Category'}
                                    onChange={(e) => handlecategory(e)}
                                    options={categoryoption}
                                />
                            </FormControl>
                        </div>

                        {formValues.map((element, index) => {
                            return (
                                <>
                                    <div>
                                        {console.log('input', formValues)}
                                        <FormControl
                                            variant="standard"
                                            className="mt-3"
                                        >
                                            <Select
                                                style={{
                                                    width: '250px',
                                                    height: '40px',
                                                }}
                                                placeholder={
                                                    'Select Sub Category'
                                                }
                                                onChange={(e) =>
                                                    handlesubcategory(index, e)
                                                }
                                                options={subcategoryoption}
                                            />
                                        </FormControl>
                                    </div>

                                    <div>
                                        <FormControl
                                            variant="standard"
                                            className="mt-3"
                                        >
                                            <Select
                                                style={{
                                                    width: '250px',
                                                    height: '40px',
                                                }}
                                                placeholder={'Select Brand'}
                                                onChange={(e) =>
                                                    handleBrandcategory(
                                                        index,
                                                        e
                                                    )
                                                }
                                                options={brandoption}
                                            />
                                        </FormControl>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </Grid>
                <div class="col-sm-12">
                    {/* <h3 className="mb-3">Input Supplier Products</h3> */}
                    <div class="card card-table">
                        <div class="card-body">
                            <div class="table-responsive">
                                <div
                                    id="DataTables_Table_0_wrapper"
                                    class="dataTables_wrapper dt-bootstrap4 no-footer"
                                >
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div
                                                class="dataTables_length"
                                                id="DataTables_Table_0_length"
                                            >
                                                <label>
                                                    Show
                                                    <select
                                                        name="DataTables_Table_0_length"
                                                        aria-controls="DataTables_Table_0"
                                                        class="custom-select custom-select-sm form-control form-control-sm"
                                                    >
                                                        <option value="10">
                                                            10
                                                        </option>
                                                        <option value="25">
                                                            25
                                                        </option>
                                                        <option value="50">
                                                            50
                                                        </option>
                                                        <option value="100">
                                                            100
                                                        </option>
                                                    </select>
                                                    entries
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 position_right">
                                            <div class="form-group">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Search"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row" style={{ margin: '2%' }}>
                                        <ProductInventoryTable
                                            inputs={ProductInput}
                                            catelogname="inputSupplier"
                                        />
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-12 col-md-5">
                                            <div
                                                class="dataTables_info"
                                                id="DataTables_Table_0_info"
                                                role="status"
                                                aria-live="polite"
                                            >
                                                Showing 1 to 10 of 12 entries
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-7">
                                            <div
                                                class="dataTables_paginate paging_simple_numbers"
                                                id="DataTables_Table_0_paginate"
                                            >
                                                <ul class="pagination">
                                                    <li
                                                        class="paginate_button page-item previous disabled"
                                                        id="DataTables_Table_0_previous"
                                                    >
                                                        <a
                                                            href="#"
                                                            aria-controls="DataTables_Table_0"
                                                            data-dt-idx="0"
                                                            tabindex="0"
                                                            class="page-link"
                                                        >
                                                            Previous
                                                        </a>
                                                    </li>
                                                    <li class="paginate_button page-item active">
                                                        <a
                                                            href="#"
                                                            aria-controls="DataTables_Table_0"
                                                            data-dt-idx="1"
                                                            tabindex="0"
                                                            class="page-link"
                                                        >
                                                            1
                                                        </a>
                                                    </li>
                                                    <li class="paginate_button page-item ">
                                                        <a
                                                            href="#"
                                                            aria-controls="DataTables_Table_0"
                                                            data-dt-idx="2"
                                                            tabindex="0"
                                                            class="page-link"
                                                        >
                                                            2
                                                        </a>
                                                    </li>
                                                    <li
                                                        class="paginate_button page-item next"
                                                        id="DataTables_Table_0_next"
                                                    >
                                                        <a
                                                            href="#"
                                                            aria-controls="DataTables_Table_0"
                                                            data-dt-idx="3"
                                                            tabindex="0"
                                                            class="page-link"
                                                        >
                                                            Next
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
        </>
    )
}

export default InputCatelog
