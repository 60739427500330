import React from 'react'
import Switch from '@mui/material/Switch'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Input from '@mui/material/Input'

function TaskAllocation() {
    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    })

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value })
    }

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        })
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Task Allocation</h5>
                </div>
                <div class="card-body ">
                    <p>
                        Set the parameters below to change the available task
                        time and the retries count for the Drivers
                    </p>
                    <form style={{ padding: '10px' }}>
                        <div class="row form-group">
                            <div class="col-sm-7 col-md-5 mb-3">
                                <div class="row form-group">
                                    <label
                                        for="addressline1"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        Number of Retries
                                    </label>
                                    <FormControl
                                        sx={{ m: 1 }}
                                        variant="standard"
                                        color="success"
                                    >
                                        <Input
                                            id="standard-adornment-password"
                                            type={
                                                values.showPassword
                                                    ? 'text'
                                                    : 'text'
                                            }
                                            value={values.password}
                                            onChange={handleChange('password')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPassword
                                                        }
                                                        edge="end"
                                                        style={{ color: 'red' }}
                                                    >
                                                        <ClearIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPassword
                                                        }
                                                        edge="end"
                                                        color="success"
                                                    >
                                                        <CheckIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </div>
                                <div class="row form-group">
                                    <label
                                        for="addressline1"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        Task available Time (in sec)
                                    </label>
                                    <FormControl
                                        sx={{ m: 1 }}
                                        variant="standard"
                                        color="success"
                                    >
                                        <Input
                                            id="standard-adornment-password"
                                            type={
                                                values.showPassword
                                                    ? 'text'
                                                    : 'text'
                                            }
                                            value={values.password}
                                            onChange={handleChange('password')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPassword
                                                        }
                                                        edge="end"
                                                        style={{ color: 'red' }}
                                                    >
                                                        <ClearIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPassword
                                                        }
                                                        edge="end"
                                                        color="success"
                                                    >
                                                        <CheckIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </div>
                                <div class="row form-group">
                                    <label
                                        for="addressline1"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        Radius of Allocation (in km)
                                    </label>
                                    <FormControl
                                        sx={{ m: 1 }}
                                        variant="standard"
                                        color="success"
                                    >
                                        <Input
                                            id="standard-adornment-password"
                                            type={
                                                values.showPassword
                                                    ? 'text'
                                                    : 'text'
                                            }
                                            value={values.password}
                                            onChange={handleChange('password')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPassword
                                                        }
                                                        edge="end"
                                                        style={{ color: 'red' }}
                                                    >
                                                        <ClearIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPassword
                                                        }
                                                        edge="end"
                                                        color="success"
                                                    >
                                                        <CheckIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <hr />
                            <div class="row form-group">
                                <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            value="start"
                                            control={<Switch color="primary" />}
                                            label="Keep Task with the last available Driver if Drivers limit is reached"
                                            labelPlacement="start"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default TaskAllocation
