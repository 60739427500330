import React, { useState, useEffect } from 'react'
import axios from 'axios'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import { API } from '../../../API/index'
import { CssTextField } from '../../Styles'
import MenuItem from '@mui/material/MenuItem'
import Input from '@mui/material/Input'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import { storage } from '../../../firebase/index'
import TraderTable from './TraderTable'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import { thumbsContainerState, thumb, img, thumbInner } from '../../Styles'
import Button from '@mui/material/Button'
import '../Create.css'

import Selects from 'react-select'
import Swal from 'sweetalert2'
import $ from 'jquery'
import Map from '../Map'
import OtpBox from '../../../components/OtpBox'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const names = ['Seed', 'Fertilizers', 'Pesticides']

const clearInputs = {
    firstName: '',
    lastName: '',
    gender: '',
    mobileNumber: '',
    email: '',
    alternatemobile: '',
    dateOfBirth: '',
    language: '',
    partner_mode: '',
    firmName: '',
    gstNo: '',
    panNumber: '',
    adhaarNumber: '',
    typeOfCrops: [],

    state: '',
    district: '',
    subDistrict: '',
    village: '',
    pincode: '',
    fullAddress: '',

    unit: '',
    commissionAmount: '',
    category: '',

    bankAccountNumber: '',
    bankAccountName: '',
    ifscCode: '',
    bankName: '',
    bankBranchName: '',
}

function CreteTraders() {
    const [tab, setTab] = useState(1)
    const [states, setStates] = useState([])
    const [cropTypes, setCropTypes] = React.useState([])
    let licenseTypesArr = cropTypes.map((type) => ({
        name: type,
        licenseNumber: '',
        expDate: '',
        photo: '',
    }))
    console.log(licenseTypesArr)
    const [Formerinfo, setFormerinfo] = useState({})

    const [createdByid,setCreatedById]=useState('')
    const [createdByRole,setCreatedByRole]=useState("")
    const [licensePhoto, setLicensePhoto] = useState(null)
    const [inputSuppliers, setInputSuppliers] = useState([])
    const [citylist, setcitylist] = useState([])
    const [statelist, setstatelist] = useState([])
    const [CropProduct, setCropProduct] = useState([])
    const [otpWindow, setOtpWindow] = useState(false)
    const [CommisionCat, setCommisionCat] = useState([])
    const [inputs, setInputs] = useState({
        firstName: '',
        lastName: '',
        mobileNumber: '',
        email: '',
        alternatemobile: '',
        dateOfBirth: '',
        language: '',
        partner_mode: '',
        firmName: '',
        gstNo: '',
        panNumber: '',
        adhaarNumber: '',
        typeOfCrops: cropTypes,

        state: '',
        district: '',
        subDistrict: '',
        village: '',
        pincode: '',
        fullAddress: '',

        unit: '',
        commissionAmount: '',
        category: '',

        bankAccountNumber: '',
        bankAccountName: '',
        ifscCode: '',
        bankName: '',
        bankBranchName: '',
    })

    useEffect(() => {
        getState()
        getCropProduct()
        // getStateList();
        getCommisionCat()
    }, [])
    const getCommisionCat = async () => {
        const { data } = await API.get(
            `/getCommissionCatelog?title=CommissionCatelog&catelogName=InputSupplierCatelog&type=2`
        )
        // console.log("hkjjhj",data)
        setCommisionCat(data?.result)
        console.log('firm search', data)
    }
    const CommisionUnit =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.commissionUnit,
            label: item.commissionUnit,
        }))
    const CommisionAmount =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.commissionAmount,
            label: item.commissionAmount,
        }))
    const commisiontype =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.subCategoryName,
            label: item.subCategoryName,
        }))
    const getStateList = async () => {
        const { data } = await API.get(`/getStateList`)
        // console.log("hkjjhj",data)
        setstatelist(data?.result)
        console.log('firm search', data)
    }
    const statelistoption =
        statelist &&
        statelist.map((item, ind) => ({
            value: item,
            label: item,
        }))
    const statechane = (e) => {
        console.log('hkjjhj', e.value)
        // setInputs({ ...inputs, ['state']: e.value })
        setInputs({ ...inputs, ['state']: e.value })
        // onChange={(e) => setInputs({ ...inputs, ['state']: e.target.value })}
        getCityList(e.value)
    }
    const getCityList = async (val) => {
        const { data } = await API.get(`/getCityList?stateName=` + val)
        // console.log("hkjjhj",data)
        setcitylist(data?.result)
        console.log('firm search', data)
    }
    const citylistoption =
        citylist &&
        citylist.map((item, ind) => ({
            value: item,
            label: item,
        }))
    const getCropProduct = async () => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const { data } = await axios.get(
            'https://prod-api.krishione.com/farmer-function/get-crops',
            config
        )
        // console.log("hkjjhj",data)
        setCropProduct(data?.crops)
        console.log('firm search', data)
    }
    const CropProductoption =
        CropProduct &&
        CropProduct.map((item, ind) => ({
            value: item.productName,
            label: item.productName,
        }))
    const getInputSuppliers = async () => {
        const { data } = await API.get(`/getTraders?type=0`)
        setInputSuppliers(data?.result)
    }

    const getState = async () => {
        const { data } = await axios.get(
            'http://65.0.145.198:4080/api/v1/getStateList'
        )
        setStates(data?.result)
        console.log(data?.result)
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event
        setCropTypes(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    const setLicenceInputs = (e, index) => {
        console.log(index)
        let myArr = [...licenseTypesArr]
        myArr[index][e.target.name] = e.target.value
        console.log(licenseTypesArr)
    }

    const handleUploadDP = (e, index) => {
        let myArr = [...licenseTypesArr]
        setLicensePhoto(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `licensePhoto/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                async () => {
                    getDownloadURL(storageRef).then((url) => {
                        //  myArr[index]['name'] = url;
                        // alert(index)
                        // console.log(url);
                    })
                }
            )
        }
    }

    const onSubmitInputs = async (e) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        let value = { ...inputs, typeOfCrops: cropTypes }
        const dataForOtp = {
            mobile_number: value.mobileNumber,
            role: 'trader',
            created_by: 'admin',
        }
        axios
            .post(
                'https://prod-api.krishione.com/auth-function/otp',
                dataForOtp,
                config
            )
            .then((res) => {
                setOtpWindow(true)
            })
    }

    const syncOtpSubmit = (e) => {
        setOtpWindow(false)
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        let value = { ...inputs, typeOfCrops: cropTypes }
        var traderdata = {
            user_id: '',
            first_name: value.firstName,
            last_name: value.lastName,
            gender: value.gender,
            dob: value.dob,
            role: 'trader',
            coordinates: [value.lat, value.lng],
            photo: 'test',
            mobile_number: value.mobileNumber,
            language: value.language,
            partner_mode: value.partner_mode,
            state: value.state,
            district: value.district,
            sub_distric: value.district,
            village: value.village,
            pincode: +value.pincode,
            full_address: value.fullAddress,
            farm_info: {
                firm_name: value.firmName,
                gst_no: value.gstNo,
                corp_img: 'test',
                // "cropsName": value.typeOfCrops
            },
            created_by: createdByRole,
            createdById:createdByid   
        }
        axios
            .post(
                'https://prod-api.krishione.com/auth-function/farmers',
                traderdata,
                config
            )
            .then((res) => {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: 'Trader Created Successfully',
                    showConfirmButton: false,
                    timer: 2000,
                })
                setInputs(clearInputs)
                setCropTypes([])
                setTab(1)
            })
            .catch((err) =>
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: 'error occured!',
                    showConfirmButton: false,
                    timer: 2000,
                })
            )
    }

    const handleMobVal = (e) => {
        setInputs({ ...inputs, [e.target.name]: e.target.value })
        if (e.target.value.length == 10) {
            mobilecheck(e.target.value)
        } else if (e.target.value.length > 10) {
            console.log('e.target.value.length', e.target.value.length)
            setInputs({ ...inputs, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: 'Mobile length should be 10',
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const mobilecheck = async (number) => {
        const { data } = await API.get(`/checkNumber?number=` + number)
        console.log('data', data)
        if (data.success == true) {
            // setFarmer({ ...farmer, [e.target.name]: number })
        } else {
            setInputs({ ...inputs, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data.result,
                showConfirmButton: false,
                timer: 2000,
            })
        }
        // setDetails(data?.result);
    }
    const handleMobilesearch = (e) => {
        console.log('e', e.target.value)
        if (e.target.value.length == 10) {
            mobilechecksearch(e.target.value)
        } else {
            setInputSuppliers([])
        }
    }
    const mobilechecksearch = async (number) => {
        const { data } = await API.get(`/searchTrader?search=` + number)
        console.log('data', data)
        if (data.success == true) {
            let arr = []
            arr.push(data?.result)
            setInputSuppliers(arr)
        } else {
        }
    }
    $(function () {
        $('#txtNumeric').keydown(function (e) {
            if (e.shiftKey || e.ctrlKey || e.altKey) {
                e.preventDefault()
            } else {
                var key = e.keyCode

                if (
                    !(
                        key == 8 ||
                        key == 32 ||
                        key == 46 ||
                        (key >= 35 && key <= 40) ||
                        (key >= 65 && key <= 90)
                    )
                ) {
                    e.preventDefault()
                }
            }
        })
    })
    const mapdata = (data) => {
        console.log('ran')
        setInputs({
            ...inputs,
            state: data.state,
            district: data.district,
            village: data.village,
            pincode: data.pincode,
            address: data.address,
            fullAddress: data.address,
            lat: data.latitude,
            lng: data.longitude,
        })
    }
    const handleFarmer = (e) => {
        if (e.target.value.length == 10) {
            getFormerinfo(e.target.value)
        } else {
            setFormerinfo({})
        }
    }
    const getFormerinfo = async (Mob) => {
        const token = localStorage.getItem('token')
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        const farmerNumber = {
            mobile_no: Mob,
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/register-details`,
                farmerNumber,
                config
            )
            .then((res) => {
                const d = res.data.data.data
                localStorage.setItem('FormerId', d.user_id)
                setCreatedById(d.user_id)
                setCreatedByRole(d.role)
                localStorage.setItem('microId', '')
                setFormerinfo(d)
            })
            .catch((err) => {
                console.log(err)
                setFormerinfo({})
            })
    }
    return (
        <>
         <div class="card card-table p-10-25">
                            <div class="card-header">
                                <h5 class="card-title"> Info</h5>
                            </div>
           <div class="tab-content">
                                        <div action="#">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="farmerNum"
                                                        //  label="Farmer Number"
                                                        placeholder="Farmer Number"
                                                        variant="standard"
                                                        //  value={}
                                                        onChange={(e) =>
                                                            handleFarmer(e)
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.role
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.id
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.first_name
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerState"
                                                        //  label="State"
                                                        placeholder="State"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.state
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="farmerDistrict"
                                                        disabled
                                                        //  label="District"
                                                        placeholder="District"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.district
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        disabled
                                                        fullWidth
                                                        id="farmerVillage"
                                                        //  label="Village"
                                                        placeholder="Village"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.village
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        disabled
                                                        fullWidth
                                                        id="farmerPincode"
                                                        //  label="Pincode"
                                                        placeholder="Pincode"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.pincode
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>
                                          
                                        </div>
                                        </div>

                                    </div>
            {otpWindow ? (
                <OtpBox
                    mobileNumber={inputs.mobileNumber}
                    syncOtpSubmit={syncOtpSubmit}
                />
            ) : (
                <div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card card-table p-10-25">
                                <div class="card-header">
                                    <h5 class="card-title">Create Trader</h5>
                                </div>
                                <div class="card-body">
                                    <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                                        <li class="nav-item">
                                            <a
                                                class={
                                                    tab === 1
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                Personal Info
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                class={
                                                    tab === 2
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                Firm Details
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                class={
                                                    tab === 3
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                Contact Details
                                            </a>
                                        </li>
                                        {/* <li class="nav-item"><a class={tab === 4 ? "nav-link active" : "nav-link"}>Commission Details</a></li> */}
                                        {/* <li class="nav-item"><a class={tab === 5 ? "nav-link active" : "nav-link"}>Bank Account Info</a></li> */}
                                    </ul>
                                    <div class="tab-content">
                                        {/* Personal Info */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab1"
                                        >
                                            {tab === 1 && (
                                                <form
                                                    onSubmit={() => setTab(2)}
                                                >
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                id="fName"
                                                                label="First Name"
                                                                variant="standard"
                                                                name="firstName"
                                                                value={
                                                                    inputs.firstName
                                                                }
                                                                onChange={(e) =>
                                                                    setInputs({
                                                                        ...inputs,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                id="lName"
                                                                label="Last Name"
                                                                variant="standard"
                                                                name="lastName"
                                                                value={
                                                                    inputs.lastName
                                                                }
                                                                onChange={(e) =>
                                                                    setInputs({
                                                                        ...inputs,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                id="mobileNumber"
                                                                type="number"
                                                                label="Mobile Number"
                                                                variant="standard"
                                                                name="mobileNumber"
                                                                value={
                                                                    inputs.mobileNumber
                                                                }
                                                                onChange={(e) =>
                                                                    handleMobVal(
                                                                        e
                                                                    )
                                                                }
                                                                // onChange={(e) => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                >
                                                                    Gender
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="gender"
                                                                    label="Gender"
                                                                    color="success"
                                                                    name="gender"
                                                                    value={
                                                                        inputs.gender
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setInputs(
                                                                            {
                                                                                ...inputs,
                                                                                [e
                                                                                    .target
                                                                                    .name]:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>
                                                                            None
                                                                        </em>
                                                                    </MenuItem>
                                                                    <MenuItem value="Male">
                                                                        Male
                                                                    </MenuItem>
                                                                    <MenuItem value="Female">
                                                                        Female
                                                                    </MenuItem>
                                                                    <MenuItem value="Others">
                                                                        Others
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <InputLabel
                                                                id="demo-simple-select-standard-label"
                                                                style={{
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                Date Of Birth
                                                            </InputLabel>
                                                            <CssTextField
                                                                fullWidth
                                                                type="date"
                                                                // id="dob"
                                                                variant="standard"
                                                                name="dob"
                                                                className="mt-2"
                                                                value={
                                                                    inputs.dob
                                                                }
                                                                onChange={(e) =>
                                                                    setInputs({
                                                                        ...inputs,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                >
                                                                    Partner
                                                                    Mode*
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    label="partner_mode"
                                                                    color="success"
                                                                    name="partner_mode"
                                                                    value={
                                                                        inputs.partner_mode
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setInputs(
                                                                            {
                                                                                ...inputs,
                                                                                [e
                                                                                    .target
                                                                                    .name]:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    <MenuItem value="Wholesale">
                                                                        Wholesale
                                                                    </MenuItem>
                                                                    <MenuItem value="Retail">
                                                                        Retail
                                                                    </MenuItem>
                                                                    <MenuItem value="Mandi">
                                                                        Mandi
                                                                    </MenuItem>
                                                                    <MenuItem value="Manufacture">
                                                                        Manufacture
                                                                    </MenuItem>
                                                                    <MenuItem value="Miller">
                                                                        Miller
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                            <button
                                                                type="submit"
                                                                class=" w-10 btn btn-primary"
                                                            >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            )}
                                        </div>

                                        {/* Firm Details */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab2"
                                        >
                                            {tab === 2 && (
                                                <form
                                                    onSubmit={(e) => {
                                                        setTab(3)
                                                        setInputs({
                                                            ...inputs,
                                                            typeOfLicense:
                                                                licenseTypesArr,
                                                        })
                                                    }}
                                                >
                                                    <div class="row">
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                id="firmName"
                                                                label="Firm Name"
                                                                variant="standard"
                                                                name="firmName"
                                                                value={
                                                                    inputs.firmName
                                                                }
                                                                onChange={(e) =>
                                                                    setInputs({
                                                                        ...inputs,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                // required
                                                                id="gstNo"
                                                                label="GST No"
                                                                variant="standard"
                                                                name="gstNo"
                                                                value={
                                                                    inputs.gstNo
                                                                }
                                                                onChange={(e) =>
                                                                    setInputs({
                                                                        ...inputs,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <InputLabel id="demo-multiple-checkbox-label">
                                                                    Crops Name
                                                                </InputLabel>
                                                                <Select
                                                                    // required
                                                                    labelId="demo-multiple-checkbox-label"
                                                                    id="demo-multiple-checkbox"
                                                                    multiple
                                                                    value={
                                                                        cropTypes
                                                                    }
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    input={
                                                                        <Input
                                                                            label="Type Of Crop"
                                                                            variant="standard"
                                                                        />
                                                                    }
                                                                    renderValue={(
                                                                        selected
                                                                    ) =>
                                                                        selected.join(
                                                                            ', '
                                                                        )
                                                                    }
                                                                    MenuProps={
                                                                        MenuProps
                                                                    }
                                                                >
                                                                    {/* {names.map((name) => (
                                                                <MenuItem key={name} value={name}>
                                                                    <Checkbox checked={cropTypes.indexOf(name) > -1} />
                                                                    <ListItemText primary={name} />
                                                                </MenuItem>
                                                            ))} */}
                                                                    {CropProductoption &&
                                                                        CropProductoption.map(
                                                                            (
                                                                                name
                                                                            ) => (
                                                                                <MenuItem
                                                                                    key={
                                                                                        name.value
                                                                                    }
                                                                                    value={
                                                                                        name.value
                                                                                    }
                                                                                >
                                                                                    {console.log(
                                                                                        'CropProduct',
                                                                                        name,
                                                                                        name.value
                                                                                    )}
                                                                                    <Checkbox
                                                                                        checked={
                                                                                            cropTypes.indexOf(
                                                                                                name.value
                                                                                            ) >
                                                                                            -1
                                                                                        }
                                                                                    />
                                                                                    <ListItemText
                                                                                        primary={
                                                                                            name.value
                                                                                        }
                                                                                    />
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    {/* {licenseTypes.map((crop, index) => {
                                                return (
                                                    <div className="row mt-3 mb-3">
                                                        <div class="col-md-3 mt-4">
                                                            <CssTextField
                                                                fullWidth id="standard-basic"
                                                                label="Type Of License"
                                                                variant="standard"
                                                                value={crop}
                                                            />
                                                        </div>
                                                        <div class="col-md-3 mt-4">
                                                            <CssTextField
                                                                fullWidth id="standard-basic"
                                                                type="number"
                                                                label="License Number"
                                                                variant="standard"
                                                                name="licenseNumber"
                                                                //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licenseNumber']}
                                                                onChange={(e) => setLicenceInputs(e, index)}
                                                            />
                                                        </div>
                                                        <div class="col-md-3 mt-4">
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
                                                                License Exp Date
                                                            </InputLabel>
                                                            <CssTextField
                                                                type="date"
                                                                fullWidth
                                                                variant="standard"
                                                                style={{ marginTop: '10px', marginBottom: '10px' }}
                                                                name="expDate"
                                                                //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licenseNumber']}
                                                                onChange={(e) => setLicenceInputs(e, index)}
                                                            />
                                                        </div>
                                                        <div class="col-md-3 d-flex" style={{ justifyContent: 'space-between' }}>
                                                            <label htmlFor="contained-button-file">
                                                                <Button variant="contained" component="span" color="success">
                                                                    <Input
                                                                        accept="image/"
                                                                        id="contained-button-file"
                                                                        multiple="false"
                                                                        name="photo"
                                                                        type="file"
                                                                        onChange={(e) => handleUploadDP(e, index)}
                                                                        style={{
                                                                            display: 'none',
                                                                            marginBottom: '16px',
                                                                            marginTop: '16px'
                                                                        }}
                                                                    />
                                                                    Upload
                                                                </Button>
                                                            </label>
                                                            <aside style={thumbsContainerState}>
                                                                <div style={thumb}>
                                                                    <div style={thumbInner}>
                                                                        <img src={licensePhoto} style={img} alt="" />
                                                                    </div>
                                                                </div>
                                                            </aside>
                                                        </div>
                                                    </div>
                                                )
                                            })} */}

                                                    <div class="col-md-12 text-end mt-2 mb-2">
                                                        <button
                                                            className="w-10 btn btn-secondary"
                                                            style={{
                                                                marginRight:
                                                                    '10px',
                                                            }}
                                                            onClick={(e) =>
                                                                setTab(1)
                                                            }
                                                        >
                                                            Previous
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            class=" w-10 btn btn-primary"
                                                        >
                                                            Next
                                                        </button>
                                                    </div>
                                                </form>
                                            )}
                                        </div>

                                        {/* Contact Details */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab2"
                                        >
                                            {tab === 3 && (
                                                <div class="row">
                                                    <div
                                                        class="col-md-3"
                                                        style={{
                                                            marginTop: '2%',
                                                        }}
                                                    >
                                                        <Map
                                                            center={{
                                                                lat: 18.5204,
                                                                lng: 73.8567,
                                                            }}
                                                            height="300px"
                                                            zoom={15}
                                                            mapdata={mapdata}
                                                        />
                                                    </div>

                                                    <div class="col-md-12 text-end justify-space-between">
                                                        <button
                                                            className="w-16 btn btn-secondary"
                                                            style={{
                                                                marginRight:
                                                                    '10px',
                                                            }}
                                                            onClick={(e) =>
                                                                setTab(1)
                                                            }
                                                        >
                                                            Previous
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={(e) =>
                                                                syncOtpSubmit()
                                                            }
                                                            class=" w-10 btn btn-primary"
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {/* Commission Details */}

                                        {/* <div class="tab-pane show active" id="bottom-justified-tab3">
                                    {tab === 4 &&
                                        <form onSubmit={() => setTab(5)}>
                                            <div class="row">
                                                <div class="col-md-4" style={{marginTop:'2%'}}>
                                                    <FormControl variant="standard" fullWidth className="mt-3">
                                                       
                                                         <Selects style={{ width: "270px", height: "40px" }}
                                onChange={(e) => setInputs({ ...inputs, ['unit']: e.value })}
                                options={CommisionUnit}
                                placeholder={'Select Unit'}
                              //  value={inputBooking.firm}
                              />
                                                    </FormControl>
                                                </div>
                                                <div class="col-md-4" style={{marginTop:'3.5%'}}>
                                                    
                                                     <Selects style={{ width: "270px", height: "40px" }}
                                onChange={(e) => setInputs({ ...inputs, ['commissionAmount']: e.value })}
                                options={CommisionAmount}
                                placeholder={'Select commissionAmount'}
                              //  value={inputBooking.firm}
                              />
                                                </div>
                                                <div class="col-md-4" style={{marginTop:'2%'}}>
                                                    <FormControl variant="standard" fullWidth className="mt-3">
                                                       
                                                         <Selects style={{ width: "270px", height: "40px" }}
                                onChange={(e) => setInputs({ ...inputs, ['category']: e.value })}
                                options={commisiontype}
                                placeholder={'Select  commision type'}
                              //  value={inputBooking.firm}
                              />
                                                    </FormControl>
                                                </div>

                                                <div class="col-md-12 text-end mt-2 mb-2">
                                                    <button className="w-10 btn btn-secondary" style={{ marginRight: '10px' }} onClick={e => setTab(3)}>Previous</button>
                                                    <button type="submit" class=" w-10 btn btn-primary">Next</button>
                                                </div>
                                            </div>
                                        </form>
                                    }
                                </div> */}

                                        {/* Bank Account Info */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab4"
                                        >
                                            {tab === 5 && (
                                                <form
                                                    action="#"
                                                    onSubmit={onSubmitInputs}
                                                >
                                                    <div class="row">
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id=""
                                                                label="Bank Acc"
                                                                variant="standard"
                                                                type="number"
                                                                name="bankAccountNumber"
                                                                value={
                                                                    inputs.bankAccountNumber
                                                                }
                                                                onChange={(e) =>
                                                                    setInputs({
                                                                        ...inputs,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>

                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id=""
                                                                label="Account Name"
                                                                variant="standard"
                                                                name="bankAccountName"
                                                                value={
                                                                    inputs.bankAccountName
                                                                }
                                                                onChange={(e) =>
                                                                    setInputs({
                                                                        ...inputs,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id=""
                                                                label="IFSC Code"
                                                                variant="standard"
                                                                name="ifscCode"
                                                                value={
                                                                    inputs.ifscCode
                                                                }
                                                                onChange={(e) =>
                                                                    setInputs({
                                                                        ...inputs,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id=""
                                                                label="Bank Name"
                                                                variant="standard"
                                                                pattern="[a-zA-Z]*"
                                                                type="text"
                                                                name="bankName"
                                                                value={
                                                                    inputs.bankName
                                                                }
                                                                onChange={(e) =>
                                                                    setInputs({
                                                                        ...inputs,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id=""
                                                                label="Branch Name"
                                                                variant="standard"
                                                                name="bankBranchName"
                                                                value={
                                                                    inputs.bankBranchName
                                                                }
                                                                onChange={(e) =>
                                                                    setInputs({
                                                                        ...inputs,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                            <button
                                                                className="w-10 btn btn-secondary"
                                                                style={{
                                                                    marginRight:
                                                                        '10px',
                                                                }}
                                                                onClick={(e) =>
                                                                    setTab(4)
                                                                }
                                                            >
                                                                Previous
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={(e) =>
                                                                    onSubmitInputs()
                                                                }
                                                                class=" w-10 btn btn-primary"
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
export default CreteTraders
