import { useEffect, useState, useMemo } from 'react'
import { useTable, useSortBy } from 'react-table'
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap'
import axios from 'axios'
import { format } from 'date-fns'

// import { COLUMNS } from './columns'
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import { CSVLink } from 'react-csv';

const COLUMNS = [
    {
        name: 'ID',
        selector:  row => row.id,
    },
    {
        name: 'Name',
        selector:  row => row.first_name,
    },
    {
        name: 'Mobile',
        selector: 'mobile_number',
        Cell: (row) => {
            let url
            switch (row.role) {
                case 'micro-entrepreneur':
                    url = `MicroEntrepDetails/${row.mobile_number}`
                    return <a href={url}>{row.mobile_number}</a>
            }
            return row.mobile_number
        },
    },
    {
        name: 'Gender',
        selector:  row => row.gender,
    },
    {
        name: 'DOB',
        selector: 'dob',
        Cell: (row) => {
            return row.dob.substring(0, 10)
        },
    },
    {
        name: 'State',
        selector:  row => row.state,
    },
    {
        name: 'District',
        selector: row => row.district,
    },
    {
        name: 'Sub Dist',
        selector: row => row.sub_distric,
    },
    {
        name: 'Village',
        selector:  row => row.village,
    },
    {
        name: 'Pincode',
        selector:  row => row.pincode,
    },
    // {
    //     name: 'Created By',
    //     selector: 'created_by',
    // },
    {
        name: 'Created Num',
        selector: row=>row.created_by[0]?.mobile_number,
    },
    {
        name: 'Created Date',
        selector: (row) => {
            return format(row.created_at, 'dd/MM/yyyy')
        },
    },
    {
        name: 'Crop Name',
        selector: (row) => {
            switch (row.role) {
                case 'farmer':
                    if (row.farm_info[0].crop_name)
                        return row.farm_info[0].crop_name
                    else return 'null'
                case 'agri-input-supplier':
                    return row.farm_info[0].firm_name
                case 'agri-transport':
                    return row.vehicle_info.vehicle_type
                case 'trader':
                    return row.farm_info[0].firm_name
                case 'agri-machine':
                    return row.machine_info.machine_type
                case 'livestock':
                    return row.farm_info[0].firm_name
                case 'micro-entrepreneur':
                    // return row.partner_mode
                    return 'not given'
                case 'fpo':
                    return row.company_info.company_name
                default:
                    return 'No Mode Found'
            }
        },
    },
    {
        name: 'Irrigation Source',
        selector: (row) => {
            return row.farm_info[0].irrigation_source
        },
    },
    {
        name: 'Farm Equipment',
        selector: (row) => {
            return row.farm_info[0].farm_equipements
        },
    },
    {
        name: 'Unit',
        selector: (row) => {
            return row.farm_info[0].unit
        },
    },
    {
        name: 'Total Land',
        selector: (row) => {
            return row.farm_info[0].total_land
        },
    },
    {
        name: 'Soil Type',
        selector: (row) => {
            return row.farm_info[0].soil_type
        },
    },
    {
        name: 'Sowing Date',
        selector: (row) => {
            return row.farm_info[0].sowing_date.substring(0, 10)
        },
    },
    {
        name: 'Soil Test',
        selector: (row) => {
            return row.farm_info[0].soil_test
        },
    },
]

const ListFarmer = () => {
    const [reports, setReports] = useState([])
    const [from, setFrom] = useState(Date.parse(new Date('01/01/1900')))
    const [to, setTo] = useState(Date.parse(new Date('01/01/1900')))
    const [limit, setLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(0)
    const [pageCount,setPageCount]=useState(5)
    const [count, setCount] = useState(0)
    const [csvdata,setCsvData]=useState([])
    useEffect(() => {
        if (to > 0 && from > 0) {
            getReports(0)
        }
    }, [to, from, limit])

    const getReports = async (page=0) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_ENDPOINT}/auth-function/dashboard-count/farmer?from=${from}&to=${to}&page=${page*10}&limit=10`,
            config
        )
        setCount(data.count)

        if (data != undefined && data.data.length != 0) {
            console.log(data)
            setReports(data.data)
            const csvData = data.data.map(report => ({
                ID: report.id,
                Name: report.first_name,
                mobile_number: report.mobile_number,
                gender: report.gender,
                DOB: report.dob.substring(0, 10),
                state: report.state,
                district: report.district,
                sub_distric: report.sub_distric,
                pincode: report.pincode,
                CreatedByNum: report.created_by[0]?.mobile_number,
                CreatedDate: format(report.created_at, 'dd/MM/yyyy'),
                crop_name: report.farm_info[0].crop_name,
                irrigation_source: report.farm_info[0].irrigation_source,
                farm_equipements: report.farm_info[0].farm_equipements,
                unit: report.farm_info[0].unit,
                total_land: report.farm_info[0].total_land,
                soil_type: report.farm_info[0].soil_type,
                sowing_date: report.farm_info[0].sowing_date.substring(0, 10),
                soil_test: report.farm_info[0].soil_test,

                // Add more fields as needed...
            }));
            console.log(csvData)
            setCsvData(csvData)
            // return
        }
    }
    const onPageChange = (page) => {
        setCurrentPage(page.selected)

        console.log('ran', page)
        getReports(page.selected)
    }
    const CustomPagination = () => (
        <ReactPaginate
          previousLabel={''}
          nextLabel={''}
          forcePage={currentPage}
          onPageChange={page => onPageChange(page)}
          pageCount={count/10}
          breakLabel={'...'}
          pageRangeDisplayed={10}
          marginPagesDisplayed={10}
          activeClassName='active'
          pageClassName='page-item'
          breakClassName='page-item'
          nextLinkClassName='page-link'
          pageLinkClassName='page-link'
          breakLinkClassName='page-link'
          previousLinkClassName='page-link'
          nextClassName='page-item next-item'
          previousClassName='page-item prev-item'
          containerClassName={'pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1'}
        />
      )
    const handleCallback = (start, end, label) => {
        const d1 = Date.parse(start._d)
        const d2 = Date.parse(end._d)
        setFrom(d1)
        setTo(d2)
    }

    // const columns = useMemo(() => COLUMNS, [])

    // const tableInstance = useTable(
    //     {
    //         columns,
    //         data: reports,
    //     },
    //     useSortBy
    // )

    // const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    //     tableInstance

    return (
        <>
            <div style={{ display: 'flex' }}>
                <DateRangePicker
                    initialSettings={{
                        startDate: new Date().getDate(),
                        endDate: new Date().getDate(),
                    }}
                    onCallback={handleCallback}
                >
                    <input className="date-filter" />
                </DateRangePicker>
                {/* <DropdownButton
                    as={ButtonGroup}
                    title="Select the Number of Data"
                    onSelect={handleSelect}
                    style={{ marginLeft: '20px' }}
                >
                    {[10, 25, 50, 100, 500].map((variant) => (
                        <Dropdown.Item eventKey={variant}>
                            {variant}
                        </Dropdown.Item>
                    ))}
                </DropdownButton> */}
            </div>
            {csvdata.length? <>
             <CSVLink
                data={csvdata}
                filename={"your_data.csv"}
                className="btn btn-primary"
                target="_blank"
            >
                Download CSV
            </CSVLink>
             </> :null}
            
            {reports.length?
        <DataTable
          noHeader
          pagination
          data={reports}
          selectableRows
          columns={COLUMNS}
          expandOnRowClicked
          className='react-dataTable'
          paginationComponent={CustomPagination}
          paginationDefaultPage={currentPage + 1}
        //   expandableRowsComponent={ExpandableTable}
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
        /> : <div className='nodatapagination'> 
        <h4 style={{textAlign:"center"}}>No More Data !</h4>
          <CustomPagination/>
          </div>}
           
        </>
    )
}
export default ListFarmer
