import React,{useEffect, useState} from 'react'
import Select from 'react-select';
import ProductCard from './ProductCard';

const ProductList = ({ products, onClickAdd }) => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [productOptions,setProductOptions]=useState([])
    console.log('sdf',products)


    const onClick = (product) => {
        console.log('onClickAdd',product)
        // setQuantityy(quantityy + quantity + 1)
        onClickAdd(product)
    }
    const handleChange = (selectedOption) => {
        setSelectedProduct(selectedOption);
      };
  

    //   const productOptions = products
    // ? products?.variants_list?.map((product) => ({
    //     value: product.salePrice,
    //     label: `${product.quantity} ${product.unit}`,
    //   }))
    // : [];
    return (
        <div className="productList">
            {products.map((product) => (
                <>
                <ProductCard product={product} variants_list={product.variants_list} onClickAdd={onClick} />
                </>
            ))}
        </div>
    )
}

export default ProductList
