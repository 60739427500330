import { useMemo, useEffect, useState } from 'react'
import { useTable, useSortBy, usePagination } from 'react-table'
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap'

import axios from 'axios'

import './report.css'
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'

const ReportTable = ({ tab }) => {
    const [reports, setReports] = useState([])
    const [from, setFrom] = useState(Date.parse(new Date('01/01/1900')))
    const [to, setTo] = useState(Date.parse(new Date('01/01/1900')))
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(0)
    const [pageCount,setPageCount]=useState(5)
    const [count, setCount] = useState(0)


  

    useEffect(() => {
        if (to > 0 && from > 0) getReports(0)
    }, [tab, to, from, page, limit])

    const getReports = async (page=0) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_ENDPOINT}/auth-function/dashboard-count/${tab}?from=${from}&to=${to}&page=${page*10}&limit=${limit}`,
            config
        )
        setCount(data.count)

        if (data != undefined && data.data.length != 0) {
            console.log(data,'thisreports')
            setReports(data.data)
            // setPageCount(data.data.length/10+1)
        
        }
    }
   const COLUMNS = [
        {
            name: 'S. No',
            selector: '_id',
            Cell: (row) => {
                return row.index + 1
            },
        },
        {
            name: 'ID',
            selector:  row => row.id,
        },
        {
            name: 'Name',
            selector:  row => row.first_name,
        },
        {
            name: 'Mobile',
            selector:  row => row.mobile_number,

        },
        {
            name: 'Gender',
            selector:  row => row.gender,
        },
        // {
        //     name: 'DOB',
        //     selector: 'dob',
        //     Cell: (row) => {
        //         return row.dob.substring(0, 10)
        //     },
        // },
        {
            name: 'State',
            selector:  row => row.state,
        },
        {
            name: 'District',
            selector: row => row.district,
        },
        {
            name: 'Sub Dist',
            selector: row => row.sub_distric,
        },
        {
            name: 'Village',
            selector:  row => row.village,
        },
        {
            name: 'Pincode',
            selector:  row => row.pincode,
           
        },
        {
            name: 'Mode',
            selector: 'isDeleted',
            cell: (row) => {
                switch (row.role) {
                    case 'farmer':
                        return row.farming_mode
                    case 'agri-input-supplier':
                        return row.business_mode
                    case 'agri-transport':
                        return row.business_mode
                    case 'trader':
                        return row.business_mode
                    case 'agri-machine':
                        return row.business_mode
                    case 'livestock':
                        return row.business_mode
                    case 'micro-entrepreneur':
                        return row.partner_mode
                    case 'fpo':
                        return row.partner_mode
                }
                return 'No Mode Found'
            },
        },
        {
            name: 'Title',
            selector: 'crop_name',
            cell: (row) => {
                switch (row.role) {
                    case 'farmer':
                        if (row.farm_info[0].crop_name)
                            return row.farm_info[0].crop_name
                        else return 'null'
                    case 'agri-input-supplier':
                        return row.farm_info[0].firm_name
                    case 'agri-transport':
                        return row.vehicle_info.vehicle_type
                    case 'trader':
                        return row.farm_info[0].firm_name
                    case 'agri-machine':
                        return row.machine_info.machine_type
                    case 'livestock':
                        return row.farm_info[0].firm_name
                    case 'micro-entrepreneur':
                        // return row.original.partner_mode
                        return 'not given'
                    case 'fpo':
                        return row.company_info.company_name
                    default:
                        return 'No Mode Found'
                }
            },
        },
        {
            name: 'Created By',
            selector: row=>row.created_by[0].mobile_number,
           
        },
        
    ]
    const handleCallback = (start, end, label) => {
        const d1 = Date.parse(start._d)
        const d2 = Date.parse(end._d)
        setFrom(d1)
        setTo(d2)
    }
    const handlePagination = page => {
        console.log(page)
        setCurrentPage(page.selected)
        getReports(page.selected)
      }
    const handleSelect = (e) => {
        setLimit(e)
    }
    // ** Custom Pagination
  const CustomPagination = () => (
    <ReactPaginate
      previousLabel={''}
      nextLabel={''}
      forcePage={currentPage}
      onPageChange={page => handlePagination(page)}
      pageCount={count/10}
      breakLabel={'...'}
      pageRangeDisplayed={2}
      marginPagesDisplayed={2}
      activeClassName='active'
      pageClassName='page-item'
      breakClassName='page-item'
      nextLinkClassName='page-link'
      pageLinkClassName='page-link'
      breakLinkClassName='page-link'
      previousLinkClassName='page-link'
      nextClassName='page-item next-item'
      previousClassName='page-item prev-item'
      containerClassName={'pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1'}
    />
  )

    return (
        <>
            <div style={{ display: 'flex' }}>
                <DateRangePicker
                    initialSettings={{
                        startDate: new Date().getDate(),
                        endDate: new Date().getDate(),
                    }}
                    onCallback={handleCallback}
                >
                    <input className="date-filter" />
                </DateRangePicker>
                <DropdownButton
                    as={ButtonGroup}
                    title="Select the Number of Data"
                    onSelect={handleSelect}
                    style={{ marginLeft: '20px' }}
                >
                    {[10, 25, 50, 100, 500].map((variant) => (
                        <Dropdown.Item eventKey={variant}>
                            {variant}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
            </div>
            <div className='react-dataTable'>
        {reports.length?
        <DataTable
          noHeader
          pagination
          data={reports}
          selectableRows
          columns={COLUMNS}
          expandOnRowClicked
          className='react-dataTable'
          paginationComponent={CustomPagination}
          paginationDefaultPage={currentPage + 1}
        //   expandableRowsComponent={ExpandableTable}
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
        /> : <div className='nodatapagination'> 
        <h4 style={{textAlign:"center"}}>No More Data !</h4>
          <CustomPagination/>
          </div>}
      </div>
        </>
    )
}

export default ReportTable
