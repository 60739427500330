import React, { useState, useEffect } from 'react'
import { CssTextField } from '../../Styles'
import axios from 'axios'
import { storage } from '../../../firebase/index'
import { API } from '../../../API/index'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import { useDropzone } from 'react-dropzone'
import {
    thumbsContainerState,
    thumb,
    img,
    thumbInner,
    baseStyle,
    driverDP,
    sectionDiv,
    uploadBtnSm,
    panImg,
} from '../../Styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import AgriTransportTable from './AgriTransportTable'
import Swal from 'sweetalert2'
import '../Create.css'
import $ from 'jquery'

import Selects from 'react-select'
import Map from '../Map'
import OtpBox from '../../../components/OtpBox'

const Input = styled('input')({
    display: 'none',
    marginBottom: '16px',
    marginTop: '16px',
})
const clearvalues = {
    firstName: '',
    lastName: '',
    mobileNumber: '',
    gender: '',
    category: '',
    dateOfBirth: '',
    AadharNo: '',
    language: '',
    business_mode: '',

    state: '',
    district: '',
    subDistrict: '',
    village: '',
    pincode: '',
    address: '',

    price: '',
    perUnit: '',
    minimumBokingAmount: '',
    category: '',
    subCategory: '',
    typeOfVehicle: '',
    vehicleNumber: '',
    document: '',
    documentExpiryDate: '',
    brandName: '',

    driverFirstName: '',
    driverLastName: '',
    driverMobileNumber: '',
    driverEmail: '',
    driverPhoto: '',
    driverLicenceNumber: '',
    drivingLicencePhoto: '',
    driverPancardPhoto: '',
    driverPancardNumber: '',
    driverAdhaarPhoto: '',
    driverAdhaarNumber: '',

    unit: '',
    commissionAmount: '',
    commissionCategory: '',

    bankAccountNumber: '',
    bankAccountName: '',
    ifscCode: '',
    bankName: '',
    bankBranchName: '',
}

function AgriTransport(props) {
    const catalogue = 'transport'
    const [tab, setTab] = useState(1)
    const [states, setStates] = useState([])
    const [agriTransportList, setAgriTransportList] = useState([])
    const [docImg, setDocImg] = useState(null)
    const [driverImg, setDriverImg] = useState(null)
    const [driverLicenseImg, setDriverLicenseImg] = useState(null)
    const [AAdharImg, setAAdharImg] = useState(null)
    const [PANImg, setPANImg] = useState(null)
    const [driverPAN, setDriverPAN] = useState([])
    const [driverLicense, setDriverLicense] = useState([])
    const [driverAD, setDriverAD] = useState([])
    const [Categorylist, setCategorylist] = useState([])
    const [subCategorylist, setsubCategorylist] = useState([])
    const [BrandCategorylist, setBrandCategorylist] = useState([])
    const [Productlist, setProductlist] = useState([])
    const [CategoryName, setCategoryName] = useState('')
    const [subcategoryName, setsubcategoryName] = useState('')
    const [citylist, setcitylist] = useState([])
    const [statelist, setstatelist] = useState([])
    const [otpWindow, setOtpWindow] = useState(false)
    const [Formerinfo, setFormerinfo] = useState({})

    const [createdByid,setCreatedById]=useState('')
    const [createdByRole,setCreatedByRole]=useState("")
    const [CommisionCat, setCommisionCat] = useState([])
    const [agriTransport, setAgriTransport] = useState({
        firstName: '',
        lastName: '',
        mobileNumber: '',
        gender: '',
        category: '',
        dateOfBirth: '',
        AadharNo: '',
        language: '',
        business_mode: '',

        state: '',
        district: '',
        subDistrict: '',
        village: '',
        pincode: '',
        address: '',

        price: '',
        perUnit: '',
        minimumBokingAmount: '',

        category: '',
        subCategory: '',
        typeOfVehicle: '',
        vehicleNumber: '',
        document: '',
        documentExpiryDate: '',
        brandName: '',

        driverFirstName: '',
        driverLastName: '',
        driverMobileNumber: '',
        driverEmail: '',
        driverPhoto: '',
        driverLicenceNumber: '',
        drivingLicencePhoto: '',
        driverPancardPhoto: '',
        driverPancardNumber: '',
        driverAdhaarPhoto: '',
        driverAdhaarNumber: '',

        unit: '',
        commissionAmount: '',
        commissionCategory: '',

        bankAccountNumber: '',
        bankAccountName: '',
        ifscCode: '',
        bankName: '',
        bankBranchName: '',
    })

    useEffect(() => {
        getState()
        // getAgriTransportList();
        getCategoryList()
        getStateList()
        getCommisionCat()
    }, [])

    const getCommisionCat = async () => {
        const { data } = await API.get(
            `/getCommissionCatelog?title=CommissionCatelog&catelogName=TransportCatelog&type=2`
        )
        // console.log("hkjjhj",data)
        setCommisionCat(data?.result)
        console.log('firm search', data)
    }
    const CommisionUnit =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.commissionUnit,
            label: item.commissionUnit,
        }))
    const CommisionAmount =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.commissionAmount,
            label: item.commissionAmount,
        }))
    const commisiontype =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.subCategoryName,
            label: item.subCategoryName,
        }))

    const getStateList = async () => {
        const { data } = await API.get(`/getStateList`)
        // console.log("hkjjhj",data)
        setstatelist(data?.result)
        console.log('firm search', data)
    }
    const statelistoption =
        statelist &&
        statelist.map((item, ind) => ({
            value: item,
            label: item,
        }))
    const statechane = (e) => {
        console.log('hkjjhj', e.value)
        // setInputs({ ...inputs, ['state']: e.value })
        setAgriTransport({ ...agriTransport, ['state']: e.value })
        // onChange={(e) => setInputs({ ...inputs, ['state']: e.target.value })}
        getCityList(e.value)
    }
    const getCityList = async (val) => {
        const { data } = await API.get(`/getCityList?stateName=` + val)
        // console.log("hkjjhj",data)
        setcitylist(data?.result)
        console.log('firm search', data)
    }
    const citylistoption =
        citylist &&
        citylist.map((item, ind) => ({
            value: item,
            label: item,
        }))
    const getState = async () => {
        const { data } = await axios.get(
            'http://65.0.145.198:4080/api/v1/getStateList'
        )
        setStates(data?.result)
        console.log(data?.result)
    }

    const getAgriTransportList = async () => {
        const { data } = await API.get(`/getAgriTransport?type=0`)
        setAgriTransportList(data?.result)
    }

    // Document Upload
    const handleUploadDoc = (e) => {
        setDocImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const uploadTask = ref(storage, `docs/${doc.name}`)
            const uploadTask02 = uploadBytesResumable(uploadTask, doc)
            uploadTask02.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    // Handle unsuccessful uploads
                },
                () => {
                    getDownloadURL(uploadTask).then((url) => {
                        setAgriTransport({ ...agriTransport, document: url })
                    })
                }
            )
        }
    }

    // Driver Photo
    const handleUploadDP = (e) => {
        setDriverImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        setAgriTransport({ ...agriTransport, driverPhoto: url })
                    })
                }
            )
        }
    }

    // Driving License Upload
    const DriverLicenseUpload = () => {
        const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
            accept: 'image/',
            onDrop: (acceptedFiles) => {
                setDriverLicense(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )
                )
            },
        })

        return (
            <section className="container" style={baseStyle}>
                <div
                    {...getRootProps({ className: 'dropzone' })}
                    style={panImg}
                >
                    <input {...getInputProps()} multiple={false} />
                    {driverLicense.length > 0 ? (
                        <aside style={thumbsContainerState}>
                            <div style={thumb}>
                                <div style={thumbInner}>
                                    <img
                                        src={driverLicense[0].preview}
                                        style={img}
                                        alt="Driving License"
                                    />
                                </div>
                            </div>
                        </aside>
                    ) : (
                        <p align="center">
                            Drop Image here or click to upload.
                        </p>
                    )}
                </div>
            </section>
        )
    }

    const handleUploadDL = (e) => {
        const doc = driverLicense[0]
        if (doc) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        setAgriTransport({
                            ...agriTransport,
                            drivingLicencePhoto: url,
                        })
                    })
                }
            )
        }
    }

    // Driver PAN upload
    const PanUpload = () => {
        const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
            accept: 'image/',
            onDrop: (acceptedFiles) => {
                setDriverPAN(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )
                )
            },
        })

        return (
            <section className="container" style={baseStyle}>
                <div
                    {...getRootProps({ className: 'dropzone' })}
                    style={panImg}
                >
                    <input {...getInputProps()} multiple={false} />
                    {driverPAN.length > 0 ? (
                        <aside style={thumbsContainerState}>
                            <div style={thumb}>
                                <div style={thumbInner}>
                                    <img
                                        src={driverPAN[0].preview}
                                        style={img}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </aside>
                    ) : (
                        <p align="center">
                            Drop Image here or click to upload.
                        </p>
                    )}
                </div>
            </section>
        )
    }

    // Driver Aadhaar upload
    const AadhaarUpload = () => {
        const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
            accept: 'image/',
            onDrop: (acceptedFiles) => {
                setDriverAD(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )
                )
            },
        })

        return (
            <section className="container" style={baseStyle}>
                <div
                    {...getRootProps({ className: 'dropzone' })}
                    style={panImg}
                >
                    <input {...getInputProps()} multiple={false} />
                    {driverAD.length > 0 ? (
                        <aside style={thumbsContainerState}>
                            <div style={thumb}>
                                <div style={thumbInner}>
                                    <img
                                        src={driverAD[0].preview}
                                        style={img}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </aside>
                    ) : (
                        <p align="center">
                            Drop Image here or click to upload.
                        </p>
                    )}
                </div>
            </section>
        )
    }

    const handleUploadAD = (e) => {
        const doc = driverAD[0]
        if (doc) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        setAgriTransport({
                            ...agriTransport,
                            driverAdhaarPhoto: url,
                        })
                    })
                }
            )
        }
    }

    // Driver Details
    const onSubmitDriverDetails = () => {
        setTab(7)
    }

    // Submit Agri Machine
    const onSubmitAgriTransport = async (e) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const dataForOtp = {
            mobile_number: agriTransport.mobileNumber,
            role: 'agriTransport',
            created_by: 'admin',
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/auth-function/otp`,
                dataForOtp,
                config
            )
            .then((res) => {
                setOtpWindow(true)
            })
    }

    function syncOtpSubmit(e) {
        setOtpWindow(false)
        if (!agriTransport.document) {
            alert('Document is not uploaded yet')
        }
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }

        var trandata = {
            user_id: '',
            first_name: agriTransport.firstName,
            last_name: agriTransport.lastName,
            gender: agriTransport.gender,
            dob: agriTransport.dateOfBirth,
            mobile_number: agriTransport.mobileNumber,
            role: 'agri-transport',
            coordinates: [agriTransport.lat, agriTransport.lng],
            photo: 'test',
            language: agriTransport.language,
            business_mode: agriTransport.business_mode,
            state: agriTransport.state,
            district: agriTransport.district,
            sub_distric: agriTransport.district,
            village: agriTransport.village,
            pincode: +agriTransport.pincode,
            full_address: agriTransport.address,
            vehicle_info: {
                category: agriTransport.category,
                body_type: agriTransport.subCategory,
                brand_name: agriTransport.brandName,
                vehicle_type: agriTransport.typeOfVehicle,
                vehicle_number: agriTransport.vehicleNumber,
                document: {
                    image: agriTransport.document || 'test',
                    document_exp: agriTransport.documentExpiryDate,
                },
            },
            created_by: createdByRole,
            createdById:createdByid           }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/auth-function/farmers`,
                trandata,
                config
            )
            .then((res) => {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: 'Agri Transport Created Successfully',
                    showConfirmButton: false,
                    timer: 2000,
                })
                setAgriTransport(clearvalues)
                setTab(1)
            })
            .catch((err) =>
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: 'error occured!',
                    showConfirmButton: false,
                    timer: 2000,
                })
            )
    }

    const handleMobVal = (e) => {
        setAgriTransport({ ...agriTransport, [e.target.name]: e.target.value })
        if (e.target.value.length == 10) {
            mobilecheck(e.target.value)
        } else if (e.target.value.length > 10) {
            console.log('e.target.value.length', e.target.value.length)
            // setInputs({ ...inputs, mobileNumber: "" })
            setAgriTransport({ ...agriTransport, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: 'Mobile length should be 10',
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const mobilecheck = async (number) => {
        const { data } = await API.get(`/checkNumber?number=` + number)
        console.log('data', data)
        if (data.success == true) {
            // setFarmer({ ...farmer, [e.target.name]: number })
        } else {
            setAgriTransport({ ...agriTransport, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data.result,
                showConfirmButton: false,
                timer: 2000,
            })
        }
        // setDetails(data?.result);
    }

    const getCategoryList = async () => {
        // const { data } = await API.get(`/getProductCategory?type=0&title=ProductCatelog&catelogName=TransportCatelog`);
        // // console.log("hkjjhj",data)
        // setCategorylist(data?.result);
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/category/${catalogue}`,
                config
            )
            .then((res) => {
                console.log('d :', res.data.category)
                setCategorylist(res.data.category)
            })
    }
    const getSubCategoryList = async (val) => {
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const subCat = {
            catalogue: catalogue,
            categoryName: val,
        }
        await axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-subcategory`,
                subCat,
                config
            )
            .then((res) => {
                console.log(
                    'get sub cat in input catalogue',
                    res.data.subcategory
                )
                setsubCategorylist(res.data.subcategory)
            })
    }
    const handleCategoryChangeVarient = (e) => {
        setAgriTransport({ ...agriTransport, [e.target.name]: e.target.value })
        getSubCategoryList(e.target.value)
        setCategoryName(e.target.value)
    }
    const handleMobilesearch = (e) => {
        console.log('e', e.target.value)
        if (e.target.value.length == 10) {
            mobilechecksearch(e.target.value)
        } else {
            setAgriTransportList([])
        }
    }
    const mobilechecksearch = async (number) => {
        const { data } = await API.get(`/searchAgriTransport?search=` + number)
        console.log('data', data)
        if (data.success == true) {
            let arr = []
            arr.push(data?.result)
            setAgriTransportList(arr)
        } else {
        }
    }

    $(function () {
        $('#txtNumeric').keydown(function (e) {
            if (e.shiftKey || e.ctrlKey || e.altKey) {
                e.preventDefault()
            } else {
                var key = e.keyCode

                if (
                    !(
                        key == 8 ||
                        key == 32 ||
                        key == 46 ||
                        (key >= 35 && key <= 40) ||
                        (key >= 65 && key <= 90)
                    )
                ) {
                    e.preventDefault()
                }
            }
        })
    })
    const handleSUbCategoryChangeVarient = (e) => {
        setsubcategoryName(e.target.value)
        setAgriTransport({ ...agriTransport, [e.target.name]: e.target.value })
        getBrandCategoryList(e.target.value)
    }
    const handleBRANDCategoryChangeVarient = (e) => {
        setAgriTransport({ ...agriTransport, [e.target.name]: e.target.value })
        getProductListVarient(e.target.value)
    }
    const getProductListVarient = async (val) => {
        let forGet = {
            categoryName: agriTransport.category,
            subCategoryName: agriTransport.subCategory,
            brandName: val,
            catalogue: catalogue,
        }
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-product`,
                forGet,
                config
            )
            .then((data) => {
                setProductlist(data.data.product)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const getBrandCategoryList = async (val) => {
        let forGetBrands = {
            categoryName: agriTransport.category,
            subCategoryName: val,
            catalogue: catalogue,
        }
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-brand`,
                forGetBrands,
                config
            )
            .then((data) => {
                console.log('brand in input', data)
                setBrandCategorylist(data.data.brand)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //PAN
    const handleUploadPAN = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setPANImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPAN/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        console.log('url', url)
                        setAgriTransport({
                            ...agriTransport,
                            driverPancardPhoto: url,
                        })
                    })
                }
            )
        }
    }

    //handleUploadAADHAR
    const handleUploadAADHAR = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setAAdharImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverAADhar/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        console.log('url', url)
                        setAgriTransport({
                            ...agriTransport,
                            driverAdhaarPhoto: url,
                        })
                    })
                }
            )
        }
    }

    // Driver Licence Photo
    const handleUploadDLP = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setDriverLicenseImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverLicence/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        console.log('url', url)
                        setAgriTransport({
                            ...agriTransport,
                            drivingLicencePhoto: url,
                        })
                    })
                }
            )
        }
    }

    const mapdata = (data) => {
        console.log('ran')
        setAgriTransport({
            ...agriTransport,
            state: data.state,
            district: data.district,
            village: data.village,
            pincode: data.pincode,
            address: data.address,
            lat: data.latitude,
            lng: data.longitude,
        })
    }
    const handleFarmer = (e) => {
        if (e.target.value.length == 10) {
            getFormerinfo(e.target.value)
        } else {
            setFormerinfo({})
        }
    }
    const getFormerinfo = async (Mob) => {
        const token = localStorage.getItem('token')
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        const farmerNumber = {
            mobile_no: Mob,
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/register-details`,
                farmerNumber,
                config
            )
            .then((res) => {
                const d = res.data.data.data
                localStorage.setItem('FormerId', d.user_id)
                setCreatedById(d.user_id)
                setCreatedByRole(d.role)
                localStorage.setItem('microId', '')
                setFormerinfo(d)
            })
            .catch((err) => {
                console.log(err)
                setFormerinfo({})
            })
    }
    return (
        <>
        <div class="card card-table p-10-25">
                            <div class="card-header">
                                <h5 class="card-title"> Info</h5>
                            </div>
           <div class="tab-content">
                                        <div action="#">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="farmerNum"
                                                        //  label="Farmer Number"
                                                        placeholder="Farmer Number"
                                                        variant="standard"
                                                        //  value={}
                                                        onChange={(e) =>
                                                            handleFarmer(e)
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.role
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.id
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.first_name
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerState"
                                                        //  label="State"
                                                        placeholder="State"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.state
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="farmerDistrict"
                                                        disabled
                                                        //  label="District"
                                                        placeholder="District"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.district
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        disabled
                                                        fullWidth
                                                        id="farmerVillage"
                                                        //  label="Village"
                                                        placeholder="Village"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.village
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        disabled
                                                        fullWidth
                                                        id="farmerPincode"
                                                        //  label="Pincode"
                                                        placeholder="Pincode"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.pincode
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>
                                          
                                        </div>
                                        </div>

                                    </div>
            {otpWindow ? (
                <OtpBox
                    mobileNumber={agriTransport.mobileNumber}
                    syncOtpSubmit={syncOtpSubmit}
                />
            ) : (
                <>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card card-table p-10-25">
                                <div class="card-header">
                                    <h5 class="card-title">
                                        Create Agri Transport
                                    </h5>
                                </div>
                                <div class="card-body">
                                    <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                                        <li class="nav-item">
                                            <a
                                                className={
                                                    tab === 1
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                Personal Info
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                className={
                                                    tab === 2
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                Contact Details
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                className={
                                                    tab === 3
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                {' '}
                                                Vehicle Info
                                            </a>
                                        </li>
                                        {/* <li class="nav-item"><a className={tab === 4 ? "nav-link active" : "nav-link"}> Vehicle Rental Details</a></li>
                <li class="nav-item"><a className={tab === 5 ? "nav-link active" : "nav-link"}> Driver Details</a></li> */}
                                        {/* <li class="nav-item"><a className={tab === 6 ? "nav-link active" : "nav-link"}> Commission Details</a></li> */}
                                        {/* <li class="nav-item"><a className={tab === 7 ? "nav-link active" : "nav-link"}>Bank Account Info</a></li> */}
                                    </ul>
                                    <div class="tab-content">
                                        {/* Personal Details */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab1"
                                        >
                                            {
                                                tab === 1 && (
                                                    // <form onSubmit={() => setTab(2)}>
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="fName"
                                                                label="First Name"
                                                                variant="standard"
                                                                className="mt-3"
                                                                name="firstName"
                                                                value={
                                                                    agriTransport.firstName
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriTransport(
                                                                        {
                                                                            ...agriTransport,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="lName"
                                                                label="Last Name"
                                                                variant="standard"
                                                                className="mt-3"
                                                                name="lastName"
                                                                value={
                                                                    agriTransport.lastName
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriTransport(
                                                                        {
                                                                            ...agriTransport,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="mobile"
                                                                label="Mobile Number"
                                                                variant="standard"
                                                                className="mt-3"
                                                                style={{
                                                                    marginBottom:
                                                                        '1.25rem',
                                                                }}
                                                                name="mobileNumber"
                                                                value={
                                                                    agriTransport.mobileNumber
                                                                }
                                                                onChange={(e) =>
                                                                    handleMobVal(
                                                                        e
                                                                    )
                                                                }
                                                                // onChange={(e) => setAgriTransport({ ...agriTransport, [e.target.name]: e.target.value })}
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                >
                                                                    Gender*
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    label="Gender"
                                                                    color="success"
                                                                    name="gender"
                                                                    value={
                                                                        agriTransport.gender
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setAgriTransport(
                                                                            {
                                                                                ...agriTransport,
                                                                                [e
                                                                                    .target
                                                                                    .name]:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>
                                                                            None
                                                                        </em>
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        value={
                                                                            'Male'
                                                                        }
                                                                    >
                                                                        Male
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        value={
                                                                            'Female'
                                                                        }
                                                                    >
                                                                        Female
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        value={
                                                                            'Others'
                                                                        }
                                                                    >
                                                                        Others
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>

                                                        <div class="col-md-4">
                                                            <InputLabel
                                                                id="demo-simple-select-standard-label"
                                                                style={{
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                DOB
                                                            </InputLabel>
                                                            <CssTextField
                                                                type="date"
                                                                fullWidth
                                                                variant="standard"
                                                                style={{
                                                                    marginTop:
                                                                        '10px',
                                                                    marginBottom:
                                                                        '10px',
                                                                }}
                                                                name="dateOfBirth"
                                                                value={
                                                                    agriTransport.dateOfBirth
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriTransport(
                                                                        {
                                                                            ...agriTransport,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                >
                                                                    Business
                                                                    Mode*
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    label="business_mode"
                                                                    color="success"
                                                                    name="business_mode"
                                                                    value={
                                                                        agriTransport.business_mode
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setAgriTransport(
                                                                            {
                                                                                ...agriTransport,
                                                                                [e
                                                                                    .target
                                                                                    .name]:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>
                                                                            None
                                                                        </em>
                                                                    </MenuItem>
                                                                    <MenuItem value="Renter">
                                                                        Renter
                                                                    </MenuItem>
                                                                    <MenuItem value="Showroom">
                                                                        Showroom
                                                                    </MenuItem>
                                                                    <MenuItem value="Distributor">
                                                                        Distributor
                                                                    </MenuItem>
                                                                    <MenuItem value="Manufacture">
                                                                        Manufacture
                                                                    </MenuItem>
                                                                    <MenuItem value="Organisation">
                                                                        Organisation
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>

                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                            <button
                                                                onClick={(e) =>
                                                                    setTab(2)
                                                                }
                                                                class=" w-16 btn btn-primary"
                                                            >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                                // </form>
                                            }
                                        </div>

                                        {/* Contact Details */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab2"
                                        >
                                            {tab === 2 && (
                                                <div class="row">
                                                    <div
                                                        class="col-md-3"
                                                        style={{
                                                            marginTop: '2%',
                                                        }}
                                                    >
                                                        <Map
                                                            center={{
                                                                lat: 18.5204,
                                                                lng: 73.8567,
                                                            }}
                                                            height="300px"
                                                            zoom={15}
                                                            mapdata={mapdata}
                                                        />
                                                    </div>

                                                    <div class="col-md-12 text-end justify-space-between">
                                                        <button
                                                            className="w-16 btn btn-secondary"
                                                            style={{
                                                                marginRight:
                                                                    '10px',
                                                            }}
                                                            onClick={(e) =>
                                                                setTab(1)
                                                            }
                                                        >
                                                            Previous
                                                        </button>
                                                        <button
                                                            onClick={(e) =>
                                                                setTab(3)
                                                            }
                                                            className="w-16 btn btn-primary"
                                                        >
                                                            Next
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {/* Transport Info */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab3"
                                        >
                                            {
                                                tab === 3 && (
                                                    // <form onSubmit={() => setTab(4)}>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                >
                                                                    Category
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    label="Category"
                                                                    color="success"
                                                                    name="category"
                                                                    value={
                                                                        agriTransport.category
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleCategoryChangeVarient(
                                                                            e
                                                                        )
                                                                    }
                                                                    // onChange={(e) => setAgriTransport({ ...agriTransport, [e.target.name]: e.target.value })}
                                                                >
                                                                    {/* <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="0">Select State</MenuItem>
                              <MenuItem value="Hindi">Hindi</MenuItem>
                              <MenuItem value="Bengali">Bengali</MenuItem>
                              <MenuItem value="Urdu">Urdu</MenuItem>
                              <MenuItem value="Punjabi">Punjabi</MenuItem>
                              <MenuItem value="Marathi">Marathi</MenuItem>
                              <MenuItem value="Telugu">Telugu</MenuItem>
                              <MenuItem value="Tamil">Tamil</MenuItem> */}
                                                                    {Categorylist &&
                                                                        Categorylist.map(
                                                                            (
                                                                                val,
                                                                                ind
                                                                            ) => {
                                                                                // {console.log("Categorylist",val)}
                                                                                return (
                                                                                    <MenuItem
                                                                                        value={
                                                                                            val.categoryName
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            val.categoryName
                                                                                        }
                                                                                    </MenuItem>
                                                                                )
                                                                            }
                                                                        )}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                >
                                                                    Body Type
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    label="Sub Category"
                                                                    color="success"
                                                                    name="subCategory"
                                                                    value={
                                                                        agriTransport.subCategory
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleSUbCategoryChangeVarient(
                                                                            e
                                                                        )
                                                                    }
                                                                    // onChange={(e) => setAgriTransport({ ...agriTransport, [e.target.name]: e.target.value })}
                                                                >
                                                                    {/* <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="0">Select State</MenuItem>
                              <MenuItem value="Hindi">Hindi</MenuItem>
                              <MenuItem value="Bengali">Bengali</MenuItem>
                              <MenuItem value="Urdu">Urdu</MenuItem>
                              <MenuItem value="Punjabi">Punjabi</MenuItem>
                              <MenuItem value="Marathi">Marathi</MenuItem>
                              <MenuItem value="Telugu">Telugu</MenuItem>
                              <MenuItem value="Tamil">Tamil</MenuItem> */}
                                                                    {subCategorylist &&
                                                                        subCategorylist.map(
                                                                            (
                                                                                val,
                                                                                ind
                                                                            ) => {
                                                                                // {console.log("Categorylist",val)}
                                                                                return (
                                                                                    <MenuItem
                                                                                        value={
                                                                                            val.subCategoryName
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            val.subCategoryName
                                                                                        }
                                                                                    </MenuItem>
                                                                                )
                                                                            }
                                                                        )}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                >
                                                                    Brand Name
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    label="Brand Name"
                                                                    color="success"
                                                                    name="brandName"
                                                                    value={
                                                                        agriTransport.brandName
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleBRANDCategoryChangeVarient(
                                                                            e
                                                                        )
                                                                    }
                                                                    // onChange={(e) => setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })}
                                                                >
                                                                    {BrandCategorylist &&
                                                                        BrandCategorylist.map(
                                                                            (
                                                                                val,
                                                                                ind
                                                                            ) => {
                                                                                // {console.log("Categorylist",val)}
                                                                                return (
                                                                                    <MenuItem
                                                                                        value={
                                                                                            val.brandName
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            val.brandName
                                                                                        }
                                                                                    </MenuItem>
                                                                                )
                                                                            }
                                                                        )}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                >
                                                                    Type Of
                                                                    Vehicle
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    label="Product Name"
                                                                    color="success"
                                                                    name="typeOfVehicle"
                                                                    value={
                                                                        agriTransport.typeOfVehicle
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setAgriTransport(
                                                                            {
                                                                                ...agriTransport,
                                                                                [e
                                                                                    .target
                                                                                    .name]:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    {Productlist &&
                                                                        Productlist.map(
                                                                            (
                                                                                val,
                                                                                ind
                                                                            ) => {
                                                                                // {console.log("Categorylist",val)}
                                                                                return (
                                                                                    <MenuItem
                                                                                        value={
                                                                                            val.productName
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            val.productName
                                                                                        }
                                                                                    </MenuItem>
                                                                                )
                                                                            }
                                                                        )}
                                                                </Select>
                                                            </FormControl>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                id="machineNumber"
                                                                label="Vehicle Number"
                                                                variant="standard"
                                                                name="vehicleNumber"
                                                                value={
                                                                    agriTransport.vehicleNumber
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriTransport(
                                                                        {
                                                                            ...agriTransport,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            className="col-md-12 mt-3"
                                                            style={{}}
                                                        >
                                                            <InputLabel
                                                                id="demo-simple-select-standard-label"
                                                                style={{
                                                                    color: 'black',
                                                                }}
                                                                className="mb-3"
                                                            >
                                                                Document :
                                                            </InputLabel>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <InputLabel
                                                                id="demo-simple-select-standard-label"
                                                                style={{
                                                                    color: 'black',
                                                                    marginTop:
                                                                        '22px',
                                                                }}
                                                            >
                                                                Document Expire
                                                                Date:
                                                            </InputLabel>
                                                            <CssTextField
                                                                fullWidth
                                                                type="date"
                                                                id="documentExpiryDate"
                                                                variant="standard"
                                                                name="documentExpiryDate"
                                                                value={
                                                                    agriTransport.documentExpiryDate
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriTransport(
                                                                        {
                                                                            ...agriTransport,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-md-2 col-sm-4">
                                                            <label
                                                                htmlFor="contained-button-file"
                                                                style={{
                                                                    marginTop:
                                                                        '60px',
                                                                }}
                                                            >
                                                                <Button
                                                                    variant="contained"
                                                                    component="span"
                                                                    color="success"
                                                                >
                                                                    <Input
                                                                        accept="image/"
                                                                        id="contained-button-file"
                                                                        multiple="false"
                                                                        type="file"
                                                                        onChange={
                                                                            handleUploadDoc
                                                                        }
                                                                    />
                                                                    Select File
                                                                </Button>
                                                            </label>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <aside
                                                                style={
                                                                    thumbsContainerState
                                                                }
                                                            >
                                                                <div
                                                                    style={
                                                                        thumb
                                                                    }
                                                                >
                                                                    <div
                                                                        style={
                                                                            thumbInner
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={
                                                                                docImg
                                                                            }
                                                                            style={
                                                                                img
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </aside>
                                                        </div>
                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                            <button
                                                                className="w-16 btn btn-secondary"
                                                                style={{
                                                                    marginRight:
                                                                        '10px',
                                                                }}
                                                                onClick={(e) =>
                                                                    setTab(2)
                                                                }
                                                            >
                                                                Previous
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={(e) =>
                                                                    syncOtpSubmit()
                                                                }
                                                                class=" w-16 btn btn-primary"
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                                // </form>
                                            }
                                        </div>

                                        {/* Bank Acc Info  */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab7"
                                        >
                                            {
                                                tab === 7 && (
                                                    // <form action="#" onSubmit={onSubmitAgriTransport}>
                                                    <div class="row">
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="accNum"
                                                                label="Bank Acc"
                                                                variant="standard"
                                                                type="number"
                                                                name="bankAccountNumber"
                                                                value={
                                                                    agriTransport.bankAccountNumber
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriTransport(
                                                                        {
                                                                            ...agriTransport,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="accName"
                                                                label="Account Name"
                                                                variant="standard"
                                                                name="bankAccountName"
                                                                value={
                                                                    agriTransport.bankAccountName
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriTransport(
                                                                        {
                                                                            ...agriTransport,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="ifscCode"
                                                                label="IFSC Code"
                                                                variant="standard"
                                                                name="ifscCode"
                                                                value={
                                                                    agriTransport.ifscCode
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriTransport(
                                                                        {
                                                                            ...agriTransport,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="bankName"
                                                                label="Bank Name"
                                                                pattern="[a-zA-Z]*"
                                                                type="text"
                                                                // id="txtNumeric"
                                                                variant="standard"
                                                                name="bankName"
                                                                value={
                                                                    agriTransport.bankName
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriTransport(
                                                                        {
                                                                            ...agriTransport,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="branchName"
                                                                label="Branch Name"
                                                                variant="standard"
                                                                name="bankBranchName"
                                                                value={
                                                                    agriTransport.bankBranchName
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriTransport(
                                                                        {
                                                                            ...agriTransport,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                            <button
                                                                className="w-16 btn btn-secondary"
                                                                style={{
                                                                    marginRight:
                                                                        '10px',
                                                                }}
                                                                onClick={(e) =>
                                                                    setTab(3)
                                                                }
                                                            >
                                                                Previous
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={(e) =>
                                                                    onSubmitAgriTransport()
                                                                }
                                                                class=" w-16 btn btn-primary"
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                                // </form>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
export default AgriTransport
