import React, { useEffect, useState } from 'react'
import { API } from '../../API/index'
import Swal from 'sweetalert2'

const appSettingclear = {
    iosCustomerUrl: '',
    andriodCustomerUrl: '',
    isSignUpNameRequired: true,
    isDeliveryTipEnabled: true,
}

function AppSettings() {
    const [appsetting, setappsetting] = useState({
        iosCustomerUrl: '',
        andriodCustomerUrl: '',
        isSignUpNameRequired: true,
        isDeliveryTipEnabled: true,
    })

    const handleBook = async () => {
        console.log('handleBook', appsetting)
        try {
            const { data } = await API.post('/addAppSetting', appsetting)
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)
                setappsetting(appSettingclear)
            } else {
                setappsetting(appSettingclear)
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setappsetting(appSettingclear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">App Settings</h5>
                </div>
                <div class="card-body ">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div class="row form-group">
                            <div class="col-sm-7">
                                <div class="row form-group">
                                    <label
                                        for="addressline1"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        iOS Customer URL
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="addressline1"
                                        placeholder="-"
                                        value={appsetting.iosCustomerUrl}
                                        onChange={(e) =>
                                            setappsetting({
                                                ...appsetting,
                                                iosCustomerUrl: e.target.value,
                                            })
                                        }
                                    />
                                </div>

                                <div class="row form-group">
                                    <label
                                        for="addressline1"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        Android Customer URL
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="addressline1"
                                        placeholder="https://play.google.com/store/apps/details?id=com.goteso.mastercart"
                                        value={appsetting.andriodCustomerUrl}
                                        onChange={(e) =>
                                            setappsetting({
                                                ...appsetting,
                                                andriodCustomerUrl:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </div>

                                <div class="row form-group">
                                    <label
                                        for="addressline1"
                                        class="p-l-0 col-md-6 col-form-label input-label pt-10"
                                    >
                                        Signup Name Required
                                    </label>
                                    <span class="col-6 col-sm-6 mt-10">
                                        <input
                                            type="checkbox"
                                            class="toggle-switch-input lb_check"
                                            id="notification_switch1"
                                            //   value={appsetting.isSignUpNameRequired}
                                            //   onChange={(e)=>setappsetting({...appsetting, isSignUpNameRequired: e.target.value})}
                                        />
                                        <span class="toggle-switch-label ms-auto">
                                            <span class="toggle-switch-indicator"></span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="text-end">
                            <button
                                type="submit"
                                class="btn btn-primary"
                                onClick={() => handleBook()}
                            >
                                Save Changes
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AppSettings
