import React, { useState, useEffect } from 'react'
import { CssTextField } from '../../Styles'
import FormControl from '@mui/material/FormControl'
import { styled } from '@mui/material/styles'
import InputLabel from '@mui/material/InputLabel'
import { useDropzone } from 'react-dropzone'
import {
    thumbsContainerState,
    thumb,
    img,
    thumbInner,
    baseStyle,
    baseStyle02,
} from '../../Styles'
import Button from '@mui/material/Button'
// import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem'
import Livestockbookingtable from './Livestockbookingtable'
import Swal from 'sweetalert2'
import { API } from '../../../API/index'
import axios from 'axios'
import Select from 'react-select'
import ProductList from '../CreateInputBooking/ProductList/ProductList'
import { Grid, Drawer } from '@mui/material'
import Cart from '../CreateInputBooking/ProductList/Cart/cart'
const formvalueclear = {
    subCategory: '',
    brand: '',
    product: '',
    productImage: '',
    unit: '',
    price: '',
    stock: '',
    quantity: '',
    totalPrice: '',
    No: '',
}
const LivestockBookingclear = {
    farmerId: '',
    MicroEnterpreneurId: '',
    Firm: '',
    modeOfBooking: '',
    status: '',
}
function LivestockBooking(props) {
    console.log('props', props)

    const [addInput, setAddInput] = useState([
        { id: 1, subCategory: '', brand: '' },
    ])
    const [state, setstate] = useState([])
    const [cartItems, setCartItems] = useState([])
    const [open, setOpen] = useState()

    const [Firmlist, setFirmlist] = useState([])
    const [categorylist, setcategorylist] = useState([])
    const [subcategorylist, setsubcategorylist] = useState([])
    const [brandlist, setbrandlist] = useState([])
    const [productlist, setproductlist] = useState([])
    const [categoryname, setCategoryName] = useState('')
    const [subcategoryName, setsubcategoryName] = useState('')
    const [list, setlist] = useState([])
    const [catlist, setCatlist] = useState('')
    const [BrandName, setBrandName] = useState('')
    const [Quantitylist, setQuantitylist] = useState([])
    const [unitnewArray, setunitnewArray] = useState([])

    const [formvalue, setFormValues] = useState([
        {
            subCategory: '',
            brand: '',
            product: '',
            productImage: '',
            unit: '',
            price: '',
            stock: '',
            quantity: '',
            totalPrice: '',
            No: '',
        },
    ])
    const [stockValues, setstockValues] = useState([
        {
            id: '',
            stock: '',
        },
    ])

    const [stockid, setstockid] = useState('')
    const [LivestockBookfetch, setLivestockBookfetch] = useState([])
    useEffect(() => {
        // getState();
        // getLivestockBooking();
        getFirmList()
        getCategoryList()
    }, [])

    const getFirmList = async () => {
        const { data } = await API.get(`/getLivestocks?type=0`)
        // console.log("hkjjhj",data)
        setFirmlist(data?.result)
        console.log('firm search', data)
    }
    const Firmoption =
        Firmlist &&
        Firmlist.map((item, ind) => ({
            value: item.mobileNumber,
            label: item.mobileNumber,
        }))
    // categorylist
    const getCategoryList = async () => {
    
        var token = localStorage.getItem("token");
        var config = {
          headers: { 'Authorization': `Bearer ${token}` }
        };
        await axios.get(`https://prod-api.krishione.com/farmer-function/category/livestock`, config)
          .then(res => {
            console.log("d :", res.data.category);
            setcategorylist(res.data.category);
          })
      }

   
    const categoryoption = categorylist.map((item, ind) => ({
        value: item.categoryName,
        label: item.categoryName,
    }))
   
    // subcategory
    const getsubCategoryList = async (val) => {
        // const { data } = await API.get(`/getProductSubCategory?type=0&title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=`+val);
        // console.log("hkjjhj",data)
        // setsubCategorylist(data?.result);
        var token = localStorage.getItem("token");
        var config = {
          headers: { 'Authorization': `Bearer ${token}` }
        };
        setCategoryName(val)
        const subCat = {
          catalogue: "livestock",
          categoryName: val
        }

        await axios.post(`https://prod-api.krishione.com/farmer-function/get-subcategory`, subCat, config)
          .then(res => {
            console.log("get sub cat in input catalogue", res.data.subcategory);
            setsubcategorylist(res.data.subcategory);
          })
      }

    const subcategoryoption = subcategorylist.map((item, ind) => ({
        value: item.subCategoryName,
        label: item.subCategoryName,
    }))

    // brand
    const getbrandList = async (val) => {
        // const { data } = await API.get(`/getProductBrand?type=0&title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=`+categoryName+'&subCategoryName='+val);
        // console.log("hkjjhj",data)
        // setbrandlist(data?.result);
        let forGet = {
          "categoryName": categoryname,
          "subCategoryName": val,
          "catalogue": "livestock"
        };
        var token = localStorage.getItem("token");
        var config = {
          headers: { 'Authorization': `Bearer ${token}` }
        };
        await axios.post(`https://prod-api.krishione.com/farmer-function/get-brand`, forGet, config)
          .then(data => {
            console.log("brand in input", data);
            setbrandlist(data.data.brand)
          })
          .catch(err => {
            console.log(err)
          })
    
      }
    
    const brandoption = brandlist.map((item, ind) => ({
        value: item.brandName,
        label: item.brandName,
    }))
    // product
    const getproductList = async (val) => {

        let forGet = {
            "categoryName": categoryname,
            "subCategoryName": subcategoryName,
            "catalogue": "livestock",
            "brandName":val,
            "mobile_number": localStorage.getItem('mobile')

          };
          var token = localStorage.getItem("token");
          var config = {
            headers: { 'Authorization': `Bearer ${token}` }
          };
          await axios.post(`https://prod-api.krishione.com/farmer-function/get-fltered-products`, forGet, config)
            .then(data => {
              console.log("brand in input", data);
              setproductlist(data.data.product)
            })
            .catch(err => {
              console.log(err)
            })
     
    }
    const productoption =
        productlist &&
        productlist.map((item, ind) => ({
            value: item.productName,
            label: item.productName,
            image: item.productImage,
        }))

    const quantityoption =
        productlist &&
        productlist.map((item, ind) => ({
            value: item.quantity,
            label: item.quantity,
        }))
    const getQuantityList = async (val) => {
        const { data } = await API.get(
            `/getProductCatelogQuantity?type=1&title=ProductCatelog&catelogName=LiveStockCatelog&categoryName=` +
                categoryname +
                '&subCategoryName=' +
                subcategoryName +
                '&brandName=' +
                BrandName +
                `&productName=` +
                val +
                `&id=` +
                catlist
        )
        // console.log("hkjjhj",data)
        setQuantitylist(data?.result)
        console.log('product search', data)
    }

    const QuantitylistOption =
        Quantitylist &&
        Quantitylist.map((item, ind) => ({
            value: item.quantity,
            label: item.quantity,
        }))

    const unitnewArraylist =
        unitnewArray &&
        unitnewArray.map((item, ind) => ({
            value: item.unit,
            label: item.unit,
        }))
    const bookingmodeoption = [
        { value: 'DELIVERY', label: 'DELIVERY' },
        { value: 'PICKUP', label: 'PICKUP' },
    ]
    // const getLivestockBooking = async () => {
    //   // const { data } = await API.get(`/getLiveStockBookings?type=0&bookedBy=Farmer`);
    //   // setLivestockBookfetch(data?.result);
    //   // var products = data.result.map(item => item.inputs[0])
    //   // // setinputBookfetch(products);
    //   // setproductlist(products)
    //   const id = "test";
    //   var token = localStorage.getItem("token");
    //   var config = {
    //     headers: { 'Authorization': `Bearer ${token}` }
    //   };
    //   const { data } = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/farmer-function/live-stock/${id}`,
    //     // {
    //     // params: {
    //     //   id: id
    //     //   }
    //     // },
    //     config)
    //     .then(res => {
    //       console.log("livestock get: ", res);
    //     })
    //   // var products = data.result.map(item => item.inputs[0])
    //   // setinputBookfetch(products);
    //   // setproductlist(products)
    //   console.log("data of products:", data)
    // }

    const [LivestockBooking, setlivestockBooking] = useState({
        farmerId: '',
        MicroEnterpreneurId: '',
        Firm: '',
        modeOfBooking: '',
        status: '',
        bookedBy: 'MI',
        createdBy: 'Admin',
    })

    const handleChange = (i, e) => {
        let newFormValues = [...formvalue]

        let newstockValues = [...stockValues]
        newFormValues[i][e.target.name] = e.target.value

        setFormValues(newFormValues)

        let val =
            newFormValues[i][e.target.name] == newFormValues[i]['No']
                ? e.target.value * newFormValues[i]['price']
                : e.target.value
        console.log('val', val)
        newFormValues[i]['totalPrice'] = val

        if (newFormValues[i][e.target.name] == newFormValues[i]['No']) {
            let res = newFormValues[i]['stock'] - e.target.value
            console.log('res', res)

            console.log('newstockValues', newstockValues, i, newstockValues[i])
            newstockValues[i]['stock'] = res
            newstockValues[i]['id'] = stockid
            console.log('newstockValues', newstockValues)
            setstockValues(newstockValues)
        }
    }

    const handleBook = async () => {
        setstate(formvalue, { ...LivestockBooking, inputs: formvalue })
        let livestock = {
            ...LivestockBooking,
            inputs: formvalue,
            remainingStack: stockValues,
            farmerId: props && props.farmer,
            MicroEnterpreneurId: props && props.MicroEnt,
        }
        console.log('handleBook', livestock)
        try {
            const { data } = await API.post('/liveStockBooking', livestock)
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)
                setFormValues([formvalueclear])
                setlivestockBooking([LivestockBookingclear])
                // getLivestockBooking();
                // setCropTypes([]);
            } else {
                setFormValues([formvalueclear])
                setlivestockBooking([LivestockBookingclear])
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setFormValues([formvalueclear])
            setlivestockBooking([LivestockBookingclear])
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formvalue]
        newFormValues.splice(i, 1)
        setFormValues(newFormValues)
    }

    const addFields = () => {
        setstockValues([...stockValues, { id: '', stock: '' }])

        setFormValues([
            ...formvalue,
            {
                subCategory: '',
                brand: '',
                product: '',
                productImage: '',
                unit: '',
                price: '',
                stock: '',
                quantity: '',
                totalPrice: '',
            },
        ])
    }

    // const addFields = () => {
    //   let myArr = [...addInput];
    //   myArr.push({id: myArr.length + 1, subCategory: "", Brand: ""});
    //   setAddInput(myArr);
    //   console.log(addInput);
    // }

    // const removeFields = (e, id) => {
    //   e.preventDefault();
    //   let myArr = [...addInput];
    //   const index = myArr.findIndex(prop => prop.id === id)
    //   myArr.splice(index,1);
    //   setAddInput(myArr);
    // }

    const handlecategory = (e) => {
        setCategoryName(e.value)
        setlivestockBooking({ ...LivestockBooking, category: e.value })
        getsubCategoryList(e.value)
    }
    const handlesubcategory = (i, e) => {
        getbrandList(e.value)
        setsubcategoryName(e.value)
        let newFormValues = [...formvalue]
        newFormValues[i]['subCategory'] = e.value
        setFormValues(newFormValues)
    }
    const handleBrandcategory = (i, e) => {
        getproductList(e.value)
        setBrandName(e.value)
        let newFormValues = [...formvalue]
        newFormValues[i]['brand'] = e.value
        setFormValues(newFormValues)
    }
    const handleunitcategory = (i, e) => {
        // handleValues(i,e)
        // getproductList(e.value)
        let newFormValues = [...formvalue]
        newFormValues[i]['unit'] = e.value
        setFormValues(newFormValues)
    }
    const handlepricecategory = (i, e) => {
        // handleValues(i,e)
        // getproductList(e.value)
        let newFormValues = [...formvalue]
        newFormValues[i]['price'] = e.value
        newFormValues[i]['totalPrice'] = ''
        newFormValues[i]['No'] = ''
        newFormValues[i]['stock'] = ''
        setFormValues(newFormValues)
    }

    const handlestockcategory = (i, e) => {
        let newFormValues = [...formvalue]
        newFormValues[i]['stock'] = e.value
        setFormValues(newFormValues)
        setstockid(e.id)
    }
    const handleProductcategory = (i, e) => {
        // handleValues(i,e)
        getQuantityList(e.value)
        // getproductList(e.value)
        let newFormValues = [...formvalue]
        newFormValues[i]['product'] = e.value

        newFormValues[i]['productImage'] = e.image
        setFormValues(newFormValues)
    }

    const handleOptioncategory = (i, e) => {
        handleValues(i, e)
        // getproductList(e.value)
        let newFormValues = [...formvalue]
        newFormValues[i]['quantity'] = e.value
        setFormValues(newFormValues)
        var newArray = Quantitylist.filter(function (el) {
            return el.quantity == e.value
        })
        setunitnewArray(newArray)
        console.log('newArray1', newArray)
    }
    const handleValues = async (i, e) => {
        console.log(
            'handleValues',
            categoryname,
            formvalue[i]['brand'],
            formvalue[i]['subCategory'],
            formvalue[i]['product'] + '&id=' + catlist
        )
        const { data } = await API.get(
            `/getProductCatelog?type=1&title=ProductCatelog&catelogName=LiveStockCatelog&brandName=` +
                formvalue[i]['brand'] +
                '&subCategoryName=' +
                formvalue[i]['subCategory'] +
                '&categoryName=' +
                categoryname +
                '&productName=' +
                formvalue[i]['product'] +
                '&id=' +
                catlist
        )
        console.log('hkjjhj', data)
        setlist(data?.result)
        // console.log("brand search",data)
        // console.log('handleValues','categoryName='+categoryname&'brandName='+formvalue[i]['Brand']&'title=ProductCatelog'& 'SubCategoryName='+formvalue[i]['subCategory']&'productName='+formvalue[i]['product']&'catelogName=LiveStockCatelog')
    }
    const stockOption =
        list &&
        list.map((item, ind) => ({
            value: item.stock,
            label: item.stock,
            id: item._id,
        }))
    const unitOption =
        list &&
        list.map((item, ind) => ({
            value: item.unit,
            label: item.unit,
        }))
    const priceOption =
        list &&
        list.map((item, ind) => ({
            value: item.price,
            label: item.price,
        }))
    const handlebooking = (e) => {
        var newArray = Firmlist.filter(function (el) {
            return el.mobileNumber == e.value
        })
        console.log('newArray', newArray[0]._id)
        setCatlist(newArray[0]._id)
        setlivestockBooking({
            ...LivestockBooking,
            Firm: e.value,
            firmId: newArray[0]._id,
        })
        getCategoryList(newArray[0]._id)
    }

    const onClickAdd = (clickedItem) => {
        setOpen(true)
        console.log(clickedItem, 'clicked')
        setCartItems((prev) => {
            const isItemInCart = prev.find(
                (item) => item._id === clickedItem._id
            )

            if (isItemInCart) {
                return prev.map((item) =>
                    item._id === clickedItem._id
                        ? { ...item, amount: item.amount + 1 }
                        : item
                )
            }

            return [...prev, { ...clickedItem, amount: 1 }]
        })
    }

    const handleAddToCart = (clickedItem) => {
        setCartItems((prev) => {
            const isItemInCart = prev.find(
                (item) => item._id === clickedItem._id
            )

            if (isItemInCart) {
                return prev.map((item) =>
                    item._id === clickedItem._id
                        ? { ...item, amount: item.amount + 1 }
                        : item
                )
            }

            return [...prev, { ...clickedItem, amount: 1 }]
        })
    }

    const handleRemoveFromCart = (clickedItem) => {
        setCartItems((prev) => {
            const isItemInCart = prev.find(
                (item) => item._id === clickedItem._id
            )

            if (isItemInCart) {
                if (isItemInCart.amount === 1)
                    return prev.filter((item) => item._id !== clickedItem._id)
                return prev.map((item) =>
                    item._id === clickedItem._id
                        ? { ...item, amount: item.amount - 1 }
                        : item
                )
            }

            return [...prev, { ...clickedItem, amount: 1 }]
            // console.log(a)
        })
    }
    const paymentmodeoption = [
        { value: 'cash', label: 'CASH' },
        { value: 'online', label: 'ONLINE' },
    ]
    const handleMobilesearch = (e) => {
        console.log('e', e.target.value)
        if (e.target.value.length == 10) {
            mobilechecksearch(e.target.value)
        } else {
            // setDetails([]);
        }
    }

    const mobilechecksearch = async (number) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }

        var payload = {
            catalogue: 'livestock',
            mobile_number: number,
        }
        localStorage.setItem('mobile', number)

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product-getinventory`,
            payload,
            config
        )
        console.log('data', data)
        setproductlist(data.data)
    }
    return (
        <div>
            <div>
                <div>
                    <div class="row">
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <div
                                    style={{
                                        padding: '30px 0',
                                        display: 'flex',
                                        width: '500px',
                                        justifyContent: 'space-between',
                                        alignItems:"center",
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    <div
                                        class="form-group"
                                        style={{ marginLeft: '2%' }}
                                        className="mt-3"
                                    >
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Search Firm Number"
                                            onChange={(e) =>
                                                handleMobilesearch(e)
                                            }
                                        />
                                    </div>
                                    <div>
                                        <FormControl
                                            variant="standard"
                                            className="mt-3"
                                        >
                                            <Select
                                                style={{
                                                    width: '270px',
                                                    height: '40px',
                                                }}
                                                placeholder={
                                                    'Select Booking Mode '
                                                }
                                                onChange={(e) =>
                                                    setlivestockBooking({
                                                        ...LivestockBooking,
                                                        modeOfBooking: e.value,
                                                    })
                                                }
                                                options={bookingmodeoption}
                                            />
                                        </FormControl>
                                    </div>
                                    </div>

                                    <div
                                    style={{
                                        padding: '30px 0',
                                        display: 'flex',
                                        width: '1032px',
                                        justifyContent: 'space-between',
                                        marginBottom: '60px',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    <div>
                                        <FormControl
                                            variant="standard"
                                            className="mt-3"
                                        >
                                            <Select
                                                style={{
                                                    width: '270px',
                                                    height: '40px',
                                                }}
                                                placeholder={
                                                    'Select Payment Mode'
                                                }
                                                onChange={(e) =>
                                                    setlivestockBooking({
                                                        ...LivestockBooking,
                                                        paymentMode: e.value,
                                                    })
                                                }
                                                options={paymentmodeoption}
                                            />
                                        </FormControl>
                                    </div>
                                    <div>
                                        <FormControl
                                            variant="standard"
                                            className="mt-3"
                                        >
                                            <Select
                                                style={{
                                                    width: '270px',
                                                    height: '40px',
                                                }}
                                                placeholder={'Select category '}
                                                onChange={(e) =>
                                                    handlecategory(e)
                                                }
                                                options={categoryoption}
                                            />
                                        </FormControl>
                                    </div>
                                    {formvalue.map((element, index) => {
                                        return (
                                            <>
                                                <div>
                                                    {console.log(
                                                        'input',
                                                        formvalue
                                                    )}
                                                    <FormControl
                                                        variant="standard"
                                                        className="mt-3"
                                                    >
                                                        <Select
                                                            style={{
                                                                width: '250px',
                                                                height: '40px',
                                                            }}
                                                            placeholder={
                                                                'Select Sub category '
                                                            }
                                                            onChange={(e) =>
                                                                handlesubcategory(
                                                                    index,
                                                                    e
                                                                )
                                                            }
                                                            options={
                                                                subcategoryoption
                                                            }
                                                        />
                                                    </FormControl>
                                                </div>

                                                <div>
                                                    <FormControl
                                                        variant="standard"
                                                        className="mt-3"
                                                    >
                                                        <Select
                                                            style={{
                                                                width: '250px',
                                                                height: '40px',
                                                            }}
                                                            placeholder={
                                                                'Select Brand'
                                                            }
                                                            onChange={(e) =>
                                                                handleBrandcategory(
                                                                    index,
                                                                    e
                                                                )
                                                            }
                                                            options={
                                                                brandoption
                                                            }
                                                        />
                                                    </FormControl>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                                

                                <ProductList
                                    products={productlist}
                                    onClickAdd={onClickAdd}
                                />
                            </Grid>
                            <Drawer
                                anchor="right"
                                open={open}
                                onClose={() => setOpen()}
                            >
                                <Cart
                                    modeOfBooking={
                                        LivestockBooking.modeOfBooking
                                    }
                                    inputBooking={LivestockBooking}
                                    cartItems={cartItems}
                                    addToCart={handleAddToCart}
                                    type="livestock"
                                    removeFromCart={handleRemoveFromCart}
                                />
                            </Drawer>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LivestockBooking
