import axios from 'axios'
import React from 'react'
import { API } from '../../API'
import Machinebookingtable from '../Bookings/CreateMachineBooking/Machinebookingtable'
import ReactPaginate from 'react-paginate'

const MachineTable = () => {
    const [inputMachinefetch, setinputMachinefetch] = React.useState([])
    const [count, setCount] = React.useState(0)
    const [currentPage, setCurrentPage] = React.useState(0)

    const getMachineBooking = async (page) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/all-machine-bookings?skip=${page*10}&limit=10`,
                config
            )
            .then((res) => {
                setinputMachinefetch(res.data.data)
                setCount(res.data.count)
            })
    }

    React.useEffect(() => {
        getMachineBooking(0)
    }, [])

    const onPageChange = (page) => {
        setCurrentPage(page.selected)

        console.log('ran', page)
        getMachineBooking(page.selected)
    }
    return (
        <div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card card-table">
                        <div class="card-body">
                            <div class="table-responsive">
                                <div
                                    id="DataTables_Table_0_wrapper"
                                    class="dataTables_wrapper dt-bootstrap4 no-footer"
                                >
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <h3 className="mt-3">
                                                All Agri Machine Bookings
                                            </h3>
                                            <div
                                                class="dataTables_length"
                                                id="DataTables_Table_0_length"
                                            >
                                                <hr />
                                                <label>
                                                    Show
                                                    <select
                                                        name="DataTables_Table_0_length"
                                                        aria-controls="DataTables_Table_0"
                                                        class="custom-select custom-select-sm form-control form-control-sm"
                                                    >
                                                        <option value="10">
                                                            10
                                                        </option>
                                                        <option value="25">
                                                            25
                                                        </option>
                                                        <option value="50">
                                                            50
                                                        </option>
                                                        <option value="100">
                                                            100
                                                        </option>
                                                    </select>
                                                    entries
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 position_right">
                                            <div class="form-group">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Search"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <Machinebookingtable
                                            inputs={inputMachinefetch}
                                            count={count}
                                            onPageChange={onPageChange}
                                        />
                                    </div>
                                    <div class="row">
                                        <ReactPaginate
                                            previousLabel={''}
                                            nextLabel={''}
                                            forcePage={currentPage}
                                            onPageChange={(page) =>
                                                onPageChange(page)
                                            }
                                            pageCount={count/10}
                                            breakLabel={'...'}
                                            pageRangeDisplayed={10}
                                            marginPagesDisplayed={10}
                                            activeClassName="active"
                                            pageClassName="page-item"
                                            breakClassName="page-item"
                                            nextLinkClassName="page-link"
                                            pageLinkClassName="page-link"
                                            breakLinkClassName="page-link"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item next-item"
                                            previousClassName="page-item prev-item"
                                            containerClassName={
                                                'pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1'
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MachineTable
