import { useMemo } from 'react'
import { useTable, useSortBy } from 'react-table'
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

import { COLUMNS } from './columns'
import './count.css'

const CountTable = ({ counts }) => {
    const columns = useMemo(() => COLUMNS, [])

    const tableInstance = useTable(
        {
            columns,
            data: counts,
        },
        useSortBy
    )

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance

    return (
        <>
            <div class="table-container">
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button report-table"
                    table="report_table"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"
                />
                <table {...getTableProps()} id="report_table">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps()
                                        )}
                                    >
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <MdArrowDownward />
                                                ) : (
                                                    <MdArrowUpward />
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default CountTable
