import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { API } from '../../API'
import Livestockbookingtable from '../Bookings/LiveStock/Livestockbookingtable'

const Livestocktable = () => {
    const [LivestockBookfetch, setLivestockBookfetch] = useState([])
    useEffect(() => {
        getLivestockBooking()
    }, [])

    const getLivestockBooking = async () => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/all-livestock-bookings`,
                config
            )
            .then((res) => {
                console.log('checking ma c booking', res.data.data)
                setLivestockBookfetch(res.data.data)
            })

        const { data } = await API.get(
            `/getLiveStockBookings?type=0&bookedBy=Farmer`
        )
        setLivestockBookfetch(data?.result)
        // setinputBookfetch(products);
    }

    return (
        <div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card card-table">
                        <div class="card-body">
                            <div class="table-responsive">
                                <div
                                    id="DataTables_Table_0_wrapper"
                                    class="dataTables_wrapper dt-bootstrap4 no-footer"
                                >
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <h3 className="mt-3">
                                                All Livestock Bookings
                                            </h3>
                                            <div
                                                class="dataTables_length"
                                                id="DataTables_Table_0_length"
                                            >
                                                <hr />
                                                <label>
                                                    Show
                                                    <select
                                                        name="DataTables_Table_0_length"
                                                        aria-controls="DataTables_Table_0"
                                                        class="custom-select custom-select-sm form-control form-control-sm"
                                                    >
                                                        <option value="10">
                                                            10
                                                        </option>
                                                        <option value="25">
                                                            25
                                                        </option>
                                                        <option value="50">
                                                            50
                                                        </option>
                                                        <option value="100">
                                                            100
                                                        </option>
                                                    </select>
                                                    entries
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 position_right">
                                            <div class="form-group">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Search"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <Livestockbookingtable
                                            inputs={LivestockBookfetch}
                                        />
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-5">
                                            <div
                                                class="dataTables_info"
                                                id="DataTables_Table_0_info"
                                                role="status"
                                                aria-live="polite"
                                            >
                                                Showing 1 to 10 of 12 entries
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-7">
                                            <div
                                                class="dataTables_paginate paging_simple_numbers"
                                                id="DataTables_Table_0_paginate"
                                            >
                                                <ul class="pagination">
                                                    <li
                                                        class="paginate_button page-item previous disabled"
                                                        id="DataTables_Table_0_previous"
                                                    >
                                                        <a
                                                            href="#"
                                                            aria-controls="DataTables_Table_0"
                                                            data-dt-idx="0"
                                                            tabindex="0"
                                                            class="page-link"
                                                        >
                                                            Previous
                                                        </a>
                                                    </li>
                                                    <li class="paginate_button page-item active">
                                                        <a
                                                            href="#"
                                                            aria-controls="DataTables_Table_0"
                                                            data-dt-idx="1"
                                                            tabindex="0"
                                                            class="page-link"
                                                        >
                                                            1
                                                        </a>
                                                    </li>
                                                    <li class="paginate_button page-item ">
                                                        <a
                                                            href="#"
                                                            aria-controls="DataTables_Table_0"
                                                            data-dt-idx="2"
                                                            tabindex="0"
                                                            class="page-link"
                                                        >
                                                            2
                                                        </a>
                                                    </li>
                                                    <li
                                                        class="paginate_button page-item next"
                                                        id="DataTables_Table_0_next"
                                                    >
                                                        <a
                                                            href="#"
                                                            aria-controls="DataTables_Table_0"
                                                            data-dt-idx="3"
                                                            tabindex="0"
                                                            class="page-link"
                                                        >
                                                            Next
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Livestocktable
