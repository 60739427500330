import { format } from 'date-fns'

export const COLUMNS = [
    {
        Header: 'ID',
        accessor: 'id',
    },
    {
        Header: 'Mobile No',
        accessor: 'mobile_number',
    },
    {
        Header: 'State',
        accessor: 'state',
    },
    {
        Header: 'District',
        accessor: 'district',
    },  {
        Header: 'Pincode',
        accessor: 'pincode',
    },
    {
        Header: 'Input Count',
        accessor: 'inputCountCount',
    },
    {
        Header: 'GMV',
        accessor: 'inputCountUserAmountSum',
    },
    {
        Header: 'Krishi Amt',
        accessor: 'inputCountKrishiAmountSum',
    },
    {
        Header: 'Sell Count',
        accessor: 'cropCountCount',
    },
    {
        Header: 'GMV',
        accessor: 'cropCountUserAmountSum',
    },
    {
        Header: 'Krishi Amt',
        accessor: 'cropCountKrishiAmountSum',
    },
    {
        Header: 'liveStock Count',
        accessor: 'liveStockCountCount',
    },
    {
        Header: 'GMV',
        accessor: 'liveStockCountUserAmountSum',
    },
    {
        Header: 'Krishi Amt',
        accessor: 'liveStockCountKrishiAmountSum',
    },
    {
        Header: 'machine Count',
        accessor: 'machineCountCount',
    },
    {
        Header: 'GMV',
        accessor: 'machineCountUserAmountSum',
    },
    {
        Header: 'Krishi Amt',
        accessor: 'machineCountKrishiAmountSum',
    },
]
