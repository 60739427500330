import React,{useState,useEffect} from 'react'
import Select from 'react-select'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from '@mui/material';
const ProductCard = ({product,variants_list,onClickAdd}) => {
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [salePrice,setSalePrice]=useState()
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
 
            
                const onClick = (product) => {
                    console.log('onClickAdd')
                    // setQuantityy(quantityy + quantity + 1)
                    onClickAdd(product)
                }
  return (
    <div class="productcard">
    <img
        src={
            product.productImage
                ? product.productImage
                : 'https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'
        }
    />
    <div class="productcard-body">
        <div class="productcard-text">
            <p>{product.productName || product.product}</p>
            <div style={{ fontSize: '12px' }}>
                <span>
                    {product.quantity} {product.unit}
                </span>
            </div>
           
            
        </div>
        <div style={{ padding: '15px' }}>
            
            <br />
            <button
                class="btn btn-lg  btn-primary "
                style={{
                    backgroundColor: '#0a5e00',
                    cursor: 'pointer',
                    fontSize: '12px',
                    borderRadius: '40px',
                }}
                onClick={handleClickOpen}
            >
                Add
            </button>
        </div>
    </div>
    {/* <Button >View Variants</Button> */}

<Dialog className='variantdialog' open={open} onClose={handleClose}>
  <DialogTitle>Product</DialogTitle>
  <DialogContent>
  <div class="productcardvaraint">
    <img width={140}
        src={
            product.productImage
                ? product.productImage
                : 'https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'
        }
    />
    <div class="productcard-body">
        <div class="productcard-text">
            <p>{product.productName || product.product}</p>
            <span style={{color:"grey",fontSize:"14px"}} >{product.brandName}</span>
        </div>
       
    </div>    </div>
    <div className='chooseSize'>Choose a Size</div>
    {variants_list.map((variant, index) => (
      <div className='variantlist' key={index}>
      
                <span> <span>
                     {variant.quantity} {variant.unit} <span style={{fontSize:"14px"}}>{variant.sizeColor? `(${variant.sizeColor})` :null} </span> 
                </span>
        <br/> <div className='variantPrice'> ₹{variant.salePrice}<span style={{color:"green",fontSize:"14px", marginLeft:"5px"}}>  (Saved Price  ₹{variant.discount}) </span> </div>
        
        </span>
        <Button   class="btn btn-lg  btn-primary "
                style={{
                    backgroundColor: '#0a5e00',
                    cursor: 'pointer',
                    fontSize: '12px',
                    borderRadius: '40px', height:"45px"
                }} onClick={()=>onClick(variant)}>Add to Cart</Button> 
           
      </div>
    
    ))}
  </DialogContent>
  <DialogActions>
    <Button onClick={handleClose}>Close</Button>
  </DialogActions>
</Dialog>
</div>
  )
}

export default ProductCard