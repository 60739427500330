import React, { useEffect, useState } from 'react'
import { API } from '../../API/index'
import Swal from 'sweetalert2'

const invoiceclear = {
    invoiceLogo: '',
    invoiceHeader: '',
    invoiceFooter: true,
}

function Invoice() {
    const [invoice, setinvoice] = useState({
        invoiceLogo: '',
        invoiceHeader: '',
        invoiceFooter: true,
    })
    const handleBook = async () => {
        console.log('handleBook', invoice)
        try {
            const { data } = await API.post('/addInvoiceSetting', invoice)
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)
                setinvoice(invoiceclear)
            } else {
                setinvoice(invoiceclear)
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setinvoice(invoiceclear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Invoice Settings</h5>
                </div>
                <div class="card-body">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div class="row form-group">
                            <div class="col-sm-3">
                                <label
                                    for="name"
                                    class="col-form-label input-label"
                                >
                                    Invoice Logo
                                </label>
                                <div class="d-flex align-items-center">
                                    <label
                                        class="avatar avatar-xxl profile-cover-avatar m-0"
                                        for="edit_img"
                                    >
                                        <img
                                            id="avatarImg"
                                            class="avatar-img"
                                            src="../assets/images/krishivan_logo.png"
                                            alt="Profile Image"
                                        />
                                        <input type="file" id="edit_img" />
                                        <span class="avatar-edit">
                                            <span class="material-icons-outlined">
                                                photo_camera
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-7">
                                <div class="row form-group">
                                    <label
                                        for="addressline1"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        Invoice Header
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="addressline1"
                                        placeholder="KRISHIVAN TECHNOLOGIES PVT LTD"
                                        value={invoice.invoiceHeader}
                                        onChange={(e) =>
                                            setinvoice({
                                                ...invoice,
                                                invoiceHeader: e.target.value,
                                            })
                                        }
                                    />
                                </div>

                                <div class="row form-group">
                                    <label
                                        for="addressline1"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        Invoice Footer
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="addressline1"
                                        placeholder="This is a computer generated invoice and doesn't require a signature."
                                        value={invoice.invoiceFooter}
                                        onChange={(e) =>
                                            setinvoice({
                                                ...invoice,
                                                invoiceFooter: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div class="row">
                                    <div class="text-end btn-krish">
                                        <button
                                            type="submit"
                                            class="btn btn-primary"
                                            onClick={() => handleBook()}
                                        >
                                            Save Changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Invoice
