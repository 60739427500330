import AddShoppingCart from '@mui/icons-material/AddShoppingCart'
import { Badge, Button } from '@mui/material'

const CartItem = ({ item, addToCart, removeFromCart }) => {
    return (
        <div className="cartItem">
            <div class="productcard" style={{ width: '390px' }}>
                <img src={item.productImage} />
                <div class="productcard-body">
                    <div class="productcard-text">
                        <p>{item.productName || item.product}</p>
                        <div style={{ fontSize: '12px' }}>
                            <span>
                                {item.quantity} {item.unit}
                            </span>
                        </div>
                        <hr />
                        <p className="itemstock">
                            {' '}
                            {item.stock} stock available
                        </p>
                    </div>
                    <div style={{ padding: '15px' }}>
                        <div>
                            <p style={{ color: 'green' }}>₹{item.salePrice}</p>
                        </div>
                        <br />
                        <Button
                            size="small"
                            disableElevation
                            variant="contained"
                            className="cartItemButton"
                            style={{ marginRight: '20px' }}
                            onClick={() => removeFromCart(item)}
                        >
                            -
                        </Button>
                        <Badge badgeContent={item.amount} color="success">
                            <AddShoppingCart />
                        </Badge>
                        <Button
                            size="small"
                            disableElevation
                            variant="contained"
                            className="cartItemButton"
                            style={{ marginLeft: '14px' }}
                            onClick={() => addToCart(item)}
                        >
                            +
                        </Button>
                    </div>
                </div>
            </div>
            {/* <div>
        <div className='productTable'>
          <div className='productRow'>Product</div>
          <div className='productRow'>Delete</div>
          <div className='productRow'>Quantity</div>
          <div className='productRow'>ADD</div>
        </div>

        <hr />
        <div className="buttons">
          <img className='cartItemimg' src={item.productImage} alt={item.title} />

          <Button
            size="small"
            disableElevation
            variant="contained"
            className='cartItemButton'
            onClick={() => removeFromCart(item.id)}
          >
            -
          </Button>
          <p>{item.amount}</p>
          <Button
            size="small"
            disableElevation
            variant="contained"
            className='cartItemButton'
            onClick={() => addToCart(item)}
          >
            +
          </Button>
        </div>
        <div className='productName'>{item.product}</div>

        <div className="information">
          <p>Price:₹
            {item.totalPrice}</p>
          <p>Total:₹
            {(item.amount * item.totalPrice).toFixed(2)}</p>
        </div>
        <br />
        <div style={{ fontSize: '12px', color: 'green' }}>
          <div className='productDetails'>
            <span>Unit : </span>
            <span> {item.unit}</span>   </div>
          <div className='productDetails'> <span> Brand :</span>
            <span> {item.brand} </span>   </div>
          <div className='productDetails'> <span> Quantity :</span>
            <span>   {item.quantity}</span>  </div>
        </div>
        <br />
      </div> */}
        </div>
    )
}

export default CartItem
