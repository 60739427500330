import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { API } from '../../API/index'
import Swal from 'sweetalert2'
import { initializeApp } from 'firebase/app'
import {
    createUserWithEmailAndPassword,
    getAuth,
    signInWithEmailAndPassword,
} from 'firebase/auth'
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
} from 'firebase/firestore'

const firebaseConfig = {
    apiKey: 'AIzaSyBCgGkH7S3OL-fNKGnQmfMQzbJyY9AZ66o',
    authDomain: 'krishivan-app.firebaseapp.com',
    projectId: 'krishivan-app',
    storageBucket: 'krishivan-app.appspot.com',
    messagingSenderId: '1018772914539',
    appId: '1:1018772914539:web:3f3d750142694da0a74c2e',
    measurementId: 'G-CMCSVD54TQ',
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
function Login() {
    const history = useHistory()
    const [admin, setAdmin] = useState({ userName: '', password: '' })
    const [passwordShow, setPasswordShow] = useState(false)

    const logInWithEmailAndPassword = async (email, password) => {
        try {
            await signInWithEmailAndPassword(auth, email, password).then(
                (res) => {
                    const token = res.user.accessToken
                    localStorage.setItem('token', token)
                    window.location.href = '/home'
                }
            )
        } catch (err) {
            console.error(err)
            alert(err.message)
        }
    }
    // const registerWithEmailAndPassword = async (name, email, password) => {
    //     try {
    //         const res = await createUserWithEmailAndPassword(
    //             auth,
    //             email,
    //             password
    //         )
    //         const user = res.user
    //         await addDoc(collection(db, 'users'), {
    //             uid: user.uid,
    //             name,
    //             authProvider: 'local',
    //             email,
    //         })
    //     } catch (err) {
    //         console.error(err)
    //         alert(err.message)
    //     }
    // }

    const onSubmit = async (e) => {
        // registerWithEmailAndPassword(admin.userName, email, admin.password)

        logInWithEmailAndPassword(admin.userName, admin.password)
        e.preventDefault()
        // let adminInfo = { userName: admin.userName, password: admin.password }
        // const { data } = await API.post('/adminLogin', adminInfo);
        // if (data.success) {
        //    Swal.fire({
        //       icon: 'success',
        //       iconColor: '#539920',
        //       title: data?.message,
        //       showConfirmButton: false,
        //       timer: 1800
        //    });

        //    history.push('./Home');

        //    localStorage.setItem('Admin', true);
        // } else {
        //    Swal.fire({
        //       icon: 'warning',
        //       iconColor: '#dc3545',
        //       title: data?.message,
        //       showConfirmButton: false,
        //       timer: 1800
        //    });
        // }
        // history.push('./Home');
        // localStorage.setItem('Admin', true);
    }
    // const onSubmit = (e) => {
    //    e.preventDefault();
    //    if(admin.email === mailID && admin.password === pswd){
    //       alert('Logged Successfully..');
    //       history.push('./Home');
    //    } else {
    //       alert('Invalid Credentials')
    //    }
    // }

    return (
        <div class="main-wrapper login-body">
            <div class="login-wrapper">
                <div class="container">
                    <div class="auth-logo-box">
                        <a href="index.html" class="logo logo-admin">
                            <img
                                src="../assets/images/logo-1.jpg"
                                height="55"
                                alt="logo"
                                class="auth-logo"
                            />
                        </a>
                    </div>
                    <div class="loginbox">
                        <div class="login-right">
                            <div class="login-right-wrap">
                                <h1>Login</h1>
                                <p class="account-subtitle">
                                    Access to our dashboard
                                </p>
                                <form onSubmit={onSubmit}>
                                    <div class="form-group">
                                        <label class="form-control-label">
                                            Email Address
                                        </label>
                                        <input
                                            required
                                            type="text"
                                            name="userName"
                                            id="userName"
                                            class="form-control"
                                            onChange={(e) =>
                                                setAdmin({
                                                    ...admin,
                                                    [e.target.name]:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label class="form-control-label">
                                            Password
                                        </label>
                                        <div class="pass-group">
                                            <input
                                                required
                                                type={
                                                    passwordShow === true
                                                        ? 'text'
                                                        : 'password'
                                                }
                                                name="password"
                                                id="password"
                                                class="form-control pass-input"
                                                onChange={(e) =>
                                                    setAdmin({
                                                        ...admin,
                                                        [e.target.name]:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                            {passwordShow === false ? (
                                                <span
                                                    class="fas fa-eye toggle-password"
                                                    onClick={(e) =>
                                                        setPasswordShow(true)
                                                    }
                                                ></span>
                                            ) : (
                                                <span
                                                    class="fas fa-eye-slash toggle-password"
                                                    onClick={(e) =>
                                                        setPasswordShow(false)
                                                    }
                                                ></span>
                                            )}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        id="cb1"
                                                    />
                                                    <label
                                                        class="custom-control-label bx-l-6"
                                                        for="cb1"
                                                    >
                                                        Remember me
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-6 text-end">
                                                <a
                                                    class="forgot-link"
                                                    href="forgot-password.html"
                                                >
                                                    Forgot Password ?
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <button
                                        class="btn btn-lg btn-block btn-primary w-100"
                                        type="submit"
                                    >
                                        Login
                                    </button>

                                    <div class="login-or">
                                        <span class="or-line"></span>
                                        <span class="span-or">or</span>
                                    </div>
                                    <div class="social-login mb-3">
                                        <span>Login with</span>
                                        <a href="#" class="facebook">
                                            <i class="fab fa-facebook-f"></i>
                                        </a>
                                        <a href="#" class="google">
                                            <i class="fab fa-google"></i>
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Login
