import React, { useState } from 'react'
import { CssTextField } from '../../Styles'
import CrpCategories from './CrpCategories'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Switch from '@mui/material/Switch'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AddIcon from '@mui/icons-material/Add'

function CropCategories() {
    const [showCategory, setShowCategory] = useState(false)

    return (
        <>
            <div className="row mb-3">
                <div className="col-6">
                    <h4 class="card-title">Crop Inventory</h4>
                </div>
                {/* <div className="col-6 text-end">
                {showCategory ? 
                 <button type="button" className="btn btn-secondary" onClick={e=>setShowCategory(false)}>
                  <AddIcon size="small" /> Create Category 
                 </button>
                 :
                 <button type="button" className="btn btn-secondary" onClick={e=>setShowCategory(true)}>
                  <VisibilityIcon size="small" /> View Categories
                 </button> 
                }
              
            </div> */}
            </div>

            {showCategory === false ? (
                <>
                    <div className="card card-table p-10-25">
                        <form action="#">
                            <div className="mt-3">
                                <h5 class="card-title">Create Product</h5>
                                <div class="row mb-4">
                                    <div class="col-md-3">
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            className="mt-3"
                                        >
                                            <InputLabel
                                                id="demo-simple-select-standard-label"
                                                style={{ color: 'black' }}
                                            >
                                                Category
                                            </InputLabel>
                                            <Select
                                                required
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                //  value={age}
                                                //  onChange={handleChange}
                                                label="Firm"
                                                color="success"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value={10}>
                                                    General
                                                </MenuItem>
                                                <MenuItem value={20}>
                                                    OBC
                                                </MenuItem>
                                                <MenuItem value={30}>
                                                    SC
                                                </MenuItem>
                                                <MenuItem value={30}>
                                                    ST
                                                </MenuItem>
                                                <MenuItem value={30}>
                                                    Others
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div class="col-md-3">
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            className="mt-3"
                                        >
                                            <InputLabel
                                                id="demo-simple-select-standard-label"
                                                style={{ color: 'black' }}
                                            >
                                                SubCategory
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                //  value={age}
                                                //  onChange={handleChange}
                                                label="Booking Mode"
                                                color="success"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value={10}>
                                                    General
                                                </MenuItem>
                                                <MenuItem value={20}>
                                                    OBC
                                                </MenuItem>
                                                <MenuItem value={30}>
                                                    SC
                                                </MenuItem>
                                                <MenuItem value={30}>
                                                    ST
                                                </MenuItem>
                                                <MenuItem value={30}>
                                                    Others
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div class="col-md-3">
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            className="mt-3"
                                        >
                                            <InputLabel
                                                id="demo-simple-select-standard-label"
                                                style={{ color: 'black' }}
                                            >
                                                Brand Name
                                            </InputLabel>
                                            <Select
                                                required
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                //  value={age}
                                                //  onChange={handleChange}
                                                label="Category"
                                                color="success"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value={10}>
                                                    test
                                                </MenuItem>
                                                <MenuItem value={20}>
                                                    Krishivan Input
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div class="col-md-3">
                                        <CssTextField
                                            required
                                            fullWidth
                                            id="productName"
                                            label="Product Name"
                                            variant="standard"
                                        />
                                    </div>
                                    <div class="col-md-3">
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            className="mt-3"
                                        >
                                            <InputLabel
                                                id="demo-simple-select-standard-label"
                                                style={{ color: 'black' }}
                                            >
                                                Unit
                                            </InputLabel>
                                            <Select
                                                required
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                //  value={age}
                                                //  onChange={handleChange}
                                                label="Unit"
                                                color="success"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value={10}>
                                                    test
                                                </MenuItem>
                                                <MenuItem value={20}>
                                                    Krishivan Input
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div class="col-md-3">
                                        <CssTextField
                                            required
                                            fullWidth
                                            id="Price"
                                            label="Price"
                                            variant="standard"
                                        />
                                    </div>
                                    <div class="col-md-3">
                                        <CssTextField
                                            required
                                            fullWidth
                                            id="Discount"
                                            label="Discount"
                                            variant="standard"
                                        />
                                    </div>
                                    <div class="col-md-3">
                                        <div className="row">
                                            <div className="col-4">
                                                <InputLabel
                                                    id="demo-simple-select-standard-label"
                                                    style={{ color: 'black' }}
                                                    className="mt-3"
                                                >
                                                    Unlimited
                                                </InputLabel>
                                                <Switch />
                                            </div>
                                            <div className="col-8">
                                                <CssTextField
                                                    required
                                                    fullWidth
                                                    id="stock"
                                                    label="Stock"
                                                    variant="standard"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row justify-content-end mb-3">
                                    <div className="col-md-1">
                                        <button
                                            type="submit"
                                            className="btn btn-danger"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                    <div className="col-md-1">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <h3 className="mb-3">Crop Products</h3>
                            <div class="card card-table">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <div
                                            id="DataTables_Table_0_wrapper"
                                            class="dataTables_wrapper dt-bootstrap4 no-footer"
                                        >
                                            <div class="row">
                                                <div class="col-sm-12 col-md-6">
                                                    <div
                                                        class="dataTables_length"
                                                        id="DataTables_Table_0_length"
                                                    >
                                                        <label>
                                                            Show
                                                            <select
                                                                name="DataTables_Table_0_length"
                                                                aria-controls="DataTables_Table_0"
                                                                class="custom-select custom-select-sm form-control form-control-sm"
                                                            >
                                                                <option value="10">
                                                                    10
                                                                </option>
                                                                <option value="25">
                                                                    25
                                                                </option>
                                                                <option value="50">
                                                                    50
                                                                </option>
                                                                <option value="100">
                                                                    100
                                                                </option>
                                                            </select>
                                                            entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-3 position_right">
                                                    <div class="form-group">
                                                        {/* <label>Search</label> */}
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="Search"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div class="row">
                         <div class="col-sm-12">
                            <table class="table table-center table-hover datatable dataTable no-footer" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                               <thead class="thead-light">
                                  <tr role="row">
                                     <th class="sorting_asc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-sort="ascending" aria-label="Customer: activate to sort column descending" >Customer</th>
                                     <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Email: activate to sort column ascending" >Email</th>
                                     <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Amount Due: activate to sort column ascending" >State</th>
                                     <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Registered On: activate to sort column ascending" >District</th>
                                     <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Registered On: activate to sort column ascending" >Village</th>
                                     <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Registered On: activate to sort column ascending" >Crops</th>
                                     <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Status: activate to sort column ascending" >Status</th>
                                     <th class="text-right sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Actions: activate to sort column ascending" >Actions</th>
                                     <th class="text-right sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Actions: activate to sort column ascending" >Manage</th>
                                  </tr>
                               </thead>
                               <tbody>
                                  <tr role="row" class="odd">
                                     <td class="sorting_1">
                                        <h2 class="table-avatar">
                                           <a href="#" class="avatar avatar-sm me-2">
                                               <img class="avatar-img rounded-circle" src="../assets/images/6.jpg" alt="User Image"/></a>
                                           <a href="#">Alex Campbell <span>9876543210</span></a>
                                        </h2>
                                     </td>
                                     <td>alexcampbell@example.com</td>
                                     <td>Karnataka</td>
                                     <td>Mangalore</td>
                                     <td>Mangalore</td>
                                     <td>Ash Gourd</td>
                                     <td><span class="badge badge-pill bg-success-light">Active</span></td>
                                     <td class="text-right">
                                        <a href="#" class="btn btn-sm btn-white text-success me-2"><i class="far fa-edit me-1"></i> Edit</a>
                                        <a href="#" class="btn btn-sm btn-white text-danger me-2"> Deactivate</a>
                                     </td>
                                     <td class="text-right">
                                       <a href="#" class="btn btn-sm btn-white text-warning me-2">Expenses</a>
                                     </td>
                                  </tr>
                                  <tr role="row" class="even">
                                     <td class="sorting_1">
                                        <h2 class="table-avatar">
                                           <a href="#" class="avatar avatar-sm me-2">
                                               <img class="avatar-img rounded-circle" src="../assets/images/1.jpg" alt="User Image"/></a>
                                           <a href="#">Barbara Moore <span>9876543210</span></a>
                                        </h2>
                                     </td>
                                     <td>barbaramoore@example.com</td>
                                     <td>Karnataka</td>
                                     <td>Mangalore</td>
                                     <td>Mangalore</td>
                                     <td>Ash Gourd</td>
                                     <td><span class="badge badge-pill bg-success-light">Active</span></td>
                                     <td class="text-right">
                                        <a href="#" class="btn btn-sm btn-white text-success me-2"><i class="far fa-edit me-1"></i> Edit</a>
                                        <a href="#" class="btn btn-sm btn-white text-danger me-2"> Deactivate</a>
                                     </td>
                                     <td class="text-right">
                                       <a href="#" class="btn btn-sm btn-white text-warning me-2">Expenses</a>
                                     </td>
                                  </tr>
                                  <tr role="row" class="odd">
                                     <td class="sorting_1">
                                        <h2 class="table-avatar">
                                           <a href="#" class="avatar avatar-sm me-2">
                                               <img class="avatar-img rounded-circle" src="../assets/images/2.jpg" alt="User Image"/></a>
                                           <a href="#">Brian Johnson <span>9876543210</span></a>
                                        </h2>
                                     </td>
                                     <td>brianjohnson@example.com</td>
                                     <td>Karnataka</td>
                                     <td>Mangalore</td>
                                     <td>Mangalore</td>
                                     <td>Ash Gourd</td>
                                     <td><span class="badge badge-pill bg-success-light">Active</span></td>
                                     <td class="text-right">
                                        <a href="#" class="btn btn-sm btn-white text-success me-2"><i class="far fa-edit me-1"></i> Edit</a>
                                        <a href="#" class="btn btn-sm btn-white text-danger me-2"> Deactivate</a>
                                     </td>
                                     <td class="text-right">
                                       <a href="#" class="btn btn-sm btn-white text-warning me-2">Expenses</a>
                                     </td>
                                  </tr>
                                  <tr role="row" class="even">
                                     <td class="sorting_1">
                                        <h2 class="table-avatar">
                                           <a href="#" class="avatar avatar-sm me-2">
                                               <img class="avatar-img rounded-circle" src="../assets/images/3.jpg" alt="User Image"/></a>
                                           <a href="#">Greg Lynch <span>9876543210</span></a>
                                        </h2>
                                     </td>
                                     <td>greglynch@example.com</td>
                                     <td>Karnataka</td>
                                     <td>Mangalore</td>
                                     <td>Mangalore</td>
                                     <td>Ash Gourd</td>
                                     <td><span class="badge badge-pill bg-success-light">Active</span></td>
                                     <td class="text-right">
                                        <a href="#" class="btn btn-sm btn-white text-success me-2"><i class="far fa-edit me-1"></i> Edit</a>
                                        <a href="#" class="btn btn-sm btn-white text-danger me-2"> Deactivate</a>
                                     </td>
                                     <td class="text-right">
                                       <a href="#" class="btn btn-sm btn-white text-warning me-2">Expenses</a>
                                     </td>
                                  </tr>
                                  <tr role="row" class="odd">
                                     <td class="sorting_1">
                                        <h2 class="table-avatar">
                                           <a href="#" class="avatar avatar-sm me-2">
                                               <img class="avatar-img rounded-circle" src="../assets/images/4.jpg" alt="User Image"/></a>
                                           <a href="#">Jennifer Floyd <span>9876543210</span></a>
                                        </h2>
                                     </td>
                                     <td>jenniferfloyd@example.com</td>
                                     <td>Karnataka</td>
                                     <td>Mangalore</td>
                                     <td>Mangalore</td>
                                     <td>Ash Gourd</td>
                                     <td><span class="badge badge-pill bg-success-light">Active</span></td>
                                     <td class="text-right">
                                        <a href="#" class="btn btn-sm btn-white text-success me-2"><i class="far fa-edit me-1"></i> Edit</a>
                                        <a href="#" class="btn btn-sm btn-white text-danger me-2"> Deactivate</a>
                                     </td>
                                     <td class="text-right">
                                       <a href="#" class="btn btn-sm btn-white text-warning me-2">Expenses</a>
                                     </td>
                                  </tr>
                                  <tr role="row" class="even">
                                     <td class="sorting_1">
                                        <h2 class="table-avatar">
                                           <a href="#" class="avatar avatar-sm me-2">
                                               <img class="avatar-img rounded-circle" src="../assets/images/5.jpg" alt="User Image"/></a>
                                           <a href="#">John Blair <span>9876543210</span></a>
                                        </h2>
                                     </td>
                                     <td>johnblair@example.com</td>
                                     <td>Karnataka</td>
                                     <td>Mangalore</td>
                                     <td>Mangalore</td>
                                     <td>Ash Gourd</td>
                                     <td><span class="badge badge-pill bg-success-light">Active</span></td>
                                     <td class="text-right">
                                        <a href="#" class="btn btn-sm btn-white text-success me-2"><i class="far fa-edit me-1"></i> Edit</a>
                                        <a href="#" class="btn btn-sm btn-white text-danger me-2"> Deactivate</a>
                                     </td>
                                     <td class="text-right">
                                       <a href="#" class="btn btn-sm btn-white text-warning me-2">Expenses</a>
                                     </td>
                                  </tr>
                                  <tr role="row" class="odd">
                                     <td class="sorting_1">
                                        <h2 class="table-avatar">
                                           <a href="#" class="avatar avatar-sm me-2">
                                               <img class="avatar-img rounded-circle" src="../assets/images/6.jpg" alt="User Image"/></a>
                                           <a href="#">Joseph Collins <span>9876543210</span></a>
                                        </h2>
                                     </td>
                                     <td>josephcollins@example.com</td>
                                     <td>Karnataka</td>
                                     <td>Mangalore</td>
                                     <td>Mangalore</td>
                                     <td>Ash Gourd</td>
                                     <td><span class="badge badge-pill bg-success-light">Active</span></td>
                                     <td class="text-right">
                                        <a href="#" class="btn btn-sm btn-white text-success me-2"><i class="far fa-edit me-1"></i> Edit</a>
                                        <a href="#" class="btn btn-sm btn-white text-danger me-2"> Deactivate</a>
                                     </td>
                                     <td class="text-right">
                                       <a href="#" class="btn btn-sm btn-white text-warning me-2">Expenses</a>
                                     </td>
                                  </tr>
                                  <tr role="row" class="even">
                                     <td class="sorting_1">
                                        <h2 class="table-avatar">
                                           <a href="#" class="avatar avatar-sm me-2">
                                               <img class="avatar-img rounded-circle" src="../assets/images/1.jpg" alt="User Image"/></a>
                                           <a href="#">Karlene Chaidez <span>9876543210</span></a>
                                        </h2>
                                     </td>
                                     <td>karlenechaidez@example.com</td>
                                     <td>Karnataka</td>
                                     <td>Mangalore</td>
                                     <td>Mangalore</td>
                                     <td>Ash Gourd</td>
                                     <td><span class="badge badge-pill bg-success-light">Active</span></td>
                                     <td class="text-right">
                                        <a href="#" class="btn btn-sm btn-white text-success me-2"><i class="far fa-edit me-1"></i> Edit</a>
                                        <a href="#" class="btn btn-sm btn-white text-danger me-2"> Deactivate</a>
                                     </td>
                                     <td class="text-right">
                                       <a href="#" class="btn btn-sm btn-white text-warning me-2">Expenses</a>
                                     </td>
                                  </tr>
                                  <tr role="row" class="odd">
                                     <td class="sorting_1">
                                        <h2 class="table-avatar">
                                           <a href="#" class="avatar avatar-sm me-2">
                                               <img class="avatar-img rounded-circle" src="../assets/images/2.jpg" alt="User Image"/></a>
                                           <a href="#">Leatha Bailey <span>9876543210</span></a>
                                        </h2>
                                     </td>
                                     <td>leathabailey@example.com</td>
                                     <td>Karnataka</td>
                                     <td>Mangalore</td>
                                     <td>Mangalore</td>
                                     <td>Ash Gourd</td>
                                     <td><span class="badge badge-pill bg-success-light">Active</span></td>
                                     <td class="text-right">
                                        <a href="#" class="btn btn-sm btn-white text-success me-2"><i class="far fa-edit me-1"></i> Edit</a>
                                        <a href="#" class="btn btn-sm btn-white text-danger me-2"> Deactivate</a>
                                     </td>
                                     <td class="text-right">
                                       <a href="#" class="btn btn-sm btn-white text-warning me-2">Expenses</a>
                                     </td>
                                  </tr>
                                  <tr role="row" class="even">
                                     <td class="sorting_1">
                                        <h2 class="table-avatar">
                                           <a href="#" class="avatar avatar-sm me-2">
                                               <img class="avatar-img rounded-circle" src="../assets/images/3.jpg" alt="User Image"/></a>
                                           <a href="#">Marie Canales <span>9876543210</span></a>
                                        </h2>
                                     </td>
                                     <td>mariecanales@example.com</td>
                                     <td>Karnataka</td>
                                     <td>Mangalore</td>
                                     <td>Mangalore</td>
                                     <td>Ash Gourd</td>
                                     <td><span class="badge badge-pill bg-success-light">Active</span></td>
                                     <td class="text-right">
                                        <a href="#" class="btn btn-sm btn-white text-success me-2"><i class="far fa-edit me-1"></i> Edit</a>
                                        <a href="#" class="btn btn-sm btn-white text-danger me-2"> Deactivate</a>
                                     </td>
                                     <td class="text-right">
                                       <a href="#" class="btn btn-sm btn-white text-warning me-2">Expenses</a>
                                     </td>
                                  </tr>
                               </tbody>
                            </table>
                         </div>
                      </div> */}
                                            <div class="row">
                                                <div class="col-sm-12 col-md-5">
                                                    <div
                                                        class="dataTables_info"
                                                        id="DataTables_Table_0_info"
                                                        role="status"
                                                        aria-live="polite"
                                                    >
                                                        Showing 1 to 10 of 12
                                                        entries
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-7">
                                                    <div
                                                        class="dataTables_paginate paging_simple_numbers"
                                                        id="DataTables_Table_0_paginate"
                                                    >
                                                        <ul class="pagination">
                                                            <li
                                                                class="paginate_button page-item previous disabled"
                                                                id="DataTables_Table_0_previous"
                                                            >
                                                                <a
                                                                    href="#"
                                                                    aria-controls="DataTables_Table_0"
                                                                    data-dt-idx="0"
                                                                    tabindex="0"
                                                                    class="page-link"
                                                                >
                                                                    Previous
                                                                </a>
                                                            </li>
                                                            <li class="paginate_button page-item active">
                                                                <a
                                                                    href="#"
                                                                    aria-controls="DataTables_Table_0"
                                                                    data-dt-idx="1"
                                                                    tabindex="0"
                                                                    class="page-link"
                                                                >
                                                                    1
                                                                </a>
                                                            </li>
                                                            <li class="paginate_button page-item ">
                                                                <a
                                                                    href="#"
                                                                    aria-controls="DataTables_Table_0"
                                                                    data-dt-idx="2"
                                                                    tabindex="0"
                                                                    class="page-link"
                                                                >
                                                                    2
                                                                </a>
                                                            </li>
                                                            <li
                                                                class="paginate_button page-item next"
                                                                id="DataTables_Table_0_next"
                                                            >
                                                                <a
                                                                    href="#"
                                                                    aria-controls="DataTables_Table_0"
                                                                    data-dt-idx="3"
                                                                    tabindex="0"
                                                                    class="page-link"
                                                                >
                                                                    Next
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <CrpCategories />
            )}
        </>
    )
}

export default CropCategories
