import React, { useState, useEffect } from 'react'
import { CssTextField } from '../Styles'
import { useDropzone } from 'react-dropzone'
import { thumb, img, thumbInner } from '../Styles'
import axios from 'axios'

import Swal from 'sweetalert2'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import { API } from '../../API'
import { storage } from '../../firebase'
import InputBookings from './CreateInputBooking/InputBookings'
import LivestockBookings from './LiveStock/LivestockBooking'
import MachineBooking from './CreateMachineBooking/MachineBooking'
import Transports from './Transport/Transport'
import SellCrop from './SellCrop/SellCrop'

const machineclear = {
    MicroEnterpreneurId: '',
    farmerId: '',
    machineType: '',
    machineModel: '',
    crop: '',
    fromdate: '',
    toDate: '',
    fromTime: '',
    toTime: '',
    area: '',
    unit: '',
}

const SellCropClear = {
    MicroEnterpreneurId: '',
    farmerId: '',
    category: '',
    cropType: '',
    cropName: '',
    variety: '',
    variety: '',
    availabilityDate: '',
    availabilityDay: '',
    expectedQuailty: '',
    expectedPriceMin: '',
    expectedPriceMax: '',
    unit: '',
    description: '',
    photoUrl: '',
    bookedBy: '',
}

const TransportClear = {
    farmerId: localStorage.getItem('FormerId')
        ? localStorage.getItem('FormerId')
        : '',
    vehicleType: '',
    bodyType: '',
    crop: '',
    pickUpAddress: '',
    dropAddress: '',
    bookingMode: '',
    bookingType: 'BookNow',
    bookedBy: 'MI',
}

const bookClear = {
    MicroEnterpreneurId: '',
    farmerId: '',

    firm: '',
    modeOfBooking: '',

    category: '',
}
const formClear = {
    subCategory: '',
    Brand: '',
    product: '',
    unit: '',
    price: '',
    stock: '',
    quantity: '',
    totalPrice: '',
}

const formvalueclear = {
    subCategory: '',
    brand: '',
    product: '',
    unit: '',
    price: '',
    stock: '',
    quantity: '',
    totalPrice: '',
}
const LivestockBookingclear = {
    farmerId: '',
    MicroEnterpreneurId: '',
    Firm: '',
    modeOfBooking: '',
    status: '',
}
function Bookings() {
    const [tab, setTab] = useState(1)
    const [Files, setFiles] = useState([])
    const [farmer, setFarmer] = useState('')
    const [state, setstate] = useState([])
    const [MicroEnt, setMicroEnt] = useState('')
    const [file, setFile] = useState(null)
    const [inputCategory, setInputCategory] = useState([
        { id: 1, subCategory: '', Brand: '' },
    ])
    const [livestockCategory, setLivestockCategory] = useState([
        { id: 1, subCategory: '', Brand: '' },
    ])
    const [Microinfo, setmicroinfo] = useState({})
    const [Formerinfo, setFormerinfo] = useState({})
    const [yourNumber, setYourNumber] = useState()
    const [infoNumber, setInfoNumber] = useState()
    const [addInput, setAddInput] = useState([
        {
            subCategory: '',
            Brand: '',
            product: '',
            unit: '',
            price: '',
            stock: '',
            quantity: '',
            totalPrice: '',
        },
    ])
    const [formValues, setFormValues] = useState([
        {
            subCategory: '',
            Brand: '',
            product: '',
            unit: '',
            price: '',
            stock: '',
            quantity: '',
            totalPrice: '',
        },
    ])

    const [Firmlist, setFirmlist] = useState([])
    const [categorylist, setcategorylist] = useState([])
    const [subcategorylist, setsubcategorylist] = useState([])
    const [brandlist, setbrandlist] = useState([])
    const [productlist, setproductlist] = useState([])
    const [categoryname, setCategoryName] = useState('')
    const [subcategoryName, setsubcategoryName] = useState('')
    const [list, setlist] = useState([])

    const [inputBooking, setInputBooking] = useState({
        MicroEnterpreneurId: '',
        farmerId: '',
        firm: '',
        modeOfBooking: '',
        category: '',
        bookedBy: 'MI',
    })

    const [machine, setmachine] = useState({
        MicroEnterpreneurId: '',
        farmerId: localStorage.getItem('FormerId')
            ? localStorage.getItem('FormerId')
            : '',
        machineType: '',
        machineModel: '',
        crop: '',
        fromdate: '',
        toDate: '',
        fromTime: '',
        toTime: '',
        area: '',
        unit: '',
        bookedBy: 'MI',
    })

    const [formvalueLive, setFormValuesLive] = useState([
        {
            subCategory: '',
            brand: '',
            product: '',
            unit: '',
            price: '',
            stock: '',
            quantity: '',
            totalPrice: '',
        },
    ])
    const [inputBookfetch, setinputBookfetch] = useState([])
    useEffect(() => {
        // getState();
        getInputBooking()
    }, [])

    const handlecategory = (e) => {
        setCategoryName(e.value)
        setInputBooking({ ...inputBooking, category: e.value })
        getsubCategoryList(e.value)
    }
    const handlesubcategory = (i, e) => {
        getbrandList(e.value)
        setsubcategoryName(e.value)
        let newFormValues = [...formValues]
        newFormValues[i]['subCategory'] = e.value
        setFormValues(newFormValues)
    }
    const handleBrandcategory = (i, e) => {
        getproductList(e.value)
        let newFormValues = [...formValues]
        newFormValues[i]['Brand'] = e.value
        setFormValues(newFormValues)
    }
    const handleProductcategory = (i, e) => {
        handleValues(i, e)
        // getproductList(e.value)
        let newFormValues = [...formValues]
        newFormValues[i]['product'] = e.value
        setFormValues(newFormValues)
    }

    const handleOptioncategory = (i, e) => {
        handleValues(i, e)
        // getproductList(e.value)
        let newFormValues = [...formValues]
        newFormValues[i]['quantity'] = e.value
        setFormValues(newFormValues)
    }
    const stockOption =
        list &&
        list.map((item, ind) => ({
            value: item.stock,
            label: item.stock,
        }))
    const unitOption =
        list &&
        list.map((item, ind) => ({
            value: item.unit,
            label: item.unit,
        }))
    const priceOption =
        list &&
        list.map((item, ind) => ({
            value: item.price,
            label: item.price,
        }))
    const handleunitcategory = (i, e) => {
        // handleValues(i,e)
        // getproductList(e.value)
        let newFormValues = [...formValues]
        newFormValues[i]['unit'] = e.value
        setFormValues(newFormValues)
    }
    const handlepricecategory = (i, e) => {
        // handleValues(i,e)
        // getproductList(e.value)
        let newFormValues = [...formValues]
        newFormValues[i]['price'] = e.value
        setFormValues(newFormValues)
    }

    const handlestockcategory = (i, e) => {
        let newFormValues = [...formValues]
        newFormValues[i]['stock'] = e.value
        setFormValues(newFormValues)
    }
    const handleValues = async (i, e) => {
        console.log(
            'handleValues',
            categoryname,
            formValues[i]['Brand'],
            formValues[i]['subCategory'],
            formValues[i]['product']
        )
        const { data } = await API.get(
            `/getProductData?title=ProductCatelog&catelogName=InputSupplierCatelog&brandName=` +
                formValues[i]['Brand'] +
                '&SubCategoryName=' +
                formValues[i]['subCategory'] +
                '&categoryName=' +
                categoryname +
                '&productName=' +
                formValues[i]['product']
        )
        setlist(data?.result)
    }

    const getInputBooking = async () => {
        const { data } = await API.get(`/getInputBookings?type=0&bookedBy=MI`)
        setinputBookfetch(data?.result)
    }
    const [sellcropBookfetch, setsellcropBookfetch] = useState([])

    useEffect(() => {
        // getState();
        getSellCropBooking()
        getFirmList()
        getCategoryList()
    }, [])

    const getFirmList = async () => {
        const { data } = await API.get(`/getInputSupplier?type=0`)
        // console.log("hkjjhj",data)
        setFirmlist(data?.result)
        console.log('firm search', data)
    }
    const Firmoption =
        Firmlist &&
        Firmlist.map((item, ind) => ({
            value: item.mobileNumber,
            label: item.mobileNumber,
        }))
    // categorylist
    const getCategoryList = async () => {
        const { data } = await API.get(
            `/getCategory?title=ProductCatelog&catelogName=InputSupplierCatelog`
        )
        // console.log("hkjjhj",data)
        setcategorylist(data?.result)
        console.log('category search', data)
    }
    const categoryoption = categorylist.map((item, ind) => ({
        value: item,
        label: item,
    }))
    // subcategory
    const getsubCategoryList = async (val) => {
        const { data } = await API.get(
            `/getSubCategory?title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=` +
                val
        )
        // console.log("hkjjhj",data)
        setsubcategorylist(data?.result)
        console.log('subcategory search', data)
    }
    const subcategoryoption = subcategorylist.map((item, ind) => ({
        value: item,
        label: item,
    }))
    // brand
    const getbrandList = async (val) => {
        const { data } = await API.get(
            `/getBrand?title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=` +
                categoryname +
                '&subCategoryName=' +
                val
        )
        // console.log("hkjjhj",data)
        setbrandlist(data?.result)
        console.log('brand search', data)
    }
    const brandoption = brandlist.map((item, ind) => ({
        value: item,
        label: item,
    }))
    // product
    const getproductList = async (val) => {
        const { data } = await API.get(
            `/getProduct?title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=` +
                categoryname +
                '&subCategoryName=' +
                subcategoryName +
                '&brandName=' +
                val
        )
        // console.log("hkjjhj",data)
        setproductlist(data?.result)
        console.log('product search', data)
    }
    const productoption =
        productlist &&
        productlist.map((item, ind) => ({
            value: item.productName,
            label: item.productName,
        }))
    const quantityoption =
        productlist &&
        productlist.map((item, ind) => ({
            value: item.quantity,
            label: item.quantity,
        }))
    const getSellCropBooking = async () => {
        const { data } = await API.get(
            `/getSellCropsBookings?type=0&bookedBy=MI`
        )
        setsellcropBookfetch(data?.result)
    }
    const [TransportBookfetch, setTransportBookfetch] = useState([])
    useEffect(() => {
        // getState();
        getTransportBooking()
    }, [])
    const getTransportBooking = async () => {
        const { data } = await API.get(`/getTransport?type=0&bookedBy=MI`)
        setTransportBookfetch(data?.result)
    }
    const [LivestockBookfetch, setLivestockBookfetch] = useState([])
    useEffect(() => {
        // getState();
        getLivestockBooking()
    }, [])
    const getLivestockBooking = async () => {
        const { data } = await API.get(
            `/getLiveStockBookings?type=0&bookedBy=MI`
        )
        setLivestockBookfetch(data?.result)
    }
    const [inputMachinefetch, setinputMachinefetch] = useState([])
    useEffect(() => {
        // getState();
        getMachineBooking()
    }, [])
    const getMachineBooking = async () => {
        const { data } = await API.get(`/getmachineBooking?type=0&bookedBy=MI`)
        setinputMachinefetch(data?.result)
    }
    const [LivestockBooking, setlivestockBooking] = useState({
        farmerId: '',
        MicroEnterpreneurId: '',
        Firm: '',
        modeOfBooking: '',
        status: '',
        bookedBy: 'MI',
    })
    const [docImg, setDocImg] = useState(null)
    console.log(file)
    // const [sellcropBookfetch, setsellcropBookfetch] = useState([])
    const [sellCrop, setSellCrop] = useState({
        MicroEnterpreneurId: '',
        farmerId: '',
        category: '',
        cropType: '',
        cropName: '',
        variety: '',
        // variety: "",
        availabilityDate: '',
        availabilityDay: '',
        expectedQuailty: '',
        expectedPriceMin: '',
        expectedPriceMax: '',
        unit: '',
        description: '',
        photoUrl: '',

        bookedBy: 'MI',
    })

    const [Transport, setTransport] = useState({
        farmerId: localStorage.getItem('FormerId')
            ? localStorage.getItem('FormerId')
            : '',
        MicroEnterpreneurId: '',
        vehicleType: '',
        bodyType: '',
        crop: '',
        pickUpAddress: '',
        dropAddress: '',
        bookingMode: '',
        bookingType: 'BookNow',
        date: '',
        time: '',
        bookedBy: 'MI',
    })

    useEffect(() => {
        //getmicroinfo();
    }, [])

    const handleEnterpreneur = (e) => {
        e.preventDefault()
        if (e.target.value.length <= 10) {
            setYourNumber(e.target.value)
        }
        if (e.target.value.length == 10) {
            console.log('evet', e.target.value)
            getmicroinfo(e.target.value)
        } else {
            console.log('ERROR')
            setmicroinfo({})
        }
    }
    const getmicroinfo = async (Mob) => {
        const token = localStorage.getItem('token')
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        const num = {
            mobile_no: Mob,
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/register-details`,
                num,
                config
            )
            .then((res) => {
                const d = res?.data?.data
                setMicroEnt(d?._id)
                setmicroinfo(d.data)
                localStorage.setItem('microId', d.data.user_id)
            })
    }
    useEffect(() => {
        // getFormerinfo();
    }, [])

    const handleFormr = (e) => {
        e.preventDefault()
        if (e.target.value.length <= 10) setInfoNumber(e.target.value)
        if (e.target.value.length == 10) {
            console.log('evet', e.target.value)
            getFormerinfo(e.target.value)
        } else {
            console.log('ERROR')
            setFormerinfo({})
        }
    }
    const getFormerinfo = async (Mob) => {
        const token = localStorage.getItem('token')
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        const num = {
            mobile_no: Mob,
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/register-details`,
                num,
                config
            )
            .then((res) => {
                const d = res?.data?.data
                setFarmer(d._id)
                setFormerinfo(d.data)
                localStorage.setItem('FormerId', d.data.user_id)
            })
    }

    // Input
    const addInputs = () => {
        let myArr = [...inputCategory]
        myArr.push({ id: myArr.length + 1, subCategory: '', Brand: '' })
        setInputCategory(myArr)
    }

    const removeInput = (e, id) => {
        e.preventDefault()
        let myArr = [...inputCategory]
        const index = myArr.findIndex((prop) => prop.id === id)
        myArr.splice(index, 1)
        setInputCategory(myArr)
    }

    //Livestock
    const addLivestock = () => {
        let myArr = [...livestockCategory]
        myArr.push({ id: myArr.length + 1, subCategory: '', Brand: '' })
        setLivestockCategory(myArr)
    }

    const removeLivestock = (e, id) => {
        e.preventDefault()
        let myArr = [...livestockCategory]
        const index = myArr.findIndex((prop) => prop.id === id)
        myArr.splice(index, 1)
        setLivestockCategory(myArr)
    }

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
            setDocImg(URL.createObjectURL(acceptedFiles[0]))
            const doc = acceptedFiles[0]
            if (acceptedFiles[0]) {
                const uploadTask = ref(storage, `docs/${doc.name}`)
                const uploadTask02 = uploadBytesResumable(uploadTask, doc)
                uploadTask02.on(
                    'state_changed',
                    (snapshot) => {},
                    (error) => {
                        // Handle unsuccessful uploads
                    },
                    () => {
                        getDownloadURL(uploadTask).then((url) => {
                            setSellCrop({ ...sellCrop, photoUrl: url })
                        })
                    }
                )
            }
        },
    })

    const PGthumbs = Files.map((file, index) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt="" />
            </div>
        </div>
    ))

    const handleBookSellCrop = async () => {
        console.log('handleChange', sellCrop, {
            ...sellCrop,
            farmerId: farmer,
            MicroEnterpreneurId: MicroEnt,
        })

        let sellCropval = {
            ...sellCrop,
            farmerId: farmer,
            MicroEnterpreneurId: MicroEnt,
        }
        try {
            const { data } = await API.post('/sellCropsBooking', sellCropval)
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)

                setDocImg(null)
                setFiles([])
                setSellCrop(SellCropClear)
                getSellCropBooking()
                //  getSellCropBooking()
                // setCropTypes([]);
            } else {
                setSellCrop(SellCropClear)
                setDocImg(null)
                setFiles([])
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setSellCrop(SellCropClear)
            setDocImg(null)
            setFiles([])
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handleTransportBook = async () => {
        console.log('handleChange', Transport)
        let Transportval = {
            ...Transport,
            farmerId: farmer,
            MicroEnterpreneurId: MicroEnt,
        }
        try {
            const { data } = await API.post('/createTransport', Transportval)
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)
                setTransport(TransportClear)
                getTransportBooking()
                // setCropTypes([]);
            } else {
                setTransport(TransportClear)
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setTransport(TransportClear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    const handleChange = (i, e) => {
        let newFormValues = [...formValues]
        newFormValues[i][e.target.name] = e.target.value
        setFormValues(newFormValues)
    }
    const addFields = () => {
        setFormValues([
            ...formValues,
            {
                subCategory: '',
                Brand: '',
                product: '',
                unit: '',
                price: '',
                stock: '',
                quantity: '',
                totalPrice: '',
            },
        ])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues]
        newFormValues.splice(i, 1)
        setFormValues(newFormValues)
    }

    const handleChangeLive = (i, e) => {
        let newFormValues = [...formvalueLive]
        newFormValues[i][e.target.name] = e.target.value
        setFormValuesLive(newFormValues)
    }

    const handleLiveStockBook = async () => {
        console.log('handleBook', LivestockBooking, formvalueLive, {
            ...LivestockBooking,
            input: formvalueLive,
            farmerId: farmer,
            MicroEnterpreneurId: MicroEnt,
        })
        setstate(formvalueLive, { ...LivestockBooking, inputs: formvalueLive })
        let livestock = {
            ...LivestockBooking,
            inputs: formvalueLive,
            farmerId: farmer,
            MicroEnterpreneurId: MicroEnt,
        }
        try {
            const { data } = await API.post('/liveStockBooking', livestock)
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)
                setFormValuesLive([formvalueclear])
                setlivestockBooking([LivestockBookingclear])
                getLivestockBooking()
                // setCropTypes([]);
            } else {
                setFormValuesLive([formvalueclear])
                setlivestockBooking([LivestockBookingclear])
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setFormValuesLive([formvalueclear])
            setlivestockBooking([LivestockBookingclear])
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    let removeFormFieldsLive = (i) => {
        let newFormValues = [...formvalueLive]
        newFormValues.splice(i, 1)
        setFormValuesLive(newFormValues)
    }

    const addFieldsLive = () => {
        setFormValuesLive([
            ...formvalueLive,
            {
                subCategory: '',
                brand: '',
                product: '',
                unit: '',
                price: '',
                stock: '',
                quantity: '',
                totalPrice: '',
            },
        ])
    }

    const handleMachinebook = async () => {
        console.log('machine', machine)
        let machineVal = {
            ...machine,
            farmerId: farmer,
            MicroEnterpreneurId: MicroEnt,
        }
        try {
            const { data } = await API.post('/machineBooking', machineVal)

            console.log('data', data)
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)
                setmachine(machineclear)
                getMachineBooking()
                // setCropTypes([]);
            } else {
                setmachine(machineclear)
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setmachine(machineclear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handleInputBook = async () => {
        console.log('handleBook', formValues, {
            ...inputBooking,
            input: formValues,
        })
        let inputBook = {
            ...inputBooking,
            inputs: formValues,
            farmerId: farmer,
            MicroEnterpreneurId: MicroEnt,
        }

        const { data } = await API.post('/inputBookings', inputBook)
        if (data?.success) {
            Swal.fire({
                icon: 'success',
                iconColor: '#28a745',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
            getInputBooking()
            console.log(data)
            setFormValues([formClear])
            setInputBooking([bookClear])
            setAddInput([formClear])
            //  getInputBooking()
            //  setCropTypes([]);
            //  props.getFarmers();
        } else {
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handleBook = async () => {
        console.log('handleBook', formValues, {
            ...inputBooking,
            input: formValues,
        })
        let inputBook = {
            ...inputBooking,
            inputs: formValues,
            farmerId: farmer,
            MicroEnterpreneurId: MicroEnt,
        }

        const { data } = await API.post('/inputBookings', inputBook)
        console.log('datas', data)
        if (data?.success) {
            Swal.fire({
                icon: 'success',
                iconColor: '#28a745',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
            console.log(data)
            setFormValues([formClear])
            setInputBooking([bookClear])
            setAddInput([formClear])
            //  getInputBooking()
            //  setCropTypes([]);
            //  props.getFarmers();
        } else {
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    return (
        <div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card card-table p-10-25">
                        <div class="card-header">
                            <h5 class="card-title">Yours</h5>
                        </div>
                        <form
                            class="card-body"
                            onSubmit={(e) => {
                                e.preventDefault()
                                console.log('Form Successfully working')
                            }}
                        >
                            <div class="tab-content">
                                <div action="#">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                id=""
                                                label="Number"
                                                variant="standard"
                                                value={yourNumber}
                                                onChange={(e) =>
                                                    handleEnterpreneur(e)
                                                }
                                            />
                                        </div>

                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                disabled
                                                id="farmerName"
                                                //  label="Farmer Name"
                                                placeholder="Role"
                                                variant="standard"
                                                value={
                                                    Microinfo
                                                        ? Microinfo.role
                                                        : ''
                                                }
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                disabled
                                                id="farmerName"
                                                //  label="Farmer Name"
                                                placeholder="Id"
                                                variant="standard"
                                                value={
                                                    Microinfo
                                                        ? Microinfo.id
                                                        : ''
                                                }
                                            />
                                        </div>

                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                disabled
                                                id=""
                                                //  label="Name"
                                                placeholder="Name"
                                                variant="standard"
                                                value={
                                                    Microinfo
                                                        ? Microinfo.first_name
                                                        : ''
                                                }
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                disabled
                                                id=""
                                                //  label="State"
                                                placeholder="State"
                                                variant="standard"
                                                value={
                                                    Microinfo
                                                        ? Microinfo.state
                                                        : ''
                                                }
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                disabled
                                                id=""
                                                //  label="District"
                                                placeholder="District"
                                                variant="standard"
                                                value={
                                                    Microinfo
                                                        ? Microinfo.district
                                                        : ''
                                                }
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                disabled
                                                id=""
                                                //  label="Village"
                                                placeholder="Village"
                                                variant="standard"
                                                value={
                                                    Microinfo
                                                        ? Microinfo.village
                                                        : ''
                                                }
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                disabled
                                                id=""
                                                //  label="Pincode"
                                                placeholder="Pincode"
                                                variant="standard"
                                                value={
                                                    Microinfo
                                                        ? Microinfo.pincode
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Farmer Info */}
                            <div class="card-header">
                                <h5 class="card-title">Info</h5>
                            </div>

                            <div class="tab-content">
                                <div action="#">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                id=""
                                                //  label="Farmer Number"
                                                placeholder="Number"
                                                variant="standard"
                                                value={infoNumber}
                                                onChange={(e) => handleFormr(e)}
                                            />
                                        </div>

                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                disabled
                                                id="farmerName"
                                                //  label="Farmer Name"
                                                placeholder="Role"
                                                variant="standard"
                                                value={
                                                    Formerinfo
                                                        ? Formerinfo.role
                                                        : ''
                                                }
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                disabled
                                                id="farmerName"
                                                //  label="Farmer Name"
                                                placeholder="Id"
                                                variant="standard"
                                                value={
                                                    Formerinfo
                                                        ? Formerinfo.id
                                                        : ''
                                                }
                                            />
                                        </div>

                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                disabled
                                                id=""
                                                //  label="Farmer Name"
                                                placeholder="Name"
                                                variant="standard"
                                                value={
                                                    Formerinfo
                                                        ? Formerinfo.first_name
                                                        : ''
                                                }
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                disabled
                                                id=""
                                                //  label="State"
                                                placeholder="State"
                                                variant="standard"
                                                value={
                                                    Formerinfo
                                                        ? Formerinfo.state
                                                        : ''
                                                }
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                disabled
                                                fullWidth
                                                id=""
                                                //  label="District"
                                                placeholder="District"
                                                variant="standard"
                                                value={
                                                    Formerinfo
                                                        ? Formerinfo.district
                                                        : ''
                                                }
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                disabled
                                                id=""
                                                //  label="Village"
                                                placeholder="Village"
                                                variant="standard"
                                                value={
                                                    Formerinfo
                                                        ? Formerinfo.village
                                                        : ''
                                                }
                                            />
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                disabled
                                                fullWidth
                                                id=""
                                                //  label="Pincode"
                                                placeholder="Pincode"
                                                variant="standard"
                                                value={
                                                    Formerinfo
                                                        ? Formerinfo.pincode
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </div>
                                    <ul className="nav nav-tabs nav-tabs-bottom nav-justified mt-4">
                                        <li class="nav-item">
                                            <a
                                                class={
                                                    tab === 1
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                                href="#bottom-justified-tab1"
                                                data-bs-toggle="tab"
                                                onClick={(e) => setTab(1)}
                                            >
                                                Input Booking
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                class="nav-link"
                                                href="#bottom-justified-tab3"
                                                data-bs-toggle="tab"
                                                onClick={(e) => setTab(3)}
                                            >
                                                Livestock Booking
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                class="nav-link"
                                                href="#bottom-justified-tab2"
                                                data-bs-toggle="tab"
                                                onClick={(e) => setTab(2)}
                                            >
                                                Machine Booking
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                class="nav-link"
                                                href="#bottom-justified-tab4"
                                                data-bs-toggle="tab"
                                                onClick={(e) => setTab(4)}
                                            >
                                                Transport Booking
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                class="nav-link "
                                                href="#bottom-justified-tab5"
                                                data-bs-toggle="tab"
                                                onClick={(e) => setTab(5)}
                                            >
                                                Sell Crop
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {/* Input Bookings */}
                                {tab === 1 && (
                                    <InputBookings
                                        farmer={farmer}
                                        MicroEnt={MicroEnt}
                                    />
                                )}
                                {/* Machine Booking */}
                                {tab === 2 && (
                                    <MachineBooking
                                        farmer={farmer}
                                        MicroEnt={MicroEnt}
                                    />
                                )}
                                {/* Livestock Booking */}
                                {tab === 3 && (
                                    <LivestockBookings
                                        farmer={farmer}
                                        MicroEnt={MicroEnt}
                                    />
                                )}
                                {/* Transport Booking */}
                                {tab === 4 && (
                                    <Transports
                                        farmer={farmer}
                                        MicroEnt={MicroEnt}
                                    />
                                )}
                                {/* Sell Crop */}
                                {tab === 5 && (
                                    <SellCrop
                                        farmer={farmer}
                                        MicroEnt={MicroEnt}
                                    />
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bookings
