import React from 'react'

function InpCategories() {
    return (
        <div>
            <div className="card card-table p-10-25">
                <form action="#">
                    <div className="mt-3">
                        <h5 class="card-title">Input Supplier Categories</h5>
                        <div class="row mb-4">
                            <div class="col-md-4"></div>
                        </div>

                        <div className="row justify-content-end mb-3">
                            <div className="col-md-1"></div>
                            <div className="col-md-1"></div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default InpCategories
