import React, { useEffect, useState } from 'react'
import { CssTextField } from '../Styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { API } from '../../API/index'
import Swal from 'sweetalert2'

const addBasicSettingclear = {
    projectLogo: '',
    emailLogo: '',
    currencySymbol: '',
    phone: '',
    email: '',
    projectName: '',
    countryCode: '',
    appLink: '',
}

function BasicSettings() {
    const [addbasic, setaddbasic] = useState({
        projectLogo: '',
        emailLogo: '',
        currencySymbol: '',
        phone: '',
        email: '',
        projectName: '',
        countryCode: '',
        appLink: '',
    })

    // useEffect(()=>{
    //    handleBook();
    // },[])
    const handleBook = async () => {
        console.log('handleBook', addbasic)
        try {
            const { data } = await API.post('/addBasicSetting', addbasic)
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)
                setaddbasic(addBasicSettingclear)
            } else {
                setaddbasic(addBasicSettingclear)
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setaddbasic(addBasicSettingclear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Basic Settings</h5>
                </div>
                <div class="card-body">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div class="row form-group">
                            <div class="col-sm-3">
                                <label
                                    for="name"
                                    class="col-form-label input-label"
                                >
                                    Project Logo
                                </label>
                                <div class="d-flex align-items-center">
                                    <label
                                        class="avatar avatar-xxl profile-cover-avatar m-0"
                                        for="edit_img"
                                    >
                                        <img
                                            id="avatarImg"
                                            class="avatar-img"
                                            src="../assets/images/master_logo.jpeg"
                                            alt="Profile Image"
                                        />
                                        <input type="file" id="edit_img" />
                                        <span class="avatar-edit">
                                            <span class="material-icons-outlined">
                                                photo_camera
                                            </span>
                                        </span>
                                    </label>
                                </div>
                                <hr class="p-hr" />
                                <label
                                    for="name"
                                    class="col-form-label input-label "
                                >
                                    Email Logo
                                </label>
                                <div class="d-flex align-items-center">
                                    <label
                                        class="avatar avatar-xxl profile-cover-avatar m-0"
                                        for="edit_img"
                                    >
                                        <img
                                            id="avatarImg"
                                            class="avatar-img"
                                            src="../assets/images/logo-1.png"
                                            alt="Profile Image"
                                        />
                                        <input type="file" id="edit_img" />
                                        <span class="avatar-edit">
                                            <span class="material-icons-outlined">
                                                photo_camera
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-7">
                                <div class="row form-group">
                                    <label
                                        for="addressline1"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        Currency Symbol
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="addressline1"
                                        value={addbasic.currencySymbol}
                                        onChange={(e) =>
                                            setaddbasic({
                                                ...addbasic,
                                                currencySymbol: e.target.value,
                                            })
                                        }
                                    />
                                </div>

                                <div class="row form-group">
                                    <label
                                        for="addressline1"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        Phone
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="addressline1"
                                        value={addbasic.phone}
                                        onChange={(e) =>
                                            setaddbasic({
                                                ...addbasic,
                                                phone: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div class="row form-group">
                                    <label
                                        for="addressline1"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        Email
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="addressline1"
                                        value={addbasic.email}
                                        onChange={(e) =>
                                            setaddbasic({
                                                ...addbasic,
                                                email: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div class="row form-group">
                                    <label
                                        for="addressline1"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        Project Name
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="addressline1"
                                        value={addbasic.projectName}
                                        onChange={(e) =>
                                            setaddbasic({
                                                ...addbasic,
                                                projectName: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div class="row form-group">
                                    <label
                                        for="addressline1"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        Country Code
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="addressline1"
                                        value={addbasic.countryCode}
                                        onChange={(e) =>
                                            setaddbasic({
                                                ...addbasic,
                                                countryCode: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div class="row form-group">
                                    <label
                                        for="addressline1"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        App Link
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="addressline1"
                                        value={addbasic.appLink}
                                        onChange={(e) =>
                                            setaddbasic({
                                                ...addbasic,
                                                appLink: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div class="row form-group">
                                    <label
                                        for="addressline1"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        Whatsapp
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="addressline1"
                                    />
                                </div>
                                <div class="row form-group">
                                    <label
                                        for="addressline1"
                                        class="p-l-0 col-form-label input-label"
                                    >
                                        Distance Unit
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="addressline1"
                                        //  value={addbasic.Distance Unit}
                                        //  onChange={(e)=>setaddbasic({...addbasic, Distance Unit: e.target.value})}
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row form-group p-hr-1">
                            <div class="col-sm-12">
                                <label
                                    for="addressline1"
                                    class="p-l-0 col-form-label input-label"
                                >
                                    Invitation Message
                                </label>
                                <span class="referral_code">
                                    Use referral_code for Referral Code
                                </span>
                                <input
                                    type="text"
                                    class="form-control m-t-10"
                                    id="addressline1"
                                    placeholder="km"
                                    value={addbasic.currencySymbol}
                                    onChange={(e) =>
                                        setaddbasic({
                                            ...addbasic,
                                            currencySymbol: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>

                        <div class="text-end">
                            <button
                                type="submit"
                                class="btn btn-primary"
                                onClick={() => handleBook()}
                            >
                                Save Changes
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default BasicSettings
