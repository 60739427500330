import React, { useState, useEffect } from 'react'
import { CssTextField } from '../Styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import MicroEnt from './MicroEnt'
import InputBookings from './CreateInputBooking/InputBookings'
import SellCrop from './SellCrop/SellCrop'
import MachineBooking from './CreateMachineBooking/MachineBooking'
import Transport from './Transport/Transport'
import LivestockBooking from './LiveStock/LivestockBooking'
import axios from 'axios'
import TradersBooking from './TradersBooking'

function Bookings() {
    const [tab, setTab] = useState(1)
    const [bookAs, setBookAs] = useState(1)
    const [Formerinfo, setFormerinfo] = useState({})

    const handleFarmer = (e) => {
        if (e.target.value.length == 10) {
            getFormerinfo(e.target.value)
        } else {
            setFormerinfo({})
        }
    }
    const getFormerinfo = async (Mob) => {
        const token = localStorage.getItem('token')
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        const farmerNumber = {
            mobile_no: Mob,
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/register-details`,
                farmerNumber,
                config
            )
            .then((res) => {
                const d = res.data.data.data
                localStorage.setItem('FormerId', d.user_id)
                localStorage.setItem('microId', '')
                setFormerinfo(d)
            })
            .catch((err) => {
                console.log(err)
                setFormerinfo({})
            })
    }

    return (
        <div>
            <div class="row">
                <div class="col-sm-12">
                    <div
                        className="Heade mb-3"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <h3 className="mt-2">Booking As</h3>
                        <FormControl
                            variant="standard"
                            sx={{ mt: 0, ml: 2, minWidth: 150 }}
                        >
                            <Select
                                className="ml-3"
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={bookAs}
                                onChange={(e) => setBookAs(e.target.value)}
                                label="Gender"
                                color="success"
                            >
                                <MenuItem value={1}>
                                    <em>- Select -</em>
                                </MenuItem>
                                <MenuItem value={1}>All</MenuItem>
                                <MenuItem value={2}>Others</MenuItem>
                                <MenuItem value={3}>Trader</MenuItem>{' '}
                            </Select>
                        </FormControl>
                    </div>
                    {bookAs === 1 && (
                        <div class="card card-table p-10-25">
                            <div class="card-header">
                                <h5 class="card-title">Info</h5>
                            </div>
                            <form
                                class="card-body"
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    console.log('Form Successfully working')
                                }}
                            >
                                <>
                                    <div class="tab-content">
                                        <div action="#">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="farmerNum"
                                                        //  label="Farmer Number"
                                                        placeholder="Farmer Number"
                                                        variant="standard"
                                                        //  value={}
                                                        onChange={(e) =>
                                                            handleFarmer(e)
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.role
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.id
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.first_name
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerState"
                                                        //  label="State"
                                                        placeholder="State"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.state
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="farmerDistrict"
                                                        disabled
                                                        //  label="District"
                                                        placeholder="District"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.district
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        disabled
                                                        fullWidth
                                                        id="farmerVillage"
                                                        //  label="Village"
                                                        placeholder="Village"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.village
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        disabled
                                                        fullWidth
                                                        id="farmerPincode"
                                                        //  label="Pincode"
                                                        placeholder="Pincode"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.pincode
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <ul className="nav nav-tabs nav-tabs-bottom nav-justified mt-4">
                                                <li class="nav-item">
                                                    <a
                                                        class={
                                                            tab === 1
                                                                ? 'nav-link active'
                                                                : 'nav-link'
                                                        }
                                                        href="#bottom-justified-tab1"
                                                        data-bs-toggle="tab"
                                                        onClick={(e) =>
                                                            setTab(1)
                                                        }
                                                    >
                                                        Input Booking
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        class="nav-link"
                                                        href="#bottom-justified-tab3"
                                                        data-bs-toggle="tab"
                                                        onClick={(e) =>
                                                            setTab(3)
                                                        }
                                                    >
                                                        Livestock Booking
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        class="nav-link"
                                                        href="#bottom-justified-tab2"
                                                        data-bs-toggle="tab"
                                                        onClick={(e) =>
                                                            setTab(2)
                                                        }
                                                    >
                                                        Machine Booking
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        class="nav-link"
                                                        href="#bottom-justified-tab4"
                                                        data-bs-toggle="tab"
                                                        onClick={(e) =>
                                                            setTab(4)
                                                        }
                                                    >
                                                        Transport Booking
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        class="nav-link "
                                                        href="#bottom-justified-tab5"
                                                        data-bs-toggle="tab"
                                                        onClick={(e) =>
                                                            setTab(5)
                                                        }
                                                    >
                                                        Sell Crop
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                        {/* Input Bookings */}
                                        {tab === 1 && <InputBookings />}
                                        {/* Machine Booking */}
                                        {tab === 2 && <MachineBooking />}
                                        {/* Livestock Booking */}
                                        {tab === 3 && <LivestockBooking />}
                                        {/* Transport Booking */}
                                        {tab === 4 && <Transport />}
                                        {/* Sell Crop */}
                                        {tab === 5 && <SellCrop />}
                                    </div>
                                </>

                                {/* <div className="col-md-12 text-end mt-2 mb-2 mt-4">
                        <button type="submit" class=" w-10 btn btn-primary">Book</button>
                      </div> */}
                            </form>
                        </div>
                    )}

                    {bookAs === 2 && <MicroEnt />}
                    {bookAs === 3 && <TradersBooking />}
                </div>
            </div>
        </div>
    )
}

export default Bookings
