import React, { useState, useEffect } from 'react'
import { CssTextField } from '../../Styles'
import FormControl from '@mui/material/FormControl'
import { styled } from '@mui/material/styles'
import { API } from '../../../API/index'
import { storage } from '../../../firebase/index'
import InputLabel from '@mui/material/InputLabel'
import { useDropzone } from 'react-dropzone'
import {
    thumbsContainerState,
    thumb,
    img,
    thumbInner,
    baseStyle,
    baseStyle02,
} from '../../Styles'
import Button from '@mui/material/Button'
import Swal from 'sweetalert2'
import axios from 'axios'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
// import Select from '@mui/material/Select';

import Select from 'react-select'
import MenuItem from '@mui/material/MenuItem'
import InputsellCroptable from './SellCropTable'
const SellCropClear = {
    MicroEnterpreneurId: '',
    // farmerId: "",
    farmerId: '',
    category: '',
    cropType: '',
    cropName: '',
    variety: '',
    variety: '',
    availabilityDate: '',
    availabilityDay: '',
    expectedQuailty: '',
    expectedPriceMin: '',
    expectedPriceMax: '',
    unit: '',
    description: '',
    photoUrl: '',
}
function SellCrop(props) {
    console.log('props', props)
    const [Files, setFiles] = useState([])
    const [file, setFile] = useState(null)
    const [docImg, setDocImg] = useState(null)
    console.log(file)
    const [getAllProduct, setgetAllProduct] = useState([])
    const [getAllCrop, setgetAllCrop] = useState([])

    const [getAllSubCategory, setgetAllSubCategory] = useState([])
    const [sellcropBookfetch, setsellcropBookfetch] = useState([])
    const [sellCrop, setSellCrop] = useState({
        MicroEnterpreneurId: '',
        farmerId: '',
        category: '',
        cropType: '',
        cropName: '',
        variety: '',
        // variety: "",
        availabilityDate: '',
        availabilityDay: '',
        expectedQuailty: '',
        expectedPriceMin: '',
        expectedPriceMax: '',
        unit: '',
        description: '',
        photoUrl: [],
        bookedBy: 'MI',
        createdBy: 'Admin',
    })
    useEffect(() => {
        // getState();
        getSellCropBooking()
        getBodyType()
        getCropCatelog()
    }, [])
    const getSellCropBooking = async () => {
        const { data } = await API.get(
            `/getSellCropsBookings?type=0&bookedBy=Farmer`
        )
        setsellcropBookfetch(data?.result)
    }
    const getBodyType = async () => {
        // const { data } = await API.get(`/getProductSubCategory?type=2&title=ProductCatelog&catelogName=CropCatelog`);
        // console.log("cate", data)
        // setgetAllSubCategory(data?.result);
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-crops`,
                config
            )
            .then((res) => {
                console.log('crops:', res.data.crops)
                setgetAllSubCategory(res.data.crops)
            })
    }
    const getCropCatelog = async () => {
        // const { data } = await API.get(`/getProductCatelogProduct?type=0&title=ProductCatelog&catelogName=CropCatelog`);
        // console.log("cate", data)
        // setgetAllCrop(data?.result);
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-crops`,
                config
            )
            .then((res) => {
                console.log('crops2:', res.data.crops)
                setgetAllCrop(res.data.crops)
            })
    }
    const handleBook = async () => {
        var token = localStorage.getItem('token')
        console.log('handleChange', sellCrop)
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }

        var data = {
            product_id:sellCrop.productId,
            user_id: localStorage.getItem('FormerId'),
            createdById: localStorage.getItem('microId')||"",
            crop_name: sellCrop.cropName,
            crop_type: sellCrop.crop_type,
            crop_image: sellCrop.cropTypeImage,
            variety: 'test',
            availability_date: sellCrop.availabilityDate,
            availability_days: sellCrop.availabilityDay,
            quantity: sellCrop.expectedQuailty,
            unit: sellCrop.unit,
            type: 'sell',
            price: Number(sellCrop.expectedPriceMax),
            remark: sellCrop.description,
            images: sellCrop.photoUrl,
            status: 'in_progress',
        }

        try {
            let sellCropval = {
                ...sellCrop,
                farmerId: props && props.farmer,
                MicroEnterpreneurId: props && props.MicroEnt,
            }
            // const { data } = await API.post('/sellCropsBooking', sellCropval);
            axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product/sell`,
                    data,
                    config
                )
                .then((res) =>
                    Swal.fire({
                        icon: 'success',
                        iconColor: '#28a745',
                        title: data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                )
                .catch((err) =>
                    Swal.fire({
                        icon: 'warning',
                        iconColor: '#dc3545',
                        title: 'error occured!',
                        showConfirmButton: false,
                        timer: 2000,
                    })
                )
            // if (data?.success) {
            //   Swal.fire({
            //     icon: 'success',
            //     iconColor: '#28a745',
            //     title: data?.message,
            //     showConfirmButton: false,
            //     timer: 2000
            //   });
            //   console.log(data);
            //   setSellCrop(SellCropClear);
            //   setDocImg(null)
            //   setFiles([])
            //   getSellCropBooking()
            //   // setCropTypes([]);
            // } else {
            //   setSellCrop(SellCropClear);
            //   setDocImg(null)
            //   setFiles([])
            //   Swal.fire({
            //     icon: 'warning',
            //     iconColor: '#dc3545',
            //     title: data?.message,
            //     showConfirmButton: false,
            //     timer: 2000
            //   });
            // }
        } catch (err) {
            setSellCrop(SellCropClear)
            setDocImg(null)
            setFiles([])
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            console.log('file1', acceptedFiles)
            console.log('file', acceptedFiles[0])
            setFiles(
                acceptedFiles.map((file) =>
                    //  { console.log("file",file)},
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
            let val = []
            let arr = []
            for (let i = 0; i < acceptedFiles.length; i++) {
                setDocImg(URL.createObjectURL(acceptedFiles[i]))
                const doc = acceptedFiles[i]
                if (acceptedFiles[i]) {
                    const uploadTask = ref(storage, `docs/${doc.name}`)
                    const uploadTask02 = uploadBytesResumable(uploadTask, doc)
                    uploadTask02.on(
                        'state_changed',
                        (snapshot) => {},
                        (error) => {
                            // Handle unsuccessful uploads
                        },
                        () => {
                            getDownloadURL(uploadTask).then((url) => {
                                console.log('url', url)

                                arr.push(url)
                            })
                        }
                    )
                    setSellCrop({ ...sellCrop, photoUrl: arr })
                    console.log('arr', arr)
                }
            }

            // setDocImg(URL.createObjectURL(acceptedFiles[0]));
            //       const doc = acceptedFiles[0];
            //       if(acceptedFiles[0]) {
            //         const uploadTask = ref(storage, `docs/${doc.name}`);
            //         const uploadTask02 = uploadBytesResumable(uploadTask, doc);
            //         uploadTask02.on('state_changed',
            //           (snapshot) => {
            //           },
            //           (error) => {
            //             // Handle unsuccessful uploads
            //           },
            //           () => {
            //             getDownloadURL(uploadTask).then((url) => {
            //               setSellCrop({...sellCrop, photoUrl: url});
            //             });
            //           }
            //         );
            //       }
        },
    })

    const PGthumbs =
        Files &&
        Files.map((file, index) => (
            <div style={thumb} key={file.name}>
                <div style={thumbInner}>
                    <img src={file.preview} style={img} alt="" />
                </div>
            </div>
        ))
    const vehicleOptiom =
        getAllSubCategory &&
        getAllSubCategory.map((item, ind) => ({
            value: item.subCategoryName,
            label: item.subCategoryName,
        }))
    const typeoptiob =
        getAllCrop &&
        getAllCrop.map((item, ind) => ({
            value: item.productName,
            label: item.productName,
            image: item.productImage,
            type: item.subCategoryName,
            productId:item._id,

        }))
    const CropOptions = [
        {
            label: 'KG',
            value: 'KG',
        },
        {
            label: 'TON',
            value: 'TON',
        },
        {
            label: 'QUINTAL',
            value: 'QUINTAL',
        },
    ]

    return (
        <>
            <div>
                <div>
                    <div class="card-header">
                        <h5 class="card-title">Crop Booking Info</h5>
                    </div>
                    {console.log('sellCrop', sellCrop)}
                    <div className="mt-3">
                        <div class="row">
                            <div class="col-md-4">
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    className="mt-3"
                                >
                                    {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Booking Mode</InputLabel> */}
                                    <Select
                                        style={{
                                            width: '270px',
                                            height: '40px',
                                        }}
                                        placeholder={'Select CropName'}
                                        // value={inputBooking.modeOfBooking}
                                        onChange={(e) =>
                                            setSellCrop({
                                                ...sellCrop,
                                                cropName: e.value,
                                                cropTypeImage: e.image,
                                                crop_type: e.type,
                                                productId:e.productId

                                            })
                                        }
                                        options={typeoptiob}
                                    />
                                </FormControl>
                            </div>

                            <div class="col-md-3"></div>
                            <div class="col-md-3">
                                <InputLabel
                                    id="demo-simple-select-standard-label"
                                    style={{ color: 'black' }}
                                >
                                    Delivery Date
                                </InputLabel>
                                <CssTextField
                                    fullWidth
                                    type="date"
                                    id="AvailDate"
                                    className="mt-2"
                                    label=""
                                    variant="standard"
                                    value={sellCrop.availabilityDate}
                                    onChange={(e) =>
                                        setSellCrop({
                                            ...sellCrop,
                                            availabilityDate: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div class="col-md-3">
                                <CssTextField
                                    value={sellCrop.availabilityDay}
                                    onChange={(e) =>
                                        setSellCrop({
                                            ...sellCrop,
                                            availabilityDay: e.target.value,
                                        })
                                    }
                                    fullWidth
                                    type="number"
                                    id="AvailDays"
                                    label="Availability Days"
                                    variant="standard"
                                />
                            </div>
                            <div class="col-md-3">
                                <CssTextField
                                    value={sellCrop.expectedQuailty}
                                    onChange={(e) =>
                                        setSellCrop({
                                            ...sellCrop,
                                            expectedQuailty: e.target.value,
                                        })
                                    }
                                    fullWidth
                                    type="text"
                                    id="expQuantity"
                                    label="Expected Quantity"
                                    variant="standard"
                                />
                            </div>
                            {/* <div class="col-md-3"> */}
                            <div class="col-md-3" style={{ marginTop: '2%' }}>
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    className="mt-3"
                                >
                                    {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Booking Mode</InputLabel> */}
                                    <Select
                                        style={{
                                            width: '270px',
                                            height: '40px',
                                        }}
                                        placeholder={'Select Unit'}
                                        // value={inputBooking.modeOfBooking}
                                        onChange={(e) =>
                                            setSellCrop({
                                                ...sellCrop,
                                                unit: e.value,
                                            })
                                        }
                                        options={CropOptions}
                                    />
                                </FormControl>
                            </div>

                            <div class="col-md-3">
                                <CssTextField
                                    value={sellCrop.expectedPriceMax}
                                    onChange={(e) =>
                                        setSellCrop({
                                            ...sellCrop,
                                            expectedPriceMax: e.target.value,
                                        })
                                    }
                                    fullWidth
                                    type="text"
                                    id="expMax"
                                    label="Price"
                                    variant="standard"
                                />
                            </div>
                            <div class="col-md-6">
                                <CssTextField
                                    value={sellCrop.description}
                                    onChange={(e) =>
                                        setSellCrop({
                                            ...sellCrop,
                                            description: e.target.value,
                                        })
                                    }
                                    fullWidth
                                    type="text"
                                    id="description"
                                    label="Description"
                                    variant="standard"
                                />
                            </div>
                            <div className="col-md-12 mt-3">
                                <InputLabel
                                    id="demo-simple-select-standard-label"
                                    style={{ color: 'black' }}
                                >
                                    Upload Crop Photo:
                                </InputLabel>
                                <div
                                    class="form-group row widget-3"
                                    style={{
                                        margin: 'auto',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <section
                                        className="container"
                                        style={baseStyle02}
                                    >
                                        <div
                                            {...getRootProps({
                                                className: 'dropzone',
                                            })}
                                            style={{
                                                borderRadius: '5px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '100px',
                                            }}
                                        >
                                            <input
                                                {...getInputProps()}
                                                multiple={true}
                                            />
                                            {Files && (
                                                <>
                                                    {Files.length === 0 && (
                                                        <p align="center">
                                                            Drop Image here or
                                                            click to upload.
                                                        </p>
                                                    )}
                                                </>
                                            )}
                                            {Files && (
                                                <>
                                                    {Files.length > 0 && (
                                                        <aside
                                                            style={
                                                                thumbsContainerState
                                                            }
                                                        >
                                                            {PGthumbs}
                                                        </aside>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 text-end mt-2 mb-2 mt-4">
                            <button
                                type="submit"
                                class=" w-10 btn btn-primary"
                                onClick={() => handleBook()}
                            >
                                Book
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SellCrop
