import React from 'react'

function AssignSettings() {
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Delivery Assign Settings</h5>
                </div>
                <div class="card-body">
                    <form>
                        <div class="col-md-7">
                            <div class="row form-group">
                                <label
                                    for="addressline1"
                                    class="p-l-0 col-md-6 col-form-label input-label pt-10"
                                >
                                    Create Delivery Tasks
                                </label>
                                <span class="col-6 col-sm-6 mt-10">
                                    <input
                                        type="checkbox"
                                        class="toggle-switch-input lb_check"
                                        id="notification_switch1"
                                    />
                                    <span class="toggle-switch-label ms-auto">
                                        <span class="toggle-switch-indicator"></span>
                                    </span>
                                </span>
                            </div>

                            <div class="row form-group">
                                <label
                                    for="addressline1"
                                    class="p-l-0 col-md-6 col-form-label input-label pt-10"
                                >
                                    Autoassign Delivery Tasks
                                </label>
                                <span class="col-6 col-sm-6 mt-10">
                                    <input
                                        type="checkbox"
                                        class="toggle-switch-input lb_check"
                                        id="notification_switch1"
                                    />
                                    <span class="toggle-switch-label ms-auto">
                                        <span class="toggle-switch-indicator"></span>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AssignSettings
