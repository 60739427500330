import React, { useState, useEffect } from 'react'
import { CssTextField } from '../Styles'
import AddFarmer from './AddFarmer'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { useDropzone } from 'react-dropzone'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useParams } from 'react-router-dom'
import Wallet from '../../components/Wallet'

import FarmerTable from '../Create/CreateFarmer/FarmerTable'
import {
    thumbsContainerState,
    thumb,
    img,
    mapThumb,
    thumbInner,
    baseStyle,
} from '../Styles'
import { API } from '../../API'
import axios from 'axios'
import Map2 from '../Create/Map2'
import ModuleOrders from '../../components/ModuleOrders'
import BankingDetails from '../../components/BankingDetails'
import EditMicroEnterpreneur from '../Create/CreateMicroEnt/EditMicro'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import Swal from 'sweetalert2'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}
const names = ['Wheat', 'Rice', 'Barley', 'Cereal', 'Tomato', 'Oat']

function MicroEntrep() {
    const [addDoc, setAddDoc] = useState(false)
    const [addWallet, setAddWallet] = useState(false)
    const [Files, setFiles] = useState([])
    const [Details, setDetails] = useState({})
    const [userId, setUserId] = useState('')
    const [idForAssign, setIdForAssign] = useState('')
    const [listCompData, setListCompData] = useState({})
    const [bankDetails, setBankDetails] = useState({})
    const { id } = useParams()
    const [fpoList, setFpoList] = useState([])
    const [fpo, setFpo] = useState([])
    const [farmers, setFarmers] = useState([])
    useEffect(() => {
        getAllFarmers()
    }, [])

    const getAllFarmers = async () => {
        const { data } = await API.get(`/getFarmer?type=0`)
        setFarmers(data?.result)
    }
    useEffect(async () => {
        var farmerid = localStorage.getItem('MicroEntfarmerid')
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }

        var payload = {
            mobile_no: id,
            role: 'micro-entrepreneur',
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-register`,
            payload,
            config
        )

        console.log('data', data)
        if (data.data) {
            var a = {
                _id: data.data._id,
                id:  data.data.data.id,

                user_id:  data.data.data.user_id,
                firstName: data.data.data.first_name,
                lastName: data.data.data.last_name,
                mobileNumber: data.data.data.mobile_number,
                gender: data.data.data.gender,
                category: data.data.data.role,
                dateOfBirth: '2022-04-10',
                partnerMode: data.data.data.partner_mode,
                education: '',
                language: data.data.data.language,
                state: data.data.data.state,
                district: data.data.data.district,
                subDistrict: data.data.data.sub_distric,
                village: data.data.data.village,
                pincode: data.data.data.pincode,
                adhaarNumber: '',
                fullAddress: data.data.data.full_address,
                bankAccountNumber: '',
                bankAccountName: '',
                // "ifscCode": "",
                lat: data.data.data.coordinates.coordinates[1],
                long: data.data.data.coordinates.coordinates[0],
                bankName: '',
                bankBranchName: '',
                irrigation_source: 'Canal',
                mobile_number: '6353454356',
                soil_test: true,
                soil_type: 'Alluvial soil',
                sowing_date: '2022-04-08',
            }
            var farmInfo = [data.data.farm_info]
            const localId = data.data.data.user_id
            setUserId(data.data.data.user_id)
            setIdForAssign(data.data.data.id)
            setBankDetails(data.data.data.bank_info)

            setDetails(a)

            let dataApi = {
                userId: localId,
                role: 'farmer',
            }
            axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/auth-function/registerBy-users-admin`,
                    dataApi,
                    config
                )
                .then((res) => {
                    const d = res.data.userData
                    if (d) {
                        const a = d.map((val) => {
                            return {
                                id: val?.id,
                                mobile_number: val?.mobile_number,
                                name: val?.first_name + val?.last_name,
                                created: val.created_at,
                                urlToRedirect: 'AgriMachineBookingDetail',
                            }
                        })
                        setListCompData(a)
                    }
                })
        }
    }, [])

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
        },
    })
    const handleSelectChange = (selectedOption, index) => {
        setFpo(selectedOption)
    }

    const joinFpo=()=>{
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data={
            userId:Details.id,
            createdById:fpo[0].createdById,
            role:"fpo"
        }
        axios.post( `${process.env.REACT_APP_API_ENDPOINT}/auth-function/setregister-by`,data,config).then((res) => {
            Swal.fire({
                icon: 'success',
                iconColor: '#28a745',
                title: 'Successfully Joined',
                showConfirmButton: false,
                timer: 2000,
            })
           

        })
        .catch((err) =>
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: 'error occured!',
                showConfirmButton: false,
                timer: 2000,
            })
        )
    }
    const handleSearch = (query) => {
        const baseUrls = `${process.env.REACT_APP_API_ENDPOINT}/auth-function/fpo-list/?search=`
        // const baseUrl = "https://Warranty.lsin.panasonic.com/api/generator/?search=";
        const configs = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }
        axios.get(`${baseUrls}${query}`, configs).then((res) => {
            console.log(res.data.msg)
            // setData(res.data.msg)
            var colourOptions = res.data.data.map((a) => {
                return {
                    //  value:a.itemCode,
                    label: a.id,
                    createdById: a.user_id,
                }
            })
            setFpoList(colourOptions)

            // fetch(`${SEARCH_URI}?q=${query}+in:login&page=1&per_page=50`)
            //   .then((resp) => resp.json())
            //   .then(({ items }: Response) => {
            //     setOptions(items);
            //     setIsLoading(false);
            //   });
        })
    }
    const PGthumbs = Files.map((file, index) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt="" />
            </div>
        </div>
    ))

    const handleCB = (e) => {
        e.preventDefault()
    }

    const handleListComp = (tab) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        let data
        switch (tab) {
            case 1:
                data = {
                    userId: userId,
                    role: 'farmer',
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/auth-function/registerBy-users-admin`,
                        data,
                        config
                    )
                    .then((res) => {
                        const d = res.data.userData
                        if (d) {
                            const a = d.map((val) => {
                                return {
                                    id: val?.id,
                                    mobile_number: val?.mobile_number,
                                    name: val?.first_name + val?.last_name,
                                    created: val.created_at,
                                    urlToRedirect: 'AgriMachineBookingDetail',
                                }
                            })
                            setListCompData(a)
                        }
                    })
                break
            case 2:
                data = {
                    userId: userId,
                    role: 'livestock',
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/auth-function/registerBy-users-admin`,
                        data,
                        config
                    )
                    .then((res) => {
                        const d = res.data.userData
                        if (d) {
                            const a = d.map((val) => {
                                return {
                                    id: val?.id,
                                    mobile_number: val?.mobile_number,
                                    name: val?.first_name + val?.last_name,
                                    created: val.created_at,
                                    urlToRedirect: 'AgriMachineBookingDetail',
                                }
                            })
                            setListCompData(a)
                        }
                    })
                break
            case 3:
                data = {
                    userId: userId,
                    role: 'agri-input-supplier',
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/auth-function/registerBy-users-admin`,
                        data,
                        config
                    )
                    .then((res) => {
                        const d = res.data.userData
                        if (d) {
                            const a = d.map((val) => {
                                return {
                                    id: val?.id,
                                    mobile_number: val?.mobile_number,
                                    name: val?.first_name + val?.last_name,
                                    created: val.created_at,
                                    urlToRedirect: 'AgriMachineBookingDetail',
                                }
                            })
                            setListCompData(a)
                        }
                    })
                break
            case 4:
                data = {
                    userId: userId,
                    role: 'agri-machine',
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/auth-function/registerBy-users-admin`,
                        data,
                        config
                    )
                    .then((res) => {
                        const d = res.data.userData
                        if (d) {
                            const a = d.map((val) => {
                                return {
                                    id: val?.id,
                                    mobile_number: val?.mobile_number,
                                    name: val?.first_name + val?.last_name,
                                    created: val.created_at,
                                    urlToRedirect: 'AgriMachineBookingDetail',
                                }
                            })
                            setListCompData(a)
                        }
                    })
                break
            case 5:
                data = {
                    userId: userId,
                    role: 'agri-transport',
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/auth-function/registerBy-users-admin`,
                        data,
                        config
                    )
                    .then((res) => {
                        const d = res.data.userData
                        if (d) {
                            const a = d.map((val) => {
                                return {
                                    id: val?.id,
                                    mobile_number: val?.mobile_number,
                                    name: val?.first_name + val?.last_name,
                                    created: val.created_at,
                                    urlToRedirect: 'AgriMachineBookingDetail',
                                }
                            })
                            setListCompData(a)
                        }
                    })
                break
            case 6:
                data = {
                    userId: userId,
                    role: 'agri-input-supplier',
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/auth-function/registerBy-users-admin`,
                        data,
                        config
                    )
                    .then((res) => {
                        const d = res.data.userData
                        if (d) {
                            const a = d.map((val) => {
                                return {
                                    id: val?.id,
                                    mobile_number: val?.mobile_number,
                                    name: val?.first_name + val?.last_name,
                                    created: val.created_at,
                                    urlToRedirect: 'AgriMachineBookingDetail',
                                }
                            })
                            setListCompData(a)
                        }
                    })
                break
        }
    }

    return (
        <div>
            <div class="row">
                <div class="page-header">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <ul class="breadcrumb justify-content-end">
                                <li class="breadcrumb-item">
                                    <a href="#">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="#">Micro Enterpreneur</a>
                                </li>
                                <li class="breadcrumb-item active">
                                    {Details.firstName}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="card-title d-flex justify-content-between store_edit">
                                <span>Micro Enterpreneur</span>
                                <a
                                    href="#"
                                    class="btn btn-tbl-edit btn-xs td_btn"
                                >
                                    <span class="material-icons-outlined">
                                        edit
                                    </span>
                                </a>
                            </h5>
                            <a class="store-avatar">
                                <div class="staffAvatar-root1">
                                    {Details &&
                                    Details.firstName &&
                                    Details.firstName.length > 0
                                        ? Details.firstName.slice(0, 1)
                                        : ''}
                                </div>
                                <div class="store_display">
                                    <h4>{Details.firstName}</h4>
                                    <span>{Details.mobileNumber}</span>
                                </div>
                            </a>
                        </div>

                        <div class="card-body">
                            <h5 class="card-title d-flex justify-content-between store_edit">
                                <span>Personal Information</span>
                                <a
                                    href="#"
                                    class="btn btn-tbl-edit btn-xs td_btn"
                                >
                                    <span class="material-icons-outlined">
                                        edit
                                    </span>
                                </a>
                            </h5>
                            <ul class="list-unstyled mb-0 store_list">
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">Name : </small>
                                    <span>
                                        {Details.firstName +
                                            ' ' +
                                            Details.lastName}
                                    </span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">
                                        MobileNumber :
                                    </small>
                                    <span>{Details.mobileNumber}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">Gender : </small>
                                    <span>{Details.gender}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">DOB : </small>
                                    <span>{Details.dateOfBirth}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">Email ID: </small>
                                    <span>{Details.email}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">
                                        Partner Mode:{' '}
                                    </small>
                                    <span>{Details.partnerMode}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <BankingDetails
                        Details={Details}
                        bankDetails={bankDetails}
                    />
                </div>

                <div class="col-xl-9 col-md-8">
                    <div class="card">
                        <div class="card-body p_7">
                            <form>
                                <div class="row form-group">
                                    <div class="col-md-12">
                                        <div class="card bg-white bn_15">
                                            <div class="card-body card-body_1">
                                                <ul class="nav nav-tabs nav-tabs-bottom nav_add_bt">
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link active"
                                                            href="#bottom-tab1"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Orders
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab2"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Wallet

                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab3"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Documents
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab4"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Farmer List
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab5"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Address Details
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab6"
                                                            data-bs-toggle="tab"
                                                        >
                                                           Edit User
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab7"
                                                            data-bs-toggle="tab"
                                                        >
                                                          Join FPO
                                                        </a>
                                                    </li>
                                                </ul>

                                                <div class="tab-content">
                                                    {/* Orders */}
                                                    <ModuleOrders
                                                        userId={userId}
                                                        idForAssign={
                                                            idForAssign
                                                        }
                                                    />

                                                    {/* Wallet */}
                                                    <Wallet />

                                                    <div
                                                        class="tab-pane"
                                                        id="bottom-tab3"
                                                    >
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="card-body card-body_1">
                                                                    <div className="row">
                                                                        <div class="col-md-12 text-end justify-space-between mb-4">
                                                                            {!addDoc ? (
                                                                                <button
                                                                                    type="submit"
                                                                                    className="btn btn-primary"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.preventDefault()
                                                                                        setAddDoc(
                                                                                            true
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    +
                                                                                    Add
                                                                                    Document
                                                                                </button>
                                                                            ) : (
                                                                                <button
                                                                                    type="submit"
                                                                                    className="btn btn-primary"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.preventDefault()
                                                                                        setAddDoc(
                                                                                            false
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    Document
                                                                                    List
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    {!addDoc ? (
                                                                        <>
                                                                            <div class="table-wrap">
                                                                                <div class="table-responsive">
                                                                                    <table class="table display setting-td mb-30">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>
                                                                                                    TITLE
                                                                                                </th>
                                                                                                <th>
                                                                                                    PHOTO
                                                                                                </th>
                                                                                                <th>
                                                                                                    CREATED
                                                                                                </th>
                                                                                                <th>
                                                                                                    ACTIONS
                                                                                                </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    TRADE
                                                                                                    LIC
                                                                                                </td>
                                                                                                <td>
                                                                                                    -
                                                                                                </td>
                                                                                                <td>
                                                                                                    Sep
                                                                                                    21,
                                                                                                    11:19
                                                                                                    AM
                                                                                                </td>
                                                                                                <td>
                                                                                                    <a
                                                                                                        href="#"
                                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                    >
                                                                                                        <span class="material-icons-outlined">
                                                                                                            edit
                                                                                                        </span>
                                                                                                    </a>
                                                                                                    <a
                                                                                                        href="#"
                                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                    >
                                                                                                        <span class="material-icons-outlined">
                                                                                                            delete
                                                                                                        </span>
                                                                                                    </a>
                                                                                                </td>
                                                                                            </tr>

                                                                                            <tr>
                                                                                                <td>
                                                                                                    TRADE
                                                                                                    LIC
                                                                                                </td>
                                                                                                <td>
                                                                                                    -
                                                                                                </td>
                                                                                                <td>
                                                                                                    Sep
                                                                                                    21,
                                                                                                    11:19
                                                                                                    AM
                                                                                                </td>
                                                                                                <td>
                                                                                                    <a
                                                                                                        href="#"
                                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                    >
                                                                                                        <span class="material-icons-outlined">
                                                                                                            edit
                                                                                                        </span>
                                                                                                    </a>
                                                                                                    <a
                                                                                                        href="#"
                                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                    >
                                                                                                        <span class="material-icons-outlined">
                                                                                                            delete
                                                                                                        </span>
                                                                                                    </a>
                                                                                                </td>
                                                                                            </tr>

                                                                                            <tr>
                                                                                                <td>
                                                                                                    TRADE
                                                                                                    LIC
                                                                                                </td>
                                                                                                <td>
                                                                                                    -
                                                                                                </td>
                                                                                                <td>
                                                                                                    Sep
                                                                                                    21,
                                                                                                    11:19
                                                                                                    AM
                                                                                                </td>
                                                                                                <td>
                                                                                                    <a
                                                                                                        href="#"
                                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                    >
                                                                                                        <span class="material-icons-outlined">
                                                                                                            edit
                                                                                                        </span>
                                                                                                    </a>
                                                                                                    <a
                                                                                                        href="#"
                                                                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                    >
                                                                                                        <span class="material-icons-outlined">
                                                                                                            delete
                                                                                                        </span>
                                                                                                    </a>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <form
                                                                                class="tab-pane show active"
                                                                                id="bottom-justified-tab4"
                                                                                onSubmit={(
                                                                                    e
                                                                                ) =>
                                                                                    console.log(
                                                                                        5
                                                                                    )
                                                                                }
                                                                            >
                                                                                <div className="row">
                                                                                    <h4>
                                                                                        Add
                                                                                        New
                                                                                        Document
                                                                                    </h4>
                                                                                    <div className="col-md-3 mb-2">
                                                                                        <InputLabel
                                                                                            id="demo-simple-select-standard-label"
                                                                                            style={{
                                                                                                color: 'black',
                                                                                            }}
                                                                                        >
                                                                                            Document
                                                                                            Upload
                                                                                        </InputLabel>
                                                                                        <div
                                                                                            class="form-group row widget-3"
                                                                                            style={{
                                                                                                width: '200px',
                                                                                                margin: 'auto',
                                                                                                display:
                                                                                                    'flex',
                                                                                                justifyContent:
                                                                                                    'center',
                                                                                            }}
                                                                                        >
                                                                                            <section
                                                                                                className="container"
                                                                                                style={
                                                                                                    baseStyle
                                                                                                }
                                                                                            >
                                                                                                <div
                                                                                                    {...getRootProps(
                                                                                                        {
                                                                                                            className:
                                                                                                                'dropzone',
                                                                                                        }
                                                                                                    )}
                                                                                                    style={{
                                                                                                        borderRadius:
                                                                                                            '5px',
                                                                                                        display:
                                                                                                            'flex',
                                                                                                        justifyContent:
                                                                                                            'center',
                                                                                                        alignItems:
                                                                                                            'center',
                                                                                                        height: '100px',
                                                                                                    }}
                                                                                                >
                                                                                                    <input
                                                                                                        {...getInputProps()}
                                                                                                        multiple={
                                                                                                            false
                                                                                                        }
                                                                                                    />
                                                                                                    {Files && (
                                                                                                        <>
                                                                                                            {Files.length ===
                                                                                                                0 && (
                                                                                                                <p align="center">
                                                                                                                    Drop
                                                                                                                    Image
                                                                                                                    here
                                                                                                                    or
                                                                                                                    click
                                                                                                                    to
                                                                                                                    upload.
                                                                                                                </p>
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                    {Files && (
                                                                                                        <>
                                                                                                            {Files.length >
                                                                                                                0 && (
                                                                                                                <aside
                                                                                                                    style={
                                                                                                                        thumbsContainerState
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        PGthumbs
                                                                                                                    }
                                                                                                                </aside>
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            </section>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div class="col-md-3">
                                                                                        <FormControl
                                                                                            variant="standard"
                                                                                            fullWidth
                                                                                        >
                                                                                            <InputLabel
                                                                                                id="demo-simple-select-standard-label"
                                                                                                style={{
                                                                                                    color: 'black',
                                                                                                }}
                                                                                            >
                                                                                                Document
                                                                                                Type
                                                                                            </InputLabel>
                                                                                            <Select
                                                                                                labelId="demo-simple-select-standard-label"
                                                                                                id="soilType"
                                                                                                color="success"
                                                                                                name="soilType"
                                                                                                //  value={farmer.selectUnit}
                                                                                                //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                                                                            >
                                                                                                <MenuItem value="">
                                                                                                    <em>
                                                                                                        -
                                                                                                        Select
                                                                                                        Unit
                                                                                                        -
                                                                                                    </em>
                                                                                                </MenuItem>
                                                                                                <MenuItem value="Hindi">
                                                                                                    Aadhar
                                                                                                </MenuItem>
                                                                                                <MenuItem value="Bengali">
                                                                                                    PAN
                                                                                                </MenuItem>
                                                                                            </Select>
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div class="col-md-3">
                                                                                        <CssTextField
                                                                                            fullWidth
                                                                                            id="fEducation"
                                                                                            label="Document Number"
                                                                                            variant="standard"
                                                                                            style={{
                                                                                                marginBottom:
                                                                                                    '1.25rem',
                                                                                            }}
                                                                                            name="education"
                                                                                            //  value={farmer.education}
                                                                                            //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-12 text-end mt-2 mb-2">
                                                                                    <button
                                                                                        type="submit"
                                                                                        class=" w-10 btn btn-primary"
                                                                                    >
                                                                                        Submit
                                                                                    </button>
                                                                                </div>
                                                                            </form>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* List */}
                                                    <div
                                                        class="tab-pane"
                                                        id="bottom-tab4"
                                                    >
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="card-body card-body_1">
                                                                    <div className="row">
                                                                        <div class="col-md-6 text-end justify-space-between">
                                                                            <input
                                                                                type="number"
                                                                                class="form-control"
                                                                                placeholder="Search Number"
                                                                            />
                                                                        </div>
                                                                        <div class="col-md-6 text-end justify-space-between mb-4">
                                                                            <button
                                                                                className="btn btn-primary"
                                                                                style={{
                                                                                    margin: '10px',
                                                                                }}
                                                                                onClick={
                                                                                    handleCB
                                                                                }
                                                                            >
                                                                                Create
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-primary"
                                                                                style={{
                                                                                    margin: '10px',
                                                                                }}
                                                                                onClick={
                                                                                    handleCB
                                                                                }
                                                                            >
                                                                                Booking
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="tab-pane show active"
                                                                        id="bottom-tab1"
                                                                    >
                                                                        <div class="row">
                                                                            <div class="col-lg-12">
                                                                                <ul class="nav nav-tabs nav-tabs-bottom nav_add_bt">
                                                                                    <li
                                                                                        class="nav-item"
                                                                                        style={{
                                                                                            width: '16.66%',
                                                                                        }}
                                                                                    >
                                                                                        <a
                                                                                            class="nav-link active"
                                                                                            href="#bottom-tab1-sub1"
                                                                                            onClick={() =>
                                                                                                handleListComp(
                                                                                                    1
                                                                                                )
                                                                                            }
                                                                                            data-bs-toggle="tab"
                                                                                        >
                                                                                            Farmer
                                                                                        </a>
                                                                                    </li>
                                                                                    <li
                                                                                        class="nav-item"
                                                                                        style={{
                                                                                            width: '16.66%',
                                                                                        }}
                                                                                    >
                                                                                        <a
                                                                                            class="nav-link"
                                                                                            href="#bottom-tab1-sub2"
                                                                                            onClick={() =>
                                                                                                handleListComp(
                                                                                                    2
                                                                                                )
                                                                                            }
                                                                                            data-bs-toggle="tab"
                                                                                        >
                                                                                            Livestock
                                                                                        </a>
                                                                                    </li>
                                                                                    <li
                                                                                        class="nav-item"
                                                                                        style={{
                                                                                            width: '16.66%',
                                                                                        }}
                                                                                    >
                                                                                        <a
                                                                                            class="nav-link"
                                                                                            href="#bottom-tab1-sub1"
                                                                                            onClick={() =>
                                                                                                handleListComp(
                                                                                                    3
                                                                                                )
                                                                                            }
                                                                                            data-bs-toggle="tab"
                                                                                        >
                                                                                            Input
                                                                                            Supplier
                                                                                        </a>
                                                                                    </li>
                                                                                    <li
                                                                                        class="nav-item"
                                                                                        style={{
                                                                                            width: '16.66%',
                                                                                        }}
                                                                                    >
                                                                                        <a
                                                                                            class="nav-link"
                                                                                            href="#bottom-tab1-sub3"
                                                                                            onClick={() =>
                                                                                                handleListComp(
                                                                                                    4
                                                                                                )
                                                                                            }
                                                                                            data-bs-toggle="tab"
                                                                                        >
                                                                                            Agri
                                                                                            Machine
                                                                                        </a>
                                                                                    </li>
                                                                                    <li
                                                                                        class="nav-item"
                                                                                        style={{
                                                                                            width: '16.66%',
                                                                                        }}
                                                                                    >
                                                                                        <a
                                                                                            class="nav-link"
                                                                                            href="#bottom-tab1-sub4"
                                                                                            onClick={() =>
                                                                                                handleListComp(
                                                                                                    5
                                                                                                )
                                                                                            }
                                                                                            data-bs-toggle="tab"
                                                                                        >
                                                                                            Agri
                                                                                            Transport
                                                                                        </a>
                                                                                    </li>
                                                                                    <li
                                                                                        class="nav-item"
                                                                                        style={{
                                                                                            width: '16.66%',
                                                                                        }}
                                                                                    >
                                                                                        <a
                                                                                            class="nav-link"
                                                                                            href="#bottom-tab1-sub5"
                                                                                            onClick={() =>
                                                                                                handleListComp(
                                                                                                    6
                                                                                                )
                                                                                            }
                                                                                            data-bs-toggle="tab"
                                                                                        >
                                                                                            Traders
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            <div>
                                                                                <div class="tab-content">
                                                                                    <div
                                                                                        class="tab-pane show active"
                                                                                        id="bottom-tab1-sub1"
                                                                                    >
                                                                                        <div class="row">
                                                                                            <div class="col-lg-12">
                                                                                                <div class="card-body card-body_1 card-body_2">
                                                                                                    <div class="table-wrap">
                                                                                                        <div class="table-responsive">
                                                                                                            <table class="table display setting-td mb-30 store_table">
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th>
                                                                                                                            ID
                                                                                                                        </th>
                                                                                                                        <th>
                                                                                                                            Mobile
                                                                                                                            Number
                                                                                                                        </th>
                                                                                                                        <th>
                                                                                                                            Name
                                                                                                                        </th>
                                                                                                                        <th>
                                                                                                                            Created
                                                                                                                        </th>
                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {Object.keys(
                                                                                                                        listCompData
                                                                                                                    )
                                                                                                                        .length !==
                                                                                                                        0 &&
                                                                                                                        listCompData.map(
                                                                                                                            (
                                                                                                                                listDetail
                                                                                                                            ) => (
                                                                                                                                <tr>
                                                                                                                                    <td>
                                                                                                                                        {
                                                                                                                                            listDetail?.id
                                                                                                                                        }
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        {
                                                                                                                                            listDetail?.mobile_number
                                                                                                                                        }
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        {
                                                                                                                                            listDetail.name
                                                                                                                                        }
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        {new Date(
                                                                                                                                            listDetail.created
                                                                                                                                        )
                                                                                                                                            .toDateString()
                                                                                                                                            .slice(
                                                                                                                                                4,
                                                                                                                                                15
                                                                                                                                            )}
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            )
                                                                                                                        )}
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {!addDoc ? (
                                                                        <div class="row">
                                                                            <div class="col-sm-12">
                                                                                <div class="card card-table">
                                                                                    <div class="card-body">
                                                                                        <div class="table-responsive">
                                                                                            <div
                                                                                                id="DataTables_Table_0_wrapper"
                                                                                                class="dataTables_wrapper dt-bootstrap4 no-footer"
                                                                                            ></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        //       <>
                                                                        //       <div class="table-wrap">
                                                                        //       <div class="table-responsive">
                                                                        //          <table class="table display setting-td mb-30" >
                                                                        //             <thead>
                                                                        //                <tr>
                                                                        //                   <th>NAME</th>
                                                                        //                   <th>PHOTO</th>
                                                                        //                   <th>CREATED</th>
                                                                        //                   <th>ACTIONS</th>
                                                                        //                </tr>
                                                                        //             </thead>
                                                                        //             <tbody>
                                                                        //                <tr>
                                                                        //                   <td>TRADE LIC</td>
                                                                        //                   <td>-</td>
                                                                        //                   <td>Sep 21, 11:19 AM</td>
                                                                        //                   <td>
                                                                        //                      <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                                                                        //                         <span class="material-icons-outlined">edit</span>
                                                                        //                      </a>
                                                                        //                      <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                                                                        //                         <span class="material-icons-outlined">delete</span>
                                                                        //                      </a>
                                                                        //                   </td>
                                                                        //                </tr>

                                                                        //                <tr>
                                                                        //                   <td>TRADE LIC</td>
                                                                        //                   <td>-</td>
                                                                        //                   <td>Sep 21, 11:19 AM</td>
                                                                        //                   <td>
                                                                        //                      <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                                                                        //                         <span class="material-icons-outlined">edit</span>
                                                                        //                      </a>
                                                                        //                      <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                                                                        //                         <span class="material-icons-outlined">delete</span>
                                                                        //                      </a>
                                                                        //                   </td>
                                                                        //                </tr>

                                                                        //                <tr>
                                                                        //                   <td>TRADE LIC</td>
                                                                        //                   <td>-</td>
                                                                        //                   <td>Sep 21, 11:19 AM</td>
                                                                        //                   <td>
                                                                        //                      <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                                                                        //                         <span class="material-icons-outlined">edit</span>
                                                                        //                      </a>
                                                                        //                      <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                                                                        //                         <span class="material-icons-outlined">delete</span>
                                                                        //                      </a>
                                                                        //                   </td>
                                                                        //                </tr>

                                                                        //             </tbody>
                                                                        //          </table>
                                                                        //       </div>
                                                                        //    </div>
                                                                        //   </>
                                                                        <>
                                                                            <AddFarmer
                                                                                val={
                                                                                    'FPO'
                                                                                }
                                                                            />
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Address Details */}
                                                    <div
                                                        class="tab-pane"
                                                        id="bottom-tab5"
                                                    >
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="card-body card-body_1">
                                                                    <div className="row">
                                                                        <div class="col-md-12 text-end justify-space-between mb-4"></div>
                                                                    </div>
                                                                    <div
                                                                        class="card"
                                                                        style={{
                                                                            backgroundColor:
                                                                                '#f3f3f3 ',
                                                                        }}
                                                                    >
                                                                        <div class="card-body">
                                                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                                                <span>
                                                                                    Address
                                                                                </span>
                                                                                <a
                                                                                    href="#"
                                                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                                                >
                                                                                    <span class="material-icons-outlined mr-3">
                                                                                        edit
                                                                                    </span>
                                                                                    <span class="material-icons-outlined">
                                                                                        delete
                                                                                    </span>
                                                                                </a>
                                                                            </h5>
                                                                            <ul class="list-unstyled mb-0 store_list">
                                                                                <div className="row">
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                State{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.state
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                District{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.district
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                Sub
                                                                                                District{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.subDistrict
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                {' '}
                                                                                                Village{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.village
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                {' '}
                                                                                                Pincode{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.pincode
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                {' '}
                                                                                                Address{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.fullAddress
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-12 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <h5 class="card-title d-flex justify-content-between mt-4">
                                                                                                <span>
                                                                                                    Location
                                                                                                </span>
                                                                                                <a
                                                                                                    href="#"
                                                                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                >
                                                                                                    <span class="material-icons-outlined">
                                                                                                        edit
                                                                                                    </span>
                                                                                                </a>
                                                                                            </h5>
                                                                                        </li>
                                                                                        <li>
                                                                                            <Map2
                                                                                                center={{
                                                                                                    lat: +Details.lat,
                                                                                                    lng: +Details.long,
                                                                                                }}
                                                                                                height="300px"
                                                                                                zoom={
                                                                                                    15
                                                                                                }
                                                                                            />
                                                                                            {/* <a class="store-avatar" style={mapThumb}>
                                                                        <div class="store_display">
                                                                           <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15318.518229107614!2d80.4303255!3d16.2907457!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3d93323686c7ea15!2sSRI%20HANUMAN%20TRADERS!5e0!3m2!1sen!2sin!4v1636515519444!5m2!1sen!2sin" width="100%" height="250" allowfullscreen="" loading="lazy"></iframe>
                                                                        </div>
                                                                     </a> */}
                                                                                        </li>
                                                                                    </div>
                                                                                </div>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="tab-pane"
                                                        id="bottom-tab6"
                                                    >
                                                        <EditMicroEnterpreneur/>
                                                    </div>
                                                    <div
                                                        class="tab-pane"
                                                        id="bottom-tab7"
                                                    >
<div class="col-md-4">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-2"
                                                            >
                                                                <InputLabel
                                                                    id="fpolist"
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                >
                                                                    FPO
                                                                </InputLabel>
                                                                FPO:{' '}
                                                                <AsyncTypeahead
                                                                    labelId="fpolist"
                                                                    id="fpolist"
                                                                    options={
                                                                        fpoList
                                                                    }
                                                                    labelKey="label"
                                                                    onSearch={
                                                                        handleSearch
                                                                    }
                                                                    style={{
                                                                        marginRight:
                                                                            '20px',
                                                                    }}
                                                                    selected={
                                                                        fpo
                                                                    }
                                                                    onChange={(
                                                                        selected
                                                                    ) =>
                                                                        handleSelectChange(
                                                                            selected
                                                                        )
                                                                    }
                                                                />{' '}
                                                                <div style={{padding:"25px"}}>
                                                                      <button style={{width:"250px"}}
                                                                                    type="submit"
                                                                                    className="btn btn-primary"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.preventDefault()
                                                                                        joinFpo()
                                                                                    }}
                                                                                >
                                                                                    +
                                                                                   Join FPO
                                                                                </button>
                                                                </div>
                                                               
                                                            </FormControl>
                                                        </div>                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MicroEntrep
