import React, { useState,useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';

function CancelAssignDialog(props) {
  const { assignId, bookingId } = props;
const [cancelopen,setCancelOpen]=useState(props.open)


  const handleAssign = () => {
    const token = localStorage.getItem('token')
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    }
    axios.post('https://prod-api.krishione.com/farmer-function/cancel-assign', {  bookingId,type:props.type },config)
      .then(res => {
        alert("Assign Removed!")
        console.log(res.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (<>
      <DialogTitle id="form-dialog-title">Cancel Assign</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to cancel assign to this booking {bookingId}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* <Button  color="primary">
          No
        </Button> */}
        <Button onClick={handleAssign} color="primary">
          Yes
        </Button>
      </DialogActions>
      </>
  );
}

export default CancelAssignDialog;
