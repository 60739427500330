import React from 'react'
import Switch from '@mui/material/Switch'

function TaskNotifications() {
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Task Notifications</h5>
                </div>
                <div class="card-body card-body_1">
                    <form>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <div class="card bg-white">
                                    <div class="card-body card-body_1">
                                        <ul class="nav nav-tabs nav-tabs-bottom nav_add_bt">
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link active"
                                                    href="#bottom-tab1"
                                                    data-bs-toggle="tab"
                                                >
                                                    Customer
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#bottom-tab2"
                                                    data-bs-toggle="tab"
                                                >
                                                    Driver
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#bottom-tab3"
                                                    data-bs-toggle="tab"
                                                >
                                                    Admin
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="tab-content">
                                            {/* Customer */}
                                            <div
                                                class="tab-pane show active"
                                                id="bottom-tab1"
                                            >
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="card-body card-body_1">
                                                            <div class="table-wrap">
                                                                <div class="table-responsive">
                                                                    <table class="table display setting-td mb-30">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    Event
                                                                                </th>
                                                                                <th>
                                                                                    SMS
                                                                                </th>
                                                                                <th>
                                                                                    EMAIL
                                                                                </th>
                                                                                <th>
                                                                                    PUSH
                                                                                </th>
                                                                                <th>
                                                                                    ACTIONS
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    Task
                                                                                    Accepted
                                                                                </td>
                                                                                <td>
                                                                                    <Switch color="success" />
                                                                                </td>
                                                                                <td>
                                                                                    <Switch color="success" />
                                                                                </td>
                                                                                <td>
                                                                                    -
                                                                                </td>
                                                                                <td>
                                                                                    <span class="material-icons-outlined">
                                                                                        edit
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Task
                                                                                    Reached
                                                                                </td>
                                                                                <td>
                                                                                    -
                                                                                </td>
                                                                                <td>
                                                                                    -
                                                                                </td>
                                                                                <td>
                                                                                    -
                                                                                </td>
                                                                                <td>
                                                                                    <span class="material-icons-outlined">
                                                                                        edit
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Task
                                                                                    Picked
                                                                                </td>
                                                                                <td>
                                                                                    -
                                                                                </td>
                                                                                <td>
                                                                                    -
                                                                                </td>
                                                                                <td>
                                                                                    -
                                                                                </td>
                                                                                <td>
                                                                                    <span class="material-icons-outlined">
                                                                                        edit
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Task
                                                                                    Arrived
                                                                                </td>
                                                                                <td>
                                                                                    -
                                                                                </td>
                                                                                <td>
                                                                                    -
                                                                                </td>
                                                                                <td>
                                                                                    -
                                                                                </td>
                                                                                <td>
                                                                                    <span class="material-icons-outlined">
                                                                                        edit
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Driver */}
                                            <div
                                                class="tab-pane"
                                                id="bottom-tab2"
                                            >
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="card-body card-body_1">
                                                            <div class="table-wrap">
                                                                <div class="table-responsive">
                                                                    <table class="table display setting-td mb-30">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    Event
                                                                                </th>
                                                                                <th>
                                                                                    SMS
                                                                                </th>
                                                                                <th>
                                                                                    EMAIL
                                                                                </th>
                                                                                <th>
                                                                                    PUSH
                                                                                </th>
                                                                                <th>
                                                                                    ACTIONS
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    Task
                                                                                    Assigned
                                                                                </td>
                                                                                <td>
                                                                                    -
                                                                                </td>
                                                                                <td>
                                                                                    -
                                                                                </td>
                                                                                <td>
                                                                                    -
                                                                                </td>
                                                                                <td>
                                                                                    <span class="material-icons-outlined">
                                                                                        edit
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Task
                                                                                    Cancelled
                                                                                </td>
                                                                                <td>
                                                                                    -
                                                                                </td>
                                                                                <td>
                                                                                    -
                                                                                </td>
                                                                                <td>
                                                                                    -
                                                                                </td>
                                                                                <td>
                                                                                    <span class="material-icons-outlined">
                                                                                        edit
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Admin  */}
                                            <div
                                                class="tab-pane"
                                                id="bottom-tab3"
                                            >
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="card-body card-body_1">
                                                            <div class="table-wrap">
                                                                <div class="table-responsive">
                                                                    <table class="table display setting-td mb-30">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    EVENT
                                                                                </th>
                                                                                <th>
                                                                                    EMAIL
                                                                                </th>
                                                                                <th>
                                                                                    ACTIONS
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    Task
                                                                                    Rejected
                                                                                </td>
                                                                                <td>
                                                                                    -
                                                                                </td>
                                                                                <td>
                                                                                    <span class="material-icons-outlined">
                                                                                        edit
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    Task
                                                                                    Failed
                                                                                </td>
                                                                                <td>
                                                                                    -
                                                                                </td>
                                                                                <td>
                                                                                    <span class="material-icons-outlined">
                                                                                        edit
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default TaskNotifications
