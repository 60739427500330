import React, { useState, useEffect } from 'react'
import { CssTextField } from '../../Styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Selects from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Input from '@mui/material/Input'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import { API } from '../../../API/index'
import Swal from 'sweetalert2'
import $ from 'jquery'
import Select from 'react-select'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const names = ['Wheat', 'Rice', 'Barley', 'Cereal', 'Tomato', 'Oat']
const farmerClear = {
    firstName: '',
    lastName: '',
    gender: '',
    mobileNumber: '',
    category: '',
    dateOfBirth: '',
    AadharNo: '',
    language: '',

    state: '',
    district: '',
    subDistrict: '',
    village: '',
    pincode: '',
    fullAddress: '',

    selectUnit: '',
    totalLand: '',
    leasedInLand: '',
    leasedOutLand: '',
    soilTest: false,

    typeOfCrop: [],
    irrigationSource: '',
    farmEquipments: '',

    bankAccountNumber: '',
    bankAccountName: '',
    ifscCode: '',
    bankName: '',
    bankBranchName: '',
}

function Farmers(props) {
    const [booking, setBooking] = useState(props.booking)
    const [tab, setTab] = useState(1)
    const [create, setCreate] = useState(props.create)
    const [cropTypes, setCropTypes] = React.useState([])
    const [statelist, setstatelist] = useState([])
    const [citylist, setcitylist] = useState([])
    const [CropProduct, setCropProduct] = useState([])
    const [farmer, setFarmer] = useState({
        firstName: '',
        lastName: '',
        gender: '',
        mobileNumber: '',
        category: '',
        dob: '',
        AadharNo: '',
        language: '',

        state: '',
        district: '',
        subDistrict: '',
        village: '',
        pincode: '',
        fullAddress: '',

        typeOfCrop: cropTypes,
        irrigationSource: '',
        farmEquipments: '',
        selectUnit: '',
        totalLand: '',
        soilType: '',
        sowingDate: '',
        soilTest: false,

        bankAccountNumber: '',
        bankAccountName: '',
        ifscCode: '',
        bankName: '',
        bankBranchName: '',
    })

    useEffect(() => {
        // getFarmer();
        getStateList()
        getCropProduct()
    }, [])

    const onSubmitFarmer = async (e) => {
        console.log('Farmer', farmer)
        const { data } = await API.post('/farmerRegister', farmer)
        if (data?.success) {
            Swal.fire({
                icon: 'success',
                iconColor: '#28a745',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
            console.log(data)
            setFarmer(farmerClear)
            setCropTypes([])
            // props.getFarmers();
        } else {
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    const getStateList = async () => {
        const { data } = await API.get(`/getStateList`)
        // console.log("hkjjhj",data)
        setstatelist(data?.result)
        console.log('firm search', data)
    }
    const getCropProduct = async () => {
        const { data } = await API.get(
            `/getProductCatelogProduct?type=0&title=ProductCatelog&catelogName=CropCatelog`
        )
        // console.log("hkjjhj",data)
        setCropProduct(data?.result)
        console.log('firm search', data)
    }
    const CropProductoption =
        CropProduct &&
        CropProduct.map((item, ind) => ({
            value: item.productName,
            label: item.productName,
        }))
    const statelistoption =
        statelist &&
        statelist.map((item, ind) => ({
            value: item,
            label: item,
        }))
    const handleChange = (event) => {
        const {
            target: { value },
        } = event
        setCropTypes(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    const gender = [
        {
            value: 'Male',
            label: 'Male',
        },
        {
            value: 'Female',
            label: 'Female',
        },
        {
            value: 'Others',
            label: 'Others',
        },
    ]

    const LanguageVale = [
        {
            value: 'Hindi',
            label: 'Hindi',
        },
        {
            value: 'Bengali',
            label: 'Bengali',
        },
        {
            value: 'Urdu',
            label: 'Urdu',
        },
        {
            value: 'Punjabi',
            label: 'Punjabi',
        },
        {
            value: 'Marathi',
            label: 'Marathi',
        },
        {
            value: 'Telugu',
            label: 'Telugu',
        },
        {
            value: 'Tamil',
            label: 'Tamil',
        },
    ]

    const IrrigationSource = [
        {
            value: 'Canal',
            label: 'Canal',
        },
        {
            value: 'River',
            label: 'River',
        },
        {
            value: 'Bore',
            label: 'Bore',
        },
        {
            value: 'Well',
            label: 'Well',
        },
    ]

    const Unitvalue = [
        {
            value: 'HECTARE',
            label: 'HECTARE',
        },
        {
            value: 'ACRE',
            label: 'ACRE',
        },
        {
            value: 'CENT',
            label: 'CENT',
        },
        {
            value: 'KATHA',
            label: 'KATHA',
        },
        {
            value: 'BIGHA',
            label: 'BIGHA',
        },
        {
            value: 'GUNTA',
            label: 'GUNTA',
        },
    ]
    const SoilType = [
        {
            value: 'Alluvial soil',
            label: 'Alluvial soil',
        },
        {
            value: 'Black Soil',
            label: 'Black Soil',
        },
        {
            value: 'Desert Soil',
            label: 'Desert Soil',
        },
        {
            value: 'Laterita Soil',
            label: 'Laterita Soil',
        },
        {
            value: 'Mountain Soil',
            label: 'Mountain Soil',
        },
        {
            value: 'Red Soil',
            label: 'Red Soil',
        },
    ]
    const farmEquipmentsValue = [
        {
            value: 'TRACTOR',
            label: 'TRACTOR',
        },
    ]
    const SoilTest = [
        {
            value: true,
            label: 'YES',
        },
        {
            value: false,
            label: 'NO',
        },
    ]

    const handleMobVal = (e) => {
        setFarmer({ ...farmer, [e.target.name]: e.target.value })
        if (e.target.value.length == 10) {
            mobilecheck(e.target.value)
        } else if (e.target.value.length > 10) {
            console.log('e.target.value.length', e.target.value.length)
            setFarmer({ ...farmer, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: 'Mobile length should be 10',
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const mobilecheck = async (number) => {
        const { data } = await API.get(`/checkNumber?number=` + number)
        console.log('data', data)
        if (data.success == true) {
            // setFarmer({ ...farmer, [e.target.name]: number })
        } else {
            setFarmer({ ...farmer, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data.result,
                showConfirmButton: false,
                timer: 2000,
            })
        }
        // setDetails(data?.result);
    }
    $(function () {
        $('#txtNumeric').keydown(function (e) {
            if (e.shiftKey || e.ctrlKey || e.altKey) {
                e.preventDefault()
            } else {
                var key = e.keyCode

                if (
                    !(
                        key == 8 ||
                        key == 32 ||
                        key == 46 ||
                        (key >= 35 && key <= 40) ||
                        (key >= 65 && key <= 90)
                    )
                ) {
                    e.preventDefault()
                }
            }
        })
    })
    const statechane = (e) => {
        console.log('hkjjhj', e.value)
        setFarmer({ ...farmer, ['state']: e.value })
        getCityList(e.value)
    }
    const getCityList = async (val) => {
        const { data } = await API.get(`/getCityList?stateName=` + val)
        // console.log("hkjjhj",data)
        setcitylist(data?.result)
        console.log('firm search', data)
    }
    const citylistoption =
        citylist &&
        citylist.map((item, ind) => ({
            value: item,
            label: item,
        }))
    const aadharvalidation = (e) => {
        setFarmer({ ...farmer, [e.target.name]: e.target.value })
        var aadhar = e.target.value
        // var adharcardTwelveDigit = /^\d{12}$/;
        // var adharSixteenDigit = /^\d{16}$/;
        // if (aadhar != '') {
        //     if (aadhar.match(adharcardTwelveDigit)) {
        //         return true;
        //     }
        //     else if (aadhar.match(adharSixteenDigit)) {
        //         return true;
        //     }
        //     else {
        //         alert("Enter valid Aadhar Number");
        //         return false;
        //     }
        // }
        // setFarmer({ ...farmer, [e.target.name]: e.target.value })
    }
    return (
        <>
            {booking === false && create === '1' && (
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card card-table p-10-25">
                            <div class="card-header">
                                <h5 class="card-title">Create Farmer</h5>
                            </div>
                            <div class="card-body">
                                <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                                    <li class="nav-item">
                                        <a
                                            class={
                                                tab === 1
                                                    ? 'nav-link active'
                                                    : 'nav-link'
                                            }
                                        >
                                            Personal Info
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            class={
                                                tab === 2
                                                    ? 'nav-link active'
                                                    : 'nav-link'
                                            }
                                        >
                                            Contact Details
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            class={
                                                tab === 4
                                                    ? 'nav-link active'
                                                    : 'nav-link'
                                            }
                                        >
                                            Farm Info
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            class={
                                                tab === 5
                                                    ? 'nav-link active'
                                                    : 'nav-link'
                                            }
                                        >
                                            Bank Account Info
                                        </a>
                                    </li>
                                </ul>

                                <div class="tab-content">
                                    {
                                        tab === 1 && (
                                            // <form class="tab-pane show active" id="bottom-justified-tab1" onSubmit={e => setTab(2)}>
                                            <div action="#">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <CssTextField
                                                            required
                                                            fullWidth
                                                            id="fName"
                                                            name="firstName"
                                                            label="First Name"
                                                            variant="standard"
                                                            className="mt-3"
                                                            value={
                                                                farmer.firstName
                                                            }
                                                            onChange={(e) =>
                                                                setFarmer({
                                                                    ...farmer,
                                                                    [e.target
                                                                        .name]:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <CssTextField
                                                            fullWidth
                                                            required
                                                            id="lName"
                                                            name="lastName"
                                                            label="Last Name"
                                                            variant="standard"
                                                            className="mt-3"
                                                            value={
                                                                farmer.lastName
                                                            }
                                                            onChange={(e) =>
                                                                setFarmer({
                                                                    ...farmer,
                                                                    [e.target
                                                                        .name]:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                        />

                                                        {/* </div> */}
                                                    </div>
                                                    <div class="col-md-4">
                                                        <CssTextField
                                                            fullWidth
                                                            required
                                                            id="farmerMobile"
                                                            name="mobileNumber"
                                                            label="Mobile Number"
                                                            variant="standard"
                                                            className="mt-3"
                                                            style={{
                                                                marginBottom:
                                                                    '1.25rem',
                                                            }}
                                                            value={
                                                                farmer.mobileNumber
                                                            }
                                                            onChange={(e) =>
                                                                handleMobVal(e)
                                                            }

                                                            // onChange={(e) => setFarmer({ ...farmer, [e.target.name]: e.target.value })}
                                                        />
                                                    </div>
                                                    <div
                                                        class="col-md-4"
                                                        style={{
                                                            marginTop: '2%',
                                                        }}
                                                    >
                                                        <FormControl
                                                            variant="standard"
                                                            fullWidth
                                                            className="mt-3"
                                                        >
                                                            {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Gender*</InputLabel>
                              <Select
                                required
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                label="Gender"
                                color="success"
                                name="gender"
                                value={farmer.gender}
                                onChange={(e) => setFarmer({ ...farmer, [e.target.name]: e.target.value })}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>
                                <MenuItem value="Others">Others</MenuItem>
                              </Select> */}
                                                            <Select
                                                                style={{
                                                                    width: '270px',
                                                                    height: '40px',
                                                                }}
                                                                onChange={(e) =>
                                                                    setFarmer({
                                                                        ...farmer,
                                                                        ['gender']:
                                                                            e.value,
                                                                    })
                                                                }
                                                                options={gender}
                                                                placeholder={
                                                                    'Select Gender'
                                                                }
                                                                //  value={inputBooking.firm}
                                                            />
                                                        </FormControl>
                                                    </div>

                                                    <div class="col-md-4">
                                                        <InputLabel
                                                            id="demo-simple-select-standard-label"
                                                            style={{
                                                                color: 'black',
                                                            }}
                                                        >
                                                            DOB
                                                        </InputLabel>
                                                        <CssTextField
                                                            required
                                                            type="date"
                                                            fullWidth
                                                            variant="standard"
                                                            style={{
                                                                marginTop:
                                                                    '10px',
                                                                marginBottom:
                                                                    '10px',
                                                            }}
                                                            name="dateOfBirth"
                                                            value={
                                                                farmer.dateOfBirth
                                                            }
                                                            onChange={(e) =>
                                                                setFarmer({
                                                                    ...farmer,
                                                                    [e.target
                                                                        .name]:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <CssTextField
                                                                required
                                                                fullWidth
                                                                id="AadharNo"
                                                                label="AadharNo"
                                                                variant="standard"
                                                                className="mt-3"
                                                                style={{
                                                                    marginBottom:
                                                                        '1.25rem',
                                                                }}
                                                                name="AadharNo"
                                                                value={
                                                                    farmer.education
                                                                }
                                                                onChange={(e) =>
                                                                    aadharvalidation(
                                                                        e
                                                                    )
                                                                }
                                                                // onChange={(e) => setFarmer({ ...farmer, [e.target.name]: e.target.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <FormControl
                                                            variant="standard"
                                                            fullWidth
                                                            className="mt-3"
                                                        >
                                                            {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Language*</InputLabel>
                              <Select
                                required
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                label="Language"
                                color="success"
                                name="language"
                                value={farmer.language}
                                onChange={(e) => setFarmer({ ...farmer, [e.target.name]: e.target.value })}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                <MenuItem value="0">Select Language</MenuItem>
                                <MenuItem value="Hindi">Hindi</MenuItem>
                                <MenuItem value="Bengali">Bengali</MenuItem>
                                <MenuItem value="Urdu">Urdu</MenuItem>
                                <MenuItem value="Punjabi">Punjabi</MenuItem>
                                <MenuItem value="Marathi">Marathi</MenuItem>
                                <MenuItem value="Telugu">Telugu</MenuItem>
                                <MenuItem value="Tamil">Tamil</MenuItem>
                              </Select> */}
                                                            <Select
                                                                style={{
                                                                    width: '270px',
                                                                    height: '40px',
                                                                }}
                                                                onChange={(e) =>
                                                                    setFarmer({
                                                                        ...farmer,
                                                                        ['language']:
                                                                            e.value,
                                                                    })
                                                                }
                                                                options={
                                                                    LanguageVale
                                                                }
                                                                placeholder={
                                                                    'Select language'
                                                                }
                                                                //  value={inputBooking.firm}
                                                            />
                                                        </FormControl>
                                                    </div>

                                                    <div class="col-md-12 text-end mt-2 mb-2">
                                                        <button
                                                            onClick={(e) =>
                                                                setTab(2)
                                                            }
                                                            class="w-16 btn btn-primary"
                                                        >
                                                            Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        // </form>
                                    }

                                    {/* Contact Details */}
                                    {
                                        tab === 2 && (
                                            // <form class="tab-pane show active" id="bottom-justified-tab2" onSubmit={e => setTab(4)}>
                                            <div class="row">
                                                <div
                                                    class="col-md-3"
                                                    style={{ marginTop: '2%' }}
                                                >
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        className="mt-3"
                                                    >
                                                        {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
                              State
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              label="State"
                              color="success"
                              name="state"
                              value={farmer.state}
                              onChange={(e) => setFarmer({ ...farmer, [e.target.name]: e.target.value })}
                            >
                              <MenuItem value="">
                                <em>- Select State -</em>
                              </MenuItem>
                              <MenuItem value="Assam">Assam</MenuItem>
                              <MenuItem value="Bihar">Bihar</MenuItem>
                              <MenuItem value="Chattisgarh">Chattisgarh</MenuItem>
                              <MenuItem value="Delhi">Delhi</MenuItem>
                              <MenuItem value="Kolkata">Kolkata</MenuItem>
                              <MenuItem value="Mumbai">Mumbai</MenuItem>
                              <MenuItem value="TamilNadu">TamilNadu</MenuItem>
                            </Select> */}
                                                        <Select
                                                            style={{
                                                                width: '270px',
                                                                height: '40px',
                                                            }}
                                                            onChange={(e) =>
                                                                statechane(e)
                                                            }
                                                            // onChange={(e) => setFarmer({ ...farmer, ['state']: e.value })}
                                                            options={
                                                                statelistoption
                                                            }
                                                            placeholder={
                                                                'Select State'
                                                            }
                                                            //  value={inputBooking.firm}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div
                                                    class="col-md-3"
                                                    style={{ marginTop: '2%' }}
                                                >
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        className="mt-3"
                                                    >
                                                        <Select
                                                            style={{
                                                                width: '270px',
                                                                height: '40px',
                                                            }}
                                                            onChange={(e) =>
                                                                setFarmer({
                                                                    ...farmer,
                                                                    ['district']:
                                                                        e.value,
                                                                })
                                                            }
                                                            options={
                                                                citylistoption
                                                            }
                                                            placeholder={
                                                                'Select district'
                                                            }
                                                            //  value={inputBooking.firm}
                                                        />
                                                    </FormControl>
                                                </div>

                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        id="subDistrict"
                                                        label="Sub District"
                                                        variant="standard"
                                                        name="subDistrict"
                                                        value={
                                                            farmer.subDistrict
                                                        }
                                                        onChange={(e) =>
                                                            setFarmer({
                                                                ...farmer,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        id="village"
                                                        label="Village"
                                                        variant="standard"
                                                        name="village"
                                                        value={farmer.village}
                                                        onChange={(e) =>
                                                            setFarmer({
                                                                ...farmer,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="pincode"
                                                        label="Pincode"
                                                        variant="standard"
                                                        name="pincode"
                                                        value={farmer.pincode}
                                                        onChange={(e) =>
                                                            setFarmer({
                                                                ...farmer,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        label="Address"
                                                        id="address"
                                                        variant="standard"
                                                        name="fullAddress"
                                                        value={
                                                            farmer.fullAddress
                                                        }
                                                        onChange={(e) =>
                                                            setFarmer({
                                                                ...farmer,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-12 text-end justify-space-between">
                                                    <button
                                                        className="w-16 btn btn-secondary"
                                                        style={{
                                                            marginRight: '10px',
                                                        }}
                                                        onClick={(e) =>
                                                            setTab(1)
                                                        }
                                                    >
                                                        Previous
                                                    </button>
                                                    <button
                                                        onClick={(e) =>
                                                            setTab(4)
                                                        }
                                                        className="w-16 btn btn-primary"
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                        // </form>
                                    }

                                    {/* Farm Info */}
                                    {
                                        tab === 4 && (
                                            // <form class="tab-pane show active" id="bottom-justified-tab4"
                                            //   onSubmit={e => {
                                            //     setTab(5)
                                            //     setFarmer({ ...farmer, typeOfCrop: cropTypes })
                                            //   }}
                                            // >
                                            <div className="row">
                                                <div className="col-md-3 mb-4">
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        className="mt-3"
                                                    >
                                                        <InputLabel id="demo-multiple-checkbox-label">
                                                            Crop Name
                                                        </InputLabel>
                                                        {console.log(
                                                            'CropProduct',
                                                            CropProduct,
                                                            CropProductoption
                                                        )}
                                                        <Selects
                                                            required
                                                            labelId="demo-multiple-checkbox-label"
                                                            id="demo-multiple-checkbox"
                                                            multiple
                                                            value={cropTypes}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            input={
                                                                <Input
                                                                    label="Crop Name"
                                                                    variant="standard"
                                                                />
                                                            }
                                                            renderValue={(
                                                                selected
                                                            ) =>
                                                                selected.join(
                                                                    ', '
                                                                )
                                                            }
                                                            MenuProps={
                                                                MenuProps
                                                            }
                                                        >
                                                            {CropProductoption &&
                                                                CropProductoption.map(
                                                                    (name) => (
                                                                        <MenuItem
                                                                            key={
                                                                                name.value
                                                                            }
                                                                            value={
                                                                                name.value
                                                                            }
                                                                        >
                                                                            {console.log(
                                                                                'CropProduct',
                                                                                name,
                                                                                name.value
                                                                            )}
                                                                            <Checkbox
                                                                                checked={
                                                                                    cropTypes.indexOf(
                                                                                        name.value
                                                                                    ) >
                                                                                    -1
                                                                                }
                                                                            />
                                                                            <ListItemText
                                                                                primary={
                                                                                    name.value
                                                                                }
                                                                            />
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                        </Selects>
                                                    </FormControl>
                                                </div>
                                                <div
                                                    class="col-md-3"
                                                    style={{ marginTop: '2%' }}
                                                >
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        className="mt-3"
                                                    >
                                                        {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
                              Irrigation Source
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="irrigationSource"
                              label="Irrigation Source"
                              color="success"
                              name="irrigationSource"
                              value={farmer.irrigationSource}
                              onChange={(e) => setFarmer({ ...farmer, [e.target.name]: e.target.value })}
                            >
                              <MenuItem value="">
                                <em>- Select Irrigation Source -</em>
                              </MenuItem>
                              <MenuItem value="Canal">Canal</MenuItem>
                              <MenuItem value="River">River</MenuItem>
                              <MenuItem value="Bore">Bore</MenuItem>
                              <MenuItem value="Well">Well</MenuItem>
                            </Select> */}
                                                        <Select
                                                            style={{
                                                                width: '270px',
                                                                height: '40px',
                                                            }}
                                                            // onChange={(e) => statechane(e)}
                                                            onChange={(e) =>
                                                                setFarmer({
                                                                    ...farmer,
                                                                    ['irrigationSource']:
                                                                        e.value,
                                                                })
                                                            }
                                                            // onChange={(e) => setFarmer({ ...farmer, ['irrigationSource']: e.value })}
                                                            options={
                                                                IrrigationSource
                                                            }
                                                            placeholder={
                                                                'Select irrigationSource'
                                                            }
                                                            //  value={inputBooking.firm}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div
                                                    class="col-md-3"
                                                    style={{ marginTop: '2%' }}
                                                >
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        className="mt-3"
                                                    >
                                                        {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
                              Farm Equipments
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="farmEquipments"
                              label="Farm Equipments"
                              color="success"
                              name="farmEquipments"
                              value={farmer.farmEquipments}
                              onChange={(e) => setFarmer({ ...farmer, [e.target.name]: e.target.value })}
                            >
                              <MenuItem value="">
                                <em>- Select Farm Equipments -</em>
                              </MenuItem>
                              <MenuItem value="Tractor">Tractor</MenuItem>
                            </Select> */}
                                                        <Select
                                                            style={{
                                                                width: '270px',
                                                                height: '40px',
                                                            }}
                                                            // onChange={(e) => statechane(e)}
                                                            onChange={(e) =>
                                                                setFarmer({
                                                                    ...farmer,
                                                                    ['farmEquipments']:
                                                                        e.value,
                                                                })
                                                            }
                                                            // onChange={(e) => setFarmer({ ...farmer, ['irrigationSource']: e.value })}
                                                            options={
                                                                farmEquipmentsValue
                                                            }
                                                            placeholder={
                                                                'Select farmEquipments'
                                                            }
                                                            //  value={inputBooking.firm}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div
                                                    class="col-md-3"
                                                    style={{ marginTop: '2%' }}
                                                >
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        className="mt-3"
                                                    >
                                                        {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
                              Select Unit
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="selectUnit"
                              label="Select Unit"
                              color="success"
                              name="selectUnit"
                              value={farmer.selectUnit}
                              onChange={(e) => setFarmer({ ...farmer, [e.target.name]: e.target.value })}
                            >
                              <MenuItem value="">
                                <em>- Select Unit -</em>
                              </MenuItem>
                              <MenuItem value="HECTARE">HECTARE</MenuItem>
                              <MenuItem value="ACRE">ACRE</MenuItem>
                              <MenuItem value="CENT KATHA">CENT</MenuItem>
                              <MenuItem value="CENT KATHA">KATHA</MenuItem>
                              <MenuItem value="BIGHA">BIGHA</MenuItem>
                              <MenuItem value="GUNTA">GUNTA</MenuItem>
                            </Select> */}
                                                        <Select
                                                            style={{
                                                                width: '270px',
                                                                height: '40px',
                                                            }}
                                                            // onChange={(e) => statechane(e)}
                                                            onChange={(e) =>
                                                                setFarmer({
                                                                    ...farmer,
                                                                    ['selectUnit']:
                                                                        e.value,
                                                                })
                                                            }
                                                            // onChange={(e) => setFarmer({ ...farmer, ['irrigationSource']: e.value })}
                                                            options={Unitvalue}
                                                            placeholder={
                                                                'Select selectUnit'
                                                            }
                                                            //  value={inputBooking.firm}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        type="number"
                                                        id="totalLand"
                                                        label="Total Land"
                                                        variant="standard"
                                                        name="totalLand"
                                                        value={farmer.totalLand}
                                                        onChange={(e) =>
                                                            setFarmer({
                                                                ...farmer,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    class="col-md-3"
                                                    style={{ marginTop: '2%' }}
                                                >
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        className="mt-3"
                                                    >
                                                        {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
                              Soil Type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="soilType"
                              label="Soil Type"
                              color="success"
                              name="soilType"
                              value={farmer.soilType}
                              onChange={(e) => setFarmer({ ...farmer, [e.target.name]: e.target.value })}
                            >
                              <MenuItem value="">
                                <em>- Select Unit -</em>
                              </MenuItem>
                              <MenuItem value="Sandy soil">Sandy soil</MenuItem>
                              <MenuItem value="Silt Soil">Silt Soil</MenuItem>
                              <MenuItem value="Clay Soil">Clay Soil</MenuItem>
                            </Select> */}
                                                        <Select
                                                            style={{
                                                                width: '270px',
                                                                height: '40px',
                                                            }}
                                                            // onChange={(e) => statechane(e)}
                                                            onChange={(e) =>
                                                                setFarmer({
                                                                    ...farmer,
                                                                    ['soilType']:
                                                                        e.value,
                                                                })
                                                            }
                                                            // onChange={(e) => setFarmer({ ...farmer, ['irrigationSource']: e.value })}
                                                            options={SoilType}
                                                            placeholder={
                                                                'Select soilType'
                                                            }
                                                            //  value={inputBooking.firm}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div class="col-md-3">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Sowing Date
                                                    </InputLabel>
                                                    <CssTextField
                                                        type="date"
                                                        fullWidth
                                                        name="sowingDate"
                                                        variant="standard"
                                                        style={{
                                                            marginTop: '10px',
                                                            marginBottom:
                                                                '10px',
                                                        }}
                                                        value={
                                                            farmer.sowingDate
                                                        }
                                                        onChange={(e) =>
                                                            setFarmer({
                                                                ...farmer,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    class="col-md-3"
                                                    style={{ marginTop: '2%' }}
                                                >
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        className="mt-3"
                                                    >
                                                        {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
                              Soil Test
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="soilTest"
                              label="Soil Test"
                              color="success"
                              name="soilTest"
                              value={farmer.soilTest}
                              onChange={(e) => setFarmer({ ...farmer, [e.target.name]: e.target.value })}
                            >
                              <MenuItem value="">
                                <em>- Select -</em>
                              </MenuItem>
                              <MenuItem value="true">Yes</MenuItem>
                              <MenuItem value="false">No</MenuItem>
                            </Select> */}
                                                        <Select
                                                            style={{
                                                                width: '270px',
                                                                height: '40px',
                                                            }}
                                                            // onChange={(e) => statechane(e)}
                                                            onChange={(e) =>
                                                                setFarmer({
                                                                    ...farmer,
                                                                    ['soilTest']:
                                                                        e.value,
                                                                })
                                                            }
                                                            // onChange={(e) => setFarmer({ ...farmer, ['irrigationSource']: e.value })}
                                                            options={SoilTest}
                                                            placeholder={
                                                                'Select soilTest'
                                                            }
                                                            //  value={inputBooking.firm}
                                                        />
                                                    </FormControl>
                                                </div>

                                                <div class="col-md-12 text-end mt-2 mb-2">
                                                    <button
                                                        className="w-16 btn btn-secondary"
                                                        style={{
                                                            marginRight: '10px',
                                                        }}
                                                        onClick={(e) =>
                                                            setTab(2)
                                                        }
                                                    >
                                                        Previous
                                                    </button>
                                                    <button
                                                        onClick={(e) => {
                                                            setTab(5)
                                                            setFarmer({
                                                                ...farmer,
                                                                typeOfCrop:
                                                                    cropTypes,
                                                            })
                                                        }}
                                                        class=" w-16 btn btn-primary"
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                        // </form>
                                    }

                                    {/* Banck ACC Details */}
                                    {
                                        tab === 5 && (
                                            // <form class="tab-pane show active" id="bottom-justified-tab5" onSubmit={onSubmitFarmer}>
                                            <div className="row">
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        id="bankAccountNumber"
                                                        label="Bank Acc No"
                                                        variant="standard"
                                                        type="number"
                                                        name="bankAccountNumber"
                                                        value={
                                                            farmer.bankAccountNumber
                                                        }
                                                        onChange={(e) =>
                                                            setFarmer({
                                                                ...farmer,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>

                                                <div class="col-md-3">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="bankAccountName"
                                                        label="Account Name"
                                                        variant="standard"
                                                        name="bankAccountName"
                                                        value={
                                                            farmer.bankAccountName
                                                        }
                                                        onChange={(e) =>
                                                            setFarmer({
                                                                ...farmer,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="ifscCode"
                                                        label="IFSC Code"
                                                        variant="standard"
                                                        name="ifscCode"
                                                        value={farmer.ifscCode}
                                                        onChange={(e) =>
                                                            setFarmer({
                                                                ...farmer,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="bankName"
                                                        label="Bank Name"
                                                        variant="standard"
                                                        pattern="[a-zA-Z]*"
                                                        type="text"
                                                        id="txtNumeric"
                                                        name="bankName"
                                                        value={farmer.bankName}
                                                        onChange={(e) =>
                                                            setFarmer({
                                                                ...farmer,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="bankBranchName"
                                                        label="Branch Name"
                                                        variant="standard"
                                                        name="bankBranchName"
                                                        value={
                                                            farmer.bankBranchName
                                                        }
                                                        onChange={(e) =>
                                                            setFarmer({
                                                                ...farmer,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>

                                                <div class="col-md-12 text-end mt-4 mb-2">
                                                    <button
                                                        className="w-16 btn btn-secondary"
                                                        style={{
                                                            marginRight: '10px',
                                                        }}
                                                        onClick={(e) =>
                                                            setTab(4)
                                                        }
                                                    >
                                                        Previous
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={(e) =>
                                                            onSubmitFarmer(e)
                                                        }
                                                        class=" w-16 btn btn-primary"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                        // </form>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
export default Farmers
