import React, { useState } from 'react'
import FarmerRevenue from './FarmerRevenue'
import InputRevenue from './InputRevenue'
import LivestockRevenue from './LivestockRevenue'
import MachineRevenue from './MachineRevenue'
import TransportRevenue from './TransportRevenue'
import MicroEntRevenue from './MicroEntRevenue'

function RevenuePlans() {
    const [tab, setTab] = useState(1)

    return (
        <div class="content container-fluid">
            <div class="page-header">
                <div class="row align-items-center">
                    <div class="col">
                        <h3 class="page-title">Revenue Plans</h3>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a href="/Home">Dashboard</a>
                            </li>
                            <li class="breadcrumb-item active">
                                Revenue Plans
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div class="card card-table p-10-25">
                                <div class="card-header">
                                    <h3 class="card-title">
                                        Create Revenue Plans
                                    </h3>
                                </div>
                                <form
                                    class="card-body"
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        console.log('Form Successfully working')
                                    }}
                                >
                                    <>
                                        <div class="tab-content">
                                            <div action="#">
                                                <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                                    <li class="nav-item">
                                                        <a
                                                            class={
                                                                tab === 1
                                                                    ? 'nav-link active'
                                                                    : 'nav-link'
                                                            }
                                                            href="#bottom-justified-tab1"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(1)
                                                            }
                                                        >
                                                            Farmer
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab2"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(2)
                                                            }
                                                        >
                                                            Input Supplier
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link "
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(3)
                                                            }
                                                        >
                                                            Livestock{' '}
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab4"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(4)
                                                            }
                                                        >
                                                            Agri Machine
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab5"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(5)
                                                            }
                                                        >
                                                            Agri Transport
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link "
                                                            href="#bottom-justified-tab6"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(6)
                                                            }
                                                        >
                                                            Micro Enterpreneur
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                </form>
                            </div>

                            {tab === 1 && <FarmerRevenue />}

                            {tab === 2 && <InputRevenue />}

                            {tab === 3 && <LivestockRevenue />}

                            {tab === 4 && <MachineRevenue />}

                            {tab === 5 && <TransportRevenue />}

                            {tab === 6 && <MicroEntRevenue />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RevenuePlans
