import React, { useState, useEffect } from 'react'
import { CssTextField } from '../../../Styles'
import CrpCategories from './ProductCCategories'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Switch from '@mui/material/Switch'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AddIcon from '@mui/icons-material/Add'
import { API } from '../../../../API'
import Swal from 'sweetalert2'
import ProductEnterpreneurTable from './ProductEnterpreneurTable'

const AddMicrocatelogclear = {
    title: 'CommissionCatelog',
    catelogName: 'MicroEnterpreneurCatelog',
    categoryName: '',
}
const AddMicrosubcategoryclear = {
    title: 'CommissionCatelog',
    catelogName: 'MicroEnterpreneurCatelog',
    categoryName: '',
    subCategoryName: '',
}
const AddMicrounitclear = {
    title: 'CommissionCatelog',
    catelogName: 'MicroEnterpreneurCatelog',
    unit: '',
}
const CreatecommissionClear = {
    title: 'CommissionCatelog',
    catelogName: 'MicroEnterpreneurCatelog',
    categoryName: '',
    subCategoryName: '',
    commissionUnit: '',
    commissionAmount: '',
}
function CropCategories() {
    const [showCategory, setShowCategory] = useState(false)
    const [ProductInput, setProductInput] = useState([])
    const [Categorylist, setCategorylist] = useState([])
    const [subCategorylist, setsubCategorylist] = useState([])
    const [categoryName, setCategoryName] = useState('')
    const [Unitlist, setUnitlist] = useState([])
    useEffect(() => {
        getProductInput()
    }, [])

    const getProductInput = async () => {
        const { data } = await API.get(
            `/getCommissionCatelog?title=CommissionCatelog&catelogName=MicroEnterpreneurCatelog&type=2`
        )
        setProductInput(data?.result)
    }
    const [AddMicrounit, setAddMicrounit] = useState({
        title: 'CommissionCatelog',
        catelogName: 'MicroEnterpreneurCatelog',
        unit: '',
    })
    const [AddMicroCategory, setAddMicroCategory] = useState({
        title: 'CommissionCatelog',
        catelogName: 'MicroEnterpreneurCatelog',
        categoryName: '',
    })
    const [AddMicrosubcategory, setAddMicrosubcategory] = useState({
        title: 'CommissionCatelog',
        catelogName: 'MicroEnterpreneurCatelog',
        categoryName: '',
        subCategoryName: '',
    })

    const AddCategorysubmit = async () => {
        // console.log("machine",machine)
        console.log('AddMicroCategory', AddMicroCategory)
        try {
            const { data } = await API.post('/createCategory', AddMicroCategory)

            console.log('data', data)
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)
                getCategoryList()
                setAddMicroCategory(AddMicrocatelogclear)
                // setAddinputsubcategory()
                // setCropTypes([]);
            } else {
                setAddMicroCategory(AddMicrocatelogclear)
                // setAddinputsubcategory()
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setAddMicroCategory(AddMicrocatelogclear)
            // setAddinputsubcategory()
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    const subCategorysubmit = async () => {
        console.log('submit', AddMicrosubcategory)
        try {
            const { data } = await API.post(
                '/createSubCategory',
                AddMicrosubcategory
            )

            console.log('data', data)
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)
                setAddMicrosubcategory(AddMicrosubcategoryclear)
                // setAddinputsubcategory()
                // setCropTypes([]);
            } else {
                setAddMicrosubcategory(AddMicrosubcategoryclear)
                // setAddinputsubcategory()
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setAddMicrosubcategory(AddMicrosubcategoryclear)
            // setAddinputsubcategory()
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    const unitsubmit = async () => {
        console.log('submit', AddMicrounit)
        try {
            const { data } = await API.post('/createUnit', AddMicrounit)

            console.log('data', data)
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)
                setAddMicrounit(AddMicrounitclear)
                // setAddinputsubcategory()
                // setCropTypes([]);
            } else {
                setAddMicrounit(AddMicrounitclear)
                // setAddinputsubcategory()
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setAddMicrounit(AddMicrounitclear)
            // setAddinputsubcategory()
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    const [Createcommission, setCreatecommission] = useState({
        title: 'CommissionCatelog',
        catelogName: 'MicroEnterpreneurCatelog',
        categoryName: '',
        subCategoryName: '',
        commissionUnit: '',
        commissionAmount: '',
    })
    const Createcommissionsubmit = async () => {
        console.log('submit', Createcommission)
        try {
            const { data } = await API.post(
                '/createCommissionCatelog',
                Createcommission
            )
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)
                getProductInput()
                setCreatecommission(CreatecommissionClear)
            } else {
                setCreatecommission(CreatecommissionClear)
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setCreatecommission(CreatecommissionClear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    const getCategoryList = async () => {
        const { data } = await API.get(
            `/getCategory?type=0&title=CommissionCatelog&catelogName=MicroEnterpreneurCatelog`
        )
        // console.log("hkjjhj",data)
        setCategorylist(data?.result)
    }

    const handleCategoryChange = (e) => {
        console.log('value', e.target.value)
        setCategoryName(e.target.value)
        setCreatecommission({
            ...Createcommission,
            categoryName: e.target.value,
        })
        getSubCategoryList(e.target.value)
    }

    const getSubCategoryList = async (val) => {
        const { data } = await API.get(
            `/getSubCategory?type=0&title=CommissionCatelog&catelogName=MicroEnterpreneurCatelog&categoryName=` +
                val
        )
        console.log('hkjjhj', data)
        setsubCategorylist(data?.result)
    }

    const getunitList = async (val) => {
        const { data } = await API.get(
            `/getUnit?type=0&title=CommissionCatelog&catelogName=MicroEnterpreneurCatelog`
        )
        console.log('hkjjhj', data)
        setUnitlist(data?.result)
    }
    const handleSubCategoryChange = (e) => {
        setCreatecommission({
            ...Createcommission,
            subCategoryName: e.target.value,
        })
        getunitList(e.target.value)
    }
    return (
        <>
            <div class="row align-items-center mb-3">
                <div class="col">
                    <h3 class="page-title">Micro Enterpreneur Catelog</h3>
                </div>
                {!showCategory ? (
                    <>
                        <div class="col-auto">
                            <button
                                class="btn btn-primary filter-btn"
                                id="openCategory"
                            >
                                <i class="fas fa-plus"></i>{' '}
                                <span> Add Category</span>
                            </button>
                        </div>
                        <div class="col-auto">
                            <button
                                class="btn btn-primary filter-btn"
                                id="openSubCategory"
                            >
                                <i class="fas fa-plus"></i>{' '}
                                <span> Add commision type</span>
                            </button>
                        </div>
                        <div class="col-auto">
                            <button
                                class="btn btn-primary filter-btn"
                                id="openProduct"
                            >
                                <i class="fas fa-plus"></i>{' '}
                                <span> Add Unit</span>
                            </button>
                        </div>
                        <div class="col-auto">
                            <button
                                class="btn btn-primary filter-btn"
                                onClick={(e) => setShowCategory(true)}
                            >
                                <i class="fas fa-eye"></i>{' '}
                                <span> View Category</span>
                            </button>
                        </div>
                    </>
                ) : (
                    <div class="col-auto">
                        <button
                            class="btn btn-primary filter-btn"
                            onClick={(e) => setShowCategory(false)}
                        >
                            <i class="fas fa-left"></i> <span> Back</span>
                        </button>
                    </div>
                )}
                {/* <div class="col-auto">
            {showCategory ? 
                 <button type="button" className="btn btn-secondary" onClick={e=>setShowCategory(false)}>
                  <AddIcon size="small" /> Create Category 
                 </button>
                 :
                 <button class="btn btn-secondary filter-btn" onClick={e=>setShowCategory(true)}>
            	   <i class="fas fa-eye"></i> <span> View Categories</span>
                 </button>
                }         
          </div> */}
            </div>

            {showCategory === false ? (
                <>
                    {/* Add Category */}
                    <div id="category" class="card filter-card ">
                        <form
                            class="card-body pb-0"
                            onSubmit={(e) => e.preventDefault()}
                        >
                            <div class="row">
                                <h5 class="card-title">Create Category</h5>
                                <div class="col-md-4 select2-container">
                                    <div
                                        class="form-group"
                                        data-select2-id="11"
                                    >
                                        <CssTextField
                                            required
                                            fullWidth
                                            id="InpCategory"
                                            label="Category"
                                            variant="standard"
                                            name="InpCategory"
                                            value={
                                                AddMicroCategory.categoryName
                                            }
                                            onChange={(e) =>
                                                setAddMicroCategory({
                                                    ...AddMicroCategory,
                                                    categoryName:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2">
                                    <div class="form-group">
                                        <button
                                            type="submit"
                                            class="btn btn-primary mt-26"
                                            onClick={() => AddCategorysubmit()}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    {/* Add SubCategory */}
                    <div id="subCategory" class="card filter-card">
                        <form
                            class="card-body pb-0"
                            onSubmit={(e) => e.preventDefault()}
                        >
                            <div class="row">
                                <h5 class="card-title">
                                    Create commision type
                                </h5>
                                <div class="col-md-4">
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        className="mt-3"
                                    >
                                        <InputLabel
                                            id="demo-simple-select-standard-label"
                                            style={{ color: 'black' }}
                                        >
                                            Category
                                        </InputLabel>
                                        <Select
                                            required
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            //  value={age}
                                            //  onChange={handleChange}
                                            value={
                                                AddMicrosubcategory.categoryName
                                            }
                                            onChange={(e) =>
                                                setAddMicrosubcategory({
                                                    ...AddMicrosubcategory,
                                                    categoryName:
                                                        e.target.value,
                                                })
                                            }
                                            label="Category"
                                            color="success"
                                        >
                                            {Categorylist &&
                                                Categorylist.map((val, ind) => {
                                                    // {console.log("Categorylist",val)}
                                                    return (
                                                        <MenuItem
                                                            value={
                                                                val.categoryName
                                                            }
                                                        >
                                                            {val.categoryName}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div class="col-md-4 select2-container">
                                    <div
                                        class="form-group"
                                        data-select2-id="11"
                                    >
                                        <CssTextField
                                            required
                                            fullWidth
                                            id="InpSubCategory"
                                            label="commision type"
                                            variant="standard"
                                            name="InpSubCategory"
                                            value={
                                                AddMicrosubcategory.subCategoryName
                                            }
                                            onChange={(e) =>
                                                setAddMicrosubcategory({
                                                    ...AddMicrosubcategory,
                                                    subCategoryName:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2">
                                    <div class="form-group">
                                        <button
                                            type="submit"
                                            class="btn btn-primary mt-26"
                                            onClick={() => subCategorysubmit()}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    {/* Add Unit */}
                    <div id="product" class="card filter-card">
                        <form
                            class="card-body pb-0"
                            onSubmit={(e) => e.preventDefault()}
                        >
                            <div class="row">
                                <h5 class="card-title">Create Unit</h5>
                                <div class="col-md-4 select2-container">
                                    <div
                                        class="form-group"
                                        data-select2-id="11"
                                    >
                                        <CssTextField
                                            required
                                            fullWidth
                                            id="CUnit"
                                            label="Unit"
                                            variant="standard"
                                            name="unit"
                                            value={AddMicrounit.unit}
                                            onChange={(e) =>
                                                setAddMicrounit({
                                                    ...AddMicrounit,
                                                    unit: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-2 col-md-2">
                                    <div class="form-group">
                                        <button
                                            type="submit"
                                            class="btn btn-primary mt-26"
                                            onClick={() => unitsubmit()}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    {/* Create Commission */}
                    <div className="card card-table p-10-25">
                        <form action="#" onSubmit={(e) => e.preventDefault()}>
                            <div className="mt-3">
                                <h5 class="card-title">Create Brand</h5>
                                <div class="row mb-4">
                                    <div class="col-md-3">
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            className="mt-3"
                                        >
                                            <InputLabel
                                                id="demo-simple-select-standard-label"
                                                style={{ color: 'black' }}
                                            >
                                                Category
                                            </InputLabel>
                                            <Select
                                                required
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                //  value={age}
                                                //  onChange={handleChange}
                                                value={
                                                    Createcommission.categoryName
                                                }
                                                onChange={(e) =>
                                                    handleCategoryChange(e)
                                                }
                                                //  onChange={(e)=>setCreatecommission({...Createcommission,categoryName:e.target.value})}
                                                label="Firm"
                                                color="success"
                                            >
                                                {Categorylist &&
                                                    Categorylist.map(
                                                        (val, ind) => {
                                                            // {console.log("Categorylist",val)}
                                                            return (
                                                                <MenuItem
                                                                    value={
                                                                        val.categoryName
                                                                    }
                                                                >
                                                                    {
                                                                        val.categoryName
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        }
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div class="col-md-3">
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            className="mt-3"
                                        >
                                            <InputLabel
                                                id="demo-simple-select-standard-label"
                                                style={{ color: 'black' }}
                                            >
                                                commision type
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                //  value={age}
                                                //  onChange={handleChange}
                                                value={
                                                    Createcommission.subCategoryName
                                                }
                                                onChange={(e) =>
                                                    handleSubCategoryChange(e)
                                                }
                                                //   onChange={(e)=>setCreatecommission({...Createcommission,subCategoryName:e.target.value})}
                                                label="Booking Mode"
                                                color="success"
                                            >
                                                {subCategorylist &&
                                                    subCategorylist.map(
                                                        (val, ind) => {
                                                            // {console.log("Categorylist",val)}
                                                            return (
                                                                <MenuItem
                                                                    value={
                                                                        val.subCategoryName
                                                                    }
                                                                >
                                                                    {
                                                                        val.subCategoryName
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        }
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div class="col-md-3">
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            className="mt-3"
                                        >
                                            <InputLabel
                                                id="demo-simple-select-standard-label"
                                                style={{ color: 'black' }}
                                            >
                                                Commission Unit
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                //  value={age}
                                                //  onChange={handleChange}
                                                value={
                                                    Createcommission.commissionUnit
                                                }
                                                onChange={(e) =>
                                                    setCreatecommission({
                                                        ...Createcommission,
                                                        commissionUnit:
                                                            e.target.value,
                                                    })
                                                }
                                                label="Booking Mode"
                                                color="success"
                                            >
                                                {Unitlist &&
                                                    Unitlist.map((val, ind) => {
                                                        // {console.log("Categorylist",val)}
                                                        return (
                                                            <MenuItem
                                                                value={val.unit}
                                                            >
                                                                {val.unit}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div class="col-md-3">
                                        <CssTextField
                                            required
                                            fullWidth
                                            id="commission"
                                            label="Commission Amount"
                                            variant="standard"
                                            name="commission"
                                            value={
                                                Createcommission.commissionAmount
                                            }
                                            onChange={(e) =>
                                                setCreatecommission({
                                                    ...Createcommission,
                                                    commissionAmount:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="row justify-content-end mb-3">
                                    <div className="col-md-1">
                                        <button
                                            type="submit"
                                            className="btn btn-danger"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                    <div className="col-md-1">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={() =>
                                                Createcommissionsubmit()
                                            }
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    {/* <div class="row">
       <div class="col-sm-12">
       <h3 className="mb-3">List</h3>
          <div class="card card-table">
             <div class="card-body">
                <div class="table-responsive">
                   <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                      <div class="row">
                         <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="DataTables_Table_0_length">
                               <label>
                                  Show 
                                  <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" class="custom-select custom-select-sm form-control form-control-sm">
                                     <option value="10">10</option>
                                     <option value="25">25</option>
                                     <option value="50">50</option>
                                     <option value="100">100</option>
                                  </select>
                                  entries
                               </label>
                            </div>
                         </div>
                         <div class="col-sm-12 col-md-3 position_right">
                            <div class="form-group">
                               <input type="text" class="form-control" placeholder="Search" />
                            </div>                                                
                         </div>
                      </div>
                      <div class="row" style={{margin:'2%'}}>
                        <ProductEnterpreneurTable inputs={ProductInput} />
                     </div>
                     
                      <div class="row">
                         <div class="col-sm-12 col-md-5">
                            <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 1 to 10 of 12 entries</div>
                         </div>
                         <div class="col-sm-12 col-md-7">
                            <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                               <ul class="pagination">
                                  <li class="paginate_button page-item previous disabled" id="DataTables_Table_0_previous"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li>
                                  <li class="paginate_button page-item active"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0" class="page-link">1</a></li>
                                  <li class="paginate_button page-item "><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="2" tabindex="0" class="page-link">2</a></li>
                                  <li class="paginate_button page-item next" id="DataTables_Table_0_next"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="3" tabindex="0" class="page-link">Next</a></li>
                               </ul>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div> */}
                </>
            ) : (
                //  <CrpCategories />
                <div class="row">
                    <div class="col-sm-12">
                        <h3 className="mb-3">List</h3>
                        <div class="card card-table">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <div
                                        id="DataTables_Table_0_wrapper"
                                        class="dataTables_wrapper dt-bootstrap4 no-footer"
                                    >
                                        <div class="row">
                                            <div class="col-sm-12 col-md-6">
                                                <div
                                                    class="dataTables_length"
                                                    id="DataTables_Table_0_length"
                                                >
                                                    <label>
                                                        Show
                                                        <select
                                                            name="DataTables_Table_0_length"
                                                            aria-controls="DataTables_Table_0"
                                                            class="custom-select custom-select-sm form-control form-control-sm"
                                                        >
                                                            <option value="10">
                                                                10
                                                            </option>
                                                            <option value="25">
                                                                25
                                                            </option>
                                                            <option value="50">
                                                                50
                                                            </option>
                                                            <option value="100">
                                                                100
                                                            </option>
                                                        </select>
                                                        entries
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-3 position_right">
                                                <div class="form-group">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Search"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="row"
                                            style={{ margin: '2%' }}
                                        >
                                            <ProductEnterpreneurTable
                                                inputs={ProductInput}
                                            />
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12 col-md-5">
                                                <div
                                                    class="dataTables_info"
                                                    id="DataTables_Table_0_info"
                                                    role="status"
                                                    aria-live="polite"
                                                >
                                                    Showing 1 to 10 of 12
                                                    entries
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-7">
                                                <div
                                                    class="dataTables_paginate paging_simple_numbers"
                                                    id="DataTables_Table_0_paginate"
                                                >
                                                    <ul class="pagination">
                                                        <li
                                                            class="paginate_button page-item previous disabled"
                                                            id="DataTables_Table_0_previous"
                                                        >
                                                            <a
                                                                href="#"
                                                                aria-controls="DataTables_Table_0"
                                                                data-dt-idx="0"
                                                                tabindex="0"
                                                                class="page-link"
                                                            >
                                                                Previous
                                                            </a>
                                                        </li>
                                                        <li class="paginate_button page-item active">
                                                            <a
                                                                href="#"
                                                                aria-controls="DataTables_Table_0"
                                                                data-dt-idx="1"
                                                                tabindex="0"
                                                                class="page-link"
                                                            >
                                                                1
                                                            </a>
                                                        </li>
                                                        <li class="paginate_button page-item ">
                                                            <a
                                                                href="#"
                                                                aria-controls="DataTables_Table_0"
                                                                data-dt-idx="2"
                                                                tabindex="0"
                                                                class="page-link"
                                                            >
                                                                2
                                                            </a>
                                                        </li>
                                                        <li
                                                            class="paginate_button page-item next"
                                                            id="DataTables_Table_0_next"
                                                        >
                                                            <a
                                                                href="#"
                                                                aria-controls="DataTables_Table_0"
                                                                data-dt-idx="3"
                                                                tabindex="0"
                                                                class="page-link"
                                                            >
                                                                Next
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default CropCategories
