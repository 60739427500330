import React from 'react'

function Email() {
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">SMS Notification Settings</h5>
                </div>
                <div class="card-body card-body_1">
                    <form>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <div class="card bg-white">
                                    <div class="card-body card-body_1">
                                        <ul class="nav nav-tabs nav-tabs-bottom nav_add_bt">
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link active"
                                                    href="#bottom-tab1"
                                                    data-bs-toggle="tab"
                                                >
                                                    Twilio
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#bottom-tab2"
                                                    data-bs-toggle="tab"
                                                >
                                                    Custom
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#bottom-tab3"
                                                    data-bs-toggle="tab"
                                                >
                                                    Orange
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="tab-content">
                                            <div
                                                class="tab-pane show active"
                                                id="bottom-tab1"
                                            >
                                                <div class="row mlt-5">
                                                    <div class="col-lg-5">
                                                        <div class="row form-group">
                                                            <label
                                                                for="addressline1"
                                                                class="p-l-0 col-form-label input-label"
                                                            >
                                                                Account SID
                                                            </label>
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                id="addressline1"
                                                                placeholder=""
                                                                value="ACbf280def577e25c97ee4fec9eaa4489b"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mlt-5">
                                                    <div class="col-lg-5">
                                                        <div class="row form-group">
                                                            <label
                                                                for="addressline1"
                                                                class="p-l-0 col-form-label input-label"
                                                            >
                                                                Auth Token
                                                            </label>
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                id="addressline1"
                                                                placeholder=""
                                                                value="1cdcc826e9e2f1fa9516d6243ea6c307"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mlt-5">
                                                    <div class="col-lg-5">
                                                        <div class="row form-group">
                                                            <label
                                                                for="addressline1"
                                                                class="p-l-0 col-form-label input-label"
                                                            >
                                                                Twilio Number/
                                                                Sender ID/ Short
                                                                Code
                                                            </label>
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                id="addressline1"
                                                                placeholder=""
                                                                value="+19292495695"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-left mt-3">
                                                    <button
                                                        type="submit"
                                                        class="btn btn-primary"
                                                    >
                                                        Update
                                                    </button>
                                                </div>

                                                <div class="row form-group p-hr-1">
                                                    <div class="col-sm-3">
                                                        <label
                                                            for="addressline1"
                                                            class="p-l-0 col-form-label input-label"
                                                        >
                                                            Check Twilio
                                                            Integration
                                                        </label>
                                                        <input
                                                            type="text"
                                                            class="form-control m-t-10"
                                                            placeholder="+1234567890"
                                                            value=""
                                                        />
                                                        <button
                                                            type="submit"
                                                            class="btn btn-primary sms_btn"
                                                        >
                                                            Send Test SMS
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="tab-pane"
                                                id="bottom-tab2"
                                            >
                                                <div class="row">
                                                    <div class="col-lg-5">
                                                        <label
                                                            for="addressline1"
                                                            class="p-l-0 col-form-label input-label"
                                                        >
                                                            Method
                                                        </label>
                                                        <div class="form-check form-check-inline">
                                                            <input
                                                                class="form-check-input"
                                                                type="radio"
                                                                name="gender"
                                                                value="option1"
                                                            />
                                                            <label
                                                                class="form-check-label get_post"
                                                                for="get"
                                                            >
                                                                GET
                                                            </label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input
                                                                class="form-check-input"
                                                                type="radio"
                                                                name="gender"
                                                                value="option2"
                                                            />
                                                            <label
                                                                class="form-check-label get_post"
                                                                for="post"
                                                            >
                                                                POST
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mlt-5">
                                                    <div class="col-lg-8">
                                                        <div class="row form-group">
                                                            <label
                                                                for="addressline1"
                                                                class="p-l-0 col-form-label input-label"
                                                            >
                                                                Url
                                                            </label>
                                                            <span class="referral_code">
                                                                Use [text] for
                                                                SMS text and
                                                                [phone]for the
                                                                recipient phone
                                                                number.
                                                                <br />
                                                                Ex:
                                                                http://example.com/sms/api.php?to=[phone]&message=[text]
                                                            </span>
                                                            <input
                                                                type="text"
                                                                class="form-control mt-2"
                                                                id="addressline1"
                                                                placeholder=""
                                                                value=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-left mt-3">
                                                    <button
                                                        type="submit"
                                                        class="btn btn-primary"
                                                    >
                                                        Set as Default
                                                    </button>
                                                </div>
                                                <div class="row form-group p-hr-1">
                                                    <div class="col-sm-3">
                                                        <label
                                                            for="addressline1"
                                                            class="p-l-0 col-form-label input-label"
                                                        >
                                                            Check Custom
                                                            Integration
                                                        </label>
                                                        <input
                                                            type="text"
                                                            class="form-control m-t-10"
                                                            placeholder="+1234567890"
                                                            value=""
                                                        />
                                                        <button
                                                            type="submit"
                                                            class="btn btn-primary sms_btn"
                                                        >
                                                            Send Test SMS
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="tab-pane"
                                                id="bottom-tab3"
                                            >
                                                <div class="row mlt-5">
                                                    <div class="col-lg-5">
                                                        <div class="row form-group">
                                                            <label
                                                                for="addressline1"
                                                                class="p-l-0 col-form-label input-label"
                                                            >
                                                                Client Id
                                                            </label>
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                id="addressline1"
                                                                placeholder=""
                                                                value=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mlt-5">
                                                    <div class="col-lg-5">
                                                        <div class="row form-group">
                                                            <label
                                                                for="addressline1"
                                                                class="p-l-0 col-form-label input-label"
                                                            >
                                                                Client Secret
                                                            </label>
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                id="addressline1"
                                                                placeholder=""
                                                                value=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mlt-5">
                                                    <div class="col-lg-5">
                                                        <div class="row form-group">
                                                            <label
                                                                for="addressline1"
                                                                class="p-l-0 col-form-label input-label"
                                                            >
                                                                Sender Address
                                                            </label>
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                id="addressline1"
                                                                placeholder=""
                                                                value=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mlt-5">
                                                    <div class="col-lg-5">
                                                        <div class="row form-group">
                                                            <label
                                                                for="addressline1"
                                                                class="p-l-0 col-form-label input-label"
                                                            >
                                                                Sender Name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                id="addressline1"
                                                                placeholder=""
                                                                value=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-left mt-3">
                                                    <button
                                                        type="submit"
                                                        class="btn btn-primary"
                                                    >
                                                        Set as Default
                                                    </button>
                                                </div>

                                                <div class="row form-group p-hr-1">
                                                    <div class="col-sm-3">
                                                        <label
                                                            for="addressline1"
                                                            class="p-l-0 col-form-label input-label"
                                                        >
                                                            Check Orange
                                                            Integration
                                                        </label>
                                                        <input
                                                            type="text"
                                                            class="form-control m-t-10"
                                                            placeholder="+1234567890"
                                                            value=""
                                                        />
                                                        <button
                                                            type="submit"
                                                            class="btn btn-primary sms_btn"
                                                        >
                                                            Send Test SMS
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Email
