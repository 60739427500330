import React, { useState, useEffect } from 'react'
import { CssTextField } from '../../Styles'
import axios from 'axios'
import { storage } from '../../../firebase/index'
import { API } from '../../../API/index'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import { useDropzone } from 'react-dropzone'
import {
    thumbsContainerState,
    thumb,
    img,
    thumbInner,
    baseStyle,
    driverDP,
    sectionDiv,
    uploadBtnSm,
    panImg,
} from '../../Styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Swal from 'sweetalert2'
import AgriMachineTable from './AgriMachineTable'
import '../Create.css'
import $ from 'jquery'
import Selects from 'react-select'
import Map from '../Map'

import OtpBox from '../../../components/OtpBox'

const Input = styled('input')({
    display: 'none',
    marginBottom: '16px',
    marginTop: '16px',
})
const clervalue = {
    firstName: '',
    lastName: '',
    mobileNumber: '',
    gender: '',
    category: '',
    dateOfBirth: '',
    AadharNo: '',
    language: '',
    business_mode: '',
    BrandName: '',
    state: '',
    district: '',
    subDistrict: '',
    village: '',
    pincode: '',
    address: '',

    price: '',
    perUnit: '',
    minimumBokingAmount: '',

    machineCategory: '',
    typeOfMachine: '',
    machineModel: '',
    machineNumber: '',
    document: '',
    documentExpiryDate: '',

    driverFirstName: '',
    driverLastName: '',
    driverMobileNumber: '',
    driverEmail: '',
    driverPhoto: '',
    driverLicenceNumber: '',
    drivingLicencePhoto: '',
    driverPancardPhoto: '',
    driverPancardNumber: '',
    driverAdhaarPhoto: '',
    driverAdhaarNumber: '',

    unit: '',
    commissionAmount: '',
    commissionCategory: '',

    bankAccountNumber: '',
    accountName: '',
    ifscCode: '',
    bankName: '',
    bankBranchName: '',
}
function AgriMachine(props) {
    const catalogue = 'machine'
    const [tab, setTab] = useState(1)
    const [states, setStates] = useState([])
    const [agriMachineList, setAgriMachineList] = useState([])
    const [docImg, setDocImg] = useState(null)
    const [driverImg, setDriverImg] = useState(null)
    const [driverLicenseImg, setDriverLicenseImg] = useState(null)
    const [AAdharImg, setAAdharImg] = useState(null)
    const [PANImg, setPANImg] = useState(null)
    const [driverPAN, setDriverPAN] = useState([])
    const [driverLicense, setDriverLicense] = useState([])
    const [driverAD, setDriverAD] = useState([])
    const [Categorylist, setCategorylist] = useState([])
    const [subCategorylist, setsubCategorylist] = useState([])
    const [BrandCategorylist, setBrandCategorylist] = useState([])
    const [Productlist, setProductlist] = useState([])
    const [CategoryName, setCategoryName] = useState('')
    const [subcategoryName, setsubcategoryName] = useState('')
    const [citylist, setcitylist] = useState([])
    const [statelist, setstatelist] = useState([])
    const [otpWindow, setOtpWindow] = useState(false)
    const [Formerinfo, setFormerinfo] = useState({})

    const [createdByid,setCreatedById]=useState('')
    const [createdByRole,setCreatedByRole]=useState("")
    const [CommisionCat, setCommisionCat] = useState([])
    const [agriMachine, setAgriMachine] = useState({
        firstName: '',
        lastName: '',
        mobileNumber: '',
        gender: '',
        category: '',
        dateOfBirth: '',
        AadharNo: '',
        language: '',
        business_mode: '',
        BrandName: '',
        state: '',
        district: '',
        subDistrict: '',
        village: '',
        pincode: '',
        address: '',

        price: '',
        perUnit: '',
        minimumBokingAmount: '',

        machineCategory: '',
        typeOfMachine: '',
        machineModel: '',
        machineNumber: '',
        document: '',
        documentExpiryDate: '',

        driverFirstName: '',
        driverLastName: '',
        driverMobileNumber: '',
        driverEmail: '',
        driverPhoto: '',
        driverLicenceNumber: '',
        drivingLicencePhoto: '',
        driverPancardPhoto: '',
        driverPancardNumber: '',
        driverAdhaarPhoto: '',
        driverAdhaarNumber: '',

        unit: '',
        commissionAmount: '',
        commissionCategory: '',

        bankAccountNumber: '',
        accountName: '',
        ifscCode: '',
        bankName: '',
        bankBranchName: '',
    })

    useEffect(() => {
        getState()
        // getAgriMachineList();
        getCategoryList()
        getStateList()
        getCommisionCat()
    }, [])
    const getCommisionCat = async () => {
        const { data } = await API.get(
            `/getCommissionCatelog?title=CommissionCatelog&catelogName=MachineCatelog&type=2`
        )
        // console.log("hkjjhj",data)
        setCommisionCat(data?.result)
        console.log('firm search', data)
    }
    const CommisionUnit =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.commissionUnit,
            label: item.commissionUnit,
        }))
    const CommisionAmount =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.commissionAmount,
            label: item.commissionAmount,
        }))
    const commisiontype =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.subCategoryName,
            label: item.subCategoryName,
        }))
    const getStateList = async () => {
        const { data } = await API.get(`/getStateList`)
        // console.log("hkjjhj",data)
        setstatelist(data?.result)
        console.log('firm search', data)
    }
    const statelistoption =
        statelist &&
        statelist.map((item, ind) => ({
            value: item,
            label: item,
        }))
    const statechane = (e) => {
        console.log('hkjjhj', e.value)
        setAgriMachine({ ...agriMachine, ['state']: e.value })
        // setInputs({ ...inputs, ['state']: e.value })
        // onChange={(e) => setInputs({ ...inputs, ['state']: e.target.value })}
        getCityList(e.value)
    }
    const getCityList = async (val) => {
        const { data } = await API.get(`/getCityList?stateName=` + val)
        // console.log("hkjjhj",data)
        setcitylist(data?.result)
        console.log('firm search', data)
    }
    const citylistoption =
        citylist &&
        citylist.map((item, ind) => ({
            value: item,
            label: item,
        }))
    const getState = async () => {
        const { data } = await axios.get(
            'http://65.0.145.198:4080/api/v1/getStateList'
        )
        setStates(data?.result)
        console.log(data?.result)
    }

    const getAgriMachineList = async () => {
        const { data } = await API.get(`/getAgriMachine?type=0`)
        setAgriMachineList(data?.result)
    }

    // Document Upload
    const handleUploadDoc = (e) => {
        setDocImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const uploadTask = ref(storage, `docs/${doc.name}`)
            const uploadTask02 = uploadBytesResumable(uploadTask, doc)
            uploadTask02.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    // Handle unsuccessful uploads
                },
                () => {
                    getDownloadURL(uploadTask).then((url) => {
                        setAgriMachine({ ...agriMachine, document: url })
                    })
                }
            )
        }
    }
    //handleUploadAADHAR

    const handleUploadAADHAR = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setAAdharImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverAADhar/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        console.log('url', url)
                        setAgriMachine({
                            ...agriMachine,
                            driverAdhaarPhoto: url,
                        })
                    })
                }
            )
        }
    }
    //PAN
    const handleUploadPAN = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setPANImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPAN/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        console.log('url', url)
                        setAgriMachine({
                            ...agriMachine,
                            driverPancardPhoto: url,
                        })
                    })
                }
            )
        }
    }

    // Driver Licence Photo
    const handleUploadDLP = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setDriverLicenseImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverLicence/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        console.log('url', url)
                        setAgriMachine({
                            ...agriMachine,
                            drivingLicencePhoto: url,
                        })
                    })
                }
            )
        }
    }

    // Driver Photo
    const handleUploadDP = (e) => {
        setDriverImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        setAgriMachine({ ...agriMachine, driverPhoto: url })
                    })
                }
            )
        }
    }

    // Driving License Upload
    const DriverLicenseUpload = () => {
        const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
            accept: 'image/',
            onDrop: (acceptedFiles) => {
                setDriverLicense(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )
                )
            },
        })

        return (
            <section className="container" style={baseStyle}>
                <div
                    {...getRootProps({ className: 'dropzone' })}
                    style={panImg}
                >
                    <input {...getInputProps()} multiple={false} />
                    {driverLicense.length > 0 ? (
                        <aside style={thumbsContainerState}>
                            <div style={thumb}>
                                <div style={thumbInner}>
                                    <img
                                        src={driverLicense[0].preview}
                                        style={img}
                                        alt="Driving License"
                                    />
                                </div>
                            </div>
                        </aside>
                    ) : (
                        <p align="center">
                            Drop Image here or click to upload.
                        </p>
                    )}
                </div>
            </section>
        )
    }

    const handleUploadDL = (e) => {
        const doc = driverLicense[0]
        if (doc) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        setAgriMachine({
                            ...agriMachine,
                            drivingLicencePhoto: url,
                        })
                    })
                }
            )
        }
    }

    // Driver PAN upload
    const PanUpload = () => {
        const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
            accept: 'image/',
            onDrop: (acceptedFiles) => {
                setDriverPAN(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )
                )
            },
        })

        return (
            <section className="container" style={baseStyle}>
                <div
                    {...getRootProps({ className: 'dropzone' })}
                    style={panImg}
                >
                    <input {...getInputProps()} multiple={false} />
                    {driverPAN.length > 0 ? (
                        <aside style={thumbsContainerState}>
                            <div style={thumb}>
                                <div style={thumbInner}>
                                    <img
                                        src={driverPAN[0].preview}
                                        style={img}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </aside>
                    ) : (
                        <p align="center">
                            Drop Image here or click to upload.
                        </p>
                    )}
                </div>
            </section>
        )
    }

    // Driver Aadhaar upload
    const AadhaarUpload = () => {
        const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
            accept: 'image/',
            onDrop: (acceptedFiles) => {
                setDriverAD(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )
                )
            },
        })

        return (
            <section className="container" style={baseStyle}>
                <div
                    {...getRootProps({ className: 'dropzone' })}
                    style={panImg}
                >
                    <input {...getInputProps()} multiple={false} />
                    {driverAD.length > 0 ? (
                        <aside style={thumbsContainerState}>
                            <div style={thumb}>
                                <div style={thumbInner}>
                                    <img
                                        src={driverAD[0].preview}
                                        style={img}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </aside>
                    ) : (
                        <p align="center">
                            Drop Image here or click to upload.
                        </p>
                    )}
                </div>
            </section>
        )
    }

    const handleUploadAD = (e) => {
        const doc = driverAD[0]
        if (doc) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        setAgriMachine({
                            ...agriMachine,
                            driverAdhaarPhoto: url,
                        })
                    })
                }
            )
        }
    }

    // Driver Details
    const onSubmitDriverDetails = () => {
        setTab(7)
    }

    // Submit Agri Machine
    const onSubmitAgriMachine = async (e) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const dataForOtp = {
            mobile_number: agriMachine.mobileNumber,
            role: 'agrimachine',
            created_by: 'admin',
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/auth-function/otp`,
                dataForOtp,
                config
            )
            .then((res) => {
                setOtpWindow(true)
            })
    }

    function syncOtpSubmit() {
        setOtpWindow(false)
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            user_id: '',
            first_name: agriMachine.firstName,
            last_name: agriMachine.lastName,
            gender: agriMachine.gender,
            dob: agriMachine.dateOfBirth,
            role: 'agri-machine',
            created_by: 'admin',
            coordinates: [agriMachine.lat, agriMachine.lng],
            mobile_number: agriMachine.mobileNumber,
            photo: 'test',
            language: agriMachine.language,
            business_mode: agriMachine.business_mode,
            state: agriMachine.state,
            district: agriMachine.district,
            sub_distric: agriMachine.village,
            village: agriMachine.village,
            pincode: +agriMachine.pincode,
            created_by: createdByRole,
            createdById:createdByid,
  full_address: agriMachine.address,
            machine_info: {
                category: agriMachine.machineCategory || 'test',
                brand_name: agriMachine.BrandName || 'test',
                machine_type: agriMachine.typeOfMachine || 'test',
                machine_number: agriMachine.machineNumber || 'test',
                machine_model: agriMachine.machineModel || 'test',
                document: {
                    image: agriMachine.document || 'test',
                    document_exp: agriMachine.documentExpiryDate || 'test',
                },
            },
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/auth-function/farmers`,
                data,
                config
            )
            .then((res) => {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: 'Agri Machine Created Successfully',
                    showConfirmButton: false,
                    timer: 2000,
                })
                setAgriMachine(clervalue)
                setTab(1)
            })
            .catch((err) =>
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: 'error occured!',
                    showConfirmButton: false,
                    timer: 2000,
                })
            )
    }

    const handleMobVal = (e) => {
        setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })
        if (e.target.value.length == 10) {
            mobilecheck(e.target.value)
        } else if (e.target.value.length > 10) {
            console.log('e.target.value.length', e.target.value.length)
            // setInputs({ ...inputs, mobileNumber: "" })
            setAgriMachine({ ...agriMachine, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: 'Mobile length should be 10',
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const mobilecheck = async (number) => {
        const { data } = await API.get(`/checkNumber?number=` + number)
        console.log('data', data)
        if (data.success == true) {
            // setFarmer({ ...farmer, [e.target.name]: number })
        } else {
            setAgriMachine({ ...agriMachine, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data.result,
                showConfirmButton: false,
                timer: 2000,
            })
        }
        // setDetails(data?.result);
    }
    const getCategoryList = async () => {
        // const { data } = await API.get(`/getProductCategory?type=0&title=ProductCatelog&catelogName=MachineCatelog`);
        // // console.log("hkjjhj",data)
        // setCategorylist(data?.result);
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/category/${catalogue}`,
                config
            )
            .then((res) => {
                console.log('d :', res.data.category)
                setCategorylist(res.data.category)
            })
    }
    const getSubCategoryList = async (val) => {
        // const { data } = await API.get(`/getProductSubCategory?type=0&title=ProductCatelog&catelogName=MachineCatelog&categoryName=` + val);
        // console.log("hkjjhj", data)
        // setsubCategorylist(data?.result);
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const subCat = {
            catalogue: catalogue,
            categoryName: val,
        }
        await axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-subcategory`,
                subCat,
                config
            )
            .then((res) => {
                console.log(
                    'get sub cat in input catalogue',
                    res.data.subcategory
                )
                setsubCategorylist(res.data.subcategory)
            })
    }
    const handleCategoryChangeVarient = (e) => {
        setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })
        setCategoryName(e.target.value)
        getSubCategoryList(e.target.value)
    }
    const handleSUbCategoryChangeVarient = (e) => {
        setsubcategoryName(e.target.value)
        setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })
        getBrandCategoryList(e.target.value)
    }
    const handleBRANDCategoryChangeVarient = (e) => {
        setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })
        getProductListVarient(e.target.value)
    }
    const getProductListVarient = async (val) => {
        // const { data } = await API.get(`/getProductCatelogProduct?type=1&title=ProductCatelog&catelogName=MachineCatelog&categoryName=` + CategoryName + '&subCategoryName=' + subcategoryName + '&brandName=' + val);
        // console.log("hkjjhj", data)
        // setProductlist(data?.result);
        let forGet = {
            categoryName: agriMachine.machineCategory,
            subCategoryName: agriMachine.machineModel,
            brandName: val,
            catalogue: catalogue,
        }
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-product`,
                forGet,
                config
            )
            .then((data) => {
                setProductlist(data.data.product)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const getBrandCategoryList = async (val) => {
        // const { data } = await API.get(`/getProductBrand?type=0&title=ProductCatelog&catelogName=MachineCatelog&categoryName=` + CategoryName + '&subCategoryName=' + val)
        // console.log("hkjjhj", data)
        // setBrandCategorylist(data?.result);
        let forGetBrands = {
            categoryName: agriMachine.machineCategory,
            subCategoryName: val,
            catalogue: catalogue,
        }
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-brand`,
                forGetBrands,
                config
            )
            .then((data) => {
                console.log('brand in input', data)
                setBrandCategorylist(data.data.brand)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleMobilesearch = (e) => {
        console.log('e', e.target.value)
        if (e.target.value.length == 10) {
            mobilechecksearch(e.target.value)
        } else {
            setAgriMachineList([])
        }
    }
    const mobilechecksearch = async (number) => {
        const { data } = await API.get(`/searchAgriMachine?search=` + number)
        console.log('data', data)
        if (data.success == true) {
            let arr = []
            arr.push(data?.result)
            setAgriMachineList(arr)
        } else {
        }
    }
    $(function () {
        $('#txtNumeric').keydown(function (e) {
            if (e.shiftKey || e.ctrlKey || e.altKey) {
                e.preventDefault()
            } else {
                var key = e.keyCode

                if (
                    !(
                        key == 8 ||
                        key == 32 ||
                        key == 46 ||
                        (key >= 35 && key <= 40) ||
                        (key >= 65 && key <= 90)
                    )
                ) {
                    e.preventDefault()
                }
            }
        })
    })

    const mapdata = (data) => {
        console.log('ran')
        setAgriMachine({
            ...agriMachine,
            state: data.state,
            district: data.district,
            village: data.village,
            pincode: data.pincode,
            full_address: data.address,
            address: data.address,
            lat: data.latitude,
            lng: data.longitude,
        })
    }
    const handleFarmer = (e) => {
        if (e.target.value.length == 10) {
            getFormerinfo(e.target.value)
        } else {
            setFormerinfo({})
        }
    }
    const getFormerinfo = async (Mob) => {
        const token = localStorage.getItem('token')
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        const farmerNumber = {
            mobile_no: Mob,
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/register-details`,
                farmerNumber,
                config
            )
            .then((res) => {
                const d = res.data.data.data
                localStorage.setItem('FormerId', d.user_id)
                setCreatedById(d.user_id)
                setCreatedByRole(d.role)
                localStorage.setItem('microId', '')
                setFormerinfo(d)
            })
            .catch((err) => {
                console.log(err)
                setFormerinfo({})
            })
    }
    return (
        <>
        <div class="card card-table p-10-25">
                            <div class="card-header">
                                <h5 class="card-title"> Info</h5>
                            </div>
           <div class="tab-content">
                                        <div action="#">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="farmerNum"
                                                        //  label="Farmer Number"
                                                        placeholder="Farmer Number"
                                                        variant="standard"
                                                        //  value={}
                                                        onChange={(e) =>
                                                            handleFarmer(e)
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.role
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.id
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.first_name
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerState"
                                                        //  label="State"
                                                        placeholder="State"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.state
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="farmerDistrict"
                                                        disabled
                                                        //  label="District"
                                                        placeholder="District"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.district
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        disabled
                                                        fullWidth
                                                        id="farmerVillage"
                                                        //  label="Village"
                                                        placeholder="Village"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.village
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        disabled
                                                        fullWidth
                                                        id="farmerPincode"
                                                        //  label="Pincode"
                                                        placeholder="Pincode"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.pincode
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>
                                          
                                        </div>
                                        </div>

                                    </div>
            {otpWindow ? (
                <OtpBox
                    mobileNumber={agriMachine.mobileNumber}
                    syncOtpSubmit={syncOtpSubmit}
                />
            ) : (
                <>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card card-table p-10-25">
                                <div class="card-header">
                                    <h5 class="card-title">
                                        Create Agri Machine
                                    </h5>
                                </div>
                                <div class="card-body">
                                    <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                                        <li class="nav-item">
                                            <a
                                                className={
                                                    tab === 1
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                Personal Info
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                className={
                                                    tab === 2
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                Contact Details
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                className={
                                                    tab === 3
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                {' '}
                                                Machine Info
                                            </a>
                                        </li>
                                        {/* <li class="nav-item"><a className={tab === 4 ? "nav-link active" : "nav-link"}> Machine Rental Details</a></li> */}
                                        {/* <li class="nav-item"><a className={tab === 5 ? "nav-link active" : "nav-link"}> Driver Details</a></li> */}
                                        {/* <li class="nav-item"><a className={tab === 6 ? "nav-link active" : "nav-link"}> Commission Details</a></li> */}
                                        {/* <li class="nav-item"><a className={tab === 7 ? "nav-link active" : "nav-link"}>Bank Account Info</a></li> */}
                                    </ul>
                                    <div class="tab-content">
                                        {/* Personal Details */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab1"
                                        >
                                            {
                                                tab === 1 && (
                                                    // <form onSubmit={() => setTab(2)}>
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="fName"
                                                                label="First Name"
                                                                variant="standard"
                                                                className="mt-3"
                                                                name="firstName"
                                                                value={
                                                                    agriMachine.firstName
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriMachine(
                                                                        {
                                                                            ...agriMachine,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="lName"
                                                                label="Last Name"
                                                                variant="standard"
                                                                className="mt-3"
                                                                name="lastName"
                                                                value={
                                                                    agriMachine.lastName
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriMachine(
                                                                        {
                                                                            ...agriMachine,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="mobile"
                                                                label="Mobile Number"
                                                                variant="standard"
                                                                className="mt-3"
                                                                style={{
                                                                    marginBottom:
                                                                        '1.25rem',
                                                                }}
                                                                name="mobileNumber"
                                                                value={
                                                                    agriMachine.mobileNumber
                                                                }
                                                                onChange={(e) =>
                                                                    handleMobVal(
                                                                        e
                                                                    )
                                                                }
                                                                // onChange={(e) => setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })}
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                >
                                                                    Gender*
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    label="Gender"
                                                                    color="success"
                                                                    name="gender"
                                                                    value={
                                                                        agriMachine.gender
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setAgriMachine(
                                                                            {
                                                                                ...agriMachine,
                                                                                [e
                                                                                    .target
                                                                                    .name]:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>
                                                                            None
                                                                        </em>
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        value={
                                                                            'Male'
                                                                        }
                                                                    >
                                                                        Male
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        value={
                                                                            'Female'
                                                                        }
                                                                    >
                                                                        Female
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        value={
                                                                            'Others'
                                                                        }
                                                                    >
                                                                        Others
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>

                                                        <div class="col-md-4">
                                                            <InputLabel
                                                                id="demo-simple-select-standard-label"
                                                                style={{
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                DOB
                                                            </InputLabel>
                                                            <CssTextField
                                                                type="date"
                                                                fullWidth
                                                                variant="standard"
                                                                style={{
                                                                    marginTop:
                                                                        '10px',
                                                                    marginBottom:
                                                                        '10px',
                                                                }}
                                                                name="dateOfBirth"
                                                                value={
                                                                    agriMachine.dateOfBirth
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriMachine(
                                                                        {
                                                                            ...agriMachine,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>

                                                        <div class="col-md-4">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                >
                                                                    Business
                                                                    Mode*
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    label="business_mode"
                                                                    color="success"
                                                                    name="business_mode"
                                                                    value={
                                                                        agriMachine.business_mode
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setAgriMachine(
                                                                            {
                                                                                ...agriMachine,
                                                                                [e
                                                                                    .target
                                                                                    .name]:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>
                                                                            None
                                                                        </em>
                                                                    </MenuItem>
                                                                    <MenuItem value="Renter">
                                                                        Renter
                                                                    </MenuItem>
                                                                    <MenuItem value="Showroom">
                                                                        Showroom
                                                                    </MenuItem>
                                                                    <MenuItem value="Distributor">
                                                                        Distributor
                                                                    </MenuItem>
                                                                    <MenuItem value="Manufacture">
                                                                        Manufacture
                                                                    </MenuItem>
                                                                    <MenuItem value="Organisation">
                                                                        Organisation
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>

                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                            <button
                                                                onClick={(e) =>
                                                                    setTab(2)
                                                                }
                                                                class=" w-106 btn btn-primary"
                                                            >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                                // </form>
                                            }
                                        </div>

                                        {/* Contact Details */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab2"
                                        >
                                            {tab === 2 && (
                                                <div class="row">
                                                    <div
                                                        class="col-md-3"
                                                        style={{
                                                            marginTop: '2%',
                                                        }}
                                                    >
                                                        <Map
                                                            center={{
                                                                lat: 18.5204,
                                                                lng: 73.8567,
                                                            }}
                                                            height="300px"
                                                            zoom={15}
                                                            mapdata={mapdata}
                                                        />
                                                    </div>

                                                    <div class="col-md-12 text-end justify-space-between">
                                                        <button
                                                            className="w-16 btn btn-secondary"
                                                            style={{
                                                                marginRight:
                                                                    '10px',
                                                            }}
                                                            onClick={(e) =>
                                                                setTab(1)
                                                            }
                                                        >
                                                            Previous
                                                        </button>
                                                        <button
                                                            onClick={(e) =>
                                                                setTab(3)
                                                            }
                                                            className="w-16 btn btn-primary"
                                                        >
                                                            Next
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {/* Machine Info */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab4"
                                        >
                                            {
                                                tab === 3 && (
                                                    // <form onSubmit={() => setTab(4)}>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                >
                                                                    Category
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    label="Category"
                                                                    color="success"
                                                                    name="machineCategory"
                                                                    value={
                                                                        agriMachine.machineCategory
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleCategoryChangeVarient(
                                                                            e
                                                                        )
                                                                    }
                                                                    // onChange={(e) => setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })}
                                                                >
                                                                    {/* <MenuItem value=""><em> - Select State - </em></MenuItem>
                              <MenuItem value="Hindi">Hindi</MenuItem>
                              <MenuItem value="Bengali">Bengali</MenuItem>
                              <MenuItem value="Urdu">Urdu</MenuItem>
                              <MenuItem value="Punjabi">Punjabi</MenuItem>
                              <MenuItem value="Marathi">Marathi</MenuItem>
                              <MenuItem value="Telugu">Telugu</MenuItem>
                              <MenuItem value="Tamil">Tamil</MenuItem> */}
                                                                    {Categorylist &&
                                                                        Categorylist.map(
                                                                            (
                                                                                val,
                                                                                ind
                                                                            ) => {
                                                                                // {console.log("Categorylist",val)}
                                                                                return (
                                                                                    <MenuItem
                                                                                        value={
                                                                                            val.categoryName
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            val.categoryName
                                                                                        }
                                                                                    </MenuItem>
                                                                                )
                                                                            }
                                                                        )}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                >
                                                                    Machine
                                                                    Model
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    label="Sub Category"
                                                                    color="success"
                                                                    name="machineModel"
                                                                    value={
                                                                        agriMachine.machineModel
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleSUbCategoryChangeVarient(
                                                                            e
                                                                        )
                                                                    }
                                                                    // onChange={(e) => setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })}
                                                                >
                                                                    {/* <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="0">Select State</MenuItem>
                              <MenuItem value="Hindi">Hindi</MenuItem>
                              <MenuItem value="Bengali">Bengali</MenuItem>
                              <MenuItem value="Urdu">Urdu</MenuItem>
                              <MenuItem value="Punjabi">Punjabi</MenuItem>
                              <MenuItem value="Marathi">Marathi</MenuItem>
                              <MenuItem value="Telugu">Telugu</MenuItem>
                              <MenuItem value="Tamil">Tamil</MenuItem> */}
                                                                    {subCategorylist &&
                                                                        subCategorylist.map(
                                                                            (
                                                                                val,
                                                                                ind
                                                                            ) => {
                                                                                // {console.log("Categorylist",val)}
                                                                                return (
                                                                                    <MenuItem
                                                                                        value={
                                                                                            val.subCategoryName
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            val.subCategoryName
                                                                                        }
                                                                                    </MenuItem>
                                                                                )
                                                                            }
                                                                        )}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                >
                                                                    Brand Name
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    label="Brand Name"
                                                                    color="success"
                                                                    name="BrandName"
                                                                    value={
                                                                        agriMachine.BrandName
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleBRANDCategoryChangeVarient(
                                                                            e
                                                                        )
                                                                    }
                                                                    // onChange={(e) => setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })}
                                                                >
                                                                    {BrandCategorylist &&
                                                                        BrandCategorylist.map(
                                                                            (
                                                                                val,
                                                                                ind
                                                                            ) => {
                                                                                // {console.log("Categorylist",val)}
                                                                                return (
                                                                                    <MenuItem
                                                                                        value={
                                                                                            val.brandName
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            val.brandName
                                                                                        }
                                                                                    </MenuItem>
                                                                                )
                                                                            }
                                                                        )}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                >
                                                                    Type of
                                                                    Machine
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    label="Product Name"
                                                                    color="success"
                                                                    name="typeOfMachine"
                                                                    value={
                                                                        agriMachine.typeOfMachine
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setAgriMachine(
                                                                            {
                                                                                ...agriMachine,
                                                                                [e
                                                                                    .target
                                                                                    .name]:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    {Productlist &&
                                                                        Productlist.map(
                                                                            (
                                                                                val,
                                                                                ind
                                                                            ) => {
                                                                                // {console.log("Categorylist",val)}
                                                                                return (
                                                                                    <MenuItem
                                                                                        value={
                                                                                            val.productName
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            val.productName
                                                                                        }
                                                                                    </MenuItem>
                                                                                )
                                                                            }
                                                                        )}
                                                                </Select>
                                                            </FormControl>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                id="machineNumber"
                                                                label="Machine Number"
                                                                variant="standard"
                                                                name="machineNumber"
                                                                value={
                                                                    agriMachine.machineNumber
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriMachine(
                                                                        {
                                                                            ...agriMachine,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            className="col-md-12 mt-3"
                                                            style={{}}
                                                        >
                                                            <InputLabel
                                                                id="demo-simple-select-standard-label"
                                                                style={{
                                                                    color: 'black',
                                                                }}
                                                                className="mb-3"
                                                            >
                                                                Document :
                                                            </InputLabel>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <InputLabel
                                                                id="demo-simple-select-standard-label"
                                                                style={{
                                                                    color: 'black',
                                                                    marginTop:
                                                                        '22px',
                                                                }}
                                                            >
                                                                Document Expire
                                                                Date:
                                                            </InputLabel>
                                                            <CssTextField
                                                                fullWidth
                                                                type="date"
                                                                id="documentExpiryDate"
                                                                variant="standard"
                                                                name="documentExpiryDate"
                                                                value={
                                                                    agriMachine.documentExpiryDate
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriMachine(
                                                                        {
                                                                            ...agriMachine,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-md-2 col-sm-4">
                                                            <label
                                                                htmlFor="contained-button-file"
                                                                style={{
                                                                    marginTop:
                                                                        '60px',
                                                                }}
                                                            >
                                                                <Button
                                                                    variant="contained"
                                                                    component="span"
                                                                    color="success"
                                                                >
                                                                    <Input
                                                                        accept="image/"
                                                                        id="contained-button-file"
                                                                        multiple="false"
                                                                        type="file"
                                                                        onChange={
                                                                            handleUploadDoc
                                                                        }
                                                                    />
                                                                    Select File
                                                                </Button>
                                                            </label>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <aside
                                                                style={
                                                                    thumbsContainerState
                                                                }
                                                            >
                                                                <div
                                                                    style={
                                                                        thumb
                                                                    }
                                                                >
                                                                    <div
                                                                        style={
                                                                            thumbInner
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={
                                                                                docImg
                                                                            }
                                                                            style={
                                                                                img
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </aside>
                                                        </div>
                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                            <button
                                                                className="w-16 btn btn-secondary"
                                                                style={{
                                                                    marginRight:
                                                                        '10px',
                                                                }}
                                                                onClick={(e) =>
                                                                    setTab(2)
                                                                }
                                                            >
                                                                Previous
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={(e) =>
                                                                    syncOtpSubmit()
                                                                }
                                                                class=" w-16 btn btn-primary"
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                                // </form>
                                            }
                                        </div>

                                        {/* Machine Rental Details */}
                                        {/* <div class="tab-pane show active" id="bottom-justified-tab5">
                  {tab === 4 &&
                    // <form onSubmit={() => setTab(5)}>
                    <div className="row">
                      <div className="col-md-3">
                        <CssTextField
                          fullWidth
                          type="text"
                          id="price"
                          label="Price"
                          variant="standard"
                          name="price"
                          value={agriMachine.price}
                          onChange={(e) => setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })}
                        />
                      </div>
                      <div className="col-md-3">
                        <CssTextField
                          fullWidth
                          type="text"
                          id="Per Unit"
                          label="Per Unit"
                          variant="standard"
                          name="perUnit"
                          value={agriMachine.perUnit}
                          onChange={(e) => setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })}
                        />
                      </div>
                      <div className="col-md-3">
                        <CssTextField
                          fullWidth
                          type="text"
                          id="MininumAmt"
                          label="Minimum Booking Amount"
                          variant="standard"
                          name="minimumBokingAmount"
                          value={agriMachine.minimumBokingAmount}
                          onChange={(e) => setAgriMachine({ ...agriMachine, [e.target.name]: e.target.value })}
                        />
                      </div>
                      <div class="col-md-12 text-end mt-2 mb-2">
                        <button className="w-16 btn btn-secondary" style={{ marginRight: '10px' }} onClick={e => setTab(3)}>Previous</button>
                        <button type="button" onClick={(e) => onSubmitAgriMachine()} class=" w-16 btn btn-primary">Submit</button>
                      </div>
                    </div>
                    // </form>
                  }
                </div> */}

                                        {/* Driver Details */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab6"
                                        >
                                            {
                                                tab === 5 && (
                                                    // <form onSubmit={onSubmitDriverDetails}>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                type="text"
                                                                id="driverFirstName"
                                                                label="First Name"
                                                                variant="standard"
                                                                name="driverFirstName"
                                                                value={
                                                                    agriMachine.driverFirstName
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriMachine(
                                                                        {
                                                                            ...agriMachine,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                type="text"
                                                                id="driverLastName"
                                                                label="Last Name"
                                                                variant="standard"
                                                                name="driverLastName"
                                                                value={
                                                                    agriMachine.driverLastName
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriMachine(
                                                                        {
                                                                            ...agriMachine,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                type="text"
                                                                id="driverMobileNumber"
                                                                label="Mobile Number"
                                                                variant="standard"
                                                                name="driverMobileNumber"
                                                                value={
                                                                    agriMachine.driverMobileNumber
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriMachine(
                                                                        {
                                                                            ...agriMachine,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                type="text"
                                                                id="driverEmail"
                                                                label="Email ID"
                                                                variant="standard"
                                                                name="driverEmail"
                                                                value={
                                                                    agriMachine.driverEmail
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriMachine(
                                                                        {
                                                                            ...agriMachine,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        {/*  */}
                                                        <div className="row">
                                                            <div className="col-md-12 mt-3 d-flex">
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                        marginRight:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    Upload
                                                                    Driver
                                                                    Photo:
                                                                </InputLabel>
                                                                <label htmlFor="contained-button-file">
                                                                    <Button
                                                                        variant="contained"
                                                                        component="span"
                                                                        color="success"
                                                                        size="small"
                                                                        style={
                                                                            uploadBtnSm
                                                                        }
                                                                    >
                                                                        <Input
                                                                            accept="image/"
                                                                            id="contained-button-file"
                                                                            multiple="false"
                                                                            type="file"
                                                                            onChange={
                                                                                handleUploadDP
                                                                            }
                                                                        />
                                                                        Upload
                                                                    </Button>
                                                                </label>
                                                            </div>
                                                            <div className="col-md-3 mt-3">
                                                                <div
                                                                    class="form-group row widget-3"
                                                                    style={
                                                                        sectionDiv
                                                                    }
                                                                >
                                                                    <section
                                                                        className="container"
                                                                        style={
                                                                            driverDP
                                                                        }
                                                                    >
                                                                        {driverImg ===
                                                                        null ? (
                                                                            <p align="center">
                                                                                Upload
                                                                                Image
                                                                            </p>
                                                                        ) : (
                                                                            <aside
                                                                                style={
                                                                                    thumbsContainerState
                                                                                }
                                                                            >
                                                                                <div
                                                                                    style={
                                                                                        thumb
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        style={
                                                                                            thumbInner
                                                                                        }
                                                                                    >
                                                                                        <img
                                                                                            src={
                                                                                                driverImg
                                                                                            }
                                                                                            style={
                                                                                                img
                                                                                            }
                                                                                            alt="driverPhoto"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </aside>
                                                                        )}
                                                                    </section>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* Driver Photo*/}
                                                        <div className="row">
                                                            <div className="col-md-12 mt-3 d-flex">
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                        marginRight:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    Upload
                                                                    Driving
                                                                    License
                                                                    Photo:
                                                                </InputLabel>
                                                                {/* <Button variant="contained" color="success" size="small" style={uploadBtnSm} onClick={handleUploadDL}>
                              
                              Upload
                            </Button> */}
                                                                <label htmlFor="contained-button">
                                                                    <Button
                                                                        variant="contained"
                                                                        component="span"
                                                                        color="success"
                                                                        size="small"
                                                                        style={
                                                                            uploadBtnSm
                                                                        }
                                                                    >
                                                                        <Input
                                                                            accept="image/"
                                                                            id="contained-button"
                                                                            multiple="false"
                                                                            type="file"
                                                                            onChange={
                                                                                handleUploadDLP
                                                                            }
                                                                        />
                                                                        Upload
                                                                    </Button>
                                                                </label>
                                                            </div>
                                                            <div className="col-md-3 mt-3">
                                                                {console.log(
                                                                    'driverLicenseImg',
                                                                    driverLicenseImg
                                                                )}
                                                                <div
                                                                    class="form-group row widget-3"
                                                                    style={
                                                                        sectionDiv
                                                                    }
                                                                >
                                                                    <section
                                                                        className="container"
                                                                        style={
                                                                            driverDP
                                                                        }
                                                                    >
                                                                        {driverLicenseImg ===
                                                                        null ? (
                                                                            <p align="center">
                                                                                Upload
                                                                                Image
                                                                            </p>
                                                                        ) : (
                                                                            <aside
                                                                                style={
                                                                                    thumbsContainerState
                                                                                }
                                                                            >
                                                                                <div
                                                                                    style={
                                                                                        thumb
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        style={
                                                                                            thumbInner
                                                                                        }
                                                                                    >
                                                                                        <img
                                                                                            src={
                                                                                                driverLicenseImg
                                                                                            }
                                                                                            style={
                                                                                                img
                                                                                            }
                                                                                            alt="driver Licence"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </aside>
                                                                        )}
                                                                    </section>
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-md-3 mt-3">
                            <div class="form-group row widget-3" style={sectionDiv}>
                              <DriverLicenseUpload />
                            </div>
                          </div> */}
                                                            <div className="col-md-3 mt-3">
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                        marginTop:
                                                                            '90px',
                                                                    }}
                                                                >
                                                                    Driving
                                                                    Licence
                                                                    Number
                                                                </InputLabel>
                                                                <CssTextField
                                                                    fullWidth
                                                                    id="driverLicenceNumber"
                                                                    label=""
                                                                    variant="standard"
                                                                    className="mt-0"
                                                                    name="driverLicenceNumber"
                                                                    value={
                                                                        agriMachine.driverLicenceNumber
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setAgriMachine(
                                                                            {
                                                                                ...agriMachine,
                                                                                [e
                                                                                    .target
                                                                                    .name]:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            }
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* PAN Upload */}
                                                        <div className="row">
                                                            <div className="col-md-12 mt-3 d-flex">
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                        marginRight:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    Upload PAN
                                                                    Card Photo:
                                                                </InputLabel>
                                                                {/* <Button variant="contained" color="success" size="small" style={uploadBtnSm} onClick={handleUploadPAN}>
                              Upload
                            </Button> */}
                                                                <label htmlFor="contained-button-PAN">
                                                                    <Button
                                                                        variant="contained"
                                                                        component="span"
                                                                        color="success"
                                                                        size="small"
                                                                        style={
                                                                            uploadBtnSm
                                                                        }
                                                                    >
                                                                        <Input
                                                                            accept="image/"
                                                                            id="contained-button-PAN"
                                                                            multiple="false"
                                                                            type="file"
                                                                            onChange={
                                                                                handleUploadPAN
                                                                            }
                                                                        />
                                                                        Upload
                                                                    </Button>
                                                                </label>
                                                            </div>
                                                            <div className="col-md-3 mt-3">
                                                                {console.log(
                                                                    'driverLicenseImg',
                                                                    PANImg
                                                                )}
                                                                <div
                                                                    class="form-group row widget-3"
                                                                    style={
                                                                        sectionDiv
                                                                    }
                                                                >
                                                                    <section
                                                                        className="container"
                                                                        style={
                                                                            driverDP
                                                                        }
                                                                    >
                                                                        {PANImg ===
                                                                        null ? (
                                                                            <p align="center">
                                                                                Upload
                                                                                Image
                                                                            </p>
                                                                        ) : (
                                                                            <aside
                                                                                style={
                                                                                    thumbsContainerState
                                                                                }
                                                                            >
                                                                                <div
                                                                                    style={
                                                                                        thumb
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        style={
                                                                                            thumbInner
                                                                                        }
                                                                                    >
                                                                                        <img
                                                                                            src={
                                                                                                PANImg
                                                                                            }
                                                                                            style={
                                                                                                img
                                                                                            }
                                                                                            alt="driver Licence"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </aside>
                                                                        )}
                                                                    </section>
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-md-3 mt-3">
                            <div class="form-group row widget-3" style={sectionDiv}>
                              <PanUpload />
                            </div>
                          </div> */}
                                                            <div className="col-md-3 mt-3">
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                        marginTop:
                                                                            '90px',
                                                                    }}
                                                                >
                                                                    PAN Number
                                                                </InputLabel>
                                                                <CssTextField
                                                                    fullWidth
                                                                    id="driverPancardNumber"
                                                                    variant="standard"
                                                                    className="mt-0"
                                                                    name="driverPancardNumber"
                                                                    value={
                                                                        agriMachine.driverPancardNumber
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setAgriMachine(
                                                                            {
                                                                                ...agriMachine,
                                                                                [e
                                                                                    .target
                                                                                    .name]:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            }
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        {/*Aadhaar Upload */}
                                                        <div className="row">
                                                            <div className="col-md-12 mt-3 d-flex">
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                        marginRight:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    Upload
                                                                    Aadhaar Card
                                                                    Photo:
                                                                </InputLabel>
                                                                {/* <Button variant="contained" color="success" size="small" style={uploadBtnSm} onClick={handleUploadAD}>
                              Upload
                            </Button> */}
                                                                <label htmlFor="contained-button-AADHAR">
                                                                    <Button
                                                                        variant="contained"
                                                                        component="span"
                                                                        color="success"
                                                                        size="small"
                                                                        style={
                                                                            uploadBtnSm
                                                                        }
                                                                    >
                                                                        <Input
                                                                            accept="image/"
                                                                            id="contained-button-AADHAR"
                                                                            multiple="false"
                                                                            type="file"
                                                                            onChange={
                                                                                handleUploadAADHAR
                                                                            }
                                                                        />
                                                                        Upload
                                                                    </Button>
                                                                </label>
                                                            </div>
                                                            <div className="col-md-3 mt-3">
                                                                {console.log(
                                                                    'AAdharImg',
                                                                    AAdharImg
                                                                )}
                                                                <div
                                                                    class="form-group row widget-3"
                                                                    style={
                                                                        sectionDiv
                                                                    }
                                                                >
                                                                    <section
                                                                        className="container"
                                                                        style={
                                                                            driverDP
                                                                        }
                                                                    >
                                                                        {AAdharImg ===
                                                                        null ? (
                                                                            <p align="center">
                                                                                Upload
                                                                                Image
                                                                            </p>
                                                                        ) : (
                                                                            <aside
                                                                                style={
                                                                                    thumbsContainerState
                                                                                }
                                                                            >
                                                                                <div
                                                                                    style={
                                                                                        thumb
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        style={
                                                                                            thumbInner
                                                                                        }
                                                                                    >
                                                                                        <img
                                                                                            src={
                                                                                                AAdharImg
                                                                                            }
                                                                                            style={
                                                                                                img
                                                                                            }
                                                                                            alt="driver Licence"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </aside>
                                                                        )}
                                                                    </section>
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-md-3 mt-3">
                            <div class="form-group row widget-3" style={sectionDiv}>
                              <AadhaarUpload />
                            </div>
                          </div> */}
                                                        </div>
                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                            <button
                                                                className="w-16 btn btn-secondary"
                                                                style={{
                                                                    marginRight:
                                                                        '10px',
                                                                }}
                                                                onClick={(e) =>
                                                                    setTab(4)
                                                                }
                                                            >
                                                                Previous
                                                            </button>
                                                            <button
                                                                onClick={(e) =>
                                                                    onSubmitDriverDetails()
                                                                }
                                                                class=" w-16 btn btn-primary"
                                                            >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                                // </form>
                                            }
                                        </div>

                                        {/* Commission Details */}
                                        {/* <div class="tab-pane show active" id="bottom-justified-tab7">
                  {tab === 6 &&
                    <form onSubmit={() => setTab(7)}>
                      <div class="row">
                        <div class="col-md-4" style={{marginTop:'2%'}}>
                          <FormControl variant="standard" fullWidth className="mt-3">
                            

                            <Selects style={{ width: "270px", height: "40px" }}
                            onChange={(e) => setAgriMachine({ ...agriMachine, ['unit']: e.value })}
                                // onChange={(e) => setInputs({ ...inputs, ['unit']: e.value })}
                                options={CommisionUnit}
                                placeholder={'Select Unit'}
                              //  value={inputBooking.firm}
                              />
                          </FormControl>
                        </div>
                        <div class="col-md-4" style={{marginTop:'3.5%'}}>
                        <Selects style={{ width: "270px", height: "40px" }}
                        //  value={agriMachine.commissionAmount}
                         onChange={(e) => setAgriMachine({ ...agriMachine, ['commissionAmount']: e.value })}
                                // onChange={(e) => setInputs({ ...inputs, ['commissionAmount']: e.value })}
                                options={CommisionAmount}
                                placeholder={'Select commissionAmount'}
                              //  value={inputBooking.firm}
                              />
                         
                        </div>
                        <div class="col-md-4" style={{marginTop:'2%'}}>
                          <FormControl variant="standard" fullWidth className="mt-3">
                           
                             <Selects style={{ width: "270px", height: "40px" }}
                              onChange={(e) => setAgriMachine({ ...agriMachine, ['commissionCategory']: e.value })}
                                // onChange={(e) => setInputs({ ...inputs, ['category']: e.value })}
                                options={commisiontype}
                                placeholder={'Select  commision type'}
                              //  value={inputBooking.firm}
                              />
                          </FormControl>
                        </div>
                        <div class="col-md-12 text-end mt-2 mb-2">
                          <button className="w-10 btn btn-secondary" style={{ marginRight: '10px' }} onClick={e => setTab(5)}>Previous</button>
                          <button type="submit" class=" w-10 btn btn-primary">Next</button>
                        </div>
                      </div>
                    </form>
                  }
                </div> */}

                                        {/* Bank Acc Info  */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab3"
                                        >
                                            {
                                                tab === 7 && (
                                                    // <form action="#" onSubmit={onSubmitAgriMachine}>
                                                    <div class="row">
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="accNum"
                                                                label="Bank Acc"
                                                                variant="standard"
                                                                type="number"
                                                                name="bankAccountNumber"
                                                                value={
                                                                    agriMachine.bankAccountNumber
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriMachine(
                                                                        {
                                                                            ...agriMachine,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="accName"
                                                                label="Account Name"
                                                                variant="standard"
                                                                name="accountName"
                                                                value={
                                                                    agriMachine.accountName
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriMachine(
                                                                        {
                                                                            ...agriMachine,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="ifscCode"
                                                                label="IFSC Code"
                                                                variant="standard"
                                                                name="ifscCode"
                                                                value={
                                                                    agriMachine.ifscCode
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriMachine(
                                                                        {
                                                                            ...agriMachine,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="bankName"
                                                                label="Bank Name"
                                                                variant="standard"
                                                                pattern="[a-zA-Z]*"
                                                                type="text"
                                                                // id="txtNumeric"
                                                                name="bankName"
                                                                value={
                                                                    agriMachine.bankName
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriMachine(
                                                                        {
                                                                            ...agriMachine,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="branchName"
                                                                label="Branch Name"
                                                                variant="standard"
                                                                name="bankBranchName"
                                                                value={
                                                                    agriMachine.bankBranchName
                                                                }
                                                                onChange={(e) =>
                                                                    setAgriMachine(
                                                                        {
                                                                            ...agriMachine,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                            <button
                                                                className="w-16 btn btn-secondary"
                                                                style={{
                                                                    marginRight:
                                                                        '10px',
                                                                }}
                                                                onClick={(e) =>
                                                                    setTab(3)
                                                                }
                                                            >
                                                                Previous
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={(e) =>
                                                                    onSubmitAgriMachine()
                                                                }
                                                                class=" w-16 btn btn-primary"
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                                // </form>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card card-table">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <div
                                            id="DataTables_Table_0_wrapper"
                                            class="dataTables_wrapper dt-bootstrap4 no-footer"
                                        >
                                            <div class="row">
                                                <div class="col-sm-12 col-md-6">
                                                    <h3 className="mt-3">
                                                        All Agri Machine
                                                    </h3>
                                                    <div
                                                        class="dataTables_length"
                                                        id="DataTables_Table_0_length"
                                                    >
                                                        <hr />
                                                        <label>
                                                            Show
                                                            <select
                                                                name="DataTables_Table_0_length"
                                                                aria-controls="DataTables_Table_0"
                                                                class="custom-select custom-select-sm form-control form-control-sm"
                                                            >
                                                                <option value="10">
                                                                    10
                                                                </option>
                                                                <option value="25">
                                                                    25
                                                                </option>
                                                                <option value="50">
                                                                    50
                                                                </option>
                                                                <option value="100">
                                                                    100
                                                                </option>
                                                            </select>
                                                            entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-3 position_right">
                                                    <div class="form-group">
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="Search"
                                                            onChange={(e) =>
                                                                handleMobilesearch(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <AgriMachineTable
                                                    machines={agriMachineList}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
export default AgriMachine
