import React, { useEffect, useState } from 'react'
import axios from 'axios'
import InvoiceTable from './InvoiceTable'
import ReactPaginate from 'react-paginate'
import DateRangePicker from 'react-bootstrap-daterangepicker'

const InvoiceTab = () => {
    const [invoices, setInvoices] = useState([])
    const [currentPage, setCurrentPage] = React.useState(0)
    const [count, setCount] = React.useState(0)
    const [from, setFrom] = useState(Date.parse(new Date('01/01/1900')))
    const [to, setTo] = useState(Date.parse(new Date('01/01/1900')))
    useEffect(() => {
        getInvoices(1)
    }, [to, from,])

    const getInvoices = async (page) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-invoices?skip=${page}&limit=100&from=${from}&to=${to}`,
            config
        )
        setCount(data.count)

        setInvoices(
            data.invoices.slice(1).map((invoice) => {
                if (invoice.bookingData?.type != undefined)
                    return { ...invoice?.bookingData, ...invoice.userData }
                else if (invoice.bookingData?.machine)
                    return {
                        ...invoice?.bookingData,
                        ...invoice?.userData,
                        type: 'machine',
                    }
                else if (invoice?.bookingData?.vehicle)
                    return {
                        ...invoice?.bookingData,
                        ...invoice.userData,
                        type: 'vehicle',
                    }
            })
        )
    }
    const getInvoicess = async (page) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-invoices?skip=${page}&limit=100&from=${from}&to=${to}`,
            config
        )
        setCount(data.count)

        setInvoices(
            data.invoices.map((invoice) => {
                if (invoice.bookingData?.type != undefined)
                    return { ...invoice?.bookingData, ...invoice.userData }
                else if (invoice.bookingData?.machine)
                    return {
                        ...invoice?.bookingData,
                        ...invoice?.userData,
                        type: 'machine',
                    }
                else if (invoice?.bookingData?.vehicle)
                    return {
                        ...invoice?.bookingData,
                        ...invoice.userData,
                        type: 'vehicle',
                    }
            })
        )
    }
    const onPageChange = (page) => {
        setCurrentPage(page.selected)

        console.log('ran', page)
        getInvoicess(page.selected+1)
    }
    const handleCallback = (start, end, label) => {
        const d1 = Date.parse(start._d)
        const d2 = Date.parse(end._d)
        setFrom(d1)
        setTo(d2)
    }
    return (
        <div class="content container-fluid">
            <div class="page-header">
                <div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div class="card card-table p-10-25">
                                <div class="card-header">
                                    <h3 class="card-title">Invoice</h3>
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                <DateRangePicker
                    initialSettings={{
                        startDate: new Date().getDate(),
                        endDate: new Date().getDate(),
                    }}
                    onCallback={handleCallback}
                >
                    <input className="date-filter" />
                </DateRangePicker>
            </div>
                            <InvoiceTable invoices={invoices} />
                        </div>
                    </div>
                    <div class="row">
                                        <ReactPaginate
                                            previousLabel={''}
                                            nextLabel={''}
                                            forcePage={currentPage}
                                            onPageChange={(page) =>
                                                onPageChange(page)
                                            }
                                            pageCount={count/100}
                                            breakLabel={'...'}
                                            pageRangeDisplayed={10}
                                            marginPagesDisplayed={10}
                                            activeClassName="active"
                                            pageClassName="page-item"
                                            breakClassName="page-item"
                                            nextLinkClassName="page-link"
                                            pageLinkClassName="page-link"
                                            breakLinkClassName="page-link"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item next-item"
                                            previousClassName="page-item prev-item"
                                            containerClassName={
                                                'pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1'
                                            }
                                        />
                                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvoiceTab
