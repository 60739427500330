import React from 'react'
import { CssTextField } from '../Styles'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

function Cities() {
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Cities</h5>
                    <div class="tab_add_new_btn">
                        <a href="#" id="openCategory">
                            Add
                        </a>
                    </div>
                </div>

                {/* Add City */}
                <div id="category" class="card filter-card mt-3">
                    <form class="card-body pb-0" action="#">
                        <div class="row">
                            <h5 class="card-title">Create City</h5>
                            <div class="col-md-4 select2-container">
                                <div class="form-group" data-select2-id="11">
                                    <CssTextField
                                        required
                                        fullWidth
                                        id="cityName"
                                        label="City Name"
                                        variant="standard"
                                    />
                                </div>
                            </div>
                            <div class="col-sm-2 col-md-2">
                                <div class="form-group">
                                    <button
                                        type="submit"
                                        class="btn btn-primary mt-26"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {/* Create Geofence */}
                <div id="subCategory" class="card filter-card mt-3">
                    <form class="card-body pb-0" action="#">
                        <div class="row">
                            <h5 class="card-title">Create Geofence</h5>
                            <div class="col-md-4 select2-container">
                                <div class="form-group" data-select2-id="11">
                                    <CssTextField
                                        required
                                        fullWidth
                                        id="GeoTittle"
                                        label="Title"
                                        variant="standard"
                                        className="mt-3"
                                    />
                                </div>
                            </div>
                            <div class="col-md-4 select2-container">
                                <div class="form-group" data-select2-id="11">
                                    <FormControl
                                        variant="standard"
                                        fullWidth
                                        className="mt-3"
                                    >
                                        <InputLabel
                                            id="demo-simple-select-standard-label"
                                            style={{ color: 'black' }}
                                        >
                                            City
                                        </InputLabel>
                                        <Select
                                            required
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            //  value={age}
                                            //  onChange={handleChange}
                                            color="success"
                                            style={{ padding: '4px 0 0px' }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={10}>
                                                Chennai
                                            </MenuItem>
                                            <MenuItem value={20}>
                                                Kerala
                                            </MenuItem>
                                            <MenuItem value={30}>
                                                Hyderabad
                                            </MenuItem>
                                            <MenuItem value={30}>
                                                Andhra Pradesh
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div class="col-sm-2 col-md-2">
                                <div class="form-group">
                                    <button
                                        type="submit"
                                        class="btn btn-primary mt-26"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="card-body card-body_1">
                    <form>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <div class="card bg-white">
                                    <div class="card-body card-body_1">
                                        <ul class="nav nav-tabs nav-tabs-bottom nav_add_bt">
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#bottom-tab1"
                                                    data-bs-toggle="tab"
                                                >
                                                    MASTERCART-GDK (1 Geofence)
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="nav-item tab_add_new">
                                            <a href="#" id="openSubCategory">
                                                <span class="material-icons-outlined">
                                                    add
                                                </span>
                                            </a>
                                        </div>
                                        <div class="tab-content">
                                            <div
                                                class="tab-pane show active"
                                                id="bottom-tab1"
                                            >
                                                <div class="row">
                                                    <div class="col-lg-4">
                                                        <div class="card card-body bg-gray">
                                                            <h5>
                                                                GODAVARIKHANI
                                                            </h5>
                                                            <div class="status-options">
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        edit
                                                                    </span>
                                                                </a>
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        delete
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body card-body_1">
                                        <ul class="nav nav-tabs nav-tabs-bottom nav_add_bt">
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#bottom-tab2"
                                                    data-bs-toggle="tab"
                                                >
                                                    MASTERCART-BLR (1 Geofence)
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="nav-item tab_add_new">
                                            <a href="#">
                                                <span class="material-icons-outlined">
                                                    add
                                                </span>
                                            </a>
                                        </div>
                                        <div class="tab-content">
                                            <div
                                                class="tab-pane show active "
                                                id="bottom-tab2"
                                            >
                                                <div class="row">
                                                    <div class="col-lg-4">
                                                        <div class="card card-body bg-gray">
                                                            <h5>
                                                                MASTERCART-BLR
                                                            </h5>
                                                            <div class="status-options">
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        edit
                                                                    </span>
                                                                </a>
                                                                <a href="#">
                                                                    <span class="material-icons-outlined">
                                                                        delete
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body card-body_1">
                                        <ul class="nav nav-tabs nav-tabs-bottom nav_add_bt">
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#bottom-tab2"
                                                    data-bs-toggle="tab"
                                                >
                                                    Kerala (0 Geofence)
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="nav-item tab_add_new">
                                            <a href="#">
                                                <span class="material-icons-outlined">
                                                    add
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="noRecords">
                                    <img
                                        src="../assets/images/norecord_empty.svg"
                                        alt=""
                                    />
                                    <h5>No Records</h5>
                                    <p>No data found for this search</p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default Cities
