import React, { useState } from 'react'
import { CssTextField } from '../Styles'
import IconButton from '@mui/material/IconButton'
import RoomIcon from '@mui/icons-material/Room'
import SendIcon from '@mui/icons-material/Send'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService'
import FormControl from '@mui/material/FormControl'
// import Select from '@mui/material/Select';
import { thumb, img, thumbInner } from '../Styles'
import './Styles.css'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { OpenInFullSharp } from '@mui/icons-material'
import Button from '@mui/material/Button'
import Select from 'react-select'

const TradersBD = () => {
    const [opens, setOpens] = React.useState(false)

    const [tab, setTab] = useState(1)
    const [editProducts, setEditProducts] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleclickopens = () => {
        console.log('truepopup')
        setOpens(true)
    }

    const handlecloses = () => {
        setOpens(false)
        handleClose()
    }
    const bookingmodeoption = [
        { value: 'DELIVERY', label: 'DELIVERY' },
        { value: 'PICKUP', label: 'PICKUP' },
    ]
    const [activeColor, setActiveColor] = useState('')
    const [activeColor1, setActiveColor1] = useState('')
    const [activeColor2, setActiveColor2] = useState('')
    const [activeColor3, setActiveColor3] = useState('')
    const handleActive = () => {
        console.log('handleActive')

        if (activeColor == '') {
            setActiveColor('green')
        } else {
            setActiveColor('')
        }
    }

    const handleActive1 = () => {
        console.log('handleActive')

        if (activeColor1 == '') {
            setActiveColor1('green')
        } else {
            setActiveColor1('')
        }
    }

    const handleActive2 = () => {
        console.log('handleActive')

        if (activeColor2 == '') {
            setActiveColor2('green')
        } else {
            setActiveColor2('')
        }
    }

    const handleActive3 = () => {
        console.log('handleActive')

        if (activeColor3 == '') {
            setActiveColor3('green')
        } else {
            setActiveColor3('')
        }
    }

    return (
        <div class="content container-fluid">
            <div class="page-header">
                <div class="row align-items-center">
                    <div class="col">
                        <h3 class="page-title">#006</h3>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a href="/Home" style={{ color: 'green' }}>
                                    <u>View Invoice</u>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div
                                class="card card-table cardMain"
                                style={{ overflow: 'hidden' }}
                            >
                                <div class="tab-content p-0">
                                    <div action="#">
                                        <ul className="nav nav-tabs nav-tabs-bottom nav-justified navs">
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab === 1
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab1"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(1)}
                                                >
                                                    Pending
                                                </a>
                                                <span
                                                    className={
                                                        tab === 1
                                                            ? 'bread active'
                                                            : 'bread'
                                                    }
                                                    style={{
                                                        marginLeft: '16%',
                                                    }}
                                                ></span>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab === 2
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab2"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(2)}
                                                >
                                                    Assigned
                                                </a>
                                                <span
                                                    className={
                                                        tab === 2
                                                            ? 'bread active'
                                                            : 'bread'
                                                    }
                                                    style={{
                                                        marginLeft: '16%',
                                                    }}
                                                ></span>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab === 3
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab3"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(3)}
                                                >
                                                    Accepted
                                                </a>
                                                <span
                                                    className={
                                                        tab === 3
                                                            ? 'bread active'
                                                            : 'bread'
                                                    }
                                                    style={{
                                                        marginLeft: '16%',
                                                    }}
                                                ></span>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab === 4
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab4"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(4)}
                                                >
                                                    Buy
                                                </a>
                                                <span
                                                    className={
                                                        tab === 4
                                                            ? 'bread active'
                                                            : 'bread'
                                                    }
                                                    style={{
                                                        marginLeft: '16%',
                                                    }}
                                                ></span>
                                            </li>
                                            {/* <li class="nav-item">
                                                <a class={tab === 5 ? "nav-link active" : "nav-link"} href="#bottom-justified-tab5" data-bs-toggle="tab" onClick={(e) => setTab(5)}>Ready</a>
                                                <span className={tab === 5 ? "bread active" : "bread"} style={{ marginLeft: '16%' }}></span>
                                            </li> */}
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab === 5
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab6"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(5)}
                                                >
                                                    Completed
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xl-8 col-md-8">
                                    <div class="card">
                                        <div class="card-body p_7">
                                            <form>
                                                <div class="row form-group">
                                                    <div class="col-md-12">
                                                        {/* Table     */}
                                                        {!editProducts ? (
                                                            <>
                                                                <div class="card-body card-body_1">
                                                                    <h5 class="card-title d-flex justify-content-between store_edit">
                                                                        <span></span>
                                                                        <button
                                                                            href="#"
                                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                                            style={{
                                                                                padding:
                                                                                    '0rem 0.75rem',
                                                                            }}
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.preventDefault()
                                                                                setEditProducts(
                                                                                    true
                                                                                )
                                                                            }}
                                                                        >
                                                                            <span>
                                                                                <u>
                                                                                    Edit
                                                                                    Items
                                                                                </u>
                                                                            </span>
                                                                        </button>
                                                                    </h5>
                                                                    <ul class="list-unstyled mb-0 store_list">
                                                                        <div className="row">
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Crop
                                                                                        Type
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-8 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Crop
                                                                                        Name
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Unit
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Quantity
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Price
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Date
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Time
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        No
                                                                                        Of
                                                                                        Days
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        ....{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Description
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        ....{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        {' '}
                                                                                        Crop
                                                                                        Images
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                        </div>
                                                                    </ul>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div class="card-body card-body_1">
                                                                    <h5 class="card-title d-flex justify-content-between store_edit">
                                                                        <ul class="list-unstyled mb-0 store_list">
                                                                            <li class="pt-2 pb-0">
                                                                                <span class="text-dark">
                                                                                    Update
                                                                                    Info
                                                                                </span>
                                                                            </li>
                                                                        </ul>
                                                                        <button
                                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                                            style={{
                                                                                padding:
                                                                                    '0rem 0.75rem',
                                                                            }}
                                                                        >
                                                                            <span
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    e.preventDefault()
                                                                                    setEditProducts(
                                                                                        false
                                                                                    )
                                                                                }}
                                                                            >
                                                                                Cancel
                                                                            </span>
                                                                        </button>
                                                                    </h5>
                                                                    <ul class="list-unstyled mb-0 store_list">
                                                                        <div className="row">
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Crop
                                                                                        Type
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        <FormControl
                                                                                            variant="standard"
                                                                                            fullWidth
                                                                                        >
                                                                                            <Select
                                                                                                labelId="demo-simple-select-standard-label"
                                                                                                id="demo-simple-select-standard"
                                                                                                value={
                                                                                                    10
                                                                                                }
                                                                                                //  onChange={handleChange}
                                                                                                color="success"
                                                                                            >
                                                                                                <MenuItem value="">
                                                                                                    <em>
                                                                                                        None
                                                                                                    </em>
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                    value={
                                                                                                        10
                                                                                                    }
                                                                                                >
                                                                                                    Acre
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                    value={
                                                                                                        11
                                                                                                    }
                                                                                                >
                                                                                                    Guntha
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                    value={
                                                                                                        12
                                                                                                    }
                                                                                                >
                                                                                                    Kantha
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                    value={
                                                                                                        13
                                                                                                    }
                                                                                                >
                                                                                                    Hectra
                                                                                                </MenuItem>
                                                                                            </Select>
                                                                                        </FormControl>
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-1 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Crop
                                                                                        Name
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        <CssTextField
                                                                                            fullWidth
                                                                                            value="Perungalathur"
                                                                                            variant="standard"
                                                                                            name="totalLand"
                                                                                            className="mt-0"
                                                                                            //  value={farmer.totalLand}
                                                                                            //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                                                                        />
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark"></small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Unit
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        <FormControl
                                                                                            variant="standard"
                                                                                            fullWidth
                                                                                        >
                                                                                            <Select
                                                                                                labelId="demo-simple-select-standard-label"
                                                                                                id="demo-simple-select-standard"
                                                                                                value={
                                                                                                    10
                                                                                                }
                                                                                                //  onChange={handleChange}
                                                                                                color="success"
                                                                                            >
                                                                                                <MenuItem value="">
                                                                                                    <em>
                                                                                                        None
                                                                                                    </em>
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                    value={
                                                                                                        10
                                                                                                    }
                                                                                                >
                                                                                                    Acre
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                    value={
                                                                                                        11
                                                                                                    }
                                                                                                >
                                                                                                    Guntha
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                    value={
                                                                                                        12
                                                                                                    }
                                                                                                >
                                                                                                    Kantha
                                                                                                </MenuItem>
                                                                                                <MenuItem
                                                                                                    value={
                                                                                                        13
                                                                                                    }
                                                                                                >
                                                                                                    Hectra
                                                                                                </MenuItem>
                                                                                            </Select>
                                                                                        </FormControl>
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-1 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Quantity
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        <CssTextField
                                                                                            type="number"
                                                                                            fullWidth
                                                                                            variant="standard"
                                                                                            name="totalLand"
                                                                                            className="mt-0"
                                                                                            //  value={farmer.totalLand}
                                                                                            //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                                                                        />
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Price
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        <CssTextField
                                                                                            type="number"
                                                                                            fullWidth
                                                                                            variant="standard"
                                                                                            name="totalLand"
                                                                                            className="mt-0"
                                                                                            //  value={farmer.totalLand}
                                                                                            //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                                                                        />
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Date
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        <CssTextField
                                                                                            fullWidth
                                                                                            type="date"
                                                                                            variant="standard"
                                                                                            name="totalLand"
                                                                                            className="mt-0"
                                                                                            //  value={farmer.totalLand}
                                                                                            //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                                                                        />
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Time
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        <CssTextField
                                                                                            fullWidth
                                                                                            type="time"
                                                                                            value=""
                                                                                            label=""
                                                                                            variant="standard"
                                                                                            name="totalLand"
                                                                                            className="mt-0"
                                                                                            //  value={farmer.totalLand}
                                                                                            //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                                                                        />
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        No
                                                                                        Of
                                                                                        Days
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        <CssTextField
                                                                                            type="number"
                                                                                            fullWidth
                                                                                            variant="standard"
                                                                                            name="totalLand"
                                                                                            className="mt-0"
                                                                                            //  value={farmer.totalLand}
                                                                                            //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                                                                        />
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-12 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Description
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        <CssTextField
                                                                                            fullWidth
                                                                                            type="text"
                                                                                            variant="standard"
                                                                                            name="totalLand"
                                                                                            className="mt-0"
                                                                                            //  value={farmer.totalLand}
                                                                                            //  onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                                                                        />
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-12 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        {' '}
                                                                                        Crop
                                                                                        Images
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2"></li>
                                                                            </div>
                                                                            <div className="col-md-12 mb-4">
                                                                                <div className="row">
                                                                                    <div className="col-2">
                                                                                        <div
                                                                                            style={
                                                                                                thumb
                                                                                            }
                                                                                            sx={{
                                                                                                backgroundColor:
                                                                                                    'grey',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                style={
                                                                                                    thumbInner
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        ''
                                                                                                    }
                                                                                                    style={
                                                                                                        img
                                                                                                    }
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <div
                                                                                            style={
                                                                                                thumb
                                                                                            }
                                                                                            sx={{
                                                                                                backgroundColor:
                                                                                                    'grey',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                style={
                                                                                                    thumbInner
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        ''
                                                                                                    }
                                                                                                    style={
                                                                                                        img
                                                                                                    }
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <div
                                                                                            style={
                                                                                                thumb
                                                                                            }
                                                                                            sx={{
                                                                                                backgroundColor:
                                                                                                    'grey',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                style={
                                                                                                    thumbInner
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        ''
                                                                                                    }
                                                                                                    style={
                                                                                                        img
                                                                                                    }
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <div
                                                                                            style={
                                                                                                thumb
                                                                                            }
                                                                                            sx={{
                                                                                                backgroundColor:
                                                                                                    'grey',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                style={
                                                                                                    thumbInner
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        ''
                                                                                                    }
                                                                                                    style={
                                                                                                        img
                                                                                                    }
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <div
                                                                                            style={
                                                                                                thumb
                                                                                            }
                                                                                            sx={{
                                                                                                backgroundColor:
                                                                                                    'grey',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                style={
                                                                                                    thumbInner
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        ''
                                                                                                    }
                                                                                                    style={
                                                                                                        img
                                                                                                    }
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ul>
                                                                    <div class="col-md-12 text-end mt-2 mb-2">
                                                                        <button
                                                                            type="submit"
                                                                            class="btn btn-primary"
                                                                        >
                                                                            Submit
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <div class="card">
                                                <div class="card-body p_7">
                                                    <form>
                                                        <div class="row form-group">
                                                            <div class="col-md-12">
                                                                <div class="card-body card-body_1">
                                                                    <h5 class="card-title d-flex justify-content-between store_edit">
                                                                        <span>
                                                                            Assigned
                                                                            Farmer
                                                                            Task
                                                                        </span>
                                                                        <a
                                                                            href="#"
                                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                                        >
                                                                            <span>
                                                                                PENDING
                                                                            </span>
                                                                        </a>
                                                                    </h5>
                                                                    <ul class="list-unstyled mb-0 store_list">
                                                                        <div className="row">
                                                                            <div className="col-md-3 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <CheckCircleIcon
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                            color: activeColor,
                                                                                        }}
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleActive()
                                                                                        }
                                                                                    />
                                                                                    <small className="text-secondary">
                                                                                        Accepted
                                                                                    </small>
                                                                                </li>

                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-3 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <CheckCircleIcon
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                            color: activeColor1,
                                                                                        }}
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleActive1()
                                                                                        }
                                                                                    />
                                                                                    <small className="text-secondary">
                                                                                        Confirm
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-3 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <CheckCircleIcon
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                            color: activeColor2,
                                                                                        }}
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleActive2()
                                                                                        }
                                                                                    />
                                                                                    <small className="text-secondary">
                                                                                        Sell
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-3 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <CheckCircleIcon
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                            color: activeColor3,
                                                                                        }}
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleActive3()
                                                                                        }
                                                                                    />
                                                                                    <small className="text-secondary">
                                                                                        Completed
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                        </div>
                                                                    </ul>
                                                                </div>
                                                                <hr
                                                                    style={{
                                                                        marginTop:
                                                                            '0px',
                                                                        marginBottom:
                                                                            '10px',
                                                                    }}
                                                                />
                                                                <h6>
                                                                    Assigned
                                                                    Details
                                                                </h6>
                                                                <div className="row">
                                                                    <div className="col-md-4"></div>
                                                                </div>
                                                                <a className="store-avatar">
                                                                    <div
                                                                        style={{
                                                                            marginRight:
                                                                                '10px',
                                                                        }}
                                                                        onClick={
                                                                            handleClick
                                                                        }
                                                                    >
                                                                        <AddCircleOutlineIcon />
                                                                    </div>
                                                                    <div
                                                                        class="store_display"
                                                                        style={{
                                                                            display:
                                                                                'flex',
                                                                            flexDirection:
                                                                                'row',
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                width: '200%',
                                                                            }}
                                                                        >
                                                                            <h4>
                                                                                Rajsekar
                                                                            </h4>
                                                                            <span>
                                                                                No
                                                                                driver
                                                                                assigned
                                                                                yet
                                                                            </span>
                                                                        </div>

                                                                        <div
                                                                            style={{
                                                                                marginLeft:
                                                                                    '50px',
                                                                                width: '25%',
                                                                            }}
                                                                        >
                                                                            <h4>
                                                                                Name
                                                                            </h4>
                                                                            <span>
                                                                                murugesh
                                                                            </span>
                                                                        </div>

                                                                        <div
                                                                            style={{
                                                                                marginLeft:
                                                                                    '50px',
                                                                                width: '25%',
                                                                            }}
                                                                        >
                                                                            <h4>
                                                                                MobileNumber
                                                                            </h4>
                                                                            <span>
                                                                                8675191591
                                                                            </span>
                                                                        </div>

                                                                        <div
                                                                            style={{
                                                                                marginLeft:
                                                                                    '50px',
                                                                            }}
                                                                        >
                                                                            <h4>
                                                                                Id
                                                                            </h4>
                                                                            <span>
                                                                                31
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <Menu
                                                                        id="basic-menu"
                                                                        anchorEl={
                                                                            anchorEl
                                                                        }
                                                                        open={
                                                                            open
                                                                        }
                                                                        onClose={
                                                                            handleClose
                                                                        }
                                                                        MenuListProps={{
                                                                            'aria-labelledby':
                                                                                'basic-button',
                                                                        }}
                                                                    >
                                                                        <MenuItem
                                                                            onClick={() =>
                                                                                handleclickopens()
                                                                            }
                                                                        >
                                                                            Assign
                                                                            Manually
                                                                        </MenuItem>
                                                                        <MenuItem>
                                                                            Assign
                                                                            Automatically
                                                                        </MenuItem>
                                                                    </Menu>
                                                                    {opens ==
                                                                    true ? (
                                                                        <Dialog
                                                                            open={
                                                                                opens
                                                                            }
                                                                            onClose={
                                                                                handlecloses
                                                                            }
                                                                        >
                                                                            <DialogTitle>
                                                                                <b>
                                                                                    Assign
                                                                                    Driver
                                                                                </b>
                                                                            </DialogTitle>
                                                                            <DialogContent>
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            'flex',
                                                                                        flexDirection:
                                                                                            'row',
                                                                                        height: '100px',
                                                                                        width: '550px',
                                                                                        marginBottom:
                                                                                            '50px',
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            margin: '2%',
                                                                                            width: '200px',
                                                                                        }}
                                                                                    >
                                                                                        <FormControl
                                                                                            variant="standard"
                                                                                            fullWidth
                                                                                            className="mt-4"
                                                                                        >
                                                                                            <Select
                                                                                                placeholder={
                                                                                                    'Search State'
                                                                                                }
                                                                                                options={
                                                                                                    bookingmodeoption
                                                                                                }
                                                                                            />
                                                                                        </FormControl>
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            margin: '2%',
                                                                                            width: '200px',
                                                                                        }}
                                                                                    >
                                                                                        <FormControl
                                                                                            variant="standard"
                                                                                            fullWidth
                                                                                            className="mt-4"
                                                                                        >
                                                                                            <Select
                                                                                                placeholder={
                                                                                                    'Search District'
                                                                                                }
                                                                                                options={
                                                                                                    bookingmodeoption
                                                                                                }
                                                                                            />
                                                                                        </FormControl>
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            margin: '2%',
                                                                                            width: '200px',
                                                                                        }}
                                                                                    >
                                                                                        <FormControl
                                                                                            variant="standard"
                                                                                            fullWidth
                                                                                            className="mt-4"
                                                                                        >
                                                                                            <Select
                                                                                                placeholder={
                                                                                                    'Search Driver'
                                                                                                }
                                                                                                options={
                                                                                                    bookingmodeoption
                                                                                                }
                                                                                            />
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div
                                                                                        style={{
                                                                                            width: '260px',
                                                                                            height: '70px',
                                                                                            marginBottom:
                                                                                                '50px',
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            class="card card-body bg-gray"
                                                                                            style={{
                                                                                                display:
                                                                                                    'flex',
                                                                                                flexDirection:
                                                                                                    'row',
                                                                                            }}
                                                                                        >
                                                                                            <div>
                                                                                                <h5>
                                                                                                    Verifier
                                                                                                </h5>

                                                                                                <div>
                                                                                                    <a href="#">
                                                                                                        <span>
                                                                                                            1234567890
                                                                                                        </span>
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div
                                                                                                    class="circle"
                                                                                                    style={{
                                                                                                        width: '30px',
                                                                                                        height: '30px',
                                                                                                        padding:
                                                                                                            '5px',
                                                                                                        marginLeft:
                                                                                                            '60px',
                                                                                                        borderRadius:
                                                                                                            '50%',
                                                                                                        background:
                                                                                                            '#fff',
                                                                                                        border: ' 3px solid #000',
                                                                                                        color: '#000',
                                                                                                        textAlign:
                                                                                                            'center',
                                                                                                        font: '14px Arial, sans-serif',
                                                                                                    }}
                                                                                                >
                                                                                                    2
                                                                                                </div>

                                                                                                <div
                                                                                                    style={{
                                                                                                        marginLeft:
                                                                                                            '60px',
                                                                                                    }}
                                                                                                >
                                                                                                    <a href="#">
                                                                                                        <span>
                                                                                                            task
                                                                                                        </span>
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            width: '260px',
                                                                                            height: '70px',
                                                                                            marginBottom:
                                                                                                '50px',
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            class="card card-body bg-gray"
                                                                                            style={{
                                                                                                display:
                                                                                                    'flex',
                                                                                                flexDirection:
                                                                                                    'row',
                                                                                            }}
                                                                                        >
                                                                                            <div>
                                                                                                <h5>
                                                                                                    Verifier
                                                                                                </h5>

                                                                                                <div>
                                                                                                    <a href="#">
                                                                                                        <span>
                                                                                                            1234567890
                                                                                                        </span>
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div
                                                                                                    class="circle"
                                                                                                    style={{
                                                                                                        width: '30px',
                                                                                                        height: '30px',
                                                                                                        padding:
                                                                                                            '5px',
                                                                                                        marginLeft:
                                                                                                            '60px',
                                                                                                        borderRadius:
                                                                                                            '50%',
                                                                                                        background:
                                                                                                            '#fff',
                                                                                                        border: ' 3px solid #000',
                                                                                                        color: '#000',
                                                                                                        textAlign:
                                                                                                            'center',
                                                                                                        font: '14px Arial, sans-serif',
                                                                                                    }}
                                                                                                >
                                                                                                    1
                                                                                                </div>

                                                                                                <div
                                                                                                    style={{
                                                                                                        marginLeft:
                                                                                                            '60px',
                                                                                                    }}
                                                                                                >
                                                                                                    <a href="#">
                                                                                                        <span>
                                                                                                            task
                                                                                                        </span>
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            width: '260px',
                                                                                            height: '70px',
                                                                                            marginBottom:
                                                                                                '50px',
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            class="card card-body bg-gray"
                                                                                            style={{
                                                                                                display:
                                                                                                    'flex',
                                                                                                flexDirection:
                                                                                                    'row',
                                                                                            }}
                                                                                        >
                                                                                            <div>
                                                                                                <h5>
                                                                                                    Verifier
                                                                                                </h5>

                                                                                                <div>
                                                                                                    <a href="#">
                                                                                                        <span>
                                                                                                            1234567890
                                                                                                        </span>
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div
                                                                                                    class="circle"
                                                                                                    style={{
                                                                                                        width: '30px',
                                                                                                        height: '30px',
                                                                                                        padding:
                                                                                                            '5px',
                                                                                                        marginLeft:
                                                                                                            '60px',
                                                                                                        borderRadius:
                                                                                                            '50%',
                                                                                                        background:
                                                                                                            '#fff',
                                                                                                        border: ' 3px solid #000',
                                                                                                        color: '#000',
                                                                                                        textAlign:
                                                                                                            'center',
                                                                                                        font: '14px Arial, sans-serif',
                                                                                                    }}
                                                                                                >
                                                                                                    0
                                                                                                </div>

                                                                                                <div
                                                                                                    style={{
                                                                                                        marginLeft:
                                                                                                            '60px',
                                                                                                    }}
                                                                                                >
                                                                                                    <a href="#">
                                                                                                        <span>
                                                                                                            task
                                                                                                        </span>
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            'flex',
                                                                                        flexDirection:
                                                                                            'row',
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            paddingLeft:
                                                                                                '300px',
                                                                                        }}
                                                                                    >
                                                                                        <Button
                                                                                            style={{
                                                                                                backgroundColor:
                                                                                                    '#60a132',
                                                                                                color: 'white',
                                                                                            }}
                                                                                            onClick={
                                                                                                handlecloses
                                                                                            }
                                                                                        >
                                                                                            Cancel
                                                                                        </Button>
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            marginLeft:
                                                                                                '30px',
                                                                                        }}
                                                                                    >
                                                                                        <Button
                                                                                            style={{
                                                                                                backgroundColor:
                                                                                                    '#60a132',
                                                                                                color: 'white',
                                                                                            }}
                                                                                            onClick={
                                                                                                handlecloses
                                                                                            }
                                                                                        >
                                                                                            Assign
                                                                                            Task
                                                                                        </Button>
                                                                                    </div>
                                                                                </div>
                                                                            </DialogContent>
                                                                        </Dialog>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </a>
                                                                {/* <div class="row mt-3">
                                                                        <div class="col">
                                                                            <div class="timeline-steps aos-init aos-animate" data-aos="fade-up" style={{ flexWrap: 'nowrap' }}>
                                                                                <div class="timeline-step">
                                                                                    <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                                                                                        <RoomIcon />
                                                                                        <p class="h6 mt-1 mb-1">Sri Kridhna Traders</p>
                                                                                        <p class="h6 text-muted mb-0 mb-lg-0"></p>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="timeline-step">
                                                                                    <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2004">
                                                                                        <HomeRepairServiceIcon />
                                                                                        <p class="h6 mt-1 mb-1"></p>
                                                                                        <p class="h6 text-muted mb-0 mb-lg-0">Home</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="timeline-step">
                                                                                    <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2005">
                                                                                        <div class="inner-circle"></div>
                                                                                        <p class="h6 mt-1 mb-1"></p>
                                                                                        <p class="h6 text-muted mb-0 mb-lg-0">Customer</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                            </div>
                                                        </div>
                                                        {/* </div> */}
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Trader Details</span>
                                                <a
                                                    href="#"
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                ></a>
                                            </h5>
                                            <a class="store-avatar">
                                                <div class="staffAvatar-root1">
                                                    R
                                                </div>
                                                <div class="store_display">
                                                    <h4>Rajesh</h4>
                                                    <span>8098081991</span>
                                                </div>
                                            </a>
                                        </div>
                                        <hr
                                            width="85%"
                                            style={{ margin: 'auto' }}
                                        />
                                        <div class="card-body ">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span> Assigned Details</span>
                                                <a
                                                    href="#"
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                ></a>
                                            </h5>
                                            <a class="store-avatar">
                                                <div class="staffAvatar-root1">
                                                    S
                                                </div>
                                                <div class="store_display">
                                                    <h4>Shivakumar</h4>
                                                    <span>8098081991</span>
                                                </div>
                                            </a>
                                        </div>
                                        <hr
                                            width="85%"
                                            style={{ margin: 'auto' }}
                                        />
                                        <div class="card-body ">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Payment Details</span>
                                                <a
                                                    href="#"
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                    style={{
                                                        padding: '0rem 0.75rem',
                                                    }}
                                                >
                                                    <span>
                                                        <u>Receive Payment</u>
                                                    </span>
                                                </a>
                                            </h5>
                                            <p>
                                                Pending Amount -{' '}
                                                <span>Rs. 4000</span>
                                            </p>
                                            <span>
                                                <i>No transaction yet</i>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Notes (0)</span>
                                                <a
                                                    href="#"
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                ></a>
                                            </h5>
                                            <hr style={{ margin: 'auto' }} />
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <CssTextField
                                                        fullWidth
                                                        id="totalLand"
                                                        variant="standard"
                                                        placeholder="Add a note...."
                                                        name="totalLand"
                                                        className="mb-0"
                                                    />
                                                </div>
                                                <div className="col-md-2">
                                                    <IconButton
                                                        color="success"
                                                        aria-label="add an alarm"
                                                        className="mt-3"
                                                    >
                                                        <SendIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Messages (0)</span>
                                                <a
                                                    href="#"
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                ></a>
                                            </h5>
                                            <hr style={{ margin: 'auto' }} />
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <CssTextField
                                                        fullWidth
                                                        id="totalLand"
                                                        variant="standard"
                                                        placeholder="Add a Message...."
                                                        name="totalLand"
                                                        className="mb-0"
                                                    />
                                                </div>
                                                <div className="col-md-2">
                                                    <IconButton
                                                        color="success"
                                                        aria-label="add an alarm"
                                                        className="mt-3"
                                                    >
                                                        <SendIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Booking from</span>
                                                <a
                                                    href="#"
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                ></a>
                                            </h5>
                                            <hr style={{ margin: 'auto' }} />
                                            <div className="row mt-3">
                                                <h6>Farmer</h6>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="card">
                                        <div class="card-body p_7">
                                            <div class="row form-group">
                                                <div class="col-md-12">
                                                    <div class="card-body card-body_1">
                                                        <h5 class="card-title d-flex justify-content-between store_edit">
                                                            <span>Order Summary</span>
                                                        </h5>
                                                        <ul class="list-unstyled mb-0 store_list">
                                                            <li class="pt-2 pb-0">
                                                                <small class="text-dark">Order Type </small>
                                                            </li>
                                                            <li>
                                                                <span>Home Delivery</span>
                                                            </li>
                                                            <li class="pt-2 pb-0">
                                                                <small class="text-dark">Delivery Type </small>
                                                            </li>
                                                            <li>
                                                                <span>Scheduled</span>
                                                            </li>
                                                            <li class="pt-2 pb-0">
                                                                <small class="text-dark">Delivery Time </small>
                                                            </li>
                                                            <li>
                                                                <span>Nov 14, 8:00 AM - Nov 14, 9:00 AM</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TradersBD
