import React, { useState, useEffect } from 'react'
import axios from 'axios'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import { API } from '../../../API/index'
import { CssTextField } from '../../Styles'
import MenuItem from '@mui/material/MenuItem'
import Input from '@mui/material/Input'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import { storage } from '../../../firebase/index'
import TraderTable from './TraderTable'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import { thumbsContainerState, thumb, img, thumbInner } from '../../Styles'
import Button from '@mui/material/Button'
import '../Create.css'

import Selects from 'react-select'
import Swal from 'sweetalert2'
import $ from 'jquery'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const names = ['Seed', 'Fertilizers', 'Pesticides']

const clearInputs = {
    firstName: '',
    lastName: '',
    gender: '',
    mobileNumber: '',
    email: '',
    alternatemobile: '',
    dateOfBirth: '',
    language: '',

    firmName: '',
    gstNo: '',
    panNumber: '',
    adhaarNumber: '',
    typeOfCrops: [],

    state: '',
    district: '',
    subDistrict: '',
    village: '',
    pincode: '',
    fullAddress: '',

    unit: '',
    commissionAmount: '',
    category: '',

    bankAccountNumber: '',
    bankAccountName: '',
    ifscCode: '',
    bankName: '',
    bankBranchName: '',
}

function CreteTraders() {
    const [tab, setTab] = useState(1)
    const [states, setStates] = useState([])
    const [cropTypes, setCropTypes] = React.useState([])
    let licenseTypesArr = cropTypes.map((type) => ({
        name: type,
        licenseNumber: '',
        expDate: '',
        photo: '',
    }))
    console.log(licenseTypesArr)
    const [licensePhoto, setLicensePhoto] = useState(null)
    const [inputSuppliers, setInputSuppliers] = useState([])
    const [citylist, setcitylist] = useState([])
    const [statelist, setstatelist] = useState([])
    const [CropProduct, setCropProduct] = useState([])

    const [CommisionCat, setCommisionCat] = useState([])
    const [inputs, setInputs] = useState({
        firstName: '',
        lastName: '',
        mobileNumber: '',
        email: '',
        alternatemobile: '',
        dateOfBirth: '',
        language: '',
        firmName: '',
        gstNo: '',
        panNumber: '',
        adhaarNumber: '',
        typeOfCrops: cropTypes,

        state: '',
        district: '',
        subDistrict: '',
        village: '',
        pincode: '',
        fullAddress: '',

        unit: '',
        commissionAmount: '',
        category: '',

        bankAccountNumber: '',
        bankAccountName: '',
        ifscCode: '',
        bankName: '',
        bankBranchName: '',
    })

    useEffect(() => {
        getState()
        getCropProduct()
        getStateList()
        getCommisionCat()
    }, [])
    const getCommisionCat = async () => {
        const { data } = await API.get(
            `/getCommissionCatelog?title=CommissionCatelog&catelogName=InputSupplierCatelog&type=2`
        )
        // console.log("hkjjhj",data)
        setCommisionCat(data?.result)
        console.log('firm search', data)
    }
    const CommisionUnit =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.commissionUnit,
            label: item.commissionUnit,
        }))
    const CommisionAmount =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.commissionAmount,
            label: item.commissionAmount,
        }))
    const commisiontype =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.subCategoryName,
            label: item.subCategoryName,
        }))
    const getStateList = async () => {
        const { data } = await API.get(`/getStateList`)
        // console.log("hkjjhj",data)
        setstatelist(data?.result)
        console.log('firm search', data)
    }
    const statelistoption =
        statelist &&
        statelist.map((item, ind) => ({
            value: item,
            label: item,
        }))
    const statechane = (e) => {
        console.log('hkjjhj', e.value)
        // setInputs({ ...inputs, ['state']: e.value })
        setInputs({ ...inputs, ['state']: e.value })
        // onChange={(e) => setInputs({ ...inputs, ['state']: e.target.value })}
        getCityList(e.value)
    }
    const getCityList = async (val) => {
        const { data } = await API.get(`/getCityList?stateName=` + val)
        // console.log("hkjjhj",data)
        setcitylist(data?.result)
        console.log('firm search', data)
    }
    const citylistoption =
        citylist &&
        citylist.map((item, ind) => ({
            value: item,
            label: item,
        }))
    const getCropProduct = async () => {
        const { data } = await API.get(
            `/getProductCatelogProduct?type=0&title=ProductCatelog&catelogName=CropCatelog`
        )
        // console.log("hkjjhj",data)
        setCropProduct(data?.result)
        console.log('firm search', data)
    }
    const CropProductoption =
        CropProduct &&
        CropProduct.map((item, ind) => ({
            value: item.productName,
            label: item.productName,
        }))
    const getInputSuppliers = async () => {
        const { data } = await API.get(`/getTraders?type=0`)
        setInputSuppliers(data?.result)
    }

    const getState = async () => {
        const { data } = await axios.get(
            'http://65.0.145.198:4080/api/v1/getStateList'
        )
        setStates(data?.result)
        console.log(data?.result)
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event
        setCropTypes(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    const setLicenceInputs = (e, index) => {
        console.log(index)
        let myArr = [...licenseTypesArr]
        myArr[index][e.target.name] = e.target.value
        console.log(licenseTypesArr)
    }

    const handleUploadDP = (e, index) => {
        let myArr = [...licenseTypesArr]
        setLicensePhoto(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `licensePhoto/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                async () => {
                    getDownloadURL(storageRef).then((url) => {
                        //  myArr[index]['name'] = url;
                        // alert(index)
                        // console.log(url);
                    })
                }
            )
        }
    }

    const onSubmitInputs = async (e) => {
        console.log('Traders', { ...inputs, typeOfCrops: cropTypes })
        let value = { ...inputs, typeOfCrops: cropTypes }
        const { data } = await API.post('/traders', value)
        if (data?.success) {
            Swal.fire({
                icon: 'success',
                iconColor: '#28a745',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
            console.log(data)
            setInputs(clearInputs)
            setCropTypes([])
            setTab(1)
            // getInputSuppliers();
        } else {
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data?.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handleMobVal = (e) => {
        setInputs({ ...inputs, [e.target.name]: e.target.value })
        if (e.target.value.length == 10) {
            mobilecheck(e.target.value)
        } else if (e.target.value.length > 10) {
            console.log('e.target.value.length', e.target.value.length)
            setInputs({ ...inputs, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: 'Mobile length should be 10',
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const mobilecheck = async (number) => {
        const { data } = await API.get(`/checkNumber?number=` + number)
        console.log('data', data)
        if (data.success == true) {
            // setFarmer({ ...farmer, [e.target.name]: number })
        } else {
            setInputs({ ...inputs, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data.result,
                showConfirmButton: false,
                timer: 2000,
            })
        }
        // setDetails(data?.result);
    }
    const handleMobilesearch = (e) => {
        console.log('e', e.target.value)
        if (e.target.value.length == 10) {
            mobilechecksearch(e.target.value)
        } else {
            setInputSuppliers([])
        }
    }
    const mobilechecksearch = async (number) => {
        const { data } = await API.get(`/searchTrader?search=` + number)
        console.log('data', data)
        if (data.success == true) {
            let arr = []
            arr.push(data?.result)
            setInputSuppliers(arr)
        } else {
        }
    }
    $(function () {
        $('#txtNumeric').keydown(function (e) {
            if (e.shiftKey || e.ctrlKey || e.altKey) {
                e.preventDefault()
            } else {
                var key = e.keyCode

                if (
                    !(
                        key == 8 ||
                        key == 32 ||
                        key == 46 ||
                        (key >= 35 && key <= 40) ||
                        (key >= 65 && key <= 90)
                    )
                ) {
                    e.preventDefault()
                }
            }
        })
    })

    return (
        <div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card card-table p-10-25">
                        <div class="card-header">
                            <h5 class="card-title">Create Trader</h5>
                        </div>
                        <div class="card-body">
                            <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                                <li class="nav-item">
                                    <a
                                        class={
                                            tab === 1
                                                ? 'nav-link active'
                                                : 'nav-link'
                                        }
                                    >
                                        Personal Info
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a
                                        class={
                                            tab === 2
                                                ? 'nav-link active'
                                                : 'nav-link'
                                        }
                                    >
                                        Firm Details
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a
                                        class={
                                            tab === 3
                                                ? 'nav-link active'
                                                : 'nav-link'
                                        }
                                    >
                                        Contact Details
                                    </a>
                                </li>
                                {/* <li class="nav-item"><a class={tab === 4 ? "nav-link active" : "nav-link"}>Commission Details</a></li> */}
                                <li class="nav-item">
                                    <a
                                        class={
                                            tab === 5
                                                ? 'nav-link active'
                                                : 'nav-link'
                                        }
                                    >
                                        Bank Account Info
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                {/* Personal Info */}
                                <div
                                    class="tab-pane show active"
                                    id="bottom-justified-tab1"
                                >
                                    {tab === 1 && (
                                        <form onSubmit={() => setTab(2)}>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        fullWidth
                                                        id="fName"
                                                        label="First Name"
                                                        variant="standard"
                                                        name="firstName"
                                                        value={inputs.firstName}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        fullWidth
                                                        id="lName"
                                                        label="Last Name"
                                                        variant="standard"
                                                        name="lastName"
                                                        value={inputs.lastName}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        className="mt-3"
                                                    >
                                                        <InputLabel
                                                            id="demo-simple-select-standard-label"
                                                            style={{
                                                                color: 'black',
                                                            }}
                                                        >
                                                            Gender
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="gender"
                                                            label="Gender"
                                                            color="success"
                                                            name="gender"
                                                            value={
                                                                inputs.gender
                                                            }
                                                            onChange={(e) =>
                                                                setInputs({
                                                                    ...inputs,
                                                                    [e.target
                                                                        .name]:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            <MenuItem value="Male">
                                                                Male
                                                            </MenuItem>
                                                            <MenuItem value="Female">
                                                                Female
                                                            </MenuItem>
                                                            <MenuItem value="Others">
                                                                Others
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        fullWidth
                                                        id="mobileNumber"
                                                        type="number"
                                                        label="Mobile Number"
                                                        variant="standard"
                                                        name="mobileNumber"
                                                        value={
                                                            inputs.mobileNumber
                                                        }
                                                        onChange={(e) =>
                                                            handleMobVal(e)
                                                        }
                                                        // onChange={(e) => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        fullWidth
                                                        id="emailId"
                                                        label="Email ID"
                                                        variant="standard"
                                                        name="email"
                                                        value={inputs.email}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        fullWidth
                                                        id="altMobile"
                                                        label="Altenate Mobile"
                                                        type="number"
                                                        variant="standard"
                                                        name="alternatemobile"
                                                        value={
                                                            inputs.alternatemobile
                                                        }
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Date Of Birth
                                                    </InputLabel>
                                                    <CssTextField
                                                        fullWidth
                                                        type="date"
                                                        // id="dob"
                                                        variant="standard"
                                                        name="dateOfBirth"
                                                        className="mt-2"
                                                        value={
                                                            inputs.dateOfBirth
                                                        }
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        className="mt-3"
                                                    >
                                                        <InputLabel
                                                            id="demo-simple-select-standard-label"
                                                            style={{
                                                                color: 'black',
                                                            }}
                                                        >
                                                            Language*
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            label="Language"
                                                            color="success"
                                                            name="language"
                                                            value={
                                                                inputs.language
                                                            }
                                                            onChange={(e) =>
                                                                setInputs({
                                                                    ...inputs,
                                                                    [e.target
                                                                        .name]:
                                                                        e.target
                                                                            .value,
                                                                })
                                                            }
                                                        >
                                                            <MenuItem value="">
                                                                <em>
                                                                    - Select
                                                                    Language -
                                                                </em>
                                                            </MenuItem>
                                                            <MenuItem value="Hindi">
                                                                Hindi
                                                            </MenuItem>
                                                            <MenuItem value="Bengali">
                                                                Bengali
                                                            </MenuItem>
                                                            <MenuItem value="Urdu">
                                                                Urdu
                                                            </MenuItem>
                                                            <MenuItem value="Punjabi">
                                                                Punjabi
                                                            </MenuItem>
                                                            <MenuItem value="Marathi">
                                                                Marathi
                                                            </MenuItem>
                                                            <MenuItem value="Telugu">
                                                                Telugu
                                                            </MenuItem>
                                                            <MenuItem value="Tamil">
                                                                Tamil
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div class="col-md-12 text-end mt-2 mb-2">
                                                    <button
                                                        type="submit"
                                                        class=" w-10 btn btn-primary"
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>

                                {/* Firm Details */}
                                <div
                                    class="tab-pane show active"
                                    id="bottom-justified-tab2"
                                >
                                    {tab === 2 && (
                                        <form
                                            onSubmit={(e) => {
                                                setTab(3)
                                                setInputs({
                                                    ...inputs,
                                                    typeOfLicense:
                                                        licenseTypesArr,
                                                })
                                            }}
                                        >
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        id="firmName"
                                                        label="Firm Name"
                                                        variant="standard"
                                                        name="firmName"
                                                        value={inputs.firmName}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        // required
                                                        id="gstNo"
                                                        label="GST No"
                                                        variant="standard"
                                                        name="gstNo"
                                                        value={inputs.gstNo}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        // required
                                                        id="panNo"
                                                        label="PAN No"
                                                        variant="standard"
                                                        name="panNumber"
                                                        value={inputs.panNumber}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        // required
                                                        id="adNo"
                                                        label="Adhaar No"
                                                        variant="standard"
                                                        name="adhaarNumber"
                                                        value={
                                                            inputs.adhaarNumber
                                                        }
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        className="mt-3"
                                                    >
                                                        <InputLabel id="demo-multiple-checkbox-label">
                                                            Crops Name
                                                        </InputLabel>
                                                        <Select
                                                            // required
                                                            labelId="demo-multiple-checkbox-label"
                                                            id="demo-multiple-checkbox"
                                                            multiple
                                                            value={cropTypes}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            input={
                                                                <Input
                                                                    label="Type Of Crop"
                                                                    variant="standard"
                                                                />
                                                            }
                                                            renderValue={(
                                                                selected
                                                            ) =>
                                                                selected.join(
                                                                    ', '
                                                                )
                                                            }
                                                            MenuProps={
                                                                MenuProps
                                                            }
                                                        >
                                                            {/* {names.map((name) => (
                                                                <MenuItem key={name} value={name}>
                                                                    <Checkbox checked={cropTypes.indexOf(name) > -1} />
                                                                    <ListItemText primary={name} />
                                                                </MenuItem>
                                                            ))} */}
                                                            {CropProductoption &&
                                                                CropProductoption.map(
                                                                    (name) => (
                                                                        <MenuItem
                                                                            key={
                                                                                name.value
                                                                            }
                                                                            value={
                                                                                name.value
                                                                            }
                                                                        >
                                                                            {console.log(
                                                                                'CropProduct',
                                                                                name,
                                                                                name.value
                                                                            )}
                                                                            <Checkbox
                                                                                checked={
                                                                                    cropTypes.indexOf(
                                                                                        name.value
                                                                                    ) >
                                                                                    -1
                                                                                }
                                                                            />
                                                                            <ListItemText
                                                                                primary={
                                                                                    name.value
                                                                                }
                                                                            />
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                            {/* {licenseTypes.map((crop, index) => {
                                                return (
                                                    <div className="row mt-3 mb-3">
                                                        <div class="col-md-3 mt-4">
                                                            <CssTextField
                                                                fullWidth id="standard-basic"
                                                                label="Type Of License"
                                                                variant="standard"
                                                                value={crop}
                                                            />
                                                        </div>
                                                        <div class="col-md-3 mt-4">
                                                            <CssTextField
                                                                fullWidth id="standard-basic"
                                                                type="number"
                                                                label="License Number"
                                                                variant="standard"
                                                                name="licenseNumber"
                                                                //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licenseNumber']}
                                                                onChange={(e) => setLicenceInputs(e, index)}
                                                            />
                                                        </div>
                                                        <div class="col-md-3 mt-4">
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
                                                                License Exp Date
                                                            </InputLabel>
                                                            <CssTextField
                                                                type="date"
                                                                fullWidth
                                                                variant="standard"
                                                                style={{ marginTop: '10px', marginBottom: '10px' }}
                                                                name="expDate"
                                                                //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licenseNumber']}
                                                                onChange={(e) => setLicenceInputs(e, index)}
                                                            />
                                                        </div>
                                                        <div class="col-md-3 d-flex" style={{ justifyContent: 'space-between' }}>
                                                            <label htmlFor="contained-button-file">
                                                                <Button variant="contained" component="span" color="success">
                                                                    <Input
                                                                        accept="image/"
                                                                        id="contained-button-file"
                                                                        multiple="false"
                                                                        name="photo"
                                                                        type="file"
                                                                        onChange={(e) => handleUploadDP(e, index)}
                                                                        style={{
                                                                            display: 'none',
                                                                            marginBottom: '16px',
                                                                            marginTop: '16px'
                                                                        }}
                                                                    />
                                                                    Upload
                                                                </Button>
                                                            </label>
                                                            <aside style={thumbsContainerState}>
                                                                <div style={thumb}>
                                                                    <div style={thumbInner}>
                                                                        <img src={licensePhoto} style={img} alt="" />
                                                                    </div>
                                                                </div>
                                                            </aside>
                                                        </div>
                                                    </div>
                                                )
                                            })} */}

                                            <div class="col-md-12 text-end mt-2 mb-2">
                                                <button
                                                    className="w-10 btn btn-secondary"
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                    onClick={(e) => setTab(1)}
                                                >
                                                    Previous
                                                </button>
                                                <button
                                                    type="submit"
                                                    class=" w-10 btn btn-primary"
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </div>

                                {/* Contact Details */}
                                <div
                                    class="tab-pane show active"
                                    id="bottom-justified-tab2"
                                >
                                    {tab === 3 && (
                                        <form
                                            onSubmit={(e) => {
                                                setTab(5)
                                                setInputs({
                                                    ...inputs,
                                                    typeOfLicense:
                                                        licenseTypesArr,
                                                })
                                            }}
                                        >
                                            <div class="row">
                                                <div
                                                    class="col-md-3"
                                                    style={{ marginTop: '2%' }}
                                                >
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        className="mt-3"
                                                    >
                                                        {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
                                                            State
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            label="State"
                                                            color="success"
                                                            className="pt-1"
                                                            name="state"
                                                            value={inputs.state}
                                                            onChange={(e) => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                                                        >
                                                            <MenuItem value="">
                                                                <em> - Select State - </em>
                                                            </MenuItem>
                                                            <MenuItem value="Assam">Assam</MenuItem>
                                                            <MenuItem value="Bihar">Bihar</MenuItem>
                                                            <MenuItem value="Chattisgarh">Chattisgarh</MenuItem>
                                                            <MenuItem value="Delhi">Delhi</MenuItem>
                                                            <MenuItem value="Kolkata">Kolkata</MenuItem>
                                                            <MenuItem value="Mumbai">Mumbai</MenuItem>
                                                            <MenuItem value="TamilNadu">TamilNadu</MenuItem>
                                                        </Select> */}
                                                        <Selects
                                                            style={{
                                                                width: '270px',
                                                                height: '40px',
                                                            }}
                                                            onChange={(e) =>
                                                                statechane(e)
                                                            }
                                                            // onChange={(e) => setFarmer({ ...farmer, ['state']: e.value })}
                                                            options={
                                                                statelistoption
                                                            }
                                                            placeholder={
                                                                'Select State'
                                                            }
                                                            //  value={inputBooking.firm}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div
                                                    class="col-md-3"
                                                    style={{
                                                        marginTop: '3.5%',
                                                    }}
                                                >
                                                    <Selects
                                                        style={{
                                                            width: '270px',
                                                            height: '40px',
                                                        }}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                ['district']:
                                                                    e.value,
                                                            })
                                                        }
                                                        options={citylistoption}
                                                        placeholder={
                                                            'Select district'
                                                        }
                                                        //  value={inputBooking.firm}
                                                    />
                                                    {/* <CssTextField
                                                        fullWidth id="standard-basic"
                                                        label="District"
                                                        variant="standard"
                                                        name="district"
                                                        value={inputs.district}
                                                        onChange={(e) => setInputs({ ...inputs, [e.target.name]: e.target.value })}
                                                    /> */}
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        id="subDistrict"
                                                        label="Sub District"
                                                        variant="standard"
                                                        name="subDistrict"
                                                        value={
                                                            inputs.subDistrict
                                                        }
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        id="standard-basic"
                                                        label="Village"
                                                        variant="standard"
                                                        name="village"
                                                        value={inputs.village}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        id="standard-basic"
                                                        label="Pincode"
                                                        variant="standard"
                                                        name="pincode"
                                                        value={inputs.pincode}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        id="standard-basic"
                                                        label="Full Address"
                                                        variant="standard"
                                                        name="fullAddress"
                                                        value={
                                                            inputs.fullAddress
                                                        }
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-12 text-end mt-2 mb-2">
                                                <button
                                                    className="w-10 btn btn-secondary"
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                    onClick={(e) => setTab(2)}
                                                >
                                                    Previous
                                                </button>
                                                <button
                                                    type="submit"
                                                    class=" w-10 btn btn-primary"
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </div>

                                {/* Commission Details */}

                                {/* <div class="tab-pane show active" id="bottom-justified-tab3">
                                    {tab === 4 &&
                                        <form onSubmit={() => setTab(5)}>
                                            <div class="row">
                                                <div class="col-md-4" style={{marginTop:'2%'}}>
                                                    <FormControl variant="standard" fullWidth className="mt-3">
                                                       
                                                         <Selects style={{ width: "270px", height: "40px" }}
                                onChange={(e) => setInputs({ ...inputs, ['unit']: e.value })}
                                options={CommisionUnit}
                                placeholder={'Select Unit'}
                              //  value={inputBooking.firm}
                              />
                                                    </FormControl>
                                                </div>
                                                <div class="col-md-4" style={{marginTop:'3.5%'}}>
                                                    
                                                     <Selects style={{ width: "270px", height: "40px" }}
                                onChange={(e) => setInputs({ ...inputs, ['commissionAmount']: e.value })}
                                options={CommisionAmount}
                                placeholder={'Select commissionAmount'}
                              //  value={inputBooking.firm}
                              />
                                                </div>
                                                <div class="col-md-4" style={{marginTop:'2%'}}>
                                                    <FormControl variant="standard" fullWidth className="mt-3">
                                                       
                                                         <Selects style={{ width: "270px", height: "40px" }}
                                onChange={(e) => setInputs({ ...inputs, ['category']: e.value })}
                                options={commisiontype}
                                placeholder={'Select  commision type'}
                              //  value={inputBooking.firm}
                              />
                                                    </FormControl>
                                                </div>

                                                <div class="col-md-12 text-end mt-2 mb-2">
                                                    <button className="w-10 btn btn-secondary" style={{ marginRight: '10px' }} onClick={e => setTab(3)}>Previous</button>
                                                    <button type="submit" class=" w-10 btn btn-primary">Next</button>
                                                </div>
                                            </div>
                                        </form>
                                    }
                                </div> */}

                                {/* Bank Account Info */}
                                <div
                                    class="tab-pane show active"
                                    id="bottom-justified-tab4"
                                >
                                    {tab === 5 && (
                                        <form
                                            action="#"
                                            onSubmit={onSubmitInputs}
                                        >
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id=""
                                                        label="Bank Acc"
                                                        variant="standard"
                                                        type="number"
                                                        name="bankAccountNumber"
                                                        value={
                                                            inputs.bankAccountNumber
                                                        }
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>

                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id=""
                                                        label="Account Name"
                                                        variant="standard"
                                                        name="bankAccountName"
                                                        value={
                                                            inputs.bankAccountName
                                                        }
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id=""
                                                        label="IFSC Code"
                                                        variant="standard"
                                                        name="ifscCode"
                                                        value={inputs.ifscCode}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id=""
                                                        label="Bank Name"
                                                        variant="standard"
                                                        pattern="[a-zA-Z]*"
                                                        type="text"
                                                        id="txtNumeric"
                                                        name="bankName"
                                                        value={inputs.bankName}
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-3">
                                                    <CssTextField
                                                        fullWidth
                                                        required
                                                        id=""
                                                        label="Branch Name"
                                                        variant="standard"
                                                        name="bankBranchName"
                                                        value={
                                                            inputs.bankBranchName
                                                        }
                                                        onChange={(e) =>
                                                            setInputs({
                                                                ...inputs,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-12 text-end mt-2 mb-2">
                                                    <button
                                                        className="w-10 btn btn-secondary"
                                                        style={{
                                                            marginRight: '10px',
                                                        }}
                                                        onClick={(e) =>
                                                            setTab(4)
                                                        }
                                                    >
                                                        Previous
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={(e) =>
                                                            onSubmitInputs()
                                                        }
                                                        class=" w-10 btn btn-primary"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <h3 className="mb-3">All Traders</h3>
                    <div class="card card-table">
                        <div class="card-body">
                            <div class="table-responsive">
                                <div
                                    id="DataTables_Table_0_wrapper"
                                    class="dataTables_wrapper dt-bootstrap4 no-footer"
                                >
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <h3 className="mt-3">Traders</h3>
                                            <div
                                                class="dataTables_length"
                                                id="DataTables_Table_0_length"
                                            >
                                                <hr />
                                                <label>
                                                    Show
                                                    <select
                                                        name="DataTables_Table_0_length"
                                                        aria-controls="DataTables_Table_0"
                                                        class="custom-select custom-select-sm form-control form-control-sm"
                                                    >
                                                        <option value="10">
                                                            10
                                                        </option>
                                                        <option value="25">
                                                            25
                                                        </option>
                                                        <option value="50">
                                                            50
                                                        </option>
                                                        <option value="100">
                                                            100
                                                        </option>
                                                    </select>
                                                    entries
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 position_right">
                                            <div class="form-group">
                                                {/* <label>Search</label> */}
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Search"
                                                    onChange={(e) =>
                                                        handleMobilesearch(e)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <TraderTable inputs={inputSuppliers} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CreteTraders
