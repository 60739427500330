import React, { useState, useEffect } from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
// import Select from '@mui/material/Select';
import Select from 'react-select'
import { CssTextField } from '../../Styles'
import MenuItem from '@mui/material/MenuItem'
import Input from '@mui/material/Input'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import { API } from '../../../API/index'
import Button from '@mui/material/Button'
import '../Create.css'
import { storage } from '../../../firebase/index'
import axios from 'axios'
import Swal from 'sweetalert2'

import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import { thumbsContainerState, thumb, img, thumbInner } from '../../Styles'
import FpoTable from './FpoTable'
import $ from 'jquery'
import OtpBox from '../../../components/OtpBox'

import Selects from 'react-select'
import Map from '../Map'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const names = ['Seed', 'Fertilizers', 'Pesticides']
const clearfpo = {
    firstName: '',
    lastName: '',
    mobileNumber: '',
    email: '',
    alternatemobile: '',
    category: '',
    panNo: '',
    aadharNo: '',

    firmName: '',
    state: '',
    district: '',
    subDistrict: '',
    village: '',
    pincode: '',
    gstNumber: '',
    fullAddress: '',
    gender: '',
    dob: '',
    language: '',

    ceoName: '',
    companyName: '',
    noOfEmployees: '',
    started: '',
    cropType: '',
    noOfMember: '',
    fundAmount: '',
    seedLicencePhoto: '',
    FertilizersLicencePhoto: '',
    Pesticides: '',

    unit: '',
    commisionAmount: '',
    commissionCategory: '',

    accountNumber: '',
    accountName: '',
    ifscCode: '',
    BankName: '',
    branchName: '',
}
function CreateFPO() {
    const [Formerinfo, setFormerinfo] = useState({})

    const [createdByid,setCreatedById]=useState('')
    const [createdByRole,setCreatedByRole]=useState("")
    const [tab, setTab] = useState(1)
    const [cropTypes, setCropTypes] = React.useState([])
    const [licensePhoto, setlicensePhoto] = useState(null)
    const [licenseFertPhoto, setlicenseFertPhoto] = useState(null)
    const [licensePestiPhoto, setlicensePestiPhoto] = useState(null)
    const [states, setStates] = useState([])
    const [fpoList, setFpoList] = useState([])
    const [citylist, setcitylist] = useState([])
    const [statelist, setstatelist] = useState([])
    const [CommisionCat, setCommisionCat] = useState([])
    const [otpWindow, setOtpWindow] = useState(false)
    const [fpo, setFpo] = useState({
        firstName: '',
        lastName: '',
        mobileNumber: '',
        email: '',
        alternatemobile: '',
        category: '',
        panNo: '',
        aadharNo: '',
        firmName: '',
        state: '',
        district: '',
        subDistrict: '',
        village: '',
        pincode: '',
        gstNumber: '',
        fullAddress: '',
        gender: '',
        dob: '',
        language: '',
        partner_mode: '',
        ceoName: '',
        companyName: '',
        noOfEmployees: '',
        started: '',
        cropType: cropTypes,
        noOfMember: '',
        fundAmount: '',
        seedLicencePhoto: '',
        FertilizersLicencePhoto: '',
        Pesticides: '',

        unit: '',
        commisionAmount: '',
        commissionCategory: '',

        accountNumber: '',
        accountName: '',
        ifscCode: '',
        BankName: '',
        branchName: '',
    })

    useEffect(() => {
        getState()
        getStateList()
        getCommisionCat()
        // getFpoList();
    }, [])
    const getCommisionCat = async () => {
        const { data } = await API.get(
            `/getCommissionCatelog?title=CommissionCatelog&catelogName=InputSupplierCatelog&type=2`
        )
        // console.log("hkjjhj",data)
        setCommisionCat(data?.result)
        console.log('firm search', data)
    }
    const CommisionUnit =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.commissionUnit,
            label: item.commissionUnit,
        }))
    const CommisionAmounts =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.commissionAmount,
            label: item.commissionAmount,
        }))
    const commisiontype =
        CommisionCat &&
        CommisionCat.map((item, ind) => ({
            value: item.subCategoryName,
            label: item.subCategoryName,
        }))
    const getStateList = async () => {
        const { data } = await API.get(`/getStateList`)
        // console.log("hkjjhj",data)
        setstatelist(data?.result)
        console.log('firm search', data)
    }
    const statelistoption =
        statelist &&
        statelist.map((item, ind) => ({
            value: item,
            label: item,
        }))
    const statechane = (e) => {
        console.log('hkjjhj', e.value)
        setFpo({ ...fpo, ['state']: e.value })
        // setInputs({ ...inputs, ['state']: e.value })
        // onChange={(e) => setInputs({ ...inputs, ['state']: e.target.value })}
        getCityList(e.value)
    }
    const getCityList = async (val) => {
        const { data } = await API.get(`/getCityList?stateName=` + val)
        // console.log("hkjjhj",data)
        setcitylist(data?.result)
        console.log('firm search', data)
    }
    const citylistoption =
        citylist &&
        citylist.map((item, ind) => ({
            value: item,
            label: item,
        }))
    const getState = async () => {
        const { data } = await axios.get(
            'http://65.0.145.198:4080/api/v1/getStateList'
        )
        setStates(data?.result)
        console.log(data?.result)
    }

    const getFpoList = async () => {
        const { data } = await API.get(`/getFpo?type=0`)
        setFpoList(data?.result)
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event
        setCropTypes(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    const onSubmitFpo = async (e) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const dataForOtp = {
            mobile_number: fpo.mobileNumber,
            role: 'fpo',
            created_by: 'admin',
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/auth-function/otp`,
                dataForOtp,
                config
            )
            .then((res) => {
                setOtpWindow(true)
            })
    }

    const syncOtpSubmit = (e) => {
        setOtpWindow(false)
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }

        var fpodata = {
            user_id: '',
            first_name: fpo.firstName,
            last_name: fpo.lastName,
            gender: fpo.gender,
            mobile_number: fpo.mobileNumber,
            dob: fpo.dob,
            role: 'fpo',
            photo: 'test',
            language: fpo.language,
            partner_mode: fpo.partner_mode,
            state: fpo.state,
            district: fpo.district,
            sub_distric: fpo.subDistrict,
            village: fpo.subDistrict,
            pincode: +fpo.pincode,
            full_address: fpo.address,
            coordinates: [fpo.lat, fpo.lng],
            company_info: {
                company_name: fpo.companyName,
                gst_no: fpo.gstNumber,
                no_of_employees: +fpo.noOfEmployees,
                gov_funding: fpo.fundAmount,
                start_date: fpo.started,
                ceo_name: fpo.ceoName,
            },
            created_by: createdByRole,
            createdById:createdByid  
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/auth-function/farmers`,
                fpodata,
                config
            )
            .then((res) => {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: 'FPO Created Successfully',
                    showConfirmButton: false,
                    timer: 2000,
                })
                setFpo(clearfpo)
                setTab(1)
            })
            .catch((err) =>
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: 'error occured!',
                    showConfirmButton: false,
                    timer: 2000,
                })
            )
    }

    const handleMobVal = (e) => {
        setFpo({ ...fpo, [e.target.name]: e.target.value })
        if (e.target.value.length == 10) {
            // mobilecheck(e.target.value)
        } else if (e.target.value.length > 10) {
            console.log('e.target.value.length', e.target.value.length)
            // setInputs({ ...inputs, mobileNumber: "" })
            // setFpo({ ...fpo, mobileNumber: "" })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: 'Mobile length should be 10',
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const mobilecheck = async (number) => {
        const { data } = await API.get(`/checkNumber?number=` + number)
        console.log('data', data)
        if (data.success == true) {
            // setFarmer({ ...farmer, [e.target.name]: number })
        } else {
            setFpo({ ...fpo, mobileNumber: '' })
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: data.result,
                showConfirmButton: false,
                timer: 2000,
            })
        }
        // setDetails(data?.result);
    }

    const handleMobilesearch = (e) => {
        console.log('e', e.target.value)
        if (e.target.value.length == 10) {
            mobilechecksearch(e.target.value)
        } else {
            setFpoList([])
        }
    }
    const mobilechecksearch = async (number) => {
        const { data } = await API.get(`/searchFpo?search=` + number)
        console.log('data', data)
        if (data.success == true) {
            let arr = []
            arr.push(data?.result)
            setFpoList(arr)
        } else {
        }
    }
    $(function () {
        $('#txtNumeric').keydown(function (e) {
            if (e.shiftKey || e.ctrlKey || e.altKey) {
                e.preventDefault()
            } else {
                var key = e.keyCode

                if (
                    !(
                        key == 8 ||
                        key == 32 ||
                        key == 46 ||
                        (key >= 35 && key <= 40) ||
                        (key >= 65 && key <= 90)
                    )
                ) {
                    e.preventDefault()
                }
            }
        })
    })
    const handleUploadLI = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setlicensePhoto(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPAN/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        console.log('url', url)
                        // fpo, setFpo
                        setFpo({ ...fpo, seedLicencePhoto: url })
                    })
                }
            )
        }
    }
    const handleUploadFERTI = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setlicenseFertPhoto(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPAN/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        console.log('url', url)
                        // fpo, setFpo
                        setFpo({ ...fpo, FertilizersLicencePhoto: url })
                    })
                }
            )
        }
    }
    const handleUploadPESTI = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setlicensePestiPhoto(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPAN/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        console.log('url', url)
                        // fpo, setFpo
                        setFpo({ ...fpo, Pesticides: url })
                    })
                }
            )
        }
    }
    const mapdata = (data) => {
        console.log('ran')
        setFpo({
            ...fpo,
            state: data.state,
            district: data.district,
            subDistrict: data.subDistrict,
            village: data.village,
            pincode: data.pincode,
            address: data.address,
            fullAddress: data.address,
            lat: data.latitude,
            lng: data.longitude,
        })
    }

    const gender = [
        {
            value: 'Male',
            label: 'Male',
        },
        {
            value: 'Female',
            label: 'Female',
        },
        {
            value: 'Others',
            label: 'Others',
        },
    ]

    const LanguageVale = [
        {
            value: 'FPO',
            label: 'FPO',
        },
        {
            value: 'NGO',
            label: 'NGO',
        },
        {
            value: 'Startup',
            label: 'Startup',
        },
    ]
    const handleFarmer = (e) => {
        if (e.target.value.length == 10) {
            getFormerinfo(e.target.value)
        } else {
            setFormerinfo({})
        }
    }
    const getFormerinfo = async (Mob) => {
        const token = localStorage.getItem('token')
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        const farmerNumber = {
            mobile_no: Mob,
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/register-details`,
                farmerNumber,
                config
            )
            .then((res) => {
                const d = res.data.data.data
                localStorage.setItem('FormerId', d.user_id)
                setCreatedById(d.user_id)
                setCreatedByRole(d.role)
                localStorage.setItem('microId', '')
                setFormerinfo(d)
            })
            .catch((err) => {
                console.log(err)
                setFormerinfo({})
            })
    }
    return (
        <>
        <div class="card card-table p-10-25">
                            <div class="card-header">
                                <h5 class="card-title"> Info</h5>
                            </div>
           <div class="tab-content">
                                        <div action="#">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="farmerNum"
                                                        //  label="Farmer Number"
                                                        placeholder="Farmer Number"
                                                        variant="standard"
                                                        //  value={}
                                                        onChange={(e) =>
                                                            handleFarmer(e)
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.role
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.id
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerName"
                                                        //  label="Farmer Name"
                                                        placeholder="Farmer Name"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.first_name
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        disabled
                                                        id="farmerState"
                                                        //  label="State"
                                                        placeholder="State"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.state
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="farmerDistrict"
                                                        disabled
                                                        //  label="District"
                                                        placeholder="District"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.district
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        disabled
                                                        fullWidth
                                                        id="farmerVillage"
                                                        //  label="Village"
                                                        placeholder="Village"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.village
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div class="col-md-4">
                                                    <CssTextField
                                                        required
                                                        disabled
                                                        fullWidth
                                                        id="farmerPincode"
                                                        //  label="Pincode"
                                                        placeholder="Pincode"
                                                        variant="standard"
                                                        value={
                                                            Formerinfo
                                                                ? Formerinfo.pincode
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>
                                          
                                        </div>
                                        </div>

                                    </div>
            {otpWindow ? (
                <OtpBox
                    mobileNumber={fpo.mobileNumber}
                    syncOtpSubmit={syncOtpSubmit}
                />
            ) : (
                <div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card card-table p-10-25">
                                <div class="card-header">
                                    <h5 class="card-title">Create FPO</h5>
                                </div>
                                <div class="card-body">
                                    <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                                        <li class="nav-item">
                                            <a
                                                class={
                                                    tab === 1
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                Personal Info
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                class={
                                                    tab === 2
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                Contact Details
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                class={
                                                    tab === 3
                                                        ? 'nav-link active'
                                                        : 'nav-link'
                                                }
                                            >
                                                Company Details
                                            </a>
                                        </li>
                                        {/* <li class="nav-item"><a class={tab === 4 ? "nav-link active" : "nav-link"}>Commission Details</a></li> */}
                                        {/* <li class="nav-item"><a class={tab === 5 ? "nav-link active" : "nav-link"}>Bank Account Info</a></li> */}
                                    </ul>
                                    <div class="tab-content">
                                        {/* Personal Info */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab1"
                                        >
                                            {
                                                tab === 1 && (
                                                    // <form onSubmit={() => setTab(2)}>
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="fName"
                                                                label="First Name"
                                                                variant="standard"
                                                                name="firstName"
                                                                value={
                                                                    fpo.firstName
                                                                }
                                                                onChange={(e) =>
                                                                    setFpo({
                                                                        ...fpo,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="lName"
                                                                label="Last Name"
                                                                variant="standard"
                                                                name="lastName"
                                                                value={
                                                                    fpo.lastName
                                                                }
                                                                onChange={(e) =>
                                                                    setFpo({
                                                                        ...fpo,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="mobile"
                                                                type="number"
                                                                label="Mobile Number"
                                                                variant="standard"
                                                                name="mobileNumber"
                                                                value={
                                                                    fpo.mobileNumber
                                                                }
                                                                onChange={(e) =>
                                                                    handleMobVal(
                                                                        e
                                                                    )
                                                                }
                                                                // onChange={(e) => setFpo({ ...fpo, [e.target.name]: e.target.value })}
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <Select
                                                                    style={{
                                                                        width: '270px',
                                                                        height: '40px',
                                                                    }}
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setFpo({
                                                                            ...fpo,
                                                                            ['gender']:
                                                                                e.value,
                                                                        })
                                                                    }
                                                                    options={
                                                                        gender
                                                                    }
                                                                    placeholder={
                                                                        'Select Gender'
                                                                    }
                                                                />
                                                            </FormControl>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <InputLabel
                                                                id="demo-simple-select-standard-label"
                                                                style={{
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                DOB
                                                            </InputLabel>
                                                            <CssTextField
                                                                type="date"
                                                                fullWidth
                                                                variant="standard"
                                                                style={{
                                                                    marginTop:
                                                                        '10px',
                                                                    marginBottom:
                                                                        '10px',
                                                                }}
                                                                name="dob"
                                                                value={
                                                                    fpo.dateOfBirth
                                                                }
                                                                onChange={(e) =>
                                                                    setFpo({
                                                                        ...fpo,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <FormControl
                                                                variant="standard"
                                                                fullWidth
                                                                className="mt-3"
                                                            >
                                                                <Select
                                                                    style={{
                                                                        width: '270px',
                                                                        height: '40px',
                                                                    }}
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setFpo({
                                                                            ...fpo,
                                                                            ['partner_mode']:
                                                                                e.value,
                                                                        })
                                                                    }
                                                                    options={
                                                                        LanguageVale
                                                                    }
                                                                    placeholder={
                                                                        'Partner Mode'
                                                                    }
                                                                />
                                                            </FormControl>
                                                        </div>
                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                            <button
                                                                onClick={(e) =>
                                                                    setTab(2)
                                                                }
                                                                class=" w-16 btn btn-primary"
                                                            >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                                // </form>
                                            }
                                        </div>

                                        {/* Firm Details */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab2"
                                        >
                                            {
                                                tab === 2 && (
                                                    // <form onSubmit={() => setTab(3)}>
                                                    <div>
                                                        <div class="row">
                                                            <div
                                                                class="col-md-3"
                                                                style={{
                                                                    marginTop:
                                                                        '2%',
                                                                }}
                                                            >
                                                                <Map
                                                                    center={{
                                                                        lat: 18.5204,
                                                                        lng: 73.8567,
                                                                    }}
                                                                    height="300px"
                                                                    zoom={15}
                                                                    mapdata={
                                                                        mapdata
                                                                    }
                                                                />
                                                                {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
                              State
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              label="State"
                              color="success"
                              name="state"
                              value={fpo.state}
                              onChange={(e) => setFpo({ ...fpo, [e.target.name]: e.target.value })}
                            >
                              <MenuItem value="">
                                <em> - Select State - </em>
                              </MenuItem>
                              <MenuItem value="Assam">Assam</MenuItem>
                              <MenuItem value="Bihar">Bihar</MenuItem>
                              <MenuItem value="Chattisgarh">Chattisgarh</MenuItem>
                              <MenuItem value="Delhi">Delhi</MenuItem>
                              <MenuItem value="Kolkata">Kolkata</MenuItem>
                              <MenuItem value="Mumbai">Mumbai</MenuItem>
                              <MenuItem value="TamilNadu">TamilNadu</MenuItem>
                            </Select> */}
                                                                {/* <Selects style={{ width: "270px", height: "40px" }}
                              onChange={(e) => statechane(e)}
                              // onChange={(e) => setFarmer({ ...farmer, ['state']: e.value })}
                              options={statelistoption}
                              placeholder={'Select State'}
                            //  value={inputBooking.firm}
                            />
                          </FormControl> */}
                                                            </div>
                                                            {/* <div class="col-md-3" style={{ marginTop: '3.5%' }}> */}
                                                            {/* <CssTextField
                            fullWidth id="standard-basic"
                            label="District"
                            variant="standard"
                            name="district"
                            value={fpo.district}
                            onChange={(e) => setFpo({ ...fpo, [e.target.name]: e.target.value })}
                          /> */}
                                                            {/* 
                          <Selects style={{ width: "270px", height: "40px" }}
                            onChange={(e) => setFpo({ ...fpo, ['district']: e.value })}
                            // onChange={(e) => setInputs({ ...inputs, ['district']: e.value })}
                            options={citylistoption}
                            placeholder={'Select district'}
                          //  value={inputBooking.firm}
                          />
                        </div>
                        <div class="col-md-3">
                          <CssTextField
                            fullWidth
                            id="subDistrict"
                            label="Sub District"
                            variant="standard"
                            name="subDistrict"
                            value={fpo.subDistrict}
                            onChange={(e) => setFpo({ ...fpo, [e.target.name]: e.target.value })}
                          />
                        </div>
                        <div class="col-md-3">
                          <CssTextField
                            fullWidth id="standard-basic"
                            label="Village"
                            variant="standard"
                            name="village"
                            value={fpo.village}
                            onChange={(e) => setFpo({ ...fpo, [e.target.name]: e.target.value })}
                          />
                        </div>
                        <div class="col-md-3">
                          <CssTextField
                            fullWidth id="standard-basic"
                            label="Pincode"
                            variant="standard"
                            name="pincode"
                            value={fpo.pincode}
                            onChange={(e) => setFpo({ ...fpo, [e.target.name]: e.target.value })}
                          />
                        </div> */}

                                                            {/* <div class="col-md-3">
                          <CssTextField
                            fullWidth id="standard-basic"
                            label="GST"
                            variant="standard"
                            name="gstNumber"
                            value={fpo.gstNumber}
                            onChange={(e) => setFpo({ ...fpo, [e.target.name]: e.target.value })}
                          />
                        </div> */}
                                                            {/* <div class="col-md-3">
                          <CssTextField
                            fullWidth id="standard-basic"
                            label="Full Address"
                            variant="standard"
                            name="fullAddress"
                            value={fpo.fullAddress}
                            onChange={(e) => setFpo({ ...fpo, [e.target.name]: e.target.value })}
                          />
                        </div> */}
                                                        </div>

                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                            <button
                                                                className="w-16 btn btn-secondary"
                                                                style={{
                                                                    marginRight:
                                                                        '10px',
                                                                }}
                                                                onClick={(e) =>
                                                                    setTab(1)
                                                                }
                                                            >
                                                                Previous
                                                            </button>
                                                            <button
                                                                onClick={(e) =>
                                                                    setTab(3)
                                                                }
                                                                class=" w-16 btn btn-primary"
                                                            >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                                // </form>
                                            }
                                        </div>

                                        {/* Company Details */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab5"
                                        >
                                            {
                                                tab === 3 && (
                                                    // <form onSubmit={() => { setTab(5); setFpo({ ...fpo, cropType: cropTypes }) }}>
                                                    <div>
                                                        <div class="row">
                                                            <div class="col-md-3">
                                                                <CssTextField
                                                                    fullWidth
                                                                    label="CEO Name"
                                                                    variant="standard"
                                                                    id="ceoName"
                                                                    name="ceoName"
                                                                    value={
                                                                        fpo.ceoName
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setFpo({
                                                                            ...fpo,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                            <div class="col-md-3">
                                                                <CssTextField
                                                                    fullWidth
                                                                    label="Company Name"
                                                                    variant="standard"
                                                                    id="companyName"
                                                                    name="companyName"
                                                                    value={
                                                                        fpo.companyName
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setFpo({
                                                                            ...fpo,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                            <div class="col-md-3">
                                                                <CssTextField
                                                                    fullWidth
                                                                    type="number"
                                                                    label="No of Employees"
                                                                    variant="standard"
                                                                    id="noOfEmployees"
                                                                    name="noOfEmployees"
                                                                    value={
                                                                        fpo.noOfEmployees
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setFpo({
                                                                            ...fpo,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                            <div class="col-md-3">
                                                                <InputLabel
                                                                    id="demo-simple-select-standard-label"
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                >
                                                                    Started
                                                                    Since
                                                                </InputLabel>
                                                                <CssTextField
                                                                    type="date"
                                                                    fullWidth
                                                                    variant="standard"
                                                                    style={{
                                                                        marginTop:
                                                                            '10px',
                                                                        marginBottom:
                                                                            '10px',
                                                                    }}
                                                                    name="started"
                                                                    value={
                                                                        fpo.started
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setFpo({
                                                                            ...fpo,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                            {/* <div className="col-md-3">
                          <FormControl variant="standard" fullWidth className="mt-3">
                            <InputLabel id="demo-multiple-checkbox-label">Types Of Licence</InputLabel>
                            <Select
                              required
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              multiple
                              value={cropTypes}
                              onChange={handleChange}
                              input={<Input label="Type Of Crop" variant="standard" />}
                              renderValue={(selected) => selected.join(', ')}
                              MenuProps={MenuProps}
                            >
                              {names.map((name) => (
                                <MenuItem key={name} value={name}>
                                  <Checkbox checked={cropTypes.indexOf(name) > -1} />
                                  <ListItemText primary={name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div> */}

                                                            <div class="col-md-3">
                                                                <CssTextField
                                                                    fullWidth
                                                                    type="number"
                                                                    label="Govt Funding if any"
                                                                    variant="standard"
                                                                    id="govtFunding"
                                                                    name="fundAmount"
                                                                    value={
                                                                        fpo.fundAmount
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setFpo({
                                                                            ...fpo,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>

                                                            <div class="col-md-3">
                                                                <CssTextField
                                                                    fullWidth
                                                                    id="standard-basic"
                                                                    label="GST"
                                                                    variant="standard"
                                                                    name="gstNumber"
                                                                    value={
                                                                        fpo.gstNumber
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setFpo({
                                                                            ...fpo,
                                                                            [e
                                                                                .target
                                                                                .name]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                            {cropTypes.map(
                                                                (
                                                                    crop,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <div className="row mt-3 mb-3">
                                                                            {
                                                                                // console.log("crop",crop)
                                                                                crop ==
                                                                                'Seed' ? (
                                                                                    <>
                                                                                        <div class="col-md-3 mt-4">
                                                                                            <CssTextField
                                                                                                fullWidth
                                                                                                id="standard-basic"
                                                                                                label="Type Of License"
                                                                                                variant="standard"
                                                                                                value={
                                                                                                    crop
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                        <div class="col-md-3 mt-4">
                                                                                            <CssTextField
                                                                                                fullWidth
                                                                                                id="standard-basic"
                                                                                                type="number"
                                                                                                label="License Number"
                                                                                                variant="standard"
                                                                                                name="licenseNumber"
                                                                                                //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licenseNumber']}
                                                                                                // onChange={(e) => setLicenceInputs(e, index)}
                                                                                            />
                                                                                        </div>
                                                                                        <div class="col-md-3 mt-4">
                                                                                            <InputLabel
                                                                                                id="demo-simple-select-standard-label"
                                                                                                style={{
                                                                                                    color: 'black',
                                                                                                }}
                                                                                            >
                                                                                                License
                                                                                                Exp
                                                                                                Date
                                                                                            </InputLabel>
                                                                                            <CssTextField
                                                                                                type="date"
                                                                                                fullWidth
                                                                                                variant="standard"
                                                                                                style={{
                                                                                                    marginTop:
                                                                                                        '10px',
                                                                                                    marginBottom:
                                                                                                        '10px',
                                                                                                }}
                                                                                                name="expDate"
                                                                                                //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licenseNumber']}
                                                                                                // onChange={(e) => setLicenceInputs(e, index)}
                                                                                            />
                                                                                        </div>

                                                                                        <div
                                                                                            class="col-md-3 d-flex"
                                                                                            style={{
                                                                                                justifyContent:
                                                                                                    'space-between',
                                                                                            }}
                                                                                        >
                                                                                            <label htmlFor="contained-button-file">
                                                                                                <Button
                                                                                                    variant="contained"
                                                                                                    component="span"
                                                                                                    color="success"
                                                                                                >
                                                                                                    <Input
                                                                                                        accept="image/"
                                                                                                        id="contained-button-file"
                                                                                                        multiple="false"
                                                                                                        name="photo"
                                                                                                        type="file"
                                                                                                        // onChange={(e) => handleUploadDP(e, index)}
                                                                                                        onChange={(
                                                                                                            e
                                                                                                        ) =>
                                                                                                            handleUploadLI(
                                                                                                                e
                                                                                                            )
                                                                                                        }
                                                                                                        style={{
                                                                                                            display:
                                                                                                                'none',
                                                                                                            marginBottom:
                                                                                                                '16px',
                                                                                                            marginTop:
                                                                                                                '16px',
                                                                                                        }}
                                                                                                    />
                                                                                                    Upload
                                                                                                </Button>
                                                                                            </label>
                                                                                            <aside
                                                                                                style={
                                                                                                    thumbsContainerState
                                                                                                }
                                                                                            >
                                                                                                <div
                                                                                                    style={
                                                                                                        thumb
                                                                                                    }
                                                                                                >
                                                                                                    <div
                                                                                                        style={
                                                                                                            thumbInner
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                licensePhoto
                                                                                                            }
                                                                                                            style={
                                                                                                                img
                                                                                                            }
                                                                                                            alt=""
                                                                                                        />
                                                                                                        {/* <img src="" style={img} alt="" /> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </aside>
                                                                                        </div>
                                                                                    </>
                                                                                ) : crop ==
                                                                                  'Fertilizers' ? (
                                                                                    <>
                                                                                        <div class="col-md-3 mt-4">
                                                                                            <CssTextField
                                                                                                fullWidth
                                                                                                id="standard-basic"
                                                                                                label="Type Of License"
                                                                                                variant="standard"
                                                                                                value={
                                                                                                    crop
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                        <div class="col-md-3 mt-4">
                                                                                            <CssTextField
                                                                                                fullWidth
                                                                                                id="standard-basic"
                                                                                                type="number"
                                                                                                label="License Number"
                                                                                                variant="standard"
                                                                                                name="licenseNumber"
                                                                                                //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licenseNumber']}
                                                                                                // onChange={(e) => setLicenceInputs(e, index)}
                                                                                            />
                                                                                        </div>
                                                                                        <div class="col-md-3 mt-4">
                                                                                            <InputLabel
                                                                                                id="demo-simple-select-standard-label"
                                                                                                style={{
                                                                                                    color: 'black',
                                                                                                }}
                                                                                            >
                                                                                                License
                                                                                                Exp
                                                                                                Date
                                                                                            </InputLabel>
                                                                                            <CssTextField
                                                                                                type="date"
                                                                                                fullWidth
                                                                                                variant="standard"
                                                                                                style={{
                                                                                                    marginTop:
                                                                                                        '10px',
                                                                                                    marginBottom:
                                                                                                        '10px',
                                                                                                }}
                                                                                                name="expDate"
                                                                                                //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licenseNumber']}
                                                                                                // onChange={(e) => setLicenceInputs(e, index)}
                                                                                            />
                                                                                        </div>

                                                                                        <div
                                                                                            class="col-md-3 d-flex"
                                                                                            style={{
                                                                                                justifyContent:
                                                                                                    'space-between',
                                                                                            }}
                                                                                        >
                                                                                            <label htmlFor="contained-button-files">
                                                                                                <Button
                                                                                                    variant="contained"
                                                                                                    component="span"
                                                                                                    color="success"
                                                                                                >
                                                                                                    <Input
                                                                                                        accept="image/"
                                                                                                        id="contained-button-files"
                                                                                                        multiple="false"
                                                                                                        name="photo"
                                                                                                        type="file"
                                                                                                        // onChange={(e) => handleUploadDP(e, index)}
                                                                                                        // onChange={(e)=>handleUploadcheck(e)}
                                                                                                        onChange={(
                                                                                                            e
                                                                                                        ) =>
                                                                                                            handleUploadFERTI(
                                                                                                                e
                                                                                                            )
                                                                                                        }
                                                                                                        style={{
                                                                                                            display:
                                                                                                                'none',
                                                                                                            marginBottom:
                                                                                                                '16px',
                                                                                                            marginTop:
                                                                                                                '16px',
                                                                                                        }}
                                                                                                    />
                                                                                                    Upload
                                                                                                </Button>
                                                                                            </label>
                                                                                            <aside
                                                                                                style={
                                                                                                    thumbsContainerState
                                                                                                }
                                                                                            >
                                                                                                <div
                                                                                                    style={
                                                                                                        thumb
                                                                                                    }
                                                                                                >
                                                                                                    <div
                                                                                                        style={
                                                                                                            thumbInner
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                licenseFertPhoto
                                                                                                            }
                                                                                                            style={
                                                                                                                img
                                                                                                            }
                                                                                                            alt=""
                                                                                                        />
                                                                                                        {/* <img src="" style={img} alt="" /> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </aside>
                                                                                        </div>
                                                                                    </>
                                                                                ) : crop ==
                                                                                  'Pesticides' ? (
                                                                                    <>
                                                                                        <div class="col-md-3 mt-4">
                                                                                            <CssTextField
                                                                                                fullWidth
                                                                                                id="standard-basic"
                                                                                                label="Type Of License"
                                                                                                variant="standard"
                                                                                                value={
                                                                                                    crop
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                        <div class="col-md-3 mt-4">
                                                                                            <CssTextField
                                                                                                fullWidth
                                                                                                id="standard-basic"
                                                                                                type="number"
                                                                                                label="License Number"
                                                                                                variant="standard"
                                                                                                name="licenseNumber"
                                                                                                //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licenseNumber']}
                                                                                                // onChange={(e) => setLicenceInputs(e, index)}
                                                                                            />
                                                                                        </div>
                                                                                        <div class="col-md-3 mt-4">
                                                                                            <InputLabel
                                                                                                id="demo-simple-select-standard-label"
                                                                                                style={{
                                                                                                    color: 'black',
                                                                                                }}
                                                                                            >
                                                                                                License
                                                                                                Exp
                                                                                                Date
                                                                                            </InputLabel>
                                                                                            <CssTextField
                                                                                                type="date"
                                                                                                fullWidth
                                                                                                variant="standard"
                                                                                                style={{
                                                                                                    marginTop:
                                                                                                        '10px',
                                                                                                    marginBottom:
                                                                                                        '10px',
                                                                                                }}
                                                                                                name="expDate"
                                                                                                //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licenseNumber']}
                                                                                                // onChange={(e) => setLicenceInputs(e, index)}
                                                                                            />
                                                                                        </div>

                                                                                        <div
                                                                                            class="col-md-3 d-flex"
                                                                                            style={{
                                                                                                justifyContent:
                                                                                                    'space-between',
                                                                                            }}
                                                                                        >
                                                                                            <label htmlFor="contained-button-filed">
                                                                                                <Button
                                                                                                    variant="contained"
                                                                                                    component="span"
                                                                                                    color="success"
                                                                                                >
                                                                                                    <Input
                                                                                                        accept="image/"
                                                                                                        id="contained-button-filed"
                                                                                                        multiple="false"
                                                                                                        name="photo"
                                                                                                        type="file"
                                                                                                        // onChange={(e) => handleUploadDP(e, index)}
                                                                                                        onChange={(
                                                                                                            e
                                                                                                        ) =>
                                                                                                            handleUploadPESTI(
                                                                                                                e
                                                                                                            )
                                                                                                        }
                                                                                                        style={{
                                                                                                            display:
                                                                                                                'none',
                                                                                                            marginBottom:
                                                                                                                '16px',
                                                                                                            marginTop:
                                                                                                                '16px',
                                                                                                        }}
                                                                                                    />
                                                                                                    Upload
                                                                                                </Button>
                                                                                            </label>
                                                                                            <aside
                                                                                                style={
                                                                                                    thumbsContainerState
                                                                                                }
                                                                                            >
                                                                                                <div
                                                                                                    style={
                                                                                                        thumb
                                                                                                    }
                                                                                                >
                                                                                                    <div
                                                                                                        style={
                                                                                                            thumbInner
                                                                                                        }
                                                                                                    >
                                                                                                        {/* <img src={licenseFertPhoto} style={img} alt="" /> */}
                                                                                                        <img
                                                                                                            src={
                                                                                                                licensePestiPhoto
                                                                                                            }
                                                                                                            style={
                                                                                                                img
                                                                                                            }
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </aside>
                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    ''
                                                                                )
                                                                            }
                                                                            {/* <div class="col-md-3 mt-4">
                              <CssTextField
                                fullWidth id="standard-basic"
                                label="Type Of License"
                                variant="standard"
                                value={crop}
                              />
                            </div>
                            <div class="col-md-3 mt-4">
                              <CssTextField
                                fullWidth id="standard-basic"
                                type="number"
                                label="License Number"
                                variant="standard"
                                name="licenseNumber"
                                //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licenseNumber']}
                                // onChange={(e) => setLicenceInputs(e, index)}
                              />
                            </div>
                            <div class="col-md-3 mt-4">
                              <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>
                                License Exp Date
                              </InputLabel>
                              <CssTextField
                                type="date"
                                fullWidth
                                variant="standard"
                                style={{ marginTop: '10px', marginBottom: '10px' }}
                                name="expDate"
                                //  value={licenseTypesArr.length > 0 && licenseTypesArr[index]['licenseNumber']}
                                // onChange={(e) => setLicenceInputs(e, index)}
                              />
                            </div>
                            
                            <div class="col-md-3 d-flex" style={{ justifyContent: 'space-between' }}>
                              <label htmlFor="contained-button-file">
                                <Button variant="contained" component="span" color="success">
                                  <Input
                                    accept="image/"
                                    id="contained-button-file"
                                    multiple="false"
                                    name="photo"
                                    type="file"
                                    // onChange={(e) => handleUploadDP(e, index)}
                                    // onChange={(e)=>handleUploadcheck(e, index)}
                                    style={{
                                      display: 'none',
                                      marginBottom: '16px',
                                      marginTop: '16px'
                                    }}
                                  />
                                  Upload
                                </Button>
                              </label>
                              <aside style={thumbsContainerState}>
                                <div style={thumb}>
                                  <div style={thumbInner}>
                                   
                                    <img src="" style={img} alt="" />
                                    
                                  </div>
                                </div>
                              </aside>
                            </div> */}
                                                                        </div>
                                                                    )
                                                                }
                                                            )}
                                                        </div>
                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                            <button
                                                                className="w-16 btn btn-secondary"
                                                                style={{
                                                                    marginRight:
                                                                        '10px',
                                                                }}
                                                                onClick={(e) =>
                                                                    setTab(2)
                                                                }
                                                            >
                                                                Previous
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                class=" w-16 btn btn-primary"
                                                                onClick={(e) =>
                                                                    syncOtpSubmit(
                                                                        e
                                                                    )
                                                                }
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                                // </form>
                                            }
                                        </div>

                                        {/* Commission Details */}

                                        {/* <div class="tab-pane show active" id="bottom-justified-tab3">
                  {tab === 4 &&
                    <form onSubmit={() => setTab(5)}>
                      <div class="row">
                        <div class="col-md-4" style={{ marginTop: '2%' }}>
                          <FormControl variant="standard" fullWidth className="mt-3">
                            
                             <Selects style={{ width: "270px", height: "40px" }}
                              onChange={(e) => setFpo({ ...fpo, ['unit']: e.value })}
                                // onChange={(e) => setInputs({ ...inputs, ['unit']: e.value })}
                                options={CommisionUnit}
                                placeholder={'Select Unit'}
                              //  value={inputBooking.firm}
                              />
                          </FormControl>
                        </div>
                        <div class="col-md-4" style={{ marginTop: '3.5%' }}>
                        <Selects style={{ width: "270px", height: "40px" }}
                         onChange={(e) => setFpo({ ...fpo, ['commisionAmount']: e.value  })}
                                // onChange={(e) => setInputs({ ...inputs, ['commissionAmount']: e.value })}
                                options={CommisionAmounts}
                                placeholder={'Select commissionAmount'}
                              //  value={inputBooking.firm}
                              />
                       
                        </div>
                        <div class="col-md-4" style={{ marginTop: '2%' }}>
                          <FormControl variant="standard" fullWidth className="mt-3">
                            
                              <Selects style={{ width: "270px", height: "40px" }}
                              onChange={(e) => setFpo({ ...fpo, ['commissionCategory']: e.value })}
                                // onChange={(e) => setInputs({ ...inputs, ['category']: e.value })}
                                options={commisiontype}
                                placeholder={'Select  commision type'}
                              //  value={inputBooking.firm}
                              />
                          </FormControl>
                        </div>

                        <div class="col-md-12 text-end mt-2 mb-2">
                          <button className="w-10 btn btn-secondary" style={{ marginRight: '10px' }} onClick={e => setTab(3)}>Previous</button>
                          <button type="submit" class=" w-10 btn btn-primary">Next</button>
                        </div>
                      </div>
                    </form>
                  }
                </div> */}

                                        {/* Bank Account Info */}
                                        <div
                                            class="tab-pane show active"
                                            id="bottom-justified-tab4"
                                        >
                                            {
                                                tab === 5 && (
                                                    // <form action="#" onSubmit={onSubmitFpo}>
                                                    <div class="row">
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="accNum"
                                                                label="Bank Acc"
                                                                variant="standard"
                                                                type="number"
                                                                name="accountNumber"
                                                                value={
                                                                    fpo.accountNumber
                                                                }
                                                                onChange={(e) =>
                                                                    setFpo({
                                                                        ...fpo,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="accName"
                                                                label="Account Name"
                                                                variant="standard"
                                                                name="accountName"
                                                                value={
                                                                    fpo.accountName
                                                                }
                                                                onChange={(e) =>
                                                                    setFpo({
                                                                        ...fpo,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="ifscCode"
                                                                label="IFSC Code"
                                                                variant="standard"
                                                                name="ifscCode"
                                                                value={
                                                                    fpo.ifscCode
                                                                }
                                                                onChange={(e) =>
                                                                    setFpo({
                                                                        ...fpo,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="bankName"
                                                                label="Bank Name"
                                                                pattern="[a-zA-Z]*"
                                                                type="text"
                                                                variant="standard"
                                                                name="BankName"
                                                                value={
                                                                    fpo.BankName
                                                                }
                                                                onChange={(e) =>
                                                                    setFpo({
                                                                        ...fpo,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <CssTextField
                                                                fullWidth
                                                                required
                                                                id="branchName"
                                                                label="Branch Name"
                                                                variant="standard"
                                                                name="branchName"
                                                                value={
                                                                    fpo.branchName
                                                                }
                                                                onChange={(e) =>
                                                                    setFpo({
                                                                        ...fpo,
                                                                        [e
                                                                            .target
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div class="col-md-12 text-end mt-2 mb-2">
                                                            <button
                                                                className="w-16 btn btn-secondary"
                                                                style={{
                                                                    marginRight:
                                                                        '10px',
                                                                }}
                                                                onClick={(e) =>
                                                                    setTab(3)
                                                                }
                                                            >
                                                                Previous
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                class=" w-16 btn btn-primary"
                                                                onClick={(e) =>
                                                                    onSubmitFpo(
                                                                        e
                                                                    )
                                                                }
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                                // </form>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
export default CreateFPO
