import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Table from './Table'
import InputsellCroptable from '../Bookings/SellCrop/SellCropTable'

const SellCropOrders = () => {
    const [tab, setTab] = useState()
    const [sellcropBookfetch, setsellcropBookfetch] = useState([])
    const [cropSearch, setCropSearch] = useState('')
    const [prevAmount, setPrevAmount] = useState('')
    const [completeAmount, setCompleteAmount] = useState('')
    const [completeCount, setCompleteCount] = useState('')
    const [acceptedCount, setAcceptedCount] = useState('')
    const [acceptedAmount, setAcceptedAmount] = useState('')
    const [pendingCount, setPendingCount] = useState('')
    const [buyAmount, setBuyAmount] = useState('')
    const [buyCount, setBuyCount] = useState('')
    const [cancalledAmount, setcancalledAmount] = useState('')
    const [cancalledCount, setcancalledCount] = useState('')

    useEffect(() => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/all-crop-bookings`,
                config
            )
            .then((res) => {
                console.log('checking ma c booking', res.data.data)
                setsellcropBookfetch(res.data.data)
            })
        setAmount('completed')
        setAmount('accepted')
        setAmount('buy')
        setAmount('in_progress')
        setAmount("Cancelled")
    }, [])

    const setAmount = (status) => {
        var data = {
            status: status,
        }
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/cropbystatus`,
                data,
                config
            )
            .then((res) => {
                console.log('checking ma c booking', res.data.data)
                const amount = res.data.data
                    .map((a) => a.userAmount)
                    .reduce((prev, next) => prev + next)
                if (status === 'completed') {
                    setCompleteCount(res.data.data.length)
                    setCompleteAmount(amount)
                }
                if (status === 'in_progress') {
                    setPendingCount(res.data.data.length)
                    setPrevAmount(amount)
                }
                if (status === 'accepted') {
                    setAcceptedCount(res.data.data.length)
                    setAcceptedAmount(amount)
                }
                if (status === 'buy') {
                    setBuyCount(res.data.data.length)
                    setBuyAmount(amount)
                }
                if (status === 'Cancelled') {
                  setcancalledCount(res.data.data.length)
                  setcancalledAmount(amount)
              }
            })
    }

    const handleTab = (status, tab) => {
        setTab(tab)
        var data = {
            status: status,
        }
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/cropbystatus`,
                data,
                config
            )
            .then((res) => {
                console.log('checking ma c booking', res.data.data)
                setCompleteCount(res.data.data.length)
                const amount = res.data.data
                    .map((a) => a.userAmount)
                    .reduce((prev, next) => prev + next)
                if (status === 'completed') {
                    setCompleteAmount(amount)
                }
                if (status === 'in_progress') {
                    setPrevAmount(amount)
                }
                if (status === 'accepted') {
                    setAcceptedCount(res.data.data.length)
                    setAcceptedAmount(amount)
                }
                if (status === 'buy') {
                    setBuyCount(res.data.data.length)
                    setBuyAmount(amount)
                }
                if (status === 'Cancelled') {
                  setcancalledCount(res.data.data.length)
                  setcancalledAmount(amount)
              }
                setsellcropBookfetch(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleCrop = (e) => {}

    return (
        <div style={{ marginBottom: '20px' }}>
            <div className="row mb-4">
                <div className="col-12">
                    <ul className="nav nav-tab nav-tab-bottom nav-justified orders-tab">
                        {/* <li
              class={
                tab === 1
                  ? 'nav-item activeOrders active'
                  : 'nav-item activeOrders'
              }
            >
              <a
                class='nav-link'
                href='#bottom-justified-tab1'
                data-bs-toggle='tab'
                onClick={(e) => setTab(1)}
              >
                <h6>RS. 1000</h6>
                <small>5 Active Orders</small>
              </a>
            </li> */}
                        <li
                            className={
                                tab === 1
                                    ? 'nav-item pending active'
                                    : 'nav-item pending'
                            }
                        >
                            <a
                                className="nav-link"
                                href="#bottom-justified-tab2"
                                data-bs-toggle="tab"
                                onClick={(e) => handleTab('in_progress', 2)}
                            >
                                <h6>RS. {prevAmount}</h6>
                                <small>{pendingCount} Pending Orders</small>
                            </a>
                        </li>
                        <li className="nav-item accepted">
                            <a
                                className={
                                    tab === 3 ? 'nav-link active' : 'nav-link'
                                }
                                href="#bottom-justified-tab3"
                                data-bs-toggle="tab"
                                onClick={(e) => handleTab('accepted', 3)}
                            >
                                <h6>RS. {acceptedAmount}</h6>
                                <small>{acceptedCount} Accepted Orders</small>
                            </a>
                        </li>
                        <li className="nav-item accepted">
                            <a
                                className={
                                    tab === 4 ? 'nav-link active' : 'nav-link'
                                }
                                href="#bottom-justified-tab4"
                                data-bs-toggle="tab"
                                onClick={(e) => handleTab('buy', 4)}
                            >
                                <h6>RS. {buyAmount}</h6>
                                <small>{buyCount} Buy Orders</small>
                            </a>
                        </li>
                        {/* <li className='nav-item picked'>
              <a
                className='nav-link'
                href='#bottom-justified-tab4'
                data-bs-toggle='tab'
                onClick={(e) => setTab(4)}
              >
                <h6>RS. 1600</h6>
                <small>0 Picked Orders</small>
              </a>
            </li> */}
                        {/* <li className='nav-item ready'>
              <a
                className='nav-link'
                href='#bottom-justified-tab5'
                data-bs-toggle='tab'
                onClick={(e) => setTab(5)}
              >
                <h6>RS. 7000</h6>
                <small>2 Ready Orders</small>
              </a>
            </li> */}
                        <li className="nav-item completed">
                            <a
                                className="nav-link "
                                href="#bottom-justified-tab6"
                                data-bs-toggle="tab"
                                onClick={(e) => handleTab('completed', 6)}
                            >
                                <h6>RS. {completeAmount}</h6>
                                <small>{completeCount} Completed Orders</small>
                            </a>
                        </li>
                        <li className="nav-item Cancelled">
                            <a
                                className="nav-link "
                                href="#bottom-justified-tab6"
                                data-bs-toggle="tab"
                                onClick={(e) => handleTab('Cancelled', 7)}
                            >
                                <h6>RS. {cancalledAmount}</h6>
                                <small>{cancalledCount} Cancelled Orders</small>
                            </a>
                        </li>
                        {/* <li className='nav-item failed'>
              <a
                className='nav-link '
                href='#bottom-justified-tab6'
                data-bs-toggle='tab'
                onClick={(e) => setTab(8)}
              >
                <h6>RS. 0</h6>
                <small>1 Failed Orders</small>
              </a>
            </li> */}
                    </ul>
                </div>
            </div>

            {/* <div className='row mb-3'>
        <div className='col-md-2'>
          <div class='form-group'>
            <input type='text' class='form-control' placeholder='All Stores' />
          </div>
        </div>
        <div className='col-md-2'>
          <div class='form-group'>
            <input
              type='text'
              class='form-control'
              placeholder='All Customer'
            />
          </div>
        </div>
        <div className='col-md-2'>
          <div class='form-group'>
            <input type='text' class='form-control' placeholder='Search' />
          </div>
        </div>
        <div className='col-md-2'>
          <div class='form-group'>
            <input type='text' class='form-control' placeholder='Search' />
          </div>
        </div>
        <div className='col-md-2'>
          <div class='form-group'>
            <input type='text' class='form-control' placeholder='Search' />
          </div>
        </div>
        <div className='col-md-2'>
          <div class='form-group'>
            <input onChange={(e) => setCropSearch(e.target.value)} type='text' class='form-control' placeholder='Search crop' />
          </div>
        </div>
        <div className='col-md-2'>
          <div class='form-group'>
            <input type='text' class='form-control' placeholder='State' />
          </div>
        </div>

        <div className='col-md-2'>
          <div class='form-group'>
            <input
              type='text'
              class='form-control'
              placeholder='Sub District'
            />
          </div>
        </div>
      </div> */}
            <InputsellCroptable
                inputs={sellcropBookfetch}
                cropSearch={cropSearch}
            />
        </div>
    )
}

export default SellCropOrders
