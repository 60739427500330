import React from 'react'

function StaffMembers() {
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Staff Members</h5>
                    <div class="tab_add_new_btn">
                        <a href="#">Create Staff</a>
                    </div>
                </div>
                <div class="card-body card-body_1">
                    <form>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card-body card-body_1">
                                    <div class="table-wrap">
                                        <div class="table-responsive">
                                            <table class="table display setting-td mb-30">
                                                <thead>
                                                    <tr>
                                                        <th>NAME</th>
                                                        <th>PHOTO</th>
                                                        <th>EMAIL</th>
                                                        <th>CREATED</th>
                                                        <th>ACTIONS</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Monika D</td>
                                                        <td>
                                                            <a
                                                                href="#"
                                                                class="staffAvatar-root"
                                                            >
                                                                M
                                                            </a>
                                                        </td>
                                                        <td>
                                                            krishivantechgdkhub@gmail.com
                                                        </td>
                                                        <td>Aug 5, 9:51 AM</td>
                                                        <td>
                                                            <a
                                                                href="#"
                                                                class="btn btn-tbl-edit btn-xs td_btn"
                                                            >
                                                                <span class="material-icons-outlined">
                                                                    edit
                                                                </span>
                                                            </a>
                                                            <a
                                                                href="#"
                                                                class="btn btn-tbl-edit btn-xs td_btn"
                                                            >
                                                                <span class="material-icons-outlined">
                                                                    delete
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default StaffMembers
