import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory,
} from 'react-router-dom'
import Sidebar from './components/sidebar/Sidebar'
import Topbar from './components/topbar/Topbar'
import './App.css'
import Login from './pages/Login/Login'
import Home from './pages/Home/Home'
import PGListWidget from './components/PGListWidget/PGListWidget'
import Bookings from './pages/Bookings/Bookings'
import Farmers from './pages/Create/Farmers'
import Catalogue from './pages/Catelog/Catelog'
import IncentiveCatelog from './pages/IncentiveCatelog/IncentiveCatelog'
import Inventory from './pages/Inventory/Inventory'
import Cities from './pages/Cities/Cities'
import RevenuePlans from './pages/RevenuePlans/RevenuePlans'
import Settings from './pages/Settings/Settings'
import Store from './pages/Store/Store'
import Livestock from './pages/Store/Livestock'
import InputSupplier from './pages/Store/InputSupplier'
import AgriMachine from './pages/Store/AgriMachine'
import AgriTransport from './pages/Store/AgriTransport'
import LivestockBD from './pages/BookingDetail/LivestockBD'
import BookingDetails from './pages/BookingDetail/BookingDetails'
import BookingDetailsidebar from './pages/BookingDetailsidebar/BookingDetails'
import InputSupplierBD from './pages/BookingDetail/InputSupplier'
import AgriMachineBD from './pages/BookingDetail/AgriMachineBD'
import AgriTransportBD from './pages/BookingDetail/AgriTransportBD'
import Orders from './pages/Orders/Orders'
import FarmerDetails from './pages/Store/FarmerInfo'
import InputSupplierDetails from './pages/Store/InputSupplier'
import LivestockDetails from './pages/Store/Livestock'
import AgriMachineDetails from './pages/Store/AgriMachine'
import AgriTransportDetails from './pages/Store/AgriTransport'
import FpoDetails from './pages/Store/FPO'
import MicroEntrep from './pages/Store/MicroEntrep'
import SellCropDB from '../src/pages/BookingDetail/SellCropBD'
import TranderTable from './pages/Store/Traders/Traders'
import InputSupplierBd from './pages/BookingDetailsidebar/InputSupplier'
import React, { useState, useEffect } from 'react'
import 'bootstrap-daterangepicker/daterangepicker.css'

import LoginTimer from './loginTimer'
import InvoiceTab from './pages/Invoice/InvoiceTab'
import ReportTab from './pages/Reports/ReportTab'
import ListTab from './pages/List/ListTab'
import CountTab from './pages/Count/CountTab'
import BookingCountTab from './pages/CountBooking/CountTab'
import ProductDescription from './additionalProduct'
function App(props) {
    const [isTimeout, setIsTimeout] = useState(false)

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split('.')[1]))
        } catch (e) {
            return null
        }
    }
    useEffect(() => {
        const token = localStorage.getItem('token')
        if (!token) setIsTimeout(true)
        const timer = new LoginTimer({
            timeout: 300,
            onTimeout: () => {
                setIsTimeout(true)
                localStorage.removeItem('token')
            },
        })

        return () => {
            timer.cleanUp()
        }
    }, [])

    return (
        <>
            {isTimeout ? (
                <Login />
            ) : (
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Login />
                        </Route>
                        <div className="main-wrapper">
                            <Topbar />

                            <Sidebar />

                            <Switch>
                                <div class="page-wrapper">
                                    <div class="content container-fluid">
                                        <Route>
                                            <Route path="/Home">
                                                <Home />
                                            </Route>
                                            <Route path="/productDescription">
                                                <ProductDescription />
                                            </Route>
                                            <Route path="/PGListWidget">
                                                <PGListWidget />
                                            </Route>

                                            <Route path="/CreatenBookings">
                                                <Farmers />
                                            </Route>

                                            <Route path="/Bookings">
                                                <Bookings />
                                            </Route>

                                            <Route path="/Catalogue">
                                                <Catalogue />
                                            </Route>

                                            <Route path="/IncentiveCatelog">
                                                <IncentiveCatelog />
                                            </Route>

                                            <Route path="/Inventory">
                                                <Inventory />
                                            </Route>

                                            <Route path="/Cities">
                                                <Cities />
                                            </Route>

                                            <Route path="/RevenuePlans">
                                                <RevenuePlans />
                                            </Route>

                                            <Route path="/Settings">
                                                <Settings />
                                            </Route>

                                            <Route path="/Modules">
                                                <Store />
                                            </Route>
                                            <Route path="/LivestockEdit">
                                                <Livestock />
                                            </Route>
                                            <Route path="/InputSupplierEdit">
                                                <InputSupplier />
                                            </Route>
                                            <Route path="/AgriMachine">
                                                <AgriMachine />
                                            </Route>
                                            <Route path="/AgriTransport">
                                                <AgriTransport />
                                            </Route>
                                            <Route path="/LivestockBookingDetails">
                                                <LivestockBD />
                                            </Route>
                                            <Route path="/InputSupplierBookingDetails">
                                                <InputSupplierBD />
                                            </Route>
                                            <Route path="/AgriMachineBookingDetails">
                                                <AgriMachineBD />
                                            </Route>
                                            <Route path="/AgriTransportBookingDetails">
                                                <AgriTransportBD />
                                            </Route>
                                            <Route path="/BookingDetails">
                                                <BookingDetails />
                                            </Route>
                                            <Route path="/BookingDetailsidebar">
                                                <BookingDetailsidebar />
                                            </Route>
                                            <Route path="/Orders">
                                                <Orders />
                                            </Route>
                                            <Route path="/Reports">
                                                <ReportTab />
                                            </Route>
                                            <Route path="/Invoice">
                                                <InvoiceTab />
                                            </Route>
                                            <Route path="/Count">
                                                <CountTab />
                                            </Route>
                                            <Route path="/BookingCount">
                                                <BookingCountTab />
                                            </Route>
                                            <Route path="/List">
                                                <ListTab />
                                            </Route>
                                            <Route path="/FarmerDetails/:id">
                                                <FarmerDetails />
                                            </Route>
                                            <Route path="/TraderDetails/:id">
                                                <TranderTable />
                                            </Route>

                                            <Route path="/InputSupplierDetails/:id">
                                                <InputSupplierDetails />
                                            </Route>
                                            <Route path="/LivestockDetails/:id">
                                                <LivestockDetails />
                                            </Route>
                                            <Route path="/AgriMachineDetails/:id">
                                                <AgriMachineDetails />
                                            </Route>
                                            <Route path="/AgriTransportDetails/:id">
                                                <AgriTransportDetails />
                                            </Route>
                                            <Route path="/FpoDetails/:id">
                                                <FpoDetails />
                                            </Route>
                                            <Route path="/MicroEntrepDetails/:id">
                                                <MicroEntrep />
                                            </Route>

                                            <Route path="/InputSupplierBookingDetail/:id">
                                                <InputSupplierBd />
                                            </Route>
                                            <Route path="/LivestockBookingDetail/:id">
                                                <LivestockBD />
                                            </Route>
                                            <Route path="/AgriMachineBookingDetail/:id">
                                                <AgriMachineBD />
                                            </Route>
                                            <Route path="/AgriTransportBookingDetail/:id">
                                                <AgriTransportBD />
                                            </Route>
                                            <Route
                                                path="/SellCropDB/:id"
                                                children={<SellCropDB />}
                                            />
                                        </Route>
                                        {/* </Route> */}
                                    </div>
                                </div>
                            </Switch>
                        </div>
                    </Switch>
                </Router>
            )}
        </>
    )
}

export default App
