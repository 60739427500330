import React, { useState, useEffect } from 'react'
import { CssTextField } from '../Styles'
import IconButton from '@mui/material/IconButton'
import SendIcon from '@mui/icons-material/Send'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Swal from 'sweetalert2'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'
import './Styles.css'
import { thumb, img, thumbInner } from '../Styles'
import { API } from '../../API'
import Dialog from '@mui/material/Dialog'
import MachineTransportInvoice from './MachineTransportInvoice'
import { Button } from 'antd'
import BookingMap from '../Create/bookingMap'
import { DialogContent, DialogTitle, Drawer } from '@mui/material'
import CancelAssignDialog from './cancelAssign'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
const AgriMachineBD = () => {
    const location = useLocation()
    const [opens, setOpens] = React.useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [District, setDistrict] = useState('')
    const [invoiceId, setInvoiceId] = useState('')
    const [paymentModal, setPaymentModal] = useState(false)
    const [editProducts, setEditProducts] = useState(false)
    const [invoiceopen, setInvoiceOpen] = React.useState(false)
    const [driverList, setDriverList] = useState([])
    const [driverList1, setDriverList1] = useState([])
    const [drivers, setDrivers] = useState([])
    const [quantity, setQuantity] = useState(0)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const [Details, setDetails] = useState({})
    const [details1, setDetails1] = useState({})
    const [DetailsInput, setDetailsInput] = useState({})
    const [TotalPrice, setTotalPrice] = useState(0)
    const [FirmDetails, setFirmDetails] = useState([])
    const [updatedvalue, setUpdatedvalue] = useState({})
    const [unitName, setunitName] = useState('')
    const [billingType, setbillingType] = useState('')
    const [booking, setBooking] = useState({})
    const [assignData, setAssignedData] = useState([])
    const [tab, setTab] = useState(true)
    const [tab1, setTab1] = useState(false)
    const [tab2, setTab2] = useState(false)
    const [tab3, setTab3] = useState(false)
    const [tab4, setTab4] = useState(false)
    const [tab5, setTab5] = useState(false)
    const [activeColor, setActiveColor] = useState('')
    const [activeColor1, setActiveColor1] = useState('')
    const [activeColor2, setActiveColor2] = useState('')
    const [activeColor3, setActiveColor3] = useState('')
    const [activeColor4, setActiveColor4] = useState('')
    const [assignDriverData, setAssignedDriverData] = useState([])
    const [orderId, setOrderId] = useState([])
    const [createdByData, setCreatedByData] = useState({})
    const [paymentStatus, setPaymentStatus] = useState(false)
    const [transactionId, setTransactionId] = useState('')
    const [transactionNotes,setTransactionNotes]=useState("")
    const [openAssign, setOpensAssign] = useState(false)
    const [description,setDescription]=useState('')
    const [disabled, setDisabled] = useState(false)
const [taxes,setTaxes]=useState()
    const [lat, setLat] = useState(18.5204)
    const [long, setLong] = useState(73.8567)
    const [paymentMode,setPaymentMode]=useState("")
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        getvalue()
        getAssignedDriver()
        getPaymentDetails()
        // getTaxes()
    }, [])

    // const getTaxes=()=>{
    //     const token = localStorage.getItem('token')

    //     const config = {
    //         headers: { Authorization: `Bearer ${token}` },
    //     }
    //     axios
    //         .get(
    //             `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/invoice-taxes/machine`,
    //             config
    //         )
    //         .then((res) => {
    //             setTaxes(res.data.data[0])
               
    //         })
    // }
    const CropOptions = [
        {
            label: 'HR',
            value: 'HR',
        },
        {
            label: 'ACER',
            value: 'ACER',
        },
        {
            label: 'TRIP',
            value: 'TRIP',
        },
    ]
    const PaymentOptions = [
        {
            label: 'Debit Card',
            value: 'Debit Card',
        },
        {
            label: 'NEFT',
            value: 'NEFT',
        },
        {
            label: 'UPI',
            value: 'UPI',
        },
        {
            label: 'Online',
            value: 'Online',
        },
        {
            label: 'Cash',
            value: 'Cash',
        },
    ]

    const handleCancelAssign = () => {
        console.log('ress')
        setOpensAssign(true)
    }
    const closeAssignDialog = () => {
        setOpensAssign(false)
    }

    const handleclickopens = () => {
        console.log('truepopup')
        setOpens(true)
    }
    const getPaymentDetails = () => {
        const u = location.pathname
        const orderId = u.split('/')[2]
        const token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-payment/${orderId}`,
                config
            )
            .then((res) => {
                console.log(res)
                if (res.data.paymentStatus) {
                    setPaymentStatus(true)
                    // setTransactionId(res.data.orderId)
                }
            })
    }

    const getvalue = async () => {
        const u = location.pathname
        const orderId = u.split('/')[2]
        setOrderId(orderId)
        const token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/machine/${orderId}`,
                config
            )
            .then((res) => {
                console.log('machining data: ', res)
                if (res.data.agrimachine.cancelled) setDisabled(true)
                if (res.data.agrimachine.taxes) setTaxes(res.data.agrimachine.taxes)
                setDetails1(res.data.agrimachine)
                if (res.data.agrimachine.invoiceId) {
                    setInvoiceId(res.data.agrimachine.invoiceId)
                }
                if (res.data.agrimachine.status === 'Accepted') {
                    setActiveColor('green')
                }
                if (res.data.agrimachine.status === 'Reached') {
                    setActiveColor('green')
                    setActiveColor1('green')
                    setActiveColor2('green')
                    setTab1(true)
                    setTab2(true)
                    setTab3(true)
                }
                if (res.data.agrimachine.status === 'Start') {
                    setActiveColor('green')
                    setActiveColor1('green')
                    setActiveColor2('green')
                    setTab1(true)
                    setTab1(true)
                    setTab2(true)
                    setTab3(true)
                    setTab4(true)
                }
                if (res.data.agrimachine.status === 'End') {
                    setActiveColor('green')
                    setActiveColor1('green')
                    setActiveColor2('green')
                    setActiveColor3('green')
                    setActiveColor4('green')

                    setTab1(true)
                    setTab2(true)
                    setTab3(true)
                    setTab4(true)
                    setTab5(true)
                }
                if (res.data.agrimachine.payment_status){
                    setPaymentStatus(true)
                    setTransactionNotes(res.data.agrimachine.payment.transaction_notes)

                }
                if (res.data.agrimachine.transactionId){
                    setPaymentStatus(true)
                    setTransactionId(res.data.agrimachine.transactionId)

                }
                const user = {
                    userId: res.data.agrimachine.user_id,
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-user`,
                        user,
                        config
                    )
                    .then((res) => {
                        if (res.data.user.length) {
                            setBooking(res.data.user[0])
                        } else {
                            setBooking({})
                        }
                    })

                const createdBy = {
                    userId: res.data.agrimachine.createdById,
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-user`,
                        createdBy,
                        config
                    )
                    .then((res) => {
                        if (!res.data.user.length) return

                        const { id, role, mobile_number } = res.data.user[0]
                        setCreatedByData({
                            id,
                            role,
                            mobile_number,
                        })
                    })
            })
    }
    const getAssignedDriver = () => {
        const input = localStorage.getItem('Bookingmachineid')
        const token = localStorage.getItem('token')
        const u = location.pathname
        const orderId = u.split('/')[2]
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-assigned-driver/${orderId}`,
                config
            )
            .then((res) => {
                console.log('res', res.data)
                setAssignedDriverData(res.data.drivers)
                if (res.data.drivers.length) {
                    setTab1(true)
                    setTab2(true)
                    setActiveColor('green')
                }
                console.log('assignedDriverData', assignDriverData)
            })
    }
    const FirmDetailsvalue = async () => {
        // console.log(localStorage.getItem('farmerid'), "hgfjgjfjh")
        const { data } = await API.get(
            `/getMachine?type=0&id=` + localStorage.getItem('farmerid')
        )
        console.log('data', data)
        setFirmDetails(data?.result)
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const inputval = async (val) => {
        const { data } = await API.get(
            `/getAgriMachineByNumber?mobileNumber=` + val
        )

        setDetailsInput(data?.result)
    }
    const BillingTypevalue = [
        {
            label: 'CASH',
            value: 'CASH',
        },
        {
            label: 'ONLINE',
            value: 'ONLINE',
        },
    ]
    const handleBook = async (e) => {
        e.preventDefault()
        console.log('handleChange', updatedvalue)
        let inputBook = { ...updatedvalue, id: updatedvalue._id }
        try {
            const { data } = await API.post('/updateMachineBooking', inputBook)
            if (data?.success) {
                getvalue()
                setEditProducts(false)
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })

                console.log(data)
                //  setSellCrop(SellCropClear);
                //  setDocImg(null)
                //  setFiles([])
                //  getSellCropBooking()
                // setCropTypes([]);
            } else {
                getvalue()
                setEditProducts(false)
                //   setSellCrop(SellCropClear);
                //  setDocImg(null)
                //  setFiles([])
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            getvalue()
            setEditProducts(false)
            // setSellCrop(SellCropClear);
            // setDocImg(null)
            // setFiles([])
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handlecloses = () => {
        setOpens(false)
        handleClose()
    }
    const categoryoption = [
        { value: '', label: 'Select Category' },
        { value: 'agri-machine', label: 'AGRI MACHINE' },
        { value: 'agri-transport', label: 'AGRI TRANSPORT' },
        { value: 'trader', label: 'TRADER' },
        { value: 'micro-entrepreneur', label: 'MIC ENTREPRENEUR' },
    ]

    const handlemachinechangeunit = (e) => {
        setUpdatedvalue({ ...updatedvalue, unit: e.value })
        setunitName({
            value: e.value,
            label: e.value,
        })
    }
    const handlemachinechange = (e) => {
        setUpdatedvalue({ ...updatedvalue, billingType: e.value })
        setbillingType({
            value: e.value,
            label: e.value,
        })
    }
    useEffect(() => {
        handlepopub()
    }, [])

    const handlepopub = () => {
        return <div id="popub">hiiiiii</div>
    }

    const searchDriver = (e) => {
        var token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            role: e.value,
            district: District,
        }
        setAssignedData(data)

        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-drivers`,
                data,
                config
            )
            .then((res) => {
                console.log('res', res.data.data)
                setDriverList(res.data.mobile_numbers)
                var options = []
                res.data.mobile_numbers.map((item, ind) => {
                    options.push({
                        value: item.mobile_number,
                        label: item.mobile_number,
                    })
                })
                setDriverList1(options)
            })
        console.log(e.value, e)
    }

    const fetchByphone = (value) => {
        const token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        if (value.length === 10) {
            var payload = {
                mobile_no: value,
            }
            axios
                .post(
                    'https://prod-api.krishione.com/farmer-function/register-details',
                    payload,
                    config
                )
                .then((res) => {
                    console.log(res.data)

                    setAssignedData({
                        ...assignData,
                        mobile_number: res.data.data.data.mobile_number,
                        trader_id: res.data.data.data.id,
                        name:
                            res.data.data.data.first_name +
                            ' ' +
                            res.data.data.data.last_name,
                    })
                    setDrivers([res.data.data.data])
                    setDriverList(res.data.data.data.mobile_numbers)
                    setDistrict(res.data.data.data.district)
                    setLat(res.data.data.data.coordinates.coordinates[1])
                    setLong(res.data.data.data.coordinates.coordinates[0])
                    var options = [
                        {
                            value: res.data.data.data.mobile_number,
                        },
                    ]
                    // res.data.map((item, ind) => {
                    //     options.push({
                    //         value: item.mobile_number,
                    //         label: item.mobile_number,
                    //     })
                    // })
                    setDriverList1(options)
                })
        } else {
            return
        }
    }

    const setDriver = (e) => {
        var details = driverList.filter((item) => item.mobile_number == e.value)

        setDrivers(details)
        setAssignedData({
            ...assignData,
            mobile_number: details[0].mobile_number,
            trader_id: details[0].id,
            name: details[0].first_name + ' ' + details[0].last_name,
        })
    }

    const mapdataPickup = (data) => {
        console.log(data)
        setDistrict(data.subDistrict)
    }

    const handleAssign = () => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            ...assignData,
            bookingId: orderId,
            type: 'machine',
            district: District,
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/assign-driver`,
                data,
                config
            )
            .then((res) => {
                setActiveColor('green')
                setTab1(true)
                setTab2(true)
                var data = {
                    bookingId: orderId,
                    status: 'Accepted',
                    type: 'machine',
                }
                console.log('handleActive')
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/set-booking-status`,
                        data,
                        config
                    )
                    .then((res) => {
                        console.log('res', res)
                        setActiveColor('green')
                        setActiveColor1('green')
                        setActiveColor3('')
                        setTab1(true)
                        setTab2(true)
                    })
                    .catch((err) => {
                        console.log('err', err)
                        setActiveColor2('')
                    })
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    // setDocImg(null)
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            })
            .catch((err) => console.log(err))
        console.log(data)
        // var data = {
        //    bookingId: localStorage.getItem('Bookingsellcropid'),

        // }
    }

    const handleActive2 = (status) => {
        var token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            bookingId: orderId,
            status: status,
            type: 'machine',
        }

        console.log('handleActive')
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/set-booking-status`,
                data,
                config
            )
            .then((res) => {
                console.log('res', res)
                setActiveColor('green')
                setActiveColor1('green')
                setActiveColor2('green')
                setActiveColor3('')
                setTab1(true)
                setTab2(true)
                setTab3(true)
            })
            .catch((err) => {
                console.log('err', err)
                setActiveColor2('')
            })
    }
    const handleActive3 = (status) => {
        var token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            bookingId: orderId,
            status: status,
            type: 'machine',
        }

        console.log('handleActive')
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/set-booking-status`,
                data,
                config
            )
            .then((res) => {
                console.log('res', res)
                setActiveColor('green')
                setActiveColor1('green')
                setActiveColor2('green')
                setActiveColor3('green')
                setTab1(true)
                setTab2(true)
                setTab3(true)
                setTab4(true)
            })
            .catch((err) => {
                console.log('err', err)
                setActiveColor3('')
            })
    }
    const handleActive4 = (status) => {
        var token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data = {
            bookingId: orderId,
            status: status,
            type: 'machine',
        }

        console.log('handleActive')
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/set-booking-status`,
                data,
                config
            )
            .then((res) => {
                console.log('res', res)
                setActiveColor('green')
                setActiveColor1('green')
                setActiveColor2('green')
                setActiveColor3('green')
                setActiveColor4('green')
                setTab1(true)
                setTab2(true)
                setTab3(true)
                setTab4(true)
                setTab5(true)
            })
            .catch((err) => {
                console.log('err', err)
                setActiveColor3('')
            })
        var data2 = {
            bookingId: orderId,
            type: 'machine',
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/generate-invoice`,
                data2,
                config
            )
            .then((res) => {
                console.log('res', res)
                setInvoiceId(res.data.data.invoiceId)
                // setI("green")
            })
            .catch((err) => {
                console.log('err', err)
                // setI("")
            })
    }
    const openpopub = () => {
        document.getElementById('popub').style.display = 'block'
    }

    const cancelBooking = () => {
        const u = location.pathname
        const orderId = u.split('/')[2]
        const token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/cancel-booking`,
                {
                    bookingId: orderId,
                    type: 'machine',
                },
                config
            )
            .then((res) => setDisabled(true))
    }
    const handlePaymentStatus=(e)=>{
        const token = localStorage.getItem('token')

        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var data={
            amount:details1.payment.indicate_order_value,
          user_id:details1.user_id,
          currency: "INR",
          orderId:orderId,
          quotationId:orderId,
          createdById:details1.createdById,
          assignById:details1.traderId,
          created_at: Date.now(),
          completed: false,
          isVisible: false,
          paymentStatus: "captured",
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/update-cashpayment`,
                {
                    wallet_data:data,
                    bookingId: orderId,
                    type: 'machine',
                    "billing_type": paymentMode.value,
                    "transaction_notes":description,
                    "date":startDate
                },
                config
            )
            .then((res) =>{
                setPaymentModal(false)
                alert("Payment Updated!")
            } )
    }

    return (
        <div class="content container-fluid">
            <div
                class="page-header"
                style={
                    disabled ? { pointerEvents: 'none', opacity: '0.4' } : {}
                }
            >
                <div class="row align-items-center">
                    <div class="col">
                        {console.log('Details', Details)}
                        <h3 class="page-title">#{invoiceId}</h3>
                        <ul class="breadcrumb">
                            <li
                                style={{ cursor: 'pointer' }}
                                class="breadcrumb-item"
                            >
                                <a
                                    onClick={() => setInvoiceOpen(true)}
                                    style={{ color: 'green' }}
                                >
                                    <u>View Invoice</u>
                                </a>
                            </li>
                            <li
                                style={{ cursor: 'pointer' }}
                                class="breadcrumb-item"
                            >
                                <a
                                    onClick={() => cancelBooking()}
                                    style={{ color: 'green' }}
                                >
                                    <u>Cancel Booking</u>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <Dialog
                className='transparent'
                    fullWidth={'xl'}
                    maxWidth={'md'}
                    open={invoiceopen}
                    onClose={() => setInvoiceOpen(false)}
                >
                    <MachineTransportInvoice
                        Details={details1}
                        trader={assignDriverData[0]}
                        booking={booking}
                        TotalPrice={TotalPrice}
                        taxes={taxes}
                    />
                </Dialog>
                <Drawer
                    open={paymentModal}
                    onClose={()=>setPaymentModal(false)}
                    anchor="right"

                >

                    <div
                        class="col-md-3"
                        style={{
                            marginTop: '2%',
                            width: 500, padding: 31,height: 500,
                        }}
                    >
                        <h3>Select Payment Mode</h3>
                        <FormControl
                            variant="standard"
                            fullWidth
                            className="mt-3"
                        >
                            {/* <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Booking Mode</InputLabel> */}
                            <Select
                                style={{
                                    width: '270px',
                                    height: '40px',
                                }}
                                placeholder={'Select Payment Mode'}
                                value={paymentMode}
                                onChange={(e) => setPaymentMode(e)}
                                //   onChange={(e)=>setUpdateDetails({...UpdateDetails, unit: e.value})}
                                options={PaymentOptions}
                            />
                            <br/>
                            <label>Select Date</label>
<br/>
                            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                            <br/>
                             <label>Transaction Notes</label>
                                    <textarea
                                        class="form-control"
                                        rows="3"
                                        onChange={(e)=>setDescription(e.target.value)}
                                        cols="30"
                                        // onChange={(e) => handlecreate(e)}
                                        // id="Biography"
                                        // value={data.Biography}
                                    ></textarea>
                            <button variant="contained" onClick={(e)=>handlePaymentStatus(e)}
                                                            component="span"
                                                            color="success"  className="btn btn-primary mt-3">Submit</button>
                            
                        </FormControl>

                    </div>
                </Drawer>
                <div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div
                                class="card card-table cardMain"
                                style={{ overflow: 'hidden' }}
                            >
                                <div class="tab-content p-0">
                                    <div action="#">
                                        <ul className="nav nav-tabs nav-tabs-bottom nav-justified navs">
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab1
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab1"
                                                    data-bs-toggle="tab"
                                                    onClick={(e) => setTab(1)}
                                                >
                                                    Pending
                                                </a>
                                                <span
                                                    className={
                                                        tab1
                                                            ? 'bread active'
                                                            : 'bread'
                                                    }
                                                ></span>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab2
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab2"
                                                    data-bs-toggle="tab"
                                                >
                                                    Accepted
                                                </a>
                                                <span
                                                    className={
                                                        tab2
                                                            ? 'bread active'
                                                            : 'bread'
                                                    }
                                                ></span>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab3
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab3"
                                                    data-bs-toggle="tab"
                                                >
                                                    Reached
                                                </a>
                                                <span
                                                    className={
                                                        tab3
                                                            ? 'bread active'
                                                            : 'bread'
                                                    }
                                                ></span>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab4
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab4"
                                                    data-bs-toggle="tab"
                                                >
                                                    Start
                                                </a>
                                                <span
                                                    className={
                                                        tab4
                                                            ? 'bread active'
                                                            : 'bread'
                                                    }
                                                ></span>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class={
                                                        tab5
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    href="#bottom-justified-tab5"
                                                    data-bs-toggle="tab"
                                                >
                                                    End
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xl-8 col-md-8">
                                    <div class="card">
                                        <div class="card-body p_7">
                                            <form>
                                                <div class="row form-group">
                                                    <div class="col-md-12">
                                                        {!editProducts ? (
                                                            <>
                                                                <div class="card-body card-body_1">
                                                                    <h5 class="card-title d-flex justify-content-between store_edit">
                                                                        <span></span>
                                                                        <button
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.preventDefault()
                                                                                setEditProducts(
                                                                                    true
                                                                                )
                                                                            }}
                                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                                            style={{
                                                                                padding:
                                                                                    '0rem 0.75rem',
                                                                            }}
                                                                        >
                                                                            <span>
                                                                                <u>
                                                                                    Edit
                                                                                    Items
                                                                                </u>
                                                                            </span>
                                                                        </button>
                                                                    </h5>
                                                                    <ul class="list-unstyled mb-0 store_list">
                                                                        <div className="row">
                                                                            {/* <div className="col-md-12 mb-4">
                                        <li class="pt-2 pb-0">
                                          <small className="text-dark">Location</small>
                                        </li>
                                        <li className="ml-2">
                                          <span> - </span>
                                        </li>
                                      </div> */}
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Crop
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <li class="pt-2 pb-0">
                                                                                        <small class="">
                                                                                            {' '}
                                                                                            -{' '}
                                                                                            {
                                                                                                details1
                                                                                                    ?.crop
                                                                                                    ?.name
                                                                                            }
                                                                                        </small>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div
                                                                                            style={
                                                                                                thumb
                                                                                            }
                                                                                            sx={{
                                                                                                backgroundColor:
                                                                                                    'grey',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                style={
                                                                                                    thumbInner
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        details1
                                                                                                            ?.crop
                                                                                                            ?.image
                                                                                                    }
                                                                                                    style={
                                                                                                        img
                                                                                                    }
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Type
                                                                                        of
                                                                                        machine
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <li class="pt-2 pb-0">
                                                                                        <small class="">
                                                                                            {details1
                                                                                                ?.machine
                                                                                                ?.type_of_machine
                                                                                                ? details1
                                                                                                      .machine
                                                                                                      .type_of_machine
                                                                                                : '--'}
                                                                                        </small>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div
                                                                                            style={
                                                                                                thumb
                                                                                            }
                                                                                            sx={{
                                                                                                backgroundColor:
                                                                                                    'grey',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                style={
                                                                                                    thumbInner
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        details1
                                                                                                            ?.machine
                                                                                                            ?.image
                                                                                                    }
                                                                                                    style={
                                                                                                        img
                                                                                                    }
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        machine
                                                                                        model
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <li class="pt-2 pb-0">
                                                                                        <small class="">
                                                                                            {' '}
                                                                                            {
                                                                                                details1
                                                                                                    ?.machine
                                                                                                    ?.machine_model
                                                                                            }
                                                                                        </small>
                                                                                    </li>
                                                                                    {/* <li>
                                            <div style={thumb} sx={{ backgroundColor: 'grey' }}>
                                              <div style={thumbInner}>
                                                <img src={""} style={img} alt="" />
                                              </div>
                                            </div>
                                          </li> */}
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Area
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    {/* <span> {Details.area}.</span> */}
                                                                                    <span>
                                                                                        {' '}
                                                                                        Unit:{' '}
                                                                                        {
                                                                                            details1
                                                                                                ?.area
                                                                                                ?.unit
                                                                                        }
                                                                                    </span>
                                                                                    <br />
                                                                                    <span>
                                                                                        {' '}
                                                                                        Land
                                                                                        Size:{' '}
                                                                                        {
                                                                                            details1
                                                                                                ?.area
                                                                                                ?.land_size
                                                                                        }
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Date
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        {
                                                                                            details1
                                                                                                ?.service_time
                                                                                                ?.date
                                                                                        }{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Time
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        {
                                                                                            details1
                                                                                                .service_time
                                                                                                ?.time
                                                                                        }{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Billing
                                                                                        Type
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        {
                                                                                            details1
                                                                                                ?.payment
                                                                                                ?.billing_type
                                                                                        }{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <small className="text-dark">
                                                                                        Indicative
                                                                                        Order
                                                                                        Value
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        {
                                                                                            details1.userAmount
                                                                                                
                                                                                        }{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                        </div>
                                                                    </ul>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div class="card-body card-body_1">
                                                                    <h5 class="card-title d-flex justify-content-between store_edit">
                                                                        <ul class="list-unstyled mb-0 store_list">
                                                                            <li class="pt-2 pb-0">
                                                                                <span class="text-dark">
                                                                                    Update
                                                                                    Info
                                                                                </span>
                                                                            </li>
                                                                            <li class="pt-2 pb-0"></li>
                                                                        </ul>
                                                                        <button
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.preventDefault()
                                                                                setEditProducts(
                                                                                    false
                                                                                )
                                                                            }}
                                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                                            style={{
                                                                                padding:
                                                                                    '0rem 0.75rem',
                                                                            }}
                                                                        >
                                                                            <span>
                                                                                Cancel
                                                                            </span>
                                                                        </button>
                                                                    </h5>
                                                                    <form>
                                                                        <ul class="list-unstyled mb-0 store_list">
                                                                            <div className="row">
                                                                                <div className="col-md-4 mb-4">
                                                                                    <li class="pt-2 pb-0">
                                                                                        <small className="text-dark">
                                                                                            Crop
                                                                                        </small>
                                                                                    </li>
                                                                                    <li className="ml-2">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="">
                                                                                                {' '}
                                                                                                {
                                                                                                    updatedvalue.crop
                                                                                                }
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            <div
                                                                                                style={
                                                                                                    thumb
                                                                                                }
                                                                                                sx={{
                                                                                                    backgroundColor:
                                                                                                        'grey',
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    style={
                                                                                                        thumbInner
                                                                                                    }
                                                                                                >
                                                                                                    <img
                                                                                                        src={
                                                                                                            updatedvalue.cropImage
                                                                                                        }
                                                                                                        style={
                                                                                                            img
                                                                                                        }
                                                                                                        alt=""
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </li>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-md-4 mb-4">
                                                                                    <li class="pt-2 pb-0">
                                                                                        <small className="text-dark">
                                                                                            Type
                                                                                            of
                                                                                            machine
                                                                                        </small>
                                                                                    </li>
                                                                                    <li className="ml-2">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="">
                                                                                                {' '}
                                                                                                {
                                                                                                    updatedvalue.machineType
                                                                                                }
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            <div
                                                                                                style={
                                                                                                    thumb
                                                                                                }
                                                                                                sx={{
                                                                                                    backgroundColor:
                                                                                                        'grey',
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    style={
                                                                                                        thumbInner
                                                                                                    }
                                                                                                >
                                                                                                    <img
                                                                                                        src={
                                                                                                            updatedvalue.machineTypeImage
                                                                                                        }
                                                                                                        style={
                                                                                                            img
                                                                                                        }
                                                                                                        alt=""
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </li>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-md-4 mb-4">
                                                                                    <li class="pt-2 pb-0">
                                                                                        <small className="text-dark">
                                                                                            machine
                                                                                            model
                                                                                        </small>
                                                                                    </li>
                                                                                    <li className="ml-2">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="">
                                                                                                {' '}
                                                                                                {
                                                                                                    updatedvalue.machineModel
                                                                                                }
                                                                                            </small>
                                                                                        </li>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-md-4 mb-4">
                                                                                    <li class="pt-2 pb-0">
                                                                                        <small className="text-dark">
                                                                                            Area
                                                                                        </small>
                                                                                    </li>
                                                                                    <li className="ml-2">
                                                                                        <span>
                                                                                            <InputLabel
                                                                                                id="demo-simple-select-standard-label"
                                                                                                style={{
                                                                                                    color: 'black',
                                                                                                }}
                                                                                            >
                                                                                                Unit
                                                                                            </InputLabel>
                                                                                            <FormControl
                                                                                                variant="standard"
                                                                                                fullWidth
                                                                                                className="mt-3"
                                                                                            >
                                                                                                <Select
                                                                                                    style={{
                                                                                                        width: '270px',
                                                                                                        height: '40px',
                                                                                                    }}
                                                                                                    //  onChange={(e)=>handlecategory(e)}
                                                                                                    value={
                                                                                                        unitName
                                                                                                    }
                                                                                                    onChange={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        handlemachinechangeunit(
                                                                                                            e
                                                                                                        )
                                                                                                    }
                                                                                                    // onChange={(e)=>setUpdatedvalue({...updatedvalue,unit:e.value})}
                                                                                                    placeholder={
                                                                                                        'Select the Unit'
                                                                                                    }
                                                                                                    options={
                                                                                                        CropOptions
                                                                                                    }
                                                                                                />
                                                                                            </FormControl>
                                                                                            {/* </div> */}
                                                                                        </span>
                                                                                    </li>
                                                                                </div>
                                                                                <div
                                                                                    className="col-md-4 mb-4"
                                                                                    style={{
                                                                                        marginTop:
                                                                                            '2%',
                                                                                    }}
                                                                                >
                                                                                    <li class="pt-1 pb-1">
                                                                                        <small className="text-dark"></small>
                                                                                    </li>
                                                                                    <li className="ml-2 mt-3">
                                                                                        <span>
                                                                                            <InputLabel
                                                                                                id="demo-simple-select-standard-label"
                                                                                                style={{
                                                                                                    color: 'black',
                                                                                                }}
                                                                                            >
                                                                                                Land
                                                                                                Size
                                                                                            </InputLabel>
                                                                                            <CssTextField
                                                                                                fullWidth
                                                                                                variant="standard"
                                                                                                name="totalLand"
                                                                                                // label="Land Size"

                                                                                                value={
                                                                                                    updatedvalue.area
                                                                                                }
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    setUpdatedvalue(
                                                                                                        {
                                                                                                            ...updatedvalue,
                                                                                                            area: e
                                                                                                                .target
                                                                                                                .value,
                                                                                                        }
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    </li>
                                                                                </div>
                                                                                <div
                                                                                    className="col-md-4 mb-4"
                                                                                    style={{
                                                                                        marginTop:
                                                                                            '4%',
                                                                                    }}
                                                                                >
                                                                                    <li class="pt-2 pb-1">
                                                                                        <small className="text-dark">
                                                                                            Date
                                                                                        </small>
                                                                                    </li>
                                                                                    <li className="ml-2">
                                                                                        <span>
                                                                                            <CssTextField
                                                                                                fullWidth
                                                                                                type="date"
                                                                                                label=""
                                                                                                variant="standard"
                                                                                                name="totalLand"
                                                                                                className="mt-2"
                                                                                                value={
                                                                                                    updatedvalue.fromdate
                                                                                                }
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    setUpdatedvalue(
                                                                                                        {
                                                                                                            ...updatedvalue,
                                                                                                            fromdate:
                                                                                                                e
                                                                                                                    .target
                                                                                                                    .value,
                                                                                                        }
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    </li>
                                                                                </div>
                                                                                <div className="col-md-4 mb-4">
                                                                                    <li class="pt-2 pb-1">
                                                                                        <small className="text-dark">
                                                                                            Time
                                                                                        </small>
                                                                                    </li>
                                                                                    <li className="ml-2">
                                                                                        <span>
                                                                                            <CssTextField
                                                                                                fullWidth
                                                                                                type="time"
                                                                                                label=""
                                                                                                variant="standard"
                                                                                                name="totalLand"
                                                                                                className="mt-2"
                                                                                                value={
                                                                                                    updatedvalue.fromTime
                                                                                                }
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    setUpdatedvalue(
                                                                                                        {
                                                                                                            ...updatedvalue,
                                                                                                            fromTime:
                                                                                                                e
                                                                                                                    .target
                                                                                                                    .value,
                                                                                                        }
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    </li>
                                                                                </div>
                                                                                <div
                                                                                    class="col-md-4"
                                                                                    style={{
                                                                                        marginTop:
                                                                                            '3%',
                                                                                    }}
                                                                                >
                                                                                    <FormControl
                                                                                        variant="standard"
                                                                                        fullWidth
                                                                                        className="mt-3"
                                                                                    >
                                                                                        <Select
                                                                                            style={{
                                                                                                width: '270px',
                                                                                                height: '40px',
                                                                                            }}
                                                                                            //  onChange={(e)=>handlecategory(e)}
                                                                                            value={
                                                                                                billingType
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                handlemachinechange(
                                                                                                    e
                                                                                                )
                                                                                            }
                                                                                            // onChange={(e)=>setUpdatedvalue({...updatedvalue,billingType:e.value})}
                                                                                            placeholder={
                                                                                                'Select the Billing Type'
                                                                                            }
                                                                                            options={
                                                                                                BillingTypevalue
                                                                                            }
                                                                                        />
                                                                                    </FormControl>
                                                                                </div>
                                                                            </div>
                                                                        </ul>
                                                                    </form>
                                                                    <div class="col-md-12 text-end mt-2 mb-2">
                                                                        <button
                                                                            class="btn btn-primary"
                                                                            onClick={(
                                                                                e
                                                                            ) =>
                                                                                handleBook(
                                                                                    e
                                                                                )
                                                                            }
                                                                        >
                                                                            Update
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <div class="card">
                                                <div class="card-body p_7">
                                                    <form>
                                                        <div class="row form-group">
                                                            <div class="col-md-12">
                                                                <div class="card-body card-body_1">
                                                                    <h5 class="card-title d-flex justify-content-between store_edit">
                                                                        <span>
                                                                            Machine
                                                                            Task
                                                                        </span>
                                                                        <a
                                                                            href="#"
                                                                            class="btn btn-tbl-edit btn-xs td_btn"
                                                                        ></a>
                                                                    </h5>
                                                                    <ul class="list-unstyled mb-0 store_list">
                                                                        <div className="row">
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <CheckCircleIcon
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                            color: activeColor,
                                                                                        }}
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleActive2(
                                                                                                'Reached'
                                                                                            )
                                                                                        }
                                                                                    />

                                                                                    <small className="text-secondary">
                                                                                        Accepted
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <CheckCircleIcon
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                            color: activeColor2,
                                                                                        }}
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleActive2(
                                                                                                'Reached'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <small className="text-secondary">
                                                                                        Reached
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <CheckCircleIcon
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                            color: activeColor3,
                                                                                        }}
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleActive3(
                                                                                                'Start'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <small className="text-secondary">
                                                                                        Start
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            <div className="col-md-4 mb-4">
                                                                                <li class="pt-2 pb-0">
                                                                                    <CheckCircleIcon
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                            color: activeColor4,
                                                                                        }}
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleActive4(
                                                                                                'End'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <small className="text-secondary">
                                                                                        End
                                                                                    </small>
                                                                                </li>
                                                                                <li className="ml-2">
                                                                                    <span>
                                                                                        {' '}
                                                                                        -{' '}
                                                                                    </span>
                                                                                </li>
                                                                            </div>
                                                                            {/* <div className="col-md-4 mb-4">
                                        <li class="pt-2 pb-0">
                                          <CheckCircleIcon style={{ marginRight: '5px' }} /><small className="text-secondary">End</small>
                                        </li>
                                        <li className="ml-2">
                                          <span> - </span>
                                        </li>
                                      </div> */}
                                                                        </div>
                                                                    </ul>
                                                                    <hr />
                                                                    <div className="assigncancel">
                                                                        <h5>
                                                                            Driver
                                                                            Details
                                                                        </h5>
                                                                        <div
                                                                            style={{
                                                                                paddingLeft:
                                                                                    '300px',
                                                                            }}
                                                                        >
                                                                            {assignDriverData.length ? (
                                                                                <Button
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            '#60a132',
                                                                                        color: 'white',
                                                                                    }}
                                                                                    onClick={
                                                                                        handleCancelAssign
                                                                                    }
                                                                                >
                                                                                    Cancel
                                                                                    Assign
                                                                                </Button>
                                                                            ) : null}
                                                                        </div>
                                                                        <Dialog
                                                                            open={
                                                                                openAssign
                                                                            }
                                                                            onClose={() =>
                                                                                closeAssignDialog()
                                                                            }
                                                                            aria-labelledby="form-dialog-title"
                                                                        >
                                                                            <CancelAssignDialog
                                                                                bookingId={
                                                                                    orderId
                                                                                }
                                                                                type="machine"
                                                                                assignId={
                                                                                    assignDriverData
                                                                                }
                                                                            />
                                                                        </Dialog>
                                                                    </div>
                                                                    <a className="store-avatar">
                                                                        {assignDriverData.length ? null : (
                                                                            <div
                                                                                style={{
                                                                                    marginRight:
                                                                                        '10px',
                                                                                }}
                                                                                onClick={
                                                                                    handleClick
                                                                                }
                                                                            >
                                                                                <AddCircleOutlineIcon />
                                                                            </div>
                                                                        )}
                                                                        <div
                                                                            class="store_display"
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                                flexDirection:
                                                                                    'row',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '50px',
                                                                                    width: '25%',
                                                                                }}
                                                                            >
                                                                                <h4>
                                                                                    Name
                                                                                </h4>
                                                                                <span>
                                                                                    {assignDriverData.length
                                                                                        ? assignDriverData[0]
                                                                                              .name
                                                                                        : ''}
                                                                                </span>
                                                                            </div>

                                                                            <div
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '50px',
                                                                                    width: '25%',
                                                                                }}
                                                                            >
                                                                                <h4>
                                                                                    MobileNumber
                                                                                </h4>
                                                                                <span>
                                                                                    {assignDriverData.length
                                                                                        ? assignDriverData[0]
                                                                                              .mobile_number
                                                                                        : ''}
                                                                                </span>
                                                                            </div>

                                                                            <div
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '50px',
                                                                                }}
                                                                            >
                                                                                <h4>
                                                                                    District
                                                                                </h4>
                                                                                <span>
                                                                                    {assignDriverData.length
                                                                                        ? assignDriverData[0]
                                                                                              .district
                                                                                        : ''}
                                                                                </span>
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '50px',
                                                                                }}
                                                                            >
                                                                                <h4>
                                                                                    Driver
                                                                                    Id
                                                                                </h4>
                                                                                <span>
                                                                                    {assignDriverData.length
                                                                                        ? assignDriverData[0]
                                                                                              .trader_id
                                                                                        : ''}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <Menu
                                                                            id="basic-menu"
                                                                            anchorEl={
                                                                                anchorEl
                                                                            }
                                                                            open={
                                                                                open
                                                                            }
                                                                            onClose={
                                                                                handleClose
                                                                            }
                                                                            MenuListProps={{
                                                                                'aria-labelledby':
                                                                                    'basic-button',
                                                                            }}
                                                                        >
                                                                            <MenuItem
                                                                                onClick={() =>
                                                                                    handleclickopens()
                                                                                }
                                                                            >
                                                                                Assign
                                                                                Manually
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                onClick={
                                                                                    handleClose
                                                                                }
                                                                            >
                                                                                Assign
                                                                                Automatically
                                                                            </MenuItem>
                                                                        </Menu>
                                                                        {opens ==
                                                                        true ? (
                                                                            <Dialog
                                                                                className="mapmodel"
                                                                                open={
                                                                                    opens
                                                                                }
                                                                                onClose={
                                                                                    handlecloses
                                                                                }
                                                                                style={{
                                                                                    zIndex: 100,
                                                                                }}
                                                                            >
                                                                                <DialogTitle>
                                                                                    <b>
                                                                                        Assign
                                                                                        Driver
                                                                                    </b>
                                                                                </DialogTitle>
                                                                                <DialogContent>
                                                                                    <div
                                                                                        style={{
                                                                                            display:
                                                                                                'flex',
                                                                                        }}
                                                                                    >
                                                                                        <div>
                                                                                            <BookingMap
                                                                                                center={{
                                                                                                    lat: lat,
                                                                                                    lng: long,
                                                                                                }}
                                                                                                height="10px"
                                                                                                zoom={
                                                                                                    15
                                                                                                }
                                                                                                booking={
                                                                                                    true
                                                                                                }
                                                                                                mapdata={
                                                                                                    mapdataPickup
                                                                                                }
                                                                                            />

                                                                                            <div
                                                                                                style={{
                                                                                                    display:
                                                                                                        'flex',
                                                                                                    width: '290px',
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    style={{
                                                                                                        margin: '2%',
                                                                                                        width: '200px',
                                                                                                    }}
                                                                                                >
                                                                                                    <FormControl
                                                                                                        variant="standard"
                                                                                                        fullWidth
                                                                                                        className="mt-4"
                                                                                                    >
                                                                                                        <Select
                                                                                                            onChange={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                searchDriver(
                                                                                                                    e
                                                                                                                )
                                                                                                            }
                                                                                                            placeholder={
                                                                                                                'Choose Category'
                                                                                                            }
                                                                                                            options={
                                                                                                                categoryoption
                                                                                                            }
                                                                                                        />
                                                                                                    </FormControl>
                                                                                                </div>
                                                                                                <div
                                                                                                    style={{
                                                                                                        margin: '2%',
                                                                                                        width: '200px',
                                                                                                    }}
                                                                                                >
                                                                                                    <FormControl
                                                                                                        variant="standard"
                                                                                                        fullWidth
                                                                                                        className="mt-4"
                                                                                                    >
                                                                                                        <Select
                                                                                                            onChange={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                setDriver(
                                                                                                                    e
                                                                                                                )
                                                                                                            }
                                                                                                            placeholder={
                                                                                                                'Search Driver'
                                                                                                            }
                                                                                                            options={
                                                                                                                driverList1
                                                                                                            }
                                                                                                        />
                                                                                                    </FormControl>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <br />
                                                                                        <div
                                                                                            style={{
                                                                                                minWidth:
                                                                                                    '150px',
                                                                                            }}
                                                                                        >
                                                                                            <div>
                                                                                                Or
                                                                                                Search
                                                                                                By
                                                                                                Phone
                                                                                                No.
                                                                                            </div>
                                                                                            <div class="col">
                                                                                                <CssTextField
                                                                                                    fullWidth
                                                                                                    id="standard-basic"
                                                                                                    label="Mobile No.*"
                                                                                                    variant="standard"
                                                                                                    name="lastName"
                                                                                                    onChange={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        fetchByphone(
                                                                                                            e
                                                                                                                .target
                                                                                                                .value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </div>

                                                                                            <div
                                                                                                class="row"
                                                                                                className="mt-4"
                                                                                            >
                                                                                                {drivers.map(
                                                                                                    (
                                                                                                        item,
                                                                                                        index
                                                                                                    ) => {
                                                                                                        return (
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    width: '260px',
                                                                                                                    height: '70px',
                                                                                                                    marginBottom:
                                                                                                                        '50px',
                                                                                                                }}
                                                                                                            >
                                                                                                                <div
                                                                                                                    class="card card-body bg-gray"
                                                                                                                    style={{
                                                                                                                        display:
                                                                                                                            'flex',
                                                                                                                        flexDirection:
                                                                                                                            'row',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <div>
                                                                                                                        <h5>
                                                                                                                            {item.first_name +
                                                                                                                                ' ' +
                                                                                                                                item.last_name}
                                                                                                                        </h5>

                                                                                                                        <div>
                                                                                                                            <a href="#">
                                                                                                                                <span>
                                                                                                                                    {
                                                                                                                                        item.mobile_number
                                                                                                                                    }
                                                                                                                                </span>
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <div
                                                                                                                            class="circle"
                                                                                                                            style={{
                                                                                                                                width: '30px',
                                                                                                                                height: '30px',
                                                                                                                                padding:
                                                                                                                                    '5px',
                                                                                                                                marginLeft:
                                                                                                                                    '60px',
                                                                                                                                borderRadius:
                                                                                                                                    '50%',
                                                                                                                                background:
                                                                                                                                    '#fff',
                                                                                                                                border: ' 3px solid #000',
                                                                                                                                color: '#000',
                                                                                                                                textAlign:
                                                                                                                                    'center',
                                                                                                                                font: '14px Arial, sans-serif',
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            0
                                                                                                                        </div>

                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                marginLeft:
                                                                                                                                    '60px',
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <a href="#">
                                                                                                                                <span>
                                                                                                                                    task
                                                                                                                                </span>
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                )}
                                                                                                <div
                                                                                                    className="mt-3"
                                                                                                    style={{
                                                                                                        fontSize:
                                                                                                            '23px',
                                                                                                    }}
                                                                                                >
                                                                                                    District:{' '}
                                                                                                    <span
                                                                                                        style={{
                                                                                                            color: 'green',
                                                                                                        }}
                                                                                                    >
                                                                                                        {' '}
                                                                                                        {
                                                                                                            District
                                                                                                        }
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            display:
                                                                                                'flex',
                                                                                            flexDirection:
                                                                                                'row',
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            style={{
                                                                                                paddingLeft:
                                                                                                    '300px',
                                                                                            }}
                                                                                        >
                                                                                            <Button
                                                                                                style={{
                                                                                                    backgroundColor:
                                                                                                        '#60a132',
                                                                                                    color: 'white',
                                                                                                }}
                                                                                                onClick={
                                                                                                    handlecloses
                                                                                                }
                                                                                            >
                                                                                                Cancel
                                                                                            </Button>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{
                                                                                                marginLeft:
                                                                                                    '30px',
                                                                                            }}
                                                                                        >
                                                                                            <Button
                                                                                                style={{
                                                                                                    backgroundColor:
                                                                                                        '#60a132',
                                                                                                    color: 'white',
                                                                                                }}
                                                                                                onClick={
                                                                                                    handleAssign
                                                                                                }
                                                                                            >
                                                                                                Assign
                                                                                                Task
                                                                                            </Button>
                                                                                        </div>
                                                                                    </div>
                                                                                </DialogContent>
                                                                            </Dialog>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Booking from</span>
                                                <a
                                                    href="#"
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                ></a>
                                            </h5>
                                            <hr style={{ margin: 'auto' }} />
                                            <div className="row mt-3">
                                                <h6>Farmer</h6>
                                                <p>
                                                    First Name:{' '}
                                                    {booking.first_name}
                                                </p>
                                                <p>
                                                    Last Name:{' '}
                                                    {booking.last_name}
                                                </p>
                                                <p>
                                                    Mobile:{' '}
                                                    {booking.mobile_number}
                                                </p>
                                                <p>BookingId: {orderId}</p>
                                            </div>
                                            {/* <div class="staffAvatar-root1">R</div>
                                 <div class="store_display">
                                    <h4>Rajsekar</h4>
                                    <span>8098081991</span>
                                 </div> */}
                                        </div>

                                        <hr
                                            width="85%"
                                            style={{ margin: 'auto' }}
                                        />
                                        {DetailsInput &&
                                        DetailsInput.length > 0 ? (
                                            <div class="card-body ">
                                                <h5 class="card-title d-flex justify-content-between store_edit">
                                                    <span>
                                                        {' '}
                                                        Agri Machine Details
                                                    </span>
                                                    <a
                                                        href="#"
                                                        class="btn btn-tbl-edit btn-xs td_btn"
                                                    ></a>
                                                </h5>
                                                <a class="store-avatar">
                                                    <div class="staffAvatar-root1">
                                                        {DetailsInput &&
                                                        DetailsInput.farmerId &&
                                                        DetailsInput.farmerId
                                                            .firstName &&
                                                        DetailsInput.farmerId
                                                            .firstName.length >
                                                            0
                                                            ? DetailsInput.farmerId.firstName.slice(
                                                                  0,
                                                                  1
                                                              )
                                                            : ''}
                                                    </div>
                                                    <div class="store_display">
                                                        <h4>
                                                            {DetailsInput &&
                                                                DetailsInput.firstName +
                                                                    ' ' +
                                                                    DetailsInput &&
                                                                DetailsInput.lastName}
                                                        </h4>
                                                        <span>
                                                            {Details &&
                                                                Details.Firm}
                                                        </span>
                                                    </div>
                                                    {/* <div class="staffAvatar-root1">R</div>
                                 <div class="store_display">
                                    <h4>Rajsekar</h4>
                                    <span>8098081991</span>
                                 </div> */}
                                                </a>
                                            </div>
                                        ) : (
                                            <></>
                                        )}

                                        <hr
                                            width="85%"
                                            style={{ margin: 'auto' }}
                                        />
                                        <div class="card-body ">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Payment Details</span>
                                                <a
                                                    href="#"
                                                   
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                    style={{
                                                        padding: '0rem 0.75rem',
                                                    }}
                                                >
                                                    <span>
                                                        {paymentStatus ? (
                                                            <u>
                                                                {' '}
                                                                {transactionId}{transactionNotes}
                                                            </u>
                                                        ) : (
                                                            <u  onClick={() =>
                                                                setPaymentModal(
                                                                    !paymentModal
                                                                )
                                                            }>
                                                                Receive Payment
                                                            </u>
                                                        )}
                                                    </span>
                                                </a>
                                            </h5>
                                            <div className="col-md-4 mb-4">
                                                <small className="text-dark">
                                                    Amount:{' '}
                                                </small>
                                                <span>
                                                    {' '}
                                                    {
                                                        details1?.userAmount
                                                    }{' '}
                                                </span>
                                            </div>
                                            <div className="">
                                                <small className="text-dark">
                                                    Payment Status:{' '}
                                                </small>
                                                <span>
                                                    {' '}
                                                    {details1?.payment_status
                                                        ? 'Received'
                                                        : 'Not Recevied'}{' '}
                                                </span>
                                            </div>
                                            <p>
                                                {paymentStatus
                                                    ? 'Payment Recieved  -'
                                                    : 'Pending Amount -'}
                                                <span>
                                                    Rs.{' '}
                                                    {
                                                        details1?.userAmount
                                                    }
                                                </span>
                                            </p>
                                            <span>
                                                {paymentStatus ? (
                                                    <i>
                                                        {' '}
                                                        Payment Recieved via {details1?.payment
                                                            ?.cash_type || details1?.payment
                                                            ?.billing_type } 
                                                    </i>
                                                ) : (
                                                    <i>No transaction yet</i>
                                                )}
                                            </span>
                                            <br/>
                                            <p>
                                            <span>
                                            Date:    {details1?.payment
                                                            ?.date ? (
                                                    <i>
                                                        {' '}
                                                      {  new Date(details1?.payment
                                                            ?.date).toDateString()}
                                                    </i>
                                                ) :null}
                                            </span>
                                            </p>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Notes (0)</span>
                                                <a
                                                    href="#"
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                ></a>
                                            </h5>
                                            <hr style={{ margin: 'auto' }} />
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <CssTextField
                                                        fullWidth
                                                        id="totalLand"
                                                        variant="standard"
                                                        placeholder="Add a note...."
                                                        name="totalLand"
                                                        className="mb-0"
                                                    />
                                                </div>
                                                <div className="col-md-2">
                                                    <IconButton
                                                        color="success"
                                                        aria-label="add an alarm"
                                                        className="mt-3"
                                                    >
                                                        <SendIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Created By :</span>
                                            </h5>
                                            <ul class="list-unstyled mb-0 store_list">
                                                <li class="pt-2 pb-0">
                                                    <small class="text-dark">
                                                        Role :{' '}
                                                    </small>
                                                    <span>
                                                        {createdByData.role}
                                                    </span>
                                                </li>
                                                <li class="pt-2 pb-0">
                                                    <small class="text-dark">
                                                        ID :{' '}
                                                    </small>
                                                    <span>
                                                        {createdByData.id}
                                                    </span>
                                                </li>
                                                <li class="pt-2 pb-0">
                                                    <small class="text-dark">
                                                        Phone Number :{' '}
                                                    </small>
                                                    <span>
                                                        {
                                                            createdByData.mobile_number
                                                        }
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                <span>Booking from</span>
                                                <a
                                                    href="#"
                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                ></a>
                                            </h5>
                                            <hr style={{ margin: 'auto' }} />
                                            <div className="row mt-3">
                                                <h6>ID: {booking.id}</h6>
                                            </div>
                                            <div className="row mt-3">
                                                <h6>Role: {booking.role}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgriMachineBD
