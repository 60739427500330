import React, { useState, useEffect } from 'react'
import { CssTextField } from '../Styles'
import AddFarmer from './AddFarmer'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { useDropzone } from 'react-dropzone'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { API } from '../../API'
import Map2 from '../Create/Map2'

import FarmerTable from '../Create/CreateFarmer/FarmerTable'
import {
    thumbsContainerState,
    thumb,
    img,
    thumbInner,
    mapThumb,
    baseStyle,
} from '../Styles'
import axios from 'axios'
import ModuleOrders from '../../components/ModuleOrders'
import Wallet from '../../components/Wallet'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}
const names = ['Wheat', 'Rice', 'Barley', 'Cereal', 'Tomato', 'Oat']

function FPO() {
    const [addFarm, setAddFarm] = useState(false)
    const [addDoc, setAddDoc] = useState(false)
    const [addWallet, setAddWallet] = useState(false)
    const [managerDoc, setManagerDoc] = useState(false)
    const [cropTypes, setCropTypes] = React.useState([])
    const [Files, setFiles] = useState([])
    const [Details, setDetails] = useState({})
    const [subDetails, setSubDetails] = useState({})
    const [listCompData, setListCompData] = useState({})
    const [userId, setUserId] = useState('')
    const [idForAssign, setIdForAssign] = useState('')

    const [farmers, setFarmers] = useState([])
    useEffect(() => {
        getAllFarmers()
    }, [])

    const getAllFarmers = async () => {
        const { data } = await API.get(`/getFarmer?type=0`)
        setFarmers(data?.result)
    }

    useEffect(async () => {
        var farmerid = localStorage.getItem('FPOfarmerid')
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var payload = {
            mobile_no: farmerid,
            role: 'fpo',
        }
        axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-register`,
                payload,
                config
            )
            .then((res) => {
                let data = res.data
                var a = {
                    _id: data.data.data._id,
                    user_id: data.data.data._id,
                    firstName: data.data.data?.first_name,
                    lastName: data.data.data.last_name,
                    mobileNumber: data.data.data.mobile_number,
                    gender: data.data.data.gender,
                    category: data.data.data.role,
                    dateOfBirth: data.data.data.dob,
                    education: '',
                    language: data.data.data.language,
                    partnerMode: data.data.data.partner_mode,
                    state: data.data.data.state,
                    district: data.data.data.district,
                    subDistrict: data.data.data.sub_distric,
                    village: data.data.data.village,
                    pincode: data.data.data.pincode,
                    adhaarNumber: '',
                    fullAddress: data.data.data.full_address,
                    bankAccountNumber: '',
                    bankAccountName: '',
                    ifscCode: '',
                    lat: data.data.data.coordinates.coordinates[1],
                    long: data.data.data.coordinates.coordinates[0],
                    ceoName: data.data.data.company_info.ceo_name,
                    companyName: data.data.data.company_info.company_name,
                    noOfEmployees: data.data.data.company_info.no_of_employees,
                    started: data.data.data.company_info.start_date,
                    fundAmount: data.data.data.company_info.gov_funding,
                    bankName: '',
                    bankBranchName: '',
                    area: data.data?.farm_info?.total_land,
                    totalLand: data.data?.farm_info?.total_land,
                    createdAt: data.data?.farm_info?.created_at,
                    irrigation_source: 'Canal',
                    mobile_number: '6353454356',
                    soil_test: true,
                    soil_type: 'Alluvial soil',
                    sowing_date: '2022-04-08',
                }
                setUserId(data.data.data.user_id)
                setIdForAssign(data.data.data.id)

                setDetails(a)
            })
    }, [])

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
        },
    })

    const PGthumbs = Files.map((file, index) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt="" />
            </div>
        </div>
    ))

    const handleCB = (e) => {
        e.preventDefault()
    }

    const handleListComp = (tab) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        let data
        switch (tab) {
            case 1:
                data = {
                    userId: userId,
                    role: 'farmer',
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/auth-function/registerBy-users-admin`,
                        data,
                        config
                    )
                    .then((res) => {
                        const d = res.data.userData
                        if (d) {
                            const a = d.map((val) => {
                                return {
                                    id: val?.id,
                                    mobile_number: val?.mobile_number,
                                    name: val?.first_name + val?.last_name,
                                    created: val.created_at,
                                    urlToRedirect: 'AgriMachineBookingDetail',
                                }
                            })
                            setListCompData(a)
                        }
                    })
                break
            case 2:
                data = {
                    userId: userId,
                    role: 'livestock',
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/auth-function/registerBy-users-admin`,
                        data,
                        config
                    )
                    .then((res) => {
                        const d = res.data.userData
                        if (d) {
                            const a = d.map((val) => {
                                return {
                                    id: val?.id,
                                    mobile_number: val?.mobile_number,
                                    name: val?.first_name + val?.last_name,
                                    created: val.created_at,
                                    urlToRedirect: 'AgriMachineBookingDetail',
                                }
                            })
                            setListCompData(a)
                        }
                    })
                break
            case 3:
                data = {
                    userId: userId,
                    role: 'agri-input-supplier',
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/auth-function/registerBy-users-admin`,
                        data,
                        config
                    )
                    .then((res) => {
                        const d = res.data.userData
                        if (d) {
                            const a = d.map((val) => {
                                return {
                                    id: val?.id,
                                    mobile_number: val?.mobile_number,
                                    name: val?.first_name + val?.last_name,
                                    created: val.created_at,
                                    urlToRedirect: 'AgriMachineBookingDetail',
                                }
                            })
                            setListCompData(a)
                        }
                    })
                break
            case 4:
                data = {
                    userId: userId,
                    role: 'agri-machine',
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/auth-function/registerBy-users-admin`,
                        data,
                        config
                    )
                    .then((res) => {
                        const d = res.data.userData
                        if (d) {
                            const a = d.map((val) => {
                                return {
                                    id: val?.id,
                                    mobile_number: val?.mobile_number,
                                    name: val?.first_name + val?.last_name,
                                    created: val.created_at,
                                    urlToRedirect: 'AgriMachineBookingDetail',
                                }
                            })
                            setListCompData(a)
                        }
                    })
                break
            case 5:
                data = {
                    userId: userId,
                    role: 'agri-transport',
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/auth-function/registerBy-users-admin`,
                        data,
                        config
                    )
                    .then((res) => {
                        const d = res.data.userData
                        if (d) {
                            const a = d.map((val) => {
                                return {
                                    id: val?.id,
                                    mobile_number: val?.mobile_number,
                                    name: val?.first_name + val?.last_name,
                                    created: val.created_at,
                                    urlToRedirect: 'AgriMachineBookingDetail',
                                }
                            })
                            setListCompData(a)
                        }
                    })
                break
            case 6:
                data = {
                    userId: userId,
                    role: 'trader',
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/auth-function/registerBy-users-admin`,
                        data,
                        config
                    )
                    .then((res) => {
                        const d = res.data.userData
                        if (d) {
                            const a = d.map((val) => {
                                return {
                                    id: val?.id,
                                    mobile_number: val?.mobile_number,
                                    name: val?.first_name + val?.last_name,
                                    created: val.created_at,
                                    urlToRedirect: 'AgriMachineBookingDetail',
                                }
                            })
                            setListCompData(a)
                        }
                    })
                break
                case 7:
                    data = {
                        userId: userId,
                        role: 'micro-entrepreneur',
                    }
                    axios
                        .post(
                            `${process.env.REACT_APP_API_ENDPOINT}/auth-function/registerBy-users-admin`,
                            data,
                            config
                        )
                        .then((res) => {
                            const d = res.data.userData
                            if (d) {
                                const a = d.map((val) => {
                                    return {
                                        id: val?.id,
                                        mobile_number: val?.mobile_number,
                                        name: val?.first_name + val?.last_name,
                                        created: val.created_at,
                                        urlToRedirect: 'AgriMachineBookingDetail',
                                    }
                                })
                                setListCompData(a)
                            }
                        })
                    break
        }
    }

    return (
        <div>
            <div class="row">
                <div class="page-header">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <ul class="breadcrumb justify-content-end">
                                <li class="breadcrumb-item">
                                    <a href="#">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="#">FPO</a>
                                </li>
                                <li class="breadcrumb-item active">
                                    {Details.firstName}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="card-title d-flex justify-content-between store_edit">
                                <span> FPO</span>
                                <a
                                    href="#"
                                    class="btn btn-tbl-edit btn-xs td_btn"
                                >
                                    <span class="material-icons-outlined">
                                        edit
                                    </span>
                                </a>
                            </h5>
                            <a class="store-avatar">
                                <div class="staffAvatar-root1">
                                    {Details &&
                                    Details.firstName &&
                                    Details.firstName.length > 0
                                        ? Details.firstName.slice(0, 1)
                                        : ''}
                                </div>
                                <div class="store_display">
                                    <h4>{Details.firstName}</h4>
                                    <span>{Details.mobileNumber}</span>
                                </div>
                            </a>
                        </div>

                        <div class="card-body">
                            <h5 class="card-title d-flex justify-content-between store_edit">
                                <span>Personal Information</span>
                                <a
                                    href="#"
                                    class="btn btn-tbl-edit btn-xs td_btn"
                                >
                                    <span class="material-icons-outlined">
                                        edit
                                    </span>
                                </a>
                            </h5>
                            <ul class="list-unstyled mb-0 store_list">
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">Name : </small>
                                    <span>
                                        {Details.firstName +
                                            ' ' +
                                            Details.lastName}
                                    </span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">GST No :</small>
                                    <span>{Details.gst_no}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">Gender : </small>
                                    <span>{Details.gender}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">
                                        Date of Birth :{' '}
                                    </small>
                                    <span>{Details.dateOfBirth}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">
                                        Company Name :{' '}
                                    </small>
                                    <span>{Details.companyName}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">
                                        Partner Mode :{' '}
                                    </small>
                                    <span>{Details.partnerMode}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="card">
                        <div class="card-body">
                            <h5 class="card-title d-flex justify-content-between store_edit">
                                <span>Bank Details</span>
                                <a
                                    href="#"
                                    class="btn btn-tbl-edit btn-xs td_btn"
                                >
                                    <span class="material-icons-outlined">
                                        edit
                                    </span>
                                </a>
                            </h5>
                            <ul class="list-unstyled mb-0 store_list">
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">
                                        Account No :{' '}
                                    </small>
                                    <span>{Details.accountNumber}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">
                                        Account Name :{' '}
                                    </small>
                                    <span>{Details.accountName}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">
                                        IFSC Code :{' '}
                                    </small>
                                    <span>{Details.ifscCode}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">
                                        Bank Name :{' '}
                                    </small>
                                    <span>{Details.BankName}</span>
                                </li>
                                <li class="pt-2 pb-0">
                                    <small class="text-dark">
                                        Branch Name :{' '}
                                    </small>
                                    <span>{Details.branchName}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-xl-9 col-md-8">
                    <div class="card">
                        <div
                            class="card-body p_7"
                            style={{ padding: '10px 0px' }}
                        >
                            <form>
                                <div class="row form-group">
                                    <div class="col-md-12">
                                        <div class="card bg-white bn_15">
                                            <div class="card-body card-body_1">
                                                <ul class="nav nav-tabs nav-tabs-bottom nav_add_bt">
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link active"
                                                            href="#bottom-tab1"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Orders
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab2"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Wallet
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab3"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Documents
                                                        </a>
                                                    </li>
                                                    {/* <li class="nav-item"><a class="nav-link" href="#bottom-tab4" data-bs-toggle="tab">Manager Documents</a></li> */}
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab5"
                                                            data-bs-toggle="tab"
                                                        >
                                                            {' '}
                                                            List
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab7 "
                                                            data-bs-toggle="tab"
                                                        >
                                                            Address Details
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-tab6"
                                                            data-bs-toggle="tab"
                                                        >
                                                            Company Details
                                                        </a>
                                                    </li>
                                                </ul>

                                                <div class="tab-content">
                                                    {/* Orders */}
                                                    <ModuleOrders
                                                        userId={userId}
                                                        idForAssign={
                                                            idForAssign
                                                        }
                                                    />

                                                    {/* Wallet */}
                                                 <Wallet/>

                                                    {/* Document */}
                                                    <div
                                                        class="tab-pane"
                                                        id="bottom-tab3"
                                                    >
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="card-body card-body_1">
                                                                    <div className="row">
                                                                        <div class="col-md-12 text-end justify-space-between mb-4">
                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-primary"
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    e.preventDefault()
                                                                                    setAddDoc(
                                                                                        true
                                                                                    )
                                                                                }}
                                                                            >
                                                                                +
                                                                                Add
                                                                                Document
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="table-wrap">
                                                                        <div class="table-responsive">
                                                                            <table class="table display setting-td mb-30">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>
                                                                                            TITLE
                                                                                        </th>
                                                                                        <th>
                                                                                            PHOTO
                                                                                        </th>
                                                                                        <th>
                                                                                            CREATED
                                                                                        </th>
                                                                                        <th>
                                                                                            ACTIONS
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            TRADE
                                                                                            LIC
                                                                                        </td>
                                                                                        <td>
                                                                                            -
                                                                                        </td>
                                                                                        <td>
                                                                                            Sep
                                                                                            21,
                                                                                            11:19
                                                                                            AM
                                                                                        </td>
                                                                                        <td>
                                                                                            <a
                                                                                                href="#"
                                                                                                class="btn btn-tbl-edit btn-xs td_btn"
                                                                                            >
                                                                                                <span class="material-icons-outlined">
                                                                                                    edit
                                                                                                </span>
                                                                                            </a>
                                                                                            <a
                                                                                                href="#"
                                                                                                class="btn btn-tbl-edit btn-xs td_btn"
                                                                                            >
                                                                                                <span class="material-icons-outlined">
                                                                                                    delete
                                                                                                </span>
                                                                                            </a>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            TRADE
                                                                                            LIC
                                                                                        </td>
                                                                                        <td>
                                                                                            -
                                                                                        </td>
                                                                                        <td>
                                                                                            Sep
                                                                                            21,
                                                                                            11:19
                                                                                            AM
                                                                                        </td>
                                                                                        <td>
                                                                                            <a
                                                                                                href="#"
                                                                                                class="btn btn-tbl-edit btn-xs td_btn"
                                                                                            >
                                                                                                <span class="material-icons-outlined">
                                                                                                    edit
                                                                                                </span>
                                                                                            </a>
                                                                                            <a
                                                                                                href="#"
                                                                                                class="btn btn-tbl-edit btn-xs td_btn"
                                                                                            >
                                                                                                <span class="material-icons-outlined">
                                                                                                    delete
                                                                                                </span>
                                                                                            </a>
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <td>
                                                                                            TRADE
                                                                                            LIC
                                                                                        </td>
                                                                                        <td>
                                                                                            -
                                                                                        </td>
                                                                                        <td>
                                                                                            Sep
                                                                                            21,
                                                                                            11:19
                                                                                            AM
                                                                                        </td>
                                                                                        <td>
                                                                                            <a
                                                                                                href="#"
                                                                                                class="btn btn-tbl-edit btn-xs td_btn"
                                                                                            >
                                                                                                <span class="material-icons-outlined">
                                                                                                    edit
                                                                                                </span>
                                                                                            </a>
                                                                                            <a
                                                                                                href="#"
                                                                                                class="btn btn-tbl-edit btn-xs td_btn"
                                                                                            >
                                                                                                <span class="material-icons-outlined">
                                                                                                    delete
                                                                                                </span>
                                                                                            </a>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* List */}
                                                    <div
                                                        class="tab-pane"
                                                        id="bottom-tab5"
                                                    >
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="card-body card-body_1">
                                                                    <div className="row">
                                                                        <div class="col-md-6 text-end justify-space-between">
                                                                            <input
                                                                                type="number"
                                                                                class="form-control"
                                                                                placeholder="Search Number"
                                                                            />
                                                                        </div>
                                                                        <div class="col-md-6 text-end justify-space-between mb-4">
                                                                            <button
                                                                                className="btn btn-primary"
                                                                                style={{
                                                                                    margin: '10px',
                                                                                }}
                                                                                onClick={
                                                                                    handleCB
                                                                                }
                                                                            >
                                                                                Create
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-primary"
                                                                                style={{
                                                                                    margin: '10px',
                                                                                }}
                                                                                onClick={
                                                                                    handleCB
                                                                                }
                                                                            >
                                                                                Booking
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="tab-pane show active"
                                                                        id="bottom-tab1"
                                                                    >
                                                                        <div class="row">
                                                                            <div class="col-lg-12">
                                                                                <ul class="nav nav-tabs nav-tabs-bottom nav_add_bt">
                                                                                    <li
                                                                                        class="nav-item"
                                                                                        style={{
                                                                                            width: '16.66%',
                                                                                        }}
                                                                                    >
                                                                                        <a
                                                                                            class="nav-link active"
                                                                                            href="#bottom-tab1-sub1"
                                                                                            onClick={() =>
                                                                                                handleListComp(
                                                                                                    1
                                                                                                )
                                                                                            }
                                                                                            data-bs-toggle="tab"
                                                                                        >
                                                                                            Farmer
                                                                                        </a>
                                                                                    </li>
                                                                                    <li
                                                                                        class="nav-item"
                                                                                        style={{
                                                                                            width: '16.66%',
                                                                                        }}
                                                                                    >
                                                                                        <a
                                                                                            class="nav-link"
                                                                                            href="#bottom-tab1-sub2"
                                                                                            onClick={() =>
                                                                                                handleListComp(
                                                                                                    2
                                                                                                )
                                                                                            }
                                                                                            data-bs-toggle="tab"
                                                                                        >
                                                                                            Livestock
                                                                                        </a>
                                                                                    </li>
                                                                                    <li
                                                                                        class="nav-item"
                                                                                        style={{
                                                                                            width: '16.66%',
                                                                                        }}
                                                                                    >
                                                                                        <a
                                                                                            class="nav-link"
                                                                                            href="#bottom-tab1-sub1"
                                                                                            onClick={() =>
                                                                                                handleListComp(
                                                                                                    3
                                                                                                )
                                                                                            }
                                                                                            data-bs-toggle="tab"
                                                                                        >
                                                                                            Input
                                                                                            Supplier
                                                                                        </a>
                                                                                    </li>
                                                                                    <li
                                                                                        class="nav-item"
                                                                                        style={{
                                                                                            width: '16.66%',
                                                                                        }}
                                                                                    >
                                                                                        <a
                                                                                            class="nav-link"
                                                                                            href="#bottom-tab1-sub3"
                                                                                            onClick={() =>
                                                                                                handleListComp(
                                                                                                    4
                                                                                                )
                                                                                            }
                                                                                            data-bs-toggle="tab"
                                                                                        >
                                                                                            Agri
                                                                                            Machine
                                                                                        </a>
                                                                                    </li>
                                                                                    <li
                                                                                        class="nav-item"
                                                                                        style={{
                                                                                            width: '16.66%',
                                                                                        }}
                                                                                    >
                                                                                        <a
                                                                                            class="nav-link"
                                                                                            href="#bottom-tab1-sub4"
                                                                                            onClick={() =>
                                                                                                handleListComp(
                                                                                                    5
                                                                                                )
                                                                                            }
                                                                                            data-bs-toggle="tab"
                                                                                        >
                                                                                            Agri
                                                                                            Transport
                                                                                        </a>
                                                                                    </li>
                                                                                    <li
                                                                                        class="nav-item"
                                                                                        style={{
                                                                                            width: '16.66%',
                                                                                        }}
                                                                                    >
                                                                                        <a
                                                                                            class="nav-link"
                                                                                            href="#bottom-tab1-sub5"
                                                                                            onClick={() =>
                                                                                                handleListComp(
                                                                                                    6
                                                                                                )
                                                                                            }
                                                                                            data-bs-toggle="tab"
                                                                                        >
                                                                                            Traders
                                                                                        </a>
                                                                                    </li>
                                                                                    <li
                                                                                        class="nav-item"
                                                                                        style={{
                                                                                            width: '16.66%',
                                                                                        }}
                                                                                    >
                                                                                        <a
                                                                                            class="nav-link"
                                                                                            href="#bottom-tab1-sub5"
                                                                                            onClick={() =>
                                                                                                handleListComp(
                                                                                                    7
                                                                                                )
                                                                                            }
                                                                                            data-bs-toggle="tab"
                                                                                        >
                                                                                            Micro Entrepreneur
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            <div>
                                                                                <div class="tab-content">
                                                                                    <div
                                                                                        class="tab-pane show active"
                                                                                        id="bottom-tab1-sub1"
                                                                                    >
                                                                                        <div class="row">
                                                                                            <div class="col-lg-12">
                                                                                                <div class="card-body card-body_1 card-body_2">
                                                                                                    <div class="table-wrap">
                                                                                                        <div class="table-responsive">
                                                                                                            <table class="table display setting-td mb-30 store_table">
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th>
                                                                                                                            ID
                                                                                                                        </th>
                                                                                                                        <th>
                                                                                                                            Mobile
                                                                                                                            Number
                                                                                                                        </th>
                                                                                                                        <th>
                                                                                                                            Name
                                                                                                                        </th>
                                                                                                                        <th>
                                                                                                                            Created
                                                                                                                        </th>
                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {Object.keys(
                                                                                                                        listCompData
                                                                                                                    )
                                                                                                                        .length !==
                                                                                                                        0 &&
                                                                                                                        listCompData.map(
                                                                                                                            (
                                                                                                                                listDetail
                                                                                                                            ) => (
                                                                                                                                <tr>
                                                                                                                                    <td>
                                                                                                                                        {
                                                                                                                                            listDetail?.id
                                                                                                                                        }
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        {
                                                                                                                                            listDetail?.mobile_number
                                                                                                                                        }
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        {
                                                                                                                                            listDetail.name
                                                                                                                                        }
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        {new Date(
                                                                                                                                            listDetail.created
                                                                                                                                        )
                                                                                                                                            .toDateString()
                                                                                                                                            .slice(
                                                                                                                                                4,
                                                                                                                                                15
                                                                                                                                            )}
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            )
                                                                                                                        )}
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {!addDoc ? (
                                                                        <div class="row">
                                                                            <div class="col-sm-12">
                                                                                <div class="card card-table">
                                                                                    <div class="card-body">
                                                                                        <div class="table-responsive">
                                                                                            <div
                                                                                                id="DataTables_Table_0_wrapper"
                                                                                                class="dataTables_wrapper dt-bootstrap4 no-footer"
                                                                                            ></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        //       <>
                                                                        //       <div class="table-wrap">
                                                                        //       <div class="table-responsive">
                                                                        //          <table class="table display setting-td mb-30" >
                                                                        //             <thead>
                                                                        //                <tr>
                                                                        //                   <th>NAME</th>
                                                                        //                   <th>PHOTO</th>
                                                                        //                   <th>CREATED</th>
                                                                        //                   <th>ACTIONS</th>
                                                                        //                </tr>
                                                                        //             </thead>
                                                                        //             <tbody>
                                                                        //                <tr>
                                                                        //                   <td>TRADE LIC</td>
                                                                        //                   <td>-</td>
                                                                        //                   <td>Sep 21, 11:19 AM</td>
                                                                        //                   <td>
                                                                        //                      <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                                                                        //                         <span class="material-icons-outlined">edit</span>
                                                                        //                      </a>
                                                                        //                      <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                                                                        //                         <span class="material-icons-outlined">delete</span>
                                                                        //                      </a>
                                                                        //                   </td>
                                                                        //                </tr>

                                                                        //                <tr>
                                                                        //                   <td>TRADE LIC</td>
                                                                        //                   <td>-</td>
                                                                        //                   <td>Sep 21, 11:19 AM</td>
                                                                        //                   <td>
                                                                        //                      <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                                                                        //                         <span class="material-icons-outlined">edit</span>
                                                                        //                      </a>
                                                                        //                      <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                                                                        //                         <span class="material-icons-outlined">delete</span>
                                                                        //                      </a>
                                                                        //                   </td>
                                                                        //                </tr>

                                                                        //                <tr>
                                                                        //                   <td>TRADE LIC</td>
                                                                        //                   <td>-</td>
                                                                        //                   <td>Sep 21, 11:19 AM</td>
                                                                        //                   <td>
                                                                        //                      <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                                                                        //                         <span class="material-icons-outlined">edit</span>
                                                                        //                      </a>
                                                                        //                      <a href="#" class="btn btn-tbl-edit btn-xs td_btn">
                                                                        //                         <span class="material-icons-outlined">delete</span>
                                                                        //                      </a>
                                                                        //                   </td>
                                                                        //                </tr>

                                                                        //             </tbody>
                                                                        //          </table>
                                                                        //       </div>
                                                                        //    </div>
                                                                        //   </>
                                                                        <>
                                                                            <AddFarmer
                                                                                val={
                                                                                    'FPO'
                                                                                }
                                                                            />
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Address Details */}
                                                    <div
                                                        class="tab-pane"
                                                        id="bottom-tab6"
                                                    >
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="card-body card-body_1">
                                                                    <div className="row">
                                                                        <div class="col-md-12 text-end justify-space-between mb-4"></div>
                                                                    </div>
                                                                    <div
                                                                        class="card"
                                                                        style={{
                                                                            backgroundColor:
                                                                                '#f3f3f3 ',
                                                                        }}
                                                                    >
                                                                        <div class="card-body">
                                                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                                                <span>
                                                                                    Details
                                                                                </span>
                                                                                <a
                                                                                    href="#"
                                                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                                                >
                                                                                    <span class="material-icons-outlined mr-3">
                                                                                        edit
                                                                                    </span>
                                                                                    <span class="material-icons-outlined">
                                                                                        delete
                                                                                    </span>
                                                                                </a>
                                                                            </h5>
                                                                            <ul class="list-unstyled mb-0 store_list">
                                                                                <div className="row">
                                                                                    <div className="col-md-4 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                CEO
                                                                                                Name{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.ceoName
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-4 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                Company
                                                                                                Name{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.companyName
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-4 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                No
                                                                                                Of
                                                                                                Employees{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.noOfEmployees
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-4 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                Started
                                                                                                Since{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.started
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    {/* <div className='col-md-4 mb-4'>
                                            <li class='pt-2 pb-0'>
                                              <small class='text-dark'>
                                                No Of Members{' '}
                                              </small>
                                            </li>
                                            <li>{Details.noOfMember}</li>
                                          </div> */}
                                                                                    {/* <div className="col-md-4 mb-4">
                                                 <li class="pt-2 pb-0">
                                                   <small class="text-dark">Types of Crop </small>
                                                  </li>
                                                  <li>
                                                  {Details['cropType']}
                                                  </li>
                                                </div> */}
                                                                                    <div className="col-md-4 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                Govt
                                                                                                Fund{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.fundAmount
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-12 mb-4"></div>
                                                                                </div>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="tab-pane"
                                                        id="bottom-tab7"
                                                    >
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="card-body card-body_1">
                                                                    <div className="row">
                                                                        <div class="col-md-12 text-end justify-space-between mb-4"></div>
                                                                    </div>
                                                                    <div
                                                                        class="card"
                                                                        style={{
                                                                            backgroundColor:
                                                                                '#f3f3f3 ',
                                                                        }}
                                                                    >
                                                                        <div class="card-body">
                                                                            <h5 class="card-title d-flex justify-content-between store_edit">
                                                                                <span>
                                                                                    Address
                                                                                </span>
                                                                                <a
                                                                                    href="#"
                                                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                                                >
                                                                                    <span class="material-icons-outlined mr-3">
                                                                                        edit
                                                                                    </span>
                                                                                    <span class="material-icons-outlined">
                                                                                        delete
                                                                                    </span>
                                                                                </a>
                                                                            </h5>
                                                                            <ul class="list-unstyled mb-0 store_list">
                                                                                <div className="row">
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                Firm
                                                                                                Name{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.companyName
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                State{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.state
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                District{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.subDistrict
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                Sub
                                                                                                District{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.village
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                {' '}
                                                                                                Village{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.village
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                {' '}
                                                                                                Pincode{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.pincode
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-3 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <small class="text-dark">
                                                                                                {' '}
                                                                                                Address{' '}
                                                                                            </small>
                                                                                        </li>
                                                                                        <li>
                                                                                            {
                                                                                                Details.fullAddress
                                                                                            }
                                                                                        </li>
                                                                                    </div>
                                                                                    <div className="col-md-12 mb-4">
                                                                                        <li class="pt-2 pb-0">
                                                                                            <h5 class="card-title d-flex justify-content-between mt-4">
                                                                                                <span>
                                                                                                    Location
                                                                                                </span>
                                                                                                <a
                                                                                                    href="#"
                                                                                                    class="btn btn-tbl-edit btn-xs td_btn"
                                                                                                >
                                                                                                    <span class="material-icons-outlined">
                                                                                                        edit
                                                                                                    </span>
                                                                                                </a>
                                                                                            </h5>
                                                                                        </li>
                                                                                        <li>
                                                                                            <Map2
                                                                                                center={{
                                                                                                    lat: +Details.lat,
                                                                                                    lng: +Details.long,
                                                                                                }}
                                                                                                height="300px"
                                                                                                zoom={
                                                                                                    15
                                                                                                }
                                                                                            />
                                                                                        </li>
                                                                                    </div>
                                                                                </div>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FPO
