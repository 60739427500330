import React, { useState } from 'react'
import { CssTextField } from '../pages/Styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useEffect } from 'react'
import axios from 'axios'

const Wallet = ({moduleType}) => {
    const [data,setData]=useState([])
    const [amount,setTotalAmount]=useState([])

    const [walletId, setWalletId] = useState('');
    const getWalletIdFromLocalStorage = () => {
        let storedWalletId = localStorage.getItem('walletId');

        if(moduleType === "live"){
            storedWalletId = localStorage.getItem('livestockwalletId');
        }
        if(moduleType === "buy"){
            storedWalletId = localStorage.getItem('inputwalletId');
        }

        if (storedWalletId) {
          setWalletId(storedWalletId);
        }
      };
    useEffect(() => {
      // Function to retrieve the latest walletId from local storage
      
  
      // Call the function when the component mounts
      getWalletIdFromLocalStorage();
  
      // Event listener to update walletId when the storage changes
      const handleStorageChange = () => {
        getWalletIdFromLocalStorage();
      };
  
      // Add event listener to listen for changes in local storage
      window.addEventListener('storage', handleStorageChange);
  
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('storage', handleStorageChange);
      };
    }, []); // The empty dependency array ensures that this effect runs only once on mount
  


    useEffect(() => {
        getWalletIdFromLocalStorage()
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        // var user_id = localStorage.getItem('walletId')
        axios.get( `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-payments-user/${walletId}`,config).then(

            res=>{
                
                setData(res.data.data)
            setTotalAmount(res.data.totalAmount)}
        )

    }, [walletId])
    function renderAmountCell(a, moduleType, bookingType) {
        if (moduleType === "farmer" && bookingType === "sell") {
          return <td>{a.machineAmount}</td>;
        } else if (moduleType === "vehicle" && bookingType === "vehicle") {
          return <td>{a.machineAmount}</td>;
        } else if (moduleType === "machine" && bookingType === "machine") {
          return <td>{a.machineAmount}</td>;
        } else if (moduleType === "buy" && bookingType === "buy") {
          return <td>{a.firmAmount}</td>;
        } else if (moduleType === "live" && bookingType === "live-stock") {
          return <td>{a.firmAmount}</td>;
        } else {
          return <td>{a.userAmount}</td>;
        }
      }
      

    const [addWallet, setAddWallet] = useState(false)

    return (
        <div class="tab-pane" id="bottom-tab2">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card-body card-body_1">
                        <div className="row">
                        <div>     Total Amount: {amount}</div>
                            <div class="col-md-12 text-end justify-space-between mb-4">
                           
                           
                                {!addWallet ? (
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setAddWallet(true)
                                        }}
                                    >
                                        + Add Wallet
                                    </button>
                                ) : (
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setAddWallet(false)
                                        }}
                                    >
                                        Wallet List
                                    </button>
                                )}
                            </div>
                        </div>
                        {!addWallet ? (
                            <>
                                <div class="table-wrap">
                                    <div class="table-responsive">
                                        <table class="table display setting-td mb-30">
                                            <thead>
                                                <tr>
                                                    <th>AMOUNT(Rs.) </th>
                                                    <th>Booking type</th>
                                                    <th>Order Id</th>
                                                    <th>DATE</th>
                                                    <th>Transaction Id</th>
                                                    <th>Payment Mode</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.length&&data.map(a=>{
                                                    return(
                                                        <tr>
                                                        {renderAmountCell(a, moduleType, a.bookingType)}
                                                        {/* <td>{a.payment_amount}</td> */}
                                                        <td>
                                                        {a.bookingType}
                                                        </td>
                                                        <td>
                                                        {a.booking_id}
                                                        </td>
                                                        <td> { new Date(a.payment_date).toDateString()}</td>
                                                        <td>
                                                        {a.transaction_id}
                                                        </td>
                                                        <td>
                                                        {a.billing_type}
                                                        </td>
                                                    </tr>
                                                    )
                                                })}
                                               

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <form
                                    class="tab-pane show active"
                                    id="bottom-justified-tab4"
                                    onSubmit={(e) => console.log(5)}
                                >
                                    <div className="row">
                                        <h4>Create Wallet</h4>
                                        <div className="col-md-3 mb-4">
                                            <CssTextField
                                                fullWidth
                                                type="number"
                                                id="totalLand"
                                                label="Amount"
                                                variant="standard"
                                                name="totalLand"
                                                //   value={farmer.totalLand}
                                                //   onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                            />
                                        </div>
                                        <div class="col-md-3">
                                            <FormControl
                                                variant="standard"
                                                fullWidth
                                                className="mt-3"
                                            >
                                                <InputLabel
                                                    id="demo-simple-select-standard-label"
                                                    style={{
                                                        color: 'black',
                                                    }}
                                                >
                                                    Wallet Type
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="irrigationSource"
                                                    label="Irrigation Source"
                                                    color="success"
                                                    name="irrigationSource"
                                                >
                                                    <MenuItem value="">
                                                        <em>
                                                            - Select An Option -
                                                        </em>
                                                    </MenuItem>
                                                    <MenuItem value="Credit">
                                                        Credit
                                                    </MenuItem>
                                                    <MenuItem value="Debit">
                                                        Debit
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                fullWidth
                                                type="number"
                                                id="totalLand"
                                                label="Description"
                                                variant="standard"
                                                name="totalLand"
                                                //   value={farmer.totalLand}
                                                //   onChange={(e)=>setFarmer({...farmer, [e.target.name]: e.target.value})}
                                            />
                                        </div>

                                        <div class="col-md-12 text-end mt-2 mb-2">
                                            <button
                                                type="submit"
                                                class=" w-10 btn btn-primary"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Wallet
