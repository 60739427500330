import axios from 'axios'
import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import TaxTable from './taxtable'
import TaxTableFlat from './taxtableflat'
import TaxTcsTable from './taxtcstable'

function TCS() {
   
    
    const [paymentMode, setPaymentMode] = useState('')
    const [role, setRole] = useState('')
    const [taxtype, setTaxType] = useState('')
    const [tableType,setTableType]=useState("")
    const [incgst, setIncgst] = useState('')
    const [percentage, setPercentage] = useState('')
    const [feeAmount, setFeeamount] = useState('')
    const [gstAmount, setGSTAmount] = useState('')
    const [percentageFlatfee,setPercentageFlatfee]=useState("")
    const [flatGSTpercentage,setFlatGSTpercentage]=useState("")
    const [taxdata, setTaxes] = useState([])
    const [tcs,setTcs]=useState('')
    useEffect(() => {
        getTaxes()
    }, [])

    const PaymentOptions = [
        {
            label: 'Input Supplier',
            value: 'input',
        },
        {
            label: 'Livestock',
            value: 'livestock',
        },
        {
            label: 'Agri Machine',
            value: 'machine',
        },
        {
            label: 'Agri Transport',
            value: 'vehicle',
        },
        {
            label: 'Sell Crop',
            value: 'sell',
        },
    ]
    const taxtypes = [
        {
            label: 'Percentage',
            value: 'percentage',
        },
        {
            label: 'flat',
            value: 'flat',
        },
    ]

   
    const fillTable=(e)=>{
        setTableType(e)
        getTaxes(e.value)
    }
    const getTaxes = (type) => {
        setTaxes([])
        const token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        axios
            .get(
                `https://prod-api.krishione.com/farmer-function/get-tcs`,
                config
            )
            .then((res) => {
                setTaxes(res.data.data)
                console.log(res.data)
            })
    }

    const onSubmit = (e) => {
        var data = {
          
            serviceType: paymentMode.value,
           tcs
        }
      
        const token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
   
        axios
            .post(
                'https://prod-api.krishione.com/farmer-function/set-tcs',
                data,
                config
            )
            .then((res) => {
                alert('Success!')
                console.log(res.data)
                getTaxes(taxtype.value)
            })
        
      
    }
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Set TCS</h4>
                   
                </div>

                <div class="card-body">
                    <div class="row tx_bdr">
                   
                       
                        <div class="col-md-4">
                            <p>Select Service Type</p>

                            <Select
                                style={{
                                    width: '270px',
                                    height: '40px',
                                }}
                                placeholder={'Select Role'}
                                value={paymentMode}
                                onChange={(e) => setPaymentMode(e)}
                                options={PaymentOptions}
                            />
                        </div>
                       
                        <div class="col-md-4">
                            <label
                                for="Percentage"
                                class="p-l-0 col-form-label input-label"
                            >
                                TCS
                            </label>
                            <input
                                class="form-control"
                                type="number"
                                value={tcs}
                                onChange={(e) => setTcs(e.target.value)}
                            />
                        </div>
                      
                     
                    

                        <br />
                      
                    </div>
                    <div class="row tx_bdr">
                      
                        <button
                            variant="contained"
                            onClick={(e) => onSubmit(e)}
                            component="span"
                            color="success"
                            className="btn btn-primary mt-3"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
          <div class="row" style={{ margin: '2%' }}>
            
           <TaxTcsTable inputs={taxdata} />
            </div> 
        </div>
    )
}

export default TCS
