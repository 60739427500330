import React, { useState, useEffect } from 'react'
import { CssTextField } from '../../../Styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Switch from '@mui/material/Switch'
import { API } from '../../../../API'
import Swal from 'sweetalert2'
import ProductInventoryTable from './ProductInventoryTable'
import axios from 'axios'
import { Inventory } from '@mui/icons-material'

function InputCatelog(props) {
    const mobileNo = localStorage.getItem('farmerid')
    console.log(mobileNo)
    // useEffect(()=> {
    //    console.log('Its Working');
    //    alert('You Clicked Add Product...')
    // }, [])
    const catalogue = 'inputSupplier'
    const InventoryClear = {
        catalogue: 'inputSupplier',
        categoryName: '',
        subCategoryName: '',
        brandName: '',
        productName: '',
        quantity: '',
        unit: '',
        purchasePrice: '',
        salePrice: '',
        discount: '',
        isUnlimited: false,
        stock: '',
    }

    const [Invendory, setInventory] = useState({
        catalogue: 'inputSupplier',
        categoryName: '',
        subCategoryName: '',
        brandName: '',
        productName: '',
        quantity: '',
        unit: '',
        purchasePrice: '',
        salePrice: '',
        discount: '',
        isUnlimited: false,
        stock: '',
    })
    
    const [Categorylist, setCategorylist] = useState([])
    const [subCategorylist, setsubCategorylist] = useState([])
    const [brandlist, setbrandlist] = useState([])
    const [categoryName, setcategoryName] = useState('')
    const [subcategoryName, setsubcategoryName] = useState('')
    const [Productlist, setProductlist] = useState([])
    const [Varientlist, setVarientlist] = useState([])
    const [Unitlist, setUnitlist] = useState([])
    const [brandName, setbrandName] = useState('')
    const [productImage, setProductImage] = useState('')
    const handleSubmit = async () => {
        console.log('handleSubmit', Invendory)
        try {
            var token = localStorage.getItem('token')
            var config = {
                headers: { Authorization: `Bearer ${token}` },
            }

            if (props.productCatelog) {
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product-Inventory`,
                        { ...Invendory, productImage, mobile_number: mobileNo },
                        config
                    )
                    .then((data) => {
                        Swal.fire({
                            icon: 'success',
                            iconColor: '#28a745',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                        console.log(data)
                        setInventory(InventoryClear)
                    })
                    .catch((data) => {
                        setInventory(InventoryClear)
                        Swal.fire({
                            icon: 'warning',
                            iconColor: '#dc3545',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                    })
            } else {
                axios
                    .post(
                        `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/addInventory`,
                        Invendory,
                        config
                    )
                    .then((data) => {
                        Swal.fire({
                            icon: 'success',
                            iconColor: '#28a745',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                        console.log(data)
                        setInventory(InventoryClear)
                    })
                    .catch((data) => {
                        setInventory(InventoryClear)
                        Swal.fire({
                            icon: 'warning',
                            iconColor: '#dc3545',
                            title: data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                        })
                    })
            }
        } catch (err) {
            setInventory(InventoryClear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
 
    const [ProductInput, setProductInput] = useState([])
    useEffect(() => {
        getCategoryList()
        // getState();
        //  getProductInput();
    }, [])

    const getProductInput = async () => {
        // const { data } = await API.get(`/getProductCatelog?title=ProductCatelog&catelogName=InputSupplierCatelog&type=0`);
        // setProductInput(data?.result);
        // var token = localStorage.getItem("token");
        // const config = {
        //   headers: { 'Authorization': `Bearer ${token}` }
        // };
        // await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/farmer-function/category', InputProduct, config)
        //   .then(data => {
        //     console.log("category in inventory: ", data);
        //     Swal.fire({
        //       icon: 'success',
        //       iconColor: '#28a745',
        //       title: data?.message,
        //       showConfirmButton: false,
        //       timer: 2000
        //     });
        //     console.log(data);
        //     // setProductInput(data);
        //     setInputProduct(inputClear)
        //   })
        //   .catch(err => {
        //     console.log(err);
        //     setInputProduct(inputClear)
        //     Swal.fire({
        //       icon: 'warning',
        //       iconColor: '#dc3545',
        //       title: data?.message,
        //       showConfirmButton: false,
        //       timer: 2000
        //     });
        //   });
    }

    const handleCategoryChangeVarient = (e) => {
        // setInventory({...Invendory,subCategoryName:e.target.value})
        setInventory({ ...Invendory, categoryName: e.target.value })
        getSubCategoryList(e.target.value)
        setcategoryName(e.target.value)
    }
    const getCategoryList = async () => {
        //  const { data } = await API.get(`/getProductCategory?type=0&title=ProductCatelog&catelogName=InputSupplierCatelog`);
        //  // console.log("hkjjhj",data)
        //  setCategorylist(data?.result);
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        if (props.productCatelog) {
            var data = {
                catalogue: catalogue,
                mobile_number: mobileNo,
            }
            await axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product-getcategory`,
                    data,
                    config
                )
                .then((res) => {
                    console.log('d :', res.data.category)
                    setCategorylist(res.data.category)
                })
        } else {
            await axios
                .get(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/category/${catalogue}`,
                    config
                )
                .then((res) => {
                    console.log('d :', res.data.category)
                    setCategorylist(res.data.category)
                })
        }
    }

    const getSubCategoryList = async (val) => {
        //  const { data } = await API.get(`/getProductSubCategory?type=0&title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=`+val);
        //  console.log("hkjjhj",data)
        //  setsubCategorylist(data?.result);
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const subCat = {
            catalogue: catalogue,
            categoryName: val,
        }
        if (props.productCatelog) {
            await axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product-getsubcategory`,
                    { ...subCat, mobile_number: mobileNo },
                    config
                )
                .then((res) => {
                    console.log(
                        'get sub cat in input catalogue',
                        res.data.subcategory
                    )
                    setsubCategorylist(res.data.subcategory)
                })
        } else {
            await axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-subcategory`,
                    subCat,
                    config
                )
                .then((res) => {
                    console.log(
                        'get sub cat in input catalogue',
                        res.data.subcategory
                    )
                    setsubCategorylist(res.data.subcategory)
                })
        }
    }

    const handlechangeproductVarientBrand = (e) => {
        console.log(e.target.value, 'e')
        setInventory({ ...Invendory, subCategoryName: e.target.value })
        getBrandListVarient(e.target.value)
        setsubcategoryName(e.target.value)
    }
    const getBrandListVarient = async (val) => {
        //  const { data } = await API.get(`/getProductBrand?type=0&title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=`+categoryName+'&subCategoryName='+val);
        //  console.log("hkjjhj",data)
        //  setbrandlist(data?.result);
        let forGetBrands = {
            categoryName: Invendory.categoryName,
            subCategoryName: val,
            catalogue: catalogue,
        }
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        if (props.productCatelog) {
            await axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product-getbrand`,
                    { ...forGetBrands, mobile_number: mobileNo },
                    config
                )
                .then((data) => {
                    console.log('brand in input', data)
                    setbrandlist(data.data.brand)
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            await axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-brand`,
                    forGetBrands,
                    config
                )
                .then((data) => {
                    console.log('brand in input', data)
                    setbrandlist(data.data.brand)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }
    const handlechangeproductVarientProduct = (e) => {
        setInventory({ ...Invendory, brandName: e.target.value })
        getProductListVarient(e.target.value)
        setbrandName(e.target.value)
    }
    const getProductListVarient = async (val) => {
        //  const { data } = await API.get(`/getProductCatelogProduct?type=3&title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=`+categoryName+'&subCategoryName='+subcategoryName+'&brandName='+val);
        //  console.log("hkjjhj",data)
        //  setProductlist(data?.result);
        let forGet = {
            categoryName: Invendory.categoryName,
            subCategoryName: Invendory.subCategoryName,
            brandName: val,
            catalogue: catalogue,
        }
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        if (props.productCatelog) {
            await axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product-getcatelogue-product`,
                    { ...forGet, mobile_number: mobileNo },
                    config
                )
                .then((data) => {
                    console.log('product', data)
                    setProductlist(data.data.product)
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            await axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-product`,
                    forGet,
                    config
                )
                .then((data) => {
                    console.log('product', data)
                    setProductlist(data.data.product)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }
    const getunitList = async (val) => {
        //  const { data } = await API.get(`/getUnit?type=0&title=ProductCatelog&catelogName=InputSupplierCatelog`);
        //  console.log("hkjjhj",data)
        //  setUnitlist(data?.result);
    }
    const handlevarientlistname = (e) => {
        setInventory({ ...Invendory, quantity: e.target.value })
        getunitList(e.target.value)
    }

    const handleVarientchange = (e) => {
        var image = Productlist.filter(
            (item) => item.productName === e.target.value
        )
        setProductImage(image[0].productImage)
        // setInventory({...Invendory,brandName:e.target.value})
        setInventory({ ...Invendory, productName: e.target.value })
        getVarientlistVarient(e.target.value)
    }
    const getVarientlistVarient = async (val) => {
        //  const { data } = await API.get(`/getProductCatelogQuantity?type=0&title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=`+categoryName+'&subCategoryName='+subcategoryName+'&brandName='+brandName +'&productName='+val);
        //  console.log("hkjjhj",data)
        //  setVarientlist(data?.result);
        let forGet = {
            categoryName: Invendory.categoryName,
            subCategoryName: Invendory.subCategoryName,
            brandName: Invendory.brandName,
            productName: val,
            catalogue: catalogue,
        }
        // const [Varientlist,setVarientlist] =useState([])
        // const [Unitlist,setUnitlist] =useState([])
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        if (props.productCatelog) {
            axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/product-getcatelog-quantity`,
                    { ...forGet, mobile_number: mobileNo },
                    config
                )
                .then((data) => {
                    console.log('qty & unit', typeof data.data.product)
                    setVarientlist(data.data.product)
                    setUnitlist(data.data.product)
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/getProductCatelogQuantity`,
                    forGet,
                    config
                )
                .then((data) => {
                    console.log('qty & unit', typeof data.data.product)
                    setVarientlist(data.data.product)
                    setUnitlist(data.data.product)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }
    return (
        <>
            <div className="card card-table p-10-25">
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="mt-3">
                        <h5 class="card-title">Create Inventory</h5>
                        <div class="row mb-4">
                            <div class="col-md-3">
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    className="mt-3"
                                >
                                    <InputLabel
                                        id="demo-simple-select-standard-label"
                                        style={{ color: 'black' }}
                                    >
                                        Category
                                    </InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        //  value={age}
                                        //  onChange={handleChange}
                                        label="Firm"
                                        color="success"
                                        value={Invendory.categoryName}
                                        // onChange={(e)=>setInventory({...Invendory,categoryName:e.target.value})}
                                        onChange={(e) =>
                                            handleCategoryChangeVarient(e)
                                        }
                                    >
                                        {Categorylist &&
                                            Categorylist.map((val, ind) => {
                                                // {console.log("Categorylist",val)}
                                                return (
                                                    <MenuItem
                                                        value={val.categoryName}
                                                    >
                                                        {val.categoryName}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div class="col-md-3">
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    className="mt-3"
                                >
                                    <InputLabel
                                        id="demo-simple-select-standard-label"
                                        style={{ color: 'black' }}
                                    >
                                        SubCategory
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        //  value={age}
                                        //  onChange={handleChange}
                                        label="Booking Mode"
                                        color="success"
                                        value={Invendory.subCategoryName}
                                        // onChange={(e)=>setInventory({...Invendory,subCategoryName:e.target.value})}
                                        onChange={(e) =>
                                            handlechangeproductVarientBrand(e)
                                        }
                                    >
                                        {subCategorylist &&
                                            subCategorylist.map((val, ind) => {
                                                // {console.log("Categorylist",val)}
                                                return (
                                                    <MenuItem
                                                        value={
                                                            val.subCategoryName
                                                        }
                                                    >
                                                        {val.subCategoryName}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div class="col-md-3">
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    className="mt-3"
                                >
                                    <InputLabel
                                        id="demo-simple-select-standard-label"
                                        style={{ color: 'black' }}
                                    >
                                        Brand Name
                                    </InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        //  value={age}
                                        //  onChange={handleChange}
                                        label="Category"
                                        color="success"
                                        value={Invendory.brandName}
                                        //  onChange={(e)=>setInventory({...Invendory,brandName:e.target.value})}
                                        onChange={(e) =>
                                            handlechangeproductVarientProduct(e)
                                        }
                                    >
                                        {brandlist &&
                                            brandlist.map((val, ind) => {
                                                // {console.log("Categorylist",val)}
                                                return (
                                                    <MenuItem
                                                        value={val.brandName}
                                                    >
                                                        {val.brandName}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div class="col-md-3">
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    className="mt-3"
                                >
                                    <InputLabel
                                        id="demo-simple-select-standard-label"
                                        style={{ color: 'black' }}
                                    >
                                        Product Name
                                    </InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        //  value={age}
                                        //  onChange={handleChange}
                                        label="Product Name"
                                        color="success"
                                        value={Invendory.productName}
                                        //  onChange={(e)=>setInventory({...Invendory,productName:e.target.value})}
                                        onChange={(e) => handleVarientchange(e)}
                                    >
                                        {Productlist &&
                                            Productlist.map((val, ind) => {
                                                // {console.log("Categorylist",val)}
                                                return (
                                                    <MenuItem
                                                        value={val.productName}
                                                    >
                                                        {val.productName}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                            </div>
                            {/* <div class="col-md-3">
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    className="mt-3"
                                >
                                    <InputLabel
                                        id="demo-simple-select-standard-label"
                                        style={{ color: 'black' }}
                                    >
                                        Quantity
                                    </InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        //  value={age}
                                        //  onChange={handleChange}
                                        label="Quantity"
                                        color="success"
                                        name="quantity"
                                        value={Invendory.quantity}
                                        onChange={(e) =>
                                            handlevarientlistname(e)
                                        }
                                    >
                                        {Varientlist &&
                                            Varientlist.map((val, ind) => {
                                                {
                                                    console.log(
                                                        'Categorylist',
                                                        val
                                                    )
                                                }
                                                return (
                                                    <MenuItem
                                                        value={val.quantity}
                                                    >
                                                        {val.quantity}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div class="col-md-3">
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    className="mt-3"
                                >
                                    <InputLabel
                                        id="demo-simple-select-standard-label"
                                        style={{ color: 'black' }}
                                    >
                                        Unit
                                    </InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        label="Quantity"
                                        color="success"
                                        name="quantity"
                                        value={Invendory.unit}
                                        onChange={(e) =>
                                            setInventory({
                                                ...Invendory,
                                                unit: e.target.value,
                                            })
                                        }
                                    >
                                        {Unitlist &&
                                            Unitlist.map((val, ind) => {
                                                {
                                                    console.log(
                                                        'unit list',
                                                        val.unit
                                                    )
                                                }
                                                return (
                                                    <MenuItem value={val.unit}>
                                                        {val.unit}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div class="col-md-3">
                                <CssTextField
                                    required
                                    fullWidth
                                    id="maxPrice"
                                    label="Purchase price"
                                    variant="standard"
                                    value={Invendory.purchasePrice}
                                    onChange={(e) =>
                                        setInventory({
                                            ...Invendory,
                                            purchasePrice: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div class="col-md-3">
                                <CssTextField
                                    required
                                    fullWidth
                                    id="Price"
                                    label="Sale Price"
                                    variant="standard"
                                    value={Invendory.salePrice}
                                    onChange={(e) =>
                                        setInventory({
                                            ...Invendory,
                                            salePrice: e.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div class="col-md-3">
                                <CssTextField
                                    required
                                    fullWidth
                                    id="Discount"
                                    label="Discount"
                                    variant="standard"
                                    value={Invendory.discount}
                                    onChange={(e) =>
                                        setInventory({
                                            ...Invendory,
                                            discount: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div class="col-md-3">
                                <div className="row">
                                    <div className="col-4">
                                        <InputLabel
                                            id="demo-simple-select-standard-label"
                                            style={{ color: 'black' }}
                                            className="mt-3"
                                        >
                                            Unlimited
                                        </InputLabel>
                                        <Switch
                                            checked={Invendory.isUnlimited}
                                            //   onChange={this.handleChange('checkedA')}
                                            value="checkedA"
                                            //   onChange={this.handleChange("checkedA")}
                                            //   value={Invendory.isUnlimited}
                                            onChange={(e) =>
                                                setInventory({
                                                    ...Invendory,
                                                    isUnlimited:
                                                        e.target.checked,
                                                })
                                            }
                                        />
                                    </div>
                                    {Invendory.isUnlimited ? (
                                        ''
                                    ) : (
                                        <div className="col-8">
                                            <CssTextField
                                                required
                                                fullWidth
                                                id="stock"
                                                label="Stock"
                                                variant="standard"
                                                value={Invendory.stock}
                                                onChange={(e) =>
                                                    setInventory({
                                                        ...Invendory,
                                                        stock: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </div> */}
                        </div>

                        <div className="row justify-content-end mb-3">
                            <div className="col-md-1">
                                <button
                                    type="submit"
                                    className="btn btn-danger"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div className="col-md-1">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={() => handleSubmit()}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default InputCatelog
