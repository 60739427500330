import React from 'react'
import { CssTextField } from '../../Styles'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import Swal from 'sweetalert2'

const permissions = [
    'Manage Create Module',
    'Manage Booking Module',
    'Manage Catelog',
    'Revenue Plans',
    'Settings',
    'Send Order Chat Message',
    'Save Order Notes',
    'Edit Order Products',
    'Receive Payments for Order',
    'Manage Customers',
    'Add Customer',
    'Update Customer (Basic Info & Address)',
    'Delete Customer',
]

function Roles() {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    })

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return
        }

        setState({ ...state, [anchor]: open })
    }

    const list = (anchor) => (
        <Box
            sx={{ width: '300px' }}
            role="presentation"
            //   onClick={toggleDrawer(anchor, false)}
            //   onKeyDown={toggleDrawer(anchor, false)}
        >
            <div style={{ padding: '10px', marginTop: '10px' }}>
                <h5>Manage Role Permissions</h5>
            </div>
            <Divider />
            <p
                style={{
                    padding: '10px',
                    marginTop: '10px',
                    marginBottom: '0px',
                }}
            >
                Assign Permissions
            </p>
            <List>
                {permissions.map((permission, index) => (
                    <ListItem button key={permission}>
                        <ListItemIcon>
                            <Checkbox color="success" />
                        </ListItemIcon>
                        <ListItemText primary={permission} />
                    </ListItem>
                ))}
            </List>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '15px',
                }}
            >
                <button
                    type="submit"
                    class="btn btn-primary"
                    style={{ width: '90%' }}
                >
                    Update
                </button>
            </div>
        </Box>
    )

    const deleteRole = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
            }
        })
    }

    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Roles</h5>
                    <div
                        class="nav-item tab_add_new"
                        style={{ padding: '10px' }}
                        id="openVariant"
                    >
                        <a href="#">
                            <span class="material-icons-outlined">add</span>
                        </a>
                    </div>
                </div>
                <div class="card-body">
                    {/* Create Role */}
                    <div
                        id="variant"
                        class="card filter-card"
                        style={{
                            backgroundColor: '#f4f5f8',
                            borderRadius: '7px',
                        }}
                    >
                        <form class="card-body pb-0 mb-3" action="#">
                            <div class="row">
                                <h5 class="card-title">Create Role</h5>
                                <div class="col-md-4 select2-container">
                                    <CssTextField
                                        required
                                        fullWidth
                                        id="name"
                                        label="Name"
                                        variant="standard"
                                    />
                                </div>
                            </div>
                            <div className="row justify-content-end">
                                <div class="col-sm-2 col-md-2 mb-3">
                                    <button
                                        type="submit"
                                        className="btn btn-primary mt-26"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    {/* Edit Role */}
                    <div
                        id="product"
                        class="card filter-card"
                        style={{
                            backgroundColor: '#f4f5f8',
                            borderRadius: '7px',
                        }}
                    >
                        <form class="card-body pb-0 mb-3" action="#">
                            <div class="row">
                                <h5 class="card-title">Edit Role</h5>
                                <div class="col-md-4 select2-container">
                                    <CssTextField
                                        required
                                        fullWidth
                                        id="name"
                                        label="Name"
                                        variant="standard"
                                        Value="OPERATION HEAD"
                                    />
                                </div>
                            </div>
                            <div className="row justify-content-end">
                                <div class="col-sm-2 col-md-2 mb-3">
                                    <button
                                        type="submit"
                                        className="btn btn-primary mt-26"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="row">
                        <div class="col-lg-4">
                            <div class="card card-body bg-gray">
                                <h5>OPERATIONS HEAD</h5>
                                <div class="status-options">
                                    <a href="#" id="openProduct">
                                        <span class="material-icons-outlined">
                                            edit
                                        </span>
                                    </a>
                                    <a href="#">
                                        <span
                                            class="material-icons-outlined"
                                            onClick={() => deleteRole()}
                                        >
                                            delete
                                        </span>
                                    </a>
                                    {['right'].map((anchor) => (
                                        <React.Fragment key={anchor}>
                                            <a
                                                href="#"
                                                onClick={toggleDrawer(
                                                    anchor,
                                                    true
                                                )}
                                            >
                                                <span class="material-icons-outlined">
                                                    lock
                                                </span>
                                            </a>
                                            <SwipeableDrawer
                                                anchor={anchor}
                                                open={state[anchor]}
                                                onClose={toggleDrawer(
                                                    anchor,
                                                    false
                                                )}
                                                onOpen={toggleDrawer(
                                                    anchor,
                                                    true
                                                )}
                                            >
                                                {list(anchor)}
                                            </SwipeableDrawer>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card card-body bg-gray">
                                <h5>Verifier</h5>
                                <div class="status-options">
                                    <a href="#">
                                        <span class="material-icons-outlined">
                                            edit
                                        </span>
                                    </a>
                                    <a href="#">
                                        <span class="material-icons-outlined">
                                            delete
                                        </span>
                                    </a>
                                    <a href="#">
                                        <span class="material-icons-outlined">
                                            lock
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card card-body bg-gray">
                                <h5>SALES MANAGER</h5>
                                <div class="status-options">
                                    <a href="#">
                                        <span class="material-icons-outlined">
                                            edit
                                        </span>
                                    </a>
                                    <a href="#">
                                        <span class="material-icons-outlined">
                                            delete
                                        </span>
                                    </a>
                                    <a href="#">
                                        <span class="material-icons-outlined">
                                            lock
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}

export default Roles
