import React from 'react'

function WebBanners() {
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Web Banners</h5>
                </div>
                <div class="card-body">
                    <form>
                        <div class="row form-group">
                            <div class="col-sm-4">
                                <div class="d-flex align-items-center">
                                    <label
                                        class="avatar avatar-xxl_lg profile-cover-avatar m-0"
                                        for="edit_img"
                                    >
                                        <span class="banner_txt_img">
                                            Add Images
                                        </span>
                                        <input type="file" id="edit_img" />
                                    </label>
                                </div>
                                <span class="banner_txt">
                                    Use images with size 500(h) x 1400(w).
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default WebBanners
