import React from 'react'

function Email() {
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Email Notification Settings</h5>
                </div>
                <div class="card-body card-body_1">
                    <form>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <div class="card bg-white">
                                    <div class="card-body card-body_1">
                                        <ul class="nav nav-tabs nav-tabs-bottom nav_add_bt">
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link active"
                                                    href="#bottom-tab1"
                                                    data-bs-toggle="tab"
                                                >
                                                    System
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    class="nav-link"
                                                    href="#bottom-tab2"
                                                    data-bs-toggle="tab"
                                                >
                                                    Mailgun
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="tab-content">
                                            <div
                                                class="tab-pane show active"
                                                id="bottom-tab1"
                                            >
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <span>
                                                            In this, email
                                                            settings are set by
                                                            system configuration
                                                        </span>
                                                        <div class="text-left mt-3">
                                                            <button
                                                                type="submit"
                                                                class="btn btn-primary"
                                                            >
                                                                Set as Default
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="tab-pane"
                                                id="bottom-tab2"
                                            >
                                                <div class="row mlt-5">
                                                    <div class="col-lg-5">
                                                        <div class="row form-group">
                                                            <label
                                                                for="addressline1"
                                                                class="p-l-0 col-form-label input-label"
                                                            >
                                                                Service Url
                                                            </label>
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                id="addressline1"
                                                                placeholder=""
                                                                value=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mlt-5">
                                                    <div class="col-lg-5">
                                                        <div class="row form-group">
                                                            <label
                                                                for="addressline1"
                                                                class="p-l-0 col-form-label input-label"
                                                            >
                                                                Secret Api
                                                            </label>
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                id="addressline1"
                                                                placeholder=""
                                                                value=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mlt-5">
                                                    <div class="col-lg-5">
                                                        <div class="row form-group">
                                                            <label
                                                                for="addressline1"
                                                                class="p-l-0 col-form-label input-label"
                                                            >
                                                                Email
                                                            </label>
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                id="addressline1"
                                                                placeholder=""
                                                                value=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-left mt-3">
                                                    <button
                                                        type="submit"
                                                        class="btn btn-primary"
                                                    >
                                                        Set as Default
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Email
