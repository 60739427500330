import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import InputLabel from '@mui/material/InputLabel'
import Select from 'react-select'


import axios from 'axios'
// import { storage } from '../../../firebase/index'

import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../firebase'
import {  thumbsContainerState,
    thumb,
    img,
    thumbInner,
    baseStyle,
    baseStyle02, } from '../Styles'
import { FormControl } from 'react-bootstrap'
import { data } from 'jquery'

function Banners() {
    const [paymentMode,setPaymentMode]=useState("")
    const [role,setRole]=useState("")
    const [disable,setDisable]=useState(true)
    const [Files, setFiles] = useState([])
    const[ VideoFiles,setVideoFiles]= useState([])
    const [videos,setVideos]=useState([])
    const [file, setFile] = useState(null)
    const [docImg, setDocImg] = useState(null)
    const [docVid, setDocVid] = useState(null)
    const [imageUrls,setImageurl]=useState([])
    const [videoUrls,setVideoUrl]=useState([])
    const [progress, setProgress] = useState([])
    const [sellCrop, setSellCrop] = useState({
        MicroEnterpreneurId: '',
        farmerId: '',
        category: '',
        cropType: '',
        cropName: '',
        variety: '',
        // variety: "",
        availabilityDate: '',
        availabilityDay: '',
        expectedQuailty: '',
        expectedPriceMin: '',
        expectedPriceMax: '',
        unit: '',
        description: '',
        photoUrl: [],
        videoUrl:[],
        bookedBy: 'MI',
        createdBy: 'Admin',
    })

    const PGthumbs =
    Files &&
    Files.map((file, index) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt="" />
            </div>
        </div>
    ))
    const VideoThumbs =
    VideoFiles &&
    VideoFiles.map((file, index) => (

        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt="" />
            </div>
        </div>
    ))
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            console.log('file1', acceptedFiles)
            console.log('file', acceptedFiles[0])
            setFiles(
                acceptedFiles.map((file) =>
                    //  { console.log("file",file)},
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
            let val = []
            let arr = []
            for (let i = 0; i < acceptedFiles.length; i++) {
                setDocImg(URL.createObjectURL(acceptedFiles[i]))
                const doc = acceptedFiles[i]
                if (acceptedFiles[i]) {
                    const uploadTask = ref(storage, `docs/${doc.name}`)
                    const uploadTask02 = uploadBytesResumable(uploadTask, doc)
                    uploadTask02.on(
                        'state_changed',
                        (snapshot) => {},
                        (error) => {
                            // Handle unsuccessful uploads
                        },
                        () => {
                            getDownloadURL(uploadTask).then((url) => {
                                console.log('url', url)
    
                                arr.push(url)
                            })
                        }
                    )
                    setSellCrop({ ...sellCrop, photoUrl: arr })
                    console.log('arr', arr)
                }
            }
    
            // setDocImg(URL.createObjectURL(acceptedFiles[0]));
            //       const doc = acceptedFiles[0];
            //       if(acceptedFiles[0]) {
            //         const uploadTask = ref(storage, `docs/${doc.name}`);
            //         const uploadTask02 = uploadBytesResumable(uploadTask, doc);
            //         uploadTask02.on('state_changed',
            //           (snapshot) => {
            //           },
            //           (error) => {
            //             // Handle unsuccessful uploads
            //           },
            //           () => {
            //             getDownloadURL(uploadTask).then((url) => {
            //               setSellCrop({...sellCrop, photoUrl: url});
            //             });
            //           }
            //         );
            //       }
        },
    })

    const { VideoacceptedFiles, getRootProps:getVideoRootProps,getInputProps: getVideoInputProps } = useDropzone({
        accept: 'video/*',
        onDrop: (acceptedFiles) => {
            console.log('file1', acceptedFiles)
            console.log('file', acceptedFiles[0])
            setVideoFiles(
                acceptedFiles.map((file) =>
                    //  { console.log("file",file)},
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
            let val = []
            let arr = []
            for (let i = 0; i < acceptedFiles.length; i++) {
                setDocVid(URL.createObjectURL(acceptedFiles[i]))
                const doc = acceptedFiles[i]
                if (acceptedFiles[i]) {
                    const uploadTask = ref(storage, `docs/${doc.name}`)
                    const uploadTask02 = uploadBytesResumable(uploadTask, doc)
                    uploadTask02.on(
                        'state_changed',
                        (snapshot) => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

        setProgress((prevProgress) => {
          const newProgress = [...prevProgress]
          newProgress[i] = progress.toFixed(2)
          return newProgress
        })
                        },
                        (error) => {
                            // Handle unsuccessful uploads
                        },
                        () => {
                            getDownloadURL(uploadTask).then((url) => {
                                console.log('url', url)
    
                                arr.push(url)
                            })
                        }
                    )
                    setSellCrop({ ...sellCrop, videoUrl: arr })
                    setDisable(false)
                    console.log('arr', arr)
                }
            }
    
            // setDocImg(URL.createObjectURL(acceptedFiles[0]));
            //       const doc = acceptedFiles[0];
            //       if(acceptedFiles[0]) {
            //         const uploadTask = ref(storage, `docs/${doc.name}`);
            //         const uploadTask02 = uploadBytesResumable(uploadTask, doc);
            //         uploadTask02.on('state_changed',
            //           (snapshot) => {
            //           },
            //           (error) => {
            //             // Handle unsuccessful uploads
            //           },
            //           () => {
            //             getDownloadURL(uploadTask).then((url) => {
            //               setSellCrop({...sellCrop, photoUrl: url});
            //             });
            //           }
            //         );
            //       }
        },
    })
    const PaymentOptions = [
        {
            label: 'Farmer',
            value: 'farmer',
        },
        {
            label: 'Trader',
            value: 'trader',
        },
        {
            label: 'Input Supplier',
            value: 'input',
        },
        {
            label: 'Livestock',
            value: 'livestock',
        },
        {
            label: 'Agri Machine',
            value: 'machine',
        },
        {
            label: 'Agri Transport',
            value: 'vehicle',
        },
        {
            label: 'FPO',
            value: 'fpo',
        },
        {
            label: 'Micro-entrepreneur',
            value: 'micro',
        },
    ] 
    const handlePaymentStatus=async(e)=>{
        const token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        var Data={
            role:paymentMode.value,
            imageUrls:sellCrop.photoUrl,
            videoUrl:sellCrop.videoUrl
        }
        console.log(Data,'data')
        await axios.post(`https://prod-api.krishione.com/auth-function/post-banner`, Data, config)
        .then(res => {
          alert("Successfully Posted")
        })
    }

    const fetchBanner=async(e)=>{
        setRole(e)
        const token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
      
        await axios.get(`https://prod-api.krishione.com/auth-function/get-banner/${e.value}`,  config)
        .then(res => {
            setImageurl(res.data.banner[0].imageUrls)
            setVideoUrl(res.data.banner[0].videoUrl)
        })
    }
    return (
        <div class="col-xl-9 col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Banners</h5>
                </div>
        
                <div class="card-body">
              
                    <form>
                        <div class="">
                        <div
                        class="col-md-3"
                       
                    >
                        <h3>Select Role</h3>
                       
                            <Select
                                style={{
                                    width: '270px',
                                    height: '40px',
                                }}
                                placeholder={'Select Role'}
                                value={paymentMode}
                                onChange={(e) => setPaymentMode(e)}
                                options={PaymentOptions}
                            />
                            <br/>
<br/>
                            
                         

                    </div>
                            <div class="col-sm-4">
                            <div className="col-md-12 mt-3">
                                <InputLabel
                                    id="demo-simple-select-standard-label"
                                    style={{ color: 'black' }}
                                >
                                    Upload  Photos:
                                </InputLabel>
                                <div
                                    class="form-group row widget-3"
                                    style={{
                                        margin: 'auto',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <section
                                        className="container"
                                        style={baseStyle02}
                                    >
                                        <div
                                            {...getRootProps({
                                                className: 'dropzone',
                                            })}
                                            style={{
                                                borderRadius: '5px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '100px',
                                            }}
                                        >
                                            <input
                                                {...getInputProps()}
                                                multiple={true}
                                            />
                                            {Files && (
                                                <>
                                                    {Files.length === 0 && (
                                                        <p align="center">
                                                            Drop Image here or
                                                            click to upload.
                                                        </p>
                                                    )}
                                                </>
                                            )}
                                            {Files && (
                                                <>
                                                    {Files.length > 0 && (
                                                        <aside
                                                            style={
                                                                thumbsContainerState
                                                            }
                                                        >
                                                            {PGthumbs}
                                                        </aside>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </section>
                                </div>
                            </div>
                                <div class="d-flex align-items-center">
                                   <div className="col-md-12 mt-3">
                                <InputLabel
                                    id="demo-simple-select-standard-label"
                                    style={{ color: 'black' }}
                                >
                                    Upload Video:       {progress.map((progress, i) => (
        <div key={i}>  {progress}% Uploaded</div>
      ))}
                                </InputLabel>
                                <div
                                    class="form-group row widget-3"
                                    style={{
                                        margin: 'auto',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <section
                                        className="container"
                                        style={baseStyle02}
                                    >
                                        <div
                                            {...getVideoRootProps({
                                                className: 'dropzone',
                                            })}
                                            style={{
                                                borderRadius: '5px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '100px',
                                            }}
                                        >
                                            <input
                                                {...getVideoInputProps()}
                                                multiple={true}
                                            />
                                            {videos && (
                                                <>
                                                    {videos.length === 0 && (
                                                        <p align="center">
                                                            Drop Video here or
                                                            click to upload.
                                                        </p>
                                                    )}
                                                </>
                                            )}
                                            {videos && (
                                                <>
                                                    {videos.length > 0 && (
                                                        <aside
                                                            style={
                                                                thumbsContainerState
                                                            }
                                                        >
                                                            {VideoThumbs}
                                                        </aside>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </section>
                                </div>
                            </div>
                                </div>
                                <button variant="contained" onClick={(e)=>handlePaymentStatus(e)}
                                                            component="span"
                                                            color="success"  className="btn btn-primary mt-3">Submit</button>
                            
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <h3>Banner List</h3>
            <br/>
            <br/>

            <h4>Select Role</h4>
                       
                            <Select
                                style={{
                                    width: '270px',
                                    height: '40px',
                                }}
                                placeholder={'Select Role'}
                                value={role}
                                onChange={(e) => fetchBanner(e)}
                                options={PaymentOptions}
                            />
                            <h5 style={{padding:"15px"}}>Images:</h5>
                            <div>
                                {imageUrls.length && imageUrls.map(a=>{
                                    return(
                                         <img width="350" src={a} alt='df'/>
                                    )
                                   
                                })}
                                            <br/>

                                                           <h5 style={{padding:"15px"}}>Videos:</h5>

                                 {videoUrls.length && videoUrls.map(a=>{
                                    return(
                                        <video width="320" height="240" controls>
  <source src={a}type="video/mp4"/>
  Your browser does not support the video tag.
</video>
                                        
                                    )
                                   
                                })}
                            </div>
        </div>
    )
}

export default Banners
