import { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import { visuallyHidden } from '@mui/utils'

export default function EnhancedTable({ fourthRow }) {
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('calories')
    const [selected, setSelected] = useState([])
    const [page, setPage] = useState(0)
    const [dense, setDense] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    // Table setup from this line
    function createData(
        id,
        farmer,
        category,
        price,
        deliveryTime,
        status,
        pendingAmt,
        created
    ) {
        return {
            id,
            farmer,
            category,
            price,
            deliveryTime,
            status,
            pendingAmt,
            created,
        }
    }

    const rows = [
        createData(
            '#001',
            'Farmer 01',
            '...',
            'RS. 1500',
            'Sep 7, 8:00 AM - Sep 7, 9:00 AM',
            'Pending',
            'Rs. 4500',
            'Nov 21, 12:30 AM'
        ),
        createData(
            '#002',
            'Farmer 02',
            '...',
            'RS. 2500',
            'Aug 7, 8:00 AM - Sep 7, 9:00 AM',
            'Pending',
            'Rs. 5000',
            'Nov 21, 12:30 AM'
        ),
        createData(
            '#003',
            'Farmer 03',
            '...',
            'RS. 1800',
            'Nov 7, 8:00 AM - Sep 7, 9:00 AM',
            'Completed',
            'Rs. 8000',
            'Nov 21, 12:30 AM'
        ),
        createData(
            '#004',
            'Farmer 04',
            '...',
            'RS. 6000',
            'Jun 7, 8:00 AM - Sep 7, 9:00 AM',
            'Pending',
            'Rs. 1500',
            'Nov 21, 12:30 AM'
        ),
        createData(
            '#005',
            'Farmer 05',
            '...',
            'RS. 500',
            'Jul 7, 8:00 AM - Sep 7, 9:00 AM',
            'Completed',
            'Rs. 500',
            'Nov 21, 12:30 AM'
        ),
        createData(
            '#005',
            'Farmer 05',
            '...',
            'RS. 500',
            'Jul 7, 8:00 AM - Sep 7, 9:00 AM',
            'Completed',
            'Rs. 500',
            'Nov 21, 12:30 AM'
        ),
        createData(
            '#005',
            'Farmer 05',
            '...',
            'RS. 500',
            'Jul 7, 8:00 AM - Sep 7, 9:00 AM',
            'Completed',
            'Rs. 500',
            'Nov 21, 12:30 AM'
        ),
        createData(
            '#005',
            'Farmer 05',
            '...',
            'RS. 500',
            'Jul 7, 8:00 AM - Sep 7, 9:00 AM',
            'Completed',
            'Rs. 500',
            'Nov 21, 12:30 AM'
        ),
        createData(
            '#005',
            'Farmer 05',
            '...',
            'RS. 500',
            'Jul 7, 8:00 AM - Sep 7, 9:00 AM',
            'Completed',
            'Rs. 500',
            'Nov 21, 12:30 AM'
        ),
        createData(
            '#005',
            'Farmer 05',
            '...',
            'RS. 500',
            'Jul 7, 8:00 AM - Sep 7, 9:00 AM',
            'Completed',
            'Rs. 500',
            'Nov 21, 12:30 AM'
        ),
        createData(
            '#005',
            'Farmer 05',
            '...',
            'RS. 500',
            'Jul 7, 8:00 AM - Sep 7, 9:00 AM',
            'Completed',
            'Rs. 500',
            'Nov 21, 12:30 AM'
        ),
    ]

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1
        }
        if (b[orderBy] > a[orderBy]) {
            return 1
        }
        return 0
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy)
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index])
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0])
            if (order !== 0) {
                return order
            }
            return a[1] - b[1]
        })
        return stabilizedThis.map((el) => el[0])
    }

    function EnhancedTableHead(props) {
        const headCells = [
            {
                id: 'id',
                numeric: false,
                disablePadding: true,
                label: 'Order Id',
            },
            {
                id: 'farmer',
                numeric: true,
                disablePadding: false,
                label: 'Customer ID',
            },
            {
                id: 'category',
                numeric: true,
                disablePadding: false,
                label: 'Mob Num',
            },
            {
                id: 'price',
                numeric: true,
                disablePadding: false,
                label: fourthRow,
            },
            {
                id: 'deliveryTime',
                numeric: true,
                disablePadding: false,
                label: 'Order Status',
            },
            {
                id: 'status',
                numeric: true,
                disablePadding: false,
                label: 'Amt',
            },
            {
                id: 'pendingAmt',
                numeric: true,
                disablePadding: false,
                label: 'Payment Status',
            },
            {
                id: 'created',
                numeric: true,
                disablePadding: false,
                label: 'CREATED',
            },
        ]

        const {
            onSelectAllClick,
            order,
            orderBy,
            numSelected,
            rowCount,
            onRequestSort,
        } = props
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property)
        }

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align="center"
                            style={{ backgroundColor: '#D3D3D3' }}
                            sortDirection={
                                orderBy === headCell.id ? order : false
                            }
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={
                                    orderBy === headCell.id ? order : 'asc'
                                }
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        )
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    }

    const EnhancedTableToolbar = (props) => {
        const { numSelected } = props

        return (
            <Toolbar>
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Orders
                </Typography>

                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Filter list">
                        <IconButton>
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Toolbar>
        )
    }

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    }
    // Table setup ends to this line

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name)
            setSelected(newSelecteds)
            return
        }
        setSelected([])
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const isSelected = (name) => selected.indexOf(name) !== -1

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

    return (
        <>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Box sx={{ width: '100%', marginBottom: '20px' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar numSelected={selected.length} />
                    <TableContainer>
                        <Table
                            style={{ width: '92%', margin: '4%' }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody style={{ marginBottom: '20px' }}>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(
                                            row.name
                                        )
                                        const labelId = `enhanced-table-checkbox-${index}`

                                        return (
                                            <TableRow
                                                hover
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.name}
                                                selected={isItemSelected}
                                            >
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    align="center"
                                                    scope="row"
                                                    padding="none"
                                                >
                                                    {row.id}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.farmer}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.category}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.price}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.deliveryTime}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.status}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.pendingAmt}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.created}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height:
                                                (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
        </>
    )
}
