import { useEffect, useState, useMemo } from 'react'
import { useTable, useSortBy } from 'react-table'
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import axios from 'axios'

import { format } from 'date-fns'
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'

const ListTrader = () => {
    const [reports, setReports] = useState([])
    const [from, setFrom] = useState(Date.parse(new Date('01/01/1900')))
    const [to, setTo] = useState(Date.parse(new Date('01/01/1900')))
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(0)
    const [pageCount,setPageCount]=useState(5)
    const [count, setCount] = useState(0)

    const CustomPagination = () => (
        <ReactPaginate
          previousLabel={''}
          nextLabel={''}
          forcePage={currentPage}
          onPageChange={page => onPageChange(page)}
          pageCount={count/10}
          breakLabel={'...'}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          activeClassName='active'
          pageClassName='page-item'
          breakClassName='page-item'
          nextLinkClassName='page-link'
          pageLinkClassName='page-link'
          breakLinkClassName='page-link'
          previousLinkClassName='page-link'
          nextClassName='page-item next-item'
          previousClassName='page-item prev-item'
          containerClassName={'pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1'}
        />
      )
    useEffect(() => {
        if (to > 0 && from > 0) {
            getReports(0)
        }
    }, [to, from, page, limit])

    const getReports = async (page=0) => {
        var token = localStorage.getItem('token')
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_ENDPOINT}/auth-function/dashboard-count/trader?from=${from}&to=${to}&page=${page*10}&limit=${limit}`,
            config
        )
        setCount(data.count)

        if (data != undefined && data.data.length != 0) {
            setReports(data.data)
            return
        }
    }
    const onPageChange = (page) => {
        setCurrentPage(page.selected)

        console.log('ran', page)
        getReports(page.selected)
    }
    const handleCallback = (start, end, label) => {
        const d1 = Date.parse(start._d)
        const d2 = Date.parse(end._d)
        setFrom(d1)
        setTo(d2)
    }

    const COLUMNS = [
        {
            name: 'ID',
            selector:  row => row.id,
        },
        {
            name: 'Name',
            selector:  row => row.first_name,
        },
        {
            name: 'Mobile',
            selector: (row) => {
                console.log('a', row)
                let url
                switch (row.role) {
                    case 'micro-entrepreneur':
                        url = `MicroEntrepDetails/${row.mobile_number}`
                        return <a href={url}>{row.mobile_number}</a>
                }
                return row.mobile_number
            },
        },
        {
            name: 'Gender',
            selector:  row => row.gender,
        },
        {
            name: 'DOB',
            selector: (row) => {
                return row.dob.substring(0, 10)
            },
        },
        {
            name: 'State',
            selector:  row => row.state,
        },
        {
            name: 'District',
            selector: row => row.district,
        },
        {
            name: 'Sub Dist',
            selector: row => row.sub_distric,
        },
        {
            name: 'Village',
            selector:  row => row.village,
        },
        {
            name: 'Pincode',
            selector:  row => row.pincode,
        },
        {
            name: 'Created Num',
            selector: row=>row.created_by[0]?.mobile_number,
        },
        {
            name: 'Business Mode',
            selector:  row => row.business_mode,

        },
        {
            name: 'Business Mode',
            selector:  row => row.business_mode,

        },
        {
            name: 'Firm Name',
            selector: (row) => {
                if (row.farm_info[0].firm_name)
                    return row.farm_info[0].firm_name
                else return 'null'
            },
        },
        {
            name: 'Created Date',
            selector: (row) => {
                return format(row.created_at, 'dd/MM/yyyy')
            },
        },
     
    ]
    return (
        <> <div style={{ display: 'flex' }}>
        <DateRangePicker
            initialSettings={{
                startDate: new Date().getDate(),
                endDate: new Date().getDate(),
            }}
            onCallback={handleCallback}
        >
            <input className="date-filter" />
        </DateRangePicker>
    </div>
   {reports.length?
<DataTable
  noHeader
  pagination
  data={reports}
  selectableRows
  columns={COLUMNS}
  expandOnRowClicked
  className='react-dataTable'
  paginationComponent={CustomPagination}
  paginationDefaultPage={currentPage + 1}
//   expandableRowsComponent={ExpandableTable}
  paginationRowsPerPageOptions={[10, 25, 50, 100]}
/> : <div className='nodatapagination'> 
<h4 style={{textAlign:"center"}}>No More Data !</h4>
  <CustomPagination/>
  </div>}
    <div className="footer-div">
        <button
            className="footer-btns"
            onClick={() => {
                if (page > 0) setPage(page - 1)
            }}
        >
            Previous
        </button>
        <div>{page}</div>
        <button
            className="footer-btns"
            onClick={() => setPage(page + 1)}
        >
            Next
        </button>
    </div>
        </>
    )
}
export default ListTrader
