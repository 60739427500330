import React, { useState } from 'react'
import InputCatelog from './InputCatelog/InputIncentives'
import LivestockCatelog from './LivestockCatelog/LivestockIncentives'
import MachineCatelog from './MachineCatelog/MachineIncentives'
import TransportCatelog from './TransportCatelog/TransportIncentives'
import CropCatelog from './MicroEntIncentives/MicroEntIncentives'

function IncentivesCatelog() {
    const [tab, setTab] = useState(1)

    return (
        <div class="content container-fluid">
            <div class="page-header">
                <div class="row align-items-center">
                    <div class="col">
                        <h3 class="page-title">Incentives Catelog</h3>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a href="/Home">Dashboard</a>
                            </li>
                            <li class="breadcrumb-item active">
                                Incentives Catelog
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div class="card card-table p-10-25">
                                <div class="card-header">
                                    <h3 class="card-title">
                                        Create Incentives
                                    </h3>
                                </div>
                                <form
                                    class="card-body"
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        console.log('Form Successfully working')
                                    }}
                                >
                                    <>
                                        <div class="tab-content">
                                            <div action="#">
                                                <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                                    <li class="nav-item">
                                                        <a
                                                            class={
                                                                tab === 1
                                                                    ? 'nav-link active'
                                                                    : 'nav-link'
                                                            }
                                                            href="#bottom-justified-tab1"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(1)
                                                            }
                                                        >
                                                            Input Supplier
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab2"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(2)
                                                            }
                                                        >
                                                            Livestock{' '}
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab3"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(3)
                                                            }
                                                        >
                                                            Machine{' '}
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link"
                                                            href="#bottom-justified-tab4"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(4)
                                                            }
                                                        >
                                                            Transport{' '}
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            class="nav-link "
                                                            href="#bottom-justified-tab5"
                                                            data-bs-toggle="tab"
                                                            onClick={(e) =>
                                                                setTab(5)
                                                            }
                                                        >
                                                            Micro Enterpreneur{' '}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                </form>
                            </div>

                            {tab === 1 && <InputCatelog />}

                            {tab === 2 && <LivestockCatelog />}

                            {tab === 3 && <MachineCatelog />}

                            {tab === 4 && <TransportCatelog />}

                            {tab === 5 && <CropCatelog />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IncentivesCatelog

//<div class="row">
//     <div class="col-sm-12">
//        <table class="table table-center table-hover datatable dataTable no-footer" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
//           <thead class="thead-light">
//              <tr role="row">
//                 <th class="sorting_asc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-sort="ascending" aria-label="Customer: activate to sort column descending" >Customer</th>
//                 <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Email: activate to sort column ascending" >Email</th>
//                 <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Amount Due: activate to sort column ascending" >State</th>
//                 <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Registered On: activate to sort column ascending" >District</th>
//                 <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Registered On: activate to sort column ascending" >Village</th>
//                 <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Registered On: activate to sort column ascending" >Crops</th>
//                 <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Status: activate to sort column ascending" >Status</th>
//                 <th class="text-right sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Actions: activate to sort column ascending" >Actions</th>
//                 <th class="text-right sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Actions: activate to sort column ascending" >Manage</th>
//              </tr>
//           </thead>
//           <tbody>
//              <tr role="row" class="odd">
//                 <td class="sorting_1">
//                    <h2 class="table-avatar">
//                       <a href="#" class="avatar avatar-sm me-2">
//                           <img class="avatar-img rounded-circle" src="../assets/images/6.jpg" alt="User Image"/></a>
//                       <a href="#">Alex Campbell <span>9876543210</span></a>
//                    </h2>
//                 </td>
//                 <td>alexcampbell@example.com</td>
//                 <td>Karnataka</td>
//                 <td>Mangalore</td>
//                 <td>Mangalore</td>
//                 <td>Ash Gourd</td>
//                 <td><span class="badge badge-pill bg-success-light">Active</span></td>
//                 <td class="text-right">
//                    <a href="#" class="btn btn-sm btn-white text-success me-2"><i class="far fa-edit me-1"></i> Edit</a>
//                    <a href="#" class="btn btn-sm btn-white text-danger me-2"> Deactivate</a>
//                 </td>
//                 <td class="text-right">
//                   <a href="#" class="btn btn-sm btn-white text-warning me-2">Expenses</a>
//                 </td>
//              </tr>
//              <tr role="row" class="even">
//                 <td class="sorting_1">
//                    <h2 class="table-avatar">
//                       <a href="#" class="avatar avatar-sm me-2">
//                           <img class="avatar-img rounded-circle" src="../assets/images/1.jpg" alt="User Image"/></a>
//                       <a href="#">Barbara Moore <span>9876543210</span></a>
//                    </h2>
//                 </td>
//                 <td>barbaramoore@example.com</td>
//                 <td>Karnataka</td>
//                 <td>Mangalore</td>
//                 <td>Mangalore</td>
//                 <td>Ash Gourd</td>
//                 <td><span class="badge badge-pill bg-success-light">Active</span></td>
//                 <td class="text-right">
//                    <a href="#" class="btn btn-sm btn-white text-success me-2"><i class="far fa-edit me-1"></i> Edit</a>
//                    <a href="#" class="btn btn-sm btn-white text-danger me-2"> Deactivate</a>
//                 </td>
//                 <td class="text-right">
//                   <a href="#" class="btn btn-sm btn-white text-warning me-2">Expenses</a>
//                 </td>
//              </tr>
//              <tr role="row" class="odd">
//                 <td class="sorting_1">
//                    <h2 class="table-avatar">
//                       <a href="#" class="avatar avatar-sm me-2">
//                           <img class="avatar-img rounded-circle" src="../assets/images/2.jpg" alt="User Image"/></a>
//                       <a href="#">Brian Johnson <span>9876543210</span></a>
//                    </h2>
//                 </td>
//                 <td>brianjohnson@example.com</td>
//                 <td>Karnataka</td>
//                 <td>Mangalore</td>
//                 <td>Mangalore</td>
//                 <td>Ash Gourd</td>
//                 <td><span class="badge badge-pill bg-success-light">Active</span></td>
//                 <td class="text-right">
//                    <a href="#" class="btn btn-sm btn-white text-success me-2"><i class="far fa-edit me-1"></i> Edit</a>
//                    <a href="#" class="btn btn-sm btn-white text-danger me-2"> Deactivate</a>
//                 </td>
//                 <td class="text-right">
//                   <a href="#" class="btn btn-sm btn-white text-warning me-2">Expenses</a>
//                 </td>
//              </tr>
//              <tr role="row" class="even">
//                 <td class="sorting_1">
//                    <h2 class="table-avatar">
//                       <a href="#" class="avatar avatar-sm me-2">
//                           <img class="avatar-img rounded-circle" src="../assets/images/3.jpg" alt="User Image"/></a>
//                       <a href="#">Greg Lynch <span>9876543210</span></a>
//                    </h2>
//                 </td>
//                 <td>greglynch@example.com</td>
//                 <td>Karnataka</td>
//                 <td>Mangalore</td>
//                 <td>Mangalore</td>
//                 <td>Ash Gourd</td>
//                 <td><span class="badge badge-pill bg-success-light">Active</span></td>
//                 <td class="text-right">
//                    <a href="#" class="btn btn-sm btn-white text-success me-2"><i class="far fa-edit me-1"></i> Edit</a>
//                    <a href="#" class="btn btn-sm btn-white text-danger me-2"> Deactivate</a>
//                 </td>
//                 <td class="text-right">
//                   <a href="#" class="btn btn-sm btn-white text-warning me-2">Expenses</a>
//                 </td>
//              </tr>
//              <tr role="row" class="odd">
//                 <td class="sorting_1">
//                    <h2 class="table-avatar">
//                       <a href="#" class="avatar avatar-sm me-2">
//                           <img class="avatar-img rounded-circle" src="../assets/images/4.jpg" alt="User Image"/></a>
//                       <a href="#">Jennifer Floyd <span>9876543210</span></a>
//                    </h2>
//                 </td>
//                 <td>jenniferfloyd@example.com</td>
//                 <td>Karnataka</td>
//                 <td>Mangalore</td>
//                 <td>Mangalore</td>
//                 <td>Ash Gourd</td>
//                 <td><span class="badge badge-pill bg-success-light">Active</span></td>
//                 <td class="text-right">
//                    <a href="#" class="btn btn-sm btn-white text-success me-2"><i class="far fa-edit me-1"></i> Edit</a>
//                    <a href="#" class="btn btn-sm btn-white text-danger me-2"> Deactivate</a>
//                 </td>
//                 <td class="text-right">
//                   <a href="#" class="btn btn-sm btn-white text-warning me-2">Expenses</a>
//                 </td>
//              </tr>
//              <tr role="row" class="even">
//                 <td class="sorting_1">
//                    <h2 class="table-avatar">
//                       <a href="#" class="avatar avatar-sm me-2">
//                           <img class="avatar-img rounded-circle" src="../assets/images/5.jpg" alt="User Image"/></a>
//                       <a href="#">John Blair <span>9876543210</span></a>
//                    </h2>
//                 </td>
//                 <td>johnblair@example.com</td>
//                 <td>Karnataka</td>
//                 <td>Mangalore</td>
//                 <td>Mangalore</td>
//                 <td>Ash Gourd</td>
//                 <td><span class="badge badge-pill bg-success-light">Active</span></td>
//                 <td class="text-right">
//                    <a href="#" class="btn btn-sm btn-white text-success me-2"><i class="far fa-edit me-1"></i> Edit</a>
//                    <a href="#" class="btn btn-sm btn-white text-danger me-2"> Deactivate</a>
//                 </td>
//                 <td class="text-right">
//                   <a href="#" class="btn btn-sm btn-white text-warning me-2">Expenses</a>
//                 </td>
//              </tr>
//              <tr role="row" class="odd">
//                 <td class="sorting_1">
//                    <h2 class="table-avatar">
//                       <a href="#" class="avatar avatar-sm me-2">
//                           <img class="avatar-img rounded-circle" src="../assets/images/6.jpg" alt="User Image"/></a>
//                       <a href="#">Joseph Collins <span>9876543210</span></a>
//                    </h2>
//                 </td>
//                 <td>josephcollins@example.com</td>
//                 <td>Karnataka</td>
//                 <td>Mangalore</td>
//                 <td>Mangalore</td>
//                 <td>Ash Gourd</td>
//                 <td><span class="badge badge-pill bg-success-light">Active</span></td>
//                 <td class="text-right">
//                    <a href="#" class="btn btn-sm btn-white text-success me-2"><i class="far fa-edit me-1"></i> Edit</a>
//                    <a href="#" class="btn btn-sm btn-white text-danger me-2"> Deactivate</a>
//                 </td>
//                 <td class="text-right">
//                   <a href="#" class="btn btn-sm btn-white text-warning me-2">Expenses</a>
//                 </td>
//              </tr>
//              <tr role="row" class="even">
//                 <td class="sorting_1">
//                    <h2 class="table-avatar">
//                       <a href="#" class="avatar avatar-sm me-2">
//                           <img class="avatar-img rounded-circle" src="../assets/images/1.jpg" alt="User Image"/></a>
//                       <a href="#">Karlene Chaidez <span>9876543210</span></a>
//                    </h2>
//                 </td>
//                 <td>karlenechaidez@example.com</td>
//                 <td>Karnataka</td>
//                 <td>Mangalore</td>
//                 <td>Mangalore</td>
//                 <td>Ash Gourd</td>
//                 <td><span class="badge badge-pill bg-success-light">Active</span></td>
//                 <td class="text-right">
//                    <a href="#" class="btn btn-sm btn-white text-success me-2"><i class="far fa-edit me-1"></i> Edit</a>
//                    <a href="#" class="btn btn-sm btn-white text-danger me-2"> Deactivate</a>
//                 </td>
//                 <td class="text-right">
//                   <a href="#" class="btn btn-sm btn-white text-warning me-2">Expenses</a>
//                 </td>
//              </tr>
//              <tr role="row" class="odd">
//                 <td class="sorting_1">
//                    <h2 class="table-avatar">
//                       <a href="#" class="avatar avatar-sm me-2">
//                           <img class="avatar-img rounded-circle" src="../assets/images/2.jpg" alt="User Image"/></a>
//                       <a href="#">Leatha Bailey <span>9876543210</span></a>
//                    </h2>
//                 </td>
//                 <td>leathabailey@example.com</td>
//                 <td>Karnataka</td>
//                 <td>Mangalore</td>
//                 <td>Mangalore</td>
//                 <td>Ash Gourd</td>
//                 <td><span class="badge badge-pill bg-success-light">Active</span></td>
//                 <td class="text-right">
//                    <a href="#" class="btn btn-sm btn-white text-success me-2"><i class="far fa-edit me-1"></i> Edit</a>
//                    <a href="#" class="btn btn-sm btn-white text-danger me-2"> Deactivate</a>
//                 </td>
//                 <td class="text-right">
//                   <a href="#" class="btn btn-sm btn-white text-warning me-2">Expenses</a>
//                 </td>
//              </tr>
//              <tr role="row" class="even">
//                 <td class="sorting_1">
//                    <h2 class="table-avatar">
//                       <a href="#" class="avatar avatar-sm me-2">
//                           <img class="avatar-img rounded-circle" src="../assets/images/3.jpg" alt="User Image"/></a>
//                       <a href="#">Marie Canales <span>9876543210</span></a>
//                    </h2>
//                 </td>
//                 <td>mariecanales@example.com</td>
//                 <td>Karnataka</td>
//                 <td>Mangalore</td>
//                 <td>Mangalore</td>
//                 <td>Ash Gourd</td>
//                 <td><span class="badge badge-pill bg-success-light">Active</span></td>
//                 <td class="text-right">
//                    <a href="#" class="btn btn-sm btn-white text-success me-2"><i class="far fa-edit me-1"></i> Edit</a>
//                    <a href="#" class="btn btn-sm btn-white text-danger me-2"> Deactivate</a>
//                 </td>
//                 <td class="text-right">
//                   <a href="#" class="btn btn-sm btn-white text-warning me-2">Expenses</a>
//                 </td>
//              </tr>
//           </tbody>
//        </table>
//     </div>
//  </div>
