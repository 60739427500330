import React from 'react'
import Logo from './logonew.jpg'
import Converter from 'number-to-words'

const machineTransportInvoice = ({
    Details,
    TotalPrice,
    invoiceId,
    booking,
    trader,
    taxes
}) => {
    var total;
    let feeAmount
    let bookingfee
    let BookingfeesAmt
    let gstAmount
    let totalCommisionAmt
    let totalCommisionBSAmt
    let totalAmount
    if(taxes.gstPercentage){
        bookingfee= +Details.payment
            .indicate_order_value*Number(taxes.PercentageIncGstBookingFee/100 );
       
            totalCommisionBSAmt= bookingfee+Details.bookingCommision.totalCommisionAmt
            totalCommisionAmt= +Details.area.land_size*Details.bookingCommision.incGSTCommision
    
            totalAmount= Number(Details.payment.indicate_order_value)-totalCommisionBSAmt
         
            console.log(Details,'od')
            BookingfeesAmt= bookingfee/(1+Number(taxes.gstPercentage/100))
            gstAmount= bookingfee-BookingfeesAmt
        console.log(BookingfeesAmt,'sdfg')
        // var result = +taxes.PercentageIncGstBookingFee / (1  + Number(taxes.gstPercentage)/100);
        // feeAmount=result
         total= Number(Details.payment
            .indicate_order_value)-Number(bookingfee);
    }
if(taxes.type==="flat"){
    bookingfee= Number(taxes.bookingFee );
    totalCommisionBSAmt= bookingfee+Details.bookingCommision.totalCommisionAmt
    totalCommisionAmt= +Details.area.land_size*Details.bookingCommision.commsionincGSTCommision

    totalAmount= Details.payment.indicate_order_value-totalCommisionBSAmt
    console.log(totalCommisionBSAmt,TotalPrice,Details.price,'sdfgd')
      total= Number(Details.payment
        .indicate_order_value)-Number(taxes.incGstBookingFee);
        console.log(total,'sdtoal')
}
    var date = new Date(Details.invoiceDate).toString()
    date = new Date(date).toDateString().slice(4, 15)
    return (
        <>
        <div class="card">
            <div class="card-body p_7">
                <div id="invoice-top">
                    <div class="logo">
                        <img
                            style={{ width: '225px', height: '100%' }}
                            src={Logo}
                            alt="Logo"
                        />
                    </div>
                    <div class="title">
                        <h5>
                            Booking Id #
                            <span class="invoiceVal invoice_num">
                                {localStorage.getItem('Bookingmachineid')}
                            </span>
                        </h5>

                        <h5>
                            Invoice #
                            <span class="invoiceVal invoice_num">
                                {Details.invoiceId}
                            </span>
                        </h5>
                        <p>
                            Invoice Date: <span id="invoice_date">{date}</span>
                            <br />
                        </p>
                    </div>
                </div>
                <div className="invoiceAdd">
                    <div class="col-left">
                        {/* <div class="clientlogo"><img src="https://cdn3.iconfinder.com/data/icons/daily-sales/512/Sale-card-address-512.png" alt="Sup" /></div> */}
                        <div class="clientinfo" style={{ fontSize: '12px' }}>
                            <h5 id="supplier">Booking From:</h5>

                            <p>Name: {booking.first_name +"  "+ booking.last_name}</p>
                            <p>ID: {booking.id}</p>
                            <p>Mobile: {booking.mobile_number}</p>
                            <p style={{ width: '330px' }}>
                                Adderss: {booking.full_address}
                            </p>
                        </div>
                    </div>
                    <div class="col-right">
                        {/* <div class="clientlogo"><img src="https://cdn3.iconfinder.com/data/icons/daily-sales/512/Sale-card-address-512.png" alt="Sup" /></div> */}
                        <div class="clientinfo" style={{ fontSize: '12px' }}>
                            <h5 id="supplier">Booking To:</h5>
                            
                            <p>Name: {trader.name}</p>
                            <p>ID: {trader.trader_id}</p>
                            <p>Mobile : {trader.mobile_number}</p>
                            {/* <p>Date:  05-07-2022</p> */}
                        </div>
                    </div>
                </div>
                <form>
                    <div class="row form-group">
                        <div class="col-md-12">
                            <div class="card-body card-body_1">
                                <h5 class="card-title d-flex justify-content-between store_edit">
                                    <span></span>
                                </h5>
                                <div class="table-wrap">
                                    <div class="table-responsive">
                                        <table class="table display setting-td mb-30 store_table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Crop</th>
                                                    <th>Machine</th>
                                                    <th>Machine Name</th>
                                                    <th>Land Size</th>
                                                    <th>Unit</th>
                                                 
                                                    <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    style={{
                                                        borderBottom:
                                                            '1px solid #ededed',
                                                    }}
                                                >
                                                    <td>
                                                        {' '}
                                                        <img
                                                            src={
                                                                Details.crop
                                                                    .image
                                                            }
                                                            style={{
                                                                width: '100px',
                                                                height: '100%',
                                                            }}
                                                            alt=""
                                                        />{' '}
                                                    </td>

                                                    <td>{Details.crop.name}</td>
                                                    <td>
                                                        {' '}
                                                        <img
                                                            src={
                                                                Details.machine
                                                                    .image
                                                            }
                                                            style={{
                                                                width: '100px',
                                                                height: '100%',
                                                            }}
                                                            alt=""
                                                        />{' '}
                                                    </td>

                                                    <td>
                                                        {
                                                            Details.machine
                                                                .type_of_machine
                                                        }
                                                    </td>
                                                    <td>{Details.area.land_size}</td>
                                                    <td>{Details.area.unit}</td>
                                                    
                                                    <td>
                                                        ₹
                                                        {
                                                            Details.payment
                                                                .indicate_order_value
                                                        }{' '}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>Sub Total</td>
                                                    <td>
                                                        ₹
                                                        {
                                                           Details.bookingCommision.totalProductIncGSTAmount
                                                        }{' '}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>Total</b>
                                                    </td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>
                                                            ₹
                                                            {
                                                            Details.userAmount.toFixed(2)
                                                            }
                                                        </b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div>
                                            <hr />
                                            <h6
                                                style={{ padding: '13px 0' }}
                                                class="c22"
                                            >
                                                <span class="c12">
                                                    Amount In Words:{' '}
                                                    {Converter.toWords(
                                                       Details.userAmount.toFixed(2)
                                                    ).toUpperCase()}{' '}
                                                    RUPEES{' '}
                                                </span>
                                            </h6>
                                            <p class="c25">
                                            <span class="c12">
                                                Terms of trade credit
                                                provided by KRISHIVAN
                                                TECHNOLOGIES PVT LTD{' '}
                                            </span>
                                        </p>
                                        <p class="c6">
                                            <span class="c1">
                                            We are not responsible for any damages or leakages in transit.
                                            </span>
                                        </p>
                                        <p class="c23">
                                            <span class="c1">
                                            No credit is given on this invoice.
                                            </span>
                                        </p>
                                        <p class="c23">
                                            <span class="c1">
                                            This invoice doesn't attribute to the receipt of payment
                                            </span>
                                        </p>
                                        <p class="c23">
                                            <span class="c1">
                                            No change in price is allowed during delivery.
                                            </span>
                                        </p>
                                        <p class="c13">
                                            <span class="c1">
                                            Declaration: We declare that the invoice shows the actual price of the goods described and that the particulars are true and correct.
                                            </span>
                                        </p>
                                        <p class="c8">
                                            <span class="c1">
                                            Any discrepancy in this invoice should be pointed within 3 days from the date of this invoice, failing which the invoiced amount shall be deemed to have been accepted as correct.
                                            </span>
                                        </p>
                                        <hr />
                                        <p class="c18">
                                            <span class="c1">
                                                This is a computer generated
                                                invoice and doesn&#39;t
                                                require a signature.{' '}
                                            </span>
                                        </p>
                                        <p class="c13">
                                            <span class="c1">
                                                No tax is payable on reverse
                                                charge basis{' '}
                                            </span>
                                        </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
      
        <div class="card">
            <div class="card-body p_7">
              
                <form>
                    <div class="row form-group">
                        <div class="col-md-12">
                            <div class="card-body card-body_1">
                                <h5 class="card-title d-flex justify-content-between store_edit">
                                    <span></span>
                                </h5>
                                <div class="table-wrap">
                                    <div class="table-responsive">
                                        <table class="table display setting-td mb-30 store_table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Crop</th>
                                                    <th>Machine</th>

                                                    <th>Machine Name</th>
                                                    <th>Land Size</th>

                                                    <th>Unit</th>
                                                    <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    style={{
                                                        borderBottom:
                                                            '1px solid #ededed',
                                                    }}
                                                >
                                                    <td>
                                                        {' '}
                                                        <img
                                                            src={
                                                                Details.crop
                                                                    .image
                                                            }
                                                            style={{
                                                                width: '100px',
                                                                height: '100%',
                                                            }}
                                                            alt=""
                                                        />{' '}
                                                    </td>

                                                    <td>{Details.crop.name}</td>
                                                    <td>
                                                        {' '}
                                                        <img
                                                            src={
                                                                Details.machine
                                                                    .image
                                                            }
                                                            style={{
                                                                width: '100px',
                                                                height: '100%',
                                                            }}
                                                            alt=""
                                                        />{' '}
                                                    </td>

                                                    <td>
                                                        {
                                                            Details.machine
                                                                .type_of_machine
                                                        }
                                                    </td>
                                                    <td>{Details.area.land_size}</td>

                                                    <td>{Details.area.unit}</td>
                                                    <td>
                                                        ₹
                                                        {
                                                            Details.payment
                                                                .indicate_order_value
                                                        }{' '}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>Sub Total</td>
                                                   

                                                    <td>
                                                        ₹
                                                        {
                                                           
                                                            Details.bookingCommision.totalProductIncGSTAmount.toFixed(2)
                                                   
                                                        }
                                                    </td>
                                                </tr>
                                              
                          {/* //flat */}
                                                {taxes.gstAmount?
                                                <>
                                              <tr>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td>Booking Fee</td>
                                             

                                              <td>
                                                  ₹
                                                  {
                                                    
                                                      taxes
                                                          .bookingFee
                                                  }{' '}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td>GST Amt.</td>
                                             

                                              <td>
                                                  ₹
                                                  {Details.bookingFeeGstAmount.toFixed(2)}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td>Booking Fee Inc. GST</td>
                                             

                                              <td>
                                                  ₹
                                                  {Details.bookingfeeIncGst}
                                              </td>
                                          </tr>
                                          </>
                                          :  <>
                                        
                                     
                                      <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>Booking Fee Inc. GST</td>
 

                                          <td>
                                              ₹
                                              {Details.bookingfeeIncGst}
                                              {/* {
                                                Math.round((bookingfee+ Number.EPSILON) * 100) / 100
                                              }{' '} */}
                                          </td>
                                      </tr>
                                      <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>Booking fees Amt</td>
                                         

                                          <td>
                                              ₹
                                              {Details.bookingfee.toFixed(2)}
                                              {/* {BookingfeesAmt} */}
                                              {/* {
                                                 
                                                    Math.round((   Number(taxes.PercentageIncGstBookingFee)-Number(feeAmount)+ Number.EPSILON) * 100) / 100
                                                
                                             
                                              }{' '} */}
                                          </td>
                                      </tr>
                                      
                                      <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>GST Amount</td>
                                         

                                          <td>
                                              ₹
                                              {Details.bookingFeeGstAmount.toFixed(2)}
                                              {/* {BookingfeesAmt} */}
                                              {/* {
                                                 
                                                    Math.round((   Number(taxes.PercentageIncGstBookingFee)-Number(feeAmount)+ Number.EPSILON) * 100) / 100
                                                
                                             
                                              }{' '} */}
                                          </td>
                                      </tr>
                                      </>
                                            }   

                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>Total</b>
                                                    </td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>
                                                            ₹
                                                           {Details.userAmount.toFixed(2)}
                                                        </b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div>
                                            <hr />
                                            <h6
                                                style={{ padding: '13px 0' }}
                                                class="c22"
                                            >
                                                <span class="c12">
                                                    Amount In Words:{' '}
                                                    {Converter.toWords(
                                                        Details.userAmount.toFixed(2)
                                                    ).toUpperCase()}{' '}
                                                    RUPEES{' '}
                                                </span>
                                            </h6>
                                            <p class="c25">
                                            <span class="c12">
                                                Terms of trade credit
                                                provided by KRISHIVAN
                                                TECHNOLOGIES PVT LTD{' '}
                                            </span>
                                        </p>
                                        <p class="c6">
                                            <span class="c1">
                                            We are not responsible for any damages or leakages in transit.
                                            </span>
                                        </p>
                                        <p class="c23">
                                            <span class="c1">
                                            No credit is given on this invoice.
                                            </span>
                                        </p>
                                        <p class="c23">
                                            <span class="c1">
                                            This invoice doesn't attribute to the receipt of payment
                                            </span>
                                        </p>
                                        <p class="c23">
                                            <span class="c1">
                                            No change in price is allowed during delivery.
                                            </span>
                                        </p>
                                        <p class="c13">
                                            <span class="c1">
                                            Declaration: We declare that the invoice shows the actual price of the goods described and that the particulars are true and correct.
                                            </span>
                                        </p>
                                        <p class="c8">
                                            <span class="c1">
                                            Any discrepancy in this invoice should be pointed within 3 days from the date of this invoice, failing which the invoiced amount shall be deemed to have been accepted as correct.
                                            </span>
                                        </p>
                                        <hr />
                                        <p class="c18">
                                            <span class="c1">
                                                This is a computer generated
                                                invoice and doesn&#39;t
                                                require a signature.{' '}
                                            </span>
                                        </p>
                                        <p class="c13">
                                            <span class="c1">
                                                No tax is payable on reverse
                                                charge basis{' '}
                                            </span>
                                        </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="card">
            <div class="card-body p_7">
              
                <form>
                    <div class="row form-group">
                        <div class="col-md-12">
                            <div class="card-body card-body_1">
                                <h5 class="card-title d-flex justify-content-between store_edit">
                                    <span></span>
                                </h5>
                                <div class="table-wrap">
                                    <div class="table-responsive">
                                        <table class="table display setting-td mb-30 store_table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Crop</th>
                                                    <th>Machine</th>

                                                    <th>Machine Name</th>
                                                    <th>Land Size</th>

                                                    <th>Unit</th>
                                                    <th>Price</th>
                                                    <th>discount</th>
                                                    <th>Sale Amt After Disc</th>
                                                    <th>PR Price</th>
                                                    <th>PR GST Amt</th>
                                                    <th>Total Sale Amt After Disc</th>
                                                    <th>Total PR Price </th>
                                                    <th>Total PR GST Amount </th>
                                                    <th>Total PR Amt Inc GST  </th>
                                                    <th>Commision Type</th>
                                                     <th>Inc GST PRD Commision</th>
                                                     <th>PRD Commision</th>
                                                     <th>PRD Commision GST </th>
                                                     <th>Total Commision Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    style={{
                                                        borderBottom:
                                                            '1px solid #ededed',
                                                    }}
                                                >
                                                    <td>
                                                        {' '}
                                                        <img
                                                            src={
                                                                Details.crop
                                                                    .image
                                                            }
                                                            style={{
                                                                width: '100px',
                                                                height: '100%',
                                                            }}
                                                            alt=""
                                                        />{' '}
                                                    </td>

                                                    <td>{Details.crop.name}</td>
                                                    <td>
                                                        {' '}
                                                        <img
                                                            src={
                                                                Details.machine
                                                                    .image
                                                            }
                                                            style={{
                                                                width: '100px',
                                                                height: '100%',
                                                            }}
                                                            alt=""
                                                        />{' '}
                                                    </td>

                                                    <td>
                                                        {
                                                            Details.machine
                                                                .type_of_machine
                                                        }
                                                    </td>
                                                    <td>{Details.area.land_size}</td>

                                                    <td>{Details.area.unit}</td>
                                                    <td>
                                                        ₹
                                                        {
                                                            Details.payment
                                                                .indicate_order_value
                                                        }{' '}
                                                    </td>
                                                    <td>
                                                                        {0
                                                                            // Details.discount
                                                                        }
                                                                    </td>
                                                                    <td>{Details.bookingCommision.SaleAmountAfterDiscount.toFixed(2)} </td>
                                                                    <td>{Details.bookingCommision.ProductPriceIncGST.toFixed(2)} </td>
                                                                    <td>{Details.bookingCommision.ProductGSTAmount.toFixed(2)} </td>
                                                                    <td>{Details.bookingCommision.totalSaleAmountAfterDiscount.toFixed(2)} </td>
                                                                    <td>{Details.bookingCommision.totalProductPrice.toFixed(2)} </td>
                                                                    <td>{Details.bookingCommision.totalProductGSTPrice.toFixed(2)} </td>
                                                                    <td>{Details.bookingCommision.totalProductIncGSTAmount.toFixed(2)} </td>
                                                    <td>{Details.bookingCommision.type} </td>                                                    <td>{Details.bookingCommision.incGSTCommision}</td>
                                                    <td>{Number(Details.bookingCommision.productCommision).toFixed(2)}</td>
                                                    <td>{Number(Details.bookingCommision.productCommisionGST).toFixed(2)}</td>
                                                    <td>{Number(Details.area.land_size)*Details.bookingCommision.incGSTCommision}</td>
                                                </tr>

                                                <tr>
                                                   
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>Sub Total</td>
                                                   

                                                    <td>
                                                        ₹
                                                        {
                                                            Details.bookingCommision.totalProductIncGSTAmount
                                                        }{' '}
                                                    </td>
                                                </tr>
                                              
                          {/* //flat */}
                                                {taxes.gstAmount?
                                                <>
                                              <tr>
                                             
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                              <td>Booking Fee</td>
                                             

                                              <td>
                                                  ₹
                                                  {
                                                    
                                                      taxes
                                                          .bookingFee
                                                  }{' '}
                                              </td>
                                          </tr>
                                          <tr>
                                              
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                              <td>GST Amt.</td>
                                             

                                              <td>
                                                  ₹
                                                  {
                                                      taxes
                                                      .gstAmount
                                                  }{' '}
                                              </td>
                                          </tr>
                                          <tr>
                                             
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                              <td>Booking Fee Inc. GST</td>
                                             

                                              <td>
                                                  ₹
                                                  {
                                                     taxes
                                                     .incGstBookingFee
                                                  }{' '}
                                              </td>
                                          </tr>
                                          </>
                                          :  <>
                                        
                                     
                                      <tr>
                                         
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                          <td>Booking Fee Inc. GST</td>
 

                                          <td>
                                              ₹
                                              {Details.bookingfeeIncGst}
                                              {/* {
                                                Math.round((bookingfee+ Number.EPSILON) * 100) / 100
                                              }{' '} */}
                                          </td>
                                      </tr>
                                      <tr>
                                         
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                          <td>Booking fees Amt</td>
                                         

                                          <td>
                                              ₹
                                              {Details.bookingfee.toFixed(2)}
                                              {/* {BookingfeesAmt} */}
                                              {/* {
                                                 
                                                    Math.round((   Number(taxes.PercentageIncGstBookingFee)-Number(feeAmount)+ Number.EPSILON) * 100) / 100
                                                
                                             
                                              }{' '} */}
                                          </td>
                                      </tr>
                                      
                                      <tr>
                                          
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                          <td>GST Amount</td>
                                         

                                          <td>
                                              ₹
                                              {Details.bookingFeeGstAmount.toFixed(2)}
                                          </td>
                                      </tr>
                                      <tr>
                                         
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                          <td>Total Commision+ BS Amount</td>
                                         

                                          <td>
                                          {Details.totolCommisionBS.toFixed(2)}                                              {Details.bookingCommision.totolCommisionBS.toFixed(2)}

                                          </td>
                                      </tr>
                                   
                                      </>
                                            }   
                                         <tr>
                                          
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                          <td>TCS Amount</td>
                                         

                                          <td>
                                              ₹ {Details.bookingCommision.tcsAmount.toFixed(2)}
                                          </td>
                                      </tr>
                                      <tr>
                                          
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                          <td>Total CM AMT Exc GST</td>
                                         

                                          <td>
                                              ₹ {Details.bookingCommision.totalCmAmtExcGst?.toFixed(2)}
                                          </td>
                                      </tr>
                                      <tr>
                                          
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                          <td>Total CM GST Amt</td>
                                         

                                          <td>
                                              ₹ {Details.bookingCommision.totalCMGSTAmount?.toFixed(2)}
                                          </td>
                                      </tr>
                                      <tr>
                                          
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                          <td>Total CM+BS AMT Exc GST</td>
                                         

                                          <td>
                                              ₹ {Details.bookingCommision.totalCmBsAmtExcGst.toFixed(2)}
                                          </td>
                                      </tr>
                                      <tr>
                                          
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                          <td>Total CM+BS AMT GST AMT</td>
                                         

                                          <td>
                                              ₹ {Details.bookingCommision.totalCmBSAmtGstAmt?.toFixed(2)}
                                          </td>
                                      </tr>
                                                <tr>
                                                 
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>Machine Amount</b>
                                                    </td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>
                                                            ₹ {Details.machineAmount.toFixed(2)}
                                                        </b>
                                                    </td>
                                                </tr>
                                             
                                                <tr>
                                                   
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>Krishi Amount</b>
                                                    </td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>
                                                            ₹ {Details.krishiAmount.toFixed(2)}
                                                        </b>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                 
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>User Amount</b>
                                                    </td>
                                                    <td
                                                        style={{
                                                            borderTop:
                                                                '1px solid #ddd',
                                                        }}
                                                    >
                                                        <b>
                                                            ₹ {Details.userAmount.toFixed(2)}
                                                        </b>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                        <div>
                                            <hr />
                                            <h6
                                                style={{ padding: '13px 0' }}
                                                class="c22"
                                            >
                                                <span class="c12">
                                                    Amount In Words:{' '}
                                                    {Converter.toWords(
                                                        Number(Details.userAmount.toFixed(2))
                                                    ).toUpperCase()}{' '}
                                                    RUPEES{' '}
                                                </span>
                                            </h6>
                                            <p class="c25">
                                            <span class="c12">
                                                Terms of trade credit
                                                provided by KRISHIVAN
                                                TECHNOLOGIES PVT LTD{' '}
                                            </span>
                                        </p>
                                        <p class="c6">
                                            <span class="c1">
                                            We are not responsible for any damages or leakages in transit.
                                            </span>
                                        </p>
                                        <p class="c23">
                                            <span class="c1">
                                            No credit is given on this invoice.
                                            </span>
                                        </p>
                                        <p class="c23">
                                            <span class="c1">
                                            This invoice doesn't attribute to the receipt of payment
                                            </span>
                                        </p>
                                        <p class="c23">
                                            <span class="c1">
                                            No change in price is allowed during delivery.
                                            </span>
                                        </p>
                                        <p class="c13">
                                            <span class="c1">
                                            Declaration: We declare that the invoice shows the actual price of the goods described and that the particulars are true and correct.
                                            </span>
                                        </p>
                                        <p class="c8">
                                            <span class="c1">
                                            Any discrepancy in this invoice should be pointed within 3 days from the date of this invoice, failing which the invoiced amount shall be deemed to have been accepted as correct.
                                            </span>
                                        </p>
                                        <hr />
                                        <p class="c18">
                                            <span class="c1">
                                                This is a computer generated
                                                invoice and doesn&#39;t
                                                require a signature.{' '}
                                            </span>
                                        </p>
                                        <p class="c13">
                                            <span class="c1">
                                                No tax is payable on reverse
                                                charge basis{' '}
                                            </span>
                                        </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </>
    )
}

export default machineTransportInvoice
