import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import { visuallyHidden } from '@mui/utils'

function createData(
    id,
    bookingId,
    firm,
    modeOfBooking,
    amount,
    district,
    status,
    created,
    _id
) {
    return {
        id,
        bookingId,
        firm,
        modeOfBooking,
        district,
        amount,
        status,
        created,
        _id,
    }
}

const headCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'ID',
    },

    {
        id: 'firm',
        numeric: true,
        disablePadding: false,
        label: 'User Id',
    },
    {
        id: 'category',
        numeric: true,
        disablePadding: false,
        label: 'Mobile No',
    },
    {
        id: 'category',
        numeric: true,
        disablePadding: false,
        label: 'Amount',
    },
    {
        id: 'bookingId',
        numeric: true,
        disablePadding: false,
        label: 'District',
    },

    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'status',
    },

    {
        id: 'created',
        numeric: true,
        disablePadding: false,
        label: 'Created',
    },
]

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox"></TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
}

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props

    return (
        <Toolbar>
            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Orders
            </Typography>

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    )
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
}

export default function InputTransporttable(props) {
    const inputs = props.inputs
    console.log('InputSuppliers : ', inputs)
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('calories')
    const [selected, setSelected] = React.useState([])
    const [page, setPage] = React.useState(0)
    const [dense, setDense] = React.useState(false)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const [rows, setRows] = React.useState([])

    React.useEffect(() => {
        var data = inputs.map((input, index) =>
            createData(
                index + 1,
                input.bookingId,
                input.user_data[0].id,
                input.user_data[0].mobile_number,
                input.userAmount,
                input.user_data[0].district,
                input.status,
                // input.crop_type,
                // input.variety,
                // input.availability_days,
                // `${input.typeOfCrop}`,
                new Date(input.created_at).toString(),
                input._id.$oid
            )
        )
        setRows(data)
        //  requestSearch(props.cropSearch)
    }, [inputs])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name)
            setSelected(newSelecteds)
            return
        }
        setSelected([])
    }

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            )
        }

        setSelected(newSelected)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleChangeDense = (event) => {
        setDense(event.target.checked)
    }
    const handlieClickValue = (id) => {
        localStorage.setItem('BookingTransportid', id)
    }
    const isSelected = (name) => selected.indexOf(name) !== -1
    const requestSearch = (searchedVal) => {
        console.log('rows', rows)
        const filteredRows = props.inputs.filter((row) => {
            console.log(row)
            return row.user_data[0].district
                .toLowerCase()
                .includes(searchedVal.toLowerCase())
        })
        var data = filteredRows.map((input, index) =>
            createData(
                index + 1,
                input.bookingId,
                input.user_data[0].id,
                input.user_data[0].mobile_number,
                input.payment.indicate_order_value,
                input.user_data[0].district,
                input.status,
                // input.crop_type,
                // input.variety,
                // input.availability_days,
                // `${input.typeOfCrop}`,
                new Date(input.created_at).toString(),
                input._id.$oid
            )
        )
        setRows(data)
        console.log('filteredRows : ', filteredRows)
        // setRows(filteredRows);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

    return (
        <>
            <div className="col-md-2">
                <div class="form-group">
                    <input
                        onChange={(e) => requestSearch(e.target.value)}
                        type="text"
                        class="form-control"
                        placeholder="District"
                    />
                </div>
            </div>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Box
                sx={{
                    width: '100%',
                    marginBottom: '20px',
                    paddingRight: '0px',
                    paddingLeft: '0px',
                }}
            >
                <Paper
                    sx={{ width: '100%', mb: 2, backgroundColor: '#f3f3f3' }}
                >
                    {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody style={{ marginBottom: '20px' }}>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(
                                            row.name
                                        )
                                        const labelId = `enhanced-table-checkbox-${index}`

                                        return (
                                            <>
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    aria-checked={
                                                        isItemSelected
                                                    }
                                                    tabIndex={-1}
                                                    key={row.name}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox"></TableCell>
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        {/* # {row.id} */}
                                                        <a
                                                            href={`/AgriTransportBookingDetail/${row.bookingId}`}
                                                            onClick={() =>
                                                                handlieClickValue(
                                                                    row.bookingId
                                                                )
                                                            }
                                                        >
                                                            <u
                                                                style={{
                                                                    color: 'green',
                                                                }}
                                                            >
                                                                {row.bookingId}
                                                            </u>
                                                        </a>
                                                    </TableCell>
                                                    {/* <TableCell align="right">{row.orderId}</TableCell> */}
                                                    {/* <TableCell align="right">{row.bookingId}</TableCell> */}
                                                    <TableCell align="right">
                                                        {row.firm}
                                                    </TableCell>
                                                    {/* <TableCell align="right">{row.category}</TableCell> */}
                                                    <TableCell align="right">
                                                        {row.modeOfBooking}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.amount}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {row.district}
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        {row.status}
                                                    </TableCell>
                                                    {/* <TableCell align="right">{row.crops}</TableCell> */}
                                                    <TableCell align="center">
                                                        {new Date(row.created)
                                                            .toDateString()
                                                            .slice(4, 15)}
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height:
                                                (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
        </>
    )
}
