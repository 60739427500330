import React, { useState, useEffect } from 'react'
import { CssTextField } from '../../../Styles'
import MachineCategories from './ProductMCategories'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import MachineInventory from './MachineInventory'
import Menu from '@mui/material/Menu'
import Fade from '@mui/material/Fade'
import RichTextEditor from 'react-rte';

import { storage } from '../../../../firebase/index'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import { API } from '../../../../API/index'
import {
    thumbsContainerState,
    thumb,
    img,
    thumbInner,
    baseStyle,
    driverDP,
    sectionDiv,
    uploadBtnSm,
    panImg,
} from '../../../Styles'

import Button from '@mui/material/Button'
import  Selected  from 'react-select'

import { styled } from '@mui/material/styles'
import Swal from 'sweetalert2'
import axios from 'axios'

import MachineInventoryTable from './MachineInventoryTable'
const inputClear = {
    catalogue: 'machine',
    categoryName: '',
}
const Subclear = {
    catalogue: 'machine',
    CategoryName: '',
    subCategoryName: '',
}
const BrandClear = {
    catalogue: 'machine',
    categoryName: '',
    subCategoryName: '',
    brandName: '',
    imageUrl: '',
}

const ProductClear = {
    catalogue: 'machine',
    categoryName: '',
    subCategoryName: '',
    brandName: '',
    productImage: '',
    productName: '',
}
const VariantClear = {
    title: 'ProductCatelog',
    catelogName: 'MachineCatelog',
    categoryName: '',
    subCategoryName: '',
    brandName: '',
    productName: '',
    variantName: '',
}

const Input = styled('input')({
    display: 'none',
    marginBottom: '16px',
    marginTop: '16px',
})
function MachineCatelog() {
    const catalogue = 'machine'
    const [showCategory, setShowCategory] = useState(false)
    const [createProduct, setCreateProduct] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [Categorylist, setCategorylist] = useState([])
    const [subCategorylist, setsubCategorylist] = useState([])
    const [brandlist, setbrandlist] = useState([])
    const [categoryName, setcategoryName] = useState('')
    const [subcategoryName, setsubcategoryName] = useState('')
    const [Productlist, setProductlist] = useState([])
    const [description, setDescription] = useState('')

    const [BrandImg, setBrandImg] = useState(null)
    const [driverImg, setDriverImg] = useState(null)

    const open = Boolean(anchorEl)
    const [editorState, setEditorState] = useState(
        RichTextEditor.createEmptyValue()
      );
    const [ProductInput, setProductInput] = useState([])

    useEffect(() => {
        // getState();
        getCategoryList()
        getProductInput()
        getAllProducts()
    }, [])

    const getAllProducts = async () => {
        var farmerid = localStorage.getItem('Traderid')
        var token = localStorage.getItem("token");
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        const db = {
          "catalogue": "machine",
        //   "mobile_number": "5555555555"
        }
        // const { data } = await API.get(`/getProductCatelog?&title=ProductCatelog&catelogName=InputSupplierCatelog&type=2`);
        axios.post("https://prod-api.krishione.com/farmer-function/get-all-products", db, config)
          .then(res => {
            console.log("test", res.data.data);
            setProductInput(res?.data?.product);
          })
      }
    const getProductInput = async () => {}
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const [InputMachineProduct, setInputMachineProduct] = useState({
        catalogue: 'machine',
        categoryName: '',
    })
    const [SubMachineProduct, setSubMachineProduct] = useState({
        catalogue: 'machine',
        categoryName: '',
        subCategoryName: '',
    })
    const [BrandMachineProduct, setBrandMachineProduct] = useState({
        catalogue: 'machine',
        categoryName: '',
        subCategoryName: '',
        brandName: '',
        imageUrl: '',
    })
    const [CommissionMachineProduct, setCommissionMachineProduct] = useState({
        catalogue: 'machine',
        categoryName: '',
        subCategoryName: '',
        brandName: '',
        brandImage:"",
        productName: '',
        productImage: '',hsn:"",sku:""
    })
    const [MachineVariantProduct, setMachineVariantProduct] = useState({
        catalogue: 'machine',
        categoryName: '',
        subCategoryName: '',
        brandName: '',
        productName: '',
        variantName: '',
    })
    const handleChange = (value) => {
        setEditorState(value);
        const htmlString = editorState.toString('html');
    // You can now save or use the 'htmlString' as needed
    console.log(htmlString);
      };
    const handleInputMachineProduct = async () => {
        console.log('InputMachineProduct', InputMachineProduct)

        try {
            // const { data } = await API.post('/createProductCategory ', InputMachineProduct);
            // if(data?.success){
            //  Swal.fire({
            //       icon: 'success',
            //       iconColor: '#28a745',
            //       title: data?.message,
            //       showConfirmButton: false,
            //       timer: 2000
            //     });
            //  console.log(data);
            //  getCategoryList()
            //  setInputMachineProduct(inputClear)
            // } else {
            //    setInputMachineProduct(inputClear)
            //  Swal.fire({
            //    icon: 'warning',
            //    iconColor: '#dc3545',
            //    title: data?.message,
            //    showConfirmButton: false,
            //    timer: 2000
            //    });
            // }
            var token = localStorage.getItem('token')
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            }
            await axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/category`,
                    InputMachineProduct,
                    config
                )
                .then((data) => {
                    console.log('category in input S: ', data)
                    Swal.fire({
                        icon: 'success',
                        iconColor: '#28a745',
                        title: data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                    console.log(data)
                    getCategoryList()
                    setInputMachineProduct(inputClear)
                })
                .catch((data) => {
                    console.log(data)
                    setInputMachineProduct(inputClear)
                    Swal.fire({
                        icon: 'warning',
                        iconColor: '#dc3545',
                        title: data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                })
        } catch (err) {
            setInputMachineProduct(inputClear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handleSubMachineProduct = async () => {
        console.log('SubMachineProduct ', SubMachineProduct)

        try {
            // const { data } = await API.post('/createProductSubCategory ',SubMachineProduct );
            // if(data?.success){
            //  Swal.fire({
            //       icon: 'success',
            //       iconColor: '#28a745',
            //       title: data?.message,
            //       showConfirmButton: false,
            //       timer: 2000
            //     });
            //  console.log(data);
            // setBrandImg(null)
            //  setSubMachineProduct (Subclear)
            // } else {
            //    setSubMachineProduct (Subclear)
            //  Swal.fire({
            //    icon: 'warning',
            //    iconColor: '#dc3545',
            //    title: data?.message,
            //    showConfirmButton: false,
            //    timer: 2000
            //    });
            // }
            var token = localStorage.getItem('token')
            var config = {
                headers: { Authorization: `Bearer ${token}` },
            }
            await axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/subcategory`,
                    SubMachineProduct,
                    config
                )
                .then((data) => {
                    console.log('sub cat:', data)
                    Swal.fire({
                        icon: 'success',
                        iconColor: '#28a745',
                        title: data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                    console.log(data)
                    getCategoryList()
                    setSubMachineProduct(Subclear)
                })
                .catch((data) => {
                    console.log(data)
                    setSubMachineProduct(Subclear)
                    Swal.fire({
                        icon: 'warning',
                        iconColor: '#dc3545',
                        title: data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                })
        } catch (err) {
            setSubMachineProduct(Subclear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handleBrandMachineProduct = async () => {
        console.log('BrandMachineProduct', BrandMachineProduct)

        try {
            // const { data } = await API.post('/createProductBrand',BrandMachineProduct);
            // if(data?.success){
            //  Swal.fire({
            //       icon: 'success',
            //       iconColor: '#28a745',
            //       title: data?.message,
            //       showConfirmButton: false,
            //       timer: 2000
            //     });
            //  console.log(data);

            //  setBrandMachineProduct(BrandClear)
            // } else {
            //    setBrandMachineProduct(BrandClear)
            //  Swal.fire({
            //    icon: 'warning',
            //    iconColor: '#dc3545',
            //    title: data?.message,
            //    showConfirmButton: false,
            //    timer: 2000
            //    });
            // }
            var token = localStorage.getItem('token')
            var config = {
                headers: { Authorization: `Bearer ${token}` },
            }
            await axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/brand`,
                    BrandMachineProduct,
                    config
                )
                .then((data) => {
                    console.log('brand in input', data)
                    Swal.fire({
                        icon: 'success',
                        iconColor: '#28a745',
                        title: data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                    setBrandMachineProduct(BrandClear)
                    setBrandImg(null)
                })
                .catch((data) => {
                    Swal.fire({
                        icon: 'warning',
                        iconColor: '#dc3545',
                        title: data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                })
        } catch (err) {
            setBrandMachineProduct(BrandClear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handleCommissionMachineProduct = async () => {
        console.log('CommissionMachineProduct', CommissionMachineProduct)

        try {
            // const { data } = await API.post('/createProductCatelogProduct ', CommissionMachineProduct);
            // if(data?.success){
            //  Swal.fire({
            //       icon: 'success',
            //       iconColor: '#28a745',
            //       title: data?.message,
            //       showConfirmButton: false,
            //       timer: 2000
            //     });
            //  console.log(data);

            //  setCommissionMachineProduct(ProductClear)
            // } else {
            //    setCommissionMachineProduct(ProductClear)
            //  Swal.fire({
            //    icon: 'warning',
            //    iconColor: '#dc3545',
            //    title: data?.message,
            //    showConfirmButton: false,
            //    timer: 2000
            //    });
            // }
            var token = localStorage.getItem('token')
            var config = {
                headers: { Authorization: `Bearer ${token}` },
            }
            await axios
                .post(
                    `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/add-product`,
                    {...CommissionMachineProduct,description: editorState.toString('html')},
                    config
                )
                .then((data) => {
                    console.log('brand in input', data)
                    Swal.fire({
                        icon: 'success',
                        iconColor: '#28a745',
                        title: data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                    setCommissionMachineProduct(ProductClear)
                    setBrandImg(null)
                })
                .catch((data) => {
                    Swal.fire({
                        icon: 'warning',
                        iconColor: '#dc3545',
                        title: data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    })
                })
        } catch (err) {
            setCommissionMachineProduct(ProductClear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }
    const handleMachineVariantProduct = async () => {
        console.log('MachineVariantProduct', MachineVariantProduct)

        try {
            const { data } = await API.post(
                '/createProductCatelogQuantity',
                MachineVariantProduct
            )
            if (data?.success) {
                Swal.fire({
                    icon: 'success',
                    iconColor: '#28a745',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
                console.log(data)

                setMachineVariantProduct(VariantClear)
            } else {
                setMachineVariantProduct(VariantClear)
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#dc3545',
                    title: data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
        } catch (err) {
            setMachineVariantProduct(VariantClear)
            Swal.fire({
                icon: 'warning',
                iconColor: '#dc3545',
                title: err.message,
                showConfirmButton: false,
                timer: 2000,
            })
        }
    }

    const getCategoryList = async () => {
        // const { data } = await API.get(`/getProductCategory?title=ProductCatelog&catelogName=MachineCatelog&type=0`);
        // // console.log("hkjjhj",data)
        // setCategorylist(data?.result);
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/category/${catalogue}`,
                config
            )
            .then((res) => {
                console.log('d :', res.data.category)
                setCategorylist(res.data.category)
            })
    }
    const handleCategoryChange = (e) => {
        // setSubLivestockProduct({...SubLivestockProduct,categoryName:e.target.value})
        setBrandMachineProduct({
            ...BrandMachineProduct,
            categoryName: e.target.value,
        })
        getSubCategoryList(e.target.value)
        setcategoryName(e.target.value)
    }
    const getSubCategoryList = async (val) => {
        // const { data } = await API.get(`/getProductSubCategory?type=0&title=ProductCatelog&catelogName=MachineCatelog&categoryName=`+val);
        // console.log("hkjjhj",data)
        // setsubCategorylist(data?.result);
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        const subCat = {
            catalogue: catalogue,
            categoryName: val,
        }
        await axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-subcategory`,
                subCat,
                config
            )
            .then((res) => {
                console.log(
                    'get sub cat in input catalogue',
                    res.data.subcategory
                )
                setsubCategorylist(res.data.subcategory)
            })
    }
    const handleCategoryChangeProduct = (e) => {
        // setCommissionLivestockProduct({...CommissionLivestockProduct, categoryName:e.target.value})
        setCommissionMachineProduct({
            ...CommissionMachineProduct,
            categoryName: e.target.value,
        })
        getSubCategoryList(e.target.value)
        setcategoryName(e.target.value)
    }

    const handlechangeproductBrand = (e) => {
        console.log(e.target.value, 'e')
        setCommissionMachineProduct({
            ...CommissionMachineProduct,
            subCategoryName: e.target.value,
        })
        getBrandList(e.target.value)
    }

    const getBrandList = async (val) => {
        // const { data } = await API.get(`/getProductBrand?type=0&title=ProductCatelog&catelogName=MachineCatelog&categoryName=`+categoryName+'&subCategoryName='+val);
        // console.log("hkjjhj",data)
        // setbrandlist(data?.result);
        let forGetBrands = {
            categoryName: CommissionMachineProduct.categoryName,
            subCategoryName: val,
            catalogue: catalogue,
        }
        var token = localStorage.getItem('token')
        var config = {
            headers: { Authorization: `Bearer ${token}` },
        }
        await axios
            .post(
                `${process.env.REACT_APP_API_ENDPOINT}/farmer-function/get-brand`,
                forGetBrands,
                config
            )
            .then((data) => {
                console.log('brand in input', data)
                setbrandlist(data.data.brand)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleCategoryChangeVarient = (e) => {
        // setLivestockVariantProduct({...LivestockVariantProduct,categoryName:e.target.value})
        setMachineVariantProduct({
            ...MachineVariantProduct,
            categoryName: e.target.value,
        })
        getSubCategoryList(e.target.value)
        setcategoryName(e.target.value)
    }

    const handlechangeproductVarientBrand = (e) => {
        console.log(e.target.value, 'e')
        // setLivestockVariantProduct({...LivestockVariantProduct,subCategoryName:e.target.value})
        setMachineVariantProduct({
            ...MachineVariantProduct,
            subCategoryName: e.target.value,
        })
        getBrandListVarient(e.target.value)
        setsubcategoryName(e.target.value)
    }
    const getBrandListVarient = async (val) => {
        const { data } = await API.get(
            `/getProductBrand?type=0&title=ProductCatelog&catelogName=MachineCatelog&categoryName=` +
                categoryName +
                '&subCategoryName=' +
                val
        )
        console.log('hkjjhj', data)
        setbrandlist(data?.result)
    }

    const handlechangeproductVarientProduct = (e) => {
        // setLivestockVariantProduct({...LivestockVariantProduct,brandName:e.target.value})
        setMachineVariantProduct({
            ...MachineVariantProduct,
            brandName: e.target.value,
        })
        getProductListVarient(e.target.value)
    }
    const typeoptiob =
    brandlist &&
    brandlist.map((item, ind) => ({
        value: item.brandName,
        label: item.brandName,
        image: item.imageUrl,
        
  
    }))
    const getProductListVarient = async (val) => {
        const { data } = await API.get(
            `/getProductCatelogProduct?type=3&title=ProductCatelog&catelogName=MachineCatelog&categoryName=` +
                categoryName +
                '&subCategoryName=' +
                subcategoryName +
                '&brandName=' +
                val
        )
        console.log('hkjjhj', data)
        setProductlist(data?.result)
    }
    const handleCloseClear = () => {
        handleClose()
        setBrandImg(null)
    }
    const handleView = () => {
        setShowCategory(true)
        getProductInput()
    }
    const handleUploadIP = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setBrandImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        // SubMachineProduct,setSubMachineProduct
                        console.log('brandImage', url)
                        setCommissionMachineProduct({
                            ...CommissionMachineProduct,
                            productImage: url,
                        })
                    })
                }
            )
        }
    }

    const handleUploadDP = (e) => {
        console.log('e.target.files[0]', e.target.files[0])
        setBrandImg(URL.createObjectURL(e.target.files[0]))
        const doc = e.target.files[0]
        if (e.target.files[0]) {
            const storageRef = ref(storage, `driverPhotos/${doc.name}`)
            const uploadTask = uploadBytesResumable(storageRef, doc)
            uploadTask.on(
                'state_changed',
                (snapshot) => {},
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(storageRef).then((url) => {
                        setBrandMachineProduct({
                            ...BrandMachineProduct,
                            imageUrl: url,
                        })
                    })
                }
            )
        }
    }

    return (
        <>
            <div class="row align-items-center mb-3">
                <div class="col">
                    {createProduct ? (
                        <h3 class="page-title">Machine Inventory</h3>
                    ) : (
                        <h3 class="page-title">Machine Catalogue</h3>
                    )}
                </div>
                {createProduct ? (
                    <div class="col-auto">
                        <button
                            class="btn btn-primary filter-btn"
                            onClick={(e) => setCreateProduct(false)}
                        >
                            <i class="fas fa-left"></i> <span> Back</span>
                        </button>
                    </div>
                ) : (
                    <>
                        {!showCategory ? (
                            <>
                                <div className="col-auto">
                                    <button
                                        class="btn btn-primary filter-btn"
                                        id="fade-button"
                                        aria-controls="fade-menu"
                                        aria-haspopup="true"
                                        aria-expanded={
                                            open ? 'true' : undefined
                                        }
                                        onClick={handleClick}
                                    >
                                        <i class="fas fa-plus"></i>{' '}
                                        <span> Create </span>
                                    </button>
                                    <Menu
                                        id="fade-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'fade-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        TransitionComponent={Fade}
                                    >
                                        <MenuItem
                                            onClick={handleCloseClear}
                                            id="openCategory"
                                        >
                                            Category
                                        </MenuItem>
                                        {/* <MenuItem onClick={handleClose} id="openSubCategory">SubCategory</MenuItem> */}
                                        <MenuItem
                                            onClick={handleCloseClear}
                                            id="openSubCategory"
                                        >
                                            Machine Model
                                        </MenuItem>

                                        <MenuItem
                                            onClick={handleCloseClear}
                                            id="openBrand"
                                        >
                                            Brand
                                        </MenuItem>
                                        <MenuItem
                                            onClick={handleCloseClear}
                                            id="openProduct"
                                        >
                                            Type of Machine
                                        </MenuItem>
                                        {/* <MenuItem onClick={handleClose} id="openVariant">Variant</MenuItem> */}
                                        <MenuItem
                                            onClick={(e) => {
                                                setCreateProduct(true)
                                                handleCloseClear()
                                            }}
                                        >
                                            Inventory
                                        </MenuItem>
                                    </Menu>
                                </div>
                                <div class="col-auto">
                                    <button
                                        class="btn btn-primary filter-btn"
                                        onClick={() => handleView()}
                                        // onClick={e=>setShowCategory(true)}
                                    >
                                        <i class="fas fa-eye"></i>{' '}
                                        <span> View Category</span>
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div class="col-auto">
                                <button
                                    class="btn btn-primary filter-btn"
                                    onClick={(e) => setShowCategory(false)}
                                >
                                    <i class="fas fa-left"></i>{' '}
                                    <span> Back</span>
                                </button>
                            </div>
                        )}
                    </>
                )}
            </div>

            {createProduct ? (
                <MachineInventory />
            ) : (
                <>
                    {showCategory === false ? (
                        <>
                            {/* Add Category */}
                            <div id="category" class="card filter-card ">
                                <form
                                    class="card-body pb-0"
                                    onSubmit={(e) => e.preventDefault()}
                                >
                                    <div class="row">
                                        <h5 class="card-title">
                                            Create Category
                                        </h5>
                                        <div class="col-md-4 select2-container">
                                            <div
                                                class="form-group"
                                                data-select2-id="11"
                                            >
                                                <CssTextField
                                                    required
                                                    fullWidth
                                                    id="InpCategory"
                                                    label="Category"
                                                    variant="standard"
                                                    name="InpCategory"
                                                    value={
                                                        InputMachineProduct.categoryName
                                                    }
                                                    onChange={(e) =>
                                                        setInputMachineProduct({
                                                            ...InputMachineProduct,
                                                            categoryName:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div class="col-sm-2 col-md-2">
                                            <div class="form-group">
                                                <button
                                                    type="submit"
                                                    class="btn btn-primary mt-26"
                                                    onClick={() =>
                                                        handleInputMachineProduct()
                                                    }
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/* Add SubCategory */}
                            <div id="subCategory" class="card filter-card">
                                <form
                                    class="card-body pb-0"
                                    onSubmit={(e) => e.preventDefault()}
                                >
                                    <div class="row">
                                        <h5 class="card-title">
                                            Create Machine Model
                                        </h5>
                                        <div class="col-md-4">
                                            <FormControl
                                                variant="standard"
                                                fullWidth
                                                className="mt-3"
                                            >
                                                <InputLabel
                                                    id="demo-simple-select-standard-label"
                                                    style={{ color: 'black' }}
                                                >
                                                    Category
                                                </InputLabel>
                                                <Select
                                                    required
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    //  value={age}
                                                    //  onChange={handleChange}
                                                    label="Category"
                                                    color="success"
                                                    value={
                                                        SubMachineProduct.categoryName
                                                    }
                                                    onChange={(e) =>
                                                        setSubMachineProduct({
                                                            ...SubMachineProduct,
                                                            categoryName:
                                                                e.target.value,
                                                        })
                                                    }
                                                >
                                                    {Categorylist &&
                                                        Categorylist.map(
                                                            (val, ind) => {
                                                                // {console.log("Categorylist",val)}
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            val.categoryName
                                                                        }
                                                                    >
                                                                        {
                                                                            val.categoryName
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    {/* <MenuItem value="">
                 <em>None</em>
               </MenuItem>
               <MenuItem value={"test"}>test</MenuItem>
               <MenuItem value={"Krishivan Input"}>Krishivan Input</MenuItem> */}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div class="col-md-4">
                                            <CssTextField
                                                required
                                                fullWidth
                                                id="InpSubCategory"
                                                label="Machine Model"
                                                variant="standard"
                                                name="InpSubCategory"
                                                value={
                                                    SubMachineProduct.subCategoryName
                                                }
                                                onChange={(e) =>
                                                    setSubMachineProduct({
                                                        ...SubMachineProduct,
                                                        subCategoryName:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                        {/* <div class="col-md-4 select2-container">
            <div class="form-group" data-select2-id="11">
            <CssTextField 
              required
              fullWidth 
              id="InpSubCategory"
              label="TypeOfMachine" 
              variant="standard"
              name="InpSubCategory" value={SubMachineProduct.subCategoryName}
              onChange={(e)=>setSubMachineProduct({...SubMachineProduct,subCategoryName:e.target.value})}
            /> 
            </div>			
               </div> */}
                                        <div class="col-sm-2 col-md-2">
                                            <div class="form-group">
                                                <button
                                                    type="submit"
                                                    class="btn btn-primary mt-26"
                                                    onClick={() =>
                                                        handleSubMachineProduct()
                                                    }
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/* Create Brand */}
                            <div id="brand" class="card filter-card">
                                <form
                                    class="card-body pb-2"
                                    onSubmit={(e) => e.preventDefault()}
                                >
                                    <div className="mt-3">
                                        <h5 class="card-title">Create Brand</h5>
                                        <div class="row mb-4">
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Category
                                                    </InputLabel>
                                                    <Select
                                                        required
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="Firm"
                                                        color="success"
                                                        value={
                                                            BrandMachineProduct.categoryName
                                                        }
                                                        onChange={(e) =>
                                                            handleCategoryChange(
                                                                e
                                                            )
                                                        }
                                                        // onChange={(e)=>setBrandMachineProduct({...BrandMachineProduct,categoryName:e.target.value})}
                                                    >
                                                        {Categorylist &&
                                                            Categorylist.map(
                                                                (val, ind) => {
                                                                    // {console.log("Categorylist",val)}
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                val.categoryName
                                                                            }
                                                                        >
                                                                            {
                                                                                val.categoryName
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                        {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"General"}>General</MenuItem>
                    <MenuItem value={"OBC"}>OBC</MenuItem>
                    <MenuItem value={"SC"}>SC</MenuItem>
                    <MenuItem value={"ST"}>ST</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem> */}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Machine Model
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="Booking Mode"
                                                        color="success"
                                                        value={
                                                            BrandMachineProduct.subCategoryName
                                                        }
                                                        onChange={(e) =>
                                                            setBrandMachineProduct(
                                                                {
                                                                    ...BrandMachineProduct,
                                                                    subCategoryName:
                                                                        e.target
                                                                            .value,
                                                                }
                                                            )
                                                        }
                                                    >
                                                        {/* <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"General"}>General</MenuItem>
                    <MenuItem value={"OBC"}>OBC</MenuItem>
                    <MenuItem value={"SC"}>SC</MenuItem>
                    <MenuItem value={"ST"}>ST</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem> */}
                                                        {subCategorylist &&
                                                            subCategorylist.map(
                                                                (val, ind) => {
                                                                    //  {console.log("Categorylist",val)}
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                val.subCategoryName
                                                                            }
                                                                        >
                                                                            {
                                                                                val.subCategoryName
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            {/* <div class="col-md-3">
                 <CssTextField 
                    required
                    fullWidth 
                    id="brandName"
                    label="Brand Name" 
                    variant="standard"
                    name="brandName" value={BrandMachineProduct.brandName}
                    onChange={(e)=>setBrandMachineProduct({...BrandMachineProduct, brandName:e.target.value})}
                  /> 
                 </div> */}
                                            <div className="row">
                                                <div className="col-md-3 mt-3">
                                                    <div
                                                        class="form-group row widget-3"
                                                        style={sectionDiv}
                                                    >
                                                        <section
                                                            className="container"
                                                            style={driverDP}
                                                        >
                                                            {BrandImg ===
                                                            null ? (
                                                                <p align="center">
                                                                    Upload Image
                                                                </p>
                                                            ) : (
                                                                <aside
                                                                    style={
                                                                        thumbsContainerState
                                                                    }
                                                                >
                                                                    <div
                                                                        style={
                                                                            thumb
                                                                        }
                                                                    >
                                                                        <div
                                                                            style={
                                                                                thumbInner
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    BrandImg
                                                                                }
                                                                                style={
                                                                                    img
                                                                                }
                                                                                alt="driverPhoto"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </aside>
                                                            )}
                                                        </section>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mt-3">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                            marginTop: '90px',
                                                        }}
                                                    >
                                                        {/* Product */}
                                                    </InputLabel>
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="brandName"
                                                        label="Brand"
                                                        variant="standard"
                                                        name="brandName"
                                                        value={
                                                            BrandMachineProduct.brandName
                                                        }
                                                        onChange={(e) =>
                                                            setBrandMachineProduct(
                                                                {
                                                                    ...BrandMachineProduct,
                                                                    brandName:
                                                                        e.target
                                                                            .value,
                                                                }
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-12 mt-3 d-flex">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                            marginRight: '10px',
                                                        }}
                                                    >
                                                        Upload Brand Image:
                                                    </InputLabel>
                                                    <label htmlFor="contained-button-file">
                                                        <Button
                                                            variant="contained"
                                                            component="span"
                                                            color="success"
                                                            size="small"
                                                            style={uploadBtnSm}
                                                        >
                                                            <Input
                                                                accept="image/"
                                                                id="contained-button-file"
                                                                multiple="false"
                                                                type="file"
                                                                onChange={
                                                                    handleUploadDP
                                                                }
                                                            />
                                                            Upload
                                                        </Button>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row justify-content-end mb-3">
                                            <div className="col-md-1">
                                                <button
                                                    type="submit"
                                                    className="btn btn-danger"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className="col-md-1">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    onClick={() =>
                                                        handleBrandMachineProduct()
                                                    }
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/* Create Product */}
                            <div id="product" class="card filter-card">
                                <form
                                    class="card-body pb-2"
                                    onSubmit={(e) => e.preventDefault()}
                                >
                                    <div className="mt-3">
                                        <h5 class="card-title">
                                            Create Type of Machine
                                        </h5>
                                        <div class="row mb-4">
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Category
                                                    </InputLabel>
                                                    <Select
                                                        required
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="Firm"
                                                        color="success"
                                                        value={
                                                            CommissionMachineProduct.categoryName
                                                        }
                                                        onChange={(e) =>
                                                            handleCategoryChangeProduct(
                                                                e
                                                            )
                                                        }
                                                        // onChange={(e)=>setCommissionMachineProduct({...CommissionMachineProduct, categoryName:e.target.value})}
                                                    >
                                                        {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"General"}>General</MenuItem>
                    <MenuItem value={"OBC"}>OBC</MenuItem>
                    <MenuItem value={"SC"}>SC</MenuItem>
                    <MenuItem value={"ST"}>ST</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem> */}
                                                        {Categorylist &&
                                                            Categorylist.map(
                                                                (val, ind) => {
                                                                    // {console.log("Categorylist",val)}
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                val.categoryName
                                                                            }
                                                                        >
                                                                            {
                                                                                val.categoryName
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Machine Model
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="Booking Mode"
                                                        color="success"
                                                        value={
                                                            CommissionMachineProduct.subCategoryName
                                                        }
                                                        onChange={(e) =>
                                                            handlechangeproductBrand(
                                                                e
                                                            )
                                                        }
                                                        // onChange={(e)=>setCommissionMachineProduct({...CommissionMachineProduct,subCategoryName:e.target.value})}
                                                    >
                                                        {/* <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"General"}>General</MenuItem>
                    <MenuItem value={"OBC"}>OBC</MenuItem>
                    <MenuItem value={"SC"}>SC</MenuItem>
                    <MenuItem value={"ST"}>ST</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem> */}
                                                        {subCategorylist &&
                                                            subCategorylist.map(
                                                                (val, ind) => {
                                                                    //  {console.log("Categorylist",val)}
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                val.subCategoryName
                                                                            }
                                                                        >
                                                                            {
                                                                                val.subCategoryName
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Brand Name
                                                    </InputLabel>
                                                  
                                                    <Selected
                                        style={{
                                            width: '270px',
                                            height: '40px',
                                        }}
                                        placeholder={'Select Brand'}
                                        // value={inputBooking.modeOfBooking}
                                        onChange={(e) => setCommissionMachineProduct({ ...CommissionMachineProduct, brandName: e.value,brandImage:e.image })}
                                        // onChange={(e) =>
                                        //     setSellCrop({
                                        //         ...sellCrop,
                                        //         cropName: e.value,
                                        //         cropTypeImage: e.image,
                                        //         crop_type: e.type,
                                        //         productId:e.productId

                                        //     })
                                        // }
                                        options={typeoptiob}
                                    />
                                                </FormControl>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 mt-3">
                                                    <div
                                                        class="form-group row widget-3"
                                                        style={sectionDiv}
                                                    >
                                                        <section
                                                            className="container"
                                                            style={driverDP}
                                                        >
                                                            {BrandImg ===
                                                            null ? (
                                                                <p align="center">
                                                                    Upload Image
                                                                </p>
                                                            ) : (
                                                                <aside
                                                                    style={
                                                                        thumbsContainerState
                                                                    }
                                                                >
                                                                    <div
                                                                        style={
                                                                            thumb
                                                                        }
                                                                    >
                                                                        <div
                                                                            style={
                                                                                thumbInner
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    BrandImg
                                                                                }
                                                                                style={
                                                                                    img
                                                                                }
                                                                                alt="driverPhoto"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </aside>
                                                            )}
                                                        </section>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mt-3">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                            marginTop: '90px',
                                                        }}
                                                    >
                                                        {/* Product */}
                                                    </InputLabel>
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="productName"
                                                        label="Type of Machine"
                                                        variant="standard"
                                                        name="productName"
                                                        value={
                                                            CommissionMachineProduct.productName
                                                        }
                                                        onChange={(e) =>
                                                            setCommissionMachineProduct(
                                                                {
                                                                    ...CommissionMachineProduct,
                                                                    productName:
                                                                        e.target
                                                                            .value,
                                                                }
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-12 mt-3 d-flex">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                            marginRight: '10px',
                                                        }}
                                                    >
                                                        Upload Type Image:
                                                    </InputLabel>
                                                    <label htmlFor="contained-button-files">
                                                        <Button
                                                            variant="contained"
                                                            component="span"
                                                            color="success"
                                                            size="small"
                                                            style={uploadBtnSm}
                                                        >
                                                            <Input
                                                                accept="image/"
                                                                id="contained-button-files"
                                                                multiple="false"
                                                                type="file"
                                                                onChange={(e) =>
                                                                    handleUploadIP(
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                            Upload
                                                        </Button>
                                                    </label>
                                                </div>
                                                <div className="col mt-3">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Product Description
                                                    </InputLabel>
                                                    <RichTextEditor
        value={editorState}
        onChange={handleChange}
      />
                                                </div>
                                                <div className="col-md-3 mt-3 d-flex">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                            marginTop: '90px',
                                                        }}
                                                    >
                                                        {/* Product */}
                                                    </InputLabel>
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="sku"
                                                        label="SKU"
                                                        variant="standard"
                                                        name="sku"
                                                        value={
                                                            CommissionMachineProduct.sku
                                                        }
                                                        onChange={(e) =>
                                                            setCommissionMachineProduct(
                                                                {
                                                                    ...CommissionMachineProduct,
                                                                    sku:
                                                                        e.target
                                                                            .value,
                                                                }
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-3 mt-3 d-flex">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                            marginTop: '90px',
                                                        }}
                                                    >
                                                        {/* Product */}
                                                    </InputLabel>
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="hsn"
                                                        label="HSN Code"
                                                        variant="standard"
                                                        name="hsn"
                                                        value={
                                                            CommissionMachineProduct.hsn
                                                        }
                                                        onChange={(e) =>
                                                            setCommissionMachineProduct(
                                                                {
                                                                    ...CommissionMachineProduct,
                                                                    hsn:
                                                                        e.target
                                                                            .value,
                                                                }
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row justify-content-end mb-3">
                                            <div className="col-md-1">
                                                <button
                                                    type="submit"
                                                    className="btn btn-danger"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className="col-md-1">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    onClick={() =>
                                                        handleCommissionMachineProduct()
                                                    }
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/* Create Variant */}
                            <div id="variant" class="card filter-card">
                                <form
                                    class="card-body pb-2"
                                    onSubmit={(e) => e.preventDefault()}
                                >
                                    <div className="mt-3">
                                        <h5 class="card-title">
                                            Create Variant
                                        </h5>
                                        <div class="row mb-4">
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Category
                                                    </InputLabel>
                                                    <Select
                                                        required
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="Firm"
                                                        color="success"
                                                        value={
                                                            MachineVariantProduct.categoryName
                                                        }
                                                        onChange={(e) =>
                                                            handleCategoryChangeVarient(
                                                                e
                                                            )
                                                        }
                                                        // onChange={(e)=>setMachineVariantProduct({...MachineVariantProduct,categoryName:e.target.value})}
                                                    >
                                                        {/* <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"General"}>General</MenuItem>
                    <MenuItem value={"OBC"}>OBC</MenuItem>
                    <MenuItem value={"SC"}>SC</MenuItem>
                    <MenuItem value={"ST"}>ST</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem> */}
                                                        {Categorylist &&
                                                            Categorylist.map(
                                                                (val, ind) => {
                                                                    // {console.log("Categorylist",val)}
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                val
                                                                            }
                                                                        >
                                                                            {
                                                                                val
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        TypeOfMachine
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="Booking Mode"
                                                        color="success"
                                                        value={
                                                            MachineVariantProduct.subCategoryName
                                                        }
                                                        onChange={(e) =>
                                                            handlechangeproductVarientBrand(
                                                                e
                                                            )
                                                        }
                                                        // onChange={(e)=>setMachineVariantProduct({...MachineVariantProduct,subCategoryName:e.target.value})}
                                                    >
                                                        {/* <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"General"}>General</MenuItem>
                    <MenuItem value={"OBC"}>OBC</MenuItem>
                    <MenuItem value={"SC"}>SC</MenuItem>
                    <MenuItem value={"ST"}>ST</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem> */}
                                                        {subCategorylist &&
                                                            subCategorylist.map(
                                                                (val, ind) => {
                                                                    // {console.log("Categorylist",val)}
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                val
                                                                            }
                                                                        >
                                                                            {
                                                                                val
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Brand Name
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="BRAND Name"
                                                        color="success"
                                                        value={
                                                            MachineVariantProduct.brandName
                                                        }
                                                        onChange={(e) =>
                                                            handlechangeproductVarientProduct(
                                                                e
                                                            )
                                                        }
                                                        // onChange={(e)=>setMachineVariantProduct({...MachineVariantProduct,brandName:e.target.value})}
                                                    >
                                                        {/* <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"General"}>General</MenuItem>
                    <MenuItem value={"OBC"}>OBC</MenuItem>
                    <MenuItem value={"SC"}>SC</MenuItem>
                    <MenuItem value={"ST"}>ST</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem> */}
                                                        {brandlist &&
                                                            brandlist.map(
                                                                (val, ind) => {
                                                                    // {console.log("Categorylist",val)}
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                val
                                                                            }
                                                                        >
                                                                            {
                                                                                val
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div class="col-md-3">
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    className="mt-3"
                                                >
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Product Name
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        //  value={age}
                                                        //  onChange={handleChange}
                                                        label="Product Name"
                                                        color="success"
                                                        value={
                                                            MachineVariantProduct.productName
                                                        }
                                                        onChange={(e) =>
                                                            setMachineVariantProduct(
                                                                {
                                                                    ...MachineVariantProduct,
                                                                    productName:
                                                                        e.target
                                                                            .value,
                                                                }
                                                            )
                                                        }
                                                    >
                                                        {/* <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"General"}>General</MenuItem>
                    <MenuItem value={"OBC"}>OBC</MenuItem>
                    <MenuItem value={"SC"}>SC</MenuItem>
                    <MenuItem value={"ST"}>ST</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem> */}
                                                        {Productlist &&
                                                            Productlist.map(
                                                                (val, ind) => {
                                                                    // {console.log("Categorylist",val)}
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                val.productName
                                                                            }
                                                                        >
                                                                            {
                                                                                val.productName
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            {/* <div class="col-md-3">
                 <CssTextField 
                    required
                    fullWidth 
                    id="variantName"
                    label="Quantity" 
                    variant="standard"
                    name="variantName"  value={MachineVariantProduct.variantName}
                    onChange={(e)=>setMachineVariantProduct({...MachineVariantProduct, variantName:e.target.value})}
                  /> 
                 </div> */}
                                        </div>

                                        <div className="row justify-content-end mb-3">
                                            <div className="col-md-1">
                                                <button
                                                    type="submit"
                                                    className="btn btn-danger"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className="col-md-1">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    onClick={() =>
                                                        handleMachineVariantProduct()
                                                    }
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            {/* <div class="row">
    <div class="col-sm-12">
    <h3 className="mb-3">List</h3>
       <div class="card card-table">
          <div class="card-body">
             <div class="table-responsive">
                <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                   <div class="row">
                      <div class="col-sm-12 col-md-6">
                         <div class="dataTables_length" id="DataTables_Table_0_length">
                            <label>
                               Show 
                               <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" class="custom-select custom-select-sm form-control form-control-sm">
                                  <option value="10">10</option>
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                               </select>
                               entries
                            </label>
                         </div>
                      </div>
                      <div class="col-sm-12 col-md-3 position_right">
                         <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search" />
                         </div>                                                
                      </div>
                   </div>
                   <div class="row">
                      <div class="col-sm-12 col-md-5">
                         <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 1 to 10 of 12 entries</div>
                      </div>
                      <div class="col-sm-12 col-md-7">
                         <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                            <ul class="pagination">
                               <li class="paginate_button page-item previous disabled" id="DataTables_Table_0_previous"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li>
                               <li class="paginate_button page-item active"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0" class="page-link">1</a></li>
                               <li class="paginate_button page-item "><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="2" tabindex="0" class="page-link">2</a></li>
                               <li class="paginate_button page-item next" id="DataTables_Table_0_next"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="3" tabindex="0" class="page-link">Next</a></li>
                            </ul>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </div> */}
                        </>
                    ) : (
                        // <MachineCategories />
                        <div class="row">
                            <div class="col-sm-12">
                                {/* <h3 className="mb-3">Machine Products</h3> */}
                                <div class="card card-table">
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <div
                                                id="DataTables_Table_0_wrapper"
                                                class="dataTables_wrapper dt-bootstrap4 no-footer"
                                            >
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-6">
                                                        <div
                                                            class="dataTables_length"
                                                            id="DataTables_Table_0_length"
                                                        >
                                                            <label>
                                                                Show
                                                                <select
                                                                    name="DataTables_Table_0_length"
                                                                    aria-controls="DataTables_Table_0"
                                                                    class="custom-select custom-select-sm form-control form-control-sm"
                                                                >
                                                                    <option value="10">
                                                                        10
                                                                    </option>
                                                                    <option value="25">
                                                                        25
                                                                    </option>
                                                                    <option value="50">
                                                                        50
                                                                    </option>
                                                                    <option value="100">
                                                                        100
                                                                    </option>
                                                                </select>
                                                                entries
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-3 position_right">
                                                        <div class="form-group">
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                placeholder="Search"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row"
                                                    style={{ margin: '2%' }}
                                                >
                                                    <MachineInventoryTable
                                                        inputs={ProductInput}
                                                    />
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-5">
                                                        <div
                                                            class="dataTables_info"
                                                            id="DataTables_Table_0_info"
                                                            role="status"
                                                            aria-live="polite"
                                                        >
                                                            Showing 1 to 10 of
                                                            12 entries
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-7">
                                                        <div
                                                            class="dataTables_paginate paging_simple_numbers"
                                                            id="DataTables_Table_0_paginate"
                                                        >
                                                            <ul class="pagination">
                                                                <li
                                                                    class="paginate_button page-item previous disabled"
                                                                    id="DataTables_Table_0_previous"
                                                                >
                                                                    <a
                                                                        href="#"
                                                                        aria-controls="DataTables_Table_0"
                                                                        data-dt-idx="0"
                                                                        tabindex="0"
                                                                        class="page-link"
                                                                    >
                                                                        Previous
                                                                    </a>
                                                                </li>
                                                                <li class="paginate_button page-item active">
                                                                    <a
                                                                        href="#"
                                                                        aria-controls="DataTables_Table_0"
                                                                        data-dt-idx="1"
                                                                        tabindex="0"
                                                                        class="page-link"
                                                                    >
                                                                        1
                                                                    </a>
                                                                </li>
                                                                <li class="paginate_button page-item ">
                                                                    <a
                                                                        href="#"
                                                                        aria-controls="DataTables_Table_0"
                                                                        data-dt-idx="2"
                                                                        tabindex="0"
                                                                        class="page-link"
                                                                    >
                                                                        2
                                                                    </a>
                                                                </li>
                                                                <li
                                                                    class="paginate_button page-item next"
                                                                    id="DataTables_Table_0_next"
                                                                >
                                                                    <a
                                                                        href="#"
                                                                        aria-controls="DataTables_Table_0"
                                                                        data-dt-idx="3"
                                                                        tabindex="0"
                                                                        class="page-link"
                                                                    >
                                                                        Next
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    )
}

export default MachineCatelog
