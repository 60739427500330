import React, { useState, useEffect } from 'react';
import { CssTextField } from '../../../Styles';
import InpCategories from './ProductInpCategory';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputInventory from './ProductInventory';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import { API } from '../../../../API/index';
import Swal from 'sweetalert2';
import { useDropzone } from 'react-dropzone';
import Button from '@mui/material/Button';
import axios from 'axios';
// import { storage } from '../../../../firebase/index';
import  Selected  from 'react-select'
import RichTextEditor from 'react-rte';

import ProductInventoryTable from './ProductInventoryTable'
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { thumbsContainerState, thumb, img, thumbInner, baseStyle, driverDP, sectionDiv, uploadBtnSm, panImg } from '../../../Styles';
import { Api } from '@mui/icons-material';

import { styled } from '@mui/material/styles';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import ProductTable from './ProductTable';
import { Grid } from '@mui/material';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBCgGkH7S3OL-fNKGnQmfMQzbJyY9AZ66o",
  authDomain: "krishivan-app.firebaseapp.com",
  projectId: "krishivan-app",
  storageBucket: "krishivan-app.appspot.com",
  messagingSenderId: "1018772914539",
  appId: "1:1018772914539:web:3f3d750142694da0a74c2e",
  measurementId: "G-CMCSVD54TQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

// import ProductInputTable from '../Prod'
// import ProductInventoryTable from './ProductInventoryTable'

const inputClear = {
  title: "ProductCatelog",
  catelogName: "InputSupplierCatelog",
  categoryName: ""
}
const Subclear = {
  title: "ProductCatelog",
  catelogName: "InputSupplierCatelog",
  CategoryName: "",
  subCategoryName: ""
}
const BrandClear = {
  catalogue: "inputSupplier",
  categoryName: "",
  subCategoryName: "",
  imageUrl: "",
  brandName: "",
  brandImage: ""
}

const ProductClear = {
  catalogue: "inputSupplier",
  categoryName: "",
  subCategoryName: "",
  brandName: "",
  productImage: "",
  productName: ""
}
const VariantClear = {
  title: "ProductCatelog",
  catelogName: "InputSupplierCatelog",
  categoryName: "",
  subCategoryName: "",
  brandName: "",
  brandImage: "",
  productName: "",
  productImage: "",
  quantity: "",
  unit: ""
}

const Input = styled('input')({
  display: 'none',
  marginBottom: '16px',
  marginTop: '16px'
});


function InputCatelog() {
  const catalogue = "inputSupplier";
  const [showCategory, setShowCategory] = useState(false);
  const [createProduct, setCreateProduct] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [Categorylist, setcategorylist] = useState([])
  // const [subCategorylist, setSubCategorylist] = useState([])
  const [brandlist, setbrandlist] = useState([])
  const [subcategoryName, setsubcategoryName] = useState('')
  const [Productlist, setProductlist] = useState([])
  const [description, setDescription] = useState('')
  const [Categorylist, setCategorylist] = useState([])
    const [subCategorylist, setsubCategorylist] = useState([])
  const [categoryname, setcategoryName] = useState('')


  const [list, setlist] = useState([])
  const [catlist, setCatlist] = useState('')
  const [BrandName, setBrandName] = useState('')
  const [ProductInput, setProductInput] = useState([])
  const [inputBooking, setInputBooking] = useState({
      MicroEnterpreneurId: '',
      farmerId: '',
      createdBy: 'Admin',
      firm: '',
      modeOfBooking: '',
      category: '',
      bookedBy: 'MI',
  })
 
  const [formValues, setFormValues] = useState([
      {
          subCategory: '',
          brand: '',
          product: '',
          productImage: '',
          quantity: '',
          unit: '',
          price: '',
          stock: '',
          No: '',
          totalPrice: '',
      },
  ])


  const [QuantityARR, setQuantityArr] = useState({
    catalogue: "inputSupplier",
    categoryName: "",
    subCategoryName: "",
    brandName: "",
    productName: "",
    productImage: "",
    quantity: "",
    unit: ""
  })
  const [driverImg, setDriverImg] = useState(null);
  const [BrandImg, setBrandImg] = useState(null)
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [editorState, setEditorState] = useState(
    RichTextEditor.createEmptyValue()
  );
  useEffect(() => {
    // getState();
    getCategoryList();
    getAllProducts();
  }, []);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseclear = () => {
    // setAnchorEl(null);
    handleClose()
    setDriverImg(null)
    setBrandImg(null)
  };
  
  const getAllProducts = async () => {
    var farmerid = localStorage.getItem('Traderid')
    var token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const db = {
      "catalogue": "inputSupplier",
    //   "mobile_number": "5555555555"
    }
    // const { data } = await API.get(`/getProductCatelog?&title=ProductCatelog&catelogName=InputSupplierCatelog&type=2`);
    axios.post("https://prod-api.krishione.com/farmer-function/get-all-products", db, config)
      .then(res => {
        console.log("test", res.data.data);
        setProductInput(res?.data?.product);
      })
  }
  const getProductInput = async () => {
    var farmerid = localStorage.getItem('Traderid')
    var token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const db = {
      "catalogue": "inputSupplier",
      "mobile_number": "5555555555"
    }
    // const { data } = await API.get(`/getProductCatelog?&title=ProductCatelog&catelogName=InputSupplierCatelog&type=2`);
    axios.post("https://prod-api.krishione.com/farmer-function/product-getinventory", db, config)
      .then(res => {
        console.log("test", res.data.data);
        setProductInput(res?.data?.data);
      })
  }
  const [InputProduct, setInputProduct] = useState({
    catalogue: "inputSupplier",
    categoryName: ""
  })
  const [SubProduct, setSubProduct] = useState({
    catalogue: "inputSupplier",
    categoryName: "",
    subCategoryName: ""

  })
  const handleChange = (value) => {
    setEditorState(value);
    const htmlString = editorState.toString('html');
// You can now save or use the 'htmlString' as needed
console.log(htmlString);
  };
  const [driverLicense, setDriverLicense] = useState([]);
  const [BrandProduct, setBrandProduct] = useState({
    catalogue: "inputSupplier",
    categoryName: "",
    subCategoryName: "",
    imageUrl: "",
    brandName: "",
    brandImage: ""
  })
  const [driverLicenses, setDriverLicenses] = useState([]);
  const [CommissionProduct, setCommissionProduct] = useState({
    catalogue: "inputSupplier",
    categoryName: "",
    subCategoryName: "",
    brandName: "",
    brandImage: "",
    productName: "",
    productImage: "",hsn:"",sku:""
  })


  const [VariantProduct, setVariantProduct] = useState({
    title: "ProductCatelog",
    catelogName: "InputSupplierCatelog",
    categoryName: "",
    subCategoryName: "",
    brandName: "",
    productName: "",
    quantity: ""
  })

  const handleInputProduct = async () => {
    console.log("InputProduct", InputProduct)

    try {
      // const { data } = await API.post('/createProductCategory ', InputProduct);
      var token = localStorage.getItem("token");
      const config = {
        headers: { 'Authorization': `Bearer ${token}` }
      };
      const data = await axios.post('https://prod-api.krishione.com/farmer-function/category', InputProduct, config)
        .then(data => {
          console.log("category in input S: ", data);
          Swal.fire({
            icon: 'success',
            iconColor: '#28a745',
            title: data?.message,
            showConfirmButton: false,
            timer: 2000
          });
          console.log(data);
          getCategoryList()
          setInputProduct(inputClear)
        })
        .catch(err => {
          console.log(err);
          setInputProduct(inputClear)
          Swal.fire({
            icon: 'warning',
            iconColor: '#dc3545',
            title: data?.message,
            showConfirmButton: false,
            timer: 2000
          });
        });
    }
    catch (err) {
      setInputProduct(inputClear)
      Swal.fire({
        icon: 'warning',
        iconColor: '#dc3545',
        title: err.message,
        showConfirmButton: false,
        timer: 2000
      });
    }
  }
  const handlesubCategoryName = async () => {
    console.log("SubProduct", SubProduct)

    try {
      // const { data } = await API.post('/createProductSubCategory ',SubProduct);
      // if(data?.success){
      //  Swal.fire({
      //       icon: 'success',
      //       iconColor: '#28a745',
      //       title: data?.message,
      //       showConfirmButton: false,
      //       timer: 2000
      //     });
      //  console.log(data);
      //  getCategoryList()
      //  setSubProduct(Subclear)
      // } else {
      //    setSubProduct(Subclear)
      //  Swal.fire({
      //    icon: 'warning',
      //    iconColor: '#dc3545',
      //    title: data?.message,
      //    showConfirmButton: false,
      //    timer: 2000
      //    });
      // }
      var token = localStorage.getItem("token");
      var config = {
        headers: { 'Authorization': `Bearer ${token}` }
      };
      await axios.post(`https://prod-api.krishione.com/farmer-function/subcategory`, SubProduct, config)
        .then(data => {
          console.log("sub cat:", data);
          Swal.fire({
            icon: 'success',
            iconColor: '#28a745',
            title: data?.message,
            showConfirmButton: false,
            timer: 2000
          });
          console.log(data);
          getCategoryList()
          setSubProduct(Subclear)
        })
        .catch(data => {
          console.log(data);
          setSubProduct(Subclear)
          Swal.fire({
            icon: 'warning',
            iconColor: '#dc3545',
            title: data?.message,
            showConfirmButton: false,
            timer: 2000
          });
        })
    }
    catch (err) {
      setSubProduct(Subclear)
      Swal.fire({
        icon: 'warning',
        iconColor: '#dc3545',
        title: err.message,
        showConfirmButton: false,
        timer: 2000
      });
    }

  }
  const handleBrandProduct = async () => {
    console.log("BrandProduct", BrandProduct)

    try {
      // const { data } = await API.post('/createProductBrand',BrandProduct);
      // if(data?.success){
      //  Swal.fire({
      //       icon: 'success',
      //       iconColor: '#28a745',
      //       title: data?.message,
      //       showConfirmButton: false,
      //       timer: 2000
      //     });
      //  console.log(data);

      //  setBrandProduct(BrandClear)
      //  setBrandImg(null);
      //  setDriverImg(null);
      // } else {
      //    setBrandProduct(BrandClear)
      //  Swal.fire({
      //    icon: 'warning',
      //    iconColor: '#dc3545',
      //    title: data?.message,
      //    showConfirmButton: false,
      //    timer: 2000
      //    });
      // }
      var token = localStorage.getItem("token");
      var config = {
        headers: { 'Authorization': `Bearer ${token}` }
      };
      await axios.post(`https://prod-api.krishione.com/farmer-function/brand`, BrandProduct, config)
        .then(data => {
          console.log("brand in input", data)
          Swal.fire({
            icon: 'success',
            iconColor: '#28a745',
            title: data?.message,
            showConfirmButton: false,
            timer: 2000
          });
          setBrandProduct(BrandClear)
          setBrandImg(null);
          setDriverImg(null);
        })
        .catch(data => {
          Swal.fire({
            icon: 'warning',
            iconColor: '#dc3545',
            title: data?.message,
            showConfirmButton: false,
            timer: 2000
          });
        })
    }
    catch (err) {
      setBrandProduct(BrandClear)
      Swal.fire({
        icon: 'warning',
        iconColor: '#dc3545',
        title: err.message,
        showConfirmButton: false,
        timer: 2000
      });
    }

  }
  const handleCommissionProduct = async () => {
    console.log("CommissionProduct", CommissionProduct)

    try {
      // const { data } = await API.post('/createProductCatelogProduct ', CommissionProduct);
      // if(data?.success){
      //  Swal.fire({
      //       icon: 'success',
      //       iconColor: '#28a745',
      //       title: data?.message,
      //       showConfirmButton: false,
      //       timer: 2000
      //     });
      //  console.log(data);

      //  setCommissionProduct(ProductClear)
      //  setBrandImg(null);
      //  setDriverImg(null);
      // } else {
      //    setCommissionProduct(ProductClear)
      //  Swal.fire({
      //    icon: 'warning',
      //    iconColor: '#dc3545',
      //    title: data?.message,
      //    showConfirmButton: false,
      //    timer: 2000
      //    });
      // }
      var token = localStorage.getItem("token");
      var config = {
        headers: { 'Authorization': `Bearer ${token}` }
      };
      await axios.post(`https://prod-api.krishione.com/farmer-function/add-product`, {...CommissionProduct,description: editorState.toString('html')}, config)
        .then(data => {
          console.log("brand in input", data)
          Swal.fire({
            icon: 'success',
            iconColor: '#28a745',
            title: data?.message,
            showConfirmButton: false,
            timer: 2000
          });
          setCommissionProduct(ProductClear)
        })
        .catch(data => {
          Swal.fire({
            icon: 'warning',
            iconColor: '#dc3545',
            title: data?.message,
            showConfirmButton: false,
            timer: 2000
          });
        })
    }
    catch (err) {
      setCommissionProduct(ProductClear)
      Swal.fire({
        icon: 'warning',
        iconColor: '#dc3545',
        title: err.message,
        showConfirmButton: false,
        timer: 2000
      });
    }

  }
  const handleVariantProduct = async () => {
    console.log("VariantProduct", QuantityARR)

    try {
      // const { data } = await API.post('/createProductCatelogQuantity', QuantityARR);
      // if(data?.success){
      //  Swal.fire({
      //       icon: 'success',
      //       iconColor: '#28a745',
      //       title: data?.message,
      //       showConfirmButton: false,
      //       timer: 2000
      //     });
      //  console.log(data);

      //  setQuantityArr(VariantClear)
      // } else {
      //   setQuantityArr(VariantClear)
      //  Swal.fire({
      //    icon: 'warning',
      //    iconColor: '#dc3545',
      //    title: data?.message,
      //    showConfirmButton: false,
      //    timer: 2000
      //    });
      // }
      var token = localStorage.getItem("token");
      var config = {
        headers: { 'Authorization': `Bearer ${token}` }
      };
      await axios.post(`https://prod-api.krishione.com/farmer-function/createProductCatelogQuantity`, QuantityARR, config)
        .then(data => {
          console.log("quantity", data)
          Swal.fire({
            icon: 'success',
            iconColor: '#28a745',
            title: data?.message,
            showConfirmButton: false,
            timer: 2000
          });
          setQuantityArr(ProductClear)
        })
        .catch(data => {
          Swal.fire({
            icon: 'warning',
            iconColor: '#dc3545',
            title: data?.message,
            showConfirmButton: false,
            timer: 2000
          });
        })
    }
    catch (err) {
      setQuantityArr(VariantClear)
      Swal.fire({
        icon: 'warning',
        iconColor: '#dc3545',
        title: err.message,
        showConfirmButton: false,
        timer: 2000
      });
    }

  }
  const handlesubcategory = (i, e) => {
    getbrandList(e.value)
    setsubcategoryName(e.value)
    let newFormValues = [...formValues]
    newFormValues[i]['subCategory'] = e.value
    newFormValues[i]['brand'] = ''
    newFormValues[i]['product'] = ''
    newFormValues[i]['quantity'] = ''
    newFormValues[i]['unit'] = ''
    newFormValues[i]['price'] = ''
    newFormValues[i]['totalPrice'] = ''
    newFormValues[i]['No'] = ''
    newFormValues[i]['stock'] = ''
    setFormValues(newFormValues)
}
const handleBrandcategory = (i, e) => {
    getproductList(e.value)
    setBrandName(e.value)
    let newFormValues = [...formValues]
    newFormValues[i]['brand'] = e.value
    newFormValues[i]['product'] = ''
    newFormValues[i]['quantity'] = ''
    newFormValues[i]['unit'] = ''
    newFormValues[i]['price'] = ''
    newFormValues[i]['totalPrice'] = ''
    newFormValues[i]['No'] = ''
    newFormValues[i]['stock'] = ''
    setFormValues(newFormValues)
}
const brandoption = brandlist.map((item, ind) => ({
  value: item.brandName,
  label: item.brandName,
}))
const getbrandList = async (val) => {
  // const { data } = await API.get(`/getProductBrand?type=0&title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=`+categoryName+'&subCategoryName='+val);
  // console.log("hkjjhj",data)
  // setbrandlist(data?.result);
  let forGet = {
      categoryName: categoryname,
      subCategoryName: val,
      catalogue: 'inputSupplier',
  }
  var token = localStorage.getItem('token')
  var config = {
      headers: { Authorization: `Bearer ${token}` },
  }
  await axios
      .post(
          `https://prod-api.krishione.com/farmer-function/get-brand`,
          forGet,
          config
      )
      .then((data) => {
          console.log('brand in input', data)
          setbrandlist(data.data.brand)
      })
      .catch((err) => {
          console.log(err)
      })
}
const getproductList = async (val) => {
    let forGet = {
        categoryName: categoryname,
        subCategoryName: subcategoryName,
        catalogue: 'inputSupplier',
        brandName: val,
        // "mobile_number": localStorage.getItem('mobile')
    }
    var token = localStorage.getItem('token')
    var config = {
        headers: { Authorization: `Bearer ${token}` },
    }
    await axios
        .post(
            `https://prod-api.krishione.com/farmer-function/get-product`,
            forGet,
            config
        )
        .then((data) => {
            console.log('brand in input', data)
            setProductInput(data.data.product)
        })
        .catch((err) => {
            console.log(err)
        })
}


  const getCategoryList = async () => {
    // const { data } = await API.get(`/getProductCategory?type=0&title=ProductCatelog&catelogName=InputSupplierCatelog`);
    // console.log("hkjjhj",data)
    // setCategorylist(data?.result);
    var token = localStorage.getItem("token");
    var config = {
      headers: { 'Authorization': `Bearer ${token}` }
    };
    await axios.get(`https://prod-api.krishione.com/farmer-function/category/inputSupplier`, config)
      .then(res => {
        console.log("d :", res.data.category);
        setCategorylist(res.data.category);
      })
  }
  const handleCategoryChange = (e) => {
    // setSubLivestockProduct({...SubLivestockProduct,categoryName:e.target.value})
    setBrandProduct({ ...BrandProduct, categoryName: e.target.value })
    getSubCategoryList(e.target.value)
    setcategoryName(e.target.value)
  }
  const getSubCategoryList = async (val) => {
    // const { data } = await API.get(`/getProductSubCategory?type=0&title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=`+val);
    // console.log("hkjjhj",data)
    // setsubCategorylist(data?.result);
    var token = localStorage.getItem("token");
    var config = {
      headers: { 'Authorization': `Bearer ${token}` }
    };
    console.log(InputProduct);
    console.log(SubProduct);
    const subCat = {
      catalogue: catalogue,
      categoryName: val
    }
    await axios.post(`https://prod-api.krishione.com/farmer-function/get-subcategory`, subCat, config)
      .then(res => {
        console.log("get sub cat in input catalogue", res.data.subcategory);
        setsubCategorylist(res.data.subcategory);
      })
  }
  const handleCategoryChangeProduct = (e) => {
    // setCommissionLivestockProduct({...CommissionLivestockProduct, categoryName:e.target.value})
    setCommissionProduct({ ...CommissionProduct, categoryName: e.target.value })
    getSubCategoryList(e.target.value)
    setcategoryName(e.target.value)
  }

  const handlechangeproductBrand = (e) => {
    console.log('words')
    console.log(e.target.value, "e")
    setCommissionProduct({ ...CommissionProduct, subCategoryName: e.target.value })
    getBrandList(e.target.value)
  }
  const handlecategory = (e) => {
    setcategoryName(e.value)
    setInputBooking({ ...inputBooking, category: e.value })
    getsubCategoryList(e.value)
    // setFormValues(formClear)
}
const getsubCategoryList = async (val) => {
  // const { data } = await API.get(`/getProductSubCategory?type=0&title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=`+val);
  // console.log("hkjjhj",data)
  // setsubCategorylist(data?.result);
  var token = localStorage.getItem('token')
  var config = {
      headers: { Authorization: `Bearer ${token}` },
  }
  setcategoryName(val)
  const subCat = {
      catalogue: 'inputSupplier',
      categoryName: val,
  }

  await axios
      .post(
          `https://prod-api.krishione.com/farmer-function/get-subcategory`,
          subCat,
          config
      )
      .then((res) => {
          console.log(
              'get sub cat in input catalogue',
              res.data.subcategory
          )
          setsubCategorylist(res.data.subcategory)
      })
}
  const getBrandList = async (val) => {
    // const { data } = await API.get(`/getProductBrand?type=0&title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=`+categoryName+'&subCategoryName='+val);
    // console.log("hkjjhj",data)
    // setbrandlist(data?.result);
    let forGetBrands = {
      "categoryName": CommissionProduct.categoryName,
      "subCategoryName": val,
      "catalogue": catalogue
    };
    var token = localStorage.getItem("token");
    var config = {
      headers: { 'Authorization': `Bearer ${token}` }
    };
    await axios.post(`https://prod-api.krishione.com/farmer-function/get-brand`, forGetBrands, config)
      .then(data => {
        console.log("brand in input", data);
       
        setbrandlist(data.data.brand)
      })
      .catch(err => {
        console.log(err)
      })
  }
  const typeoptiob =
  brandlist &&
  brandlist.map((item, ind) => ({
      value: item.brandName,
      label: item.brandName,
      image: item.brandImage,
      

  }))


  const handleCategoryChangeVarient = (e) => {
    // setLivestockVariantProduct({...LivestockVariantProduct,categoryName:e.target.value})
    setQuantityArr({ ...QuantityARR, categoryName: e.target.value })
    getSubCategoryList(e.target.value)
    setcategoryName(e.target.value)
  }

  const handlechangeproductVarientBrand = (e) => {
    console.log(e.target.value, "e")
    // setLivestockVariantProduct({...LivestockVariantProduct,subCategoryName:e.target.value})
    setQuantityArr({ ...QuantityARR, subCategoryName: e.target.value })
    getBrandListVarient(e.target.value)
    setsubcategoryName(e.target.value)
  }
  const getBrandListVarient = async (val) => {
    // const { data } = await API.get(`/getProductBrand?type=0&title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=`+categoryName+'&subCategoryName='+val);
    // console.log("hkjjhj",data)
    // setbrandlist(data?.result);
    let forGet = {
      "categoryName": QuantityARR.categoryName,
      "subCategoryName": val,
      "catalogue": catalogue
    };
    var token = localStorage.getItem("token");
    var config = {
      headers: { 'Authorization': `Bearer ${token}` }
    };
    await axios.post(`https://prod-api.krishione.com/farmer-function/get-brand`, forGet, config)
      .then(data => {
        console.log("brand in input", data);
        setbrandlist(data.data.brand)
      })
      .catch(err => {
        console.log(err)
      })

  }

  const handlechangeproductVarientProduct = (e) => {
    // setLivestockVariantProduct({...LivestockVariantProduct,brandName:e.target.value})
    setQuantityArr({ ...QuantityARR, brandName: e.target.value })
    getProductListVarient(e.target.value)
  }
  const getProductListVarient = async (val) => {
    // const { data } = await API.get(`/getProductCatelogProduct?type=3&title=ProductCatelog&catelogName=InputSupplierCatelog&categoryName=`+categoryName+'&subCategoryName='+subcategoryName+'&brandName='+val);
    // console.log("hkjjhj",data)
    // setProductlist(data?.result);
    let forGet = {
      "categoryName": QuantityARR.categoryName,
      "subCategoryName": QuantityARR.subCategoryName,
      "brandName": val,
      "catalogue": catalogue
    };
    var token = localStorage.getItem("token");
    var config = {
      headers: { 'Authorization': `Bearer ${token}` }
    };
    await axios.post(`https://prod-api.krishione.com/farmer-function/get-product`, forGet, config)
      .then(data => {
        console.log("product", data);
        setProductlist(data.data.product)
      })
      .catch(err => {
        console.log(err)
      })
  }
  const categoryoption = Categorylist.map((item, ind) => ({
    value: item.categoryName,
    label: item.categoryName,
}))
const subcategoryoption = subCategorylist.map((item, ind) => ({
  value: item.subCategoryName,
  label: item.subCategoryName,
}))
  const handleView = () => {
    setShowCategory(true)
    getAllProducts()
  }
  // brand
  const BrandUpload = () => {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
      accept: "image/",
      onDrop: (acceptedFiles) => {
        setDriverLicense(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            }))
        );
      }
    });

    return (
      <section className="container" style={baseStyle}>
        <div {...getRootProps({ className: 'dropzone' })} style={panImg}>
          <input {...getInputProps()} multiple={false} />
          {driverLicense.length > 0 ?
            <aside style={thumbsContainerState}>
              <div style={thumb}>
                <div style={thumbInner}>
                  <img src={driverLicense[0].preview} style={img} alt="brand" />
                </div>
              </div>
            </aside>
            :
            <p align="center">Drop Image  here or click to upload.</p>
          }
        </div>
      </section>
    )
  }
  const handleUploadDL = (e) => {
    const doc = driverLicense[0];
    if (doc) {
      const storageRef = ref(storage, `driverPhotos/${doc.name}`);
      const uploadTask = uploadBytesResumable(storageRef, doc);
      uploadTask.on('state_changed',
        (snapshot) => {
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(storageRef).then((url) => {
            setBrandProduct({ ...BrandProduct, drivingLicensePhoto: url });
          });
        }
      );
    }
  };

  // product

  const ProductUpload = () => {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
      accept: "image/",
      onDrop: (acceptedFiles) => {
        setDriverLicenses(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            }))
        );
      }
    });

    return (
      <section className="container" style={baseStyle}>
        <div {...getRootProps({ className: 'dropzone' })} style={panImg}>
          <input {...getInputProps()} multiple={false} />
          {driverLicenses.length > 0 ?
            <aside style={thumbsContainerState}>
              <div style={thumb}>
                <div style={thumbInner}>
                  <img src={driverLicenses[0].preview} style={img} alt="product" />
                </div>
              </div>
            </aside>
            :
            <p align="center">Drop Image  here or click to upload.</p>
          }
        </div>
      </section>
    )
  }
  const handleUploadDLs = (e) => {
    const doc = driverLicenses[0];
    if (doc) {
      const storageRef = ref(storage, `driverPhotos/${doc.name}`);
      const uploadTask = uploadBytesResumable(storageRef, doc);
      uploadTask.on('state_changed',
        (snapshot) => {
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(storageRef).then((url) => {
            setCommissionProduct({ ...CommissionProduct, productName: url });
          });
        }
      );
    }
  };
  const handleUploadDP = (e) => {
    console.log("e.target.files[0]", e.target.files[0])
    setDriverImg(URL.createObjectURL(e.target.files[0]));
    const doc = e.target.files[0];
    if (e.target.files[0]) {
      const storageRef = ref(storage, `driverPhotos/${doc.name}`);
      const uploadTask = uploadBytesResumable(storageRef, doc);
      uploadTask.on('state_changed',
        (snapshot) => {
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(storageRef).then((url) => {
            setBrandProduct({ ...BrandProduct, brandImage: url });
          });
        }
      );
    }
  };
  const handleUploadIP = (e) => {
    console.log("e.target.files[0]", e.target.files[0])
    setBrandImg(URL.createObjectURL(e.target.files[0]));
    const doc = e.target.files[0];
    if (e.target.files[0]) {
      const storageRef = ref(storage, `driverPhotos/${doc.name}`);
      const uploadTask = uploadBytesResumable(storageRef, doc);
      uploadTask.on('state_changed',
        (snapshot) => {
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(storageRef).then((url) => {
            setCommissionProduct({ ...CommissionProduct, productImage: url });
          });
        }
      );
    }
  };
  return (
    <>
      <div class="row align-items-center mb-3">
        <div class="col">
          {createProduct ?
            <h3 class="page-title">Input Supplier Inventory</h3>
            :
            <h3 class="page-title">Input Supplier Catalogue</h3>
          }
        </div>
        {createProduct ?
          <div class="col-auto">
            <button class="btn btn-primary filter-btn" onClick={e => setCreateProduct(false)}>
              <i class="fas fa-left"></i> <span>  Back</span>
            </button>
          </div>
          :
          <>
            {!showCategory ?
              <>
                <div className="col-auto">
                  <button class="btn btn-primary filter-btn"
                    id="fade-button"
                    aria-controls="fade-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    <i class="fas fa-plus"></i> <span>  Create </span>
                  </button>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem onClick={handleClose} id="openCategory">Category</MenuItem>
                    <MenuItem onClick={handleClose} id="openSubCategory">SubCategory</MenuItem>
                    <MenuItem onClick={handleCloseclear} id="openBrand">Brand</MenuItem>
                    <MenuItem onClick={handleCloseclear} id="openProduct">Product</MenuItem>
                    {/* <MenuItem onClick={handleClose} id="openVariant">Quantity & Unit</MenuItem>
                    <MenuItem onClick={(e) => { setCreateProduct(true); handleClose() }}>Inventory</MenuItem> */}
                  </Menu>
                </div>
                <div class="col-auto">
                  <button class="btn btn-primary filter-btn" onClick={() => handleView()}
                  //  onClick={e=>setShowCategory(true)}
                  >
                    <i class="fas fa-eye"></i> <span>  View Category</span>
                  </button>
                </div>
              </>
              :
              <div class="col-auto">
                <button class="btn btn-primary filter-btn" onClick={e => setShowCategory(false)}>
                  <i class="fas fa-left"></i> <span>  Back</span>
                </button>
              </div>
            }
          </>
        }
      </div>

      {createProduct ?
        <InputInventory />
        :
        <>
          {showCategory === false ?
            <>
              {/* Add Category */}
              <div id="category" class="card filter-card ">
                <form class="card-body pb-0" onSubmit={(e) => e.preventDefault()}>
                  <div class="row">
                    <h5 class="card-title">Create Category</h5>
                    <div class="col-md-4 select2-container">
                      <div class="form-group" data-select2-id="11">
                        <CssTextField
                          required
                          fullWidth
                          id="InpCategory"
                          label="Category"
                          variant="standard"
                          name="InpCategory" value={InputProduct.categoryName}
                          onChange={(e) => setInputProduct({ ...InputProduct, categoryName: e.target.value })}
                        />
                      </div>
                    </div>
                    <div class="col-sm-2 col-md-2">
                      <div class="form-group">
                        <button type="submit" class="btn btn-primary mt-26" onClick={() => handleInputProduct()}>Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* Add SubCategory */}
              <div id="subCategory" class="card filter-card">
                <form class="card-body pb-0" onSubmit={(e) => e.preventDefault()}>
                  <div class="row">
                    <h5 class="card-title">Create SubCategory</h5>
                    <div class="col-md-4">
                      <FormControl variant="standard" fullWidth className="mt-3">
                        <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Category</InputLabel>
                        <Select
                          required
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          //  value={age}
                          //  onChange={handleChange}
                          label="Category"
                          color="success" value={SubProduct.categoryName}
                          onChange={(e) => setSubProduct({ ...SubProduct, categoryName: e.target.value })}
                        >
                          {
                            Categorylist && Categorylist.map((val, ind) => {
                              // {console.log("Categorylist",val)}
                              return (
                                <MenuItem value={val.categoryName}>{val.categoryName}</MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>
                    </div>
                    <div class="col-md-4 select2-container">
                      <div class="form-group" data-select2-id="11">
                        <CssTextField
                          required
                          fullWidth
                          id="InpSubCategory"
                          label="SubCategory"
                          variant="standard"
                          name="InpSubCategory" value={SubProduct.subCategoryName}
                          onChange={(e) => setSubProduct({ ...SubProduct, subCategoryName: e.target.value })}
                        />
                      </div>
                    </div>
                    <div class="col-sm-2 col-md-2">
                      <div class="form-group">
                        <button type="submit" class="btn btn-primary mt-26" onClick={() => handlesubCategoryName()}>Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* Create Brand */}
              <div id="brand" class="card filter-card">
                <form class="card-body pb-2" onSubmit={(e) => e.preventDefault()}>
                  <div className="mt-3">
                    <h5 class="card-title">Create Brand</h5>
                    <div class="row mb-4">
                      <div class="col-md-3">
                        <FormControl variant="standard" fullWidth className="mt-3">
                          <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Category</InputLabel>
                          <Select
                            required
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            //  value={age}
                            //  onChange={handleChange}
                            label="Firm"
                            color="success" value={BrandProduct.categoryName}
                            // onChange={(e)=>setBrandProduct({...BrandProduct, categoryName:e.target.value})}
                            onChange={(e) => handleCategoryChange(e)}
                          >
                            {
                              Categorylist && Categorylist.map((val, ind) => {
                                // {console.log("Categorylist",val)}
                                return (
                                  <MenuItem value={val.categoryName}>{val.categoryName}</MenuItem>
                                )
                              })
                            }
                          </Select>
                        </FormControl>
                      </div>
                      <div class="col-md-3">
                        <FormControl variant="standard" fullWidth className="mt-3">
                          <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>SubCategory</InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            //  value={age}
                            //  onChange={handleChange}
                            label="Booking Mode"
                            color="success" value={BrandProduct.subCategoryName}
                            onChange={(e) => setBrandProduct({ ...BrandProduct, subCategoryName: e.target.value })}
                          >
                            {
                              subCategorylist && subCategorylist.map((val, ind) => {
                                //  {console.log("Categorylist",val)}
                                return (
                                  <MenuItem value={val.subCategoryName}>{val.subCategoryName}</MenuItem>
                                )
                              })
                            }
                          </Select>
                        </FormControl>
                      </div>
                      <div className="row">


                        <div className="col-md-3 mt-3">

                          <div class="form-group row widget-3" style={sectionDiv}>
                            <section className="container" style={driverDP}>
                              {driverImg === null ?
                                <p align="center">Upload Image</p>
                                :
                                <aside style={thumbsContainerState}>
                                  <div style={thumb}>
                                    <div style={thumbInner}>
                                      <img src={driverImg} style={img} alt="driverPhoto" />
                                    </div>
                                  </div>
                                </aside>
                              }
                            </section>
                          </div>
                        </div>
                        <div className="col-md-3 mt-3">
                          <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black', marginTop: '90px' }}>
                            {/* Product */}
                          </InputLabel>
                          <CssTextField
                            required
                            fullWidth
                            id="brandName"
                            label="Brand Name"
                            variant="standard"
                            name="brandName" value={BrandProduct.brandName}
                            onChange={(e) => setBrandProduct({ ...BrandProduct, brandName: e.target.value })}
                          />
                        </div>
                        <div className="col-md-12 mt-3 d-flex">
                          <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black', marginRight: '10px' }}>
                            Upload Brand Image:
                          </InputLabel>
                          <label htmlFor="contained-button-file">
                            <Button variant="contained" component="span" color="success" size="small" style={uploadBtnSm}>
                              <Input accept="image/" id="contained-button-file" multiple="false" type="file" onChange={handleUploadDP} />
                              Upload
                            </Button>
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-md-12 mt-3 d-flex">
                            <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black', marginRight: '10px' }}>
                              Upload Brand:
                            </InputLabel>
                            <Button variant="contained" color="success" size="small" style={uploadBtnSm} onClick={handleUploadDL}>
                              Upload
                            </Button>
                          </div> */}
                        {/* <div className="col-md-3 mt-3">
                            <div class="form-group row widget-3" style={sectionDiv}>
                              <BrandUpload />
                            </div>
                          </div> */}
                        {/* <div className="col-md-3 mt-3">
                            <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black', marginTop: '90px' }}>
                              Brand
                            </InputLabel>
                            <CssTextField 
                    required
                    fullWidth 
                    id="brandName"
                    label="Brand Name" 
                    variant="standard"
                    name="brandName" value={BrandProduct.brandName}
                    onChange={(e)=>setBrandProduct({...BrandProduct, brandName:e.target.value})}
                  /> 
                          </div> */}
                      </div>

                      {/* <div class="col-md-3">
                 <CssTextField 
                    required
                    fullWidth 
                    id="brandName"
                    label="Brand Name" 
                    variant="standard"
                    name="brandName" value={BrandProduct.brandName}
                    onChange={(e)=>setBrandProduct({...BrandProduct, brandName:e.target.value})}
                  /> 
                 </div> */}
                    </div>

                    <div className="row justify-content-end mb-3">
                      <div className="col-md-1">
                        <button type="submit" className="btn btn-danger">Cancel</button>
                      </div>
                      <div className="col-md-1">
                        <button type="submit" className="btn btn-primary" onClick={() => handleBrandProduct()}>Submit</button>
                      </div>
                    </div>

                  </div>
                </form>
              </div>
              {/* Create Product */}
              <div id="product" class="card filter-card">
                <form class="card-body pb-2" onSubmit={(e) => e.preventDefault()}>
                  <div className="mt-3">
                    <h5 class="card-title">Create Product</h5>
                    <div class="row mb-4">
                      <div class="col-md-3">
                        <FormControl variant="standard" fullWidth className="mt-3">
                          <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Category</InputLabel>
                          <Select
                            required
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            //  value={age}
                            //  onChange={handleChange}
                            label="Firm"
                            color="success" value={CommissionProduct.categoryName}
                            onChange={(e) => handleCategoryChangeProduct(e)}
                          // onChange={(e)=>setCommissionProduct({...CommissionProduct, categoryName:e.target.value})}
                          >
                            {
                              Categorylist && Categorylist.map((val, ind) => {
                                // {console.log("Categorylist",val)}
                                return (
                                  <MenuItem value={val.categoryName}>{val.categoryName}</MenuItem>
                                )
                              })
                            }
                          </Select>
                        </FormControl>
                      </div>
                      <div class="col-md-3">
                        <FormControl variant="standard" fullWidth className="mt-3">
                          <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>SubCategory</InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            //  value={age}
                            //  onChange={handleChange}
                            label="Booking Mode"
                            color="success" value={CommissionProduct.subCategoryName}
                            onChange={(e) => handlechangeproductBrand(e)}
                          // onChange={(e)=>setCommissionProduct({...CommissionProduct, subCategoryName:e.target.value})}
                          >

                            {
                              subCategorylist && subCategorylist.map((val, ind) => {
                                //  {console.log("Categorylist",val)}
                                return (
                                  <MenuItem value={val.subCategoryName}>{val.subCategoryName}</MenuItem>
                                )
                              })
                            }
                          </Select>
                        </FormControl>
                      </div>
                      <div class="col-md-3">
                        <FormControl variant="standard" fullWidth className="mt-3">
                          <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Brand Name</InputLabel>
                          {/* <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard" value={CommissionProduct.brandName}
                            onChange={(e) => setCommissionProduct({ ...CommissionProduct, brandName: e.target.value,brandImage:e.target.brandImage })}
                            //  value={age}
                            //  onChange={handleChange}
                            label="BRAND Name"
                            color="success"
                          >
                            {
                              brandlist && brandlist.map((val, ind) => {
                                //  {console.log("Categorylist",val)}
                                return (
                                  <MenuItem name={val.brandImage} value={val.brandName}>{val.brandName}</MenuItem>
                                )
                              })
                            }
                          </Select> */}
                           <Selected
                                        style={{
                                            width: '270px',
                                            height: '40px',
                                        }}
                                        placeholder={'Select Brand'}
                                        // value={inputBooking.modeOfBooking}
                                        onChange={(e) => setCommissionProduct({ ...CommissionProduct, brandName: e.value,brandImage:e.image })}
                                        // onChange={(e) =>
                                        //     setSellCrop({
                                        //         ...sellCrop,
                                        //         cropName: e.value,
                                        //         cropTypeImage: e.image,
                                        //         crop_type: e.type,
                                        //         productId:e.productId

                                        //     })
                                        // }
                                        options={typeoptiob}
                                    />
                        </FormControl>
                      </div>
                      {/* <div class="col-md-3">
                 <CssTextField 
                    required
                    fullWidth 
                    id="variantName"
                    label="Variant Name" 
                    variant="standard"
                    name="variantName"  value={
                      VariantProduct.variantName}
                    onChange={(e)=>setVariantProduct({...VariantProduct, variantName:e.target.value})}
                  /> 
                 </div> */}
                      <div className="row">


                        <div className="col-md-3 mt-3">

                          <div class="form-group row widget-3" style={sectionDiv}>
                            <section className="container" style={driverDP}>
                              {BrandImg === null ?
                                <p align="center">Upload Image</p>
                                :
                                <aside style={thumbsContainerState}>
                                  <div style={thumb}>
                                    <div style={thumbInner}>
                                      <img src={BrandImg} style={img} alt="driverPhoto" />
                                    </div>
                                  </div>
                                </aside>
                              }
                            </section>
                          </div>
                        </div>
                        <div className="col-md-3 mt-3">
                          <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black', marginTop: '90px' }}>
                            {/* Product */}
                          </InputLabel>
                          <CssTextField
                            required
                            fullWidth
                            id="productName"
                            label="Product Name"
                            variant="standard"
                            name="productName" value={CommissionProduct.productName}
                            onChange={(e) => setCommissionProduct({ ...CommissionProduct, productName: e.target.value })}
                          />
                        </div>
                        {/* <div className="col-md-3 mt-3">
                            <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black', marginTop: '90px' }}>
                           
                            </InputLabel>
                            <CssTextField 
                    required
                    fullWidth 
                    id="quantity"
                    label="Quantity"  
                    variant="standard"
                    name="quantity"  value={
                      CommissionProduct.quantity}
                    onChange={(e)=>setCommissionProduct({...CommissionProduct, quantity:e.target.value})}
                  /> 
                          </div> */}

                        <div className="col-md-12 mt-3 d-flex">
                          <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black', marginRight: '10px' }}>
                            Upload Product Image:
                          </InputLabel>
                          <label htmlFor="contained-button-files">
                            <Button variant="contained" component="span" color="success" size="small" style={uploadBtnSm}>
                              <Input accept="image/" id="contained-button-files" multiple="false" type="file" onChange={(e) => handleUploadIP(e)} />
                              Upload
                            </Button>
                          </label>
                        </div>

                      </div>
                      <div className="col mt-3">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                        }}
                                                    >
                                                        Product Description
                                                    </InputLabel>
                                                    <RichTextEditor
        value={editorState}
        onChange={handleChange}
      />
                                                </div>
                                                <div className="col-md-3 mt-3 d-flex">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                            marginTop: '90px',
                                                        }}
                                                    >
                                                        {/* Product */}
                                                    </InputLabel>
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="sku"
                                                        label="SKU"
                                                        variant="standard"
                                                        name="sku"
                                                        value={
                                                            CommissionProduct.sku
                                                        }
                                                        onChange={(e) =>
                                                            setCommissionProduct(
                                                                {
                                                                    ...CommissionProduct,
                                                                    sku:
                                                                        e.target
                                                                            .value,
                                                                }
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-3 mt-3 d-flex">
                                                    <InputLabel
                                                        id="demo-simple-select-standard-label"
                                                        style={{
                                                            color: 'black',
                                                            marginTop: '90px',
                                                        }}
                                                    >
                                                        {/* Product */}
                                                    </InputLabel>
                                                    <CssTextField
                                                        required
                                                        fullWidth
                                                        id="hsn"
                                                        label="HSN Code"
                                                        variant="standard"
                                                        name="hsn"
                                                        value={
                                                            CommissionProduct.hsn
                                                        }
                                                        onChange={(e) =>
                                                            setCommissionProduct(
                                                                {
                                                                    ...CommissionProduct,
                                                                    hsn:
                                                                        e.target
                                                                            .value,
                                                                }
                                                            )
                                                        }
                                                    />
                                                </div>
                      <div className="row">

                        {/* <div className="col-md-3 mt-3">
                            <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black', marginTop: '90px' }}>
                            Product
                            </InputLabel>
                            <CssTextField 
                    required
                    fullWidth 
                    id="productName"
                    label="Product Name" 
                    variant="standard"
                    name="productName" value={CommissionProduct.productName}
                    onChange={(e)=>setCommissionProduct({...CommissionProduct, productName:e.target.value})}
                  /> 
                          </div> */}
                      </div>
                      {/* <div class="col-md-3">
                 <CssTextField 
                    required
                    fullWidth 
                    id="productName"
                    label="Product Name" 
                    variant="standard"
                    name="productName" value={CommissionProduct.productName}
                    onChange={(e)=>setCommissionProduct({...CommissionProduct, productName:e.target.value})}
                  /> 
                 </div> */}
                    </div>

                    <div className="row justify-content-end mb-3">
                      <div className="col-md-1">
                        <button type="submit" className="btn btn-danger">Cancel</button>
                      </div>
                      <div className="col-md-1">
                        <button type="submit" className="btn btn-primary" onClick={() => handleCommissionProduct()}>Submit</button>
                      </div>
                    </div>

                  </div>
                </form>
              </div>
              {/* Create Quantity & Unit */}
              <div id="variant" class="card filter-card">
                <form class="card-body pb-2" onSubmit={(e) => e.preventDefault()}>
                  <div className="mt-3">
                    <h5 class="card-title">Quantity & unit</h5>
                    <div class="row mb-4">
                      <div class="col-md-3">
                        <FormControl variant="standard" fullWidth className="mt-3">
                          <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Category</InputLabel>
                          <Select
                            required
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            //  value={age}
                            //  onChange={handleChange}
                            label="Firm"
                            // QuantityARR,setQuantityArr
                            color="success" value={QuantityARR.categoryName}
                            onChange={(e) => handleCategoryChangeVarient(e)}
                          //  onChange={(e)=>setQuantityArr({...VariantProduct,categoryName:e.target.value})}
                          >
                            {
                              Categorylist && Categorylist.map((val, ind) => {
                                return (
                                  <MenuItem value={val.categoryName}>{val.categoryName}</MenuItem>
                                )
                              })
                            }
                          </Select>
                        </FormControl>
                      </div>
                      <div class="col-md-3">
                        <FormControl variant="standard" fullWidth className="mt-3">
                          <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>SubCategory</InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            //  value={age}
                            //  onChange={handleChange}
                            label="Booking Mode"
                            color="success"
                            value={QuantityARR.subCategoryName}
                            onChange={(e) => handlechangeproductVarientBrand(e)}
                          //  onChange={(e)=>setVariantProduct({...VariantProduct,subCategoryName:e.target.value})}
                          >
                            {
                              subCategorylist && subCategorylist.map((val, ind) => {
                                return (
                                  <MenuItem value={val.subCategoryName}>{val.subCategoryName}</MenuItem>
                                )
                              })
                            }
                          </Select>
                        </FormControl>
                      </div>
                      <div class="col-md-3">
                        <FormControl variant="standard" fullWidth className="mt-3">
                          <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Brand Name</InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            //  value={age}
                            //  onChange={handleChange}
                            label="BRAND Name"
                            color="success" value={QuantityARR.brandName}
                            onChange={(e) => handlechangeproductVarientProduct(e)}
                          // onChange={(e)=>setVariantProduct({...VariantProduct,brandName:e.target.value})}
                          >
                            {
                              brandlist && brandlist.map((val, ind) => {
                                return (
                                  <MenuItem value={val.brandName}>{val.brandName}</MenuItem>
                                )
                              })
                            }
                          </Select>
                        </FormControl>
                      </div>
                      <div class="col-md-3">
                        <FormControl variant="standard" fullWidth className="mt-3">
                          <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Product Name</InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            //  value={age}
                            //  onChange={handleChange}
                            label="Product Name"
                            color="success"
                            value={QuantityARR.productName}
                            onChange={(e) => setQuantityArr({ ...QuantityARR, productName: e.target.value })}
                          >
                            {
                              Productlist && Productlist.map((val, ind) => {
                                return (
                                  <MenuItem value={val.productName}>{val.productName}</MenuItem>
                                )
                              })
                            }
                          </Select>
                        </FormControl>
                      </div>
                      <div class="col-md-3">
                        <CssTextField
                          required
                          fullWidth
                          id="quantity"
                          label="quantity"
                          variant="standard"
                          name="quantity" value={
                            QuantityARR.quantity}
                          onChange={(e) => setQuantityArr({ ...QuantityARR, quantity: e.target.value })}
                        />
                      </div>

                      {/* <div class="col-md-3">
                 <CssTextField 
                    required
                    fullWidth 
                    id="unit"
                    label="unit" 
                    variant="standard"
                    name="unit"  value={
                      QuantityARR.unit}
                    onChange={(e)=>setQuantityArr({...QuantityARR, unit:e.target.value})}
                  /> 
                 </div> */}
                      <div class="col-md-3">
                        <FormControl variant="standard" fullWidth className="mt-3">
                          <InputLabel id="demo-simple-select-standard-label" style={{ color: 'black' }}>Unit</InputLabel>
                          <Select
                            required
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            //  value={age}
                            //  onChange={handleChange}
                            label="Unit"
                            color="success"
                            value={QuantityARR.unit}
                            //  onChange={(e)=>setInventory({...Invendory,unit:e.target.value})}
                            onChange={(e) => setQuantityArr({ ...QuantityARR, unit: e.target.value })}
                          >
                          
                            <MenuItem value="">
                              <em>- Select Unit - </em>
                            </MenuItem>
                            <MenuItem value={"GM"}>GM</MenuItem>
                            <MenuItem value={"KG"}>KG</MenuItem>
                            <MenuItem value={"ML"}>ML</MenuItem>
                            <MenuItem value={"LTR"}>LTR</MenuItem>
                            <MenuItem value={"SEEDS"}>SEEDS</MenuItem>
                            <MenuItem value={"BAG"}>BAG</MenuItem>
                            <MenuItem value={"PACKET"}>PACKET</MenuItem>

                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="row justify-content-end mb-3">
                      <div className="col-md-1">
                        <button type="submit" className="btn btn-danger">Cancel</button>
                      </div>
                      <div className="col-md-1">
                        <button type="submit" className="btn btn-primary" onClick={() => handleVariantProduct()}>Submit</button>
                      </div>
                    </div>

                  </div>
                </form>
              </div>

              {/* <div class="row">
    <div class="col-sm-12">
    <h3 className="mb-3">List</h3>
       <div class="card card-table">
          <div class="card-body">
             <div class="table-responsive">
                <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                   <div class="row">
                      <div class="col-sm-12 col-md-6">
                         <div class="dataTables_length" id="DataTables_Table_0_length">
                            <label>
                               Show 
                               <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" class="custom-select custom-select-sm form-control form-control-sm">
                                  <option value="10">10</option>
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                               </select>
                               entries
                            </label>
                         </div>
                      </div>
                      <div class="col-sm-12 col-md-3 position_right">
                         <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search" />
                         </div>                                                
                      </div>
                      <div class="row" style={{margin:'2%'}}>
                     </div>
                   </div>
                   <div class="row">
                      <div class="col-sm-12 col-md-5">
                         <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 1 to 10 of 12 entries</div>
                      </div>
                      <div class="col-sm-12 col-md-7">
                         <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                            <ul class="pagination">
                               <li class="paginate_button page-item previous disabled" id="DataTables_Table_0_previous"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li>
                               <li class="paginate_button page-item active"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0" class="page-link">1</a></li>
                               <li class="paginate_button page-item "><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="2" tabindex="0" class="page-link">2</a></li>
                               <li class="paginate_button page-item next" id="DataTables_Table_0_next"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="3" tabindex="0" class="page-link">Next</a></li>
                            </ul>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </div> */}
            </>
            :
            // <InpCategories />
            <div class="row">
              <div class="col-sm-12">
                {/* <h3 className="mb-3">Input Supplier Products</h3> */}
                <div class="card card-table">
                  <div class="card-body">
                    <div class="table-responsive">
                      <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div class="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="DataTables_Table_0_length">
                              <label>
                                Show
                                <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" class="custom-select custom-select-sm form-control form-control-sm">
                                  <option value="10">10</option>
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                </select>
                                entries
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-3 position_right">
                            <div class="form-group">
                              <input type="text" class="form-control" placeholder="Search" />
                            </div>
                          </div>
                        </div>
                        <Grid item xs={8}>
                    <div
                        style={{
                            padding: '30px 0',
                            display: 'flex',
                            width: '500px',
                            justifyContent: 'space-between',
                            marginBottom: '60px',
                            flexWrap: 'wrap',
                        }}
                    >
                        <div>
                            <FormControl variant="standard" className="mt-3">
                                <Selected

                                    style={{
                                        width: '270px',
                                        height: '40px',
                                    }}
                                    placeholder={'Select Category'}
                                    onChange={(e) => handlecategory(e)}
                                    options={categoryoption}
                                />
                            </FormControl>
                        </div>

                        {formValues.map((element, index) => {
                            return (
                                <>
                                    <div>
                                        {console.log('input', formValues)}
                                        <FormControl
                                            variant="standard"
                                            className="mt-3"
                                        >
                                            <Selected
                                                style={{
                                                    width: '250px',
                                                    height: '40px',
                                                }}
                                                placeholder={
                                                    'Select Sub Category'
                                                }
                                                onChange={(e) =>
                                                    handlesubcategory(index, e)
                                                }
                                                options={subcategoryoption}
                                            />
                                        </FormControl>
                                    </div>

                                    <div>
                                        <FormControl
                                            variant="standard"
                                            className="mt-3"
                                        >
                                            <Selected
                                                style={{
                                                    width: '250px',
                                                    height: '40px',
                                                }}
                                                placeholder={'Select Brand'}
                                                onChange={(e) =>
                                                    handleBrandcategory(
                                                        index,
                                                        e
                                                    )
                                                }
                                                options={brandoption}
                                            />
                                        </FormControl>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </Grid>
                        <div class="row" style={{ margin: '2%' }}>
                          <ProductTable inputs={ProductInput} />
                        </div>

                        <div class="row">
                          <div class="col-sm-12 col-md-5">
                            <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 1 to 10 of 12 entries</div>
                          </div>
                          <div class="col-sm-12 col-md-7">
                            <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                              <ul class="pagination">
                                <li class="paginate_button page-item previous disabled" id="DataTables_Table_0_previous"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li>
                                <li class="paginate_button page-item active"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0" class="page-link">1</a></li>
                                <li class="paginate_button page-item "><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="2" tabindex="0" class="page-link">2</a></li>
                                <li class="paginate_button page-item next" id="DataTables_Table_0_next"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="3" tabindex="0" class="page-link">Next</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </>
      }

    </>
  )
}

export default InputCatelog